import ModelForm from "./ModelForm";
import ModelPreview from "./ModelPreview";
import "./Checkout.scss";
import { CheckOutProps } from "./CheckOutprops";

// Pages- order-details and order-summary
const Checkout = ({ setPrototypePage, prototypePage }: CheckOutProps) => (
  <div className="checkout">
    <ModelForm
      setPrototypePage={setPrototypePage}
      prototypePage={prototypePage}
    />
    <ModelPreview setPrototypePage={setPrototypePage} />
  </div>
);

export default Checkout;
