import { useEffect, useState } from 'react';
import './UnapprovedUser.scss';
import SocialLinks from '../socialLinks/SocialLinks';

const UnapprovedUser: React.FC<{}> = () => {
	// State to manage the email address
	const [email, setEmail] = useState('');

	// Load the email from local storage when the component mounts
	useEffect(() => {
		const signinEmail = window.localStorage.getItem('signinEmail');
		if (signinEmail) {
			setEmail(signinEmail);
			window.localStorage.removeItem('signinEmail');
		}
	}, []);

	return (
		<div id="unapproved-container" className="tw-text-center">
			<p>
				Get ready for an exciting journey! 🚀 Someone from the team will reach out shortly
				to your email address: &nbsp;<span className="email">{email}</span>. Your patience
				is appreciated as we prepare to launch into this adventure!
			</p>
			<button
				type="button"
				className="primary-btn tw-w-full tw-text-sm tw-mb-4 tw-rounded-3xl tw-max-w-[300px]"
				onClick={() => {
					window.location.href = '/';
				}}
			>
				Back to homepage
			</button>
			<SocialLinks />
		</div>
	);
};

export default UnapprovedUser;
