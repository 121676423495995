import { IUser, TAnswer, TUserAndRole } from '@naya_studio/types';
import { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { ReduxState } from 'src/redux/reducers/root.types';
import { getAllUsersBasedOnIds } from 'src/redux/reduxActions/util';
import { Button, HtmlTextViewer, FeedbackInput, UserT } from '@naya_studio/radix-ui';
import _ from 'lodash';
import useFeedbackActions from 'src/util/feedback/useFeedbackActions';
import { useParams } from 'react-router';
import { PathParamsType } from 'src/types/pathParams';
import useUser from 'src/redux/hooks/user';
import { getFormattedUsersData } from '../util';
import { FeedbackKebabMenuProps, FeedbackUserMenu } from '../feedbackUserMenu/FeedbackUserMenu';

// Props for answer component
export type AnswerProps = {
	allAnswers: TAnswer[];
	answer: TAnswer;
	setParentEditState: (val: boolean) => void;
	feedbackId: string;
	actionItemAssignees: string[];
	setActionItemAsignees: (userIds: string[]) => void;
};

// QNA's answer component
export const Answer = ({
	answer,
	setParentEditState,
	allAnswers,
	feedbackId,
	actionItemAssignees,
	setActionItemAsignees
}: AnswerProps) => {
	// hold the feedback text
	const [feedbackText, setFeedbackText] = useState('');
	// hold the edit state of the answer
	const [isEditing, setIsEditing] = useState(false);

	// extract current user
	const { user } = useUser();

	// extracting ids from url
	const { canvasId: blockId } = useParams<PathParamsType>();

	// extract all blocks from redux
	const allBlocks = useSelector((state) => (state as ReduxState).blocks.data, shallowEqual);

	// extract user role objs in current block
	const userRoles = allBlocks[blockId]?.users as TUserAndRole[];

	// extract all populated users based on ids
	const allUsers = getAllUsersBasedOnIds(userRoles.map((x: TUserAndRole) => x.user as string));

	// extarcting functions from feedback actions hook
	const { sendEditFeedback, extractTextFromHTML } = useFeedbackActions();

	// setting initial data
	useEffect(() => {
		setFeedbackText(answer.answer);
	}, [answer]);

	// extract user data based o feedback's created by
	const getFeedbackCreatorData = () => {
		const creator = getAllUsersBasedOnIds([answer.answeredBy as string])[0] as IUser;
		return {
			name: creator.userName,
			profilePic: creator.profilePic
		};
	};

	// send out an api call to delete Q & A
	const onDeleteAnswer = () => {
		const newAnswers = allAnswers?.filter((an: TAnswer) => an.answeredBy !== user._id);
		sendEditFeedback({
			_id: feedbackId,
			answers: newAnswers
		});
	};

	// send out an API call to update QNA
	const updateAnswer = () => {
		const tempAnswers = _.cloneDeep(allAnswers);
		if (tempAnswers) {
			for (let i = 0; i < tempAnswers.length; i++) {
				if (tempAnswers[i]?._id === answer._id) {
					tempAnswers[i]!.answer = feedbackText;
				}
			}
		}
		sendEditFeedback({
			_id: feedbackId,
			answers: tempAnswers
		});
		setIsEditing(false);
	};

	// function to modify action item asigneed
	const onTagUser = (selectedIds: string[]) => {
		const tempList = actionItemAssignees;
		for (let i = 0; i < selectedIds.length; i++) {
			if (!actionItemAssignees.includes(selectedIds[i] as string)) {
				tempList.push(selectedIds[i] as string);
			}
		}
		setActionItemAsignees(tempList);
	};

	/**
	 * Renders -
	 * 1. User's profile pic
	 * 2. user's display name
	 * 3. Created at time
	 * 4. Kebab menu
	 */
	const renderUserDetails = () => {
		// generating dropdown options to be passes to kebab menu
		const dropdownOptions: { key: string; callback: () => void }[] = [
			{
				key: 'Edit Answer',
				callback: () => {
					setIsEditing(true);
					setParentEditState(true);
				}
			},
			{ key: 'Delete Answer', callback: onDeleteAnswer }
		];

		const props: FeedbackKebabMenuProps = {
			options: answer.answeredBy === user._id ? dropdownOptions : undefined,
			profilePic: getFeedbackCreatorData().profilePic || '',
			name: (getFeedbackCreatorData().name as string) || '',
			createdAt: answer.answeredAt as string
		};

		return <FeedbackUserMenu {...props} />;
	};

	return (
		<div className="tw-p-2">
			{isEditing ? (
				<>
					<FeedbackInput
						text={feedbackText}
						variant="BORDERED"
						onSubmit={updateAnswer}
						onEsc={() => {
							setFeedbackText(answer.answer || '');
						}}
						id={answer._id as string}
						placeHolder="Short answer text"
						onTagUser={onTagUser}
						users={getFormattedUsersData(allUsers, false).blockUsers as UserT[]}
						currentUser={getFormattedUsersData(allUsers, false).currentUser}
						onInputChange={(value: string) => {
							setFeedbackText(value);
						}}
						onShowReplyBtn={() => {}}
						className="tw-mb-4"
						key={answer._id as string}
					/>
					<div className="tw-flex">
						<Button
							variant="PRIMARY"
							text="Save"
							className="tw-mr-2"
							disabled={extractTextFromHTML(feedbackText).length === 0}
							onBtnClick={updateAnswer}
						/>
						<Button
							variant="SECONDARY-GREY"
							text="Cancel"
							disabled={false}
							onBtnClick={() => {
								setIsEditing(false);
								setFeedbackText(answer.answer || '');
							}}
						/>
					</div>
				</>
			) : (
				<>
					{renderUserDetails()}
					{/* rendering question text  */}
					<HtmlTextViewer text={feedbackText} className="tw-pt-4 tw-text-xs" />
				</>
			)}
		</div>
	);
};
