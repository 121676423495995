import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'src/redux/reducers/root.types';
import { loadNoteById } from '../reduxActions/notes';

/**
 * Custom hook to access all the notes and state of note with provided id
 * @param id - note id to be accessed
 * @returns redux state of note with provided id and all redux notes
 */
const useNotes = (id?: string) => {
	const dispatch = useDispatch();
	const noteId = id as string;

	const { reduxNotes, note, isLoading, error } = useSelector((state: ReduxState) => ({
		reduxNotes: state.notes.data,
		note: state.notes.data[noteId],
		isLoading: state.notes.loading[noteId],
		error: state.notes.error[noteId]
	}));

	/**
	 * Re-runs when hook is mounted
	 */
	useEffect(() => {
		// If note does not exist send a dispatch to load it into redux
		if (!note && noteId) {
			dispatch(loadNoteById({ payload: { _id: noteId } }));
		}
	}, []);

	return {
		reduxNotes,
		note,
		isLoading,
		error
	};
};

export default useNotes;
