import { IFeedback, IUser, TUserAndRole } from '@naya_studio/types';
import { useEffect, useState } from 'react';
import { getAllUsersBasedOnIds } from 'src/redux/reduxActions/util';
import { Button, HtmlTextViewer, FeedbackInput, UserT, WarningModal } from '@naya_studio/radix-ui';
import { shallowEqual, useSelector } from 'react-redux';
import { ReduxState } from 'src/redux/reducers/root.types';
import useFeedbackActions from 'src/util/feedback/useFeedbackActions';
import { useParams } from 'react-router';
import { PathParamsType } from 'src/types/pathParams';
import useUser from 'src/redux/hooks/user';
import { FeedbackUserMenu, FeedbackKebabMenuProps } from '../feedbackUserMenu/FeedbackUserMenu';
import { getFormattedUsersData } from '../util';

// Props for question component
export type QuestionProps = {
	data: IFeedback;
	setParentEditState: (val: boolean) => void;
	isEditState: boolean;
	actionItemAssignees: string[];
	setActionItemAsignees: (userIds: string[]) => void;
};

// Question component of QNA feedback type
export const Question = ({
	data,
	setParentEditState,
	isEditState,
	actionItemAssignees,
	setActionItemAsignees
}: QuestionProps) => {
	// hold the feedback text
	const [feedbackText, setFeedbackText] = useState('');
	// hold the edit state state of the question input
	const [isEditing, setIsEditing] = useState(false);

	// handles the visibility of delete warning modal
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	// extracting ids from url
	const { canvasId: blockId } = useParams<PathParamsType>();

	// extract current user
	const { user } = useUser();

	// extract all blocks from redux
	const allBlocks = useSelector((state) => (state as ReduxState).blocks.data, shallowEqual);

	// extract all user role objs of users in current block
	const userRoles = allBlocks[blockId]?.users as TUserAndRole[];

	// extract all populated users based on ids
	const allUsers = getAllUsersBasedOnIds(userRoles.map((x: TUserAndRole) => x.user as string));

	// extarcting functions from feedback actions hook
	const {
		sendEditFeedback,
		sendDeleteFeedback,
		getTaggedUsersFromHtmlString,
		extractTextFromHTML
	} = useFeedbackActions();

	useEffect(() => {
		setIsEditing(isEditState);
	}, [isEditState]);

	useEffect(() => {
		if (data.statement) setFeedbackText(data.statement);
	}, [data.statement]);

	// extract user data based o feedback's created by
	const getFeedbackCreatorData = () => {
		const creator = data.createdBy as IUser;
		return {
			name: creator.userName,
			profilePic: creator.profilePic
		};
	};

	// send out an api call to delete Q & A
	const onDeleteQNA = () => {
		setShowDeleteModal(false);
		sendDeleteFeedback(data._id as string);
	};

	// send out an API call to update QNA
	const updateQNA = () => {
		setIsEditing(false);
		setParentEditState(false);

		if (feedbackText === '' || feedbackText === '<p><br></p>') {
			return;
		}

		// geenrating the api payload for edit action
		sendEditFeedback({
			_id: data._id as string,
			statement: feedbackText,
			taggedUsers: getTaggedUsersFromHtmlString(feedbackText)
		});
	};

	// function to modify action item asigneed
	const onTagUser = (selectedIds: string[]) => {
		const tempList = actionItemAssignees;
		for (let i = 0; i < selectedIds.length; i++) {
			if (!actionItemAssignees.includes(selectedIds[i] as string)) {
				tempList.push(selectedIds[i] as string);
			}
		}
		setActionItemAsignees(tempList);
	};

	/**
	 * Renders -
	 * 1. User's profile pic
	 * 2. user's display name
	 * 3. Created at time
	 * 4. Kebab menu
	 */
	const renderUserDetails = () => {
		// generating dropdown options to be passes to kebab menu
		const dropdownOptions: { key: string; callback: () => void }[] = [
			{
				key: 'Edit Q&A',
				callback: () => {
					setIsEditing(true);
					setParentEditState(true);
				}
			},
			{
				key: 'Delete Q&A',
				callback: () => {
					setShowDeleteModal(true);
				}
			}
		];

		const props: FeedbackKebabMenuProps = {
			options: (data.createdBy as IUser)._id === user._id ? dropdownOptions : undefined,
			profilePic: getFeedbackCreatorData().profilePic || '',
			name: (getFeedbackCreatorData().name as string) || '',
			createdAt: data.createdAt as string
		};

		return <FeedbackUserMenu {...props} />;
	};

	return (
		<div className="tw-p-2 tw-rounded-[16px] tw-mb-4" style={{ backgroundColor: '#F4ECFF' }}>
			<WarningModal
				show={showDeleteModal}
				onClose={() => setShowDeleteModal(false)}
				onModalSubmit={onDeleteQNA}
				title="Are you sure?"
				bodyText={
					<div>
						Are you sure you want to delete the short Q&A? This will delete all the
						answers given in response to this short question.
					</div>
				}
				cancelBtnText="Cancel"
				submitBtnText="Delete"
				showDAA={false}
			/>
			{renderUserDetails()}
			{isEditing ? (
				<>
					<FeedbackInput
						text={feedbackText}
						variant="BORDERED"
						onSubmit={updateQNA}
						onEsc={() => {
							setFeedbackText(data.statement || '');
						}}
						id={data._id as string}
						placeHolder="Add a question to create poll"
						className="tw-pt-4 tw-pb-4"
						onTagUser={onTagUser}
						users={getFormattedUsersData(allUsers, false).blockUsers as UserT[]}
						currentUser={getFormattedUsersData(allUsers, false).currentUser}
						onInputChange={(value: string) => {
							setFeedbackText(value);
						}}
						onShowReplyBtn={() => {}}
						key={data._id as string}
					/>
					<div className="tw-flex tw-pr-2">
						<Button
							variant="PRIMARY"
							text="Save"
							disabled={extractTextFromHTML(feedbackText).length === 0}
							onBtnClick={updateQNA}
							className="tw-mr-2"
						/>
						<Button
							variant="SECONDARY-GREY"
							text="Cancel"
							disabled={false}
							onBtnClick={() => {
								setIsEditing(false);
								setParentEditState(false);
								setFeedbackText(data.statement || '');
							}}
						/>
					</div>
				</>
			) : (
				// rendering question text
				<HtmlTextViewer text={feedbackText} className="tw-pt-4 tw-text-xs" />
			)}
		</div>
	);
};
