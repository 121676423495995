import { INode } from "@naya_studio/types"
import { store } from "src"

// eslint-disable-next-line import/prefer-default-export
export const getNodeData = (nodeId: string) => {
    const reduxState = store.getState();

    if(reduxState.nodes.data[nodeId]) {
        return reduxState.nodes.data[nodeId] as INode;
    }

    return null;
}