import App from '../App';
import BaseNode from '../nodes/BaseNode';
import { onTransformCommit } from '../transformerActions';

const moveNodes = (delta: { x: number, y: number }, app:App) => {
  const nodes = app.selectedNodes as BaseNode[]
  nodes.forEach((node) => {
    if (node.displayObject) {
      node.displayObject.x += delta.x;
      node.displayObject.y += delta.y;
      node._transformerChanged = true;
      onTransformCommit(app);
      node.save();
    }
  });
};

// Path: src/components/canvas/app/utils/moveNode.ts
export default moveNodes;
