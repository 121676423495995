import {
	EBlockType,
	I3D,
	I3DSubtype,
	IBlock,
	ICanvas,
	IFeedback,
	IFile,
	IFileSubtype,
	IImage,
	ILink,
	ILinkSubtype,
	IPdf,
	IText,
	ITodo,
	IUser,
	IVideo,
	TPoint
} from '@naya_studio/types';
import { TMyTaskItem } from 'src/util/notifications/useNotifications';

export type PathParamsType = {
	canvasId: string;
	projectId: string;
	stageOrDashboard: string;
};

export type TSearchQuery = {
	text?: string; // text search
	types?: string[]; // any file/ link
	sharedWith?: Partial<IUser>;
	createdBy?: Partial<IUser>;
	lastUpdated?: boolean;
	stickyNotes?: string[];
	dueDate?: boolean;
	timelineView?: boolean;
};

// Get the tools from env. Pass an empty array if REACT_APP_TOOLS is not present in user's env file
export const TOOLS = JSON.parse(process.env.REACT_APP_TOOLS || '[]');

export type TCombinedBlock = IBlock &
	ICanvas &
	IImage &
	Omit<ILink, 'subType'> &
	IPdf &
	IVideo &
	IText &
	ITodo &
	Omit<I3D, 'subType'> &
	Omit<IFile, 'subType'> & {
		sharedWith: IUser[];
		subType: string;
		isFavorite: boolean;
		isGeneratedByAI: boolean;
		tasks: TMyTaskItem[];
		hasFeedback: boolean;
	};

export type ICommonSubType =
	| keyof typeof EBlockType
	| keyof typeof I3DSubtype
	| keyof typeof ILinkSubtype
	| keyof typeof IFileSubtype;

export enum EFeedbackActionViewer {
	ADD_FB = 'ADD_FB',
	CLEAR = 'CLEAR',
	UPDATE_NEW_FEEDBACK = 'UPDATE_NEW_FEEDBACK',
	UPDATE_FEEDBACK = 'UPDATE_FEEDBACK',
	RERENDER = 'RERENDER',
	UPDATE_POS_ALL_FB = 'UPDATE_POS_ALL_FB'
}

export type TAbsBounds = {
	x: number;
	y: number;
	width: number;
	height: number;
};
export type TPaperView = {
	zoom: number;
	bounds: TAbsBounds;
	/**
	 * Converts the passed point from project coordinate space to view
	 * coordinate space, which is measured in browser pixels in relation to the
	 * position of the view element.
	 *
	 * @param point - the point in project coordinates to be converted
	 *
	 * @return the point converted into view coordinates
	 */
	projectToView(point: TPoint): TPoint;
};
export type TPaperImageRef = {
	bounds: TAbsBounds;
	view: TPaperView;
	/**
	 * Converts the passed point from project coordinate space to view
	 * coordinate space, which is measured in browser pixels in relation to the
	 * position of the view element.
	 *
	 * @param point - the point in project coordinates to be converted
	 *
	 * @return the point converted into view coordinates
	 */
	projectToView(point: TPoint): TPoint;
};

export type TPaperUnsupportedRef = {
	view: TPaperView;
};

export type NayaStatsBlockType = {
	[key in EBlockType]: number;
};

export type NayaStats = {
	noOfBlocks: number;
	blockTypeCounts: NayaStatsBlockType;
	noOfPhases: number;
	noOfFeedback: number;
};

/** Feedback with the index */
export type IFeedbackWithIndex = IFeedback & { index?: number };

// Deliverable type for different deliverables options
export type IDeliverableTypes = 'DOWNLOAD_POSTER' | 'DOWNLOAD_FILES' | undefined;

export type TGuestAccessTo = 'BLOCK' | 'PROJECT' | false;

// Launch darkly flags
export type TLDFlags = {
	miroCanvas: boolean;
	generativeAiFeatures: boolean;
	textEditor: boolean;
	notesFavourites: boolean;
	journeyZoom: boolean;
	isDueDateEnabled: boolean;
	myTaskEnabled: boolean;
	journeyRtc: boolean;
	guestAccess: boolean;
	isTextTo3DEnabled: boolean;
	isTemplatesEnabled: boolean;
	isPmAiEnabled: boolean;
	is3DGenEnabled: boolean;
	createInNaya: boolean;
	isMiroIntegrated: boolean;
	isGoogleIntegrated: boolean;
	isFullscreen: boolean;
	isMultiSelectEnabled: boolean;
	isJourneyAlignEnabled: boolean;
	isReusingNotesEnabled: boolean;
	isBlockResetEnabled: boolean;
	isPmAiHackthon: boolean;
	isCurrentHomebase: boolean;
	isCustomThumbnail: boolean;
	isCustomColorEnabled: boolean;
	isProductTourEnabled: boolean;
	isSyncStorageEnabled: boolean;
	isSyncDriveEnabled: boolean;
	isTextToTextAiEnabled: boolean;
	isUndoRedo: boolean;
	isPopUpsEnabled: boolean;
	isAiRenameEnabled: boolean;
	isAiOrganizeEnabled: boolean;
	isZoomOnStudioEnabled: boolean;
	isLinkEmbedsEnabled: boolean;
	isMultiselectAiEnabled: boolean;
	isPasswordProtectionEnabled: boolean;
	isLoggingEnabled: boolean;
	isExcalidrawCanvasEnabled: boolean;
	isAiPresentationEnabled: boolean;
	isTodoBlocksEnabled: boolean;
	isImageEnhancementsEnabled: boolean;
	isJourneyToolbarEnabled: boolean;
	isTimelineEnabled: boolean;
	isFeedbackAiEnabled: boolean; // Flag to enable feedback AI
	isBlockShareEnabled?: boolean; // flag to enable block sharing
	isBlockFavoriteEnabled?: boolean; // flag to enable block fav
	isGeneratePresentationEnabled?: boolean; // flag to enable generating a presentation
	isSummarizeEnabled?: boolean; // flag to enable summarizing content
	isCheckboxEnabled?: boolean; // flag to enable checkbox functionality
	isMultiselectPresentEnabled?: boolean; // flag to enable presenting multiple selections
	isMultiselectDownloadEnabled?: boolean; // flag to enable downloading multiple selections
	isMultiselectShareEnabled?: boolean; // flag to enable sharing multiple selections
	isMultiselectCreateGroupEnabled?: boolean; // flag to enable creating a group with multiple selections
	isGenerateVideoEnabled?: boolean; // flag to enable generating a video
	isGenerateEstimateEnabled?: boolean; // flag to enable generating an estimate
	isEvaluateSustainabilityEnabled?: boolean; // flag to enable evaluating sustainability
	isEvaluateManufacturingEnabled?: boolean; // flag to enable evaluating manufacturing
	isGenerateNextStepsEnabled?: boolean; // flag to enable generating next steps
	isShareGroupEnabled?: boolean; // flag to enable sharing in a group
	isMarkupEnabled?: boolean; // flag to enable markup
	isCreateOptionsEnabled?: boolean; // flag to enable create option in the journey toolbar.
	isNayaLabsEnabled?: boolean; /// flag to enable naya labs feature in homebase.
	isHomebaseSearchEnabled?: boolean; // flag to enable homebase search bar.
	isProjectCollaboratorsEnabled?: boolean; // flag to enable collaborators feature in homebase project.
	isTurnIntoTemplateEnabled?: boolean; // flag to enable turn into template option.
	isProjectFavoriteEnabled?: boolean; // flag to enable favorite project feature in homebase.
	isMoveProjectEnabled?: boolean; // flag to enable move to project option in project context menu homebase.
	isEmailNotificationOptionEnabled?: boolean; // flag to toggle email notification of a project from context menu on homebase
	isProfileOptionEnabled?: boolean; // flag to toggle profile option in user dropdown.
	isPasteHereEnabled?: boolean;
	isOpenLinkEnabled?: boolean; // flag to open a block link in a new tab
	isCollapseBlocksEnabled?: boolean;
};

/** Status of the project/block/user */
export type TProjectStatuses = 'PROJECT_NOT_FOUND' | 'PROJECT_IS_DELETED' | 'FAILED_TO_LOAD';

export type TBlockStatuses = 'BLOCK_NOT_FOUND' | 'BLOCK_IS_DELETED';

export type TAccess = 'ALLOWED' | 'DENIED' | 'PENDING';

export type TGroupParent = {
	id: string;
	type: 'JOURNEY' | 'PHASE';
};

/**
 * Type for Reminder modal details
 */
export type TReminderModalDetails = {
	show: boolean;
	onRedirect: () => void;
};

/**
 * Type for Redirect modal details
 */
export type TRedirectModalDetails = {
	/**
	 * Boolean to indicate whether block is last block in navigation
	 */
	canSkip: boolean;
	/**
	 * Link of the Project of Naya block
	 */
	link: string;
	/**
	 * Callback for cancelling the action
	 */
	onCancel: () => void;
};
