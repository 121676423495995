import {
	createContext,
	useCallback,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
	forwardRef,
	useContext,
	useMemo
} from 'react';
import {
	ContextMenu,
	Pagination as BackToJourney,
	PasswordModal,
	SessionStorageKeys,
	TreeEntry
} from '@naya_studio/radix-ui';
import { useHistory, useLocation, useParams } from 'react-router';
import { Helmet } from 'react-helmet';
import {
	generateIdsFromUrl,
	getFeedbackBasedOnBlockType,
	getStagesBlocksNodesOfProject
} from 'src/redux/reduxActions/util';
import {
	EAIGenerationType,
	EJobType,
	EUserRole,
	IFeedback,
	IFeedbackBlock,
	ILink,
	INode,
	INodesBlock,
	TEditBlockFnArgs,
	TLinkEdit,
	TUserAndRole
} from '@naya_studio/types';
import useProject from 'src/redux/hooks/useProject';
import { shallowEqual, useSelector } from 'react-redux';
import { ISnackBar, OpenCommentTypes, ReduxState } from 'src/redux/reducers/root.types';
import { store } from 'src';
import { addNewCommentData, resetNewComment } from 'src/redux/features/newComment';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';
import { throttle } from 'lodash';
import { TextEditorRef, View } from '@naya_studio/viewers';
import useBlockActions from 'src/util/block/useBlockActions';
import { checkAccess, checkUserAccessLevel } from 'src/util/accessLevel/accessLevelActions';
import useFeedbackActions from 'src/util/feedback/useFeedbackActions';
import useUser from 'src/redux/hooks/user';
import { fetchBlockByIdFromRedux, findStageWithStageId } from 'src/redux/actions/util';
import { getGoogleFileDetails } from 'src/redux/reduxActions/integrations/googleIntegrations';
import filterPhaseAndBlocks from 'src/util/collaboration/searchAndSortAlgo';
import { EMultiselectAIOpt, getMessageContent } from 'src/util/block/utilAI';
import App from '../canvas/app/App';
import FeedbackWrapper from '../canvas/feedback/feedbackWrapper/feedbackWrapper';
import { checkIfUserHasEditAccess } from '../canvas/utils/helper';
import {
	EFeedbackActionViewer,
	IFeedbackWithIndex,
	PathParamsType,
	TGroupParent,
	TPaperImageRef,
	TPaperUnsupportedRef
} from '../collaborationTool/CollaborationTool.types';
import { ExpandedBlockProps } from './ExpandedBlockView.types';
import './ExpandedBlockView.scss';
import { getUserFirstName } from '../feedbackSuite/util';
import AIContextMenu from './AIContextMenu';
import { AuthContext } from '../auth/AuthProvider';
import { getFormattedData, isProjectEditor } from '../journeyContainer/JourneyContainerCallbacks';
import { getPastedData } from '../../features/copyPaste/paste';
import ViewerPaginationWrapper from './ViewerPaginationWrapper';
import { CollaborationToolContext } from '../collaborationTool/CollaborationTool';

export const FeedbackContext = createContext<{
	activeFeedbackId: string | undefined; // holds expanded feedback id
	setActiveFeedbackId: (id: string | undefined) => void; // function to set active feedback id
	feedbackView: 'DEFAULT' | 'PANEL_VIEW'; // holds feedback view
	showPanel: boolean; // holds booleon value for show/hide of Feedback Panel
	setShowPanel: (show: boolean) => void; // function to set visiblity of Feedback Panel
}>({
	activeFeedbackId: undefined,
	setActiveFeedbackId: () => {},
	feedbackView: 'DEFAULT',
	showPanel: false,
	setShowPanel: () => {}
});

/**
 * @Component Creates Expanded View of block
 * @param { ExpandedBlockProps } props
 * @returns ReactComponent
 */
export const ExpandedBlock = forwardRef(
	(
		{
			app,
			currentBlock,
			onBlockShare,
			createThumbnailOfPage,
			activeFeedbackId,
			setActiveFeedbackId,
			children: canvas,
			onGenerateWithAI,
			flags,
			status,
			query,
			handleUndoRedo,
			isUndoActive,
			isRedoActive
		}: ExpandedBlockProps,
		forwardedRef
	) => {
		const location = useLocation();
		// fetch all blocks from redux
		const allBlocks = useSelector((state) => (state as ReduxState).blocks.data, shallowEqual);
		// extract integration access tokens
		const { google } = useSelector((state) => (state as ReduxState).integrations);
		// handles feedback view in expanded state
		const [feedbackView, setFeedbackView] = useState<'DEFAULT' | 'PANEL_VIEW'>('DEFAULT');
		// handles feedback of a block on block change
		const [feedbacks, setFeedbacks] = useState<IFeedback[]>([]);
		// used to rerender FBS whenever block, view type changes
		const [renderFBS, setRenderFBS] = useState(false);
		// handles show/hide Feedback Panel
		const [showPanel, setShowPanel] = useState<boolean>(false);
		// handle show/hide comment bubbles
		const [showBubbles, setShowBubbles] = useState<boolean>(true);
		// handle full screen mode
		const [fullScreen, setFullScreen] = useState<boolean>(false);
		// State to store whether to show the password modal
		const [showPasswordModal, setShowPasswordModal] = useState<boolean>(
			Boolean(currentBlock?.password && flags.isPasswordProtectionEnabled)
		);

		const {
			handleRegenerate,
			updateMultiselectAiProgressSnackbar,
			handleReplaceOlder,
			isGuestUser
		} = useContext(CollaborationToolContext);

		// useEffect to update the state
		useEffect(() => {
			const storedValue = sessionStorage.getItem(SessionStorageKeys.VERIFIED_BLOCK_IDS);
			const blockIds: string[] = storedValue ? JSON.parse(window.atob(storedValue)) : [];
			setShowPasswordModal(
				Boolean(currentBlock?.password) &&
					!blockIds.includes(currentBlock?._id?.toString() || '')
			);
		}, [currentBlock?.password]);

		// Ref to store if of the new block that's created when asset is dropped on last expanded block
		const idOfNewBlockAtEndRef = useRef<string>();

		// Get fullscreen status from session
		const fullScreenBlockId = window.sessionStorage.getItem('edit-fullscreen');

		const history = useHistory();
		const params = useParams<PathParamsType>();
		const { project } = useProject(params.projectId);
		const allFeedbacksOfBlock = useSelector((state) => {
			const allFeedbacks = Object.values((state as ReduxState).feedbacks.data);
			const blockFeedbacks: { [key: string]: IFeedback } = {};
			for (let i = 0; i < allFeedbacks.length; i++) {
				const feedback = allFeedbacks[i];
				if (feedback?.blockId === params.canvasId)
					blockFeedbacks[feedback._id as string] = feedback;
			}

			return blockFeedbacks;
		}, shallowEqual);
		const allNodesOfBlock = useSelector((state) => {
			const allNodes = Object.values((state as ReduxState).nodes.data);
			const blockNodes: { [key: string]: INode } = {};
			for (let i = 0; i < allNodes.length; i++) {
				const node = allNodes[i];
				if (node?.blockId === params.canvasId) blockNodes[node._id as string] = node;
			}

			return blockNodes;
		}, shallowEqual);
		const newComment = useSelector(
			(state) => (state as ReduxState).newComment.data,
			shallowEqual
		);
		const { user } = useUser();

		const currentStage = useSelector(
			(state: ReduxState) => state.stages.data[params.stageOrDashboard]
		);

		const { hasGuestAccessTo } = useContext(AuthContext);

		// extarcting functions from feedback actions hook
		const { sendEditFeedback } = useFeedbackActions();

		// Ref whose value is received from viewer's PaperJs. used for FBS on Image, Link and Unsupported Blocks
		const viewerRef = useRef<TPaperImageRef | TPaperUnsupportedRef | null>(null);
		const textEditorRef = useRef<TextEditorRef>(null);
		// Holds reference of feedback bubbles
		const feedbacksRef = useRef<NodeListOf<Element>>();

		const {
			onBlockEdit,
			getBlockWithPopulatedNodes,
			getBlockNameById,
			onAddBlocks,
			onFolderOrLinkDrop
		} = useBlockActions();

		/**
		 * Callback to handle edit block calls
		 */
		const onEditBlock = async (data: TEditBlockFnArgs) => {
			const idOfNewlyCreatedBlock = await onBlockEdit(data);

			// When file/link is dropped on expanded block, store the newly created block's id in ref
			if (
				(data.editType === 'ADD_BLOCKS_UPLOAD' || data.editType === 'NEW_BLOCK_ADD_LINK') &&
				idOfNewlyCreatedBlock
			)
				idOfNewBlockAtEndRef.current = idOfNewlyCreatedBlock;
		};

		// Function to sync block name with integrated file name
		const syncIntegratedLinkBlockName = async () => {
			// only for LINK blockTypes
			if (currentBlock?.blockType !== 'LINK') return;

			const { link, subType } = currentBlock as ILink;

			let fileName = '';

			// getting file details
			if (
				['GOOGLE_DOCS', 'GOOGLE_SHEETS', 'GOOGLE_SLIDES', 'GOOGLE_DRIVE'].includes(
					subType
				) &&
				flags.isGoogleIntegrated
			) {
				// GOOGLE
				const details = await getGoogleFileDetails(google.access_token, link);
				if (!details.status) return;
				fileName = details.data.name;
			}
			if (['MIRO'].includes(subType) && flags.isMiroIntegrated) {
				// MIRO
			}

			// updating block name
			if (fileName !== '' && fileName !== currentBlock.name)
				onBlockEdit({
					editType: 'BLOCK_EDIT',
					blockId: currentBlock._id as string,
					payload: { name: fileName }
				});
		};

		/**
		 * Block with nodes populated inside it
		 */
		const populatedBlock = useMemo(
			() =>
				currentBlock
					? getBlockWithPopulatedNodes(currentBlock._id as string, !!hasGuestAccessTo)
					: currentBlock,
			[allNodesOfBlock, (currentBlock as INodesBlock)?.nodes, currentBlock]
		);

		// resize listner for viewers
		const setRenderFBSListner = useCallback(() => {
			setRenderFBS((renderFBSStatus) => !renderFBSStatus);
		}, []);

		/**
		 * Re-renders when feedback is changed in redux
		 */
		useEffect(() => {
			setRenderFBS((renderFBSStatus) => !renderFBSStatus);
		}, [allFeedbacksOfBlock, (currentBlock as IFeedbackBlock)?.feedbacks]);

		/**
		 * @param val : a ref that holds refernce to either Imager or Unsupported(Viewer)
		 */
		const updateViewerRef = (val: any) => {
			viewerRef.current = val.current as TPaperImageRef | TPaperUnsupportedRef;
			setRenderFBS((renderFBSStatus) => !renderFBSStatus);
			window.addEventListener('resize', setRenderFBSListner);
		};

		/** Function to handle feedbackpanelevent */
		function handleFeedbackPanelEvent(e: CustomEvent<{ show: boolean }>) {
			setShowPanel(e.detail.show);
		}

		/**
		 * Memoized block list with block ids as keys and parent ids as values
		 */
		const simplifiedBlockList = useMemo(() => {
			const simplifiedBlockListFromSession =
				window.sessionStorage.getItem('simplified-blocklist');
			const blockWrapperArray = document.querySelectorAll<HTMLDivElement>('#block-wrapper');
			let visibleBlocks = {} as { [key: string]: string };

			// If block wrapper array exists navigate it
			if (blockWrapperArray.length) {
				blockWrapperArray.forEach((blockWrapper) => {
					const blockId = blockWrapper.getAttribute('data-blockid');

					if (blockId) {
						const block = fetchBlockByIdFromRedux(blockId);
						if (block && block.parentId) visibleBlocks[blockId] = block.parentId;
						// If block.parentId is not present then get the groupId from dom
						else {
							const groupWrapper = blockWrapper.closest<HTMLDivElement>('#phase-rbd');

							if (groupWrapper) {
								const groupId = groupWrapper.getAttribute('data-phaseid');
								if (groupId) visibleBlocks[blockId] = groupId;
							}
						}
					}
				});

				return visibleBlocks;
			}
			// If it does not(in case of refresh inside expanded view), use data stored in session
			if (simplifiedBlockListFromSession)
				visibleBlocks = JSON.parse(simplifiedBlockListFromSession);
			// If block wrapper does not exist, and order is not present in session then use redux
			// This would happen if someone directly opens a block url
			else {
				const { notes: reduxNotes } = store.getState();

				if (project?._id && Object.keys(query).length) {
					const { stages, blocksJson } = getStagesBlocksNodesOfProject(
						project?._id as string
					);
					const { formattedBlocks: blocks, formattedPhases: phases } = getFormattedData(
						stages,
						blocksJson,
						reduxNotes.data
					);
					const filteredResult = filterPhaseAndBlocks(query, phases, blocks);

					if (filteredResult) {
						Object.keys(filteredResult?.blocks).forEach((key) => {
							if (filteredResult?.blocks[key]?.parentId)
								visibleBlocks[key] = filteredResult.blocks[key]!.parentId as string;
						});
					}
				} else {
					project?.children?.forEach((id) => {
						const group = findStageWithStageId(id as string);

						if (group && group.isVisible) {
							group.children.forEach((bid) => {
								const block = fetchBlockByIdFromRedux(bid as string);
								if (block) visibleBlocks[bid as string] = block.parentId;
							});
						}
					});
				}
			}

			return visibleBlocks;
		}, [params.projectId, query, allBlocks.length]);

		useEffect(() => {
			/**
			 * Function to store simplified block list in session before refresh
			 * To persist the navigation order in case someone refreshed inside expanded view
			 */
			const onBeforeUnload = () => {
				const simplifiedBlockListToStore = simplifiedBlockList;

				// If someone dropped an asset on expanded block then update it as well
				if (idOfNewBlockAtEndRef.current)
					simplifiedBlockListToStore[idOfNewBlockAtEndRef.current] =
						generateIdsFromUrl().stageId;

				window.sessionStorage.setItem(
					'simplified-blocklist',
					JSON.stringify(simplifiedBlockListToStore)
				);
			};

			window.addEventListener('beforeunload', onBeforeUnload);
			return () => {
				window.removeEventListener('beforeunload', onBeforeUnload);
			};
		}, [simplifiedBlockList]);

		// triggered when block id and allFeedbacksOfBlock is changed
		useEffect(() => {
			const sortedFeedbacks = getFeedbackBasedOnBlockType(
				currentBlock?._id as string
			) as IFeedbackWithIndex[];
			// if feedbackView is PANEL_VIEW then add count and sort it
			if (feedbackView === 'PANEL_VIEW') {
				// add count to feedbacks before sorting
				const feedbackWithIndex = sortedFeedbacks.map((each, index) => ({
					...each,
					index: index + 1
				}));
				// sort based on updatedAt field
				feedbackWithIndex.sort(
					(a: IFeedback, b: IFeedback) =>
						new Date(b.updatedAt as string).valueOf() -
						new Date(a.updatedAt as string).valueOf()
				);
				setFeedbacks(feedbackWithIndex);
			} else {
				setFeedbacks(sortedFeedbacks);
			}
		}, [
			allFeedbacksOfBlock,
			currentBlock?._id,
			feedbackView,
			(currentBlock as IFeedbackBlock)?.feedbacks
		]);

		// triggered when block is changed
		useEffect(() => {
			// sync link block names with integrated blocks
			if (flags.isCreateInNayaEnabled) syncIntegratedLinkBlockName();

			// check block type to set feedback view
			if (currentBlock && ['THREE_D', 'VIDEO'].includes(currentBlock.blockType)) {
				setFeedbackView('PANEL_VIEW');
			} else {
				setFeedbackView('DEFAULT');
			}
		}, [currentBlock]);

		// run on initial mouse and on feedbacks change
		useEffect(() => {
			// Get all the feedback bubbles when feedbacks data changes
			const feedbackElements = document.querySelectorAll('.feedback-trigger');
			feedbacksRef.current = feedbackElements;
		}, [feedbacks]);

		// Expose setRenderFBS to parent component
		useImperativeHandle(forwardedRef, () => ({ setRenderFBS }));

		// Function to update feedbacks for INTEGRATION, PDF, VIDEO viewers
		function updateFeedbacks() {
			throttle(() => {
				if (
					currentBlock?.blockType &&
					!['CANVAS', 'THREE_D', 'IMAGE', 'VIDEO'].includes(currentBlock.blockType) &&
					!viewerRef.current
				) {
					// During initial mount feedbacksRef will have empty list, so added a check here
					// If no feedbacks stored in the ref then get all the feedbacks and store them in ref
					if (feedbacksRef.current && !feedbacksRef.current.length) {
						feedbacksRef.current = document.querySelectorAll('.feedback-trigger');
					}
					const feedbackElements = feedbacksRef.current;
					feedbackElements?.forEach((feedback) => {
						// get translateX, Y values of each fb
						const x = feedback.getAttribute('data-translateX');
						const y = feedback.getAttribute('data-translateY');
						if (x && y) {
							(feedback as HTMLButtonElement).style.transform = `translate3d(${
								+x * window.innerWidth
							}px, ${+y * window.innerHeight}px, 0px)`;
						}
					});
				}
			}, 50)();
		}

		/**
		 * Handles AI-related data from session storage, triggering updates to the UI and executing additional actions if provided.
		 *
		 * @param {string} storageKey - The session storage key where AI data is stored.
		 * @param {string} messageSuffix - A suffix to append to the message displayed in the snackbar.
		 * @param {string} buttonMsg - The message to display on the snackbar button.
		 * @param {function} buttonAction - The action to perform when the snackbar button is clicked, which initiates the AI process.
		 * @param {boolean} [isRegenerate=false] - Flag indicating whether the AI operation is a regeneration.
		 * @param {function} [additionalAction] - An optional additional action to perform with the current block AI data.
		 */
		const handleAIData = (
			storageKey: string,
			messageSuffix: string,
			buttonMsg: string,
			buttonAction: (
				aiType: EMultiselectAIOpt,
				prevPhaseId?: string,
				shouldReplace?: boolean
			) => void,
			isRegenerate: boolean = false,
			additionalAction?: (currentBlockAIData: any) => void
		): void => {
			// Retrieve AI-related data from session storage using the provided key.
			const AIData = window.sessionStorage.getItem(storageKey);
			if (AIData) {
				// Parse the AI data from JSON format.
				const parsedData = JSON.parse(AIData);
				const currentBlockAIData = parsedData[currentBlock?._id as string];
				if (currentBlockAIData) {
					// Remove the AI data from session storage after processing.
					window.sessionStorage.removeItem(storageKey);

					// Update the snackbar with the relevant message, type, and action button.
					updateMultiselectAiProgressSnackbar({
						start: 0,
						end: 0,
						msg: `${getMessageContent(
							currentBlockAIData.aiType,
							isRegenerate
						)}! ${messageSuffix}`,
						snackbarType: 'NORMAL',
						buttonMsg,
						buttonAction: () =>
							buttonAction(
								currentBlockAIData.aiType,
								currentBlockAIData.prevBlockPhase
							)
					});

					// Execute any additional action if provided.
					if (additionalAction) {
						additionalAction(currentBlockAIData);
					}
				}
			}
		};

		// triggered on block change
		useEffect(() => {
			handleAIData(
				'ai-generate-data',
				'Want to explore different results?',
				'Regenerate',
				(aiType) => handleRegenerate(aiType, undefined, 1)
			);

			handleAIData(
				'ai-regenerate-data',
				'',
				'Replace older',
				(aiType, prevId) => {
					if (prevId) {
						handleReplaceOlder(prevId, aiType, false, false);
					}
				},
				true
			);
			// remove new comment if it existis on block change
			const { newComment: reduxNewComment } = store.getState();
			if (reduxNewComment.data.initialComment || reduxNewComment.data.initialType)
				store.dispatch(resetNewComment());

			window.addEventListener('resize', updateFeedbacks);
			return () => {
				window.removeEventListener('resize', updateFeedbacks);
				window.removeEventListener('resize', setRenderFBSListner);
				viewerRef.current = null;
			};
		}, [currentBlock?._id]);

		// Runs on mount, used in full screen exit
		useEffect(() => {
			// Attaches a listener to CustomEvent which can be fired/dispatched from children level elements
			// Used to hide and show feedback panel from video block level
			document.addEventListener('feedbackpanelevent', handleFeedbackPanelEvent);

			// Used to collapse the expanded feedback
			const handleEscKey = (e: KeyboardEvent) => {
				if (e.code === 'Escape') {
					setFullScreen(false);
					removeSnackbar(0);
					const fromJourney = window.sessionStorage.getItem('edit-fullscreen');
					if (fromJourney) {
						history.push(`/project/${params.projectId}`);
					}
					window.sessionStorage.removeItem('edit-fullscreen');
				}
			};
			window.addEventListener('keydown', handleEscKey);
			return () => {
				document.removeEventListener('feedbackpanelevent', handleFeedbackPanelEvent);
				window.removeEventListener('keydown', handleEscKey);

				window.sessionStorage.removeItem('simplified-blocklist');
				window.sessionStorage.removeItem('edit-fullscreen');
				removeSnackbar(0);
				const feedbackWidget = document.querySelector<HTMLIFrameElement>('#jsd-widget');
				if (feedbackWidget && feedbackWidget.style.display === 'none') {
					feedbackWidget.style.display = 'block';
				}
			};
		}, []);

		// triggers if edit fullscreen is selected from journey
		useEffect(() => {
			if (!fullScreen && fullScreenBlockId === (currentBlock?._id as string)) {
				setFullScreen(true);
				addSnackbar({
					show: true,
					type: 'NORMAL',
					actionButtonData: [
						{
							buttonData: 'Exit fullscreen',
							onClick: () => {
								removeSnackbar(0);
								setFullScreen(false);
								const fromJourney =
									window.sessionStorage.getItem('edit-fullscreen');
								if (fromJourney) {
									// persisting project zoom value in params for guest user
									const projectZoomValue =
										!user._id || isGuestUser
											? location.state?.projectZoomValue
											: null;
									history.push({
										pathname: `/project/${params.projectId}`,
										search: projectZoomValue ? `?zoom=${projectZoomValue}` : ''
									});
								}
								window.sessionStorage.removeItem('edit-fullscreen');
							},
							className: 'snackbar-gothere',
							show: true
						}
					],
					text: (
						<p
							style={{
								display: 'flex',
								alignItems: 'center',
								textAlign: 'start',
								margin: 0
							}}
						>
							Editing block in full screen.
						</p>
					)
				});
			}
			// Hide feedback widget in full screen
			const feedbackWidget = document.querySelector('#jsd-widget') as HTMLIFrameElement;
			if (feedbackWidget) {
				if (fullScreen) {
					feedbackWidget.style.display = 'none';
				} else {
					feedbackWidget.style.display = 'block';
				}
			}
		}, [fullScreenBlockId, fullScreen, currentBlock, currentBlock?._id]);

		/**
		 * This function handles feedback in viewers
		 * @param action required action to be passed from view
		 * @param e event
		 * @param feedbackType feedback option type
		 */
		const handleFeedbackInViewer = (
			action: keyof typeof EFeedbackActionViewer,
			e?: any,
			feedbackType?: 'CHAT' | 'POLL',
			feedbackData?: Partial<IFeedback>
		) => {
			switch (action) {
				case EFeedbackActionViewer.ADD_FB: {
					if (viewerRef.current) {
						if ((viewerRef.current as TPaperImageRef).bounds) {
							// --- ADDING FEEDBACK TO IMAGE OR LINK BLOCK THAT HAS AN IMAGE ---
							const viewer = viewerRef.current as TPaperImageRef;
							// convert bounds' x, y wrt to view
							const projToViewVal = viewer.view.projectToView({
								x: viewer.bounds.x,
								y: viewer.bounds.y
							});

							// get exact pointer location
							const imgX = e.offsetX - projToViewVal.x;
							const imgY = e.offsetY - projToViewVal.y;

							// get zoomed height and width of the image
							const imgWidth = viewer.bounds.width * viewer.view.zoom;
							const imgHeight = viewer.bounds.height * viewer.view.zoom;

							// absoluteBounds' x, y lie between 0 and 1
							const data: IFeedback = {
								absoluteBounds: { x: imgX / imgWidth, y: imgY / imgHeight },
								statement: '',
								feedbackType
							};

							// update position of feedback in redux's newComment
							store.dispatch(addNewCommentData(data));
						} else {
							// --- ADDING FEEDBACK TO UNSUPPORTED FILE OR LINK BLOCK ---

							const viewer = viewerRef.current as TPaperUnsupportedRef;

							// convert bounds' x, y wrt to view
							const projToViewVal = viewer.view.projectToView({
								x: viewer.view.bounds.x,
								y: viewer.view.bounds.y
							});

							// get exact pointer location
							const imgX = e.offsetX - projToViewVal.x;
							const imgY = e.offsetY - projToViewVal.y;

							// get zoomed height and width of the image
							const imgWidth = viewer.view.bounds.width * viewer.view.zoom;
							const imgHeight = viewer.view.bounds.height * viewer.view.zoom;

							// absoluteBounds' x, y lie between 0 and 1
							const data: IFeedback = {
								absoluteBounds: { x: imgX / imgWidth, y: imgY / imgHeight },
								statement: '',
								feedbackType
							};

							// update position of feedback in redux's newComment
							store.dispatch(addNewCommentData(data));
						}
					} else {
						// update position of feedback in redux's newComment
						let data: IFeedback = {};
						switch (currentBlock?.blockType) {
							case 'THREE_D': {
								data = {
									...feedbackData,
									statement: '',
									feedbackType
								};
								break;
							}
							case 'VIDEO': {
								data = {
									...feedbackData,
									statement: '',
									feedbackType
								};
								break;
							}
							default: {
								// Feedback on integrations
								data = {
									absoluteBounds: {
										x: e.offsetX / window.innerWidth,
										y: e.offsetY / window.innerHeight
									},
									statement: '',
									feedbackType
								};
							}
						}
						// update position of feedback in redux's newComment
						store.dispatch(addNewCommentData(data));
					}
					break;
				}
				case EFeedbackActionViewer.CLEAR: {
					// clear redux of new comment data. Used when changing tool
					store.dispatch(resetNewComment());
					// clear open comment data from redux to prevent comment pop-ups on navigation
					store.dispatch({
						type: OpenCommentTypes.OPEN_COMMENT,
						payload: { commentId: undefined }
					});
					break;
				}
				case EFeedbackActionViewer.UPDATE_NEW_FEEDBACK: {
					const { initialComment, initialType } = newComment;
					// if new comment is already present and feedback option is changes,
					// update the new comment data
					if (initialComment && initialType !== feedbackType) {
						const data: IFeedback = {
							...initialComment,
							feedbackType
						};
						store.dispatch(addNewCommentData(data));
					}
					break;
				}
				case EFeedbackActionViewer.RERENDER: {
					setRenderFBS((renderFBSStatus) => !renderFBSStatus);
					break;
				}
				case EFeedbackActionViewer.UPDATE_POS_ALL_FB: {
					// The positions for FB only need to be updated for image viewer when it is zoomed and panned

					// During initial mount feedbacksRef will have empty list, so added a check here
					// If no feedbacks stored in the ref then get all the feedbacks and store them in ref
					if (feedbacksRef.current && !feedbacksRef.current.length) {
						feedbacksRef.current = document.querySelectorAll('.feedback-trigger');
					}
					const feedbackElements = feedbacksRef.current;
					// loop through each fb
					feedbackElements?.forEach((feedback) => {
						// get translateX, Y values of each fb
						const x = feedback.getAttribute('data-translateX');
						const y = feedback.getAttribute('data-translateY');

						if (x && y) {
							if ((viewerRef.current as TPaperImageRef).bounds) {
								const viewer = viewerRef.current as TPaperImageRef;

								// convert img bounds from project to view
								const projToViewVal = viewer.view.projectToView({
									x: viewer.bounds.x,
									y: viewer.bounds.y
								});

								// get height and width of the image
								const imgWidth = viewer.bounds.width * viewer.view.zoom;
								const imgHeight = viewer.bounds.height * viewer.view.zoom;

								// get exact coordinates of fb wrt to img
								const imgX = +x * imgWidth + projToViewVal.x;
								const imgY = +y * imgHeight + projToViewVal.y + 49;

								// NOTE - subtract 50px to take into acc the approx height and width of fb marker
								if (
									imgY > window.innerHeight - 50 ||
									imgX > window.innerWidth - 50
								) {
									// if fb co-ords out of window, hide fb
									(feedback as HTMLButtonElement).style.display = 'none';
								} else {
									// else show fb and translate its postion
									(feedback as HTMLButtonElement).style.display = 'block';
									(
										feedback as HTMLButtonElement
									).style.transform = `translate3d(${imgX}px, ${imgY}px, 0px)`;
								}
							}
						}
					});
					break;
				}
				case EFeedbackActionViewer.UPDATE_FEEDBACK: {
					sendEditFeedback({
						...feedbackData
					});
					break;
				}
				default:
					break;
			}
		};

		/**
		 * Generate AI renders based on prompt and aiType
		 * @param aiType type of AI to be generated
		 * @param prompt
		 */
		const generateAiBlocks = (aiType: keyof typeof EAIGenerationType, prompt?: string) => {
			const { projectId, canvasId, stageOrDashboard } = params;
			const allBlocksInPhase = findStageWithStageId(stageOrDashboard)
				? findStageWithStageId(stageOrDashboard)?.children
				: [];
			let genType = EJobType.TEXT_TO_IMAGE_GENERATION;
			switch (aiType) {
				case 'IMAGE':
					genType = EJobType.TEXT_TO_IMAGE_GENERATION;
					break;
				case 'TEXT':
					genType = EJobType.TEXT_GENERATION;
					break;
				default:
					break;
			}
			const index = allBlocksInPhase?.indexOf(canvasId) || 0;
			if (!prompt) prompt = textEditorRef.current?.getPlainText();
			if (prompt)
				onGenerateWithAI({
					type: genType,
					aiType,
					prompt,
					phaseId: stageOrDashboard,
					newBlockIndex: index + 1,
					projectId
				});
		};

		// generate image AI blocks
		const handleImageGeneration = (prompt: string, aiType: keyof typeof EAIGenerationType) => {
			generateAiBlocks(aiType, prompt);
		};

		/**
		 * True if current user has edit access to the current block
		 */
		const userHasEditAccessToBlock = useMemo(
			() =>
				user.userType?.includes('ADMIN') ||
				checkUserAccessLevel(currentBlock?.users as TUserAndRole[], user._id as string, [
					'OWNER',
					'EDITOR'
				]),
			[(user._id, user.userType, currentBlock?.users, hasGuestAccessTo, currentBlock?._id)]
		);

		/**
		 * Memoized value to store user's role on expanded block
		 */
		const userRole = useMemo(() => {
			if (user.userType?.includes('ADMIN')) return 'EDITOR';
			let role = 'VIEWER' as keyof typeof EUserRole;

			currentBlock?.users?.forEach((bUser) => {
				if ((bUser as TUserAndRole).user?.toString() === user._id?.toString())
					role = (bUser as TUserAndRole).role as keyof typeof EUserRole;
			});

			return role;
		}, [user._id, user.userType, currentBlock?.users, hasGuestAccessTo, currentBlock?._id]);

		/**
		 * True, if current user has edit access to current block's phase
		 */
		const userHasEditAccessToStage = useMemo(
			() =>
				currentStage &&
				checkUserAccessLevel(currentStage.users as TUserAndRole[], user._id as string, [
					'OWNER',
					'EDITOR'
				]),
			[user._id, currentStage?.users]
		);

		const hasAccessToProject = useMemo(
			() =>
				hasGuestAccessTo === 'PROJECT' ||
				isProjectEditor() ||
				checkAccess(params.projectId, 'PROJECT', true),
			[project?.users, currentBlock?.users, hasGuestAccessTo, currentStage?.users]
		);

		/**
		 * Block input options
		 */
		const blockOptions = useMemo(() => {
			let options = JSON.parse(
				window.__RUNTIME_CONFIG__.REACT_APP_BLOCK_OPTIONS ||
					'["LINK","FILE","CANVAS","AI_IMAGE","TEXT","MIRO_CANVAS","TODO"]'
			);
			// If a block has guest access and user doesn't have edit access, just allow them to upload file and enter link
			if (currentBlock?.hasGuestAccess && !userHasEditAccessToBlock) {
				options = options.filter((option: string) => ['LINK', 'FILE'].includes(option));
			}
			return options;
		}, [
			window.__RUNTIME_CONFIG__.REACT_APP_BLOCK_OPTIONS,
			currentBlock?.hasGuestAccess,
			userHasEditAccessToBlock
		]);

		/**
		 * Sent as props to handle fullScreen from Expandded View toolbar
		 */
		const handleFullScreen = () => {
			setFullScreen(true);
			addSnackbar({
				show: true,
				type: 'NORMAL',
				actionButtonData: [
					{
						buttonData: 'Exit fullscreen',
						onClick: () => {
							removeSnackbar(0);
							setFullScreen(false);
						},
						className: 'snackbar-gothere',
						show: true
					}
				],
				text: (
					<p
						style={{
							display: 'flex',
							alignItems: 'center',
							textAlign: 'start',
							margin: 0
						}}
					>
						Editing block in full screen.
					</p>
				)
			});
		};
		/**
		 * Function to get message according to status
		 * @returns {string} message
		 */
		const getErrorMessage = () => {
			if (status === 'BLOCK_IS_DELETED') {
				return "Oops! The block you're looking for has been deleted.";
			}
			return "Oops! The block you're looking for couldn't be found.";
		};

		// Show fullscreen button only for google and miro files
		const showFullScreenBtn =
			currentBlock?.blockType === 'LINK' &&
			['GOOGLE_DOCS', 'GOOGLE_SHEETS', 'GOOGLE_SLIDES', 'MIRO'].includes(
				(currentBlock as ILink)?.subType
			);

		// This function handles pasted data and prepares a payload based on its type.
		const handlePaste = async () => {
			const { stageId } = generateIdsFromUrl();
			// Get the pasted data using the getPastedData function
			const pastedData = (await getPastedData()) as
				| { type: 'FILE' | 'LINK'; data: File[] | string }
				| undefined;

			if (pastedData && pastedData.type === 'FILE') {
				// If pasted data is of type 'FILE', prepare payload for files
				(pastedData.data as File[]).forEach((file: File) => {
					onAddBlocks({
						blocksToAdd: [
							{
								addType: 'FILE',
								payload: file,
								blockId: currentBlock?._id as string
							}
						],
						options: {
							phaseId: stageId,
							existingBlockId: currentBlock?._id as string
						}
					});
				});
			} else if (pastedData && pastedData.type === 'LINK') {
				// If pasted data is of type 'LINK', prepare payload for links
				onEditBlock({
					editType: 'ADD_LINK',
					blockId: currentBlock?._id as string,
					payload: {
						blockType: 'LINK',
						link: pastedData.data as string
					} as TLinkEdit
				});
			}

			if (!pastedData) {
				// Show an error snackbar if the pasted data is invalid
				const snackbarPayload: ISnackBar = {
					text: 'Link is invalid. Please try again.',
					show: true,
					type: 'ERROR'
				};

				if (!pastedData) {
					// if pastedData is undefined, it denotes pasting of files

					// Update snackbar msg according to Mac/Windows
					if (navigator.userAgent.indexOf('Mac') !== -1) {
						snackbarPayload.text =
							'Unable to paste through edit menus. Try Cmd+V instead.';
					} else {
						snackbarPayload.text =
							'Unable to paste through edit menus. Try Ctrl+V instead.';
					}
				}

				// Set the snackbar data to display the error message
				addSnackbar(snackbarPayload);
				// Hide the snackbar after 5 seconds
				removeSnackbar(5000);
			}
		};

		/**
		 * Function to handle remove password
		 */
		const handleRemovePassword = (): void => {
			const storedValue = sessionStorage.getItem(SessionStorageKeys.VERIFIED_BLOCK_IDS);
			let blockIds: string[] = storedValue ? JSON.parse(window.atob(storedValue)) : [];
			if (blockIds.length > 0) {
				blockIds = blockIds.filter((bckId) => bckId !== (currentBlock?._id as string));
				sessionStorage.setItem(
					SessionStorageKeys.VERIFIED_BLOCK_IDS,
					window.btoa(JSON.stringify(blockIds))
				);
			}
			onBlockEdit({
				editType: 'REMOVE_PASSWORD',
				blockId: currentBlock?._id as string,
				payload: { password: null }
			});
			setShowPasswordModal(false);
		};
		/**
		 * Handle folder upload.
		 * Create groups, nested groups, and blocks.
		 * @param {TreeEntry[]} folderData Folder tree / Links
		 */
		const handleFolderOrLinkDrop = useCallback(
			(folderData: TreeEntry[]) => {
				if (currentStage && currentBlock) {
					const parent: TGroupParent = { id: currentStage._id.toString(), type: 'PHASE' };
					const currentBlockIndex = currentStage.children.indexOf(
						currentBlock._id as string
					);
					const isEmpty = currentBlock.blockType === 'EMPTY';
					if (currentBlockIndex !== -1) {
						onFolderOrLinkDrop(
							folderData,
							parent,
							currentBlockIndex + 1,
							isEmpty ? currentBlock._id.toString() : undefined
						);
					}
				}
			},
			[currentStage, currentBlock]
		);

		// Memoized group parent for its block
		const groupParent: {
			id: string;
			type: 'GROUP' | 'JOURNEY';
		} = useMemo(
			() => ({
				id: project?._id as string,
				type: currentStage?.parentId === project?._id ? 'JOURNEY' : 'GROUP'
			}),
			[currentBlock?._id]
		);

		// Stores whether block is protected or not
		const isProtected = showPasswordModal && flags.isPasswordProtectionEnabled;

		return (
			<div
				className={`expanded-block-view tw-h-screen tw-bg-[#f5f5f5]
					${fullScreen ? 'full-screen' : ''}
					${showFullScreenBtn ? 'full-screen-btn' : ''}
				`}
			>
				{/* Set the title that is shown in browser tab */}
				<Helmet>
					<title>{getBlockNameById(currentBlock?._id as string) || 'Naya'}</title>
				</Helmet>
				{hasAccessToProject && (
					<div className="canvas-pagination">
						<BackToJourney
							data-test-id="btn-back-to-journey"
							onBack={() => {
								const blockId = params.canvasId;
								// persisting project zoom value in params for guest user
								const projectZoomValue =
									!user._id || isGuestUser
										? location.state?.projectZoomValue
										: null;
								history.push({
									pathname: `/project/${params.projectId}`,
									search: projectZoomValue ? `?zoom=${projectZoomValue}` : ''
								});

								createThumbnailOfPage(blockId).then(() => {
									if (app) {
										app.hasCanvasUpdatedForThumbnail = false;
										if (currentBlock?.blockType === 'CANVAS')
											app.textInputNode.removeDOMElement();
									}
								});
							}}
						/>
					</div>
				)}
				{currentBlock && !isProtected && (
					<FeedbackContext.Provider
						value={{
							activeFeedbackId,
							setActiveFeedbackId,
							feedbackView,
							showPanel,
							setShowPanel
						}}
					>
						<FeedbackWrapper
							feedbacks={feedbacks as IFeedback[]}
							app={app as App}
							canvasId={currentBlock?._id as string}
							view={viewerRef}
							renderFBS={renderFBS}
							key={currentBlock._id as string}
						/>
					</FeedbackContext.Provider>
				)}
				{/* if children i.e Canvas component is present then mount Canvas otherwise mount View */}
				{currentBlock?.blockType === 'CANVAS' ? (
					canvas
				) : (
					<div style={{ fontSize: '1px' }}>
						{currentBlock ? (
							<ContextMenu>
								<ContextMenu.ContextMenuTrigger
									disabled={!checkIfUserHasEditAccess(user)}
								>
									{isProtected ? (
										<div className="passwordModal tw-absolute">
											<PasswordModal
												blockId={currentBlock?._id.toString()}
												blockName={currentBlock?.name}
												hashedPassword={currentBlock?.password || ''}
												showCloseIcon={false}
												onValidate={() => {
													setShowPasswordModal(false);
												}}
												onClose={() => {
													setShowPasswordModal(false);
												}}
											/>
										</div>
									) : (
										<View
											key={currentBlock?._id as string}
											block={populatedBlock || currentBlock}
											onBlockEdit={(data: TEditBlockFnArgs) =>
												onEditBlock(data)
											}
											userRole={userRole}
											hasEditAccessToStage={userHasEditAccessToStage}
											onBlockShare={onBlockShare}
											handleFeedbackInViewer={handleFeedbackInViewer}
											handleImageGeneration={handleImageGeneration}
											updateViewerRef={updateViewerRef}
											blockOptions={blockOptions}
											flags={flags}
											groupParent={groupParent}
											onAddBlocks={onAddBlocks}
											activeFeedbackId={activeFeedbackId}
											setActiveFeedbackId={setActiveFeedbackId}
											handleFolderOrLinkDrop={handleFolderOrLinkDrop}
											feedbacks={(() => {
												const newFeedbacks = [...feedbacks];
												if (newComment.initialComment) {
													newFeedbacks.push(newComment.initialComment);
												}
												return newFeedbacks;
											})()}
											currentUser={{
												id: user._id as string,
												userName: getUserFirstName(user),
												userType: user.userType
											}}
											style={{
												width:
													showPanel && currentBlock.blockType === 'VIDEO'
														? `calc(100% - 336px - 80px)`
														: '100%'
											}}
											ref={textEditorRef}
											handleFullScreen={handleFullScreen}
											pdfClientId={
												window.__RUNTIME_CONFIG__
													.REACT_APP_ADOBE_CLIENT_ID as string
											}
											projectId={params.projectId}
										/>
									)}
								</ContextMenu.ContextMenuTrigger>
								<ContextMenu.ContextMenuContent>
									{currentBlock.blockType === 'TEXT' &&
										flags.areGenerativeAIFeaturesEnabled && (
											<AIContextMenu
												generateAiBlocks={generateAiBlocks}
												flags={flags}
											/>
										)}
									{flags.isBlockShareEnabled && (
										<ContextMenu.ContextMenuItem onClick={onBlockShare}>
											<div
												className="kebab-items tw-text-xs tw-font-randRegular 
														tw-leading-6 tw-pt-1 tw-pb-1 tw-px-2"
												data-testid="share-block-context"
											>
												<p className="m-0">
													{currentBlock.blockType === 'EMPTY'
														? 'Request file upload'
														: 'Share Block'}
												</p>
											</div>
										</ContextMenu.ContextMenuItem>
									)}
									{currentBlock.blockType === 'EMPTY' && (
										<ContextMenu.ContextMenuItem onClick={handlePaste}>
											<div
												className="kebab-items tw-text-xs tw-font-randRegular 
													tw-leading-6 tw-pt-1 tw-pb-1 tw-px-2"
												data-testid="share-block-context"
											>
												<p className="m-0">Paste here</p>
											</div>
										</ContextMenu.ContextMenuItem>
									)}
									{currentBlock.blockType === 'VIDEO' && (
										<ContextMenu.ContextMenuItem
											onClick={() => {
												// Toggle the visibility of comment bubbles
												const bubblesContainer = document.querySelector(
													'.feedbacks-container'
												) as HTMLDivElement;
												if (bubblesContainer) {
													bubblesContainer.style.display = showBubbles
														? 'none'
														: 'block';
													setShowBubbles((show) => !show);
												}
											}}
										>
											<div
												className="kebab-items tw-text-xs tw-font-randRegular 
													tw-leading-6 tw-pt-1 tw-pb-1 tw-px-2"
												data-testid="hide-comments-context"
											>
												<p className="m-0">
													{showBubbles ? 'Hide' : 'Show'} Comments
												</p>
											</div>
										</ContextMenu.ContextMenuItem>
									)}
									{flags?.isPasswordProtectionEnabled &&
										currentBlock.password && (
											<ContextMenu.ContextMenuItem
												onClick={handleRemovePassword}
											>
												<div
													className="kebab-items tw-text-xs tw-font-randRegular 
													tw-leading-6 tw-pt-1 tw-pb-1 tw-px-2"
													data-testid="password-protection-block-context"
												>
													<p className="m-0">
														Remove password protection
													</p>
												</div>
											</ContextMenu.ContextMenuItem>
										)}
									{flags?.isUndoRedoEnabled && (
										<ContextMenu.ContextMenuItem
											onClick={() => {
												if (isUndoActive) {
													handleUndoRedo({ type: 'UNDO' });
												}
											}}
										>
											<div
												className="kebab-items tw-text-xs tw-font-randRegular 
												tw-leading-6 tw-pt-1 tw-pb-1 tw-px-2"
												data-testid="undo"
											>
												<p
													className={`${
														!isUndoActive && 'tw-text-[#9F9F9F]'
													} m-0`}
												>
													Undo
												</p>
											</div>
										</ContextMenu.ContextMenuItem>
									)}

									{flags?.isUndoRedoEnabled && (
										<ContextMenu.ContextMenuItem
											onClick={() => {
												if (isRedoActive) {
													handleUndoRedo({ type: 'REDO' });
												}
											}}
										>
											<div
												className="kebab-items tw-text-xs tw-font-randRegular 
												tw-leading-6 tw-pt-1 tw-pb-1 tw-px-2"
												data-testid="redo"
											>
												<p
													className={`${
														!isRedoActive && 'tw-text-[#9F9F9F]'
													} m-0`}
												>
													Redo
												</p>
											</div>
										</ContextMenu.ContextMenuItem>
									)}
								</ContextMenu.ContextMenuContent>
							</ContextMenu>
						) : (
							<div
								className="block-not-found tw-flex tw-items-center tw-justify-center tw-flex-col"
								style={{ width: '100%', height: '100vh', fontSize: '16em' }}
							>
								<p>{getErrorMessage()}</p>
							</div>
						)}
					</div>
				)}
				<ViewerPaginationWrapper project={project} params={params} query={query} />
			</div>
		);
	}
);
