import { IObserver } from '@naya_studio/types';
import { store } from 'src';
import { CustomDispatch } from 'src/redux/actions/types';
import { renewFolderWatch } from 'src/redux/reduxActions/observer';

/**
 * Function to renew observer channels based on observer type
 * @param observer - The observer whose channel will be checked for expiration
 * @param accessToken - The access token for authentication
 */
async function renewObserverChannel(observer: IObserver, accessToken: string) {
	switch (observer.observerType) {
		case 'GOOGLE_DRIVE': {
			(store.dispatch as CustomDispatch)(
				renewFolderWatch({ observerId: observer._id as string, accessToken })
			);
			break;
		}
		default:
			break;
	}
}

/**
 * Function to check if observer channel has expired or not
 * @param {IObserver} observer - The observer whose channel will be checked for expiration
 * @param {string} accessToken - The access token for authentication
 * @returns
 */
export default async function checkChannelExpiration(observer: IObserver, accessToken: string) {
	const { channelDetails } = observer;
	if (!channelDetails) return;
	const createdAt = new Date(channelDetails.createdAt);
	const { expiresIn } = channelDetails;
	// Calculate expiration time
	const expirationTime = new Date(createdAt.getTime() + expiresIn * 1000);

	// Get the current time
	const currentTime = new Date();
	const fiveMinutesFromNow = new Date(currentTime.getTime() + 5 * 60 * 1000);
	// Check if the token is expired or will expire in the next five minute
	if (expirationTime <= fiveMinutesFromNow) {
		// Token is expired or about to expire, refresh it
		await renewObserverChannel(observer, accessToken);
	}
}
