/* eslint-disable max-len */
import { EBlockType } from '@naya_studio/types/lib/Block';
import { TI2RData } from './i2rData';

const spaceship: TI2RData = {
	text: `{"root":{"children":[{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":"design a futuristic faceted space shuttle","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}`,
	projectTitle: 'Example - Spaceship',
	groups: [
		{
			name: 'Example - Spaceship Form Inspiration',
			blocks: [
				{
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/spaceship/waehb3emv2ppbdrxjot3',
					name: 'Rocket Frame',
					blockType: EBlockType.IMAGE
				},
				{
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/spaceship/ddp7qqwzmwtqqbhpyapv',
					name: 'Space Vessel',
					blockType: EBlockType.IMAGE
				},
				{
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/spaceship/tshrwlmntorvi4iaanfg',
					name: 'Star Cruiser',
					blockType: EBlockType.IMAGE
				},
				{
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/spaceship/zmtwrjdjwu3ttbw6kigh',
					name: 'Orbital Launch',
					blockType: EBlockType.IMAGE
				}
			]
		},
		{
			name: 'Example - Spaceship Geometric Inspiration',
			blocks: [
				{
					name: 'Geometric Vase',
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/spaceship/rddpanvskbw7ndoeb4je',
					blockType: EBlockType.IMAGE
				},
				{
					name: 'Curved Bowl',
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/spaceship/fj8rwmrg0zdy5kz4gxeg',
					blockType: EBlockType.IMAGE
				}
			]
		},
		{
			name: 'Example - Spaceship Color Inspiration',
			blocks: [
				{
					name: 'Colorful Steps',
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/spaceship/dr8ss2juplzjpe8na8b2',
					blockType: EBlockType.IMAGE
				},
				{
					name: 'Rainbow Arches',
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/spaceship/e7qrs2peq8smtookhzi5',
					blockType: EBlockType.IMAGE
				},
				{
					name: 'Pink Spheres',
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/spaceship/dz3h66ksinz78ecxyzdc',
					blockType: EBlockType.IMAGE
				}
			]
		}
	]
};

export default spaceship;
