import TaggedText from '@naya_studio/pixi-tagged-text';
import { ENodeType, INode } from '@naya_studio/types';
import * as PIXI from 'pixi.js';
import * as THREE from 'three';
import _ from 'lodash';
import App from './App';
import BaseNode from './nodes/BaseNode';
import { positionAuthorFill, positionAuthorImage } from './nodes/utils/helper';
import Model from './nodes/Model';

/**
 * Function for listening transformer's click events
 * @param e
 * @param app
 */
export const onTransformerClick = (e: PIXI.InteractionEvent, app:App) => {
  const isSingleNodeSelected = app.selectedNodes.length;
  const firstSelectedNode = app.selectedNodes[0];
  if (app._lastTransformType !== 'scale') {
    clearTimeout(app._doubleTimer);
    app._doubleTimer = null;
    if (isSingleNodeSelected && firstSelectedNode) {
      if (firstSelectedNode.nodeData.nodeType === 'MODEL') {
        firstSelectedNode.transformerClick(e);
      }
      firstSelectedNode.onDoubleClick(e);
    }
  } else {
    app._lastTransformType = undefined;
  }
  app.toggle3dContext(false);
};

/**
 * Function for listening transformer's mouse up events
 * @param e
 * @param app
 */
export const onTransformerMouseUp = (e: PIXI.InteractionEvent, app:App) => {
  try {
    if (!app._doubleTimer) {
      app._doubleTimer = setTimeout(() => {
        app.selectedNodes.forEach((node:BaseNode) => {
          node.onClick();
        });
        clearTimeout(app._doubleTimer);
        app._doubleTimer = null;
      }, 200);
    }
  } catch (error) {
    console.error(error, e);
  }
};

/**
 * Function for listening transformer's mouse move events
 * @param e
 * @param app
 */
export const onTransformerMouseMove = (e: PIXI.InteractionEvent, app:App) => {
  const isSingleNodeSelected = app.selectedNodes.length;
  const firstSelectedNode = app.selectedNodes[0];
  try {
    if (isSingleNodeSelected && firstSelectedNode?.nodeData.nodeType === 'MODEL') {
      firstSelectedNode.transformerMouseMove(e);
    }
  } catch (error) {
    console.error(error, e);
  }
};

/**
 * Function for listening transformer's transform change events
 * loops over all the selected nodes
 * sets the prev node data for undo redo
 * modify the node data based upon the object's transform
 * @param e
 * @param app
 */
export const onTransformChange = (app:App) => {
  // removing the click listener for sticky note
  // if not removed, sticky note randomly goes into edit mode even when the node is moved
  app.transformer.off('click');

  app.canvasEditing = true;
  app.hasCanvasUpdatedForThumbnail = true

  app._lastTransformType = app.transformer.transformType;
  if (app.rightClick) {
    app.toggle3dContext(false);
    app.rightClick = false;
  }
  if (app.transformer.transformType === 'translate') {
    app.transformer.alpha = 0;
  }
  if (!app.transformChanged) {
    app.transformChanged = true;
  }

  app.disableHighlighting();
  app.transformChangeIteration++;

  app.selectedNodes.forEach((node:BaseNode) => {
    if (node.outline) {
      app.viewport.removeChild(node.outline);
      node.outline = undefined;
    }

    if (app.transformChangeIteration === 1) {
      node.prevNodeData = _.cloneDeep(node.nodeData);
    }

    if (!node.transformChangeStarted) {
      node.transformChangeStarted = true;
    }
    if (!node._transformerChanged) {
      node._transformerChanged = true;
    }

    switch (node.nodeData.nodeType) {
      case ENodeType.SHAPE: {
        if (app.shiftPressed) {
          const ratio = (node.nodeData.absoluteBounds?.width as number) / (node.nodeData.absoluteBounds?.height as number);
          node.displayObject!.width = (node.displayObject?.height as number) * ratio;
        }
        break;
      }
      case ENodeType.IMAGE: {
        // node._transformerChanged = true;
        node.updateAuthorRotation();
        break;
      }
      case ENodeType.FILE_PLACEHOLDER: {
        // node._transformerChanged = true;
        break;
      }
      case ENodeType.TEXT: {
        const { position, scale } = node.displayObject!.children[0]!.transform;
        const { width, height } = node.nodeData.absoluteBounds!;
        const { x: newX, y: newY } = position;
        const newWidth = width! * scale.x;
        const newHeight = height! * scale.y;
        const textDisplayobject = node.displayObject!.children[1]! as TaggedText;
        const { left, right } = BaseNode.textNodeDefaults.padding;
        if (newX !== 0 || newY !== 0) {
          node.displayObject!.x += newX;
          node.displayObject!.y += newY;
          node.displayObject!.children[0]!.setTransform(0, 0, scale.x, scale.y);
        }
        if (newWidth !== width || newHeight !== height) {
          textDisplayobject.defaultStyle.wordWrapWidth = newWidth - right - left;
          textDisplayobject.update();
        }
        break;
      }
      case ENodeType.STICKY_NOTE: {
        const { position, scale, rotation } = node.displayObject!.transform;
        if (node.transformChangeStarted) {
          if (!node.nodeData.absoluteBounds) {
            node.nodeData.absoluteBounds = {};
          }

          const { absoluteBounds, scale: oldScale, rotation: oldRotation } = node.nodeData;
          const positionChanged = absoluteBounds.x !== position.x || absoluteBounds.y !== position.y;
          const scaleChanged = oldScale?.scaleX !== scale._x;
          const rotationChanged = oldRotation !== rotation;
          if (positionChanged || scaleChanged || rotationChanged) {
            node.nodeData.absoluteBounds.x = position.x;
            node.nodeData.absoluteBounds.y = position.y;

            node.nodeData.scale = { scaleX: scale._x, scaleY: scale._y };
            node.nodeData.rotation = rotation;
          }
        }
        break;
      }
      case ENodeType.MODEL: {
        app.transformChanged = true;
        const { position, scale, rotation } = node.displayObject!.transform;
        if (node.transformChangeStarted) {
          if (!node.nodeData.absoluteBounds) {
            node.nodeData.absoluteBounds = {};
          }

          const { absoluteBounds, scale: oldScale, rotation: oldRotation } = node.nodeData;
          const positionChanged = absoluteBounds.x !== position.x || absoluteBounds.y !== position.y;
          const scaleChanged = oldScale?.scaleX !== scale._x;
          const rotationChanged = oldRotation !== rotation;
          if (positionChanged || scaleChanged || rotationChanged) {
            node.nodeData.absoluteBounds.x = position.x;
            node.nodeData.absoluteBounds.y = position.y;

            node.nodeData.scale = { scaleX: scale._x, scaleY: scale._y };
            node.nodeData.rotation = rotation;
          }
        }
        break;
      }
      default: {
        break;
      }
    }
  });
};

/**
 * Function for listening transformer's transform commit events
 * loops over all the selected nodes
 * updates the node data of the nodes based upon the transform
 * saving the node changes to db
 * @param e
 * @param app
 */
export const onTransformCommit = async (app:App) => {
  // adding back the click listener which was removed in onTransformChange
  if (app.transformer.listeners('click').length === 0) {
    app.transformer.on('click', (e:PIXI.InteractionEvent) => {
      onTransformerClick(e, app);
    });
  }

  app.canvasEditing = true;
  app.hasCanvasUpdatedForThumbnail = true
  app.transformer.alpha = 1;
  const excludeSaveIds:string[] = [];

  // boolean to determine when to readd the transformer
  // because we don't need to readd the transformer in all the cases
  let reAddTransformer = false;

  // if the mode node is in move mode then edit menu is not required
  let shouldToggleEditMenu = true;

  // Adding a promises array to hold async draw methods
  // currently used only for file placeholder
  const nodePromises = [];

  for (let i = 0; i < app.selectedNodes.length; i++) {
    const node = app.selectedNodes[i] as BaseNode;
    const { position, scale, rotation } = node.displayObject!.transform;
    const horizontallyFlipped = node.displayObject!.transform.worldTransform.a < 0;
    const verticallyFlipped = node.displayObject!.transform.worldTransform.d < 0;
    switch (node.nodeData.nodeType) {
      case ENodeType.SHAPE:
        if (node.transformChangeStarted) {
          if (!node.nodeData.absoluteBounds) {
            node.nodeData.absoluteBounds = {};
          }

          node.nodeData.absoluteBounds.x = position.x;
          node.nodeData.absoluteBounds.y = position.y;

          node.nodeData.scale = {
            scaleX: horizontallyFlipped ? -Math.abs(scale._x) : scale._x,
            scaleY: verticallyFlipped ? -Math.abs(scale._y) : scale._y,
          };
          node.nodeData.rotation = rotation;

          const { scale: oldScale } = node.prevNodeData as INode;
          const scaleChanged = oldScale?.scaleX !== scale._x;

          if (scaleChanged) {
            node.draw();
            reAddTransformer = true;
          }
        } else {
          excludeSaveIds.push(node.nodeData._id as string);
        }
        break;
      case ENodeType.IMAGE:

        if (!node.nodeData.absoluteBounds) {
          node.nodeData.absoluteBounds = {};
        }

        // If not in crop mode, the transformations apply to wrapper
        if (!node._cropMode) {
          node.nodeData.absoluteBounds.x = position.x;
          node.nodeData.absoluteBounds.y = position.y;

          node.nodeData.scale = { scaleX: scale._x, scaleY: scale._y };
          node.nodeData.rotation = rotation;
          const iWidth = node.nodeData.absoluteBounds.width;
          const iHeight = node.nodeData.absoluteBounds.height;
          const mScaleX = node.nodeData.image?.mask?.width || 1;
          const mScaleY = node.nodeData.image?.mask?.height || 1;
          const mX = node.nodeData.image?.mask?.x || 0;
          const mY = node.nodeData.image?.mask?.y || 0;
          if (node.isAuthorImage) {
            node.author!.circularMask.clear();
            positionAuthorImage(
              node.author!.image,
              node.author!.circularMask,
              {
                width: iWidth! * mScaleX!,
                height: iHeight! * mScaleY!,
                x: mX!,
                y: mY!,
                scaleX: scale._x!,
                scaleY: scale._y!,
                rotation: -rotation,
              },
              0,
            );
          } else if (node.pixiAuthor) {
            node.pixiAuthor.graphics.clear();
            node.pixiAuthor.graphics.removeChildren();
            const createdBy = node.nodeData.createdBy as any;
            node.pixiAuthor.text = new PIXI.Text(createdBy.userName!.toUpperCase()[0]);
            positionAuthorFill(
              node.pixiAuthor.graphics,
              node.pixiAuthor.text,
              {
                width: iWidth! * mScaleX!,
                height: iHeight! * mScaleY!,
                x: mX!,
                y: mY!,
                scaleX: scale._y!,
                scaleY: scale._y!,
                rotation: -rotation,
              },
              0.1,
            );
          }
        } else {
          excludeSaveIds.push(node.nodeData._id as string);
          node.saveCropMode();
        }
        if (!node._transformerChanged) {
          excludeSaveIds.push(node.nodeData._id as string);
        }
        break;
      case ENodeType.TEXT: {
        if (node.transformChangeStarted) {
          const { absoluteBounds } = node.nodeData;
          if (absoluteBounds) {
            absoluteBounds.x = position.x;
            absoluteBounds.y = position.y;
            const taggedTextContainer = (node.displayObject!.children[1]! as TaggedText).textContainer;
            const displayObjectContainer = node.displayObject as PIXI.Container;
            const {
              left, right, top, bottom,
            } = BaseNode.textNodeDefaults.padding;
            absoluteBounds.width = Math.max(taggedTextContainer.width, displayObjectContainer.width) + left + right + 10;
            absoluteBounds.height = Math.max(taggedTextContainer.height, displayObjectContainer.height) + top + bottom;
            node.nodeData.scale = { scaleX: 1, scaleY: 1 };
          }
        } else {
          excludeSaveIds.push(node.nodeData._id as string);
        }
        break;
      }
      case ENodeType.FILE_PLACEHOLDER: {
        const { scale: scale1 } = node.withoutTextContainer.transform;
        const finalScale = { _x: scale._x * scale1._x, _y: scale._y * scale1._y };
        if (node._transformerChanged) {
          if (!node.nodeData.absoluteBounds) {
            node.nodeData.absoluteBounds = {};
          }
          const { absoluteBounds, scale: oldScale, rotation: oldRotation } = node.nodeData;
          if (
            absoluteBounds.x !== position.x
            || absoluteBounds.y !== position.y
            || oldScale?.scaleX !== scale._x
            || oldScale?.scaleY !== scale._y
            || oldRotation !== rotation
          ) {
            node.nodeData.absoluteBounds.x = position.x;
            node.nodeData.absoluteBounds.y = position.y;

            node.nodeData.scale = {
              scaleX: horizontallyFlipped ? -Math.abs(finalScale._x) : finalScale._x,
              scaleY: verticallyFlipped ? -Math.abs(finalScale._y) : finalScale._y,
            };

            // eslint-disable-next-line no-await-in-loop
            nodePromises.push(node.draw());
            reAddTransformer = true;
          }
        } else {
          excludeSaveIds.push(node.nodeData._id as string);
        }
        break;
      }
      case ENodeType.MODEL: {
        if (app.transformChanged) {
          node.updateAuthorRotation();
        }
        if (node.transformChangeStarted) {
          const newBounds = node.displayObject!.getBounds();
          const canvasLength = Math.max(newBounds.width, newBounds.height);
          const newRendererIndex = Model.getRendererIndex(canvasLength);
          if (newRendererIndex !== node.modelRendererIndex) {
            node.modelRendererIndex = newRendererIndex;
            const modelCanvas = document.createElement('canvas');
            const canvasContext = modelCanvas.getContext('2d');
            const currentSize = new THREE.Vector2();
            app.modelrenderer[node.modelRendererIndex]!.getSize(currentSize);
            canvasContext!.canvas.width = currentSize.x;
            canvasContext!.canvas.height = currentSize.y;
            canvasContext!.globalCompositeOperation = 'copy';
            node.canvasContext = canvasContext;
            node.threeTexture = PIXI.BaseTexture.from(modelCanvas, {
              scaleMode: PIXI.SCALE_MODES.LINEAR,
              resolution: window.devicePixelRatio,
              anisotropicLevel: 16,
            });
            if (node.editMode) {
              reAddTransformer = true;
            }
          }
          if ((node.moveMode || node.isFrameHover) && !node.editMode) {
            reAddTransformer = false;
            app.removeTransformer();
            shouldToggleEditMenu = false;
          }
          node.isMouseMove = false;
          node.isMouseDown = false;
        }
        if (!node.transformChangeStarted && node.isFrameMouseDown) {
          node.cubeContainer.emit('mouseup');
        }
        reAddTransformer = true;
        break;
      }
      default: {
        // Added a check for stickynote just to check if 
        // edit was started to prevent unnecessary api calls
        if(node.nodeData.nodeType==='STICKY_NOTE' && !node.transformChangeStarted){
          excludeSaveIds.push(node.nodeData._id as string);
        }

        if (!node.nodeData.absoluteBounds) {
          node.nodeData.absoluteBounds = {};
        }

        const { absoluteBounds, scale: oldScale, rotation: oldRotation } = node.nodeData;
        if (
          absoluteBounds.x !== position.x
          || absoluteBounds.y !== position.y
          || oldScale?.scaleX !== scale._x
          || oldScale.scaleY !== scale._y
          || oldRotation !== rotation
        ) {
          node.nodeData.absoluteBounds.x = position.x;
          node.nodeData.absoluteBounds.y = position.y;

          node.nodeData.scale = {
            scaleX: horizontallyFlipped
              ? -Math.abs(scale._x) : scale._x,
            scaleY: verticallyFlipped
              ? -Math.abs(scale._y) : scale._y,
          };
          node.nodeData.rotation = rotation;
        }
        else {
          excludeSaveIds.push(node.nodeData._id as string);
        }
        break;
      }
    }
    node.transformChangeStarted = false;
    node._transformerChanged = false;
  }

  await Promise.all(nodePromises);

  if (reAddTransformer) {
    app.removeTransformer();
    app.addSelectedNodesToTransformer();
  }
  
  app.saveAllNodes(excludeSaveIds);
  app.transformChanged = false;
  app.transformChangeIteration = 0;
  app.enableHighlighting();
  if (shouldToggleEditMenu) app.toggleEditMenu(true);
};
