import { EBlockType } from '@naya_studio/types';
import { unwrapResult } from '@reduxjs/toolkit';
import mongoose from 'mongoose';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';
import { editBlockById } from 'src/redux/features/blocks';
import { getFCTask, publishFCTask } from 'src/redux/reduxActions/fileConverter';

// Holds the count of total tasks in progress along with id
let stepConversionsInProgress: { [key: string]: string[] | undefined } = {};

const completedTaskIds: string[] = [];

// holds the string identifier which is responsible for the snackbar
let currentSnackBar: 'PROCESSING' | 'FAILED' | 'COMPLETED';

let isTaskCanceled = false;

const TASK_ID = new mongoose.Types.ObjectId().toString();

const SESSION_KEY = 'STEP_CONVERSION';

/**
 * Function to remove the subtask id from progress ids array
 * @param taskId string
 * @param subTaskId string
 */
const removeSubTask = (taskId: string, subTaskId: string) => {
	stepConversionsInProgress[taskId] = stepConversionsInProgress[taskId]?.filter(
		(id) => id !== subTaskId
	);
};

/**
 * Function to handle task failure
 * @param taskId string
 */
const handleTaskFailure = (taskId: string) => {
	// If all the tasks were failed
	if (!stepConversionsInProgress[taskId]?.length) {
		addSnackbar({
			text: 'Failed to process step file(s)',
			show: true
		});
		currentSnackBar = 'FAILED';
		setTimeout(() => {
			if (['FAILED'].includes(currentSnackBar)) {
				removeSnackbar(0);
			}
		}, 3000);
		completedTaskIds.length = 0;
	}
};

/**
 * Hook to 3D model from stp to gltf format
 */
export const useStpToGltfConverter = () => {
	const dispatch = useDispatch<AppDispatch>();

	/**
	 * Function for polling get task api, till the task is completed
	 * @param taskId identifier
	 */
	const pollApi = (taskId: string) => {
		dispatch(getFCTask({ taskId }))
			.then(unwrapResult)
			.then((res) => {
				if (!res) return;
				const {
					data: { sub_tasks, status, error }
				} = res;

				if (sub_tasks && sub_tasks.length) {
					sub_tasks.forEach((task) => {
						const { converted_link, id: subTaskId, original_link } = task;
						if (task.status === 'COMPLETED' && !completedTaskIds.includes(task.id)) {
							completedTaskIds.push(task.id);
							removeSubTask(taskId, subTaskId);
							const payload = {
								blockType: EBlockType.THREE_D,
								link: [converted_link, original_link],
								originalLink: [original_link],
								_id: subTaskId
							};
							// Update the block in redux
							dispatch(editBlockById(payload));
						}
						if (task.status === 'FAILED' && !completedTaskIds.includes(task.id)) {
							removeSubTask(taskId, subTaskId);
						}
					});
				}

				// There could be a delay in receiving a response from the server. If a new task is initiated during that time,
				// totalStpConversionsInProgress[taskId] will not be equal to zero. In such a case, we should refrain from removing
				// the snackbar until the new task is completed.
				if (
					((['COMPLETED', 'FAILED'].includes(status) &&
						stepConversionsInProgress[taskId]?.length === 0) ||
						error) &&
					!isTaskCanceled
				) {
					window.sessionStorage.removeItem(SESSION_KEY);
					// remove the task id from polling tasks list
					// pollingTasks = pollingTasks.filter((tId) => tId !== taskId);
					if (!error) {
						addSnackbar({
							show: true,
							text:
								status === 'COMPLETED'
									? 'Finished processing step file(s)'
									: 'Failed to process step file',
							type: 'NORMAL'
						});
						currentSnackBar = status || 'FAILED';
						setTimeout(() => {
							// stepConversionsInProgress[taskId]?.length === 0 will prevent removing a snackbar in the
							// condition mentioned above
							if (
								stepConversionsInProgress[taskId]?.length === 0 &&
								['COMPLETED', 'FAILED'].includes(currentSnackBar)
							) {
								removeSnackbar(0);
								completedTaskIds.length = 0;
							}
						}, 3000);
						completedTaskIds.length = 0;
					}
				} else {
					setTimeout(() => {
						if (!isTaskCanceled) pollApi(taskId);
					}, 2000);
				}
			})
			.catch((err) => {
				console.error(err);
				window.sessionStorage.removeItem(SESSION_KEY);
				removeSnackbar(0);
			});
	};

	/**
	 * Converts the stp files to gltf
	 * @param link lint to a .stp file
	 * @param fileName file name of .stp file
	 * @param blockId task id which is used to identify the conversion task
	 */
	const convertStepToGltf = async (link: string, fileName: string, subTaskId: string) => {
		isTaskCanceled = false;
		try {
			if (!stepConversionsInProgress[TASK_ID]?.length) {
				currentSnackBar = 'PROCESSING';
				addSnackbar({
					show: true,
					text: `Processing step file(s) — this may take a while!`,
					type: 'LOADER',
					actionButtonData: [
						{
							buttonData: 'Hide',
							onClick: () => {
								removeSnackbar(0);
							},
							className: 'snackbar-cancel',
							show: true
						}
					]
				});
			}

			// Update the in progress
			if (!stepConversionsInProgress[TASK_ID])
				stepConversionsInProgress[TASK_ID] = [subTaskId];
			else stepConversionsInProgress[TASK_ID]?.push(subTaskId);

			// Store the task data in session
			window.sessionStorage.setItem(
				SESSION_KEY,
				JSON.stringify({ subTasks: stepConversionsInProgress[TASK_ID], taskId: TASK_ID })
			);

			unwrapResult(
				await dispatch(
					publishFCTask({
						fileName,
						link,
						subTaskId,
						taskId: TASK_ID,
						format: 'STEP'
					})
				)
			);

			pollApi(TASK_ID);
		} catch (error) {
			console.error(error);

			stepConversionsInProgress[TASK_ID] = stepConversionsInProgress[TASK_ID]?.filter(
				(id) => id !== subTaskId
			);
			handleTaskFailure(TASK_ID);
		}
	};

	// Runs on mount, finishes the prev pending task.
	useEffect(() => {
		const prevPendingTask = window.sessionStorage.getItem(SESSION_KEY);
		if (prevPendingTask) {
			const { subTasks, taskId } = JSON.parse(prevPendingTask);
			if (subTasks && taskId) {
				stepConversionsInProgress[taskId] = subTasks;
				pollApi(taskId);
			}
		}
	}, []);

	return { convertStepToGltf };
};

/**
 * Function to cancel on going step file conversion task if a block is deleted
 * @param data ids of the blocks
 * @param type 'BLOCK' | 'PHASE'
 */
export const cancelTaskOnBlockOrPhaseDelete = (
	data: string | string[],
	type: 'BLOCK' | 'PHASE' = 'BLOCK'
) => {
	/**
	 * Cancels the task and reset the data stored
	 */
	const cancelTheTask = () => {
		isTaskCanceled = true;
		removeSnackbar(0);
		window.sessionStorage.removeItem(SESSION_KEY);
		stepConversionsInProgress = {};
	};

	switch (type) {
		case 'BLOCK':
			if (typeof data !== 'string') break;
			if (stepConversionsInProgress[TASK_ID]?.includes(data)) {
				removeSubTask(TASK_ID, data);
			}
			if (!stepConversionsInProgress[TASK_ID]?.length) {
				cancelTheTask();
			}
			break;
		default: {
			if (Array.isArray(data))
				data.forEach((subTaskId) => {
					if (stepConversionsInProgress[TASK_ID]?.includes(subTaskId)) {
						removeSubTask(TASK_ID, subTaskId);
					}
					if (!stepConversionsInProgress[TASK_ID]?.length) {
						cancelTheTask();
					}
				});
			break;
		}
	}
};
