/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Form,
  FormControl,
  Button,
  Col,
  Row,
  Modal,
  InputGroup,
  OverlayTrigger, Tooltip,
} from 'react-bootstrap';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { Oval } from 'react-loader-spinner';
import { Layer, Feature } from 'react-mapbox-gl';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'mapbox-gl/dist/mapbox-gl.css';
import {
  useMemo, useState, FormEvent, useEffect,
} from 'react';
import Select, { OptionsType, components } from 'react-select';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement } from 'chart.js';
import { store } from 'src';
import { CustomDispatch } from 'src/redux/actions/types';
import { cancelAIResults, DisplayAIResults, toggleCommunitySnackBar } from 'src/redux/actions/communityAI';
import { useSelector } from 'react-redux';
import { ReactComponent as Brain } from 'src/assets/icons/toolbar/brain.svg';
import { ReduxState } from 'src/redux/reducers/root.types';
import trackEvent from 'src/util/analytics/analytics';
import { SuperPowerEvents } from 'src/util/analytics/events';
import { ReactComponent as Question } from 'src/assets/question.svg';
import Frame from '../../../../assets/Frame307.svg';
import './CommunityAI.scss';
import { materialsList } from '../../utils/EstimateAI.config';
import profileIcon from '../../../../assets/icons/ProfileIcon.svg';
import ImageSearch from '../../../../assets/icons/ImageSearch.png';
import NumberInput from '../../utils/NumberInput';
import { ReactComponent as Activeloc } from '../../../../assets/icons/active_location-dot.svg';
import inactive_location from '../../../../assets/icons/inactive_location-dot.svg';
import loaderImage from '../../../../assets/icons/Searching.svg';
import AlertIcon from '../../../../assets/Alert.svg';
import { Map } from '../../project/HomebaseProject';
import mapboxstyle from '../../utils/mapboxstyles.json';

function MyCenteredModal(props: any) {
  const { onHide, show } = props;
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        className="modal-header border-0"
        style={{ marginTop: '1rem' }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          <h5> Request Sent</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ marginBottom: '1.5rem' }}>
        <p style={{ fontWeight: '400', fontSize: '14px' }}>
          A request has been sent.
          A Naya representative will get back to you on whether or not your request has been accepted.
          You may continue to send connection request to other matches.
        </p>
      </Modal.Body>
      <Modal.Footer
        className="modal-footer border-0"
      >
        <Button
          style={{ backgroundColor: '#4f00c1', padding: '8px 16px' }}
          onClick={onHide}
        >
          <span style={{
            color: 'white', fontStyle: 'normal', fontSize: '12px', fontWeight: '700', display: 'flex',
          }}
          >
            Close
          </span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function DropdownIndicator(props : any) {
  return (
  // materials.length > 0 ? (
    <components.DropdownIndicator {...props} />
  // )
  // : (
  //   <components.DropdownIndicator {...props}>
  //     <img src={DownArrow} alt="" />
  //   </components.DropdownIndicator>
  // )
  );
}

const initialDimensions = {
  height: 0,
  width: 0,
  length: 0,
};

export default function CommunityAI() {
  const [quantity, setQuantity] = useState(0);
  const [budget, setBudget] = useState(0);
  const [dimension, setDimension] = useState(initialDimensions);
  const [price, setPrice] = useState(0);
  const [materials, setMaterials] = useState<OptionsType<SelectOptions>>([]);
  const [designerIndex, setDesignerIndex] = useState(0);
  const [selectDesigner, setSelectDesigner] = useState(false);
  const [radio, setRadio] = useState('Designer');
  const [showDesigner, setShowDesigner] = useState(false);
  const [address, setAddress] = useState('');
  const [coordsAI, setCoordsAI] = useState({
    lat: 0,
    long: 0,
  });
  const [formSubmit, setFormSubmit] = useState(false);
  const [materialFocus, setMaterialFocus] = useState(false);
  const [locationFocus, setLocationFocus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState(true);

  const theme = useSelector((state: ReduxState) => state.theme);
  const communityAIData = useSelector((state: ReduxState) => state.communityAI.communityAIData);

  const materialOptions = useMemo(() => materialsList.map((material: string) => ({
    value: material,
    label: material,
  })), []);

  const selectStyles = {
    control: (base: any) => ({
      ...base,
      fontSize: '12px',
      color: '#161616',
      lineHeight: '16px',
      fontWeight: '400px',
      borderColor: formSubmit && materials.length <= 0 && materialFocus === false ? '#E34300' : '#D1D1D1',
      boxShadow: 'none',
      '&:hover': {
        cursor: 'pointer',
      },
      '&:focused': {
        borderColor: 'var(--theme-color-with-opacity-2)',
        boxShadow: '0 0 0 0.1rem var(--theme-color-with-opacity-2)',
      },
    }),
    option: (styles: any) => ({
      ...styles,
      fontSize: '12px',
      color: '#161616',
      lineHeight: '16px',
      fontWeight: '400px',
      borderColor: formSubmit && materials.length <= 0 && materialFocus === false ? '#E34300' : '#D1D1D1',
      '&:hover': {
        backgroundColor: '#F4F4F4',
        cursor: 'pointer',
      },
      '&:active': {
        backgroundColor: '#F4F4F4',
        checked: 'true',
      },
      '&:focused': {
        borderColor: 'var(--theme-color-with-opacity-2)',
        boxShadow: '0 0 0 0.1rem var(--theme-color-with-opacity-2)',
        font: '700, bold',
      },
    }),
    placeholder: (base:any) => ({
      ...base,
      position: 'absolute',
      left: '10px',
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '16px',
      color: '#9F9F9F',
    }),
  };

  useEffect(() => {
    if (coordsAI.lat || coordsAI.long) window.localStorage.setItem('CoordsAI', JSON.stringify(coordsAI));
    if (address) window.localStorage.setItem('address', JSON.stringify(address));
    if (radio !== 'Designer') window.localStorage.setItem('Radio', JSON.stringify(radio));
    if (!checked) window.localStorage.setItem('Checked', JSON.stringify(checked));
    if (quantity > 0) window.localStorage.setItem('quantity', JSON.stringify(quantity));
    if (budget > 0) window.localStorage.setItem('budget', JSON.stringify(budget));
    if (dimension.length && dimension.height && dimension.width) {
      window.localStorage.setItem('dimensionAI', JSON.stringify(dimension));
    }
    if (materials.length > 0) window.localStorage.setItem('materials', JSON.stringify(materials));
    if (price > 0) window.localStorage.setItem('price', JSON.stringify(price));
  }, [coordsAI, address, quantity, materials, budget, dimension, price, radio, checked]);

  useEffect(() => {
    setRadio(JSON.parse(window.localStorage.getItem('Radio') || JSON.stringify(radio)));
    setChecked(JSON.parse(window.localStorage.getItem('Checked') || JSON.stringify(checked)));
    setCoordsAI(JSON.parse(window.localStorage.getItem('CoordsAI') || JSON.stringify(coordsAI)));
    setAddress(JSON.parse(window.localStorage.getItem('address') || JSON.stringify(address)));
    setDimension(JSON.parse(window.localStorage.getItem('dimensionAI') || JSON.stringify(dimension)));
    setMaterials(JSON.parse(window.localStorage.getItem('materials') || JSON.stringify(materials)));
    setQuantity(JSON.parse(window.localStorage.getItem('quantity') || JSON.stringify(quantity)));
    setPrice(JSON.parse(window.localStorage.getItem('price') || JSON.stringify(price)));
    setBudget(JSON.parse(window.localStorage.getItem('budget') || JSON.stringify(budget)));

    trackEvent(SuperPowerEvents.OPEN_MATCHMAKING);
  }, []);

  window.onunload = () => {
    // Clear the local storage
    window.localStorage.clear();
  };

  const validForm = useMemo(() => {
    if (
      radio
      && dimension.height > 0
      && dimension.width > 0
      && dimension.length > 0
      && materials.length > 0
      && quantity > 0
      && budget > 0
      && price > 0
      && coordsAI.lat !== 0
      && coordsAI.long !== 0
    ) return true;
    return false;
  }, [
    radio,
    dimension,
    materials,
    quantity,
    budget,
    price,
    coordsAI,
  ]);

  const submitForm = async (e: FormEvent | MouseEvent) => {
    e.preventDefault();
    setFormSubmit(true);
    if (validForm) {
      setIsLoading(true);
      try {
        const data = {
          project_materials: materials.map((m) => m.value),
          project_units: quantity,
          project_budget: budget,
          project_dimensions: {
            dimension,
          },
          project_location: { latitude: coordsAI.lat, longitude: coordsAI.long },
        };

        await (store.dispatch as CustomDispatch)(toggleCommunitySnackBar(true));
        await (store.dispatch as CustomDispatch)(DisplayAIResults(data, radio));
      } catch (err) {
        await (store.dispatch as CustomDispatch)(cancelAIResults());
      } finally {
        setIsLoading(false);
        trackEvent(SuperPowerEvents.RUN_MATCHMAKING);
      }
    }
  };

  const returnData = (value : number) => {
    Chart.register(ArcElement);
    const data = {
      labels: ['Rating'],
      datasets: [
        {
          label: '# of Votes',
          data: [value, 100 - value],

          backgroundColor: [
            `${theme.color}`,
            '#F5F5F5',
          ],
          borderWidth: 0,
        },
      ],
    };
    return data;
  };

  const handleName = (userName : string, lastName: string) => {
    let user : string = '';
    for (let i = 0; i < userName?.length; i++) {
      if (userName.charAt(i) !== ' ') {
        user += userName.charAt(i);
      } else break;
    }
    user = `${user} ${lastName.charAt(0) ? lastName.charAt(0) : ''}`;
    return user;
  };

  const handleLocationFocus = () => {
    const Input = document.getElementById('addressField') as HTMLInputElement;
    Input.focus();
  };

  const handlePriceUnit = (num : any) => {
    setPrice(num > 0 ? num : 0);
    if (quantity) setBudget((pre : any) => { console.log(pre); return num * quantity; });
    else if (budget) setQuantity((pre : any) => { console.log(pre); return Math.trunc((num * quantity) / num); });
  };

  const handleLocationChange = (newAddress: string) => {
    setAddress(newAddress);
  };

  const handleSelect = (selectAddress: string) => {
    setAddress(selectAddress);
    geocodeByAddress(selectAddress)
      .then(async (results: any) => {
        const coords = await getLatLng(results[0]);
        setCoordsAI({
          lat: (coords.lng * 1000) / 1000,
          long: (coords.lat * 1000) / 1000,
        });
      })
      .catch((e) => {
        console.log('Error', e);
      });
  };

  const handleUnits = (num : any) => {
    setQuantity(num > 0 ? num : 0);
    if (price) {
      setBudget((pre : any) => { console.log(pre); return num * price; });
    } else if (budget) setPrice((pre : any) => { console.log(pre); return Math.trunc((num * price) / num); });
  };

  const handleBudget = (num : any) => {
    setBudget(num > 0 ? num : 0);
    if (quantity) setPrice((pre : any) => { console.log(pre); return Math.trunc(num / quantity); });
    else if (price) setQuantity((pre : any) => { console.log(pre); return Math.trunc(num / Math.trunc(num / quantity)); });
  };

  return (
    <div className="communityEstimate">
      <Col lg={4} className="searchForm">
        <h4 className="searchHeader">Search criteria</h4>
        <Form onSubmit={submitForm}>
          <Form.Group>
            <Row>
              <Col xl={5} style={{ paddingRight: '0', marginBottom: '1.25rem' }}>
                <Form.Label>Search for</Form.Label>
              </Col>
              <Col xl={7}>
                <Form.Check
                  inline
                  type="radio"
                  label="Designer"
                  name="searchFor"
                  id="searchForDesigner"
                  aria-label="radio 1"
                  value="Designer"
                  onChange={(e) => {
                    if (e.target.checked === true) { setRadio('Designer'); setChecked(true); }
                  }}
                  checked={checked}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Fabricator"
                  name="searchFor"
                  id="searchForFabricator"
                  value="Fabricator"
                  aria-label="radio 2"
                  onChange={(e) => {
                    if (e.target.checked === true) { setRadio('Fabricator'); setChecked(false); }
                  }}
                  checked={!checked}
                />
              </Col>
            </Row>
          </Form.Group>

          <Form.Group>
            <Row>
              <Col xl={4} style={{ paddingRight: '0' }}>
                <Form.Label>Number of Units</Form.Label>
              </Col>
              <Col xl={8}>
                <NumberInput
                  name="number of units"
                  id="numberOfUnits"
                  onChange={(num) => handleUnits(num)}
                  value={quantity}
                  placeholder="1"
                  formSubmit={formSubmit}
                />
              </Col>
            </Row>
          </Form.Group>
          <Form.Group>
            <Row>
              <Col xl={4} style={{ paddingRight: '0' }}>
                <Form.Label>Price per unit</Form.Label>
              </Col>
              <Col xl={8}>
                <NumberInput
                  name="price per unit"
                  id="price"
                  icon
                  onChange={(num) => handlePriceUnit(num)}
                  value={price}
                  placeholder="$100.00"
                  numberSuffix=""
                  formSubmit={formSubmit}
                />
              </Col>
            </Row>

          </Form.Group>
          <Form.Group>
            <Row>
              <Col xl={4} style={{ paddingRight: '0' }}>
                <Form.Label>Total budget</Form.Label>
              </Col>
              <Col xl={8}>
                <NumberInput
                  name="budget"
                  id="budget"
                  icon
                  onChange={(num) => handleBudget(num)}
                  value={budget}
                  placeholder="$100.00"
                  numberSuffix=""
                  formSubmit={formSubmit}
                />
              </Col>
            </Row>
          </Form.Group>
          <Form.Group style={{ margin: '1.5rem 0' }}>
            <Row>
              <Col xl={4} style={{ paddingRight: '0' }}>
                <Form.Label>Materials</Form.Label>
              </Col>
              <Col xl={8}>
                <Select
                  isClearable={false}
                  styles={selectStyles}
                  closeMenuOnSelect={false}
                  options={materialOptions}
                  hideSelectedOptions={false}
                  isMulti
                  onFocus={() => setMaterialFocus(true)}
                  onBlur={() => setMaterialFocus(false)}
                  value={materials}
                  onChange={(opt) => setMaterials(opt)}
                  className="react-select"
                  classNamePrefix="react-select"
                  id="materials"
                  placeholder="Search or select from options"
                  components={{
                    DropdownIndicator,
                    IndicatorSeparator: () => null,
                  }}
                />
                { formSubmit && materials.length <= 0 && materialFocus === false && (
                <div>
                  <img src={AlertIcon} alt="" />
                  <span className="FieldWarnings">Enter materials to continue</span>
                </div>
                )}
              </Col>
            </Row>
          </Form.Group>
          <hr style={{ marginBottom: '0.5rem' }} />
          <Form.Group>
            <Form.Label>Dimensions</Form.Label>
          </Form.Group>
          <Form.Group>
            <Row>
              <Col xl={4} style={{ paddingRight: '0' }}>
                <Form.Label>Length</Form.Label>
              </Col>
              <Col xl={8}>
                <NumberInput
                  name="length"
                  id="AIlength"
                  onChange={(num) => setDimension({ height: dimension.height, width: dimension.width, length: (num > 0 ? num : 0) })}
                  value={dimension.length}
                  placeholder="3"
                  numberSuffix="inches"
                  formSubmit={formSubmit}
                />
              </Col>
            </Row>
          </Form.Group>
          <Form.Group>
            <Row>
              <Col xl={4} style={{ paddingRight: '0' }}>
                <Form.Label>Width</Form.Label>
              </Col>
              <Col xl={8}>
                <NumberInput
                  name="width"
                  id="AIwidth"
                  onChange={(num) => setDimension({ height: dimension.height, width: (num > 0 ? num : 0), length: dimension.length })}
                  value={dimension.width}
                  formSubmit={formSubmit}
                  placeholder="3"
                  numberSuffix="inches"
                />
              </Col>
            </Row>
          </Form.Group>
          <Form.Group>
            <Row>
              <Col xl={4} style={{ paddingRight: '0' }}>
                <Form.Label>Height</Form.Label>
              </Col>
              <Col xl={8}>
                <NumberInput
                  name="height"
                  id="AIheight"
                  onChange={(num) => setDimension({ height: (num > 0 ? num : 0), width: dimension.width, length: dimension.length })}
                  value={dimension.height}
                  placeholder="3"
                  formSubmit={formSubmit}
                  numberSuffix="inches"
                />
              </Col>
            </Row>
          </Form.Group>
          <Form.Group style={{ marginTop: '2.5rem' }}>
            <Row>
              <Col xl={4} style={{ paddingRight: '0' }}>
                <Form.Label>Location</Form.Label>
              </Col>
              <Col xl={8}>
                <PlacesAutocomplete
                  value={address}
                  onChange={handleLocationChange}
                  onSelect={handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <InputGroup
                        onFocus={() => setLocationFocus(true)}
                        onBlur={() => setLocationFocus(false)}
                        id="locationField"
                        style={{
                          border: !address && formSubmit && locationFocus === false
                            ? '1px solid #e34300' : '1px solid #d1d1d1',
                        }}
                      >
                        <InputGroup.Text
                          onClick={handleLocationFocus}
                          style={{ backgroundColor: 'transparent', borderRight: '0px', borderRadius: '4px 0 0 4px' }}
                        >
                          {coordsAI.lat && coordsAI.long ? <Activeloc className="active_loc" /> : <img src={inactive_location} alt="" />}
                        </InputGroup.Text>
                        <FormControl
                          style={{ borderLeft: '0px', paddingLeft: '0px' }}
                          className="textField"
                          id="addressField"
                          {...getInputProps({
                            placeholder: 'Search by Address',
                          })}
                        />
                      </InputGroup>
                      <div
                        className={
                          loading || suggestions.length > 0
                            ? 'community-autocomplete-dropdown-container'
                            : ''
                        }
                      >
                        {suggestions.map((suggestion: any) => {
                          const className = 'community-suggestion-item';
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                              })}
                              key={suggestion.placeId}
                            >
                              {suggestion.description ? (
                                <span className="suggestion" style={{ overflowX: 'scroll' }}>
                                  {suggestion.description}
                                </span>
                              ) : (
                                <span className="suggestion">
                                  No results to show
                                </span>
                              )}
                              <hr />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
                { formSubmit && !address && locationFocus === false && (
                <div style={{ marginBottom: '8px' }}>
                  <img src={AlertIcon} alt="" />
                  <span className="FieldWarnings">Enter location to continue</span>
                </div>
                )}
              </Col>
            </Row>
          </Form.Group>

          <Form.Group>
            <Row>
              <Col xl={4} />
              <Col xl={8}>
                <Map
                  center={coordsAI.lat !== 0 ? [coordsAI.lat, coordsAI.long] : [37.0902, 85.7129]}
                  style={mapboxstyle.mapboxglstyle}
                  containerStyle={{
                    height: '200px',
                    width: '100%',
                  }}
                  zoom={[13]}
                >
                  <Layer
                    type="symbol"
                    id="marker"
                    layout={{ 'icon-image': 'marker-15' }}
                  >
                    <Feature coordinates={[coordsAI.lat, coordsAI.long]} />
                  </Layer>
                </Map>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group style={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              className="searchDesigner"
              style={{
                background: 'var(--theme-color-1)',
                border: '#F5F5F5',
                borderRadius: '4px',
              }}
              onClick={submitForm}
            >
              <span className="searchBtn" style={{ color: 'white' }}>Search</span>
            </Button>
          </Form.Group>
        </Form>
      </Col>
      <Col
        lg={8}
        className="topDesignerMatches"
        style={{
          background: communityAIData?.length > 0 || isLoading ? 'none' : `no-repeat url(${Frame}) center`,
          padding: '0',
          maxHeight: communityAIData?.length > 0 ? 'fit-content' : '875px',
        }}
      >
        <h4 className="searchHeaderDesigner">Top Designer Matches</h4>
        <div style={{
          display: 'flex', justifyContent: 'space-around', alignItems: 'center', margin: '0 3rem',
        }}
        >
          <Col
            className="Features"
            style={{
              color: 'var(--theme-color-1)',
              justifyContent: communityAIData?.length > 0 ? 'center' : 'start',
              alignItems: 'flex-end ',
              lineHeight: '17px',
              letterSpacing: '0.085em',
              fontSize: '12px',
            }}
          >
            <Brain className="brainIcon" />
            {communityAIData?.length > 0 ? '' : 'RECOMMENDED'}
          </Col>
          <Col className="FeaturesName">Rating</Col>
          <Col className="FeaturesName">Capacity</Col>
          <Col className="FeaturesName">Budget</Col>
          <Col className="FeaturesName">Material</Col>
          <Col className="FeaturesName">Experience</Col>
          {communityAIData?.length > 0 && <Col style={{ marginRight: '1.5rem' }} />}
        </div>
        <hr />
        <div className="designerData">
          {communityAIData?.length > 0 && !isLoading
            ? communityAIData.slice(0, 10).map((des: any, index: number) => (
              <div
                role="presentation"
                style={{ display: 'flex', flexDirection: 'column' }}
                key={des.name}
                onClick={() => {
                  setDesignerIndex(index);
                  setSelectDesigner(true);
                }}
              >
                <Row
                  className="apiUi"
                  style={{
                    background: selectDesigner && designerIndex === index ? 'var(--theme-color-matches)' : '',
                    marginBottom: '0.5rem',
                  }}
                >
                  <Col className="Features">
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <img
                        src={des.profile_pic ? des.profile_pic : profileIcon}
                        alt=""
                        style={{ width: '48px', height: '48px', borderRadius: '50%' }}
                      />
                      <span className="desName">{handleName(des.name, des.last_name ? des.last_name : '')}</span>
                    </div>
                  </Col>
                  <Col className="Features">
                    <div>
                      <Doughnut
                        width={64}
                        height={64}
                        plugins={[
                          {
                            id: 'doughNut',
                            beforeDraw(chart : any) {
                              const { width } = chart;
                              const { height } = chart;
                              const { ctx } = chart;
                              ctx.restore();
                              ctx.font = '16px';
                              ctx.fontWeight = '500';
                              ctx.fillStyle = '#7E7E7E';
                              ctx.lineHeight = '23px';
                              const text = `${Math.trunc((des.feature_distances.designer_distance
                                ? des.feature_distances.designer_distance : des.feature_distances.maker_distance) * 100)}%`;
                              const textX = Math.round((width - ctx.measureText(text).width) / 2) + 2;
                              const textY = height / 2 + 3;
                              ctx.fillText(text, textX, textY);
                              ctx.save();
                            },
                          },
                        ]}
                        options={{ cutout: 20, responsive: true, maintainAspectRatio: true }}
                        data={returnData(Math.trunc((des.feature_distances.designer_distance
                          ? des.feature_distances.designer_distance : des.feature_distances.maker_distance) * 100))}
                      />
                    </div>
                  </Col>
                  <Col className="Features">
                    {Math.trunc((des.feature_distances.capacity_distance || 0) * 100) }
                    %
                  </Col>
                  <Col className="Features">
                    {Math.trunc(des.feature_distances.price_distance * 100)}
                    %
                  </Col>
                  <Col className="Features">
                    {Math.trunc(des.feature_distances.materials_distance * 100)}
                    %
                  </Col>

                  <Col className="Features">
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <span style={{
                        color: 'var(--theme-color-5)', fontSize: '24px', fontWeight: 500, lineHeight: '24px',
                      }}
                      >
                        {des.work_experience}
                      </span>
                      <span style={{
                        color: '#5D5D5D', fontSize: '10px', fontWeight: 400, lineHeight: '10px',
                      }}
                      >
                        Years
                      </span>
                    </div>
                  </Col>
                  <Col className="Features" style={{ paddingRight: '0px' }}>
                    {des.designer_portfolio?.length > 0
                      ? <img src={des.designer_portfolio[0]} alt="" style={{ width: '100px', height: '124px' }} />
                      : (des.maker_portfolio?.images?.length > 0 && des.maker_portfolio.images[0].split('.').pop() !== 'pdf')
                        ? <img src={des.maker_portfolio.images[0]} alt="" style={{ width: '100px', height: '124px' }} />
                        : (
                          <div style={{
                            width: '100px',
                            height: '124px',
                            backgroundColor: '#F5F5F5',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          >
                            <img src={ImageSearch} alt="" />
                          </div>
                        )}
                  </Col>
                </Row>
                {selectDesigner && designerIndex === index
                    && (
                    <div style={{
                      display: 'flex', justifyContent: 'end', alignItems: 'center', marginBottom: '1rem',
                    }}
                    >
                      <OverlayTrigger
                        placement="bottom"
                        overlay={(
                          <Tooltip
                            id="button-tooltip-designer"
                            style={{
                              fontSize: '12px',
                              borderRadius: '2px',
                              lineHeight: '16px',
                            }}
                          >
                            After your request a Naya representative will get back to you on whether or not your request has been accepted.
                            You may continue to send connection request to other matches as well.
                          </Tooltip>
                    )}
                      >
                        {({ ref, ...triggerHandler }) => (
                          <Question
                            className="question_mark"
                            {...triggerHandler}
                            ref={ref}
                            style={{ marginRight: '0.75rem', cursor: 'pointer' }}
                          />
                        )}
                      </OverlayTrigger>
                      <button
                        type="button"
                        className="requestDesigner"
                        onClick={(e : any) => {
                          e.preventDefault();
                          setShowDesigner(true);
                        }}
                      >
                        <span className="Modalbtn">Request designer</span>
                      </button>
                    </div>
                    )}
              </div>

            )) : isLoading && (
              <div style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '200px',
              }}
              >
                <img src={loaderImage} alt="" />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Oval color="#6B04FF" height={16} width={16} />
                  <span style={{ fontSize: '12px', color: '#161616', marginLeft: '6px' }}>Looking for top matches</span>
                </div>
              </div>
            )}
          <MyCenteredModal
            show={showDesigner}
            onHide={() => setShowDesigner(false)}
          />
        </div>
      </Col>
    </div>
  );
}

export const inputText = {
  color: '#161616',
};

type SelectOptions = {
  value: string;
  label: string;
};
