import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { configureStore } from '@reduxjs/toolkit';

/* WebVitals set up? */
// import reportWebVitals from './reportWebVitals';

/* REDUX */
import { Provider } from 'react-redux';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import rootReducer from './redux/reducers/root';
import AuthProvider from './components/auth/AuthProvider';
import ReduxManager from './redux/manager/ReduxManager';
import { notificationSlice } from './redux/features/api/notification';
import GTagTrackingHOC from './util/googleTag/googleTagHOC';
import './.well-known/microsoft-identity-association.json';
import App from './App';
import './index.scss';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { sentNotificationSlice } from './redux/features/api/sentNotification';
import { searchSlice } from './redux/features/api/search';

axios.interceptors.request.use(async (config) => {
	if (config.url?.includes('proxy.naya.studio')) {
		return config;
	}
	if (
		config.url?.includes('naya.studio') ||
		config.url?.includes('naya-app.com') ||
		config.url?.includes('naya.love')
	) {
		// Set withCredentials = true to send workos cookie with backend requests
		config.withCredentials = true;
	}
	return config;
});

if (process.env.REACT_APP_PRODUCTION || process.env.REACT_APP_PRODUCTION_AWS) {
	Sentry.init({
		dsn: 'https://d79aef69ada4aa0f8f2c309aa181c6c5@o4506670603698176.ingest.sentry.io/4506733114032128',
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.browserProfilingIntegration(),
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false
			})
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: [
			/^https:\/\/.*\.run\.app/,
			/^https:\/\/(?!analytics).*naya\.studio/,
			/^https:\/\/(?!analytics).*\.naya-app\.com/
		],
		profilesSampleRate: 1.0,
		// Session Replay
		// This sets the sample rate at 30%.
		// You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysSessionSampleRate: 0.3,
		// If you're not already sampling the entire session,
		// change the sample rate to 100% when sampling sessions where errors occur.
		replaysOnErrorSampleRate: 1.0
	});
}

export const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			immutableCheck: false,
			serializableCheck: false
		})
			.concat(notificationSlice.middleware)
			.concat(sentNotificationSlice.middleware)
			.concat(searchSlice.middleware)
});

export type AppDispatch = typeof store.dispatch;

// Note: Exporting redux manager instance from here so that it will get initialized only after redux store initialization
export const reduxManager = new ReduxManager();

const renderApp = async () => {
	const LD_CLIENT_SIDE_ID = process.env.REACT_APP_LD_CLIENT_SIDE_ID || '';
	const LDProvider = await asyncWithLDProvider({
		clientSideID: LD_CLIENT_SIDE_ID,
		context: {
			kind: 'default',
			key: 'default'
		},
		options: {
			bootstrap: 'localStorage'
		}
	});
	ReactDOM.render(
		<React.StrictMode>
			<Provider store={store}>
				<Router>
					<GTagTrackingHOC>
						<LDProvider>
							<AuthProvider>
								<App />
							</AuthProvider>
						</LDProvider>
					</GTagTrackingHOC>
				</Router>
			</Provider>
		</React.StrictMode>,
		document.getElementById('root')
	);
};
renderApp();
serviceWorkerRegistration.unregister();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
