import {
	IGroup,
	TUserAndRole,
	TInvitedEmail,
	IProject,
	IBlock,
	INodesBlock,
	IFeedbackBlock
} from '@naya_studio/types';
import {
	updateProject,
	removeIdFromRestrictedAccess,
	addIdToRestrictedAccess
} from 'src/redux/features/projects';
import { deleteStageById } from 'src/redux/features/stages';
import * as Y from 'yjs';
import { loadStage } from 'src/redux/reduxActions/stage';
import { TChangeType, getYDoc } from 'src/rtc/yjs/yjsConfig';
import { useDispatch } from 'react-redux';
import { store } from 'src';
import { addBlock, editBlockById, deleteBlockById } from 'src/redux/features/blocks';
import { loadBlockById } from 'src/redux/reduxActions/block';
import { useHistory } from 'react-router';
import { ISnackBar } from 'src/redux/reducers/root.types';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';
import { generateIdsFromUrl } from 'src/redux/reduxActions/util';
import { cancelTaskOnBlockOrPhaseDelete } from 'src/hooks/useFileConverter';
import getUserFromRedux from 'src/util/helper/user';
import { broadcastSelfDispatch, checkIfUserHasAccess, getProjectFromYDocOrRedux } from './utils';

/**
 * Hook to update block in redux after yjs listens to block change
 */
const useBlockObserver = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	/**
	 * If user had the block expanded which was deleted, notify him about it
	 * And redirect him to journey
	 */
	const notifyBlockLeave = (
		message: string,
		ids: {
			projectId: string;
			stageId: string;
			blockId: string;
		}
	) => {
		const snackbarData: ISnackBar = {
			text: message,
			type: 'NORMAL',
			show: true
		};

		addSnackbar(snackbarData);
		removeSnackbar(2500, () => {
			const { projectId, stageId, blockId } = ids;

			const activeWindow = window.location.pathname;
			const blockWindow = `/project/${projectId}/${stageId}/${blockId}`;

			if (activeWindow === blockWindow) history.push(`/project/${projectId}`);
			dispatch(deleteBlockById(blockId));
		});
	};

	/**
	 * Observer to listen to block changes in ymap
	 * @param update block change to do in redux
	 */
	const blockObserver = (update: Y.YMapEvent<string>) => {
		try {
			const user = getUserFromRedux();
			const doc = getYDoc();

			update.changes.keys.forEach((change: TChangeType<string>, key: string) => {
				if (change.action === 'update') {
					const block = JSON.parse(doc.getMap('blocks').get(key) as string) as IBlock;
					// console.info('RTC - Received block update', block);
					const oldBlock = JSON.parse(change.oldValue as string) as IBlock;
					const selfDispatch = block.lastUpdatedBy === (user._id as string);
					if (selfDispatch) {
						broadcastSelfDispatch(block, 'EDIT', 'BLOCK');
						return;
					}

					const { projectId } = block;
					const project = getProjectFromYDocOrRedux(projectId);
					const hasGuestAccess = block.hasGuestAccess || project?.hasGuestAccess || false;
					const hadGuestAccess =
						oldBlock.hasGuestAccess || project?.hasGuestAccess || false;
					const reduxState = store.getState();
					const parenGroup = reduxState.stages.data[block.parentId];

					// If user has access to the updated block
					const hasAccess =
						checkIfUserHasAccess(
							user,
							block.users as TUserAndRole[],
							block.invitedEmails as TInvitedEmail[]
						) || hasGuestAccess;
					// If user had access to the block before update
					const hadAccess =
						checkIfUserHasAccess(
							user,
							oldBlock.users as TUserAndRole[],
							oldBlock.invitedEmails as TInvitedEmail[]
						) || hadGuestAccess;

					if (hasAccess) {
						// If user has access now and didn't had it before
						// that means he is invited on the block so load the block and stage
						if (!hadAccess) {
							// Do this to have the correct order of stages
							const filteredStages = (project.children as string[])?.filter((s) =>
								[
									...(project.children as string[]),
									block.parentId as string
								].includes(s)
							);

							dispatch(
								updateProject({
									_id: projectId,
									children: filteredStages
								} as Partial<IProject>)
							);

							if (!parenGroup) {
								// Load the stage in redux and don't add the stage blocks in it
								// Otherwise blocks on which user is not invited might also get added in redux
								dispatch(
									loadStage({
										payload: { _id: block.parentId as string }
									})
								);
							}

							const blockHasChildren =
								(block.notes || []).length > 0 ||
								((block as INodesBlock)?.nodes || []).length > 0;
							const blockHasFeedback =
								((block as IFeedbackBlock)?.feedbacks || []).length > 0;

							// Add the block in redux
							if (blockHasChildren || blockHasFeedback)
								dispatch(loadBlockById({ id: key }));
							else dispatch(addBlock(block));

							// If user is invited on this block remove his id from restricted blocks
							dispatch(
								removeIdFromRestrictedAccess({
									_id: projectId,
									type: 'BLOCK',
									invitedTo: key
								})
							);
						} else dispatch(editBlockById(block));
					}
					// If user does not have access anymore, but he had access in previous change
					// that means he is removed from block
					else if (hadAccess) {
						const { blockId } = generateIdsFromUrl();

						// Remove the snackbar for all the ongoing step file processes
						cancelTaskOnBlockOrPhaseDelete(key, 'BLOCK');

						if (blockId && blockId === key) {
							// If the block from which user has been removed was expanded by user,
							// notify him about it and redirect to journey
							// Added setTimeout to prevent snackbar getting removed for step task
							setTimeout(
								() =>
									notifyBlockLeave(
										"You've been removed from this block. You'll be redirected to journey shortly.",
										{
											projectId,
											stageId: block.parentId,
											blockId
										}
									),
								0
							);
						} else dispatch(deleteBlockById(key));

						const ydocStage = doc
							.getMap('pathways')
							.get(block.parentId as string) as string;
						const reduxStage = reduxState.stages.data[
							block.parentId as string
						] as IGroup;
						const stage = (ydocStage ? JSON.parse(ydocStage) : reduxStage) as IGroup;
						if (reduxStage) {
							const isOrphanBlock =
								stage.children?.length === 1 && !stage.isPhaseCreated;

							// If orphan block delete the stage
							if (isOrphanBlock) dispatch(deleteStageById(block.parentId as string));
						}

						// If user is removed from this block, add his id in restricted blocks
						dispatch(
							addIdToRestrictedAccess({
								_id: projectId,
								type: 'BLOCK',
								removedFrom: key
							})
						);
					}
				} else if (change.action === 'add') {
					const block = JSON.parse(doc.getMap('blocks').get(key) as string) as IBlock;
					// console.info('RTC - Received block add', block);
					const selfDispatch = block.lastUpdatedBy === (user._id as string);
					const originatedFromNaya = block.origin === 'NAYA';
					if (selfDispatch && originatedFromNaya) {
						broadcastSelfDispatch(block, 'ADD', 'BLOCK');
						return;
					}

					const project = getProjectFromYDocOrRedux(block.projectId);
					const hasGuestAccess = block.hasGuestAccess || project?.hasGuestAccess || false;

					const hasAccess =
						checkIfUserHasAccess(
							user,
							block.users as TUserAndRole[],
							block.invitedEmails as TInvitedEmail[]
						) || hasGuestAccess;

					// Only load the block if user has access to it or it is originated from somewhere else
					if (hasAccess) dispatch(addBlock(block));
				} else if (change.action === 'delete') {
					const { projectId, blockId } = generateIdsFromUrl();

					const block = JSON.parse(change.oldValue || '') as IBlock;
					// console.info('RTC - Received block delete', block);
					const selfDispatch = block.lastUpdatedBy === (user._id as string);
					if (selfDispatch) {
						broadcastSelfDispatch(key, 'DELETE', 'BLOCK');
						return;
					}

					const project = getProjectFromYDocOrRedux(block.projectId);
					const hasGuestAccess = block.hasGuestAccess || project?.hasGuestAccess || false;

					const hasAccess =
						checkIfUserHasAccess(
							user,
							block.users as TUserAndRole[],
							block.invitedEmails as TInvitedEmail[]
						) || hasGuestAccess;

					if (hasAccess) {
						// Remove the snackbar for all the ongoing step file processes
						cancelTaskOnBlockOrPhaseDelete(key, 'BLOCK');

						if (blockId && blockId === key) {
							// If the deleted block was expanded by user, notify him about it and redirect to journey
							// Added setTimeout to prevent snackbar getting removed for step task
							setTimeout(
								() =>
									notifyBlockLeave(
										"This block has been deleted. You'll be redirected to Journey shortly.",
										{
											projectId,
											stageId: block.parentId,
											blockId
										}
									),
								0
							);
						} else dispatch(deleteBlockById(key));
					}
				}
			});
		} catch (error) {
			console.error('Error in Block Listener : ', error);
		}
	};

	return blockObserver;
};

export default useBlockObserver;
