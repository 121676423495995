import { FC, ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import './JourneyNavbar.scss';
import { IProjectDetail, EUserType, TUserAndRole, IProject } from '@naya_studio/types';
import { IconWithTooltip, MyTasks, SessionStorageKeys } from '@naya_studio/radix-ui';
import { AppDispatch, store } from 'src';
import { AuthContext } from 'src/components/auth/AuthProvider';
import { generateIdsFromUrl } from 'src/redux/reduxActions/util';
import useProject from 'src/redux/hooks/useProject';
import { TMyTaskItem, useNotifications } from 'src/util/notifications/useNotifications';
import UserProfileButton from 'src/components/utilities/navbar/userProfile/UserProfileButton';
import CTA from 'src/components/utilities/navbar/cta/CTA';
import { checkAccess, checkUserAccessLevel } from 'src/util/accessLevel/accessLevelActions';
import { PathParamsType } from 'src/types/pathParams';
import { ESharingTabs } from 'src/components/sharingModal/SharingModal.types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useWebSocketConnection from 'src/rtc/yjs/useWebSocketConnection';
import { checkIfUserOnJourney, isBlockUrl } from 'src/util/collaboration/util';
import useSocketUsers from 'src/redux/hooks/useSocketUsers';
import useUser from 'src/redux/hooks/user';
import DeleteModal from 'src/components/deleteModal/DeleteModal';
import { useDispatch } from 'react-redux';
import { TDeleteProjectThunkArg } from 'src/types/argTypes';
import { deleteProject } from 'src/redux/reduxActions/project';
import { ISnackBar } from 'src/redux/reducers/root.types';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';
import { setShowSyncStorageModal } from 'src/redux/features/integration';
import { getProjectSyncObserver } from 'src/util/helper/project';
import SyncStorageModal from 'src/components/syncStorages/SyncStorageModal';
import { isProjectOwner } from 'src/redux/actions/util';
import {
	deleteGroup,
	deleteProjectFromGroup,
	revertDeletedProjectFromGroup
} from 'src/redux/features/groupDetail';
import {
	deleteProjectForUser,
	removeGroup,
	revertDeletedProjectForUser
} from 'src/redux/features/user';
import SearchBar from './searchBar/SearchBar';
import { CollaborationToolContext } from '../CollaborationTool';
import NayaLogo from './NayaLogo/NayaLogo';
import JourneyName from './JourneyName/JourneyName';
import Exports from './Exports/Exports';
import SocketUsers from './SocketUsers/SocketUsers';
import JourneyNameBreadCrumb from './JourneyName/JourneyNameBreadCrumb';
import AISettings from './AISettings/AISettings';

/**
 * Navbar Prop Types
 */
export type NavBarProps = {
	// Available block types to show as filter options
	setActiveFeedbackId: (id: string | undefined) => void;
	setSharingDetails: React.Dispatch<
		React.SetStateAction<{
			projectId: string;
			phaseId: string | undefined;
			blockId: string | undefined;
		}>
	>;
	setActiveTab: React.Dispatch<React.SetStateAction<keyof typeof ESharingTabs>>;
	onAIOrganize: (blockIds: string[], groupIds: string[]) => void;
};

/**
 * Component to render navbar in Journey
 * @returns navbar component
 */
const NavBar: FC<NavBarProps> = ({
	setActiveFeedbackId,
	setSharingDetails,
	setActiveTab,
	onAIOrganize
}): ReactElement => {
	const location = useLocation();
	const dispatch = useDispatch<AppDispatch>();
	const history = useHistory();
	// Extract the socket users from useSocketUsers hook
	const socketUsers = useSocketUsers();
	const params = useParams<PathParamsType>();
	const wsConnected = useWebSocketConnection();

	const collaborationContext = CollaborationToolContext;
	const { setShowShareModal, onCollabUnMount, searchFilters } = useContext(collaborationContext);

	const { completedAuthCheck, status, hasGuestAccessTo } = useContext(AuthContext);

	const { projectId, blockId } = generateIdsFromUrl();
	const { project } = useProject(projectId);

	// Checks if user is logged in
	const { user: currentUser } = useUser();
	const userLoggedIn =
		currentUser._id && (currentUser.email || currentUser.userType?.includes(EUserType.GUEST));

	const {
		journeyToDeliverables,
		myTaskEnabled,
		createInNaya,
		isSyncStorageEnabled,
		isSyncDriveEnabled,
		isTextToTextAiEnabled,
		isAiSettingsManagerEnabled,
		isCollaboratorsListEnabled,
		isJourneySearchEnabled
	} = useFlags();

	// State to check if user is on project page
	const [inProject, setInProject] = useState<boolean>(false);
	// State to check if user is on journey page
	const [onJourney, setOnJourney] = useState<boolean>(false);
	// State to store journey name
	const [journeyName, setJourneyName] = useState<string>('');
	// State to store block name
	const [blockName, setBlockName] = useState<string>('');
	// State to toggle delete modal
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	// State to update socket connection status
	const [connectedToSocket, setConnectedToSocket] = useState<boolean>(false);
	// State to toggle AI settings modal
	const [showAISettingsModal, setShowAISettingsModal] = useState<boolean>(false);

	const { tasks, isTaskActive, handleTask } = useNotifications(true);

	// Re-runs when project is renamed i.e. proejct details are changed
	useEffect(() => {
		setJourneyName(((project?.projectDetails as IProjectDetail)?.name as string) || 'Untitled');
	}, [project?.projectDetails]);

	useEffect(() => {
		if (wsConnected && !connectedToSocket) setConnectedToSocket(true);
		else if (!wsConnected && connectedToSocket) setConnectedToSocket(false);

		return () => setConnectedToSocket(false);
	}, [wsConnected]);

	/** True, if user has access to block | stage | project */
	const hasAccessToProject = useMemo(
		() => project?._id && checkAccess(params.projectId, 'PROJECT', true),
		[project]
	);

	/**
	 * True, if user has owner or editor access to project
	 */
	const isEditor = checkUserAccessLevel(
		project?.users as TUserAndRole[],
		currentUser._id as string,
		['OWNER', 'EDITOR']
	);

	/** True, if user has connected to socket and has socket users */
	const showSocketUsers = connectedToSocket && hasAccessToProject && socketUsers.length > 0;

	// Re-runs if user is in any of the /project domains and online
	useEffect(() => {
		const userOnJourney = checkIfUserOnJourney();
		const split = location.pathname.split('/');
		if (split.length >= 2 && split[1] === 'project' && (userLoggedIn || hasGuestAccessTo)) {
			setInProject(true);
			setOnJourney(userOnJourney);
		} else {
			setInProject(false);
			setOnJourney(false);
		}
	}, [location, userLoggedIn, project, hasGuestAccessTo]);

	/**
	 * Function to toggle delete modal
	 */
	const toggleDeleteModal = () => {
		setShowDeleteModal(!showDeleteModal);
	};

	/**
	 * Function to render Journey Name in Navbar
	 * @returns journey name with editable title
	 */
	const renderJourneyName = () => {
		// if blockId(expanded View) - show breadCrumb like format
		if (blockId) {
			return (
				<div
					className={`journey-title-wrapper tw-flex tw-justify-center 
			tw-items-center tw-h-8 ${showSocketUsers ? 'has-socket' : ''}`}
				>
					<JourneyNameBreadCrumb
						blockName={blockName}
						setBlockName={setBlockName}
						setSharingDetails={setSharingDetails}
						setActiveTab={setActiveTab}
					/>
				</div>
			);
		}
		// show project name
		return (
			<div
				className={`journey-title-wrapper tw-flex tw-justify-center 
			tw-items-center tw-h-8 ${showSocketUsers ? 'has-socket' : ''}`}
			>
				<JourneyName
					journeyName={journeyName}
					setJourneyName={setJourneyName}
					setShowDeleteModal={setShowDeleteModal}
					onAIOrganize={onAIOrganize}
					setActiveTab={setActiveTab}
					setSharingDetails={setSharingDetails}
				/>
			</div>
		);
	};

	/**
	 * Function to render Sync storage button in Navbar
	 */
	const renderSyncDrive = () => {
		// get Google Drive project observer
		const driveObserver = getProjectSyncObserver(projectId, 'GOOGLE_DRIVE');
		// check if project is connected to stage or not
		const isConnected = driveObserver?.channelDetails?.channelId;
		// check if project folder sync is in progress or not
		const isSyncInProgress = driveObserver?.isSyncInProgress;

		// select icon and text to be rendered based on sync status
		let iconUrl = '';
		let iconText = '';
		if (isConnected && isSyncInProgress) {
			iconUrl =
				'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/drive-sync.svg' +
				'?alt=media&token=a32795cd-6756-4c33-b654-391d73b8291f';
			iconText = 'Storage syncing';
		} else if (isConnected) {
			iconUrl =
				'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/cloud-checked.svg' +
				'?alt=media&token=55e44fc4-8ff3-4dde-aa56-7d36229e2eb5';
			iconText = 'Storage sync status';
		} else {
			iconUrl =
				'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/cloud.svg' +
				'?alt=media&token=67d662d1-a7c9-4b6c-b269-d24734a1ec64';
			iconText = 'Sync storage';
		}

		return (
			<IconWithTooltip tooltipText={iconText} side="bottom">
				<button
					type="button"
					className={
						'sync-menu tw-flex tw-justify-center tw-items-center ' +
						'tw-h-10 tw-text-[#4F00C1] tw-rounded-2xl tw-cursor-pointer'
					}
					onClick={(e) => {
						e.preventDefault();
						dispatch(setShowSyncStorageModal(true));
					}}
				>
					<img src={iconUrl} alt="sync" />
				</button>
			</IconWithTooltip>
		);
	};

	/**
	 * Function to render Share button in Navbar
	 * @returns Share button in navbar
	 */
	const renderShareButton = () => (
		<IconWithTooltip
			tooltipText={`Shared with ${project?.users?.length || 0} people`}
			side="bottom"
			delayDuration={200}
			classNames={`naya-logo-tooltip tw-relative tw-left-4 ${
				(project?.users?.length || 0) > 1 ? '' : 'tw-hidden'
			}`}
		>
			<button
				type="button"
				onClick={() =>
					onCollabUnMount(() => {
						setActiveTab(
							isBlockUrl(window.location.href)
								? ESharingTabs.BLOCK
								: ESharingTabs.PROJECT
						);
						setSharingDetails({
							projectId,
							phaseId: undefined,
							blockId: undefined
						});

						setShowShareModal((prev) => !prev);
					})
				}
				className="share-btn tw-flex tw-justify-center tw-items-center 
				tw-padding-4 tw-rounded-2xl tw-font-randMedium tw-text-sm"
			>
				Share
			</button>
		</IconWithTooltip>
	);

	/**
	 * Function to render Profile icon in navbar
	 * @returns profile icon
	 */
	const renderProfileIcon = () =>
		userLoggedIn ? (
			<UserProfileButton />
		) : (
			(completedAuthCheck || status === 'SIGNED_OUT') && <CTA />
		);

	/**
	 * Function to render My Tasks in navbar
	 * @returns my tasks button
	 */
	const renderMyTasks = () => (
		<div className="my-tasks-nav tw-flex tw-justify-center tw-items-center tw-gap-1">
			<MyTasks
				tasksTitle="Tasks"
				tasks={(tasks as TMyTaskItem[]) || []}
				handleTask={(type: string, task: TMyTaskItem | {}) => {
					handleTask(type, task);
					// Set the active feedback when a task is clicked
					if ((task as TMyTaskItem).feedbackId && setActiveFeedbackId) {
						setActiveFeedbackId((task as TMyTaskItem).feedbackId);
					}
				}}
				isTaskActive={isTaskActive}
				hasProjects
				currentProjectId={projectId}
			/>
		</div>
	);

	/**
	 * Function to render AI settings in navbar
	 * @returns
	 */
	const renderAISettings = () => {
		const contentTrainingStatus = project?.aiIngestStatus;
		const isAIFeatureEnabled = project?.isAIFeatureEnabled;

		let iconUrl = `https://firebasestorage.googleapis.com/v0/b/naya-assets/o
		/Settings.svg?alt=media&token=379b204d-d830-4739-8628-2bb4fbdb4f94`;
		if (contentTrainingStatus === 'INACTIVE' && !isAIFeatureEnabled) {
			iconUrl = `https://firebasestorage.googleapis.com/v0/b/naya-assets/o
		/AI%20Settings.svg?alt=media&token=6873cd63-d00f-4cd1-94cc-4560a3e93ffb`;
		}

		return (
			<IconWithTooltip tooltipText="AI settings" side="bottom">
				<button
					type="button"
					className={
						'ai-settings tw-flex tw-justify-center tw-items-center ' +
						'tw-h-10 tw-text-[#4F00C1] tw-rounded-2xl tw-cursor-pointer'
					}
					onClick={(e) => {
						e.preventDefault();
						setShowAISettingsModal(true);
					}}
				>
					<img src={iconUrl} alt="sync" />
				</button>
			</IconWithTooltip>
		);
	};

	/**
	 * Function to handle project delete from Navbar title dropdown
	 */
	const handleOnProjectDelete = useCallback(() => {
		const reduxState = store.getState();
		const { projectGroup } = reduxState;

		const activeGroupFromRedux = projectGroup.data;
		const isGroupOpened = Boolean(activeGroupFromRedux._id);
		// Check if the parent group of active project has this last project inside it
		const isLastProjectInGroup =
			isGroupOpened &&
			(activeGroupFromRedux.projects as IProject[])?.filter((journey) => {
				const { statuses } = journey;

				if (!statuses) return false;

				const latestStatus = statuses[statuses.length - 1];

				if (latestStatus?.status && ['ARCHIVED', 'DELETED'].includes(latestStatus.status))
					return false;

				// only return those which aren't ARCHIVED or DELETED
				return true;
			}).length === 1;

		const payload: ISnackBar = {
			text: `"${project?.projectDetails?.name || 'Untitled'}" deleted`,
			show: true,
			type: 'NORMAL'
		};

		addSnackbar(payload);

		const deleteDispatch = isGroupOpened ? deleteProjectFromGroup : deleteProjectForUser;
		const revertDispatch = isGroupOpened
			? revertDeletedProjectFromGroup
			: revertDeletedProjectForUser;

		// Dispatch appropriate call to remove project from proper group
		dispatch(
			deleteDispatch({
				projectId
			})
		);

		if (isGroupOpened) {
			// Dispatch to update project in active user as well to update group thumbnail and remove project from it
			dispatch(
				deleteProjectForUser({
					projectId,
					groupId: activeGroupFromRedux._id as string
				})
			);
		}

		const apiPayload: TDeleteProjectThunkArg = {
			payload: {
				_id: projectId
			}
		};

		// If active project is last project in group, then remove the group from user and redux
		if (isLastProjectInGroup) {
			dispatch(deleteGroup());
			dispatch(
				removeGroup({
					groupId: activeGroupFromRedux._id as string
				})
			);
			window.sessionStorage.removeItem(SessionStorageKeys.ACTIVE_GROUP);
		}

		dispatch(deleteProject(apiPayload))
			.unwrap()
			.then(() => {
				history.push('/studio');
			})
			.catch((error) => {
				dispatch(
					revertDispatch({
						projectId
					})
				);

				console.error('Failed to delete journey : ', error);
			})
			.finally(() => removeSnackbar(3000));
	}, [project?._id]);

	return (
		<>
			{showDeleteModal && project && (
				<DeleteModal
					show={showDeleteModal}
					close={toggleDeleteModal}
					projectName={project.projectDetails?.name || 'Untitled'}
					onDelete={handleOnProjectDelete}
				/>
			)}
			<nav
				id="journey-navbar"
				role="presentation"
				className="tw-w-full tw-h-14 tw-fixed tw-z-[100] tw-px-4 tw-py-2 tw-flex-1 
				tw-flex tw-items-center tw-justify-between tw-bg-white tw-top-0 tw-left-0"
				onClick={(e: any) => {
					e.stopPropagation();
				}}
				onMouseDown={(e: any) => {
					e.stopPropagation();
				}}
			>
				<div className="navbar-left tw-h-10 tw-flex tw-justify-between tw-items-center tw-gap-2">
					<NayaLogo />
					{inProject &&
						(hasAccessToProject ||
							currentUser.userType?.includes(EUserType.GUEST) ||
							hasGuestAccessTo) &&
						renderJourneyName()}
				</div>
				{onJourney &&
					(hasAccessToProject || currentUser.userType?.includes(EUserType.GUEST)) &&
					isJourneySearchEnabled && (
						<div className="journey-search-bar tw-flex tw-justify-center tw-items-center tw-flex-1">
							{/* Journey search bar */}
							<SearchBar />
						</div>
					)}
				<div className="navbar-right tw-flex tw-justify-center tw-items-center tw-gap-2">
					{showSocketUsers && isCollaboratorsListEnabled && (
						<SocketUsers socketUsers={socketUsers} />
					)}
					{tasks &&
						inProject &&
						myTaskEnabled &&
						!currentUser.userType?.includes(EUserType.GUEST) &&
						hasAccessToProject &&
						renderMyTasks()}
					{!currentUser.userType?.includes(EUserType.GUEST) &&
						createInNaya &&
						isSyncDriveEnabled &&
						isSyncStorageEnabled &&
						isProjectOwner() &&
						renderSyncDrive()}
					{onJourney &&
						hasAccessToProject &&
						journeyToDeliverables &&
						!searchFilters?.timelineView && <Exports />}
					{inProject &&
						hasAccessToProject &&
						isAiSettingsManagerEnabled &&
						!currentUser.userType?.includes(EUserType.GUEST) &&
						isEditor &&
						renderAISettings()}
					{inProject && userLoggedIn && hasAccessToProject && renderShareButton()}
					{renderProfileIcon()}
				</div>
				<SyncStorageModal flags={{ isSyncDriveEnabled, isTextToTextAiEnabled }} />
				<AISettings show={showAISettingsModal} setShow={setShowAISettingsModal} />
			</nav>
		</>
	);
};

export default NavBar;
