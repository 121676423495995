import { AISettingsManager } from '@naya_studio/radix-ui';
import { store } from 'src';
import { CustomDispatch } from 'src/redux/actions/types';
import useProject from 'src/redux/hooks/useProject';
import { toggleAIStatus } from 'src/redux/reduxActions/project';
import { generateIdsFromUrl } from 'src/redux/reduxActions/util';

/**
 * AI settings modal
 */
const AISettings = ({ show, setShow }: { show: boolean; setShow: (show: boolean) => void }) => {
	const { projectId } = generateIdsFromUrl();
	const { project } = useProject(projectId);
	const contentTraining =
		(project?.aiIngestStatus === 'READY' || project?.aiIngestStatus === 'IN_PROGRESS') ?? false;
	const aiFeatures = project?.isAIFeatureEnabled ?? true;
	const disableAll = !contentTraining && !aiFeatures;

	/**
	 * Function to close the modal
	 */
	const onClose = () => {
		setShow(false);
	};

	/**
	 * Fuction to add redux updates and call api with updated settings
	 */
	const onSave = (isContentTraningEnabled: boolean, isAIFeaturesEnabled: boolean) => {
		(store.dispatch as CustomDispatch)(
			toggleAIStatus({
				projectId,
				isContentTraningEnabled,
				isAIFeaturesEnabled
			})
		);
		setShow(false);
	};
	return (
		<AISettingsManager
			show={show}
			onClose={onClose}
			onSave={onSave}
			isContentTraningEnabled={contentTraining}
			isAIFeaturesEnabled={aiFeatures}
			isAllDisabled={disableAll}
		/>
	);
};

export default AISettings;
