import { ENodeType, INode, TAbsoluteBoundingBox } from '@naya_studio/types';
import _ from 'lodash';
import { Rectangle } from 'pixi.js';
import App from 'src/components/canvas/app/App';

export const jsonEncryptionSecretKey = 'NayaJSONKey';

export const isChrome = navigator.userAgent.indexOf('Chrome') !== -1;

function areNodeBoundsWithinBox(absBounds: TAbsoluteBoundingBox, app: App) {
  const {
    x,
    y,
  } = absBounds;
  const bounds = app.viewport.getVisibleBounds();
  const {
    x: rX,
    y: rY,
    width: rWidth,
    height: rHeight,
  } = bounds;

  if (!(x === undefined) && !(y === undefined)) {
    if (x > rX && x < (rX + rWidth)) {
      if (y > rY && y < (rY + rHeight)) {
        return true;
      }
    }
  }
  return false;
}

export const getNodeDataWithAbsoluteBounds = (
  nodeData: INode,
  absBounds: TAbsoluteBoundingBox,
  boxCordinates: any,
  type: string,
  offset?: number,
  app?: App,
  prevCenter?: Rectangle,
) => {
  const copyData = _.cloneDeep(nodeData) as INode;
  const { topLeft, centre } = boxCordinates;
  switch (type) {
    case 'TOPLEFT':
      copyData.absoluteBounds!.x = (absBounds.x as number)
      + ((copyData.absoluteBounds!.x as number) - (topLeft.x as number));
      copyData.absoluteBounds!.y = (absBounds.y as number)
      + ((copyData.absoluteBounds!.y as number) - (topLeft.y as number));
      break;
    case 'CENTRE':
      copyData.absoluteBounds!.x = (copyData.absoluteBounds!.x as number) < (centre.x as number)
        ? (absBounds.x as number) - ((centre.x as number) - (copyData.absoluteBounds!.x as number))
        : (absBounds.x as number) + ((copyData.absoluteBounds!.x as number) - (centre.x as number));
      copyData.absoluteBounds!.y = (copyData.absoluteBounds!.y as number) < (centre.y as number)
        ? (absBounds.y as number) - ((centre.y as number) - (copyData.absoluteBounds!.y as number))
        : (absBounds.y as number) + ((copyData.absoluteBounds!.y as number) - (centre.y as number));
      break;
    case 'VIEWPORT_CENTER':
      copyData.absoluteBounds!.x = (absBounds.x as number) + (app?.viewport.center.x as number - (prevCenter?.x || 0));
      copyData.absoluteBounds!.y = (absBounds.y as number) + (app?.viewport.center.y as number - (prevCenter?.y || 0));

      break;
    case 'OFFSET':
      if (app && areNodeBoundsWithinBox(absBounds, app)) {
        copyData.absoluteBounds!.x = (absBounds.x as number) + (offset || 25);
        copyData.absoluteBounds!.y = (absBounds.y as number) + (offset || 25);
      } else {
        // console.log('VIEWPORT_CENTER', absBounds, app?.viewport.center, centre);
        const returnData = getNodeDataWithAbsoluteBounds(
          nodeData,
          absBounds,
          boxCordinates,
          'VIEWPORT_CENTER',
          offset,
          app,
          prevCenter,
        ) as INode;
        return returnData;
      }
      break;
    case 'IN_PLACE':
      copyData.absoluteBounds!.x = (absBounds.x as number);
      copyData.absoluteBounds!.y = (absBounds.y as number);
      break;
    default:
      if (app && areNodeBoundsWithinBox(absBounds, app)) {
        copyData.absoluteBounds!.x = (absBounds.x as number) + (offset === 0 ? 0 : offset || 25);
        copyData.absoluteBounds!.y = (absBounds.y as number) + (offset === 0 ? 0 : offset || 25);
      } else {
        const returnData = getNodeDataWithAbsoluteBounds(
          nodeData,
          absBounds,
          boxCordinates,
          'VIEWPORT_CENTER',
          offset,
          app,
          prevCenter,
        ) as INode;
        return returnData;
      }
      break;
  }
  delete copyData._id;
  delete copyData.createdAt;
  delete copyData.createdBy;
  delete copyData.updatedAt;
  delete copyData.lastUpdatedBy;
  return copyData as INode;
};

export const getHighlightedBoxCoordinates = (nodeDataArray: INode[]) => {
  const ascendingXNodeArray = (_.cloneDeep(nodeDataArray) as INode[])
    .sort((nodeData1: INode, nodeData2: INode) => (nodeData1.absoluteBounds?.x as number) - (nodeData2.absoluteBounds?.x as number));
  const ascendingYNodeArray = (_.cloneDeep(nodeDataArray) as INode[])
    .sort((nodeData1: INode, nodeData2: INode) => (nodeData1.absoluteBounds?.y as number) - (nodeData2.absoluteBounds?.y as number));
  const minXNode = ascendingXNodeArray[0] as INode;
  const minYNode = ascendingYNodeArray[0] as INode;
  const maxXNode = ascendingXNodeArray[ascendingXNodeArray.length - 1] as INode;
  const maxYNode = ascendingYNodeArray[ascendingYNodeArray.length - 1] as INode;
  const maxXNodeWidth = (maxXNode.absoluteBounds?.width as number);
  const maxXNodeHeight = (maxXNode.absoluteBounds?.height as number);
  const minXNodeX = (minXNode.absoluteBounds?.x as number);
  const minXNodeY = (minYNode.absoluteBounds?.y as number);
  const imageWidth = maxXNodeWidth > maxXNodeHeight ? 300 : 300 * (maxXNodeWidth / maxXNodeHeight);
  const imgHeight = maxXNodeHeight > maxXNodeWidth ? 300 : (maxXNodeHeight / maxXNodeWidth) * 300;
  const nodeWidth = maxXNode.nodeType === ENodeType.IMAGE ? imageWidth
    : maxXNodeWidth || 145;
  const nodeHeight = maxXNode.nodeType === ENodeType.IMAGE ? imgHeight
    : maxXNodeHeight || 145;
  const boxCordinates = {
    topLeft: { x: minXNodeX, y: minXNodeY },
    centre: {
      x: minXNodeX + ((((maxXNode.absoluteBounds?.x as number) + nodeWidth) - minXNodeX) / 2),
      y: minXNodeY + ((((maxYNode.absoluteBounds?.y as number) + nodeHeight) - minXNodeY) / 2),
    },
  };
  return boxCordinates;
};
