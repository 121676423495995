import { ActionReducerMapBuilder, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IProject, IProjectGroup } from '@naya_studio/types';
import {
	TActionType,
	TDuplicateProjectOptimisticallyThunkArg,
	TEditProjectDetailsThunkArg,
	TGetProjectGroup
} from 'src/types/argTypes';
import { removeGroupFromLocalForage } from 'src/util/storage/indexedDBStorage';
import { IGroupDetailInitState } from '../reducers/root.types';
import { loadProjectGroupById } from '../actions/user';
import {
	duplicateProjectOptimistically,
	editGroupProjectDetails,
	editProjectDetails
} from '../reduxActions/project';
import { findAndUpdateJourneyInGroup } from './util';

const initialProjectGroup: IProjectGroup = {
	_id: '',
	projects: [],
	createdBy: '',
	projectDetails: {},
	name: ''
};
const initialState: IGroupDetailInitState = {
	data: initialProjectGroup,
	loading: false,
	error: null
};

/**
 * User Slice to Handle User related Redux Updates
 */
const groupDetailSlice = createSlice({
	name: 'groupDetail',
	initialState,
	reducers: {
		addGroup: (state: IGroupDetailInitState, action: PayloadAction<IProjectGroup>) => {
			state.data = action.payload;
			state.error = null;
			state.loading = false;
		},
		updateGroup: (state: IGroupDetailInitState, action) => {
			state.data = { ...state.data, ...action.payload };
			state.error = null;
			state.loading = false;
		},
		deleteGroup: (state: IGroupDetailInitState) => {
			if (state.data._id) removeGroupFromLocalForage(state.data._id as string);
			state.data = {
				_id: '',
				projects: [],
				createdBy: '',
				projectDetails: {},
				name: ''
			};
		},
		deleteProjectFromGroup: (
			state: IGroupDetailInitState,
			action: PayloadAction<{
				projectId: string;
			}>
		) => {
			const { projectId } = action.payload;
			const groupProjects = state.data.projects as IProject[];
			const index = groupProjects.findIndex(
				(p) => ((p as IProject)._id as string) === projectId
			);

			if (index !== -1) {
				const projectToDelete = groupProjects[index]!;
				projectToDelete.statuses = projectToDelete?.statuses?.concat({
					status: 'DELETED'
				});

				groupProjects[index] = projectToDelete;
			}
			state.data.projects = groupProjects;
		},
		revertDeletedProjectFromGroup: (
			state: IGroupDetailInitState,
			action: PayloadAction<{
				projectId: string;
			}>
		) => {
			const { projectId } = action.payload;
			const groupProjects = state.data.projects as IProject[];
			const index = groupProjects.findIndex(
				(p) => ((p as IProject)._id as string) === projectId
			);

			if (index !== -1) {
				const projectToDelete = groupProjects[index]!;
				projectToDelete.statuses!.pop();

				groupProjects[index] = projectToDelete;
			}

			state.data.projects = groupProjects;
		}
	},
	extraReducers: (builder: ActionReducerMapBuilder<IGroupDetailInitState>) => {
		builder
			/** ------ Project Groups------ */
			.addCase(
				loadProjectGroupById.fulfilled,
				(
					state: IGroupDetailInitState,
					action: PayloadAction<TActionType<IProjectGroup | null, {}>['payload']>
				) => {
					state.loading = false;
					if (action.payload) state.data = action.payload;
					state.error = null;
				}
			)
			// rejected
			.addCase(
				loadProjectGroupById.rejected,
				(
					state: IGroupDetailInitState,
					action: PayloadAction<
						unknown,
						string,
						TActionType<{}, TGetProjectGroup>['rejectedMeta']
					>
				) => {
					state.loading = false;
					state.error = action.payload as string;
				}
			)
			/** ---- EDIT PROJECT DETAILS ---- */
			// FULFILLED
			.addCase(
				editProjectDetails.fulfilled,
				(
					state: IGroupDetailInitState,
					action: PayloadAction<
						TActionType<TEditProjectDetailsThunkArg['payload'], {}>['payload']
					>
				) => {
					const { payload } = action;

					// On project rename fulfillment, update the project in active group in redux
					if (payload) {
						const { updates, projectId } = payload;

						const updatedGroup = findAndUpdateJourneyInGroup(
							state.data,
							projectId,
							updates,
							true
						);
						state.data = updatedGroup;
					}
				}
			)
			// REJECTED
			.addCase(
				editProjectDetails.rejected,
				(
					state: IGroupDetailInitState,
					action: PayloadAction<
						unknown,
						string,
						TActionType<{}, TEditProjectDetailsThunkArg>['rejectedMeta']
					>
				) => {
					const { payload, prevState } = action.meta.arg;

					if (payload && prevState) {
						const { updates, projectId } = payload;

						// On project rename rejection, update the project in active group in redux with old name
						const prevUpdates = {
							...updates,
							name: prevState?.name
						};
						const updatedGroup = findAndUpdateJourneyInGroup(
							state.data,
							projectId,
							prevUpdates,
							true
						);

						state.data = updatedGroup;
					}
				}
			)
			/** ---- EDIT GROUP DETAILS ---- */
			// FULFILLED
			.addCase(
				editGroupProjectDetails.fulfilled,
				(
					state: IGroupDetailInitState,
					action: PayloadAction<
						TActionType<TEditProjectDetailsThunkArg['payload'], {}>['payload']
					>
				) => {
					const { payload } = action;

					// Update the project group in redux only if a group is active
					// To prevent issues if group is renamed from studio without opening it
					if (payload && payload?.isGroupOpened) {
						const { updates } = payload;

						state.data = {
							...state.data,
							...updates
						};
					}
				}
			)
			// REJECTED
			.addCase(
				editGroupProjectDetails.rejected,
				(
					state: IGroupDetailInitState,
					action: PayloadAction<
						unknown,
						string,
						TActionType<{}, TEditProjectDetailsThunkArg>['rejectedMeta']
					>
				) => {
					const { prevState, payload } = action.meta.arg;
					const isGroupOpened = payload?.isGroupOpened;

					// Update the project group in redux only if a group is active
					// To prevent issues if group is renamed from studio without opening it
					if (prevState && isGroupOpened) {
						state.data.name = prevState?.name;
					}
				}
			)
			/** ---- DUPLICATE PROJECT OPTIMISTICALLY---- */
			// FULFILLED
			.addCase(
				duplicateProjectOptimistically.fulfilled,
				(
					state: IGroupDetailInitState,
					action: PayloadAction<
						TActionType<
							TDuplicateProjectOptimisticallyThunkArg['payload'],
							{}
						>['payload']
					>
				) => {
					const { clonedItems, populatedUser } = action.payload;
					const { project } = clonedItems;

					if (state.data._id) {
						state.data!.projects?.push({
							...project,
							users: [
								{
									user: populatedUser,
									role: 'OWNER'
								}
							]
						});
					}
				}
			);
	}
});

export const {
	addGroup,
	updateGroup,
	deleteGroup,
	deleteProjectFromGroup,
	revertDeletedProjectFromGroup
} = groupDetailSlice.actions;

export default groupDetailSlice.reducer;
