import { IGroup, TUserAndRole, TInvitedEmail, IProject } from '@naya_studio/types';
import {
	updateProject,
	removeIdFromRestrictedAccess,
	addIdToRestrictedAccess
} from 'src/redux/features/projects';
import { deleteStageById, editStageById, addStagesToRedux } from 'src/redux/features/stages';
import * as Y from 'yjs';
import { TChangeType, getYDoc } from 'src/rtc/yjs/yjsConfig';
import { useDispatch } from 'react-redux';
import { cancelTaskOnBlockOrPhaseDelete } from 'src/hooks/useFileConverter';
import { isEqual } from 'lodash';
import getUserFromRedux from 'src/util/helper/user';
import { broadcastSelfDispatch, checkIfUserHasAccess, getProjectFromYDocOrRedux } from './utils';

/**
 * Hook to update stages in redux after yjs listens to stage change
 */
const useStageObserver = () => {
	const dispatch = useDispatch();

	/**
	 * Observer to listen to stage changes in ymap
	 * @param update stage change to do in redux
	 */
	const stageObserver = (update: Y.YMapEvent<string>) => {
		try {
			const doc = getYDoc();
			const user = getUserFromRedux();

			update.changes.keys.forEach((change: TChangeType<string>, key: string) => {
				if (change.action === 'update') {
					const stage = JSON.parse(doc.getMap('pathways').get(key) as string) as IGroup;
					// console.info('RTC - Received stage update', stage);
					const oldStage = JSON.parse(change.oldValue) as IGroup;
					stage.isVisible = stage.isVisible && (stage.children?.length || -1) > 0;
					const selfDispatch = stage.lastUpdatedBy === (user._id as string);
					// Adding check in edit case because
					// Stage could be updated for updates done via email, slack
					const originatedFromNaya = stage.origin === 'NAYA';
					if (selfDispatch && originatedFromNaya) {
						broadcastSelfDispatch(stage, 'EDIT', 'GROUP');
						return;
					}

					const { projectId } = stage;
					const project = getProjectFromYDocOrRedux(projectId);
					const hasGuestAccess = project?.hasGuestAccess || false;

					// If user has access on current stage
					const hasAccess =
						checkIfUserHasAccess(
							user,
							stage.users as TUserAndRole[],
							stage.invitedEmails as TInvitedEmail[]
						) || hasGuestAccess;
					// If user had access before this stage update
					const hadAccess =
						checkIfUserHasAccess(
							user,
							oldStage.users as TUserAndRole[],
							oldStage.invitedEmails as TInvitedEmail[]
						) || hasGuestAccess;

					if (selfDispatch || (!hasAccess && !hadAccess)) {
						// For case when we receive self update but we're missing that update in redux
						// Or if a block is added/removed from parent group but we don't have access to it
						const newBlocks = stage.children as string[];
						const oldBlocks = oldStage.children as string[];

						if (!isEqual(newBlocks, oldBlocks)) dispatch(editStageById(stage));
						return;
					}

					if (hasAccess) {
						// Fix for reordering a block to leave phase empty
						if (!stage.isVisible && !stage.isPhaseCreated)
							dispatch(deleteStageById(key));
						// If user has access now and didn't had it before
						// that means he is invited on the stage so load the project
						else if (!hadAccess) {
							// Do this to have the correct order of stages
							const filteredStages = (project.children as string[])?.filter((s) =>
								[...(project.children as string[]), key].includes(s)
							);

							dispatch(
								updateProject({
									_id: projectId,
									children: filteredStages
								} as Partial<IProject>)
							);

							// Load the stage as well as the blocks in redux
							// Since user is invited to this stage he'll also have access to all blocks
							dispatch(addStagesToRedux([stage]));

							// If user is invited on this phase remove his id from restricted phases
							dispatch(
								removeIdFromRestrictedAccess({
									_id: projectId,
									type: 'PHASE',
									invitedTo: key
								})
							);
						} else dispatch(editStageById(stage));
					} else if (hadAccess) {
						// If user is removed from this stage
						// delete this stage from his redux
						dispatch(deleteStageById(key));

						// Remove the snackbar for all the ongoing step file processes
						cancelTaskOnBlockOrPhaseDelete(stage.children as string[], 'PHASE');

						// If user is removed from this phase, add his id in restricted phases
						dispatch(
							addIdToRestrictedAccess({
								_id: projectId,
								type: 'PHASE',
								removedFrom: key
							})
						);
					}
				} else if (change.action === 'add') {
					const stage = JSON.parse(doc.getMap('pathways').get(key) as string) as IGroup;
					// console.info('RTC - Received stage add', stage);
					const selfDispatch = stage.lastUpdatedBy === (user._id as string);
					const originatedFromNaya = stage.origin === 'NAYA';
					if (selfDispatch && originatedFromNaya) {
						broadcastSelfDispatch([stage], 'ADD', 'GROUP');
						return;
					}

					const { projectId } = stage;
					const project = getProjectFromYDocOrRedux(projectId);
					const hasGuestAccess = project?.hasGuestAccess || false;

					const hasAccess =
						checkIfUserHasAccess(
							user,
							stage.users as TUserAndRole[],
							stage.invitedEmails as TInvitedEmail[]
						) || hasGuestAccess;
					const isParentOfOrphanBlock =
						!stage.isPhaseCreated && stage.children && stage.children?.length === 1;

					if (hasAccess || isParentOfOrphanBlock) dispatch(addStagesToRedux([stage]));
				} else if (change.action === 'delete') {
					const stage = JSON.parse(change.oldValue) as IGroup;
					// console.info('RTC - Received stage delete', stage);
					const selfDispatch = stage.lastUpdatedBy === (user._id as string);
					if (selfDispatch) {
						broadcastSelfDispatch(key, 'DELETE', 'GROUP');
						return;
					}

					const { projectId } = stage;
					const project = getProjectFromYDocOrRedux(projectId);
					const hasGuestAccess = project?.hasGuestAccess || false;

					const hasAccess =
						checkIfUserHasAccess(
							user,
							stage.users as TUserAndRole[],
							stage.invitedEmails as TInvitedEmail[]
						) || hasGuestAccess;

					if (hasAccess) {
						dispatch(deleteStageById(key));
						// Remove the snackbar for all the ongoing step file processes
						cancelTaskOnBlockOrPhaseDelete(stage.children as string[], 'PHASE');
					}
				}
			});
		} catch (error) {
			console.error('Error in Stage Listener : ', error);
		}
	};

	return stageObserver;
};

export default useStageObserver;
