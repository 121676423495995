import * as PIXI from 'pixi.js';
import { CustomDispatch } from 'src/redux/actions/types';
import {
	ENodeType,
	EShapeType,
	IBlock,
	ICanvas,
	IFeedback,
	INode,
	IProject,
	IUser,
	TUserAndRole
} from '@naya_studio/types';
import { EActionType } from 'src/redux/reducers/undoRedo/undoActionHistory.types';
import { undoAction } from 'src/redux/actions/undoRedoActions';
import _ from 'lodash';
import {
	checkIfCurrentUserIsAdmin,
	checkUserAccessLevel,
	getCurrentBlock
} from 'src/util/accessLevel/accessLevelActions';
import { fetchBlockByIdFromRedux } from 'src/redux/actions/util';
import { Graphics } from 'pixi.js';
import { v1 as uuidv1 } from 'uuid';
import { ISnackBar, ReduxState } from 'src/redux/reducers/root.types';
import { addNodes } from 'src/redux/reduxActions/node';
import { TAddNodesArg } from 'src/types/argTypes';
import { generateIdsFromUrl } from 'src/redux/reduxActions/util';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';
import { addNewCommentData, resetNewCommentData } from 'src/redux/features/newComment';
import getUserFromRedux from 'src/util/helper/user';
import App from '../App';
import BaseNode from './BaseNode';
import Markup from './Markup';
import Shape from './Shape';
import StickyNote from './StickyNote';
import attachGraphicsLines from '../utils/attachLines';
/** Redux */
import { store } from '../../../../index';
/** Endpoints */

import Polygon from './Polygon';
import Text from './Text';

let rateLimiter: any = false;

/**
 * Frame
 */
class Frame {
	app: App;

	displayObject: any;

	markup?: Markup;

	currentNode?: BaseNode | Shape;

	hoverNode: PIXI.Container | undefined;

	textNodeBox: PIXI.Graphics | undefined;

	startPos?: { x: number; y: number };

	endPos?: { x: number; y: number };

	prevPos?: { x: number; y: number };

	isMouseDown?: boolean;

	project: IProject;

	markupLock: 'HORIZONTAL' | 'VERTICAL' | 'DIAGONAL' | 'NONE' = 'NONE';

	outline?: Graphics;

	maskContainer?: Graphics;

	/**
	 * Frame constructor
	 * @param app
	 */
	constructor(app: App) {
		this.app = app;
		// this.startPos = { x: 0, y: 0 };
		// this.endPos = { x: 0, y: 0 };
		const { projectId } = generateIdsFromUrl();
		this.project = store.getState().projects.data[projectId] as IProject;
		const canvas = fetchBlockByIdFromRedux(app.getCanvasId()) as ICanvas;
		if (canvas && canvas.bcolor) {
			const { bcolor } = canvas;
			app.bkgColor = bcolor;
		}
		if (canvas && canvas.pattern) {
			const { type, opacity } = canvas.pattern;
			app.activeTexture = type as string;
			app.textureOpacity = opacity as number;
		}
	}

	static checkIfUserHasEditAccess = () => {
		const { projectId } = generateIdsFromUrl();
		const project = store.getState().projects.data[projectId] as IProject;
		const currentUser: IUser = getUserFromRedux();
		if (
			checkUserAccessLevel(project.users as TUserAndRole[], currentUser._id as string, [
				'OWNER',
				'EDITOR'
			]) ||
			checkIfCurrentUserIsAdmin()
		) {
			return true;
		}
		return false;
	};

	static findPoint = (x1: number, y1: number, x2: number, y2: number, x: number, y: number) => {
		if (x > x1 && x < x2 && y < y1 && y > y2) return true;

		return false;
	};

	checkIfHittingEdge = (e: any) => {
		const lastvp = this.app.viewport.lastViewport;
		const pointData = e.data.global;
		const vertices = this.displayObject.vertexData;
		const a = Frame.findPoint(
			vertices[4],
			vertices[5],
			vertices[2],
			vertices[3],
			pointData.x,
			pointData.y
		);
		if (lastvp) {
			const x1 = vertices[4] + 4 * lastvp.scaleX!;
			const y1 = vertices[5] - 4 * lastvp.scaleY!;
			const x2 = vertices[2] - 4 * lastvp.scaleX!;
			const y2 = vertices[3] + 4 * lastvp.scaleY!;
			const b = Frame.findPoint(x1, y1, x2, y2, pointData.x, pointData.y);
			if (a && !b && Frame.checkIfUserHasEditAccess()) {
				this.app.setCanvasEdgeHover(true);
				return true;
			}
		}
		this.app.setCanvasEdgeHover(false);
		return false;
	};

	/**
	 * onClick function
	 */
	onClick = (e: any) => {
		if (this.checkIfHittingEdge(e)) {
			// Removing the outline if exists
			this.app.selectFrame();
		}
		this.app.viewportMouseDown();
		// this.app.selectedNodes = [];
		// this.app.removeTransformer();
		// this.app.toggleEditMenu(false)
	};

	async drawHoverNode(x: number, y: number, nodeType: ENodeType, hoverColor?: any) {
		if (this.hoverNode) {
			this.app.viewport.removeChild(this.hoverNode);
			this.hoverNode.destroy();
		}
		switch (nodeType) {
			case ENodeType.STICKY_NOTE: {
				const HoverColor: any = hoverColor;
				const TempColor = `0x${HoverColor.substring(1, HoverColor.length + 1)}`;
				const ghostStickyNoteColor = parseInt(TempColor, 16);
				const shape = new PIXI.Graphics();
				shape.name = 'STICKY_NOTE_HOVER';
				shape.beginFill(ghostStickyNoteColor, 0.4);
				shape.drawRect(
					-StickyNote.defaultStickyNodeProps.width / 2,
					-StickyNote.defaultStickyNodeProps.height / 2,
					StickyNote.defaultStickyNodeProps.width +
						StickyNote.defaultStickyNodeProps.padding.x,
					StickyNote.defaultStickyNodeProps.height +
						StickyNote.defaultStickyNodeProps.padding.y
				);
				shape.endFill();
				this.hoverNode = new PIXI.Container();
				this.hoverNode.addChild(shape);
				this.hoverNode.setTransform(x, y);
				this.hoverNode.zIndex = this.app.getNewZindex(undefined, false, true);
				this.app.viewport.addChild(this.hoverNode);
				break;
			}
			case ENodeType.MODEL: {
				/** Draw Image */
				let modelShape;
				if (this.app.upload2dFiles.length !== 0) {
					modelShape = PIXI.Sprite.from(this.app.upload2dFiles[0].url);
				} else if (this.app.uploadPlaceholderFiles.length !== 0) {
					modelShape = PIXI.Sprite.from(
						'https://firebasestorage.googleapis.com/v0/b/' +
							'naya-uploads-test.appspot.com/o/1657614983871.svg?alt=media&token=c4f92de8-8f09-4381-bba9-56ff676bb448'
					);
				} else {
					modelShape = PIXI.Sprite.from(
						'https://firebasestorage.googleapis.com/v0/b/' +
							'naya-uploads-test.appspot.com/o/1657557594795.svg?alt=media&token=0815ee14-59d7-44c3-9fbf-b883c8b6c03f'
					);
				}
				modelShape.name = 'UPLOAD_HOVER';
				let scale = 1;
				if (this.app.upload2dFiles[0]?.height && this.app.upload2dFiles[0]?.width) {
					scale =
						this.app.upload2dFiles[0].height > this.app.upload2dFiles[0].width
							? 200 / this.app.upload2dFiles[0].height
							: 200 / this.app.upload2dFiles[0].width;
				} else {
					scale = 0.1;
				}

				if (this.app.upload2dFiles[0]) {
					modelShape.scale.set(scale);
				} else {
					// modelShape.scale.set(1)
					modelShape.anchor.set(-0.3, -0.15);
					modelShape.height = 120;
					modelShape.width = 184;
				}
				modelShape.position.set(43, 43);
				/** Draw border to image */
				const imageBorder = new PIXI.Graphics();
				imageBorder.lineStyle(3, 0xc0c0c0);
				imageBorder.drawRect(
					43,
					43,

					(this.app.upload2dFiles[0]?.width || 184) * scale || 184,

					(this.app.upload2dFiles[0]?.height || 184) * scale || 120
				);
				// imageBorder.beginFill(0xFFFF00);
				imageBorder.endFill();

				/** Draw bundle - only 1 now */
				const imageStack = new PIXI.Graphics();
				imageStack.name = 'STICKY_NOTE_HOVER';
				imageStack.lineStyle(3, 0xc0c0c0);
				imageStack.beginFill(0xe2e2e2);
				imageStack.drawRect(
					49,
					49,

					(this.app.upload2dFiles[0]?.width || 184) * scale + 4 || 184,

					(this.app.upload2dFiles[0]?.height || 120) * scale + 4 || 120
				);
				imageStack.endFill();

				/** Add number */
				const pixiText = new PIXI.Text(
					`${
						this.app.upload2dFiles.length +
						this.app.upload3dFiles.length +
						this.app.uploadPlaceholderFiles.length
					}`,
					{
						fontFamily: 'Arial',
						fontSize: 14 * (25 / 16.5),
						align: 'center',
						fill: 'white'
					}
				);
				pixiText.anchor.set(0.5);
				pixiText.x = 43;
				pixiText.y = 43;
				/** Draw number */
				const gr = new PIXI.Graphics();
				const color = `0x${store.getState().theme.color.substring(1)}`;
				gr.beginFill(parseInt(color, 16));
				gr.drawCircle(43, 43, 20);
				gr.endFill();
				gr.addChild(pixiText);
				this.hoverNode = new PIXI.Container();
				if (this.app.upload2dFiles.length + this.app.upload3dFiles.length > 2) {
					const imageStack2 = new PIXI.Graphics();
					imageStack2.name = 'IMAGE_STACK_2';
					imageStack2.lineStyle(3, 0xc0c0c0);
					imageStack2.beginFill(0xe2e2e2);
					imageStack2.drawRect(
						55,
						55,
						(this.app.upload2dFiles[0]?.width || 184) * scale + 10 || 184,
						(this.app.upload2dFiles[0]?.height || 120) * scale + 10 || 120
					);
					imageStack.endFill();
					this.hoverNode.addChild(imageStack2);
					this.hoverNode.addChild(imageStack);
				} else if (this.app.upload2dFiles.length + this.app.upload3dFiles.length > 1) {
					this.hoverNode.addChild(imageStack);
				}
				const whiteBackground = new PIXI.Graphics();
				whiteBackground.beginFill(0xffffff);
				whiteBackground.drawRect(
					43,
					43,

					(this.app.upload2dFiles[0]?.width || 184) * scale || 184,

					(this.app.upload2dFiles[0]?.height || 120) * scale || 120
				);
				whiteBackground.endFill();
				this.hoverNode.addChild(whiteBackground);
				this.hoverNode.addChild(modelShape);
				this.hoverNode.addChild(imageBorder);
				// this.hoverNode.addChild(imageBorder);
				this.hoverNode.addChild(gr);
				this.hoverNode.setTransform(x + 100, y + 100);
				this.hoverNode.zIndex = (this.app?.getHighestZIndex() as number) + 1;
				this.app.viewport.addChild(this.hoverNode);
				break;
			}
			case ENodeType.REACTION: {
				const reactionSprite = PIXI.Sprite.from(this.app.nodeData?.image?.src!);
				reactionSprite.width = 32;
				reactionSprite.height = 32;
				reactionSprite.alpha = 0.5;
				this.hoverNode = new PIXI.Container();
				this.hoverNode.zIndex = 999;
				this.hoverNode.addChild(reactionSprite);
				this.hoverNode.setTransform(x - 16, y - 16);
				this.app.viewport.addChild(this.hoverNode);
				break;
			}
			default:
				break;
		}
	}

	removeHoverNode() {
		if (this.hoverNode) {
			this.app.viewport.removeChild(this.hoverNode);
		}
		this.hoverNode = undefined;
	}

	// Draw background pattern
	setBackgroundTexture() {
		if (this.app.backgroundPattern) {
			this.app.viewport.removeChild(this.app.backgroundPattern);
			this.app.viewport.removeChild(this.maskContainer!);
		}
		const lineGraphics = new PIXI.Graphics();
		switch (this.app.activeTexture) {
			case 'DOT': {
				let column = 0;
				while (column < this.app.height - 24) {
					let row = 0;
					while (row < this.app.width - 24) {
						lineGraphics.beginFill(0x7e7e7e, 1);
						lineGraphics.drawCircle(24 + row, 24 + column, 1);
						lineGraphics.endFill();
						row += 24;
					}
					column += 24;
				}
				break;
			}
			case 'GRID': {
				let row = 24;
				lineGraphics.beginFill(0xffffff, 1);
				while (row < this.app.width) {
					lineGraphics.moveTo(row, 0);
					lineGraphics.lineStyle(0.5, 0x7e7e7e);
					lineGraphics.lineTo(row, this.app.height);
					row += 24;
				}
				let column = 24;
				while (column < this.app.height) {
					lineGraphics.moveTo(0, column);
					lineGraphics.lineStyle(0.5, 0x7e7e7e);
					lineGraphics.lineTo(this.app.width, column);
					column += 24;
				}
				lineGraphics.endFill();
				break;
			}
			case 'ISOS': {
				lineGraphics.beginFill(0xffffff, 1);
				lineGraphics.lineStyle(0.5, 0x7e7e7e);
				let column = 0;
				while (column < this.app.height) {
					let row = 0;
					while (row < this.app.width) {
						lineGraphics.moveTo(30 + row, 0 + column);
						lineGraphics.lineTo(60 + row, 15 + column);
						lineGraphics.lineTo(30 + row, 30 + column);
						lineGraphics.lineTo(30 + row, 0 + column);
						lineGraphics.lineTo(0 + row, 15 + column);
						lineGraphics.lineTo(30 + row, 30 + column);
						lineGraphics.moveTo(0 + row, 0 + column);
						lineGraphics.lineTo(0 + row, 30 + column);
						row += 60;
					}
					column += 30;
				}
				lineGraphics.endFill();
				break;
			}
			case 'LINED': {
				lineGraphics.beginFill(0xffffff, 1);
				lineGraphics.lineStyle(0.5, 0xf6c4c0);
				lineGraphics.moveTo(132, 0);
				lineGraphics.lineTo(132, this.app.height);

				let row = 96;
				while (row < this.app.height) {
					lineGraphics.moveTo(0, row);
					lineGraphics.lineStyle(0.5, 0x02cbef);
					lineGraphics.lineTo(this.app.width, row);
					row += 24;
				}
				lineGraphics.endFill();
				break;
			}
			default:
				break;
		}
		lineGraphics.alpha = this.app.textureOpacity;
		lineGraphics.zIndex = 0;
		lineGraphics.setTransform(this.app.frameX, this.app.frameY);
		if (this.app.activeTexture === 'ISOS') {
			const frameContainer = new PIXI.Graphics();
			frameContainer.beginFill(0xffffff);
			frameContainer.drawRect(
				this.app.frameX as number,
				this.app.frameY as number,
				this.app.width as number,
				this.app.height as number
			);
			frameContainer.endFill();
			this.app.backgroundPattern = lineGraphics;
			this.app.viewport.addChild(lineGraphics);
			this.app.viewport.addChild(frameContainer);
			lineGraphics.mask = frameContainer;
			this.maskContainer = frameContainer;
		} else {
			this.app.backgroundPattern = lineGraphics;
			this.app.viewport.addChild(lineGraphics);
		}
	}

	// Mouse actions for the canvas on hover

	frameMouseover = () => {
		this.app.setCanvasEdgeHover(true);
		this.highlightFrame();
	};

	frameMouseout = () => {
		this.app.setCanvasEdgeHover(false);
		this.removeFrameHighlight();
	};

	/**
	 * Draw function
	 */
	draw() {
		const graphics = new PIXI.Graphics();
		const hexColor = PIXI.utils.string2hex(this.app.bkgColor);
		graphics.beginFill(hexColor, 1);

		graphics.scale.set(1);
		graphics.interactive = true;
		graphics.zIndex = -999999;

		const canvas = fetchBlockByIdFromRedux(this.app.getCanvasId()) as ICanvas;
		if (!canvas.bounds) {
			// Adding temp bounds if bounds don't exist
			canvas.bounds = {
				x: 0,
				y: 0,
				width: 1920,
				height: 1080
			};
		}

		graphics.drawRect(
			0,
			0,
			(canvas?.bounds?.width as number) || this.app.width || 1920,
			(canvas?.bounds?.height as number) || this.app.height || 1080
		);
		graphics.endFill();

		if (this.displayObject) {
			this.app.viewport.removeChild(this.displayObject);
		}
		this.displayObject = graphics;
		this.displayObject.setTransform(
			canvas && canvas.bounds && canvas.bounds.x ? canvas.bounds.x : 0,
			canvas && canvas.bounds && canvas.bounds.y ? canvas.bounds.y : 0,
			0,
			0,
			0
		);

		attachGraphicsLines(canvas.bounds, graphics, this.frameMouseover, this.frameMouseout);

		this.displayObject.on('click', this.onClick);
		this.displayObject.on('mouseup', this.onMouseUp);
		this.displayObject.on('mousedown', this.onMouseDown);
		this.displayObject.on('mousemove', this.onMouseMove);
		this.displayObject.on('mouseout', this.onMouseOut);
		this.displayObject.name = 'FRAME';

		this.app.viewport.addChild(this.displayObject);
		if (this.app.activeTexture) {
			this.setBackgroundTexture();
		}
	}

	/**
	 * Converts string based hex string colors to pixi compatible hex colors
	 * @function
	 * @param {string} color
	 * @returns {number} converted hex value in the required format - 0xFF0000
	 */
	static getColorValue = (color: any) => {
		if (color) {
			const TempColor = `0x${color.substring(1, color.length + 1)}`;
			return parseInt(TempColor, 16);
		}
		return 0x000000;
	};

	onMouseOut = () => {
		this.removeFrameHighlight();
	};

	/**
	 * onMouseDown
	 * @param e Mouse event
	 */
	onMouseDown = async (e: any) => {
		if (this.app.is3DSnapshot) return;
		if (e.data.button === 1) {
			this.app.isPanSelected = true;
			this.app.resumeDragPlugin();
			this.app.setActiveTool('PAN');
			this.app.throughPanTool = true;
		}
		if (this.app.isPanSelected) return;
		this.app.removeTransformer();
		const { x: worldX, y: worldY } = this.app.viewport.toLocal(e.data.global);
		this.startPos = { x: worldX, y: worldY };
		this.isMouseDown = true;
		if (this.app.checkIfAnySelectedNodeIsLocked()) {
			const bounds = this.app.transformer.getGroupBounds().innerBounds;
			const { x } = bounds;
			const { y } = bounds;
			const { width } = bounds;
			const { height } = bounds;
			const currentX = e.data.global.x;
			const currentY = e.data.global.y;
			if (currentX > x && currentX < x + width && currentY > y && currentY < y + height) {
				return;
			}
		}
		this.app.resetFileIconColor();
		this.app.removeAllSelectedNodes();
		this.app.enableHighlighting();
		this.app.removeTransformer();

		// save text if using text node
		// this.app.saveTextEdit();

		this.app.toggleEditMenu(false);
		this.app.toggleCanvasEditMenu(false);
		this.app.isColourPickerOpen = false;
		this.app.textLinkTooltip.url = '';

		const newZIndex = Math.floor(this.app.getHighestZIndex() + 1);

		switch (this.app.activeTool) {
			case 'SHAPE': {
				// Drawing tha shape -- shape is added to the database on mouseup
				const shapeData = {
					...Shape.defaultNodeData,
					...this.app.nodeData,
					x: worldX,
					y: worldY
				};
				if (shapeData.absoluteBounds) {
					shapeData.absoluteBounds.x = worldX;
					shapeData.absoluteBounds.y = worldY;
				}
				shapeData.zIndex = newZIndex;
				switch (shapeData.shapeType) {
					case EShapeType.RECTANGLE:
					case EShapeType.ELLIPSE:
					case EShapeType.ROUNDED_RECTANGLE:
					case EShapeType.STAR:
					case EShapeType.TRIANGLE:
					case EShapeType.HEART:
						if (shapeData.fill?.fillColor === 'NO_FILL')
							shapeData.fill.fillColor = '#7E7E7E';
						this.currentNode = new Polygon(this.app, shapeData);
						break;
					case EShapeType.ARROW:
					case EShapeType.DASHED_LINE:
					case EShapeType.SOLID_LINE:
						this.currentNode = new Shape(this.app, shapeData);
						break;
					default:
						break;
				}
				this.currentNode?.draw();
				break;
			}
			case 'MARKUP': {
				// Drawing tha markup -- markup is added to the database on mouseup
				const markupData = { ...Markup.defaultNodeData, ...this.app.nodeData };
				markupData.paths = [this.startPos, this.startPos];
				markupData.zIndex = newZIndex;
				this.currentNode = new Markup(this.app, markupData);
				this.currentNode.draw();
				break;
			}
			case 'COMMENT': {
				if (!this.app.commentHasText) {
					if (window.location.pathname === '/onboarding') {
						this.addNewComment(e);
						break;
					}
					const state = store.getState();
					const currentUser = state.user as IUser;
					const block = (await getCurrentBlock()) as IBlock;
					if (
						!checkUserAccessLevel(
							block.users as TUserAndRole[],
							currentUser._id as string,
							['VIEWER']
						)
					) {
						this.addNewComment(e);
					}
				}
				break;
			}
			case 'REACTION': {
				if (this.app.nodeData) {
					const { x: reactionX, y: reactionY } = this.app.viewport.toLocal({
						x: e.data.global.x,
						y: e.data.global.y
					});
					const reactionData = _.cloneDeep(this.app.nodeData);
					if (reactionData?.absoluteBounds) {
						reactionData.absoluteBounds.x = reactionX - 16;
						reactionData.absoluteBounds.y = reactionY - 16;
						// To allow only one reaction to be rendered at one time
						// this.app.nodeData = undefined;
					}
					reactionData.zIndex = newZIndex;
					if (!reactionData?._id) reactionData._id = uuidv1();

					// const reactionNode = new Reaction(this.app, reactionData);
					// await reactionNode.draw();
					// extracting redux state
					const reduxState = store.getState() as ReduxState;
					const { blocks, nodes } = reduxState;
					const user: IUser = getUserFromRedux();

					const { blockId } = generateIdsFromUrl();
					// generating add nodes api payload
					const payload: TAddNodesArg = {
						data: {
							nodes: [
								{
									...reactionData,
									createdBy: user._id as string,
									lastUpdatedBy: user._id as string,
									version: 0
								}
							],
							...generateIdsFromUrl()
						},
						prevState: {
							prevNodes: nodes.data,
							prevBlock: blocks.data[blockId] as IBlock
						}
					};
					// dispatch add nodes
					(store.dispatch as CustomDispatch)(addNodes(payload))
						.unwrap()
						.then((response?: { nodes?: INode[] }) => {
							if (response?.nodes) {
								undoAction(
									EActionType.NEW_NODE,
									{
										...(response?.nodes![0] as INode),
										isVisible: false
									} as INode,
									_.cloneDeep(response?.nodes[0]) as INode,
									false
								);
							}
						})
						.catch((error) => {
							console.error('Error : ', error);

							const snackbarPayload: ISnackBar = {
								text: 'Failed to upload reaction.',
								show: true,
								type: 'ERROR'
							};

							addSnackbar(snackbarPayload);
							removeSnackbar(2000);
						});
					// To allow only one reaction to be rendered at one time
					// this.app.nodeData = undefined;
				}
				break;
			}
			case 'TEXT': {
				// this.app.openTextEdit(e, 'TEXT')
				const reduxState = store.getState();

				this.textNodeBox = new PIXI.Graphics();
				this.textNodeBox.beginFill(0x000000, 0.1);
				this.textNodeBox.lineStyle(
					1,
					Frame.getColorValue(reduxState.theme.color),
					1,
					0.5,
					true
				);
				this.textNodeBox.drawRect(0, 0, 200, 28);
				this.textNodeBox.endFill();
				break;
			}
			case 'STICKY_NOTE': {
				const reduxState = store.getState();

				this.textNodeBox = new PIXI.Graphics();
				this.textNodeBox.beginFill(0xffdb41, 1);
				this.textNodeBox.lineStyle(
					1,
					Frame.getColorValue(reduxState.theme.color),
					1,
					0.5,
					true
				);
				this.textNodeBox.drawRect(
					0,
					0,
					StickyNote.defaultStickyNodeProps.width,
					StickyNote.defaultStickyNodeProps.height
				);
				this.textNodeBox.endFill();
				break;
			}
			case '3D': {
				if (this.hoverNode) {
					this.removeHoverNode();
				}
				this.hoverNode = undefined;
				break;
			}
			default: {
				this.currentNode = undefined;
				break;
			}
		}
	};

	highlightFrame = () => {
		if (this.outline) {
			this.removeFrameHighlight();
		} else {
			this.outline = new PIXI.Graphics();
		}
		if (!this.app.canvasSelected && Frame.checkIfUserHasEditAccess()) {
			const reduxState = store.getState();
			// const canvas = fetchBlockByIdFromRedux(this.app.getCanvasId())

			this.outline.beginFill(0x000000, 1e-4);
			this.outline.lineStyle(1, BaseNode.getColorValue(reduxState.theme.color), 1, 0);
			this.outline.drawRect(
				this.displayObject.vertexData[0],
				this.displayObject.vertexData[1],
				this.displayObject.vertexData[2] - this.displayObject.vertexData[0],
				this.displayObject.vertexData[5] - this.displayObject.vertexData[1]
			);
			// this.outline.drawRect((canvas && canvas.bounds && canvas.bounds.x ? canvas.bounds.x : 0) ,
			// (canvas && canvas.bounds && canvas.bounds.y ? canvas.bounds.y : 0), this.displayObject.width, this.displayObject.height);
			this.outline.endFill();
			this.outline.zIndex = this.app.getHighestZIndex();
			this.outline.interactive = false;
			this.app.app.stage.addChild(this.outline);
		}
	};

	removeFrameHighlight = () => {
		if (this.outline) {
			this.app.app.stage?.removeChild(this.outline);
			this.outline = undefined;
		}
	};

	/**
	 * handle mouse move
	 * @param e
	 */
	onMouseMove = (e: any) => {
		if (this.app.isPanSelected) return;
		const { x: worldX, y: worldY } = this.app.viewport.toLocal(e.data.global);
		this.endPos = { x: worldX, y: worldY };

		if (this.startPos) {
			const delta = {
				delX: this.endPos.x - this.startPos.x,
				delY: this.endPos.y - this.startPos.y
			};

			this.isMouseDown = e.data.buttons === 1;

			switch (this.app.activeTool) {
				// Drag and draw handler for the shape
				case 'SHAPE':
					if (this.isMouseDown && this.currentNode) {
						const { delX, delY } = delta;
						const { shiftPressed } = this.app;
						const { shapeType } = this.currentNode.nodeData;
						if (
							[
								EShapeType.SOLID_LINE,
								EShapeType.ARROW,
								EShapeType.DASHED_LINE
							].includes(shapeType as EShapeType)
						) {
							if (shiftPressed && delX !== 0) {
								let slopeType = '';
								const shapeSlope = Math.abs(delY / delX);
								if (shapeSlope < 0.3) {
									slopeType = 'VERTICAL';
								} else if (shapeSlope > 3) {
									slopeType = 'HORIZONTAL';
								} else {
									slopeType = 'DIAGONAL';
								}

								if (slopeType === 'DIAGONAL') {
									const slope = delY / delX / Math.abs(delY / delX);
									if (Math.abs(delX) > Math.abs(delY)) {
										const y =
											this.startPos.y +
											slope * (this.endPos.x - this.startPos.x);
										this.currentNode.resize(
											this.startPos.x,
											this.startPos.y,
											delX,
											y - this.startPos.y
										);
									} else {
										const x =
											this.startPos.x +
											(1 / slope) * (this.endPos.y - this.startPos.y);
										this.currentNode.resize(
											this.startPos.x,
											this.startPos.y,
											x - this.startPos.x,
											delY
										);
									}
								} else if (slopeType === 'HORIZONTAL') {
									this.currentNode.resize(
										this.startPos.x,
										this.startPos.y,
										0,
										delY
									);
								} else if (slopeType === 'VERTICAL') {
									this.currentNode.resize(
										this.startPos.x,
										this.startPos.y,
										delX,
										0
									);
								}
							} else {
								this.currentNode.resize(
									this.startPos.x,
									this.startPos.y,
									delX,
									delY
								);
							}
						} else if (this.startPos.x > this.endPos.x) {
							if (this.startPos.y > this.endPos.y) {
								const dX = this.startPos.x - this.endPos.x;
								const dY = this.startPos.y - this.endPos.y;
								const proportionateDxDy = dX > dY ? dX : dY;
								this.currentNode.resize(
									shiftPressed
										? this.startPos.x - proportionateDxDy
										: this.endPos.x,
									shiftPressed
										? this.startPos.y - proportionateDxDy
										: this.endPos.y,
									shiftPressed ? proportionateDxDy : dX,
									shiftPressed ? proportionateDxDy : dY
								);
							} else {
								const dX = this.startPos.x - this.endPos.x;
								const dY = this.endPos.y - this.startPos.y;
								const proportionateDxDy = dX > dY ? dX : dY;
								this.currentNode.resize(
									shiftPressed
										? this.startPos.x - proportionateDxDy
										: this.endPos.x,
									this.startPos.y,
									shiftPressed ? proportionateDxDy : dX,
									shiftPressed ? proportionateDxDy : dY
								);
							}
						} else if (this.startPos.y > this.endPos.y) {
							const dX = this.endPos.x - this.startPos.x;
							const dY = this.startPos.y - this.endPos.y;
							const proportionateDxDy = dX > dY ? dX : dY;
							this.currentNode.resize(
								this.startPos.x,
								shiftPressed ? this.startPos.y - proportionateDxDy : this.endPos.y,
								shiftPressed ? proportionateDxDy : dX,
								shiftPressed ? proportionateDxDy : dY
							);
						} else {
							const proportionateDxDy = delX > delY ? delX : delY;
							this.currentNode.resize(
								this.startPos.x,
								this.startPos.y,
								shiftPressed ? proportionateDxDy : delX,
								shiftPressed ? proportionateDxDy : delY
							);
						}
					} else if (
						this.currentNode?.nodeData.nodeType === 'SHAPE' &&
						this.currentNode
					) {
						this.currentNode?.save();
						this.currentNode = undefined;
					}
					break;
				// Draw the markup -- freehand -- add points to the existing markup
				case 'MARKUP':
					if (this.isMouseDown) {
						if (!rateLimiter) {
							if (this.currentNode !== undefined) {
								const dX = this.endPos.x - this.startPos.x;
								const dY = this.endPos.y - this.startPos.y;
								if (this.app.shiftPressed) {
									if (this.markupLock === 'NONE') {
										if (dY !== 0) {
											const markupSlope = Math.abs(dX / dY);
											if (markupSlope < 0.1) {
												this.markupLock = 'VERTICAL';
											} else if (markupSlope > 4) {
												this.markupLock = 'HORIZONTAL';
											} else {
												this.markupLock = 'DIAGONAL';
											}
										}
										this.prevPos = { x: worldX, y: worldY };
									}

									if (this.markupLock === 'HORIZONTAL' && this.prevPos) {
										(this.currentNode as Markup).addPoint(
											worldX,
											this.startPos.y
										);
									} else if (this.markupLock === 'VERTICAL' && this.prevPos) {
										(this.currentNode as Markup).addPoint(
											this.startPos.x,
											worldY
										);
									} else if (this.markupLock === 'DIAGONAL' && this.prevPos) {
										const slope = dY / dX / Math.abs(dY / dX);
										if (Math.abs(dX) > Math.abs(dY)) {
											const y =
												this.startPos.y +
												slope * (worldX - this.startPos.x);
											(this.currentNode as Markup).addPoint(worldX, y);
										} else {
											const x =
												this.startPos.x +
												(1 / slope) * (worldY - this.startPos.y);
											(this.currentNode as Markup).addPoint(x, worldY);
										}
									}
								} else {
									(this.currentNode as Markup).addPoint(worldX, worldY);
								}
								setTimeout(() => {
									rateLimiter = false;
								}, 10);
							}
						}
					} else if (
						this.currentNode?.nodeData.nodeType === 'MARKUP' &&
						this.currentNode
					) {
						this.currentNode?.save();
						this.currentNode = undefined;
					}
					break;
				case 'TEXT':
					if (this.isMouseDown) {
						if (this.textNodeBox) {
							const width = this.endPos.x - this.startPos.x;
							const height = this.endPos.y - this.startPos.y;
							if (width > 25 && height > 25) {
								this.app.viewport.addChild(this.textNodeBox);
								this.textNodeBox.position.x = this.startPos.x;
								this.textNodeBox.position.y = this.startPos.y;
								this.textNodeBox.width = this.endPos.x - this.startPos.x;
								this.textNodeBox.height = this.endPos.y - this.startPos.y;
							}
						}
					}
					break;
				default:
					break;
			}
		}

		switch (this.app.activeTool) {
			case 'SELECT':
				if (!this.app.isPanSelected) {
					this.removeHoverNode();
				}
				break;
			case 'REACTION':
				// if (!this.hoverNode) {
				this.drawHoverNode(worldX, worldY, ENodeType.REACTION, '#FFDB41');
				// }
				// this.hoverNode?.setTransform(worldX, worldY);
				break;
			case 'STICKY_NOTE':
				// if (!this.hoverNode) {
				this.drawHoverNode(worldX, worldY, ENodeType.STICKY_NOTE, '#FFDB41');
				// }
				this.hoverNode?.setTransform(worldX, worldY);
				break;
			default:
				if (this.hoverNode) {
					this.removeHoverNode();
				}
				break;
		}
		// for upload preview
		if (this.app.showFilesHover) {
			if (!this.hoverNode) {
				this.drawHoverNode(worldX, worldY, ENodeType.MODEL, '#FFDB41');
			}
			this.hoverNode?.setTransform(worldX, worldY);
		}
	};

	/**
	 * Handle mouse up
	 * @param e
	 */
	onMouseUp = async (e: any) => {
		const { x: worldX, y: worldY } = this.app.viewport.toLocal(e.data.global);

		this.app.canvasEditing = true;
		if (e.data.button === 1) {
			this.app.isPanSelected = false;
			if (this.app.activeTool !== 'SELECT') this.app.pauseDragPlugin();
			this.app.setActiveTool(this.app.activeTool);
			this.app.throughPanTool = false;
		}
		if (this.app.isPanSelected) return;
		this.isMouseDown = e.data.buttons === 1;

		switch (this.app.activeTool) {
			case 'SELECT':
				// this.app.highlighting = false;
				// this.app.resetInteractivity()
				// this.app.checkIfAnyNodeIsWithinBounds(this.currentNode?.nodeData.absoluteBounds)
				// this.app.viewport.removeChild(this.currentNode?.displayObject as DisplayObject)
				// this.currentNode = undefined
				break;
			case 'SHAPE':
			case 'MARKUP':
				if (this.currentNode && this.currentNode.nodeData)
					this.currentNode.nodeData._id = uuidv1() as string;
				this.currentNode?.save();
				this.currentNode = undefined;
				this.markupLock = 'NONE';
				break;
			case 'TEXT':
				if (this.textNodeBox) {
					const zoomLevel = this.app.viewport.scale._x;
					const textNodeBoxBounds = this.textNodeBox.getBounds();
					const defaultData: INode = {
						_id: uuidv1(),
						nodeType: 'TEXT',
						isVisible: true,
						zIndex: this.app.getNewZindex(undefined, false, true),
						absoluteBounds: {
							x:
								this.textNodeBox.position.x === 0
									? worldX
									: this.textNodeBox.position.x,
							y:
								this.textNodeBox.position.y === 0
									? worldY
									: this.textNodeBox.position.y,
							width:
								this.textNodeBox.position.x === 0
									? textNodeBoxBounds.width
									: textNodeBoxBounds.width / zoomLevel,
							height:
								this.textNodeBox.position.y === 0
									? textNodeBoxBounds.height
									: textNodeBoxBounds.height / zoomLevel
						},
						text: {
							value: '',
							style: {
								fill: '#000000',
								align: 'left',
								fontSize: 16,
								fontWeight: 'normal'
							}
						},
						version: 0
					};
					const textNodeData = {
						...defaultData
					};
					this.app.viewport.removeChild(this.textNodeBox);
					this.currentNode = new Text(this.app, textNodeData);
					await this.currentNode.save();
					// this.app.toggleCanvasEditMenu(false);
					const currentTextNode = this.app.allNodes?.find(
						(an) => an.nodeData._id === defaultData._id
					);
					currentTextNode?.draw();
					this.app.selectTool('SELECT', {});
					currentTextNode?.selectNode();
					currentTextNode?.onEditStart();
					// this.currentNode.selectNode();
					// this.currentNode.onEditStart();
					e.stopPropagation();
					this.currentNode = undefined;
					this.textNodeBox = undefined;
				}
				break;
			case 'STICKY_NOTE':
				if (this.textNodeBox) {
					const defaultData: INode = {
						_id: uuidv1(),
						nodeType: 'STICKY_NOTE',
						isVisible: true,
						showAuthor: false,
						absoluteBounds: {
							x: worldX - StickyNote.defaultStickyNodeProps.width / 2,
							y: worldY - StickyNote.defaultStickyNodeProps.height / 2
						},
						fill: {
							fillColor: '#FFDB41'
						},
						zIndex: this.app.getNewZindex(undefined, false, true),
						text: {
							value: '',
							style: {
								fill: '#000000',
								align: 'left',
								fontSize: 16,
								fontWeight: 'normal'
							}
						},
						version: 0
					};
					const stickyNoteNode = {
						...defaultData
					};

					this.app.viewport.removeChild(this.textNodeBox);
					this.currentNode = new Text(this.app, stickyNoteNode);
					await this.currentNode.save();

					const currentStickynote = this.app.allNodes?.find(
						(an) => an.nodeData._id === this.currentNode!.nodeData._id
					);
					currentStickynote?.draw();

					this.app.selectTool('SELECT', {});
					currentStickynote?.selectNode();
					currentStickynote?.onEditStart();
					e.stopPropagation();
					this.currentNode = undefined;
					this.textNodeBox = undefined;
				}
				break;
			default:
				this.app.removeTransformer();
				this.app.removeAllSelectedNodes();
				break;
		}
	};

	addNewComment = (e: any) => {
		const { newComment } = store.getState();
		if (this.app.activeTool === 'COMMENT') {
			if (newComment?.data.initialComment) {
				store.dispatch(resetNewCommentData());
			} else {
				const { x } = e.data.global;
				// Subtracting here to adjust the position of new feedback bubble
				// exactly behind bubble cursor
				const y = e.data.global.y - 27;
				const { x: worldX, y: worldY } = this.app.viewport.toLocal({ x, y });
				const data: IFeedback = {
					absoluteBounds: { x: worldX as number, y: worldY as number },
					statement: ''
				};

				// If initial type exists in redux update it in new comment data
				if (newComment.data.initialType) data.feedbackType = newComment.data.initialType;
				store.dispatch(addNewCommentData(data));
			}
		}
	};
}

export default Frame;
