export type SharingModalProps = {
	open: boolean;
	selectedTab: keyof typeof ESharingTabs;
	sharingDetails: {
		projectId: string;
		phaseId: string | undefined;
		blockId: string | undefined;
	};
	onClose: () => void;
	variant?: 'DEFAULT' | 'WITH_TABS';
};

export enum ESharingTabs {
	PROJECT = 'PROJECT',
	PHASE = 'PHASE',
	BLOCK = 'BLOCK',
};