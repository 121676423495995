// import boldIcon from "src/assets/icons/toolbar/bold_icon.svg";
// import italicsIcon from "src/assets/icons/toolbar/italics_icon.svg";
// import underlineIcon from "src/assets/icons/toolbar/underline_icon.svg"

const fontFormatTypes = {
  bold: {
    palceholder: 'Bold',
    className: 'boldOption',
    text: 'B',
    cmd: 'bold',
    htmlTag: 'b',
    value: 'bold'
  },
  italic: {
    palceholder: 'Italics',
    className: 'italicsOption',
    text: 'i',
    cmd: 'italic',
    htmlTag: 'i',
    value: 'italic'
  },
  underline: {
    palceholder: 'Underline',
    className: 'underlineOption',
    text: 'U',
    cmd: 'textDeco',
    htmlTag: 'u',
    value: 'underline'
  },
};

export default fontFormatTypes;
