import _ from 'lodash';
import { filterTextNodeStyles } from '../../utils/helper';

export const trixInitialize = () => {
  // Change Trix.config if you need
  // @ts-ignore
  const { textAttributes } = window.Trix.config;
  // @ts-ignore
  const { blockAttributes } = window.Trix.config;
  textAttributes.foreColor = {
    styleProperty: 'color',
    inheritable: true,
  };
  textAttributes.textDeco = {
    styleProperty: 'text-decoration',
    inheritable: true,
  };
  textAttributes.fontSize = {
    styleProperty: 'font-size',
    inheritable: true,
  };
  textAttributes.fontFamily = {
    styleProperty: 'font-family',
    inheritable: true,
  };
  textAttributes.bold = {
    styleProperty: 'font-weight',
    inheritable: true,
  };
  textAttributes.italic = {
    styleProperty: 'font-style',
    inheritable: true,
  };
  blockAttributes.leftAlign = {
    tagName: 'leftalign',
  };
  blockAttributes.rightAlign = {
    tagName: 'rightalign',
  };
  blockAttributes.centerAlign = {
    tagName: 'centeralign',
  };
};

export const trixSelectionChange = () => {
  if (!window.pixiApp) return;
  const app = window.pixiApp;
  const trixNode = app.textInputNode._domInput as any;
  if (trixNode) {
    const [start, end] = trixNode.editor.getSelectedRange();
    // @ts-ignore
    if (start !== end && _.isEmpty(trixNode?.editorController.composition.currentAttributes)) {
      const propertyArray = [];
      for (let i = start; i < end; i++) {
        if (!_.isEmpty(trixNode.editorController.composition.getBlock().text.getAttributesAtPosition(i))) {
          const attributes = trixNode.editorController.composition.getBlock().text.getAttributesAtPosition(i);
          if (attributes.fontSize) {
            const newFontSize = parseInt(attributes.fontSize.match(/[0-9]+/)![0]!, 10);
            attributes.fontSize = `${Math.ceil(newFontSize / app.viewport.scale.x)}px`;
          }
          propertyArray.push(attributes);
        } else {
          const nodeData = app.selectedNodes[0]?.nodeData;
          if (nodeData) {
            const existingFontSize = nodeData?.text?.style?.fontSize || 16;
            propertyArray.push({
              foreColor: '#000000',
              fontSize: `${existingFontSize}px`,
              fontFamily: 'Rand-Regular',
            });
          }
        }
      }
      app.onTextSelectionChange(propertyArray);
    } else {
      const activeProperties = trixNode?.editorController.composition.currentAttributes;
      app.onTextSelectionChange([activeProperties]);
    }
  }
};

export const trixPaste = () => {
  if (!window.pixiApp) return;
  const app = window.pixiApp;
  // @ts-ignore
  const trixEditor = app.textInputNode._domInput.editor;
  const trixDoc = trixEditor.getDocument();
  ['bullet', 'number'].forEach((attr: string) => {
    const ranges = trixDoc.findRangesForBlockAttribute(attr);
    if (ranges && ranges.length > 0) {
      ranges.forEach((r: any) => {
        trixEditor.setSelectedRange(r);
        trixEditor.deactivateAttribute(attr);
      });
    }
  });
  trixEditor.setSelectedRange(0);
  const domParser = new DOMParser();
  const pasteDoc = domParser.parseFromString(app.textInputNode._domInput.innerHTML, 'text/html');
  const pasteNode = pasteDoc.body;
  filterTextNodeStyles(pasteNode);
  app.textInputNode._domInput.innerHTML = pasteDoc.body.innerHTML;
};
