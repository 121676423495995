// Import the functions you need from the SDKs you need

import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseStorageConfig = {
	apiKey: process.env.REACT_APP_FS_API_KEY,
	authDomain: process.env.REACT_APP_FS_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FS_PROJECT_ID,
	storageBucket: window.__RUNTIME_CONFIG__.REACT_APP_FS_STORAGE_BUCKET || 'naya-uploads-dev',
	messagingSenderId: process.env.REACT_APP_FS_MESSAGIN_SENDER_ID,
	appId: process.env.REACT_APP_FS_APP_ID,
	measurementId: process.env.REACT_APP_FS_MEASUREMENT_ID
};

// // Initialize Firebase
// export const firebaseStorage = firebase.initializeApp(firebaseStorageConfig, "secondary");

// export const storage = firebase.storage();

export const firebaseStorage = initializeApp(firebaseStorageConfig, 'secondary');

export const storage = getStorage(firebaseStorage);
