import { IGroup, IBlock, ILink, EBlockType, IFile, I3D } from '@naya_studio/types';
import axios from 'axios';
import saveAs from 'file-saver';
import JSZip from 'jszip';
import {
	PROXY_API,
	SessionStorageKeys,
	htmlToPdf,
	convertHtmlToImagePdf,
	isThumbnailColorString
} from '@naya_studio/radix-ui';
import { useCallback, useEffect, useRef, useState } from 'react';
import { cleanFileName } from 'src/util/collaboration/util';
import { TLDFlags } from '../CollaborationTool.types';

// const folderStructure = {
// 	// project level
// 	name: 'Project',
// 	type: 'FOLDER',
// 	children: [
// 		// Phase level
// 		{
// 			name: 'Phase 1',
// 			type: 'FOLDER',
// 			children: [
// 				// Block level
// 				{
// 					name: 'File 1',
// 					type: 'FILE',
// 					link: 'https://naya.studio'
// 				},
// 				{
// 					name: 'File 2',
// 					type: 'LINK',
// 					link: 'https://naya.studio'
// 				}
// 			]
// 		},
// 		{
// 			name: 'Phase 2',
// 			type: 'FOLDER',
// 			children: [
// 				{
// 					name: 'File 1',
// 					type: 'FILE',
// 					link: 'https://naya.studio'
// 				},
// 				{
// 					name: 'File 2',
// 					type: 'LINK',
// 					link: 'https://naya.studio'
// 				}
// 			]
// 		}
// 	]
// };

type DownloadAndZipData = {
	// Name of the file/folder
	name: string;
	// Asset type
	type: 'FILE' | 'FOLDER' | 'LINK' | 'PDF';
	// data of asset, it could be link for a asset block and id for text block
	data?: string;
	children?: DownloadAndZipData[];
	// Total no of files in the folder
	totalFiles?: number;
	// Block type of the file
	blockType?: keyof typeof EBlockType;
};

type DownloadAndZipProgress = {
	status?: 'ESTIMATING' | 'DOWNLOADING' | 'PACKING' | 'DOWNLOADED' | 'COMPLETED' | 'FAILED';
	// Default message
	message?: string;
	reason?: 'SOMETHING_WENT_WRONG' | 'INTERNET_CONNECTION_LOST';
	downloadData?: {
		downloadedFiles?: number;
		totalFiles?: number;
		totalSize?: number;
		downloadedSize?: number;
	};
	zipData?: JSZip.JSZipMetadata;
};

/**
 * Function to get the file name based on block type
 * @param name {string} Block name
 * @param type {string} Block type
 * @returns {string} Block name
 */
const getFileName = (name: string, type: keyof typeof EBlockType, fileName?: string) => {
	if (['THREE_D', 'FILE', 'IMAGE', 'PDF', 'VIDEO'].includes(type)) {
		// If the block name and file name is same or user has not changed the extension return the block name
		if (
			name === fileName ||
			name.split('.').pop()?.toLocaleLowerCase() ===
				fileName?.split('.').pop()?.toString().toLocaleLowerCase()
		)
			return name;
		return `${name.trimEnd()}.${fileName?.split('.').pop()?.toString().toLocaleLowerCase()}`;
	}
	if (type === EBlockType.CANVAS || type === EBlockType.EXCALIDRAW_CANVAS) {
		// Save canvas thumbnail in jpg format
		return `${name}.jpg`;
	}
	if (type === EBlockType.TEXT || type === EBlockType.TODO) return `${name}.pdf`;
	// Save links in html files
	return `${name}.html`;
};

/**
 * Function to convert Bytes to MB
 * @param size number in Bytes
 * @returns number - size in MB
 */
export const convertBytesToMb = (size: number) => size / (1024 * 1024);

/**
 * Function to check the duplicate file/folder name
 * @param name File/Folder name
 * @param data Object with key as File/Folder name and value as number
 * @returns {number} Duplicate count
 */
const getDuplicateCount = (name: string, data: { [key: string]: number }) => {
	if (data[name as string] === undefined) {
		data[name as string] = 0;
	} else {
		data[name as string]++;
	}
	return data[name as string] || 0;
};

/**
 * Function to get file download type
 * @param blockType block type of file
 * @returns type of download
 */
const getDownloadType = (blockType: keyof typeof EBlockType): DownloadAndZipData['type'] => {
	if (blockType === 'LINK') return 'LINK';
	if (blockType === 'TEXT' || blockType === 'TODO') return 'PDF';

	return 'FILE';
};

/**
 * Function to format the data required to download and create zip
 * @param stages { [id: string]: Partial<IGroup> }
 * @param blocks [key: string]: IBlock
 * @param rootName Name of the root folder
 * @returns DownloadAndZipData | undefined
 */
export const getDownloadAndZipData = (
	stages: { [id: string]: Partial<IGroup> },
	blocks: { [key: string]: IBlock },
	rootName: string,
	parentId: string,
	flags: TLDFlags,
	isRoot = true,
	rootChildren?: string[],
	filterTypes?: (keyof typeof EBlockType)[]
): DownloadAndZipData | undefined => {
	/** stores the phase names along with duplicate count */
	const phaseNames: { [key: string]: number } = {};
	/** Total number of files */
	let totalFiles = 0;
	/** Formatted phases as per DownloadAndZipData */
	const formattedPhases: DownloadAndZipData[] = [];

	const getFolderName = (index: number, name?: string) =>
		`${(index + 1).toString().padStart(3, '0')}_${cleanFileName(name || 'Untitled')}`;

	rootChildren?.forEach((id, index) => {
		const phase = stages[id];
		if (!phase) return;
		if (parentId !== phase.parentId) return;
		/** stores the phase names along with duplicate count */
		const blockNames: { [key: string]: number } = {};
		/** Formatted blocks as per DownloadAndZipData */
		const children: DownloadAndZipData[] = [];

		phase.children?.forEach((childId, childIndex) => {
			const block = blocks[childId as string];
			if (!block && childId) {
				const nestedGroup = stages[childId.toString()];
				if (nestedGroup && phase._id && nestedGroup._id) {
					const nestedFolderData = getDownloadAndZipData(
						stages,
						blocks,
						nestedGroup.name || `Untitled ${childIndex}`,
						phase._id.toString(),
						flags,
						false,
						[nestedGroup._id.toString()]
					);
					if (nestedFolderData && nestedFolderData.children) {
						totalFiles += nestedFolderData.children.length;
						nestedFolderData.name = getFolderName(childIndex, nestedGroup.name);
						children.push(nestedFolderData);
					}
				}
			}
			const isEmpty = !block || block.blockType === 'EMPTY';
			if (isEmpty) return;

			if (block.password && flags.isPasswordProtectionEnabled) {
				const storedValue = sessionStorage.getItem(SessionStorageKeys.VERIFIED_BLOCK_IDS);
				const blockIds: string[] = storedValue ? JSON.parse(window.atob(storedValue)) : [];
				if (!blockIds.includes(block._id as string)) return;
			}

			const isFilteredIgnored =
				filterTypes && !filterTypes.includes(block.blockType as keyof typeof EBlockType);
			const hasValidThumbnail =
				(block.thumbnail && !isThumbnailColorString(block.thumbnail.src)) ||
				(block as ILink).link;
			const isText = block.blockType === 'TEXT' || block.blockType === 'TODO';

			if (isFilteredIgnored) return;
			if (hasValidThumbnail || isText) {
				let data: string | undefined;
				// Get the download link
				switch (block.blockType) {
					case 'THREE_D': {
						const [modelLink] = (block as I3D).link;
						data = modelLink;
						break;
					}
					case 'CANVAS':
					case 'EXCALIDRAW_CANVAS': {
						data = isThumbnailColorString(block.thumbnail?.src)
							? undefined
							: block.thumbnail?.src;
						break;
					}
					case 'TEXT':
					case 'TODO': {
						data = block._id as string;
						break;
					}
					default: {
						data = (block as IFile).link;
					}
				}
				if (!data) return;
				// Name of the file with out any special characters
				let name = cleanFileName(
					getFileName(
						(block as IFile).name || (block as IFile).fileName || 'Untitled',
						block.blockType,
						(block as IFile).fileName || 'Untitled'
					),
					true
				);
				// Duplicate name count
				const duplicateCount = getDuplicateCount(name as string, blockNames);

				if (duplicateCount !== undefined && duplicateCount > 0) {
					// Adding duplicate count to the file name eg:- Screenshot (2).png
					const splittedName = name.split('.');
					name = `${splittedName
						.slice(0, splittedName.length - 1)
						.toString()}(${duplicateCount}).${splittedName.slice(-1).toString()}`;
				}
				totalFiles++;
				children.push({
					name,
					data,
					type: getDownloadType(block.blockType),
					blockType: block.blockType
				});
			}
		});
		if (!phase.children?.length) return;
		// Name of the folder with out any special characters
		const name = getFolderName(index, phase.name);
		// Duplicate name count
		const duplicateCount = getDuplicateCount(name as string, phaseNames);
		if (children.length) {
			formattedPhases.push({
				name:
					duplicateCount !== undefined && duplicateCount > 0
						? `${name}(${duplicateCount})`
						: name,
				type: 'FOLDER',
				children,
				totalFiles: children.length
			});
		}
	});
	if (!isRoot) {
		return formattedPhases[0];
	}
	return {
		name: rootName,
		children: formattedPhases,
		totalFiles,
		type: 'FOLDER'
	} as DownloadAndZipData;
};

/**
 * Function to check the proxy link requirement
 * @param link string
 * @returns Link with a proxy if required
 */
const checkProxyRequirement = (link: string) =>
	link.includes('firebasestorage.googleapis.com') ? link : PROXY_API + link;

/**
 * Function to download the given assets and create a zip folder of them
 * @param data DownloadAndZipData
 * @returns DownloadAndZipProgress
 */
export const useDownloadAndZip = () => {
	// Holds DownloadAndZipProgress data
	const [progress, setProgress] = useState<DownloadAndZipProgress>({});

	// Used to determine whether to stop the process or not
	const shouldStop = useRef<boolean>(false);
	// Axios req cancelation token to cancel the ongoing requests
	const reqCancel = useRef(axios.CancelToken.source());
	/**
	 * Holds boolean values that determines whether the process has completed or not
	 */
	const processCompleted = useRef<boolean>(false);

	/**
	 * Function to abort the process
	 */
	const abortController = () => {
		shouldStop.current = true;
		reqCancel.current.cancel();
	};

	/**
	 * Function to start the download and zip process
	 * @return Promise<Blob | undefined>
	 */
	const startDownloadAndZip = useCallback(async (data: DownloadAndZipData | undefined) => {
		setProgress({ message: 'Downloading files – estimating size.', status: 'ESTIMATING' });
		let countOfDownloadingFiles = 0;
		// Holds total no of files downloaded
		let downloadedFiles = 0;
		// Total of size of the download
		let totalSize = 0;
		// Total downloaded size
		let loadedSize = 0;
		// Total no of files to be downloaded
		const totalFiles = data?.totalFiles || 0;
		// Zip instance
		const zip = new JSZip();

		/**
		 * Function to download assets and create folders recursively
		 * @param rootFolder DownloadAndZipData[] | DownloadAndZipData
		 * @param folder JSZip | null - to create the folders
		 * @returns Promise<any>
		 */
		const downloadFilesAndCreateFolders = async (
			rootFolder: DownloadAndZipData[] | DownloadAndZipData,
			folder: JSZip | null
		): Promise<Blob | undefined> => {
			// If the rootFolder type is array
			if (Array.isArray(rootFolder)) {
				await Promise.all(
					// Tried adding return undefined, but that led to some other issue. So disabled it.
					// eslint-disable-next-line consistent-return
					rootFolder.map(async (subItem) => {
						// If subitem type is folder
						if (subItem.type === 'FOLDER' && folder && subItem.children) {
							// Recursively create folders and files for sub-folders
							return downloadFilesAndCreateFolders(
								subItem.children,
								folder.folder(subItem.name)
							);
						}
						try {
							// Holds file size and file loaded size
							let fileSize = 0;
							let fileLoaded = 0;
							/**
							 * If type === FILE
							 *      download the file and return the block
							 * Else (if type is LINK)
							 *      create html code to redirect to the original link on opening the webFile
							 */
							let fileData = null;

							if (subItem.type === 'FILE' && subItem.data) {
								fileData = (
									await axios.get(checkProxyRequirement(subItem.data), {
										responseType: 'blob',
										cancelToken: reqCancel.current.token,
										onDownloadProgress: (event) => {
											loadedSize += event.loaded - fileLoaded;
											fileLoaded = event.loaded;
											if (!fileSize) {
												totalSize += event.total;
												fileSize = event.total;
												++countOfDownloadingFiles;
											}
											if (countOfDownloadingFiles === totalFiles)
												setProgress((prev) => ({
													...prev,
													status: 'DOWNLOADING',
													downloadData: {
														...prev.downloadData,
														downloadedSize: loadedSize,
														totalSize
													},
													message: `Downloading files - ${(
														loadedSize /
														(1024 * 1024)
													).toFixed(2)}mb of ${(
														totalSize /
														(1024 * 1024)
													).toFixed(2)}mb`
												}));
										}
									})
								).data;
							} else if (subItem.type === 'LINK') {
								fileData = new Blob(
									[
										`<html>
											<head>
												<title>${subItem.name}</title>
												<script>window.location="${subItem.data}"</script>
											</head>
											<body></body>
										</html>`
									],
									{ type: 'text/html' }
								);
							} else if (subItem.type === 'PDF') {
								const parentBlock = document.querySelector<HTMLDivElement>(
									`[data-blockid='${subItem.data}']`
								);
								const editor = parentBlock?.querySelector(
									subItem.blockType === 'TEXT' ? '.editor' : '.todo-container'
								) as HTMLElement;

								if (subItem.blockType === 'TEXT') {
									const clonedEditor = editor.cloneNode(true) as HTMLDivElement;
									// Expand the cloned editor to prevent data wrapping in generated pdf
									clonedEditor.style.width = '80vw';
									clonedEditor.style.height = '80vh';
									clonedEditor.style.position = 'absolute';

									fileData = await htmlToPdf(
										clonedEditor,
										`${subItem.name}.pdf`,
										true
									);
								} else
									fileData = await convertHtmlToImagePdf(
										editor,
										`${subItem.name}.pdf`,
										true
									);
							}

							if (subItem.type === 'LINK' || subItem.type === 'PDF')
								++countOfDownloadingFiles;
							++downloadedFiles;
							setProgress((prev) => ({
								...prev,
								downloadData: { ...prev.downloadData, downloadedFiles }
							}));
							// Add the fileData to the respective folder
							if (folder && fileData) {
								folder.file(subItem.name, fileData, { binary: true });
							}
						} catch (error) {
							++countOfDownloadingFiles;
							console.error(error);
						}
					})
				);
				return undefined;
			}
			if (rootFolder && rootFolder.children) {
				return downloadFilesAndCreateFolders(rootFolder.children, folder);
			}
			return undefined;
		};

		if (data && data.children) {
			try {
				await downloadFilesAndCreateFolders(data.children, zip);
				if (shouldStop.current) return;
				if (!navigator.onLine) {
					processCompleted.current = true;
					throw new Error('Please check the internet connection and try again.');
				}
				// Generate the zip folder asynchronously
				zip.generateAsync({ type: 'blob' }, (zipData) => {
					if (zipData.percent !== progress.zipData?.percent)
						setProgress({
							status: 'PACKING',
							zipData,
							message: `Packing downloaded files - ${zipData.percent.toFixed(2)}%`
						});
				}).then((content) => {
					if (content && !shouldStop.current) {
						// Download the zip folder
						saveAs(content, data.name);
						processCompleted.current = true;
						let message = 'File download complete!';
						if (downloadedFiles - totalFiles !== 0) {
							message = `File download complete! Unable to download ${
								totalFiles - downloadedFiles
							} files.`;
						}
						setProgress({ status: 'COMPLETED', message });
					}
				});
			} catch (error: any) {
				console.error(error);
				processCompleted.current = true;
				setProgress({
					status: 'FAILED',
					message: `Unable to download files. - ${
						error.message ? error.message : 'Please try again.'
					}`,
					reason: 'SOMETHING_WENT_WRONG'
				});
			}
		}
	}, []);

	// Runs on mount
	useEffect(() => {
		// Function to handle offline/internet disconnection
		const handleOffline = () => {
			processCompleted.current = true;
			setProgress({
				status: 'FAILED',
				message:
					'Unable to download files. Please check the internet connection and try again.',
				reason: 'INTERNET_CONNECTION_LOST'
			});
			reqCancel.current.cancel();
			shouldStop.current = true;
		};
		// Function to handle beforeunload
		const handleBeforeUnload = (event: BeforeUnloadEvent) => {
			if (processCompleted.current) return true;
			// Display a warning message to the user
			const message = 'A download is in progress. Are you sure you want to leave the page?';
			event.returnValue = message; // Standard for most browsers
			return message; // For some older browsers
		};
		window.addEventListener('offline', handleOffline);
		window.addEventListener('beforeunload', handleBeforeUnload);
		// On unmount stop the process
		return () => {
			reqCancel.current.cancel();
			shouldStop.current = true;
			window.removeEventListener('offline', handleOffline);
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, []);

	return { progress, startDownloadAndZip, abortController };
};
