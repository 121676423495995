import { EFeedbackType, IBlock, IFeedback, TUserAndRole } from '@naya_studio/types';
import { useContext, useEffect, useState } from 'react';
import { isUndefined } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'src/redux/reducers/root.types';
import { getAllUsersBasedOnIds } from 'src/redux/reduxActions/util';
import { ReactComponent as AlertIcon } from 'src/assets/icons/sharing-modal/alert.svg';
import { FeedbackBubble, FeedbackWrapper, WarningModal } from '@naya_studio/radix-ui';
import { PathParamsType } from 'src/types/pathParams';
import { useParams } from 'react-router';
import useFeedbackActions from 'src/util/feedback/useFeedbackActions';
import formatTime from 'src/util/collaboration/util';
import { FeedbackContext } from 'src/components/expandedBlockView/ExpandedBlockView';
import trackEvent from 'src/util/analytics/analytics';
import { FeedbackEvents } from 'src/util/analytics/events';
import { TMarkCompleteEventData } from 'src/util/analytics/analytic.types';
import useUser from 'src/redux/hooks/user';
import { Feedback } from './Feedback';
import { NewReply } from './NewReply';
import { ActionItemPanel } from '../actionItemPanel/ActionItemPanel';
import './Feedback.scss';
import { isActiveUserViewer } from '../util';

/** props for feedback tile */
export type FeedbackTileProps = {
	data: IFeedback;
	// Index of feedback
	index: number;
	// Callback to handle action item panel close
	onClose?: () => void;
};

// Feedback tile - wrapper of feedback
export const FeedbackTile = ({ data, index, onClose }: FeedbackTileProps) => {
	// we need the edit state on parent level so that if any of the comment is in edit state
	// mark complete on action panel can be disabled
	const [parentEditState, setParentEditState] = useState(false);
	// handles the visibility of action item toggle warning
	const [showActionItemWarning, setShowActionItemWarning] = useState(false);
	// handles the toggle of mark complete action
	const [isCompleted, setIsCompleted] = useState(false);
	// holds the list of all the users selected for action item assignees
	const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
	const { user } = useUser();

	const { activeFeedbackId, feedbackView } = useContext(FeedbackContext);

	// extarcting functions from feedback actions hook
	const {
		sendEditFeedback,
		checkIfUserTurnerOffActionItemToggleWarning,
		setNoActionItemToggleWarning,
		removeActionItemAssignee,
		addActionItemAssignee
	} = useFeedbackActions();

	// extracting ids from url
	const { canvasId: blockId, projectId } = useParams<PathParamsType>();

	// extracting current block
	const block = useSelector(
		(state) => (state as ReduxState).blocks.data[blockId],
		shallowEqual
	) as IBlock;

	// extract all the user ids of the current block users
	const userIds: string[] = (block?.users as TUserAndRole[])?.map(
		(u: TUserAndRole) => u.user as string
	);

	// to check if user is viewer or not
	const isViewer = isActiveUserViewer(user, block?.users as TUserAndRole[]);

	// extracting all the populated users based on ids
	const allUsers = getAllUsersBasedOnIds(userIds);

	const dispatch = useDispatch();

	// setting data to state variabkes
	useEffect(() => {
		if (data.actionItemFor) {
			if (!isUndefined(data.actionItemFor[user?._id as string])) {
				setIsCompleted(data.actionItemFor[user._id as string] as boolean);
			}

			const allActionItemUsers = Object.keys(data.actionItemFor);
			setSelectedUsers(allActionItemUsers);
		}
	}, [data]);

	// sends out an API call to mark complete
	const onMarkComplete = (val: boolean) => {
		setIsCompleted(val);

		if (val) {
			// Track marking feedback complete event
			const feedbackEventProperties: TMarkCompleteEventData = {
				activityType: data.feedbackType as EFeedbackType,
				blockType: block.blockType
			};
			trackEvent(FeedbackEvents.MARK_TASK_COMPLETE, feedbackEventProperties);
		}

		dispatch({
			type: 'MARK_FB_NOTIF_COMP',
			payload: { markFeedbackAsComplete: data._id, isCompleted: val }
		});
	};

	// send out an api call to save the action item value
	const saveActionItemToggle = (val: boolean, noWarningCheck: boolean) => {
		// closing the modal if open
		if (showActionItemWarning) {
			setShowActionItemWarning(false);
		}

		// dispatch action
		sendEditFeedback({
			_id: data._id,
			isActionItem: val
		});

		if (noWarningCheck) {
			setNoActionItemToggleWarning(projectId);
		}
	};

	// sends out an api call to toggle action item/opens the warning modal based upon toggle value
	const toggleActionItem = (val: boolean) => {
		if (!val && !checkIfUserTurnerOffActionItemToggleWarning(projectId)) {
			setShowActionItemWarning(true);
		} else {
			saveActionItemToggle(val, false);
		}
	};

	// Sends out an api call updated selected users for action item
	const onModifySelectedUsers = (id: string) => {
		let tempUsers = selectedUsers;
		if (selectedUsers.includes(id)) {
			tempUsers = tempUsers.filter((x) => x !== id);

			removeActionItemAssignee(data._id as string, id);
		} else {
			tempUsers.push(id);

			addActionItemAssignee(data._id as string, id);
		}
		setSelectedUsers(tempUsers);
	};

	/** Function to check if the feedback is marked completed or not */
	const checkIfCompleted = () => {
		if (data.actionItemFor) {
			if (data.actionItemFor[user?._id as string]) {
				return true;
			}
		}
		return false;
	};

	return (
		<FeedbackWrapper className="tw-bg-white">
			<>
				<WarningModal
					show={showActionItemWarning}
					onClose={() => setShowActionItemWarning(false)}
					onModalSubmit={(modalData: { isDaaChecked: boolean }) => {
						saveActionItemToggle(!toggleActionItem, modalData.isDaaChecked);
					}}
					title="Turn off action item?"
					bodyText={
						<div className="warning-model">
							{' '}
							<AlertIcon />{' '}
							<span>
								The following task will be removed as an action item for all the
								users in this project?. Continue?
							</span>
						</div>
					}
					cancelBtnText={"Don't remove"}
					submitBtnText="Turn off action item"
					showDAA
				/>
				{/* render action item panel */}
				<ActionItemPanel
					markCompleteDisabled={parentEditState || isViewer}
					isCompleted={isCompleted}
					onMarkComplete={onMarkComplete}
					users={allUsers}
					selectedUsers={selectedUsers}
					onModifySelectedUsers={onModifySelectedUsers}
					onClose={() => {
						if (onClose) onClose();
					}}
				>
					{/* This bubble & time stamp is shown in actionItemPanel of feedback inside feedback panel */}
					{feedbackView === 'PANEL_VIEW' && (
						<>
							<FeedbackBubble
								isNew={!data._id}
								index={index}
								isCompleted={checkIfCompleted()}
								key={data._id as string}
								onClick={() => {}}
								isActionItem={data.isActionItem as boolean}
								feedbackType={data.feedbackType as EFeedbackType}
							/>
							{/* Shows the feedback added time in HH:MM:SS */}
							{block?.blockType === 'VIDEO' && (
								<div className="time-stamp">
									<span className="tw-px-2 tw-py-1">time</span>
									<span className="tw-px-2 tw-py-1 tw-rounded">
										{formatTime(data.timeStamp || 0)}
									</span>
								</div>
							)}
						</>
					)}
				</ActionItemPanel>
				<div id="chat-container" className="tw-pl-2 tw-pt-2 tw-mr-2">
					{/* This is added to collapse and expand the feedback in feedback panel.
					 *  In collapse mode only last reply will be shown.
					 */}
					{activeFeedbackId === data._id || feedbackView === 'DEFAULT' ? (
						<>
							{/* render parent chat */}
							<Feedback
								data={data}
								setParentEditState={setParentEditState}
								isParent
								actionItemAssignees={selectedUsers}
								setActionItemAsignees={setSelectedUsers}
							/>
							{(data.replies as IFeedback[]).length > 0 && (
								<hr className="tw-mb-2 tw-mx-2" />
							)}
							{/* render replies */}
							{data.replies &&
								(data.replies as IFeedback[]).map((reply: IFeedback, i: number) => (
									<>
										<Feedback
											data={reply}
											setParentEditState={setParentEditState}
											isParent={false}
											parentId={data._id as string}
											actionItemAssignees={selectedUsers}
											setActionItemAsignees={setSelectedUsers}
										/>
										{i !== (data.replies as IFeedback[]).length - 1 && (
											<hr className="tw-mb-2 tw-mx-2" />
										)}
									</>
								))}
						</>
					) : (
						<>
							{data.replies && data.replies.length === 0 && (
								<Feedback
									data={data}
									setParentEditState={setParentEditState}
									isParent
									actionItemAssignees={selectedUsers}
									setActionItemAsignees={setSelectedUsers}
								/>
							)}
							{data.replies && data.replies.length > 0 && (
								<Feedback
									data={data.replies[data.replies.length - 1] as IFeedback}
									setParentEditState={setParentEditState}
									isParent={false}
									parentId={data._id as string}
									actionItemAssignees={selectedUsers}
									setActionItemAsignees={setSelectedUsers}
								/>
							)}
						</>
					)}
					{/* render new reply input */}
				</div>
				{(activeFeedbackId === data._id || feedbackView === 'DEFAULT') && (
					<div className="tw-px-4 tw-pb-4">
						<hr className="tw-mb-0 tw-mt-0" />
						{(!isViewer || block.hasGuestAccess) && (
							<NewReply
								parentId={data._id as string}
								actionItemAssignees={selectedUsers}
								setActionItemAsignees={setSelectedUsers}
								parentFeedback={data}
							/>
						)}
					</div>
				)}
			</>
		</FeedbackWrapper>
	);
};

FeedbackTile.defaultProps = {
	onClose: () => {}
};
