import { FC, ReactElement, useState, useEffect } from 'react';
import './ProfileCard.scss';
// import { IUser, IUserDetailNode } from "@naya_studio/types";
import { IUserDetail, IUserDetailNode } from '@naya_studio/types';
// import ToggleButton from 'src/components/utilities/customToggleButtom/ToggleButton';
import dummyUserIcon from 'src/assets/icons/dummy-user.svg';
// import { store } from 'src';
// import { CustomDispatch } from 'src/redux/actions/types';
// import { addUserDetail } from 'src/redux/actions/user';
import useUser from 'src/redux/hooks/user';
import { useHistory } from 'react-router';
import EditProfile from './editProfile/EditProfile';
import { ProfileCardProps } from './ProfileCard.types';
import AccountDetails from '../../accountDetails/AccountDetails';

const ProfileCard: FC<ProfileCardProps> = (): ReactElement => {
	// const [openToWorkDesign, toggleOpenToWorkDesign] = useState(false);
	const [showEditProfileModal, setShowEditProfileModal] = useState(false);
	// const [openToWorkFabrication, toggleOpenToWorkFabrication] = useState(false);
	const { user } = useUser();
	const history = useHistory();

	useEffect(() => {
		const allUserDetails = user.userDetails as IUserDetailNode[];
		for (let i = 0; i < allUserDetails.length; i++) {
			if (allUserDetails[i]?.userType === 'DESIGNER' && allUserDetails[i]?.openToWork) {
				// toggleOpenToWorkDesign(true);
			} else if (
				allUserDetails[i]?.userType === 'FABRICATOR' &&
				allUserDetails[i]?.openToWork
			) {
				// toggleOpenToWorkFabrication(true);
			}
		}
	}, [user]);

	/**
	 * returns firstName (if exists) else username
	 */
	const getUserName = () => user.firstName || user.userName;

	const getUserDetails = () => {
		const allUserDetails = user.userDetails as IUserDetailNode[];
		if (allUserDetails[0]) return allUserDetails[0].details as IUserDetail;
		return undefined;
	};

	// const createUserDetailWithUserType = (type:string) => {
	//   const userDetailsPayload = {
	//     userType: type,
	//     openToWork: true,
	//   } as IUserDetailNode;
	//   (store.dispatch as CustomDispatch)(
	//     addUserDetail(userDetailsPayload, () => {

	//     }),
	//   );
	// };

	// const toggleOpenToWork = (type:string) => {
	//   const allUserDetails = user.userDetails as IUserDetailNode[];
	//   if (allUserDetails.length < 2) {
	//     createUserDetailWithUserType(type);
	//   } else {
	//     let exists = false;
	//     for (let i = 1; i < allUserDetails.length; i++) {
	//       if (allUserDetails[i]!.userType === type) {
	//         const payload = {
	//           userDetailsId: allUserDetails[i]?._id as string,
	//           update: {
	//             openToWork: !allUserDetails[i]?.openToWork,
	//           },
	//         };
	//         (store.dispatch as CustomDispatch)(
	//           editUserDetail(payload, () => {
	//           }),
	//         );
	//         exists = true;
	//       }
	//     }
	//     if (!exists) {
	//       createUserDetailWithUserType(type);
	//     }
	//   }
	// };

	// const checkIfUserTypeIsOpenToWork = (type:string) => {
	//   const allUserDetails = user.userDetails as IUserDetailNode[];
	//   if (allUserDetails.length > 1) {
	//     for (let i = 1; i < allUserDetails.length; i++) {
	//       if (allUserDetails[i]!.userType === type && allUserDetails[i]?.openToWork) {
	//         return true;
	//       }
	//     }
	//   }
	//   return false;
	// };

	return (
		<div id="profile-card">
			{showEditProfileModal && (
				<EditProfile
					show={showEditProfileModal}
					close={() => {
						setShowEditProfileModal(false);
					}}
				/>
			)}
			<div className="profile-pic-container">
				<img
					referrerPolicy="no-referrer"
					src={user && user.profilePic ? user.profilePic : dummyUserIcon}
					alt="profile-pic"
				/>
			</div>
			<div className="user-details">
				<h6>{getUserName()}</h6>
				<AccountDetails />
				<p className={!getUserDetails()?.profession ? 'muted' : ''}>
					{getUserDetails()?.profession ? getUserDetails()?.profession : 'What I do'}
				</p>
				<p className={!getUserDetails()?.timeZone ? 'muted' : ''}>
					{getUserDetails()?.timeZone ? getUserDetails()?.timeZone : 'Local time'}
				</p>
				<p className={!getUserDetails()?.preferredLanguages ? 'muted' : ''}>
					{getUserDetails()?.preferredLanguages
						? getUserDetails()?.preferredLanguages
						: 'Preferred languages'}
				</p>

				<button
					className="primary-btn"
					type="button"
					onClick={() => {
						setShowEditProfileModal(true);
					}}
				>
					Edit your profile
				</button>
				<button
					className="primary-btn"
					type="button"
					onClick={() => {
						history.push('/studio');
					}}
				>
					+ Add more Blocks
				</button>
			</div>
			{/* <div className="work-container">
        <p className="title">Open for work</p>
        <div className="work-options-container">
          <div className="toggle-container" style={{ marginBottom: '1rem' }}>
            <ToggleButton
              selected={checkIfUserTypeIsOpenToWork('DESIGNER')}
              toggleSelected={() => {
                toggleOpenToWork('DESIGNER');
                toggleOpenToWorkDesign(!openToWorkDesign);
              }}
            />
            <p className={checkIfUserTypeIsOpenToWork('DESIGNER') ? 'active-toggle' : ''}>Design</p>
          </div>
          <div className="toggle-container">
            <ToggleButton
              selected={checkIfUserTypeIsOpenToWork('FABRICATOR')}
              toggleSelected={() => {
                toggleOpenToWork('FABRICATOR');
                toggleOpenToWorkFabrication(!openToWorkFabrication);
              }}
            />
            <p className={checkIfUserTypeIsOpenToWork('FABRICATOR') ? 'active-toggle' : ''}>Fabrication</p>
          </div>
        </div>
      </div> */}
		</div>
	);
};

export default ProfileCard;
