import { IntegrationModal } from '@naya_studio/radix-ui';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setShowAppIntegrationModal } from 'src/redux/features/integration';
import { ReduxState } from 'src/redux/reducers/root.types';
import useIntegration from 'src/util/integration/useIntegration';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { integrationOptions } from '../utilities/navbar/utils';

/**
 * Renders a list of app integrations supported
 * Handles its connection / disconnection based on integration auth tokens
 */
const AppIntegrationModal = () => {
	const dispatch = useDispatch();
	const integrations = useSelector((state) => (state as ReduxState).integrations);
	const { isMiroIntegrated, isGoogleIntegrated } = useFlags();

	const showAppIntegrationModal = useSelector(
		(state) => (state as ReduxState).integrations.isIntegrationModalOpen,
		shallowEqual
	);
	const isGoogleConnected = !!integrations?.google?.access_token;
	const isMiroConnected = !!integrations?.miro?.access_token;
	const { toggleAuth } = useIntegration();

	// Function to handle connecting or revoking access to apps
	const onConnectOrRemove = (active: boolean | undefined, name: string) => {
		switch (name) {
			case 'GOOGLE':
				dispatch(toggleAuth(name, active as boolean));
				break;
			case 'MIRO':
				dispatch(toggleAuth(name, active as boolean));
				break;

			default:
				break;
		}
	};

	// List of apps we support
	const appOptions = [
		{
			name: 'GOOGLE',
			title: 'Google',
			icon: integrationOptions.GOOGLE as string,
			isConnected: isGoogleConnected,
			isActive: isGoogleIntegrated
		},
		{
			name: 'FIGMA',
			title: 'Figma',
			icon: integrationOptions.FIGMA as string
		},
		{
			name: 'MIRO',
			title: 'Miro',
			icon: integrationOptions.MIRO as string,
			isConnected: isMiroConnected,
			isActive: isMiroIntegrated
		},
		{
			name: 'ADOBE',
			title: 'Adobe CC',
			icon: integrationOptions.ADOBE as string
		},
		{
			name: 'ONSHAPE',
			title: 'Onshape',
			icon: integrationOptions.ONSHAPE as string
		},
		{
			name: 'MS',
			title: 'Microsoft 365',
			icon: integrationOptions.MS as string
		}
	];

	// List of ai options we support
	const aiOptions = [
		{
			name: 'OPEN_AI',
			title: 'OpenAI',
			icon: integrationOptions.OPEN_AI as string
		},
		{
			name: 'MID_JOURNEY',
			title: 'Midjourney',
			icon: integrationOptions.MID_JOURNEY as string
		},
		{
			name: 'STABILITY_AI',
			title: 'StabilityAI',
			icon: integrationOptions.STABILITY_AI as string
		}
	];

	return (
		<IntegrationModal
			title="Apps & integrations"
			description={`Connect your Naya account with other platforms to create documents 
                right from Naya, edit in the journey, and more.`}
			onClose={() => dispatch(setShowAppIntegrationModal(false))}
			onConnectOrRemove={onConnectOrRemove}
			show={showAppIntegrationModal}
			appOptions={appOptions}
			aiOptions={aiOptions}
		/>
	);
};

export default AppIntegrationModal;
