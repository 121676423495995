import axios from 'axios';

import {
  AxiosResponseWrapper, ThunkDispatchWrapper,
} from './types';
import { CommunityAITypes } from '../reducers/root.types';
import { CommunityAIData } from '../reducers/communityAI/communityAI';

export type CommunityAIPayload = {
  project_materials: string[],
  project_units: number,
  project_budget: number,
  project_dimensions: any
  project_location: any,
};

/**
 *
 * @param payload Display Results using Community AI Tool
 * @returns
 */
export const DisplayAIResults = (payload: CommunityAIPayload, option : string) => async (dispatch: ThunkDispatchWrapper) => {
  try {
    let communityAIData : any = [];
    const url = `https://naya-matchmaking-alg-production-g6ghd2z3bq-ue.a.run.app/${option === 'Designer'
      ? 'designer-match' : 'maker-match'}`;
    const res = await axios.post<AxiosResponseWrapper<CommunityAIData>>(`${url}`, payload);

    // need to handle errror
    if (res.status !== 200) throw new Error('Error creating the prediction');

    if (option === 'Designer') {
      communityAIData = res.data.matched_designers;
    } else communityAIData = res.data.matched_fabricators;

    dispatch({
      type: CommunityAITypes.DISPLAY_AI_RESULTS,
      payload: {
        communityAIData: Object.values(communityAIData),
      },
    });
  } catch (e) {
    dispatch({
      type: CommunityAITypes.EDIT_AI_RESULTS,
      payload: {
        communityAIData: [],
        isAiLoading: false,
      },
    });
  }
};

export const cancelAIResults = () => async (dispatch: ThunkDispatchWrapper) => {
  try {
    dispatch({
      type: CommunityAITypes.EDIT_AI_RESULTS,
      payload: {
        communityAIData: [],
        isAiLoading: false,
      },
    });
  } catch (e) {
    console.log(e);
  }
};

export const toggleCommunitySnackBar = (showSnackBar: boolean) => async (dispatch: ThunkDispatchWrapper) => {
  dispatch({
    type: CommunityAITypes.EDIT_AI_RESULTS,
    payload: {
      isAiLoading: showSnackBar,
    },
  });
};
