import { FC, ReactElement, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import NayaLoader from 'src/components/loader/Loader';
import './AppLoader.scss';
import data from './AppLoaderMessages.json';

// const video = require('src/assets/brand/loader1.webm')

export type AppLoaderProps = {
	progress?: number;
};

const AppLoader: FC<AppLoaderProps> = (props): ReactElement => {
	const [message] = useState(data.messages[Math.floor(Math.random() * data.messages.length)]);
	const { progress } = props;
	return (
		<div id="app-loader">
			<div className="loader-container tw-justify-center">
				<div className="logo-container">
					{/* <video width="600" height="500" autoPlay loop muted>
            <source src={video} type="video/mp4"/>
          </video> */}
					<NayaLoader />
					<div
						style={{
							width: 250,
							marginTop: '-10rem'
						}}
					>
						<ProgressBar now={progress || 1} />
					</div>
				</div>
				<p className="tw-absolute tw-bottom-0 tw-left-[50%] tw-w-max -tw-translate-x-[50%]">
					{message}
				</p>
			</div>
		</div>
	);
};

AppLoader.defaultProps = {
	progress: 0
};

export default AppLoader;
