import { store } from 'src';
import {
	I3D,
	IBlock,
	ICanvas,
	IFile,
	IImage,
	ILink,
	IPdf,
	IGroup,
	IVideo,
	IProject,
	INode,
	IFeedback,
	EBlockType,
	TUserAndRole,
	IUser,
	INotification,
	ISentNotification,
	TInvitedEmail
} from '@naya_studio/types';
import mongoose from 'mongoose';
import { TGenerateOptimisticProjectArg } from 'src/types/argTypes';
import {
	saveProjectInLocalForage,
	saveNodesInLocalForage
} from 'src/util/storage/indexedDBStorage';
import { TGroupParent } from 'src/components/collaborationTool/CollaborationTool.types';
import getUserFromRedux from 'src/util/helper/user';
import { getProjectFromRedux } from 'src/util/helper/project';
import { colors, generateColorArray } from '@naya_studio/radix-ui';
import { getStageAndChildren } from '../actions/util';
import { IBlocksInitState, ReduxState } from '../reducers/root.types';

/**
 * Function to extract nodes based on the block type
 * @param block
 * @returns
 */
export const getNodesBasedOnBlockType = (block: IBlock) => {
	const reduxState = store.getState();
	let nodeIds = [] as string[];
	switch (block.blockType) {
		case 'CANVAS':
			nodeIds = (block as ICanvas).nodes as string[];
			break;
		case 'IMAGE':
			nodeIds = (block as IImage).nodes as string[];
			break;
		case 'THREE_D':
			nodeIds = (block as I3D).nodes as string[];
			break;
		case 'LINK':
			nodeIds = (block as ILink).nodes as string[];
			break;
		case 'FILE':
			nodeIds = (block as IFile).nodes as string[];
			break;
		default:
			break;
	}
	const nodes = [] as INode[];
	for (let i = 0; i < nodeIds?.length; i++) {
		const node = reduxState.nodes.data[nodeIds[i] as string] as INode;
		if (node) nodes.push(node);
	}
	return nodes;
};

/**
 * Function to extract ids from url
 * @returns
 */
export const generateIdsFromUrl = () => {
	const params = window.location.pathname.split('/');
	let projectId = '';
	let stageId = '';
	let blockId = '';
	// decoding the url to extract project id in case its a redirect to url
	projectId = params[2] as string;
	if (projectId) {
		const decoded = decodeURIComponent(projectId);
		if (decoded.split('/').length > 1) {
			projectId = decoded.split('/')[decoded.split('/').length - 1] as string;
		}
	}

	if (params.length === 5) {
		stageId = params[3] as string;
		blockId = params[4] as string;
	}

	return { stageId, blockId, projectId };
};

// Function to identify if the user is in expanded view
export const checkIfExpandedView = () => {
	const { stageId, blockId } = generateIdsFromUrl();
	return stageId && blockId;
};

/**
 * Function to generate optimistic block
 * @param _id
 * @param stageId
 * @param name
 * @returns
 */
export const generateOptimisticBlock = (
	_id: string, // blockid
	stageId: string,
	projectId: string,
	name: string,
	blockType: keyof typeof EBlockType,
	users?: TUserAndRole[],
	invitedEmails?: TInvitedEmail[]
) => {
	const reduxState = store.getState();
	const user: IUser = getUserFromRedux();
	const { stages, projects } = reduxState;
	const stage = stages.data[stageId] as IGroup;
	let blockUsers: TUserAndRole[] = [];

	if (stage && stage.users) {
		blockUsers = (stage.users as TUserAndRole[]).filter(
			(stageUser) => stageUser?.status !== 'PENDING'
		);
	} else if (projects && projects.data[projectId] && projects.data[projectId]?.users) {
		blockUsers = ((projects.data[projectId] as IProject).users as TUserAndRole[]).filter(
			(projectUser) => projectUser?.status !== 'PENDING'
		);
	}

	const blockData: IBlock = {
		createdBy: user._id as string,
		lastUpdatedBy: user._id as string,
		users: users || blockUsers,
		_id,
		name,
		color: '#EAEAEA',
		blockType,
		invitedEmails: invitedEmails || [],
		isVisible: true,
		updatedAt: new Date().toISOString(),
		parentId: stageId,
		projectId,
		origin: 'NAYA',
		thumbnail: {
			src: '#FFFFFF',
			isCustom: false,
			originalSrc: '#FFFFFF'
		},
		deliverableStatus: null
	};

	return blockData;
};

/**
 * Function to generate optimistic stage
 * @param _id group id
 * @param name group name
 * @param parent parent id and parent type
 * @returns
 */
export const generateOptimisticStage = (_id: string, name: string, parent: TGroupParent) => {
	const reduxState = store.getState();
	const projectId = parent.type === 'JOURNEY' ? parent.id : generateIdsFromUrl().projectId;
	const user: IUser = getUserFromRedux();
	let users: TUserAndRole[] = [];
	let invitedEmails: TInvitedEmail[] = [];

	// Get the users from parent.
	if (parent.type === 'PHASE') {
		const group = reduxState.stages.data[parent.id];
		if (group) {
			if (group.users) users = group.users;
			if (group.invitedEmails) invitedEmails = group.invitedEmails;
		}
	} else {
		const project = reduxState.projects.data[projectId] as IProject;
		if (project) {
			if (project.users) users = project.users as TUserAndRole[];
			if (project.invitedEmails) invitedEmails = project.invitedEmails as TInvitedEmail[];
		}
	}

	const filteredUsers = users.filter((projectUser) => projectUser?.status !== 'PENDING');

	const data: IGroup = {
		_id,
		name,
		color: '#F5F5F5',
		children: [],
		users: filteredUsers,
		invitedEmails,
		isVisible: true,
		lastUpdatedBy: user._id as string,
		createdBy: user._id as string,
		isPhaseCreated: false,
		projectId,
		type: 'GROUP',
		parentId: parent.id,
		hasCompleteAccess: false,
		origin: 'NAYA'
	};
	return data;
};

/**
 * Function to generate a Project Template
 */
export const generateOptimisticProject = (payload: TGenerateOptimisticProjectArg) => {
	const { _id: projectId, createdBy, parentGroupId, isAIGenerated } = payload;

	const user: IUser = getUserFromRedux();

	const userObj = {
		_id: user._id,
		email: user.email,
		userName: user.userName,
		profilePic: user.profilePic,
		userDetails: user.userDetails
	};

	/** Create project details object */
	const projectDetailsPayload = {
		_id: new mongoose.Types.ObjectId().toString() as string,
		name: payload.name || 'Untitled',
		color: '#FFFFFF'
	};

	const optimisticProject: IProject = {
		_id: projectId,
		children: [],
		shipments: [],
		users: [
			{
				user: userObj._id as string,
				role: 'OWNER'
			}
		],
		lastUpdatedBy: createdBy as string,
		projectGroup: parentGroupId as string,
		createdBy: createdBy as string,
		invitedEmails: [],
		projectDetails: projectDetailsPayload,
		thumbnail: {
			src: '#FFFFFF',
			isCustom: false,
			originalSrc: '#FFFFFF'
		},
		statuses: [{ status: 'ACTIVE', createdAt: new Date() }],
		hasCompleteAccess: true,
		isAIGenerated: isAIGenerated || false,
		origin: 'NAYA',
		observers: [],
		aiIngestStatus: 'INACTIVE',
		isAIFeatureEnabled: true
	};

	return {
		optimisticProject
	};
};

/**
 * Function to extract node ids based on the block type
 * @param block
 * @returns
 */
export const getNodeIdsBasedOnBlockType = (block: IBlock) => {
	let nodes = [] as string[];
	switch (block.blockType) {
		case 'CANVAS':
			nodes = (block as ICanvas).nodes as string[];
			break;
		case 'IMAGE':
			nodes = (block as IImage).nodes as string[];
			break;
		case 'THREE_D':
			nodes = (block as I3D).nodes as string[];
			break;
		case 'FILE':
			nodes = (block as IFile).nodes as string[];
			break;
		case 'LINK':
			nodes = (block as ILink).nodes as string[];
			break;
		default:
			break;
	}
	return nodes;
};

/**
 * Function to get feedbacks based on block type
 * @param blockId
 * @returns
 */
export const getFeedbackBasedOnBlockType = (blockId: string, blockState?: IBlocksInitState) => {
	const block = blockState
		? (blockState.data[blockId] as IBlock)
		: (store.getState().blocks.data[blockId] as IBlock);
	const { feedbacks: reduxFeedbacks } = store.getState() as ReduxState;
	const oldComments = [] as IFeedback[];

	switch (block?.blockType) {
		case 'CANVAS':
			// oldComments = (block as ICanvas).feedbacks as IFeedback[];
			(block as ICanvas)?.feedbacks?.forEach((f) => {
				if (typeof f === 'string') {
					const feedback = reduxFeedbacks.data[f as string] as IFeedback;
					if (feedback !== undefined) {
						oldComments.push(feedback);
					}
				}
			});
			break;
		case 'FILE':
		case 'LINK':
		case 'IMAGE':
			(block as IImage)?.feedbacks?.forEach((f) => {
				if (typeof f === 'string') {
					const feedback = reduxFeedbacks.data[f as string] as IFeedback;
					if (feedback !== undefined) {
						oldComments.push(feedback);
					}
				}
			});
			break;
		case 'THREE_D':
			(block as I3D)?.feedbacks?.forEach((f) => {
				if (typeof f === 'string') {
					const feedback = reduxFeedbacks.data[f as string] as IFeedback;
					if (feedback !== undefined) {
						oldComments.push(feedback);
					}
				}
			});
			break;
		case 'VIDEO': {
			(block as IVideo)?.feedbacks?.forEach((f) => {
				if (typeof f === 'string') {
					const feedback = reduxFeedbacks.data[f as string] as IFeedback;
					if (feedback !== undefined) {
						oldComments.push(feedback);
					}
				}
			});
			break;
		}
		case 'PDF': {
			(block as IPdf)?.feedbacks?.forEach((f) => {
				if (typeof f === 'string') {
					const feedback = reduxFeedbacks.data[f as string] as IFeedback;
					if (feedback !== undefined) {
						oldComments.push(feedback);
					}
				}
			});
			break;
		}
		default:
			break;
	}
	return oldComments;
};

/**
 * Function to extract all blocks within a project
 * @param projectId
 * @returns list of all blocks, stages, and json for blocks and stages
 */
export const getStagesBlocksNodesOfProject = (projectId: string) => {
	const reduxState = store.getState() as ReduxState;
	const { projects, stages, blocks } = reduxState;

	const allBlocks = [] as IBlock[]; // array of project blocks
	const allStages = [] as IGroup[]; // array of project stages
	let allNodes = [] as INode[]; // array of project nodes
	const stagesJson: { [key: string]: IGroup } = {}; // key value pair of project stages
	const blocksJson: { [key: string]: IBlock } = {}; // key value pair of project blocks
	const nodesJson: { [key: string]: INode } = {}; // key value pair of project nodes

	const project = projects.data[projectId];

	if (project) {
		const stagesArr = Object.values(stages.data);
		for (let i = 0; i < stagesArr.length; i++) {
			const stage = stagesArr[i];
			if (stage && stage.isVisible) {
				allStages.push(stage);
				stagesJson[(stage as IGroup)._id as string] = stage;
				const stageBlocks = stage.children as string[];
				// extracting blocks
				for (let j = 0; j < stageBlocks.length; j++) {
					const block = blocks.data[stageBlocks[j] as string];
					if (block) {
						allBlocks.push(block);
						blocksJson[(block as IBlock)._id as string] = block as IBlock;

						const blockNodes = getNodesBasedOnBlockType(block as IBlock) as INode[];
						allNodes = [...allNodes, ...blockNodes];

						for (let k = 0; k < blockNodes.length; k++) {
							if ((blockNodes[k] as INode) && (blockNodes[k] as INode)._id) {
								nodesJson[(blockNodes[k] as INode)._id as string] = blockNodes[
									k
								] as INode;
							}
						}
					}
				}
			}
		}
	}

	return {
		blocks: allBlocks,
		stages: allStages,
		stagesJson,
		blocksJson,
		nodes: allNodes,
		nodesJson
	};
};

/**
 * Function to extract all the blocks of a stage
 * @param stageId
 * @returns an array of blocks
 */
export const getStageBlocks = (stageId: string) => {
	const reduxState = store.getState() as ReduxState;
	const { stages, blocks } = reduxState;
	const allBlocks = [] as IBlock[];
	const stage = stages.data[stageId];
	if (stage) {
		const stageBlocks = stage.children as string[];
		for (let i = 0; i < stageBlocks?.length; i++) {
			const tempBlock = blocks.data[stageBlocks[i] as string];
			if (tempBlock) allBlocks.push(tempBlock);
		}
	}
	return allBlocks;
};

// Function to generate populated TUserAndRole object
export const getUserRoleObjBasedOnIds = (usersWithRole: TUserAndRole[]) => {
	const { projectUsers } = store.getState();
	const allUsers = [] as TUserAndRole[];
	for (let i = 0; i < usersWithRole?.length; i++) {
		const temp = { ...(usersWithRole[i] as TUserAndRole) };
		const found = projectUsers.data.find((x) =>
			typeof temp.user === 'string'
				? x._id === temp.user
				: x._id === (temp?.user as IUser)._id
		);

		if (found) {
			temp.user = found as IUser;
			allUsers.push(temp);
		}
	}
	return allUsers;
};

export const getStageAndBlockIdsOfProject = (projectId: string) => {
	const reduxState = store.getState() as ReduxState;
	const { projects } = reduxState;
	const projectChildren = projects.data[projectId]?.children;
	const stageIds = [] as string[];
	const blockIds = [] as string[];
	projectChildren?.forEach((id) => {
		const { stages, blocks } = getStageAndChildren(id as string, 'NORMALIZED');
		blocks.forEach((block) => blockIds.push(block._id as string));
		stages.forEach((stage) => stageIds.push(stage._id as string));
	});

	return { blockIds, stageIds };
};

/**
 * Function to return project stages and blocks
 * @param { string } projectId
 * @returns
 */
export const getStagesAndBlocksOfProject = (projectId: string) => {
	const projectChildren = getProjectFromRedux(projectId).children as string[];
	const stages: IGroup[] = [];
	const blocks: IBlock[] = [];
	projectChildren.forEach((group) => {
		const { stages: allStages, blocks: allBlocks } = getStageAndChildren(group, 'NORMALIZED');
		stages.push(...allStages);
		blocks.push(...allBlocks);
	});
	return { stages, blocks };
};

// Function to get users data based on user ids
export const getAllUsersBasedOnIds = (userIds: string[]) => {
	const allUsers: IUser[] = [];
	const { projectUsers } = store.getState();
	for (let i = 0; i < userIds?.length; i++) {
		const found = projectUsers.data.find((x) => x._id === userIds[i]);
		if (found) {
			allUsers.push(found);
		}
	}
	return allUsers;
};

// Function to extract all the users of given block
export const getBlockUsers = (blockId: string) => {
	const { blocks } = store.getState();
	const currentBlock = blocks.data[blockId] as IBlock;

	const userIds: string[] = (currentBlock.users as TUserAndRole[]).map(
		(u: TUserAndRole) => u.user as string
	);
	const blockUsers = getAllUsersBasedOnIds(userIds);
	return {
		blockUsers,
		userIds
	};
};

/**
 * Response Type of RTK for Notification
 */
export type TRtkResponse = {
	payload: {
		data: {
			payload: INotification | ISentNotification;
		};
	};
};

/**
 * Function to save redux state in indexed db
 * @param projectId id of the active project whose state is to be saved
 */
export const saveReduxStateInIndexedDB = (projectId: string) => {
	const reduxState = store.getState();
	const {
		projects,
		stages: reduxStages,
		blocks: reduxBlocks,
		feedbacks: reduxFeedbacks,
		notes: reduxNotes,
		nodes: reduxNodes
	} = reduxState;

	const project = projects.data[projectId] as IProject;
	const stages = Object.values(reduxStages.data).filter((s) => s.projectId === projectId);
	const blocks = Object.values(reduxBlocks.data).filter((b) => b.projectId === projectId);
	const notes = Object.values(reduxNotes.data).filter((n) => n.projectId === projectId);
	const feedbacks = Object.values(reduxFeedbacks.data).filter((f) => f.projectId === projectId);
	const nodes = Object.values(reduxNodes.data).filter((n) => n.projectId === projectId);

	saveProjectInLocalForage(project, stages, blocks, feedbacks, notes);
	saveNodesInLocalForage(nodes);
};

// Funtion to handle scrolling to view
let timeoutId: ReturnType<typeof setTimeout> | undefined;
// Declared outside the function to persist across calls

export const highlightAndScrollToView = (id: string) => {
	const targetElement = document.getElementById(id);
	if (targetElement) {
		targetElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });

		targetElement.classList.add('highlighted');

		if (timeoutId) clearTimeout(timeoutId);

		timeoutId = setTimeout(() => {
			targetElement.classList.remove('highlighted');
			timeoutId = undefined; // Moved inside the timeout callback
		}, 2000);
	}
};

/**
 * Function to ignore further reminders for a journey
 */
export const ignoreRemindersForJourney = (projectId: string) => {
	try {
		const ignoredReminderForJourneysString = window.localStorage.getItem(
			'ignored-reminder-for-journeys'
		);

		const ignoredJourneys = ignoredReminderForJourneysString
			? (JSON.parse(ignoredReminderForJourneysString) as string[])
			: [];
		ignoredJourneys.push(projectId);
		const uniqueEnteries = new Set(ignoredJourneys);

		window.localStorage.setItem(
			'ignored-reminder-for-journeys',
			JSON.stringify([...uniqueEnteries])
		);
	} catch (error) {
		console.error('Failed to insert journey in ignored journeys', error);
	}
};

// Generate all color arrays
export const allShades = colors.map((baseColor) => generateColorArray(baseColor));
