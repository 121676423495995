import CryptoJS from 'crypto-js';
import { IBlock, INode, IProject, IUser, TEditBlockFnArgs, TUserAndRole } from '@naya_studio/types';
import React from 'react';
import { jsonEncryptionSecretKey } from 'src/components/utilities/clipboard/clipboard';
import { threeDModelFormats } from 'src/util/helper/constants';
import App from 'src/components/canvas/app/App';
import { generateIdsFromUrl } from 'src/redux/reduxActions/util';
import { TAddBlocksFnArgs } from 'src/util/block/blockAction.types';
import { store } from 'src';
import { ReduxState } from 'src/redux/reducers/root.types';
import { checkUserAccessLevel } from 'src/util/accessLevel/accessLevelActions';
import { TOnAddBlocksParams } from 'src/util/block/useBlockActions';
import getUserFromRedux from 'src/util/helper/user';
import validator from 'validator';
import { getProjectFromRedux } from 'src/util/helper/project';
import { isJson, DEFAULT_TEXT, DEFAULT_STICKY_NOTE } from './utils/helpers';
import pasteNode from './pasteNode';

const HTMLDOMParser = new DOMParser();

/** Offset counter and prev data to calculate offset on repeated pastes */
let offsetCounter = 1;
let prevNodeData: string = '';

/**
 * Processes the naya data buffer and pastes the nodes to the canvas
 * @param clipboardData clipboard data
 * @param app App instance
 * @returns True if successful, false otherwise
 */
function processNayaClipboardData(clipboardData: any, app: App, type?: string) {
	try {
		const { nodes, canvasId: id, center } = JSON.parse(clipboardData);
		const newNodeIds = JSON.stringify(nodes.map((node: INode) => node._id).sort());
		if (newNodeIds === prevNodeData && id === app.getCanvasId()) {
			offsetCounter += 1;
		} else {
			offsetCounter = 1;
		}
		const allNodesData: INode[] = [];
		nodes.forEach(async (node: INode) => {
			const newNode: INode = { ...node, showAuthor: false };
			allNodesData.push(newNode);
		});
		pasteNode(app, allNodesData, type || 'OFFSET', undefined, id, offsetCounter, center);
		prevNodeData = newNodeIds;
		return true;
	} catch (error) {
		console.error(error);
		return false;
	}
}

const onPasteInPlace = (encryptedData: any, app: App) => {
	const nayaDataBuffer: any = CryptoJS.enc.Utf8.stringify(
		CryptoJS.AES.decrypt(encryptedData, jsonEncryptionSecretKey)
	);
	if (isJson(nayaDataBuffer)) {
		const status = processNayaClipboardData(nayaDataBuffer, app, 'IN_PLACE');
		if (status) {
			return true;
		}
	}
	return false;
};

/**
 * Function to extract files from paste event
 * @param items
 */
const extractFiles = (items: DataTransferItemList) => {
	const pastedFiles: File[] = [];
	// Extract files from the clipboard data
	for (let i = 0; i < items.length; i++) {
		if (items[i]?.kind === 'file') {
			const file = items[i]?.getAsFile();
			pastedFiles.push(file as File);
		}
	}
	return pastedFiles;
};

/**
 * Pastes the nodes from the clipboard. For compatibility with other applications,
 * the data is pasted as text and/or sticky notes for tabular data.
 * For compatibility with Naya, the data is pasted from JSON
 * @param e ClipboardEvent Paste event
 * @param app app instance
 * @param onDropFilesInProjectPanel drop listener for files
 * @param gridPositionWrap grid calculation function
 * @param timeout timeout for grid pastes
 * @param page screen name
 * @param callback callback requirect for snackbar redirection
 */
const onPaste = async (
	e: ClipboardEvent,
	app: App,
	onDropFilesInProjectPanel: Function,
	gridPositionWrap: React.MutableRefObject<any>,
	timeout: React.MutableRefObject<any>,
	onAddBlocks: (data: TOnAddBlocksParams) => void,
	onBlockEdit: (data: TEditBlockFnArgs) => void,
	startUploadOnPaste: (file: File, blockId: string) => void
) => {
	const { blockId: canvasId, projectId } = generateIdsFromUrl();
	const target = e.target as HTMLElement;
	const isInput = ['INPUT', 'TEXTAREA', 'P', 'BR'].includes(target.tagName);
	if (isInput && (getProjectFromRedux(projectId).children || []).length !== 0) return;
	if (!e.clipboardData) return;
	const { items } = e.clipboardData;
	if (items === undefined) return;

	let allowFilesPaste = true; // to check if pasting of files is allowed
	const user: IUser = getUserFromRedux();
	const { blocks, projects } = store.getState() as ReduxState;
	const renderedProject = projects.data[projectId] as IProject;

	const textData = e.clipboardData.getData('text/plain');
	const blockFiles = extractFiles(items);
	if (canvasId && (blocks.data[canvasId] as IBlock).blockType === 'EXCALIDRAW_CANVAS') {
		return;
	}

	// check if any block is open
	if (canvasId) {
		const renderedBlock = blocks.data[canvasId] as IBlock;

		// // not creating new blocks on paste when in text block
		if (renderedBlock.blockType === 'TEXT') {
			return;
		}

		// check if currently open block is of type canvas
		// if yes, don't allow paste
		if (renderedBlock.blockType === 'CANVAS') {
			allowFilesPaste = false;
		} else if (
			checkUserAccessLevel(renderedBlock.users as TUserAndRole[], user._id as string, [
				'EDITOR',
				'OWNER'
			])
		) {
			if (blockFiles && blockFiles.length > 0) {
				// if rendered block is empty
				if (renderedBlock.blockType === 'EMPTY') {
					const editPayload: TEditBlockFnArgs = {
						editType: 'ADD_FILE',
						blockId: canvasId,
						payload: {
							files: blockFiles
						}
					};
					onBlockEdit(editPayload);
					startUploadOnPaste(blockFiles[0] as File, canvasId);
					return;
				}
				const editPayload: TEditBlockFnArgs = {
					editType: 'ADD_BLOCKS_UPLOAD',
					blockId: canvasId,
					payload: {
						files: blockFiles
					}
				};
				onBlockEdit(editPayload);
				return;
			}
			// if rendered block is empty
			if (renderedBlock.blockType === 'EMPTY') {
				const editPayload: TEditBlockFnArgs = {
					editType: 'ADD_LINK',
					blockId: canvasId,
					payload: {
						link: textData
					}
				};
				onBlockEdit(editPayload);
				return;
			}
		}
	}

	if (
		allowFilesPaste &&
		checkUserAccessLevel(renderedProject.users as TUserAndRole[], user._id as string, [
			'EDITOR',
			'OWNER'
		]) &&
		// To prevent text pastes inside text block in journey from propogating to journey
		!(e.target as HTMLElement).getAttribute('data-lexical-text')
	) {
		if (blockFiles && blockFiles.length > 0) {
			// if pasted data has files, then sending out a call to add blocks with files
			const blocksToAdd = [] as TAddBlocksFnArgs[];
			for (let i = 0; i < blockFiles.length; i++) {
				if ((blockFiles[i] as File).type) {
					const tempData: TAddBlocksFnArgs = {
						addType: 'FILE',
						payload: blockFiles[i] as File
					};
					blocksToAdd.push(tempData);
				}
			}
			const data = {
				blocksToAdd,
				options: {}
			};
			onAddBlocks(data);
			return;
		}
		if (textData) {
			const { stageId } = generateIdsFromUrl();
			// if the url has phaseId (ie. expanded view) add LINK block to the same phase
			if (stageId) {
				const data = {
					blocksToAdd: [{ addType: 'LINK', payload: textData }] as TAddBlocksFnArgs[],
					options: { phaseId: stageId }
				};
				onAddBlocks(data);
			}
			// else, in journey view, hence create a new phase and add block
			else {
				const data = {
					blocksToAdd: [{ addType: 'LINK', payload: textData }] as TAddBlocksFnArgs[],
					options: {}
				};
				onAddBlocks(data);
			}

			return;
		}
	}

	if (!app || app.editText || app.disablePaste || !canvasId || isInput) {
		return;
	}

	// Extract text/html data
	const htmlClipboardData = HTMLDOMParser.parseFromString(
		e.clipboardData.getData('text/html'),
		'text/html'
	);

	// If the clipboard data has a naya-data-buffer attribute, it is a Naya clipboard data
	// Decrypt and process the data
	const nayaDataBufferEncrypted = htmlClipboardData
		.querySelector('span[naya-data-buffer]')
		?.getAttribute('naya-data-buffer');
	if (nayaDataBufferEncrypted) {
		const nayaDataBuffer: any = CryptoJS.enc.Utf8.stringify(
			CryptoJS.AES.decrypt(nayaDataBufferEncrypted, jsonEncryptionSecretKey)
		);
		if (isJson(nayaDataBuffer)) {
			const status = processNayaClipboardData(nayaDataBuffer, app);
			if (status) {
				e.stopPropagation();
				e.preventDefault();
				return;
			}
		}
	}

	// Check if the clipboard data is a Naya data buffer
	const files = [];

	const bound = app.viewport.getVisibleBounds();
	const x = bound.x + bound.width / 2;
	const y = bound.y + bound.height / 2;

	let isFileData = false;
	let isTextData = false;
	const tableData = htmlClipboardData.querySelector('table');

	// Extract files from the clipboard data
	for (let i = 0; i < items.length; i++) {
		if (items[i]?.kind === 'file') {
			if (!tableData) {
				const file = items[i]?.getAsFile();
				files.push(file);
				isFileData = true;
			}
		} else if (items[i]?.type.indexOf('text/plain') === 0) {
			isTextData = true;
		}
	}

	// If the clipboard data is a file, process it
	if (isFileData && files.length > 0) {
		// Separate files into image and 3D model files
		const modelFiles = files
			.filter((file) => file?.size && file?.size > 0)
			.filter((f) =>
				threeDModelFormats.includes(f?.name.split('.').pop()?.toUpperCase() as string)
			);

		const nonModelFiles = files
			.filter((file) => file?.size && file?.size > 0)
			.filter(
				(f) =>
					!threeDModelFormats.includes(f?.name.split('.').pop()?.toUpperCase() as string)
			);

		setTimeout(() => {
			gridPositionWrap.current = {
				xPosition: bound.x + 25,
				yPosition: bound.y + (1 / app.viewport.scale._y) * 50,
				isCentred: true
			};
			timeout.current = new Date(Date.now() - 20000);
		}, 1000);
		onDropFilesInProjectPanel(modelFiles, nonModelFiles);
		e.stopPropagation();
		e.preventDefault();
		return;
	}

	// Extract cell string from html table
	if (tableData) {
		const { rows } = tableData;
		const height = rows.length * 200;
		const viewportCenter = app.viewport.center;
		for (let i = 0; i < rows.length; i++) {
			if (rows[i]) {
				const width = (rows[0]?.cells.length || 1) * 200;
				const { cells } = rows[i]!;
				for (let j = 0; j < cells.length; j++) {
					if (cells[j]) {
						const { innerText: text } = cells[j]!;
						if (text.trim().length > 0) {
							const newSticky: INode = DEFAULT_STICKY_NOTE(
								viewportCenter.x - width / 2 + j * 200,
								viewportCenter.y - height / 2 + i * 200,
								text,
								app
							);
							pasteNode(app, [newSticky], '');
						}
					}
				}
			}
		}
		e.stopPropagation();
		e.preventDefault();
		return;
	}

	// Extract text/plain first
	const text = e.clipboardData.getData('text/plain');
	if (isTextData && text) {
		const newText = DEFAULT_TEXT(x, y, text, app);
		pasteNode(app, [newText], '');
		e.stopPropagation();
		e.preventDefault();
	}
};

/**
 * Function to extract files from paste event
 * @param items
 */
export const extractFilesOnExpandedBlock = async (items: any) => {
	const promises: any[] = [];
	// Extract files from the clipboard data
	for (let i = 0; i < items.length; i++) {
		const isImage = items[i].types.some((type: string) => type.startsWith('image/'));
		if (items[i].types && isImage) {
			items[i].types.forEach((type: string) => {
				if (type.startsWith('image/')) {
					const filePromise = new Promise((resolve) => {
						items[i].getType(type).then((blob: Blob) => {
							const file = new File([blob], `image.${type.split('/')[1]}`);
							resolve(file);
						});
					});
					promises.push(filePromise);
				}
			});
		}
	}
	const res = await Promise.all(promises);
	return res;
};

/**
 * This function reads data from the clipboard and processes it to determine its type.
 * @returns {Object|undefined} An object containing the data type and the
 * corresponding data, or undefined if the data couldn't be determined.
 */
export const getPastedData = async () => {
	try {
		// Read data from the clipboard
		const data = await navigator.clipboard.read();

		// Read the text data from the clipboard
		const textData = await navigator.clipboard.readText();

		// Extract files from the clipboard data
		const files = await extractFilesOnExpandedBlock(data);

		// Check if files were found in the clipboard data
		if (files && files.length > 0) {
			// Return the data as a FILE type
			return { type: 'FILE', data: files };
		}

		// Check if the text data is a valid URL
		if (validator.isURL(textData, { protocols: ['http', 'https'], require_protocol: true })) {
			// Return the data as a LINK type
			return { type: 'LINK', data: textData };
		}

		// If no data type could be determined, return undefined
		return undefined;
	} catch (error) {
		// Handle any errors that may occur during clipboard access
		console.error('Error reading clipboard data:', error);
		return undefined;
	}
};

export { onPasteInPlace };
export default onPaste;
