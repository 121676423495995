import { FontTypes } from "./Font.types";

export const fontTypes: FontTypes = {
    rand: {
        font: "Rand-Regular",
        placeholder: "Rand"
    },
    arial: {
        font: "Arial-Regular",
        placeholder: "Arial"
    },
    courierNew: {
        font: "Courier_New-Regular",
        placeholder: "Courier New"
    },
    garamond: {
        font: "Garamond-Regular",
        placeholder: "Garamond"
    },
    georgia: {
        font: "Georgia-Regular",
        placeholder: "Georgia"
    },
    // montserrat: {
    //     font: "Montserrat-Regular",
    //     placeholder: "Montserrat"
    // },
    openSans: {
        font: "Open_Sans-Regular",
        placeholder: "Open Sans"
    },
    timesNewRoman: {
        font: "Times_New_Roman-Regular",
        placeholder: "Times New Roman"
    }
}