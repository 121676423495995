/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import 'quill-mention';
import 'quill-mention/dist/quill.mention.min.css';
import 'react-quill/dist/quill.snow.css';
import { IUser, TUserAndRole } from '@naya_studio/types';
import userPlaceholder from 'src/assets/icons/profile1.svg';
import { generateIdsFromUrl, getUserRoleObjBasedOnIds } from 'src/redux/reduxActions/util';
import { fetchBlockByIdFromRedux } from 'src/redux/actions/util';
import getUserFromRedux from 'src/util/helper/user';

export const getUserName = (user: IUser, otherUsers: any[]) => {
	const { userName, firstName, lastName } = user;
	// const { lastName } = (user?.userDetails as IUserDetailNode[])[0]?.details as IUserDetail;
	let finalName = userName;
	if (firstName) {
		finalName = firstName;
		for (let i = 0; i < otherUsers.length; i++) {
			if (otherUsers[i].value === firstName) {
				if (lastName) {
					finalName = `${finalName} ${lastName}`;
				} else {
					finalName = userName;
				}
			}
		}
	}
	return finalName;
};

export async function suggestPeople(searchTerm: any) {
	const currentUser: IUser = getUserFromRedux();

	const { blockId } = generateIdsFromUrl();
	let allUsersList: any[] = [];

	const block = fetchBlockByIdFromRedux(blockId);

	// extract all the users in the block
	if (block) {
		const users = getUserRoleObjBasedOnIds(block.users as TUserAndRole[]);
		allUsersList = users.map((user: TUserAndRole) => user.user);
	}

	let finalList: any = [];
	allUsersList.forEach((user) => {
		finalList.push({
			id: (user as IUser)._id,
			value: getUserName(user as IUser, finalList),
			profilePic: (user as IUser).profilePic ? (user as IUser).profilePic : '',
			email: (user as IUser).email
		});
	});

	finalList = finalList.filter((x: any) => (x as any).id !== currentUser._id);

	const filteredList = finalList.filter((person: any) =>
		person.value.toLowerCase().includes(searchTerm.toLowerCase())
	);
	return filteredList.length < 3 ? filteredList : filteredList.splice(0, 2);
}

// const getMentionData = (id:string) => {
const mention = {
	allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
	mentionDenotationChars: ['@', '#'],
	dataAttributes: ['id', 'value', 'email', 'profilePic'],
	// @ts-ignore
	async source(searchTerm: any, renderList: any) {
		// console.log('source called')
		const matchedPeople = await suggestPeople(searchTerm);
		renderList(matchedPeople);
	},
	// @ts-ignore
	renderItem: (item: any) => `
      <div class="cql-list-item-inner">
      <img class="list-item-pic" referrerPolicy="no-referrer" src=${
			item.profilePic ? item.profilePic : userPlaceholder
		} />
        ${item.value}
      </div>`,
	// positioningStrategy:'normal'
	onOpen: () => {},
	spaceAfterInsert: false
};

// eslint-disable-next-line react/prefer-stateless-function
class CustomToolbar extends React.Component<{ show: boolean; id: string }> {
	render(): React.ReactNode {
		const { id, show } = this.props;
		return (
			<div
				id={`custom-toolbar-${id}`}
				className="custom-toolbar"
				style={{ display: show ? 'flex' : 'none' }}
			>
				<button className="ql-bold" type="button" />
				<button className="ql-italic" type="button" />
				<button className="ql-link" type="button" />
				<button className="ql-blockquote" type="button" />
				<button className="ql-list" value="ordered" type="button" />
				<button className="ql-list" value="bullet" type="button" />
				<button className="ql-header" value="1" type="button" />
				<button className="ql-header" value="2" type="button" />
			</div>
		);
	}
}

const getModules = (id: string) => ({
	toolbar: {
		container: `#custom-toolbar-${id}`
	},
	mention
	// mention:getMentionData(id)
});

// Formats objects for setting up the Quill editor
const formats = [
	'header',
	'font',
	'size',
	'bold',
	'italic',
	'underline',
	'align',
	'strike',
	'script',
	'blockquote',
	'background',
	'list',
	'bullet',
	'indent',
	'link',
	'image',
	'color',
	'code-block',
	'mention'
];

export { getModules, formats, CustomToolbar };
