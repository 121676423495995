/**
 * /**
 * polygon-polygon collision
 * based on http://stackoverflow.com/questions/10962379/how-to-check-intersection-between-2-rotated-rectangles
 * @param {number[]} points1 [x1, y1, x2, y2, ... xn, yn] of first polygon
 * @param {number[]} points2 [x1, y1, x2, y2, ... xn, yn] of second polygon
 * @return {boolean}
 */
export const detectCollision = (points1:number[], points2: number[]) => {
    var a = points1
    var b = points2
    var polygons = [a, b]
    var minA, maxA, projected, minB, maxB, j
    for (var i = 0; i < polygons.length; i++){
        var polygon = polygons[i] as number[]
        for (var i1 = 0; i1 < polygon.length; i1 += 2){
            var i2 = (i1 + 2) % polygon.length
            var normal = { x: polygon[i2 + 1]! - polygon[i1 + 1]!, y: polygon[i1]! - polygon[i2]! }
            minA = maxA = null
            for (j = 0; j < a.length; j += 2){
                projected = normal.x * a[j]! + normal.y * a[j + 1]!
                if (minA === null || projected < minA){
                    minA = projected
                }
                if (maxA === null || projected > maxA){
                    maxA = projected
                }
            }
            minB = maxB = null
            for (j = 0; j < b.length; j += 2){
                projected = normal.x * b[j]! + normal.y * b[j + 1]!
                if (minB === null || projected < minB){
                    minB = projected
                }
                if (maxB === null || projected > maxB){
                    maxB = projected
                }
            }
            if (maxA && maxB && minA && minB && (maxA < minB || maxB < minA)){
                return false
            }
        }
    }
    return true
  }


/** -------- NOTE: FOLLOWING FUNCTIONS ARE NOT BEING USED ANYWHERE AS OF NOW, BUT CAN BE USED IN FUTURE IF WE AIM TO MAKE MULTI-SELECT MORE SPECIFIC TO NODES ------- */

export const generateBoxPoints = (x:number, y:number, w:number, h:number) => {
  return [x, y, x + w, y, x + w, y + h, x, y + h]
}

export const generateTrianglePoints = (x:number, y:number, w:number, h:number) => {
    return [x + w / 2, y, x, h + y!, (x + w), (y + h)]
}

// @ts-ignore
export const generateStarPoints = (x:number, y:number, w:number, h:number) => {
    let arr = [];
    let starX = 0;
    let starY = 0;
    let R = w;
    let r = w / 2;

    for (let i = 0; i < 5; i++) {
        // The coordinates of each point protruding from the periphery
        starX = Math.cos((18 + 72 * i) / 180 * Math.PI) * R
        starY = - Math.sin((18 + 72 * i) / 180 * Math.PI) * R // The positive direction of the y-axis in the canvas is opposite to the rectangular coordinate system
        arr.push(x + starX)
        arr.push( y + starY)
        // The coordinates of each point where the periphery is recessed
        starX = Math.cos((54 + 72 * i) / 180 * Math.PI) * r
        starY = - Math.sin((54 + 72 * i) / 180 * Math.PI) * r // The positive direction of the y-axis in the canvas is opposite to the rectangular coordinate system
        arr.push(x + starX)
        arr.push( y + starY)
    }
    return arr;
}