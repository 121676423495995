import { Component, createRef, MutableRefObject, RefObject } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import './sideNav.scss';

export type SideBarProps = {
	expanded: boolean;
	toggleHamburgerMenu: () => void;
	showPartnerPopup: () => void;
};

class SideBar extends Component<RouteComponentProps<{}> & SideBarProps, { [key: string]: any }> {
	sidenavRef: RefObject<HTMLDivElement>;

	windowRef: MutableRefObject<any>;

	constructor(props: any) {
		super(props);

		this.sidenavRef = createRef();
		this.windowRef = createRef();

		this.state = {
			active: '/'
		};
	}

	componentDidMount() {
		const { location } = this.props;
		this.setState((prev) => ({
			...prev,
			active: location.pathname
		}));
	}

	componentDidUpdate(prevProps: SideBarProps & RouteComponentProps) {
		const { location } = this.props;
		if (prevProps.location.pathname !== location.pathname) {
			this.setState((prev) => ({
				...prev,
				active: location.pathname
			}));
		}

		if (this.windowRef.current) {
			this.windowRef.current = undefined;
			document.removeEventListener('click', this.handleOutsideWindowClick);
		}

		document.addEventListener('click', this.handleOutsideWindowClick);
		this.windowRef.current = 1;
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleOutsideWindowClick);
	}

	handleOutsideWindowClick = (e: MouseEvent) => {
		const { toggleHamburgerMenu, expanded } = this.props;

		if (expanded && e.target !== this.sidenavRef.current) {
			toggleHamburgerMenu();
		}
	};

	// function to return the navitems as per the rendered url
	getNavContents = () => {
		const { toggleHamburgerMenu } = this.props;
		const { active } = this.state;
		return (
			<>
				<Nav.Link as={Link} to="/" active={active === '/'} onClick={toggleHamburgerMenu}>
					Home
				</Nav.Link>
				<a className="nav-link" href="#partners">
					Our Partners
				</a>
				<a className="nav-link" href="#hiw">
					How It Works
				</a>
				<a className="nav-link" href="#name">
					Our Why
				</a>
			</>
		);
	};

	render() {
		const { toggleHamburgerMenu, expanded } = this.props;

		return (
			<Navbar
				expand="lg"
				variant="light"
				id="sidenav"
				className={expanded ? 'show' : 'hide'}
				expanded={expanded}
			>
				{/* <Navbar.Toggle aria-controls="sidenav-collapse" /> */}
				<Navbar.Collapse id="sidenav-collapse">
					<Nav id="sidenav-container" className="mr-auto" ref={this.sidenavRef}>
						<Navbar.Brand
							as={Link}
							to="/"
							style={{ marginTop: '0rem' }}
							onClick={() => {
								toggleHamburgerMenu();
								window.location.href = '/';
							}}
						>
							NAYA
						</Navbar.Brand>
						{this.getNavContents()}
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		);
	}
}

export default withRouter(SideBar);
