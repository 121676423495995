import { createSlice } from '@reduxjs/toolkit';
import { IUser } from '@naya_studio/types';
import { IUserInitState } from '../reducers/root.types';

const initialUser: IUser = {
	_id: '',
	userName: '',
	firstName: '',
	lastName: '',
	email: '',
	contactEmail: '',
	profilePic: '',
	isVerified: false,
	userType: [],
	projectGroups: [], // project within this will be populated
	archivedProjects: [],
	permissions: [],
	acceptedTermsOfService: {
		client: false,
		designer: false,
		maker: false,
		timestamps: {}
	},
	userPreferences: {
		_id: '',
		userId: '',
		pinnedProjects: [],
		noNotificationsFor: [],
		noActionItemToggleWArning: [],
		noReorderWarningModals: [],
		noPageReorderAllowAccess: [],
		color: '#4F00C1'
	},
	userDetails: [{}],
	defaultProjectGroup: '', // id of the default project group
	notifications: [],
	prototypeOrders: [],
	googleToken: null,
	miroToken: null
};
const initialState: IUserInitState = {
	data: initialUser,
	loading: false,
	error: null
};

/**
 * User Slice to Handle User related Redux Updates
 */
const aiUserSlice = createSlice({
	name: 'aiUser',
	initialState,
	reducers: {
		setAIUser: (state: IUserInitState, action) => {
			state.data = action.payload;
			state.error = null;
			state.loading = false;
		}
	}
});

export const { setAIUser } = aiUserSlice.actions;

export default aiUserSlice.reducer;
