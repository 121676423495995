import {
	EAIGenerationType,
	EBlockType,
	IBlock,
	IProject,
	IGroup,
	IUser,
	TUserAndRole,
	I3D,
	IImage,
	ILink,
	ILinkSubtype,
	IPdf,
	IText,
	TBlockThumbnail
} from '@naya_studio/types';
import { store } from 'src';
import { ISnackBar, ReduxState } from 'src/redux/reducers/root.types';
import { storage } from 'src/util/storage/firebaseStorage';
import { PDF_THUMBNAIL_ENDPOINT } from 'src/endpoints/upload-endpoints';
import axios from 'axios';
import { getDownloadURL, ref, uploadBytesResumable, uploadString } from 'firebase/storage';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';
import { getFileLinkSubType } from 'src/components/utilities/navbar/utils';
import { getEmbedType, isLinkEmbeddable } from '@naya_studio/radix-ui';
import validator from 'validator';
import { generateIdsFromUrl, generateOptimisticBlock } from 'src/redux/reduxActions/util';
import mongoose from 'mongoose';
import { checkUserAccessLevel } from '../accessLevel/accessLevelActions';
import getUserFromRedux from '../helper/user';

/**
 * Retrieves the extension of a url
 * @param url The url to get the extension from
 * @returns The extension of the url
 */
const getUrlExtension = (url: string) => {
	const splitUrl = url.split(/[#?]/);
	if (splitUrl.length > 0) {
		const ext = splitUrl[0]?.split('.').pop()?.trim();
		return ext;
	}
	return undefined;
};

/** Function to extract block type based upon file's type */
export const getBlockType = (fileName: string) => {
	const ext = getUrlExtension(fileName.toLowerCase());
	switch (ext) {
		case 'jpg':
		case 'jpeg':
		case 'png':
		case 'svg':
		case 'gif':
		case 'webp':
		case 'avif':
		case 'heic':
		case 'psd':
		case 'ai':
			return EBlockType.IMAGE;
		case 'pdf':
			return EBlockType.PDF;
		case 'mp4':
		case '3gp':
		case 'webm':
		case 'mpeg':
		case 'ogg':
		case 'mov':
			return EBlockType.VIDEO;
		case 'obj':
		case 'gltf':
		case 'fbx':
		case 'dae':
		case 'stl':
		case 'glb':
		case 'gcode':
		case '3ds':
		case '3mf':
		case 'amf':
		case 'pcd':
		case 'ply':
		case 'usd':
		case 'usdz':
		case '3dm':
			return EBlockType.THREE_D;
		default:
			return EBlockType.FILE;
	}
};

/**
 * Function to extact the last accessible and editable stage for a user
 * @param projectId
 * @returns stage id
 */
export const getLastAccessibleStageId = (projectId: string) => {
	const reduxState = store.getState() as ReduxState;
	let stageId = '';
	const user: IUser = getUserFromRedux();
	const { projects, stages } = reduxState;
	const project = projects.data[projectId];
	const projectStages = project?.children as string[];
	if (projectStages) {
		for (let i = projectStages.length - 1; i >= 0; i--) {
			const stage = stages.data[projectStages[i] as string];
			if (stage) {
				if (
					checkUserAccessLevel(stage.users as TUserAndRole[], user._id as string, [
						'EDITOR',
						'OWNER'
					])
				) {
					stageId = projectStages[i] as string;
					break;
				}
			}
		}
	}
	return stageId;
};

/**
 * Generate thumbnail from the given video and upload it to firebase and save the url to db
 * @param video File | file url
 * @param blockId string - block id
 */
export const generateVideoThumbnail = async (fileUrl: string): Promise<string> =>
	new Promise((res, rej) => {
		// using timestamp as name
		const fileName = `${Date.now().toString()}.png`;

		// upload thumbnail to fire store and return thumbnail url
		const storeFrame = async (
			video: HTMLVideoElement,
			context: CanvasRenderingContext2D,
			canvas: HTMLCanvasElement
		) => {
			try {
				context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
				const thumbnailDataUrl = canvas.toDataURL('image/png', 1.0);
				const storageRef = ref(storage, fileName);
				await uploadString(storageRef, thumbnailDataUrl, 'data_url');
				const url = await getDownloadURL(storageRef);
				res(url);
			} catch (error) {
				rej(error);
			}
		};

		/**
		 *
		 * @param video HTMLVideoElement
		 * @param context canvas context
		 * @param canvas HTMLCanvasElement
		 * @param time number - seek time
		 */
		const seekToFirstFrame = async (
			video: HTMLVideoElement,
			context: CanvasRenderingContext2D,
			canvas: HTMLCanvasElement,
			seekTime: number
		) => {
			try {
				const eventCallback = () => {
					video.removeEventListener('seeked', eventCallback);
					storeFrame(video, context, canvas);
				};
				video.addEventListener('seeked', eventCallback);
				video.currentTime = seekTime;
			} catch (error) {
				rej(error);
			}
		};

		const canvas: HTMLCanvasElement = document.createElement('canvas') as HTMLCanvasElement;
		const context: CanvasRenderingContext2D | null = canvas.getContext('2d');
		const videoEle = document.createElement('video') as HTMLVideoElement;

		videoEle.preload = 'auto';
		videoEle.crossOrigin = 'anonymous';
		videoEle.addEventListener('loadeddata', async () => {
			try {
				canvas.width = videoEle.videoWidth;
				canvas.height = videoEle.videoHeight;
				if (videoEle && context) {
					await seekToFirstFrame(videoEle, context, canvas, 1);
				}
			} catch (error) {
				rej(error);
			}
		});

		videoEle.src = fileUrl;
		videoEle.load();
	});

/**
 * Get thumbnail of PDF url
 * @param link pdf file url
 */
export const generatePDFThumbnail = async (link: string) => {
	const { projectId } = generateIdsFromUrl();
	const payload = {
		link,
		projectId
	};
	const response = await axios.post(PDF_THUMBNAIL_ENDPOINT, payload);

	if (response.status !== 200) return new Error('Failed to generate pdf thumbnail');

	if (response.data && response.data.payload) return response.data.payload;

	return '';
};

export const scrollToView = (id: string) => {
	const element = document.querySelector(id);
	if (element) element.scrollIntoView({ block: 'nearest' });
};

/**
 * Function to return keywords based on AI generate type
 * @param aiType type of AI to be generated
 */
export const generateAIPromptKeywords = (aiType: keyof typeof EAIGenerationType) => {
	const selectRandomly = (aiKeywordList: string[]) =>
		aiKeywordList[Math.floor(Math.random() * aiKeywordList.length)] as string;

	const aiKeywords: { [key in EAIGenerationType]: string[] } = {
		SKETCH: [
			' - Create Product Design Sketch, Orthographic, Vibrant, Clean, White Background',
			' - Three Views, bauhaus, soft, futuristic industrial design,' +
				'advanced technology, strange style, ergonomic form,' +
				'continuous line sketch, line art style, high detail, wide-angle lens, 8k, industrial design, white background',
			' - detailed product design sketch, copic marker style'
		],
		MOOD_BOARD: [
			' - Create a design moodboard with objects, sizes, textures, spaces, lighting, designs, cultures, vibes, stuctures, geometry'
		],
		IMAGE: [],
		RENDER: [
			' - industrial design of a ultra modern, photo realistic render,' +
				'advertisement product photography, cinematic lighting, dynamic angle, high key lighting',
			' - industrial design ultra modern, photo realistic render,' +
				'advertisement product photography, cinematic lighting, dynamic angle, home interior background, bokeh'
		],
		THREE_D: [],
		REFINED_3D: [],
		VIDEO: [],
		PM_JOURNEY: [],
		TEXT: []
	};
	return selectRandomly(aiKeywords[aiType]) || '';
};

/**
 * Function to show appropriate snackbar for coming soon disabled ai menu
 * @param aiType
 */
export const handleDisabledAiMenu = (aiType: keyof typeof EAIGenerationType) => {
	const aiMessage = () => {
		switch (aiType) {
			case 'THREE_D':
				return '3D model AI is coming soon!';
			case 'VIDEO':
				return 'AI Video is coming soon!';
			default:
				return '';
		}
	};
	addSnackbar({
		show: true,
		type: 'NORMAL',
		text: aiMessage(),
		actionButtonData: []
	});
	removeSnackbar(3000);
};

// Type of PM-AI results from super-power api
export type PM_AI_RESULTS = {
	stages: {
		name: string;
		blocks: {
			name: string;
		}[];
	}[];
};

/**
 * Get structure of phases blocks untill the given phase while eliminating Untitled blocks and phases
 */
export const getStructureOfPreviousPhasesBlocks = (projectId: string, phaseId: string) => {
	const { projects, stages, blocks: blocksRedux } = store.getState() as ReduxState;
	const projectData = projects.data[projectId] as IProject;
	const resultStructure: PM_AI_RESULTS = { stages: [] };
	const regexUntitled = /untitled/i;
	if (projectData.children) {
		for (let i = 0; i < projectData.children?.length; i++) {
			const stageId = projectData.children[i];
			const stageData = stages.data[stageId as string] as IGroup;
			if (stageData) {
				if (stageId !== phaseId) {
					if (!regexUntitled.test(stages.data[stageId as string]?.name as string)) {
						const blocksArray = [];
						if (stageData.children?.length) {
							for (let j = 0; j < stageData.children?.length; j++) {
								const blockId = stageData.children[j] as string;
								const blockData = blocksRedux.data[blockId] as IBlock;
								const blockName = { name: blockData?.name };
								blocksArray.push(blockName);
							}
						}
						const obj = {
							name: stages.data[stageId as string]?.name as string,
							blocks: blocksArray
						};
						resultStructure.stages.push(obj);
					}
				} else {
					break;
				}
			}
		}
	}
	return resultStructure;
};

export type TPrompt = {
	prompt: string;
	phaseId: string;
	newBlockIndex: number;
	phaseIndex: number;
	aiType: string;
};

/**
 * Function to check is url is image link and size <= 20MB
 * Only then suitable for AI model
 * @param {string} url - image url
 * @returns
 */
export const isValidAIImageUrl = async (url: string) => {
	try {
		// Fetch the headers of the URL
		const response = await axios.head(url);

		// Get the Content-Type and Content-Length headers
		const contentType = response.headers['content-type'];
		const contentLength = parseInt(response.headers['content-length'], 10);

		// Check if Content-Type is an image
		const urlType = contentType.split('/');
		let isValidImage = false;

		if (urlType.length === 2)
			isValidImage =
				urlType[0] === 'image' && ['png', 'jpeg', 'gif', 'webp'].includes(urlType[1]);

		// Check if Content-Length is less than or equal to 20MB (20 * 1024 * 1024 bytes)
		const isSizeBelow20MB = contentLength <= 20 * 1024 * 1024;

		return isValidImage && isSizeBelow20MB;
	} catch (error) {
		return false;
	}
};

/**
 * Uploads a file to Firebase storage and executes callback functions upon completion or failure.
 *
 * @param {File} file - The file to be uploaded.
 * @param {string} location - The storage location/path where the file will be saved.
 * @param {(link: string) => void} onComplete - Callback function to be executed with the download URL upon successful upload.
 * @param {() => void} [onFailure] - Optional callback function to be executed in case of upload failure.
 */
export const uploadFileToFirebase = async (
	file: File,
	location: string,
	onComplete: (link: string) => void,
	onFailure?: () => void
) => {
	try {
		// Create a reference to the storage location
		const storageRef = ref(storage, location);

		// Create a resumable upload task
		const uploadTask = uploadBytesResumable(storageRef, file);

		// Wrap the upload process in a Promise
		await new Promise((resolve, reject) => {
			// Attach a listener to handle the upload completion
			uploadTask.then(() => {
				// Attempt to retrieve the download URL
				getDownloadURL(storageRef)
					.then((url: string) => {
						// Call the onComplete callback with the download URL
						onComplete(url);
						// Resolve the promise indicating successful upload
						resolve(true);
					})
					.catch((e: Error) => {
						// If an error occurs, call the onFailure callback if provided
						if (onFailure) onFailure();

						// Reject the promise indicating failure
						reject(new Error(e.message));
					});
			});
		});
	} catch (error) {
		// Log the error to the console
		console.error('Failed to upload file to Firebase: ', error);
	}
};

/**
 * Converts a given URL into a PNG image and uploads it to Firebase.
 *
 * @param {string} link - The URL to be converted into a PNG image.
 * @param {boolean} isLinkEmbedsEnabled - Flag indicating whether link embedding is enabled.
 * @param {string} fileName - The name of the file to be created.
 * @param {function} onComplete - Callback function to be called upon successful upload of the PNG file.
 *
 * @returns {Promise<void>}
 *
 * @example
 * convertLinkToPng(
 *   'https://example.com',
 *   true,
 *   'example-image',
 *   (link) => {
 *     console.log('Upload complete:', link);
 *   }
 * );
 *
 * @throws {Error} If an error occurs during the conversion or upload process.
 */
export const convertLinkToPng = async (
	link: string,
	isLinkEmbedsEnabled: boolean,
	fileName: string,
	onComplete: (link: string) => void
): Promise<void> => {
	try {
		// Check if link embedding is enabled
		if (!isLinkEmbedsEnabled) return;
		// Add protocol to link if it is not present
		const linkWithProtocol = !/^https?:\/\//i.test(link) ? `https://${link}` : link;

		// Get the link subtype and embeddability
		const subType = getFileLinkSubType(linkWithProtocol, 'LINK');
		const isEmbeddable = await isLinkEmbeddable(linkWithProtocol);
		const embedType = await getEmbedType(linkWithProtocol);
		const isValidUrl = validator.isURL(linkWithProtocol);

		// If conditions are not met, exit the function
		if (subType !== 'LINK' || isEmbeddable || embedType !== 'URL2PNG' || !isValidUrl) return;

		// Show a loading snackbar
		const convertToPngSnackbarPayload: ISnackBar = {
			text: 'Processing your link into a static PNG image. Hang tight!',
			show: true,
			type: 'LOADER'
		};
		addSnackbar(convertToPngSnackbarPayload);

		// Fetch the PNG image from Cloudinary using the provided link
		const response = await fetch(
			`https://res.cloudinary.com/demo/image/url2png/${linkWithProtocol}/url2png/viewport=900x750%7Cfullpage=false`
		);

		// If the response is OK, process the blob
		if (response.ok) {
			const uniqueName = `${fileName}-${Date.now()}`;
			const blob = await response.blob();
			// Convert blob to file
			const file = new File([blob], `${uniqueName}.png`, {
				type: blob.type
			});
			const { projectId } = generateIdsFromUrl();
			const thumbnailLocation = `${projectId}/THUMBNAIL/${uniqueName}.png`;

			// Show a loading snackbar
			const uploadingSnackbarPayload: ISnackBar = {
				text: 'Your PNG image is on its way to the cloud. Almost done...',
				show: true,
				type: 'LOADER'
			};
			addSnackbar(uploadingSnackbarPayload);

			// Upload the file to Firebase
			await uploadFileToFirebase(file, thumbnailLocation, onComplete);
		}
	} catch (error) {
		// Log any errors that occur during the fetch operation
		console.error('Failed to convert link to PNG: ', error);
	}
};

/**
 * Fetches the name of a Figma file from a given Figma file link.
 *
 * This function extracts the file key from the provided Figma file link and makes an API request
 * to the Figma API to retrieve the file name. The function assumes that the provided link is a
 * valid Figma file link and that the Figma API token used has the necessary permissions to
 * access the file.
 *
 * @param {string} link - The Figma file link.
 * @returns {Promise<string>} - A promise that resolves to the name of the Figma file.
 *
 * @example
 *
 * const fileName = await fetchFigmaFileTitle('https://www.figma.com/file/FILE_KEY/FILE_NAME');
 * console.log(fileName); // Outputs the name of the file
 */
export const fetchFigmaFileTitle = async (link: string): Promise<string> => {
	try {
		const subType = getFileLinkSubType(link, 'LINK');
		if (subType === 'FIGMA') {
			const FIGMA_API_TOKEN = process.env.REACT_APP_FIGMA_DEV_TOKEN;

			if (!FIGMA_API_TOKEN) {
				console.error('Invalid figma file token', FIGMA_API_TOKEN);
				return 'Figma';
			}

			// Extract the file key from the URL
			const fileKey = link.split('/')[4];

			// Define the API endpoint
			const endpoint = `https://api.figma.com/v1/files/${fileKey}`;

			// Make the API request
			const response = await fetch(endpoint, {
				method: 'GET',
				headers: {
					'X-Figma-Token': FIGMA_API_TOKEN
				}
			});

			if (!response.ok) {
				console.error('Failed to access file name', response);
				return 'Figma';
			}

			const data = await response.json();
			return data.name as string;
		}
		return 'UNDEFINED';
	} catch (error) {
		console.error('Failed to fetch file name: ', error);
		return 'UNDEFINED';
	}
};

/**
 * Creates an optimistic image block.
 * @param id - The block ID.
 * @param phaseId - The ID of the phase to which the block belongs.
 * @param projectId - The ID of the project to which the block belongs.
 * @param url - The URL of the image.
 * @param name - The name of the image block.
 * @returns The created image block.
 */
export const createImageBlock = (
	id: string,
	phaseId: string,
	projectId: string,
	url: string,
	name: string
): IImage => {
	const user = getUserFromRedux(); // Retrieve the current user from the Redux store
	const optimisticBlock = generateOptimisticBlock(
		id,
		phaseId,
		projectId,
		name,
		'IMAGE'
	) as IImage;

	return {
		...optimisticBlock,
		link: url || '',
		originalLink: url || '',
		thumbnail: {
			originalSrc: url || '',
			src: url || '',
			isCustom: false
		} as TBlockThumbnail,
		fileName: name || '',
		uploadedBy: user._id as string
	};
};

/**
 * Creates an optimistic link block.
 * @param id - The block ID.
 * @param phaseId - The ID of the phase to which the block belongs.
 * @param projectId - The ID of the project to which the block belongs.
 * @param url - The URL of the link.
 * @param name - The name of the link block.
 * @returns The created link block.
 */
export const createLinkBlock = (
	id: string,
	phaseId: string,
	projectId: string,
	url: string,
	name: string
): ILink => {
	const user = getUserFromRedux(); // Retrieve the current user from the Redux store
	const linkBlock = generateOptimisticBlock(id, phaseId, projectId, name, 'LINK') as ILink;

	return {
		...linkBlock,
		link: url,
		subType: getFileLinkSubType(url, 'LINK') as ILinkSubtype,
		addedBy: user._id as string
	};
};

/**
 * Creates an optimistic PDF block.
 * @param id - The block ID.
 * @param phaseId - The ID of the phase to which the block belongs.
 * @param projectId - The ID of the project to which the block belongs.
 * @param url - The URL of the PDF.
 * @param name - The name of the PDF block.
 * @returns The created PDF block.
 */
export const createPDFBlock = (
	id: string,
	phaseId: string,
	projectId: string,
	url: string,
	name: string
): IPdf => {
	const user = getUserFromRedux(); // Retrieve the current user from the Redux store
	const optimisticBlock = generateOptimisticBlock(id, phaseId, projectId, name, 'PDF') as IPdf;

	return {
		...optimisticBlock,
		link: url || '',
		originalLink: url || '',
		thumbnail: {
			originalSrc: '#FFFFFF',
			src: '#FFFFFF',
			isCustom: false
		} as TBlockThumbnail,
		fileName: name || '',
		uploadedBy: user._id as string
	};
};

/**
 * Creates an optimistic 3D block.
 * @param id - The block ID.
 * @param phaseId - The ID of the phase to which the block belongs.
 * @param projectId - The ID of the project to which the block belongs.
 * @param url - The URL of the 3D model.
 * @param name - The name of the 3D block.
 * @returns The created 3D block.
 */
export const create3DBlock = (
	id: string,
	phaseId: string,
	projectId: string,
	url: string,
	name: string
): I3D => {
	const user = getUserFromRedux(); // Retrieve the current user from the Redux store
	const optimisticBlock = generateOptimisticBlock(id, phaseId, projectId, name, 'THREE_D') as I3D;

	return {
		...optimisticBlock,
		link: [url as string],
		originalLink: [url as string],
		thumbnail: {
			src: '#FFFFFF',
			isCustom: false,
			originalSrc: '#FFFFFF'
		},
		uploadedBy: user._id as string,
		fileName: `${name}.glb` // 3D model files typically have a .glb extension
	};
};

/**
 * Creates an optimistic text block.
 * @param id - The block ID.
 * @param phaseId - The ID of the phase to which the block belongs.
 * @param projectId - The ID of the project to which the block belongs.
 * @param text - The text content of the block.
 * @param name - The name of the text block.
 * @returns The created text block.
 */
export const createTextBlock = (
	id: string,
	phaseId: string,
	projectId: string,
	text: string,
	name: string
): IText => {
	const textBlock = generateOptimisticBlock(id, phaseId, projectId, name, 'TEXT') as IText;

	return {
		...textBlock,
		text
	};
};

/**
 * Creates a block of the specified type based on the provided blockType.
 * @param blockType - The type of block to create (e.g., 'IMAGE', 'LINK', 'PDF', 'THREE_D').
 * @param phaseId - The ID of the phase to which the block belongs.
 * @param projectId - The ID of the project to which the block belongs.
 * @param url - The URL associated with the block (if applicable).
 * @param name - The name of the block.
 * @returns The created block object or undefined if the blockType is invalid.
 */
export const createBlockByType = (
	blockType: string,
	phaseId: string,
	projectId: string,
	url: string,
	name: string
): IBlock | undefined => {
	const blockId = new mongoose.Types.ObjectId().toString();
	switch (blockType) {
		case 'IMAGE':
			return createImageBlock(blockId, phaseId, projectId, url, name);
		case 'LINK':
			return createLinkBlock(blockId, phaseId, projectId, url, name);
		case 'PDF':
			return createPDFBlock(blockId, phaseId, projectId, url, name);
		case 'THREE_D':
			return create3DBlock(blockId, phaseId, projectId, url, name);
		default:
			return undefined;
	}
};
