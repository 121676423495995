import { getWebSocket } from 'src/rtc/yjs/yjsConfig';
import { generateIdsFromUrl } from 'src/redux/reduxActions/util';
import { TInvitedEmail, TUserAndRole } from '@naya_studio/types';
import getUserFromRedux from 'src/util/helper/user';
import { ISocketUser, TDragHistory } from '../useSocketUsers';
import { checkIfUserHasAccess, getBlockFromYDoc, getProjectFromYDocOrRedux } from './utils';

/**
 * Hook to highligh blocks/phases which are being reordered
 */
const useHighlightObserver = () => {
	/**
	 * Function to create the user profile displayed at right bottom of dragged element
	 * @param client dragging user
	 */
	const createUserProfileElement = (client: ISocketUser, parentId: string) => {
		const containerDiv = document.createElement('div');
		const { profile, displayName, color, userName } = client;
		containerDiv.classList.add(
			'rtc-drag-profile',
			'tw-absolute',
			'tw-rounded-full',
			'tw-border-solid',
			`tw-border-[${color}]`
		);
		containerDiv.style.borderColor = color;
		containerDiv.setAttribute('parent-id', parentId);
		containerDiv.setAttribute('user-id', client.userId);

		// If profile url exist create the img tag
		if (profile) {
			const profileWrapper = document.createElement('img');
			profileWrapper.classList.add(
				'tw-w-full',
				'tw-h-full',
				'tw-rounded-full',
				'tw-object-cover'
			);
			profileWrapper.src = profile;
			containerDiv.appendChild(profileWrapper);
		}
		// If profile url does not exist, create p with display name
		else {
			const nameWrapper = document.createElement('p');
			nameWrapper.textContent = displayName as string;
			nameWrapper.classList.add(
				'tw-font-randMedium',
				'tw-m-0',
				'tw-bg-white',
				'tw-flex',
				'tw-justify-center',
				'tw-items-center',
				'tw-w-full',
				'tw-h-full',
				'tw-rounded-full'
			);
			containerDiv.appendChild(nameWrapper);
		}

		const toolTip = document.createElement('p');
		toolTip.textContent = userName as string;
		toolTip.classList.add(
			'username-tooltip',
			'tw-bg-tootltip-color',
			'tw-flex',
			'tw-font-randMedium',
			'tw-m-0',
			'tw-justify-center',
			'tw-items-center',
			'tw-w-full',
			'tw-h-full',
			'tw-rounded'
		);
		toolTip.style.right = `${userName.length * 9}px`;
		toolTip.style.bottom = `calc(50% + 12px)`;
		containerDiv.appendChild(toolTip);

		return containerDiv;
	};

	/**
	 * Function to remove user profile from dragged item
	 * @param parentElement parent element of profile icon i.e Phase/Block Wrapper
	 * @param parentId Id of the parent element
	 * @returns boolean indicating if user was removed
	 */
	const removeUserProfileElement = (parentElement: HTMLDivElement, parentId: string) => {
		const profileIcons = parentElement.querySelectorAll(
			`.rtc-drag-profile[parent-id="${parentId}"]`
		) as NodeListOf<HTMLDivElement> | null;

		if (profileIcons) profileIcons.forEach((icon) => icon.remove());
	};

	/**
	 * Checks if a user exists in the highlighted elements within the parent element.
	 * @param parentElement The parent HTMLDivElement to search for highlighted elements.
	 * @returns The user ID if found in highlighted elements, otherwise null.
	 */
	const highlightWithUserExists = (parentElement: HTMLDivElement): string | null => {
		// Consider direct children only, preventing false positives in phaseWrapper detection.
		const directChildren = parentElement.children;

		// Iterate through direct children to find highlighted elements.
		for (let i = 0; i < directChildren.length; i++) {
			const child = directChildren[i] as HTMLDivElement;
			// Check if the child is a highlighted element.
			if (child.classList.contains('rtc-drag-profile')) return child.getAttribute('user-id');
		}

		// Return null if no highlighted elements found.
		return null;
	};

	/**
	 * Function to access user from socket users
	 * @param userId of the user to find
	 * @returns user as ISocketUser
	 */
	const getUserFromSocketUsers = (userId: string) => {
		const wsProvider = getWebSocket();

		let userToFind = wsProvider.awareness.getLocalState() as ISocketUser;
		wsProvider.awareness.states.forEach((awareness) => {
			if (awareness.userId === userId) {
				userToFind = awareness as ISocketUser;
			}
		});

		return userToFind;
	};

	/**
	 * Function to highlight block wrapper in dom
	 * @param elements dom elements
	 * @param args respective ids
	 */
	const highlightBlockWrapperInDom = (
		elements: {
			innerList: HTMLDivElement;
			blockWrapper: HTMLDivElement;
			blockWrap: HTMLDivElement;
		},
		args: {
			userId: string;
			blockId: string;
			isGuestUser: boolean;
			isOrphan: boolean;
		}
	) => {
		const { blockWrap, blockWrapper, innerList } = elements;
		const { blockId, userId, isGuestUser, isOrphan } = args;

		const existingProfileIcon = highlightWithUserExists(blockWrapper);
		if (existingProfileIcon && existingProfileIcon !== userId)
			removeUserProfileElement(blockWrapper, blockId);
		else if (existingProfileIcon && existingProfileIcon === userId) return;

		blockWrapper.style.position = 'relative';
		const awareness = getUserFromSocketUsers(userId);
		const profileIcon = createUserProfileElement(awareness, blockId);
		blockWrapper.appendChild(profileIcon);
		blockWrap.style.boxShadow = `0 0 0 3em ${awareness.color}`;
		blockWrapper.setAttribute('data-rbd-draggable-id', '');
		blockWrapper.setAttribute('highlighted-by', userId);

		// Add padding on orphan blocks to prevent profile getting cut-off
		if (isGuestUser && isOrphan) {
			innerList.style.paddingTop = '32px';
			innerList.style.paddingBottom = '32px';
		}
	};

	/**
	 * Function to remove block wrapper highlight from dom
	 * @param elements dom elements
	 * @param args respective ids
	 */
	const removeHighlightFromBlockWrapperInDom = (
		elements: {
			innerList: HTMLDivElement;
			blockWrapper: HTMLDivElement;
			blockWrap: HTMLDivElement;
		},
		args: {
			blockId: string;
			isGuestUser: boolean;
			isOrphan: boolean;
		}
	) => {
		const { blockWrap, blockWrapper, innerList } = elements;
		const { blockId, isGuestUser, isOrphan } = args;

		blockWrapper.style.position = 'static';
		// If userId does not exist that means drag stop and un-highlight the block
		removeUserProfileElement(blockWrapper, blockId);
		blockWrapper.setAttribute('data-rbd-draggable-id', blockId);
		blockWrapper.removeAttribute('highlighted-by');
		blockWrap.style.boxShadow = `0 2em 6em 2em #0000000a, 0 1em 2em 0 #00000012`;

		if (isGuestUser && isOrphan) {
			innerList.style.paddingTop = '0.25rem';
			innerList.style.paddingBottom = '0.25rem';
		}
	};

	/**
	 * Function to highlight dragged block
	 * @param projectId id of the project
	 * @param blockId if of the block to highlight
	 * @param userId if of the user whose dragging the block
	 */
	const toggleHighlightOfBlock = (blockId: string, isGuestUser: boolean, userId?: string) => {
		try {
			const blockWrapper = document.querySelector(
				`#block-wrapper[data-blockid="${blockId}"]`
			) as HTMLDivElement | null;
			const innerList = document.querySelector(
				`.inner-list[data-blockid="${blockId}"]`
			) as HTMLDivElement | null;

			let isOrphan = false;

			if (innerList) {
				isOrphan = innerList.getAttribute('data-isorphan') === 'true';
			}

			if (blockWrapper && innerList) {
				const blockWrap = blockWrapper.querySelector(
					'.block-wrap'
				) as HTMLDivElement | null;

				if (blockWrap) {
					const elements = {
						blockWrap,
						blockWrapper,
						innerList
					};
					const args = {
						blockId,
						isOrphan,
						isGuestUser
					};

					// If userId exist that means drag start and highlight the block
					if (userId) highlightBlockWrapperInDom(elements, { ...args, userId });
					else removeHighlightFromBlockWrapperInDom(elements, args);
				}
			}
		} catch (error) {
			console.error('Error highlighting block : ', error);
		}
	};

	/**
	 * Function to highlight phase wrapper in dom
	 * @param elements dom elements
	 * @param args respective ids
	 */
	const highlightPhaseWrapperInDom = (
		elements: {
			phaseWrapper: HTMLDivElement;
		},
		args: {
			userId: string;
			phaseId: string;
		}
	) => {
		const { phaseWrapper } = elements;
		const { userId, phaseId } = args;

		// Check if the browser is Safari
		const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

		const existingProfileIcon = highlightWithUserExists(phaseWrapper);
		if (existingProfileIcon && existingProfileIcon !== userId)
			removeUserProfileElement(phaseWrapper, phaseId);
		else if (existingProfileIcon && existingProfileIcon === userId) return;

		const awareness = getUserFromSocketUsers(userId);
		const profileIcon = createUserProfileElement(awareness, phaseId);
		// 19em for half of the total container width
		// 12em for the gap between block and phase
		profileIcon.style.right = '31em';
		profileIcon.style.bottom = '-19em';
		profileIcon.style.zIndex = '2';
		phaseWrapper.appendChild(profileIcon);

		// Fix for Safari V16.0
		if (isSafari) phaseWrapper.style.margin = '2em';
		phaseWrapper.style.boxShadow = `0 0 0 3em ${awareness.color}`;
		phaseWrapper.closest('.phase-wrapper')?.setAttribute('draggable', 'false');
		phaseWrapper.setAttribute('highlighted-by', userId);

		// Prevent re-order for blocks inside phase
		const phaseBlocks = phaseWrapper.querySelectorAll('#block-wrapper');
		phaseBlocks.forEach((blockWrapper) => {
			blockWrapper.closest('.block-draggable')?.setAttribute('draggable', 'false');
		});
	};

	/**
	 * Function to remove phase wrapper highlight from dom
	 * @param elements dom elements
	 * @param args respective ids
	 */
	const removeHighlightFromPhaseWrapperInDom = (
		elements: {
			phaseWrapper: HTMLDivElement;
		},
		args: {
			phaseId: string;
		}
	) => {
		const { phaseWrapper } = elements;
		const { phaseId } = args;

		// Check if the browser is Safari
		const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

		// If userId does not exist that means drag stop and un-highlight the stage
		removeUserProfileElement(phaseWrapper, phaseId);
		phaseWrapper.closest('.phase-wrapper')?.setAttribute('draggable', 'true');
		phaseWrapper.removeAttribute('highlighted-by');
		phaseWrapper.style.boxShadow = 'none';
		if (isSafari) phaseWrapper.style.margin = '0em';

		// Resume re-order for blocks inside phase
		const phaseBlocks = phaseWrapper.querySelectorAll('#block-wrapper');
		phaseBlocks.forEach((blockWrapper) => {
			const blockId = blockWrapper.getAttribute('data-blockid');

			if (blockId) {
				blockWrapper.closest('.block-draggable')?.setAttribute('draggable', 'true');
			}
		});
	};

	/**
	 * Function to highlight dragged phase
	 * @param phaseId if of the phase to highlight
	 * @param userId if of the user whose dragging the phase
	 */
	const toggleHighlightOfPhase = (phaseId: string, userId?: string) => {
		try {
			const phaseWrapper = document.querySelector(
				`#phase-rbd[data-phaseid="${phaseId}"]`
			) as HTMLDivElement | null;

			if (phaseWrapper) {
				const elements = {
					phaseWrapper
				};
				const args = {
					phaseId
				};

				// If userId exist that means drag start and highlight the stage
				if (userId) highlightPhaseWrapperInDom(elements, { ...args, userId });
				else removeHighlightFromPhaseWrapperInDom(elements, args);
			}
		} catch (error) {
			console.error('Error highlighting stage : ', error);
		}
	};

	/**
	 * Clears DOM highlights for blocks and phases based on drag history and project access.
	 * @param selfDrags Array of IDs of elements being dragged by the current user.
	 * @param blocksToHighlight Object containing block IDs as keys and user IDs as values for blocks to be highlighted.
	 * @param phasesToHighlight Object containing phase IDs as keys and user IDs as values for phases to be highlighted.
	 * @param hasProjectAccess Boolean indicating whether the current user has access to the project.
	 * @param hasGuestAccess Boolean indicating whether the project has guest access enabled.
	 */
	const clearDomHighlights = (
		selfDrags: string[],
		blocksToHighlight: { [key: string]: string },
		phasesToHighlight: { [key: string]: string },
		hasProjectAccess: boolean,
		hasGuestAccess: boolean
	) => {
		const user = getUserFromRedux();

		/**
		 * Clears highlights for entities based on their drag status and current highlights.
		 * @param type Type of item to highlight
		 * @param wrapperSelector CSS selector for the wrappers of the entities.
		 * @param getId Function to extract the ID from an entity wrapper.
		 * @param isCurrentlyBeingDragged Function to determine if an entity is currently being dragged.
		 */
		const clearHighlights = (
			type: 'BLOCK' | 'PHASE',
			wrapperSelector: string,
			getId: (wrapper: HTMLDivElement) => string,
			isCurrentlyBeingDragged: (id: string) => boolean
		) => {
			const wrappers = document.querySelectorAll(
				wrapperSelector
			) as NodeListOf<HTMLDivElement>;
			wrappers.forEach((wrapper) => {
				const id = getId(wrapper);
				const highlightedBy = wrapper.getAttribute('highlighted-by');

				const isDraggedByUser = selfDrags.includes(id);
				const isCurrentlyDragged = isCurrentlyBeingDragged(id);
				const isStillHighlighted = Boolean(highlightedBy);

				if (!isDraggedByUser && !isCurrentlyDragged && isStillHighlighted) {
					if (type === 'BLOCK') {
						const block = getBlockFromYDoc(id);
						const hasBlockAccess = checkIfUserHasAccess(
							user,
							block?.users as TUserAndRole[],
							block?.invitedEmails as TInvitedEmail[]
						);
						const hasAccess = hasBlockAccess || hasProjectAccess;
						const isGuestUser = !hasAccess && hasGuestAccess;

						toggleHighlightOfBlock(id, isGuestUser);
					} else if (type === 'PHASE') toggleHighlightOfPhase(id);
				}
			});
		};

		// Clear block highlights
		clearHighlights(
			'BLOCK',
			'#block-wrapper',
			(wrapper) => wrapper.getAttribute('data-blockid') as string,
			(id) => Boolean(blocksToHighlight[id])
		);

		// Clear phase highlights
		clearHighlights(
			'PHASE',
			'#phase-rbd',
			(wrapper) => wrapper.getAttribute('data-phaseid') as string,
			(id) => Boolean(phasesToHighlight[id])
		);
	};

	/**
	 * Observer to listen to highlight changes in ymap
	 * @param update highlight change to do in redux
	 */
	const highlightObserver = () => {
		try {
			const user = getUserFromRedux();
			const { projectId: pid } = generateIdsFromUrl();
			const webSocket = getWebSocket();
			const socketUsers = webSocket.awareness.getStates();
			const blocksToHighlight: { [key: string]: string } = {};
			const phasesToHighlight: { [key: string]: string } = {};
			const selfDrags: string[] = [];
			const project = getProjectFromYDocOrRedux(pid);
			const hasProjectAccess = checkIfUserHasAccess(
				user,
				project?.users as TUserAndRole[],
				project?.invitedEmails as TInvitedEmail[]
			);

			socketUsers.forEach((awareness) => {
				const socketUser = awareness as ISocketUser;
				const { userId, projectId } = socketUser;

				// Skip if drag is not related to the current project or no dragHistory available
				if (projectId !== pid || !socketUser?.dragHistory) return;
				const { dragHistory } = socketUser;

				if (userId === user._id) {
					selfDrags.push(...Object.keys(dragHistory));
					return;
				}

				Object.entries(dragHistory).forEach(([key, value]) => {
					const { type } = value;
					if (type === 'BLOCK') blocksToHighlight[key] = userId;
					else if (type === 'PHASE') phasesToHighlight[key] = userId;
				});
			});

			clearDomHighlights(
				selfDrags,
				blocksToHighlight,
				phasesToHighlight,
				hasProjectAccess,
				project?.hasGuestAccess || false
			);

			// Highlight blocks
			Object.entries(blocksToHighlight).forEach(([key, userId]) => {
				const block = getBlockFromYDoc(key);
				const hasBlockAccess = checkIfUserHasAccess(
					user,
					block?.users as TUserAndRole[],
					block?.invitedEmails as TInvitedEmail[]
				);
				const hasAccess = hasBlockAccess || hasProjectAccess;
				const isGuestUser = !hasAccess && (project?.hasGuestAccess || false);

				toggleHighlightOfBlock(key, isGuestUser, userId);
			});

			// Highlight phases
			Object.entries(phasesToHighlight).forEach(([key, userId]) =>
				toggleHighlightOfPhase(key, userId)
			);
		} catch (error) {
			console.error('Error in Highlight Listener : ', error);
		}
	};

	/**
	 * Callback to handle highlights on dragged element
	 * @param operation To highlight or to remove highlight
	 * @param type BLOCK | PHASE
	 * @param draggableId id of the dragged element
	 */
	const handleHighlightForDraggedElement = (
		operation: 'INSERT' | 'DELETE',
		type: 'BLOCK' | 'PHASE',
		draggableId: string
	) => {
		try {
			const user = getUserFromRedux();
			const websocket = getWebSocket();
			const { awareness } = websocket;

			if (awareness?.getLocalState()?.userId !== (user._id as string)) return;

			const updateDragHistory = (newDragHistory: TDragHistory) => {
				awareness.setLocalStateField('dragHistory', newDragHistory);
			};

			if (operation === 'INSERT') {
				const socketUser = awareness?.getLocalState() as ISocketUser;
				const dragHistory = socketUser?.dragHistory;
				const dragCount = dragHistory?.[draggableId]?.count || 0;
				const newDragHistory = {
					...dragHistory,
					[draggableId]: {
						type,
						count: dragCount + 1,
						draggedBy: user._id as string,
						startedAt: Date.now()
					}
				};

				if (Object.keys(newDragHistory).length > 1) {
					Object.entries(newDragHistory).forEach(([key, value]) => {
						if (key === draggableId) return;

						const { startedAt } = value;
						const timeAway = Date.now() - startedAt;
						const secondsSinceAnotherDrag = timeAway / 1000;

						// If user has more than 1 active drag in his awareness
						// and any of the drag was started more than 3 seconds ago, clear it
						if (secondsSinceAnotherDrag > 3) delete newDragHistory[key];
					});
				}

				updateDragHistory(newDragHistory);
			} else if (operation === 'DELETE') {
				setTimeout(() => {
					const socketUser = awareness?.getLocalState() as ISocketUser;
					const dragHistory = socketUser?.dragHistory;
					const draggedItem = dragHistory?.[draggableId];
					const dragCount = draggedItem?.count || 0;
					const isDragging = dragCount !== 1;

					if (draggedItem && draggedItem?.draggedBy === (user._id as string)) {
						const newDragHistory = { ...dragHistory };
						if (!isDragging) delete newDragHistory[draggableId];
						else
							newDragHistory[draggableId] = {
								type,
								count: dragCount - 1,
								draggedBy: user._id as string,
								startedAt: Date.now() - 1500
							};
						updateDragHistory(newDragHistory);
					}
				}, 1500);
			}
		} catch (error) {
			console.error('Error updating awareness state : ', error);
		}
	};

	/**
	 * Function to remove highlight from all dragged, edit block/phases of active user
	 */
	const removeHighlightsOfUser = () => {
		try {
			const webSocket = getWebSocket();

			if (webSocket) {
				const socketUser = webSocket?.awareness?.getLocalState() as ISocketUser;

				if (socketUser?.dragHistory) {
					webSocket.awareness.setLocalStateField('dragHistory', null);
				}
			}
		} catch (highlightError) {
			console.error('Failed to remove user highlights', highlightError);
		}
	};

	return {
		highlightObserver,
		handleHighlightForDraggedElement,
		removeHighlightsOfUser
	};
};

export default useHighlightObserver;
