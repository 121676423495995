import { TUserAndRole, TInvitedEmail, INode } from '@naya_studio/types';
import * as Y from 'yjs';
import { TChangeType, getYDoc } from 'src/rtc/yjs/yjsConfig';
import { useDispatch } from 'react-redux';
import { editNodeById, addNode, deleteNodeById } from 'src/redux/features/nodes';
import getUserFromRedux from 'src/util/helper/user';
import {
	broadcastSelfDispatch,
	checkIfUserHasAccess,
	getBlockFromYDoc,
	getProjectFromYDocOrRedux
} from './utils';

/**
 * Hook to update ndoe in redux after yjs listens to node change
 */
const useNodeObserver = () => {
	const dispatch = useDispatch();

	/**
	 * Observer to listen to node changes in ymap
	 * @param update node change to do in redux
	 */
	const nodeObserver = (update: Y.YMapEvent<string>) => {
		try {
			const user = getUserFromRedux();
			const doc = getYDoc();

			update.changes.keys.forEach((change: TChangeType<string>, key: string) => {
				if (change.action === 'update') {
					const nodeAfterUpdate = JSON.parse(
						doc.getMap('nodes').get(key) as string
					) as INode;
					// console.info('RTC - Received node update', nodeAfterUpdate);
					const selfDispatch = nodeAfterUpdate.lastUpdatedBy === (user._id as string);
					if (selfDispatch) {
						broadcastSelfDispatch(nodeAfterUpdate, 'EDIT', 'NODE');
						return;
					}

					// If block is not present in ydoc take the redux block
					// this case might happen when we update note after server is just started
					// and block is not in ydoc
					const block = getBlockFromYDoc(nodeAfterUpdate.blockId as string);
					let hasAccess = true;

					const projectId = nodeAfterUpdate.projectId || block.projectId;
					const project = getProjectFromYDocOrRedux(projectId);
					const hasGuestAccess = block.hasGuestAccess || project?.hasGuestAccess || false;

					if (block?.users || block?.invitedEmails) {
						hasAccess =
							checkIfUserHasAccess(
								user,
								block.users as TUserAndRole[],
								block.invitedEmails as TInvitedEmail[]
							) || hasGuestAccess;
					}

					if (hasAccess) dispatch(editNodeById(nodeAfterUpdate));
				} else if (change.action === 'add') {
					const nodeToAdd = JSON.parse(doc.getMap('nodes').get(key) as string) as INode;
					// console.info('RTC - Received node add', nodeToAdd);
					const blockId = nodeToAdd.blockId as string;
					const block = getBlockFromYDoc(blockId);
					const selfDispatch = nodeToAdd.lastUpdatedBy === (user._id as string);
					if (selfDispatch) {
						broadcastSelfDispatch(nodeToAdd, 'ADD', 'NODE');
						return;
					}

					let hasAccess = true;
					const projectId = nodeToAdd.projectId || block.projectId;
					const project = getProjectFromYDocOrRedux(projectId);
					const hasGuestAccess = block.hasGuestAccess || project?.hasGuestAccess || false;

					if (block?.users || block?.invitedEmails) {
						hasAccess =
							checkIfUserHasAccess(
								user,
								block.users as TUserAndRole[],
								block.invitedEmails as TInvitedEmail[]
							) || hasGuestAccess;
					}

					// Only load the block if user has access to it
					if (hasAccess) dispatch(addNode(nodeToAdd));
				} else if (change.action === 'delete') {
					const nodeBeforeDelete = JSON.parse(change.oldValue) as INode;
					// console.info('RTC - Received node delete', nodeBeforeDelete);
					const blockId = nodeBeforeDelete.blockId as string;
					const block = getBlockFromYDoc(blockId);
					const selfDispatch = nodeBeforeDelete.lastUpdatedBy === (user._id as string);
					if (selfDispatch) {
						broadcastSelfDispatch(key, 'DELETE', 'NODE');
						return;
					}

					let hasAccess = true;
					const projectId = nodeBeforeDelete.projectId || block.projectId;
					const project = getProjectFromYDocOrRedux(projectId);
					const hasGuestAccess = block.hasGuestAccess || project?.hasGuestAccess || false;

					if (block?.users || block?.invitedEmails) {
						hasAccess =
							checkIfUserHasAccess(
								user,
								block.users as TUserAndRole[],
								block.invitedEmails as TInvitedEmail[]
							) || hasGuestAccess;
					}

					// Only delete the node if user has access to it's parent block
					if (hasAccess) dispatch(deleteNodeById(key));
				}
			});
		} catch (error) {
			console.error('Error in Node Listener : ', error);
		}
	};

	return nodeObserver;
};

export default useNodeObserver;
