import {
  FormEvent, useEffect, useMemo, useState,
} from 'react';
import {
  Form,
  InputGroup,
  FormControl,
  Button,
  Col,
  Row,
  Modal,
} from 'react-bootstrap';
import Select, { OptionsType, components } from 'react-select';
import { Oval } from 'react-loader-spinner';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { Layer, Feature } from 'react-mapbox-gl';
import { store } from 'src';
import { CustomDispatch } from 'src/redux/actions/types';
import {
  DisplayPrediction, EstimationPayload, CancelPrediction, toggleEstimateSnackBar,
} from 'src/redux/actions/estimateAI';
import trackEvent from 'src/util/analytics/analytics';
import { SuperPowerEvents } from 'src/util/analytics/events';
import {
	Dimensions,
	defaultDimensions,
	materialsList,
  } from '../../utils/EstimateAI.config';
import { ReactComponent as Cross } from '../../../../assets/cross.svg';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'mapbox-gl/dist/mapbox-gl.css';
import issue from '../../../../assets/issue.png';
import DownArrow from '../../../../assets/icons/DownArrow.svg';
import { Map } from '../../project/HomebaseProject';
import NumberInput from '../../utils/NumberInput';
import '../../utils/ESform.scss';
import active_location from '../../../../assets/icons/active_location-dot.svg';
import inactive_location from '../../../../assets/icons/inactive_location-dot.svg';
import AlertIcon from '../../../../assets/Alert.svg';
import loaderImage from '../../../../assets/icons/Searching.svg';
import mapboxstyle from '../../utils/mapboxstyles.json';
import FrameTwo from '../../../../assets/Frame306.svg';
// import { RoundTo } from "./EstimateAIChat";
// import { getUserProjects } from "./subComponents/AggregateProjectForTable";

// export type EstimateAIFormProps = {
// setEstimate: (est: EstimateDisplay) => void;
// setIsLoading: (boo: boolean) => void;
// setSubmitted: (boo: boolean) => void;
// connectedProject?: string;
// setConnectedProject: (str: string) => void;
// setShowConnectProject: (boo: boolean) => void;
// };

function MyVerticallyCenteredModal(props: any) {
  const { onHide, show } = props;
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        className="modal-header border-0"
        style={{ marginTop: '1rem' }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          <h5> Report issue with estimation results</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ marginBottom: '1.5rem' }}>
        <p style={{ fontWeight: '400', fontSize: '14px' }}>Do you want to report issue with estimation results?</p>
      </Modal.Body>
      <Modal.Footer
        className="modal-footer border-0"
      >
        <Button
          style={{ backgroundColor: 'white', color: 'black', border: 'none' }}
          onClick={onHide}
        >
          <span className="ModalBtn">Cancel</span>

        </Button>
        <Button
          style={{
            backgroundColor: 'var(--theme-color-1)', color: 'white', padding: '8px 16px', border: 'none',
          }}
          onClick={onHide}
        >
          <span className="ModalBtn">Confirm report</span>

        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export function capitalizeWords(words: string | Array<string>) {
  if (typeof words === 'string') {
    return words.slice(0, 1).toUpperCase() + words.substring(1);
  }
  return words
    .map((word) => word.slice(0, 1).toUpperCase() + word.substring(1))
    .join(' ');
}

function getComponent(isFocus: boolean) {
  /* eslint-disable react/jsx-props-no-spreading */
  return function DropdownIndicator(props : any) {
    return (
      <components.DropdownIndicator {...props} className={`es-dropdown ${isFocus && 'active'}`}>
        <img src={DownArrow} alt="" />
      </components.DropdownIndicator>
    );
  };
}

const MultiValueRemove = (props: any) => (
  <components.MultiValueRemove {...props}>
    <Cross style={{ color: '#5D5D5D' }} />
  </components.MultiValueRemove>
);

export default function EstimateAIForm() {
  // setIsLoading,
  // setEstimate,
  // setSubmitted,
  // }:
  // connectedProject,
  // setConnectedProject,
  // setShowConnectProject,
  // EstimateAIFormProps)
  // {

  const dimensionLabels: Array<'Length' | 'Width' | 'Height'> = [
    'Length',
    'Width',
    'Height',
  ];

  const clearedDimensions = {
    Height: 0,
    Width: 0,
    Length: 0,
  };

  const [dimensions, setDimensions] = useState(clearedDimensions);
  const [modalShow, setModalShow] = useState(false);
  const [estLocation, setEstLocation] = useState('');
  const [furniture, setFurniture] = useState('');
  const [coords, setCoords] = useState({
    lat: 0,
    long: 0,
  });
  const [materials, setMaterials] = useState<OptionsType<SelectOptions>>([]);
  const [estQuantity, setEstQuantity] = useState(0);
  const [estFormSubmit, setEstFormSubmit] = useState(false);
  const [materialFocus, setMaterialFocus] = useState(false);
  const [objectFocus, setObjectFocus] = useState(false);
  const [locationFocus, setLocationFocus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const estimateAIData = store.getState().estimateAI;

  const selectStyles = {
    control: (base: any) => ({
      ...base,
      fontSize: '12px',
      color: '#161616',
      lineHeight: '16px',
      fontWeight: '400px',
      borderColor: estFormSubmit && materials.length <= 0 && materialFocus === false ? '#E34300' : '#D1D1D1',
      boxShadow: 'none',
      '&:hover': {
        cursor: 'pointer',
      },
      '&:focused': {
        borderColor: 'var(--theme-color-with-opacity-2)',
        boxShadow: '0 0 0 0.1rem var(--theme-color-with-opacity-2)',
      },
    }),
    option: (styles: any) => ({
      ...styles,
      fontSize: '12px',
      color: '#161616',
      lineHeight: '16px',
      fontWeight: '400px',
      borderColor: '#D1D1D1',
      textTransform: 'capitalize',
      '&:hover': {
        backgroundColor: '#EAEAEA;',
        cursor: 'pointer',
      },
      '&:active': {
        backgroundColor: '#EAEAEA;',
        checked: 'true',
      },
      '&:focused': {
        borderColor: 'var(--theme-color-with-opacity-2)',
        boxShadow: '0 0 0 0.1rem var(--theme-color-with-opacity-2)',
        font: '700, bold',
      },
    }),
    placeholder: (base:any) => ({
      ...base,
      position: 'absolute',
      left: '10px',
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '16px',
      color: '#9F9F9F',
    }),
    multiValue: (base: any) => ({
      ...base,
      textTransform: 'capitalize',
    }),
    multiValueRemove: (styles: any) => ({
      ...styles,
      color: '#606060',
      ':hover': {
        backgroundColor: 'none',
      },
    }),
  };

  const selectObjectStyles = {
    control: (base: any) => ({
      ...base,
      fontSize: '12px',
      color: '#161616',
      lineHeight: '16px',
      fontWeight: '400px',
      borderColor: estFormSubmit && furniture.length <= 0 && objectFocus === false ? '#E34300' : '#D1D1D1',
      boxShadow: 'none',
      '&:hover': {
        cursor: 'pointer',
      },
      '&:focused': {
        borderColor: '#cdaaff',
        boxShadow: '0 0 0 0.1rem #cdaaff',
      },
    }),
    option: (styles: any) => ({
      ...styles,
      fontSize: '12px',
      color: '#161616',
      lineHeight: '16px',
      fontWeight: '400px',
      borderColor: '#D1D1D1',
      '&:hover': {
        backgroundColor: '#EAEAEA;',
        cursor: 'pointer',
      },
      '&:active': {
        backgroundColor: '#EAEAEA;',
        checked: 'true',
      },
      '&:focused': {
        borderColor: ' #cdaaff',
        boxShadow: '0 0 0 0.1rem #cdaaff',
        font: '700, bold',
      },
    }),
    placeholder: (base:any) => ({
      ...base,
      position: 'absolute',
      left: '10px',
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '16px',
      color: '#9F9F9F',
    }),
  };

  // connected project image
  // const connectedProjectImage = useMemo(() => {
  //   const projects = getUserProjects(user);
  //   return projects?.find((proj) => proj._id === connectedProject)?.image;
  // }, [user, connectedProject]);

  const validForm = useMemo(() => {
    if (
      coords.lat !== 0
      && coords.long !== 0
      && dimensions.Height
      && dimensions.Width
      && dimensions.Length
      && materials.length > 0
      && furniture
      && estQuantity > 0
    ) return true;

    return false;
  }, [coords, dimensions, materials, furniture, estQuantity]);

  useEffect(()=>{
    if(!coords.lat && !coords.long){
      navigator.geolocation.getCurrentPosition(position => {
        setCoords({
          long: position.coords.longitude,
          lat: position.coords.latitude
       })
      }, (error) => console.log(error), {maximumAge:60000, timeout:5000, enableHighAccuracy:true})
    }
  },[coords])

  useEffect(() => {
    if (dimensions.Height !== 0 || dimensions.Width !== 0 || dimensions.Length !== 0) {
      window.localStorage.setItem('estimateDimension', JSON.stringify(dimensions));
    }
    if (coords.lat || coords.long !== 0) { window.localStorage.setItem('estimateCoords', JSON.stringify(coords)); }
    if (estLocation) window.localStorage.setItem('estimateLocation', JSON.stringify(estLocation));
    if (furniture) window.localStorage.setItem('estimateFurniture', JSON.stringify(furniture));
    if (estQuantity > 0) window.localStorage.setItem('estimateQuantity', JSON.stringify(estQuantity));
    if (materials.length > 0) window.localStorage.setItem('estimateMaterials', JSON.stringify(materials));
  }, [dimensions, coords, estLocation, furniture, estQuantity, materials]);

  useEffect(() => {
    setDimensions(JSON.parse(window.localStorage.getItem('estimateDimension') || JSON.stringify(dimensions)));
    setCoords(JSON.parse(window.localStorage.getItem('estimateCoords') || JSON.stringify(coords)));
    setEstLocation(JSON.parse(window.localStorage.getItem('estimateLocation') || JSON.stringify(estLocation)));
    setFurniture(JSON.parse(window.localStorage.getItem('estimateFurniture') || JSON.stringify(furniture)));
    setEstQuantity(JSON.parse(window.localStorage.getItem('estimateQuantity') || JSON.stringify(estQuantity)));
    setMaterials(JSON.parse(window.localStorage.getItem('estimateMaterials') || JSON.stringify(materials)));

    trackEvent(SuperPowerEvents.OPEN_ESTIMATES);
  }, []);

  window.onunload = () => {
    // Clear the local storage
    window.localStorage.clear();
  };

  function backgroundStyle() {
    if (estimateAIData.predictedPrice === 0) {
      if (isLoading) {
        return 'none';
      }

      return `no-repeat url(${FrameTwo}) center`;
    }
    return 'none';
  }
  // resets all inputs and un-connect project
  // function resetForm() {
  //   setFurniture('')
  //   setMaterials([])
  //   setEstQuantity(0)
  //   setDimensions(clearedDimensions)
  //   setConnectedProject('')
  // }

  // set default dimensions when furniture changes
  useEffect(() => {
    const dimension : Dimensions | undefined = defaultDimensions[furniture];

    if (!furniture || !dimension) return;

    const [Length, Width, Height] = dimension;

    setDimensions({
      Height,
      Width,
      Length,
    });
  }, [furniture]);

  // All furniture selects
  const furnitureOptions = useMemo(() => {
    const options = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const key in defaultDimensions) {
      // eslint-disable-next-line no-prototype-builtins
      if (defaultDimensions.hasOwnProperty(key)) {
        const label = key.replace(/[_/-]/g, ' ').split(' ');
        const obj = {
          value: key,
          label: capitalizeWords(label),
        };
        options.push(obj);
      }
    }
    return options;
  }, []);

  // Prepares material array for react-select
  const materialOptions = useMemo(() => materialsList.map((material) => ({
    value: material,
    label: material,
  })), []);

  const onSubmit = async (e: MouseEvent | FormEvent) => {
    setEstFormSubmit(true);
    e.preventDefault();
    if (validForm) {
      try {
        setIsLoading(true);

        const { Length, Width, Height } = dimensions;

        const payload : EstimationPayload = {
          category: [furniture],
          dimensions: [Length, Width, Height],
          materials: materials.map((m) => m.value),
          quantity: estQuantity,
        };

        await (store.dispatch as CustomDispatch)(toggleEstimateSnackBar(true));
        await (store.dispatch as CustomDispatch)(DisplayPrediction(payload));
      } catch (err : any) {
        await (store.dispatch as CustomDispatch)(CancelPrediction());
      } finally {
        setIsLoading(false);

        trackEvent(SuperPowerEvents.RUN_ESTIMATES);
      }
    }
  };

  const handleLocationFocus = () => {
    const Input = document.getElementById('addressField') as HTMLInputElement;
    Input.focus();
  };

  // const ValueContainer = (props : any) => {
  //   const length = props.getValue().length;
  //   const children = props.children;
  //   return ( length?
  //             <components.ValueContainer {...props}></components.ValueContainer> :
  //           <div style={{display:'flex'}}>
  //             <span  style={{padding:'4px 4px', borderRight:'1px solid #E2E2E2'}}><img src={Search} alt="" /></span>
  //             {/* <div style={{width:'fit-content'}}>{children.slice()}</div>        */}
  //             <components.ValueContainer {...props}>{children}</components.ValueContainer>
  //           </div>
  //   )
  // };

  const estLocationChange = (location: string) => {
    setEstLocation(location);
  };

  const estSelect = (selectLocation: string) => {
    setEstLocation(selectLocation);
    geocodeByAddress(selectLocation)
      .then(async (results: any) => {
        const coordsResult = await getLatLng(results[0]);
        setCoords({
          lat: (coordsResult.lat * 1000) / 1000,
          long: (coordsResult.lng * 1000) / 1000,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <div className="estimateContainer">
        <Col
          lg={4}
          xs={12}
          className="estimate"
          style={{ marginRight: '16px' }}
        >
          <h4 className="estimateHeader" style={{ marginBottom: '1rem' }}>Estimation Criteria</h4>
          <hr />
          <Form onSubmit={onSubmit} noValidate>
            <Form.Group>
              <Row>
                <Col xl={4} style={{ paddingRight: '0' }}>
                  <Form.Label>Object Type</Form.Label>
                </Col>
                <Col xl={8}>
                  <Select
                    isClearable={false}
                    closeMenuOnSelect
                    options={furnitureOptions}
                    styles={selectObjectStyles}
                    className="react-select"
                    id="objectType"
                    // menuIsOpen={true}
                    onChange={(opt) => {
                      setFurniture(opt!.value);
                    }}
                    value={{
                      value: furniture || '',
                      label: furniture ? capitalizeWords(furniture) : '',
                    }}
                    onFocus={() => setObjectFocus(true)}
                    onBlur={() => setObjectFocus(false)}
                    classNamePrefix="react-select"
                    placeholder="Select object type"
                    components={{
                      DropdownIndicator: getComponent(objectFocus),
                      IndicatorSeparator: () => null,
                    }}
                  />
                  {estFormSubmit && furniture.length <= 0 && objectFocus === false && (
                  <div>
                    <img src={AlertIcon} alt="" />
                    <span className="FieldWarnings">Select object Type to continue</span>
                  </div>
                  )}
                </Col>
              </Row>
            </Form.Group>
            <Form.Group>
              <Row>
                <Col xl={4}>
                  <Form.Label>Number of units</Form.Label>
                </Col>
                <Col xl={8}>
                  <NumberInput
                    name="number of units"
                    id="quantity"
                    onChange={(num) => { setEstQuantity(num > 0 ? num : 0); }}
                    value={estQuantity}
                    formSubmit={estFormSubmit}
                    placeholder="1"
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group style={{ margin: '0.5rem 0 1rem 0' }}>
              <Row>
                <Col xl={4}>
                  <Form.Label>Materials</Form.Label>
                </Col>
                <Col xl={8}>
                  <Select
                    isClearable={false}
                    styles={selectStyles}
                    closeMenuOnSelect={false}
                    options={materialOptions}
                    isMulti
                    onFocus={() => setMaterialFocus(true)}
                    onBlur={() => setMaterialFocus(false)}
                    hideSelectedOptions={false}
                    value={materials}
                    onChange={(opt) => setMaterials(opt)}
                    className="react-select"
                    classNamePrefix="react-select"
                    id="materials"
                    placeholder="Search or select from options"
                    components={{
                      DropdownIndicator: getComponent(materialFocus),
                      // ValueContainer : ValueContainer,
                      IndicatorSeparator: () => null,
                      MultiValueRemove,
                    }}
                  />
                  { estFormSubmit && materials.length <= 0 && materialFocus === false && (
                  <div>
                    <img src={AlertIcon} alt="" />
                    <span className="FieldWarnings">Select materials to continue</span>
                  </div>
                  )}
                  {/* </InputGroup> */}
                </Col>
              </Row>
            </Form.Group>
            <hr />
            {dimensionLabels.map((label, index) => (
              <Form.Group key={label}>
                <Row>
                  <Col xl={4}>
                    <Form.Label>{label}</Form.Label>
                  </Col>
                  <Col xl={8}>
                    <NumberInput
                      name={dimensionLabels[index]?.toLowerCase()}
                      id={dimensionLabels[index]}
                      onChange={(num) => {
                        setDimensions((prev) => ({
                          ...prev,
                          [label]: num,
                        }));
                      }}
                      value={dimensions[label]}
                      placeholder="3"
                      numberSuffix="inches"
                      formSubmit={estFormSubmit}
                    />
                  </Col>
                </Row>
              </Form.Group>
            ))}
            <Form.Group style={{ marginTop: '1rem' }}>
              <Row>
                <Col xl={4}>
                  <Form.Label>Location</Form.Label>
                </Col>
                <Col xl={8}>
                  <PlacesAutocomplete
                    value={estLocation}
                    onChange={estLocationChange}
                    onSelect={estSelect}
                    searchOptions={{ types: ['locality', 'country'] }}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <InputGroup
                          onFocus={() => setLocationFocus(true)}
                          onBlur={() => setLocationFocus(false)}
                          id="locationField"
                          style={{
                            border: !estLocation && estFormSubmit && locationFocus === false
                              ? '1px solid #e34300' : '1px solid #d1d1d1',
                          }}
                        >
                          <InputGroup.Text
                            style={{
                              backgroundColor: 'transparent',
                              borderRight: '0px',
                              borderRadius: '4px 0 0 4px',
                            }}
                            onClick={handleLocationFocus}
                          >
                            <img src={coords.lat && coords.long ? active_location : inactive_location} alt="" />

                          </InputGroup.Text>
                          <FormControl
                            style={{ borderLeft: '0px', paddingLeft: '0px' }}
                            className="textField"
                            id="addressField"
                            {...getInputProps({
                              placeholder: 'Search by address',
                            })}
                          />
                        </InputGroup>
                        <div
                          className={
                            loading || suggestions.length > 0
                              ? 'community-autocomplete-dropdown-container'
                              : ''
                          }
                        >
                          {suggestions.map((suggestion: any) => {
                            const className = 'community-suggestion-item';
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                })}
                              >
                                <span key={suggestion.description} className="suggestion">
                                  {suggestion.description}
                                </span>
                                <hr />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                  { estFormSubmit && !estLocation && locationFocus === false && (
                  <div style={{ marginBottom: '8px' }}>
                    <img src={AlertIcon} alt="" />
                    <span className="FieldWarnings">Enter location to continue</span>
                  </div>
                  )}
                </Col>
              </Row>
            </Form.Group>
            <Form.Group>
              <Row>
                <Col xl={4} />
                <Col xl={8}>
                  <Map
                    center={coords.lat !== 0 ? [coords.long, coords.lat] : [37.0902, 85.7129]}
                    style={mapboxstyle.mapboxglstyle}
                    containerStyle={{
                      height: '200px',
                      width: '100%',
                    }}
                    zoom={[13]}
                  >
                    <Layer
                      type="symbol"
                      id="marker"
                      layout={{ 'icon-image': 'marker-15' }}
                    >
                      <Feature coordinates={[coords.long, coords.lat]} />
                    </Layer>
                  </Map>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group style={{ display: 'flex', justifyContent: 'end' }}>
              <Button
                className="calculateEstimate"
                style={{
                  background: 'var(--theme-color-1)',
                  border: '#F5F5F5',
                  borderRadius: '4px',
                }}
                onClick={onSubmit}
              >
                <span style={{
                  fontWeight: '700', color: 'white', fontSize: '14px', letterSpacing: '0.02em', lineHeight: '16px',
                }}
                >
                  Calculate
                </span>
              </Button>
            </Form.Group>
          </Form>
        </Col>
        <Col
          className="estimatePrice"
          lg={8}
          xs={12}
          style={{ background: backgroundStyle(), padding: '0' }}
        >
          <h4 className="estimateHeader" style={{ margin: '1.5rem 1rem 1.25rem 3rem' }}>Your Estimation Results</h4>
          <hr style={{ width: '95%' }} />
          {isLoading && (
          <div style={{
            display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '50px',
          }}
          >
            <img src={loaderImage} alt="" />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Oval color="#6B04FF" height={16} width={16} />
              <span style={{ fontSize: '12px', color: '#161616', marginLeft: '6px' }}>Calculating estimate</span>
            </div>
          </div>
          )}
          { !isLoading && (
          <div
            style={{
              margin: '2rem',
              display: estimateAIData.predictedPrice === 0 ? 'none' : 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              alignContent: 'start',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'space-evenly',
                justifyContent: 'space-evenly',
                marginBottom: '3rem',
              }}
            >
              <span className="priceHeader">Predicted price</span>
              <div className="price predicted">{estimateAIData.predictedPrice}</div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'space-evenly',
                justifyContent: 'space-evenly',
              }}
            >
              <span className="priceHeader">Predicted price range is</span>
              <div className="price predicted_range">
                {estimateAIData.minPrice}
                {' '}
                -
                {' '}
                {estimateAIData.maxPrice}
              </div>
            </div>
          </div>
          )}
        </Col>
      </div>
      {estimateAIData.predictedPrice === 0 ? (
        ''
      ) : (
        <span className="reportIssue">
          <img src={issue} alt="" />
          <a
            href="."
            onClick={(e) => {
              e.preventDefault();
              setModalShow(true);
            }}
            style={{
              color: '#9F9F9F',
              textDecoration: '1px underline #9F9F9F',
              fontSize: '12px',
              lineHeight: '16px',
            }}
          >
            Report issue
          </a>
        </span>
      )}
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>

  /* <div
          className="connect-project_container"
          data-connected={!!connectedProject}
          tabIndex={0}
          onClick={() => user._id && setShowConnectProject(true)}
        >
          {connectedProject ? (
            <div className="image-container">
              <img
                src={connectedProjectImage || dummy_thumbnail_2}
                alt="connected-project"
              />
              <div className="watermark-container">
                <NayaLogo />
              </div>
            </div>
          ) : (
            <>
              <p>Connect Naya Project</p>
              <div className="drop-button">
                <div className="plus" />
              </div>
            </>
          )}
  </div> */
  );
}

export function convertToUSD(price: number) {
  return price.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
}

export const RoundTo = (num?: number, round?: number) => {
  if (!num || !round || num < 0 || round < 0) return undefined;

  return round * Math.round(num / round);
};

export function prepEstimateDisplay(num: number) {
  return convertToUSD(RoundTo(num, 25) || 0);
}

type SelectOptions = {
  label: string;
  value: string;
};
