import { ReactComponent as ImageToolIcon } from '../../../assets/icons/toolbar/image.svg';
import { ReactComponent as MarkupToolIcon } from '../../../assets/icons/toolbar/markup.svg';
import { ReactComponent as CommentToolIcon } from '../../../assets/icons/toolbar/commentIcon.svg';
import { ReactComponent as BrainToolIcon } from '../../../assets/icons/toolbar/brain.svg';
import { ReactComponent as SelectToolIcon } from '../../../assets/icons/toolbar/select.svg';
import { ReactComponent as ShapesToolIcon } from '../../../assets/icons/toolbar/shapes.svg';
import { ReactComponent as TextToolIcon } from '../../../assets/icons/toolbar/text_input.svg';
import { ReactComponent as StickyNoteIcon } from '../../../assets/icons/toolbar/sticky_note.svg';
import { ReactComponent as ThreeDToolIcon } from '../../../assets/icons/toolbar/3dtool.svg';
import stroke_thin from '../../../assets/icons/toolbar/stroke_thin.svg';
import stroke_medium from '../../../assets/icons/toolbar/stroke_medium.svg';
import stroke_thick from '../../../assets/icons/toolbar/stroke_thick.svg';
import rectangle from '../../../assets/icons/toolbar/rectangle.svg';
import rounded_rect from '../../../assets/icons/toolbar/rounded_rect.svg';
import circle from '../../../assets/icons/toolbar/circle.svg';
import triangle from '../../../assets/icons/toolbar/triangle.svg';
import star from '../../../assets/icons/toolbar/star.svg';
import solid_line from '../../../assets/icons/toolbar/solid_line.svg';
import dashed_line from '../../../assets/icons/toolbar/dashed_line.svg';
import arrow from '../../../assets/icons/toolbar/arrow.svg';
import question2 from '../../../assets/icons/toolbar/question2.png';
import smile2 from '../../../assets/icons/toolbar/smile2.png';
import thinking2 from '../../../assets/icons/toolbar/think2.png';
import bulb2 from '../../../assets/icons/toolbar/bulb2.png';
import heart2 from '../../../assets/icons/toolbar/heart2.png';
import thought2 from '../../../assets/icons/toolbar/thought2.png';
import heart_shape from '../../../assets/icons/toolbar/heartIcon.svg';
import { createUnsplashAPIQuery } from 'src/endpoints/unsplash-api';
import axios from 'axios';
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

const strokes = [
  {
    icon: stroke_thin,
    strokeWidth: 2,
  },
  {
    icon: stroke_medium,
    strokeWidth: 4,
  },
  {
    icon: stroke_thick,
    strokeWidth: 6,
  },
];

const colors = ['#FFFFFF', '#7E7E7E', '#161616', '#02CBEF', '#9ED36D', '#F9DB60', '#E397B3', '#EE8C84', '#FFA35E', '#B989FF'];
const stickyNoteColors = ['#FFFFFF', '#7E7E7E', '#161616', '#02CBEF', '#9ED36D', '#F9DB60', '#E397B3', '#EE8C84', '#FFA35E', '#B989FF'];

const shapeOptions = [
  {
    icon: rectangle,
    shapeType: 'RECTANGLE',
  },
  {
    icon: rounded_rect,
    shapeType: 'ROUNDED_RECTANGLE',
  },
  {
    icon: circle,
    shapeType: 'ELLIPSE',
  },
  {
    icon: triangle,
    shapeType: 'TRIANGLE',
  },
  {
    icon: star,
    shapeType: 'STAR',
  },
  {
    icon: heart_shape,
    shapeType: 'HEART',
  },
  {
    icon: solid_line,
    shapeType: 'SOLID_LINE',
  },
  {
    icon: dashed_line,
    shapeType: 'DASHED_LINE',
  },
  {
    icon: arrow,
    shapeType: 'ARROW',
  },
];

const reactionOptions = [
  {
    icon: smile2,
    reactionType: 'SMILE',
  },
  {
    icon: thinking2,
    reactionType: 'THINKING',
  },
  {
    icon: bulb2,
    reactionType: 'BULB',
  },
  {
    icon: question2,
    reactionType: 'QUESTION_MARK',
  },
  {
    icon: heart2,
    reactionType: 'HEART',
  },
  {
    icon: thought2,
    reactionType: 'THOUGHT_BUBBLE',
  },
];

const toolbarData = {
  BRAIN: {
    type: 'BRAIN',
    icon: <BrainToolIcon />,
  },
  SELECT: {
    type: 'SELECT',
    icon: <SelectToolIcon />,
  },
  '3D': {
    type: '3D',
    icon: <ThreeDToolIcon />,
  },
  IMAGE: {
    type: 'IMAGE',
    icon: <ImageToolIcon />,
  },
  SHAPE: {
    type: 'SHAPE',
    icon: <ShapesToolIcon />,
  },
  MARKUP: {
    type: 'MARKUP',
    icon: <MarkupToolIcon />,
    pointerType: '',
  },
  COMMENT: {
    type: 'COMMENT',
    icon: <CommentToolIcon />,
  },
  TEXT: {
    type: 'TEXT',
    icon: <TextToolIcon />,
  },
  STICKY_NOTE: {
    type: 'STICKY_NOTE',
    icon: <StickyNoteIcon />,
  },
};

export const getUnsplashImagesget = (searchTerm: string) => {
  const url1 = createUnsplashAPIQuery(searchTerm, 1);
  return axios.get(url1)
}

export {
  toolbarData, strokes, colors, stickyNoteColors, shapeOptions, reactionOptions,
};
