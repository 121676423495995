import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { getGatewayKey } from 'src/util/helper/queryString';
import {
	EBlockType,
	EEvent,
	EUserRole,
	IBlock,
	IFile,
	IFileSubtype,
	ILink,
	ILinkSubtype,
	INotificationPayload,
	IProject,
	IProjectDetail,
	IUser,
	TInvitedEmail,
	TNotificationData,
	TUserAndRole
} from '@naya_studio/types';
import { blockRouter } from 'src/endpoints/projects-api';
import {
	TAddMultipleBlockPayload,
	TAddSingleBlockPayload,
	TEditBlockPayload,
	TDeleteBlockPayload,
	TDuplicateBlockPayload,
	TRemoveUserFromBlockBlockPayload,
	TAddUserToBlockPayload,
	TEditUserRoleInBlockPayload,
	TConvertBlockPayload,
	TResetBlockPayload,
	TUndoRedoPayload
} from 'src/types/payloadTypes';
import {
	TAddMultipleBlockArgs,
	TAddSingleBlockArgs,
	TEditBlockArgs,
	TLoadBlockThunkArg,
	TDeleteBlockArgs,
	TDuplicateBlockArgs,
	TRemoveUserFromBlockBlockArgs,
	TAddUserToBlockThunkArgs,
	TEditUserRoleInBlockArgs,
	TUndoredoBlockArgs,
	TEditUserInBlockFulfill,
	TAddUserToBlockFulfill,
	TRemoveUserFromBlockFulfill,
	TConvertBlockArgs,
	TAddNotificationThunkArg,
	TInviteToBlockNotificationThunkArg,
	TResetBlockArgs,
	TUndoRedoArgs,
	TBulkUpdateBlockArgs,
	TUndoDeleteFufill
} from 'src/types/argTypes';
import { DUMMY_IMG } from 'src/util/helper/constants';
import trackEvent from 'src/util/analytics/analytics';
import { BlockEvents, CustomEvents } from 'src/util/analytics/events';
import getUserFromRedux from 'src/util/helper/user';
import {
	TAddBlockEventData,
	TDuplicateElementEventData,
	TRenameBlockEventData
} from 'src/util/analytics/analytic.types';
import { getFileLinkSubType } from 'src/components/utilities/navbar/utils';
import { AxiosResponseWrapper } from '../actions/types';
import { TRtkResponse } from './util';
import { ReduxState } from '../reducers/root.types';
import { fetchBlockByIdFromRedux, getHostName } from '../actions/util';
import { addNotification } from './project';
import { notificationSlice } from '../features/api/notification';

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
const qs = `?${getGatewayKey()}`;

/**
 * API action to load a block by id
 */
export const loadBlockById = createAsyncThunk(
	'blocks/loadBlock',
	async (payload: TLoadBlockThunkArg, thunkApi) => {
		try {
			const response = await axios.get<AxiosResponseWrapper<IBlock>>(
				`${blockRouter}/${payload.id}${qs}`
			);

			const block = response.data.payload as IBlock;

			return { block };
		} catch (error) {
			const axiosError = error as AxiosError;
			if (axiosError?.response?.data?.message) {
				return thunkApi.rejectWithValue({
					status: axiosError.response.status,
					message: axiosError.response.data.message
				});
			}
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * API action to add a single empty block
 */
export const addSingleBlock = createAsyncThunk(
	'blocks/addBlock',
	async (payload: TAddSingleBlockArgs, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();
			const { data } = payload;
			const { projectId, stageId, blockData, isGroupCreated } = data;
			const apiPayload: TAddSingleBlockPayload = {
				block: blockData,
				stageId,
				projectId,
				isGroupCreated,
				user: user._id as string,
				lastUpdatedInfo: {
					lastUpdatedBy: user._id as string
				}
			};

			const response = await axios.post<AxiosResponseWrapper<IBlock>>(
				`${blockRouter}/${qs}`,
				{
					payload: apiPayload
				}
			);

			// Track creating block event
			const eventProps: TAddBlockEventData = {
				blockType: blockData.blockType
			};
			if (blockData.blockType === 'FILE')
				eventProps.fileType = getFileLinkSubType(
					(blockData as IFile).fileName,
					'FILE'
				) as keyof typeof IFileSubtype;
			if (blockData.blockType === 'LINK')
				eventProps.linkType = getFileLinkSubType(
					(blockData as ILink).link,
					'LINK'
				) as keyof typeof ILinkSubtype;
			trackEvent(BlockEvents.ADD_BLOCK, eventProps);

			return {
				...payload,
				blockData: response.data.payload
			};
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * API action to add a multiple empty block
 */
export const addMultipleBlocks = createAsyncThunk(
	'blocks/addMultipleBlocks',
	async (payload: TAddMultipleBlockArgs, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();
			const { data } = payload;
			const { stageId, projectId, blocks, newBlockIndex } = data;
			const apiPayload: TAddMultipleBlockPayload = {
				blocks,
				stageId,
				projectId,
				user: user._id as string,
				newBlockIndex,
				lastUpdatedInfo: {
					lastUpdatedBy: user._id as string
				}
			};

			const response = await axios.post<AxiosResponseWrapper<IBlock[]>>(
				`${blockRouter}/multiple${qs}`,
				{
					payload: apiPayload
				}
			);

			const newBlocks = response.data.payload?.filter((b) => b.blockType);

			(newBlocks ?? blocks).forEach((block) => {
				// Track creating block event
				const eventProps: TAddBlockEventData = {
					blockType: block.blockType
				};
				if (block.blockType === 'FILE')
					eventProps.fileType = getFileLinkSubType(
						(block as IFile).fileName,
						'FILE'
					) as keyof typeof IFileSubtype;
				if (block.blockType === 'LINK')
					eventProps.linkType = getFileLinkSubType(
						(block as ILink).link,
						'LINK'
					) as keyof typeof ILinkSubtype;
				trackEvent(BlockEvents.ADD_BLOCK, eventProps);
			});

			return {
				...payload,
				data: {
					...payload.data,
					blocks: newBlocks && newBlocks.length ? newBlocks : payload.data.blocks
				}
			};
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * API action to edit a block
 */
export const editBlock = createAsyncThunk(
	'blocks/editBlock',
	async (payload: TEditBlockArgs, thunkApi) => {
		try {
			const reduxState = thunkApi.getState() as ReduxState;
			const { blocks } = reduxState;
			const user: IUser = getUserFromRedux();
			const { data } = payload;
			const { stageId, projectId, block, sendBlockTypeAsEmpty } = data;
			const blockData = blocks.data[block._id as string];
			const bType = block.blockType
				? (block.blockType as EBlockType)
				: (blockData?.blockType as EBlockType);
			const apiPayload: TEditBlockPayload = {
				block,
				blockId: block._id as string,
				stageId,
				blockType: sendBlockTypeAsEmpty ? 'EMPTY' : bType,
				projectId,
				user: user._id as string,
				isAdmin: user.userType?.includes('ADMIN') as boolean,
				lastUpdatedInfo: {
					lastUpdatedBy: user._id as string
				}
			};

			const response = await axios.put<AxiosResponseWrapper<IBlock>>(`${blockRouter}/${qs}`, {
				payload: apiPayload
			});
			/** --- RENAME BLOCK --- */
			if (block?.name) {
				/** --- Update Block Name in all notifications of that block ---*/
				thunkApi.dispatch(
					notificationSlice.endpoints.updateMultipleNotifs.initiate({
						searchBy: { 'data.blockId': block._id },
						update: { 'data.canvasName': block.name }
					})
				);

				// Track renaming block event
				const eventProps: TRenameBlockEventData = {
					blockType: bType,
					elementId: block._id as string
				};
				trackEvent(BlockEvents.RENAME_BLOCK, eventProps);
			}

			return {
				...payload,
				data: {
					...payload.data,
					block: ((!sendBlockTypeAsEmpty && response.data.payload) || block) as IBlock
				}
			};
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * API action to bulk update blocks
 */
export const bulkUpdateBlocks = createAsyncThunk(
	'blocks/bulk-edit',
	async (payload: TBulkUpdateBlockArgs, thunkApi) => {
		try {
			const apiPayload = {
				payload: payload.data
			};
			await axios.put(`${blockRouter}/bulk-edit${qs}`, apiPayload);
			return payload;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred in bulkUpdateBlocks');
		}
	}
);

/**
 * API action to favorite a block
 */
export const favoriteBlock = createAsyncThunk(
	'blocks/favourite',
	async (payload: TEditBlockArgs, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();
			const { data } = payload;
			const { block } = data;

			const response = await axios.put<AxiosResponseWrapper<IBlock>>(
				`${blockRouter}/favourite/${block._id as string}/${user._id}/${qs}`,
				{
					payload: {
						lastUpdatedInfo: {
							lastUpdatedBy: user._id as string
						}
					}
				}
			);
			if (response.status !== 200) throw new Error('Failed to edit node.');

			return {
				...payload,
				data: {
					...payload.data,
					block: response.data.payload as IBlock
				}
			};
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * API action to convert a block to a certain blocktype
 */
export const convertBlock = createAsyncThunk(
	'blocks/convertBlock',
	async (payload: TConvertBlockArgs, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();
			const { data } = payload;
			const { stageId, projectId, blockData, blockId, blockType } = data;
			const apiPayload: TConvertBlockPayload = {
				blockData,
				blockId,
				stageId,
				blockType,
				projectId,
				user: user._id as string,
				isAdmin: user.userType?.includes('ADMIN') as boolean,
				lastUpdatedInfo: {
					lastUpdatedBy: user._id as string
				}
			};

			const response = await axios.put<AxiosResponseWrapper<IBlock>>(
				`${blockRouter}/convert/${qs}`,
				{
					payload: apiPayload
				}
			);

			// Track creating block event
			const eventProps: TAddBlockEventData = {
				blockType
			};
			if (blockType === 'FILE')
				eventProps.fileType = getFileLinkSubType(
					(blockData as IFile).fileName,
					'FILE'
				) as keyof typeof IFileSubtype;
			if (blockType === 'LINK')
				eventProps.linkType = getFileLinkSubType(
					(blockData as ILink).link,
					'LINK'
				) as keyof typeof ILinkSubtype;
			trackEvent(BlockEvents.ADD_BLOCK, eventProps);

			return {
				...payload,
				blockData: response.data.payload
			};
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * API action to delete a block
 */
export const deleteBlock = createAsyncThunk(
	'blocks/deleteBlock',
	async (payload: TDeleteBlockArgs, thunkApi) => {
		try {
			const user = getUserFromRedux();
			const { data } = payload;

			const apiPayload: TDeleteBlockPayload = {
				...data,
				lastUpdatedInfo: {
					lastUpdatedBy: user._id as string
				}
			};

			await axios.put<AxiosResponseWrapper<IBlock>>(`${blockRouter}/delete/${qs}`, {
				payload: apiPayload
			});

			return payload;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * API action to duplicate block
 */
export const duplicateBlock = createAsyncThunk(
	'blocks/duplicateBlock',
	async (payload: TDuplicateBlockArgs, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();
			const { data } = payload;
			const { stageId, blockIndex, blockId, clonedItems } = data;

			const apiPayload: TDuplicateBlockPayload = {
				insertIndex: blockIndex,
				clonedItems,
				parentId: stageId,
				lastUpdatedInfo: {
					lastUpdatedBy: user._id as string
				}
			};

			const response = await axios.post<AxiosResponseWrapper<IBlock>>(
				`${blockRouter}/duplicate/${qs}`,
				{
					payload: apiPayload
				}
			);

			if (response.status !== 200) throw new Error(response.statusText);

			// Track duplicating block event
			const eventProps: TDuplicateElementEventData = {
				elementId: blockId,
				elementType: 'BLOCK'
			};
			trackEvent(CustomEvents.DUPLICATE_ELEMENT, eventProps);

			return payload;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * API action to remove user from block
 */
export const removeUserFromBlock = createAsyncThunk(
	'blocks/removeUserFromBlock',
	async (payload: TRemoveUserFromBlockBlockArgs, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();
			const { data } = payload;
			const {
				blockId,
				userId,
				stageId,
				projectId,
				invitedUserRole,
				userRole,
				existOnOtherLevels
			} = data;

			if (!userRole && !invitedUserRole)
				throw new Error('Can not find user in block user list, please contact an admin');

			if (!!invitedUserRole && userRole?.role === 'OWNER')
				throw new Error('Cannot remove owner');

			const apiPayload: TRemoveUserFromBlockBlockPayload = {
				blockId,
				userToRemove: userId,
				isInvitedUser: !!invitedUserRole,
				projectId,
				stageId,
				userId: user._id as string,
				lastUpdatedInfo: {
					lastUpdatedBy: user._id as string,
					stageId,
					projectId
				}
			};

			const response = await axios.put<AxiosResponseWrapper<{ user: string }>>(
				`${blockRouter}/remove-user/${qs}`,
				{
					payload: apiPayload
				}
			);

			if (payload.next && typeof payload.next === 'function') {
				payload.next();
			}

			const fulfillValue: TRemoveUserFromBlockFulfill = {
				blockId,
				isInvitedUser: !!invitedUserRole,
				existOnOtherLevels,
				...response.data.payload
			};

			return fulfillValue;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * API action to create notification for added user to block
 */
export const blockInviteNotification = createAsyncThunk(
	'blocks/addUserToBlock/notification',
	async (payload: TInviteToBlockNotificationThunkArg, thunkApi) => {
		try {
			const { data } = payload;
			const {
				projectId,
				blockId,
				email,
				inviteNote,
				stageId,
				isRequestFile,
				requestType,
				thumbnailUrl
			} = data;
			const reduxState = thunkApi.getState() as ReduxState;
			const user = getUserFromRedux();
			const project = reduxState.projects.data[projectId] as IProject;
			const block = fetchBlockByIdFromRedux(blockId) as IBlock;

			const notificationPayload: INotificationPayload = {
				eventType:
					requestType ||
					(block.blockType === 'EMPTY' && isRequestFile
						? EEvent.REQUEST_FILE_UPLOAD
						: EEvent.INVITE_USER_CANVAS),
				data: {
					canvasName: block.name === 'UNDEFINED' ? 'Untitled' : block.name,
					blockId: block._id,
					uniqueIdentifier: block._id,
					projectId: project._id,
					user: email.split('@')[0], // holds the username
					link: `${getHostName()}/project/${projectId}/${stageId}/${blockId}`,
					projectName: (project.projectDetails as IProjectDetail).name,
					inviterName: user.firstName || user.userName,
					inviteNote,
					profilePic: user.profilePic || DUMMY_IMG,
					fromUserId: user._id,
					actionDetails: `${user.firstName} has requested file upload`,
					thumbnailUrl
				} as TNotificationData,
				toUser: email.toLowerCase()
			};

			if (inviteNote !== '') {
				(
					(notificationPayload as INotificationPayload)!.data as TNotificationData
				).inviteNote = inviteNote;
			}

			const apiPayload: TAddNotificationThunkArg = {
				payload: {
					notification: notificationPayload,
					userId: user._id as string
				}
			};
			const inviteBlockNotif = await thunkApi.dispatch(addNotification(apiPayload));

			return (inviteBlockNotif as TRtkResponse).payload.data.payload;
		} catch (error) {
			console.error('Notification Error:', error);
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * API action to add user to block
 */
export const addUserToBlock = createAsyncThunk(
	'blocks/addUserToBlock',
	async (payload: TAddUserToBlockThunkArgs, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();

			const { data } = payload;
			const {
				projectId,
				blockId,
				email,
				role,
				isInvitedUser,
				userId,
				stageId,
				inviteNote,
				isRequestFile,
				requestType,
				thumbnailUrl
			} = data;

			const apiPayload: TAddUserToBlockPayload = {
				projectId,
				blockId,
				userId: isInvitedUser ? email : (userId as string),
				role,
				isInvitedUser,
				lastUpdatedInfo: {
					lastUpdatedBy: user._id as string,
					stageId,
					projectId
				}
			};

			const response = await axios.put<
				AxiosResponseWrapper<{
					user: IUser | string;
				}>
			>(`${blockRouter}/invite-user/${qs}`, {
				payload: apiPayload
			});

			const blockInviteNotifPayload: TInviteToBlockNotificationThunkArg = {
				data: {
					projectId,
					stageId,
					blockId,
					email,
					inviteNote,
					isRequestFile,
					requestType,
					thumbnailUrl
				}
			};
			const notifResponse = await thunkApi
				.dispatch(blockInviteNotification(blockInviteNotifPayload))
				.unwrap();

			if (payload.next && typeof payload.next === 'function') {
				payload.next();
			}

			const fulfillValue: TAddUserToBlockFulfill = {
				user: response.data.payload?.user as IUser | string,
				role: role as EUserRole,
				blockId,
				isInvitedUser,
				notification: notifResponse as INotificationPayload
			};

			return fulfillValue;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * API action to edit user's role in block
 */
export const editUserRoleInBlock = createAsyncThunk(
	'blocks/editUserRoleInBlock',
	async (payload: TEditUserRoleInBlockArgs, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();
			const { data } = payload;
			const { blockId, userId, role, invitedUser } = data;

			const apiPayload: TEditUserRoleInBlockPayload = {
				blockId,
				userId,
				role,
				isInvitedUser: !!invitedUser,
				lastUpdatedInfo: {
					lastUpdatedBy: user._id as string
				}
			};

			const response = await axios.put<
				AxiosResponseWrapper<{
					users: TUserAndRole[] | TInvitedEmail[];
					isInvitedUser: boolean;
				}>
			>(`${blockRouter}/edit-user/${qs}`, {
				payload: apiPayload
			});

			if (payload.next && typeof payload.next === 'function') {
				payload.next();
			}

			const fulfillValue: TEditUserInBlockFulfill = {
				...response.data.payload,
				blockId
			};

			return fulfillValue;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * API action to undo redo a block
 */
export const undoRedoBlock = createAsyncThunk(
	'blocks/undoRedoBlock',
	async (payload: TUndoredoBlockArgs, thunkApi) => {
		try {
			const reduxState = thunkApi.getState() as ReduxState;
			const { blocks } = reduxState;
			const user: IUser = getUserFromRedux();
			const { projectId, stageId, block } = payload.data;
			const blockId = payload.data.block._id as string;
			const blockType = blocks.data[blockId]?.blockType as EBlockType;
			const apiPayload: TEditBlockPayload = {
				user: user._id as string,
				block,
				isAdmin: user.userType?.includes('ADMIN') as boolean,
				stageId,
				projectId,
				blockId: block._id as string,
				blockType,
				lastUpdatedInfo: {
					lastUpdatedBy: user._id as string
				}
			};
			const response = await axios.put<AxiosResponseWrapper<IBlock>>(`${blockRouter}/${qs}`, {
				payload: apiPayload
			});

			if (response.status !== 200) {
				return thunkApi.rejectWithValue(`Error editing canvas at ${blockId}`);
			}

			const blockData = response.data.payload as IBlock;
			return blockData;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * API action to edit a block
 */
export const resetBlock = createAsyncThunk(
	'blocks/resetBlock',
	async (payload: TResetBlockArgs, thunkApi) => {
		try {
			const { data } = payload;
			const { block } = data;
			const apiPayload: TResetBlockPayload = {
				block
			};

			await axios.put<AxiosResponseWrapper<IBlock>>(`${blockRouter}/reset-block${qs}`, {
				payload: apiPayload
			});

			// Track reset block event
			trackEvent(BlockEvents.BLOCK_RESET);

			return {
				...payload
			};
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * API action to un-delete a block or a phase
 */
export const undoDelete = createAsyncThunk(
	'blocks/undoDelete',
	async (payload: TUndoRedoArgs, thunkApi) => {
		try {
			const { data } = payload;
			const apiPayload: TUndoRedoPayload = data;

			const response = await axios.put<AxiosResponseWrapper<{}>>(
				`${blockRouter}/undo-delete${qs}`,
				{
					payload: apiPayload
				}
			);

			return response.data.payload as TUndoDeleteFufill;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);
