import { ESharingTabs } from '../../SharingModal.types';

export type SharingOptionsProps = {
	activeTab: keyof typeof ESharingTabs;
	projectId: string;
	stageId: string;
	blockId: string;
};

export enum ESharingOptions {
	COPY_LINK = 'COPY_LINK',
	WHATSAPP = 'WHATSAPP',
	TEAMS = 'TEAMS',
	LINKEDIN = 'LINKEDIN',
	PINTEREST = 'PINTEREST',
	SLACK = 'SLACK'
}
