import * as PIXI from 'pixi.js';
import { Viewport } from 'pixi-viewport';
import { Handle, Transformer } from '@naya_studio/transformer';
import {
	ENodeType,
	EPattern_Type,
	EShapeType,
	IBlock,
	ICanvas,
	IFeedback,
	INode,
	TAbsoluteBoundingBox,
	TReferenceOrObject,
	TScale,
	TUserAndRole,
	IUser
} from '@naya_studio/types';
import { CustomDispatch } from 'src/redux/actions/types';
import { Container, DisplayObject, InteractionEvent, Rectangle } from 'pixi.js';
import { fetchBlockByIdFromRedux, getNodeDataDifferences } from 'src/redux/actions/util';
import _ from 'lodash';
import * as THREE from 'three';
import { EActionType } from 'src/redux/reducers/undoRedo/undoActionHistory.types';
import { addBatchUndoActions } from 'src/redux/actions/undoRedoActions';
import {
	checkIfCurrentUserIsAdmin,
	checkUserAccessLevel,
	getCurrentBlock
} from 'src/util/accessLevel/accessLevelActions';
import { WebGLRenderer } from 'three';
import { ITheme } from 'src/redux/reducers/theme/theme';
import { toggleTTISnackBar } from 'src/redux/actions/textToImage';
import { generateIdsFromUrl } from 'src/redux/reduxActions/util';
import { TAddNodesArg, TEditBlockArgs, TEditNodesArgs } from 'src/types/argTypes';
import { ReduxState } from 'src/redux/reducers/root.types';
import { addNodes, editNodes } from 'src/redux/reduxActions/node';
import { editBlock } from 'src/redux/reduxActions/block';
import { detectCollision, generateBoxPoints } from 'src/util/collisionDetection/collisionDetection';
import getUserFromRedux from 'src/util/helper/user';
import Shape from './nodes/Shape';
import Image from './nodes/Image';
import Markup from './nodes/Markup';
import Frame from './nodes/Frame';
import Model from './nodes/Model';
import { reduxManager, store } from '../../../index';
import BaseNode from './nodes/BaseNode';
import Polygon from './nodes/Polygon';
// import { CanvasType } from '../Canvas';
import OnboardingImage from './nodes/OnboardingImage';
import FilePlaceholder from './nodes/FilePlaceholder';
import SampleImage from './nodes/SampleImage';
import Reaction from './nodes/Reaction';
import Text from './nodes/Text';
import StickyNote from './nodes/StickyNote';
import {
	appKeyDownEvent,
	appKeyUpEvent,
	appMouseMoveEvent,
	appMouseDownEvent,
	appMouseUpEvent
} from './AppControls';
import { cursors, orbits3D, moves3D, cubes3D, links } from './AppAssetImports';
import { getFileName } from './nodes/utils/helper';
import appInstance from './AppInstance';
import SimpleCull from './nodes/utils/SimpleCull';
import TextInputNode from './nodes/TextInputNode';
import { trixInitialize, trixPaste, trixSelectionChange } from './utils/trixUtil';
import {
	onTransformChange,
	onTransformCommit,
	onTransformerClick,
	onTransformerMouseMove,
	onTransformerMouseUp
} from './transformerActions';

// default transformer handles
export const defaultTransformerHandles: Handle[] = [
	'bottomLeft',
	'bottomRight',
	'topLeft',
	'topRight'
];

// simple transformer handles
// -- used in Text nodes
export const textNodeTransformerHandles: Handle[] = [
	'bottomLeft',
	'bottomRight',
	'topLeft',
	'topRight'
];
export const cropImageTransformerHandles: Handle[] = [
	'bottomLeft',
	'bottomRight',
	'topLeft',
	'topRight',
	'topCenter',
	'middleRight',
	'bottomCenter',
	'middleLeft'
];

class App {
	width: number; // holds width of the pixi app --> thus viewport

	height: number; // holds height of the pixi app --> thus viewport

	theme: ITheme;

	app: PIXI.Application; // pixi app

	viewport: Viewport; // viewport that holds all the children

	transformer: Transformer; // transformer --> for transforming nodes

	// holdes all the nodes of a canvas --> just the node's data
	private nodes?: TReferenceOrObject<INode>[];

	activeTool: string; // holds the tool that is active on the left toolbar

	nodeData?: INode; //

	selectedNodes: BaseNode[]; // holds all the selected nodes --> added to the transformer

	// holds all the children added to the viewport -->
	// holds the entire BaseNode hece different from nodes
	allNodes: BaseNode[] | undefined;

	private prevNodes?: TReferenceOrObject<INode>[];

	private _dragging: boolean;

	zoomPercent: number;

	private canvasId: string;

	throughPanTool: boolean;

	isPanSelected: boolean;

	isColourPickerOpen: boolean;

	activeTexture: string;

	textureOpacity: number;

	bkgColor: string;

	firstMouseDown: boolean;

	zoomMultiplyingFactor: number;

	/** Controlling zoom level index from here instead of
	 * Control.tsx so that alt+mouseWheel can also decide the zoom level */
	zoomLevelIndex: number;

	zoomLevels: number[];

	firstRender: boolean; // tells if its the first render of the pixi app

	commentHasText: boolean;

	transformChangeIteration: number = 0;

	// function to add new empty comment on the canvas --> fn incoming from Canvas.tsx
	// addNewComment: (arg0: any) => void;

	// function to open the text edit as per user interaction --> fn incoming from Canvas.tsx
	// openTextEdit: (
	//   e: InteractionEvent,
	//   nodeType?: keyof typeof ENodeType,
	//   nodeData?: INode,
	//   callback?: (data: INode) => void
	// ) => void;

	// function to save text modified by the user --> fn incoming from Canvas.tsx
	// saveTextEdit: () => void;

	// function to notify zoom to react components to reposition the componets as per canvas
	// --> fn incoming from Canvas.tsx
	notifyZoom: () => void;

	// Used to update feedback position on canvas pan and zoom
	updateFeedbacks: () => void;

	// function to toggle the edit menu --> fn incoming from Canvas.tsx
	toggleEditMenu: (arg0: boolean) => void;

	redirect: (arg0: string) => void;

	// function to toggle the show active tool --> fn incoming from Canvas.tsx
	toggleShowActiveTool: (arg0: boolean) => void;

	// function to toggle the canvas edit menu --> fn incoming from Canvas.tsx
	toggleCanvasEditMenu: (arg0: boolean) => void;

	// function to toggle val of showPageName (used to show pageName in Canvas.tsx)
	setShowPageName: (arg0: boolean) => void;

	// function to toggle val of canvasEdgeHover (used to show canvasEdgeHover in Canvas.tsx)
	setCanvasEdgeHover: (arg0: boolean) => void;

	/* function to set the active tool
  --> called when the tool is changed
  --> fn incoming from Canvas.tsx */
	selectTool: (tool: string, node: INode) => void;

	onTextSelectionChange: (arg0: any) => void;

	// holds the navbar height --> used to determine the height of the viewport
	private navbarHeight: number;

	frame: Frame | null; // background frame (white or patterened)

	cntrlPressed: boolean = false; // tells if cntrl is pressed

	shiftPressed: boolean = false; // tells if shift is pressed

	// holds the transformer type --> set based upon the types of nodes selected
	private _transformerType: 'default' | 'text' | 'crop' | 'sticky' | 'rotate_disable';

	private _keyboardLocked: boolean; // tells if the keyboard should be locked or not

	private _editingText: boolean; // tells if the user is editing the text

	// tells if the text box is empty --> useful to remove the emoty text nodes
	private _emptyText: boolean;

	highlighting: boolean = false; // tells is the highling is progress --> drag and select

	allowHighlighting: boolean = true;

	transformChanged: boolean = false;

	nodeSaveIndex: number = 0;

	rightClickedNode: BaseNode | null = null;

	rightClick: boolean = false;

	modelrenderer: WebGLRenderer[] = [];

	modelAnimationId: number = -1;

	modelActiveNode: BaseNode | undefined;

	modelTooltipContainer: PIXI.Container = new PIXI.Container();

	modelTooltipShortcut: PIXI.Text;

	modelTooltipText: PIXI.Text;

	modelTopTooltipContainer: PIXI.Container = new PIXI.Container();

	modelTopTooltipShortcut: PIXI.Text;

	modelTopTooltipText: PIXI.Text;

	textLinkTooltip: {
		pixiContainer: PIXI.Container;
		url: string;
		activeNode: BaseNode | null;
		isHovered: boolean;
		index: any;
		timeoutId: any;
		isPixiContainerHovered: boolean;
	};

	place2dFiles: number = 0;

	place3dFiles: number = 0;

	showFilesHover: boolean = false;

	/** All variables prefixed by upload are used to
	 * show preview of upload on mouseMove, more in Frame.tsx */
	upload2dFiles: any[] = [];

	upload3dFiles: any[] = [];

	uploadPlaceholderFiles: any[] = [];

	is3DSnapshot: boolean = false;

	ptMode: boolean = false;

	canvasEditing: boolean = false;

	globalFilesDnD: boolean = true; /** Determines if global file drag and drop should be allowed */

	// function to upload snapshot in collaboration component
	upload3DSnapshot?: (snapUrls: string[], snapNames: string[]) => void;

	// StickyNote shortcut feature related variables
	// function to toggle the plus button --> fn incoming from Canvas.tsx
	togglePlus: (arg0: boolean) => void;

	stickyNoteData?: BaseNode;

	stickyNoteWidth?: number;

	stickyNoteHeight?: number;

	btnPos: String = 'NEITHER';

	btn2X?: number;

	btn2Y?: number;

	btn1X?: number;

	btn1Y?: number;

	editMenuPosition?: 'top' | 'bottom';

	horizontalHoverStickyNodeX?: number;

	horizontalHoverStickyNodeY?: number;

	hoveringArrowColor: string = '#4F00C1';

	shortcutStickyNoteExist: undefined | 'yes';

	removeHoverArrow?: () => void;

	// ----------------------------
	startPos?: { x: number; y: number };

	isMouseDown?: boolean;

	highlightNode?: BaseNode | Shape;

	toggle3dContext: (arg0: boolean) => void;

	toggleCanvasMenu: (arg0: boolean) => void;

	disablePaste: boolean = false;

	showLinkContainer: boolean = false;

	cursors?: PIXI.Container;

	canvasSelected?: boolean;

	cropModeActive?: boolean;

	// Optimizations
	imageResolver?: any;

	frameX?: number;

	frameY?: number;

	showBrainToolStatus: boolean;

	backgroundPattern?: PIXI.Graphics;

	textInputNode: TextInputNode;

	stats: any;

	_lastTransformType: string | undefined;

	_doubleTimer: any;

	precomputedBounds: any = {};

	hasCanvasUpdatedForThumbnail: boolean; // used to keep track if canvas was updated in any form

	constructor(
		width: number,
		height: number,
		// addNewComment: (arg0: any) => void,
		notifyZoom: () => void,
		updateFeedbacks: () => void,
		toggleEditMenu: (arg0: boolean) => void,
		togglePlus: (arg0: boolean) => void,
		canvasId: string,
		// canvasType: CanvasType,
		// openTextEdit: (e: any) => void,
		// saveTextEdit: () => void,
		selectTool: (tool: string, node: INode) => void,
		toggle3dContext: (arg0: boolean) => void,
		toggleCanvasMenu: (arg0: boolean) => void,
		redirect: (arg0: string) => void,
		toggleShowActiveTool: (arg0: boolean) => void,
		toggleCanvasEditMenu: (arg0: boolean) => void,
		setShowPageName: (arg0: boolean) => void,
		setCanvasEdgeHover: (arg0: boolean) => void,
		onTextSelectionChange: (arg0: any) => void
	) {
		this.frame = null;
		this._dragging = false;
		this.width = width;
		this.height = height;
		this.throughPanTool = false;
		this.isPanSelected = false;
		this.isColourPickerOpen = false;
		this.activeTexture = '';
		this.textureOpacity = 0.4;
		this.bkgColor = '#fefefe';
		this.firstMouseDown = true;
		this.zoomMultiplyingFactor = 25;
		this.zoomLevelIndex = 4; /** 4 is 100% zoom */
		this.zoomLevels = [
			10, 25, 50, 75, 100, 125, 150, 175, 200, 225, 250, 275, 300, 325, 350, 375, 400
		];
		this.firstRender = true;
		this.commentHasText = false;
		this.canvasEditing = false;
		this.showBrainToolStatus = false;

		const state = store.getState();
		this.theme = state.theme;

		// create base pixi application

		if (appInstance) {
			this.app = appInstance;
			this.app.render();
		} else {
			this.app = new PIXI.Application({
				width: window.innerWidth,
				height: window.innerHeight,
				autoDensity: true,
				resolution: window.devicePixelRatio || 1,
				// resolution: 5,
				backgroundColor: 0xf1f1f1,
				antialias: true,
				autoStart: false
			});
		}
		const { interaction } = this.app.renderer.plugins;
		interaction.cursorStyles.orbit_3d = `url('${cursors.orbit_3d_cursor}') 12 12, auto`;
		// WebGL Renderers to render 3d Models
		const maxLength = Math.max(window.innerWidth, window.innerHeight);
		let tempSize = 0.25;
		for (let i = 0; i < 8; i++) {
			const tempModelrenderer = new WebGLRenderer({ antialias: true });
			tempModelrenderer.setClearColor(0x000000, 0);
			tempModelrenderer.setSize(maxLength * tempSize, maxLength * tempSize);
			tempModelrenderer.setPixelRatio(1);
			tempSize += 0.25;
			this.modelrenderer.push(tempModelrenderer);
		}

		// PIXI extenstion
		(globalThis as any).__PIXI_APP__ = this.app;
		window.pixiApp = this;

		// calculating the navbar height --> needed to correctly position the frame
		this.navbarHeight = document.getElementById('top-navbar')?.offsetHeight || 0;

		// create viewport --> all the nodes would be added to the viewport
		this.viewport = new Viewport({
			screenWidth: window.innerWidth,
			screenHeight: window.innerHeight,
			passiveWheel: false,
			worldWidth: window.innerWidth + 100,
			worldHeight: window.innerHeight + 100 + this.navbarHeight,
			// the interaction module is important for wheel to work when renderer.view is placed/scaled
			interaction: this.app.renderer.plugins.interaction
		});
		// const fpsCounter = new PixiFps();

		// this.viewport.addChild(fpsCounter);
		this.viewport.sortableChildren = true;
		this.viewport.clampZoom({ maxScale: 4, minScale: 0.05 });

		this.toggleEditMenu = toggleEditMenu;
		this.toggleCanvasEditMenu = toggleCanvasEditMenu;
		this.setShowPageName = setShowPageName;
		this.setCanvasEdgeHover = setCanvasEdgeHover;
		this.toggleShowActiveTool = toggleShowActiveTool;
		this.togglePlus = togglePlus;
		this.toggle3dContext = toggle3dContext;
		this.toggleCanvasMenu = toggleCanvasMenu;
		this.redirect = redirect;
		this.onTextSelectionChange = onTextSelectionChange;
		this.canvasSelected = false;
		this.cropModeActive = false;
		this.hasCanvasUpdatedForThumbnail = false;
		// activate the viewport plugins
		this.viewport.drag({ wheel: true }).pinch({ percent: 50 }).wheel({ reverse: false });
		// .decelerate(); //decelerate makes commentsmove slowly at zoom end
		this.viewport.on('click', this.viewportMouseDown);

		this._keyboardLocked = false;

		// create transformer
		this.transformer = new Transformer({
			rotateEnabled: true,
			boxScalingEnabled: true,
			boxRotationEnabled: true,
			group: [],
			wireframeStyle: {
				thickness: 1,
				color: 0x27a2f8
			},
			handleStyle: {
				outlineColor: 0x27a2f8,
				outlineThickness: 1
			},
			cursors: {
				default: `url('${cursors.move}') 5 5, auto`,
				boxRotateTopLeft: `url('${cursors.nw_rotator}') 5 5, auto`,
				boxRotateTopRight: `url('${cursors.ne_rotator}') 5 5, auto`,
				boxRotateBottomLeft: `url('${cursors.sw_rotator}') 5 5, auto`,
				boxRotateBottomRight: `url('${cursors.se_rotator}') 5 5, auto`,
				topLeft: `url('${cursors.nw_resize}') 5 5, auto`,
				topCenter: `url('${cursors.n_resize}') 5 10, auto`,
				topRight: `url('${cursors.ne_resize}') 5 5, auto`,
				middleLeft: `url('${cursors.w_resize}') 10 5, auto`,
				middleRight: `url('${cursors.e_resize}') 10 5, auto`,
				bottomLeft: `url('${cursors.sw_resize}') 5 5, auto`,
				bottomCenter: `url('${cursors.s_resize}') 5 10, auto`,
				bottomRight: `url('${cursors.se_resize}') 5 5, auto`
			}
		});
		this.transformer.lockAspectRatio = true;
		this.transformer.zIndex = 999999;

		this.transformer.on('transformchange', () => {
			onTransformChange(this);
		});
		this.transformer.on('transformcommit', () => {
			onTransformCommit(this);
		});

		this.transformer.on('click', (e: PIXI.InteractionEvent) => {
			onTransformerClick(e, this);
		});

		this.transformer.on('mouseup', (e: PIXI.InteractionEvent) => {
			onTransformerMouseUp(e, this);
		});

		this.transformer.on('mousemove', (e: PIXI.InteractionEvent) => {
			onTransformerMouseMove(e, this);
		});

		this.transformer.enabledHandles = ['bottomLeft', 'bottomRight', 'topLeft', 'topRight'];
		this._transformerType = 'default';
		this.transformer.name = 'TRANSFORMER';

		this.transformer.handles.bottomLeft?.on('mousedown', () => {
			this.toggleEditMenu(false);
			this.disableHighlighting();
		});

		this.transformer.handles.bottomLeft?.on('mouseup', () => {
			this.toggleEditMenu(true);
			this.enableHighlighting();
		});

		this.transformer.handles.bottomRight?.on('mousedown', () => {
			this.toggleEditMenu(false);
			this.disableHighlighting();
		});

		this.transformer.handles.bottomRight?.on('mouseup', () => {
			this.toggleEditMenu(true);
			this.enableHighlighting();
		});

		this.transformer.handles.topLeft?.on('mousedown', () => {
			this.toggleEditMenu(false);
			this.disableHighlighting();
		});

		this.transformer.handles.topLeft?.on('mouseup', () => {
			this.toggleEditMenu(true);
			this.enableHighlighting();
		});

		this.transformer.handles.topRight?.on('mousedown', () => {
			this.toggleEditMenu(false);
			this.disableHighlighting();
		});

		this.transformer.handles.topRight?.on('mouseup', () => {
			this.toggleEditMenu(true);
			this.enableHighlighting();
		});

		this.transformer.handles.topCenter?.on('mousedown', () => {
			this.toggleEditMenu(false);
			this.disableHighlighting();
		});

		this.transformer.handles.topCenter?.on('mouseup', () => {
			this.toggleEditMenu(true);
			this.enableHighlighting();
		});

		this.transformer.handles.middleRight?.on('mousedown', () => {
			this.toggleEditMenu(false);
			this.disableHighlighting();
		});

		this.transformer.handles.middleRight?.on('mouseup', () => {
			this.toggleEditMenu(true);
			this.enableHighlighting();
		});

		this.transformer.handles.bottomCenter?.on('mousedown', () => {
			this.toggleEditMenu(false);
			this.disableHighlighting();
		});

		this.transformer.handles.bottomCenter?.on('mouseup', () => {
			this.toggleEditMenu(true);
			this.enableHighlighting();
		});

		this.transformer.handles.middleLeft?.on('mousedown', () => {
			this.toggleEditMenu(false);
			this.disableHighlighting();
		});

		this.transformer.handles.middleLeft?.on('mouseup', () => {
			this.toggleEditMenu(true);
			this.enableHighlighting();
		});

		this.transformer.on('mousedown', (e: InteractionEvent) => {
			this.toggleEditMenu(false);
			// return if mouse middle button is used and emitting mousedown on frame, so it will handle
			// the middle button
			if (e.data.button === 1) {
				this.frame?.displayObject.emit('mousedown', e);
				return;
			}
			if (this.checkIfAnySelectedNodeIsLocked()) {
				this.transformer.translateEnabled = false;
			}
			this.disableHighlighting();

			// If shift key is pressed, check if a node underneath
			// the transformer needs to be selected
			if (e.data.originalEvent.shiftKey) {
				this.transformer.interactive = false;
				const clickPosition = new PIXI.Point(e.data.global.x, e.data.global.y);
				const hitDisplayObject =
					this.app.renderer.plugins.interaction.hitTest(clickPosition);
				if (
					hitDisplayObject &&
					hitDisplayObject.name &&
					hitDisplayObject.name !== 'FRAME'
				) {
					hitDisplayObject.emit('mousedown', e);
				}
				this.app.stage.emit('mousedown', e);
				this.transformer.interactive = true;
			}
		});

		// this.transformer.on('click', this.onTransformClick)
		this.transformer.on('rightclick', (e: any) => {
			/** Use this code when right click works for all nodes
      this.rightClick = true;
      setTimeout(()=>{
        this.toggle3dContext(true)
      }, 100)
      this.rightClickedNode = this.selectedNodes[0] as BaseNode
       */
			if (this.selectedNodes.length === 1) {
				this.rightClick = true;
				this.toggleCanvasMenu(false);
				this.toggle3dContext(true);
				this.rightClickedNode = this.selectedNodes[0] as BaseNode;
			}
			e.stopPropagation();
		});

		// initializing the active tool
		this.activeTool = 'BRAIN';
		this.selectedNodes = [];
		this.zoomPercent = 1;

		this.canvasId = canvasId;

		// getting the function to add new comment
		// this.addNewComment = addNewComment;

		// ---- Fnctions associated to text node -----
		// this.openTextEdit = openTextEdit;
		// this.saveTextEdit = () => {
		//   if (this.editText) {
		//     // saveTextEdit();
		//   }
		// };
		// used for Text Node
		this._editingText = false;
		this._emptyText = true;
		this.textLinkTooltip = {
			pixiContainer: new PIXI.Container(),
			url: '',
			activeNode: null,
			isHovered: false,
			index: undefined,
			timeoutId: -1,
			isPixiContainerHovered: false
		};
		const { pixiContainer: textLinkContainer } = this.textLinkTooltip;
		textLinkContainer.x = 0;
		textLinkContainer.y = 0;
		const textTooltipGraphics = new PIXI.Graphics();
		textTooltipGraphics.lineStyle(2, 0xff00ff, 0);
		textTooltipGraphics.beginFill(0x393939, 1);
		textTooltipGraphics.drawRoundedRect(0, 8, 132, 40, 4);
		textTooltipGraphics.moveTo(61, 8);
		textTooltipGraphics.lineTo(66, 0);
		textTooltipGraphics.lineTo(71, 8);
		textTooltipGraphics.moveTo(61, 8);
		textTooltipGraphics.endFill();
		textLinkContainer.addChild(textTooltipGraphics);
		const textTooltipTextContainer = new PIXI.Container();
		textTooltipTextContainer.x = 0;
		textTooltipTextContainer.y = 8;
		const linkTooltipSvg = PIXI.Sprite.from(links.link_svg);
		linkTooltipSvg.x = 8;
		linkTooltipSvg.y = 8;
		linkTooltipSvg.width = 16;
		linkTooltipSvg.height = 22;
		textTooltipTextContainer.addChild(linkTooltipSvg);
		const textTooltipText = new PIXI.Text('Go to link', {
			fontFamily: 'Rand Regular',
			fontSize: 12,
			wordWrap: true,
			wordWrapWidth: 108,
			align: 'center',
			fill: 0xffffff
		});
		textTooltipText.x = 32;
		textTooltipText.y = 11;
		textTooltipText.resolution = 3;
		textTooltipTextContainer.addChild(textTooltipText);
		const textTooltipSeperator = new PIXI.Graphics();
		textTooltipSeperator.lineStyle(0.5, 0x5d5d5d, 1);
		textTooltipSeperator.drawRect(94, 8, 0.5, 24);
		textTooltipTextContainer.addChild(textTooltipSeperator);
		const textTooltipEdit = new PIXI.Text('Edit', {
			fontFamily: 'Rand Regular',
			fontSize: 12,
			align: 'right',
			fill: 0xffffff
		});
		textTooltipEdit.x = 102;
		textTooltipEdit.y = 11;
		textTooltipEdit.resolution = 3;
		textTooltipTextContainer.addChild(textTooltipEdit);
		textLinkContainer.addChild(textTooltipTextContainer);
		textLinkContainer.zIndex = 9999;
		textLinkContainer.interactive = true;
		textTooltipText.interactive = true;
		textTooltipEdit.interactive = true;
		textTooltipText.cursor = 'pointer';
		textTooltipEdit.cursor = 'pointer';
		textLinkContainer.on('mouseover', () => {
			if (this.textLinkTooltip.timeoutId !== -1) {
				clearTimeout(this.textLinkTooltip.timeoutId);
			}
			this.textLinkTooltip.isHovered = true;
		});
		textTooltipTextContainer.on('mousemove', () => {
			this.textLinkTooltip.isHovered = true;
		});
		textLinkContainer.on('mouseout', () => {
			this.textLinkTooltip.isHovered = false;
			if (!this.textLinkTooltip.isPixiContainerHovered) {
				this.textLinkTooltip.timeoutId = setTimeout(() => {
					if (!this.textLinkTooltip.isHovered) this.resetTextLinkToolTip();
				}, 1000);
			}
		});
		textTooltipText.on('mousedown', (e: InteractionEvent) => {
			e.stopPropagation();
			const { url } = this.textLinkTooltip;
			// pixiContainer.visible = false;
			// this.textLinkTooltip.activeNode = null;
			// pixiContainer.emit('mouseout')
			this.resetTextLinkToolTip();
			window.open(url, '_blank');
		});
		textTooltipEdit.on('mousedown', (e: InteractionEvent) => {
			e.stopPropagation();
			const { pixiContainer, activeNode } = this.textLinkTooltip;
			pixiContainer.visible = false;
			if (activeNode) {
				// this.editText = true;
				this.showLinkContainer = true;
				activeNode.onMouseDown(e);
				activeNode.onDoubleClick(e);
			}
			// const trixEditor = document.querySelector('trix-editor')?.editor;
			// const linkRanges = trixEditor?.getDocument().findRangesForTextAttribute('href');
			// trixEditor?.setSelectedRange(linkRanges[index]);
			// trixEditor?.activateAttribute('frozen', true);
			this.textLinkTooltip.activeNode = null;
		});

		// notify zoom function to canvas
		this.notifyZoom = notifyZoom;

		this.updateFeedbacks = updateFeedbacks;

		// Select tool -> modify the react toolbar too
		this.selectTool = selectTool;

		// adding model node tooltips
		const { modelTooltipContainer } = this;
		modelTooltipContainer.x = 0;
		modelTooltipContainer.y = 0;
		const modelTooltipGraphics = new PIXI.Graphics();
		modelTooltipGraphics.lineStyle(2, 0xff00ff, 0);
		modelTooltipGraphics.beginFill(0x393939, 1);
		modelTooltipGraphics.drawRoundedRect(0, 8, 124, 32, 4);
		modelTooltipGraphics.moveTo(57, 8);
		modelTooltipGraphics.lineTo(62, 0);
		modelTooltipGraphics.lineTo(67, 8);
		modelTooltipGraphics.moveTo(57, 8);
		modelTooltipGraphics.endFill();
		const modelTooltipText = new PIXI.Text('Move 3D Frame', {
			fontFamily: 'Rand Regular',
			fontSize: 12,
			wordWrap: true,
			wordWrapWidth: 108,
			align: 'left',
			fill: 0xffffff
		});
		modelTooltipText.x = 8;
		modelTooltipText.y = 16;
		modelTooltipText.resolution = 3;
		const modelTooltipShortcut = new PIXI.Text('M', {
			fontFamily: 'Rand Regular',
			fontSize: 12,
			align: 'right',
			fill: 0x9f9f9f
		});
		modelTooltipShortcut.x = 105;
		modelTooltipShortcut.y = 16;
		modelTooltipShortcut.resolution = 3;
		this.modelTooltipShortcut = modelTooltipShortcut;
		this.modelTooltipText = modelTooltipText;
		modelTooltipContainer.addChild(modelTooltipGraphics);
		modelTooltipContainer.addChild(modelTooltipText);
		modelTooltipContainer.addChild(modelTooltipShortcut);
		// top tooltip
		const { modelTopTooltipContainer } = this;
		modelTopTooltipContainer.x = 0;
		modelTopTooltipContainer.y = 0;
		const modelTopTooltipGraphics = new PIXI.Graphics();
		modelTopTooltipGraphics.lineStyle(2, 0xff00ff, 0);
		modelTopTooltipGraphics.beginFill(0x393939, 1);
		modelTopTooltipGraphics.drawRoundedRect(0, 0, 124, 32, 4);
		modelTopTooltipGraphics.moveTo(57, 32);
		modelTopTooltipGraphics.lineTo(62, 40);
		modelTopTooltipGraphics.lineTo(67, 32);
		modelTopTooltipGraphics.moveTo(57, 32);
		modelTopTooltipGraphics.endFill();
		const modelTopTooltipText = new PIXI.Text('Move 3D Frame', {
			fontFamily: 'Rand Regular',
			fontSize: 12,
			wordWrap: true,
			wordWrapWidth: 108,
			align: 'left',
			fill: 0xffffff
		});
		modelTopTooltipText.x = 8;
		modelTopTooltipText.y = 8;
		const modelTopTooltipShortcut = new PIXI.Text('M', {
			fontFamily: 'Rand Regular',
			fontSize: 12,
			align: 'right',
			fill: 0x9f9f9f
		});
		modelTopTooltipShortcut.x = 105;
		modelTopTooltipShortcut.y = 8;
		this.modelTopTooltipShortcut = modelTopTooltipShortcut;
		this.modelTopTooltipText = modelTopTooltipText;
		modelTopTooltipContainer.addChild(modelTopTooltipGraphics);
		modelTopTooltipContainer.addChild(modelTopTooltipText);
		modelTopTooltipContainer.addChild(modelTopTooltipShortcut);

		// model nodes update on zoom end
		this.viewport.on('zoomed-end', () => {
			if (this.frame?.outline) {
				this.highlightFrame();
			}
			this.allNodes
				?.filter((an: BaseNode) => an.nodeData.nodeType === ENodeType.MODEL)
				.forEach((mn: BaseNode) => {
					const modelNode = mn as Model;
					const { absoluteBounds } = modelNode.nodeData;
					if (!modelNode.displayObject) return;
					let canvasLength;
					if (absoluteBounds && absoluteBounds.height && absoluteBounds.width) {
						canvasLength =
							Math.max(absoluteBounds.width, absoluteBounds.height) *
							this.viewport.scale._x;
					} else {
						const newBounds = modelNode.displayObject.getBounds();
						canvasLength = Math.max(newBounds.width, newBounds.height);
					}
					const newRendererIndex = Model.getRendererIndex(canvasLength);
					if (newRendererIndex !== modelNode.modelRendererIndex) {
						modelNode.modelRendererIndex = newRendererIndex;
						const modelCanvas = document.createElement('canvas');
						const canvasContext = modelCanvas.getContext('2d');
						const currentSize = new THREE.Vector2();
						this.modelrenderer[modelNode.modelRendererIndex]!.getSize(currentSize);
						canvasContext!.canvas.width = currentSize.x;
						canvasContext!.canvas.height = currentSize.y;
						canvasContext!.globalCompositeOperation = 'copy';
						modelNode.canvasContext = canvasContext;
						modelNode.threeTexture = PIXI.BaseTexture.from(modelCanvas, {
							scaleMode: PIXI.SCALE_MODES.LINEAR,
							resolution: window.devicePixelRatio,
							anisotropicLevel: 16
						});
						modelNode.draw();
					}
					if (this.viewport.scale.x <= 1) {
						modelNode.orbitSprite.texture = PIXI.Texture.from(orbits3D.orbit_3d_32);
						modelNode.moveSprite.texture = PIXI.Texture.from(moves3D.move_3d_32);
						modelNode.cubeSprite.texture = PIXI.Texture.from(cubes3D.cube_3d_32);
					} else if (this.viewport.scale.x <= 2) {
						modelNode.orbitSprite.texture = PIXI.Texture.from(orbits3D.orbit_3d_64);
						modelNode.moveSprite.texture = PIXI.Texture.from(moves3D.move_3d_64);
						modelNode.cubeSprite.texture = PIXI.Texture.from(cubes3D.cube_3d_64);
					} else if (this.viewport.scale.x <= 3) {
						modelNode.orbitSprite.texture = PIXI.Texture.from(orbits3D.orbit_3d_128);
						modelNode.moveSprite.texture = PIXI.Texture.from(moves3D.move_3d_128);
						modelNode.cubeSprite.texture = PIXI.Texture.from(cubes3D.cube_3d_128);
					} else {
						modelNode.orbitSprite.texture = PIXI.Texture.from(orbits3D.orbit_3d_256);
						modelNode.moveSprite.texture = PIXI.Texture.from(moves3D.move_3d_256);
						modelNode.cubeSprite.texture = PIXI.Texture.from(cubes3D.cube_3d_256);
					}
				});
			const selectedModelNodes = this.selectedNodes.filter(
				(sn: BaseNode) => sn.nodeData.nodeType === ENodeType.MODEL
			);
			if (!_.isEmpty(selectedModelNodes)) {
				this.removeTransformer();
				this.addSelectedNodesToTransformer();
			}
			this.allNodes
				?.filter((an: BaseNode) => an.nodeData.nodeType === ENodeType.FILE_PLACEHOLDER)
				.forEach(async (fn: BaseNode) => {
					const fileNode = fn as FilePlaceholder;
					if (!fileNode.displayObject) return;
					if (fileNode && fileNode.fileTextStyle) {
						fileNode.activeColor = fileNode.selected
							? fileNode.activeColor
							: PIXI.utils.string2hex('#5D5D5D');
						if (fileNode.nodeData.text?.value) {
							const temp: string[] = (fileNode.nodeData.text?.value as string).split(
								'.'
							);
							const textValue = getFileName(temp, this.viewport.scale._x);
							fileNode.textContainer.text = textValue;
						}
						const { scaleX, scaleY } = fileNode.nodeData.scale
							? (fileNode.nodeData.scale as TScale)
							: { scaleX: 1, scaleY: 1 };
						const textYPosition =
							(fileNode.iconSprite.x + fileNode.iconSprite.width) * scaleY! + 20;
						fileNode.textContainer.setTransform(
							(fileNode.iconSprite.x + fileNode.iconSprite.width / 2) * scaleX!,
							textYPosition,
							1 / this.viewport.scale._x,
							1 / this.viewport.scale._y
						);
						fileNode.render();
					}
				});
			this.resolveImageResolution();
			// this.updateStickyNotesCache();
		});

		// document.addEventListener("trix-before-initialize", () => {
		//   // Change Trix.config if you need
		//   //@ts-ignore

		//   //@ts-ignore
		//   const textAttributes = window.Trix.config.textAttributes;
		//   //@ts-ignore
		//   const blockAttributes = window.Trix.config.blockAttributes;
		//   textAttributes.foreColor = {
		//     styleProperty: 'color',
		//     inheritable: true,
		//   };
		//   textAttributes.textDeco = {
		//     styleProperty: 'text-decoration',
		//     inheritable: true,
		//   };
		//   textAttributes.fontSize = {
		//     styleProperty: 'font-size',
		//     inheritable: true,
		//   };
		//   textAttributes.fontFamily = {
		//     styleProperty: 'font-family',
		//     inheritable: true,
		//   };
		//   blockAttributes.leftAlign = {
		//     tagName: 'leftalign'
		//   };
		//   blockAttributes.rightAlign = {
		//     tagName: 'rightalign'
		//   };
		//   blockAttributes.centerAlign = {
		//     tagName: 'centeralign'
		//   };
		// })

		// document.addEventListener("trix-selection-change", () => {
		//   const trixNode = this.textInputNode._domInput;
		//   if (trixNode) {
		//     //@ts-ignore
		//     const activeProperties = trixNode?.editorController.composition.currentAttributes;
		//     this.onTextSelectionChange(activeProperties);
		//   }
		// })

		// post render modifications
		// this.app.renderer.on('postrender', () => {
		//   this.allNodes
		//     ?.filter((an: BaseNode) => an.nodeData.nodeType === ENodeType.STICKY_NOTE)
		//     .forEach((sn: StickyNote) => {
		//       const { displayObject } = sn;
		//       if (displayObject && displayObject.visible) {
		//         displayObject.updateTransform();
		//       }
		//     });
		// });
		document.removeEventListener('trix-before-initialize', trixInitialize);
		document.removeEventListener('trix-selection-change', trixSelectionChange);
		document.removeEventListener('trix-paste', trixPaste);
		document.addEventListener('trix-before-initialize', trixInitialize);
		document.addEventListener('trix-selection-change', trixSelectionChange);
		document.addEventListener('trix-paste', trixPaste);
		this.textInputNode = new TextInputNode();
		this.viewport.on('zoomed-end', this.textInputNode._onViewportChange);
	}

	resetTextLinkToolTip = () => {
		this.textLinkTooltip.url = '';
		this.textLinkTooltip.activeNode = null;
		this.textLinkTooltip.pixiContainer.visible = false;
		this.textLinkTooltip.index = undefined;
	};

	resolveImageResolution = () => {
		if (!this.imageResolver) {
			this.imageResolver = setTimeout(() => {
				const loader = PIXI.Loader.shared;
				this.allNodes
					?.filter((node: BaseNode) => node.nodeData.nodeType === 'IMAGE')
					.forEach((node: BaseNode) => {
						const im = node as Image;
						if (!loader.loading) {
							if (im.nodeData.image) {
								let { width } = (im.displayObject?.getBounds() as Rectangle) || 300;
								width = Math.round(width / 100) * 100;
								if (im.displayObject?.visible) {
									if (im.texture) {
										if (im.texture.width < width) {
											if (
												im.generateCloudinaryURL(width) in loader.resources
											) {
												const a =
													loader.resources[
														im.generateCloudinaryURL(width)
													];
												if (a && !a.error) {
													im.texture = a.texture;
													im.draw();
												}
											} else {
												loader.add({
													url: im.generateCloudinaryURL(width),
													onComplete: async (a: any) => {
														if (a && !a.error) {
															im.texture = a.texture;
															// Check if image is still a child of the viewport
															if (im.displayObject?.parent) {
																im.draw();
																// Remove and re-add transformer in case the image is selected
																this.removeTransformer();
																this.addSelectedNodesToTransformer();
															}
														}
													}
												});
											}
										}
									}
								}
							}
						}
					});

				loader.load();
				this.imageResolver = null;
			}, 500);
		}
	};

	updateStickyNotesCache = () => {
		this.viewport.children.forEach((child: any) => {
			if (this.viewport.scale.x > 2) {
				if (child.visible && child.cacheAsBitmap && child.name === 'STICKY_NOTE') {
					child.cacheAsBitmap = false;
				}
			} else if (child.visible && !child.cacheAsBitmap && child.name === 'STICKY_NOTE') {
				child.cacheAsBitmap = true;
			}
		});
	};

	modelAnimate = () => {
		if (this.modelActiveNode) {
			const modelData = this.modelActiveNode as Model;
			const currentRenderer = this.modelrenderer[modelData.modelRendererIndex];
			if (modelData.camera) currentRenderer!.render(modelData.threeScene, modelData.camera);
			modelData.canvasContext.globalCompositeOperation = 'copy';
			modelData.canvasContext.drawImage(currentRenderer!.domElement, 0, 0);
			if (modelData.threeTexture) {
				modelData.threeTexture.update();
			}
			this.modelAnimationId = requestAnimationFrame(this.modelAnimate);
		}
	};

	/** when true, onKeyUp and onKeyDown will not fire */
	get keyboardLock() {
		return this._keyboardLocked;
	}

	set keyboardLock(locked: boolean) {
		this._keyboardLocked = locked;
	}

	/** used by textNodes to show whether text editing is active */
	get editText() {
		return this._editingText;
	}

	set editText(editing: boolean) {
		this._editingText = editing;
	}

	/** determines whether frame clicks on active empty texts should save or delete */
	get emptyText() {
		return this._emptyText;
	}

	set emptyText(empty: boolean) {
		this._emptyText = empty;
	}

	/** determines whether frame clicks on active empty texts should save or delete */
	get dragging() {
		return this._dragging;
	}

	get transformerType() {
		return this._transformerType;
	}

	/** sets transformer type and in turn changes transformer handles */
	set transformerType(type: typeof this._transformerType) {
		/** Re-setting defaults */
		this.transformer.boxRotationEnabled = false;
		this.transformer.boxScalingEnabled = false;
		this.transformer.centeredScaling = false;
		this.transformer.rotateEnabled = true;
		this.transformer.scaleEnabled = true;
		this.transformer.skewEnabled = false;
		this.transformer.translateEnabled = true;
		this.transformer.lockAspectRatio = true;
		if (
			this.selectedNodes.length === 1 &&
			this.selectedNodes[0]?.nodeData.nodeType === 'IMAGE'
		) {
			this.transformer.lockAspectRatio = true;
		} else {
			this.transformer.lockAspectRatio = false;
		}
		switch (type) {
			case 'default':
				this.setTransformerHandlesForDefaultMode();
				this.transformer.rotateEnabled = false;
				this.transformer.boxScalingEnabled = true;
				this.transformer.boxRotationEnabled = true;
				this.transformer.centeredScaling = false;
				this.transformer.enabledHandles = defaultTransformerHandles;
				break;
			case 'text':
				this.transformer.rotateEnabled = false;
				this.transformer.boxRotationEnabled = false;
				this.transformer.enabledHandles = textNodeTransformerHandles;
				this.transformer.lockAspectRatio = false;
				break;
			case 'crop':
				this.transformer.rotateEnabled = false;
				this.transformer.lockAspectRatio = false;
				this.transformer.boxScalingEnabled = true;
				this.transformer.boxRotationEnabled = true;
				this.transformer.enabledHandles = cropImageTransformerHandles;
				this.cropModeActive = true;
				this.setTransformerHandlesForCropMode();
				break;
			case 'sticky':
				this.transformer.rotateEnabled = false;
				this.transformer.scaleEnabled = false;
				this.transformer.boxRotationEnabled = false;
				this.transformer.enabledHandles = [];
				break;
			case 'rotate_disable':
				this.transformer.rotateEnabled = false;
				this.transformer.boxScalingEnabled = true;
				this.transformer.boxRotationEnabled = false;
				this.transformer.translateEnabled = false;
				this.transformer.enabledHandles = textNodeTransformerHandles;
				break;
			default:
				return; // return early if wrong type
		}

		this._transformerType = type;
	}

	enableHighlighting = () => {
		this.allowHighlighting = true;
	};

	disableHighlighting = () => {
		this.allowHighlighting = false;
	};

	// Changes the color of the transformer based upon the theme selected by the user
	setTransformerColor = (color: string) => {
		if (color) {
			this.hoveringArrowColor = color;
			const tempColor = `0x${color.substring(1, color.length + 1)}`;
			const parsedColor = parseInt(tempColor, 16);
			this.transformer.wireframeStyle.color = parsedColor;
			const { bottomRight, bottomLeft, middleLeft, middleRight, topRight, topLeft } =
				this.transformer.handles;

			if (bottomRight) {
				bottomRight.style = { outlineColor: parsedColor };
			}
			if (bottomLeft) {
				bottomLeft.style = { outlineColor: parsedColor };
			}
			if (middleLeft) {
				middleLeft.style = {
					outlineColor: parsedColor,
					shape: 'square',
					radius: 7
				};
			}
			if (middleRight) {
				middleRight.style = {
					outlineColor: parsedColor,
					shape: 'square',
					radius: 7
				};
			}
			if (topRight) {
				topRight.style = { outlineColor: parsedColor };
			}
			if (topLeft) {
				topLeft.style = { outlineColor: parsedColor };
			}
		}
	};

	/**
	 * Redraw nodes of given type
	 */
	redrawNodesByType = (nodeTypes: ENodeType[]) => {
		const nodesToBeDrawn = this.allNodes?.filter(
			// @ts-ignore
			(an: BaseNode) => an.nodeData.nodeType && nodeTypes.includes(an.nodeData.nodeType!)
		);
		if (nodesToBeDrawn) {
			nodesToBeDrawn.forEach((n: BaseNode) => {
				n.draw();
			});
		}
	};

	/**
	 * Setting 4 corner transformer handles to square for default mode
	 */
	private setTransformerHandlesForDefaultMode = () => {
		const { bottomRight, bottomLeft, topRight, topLeft } = this.transformer.handles;
		const themeColor = +store.getState().theme.color.replace('#', '0x') as number;
		const handleStyle = { shape: 'square', outlineColor: themeColor };

		if (bottomRight) {
			bottomRight.style = handleStyle;
		}
		if (bottomLeft) {
			bottomLeft.style = handleStyle;
		}
		if (topRight) {
			topRight.style = handleStyle;
		}
		if (topLeft) {
			topLeft.style = handleStyle;
		}
	};

	/**
	 * Setting 8 corner transformer handles to clip for crop mode
	 */
	private setTransformerHandlesForCropMode = () => {
		const {
			bottomRight,
			bottomLeft,
			topRight,
			topLeft,
			bottomCenter,
			topCenter,
			middleLeft,
			middleRight
		} = this.transformer.handles;

		const themeColor = +store.getState().theme.color.replace('#', '0x') as number;
		// Radius and Height for Shape Clip should be same
		const cropHandleStyle = {
			radius: 5,
			shape: 'clip',
			color: themeColor,
			width: 20,
			height: 5,
			outlineThickness: 0
		};

		if (bottomRight) {
			bottomRight.style = cropHandleStyle;
		}
		if (bottomLeft) {
			bottomLeft.style = cropHandleStyle;
		}
		if (topRight) {
			topRight.style = cropHandleStyle;
		}
		if (topLeft) {
			topLeft.style = cropHandleStyle;
		}
		if (bottomCenter) {
			bottomCenter.style = cropHandleStyle;
		}
		if (topCenter) {
			topCenter.style = cropHandleStyle;
		}
		if (middleLeft) {
			middleLeft.style = cropHandleStyle;
		}
		if (middleRight) {
			middleRight.style = cropHandleStyle;
		}

		this.render();
	};

	/**
	 * centers the viewport as per the givn width and the height
	 * @param width
	 * @param height
	 */
	// @ts-ignore
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	centerViewport = (width: number = this.width, height: number = this.height) => {
		// this.viewport.ensureVisible(100, 100, width + 200, height + 200, true);
		// this.viewport.position.x = (this.viewport.screenWidth - width * this.viewport.scale.x) / 2;
		// this.viewport.position.y = (this.viewport.screenHeight - height * this.viewport.scale.y) / 2;
		// this.render();
	};

	/**
	 * checks if any of the selected nodes is locked
	 * @returns {boolean}
	 */
	checkIfAnySelectedNodeIsLocked = () => {
		const allNodes = this.selectedNodes;
		for (let i = 0; i < allNodes.length; i++) {
			if (allNodes[i]?._isLocked) {
				return true;
			}
		}
		return false;
	};

	/**
	 *
	 * @param displayObject
	 * @param corners
	 * @param index
	 * @returns corners of a particular node
	 */
	static calculateTransformedCorners = (displayObject: DisplayObject): PIXI.Point[] => {
		const localBounds = displayObject.getLocalBounds();
		// Calculating width and height from the local bounds
		const { x } = localBounds;
		const { y } = localBounds;
		const w = localBounds.width;
		const h = localBounds.height;

		// Calculating 4 corners of the selected node
		const index = 0;
		const corners = [new PIXI.Point(), new PIXI.Point(), new PIXI.Point(), new PIXI.Point()];
		corners[index]!.set(x, y);
		corners[index + 1]!.set(x + w, y);
		corners[index + 2]!.set(x + w, y + h);
		corners[index + 3]!.set(x, y + h);

		// applying transformations
		displayObject.localTransform.apply(corners[index]!, corners[index]);
		displayObject.localTransform.apply(corners[index + 1]!, corners[index + 1]);
		displayObject.localTransform.apply(corners[index + 2]!, corners[index + 2]);
		displayObject.localTransform.apply(corners[index + 3]!, corners[index + 3]);

		return corners;
	};

	/**
	 * Checks if any node is within the bounds --> if yes, select the node
	 * @param bounds
	 */
	checkIfAnyNodeIsWithinBounds = async (bounds: any) => {
		if (this.rightClick || this.is3DSnapshot) return;
		let collidingNodes: BaseNode[] = [];

		if (bounds) {
			const { x, y, width, height } = bounds;
			if (width === 0 && height === 0) {
				return;
			}

			const highlightBounds = generateBoxPoints(x, y, width, height);
			if (this.allNodes) {
				for (let i = 0; i < this.allNodes.length; i++) {
					const tempNode = this.allNodes[i]?.nodeData as INode;
					const displayObject = this.allNodes[i]?.displayObject as DisplayObject;
					let objectBounds: number[] = [];
					if (this.precomputedBounds[tempNode._id as string]) {
						objectBounds = this.precomputedBounds[tempNode._id as string];
					} else if (displayObject) {
						const corners = App.calculateTransformedCorners(displayObject);
						// conveting the points to array
						corners.forEach((item) => {
							objectBounds = [...objectBounds, item.x, item.y];
						});

						this.precomputedBounds[tempNode._id as string] = objectBounds;
					}

					let collided = false;
					// detecting collision
					collided = detectCollision(highlightBounds, objectBounds);
					// if collising --> adding that node to the list of colliding npdes
					if (collided) {
						collidingNodes = collidingNodes.concat(this.allNodes[i] as BaseNode);
					}
				}
			}
		}

		const tempGroup: DisplayObject[] = [];
		let isAnyNodeLocked = false;

		collidingNodes.forEach((node) => {
			tempGroup.push(node.displayObject as DisplayObject);
			if (node._isLocked && !isAnyNodeLocked) {
				isAnyNodeLocked = true;
			}
		});
		if (!_.isEqual(this.selectedNodes, collidingNodes)) {
			this.removeAllSelectedNodes();
			this.selectedNodes = collidingNodes;

			if (this.transformer.group.length === 0) {
				this.app.stage.addChild(this.transformer);
			}
			this.transformer.group = tempGroup;
			collidingNodes[0]?.setTransformerType();

			if (isAnyNodeLocked) {
				this.transformer.translateEnabled = false;
			}
		}
	};

	/**
	 * function that adds all the selected nodes to the transformer
	 */
	addSelectedNodesToTransformer = () => {
		this.addNodesToTransformer();
		this.selectedNodes.forEach((node) => {
			if (node.nodeData.isVisible) {
				node?.setTransformerType();
			}
		});
	};

	saveAllNodes = async (excludeSaveIds: string[]) => {
		const allNodesToUpdate: BaseNode[] = [];
		const allNodesToSave: INode[] = [];
		const reduxState = store.getState() as ReduxState;
		const user: IUser = getUserFromRedux();
		for (let i = 0; i < this.selectedNodes.length; i++) {
			const node = this.selectedNodes[i] as BaseNode;
			if (!excludeSaveIds.includes(node.nodeData._id as string)) {
				const isPersisted = !!node.nodeData.createdBy;
				if (isPersisted) {
					allNodesToUpdate.push(node);
				} else {
					node.nodeData = {
						...node.nodeData,
						createdBy: user._id as string,
						lastUpdatedBy: user._id as string,
						version: 0
					};
					allNodesToSave.push(node.nodeData);
				}
			}
		}

		if (allNodesToUpdate.length > 0) {
			const nodeDataDiffs = getNodeDataDifferences(allNodesToUpdate);
			if (nodeDataDiffs.length > 0) {
				// readding the transformer for node edits to prevent ghost placement of the trasnformer
				this.removeTransformer();
				this.addSelectedNodesToTransformer();

				// generating payload for edit nodes action
				const apiPayload: TEditNodesArgs = {
					data: {
						nodes: nodeDataDiffs,
						...generateIdsFromUrl()
					},
					prevState: {
						prevBlocks: reduxState.nodes.data
					}
				};

				(store.dispatch as CustomDispatch)(editNodes(apiPayload))
					.unwrap()
					.then(() => {
						this.addUndoAction();
					});
			}
		}
		if (allNodesToSave.length > 0) {
			const { blockId } = generateIdsFromUrl();
			// extracting redux sttae
			// generating add nodes API payload
			const payload: TAddNodesArg = {
				data: {
					nodes: allNodesToSave,
					...generateIdsFromUrl()
				},
				prevState: {
					prevNodes: reduxState.nodes.data,
					prevBlock: reduxState.blocks.data[blockId as string] as IBlock
				}
			};
			// item does not exist in database
			(store.dispatch as CustomDispatch)(addNodes(payload));
		}
	};

	addUndoAction = () => {
		const data: Array<{ prevNodeData: INode | IFeedback; newNodeData: INode | IFeedback }> = [];

		this.selectedNodes.forEach((node: BaseNode) => {
			if (node.prevNodeData) {
				data.push({
					prevNodeData: node.prevNodeData,
					newNodeData: node.nodeData
				});
			}
		});

		addBatchUndoActions('EDIT' as EActionType, data);
	};

	/**
	 * function to find the index of a particular node in the list of selected nodes
	 * @param nodeId
	 * @returns {index}
	 */
	getNodeIndexFromSelectedNodes = (nodeId: string) => {
		let index = 0;
		this.selectedNodes.forEach((node, i) => {
			if (node.nodeData._id === nodeId) {
				index = i;
			}
		});
		return index;
	};

	/**
	 * Center the viewport focusing on a speicific comment based upon specific width and height
	 * @param width
	 * @param height
	 */
	commentsCenterViewport = (width: number = this.width, height: number = this.height) => {
		if (width < window.innerWidth / 2) {
			this.viewport.position.x = window.innerWidth / 2 - width + 150;
		} else {
			this.viewport.position.x = window.innerWidth / 2 - width + 150;
		}

		if (height < window.innerHeight / 2) {
			this.viewport.position.y = window.innerHeight / 2 - height;
		} else {
			this.viewport.position.y = window.innerHeight / 2 - height;
		}
	};

	/** returns the current canvas id */
	getCanvasId = () => this.canvasId;

	// sets the canvas id
	setCanvasId = (id: string) => {
		this.canvasId = id;
		this.resetCanvasData();
	};

	// checks if the nodes should be interactive depending upon the user access level
	checkIfNodesShouldBeInteractive = () => {
		if (this.ptMode) {
			return false;
		}
		const user: IUser = getUserFromRedux();
		if (user && user._id) {
			const block = getCurrentBlock() as IBlock;
			if (
				block &&
				(checkUserAccessLevel(block.users as TUserAndRole[], user._id as string, [
					'EDITOR',
					'OWNER'
				]) ||
					checkIfCurrentUserIsAdmin()) &&
				this.activeTool === 'SELECT' &&
				!this.highlighting
			) {
				return true;
			}
		}
		return false;
	};

	/**
	 * function that resets the interactivity of all the nodes
	 * used in Frame.tsx
	 */
	resetInteractivity = () => {
		const allChildren = this.viewport.children;
		allChildren.forEach((element) => {
			if (element.name !== 'FRAME' && element.name !== 'MODEL') {
				element.interactive = this.checkIfNodesShouldBeInteractive();
			}
		});
	};

	removeHighlightNode = () => {
		if (this.highlightNode) {
			this.viewport.removeChild(this.highlightNode.displayObject as DisplayObject);
			this.highlightNode = undefined;
		}
	};

	/**
	 * sets the active selected tool from the toolbar
	 * @param tool
	 */
	setActiveTool = (tool: string) => {
		const user: IUser = getUserFromRedux();
		// this.activeTool = tool;
		const { brainTool } = store.getState();
		const block = getCurrentBlock() as IBlock;
		// clear transformer from viewport children and clean up UI
		this.resetEditMenu();
		this.removeTransformer();
		this.toggleCanvasEditMenu(false);
		this.removeAllSelectedNodes();
		this.toggleEditMenu(false);
		this.isColourPickerOpen = false;
		this.togglePlus(false);
		// disable displayObject interactive property so nodes can be drawn over one another
		if (!['SELECT'].includes(tool)) {
			this.viewport.children.forEach((displayObject) => {
				if (displayObject.name === 'FRAME') {
					return;
				}

				displayObject.interactive = false;
			});
		} else if (
			block &&
			(checkUserAccessLevel(block.users as TUserAndRole[], user._id as string, [
				'EDITOR',
				'OWNER'
			]) ||
				checkIfCurrentUserIsAdmin())
		) {
			this.viewport.children.forEach((displayObject) => {
				displayObject.interactive = true;
			});
		}
		if (!this.isPanSelected) this.activeTool = tool;
		if (
			this.activeTool !== 'BRAIN' &&
			['processing', 'queued', 'starting'].includes(brainTool.status) &&
			!brainTool.showSnackBar
		) {
			(store.dispatch as CustomDispatch)(toggleTTISnackBar(true));
		}
		if (this.activeTool === 'BRAIN' && brainTool.showSnackBar)
			(store.dispatch as CustomDispatch)(toggleTTISnackBar(false));
	};

	/**
	 * Redraws a Node with new nodeData, using for undo and redo functions
	 */
	redrawNode = (nodeData: INode) => {
		this.prevNodes = _.cloneDeep(this.nodes);
		// const oldNodeIndex = this.nodes?.findIndex((node) => (node as INode)._id === nodeData._id);
		let index = -1;

		// If this object is currently selected, find the index of this node in the selected nodes
		for (let i = 0; i < this.selectedNodes.length; i++) {
			if (this.selectedNodes[i]?.nodeData._id === nodeData._id) {
				index = i;
				break;
			}
		}

		// If this object is currently selected, update the selected nodes
		if (index !== -1) {
			const temp = _.clone(this.selectedNodes);
			temp.splice(index, 1);
			this.selectedNodes = temp;
		}

		const nodeFound = this.allNodes?.find((node: any) => node.nodeData._id === nodeData._id);
		if (!nodeFound) {
			let currentNode;
			this.nodes?.push(nodeData);
			switch ((nodeData as INode).nodeType) {
				case 'SHAPE':
					switch ((nodeData as INode).shapeType) {
						case EShapeType.RECTANGLE:
						case EShapeType.ROUNDED_RECTANGLE:
						case EShapeType.ELLIPSE:
						case EShapeType.TRIANGLE:
						case EShapeType.STAR:
						case EShapeType.HEART: {
							currentNode = new Polygon(this, nodeData as INode);
							// const polyObj = currentNode.getDisplayObject();
							// if (polyObj) displayObjects.push(polyObj);
							// polyObj && children.push(polyObj);
							this.addNodeToAllNodesList(currentNode as BaseNode);
							break;
						}
						case EShapeType.ARROW:
						case EShapeType.DASHED_LINE:
						case EShapeType.SOLID_LINE: {
							currentNode = new Shape(this, nodeData as INode);
							// const lineObj = currentNode.getDisplayObject();
							// if (lineObj) displayObjects.push(lineObj);
							// lineObj && children.push(lineObj);
							this.addNodeToAllNodesList(currentNode as BaseNode);
							break;
						}
						default:
							break;
					}
					break;
				case 'IMAGE': {
					currentNode = new Image(this, nodeData as INode);
					// const imgObj = currentNode.getDisplayObject();
					// if (imgObj) displayObjects.push(imgObj);
					// imgObj && children.push(imgObj);
					this.addNodeToAllNodesList(currentNode as BaseNode);
					break;
				}
				case 'MARKUP': {
					currentNode = new Markup(this, nodeData as INode);
					// const mrkObj = currentNode.getDisplayObject();
					// mrkObj && children.push(mrkObj);
					this.addNodeToAllNodesList(currentNode as BaseNode);
					break;
				}
				case 'ONBOARDING_IMAGE': {
					currentNode = new OnboardingImage(this, nodeData as INode);
					// const onbrdObj = currentNode.getDisplayObject();
					// onbrdObj && children.push(onbrdObj);
					this.addNodeToAllNodesList(currentNode as BaseNode);
					break;
				}
				case 'FILE_PLACEHOLDER': {
					currentNode = new FilePlaceholder(this, nodeData as INode);
					// const filepObj = currentNode.getDisplayObject();
					// filepObj && children.push(filepObj);
					this.addNodeToAllNodesList(currentNode as BaseNode);
					break;
				}
				case 'SAMPLE_IMAGE': {
					currentNode = new SampleImage(this, nodeData as INode);
					// const sampObj = currentNode.getDisplayObject();
					// sampObj && children.push(sampObj);
					this.addNodeToAllNodesList(currentNode as BaseNode);
					break;
				}
				case 'REACTION': {
					currentNode = new Reaction(this, nodeData as INode);
					// const reactObj = currentNode.getDisplayObject();
					// reactObj && children.push(reactObj);
					this.addNodeToAllNodesList(currentNode as BaseNode);
					break;
				}
				case 'TEXT':
					// if (
					//   (nodesToBeAdded[i] as INode).text
					//   && ((nodesToBeAdded[i] as INode).text?.value as string).length > 0
					// ) {
					//   currentNode = new Text(this, nodesToBeAdded[i] as INode);
					//   currentNode.draw();
					//   // const textObj = currentNode.getDisplayObject();
					//   // textObj && children.push(textObj);
					//   this.addNodeToAllNodesList(currentNode as BaseNode);
					// }
					currentNode = new Text(this, nodeData as INode);
					// const textObj = currentNode.getDisplayObject();
					// textObj && children.push(textObj);
					this.addNodeToAllNodesList(currentNode as BaseNode);
					break;
				case 'STICKY_NOTE': {
					currentNode = new StickyNote(this, nodeData as INode);

					this.addNodeToAllNodesList(currentNode as BaseNode);
					break;
				}
				case 'MODEL': {
					currentNode = new Model(this, nodeData as INode);
					// const modelObj = currentNode.getDisplayObject();
					// modelObj && children.push(modelObj);
					this.addNodeToAllNodesList(currentNode as BaseNode);
					break;
				}
				default:
					break;
			}
		}
		// if (this.nodes && oldNodeIndex) this.nodes[oldNodeIndex] = nodeData;
		if (this.allNodes) {
			if (nodeData.isVisible) {
				// check if nodeData is present in allNodes
				this.allNodes.forEach((an: any) => {
					if (an.nodeData._id === nodeData._id) {
						an.nodeData = nodeData;
						an._isLocked = nodeData.isLocked ? nodeData.isLocked : false;
						an.draw();
						if (index !== -1) {
							this.selectedNodes.push(an);
						}
					}
				});
			} else {
				this.allNodes = this.allNodes.filter((an: any) => {
					if (an.nodeData._id === nodeData._id) {
						this.viewport.removeChild(an.displayObject);
						return false;
					}
					return true;
				});
			}
		}
	};

	/**
	 * Deleted selected nodes
	 * @returns
	 */
	deleteNodes = async () => {
		// const { user } = store.getState();
		// const online = !!(user._id && user.email);
		this.hasCanvasUpdatedForThumbnail = true;
		if (!this.checkIfAnySelectedNodeIsLocked() && !this.isColourPickerOpen) {
			this.removeTransformer();
			// @ts-ignore
			this.selectedNodes.forEach((node) => {
				// unsubscribing from the redux listener
				node.unsubscribeFromListeners();

				this.removeNode(node.nodeData._id!);
			});
			// TODO: removing this works for all nodes except sticky note
			// when I draw a sticky note and then immediately try to delete
			// deletion doesn't work
			// needs a screen refresh to delete
			this.render();

			// Backend was taking time to deleteNodes and just after deleting a node,
			// a diff node was selected, was also getting deleted
			const selectedNodesCopy = _.cloneDeep(this.selectedNodes);
			this.removeAllSelectedNodes();
			this.toggleEditMenu(false);
			this.isColourPickerOpen = false;
			const nodesToDelete: BaseNode[] = [];
			const dataToUndo = [] as Array<{
				prevNodeData: INode | IFeedback;
				newNodeData: INode | IFeedback;
			}>;
			selectedNodesCopy.forEach((snc) => {
				const { nodeData } = snc;
				snc.prevNodeData = _.cloneDeep(snc.nodeData);
				if (nodeData.nodeType !== ENodeType.ONBOARDING_IMAGE && nodeData._id) {
					const nodeDataAfterDelete = _.cloneDeep(nodeData);
					if (nodeDataAfterDelete?.isVisible) {
						nodeDataAfterDelete!.isVisible = false;
						nodeDataAfterDelete.version = nodeDataAfterDelete.version
							? nodeDataAfterDelete.version + 1
							: 1;
					}
					snc.nodeData = nodeDataAfterDelete;
					dataToUndo.push({
						prevNodeData: snc.prevNodeData,
						newNodeData: snc.nodeData
					});
					nodesToDelete.push(snc);
				}
			});
			addBatchUndoActions('EDIT' as EActionType, dataToUndo);
			if (nodesToDelete.length > 0) {
				const nodeDataDiffs = getNodeDataDifferences(nodesToDelete);

				// extracting redux
				const reduxState = store.getState() as ReduxState;

				// generating payload for edit nodes action
				const apiPayload: TEditNodesArgs = {
					data: {
						nodes: nodeDataDiffs,
						...generateIdsFromUrl()
					},
					prevState: {
						prevBlocks: reduxState.nodes.data
					}
				};
				// dispatch edit nodes
				(store.dispatch as CustomDispatch)(editNodes(apiPayload));
			}
		}
	};

	/**
	 * adds the node to allNodes
	 * @param node
	 */
	addNodeToAllNodesList = (node: BaseNode) => {
		if (this.allNodes) {
			if (!this.allNodes.some((an) => an.nodeData._id === node.nodeData._id))
				this.allNodes.push(node);
		} else {
			this.allNodes = [node];
		}
	};

	/**
	 * Resume drag
	 */
	resumeDragPlugin = () => {
		this.viewport.plugins.resume('drag');
		this._dragging = true;
	};

	/**
	 * Pause drag
	 */
	pauseDragPlugin = () => {
		this.viewport.plugins.pause('drag');
		this._dragging = false;
	};

	/**
	 * Initialize the app
	 * Adding app to the canvasLocator
	 * Adding transformer to the viewport
	 * @param canvasLocator
	 */
	initializeApp = (canvasLocator: string) => {
		let isDragging = false;
		// Add app to the canvas
		document.querySelector(canvasLocator)?.appendChild(this.app.view);
		this.app.renderer.view.onselectstart = () => false;

		// add viewport to the app
		this.app.stage.removeChildren();
		this.app.stage.addChild(this.viewport);

		this.removeListeners();

		// this.viewport.on('zoomed', () => { });
		this.viewport.setZoom(0.05, true);
		this.zoomPercent = 5;
		// add transformer to the viewport
		// this.viewport.addChild(this.transformer);

		this.viewport.on('drag-start', () => {
			isDragging = true;
		});
		this.viewport.on('drag-end', () => {
			isDragging = false;
		});

		this.viewport.on('moved-end', () => {
			this.zoomPercent = this.viewport.scale._x;
			this.notifyZoom();
		});

		this.viewport.on('rightclick', () => {
			this.pauseDragPlugin();
			this.toggle3dContext(false);
			this.toggleCanvasMenu(true);
		});
		// document.addEventListener('keydown', (e: any) => {
		//   e.stopImmediatePropagation();
		//   appKeyDownEvent(e, this);
		// });
		document.addEventListener('keydown', appKeyDownEvent);
		document.addEventListener('keyup', appKeyUpEvent);

		this.viewport.on('mousemove', (e: PIXI.InteractionEvent) => {
			appMouseMoveEvent(e.data.originalEvent, this);
			if (this.isPanSelected && isDragging) this.notifyZoom();
		});
		this.viewport.on('mousedown', (e: PIXI.InteractionEvent) =>
			appMouseDownEvent(e.data.originalEvent, this)
		);
		this.viewport.on('mouseup', (e: PIXI.InteractionEvent) =>
			appMouseUpEvent(e.data?.originalEvent, this)
		);

		this.viewport.on('wheel', this.mouseWheel);

		const cull = new SimpleCull(); // new SpatialHash()
		cull.addList(
			this.viewport.children.filter((c: DisplayObject) => c.visible && c.name !== 'TEXT')
		);
		cull.cull(this.viewport.getVisibleBounds());

		// cull whenever the viewport moves
		PIXI.Ticker.shared.add(() => {
			if (this.viewport.dirty) {
				cull.addList(
					this.viewport.children.filter(
						(c: DisplayObject) => c.visible && c.name !== 'TEXT'
					)
				);
				cull.cull(this.viewport.getVisibleBounds());
				this.viewport.dirty = false;
			}
		});

		// document.addEventListener("mousemove",(e:any)=>appMouseMoveEvent(e, this) );
		// document.addEventListener("mousedown",(e:any)=>appMouseDownEvent(e, this) );
		// document.addEventListener("mouseup",(e:any)=>appMouseUpEvent(e, this) );
		// // document.addEventListener("mousedown", (e:any)=>appMouseDownEvent(e, this));
		// document.addEventListener('wheel', this.mouseWheel);
		// document.querySelector(canvasLocator)!.addEventListener('wheel', this.mouseWheel);
	};

	// Set canvas color opacity texture and bounds
	resetCanvasData = () => {
		const canvas = getCurrentBlock() as ICanvas;
		if (canvas) {
			const { bounds } = canvas;
			this.width = bounds?.width as number;
			this.height = bounds?.height as number;

			if (canvas && canvas.bcolor) {
				const { bcolor } = canvas;
				this.bkgColor = bcolor;
			} else {
				this.bkgColor = '#fefefe';
			}

			if (canvas && canvas.pattern) {
				const { type, opacity } = canvas.pattern;
				this.activeTexture = type as string;
				this.textureOpacity = opacity as number;
			} else {
				this.activeTexture = '';
				this.textureOpacity = 0.4;
			}
		}
	};

	removeListeners = () => {
		// this.viewport.off('zoomed')
		this.viewport.off('moved-end');
		// this.viewport.off('drag-end');
		this.viewport.off('rightdown');
		this.viewport.off('keydown');
		this.viewport.off('keyup');
		this.viewport.off('mousemove');
		this.viewport.off('mouseup');
		this.viewport.off('mouseupoutside');
		this.viewport.off('wheel');
		this.viewport.off('drag-end');
		this.viewport.off('drag-start');
		document.removeEventListener('keydown', appKeyDownEvent);
		document.removeEventListener('keyup', appKeyUpEvent);
	};

	/**
	 * Handles the mosedown events for viewport
	 */
	viewportMouseDown = () => {
		this.resetEditMenu();
	};

	/**
	 * Removes the transformer
	 * Saves the cropped portion (if any) and keep the selected portion
	 */
	resetEditMenu = () => {
		if (this.selectedNodes.length > 0) {
			for (let i = 0; i < this.selectedNodes.length; i++) {
				const selectedNode = this.selectedNodes[i];
				if (selectedNode?.nodeData.nodeType === 'IMAGE' && selectedNode._cropMode) {
					this.removeAllSelectedNodes();
					this.removeTransformer();
					// selectedNode.exitCropMode();
				}
			}
		}
	};

	getCanvasBlob = () => {
		try {
			return this.app.renderer.plugins.extract.base64(this.app.stage);
		} catch (error: any) {
			console.error('error : ', error);
			return error;
		}
	};

	/**
	 * Sets the selected node and open appropriate edit menu
	 * @param node
	 */
	setSelected = (node: BaseNode) => {
		if (!node._editMode) {
			if (this.shiftPressed) {
				// check if node is already selected
				let alreadySelected = false;
				this.selectedNodes.forEach((data) => {
					if (data.nodeData._id === node.nodeData._id) {
						alreadySelected = true;
					}
				});
				// if node is already selected --> remove the node
				if (alreadySelected) {
					const temp = this.selectedNodes.filter((x) => {
						if (x?.nodeData.nodeType === ENodeType.IMAGE && x?._cropMode) {
							x?.exitCropMode();
						}
						if (x.nodeData.nodeType === ENodeType.MODEL) {
							x.exitModelEditMode();
						}
						return x.nodeData._id !== node.nodeData._id;
					});
					this.selectedNodes = temp;
				} else {
					// else --> add node to selected nodes
					const temp = this.selectedNodes.concat(node);
					this.selectedNodes = temp;
				}
			} else {
				this.removeAllSelectedNodes();
				this.selectedNodes = [node];
			}
			this.removeTransformer();
			this.addSelectedNodesToTransformer();
			this.toggleEditMenu(false);
			this.toggleEditMenu(true);
			this.viewport.emit('node-selected', this.selectedNodes);
		}
	};

	/**
	 * re-render the app
	 */
	render = () => {
		this.app.renderer.render(this.app.stage);
	};

	/**
	 * remove the transformer, render the app again
	 * - by default, only 'transformchange' and 'transformcommit' is removed, please specify the other
	 */
	removeTransformer = () => {
		this.toggle3dContext(false);
		this.rightClick = false;
		this.transformer.group = [];

		this.app.stage.removeChild(this.transformer);
	};

	/**
	 * function to remove all the seleted nodes
	 */
	removeAllSelectedNodes = () => {
		for (let i = 0; i < this.selectedNodes.length; i++) {
			if (
				this.selectedNodes[i]?.nodeData.nodeType === ENodeType.IMAGE &&
				this.selectedNodes[i]?._cropMode
			) {
				this.selectedNodes[i]?.exitCropMode();
			}
			if (this.selectedNodes[i]?.nodeData.nodeType === ENodeType.MODEL) {
				this.selectedNodes[i]?.exitModelEditMode();
			}
		}
		this.togglePlus(false);
		this.disablePaste = false;
		// this.togglePlus(false);
		this.selectedNodes = [];
	};

	static getTransformerGroupObject = (node: BaseNode) => {
		if (node.nodeData.nodeType === ENodeType.TEXT && node.displayObject) {
			return node.displayObject.children[0];
		}
		return node.displayObject;
	};

	/**
	 * Add node to the transformer
	 * @param node
	 */
	// @ts-ignore
	addNodesToTransformer = () => {
		const temp: Container[] = [];
		for (let i = 0; i < this.selectedNodes.length; i++) {
			const currNode = this.selectedNodes[i];
			if (currNode) {
				const tempGroupObject = App.getTransformerGroupObject(currNode);
				if (tempGroupObject) temp.push(tempGroupObject as Container);
			}
		}
		this.transformer.group = temp;
		// this.pauseDragPlugin()
		// @ts-ignore
		// this.transformer.on('mouseover', (e:any)=>{
		// })
		// this.transformer.group = [node]
		this.app.stage.addChild(this.transformer);
	};

	/**
	 * Draw all the nodes / rerender
	 */
	private async drawNodes(redrawAll: boolean) {
		// clear previous transformer commit and changes
		if (!this.is3DSnapshot) {
			// this.removeAllSelectedNodes();
			this.removeTransformer();
		}

		const children: Viewport['children'] = [];

		if (!this.frame) {
			this.frame = new Frame(this);
		}
		this.frame?.draw();

		let currentNode: BaseNode | Shape;
		if (redrawAll) {
			this.allNodes?.forEach((an) => {
				if (an.nodeData.nodeType === ENodeType.MODEL) (an as Model).disposeScene();
			});
			this.allNodes = undefined;
		}

		/** If zoom stored in session storage, apply zoom before drawing nodes */
		let zoomApplied = false;
		if (window.sessionStorage.getItem('zoom') && !this.ptMode) {
			/** If the canvas was recently visited, use the zoom/center value as it was previously */
			const sessStorageZoom = JSON.parse(window.sessionStorage.getItem('zoom') as string)[
				this.canvasId
			];
			if (sessStorageZoom) {
				/** Object of canvas zoom/center values */
				const obj = JSON.parse(window.sessionStorage.getItem('zoom') as string)[
					this.canvasId
				];
				this.viewport.scale._x = Number(obj.zoomLevel) || 4;
				this.viewport.scale._y = Number(obj.zoomLevel) || 4;
				this.zoomPercent = Number(obj.zoomLevel);
				this.viewport.setZoom(this.viewport.scale._x, true);

				this.viewport.center = new PIXI.Point(obj.center.x, obj.center.y);
				zoomApplied = true;
			}
		}

		let nodesToBeAdded;
		// draw all nodes
		if (this.nodes) {
			if (redrawAll) {
				nodesToBeAdded = this.nodes;
			} else if (this.prevNodes) {
				const prevNodeIds = this.prevNodes.map((pv: any) => pv!._id);
				const allNodesIds = this.allNodes
					? this.allNodes!.map((an) => an.nodeData._id)
					: null;
				nodesToBeAdded = this.nodes.filter((n: any) => {
					if (allNodesIds)
						return !prevNodeIds.includes(n._id) && !allNodesIds.includes(n._id);
					return !prevNodeIds.includes(n._id);
				});
			}
			const imageLoad = [];
			if (nodesToBeAdded) {
				for (let i = 0; i < nodesToBeAdded.length; i++) {
					switch ((nodesToBeAdded[i] as INode).nodeType) {
						case 'SHAPE':
							switch ((nodesToBeAdded[i] as INode).shapeType) {
								case EShapeType.RECTANGLE:
								case EShapeType.ROUNDED_RECTANGLE:
								case EShapeType.ELLIPSE:
								case EShapeType.TRIANGLE:
								case EShapeType.STAR:
								case EShapeType.HEART: {
									currentNode = new Polygon(this, nodesToBeAdded[i] as INode);
									currentNode.draw();
									// const polyObj = currentNode.getDisplayObject();
									// if (polyObj) displayObjects.push(polyObj);
									// polyObj && children.push(polyObj);
									this.addNodeToAllNodesList(currentNode as BaseNode);
									break;
								}
								case EShapeType.ARROW:
								case EShapeType.DASHED_LINE:
								case EShapeType.SOLID_LINE: {
									currentNode = new Shape(this, nodesToBeAdded[i] as INode);
									currentNode.draw();
									// const lineObj = currentNode.getDisplayObject();
									// if (lineObj) displayObjects.push(lineObj);
									// lineObj && children.push(lineObj);
									this.addNodeToAllNodesList(currentNode as BaseNode);
									break;
								}
								default:
									break;
							}
							break;
						case 'IMAGE': {
							currentNode = new Image(this, nodesToBeAdded[i] as INode);
							imageLoad.push(currentNode.draw());
							// const imgObj = currentNode.getDisplayObject();
							// if (imgObj) displayObjects.push(imgObj);
							// imgObj && children.push(imgObj);
							this.addNodeToAllNodesList(currentNode as BaseNode);
							break;
						}
						case 'MARKUP': {
							currentNode = new Markup(this, nodesToBeAdded[i] as INode);
							currentNode.draw();
							// const mrkObj = currentNode.getDisplayObject();
							// mrkObj && children.push(mrkObj);
							this.addNodeToAllNodesList(currentNode as BaseNode);
							break;
						}
						case 'ONBOARDING_IMAGE': {
							currentNode = new OnboardingImage(this, nodesToBeAdded[i] as INode);
							currentNode.draw();
							// const onbrdObj = currentNode.getDisplayObject();
							// onbrdObj && children.push(onbrdObj);
							this.addNodeToAllNodesList(currentNode as BaseNode);
							break;
						}
						case 'FILE_PLACEHOLDER': {
							currentNode = new FilePlaceholder(this, nodesToBeAdded[i] as INode);
							currentNode.draw();
							// const filepObj = currentNode.getDisplayObject();
							// filepObj && children.push(filepObj);
							this.addNodeToAllNodesList(currentNode as BaseNode);
							break;
						}
						case 'SAMPLE_IMAGE': {
							currentNode = new SampleImage(this, nodesToBeAdded[i] as INode);
							currentNode.draw();
							// const sampObj = currentNode.getDisplayObject();
							// sampObj && children.push(sampObj);
							this.addNodeToAllNodesList(currentNode as BaseNode);
							break;
						}
						case 'REACTION': {
							currentNode = new Reaction(this, nodesToBeAdded[i] as INode);
							currentNode.draw();
							// const reactObj = currentNode.getDisplayObject();
							// reactObj && children.push(reactObj);
							this.addNodeToAllNodesList(currentNode as BaseNode);
							break;
						}
						case 'TEXT':
							// if (
							//   (nodesToBeAdded[i] as INode).text
							//   && ((nodesToBeAdded[i] as INode).text?.value as string).length > 0
							// ) {
							//   currentNode = new Text(this, nodesToBeAdded[i] as INode);
							//   currentNode.draw();
							//   // const textObj = currentNode.getDisplayObject();
							//   // textObj && children.push(textObj);
							//   this.addNodeToAllNodesList(currentNode as BaseNode);
							// }
							currentNode = new Text(this, nodesToBeAdded[i] as INode);
							currentNode.draw();
							// const textObj = currentNode.getDisplayObject();
							// textObj && children.push(textObj);
							this.addNodeToAllNodesList(currentNode as BaseNode);
							break;
						case 'STICKY_NOTE': {
							currentNode = new StickyNote(this, nodesToBeAdded[i] as INode);
							currentNode.draw();
							this.addNodeToAllNodesList(currentNode as BaseNode);
							break;
						}
						case 'MODEL': {
							currentNode = new Model(this, nodesToBeAdded[i] as INode);
							currentNode.draw();
							// const modelObj = currentNode.getDisplayObject();
							// modelObj && children.push(modelObj);
							this.addNodeToAllNodesList(currentNode as BaseNode);
							break;
						}
						default:
							break;
					}
				}
				await Promise.all(imageLoad);
			}
		}
		if (this.firstRender && !zoomApplied) {
			this.firstRender = false;
			this.viewport.setZoom(4, true);
			this.zoomPercent = 4;
			if (this.ptMode) {
				this.resizePixiApp();
				this.fullScreenZoom();
			} else {
				/** Visiting canvas first time in the current session */
				this.initialZoom();
			}
			this.getCurrentZoomLevel();
		}

		return children;
	}

	/**
	 * @returns current zoom level of the canvas
	 */
	private getCurrentZoomLevel = () => {
		const { scale } = this.viewport;
		for (let i = 0; i < this.zoomLevels.length - 1; i++) {
			const currentZoomLevel = this.zoomLevels[i];
			const nextZoomLevel = this.zoomLevels[i + 1];
			if (scale._x * 100 > currentZoomLevel! && scale._x * 100 < nextZoomLevel!) {
				this.zoomLevelIndex = i;
				return;
			}
		}
	};

	/**
	 * Add nodes
	 * @param nodes
	 */
	addNodes = async (nodes: INode[], redrawAll: boolean) => {
		// Store the passed nodes
		this.prevNodes = redrawAll ? [] : this.nodes;
		if (nodes && nodes.length > 0) {
			this.nodes = _.cloneDeep(nodes.filter((node) => (node as INode).isVisible));
		} else {
			this.nodes = [];
		}
		if (redrawAll) {
			if (this.allNodes) {
				this.allNodes.forEach((node: BaseNode) => {
					reduxManager.unsubscribe(node.nodeData._id as string);
				});
				this.allNodes = [];
			}
			this.viewport.removeChildren();
			if (!this.frame) {
				this.frame = new Frame(this);
			}
			this.frame?.draw();
		}
		// const children = await
		this.drawNodes(redrawAll);
		// children.length > 0 && this.viewport.addChild(...children);
		if (this.selectedNodes.length > 0 && !this.editText) {
			this.addSelectedNodesToTransformer();
		}
	};

	removeNode = (nodeId: string) => {
		const nodeToBeRemoved = this.allNodes?.find((an: BaseNode) => an.nodeData._id === nodeId);
		if (nodeToBeRemoved && nodeToBeRemoved.displayObject && !this.isColourPickerOpen) {
			this.viewport.removeChild(nodeToBeRemoved.displayObject);
		}
		this.allNodes = this.allNodes?.filter((an: BaseNode) => an.nodeData._id !== nodeId);
	};

	updateNode = (node: INode) => {
		const nodeToBeUpdated = this.allNodes?.find((an: BaseNode) => an.nodeData._id === node._id);
		if (nodeToBeUpdated) {
			nodeToBeUpdated.updateNode(node);
		}
	};

	resetFileIconColor = () => {
		this.allNodes
			?.filter((an: BaseNode) => an.nodeData.nodeType === ENodeType.FILE_PLACEHOLDER)
			.forEach((fn: BaseNode) => {
				const fileNode = fn as FilePlaceholder;
				if (!fileNode.displayObject) return;
				fileNode.selected = false;
				const activeColor = PIXI.utils.string2hex('#5D5D5D');
				const sprite = fileNode.iconSprite;
				const text = fileNode.displayObject.children[1];
				if (sprite) {
					sprite.tint = activeColor;
					sprite.color.dark[0] = sprite.color.light[0] as number;
					sprite.color.dark[1] = sprite.color.light[1] as number;
					sprite.color.dark[2] = sprite.color.light[2] as number;
				}
				if (text) {
					fileNode.fileTextStyle.fill = activeColor;
				}
			});
	};

	initializeFrameDimentions = (x: number, y: number, width: number, height: number) => {
		this.frameX = x;
		this.frameY = y;
		this.width = width;
		this.height = height;
	};

	setBackground = async (pattern: string, opacity: number, bkgColor: string) => {
		const reduxState = store.getState() as ReduxState;
		const block = reduxState.blocks.data[this.canvasId] as IBlock;
		if (
			this.activeTexture !== pattern ||
			this.textureOpacity !== opacity ||
			this.bkgColor !== bkgColor
		) {
			this.activeTexture = pattern;
			this.textureOpacity = opacity;
			this.bkgColor = bkgColor;
			this.frame?.setBackgroundTexture();
			// generating payload for edit block action
			const apiPayload: TEditBlockArgs = {
				data: {
					block: {
						_id: block._id,
						bcolor: bkgColor,
						pattern: { opacity, type: pattern as keyof typeof EPattern_Type }
					},
					...generateIdsFromUrl()
				},
				prevState: {
					prevStateBlock: block
				}
			};
			// edit block dispatch
			await (store.dispatch as CustomDispatch)(editBlock(apiPayload));
		}
	};

	/**
	 * get the highest zIndex of all the nodes
	 */
	getHighestZIndex = () => {
		let max = 0;
		const nodes = this.allNodes;
		if (nodes) {
			nodes.forEach((n: BaseNode) => {
				const currZIndex = n.nodeData.zIndex;
				if (currZIndex && currZIndex > max) {
					max = currZIndex;
				}
			});
		}
		return max;
	};

	/**
	 * get the lowest zIndex of all the nodes
	 */
	getLowestZIndex = () => {
		let min = 1;
		const nodes = this.allNodes;
		if (nodes) {
			nodes.forEach((n: BaseNode) => {
				const currZIndex = n.nodeData.zIndex;
				if (currZIndex && currZIndex < min) {
					min = currZIndex;
				}
			});
		}
		return min;
	};

	/**
	 * Returns new zIndex of the selected node based on the existing nodes zIndex
	 * @param isBackwardMovement
	 */
	getNewZindex = (
		selectedNode: BaseNode | undefined,
		isBackwardMovement: boolean,
		isExtremeMovement: boolean
	) => {
		this.allNodes?.sort((a: BaseNode, b: BaseNode) => {
			const diff = a!.nodeData.zIndex! - b!.nodeData.zIndex!;
			if (diff === 0) return 0;
			if (diff > 0) return 1;
			return -1;
		});
		let selectedNodeId: string | undefined;
		let selectedNodeZIndex;
		let selectedNodeIndex;

		if (!selectedNode) {
			selectedNodeId = this.selectedNodes[0]?.nodeData._id;
			selectedNodeZIndex = (this.selectedNodes[0]?.nodeData.zIndex as number) || 0;
			selectedNodeIndex = this.nodes?.findIndex(
				(n: any) => n._id === selectedNodeId
			) as number;
		} else {
			selectedNodeId = selectedNode.nodeData._id;
			selectedNodeZIndex = selectedNode.nodeData.zIndex as number;
			selectedNodeIndex = this.allNodes?.findIndex(
				(n: BaseNode) => n.nodeData._id === selectedNodeId
			) as number;
		}
		const currentNodes = this.allNodes;
		if (!currentNodes) return 0;
		if (isBackwardMovement) {
			if (isExtremeMovement) {
				if (selectedNodeIndex === 0) return selectedNodeZIndex;
				return currentNodes[0]!.nodeData.zIndex! / 2;
			}
			const belowIndex = selectedNodeIndex - 1;
			const belowIndex2 = selectedNodeIndex - 2;
			if (belowIndex < 0) return selectedNodeZIndex;
			if (belowIndex === 0) return selectedNodeZIndex / 2;
			if (currentNodes) {
				return (
					(currentNodes[belowIndex]!.nodeData.zIndex! +
						currentNodes[belowIndex2]!.nodeData.zIndex!) /
					2
				);
			}
		} else {
			if (isExtremeMovement) {
				if (selectedNodeIndex === currentNodes.length - 1) return selectedNodeZIndex;
				return Math.floor(currentNodes[currentNodes.length - 1]!.nodeData.zIndex! + 1);
			}
			const aboveIndex = selectedNodeIndex + 1;
			const aboveIndex2 = selectedNodeIndex + 2;
			if (aboveIndex > currentNodes.length - 1) return selectedNodeZIndex;
			if (aboveIndex === currentNodes.length - 1) {
				return Math.floor(currentNodes[currentNodes.length - 1]!.nodeData.zIndex! + 1);
			}
			if (currentNodes) {
				return (
					((currentNodes[aboveIndex]!.nodeData.zIndex! || 0) +
						(currentNodes[aboveIndex2]?.nodeData.zIndex! || 0)) /
					2
				);
			}
		}

		return selectedNodeZIndex;
	};

	/**
	 * Resizes the PIXI app to specific with and height
	 * @param width
	 * @param height
	 */
	resize = (width: number, height: number) => {
		this.centerViewport(width, height);
		this.width = width;
		this.height = height;

		this.render(); // sort of a hack, might need to clean up
	};

	/**
	 * resizes the pixi app based about the browser dimensions
	 * used in Canvas.tsx on window listener for resize
	 * */
	resizePixiApp = () => {
		this.app.resizeTo = window;
		this.viewport.screenWidth = window.innerWidth;
		this.viewport.screenHeight = window.innerHeight;
		this.viewport.worldWidth = window.innerWidth + 100;
		this.viewport.worldHeight = window.innerHeight + 100 + this.navbarHeight;
		this.centerViewport(this.width, this.height);
		if (this.ptMode) this.app.renderer.resize(1920, 1080);
		else {
			this.app.renderer.resize(window.innerWidth, window.innerHeight);
			this.initialZoom();
		}
	};

	/** handles the wheel movement of the mouse */
	private mouseWheel = () => {
		this.updateFeedbacks();
		if (this.frame?.outline) this.highlightFrame();
	};

	/**
	 * resizes the canvas to fit the screen --> i.e. the whole canvas is visible at once on the screen
	 */
	resizeToFit = () => {
		const bounds = new PIXI.Rectangle();
		this.viewport.children.forEach((child) => {
			if (child.getBounds) {
				bounds.fit(child.getBounds());
			}
		});

		const screenWidth = window.innerWidth;
		const screenHeight = window.innerHeight;
		const scale = Math.min(screenWidth / bounds.width, screenHeight / bounds.height);
		const centerX = (screenWidth - bounds.width * scale) / 2;
		const centerY = (screenHeight - bounds.height * scale) / 2;

		if (
			bounds.x < 0 ||
			bounds.y < 0 ||
			bounds.x + bounds.width > this.viewport.screenWidth ||
			bounds.y + bounds.height > this.viewport.screenHeight
		) {
			if (this.viewport.scale.x > 0.05) {
				this.viewport.moveCenter(centerX + bounds.width / 2, centerY + bounds.height / 2);
				this.viewport.setZoom(scale, true);
				this.render();
				this.notifyZoom();
				this.resizeToFit();
			}
		} else {
			this.viewport.moveCenter(centerX + bounds.width / 2, centerY + bounds.height / 2);
			this.initialZoom();
			this.notifyZoom();
		}
	};

	highlightFrame = async () => {
		await this.frame?.highlightFrame();
	};

	removeFrameHighlight = () => {
		this.frame?.removeFrameHighlight();
	};

	selectFrame = () => {
		if (this.frame && Frame.checkIfUserHasEditAccess()) {
			this.removeFrameHighlight();
			this.app.stage.removeChild(this.transformer);
			this.transformer.group = [this.frame?.displayObject];
			this.transformerType = 'rotate_disable';
			this.app.stage.addChild(this.transformer);

			this.transformer.on('transformchange', () => {
				const position = this.frame?.displayObject.transform.position;
				const xFlip = this.frame?.displayObject.transform.localTransform.a < 0;
				const yFlip = this.frame?.displayObject.transform.localTransform.d < 0;
				const width = this.frame?.displayObject.width;
				const height = this.frame?.displayObject.height;
				let { x } = position;
				let { y } = position;
				this.width = width;
				this.height = height;
				if (xFlip) {
					x -= width;
				}
				if (yFlip) {
					y -= height;
				}
				this.frameX = x;
				this.frameY = y;
				this.frame?.setBackgroundTexture();
				if (this.frame?.displayObject.height < 100) {
					this.frame!.displayObject.height = 100;
				}
				if (this.frame?.displayObject.width < 100) {
					this.frame!.displayObject.width = 100;
				}
				this.toggleCanvasEditMenu(false);
				this.setShowPageName(false);
				// this.app.render();
			});
			this.transformer.on('transformcommit', () => {
				const position = this.frame?.displayObject.transform.position;
				const xFlip = this.frame?.displayObject.transform.localTransform.a < 0;
				const yFlip = this.frame?.displayObject.transform.localTransform.d < 0;
				const width = this.frame?.displayObject.width;
				const height = this.frame?.displayObject.height;
				let { x } = position;
				let { y } = position;
				this.width = width;
				this.height = height;
				if (xFlip) {
					x -= width;
				}
				if (yFlip) {
					y -= height;
				}
				this.frameX = x;
				this.frameY = y;
				this.frame?.setBackgroundTexture();
				this.toggleCanvasEditMenu(true);
				this.setShowPageName(true);
				// extracting redux
				const reduxState = store.getState() as ReduxState;

				// extracting block
				const block = reduxState.blocks.data[this.getCanvasId()] as IBlock;

				// generating payload for edit block action
				const apiPayload: TEditBlockArgs = {
					data: {
						block: {
							bounds: {
								width,
								height,
								x,
								y
							},
							_id: this.getCanvasId()
						},
						...generateIdsFromUrl()
					},
					prevState: {
						prevStateBlock: block
					}
				};
				// edit block dispatch
				(store.dispatch as CustomDispatch)(editBlock(apiPayload));
			});
			this.canvasSelected = true;
			this.toggleCanvasEditMenu(true);
			this.toggleEditMenu(false);
		}
	};

	// eslint-disable-next-line class-methods-use-this
	getBoundsForRotated = (node: BaseNode) =>
		new Promise((resolve) => {
			setTimeout(() => {
				const corners = App.calculateTransformedCorners(
					node?.displayObject as DisplayObject
				);
				let minX = 9999;
				let minY = 9999;
				let maxX = -9999;
				let maxY = -9999;
				// eslint-disable-next-line no-restricted-syntax
				for (const corner of corners) {
					if (corner.x < minX) minX = corner.x;
					if (corner.y < minY) minY = corner.y;

					if (corner.x > maxX) maxX = corner.x;
					if (corner.y > maxY) maxY = corner.y;
				}

				// Transformer.calculateOrientedBounds(node.displayObject).topLeft)
				const bounds = {
					x: minX,
					y: minY,
					width: maxX - minX,
					height: maxY - minY
				};
				resolve(bounds);
			}, 1);
		});

	getMinMaxBounds = async () => {
		const canvas = fetchBlockByIdFromRedux(this.canvasId) as ICanvas;
		let minX = canvas.bounds!.x!;
		let minY = canvas.bounds!.y!;

		let maxX = canvas.bounds!.width! + minX;
		let maxY = canvas.bounds!.height! + minY;

		if (minX > 0 && (this.allNodes as BaseNode[])?.length === 0) maxX = canvas.bounds!.width!;

		if (minY > 0 && (this.allNodes as BaseNode[])?.length === 0) maxY = canvas.bounds!.height!;

		for (let i = 0; i < (this.allNodes as BaseNode[])?.length; i++) {
			const node = (this.allNodes as BaseNode[])[i] as BaseNode;
			let bounds = _.cloneDeep(node.nodeData.absoluteBounds!);
			if (node.nodeData.nodeType === 'MARKUP' || node.nodeData.nodeType === 'IMAGE')
				bounds = node.displayObject?.getLocalBounds()!;
			if (bounds === undefined) {
				bounds = node.nodeData.absoluteBounds!;
			}
			if (node.nodeData.rotation) {
				// eslint-disable-next-line no-await-in-loop
				bounds = (await this.getBoundsForRotated(node)) as TAbsoluteBoundingBox;
			}
			let scaleX = 1;
			let scaleY = 1;
			let mask;
			bounds.width = bounds.width ? bounds.width! : 200;
			bounds.height = bounds.height ? bounds.height! : 200;

			// eslint-disable-next-line no-empty
			if (node.nodeData?.rotation && node.nodeData.rotation !== 0) {
			} else if (node.nodeData.nodeType === 'IMAGE') {
				scaleX = node.nodeData.scale
					? (node.nodeData.scale.scaleX as number)
					: 300 / bounds.width;
				scaleY = node.nodeData.scale
					? (node.nodeData.scale.scaleY as number)
					: 300 / bounds.height;
				mask = node.nodeData?.image?.mask;
				if (mask && mask.x !== 0 && mask.y !== 0 && scaleX !== 1 && scaleY !== 1) {
					bounds.x =
						node.nodeData.absoluteBounds!.x! +
						(bounds.x! + (mask.x! * mask.width!)!) * scaleX;
					bounds.y =
						node.nodeData.absoluteBounds!.y! +
						(bounds.y! + (mask.y! * mask.height!)!) * scaleY;

					bounds.width = bounds.width * mask.width! * scaleX!;
					bounds.height = bounds.height * mask.height! * scaleY!;
				} else {
					bounds.x = node.nodeData.absoluteBounds!.x;
					bounds.y = node.nodeData.absoluteBounds!.y;

					// bounds.x = node.nodeData.absoluteBounds!.x! + (bounds.x! * scaleX);
					// bounds.y = node.nodeData.absoluteBounds!.y! * (bounds.y! * scaleY);
					bounds.width = bounds.width! * scaleX;
					bounds.height = bounds.height! * scaleY;
				}
			} else if (node.nodeData.nodeType === 'MARKUP') {
				scaleX = node.nodeData.scale ? (node.nodeData.scale!.scaleX as number) : 1;
				scaleY = node.nodeData.scale ? (node.nodeData.scale!.scaleY as number) : 1;
				bounds.x = node.nodeData.absoluteBounds
					? node.nodeData.absoluteBounds!.x! + (bounds.x! * scaleX)!
					: bounds.x;
				bounds.y = node.nodeData.absoluteBounds
					? node.nodeData.absoluteBounds!.y! + bounds.y! * scaleY
					: bounds.y;
			}

			if (minX > bounds.x!) minX = bounds.x!;
			if (minY > bounds.y!) minY = bounds.y!;

			if (mask) {
				if (bounds.x! + bounds.width! > maxX) {
					maxX = bounds.x! + bounds.width!!;
				}
				if (bounds.y! + bounds.height! > maxY) {
					maxY = bounds.y! + bounds.height!;
				}
			} else {
				if (bounds.x! + bounds.width! * scaleX! > maxX) {
					maxX = bounds.x! + bounds.width! * scaleX!;
				}
				if (bounds.y! + bounds.height! * scaleY! > maxY) {
					maxY = bounds.y! + bounds.height! * scaleY!;
				}
			}
		}
		return [minX, minY, maxX! - minX!, maxY - minY!];
	};

	/** Initialize the zoom percent for the canvas initially
	 * so that it fits the screen and the entire canvas is visible at once */
	initialZoom = async () => {
		if (this.viewport.children.length > 0) {
			const [minX, minY, maxX, maxY] = await this.getMinMaxBounds();

			this.viewport.center = new PIXI.Point(maxX! / 2, maxY! / 2);

			this.viewport.ensureVisible(minX!, minY!, maxX!, maxY!, true);

			const scale = this.viewport.scale.x;
			if (Math.abs(this.viewport.screenWidthInWorldPixels - (maxX as number)) < 2) {
				this.viewport.ensureVisible(
					minX! - 60 / scale,
					minY! - 60 / scale,
					maxX! + 120 / scale,
					maxY!,
					true
				);
				this.viewport.center = new PIXI.Point(
					this.viewport.center.x,
					minY! + maxY! / 2 - 25 / scale
				);
			}

			if (Math.abs(this.viewport.screenHeightInWorldPixels - (maxY as number)) < 2) {
				this.viewport.ensureVisible(
					minX!,
					minY! - 60 / scale - 50 / scale,
					maxX!,
					maxY! + 100 / scale + 60 / scale,
					true
				);
				this.viewport.center = new PIXI.Point(minX! + maxX! / 2, this.viewport.center.y);
			}

			this.zoomPercent = this.viewport.scale._x;
			// setting the zoom percent such that by default the canvas does overlap the canvas navigation component
			this.viewport.setZoom(this.viewport.scale._x - 0.02, true);

			this.getCurrentZoomLevel();
			this.viewport.emit('zoomed-end');
			this.notifyZoom();
			/** After the viewport centers, the page name posiiton doesnt update
			 * Need better approach
			 */
			this.toggleEditMenu(false);
		}
	};

	getMinMaxBoundsFrame = () => {
		const canvas = fetchBlockByIdFromRedux(this.canvasId) as ICanvas;

		const minX = canvas.bounds!.x!;
		const minY = canvas.bounds!.y!;
		const maxX = canvas.bounds!.width!;
		const maxY = canvas.bounds!.height!;

		const finalwidth = maxX;
		const finalHeight = maxY;

		return [minX, minY, finalwidth, finalHeight];
	};

	fullScreenZoom = () => {
		if (this.viewport.children.length > 0) {
			this.viewport.setZoom(4, true);
			this.zoomPercent = 4;

			this.viewport.center = new PIXI.Point(1920, 1080);

			const [minX, minY, maxX, maxY] = this.getMinMaxBoundsFrame();

			/** Fit the max and min bounds first */
			this.viewport.ensureVisible(minX!, minY!, maxX!, maxY!, true); // --working

			this.viewport.mask = null;

			const fsMask = new PIXI.Graphics();
			fsMask.beginFill(0xffffff);
			fsMask.drawRect(minX! as number, minY! as number, maxX as number, maxY as number); // In this case it is 8000x8000
			fsMask.endFill();
			this.removeTransformer();
			this.viewport.addChild(fsMask);
			this.viewport.mask = fsMask;

			this.viewport.center = new PIXI.Point(minX! + maxX! / 2, minY! + maxY! / 2);
		}
	};

	/** ---------------------- DEPRECATED FUNCTIONS ---------------------- */
	/**
	 * DEPRECATED: Zoom into the canvas by 10 percent
	 */
	zoomIn = () => {
		if ((this.zoomPercent + 1) * this.zoomMultiplyingFactor < 401) {
			this.centerViewport();
			this.viewport.setZoom((this.zoomPercent + 1) / 100, true);
			this.zoomPercent += 1;
			// if (
			//   this.zoomPercent * this.zoomMultiplyingFactor > this.zoomLevels[this.zoomLevelIndex]! &&
			//   this.zoomPercent * this.zoomMultiplyingFactor
			// <= this.zoomLevels[this.zoomLevelIndex + 1]!
			// ) {
			// } else
			if (this.zoomLevelIndex < 16) {
				this.zoomLevelIndex += 1;
			}
			this.render();
			this.notifyZoom();
		}
	};

	/**
	 * DEPRECATED: Zoom out of the canvas by 10 percent
	 */
	zoomOut = () => {
		if ((this.zoomPercent - 1) * this.zoomMultiplyingFactor > 9) {
			this.centerViewport();
			this.viewport.setZoom((this.zoomPercent - 1) / 100, true);
			this.zoomPercent -= 1;
			/** Set proper zoomIndex based on the current zoom */
			// TODO: MADHUMITA: Is this if condition necessary? Cleanup
			// if ((this.zoomPercent * this.zoomMultiplyingFactor) < this.zoomLevels[this.zoomLevelIndex]!
			// && (this.zoomPercent * this.zoomMultiplyingFactor)
			// >= this.zoomLevels[this.zoomLevelIndex - 1]!) {
			// } else
			if (this.zoomLevelIndex > 0) {
				this.zoomLevelIndex -= 1;
			}
			this.render();
			this.notifyZoom();
		}
	};
}

export default App;
