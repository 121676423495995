import * as PIXI from 'pixi.js';
import { INode, TAbsoluteBoundingBox, TMask } from '@naya_studio/types';
import _ from 'lodash';
import BaseNode from './BaseNode';
import { positionAuthorImage, positionAuthorFill } from './utils/helper';

class Image extends BaseNode {
  static defaultNodeData: INode = {
    nodeType: 'IMAGE',
    isVisible: true,
    absoluteBounds: {
      x: 0,
      y: 0,
      width: 32,
      height: 32,
    },
  };

  _clicked: boolean | undefined;

  _double?: any;

  // _cropMode: boolean = false;
  baseImage?: PIXI.Sprite;

  cropMask?: PIXI.Graphics;

  texture?: PIXI.Texture;

  wrapper?: PIXI.Container;

  prevMaskData?: TMask;

  /**
   * Edit the node
   * @param {string} type -- defines the edit type
   * @param data -- data to be changed
   */
  edit = (type: string, data: any) => {
    this.app.removeTransformer();
    this.prevNodeData = JSON.parse(JSON.stringify(this.nodeData));
    // this.nodeData.rotation = 0
    switch (type) {
      case 'ZINDEX':
        this.nodeData.zIndex = data;
        break;
      case 'AUTHOR':{
        // Rotation of mask
        const mRotation = this.nodeData.image?.mask?.rotation;
        if(data && (mRotation === 0 || mRotation === undefined)){
          this.nodeData.showAuthor = true;
        }
        else{
          this.nodeData.showAuthor = false;
        }
        break;
      }
      case 'OPACITY': {
        this.nodeData.opacity = data;
        break;
      }
      default:
        break;
    }
    this.draw();
  };

  getDisplayObject() {
    //
    return this.wrapper;
  }

  /**
   * Get the scale for the initial image draw.
   *  This is done to avoid large image textures taking over the screen
   * @param texture Pass the texture to get the original size from
   * @returns {scaleX, scaleY} to set for the initial drawing
   */
  // eslint-disable-next-line class-methods-use-this
  getInitScale(texture: PIXI.Texture) {
    let scaleX = 1; let
      scaleY = 1;
    const { height, width } = texture;

    if (width > height) {
      scaleX = 300 / width;
      scaleY = scaleX;
    } else {
      scaleY = 300 / height;
      scaleX = scaleY;
    }
    return { scaleX, scaleY };
  }

  /**
   * Saves crop mode
   */
  saveCropMode = () => {
    const { position, scale, rotation } = this.displayObject!.transform;
    // If in crop mode, the transformations apply to the mask
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { _x, _y } = scale;
      const { x, y } = position;

      this.nodeData.image!.mask! = {
        x,
        y,
        width: _x,
        height: _y,
        rotation: rotation,
      };

      // Hide the Author Tag if there's rotation to the Mask
      const mRotation = this.nodeData.image?.mask?.rotation;
      if(mRotation === 0 || mRotation === undefined){
        this.nodeData.showAuthor = true;
      }
      else{
        this.nodeData.showAuthor = false;
      }
  };

  revertCropChanges = () => {
    this.nodeData.image!.mask = this.prevMaskData as TMask;
    this._cropMode = false;
    this.app.cropModeActive = false;
    this.app.removeTransformer();
    // this.save();
    this.draw();
  };

  addCropModeToTransformer = (node: any) => {
    this.app.selectedNodes = [node];
    this.app.transformer.group = [node.displayObject];
    this.app.app.stage.addChild(this.app.transformer);
    this.app.render();
  };

  /**
   * Toggles between crop mode
   */
  setCropMode = () => {
    this.app.transformer.lockAspectRatio = false;
    this.prevNodeData = _.cloneDeep(this.nodeData);
    if (this.app.transformerType !== 'crop') this.app.transformerType = 'crop';
    this.prevMaskData = this.nodeData.image?.mask;
    this._cropMode = true;
    // this._cropMode = !this._cropMode
    if (this._cropMode) {
      this.app.toggleEditMenu(false);
      this.app.isColourPickerOpen = false;
    }
    this.draw();

    // This is needed because the displayObject changes between cropmodes
    this.app.removeTransformer();
    this.addCropModeToTransformer(this);
  };

  /**
   * Exit crop mode
   * Save the transformer data
   */
  exitCropMode = () => {
    this.app.transformer.lockAspectRatio = true;
    this.saveCropMode();
    this.save();
    // this.addUndoAction();
    this._cropMode = false;
    this.app.cropModeActive = false;
    this.app.transformerType = 'default';
    this.app.removeAllSelectedNodes();
    this.draw();
  };

  rotate = () => {
    if (this.displayObject) {
      if (!this.displayObject?.rotation) {
        this.displayObject!.rotation = 0;
      }
      this.displayObject!.rotation += this.nodeData.rotation!;
    }
  };

  generateCloudinaryURL = (width: number) => {
    const fileName = this.nodeData.image?.src?.toString()!.split('/').pop()?.split('?')[0];
    let widthCopy = width;
    widthCopy = Math.round((widthCopy / 100)) * 100;
    if (widthCopy > (this.nodeData.absoluteBounds?.width || 300)) {
      return `${process.env.REACT_APP_CLOUDINARY_URL}${process.env.REACT_APP_CLOUDINARY_BUCKET}/${fileName}`;
    }
    return `${process.env.REACT_APP_CLOUDINARY_URL}c_scale,w_`
    + `${Math.floor(widthCopy)}/${process.env.REACT_APP_CLOUDINARY_BUCKET}/${fileName}`;
  };

  /**
   * Draw image
   */
  draw = async () => {
    // Remove container before re-drawing
    const prevWrapper = this.wrapper;
    const clonedData = _.cloneDeep(this.nodeData);

    if (this.nodeData.image) {
      // Don't need to reload texture if it already exists
      // let id = this.nodeData._id
      if (!this.texture) {
        // if (`img-${id}` in PIXI.Loader.shared.resources) {
        if (this.nodeData.image?.src!.toString() in PIXI.Loader.shared.resources) {
          // this.texture = PIXI.Loader.shared.resources[`img-${id}`]?.texture;
          this.texture = PIXI.Loader.shared.resources[this.nodeData.image?.src!]?.texture;
        }
      }

      if(!this.texture) {
        this.texture = await PIXI.Texture.fromURL(this.nodeData.image?.src!);
        this.nodeData = clonedData;
      }

      let {
        scale, rotation,
      } = this.nodeData;

      const {
        absoluteBounds, image: imageData,
      } = this.nodeData;

      rotation = rotation || 0;
      const { width: iWidth, height: iHeight } = absoluteBounds as TAbsoluteBoundingBox;

      // Limit the maxWidth or maxHeight
      if (!scale) scale = this.getInitScale(this.texture!);

      // By default the cropped image is the same size as the full image
      if (!imageData?.mask) {
        this.nodeData.image!.mask = {
          x: 0,
          y: 0,
          // Note that this is relative value. 1 means 100% of image width
          height: 1,
          width: 1,
          rotation: 0,
        };
      }

      const {
        // The x coordinate is relative to the top left of the wrapper
        x: mX,
        // The y coordinate is relative to the top left of the wrapper
        y: mY,
        // Note that this is relative value. 1 means 100% of image width
        width: mScaleX,
        // Note that this is relative value. 1 means 100% of image height
        height: mScaleY,
        // Note that this is rotation value of the crop frame(i.e mask). It is different from Image Rotation Value.
        rotation: mRotation,
      } = this.nodeData.image!.mask as TMask;

      // Wrapper is a container
      this.wrapper = new PIXI.Container();

      // Base Image is a Sprite
      this.baseImage = new PIXI.Sprite(this.texture);
      const fitScale = (this.nodeData.absoluteBounds?.width! || 300) / this.texture.width;
      this.baseImage.scale.set(fitScale, fitScale);
      this.baseImage.alpha = this.nodeData.opacity !== undefined ? this.nodeData.opacity : 1;

      // Crop Mask is a rectangle
      this.cropMask = new PIXI.Graphics();
      this.cropMask.beginFill(0xFFFFFF);
      // Crop mask always initialized to full size and
      // transformed to the right dimensions using setTransform below
      this.cropMask.drawRect(0, 0, iWidth!, iHeight!);
      this.cropMask.endFill();
      // Crop mask is resized based on data
      this.cropMask.setTransform(mX!, mY!, mScaleX!, mScaleY!);
      this.cropMask.rotation = mRotation || 0;
      // this.cropMask.alpha = 0.4;
      // this.cropMask.clear()

      // Add author if required
      const createdBy = this.nodeData.createdBy as any;
      const profilePicStatus = typeof createdBy === 'object' && 'profilePic' in createdBy && createdBy.profilePic !== '';
      const userNameStatus = typeof createdBy === 'object' && 'userName' in createdBy && createdBy.userName !== '';
      const currentWidth = iWidth! * mScaleX!;
      const currnetHeight = iHeight! * mScaleY!;
      if (this.nodeData.nodeType === 'IMAGE' && this.nodeData.showAuthor && profilePicStatus) {
        const authorImage = PIXI.Sprite.from(createdBy.profilePic);
        const circularMask = new PIXI.Graphics();
        positionAuthorImage(
          authorImage,
          circularMask,
          {
            width: currentWidth,
            height: currnetHeight,
            x: mX!,
            y: mY!,
            scaleX: scale.scaleX!,
            scaleY: scale.scaleY!,
            rotation: -rotation,
          },
          0.1,
        );
        this.baseImage.addChild(authorImage);
        this.baseImage.addChild(circularMask);
        authorImage.mask = circularMask;
        this.isAuthorImage = true;
        this.author = {
          image: authorImage,
          circularMask,
        };
      } else if (this.nodeData.nodeType === 'IMAGE' && this.nodeData.showAuthor && userNameStatus) {
        // TODO: pixi graphics to be replaced with corresponding letter picture
        const circleFill = new PIXI.Graphics();
        const circleText = new PIXI.Text(createdBy.userName.toUpperCase()[0]);
        positionAuthorFill(
          circleFill,
          circleText,
          {
            width: currentWidth,
            height: currnetHeight,
            x: mX!,
            y: mY!,
            scaleX: scale.scaleX!,
            scaleY: scale.scaleY!,
            rotation: -rotation,
          },
          0.1,
        );
        this.baseImage.addChild(circleFill);
        this.pixiAuthor = {
          graphics: circleFill,
          text: circleText,
        };
        this.isAuthorImage = false;
      }

      // Add both baseImage and cropMask to wrapper
      this.wrapper.addChild(this.baseImage);
      this.wrapper.addChild(this.cropMask);
      // console.log("newzindex", this.nodeData.zIndex);
      this.wrapper.zIndex = this.nodeData.zIndex as number;

      // Normal Mode
      if (!this._cropMode) {
        this.baseImage.mask = this.cropMask;
        // The container is moveable. Children move, rotate, resize along with it
        this.displayObject = this.wrapper;
      // eslint-disable-next-line @typescript-eslint/brace-style
      }
      // Crop Mode
      else {
        // Create a copy of the baseImage and crop it with the mask.
        // This acts as the clear window on top of the greyed out baseImage in the back
        const croppedImage = new PIXI.Sprite(this.texture);
        croppedImage.mask = this.cropMask;
        this.wrapper.addChild(croppedImage);
        // if (this.cropMask.width >= iWidth!) {
        //   this.cropMask.width -= 100;
        //   this.cropMask.height -= 100;
        // }
        // Set the dimensions of the new sprite for crop image to the crop window, 
        // the dimensions will change as user will change the crop window
        croppedImage.scale.set(fitScale, fitScale);
        
        // Tint the baseImage with a grey
        this.baseImage.tint = 0xFFFFFF;
        this.baseImage.alpha = 0.6;
        // Set the crop mask to be the movable component
        this.displayObject = this.cropMask;
      }
      // The wrapper is scaled or rotated to scale everything inside
      // Below, The pivot is set to the center of the Image --
      // (Width/2 , Height /2) and the rotation is updated by +0.785398 (45 Deg)
      // radians each time the rotate icon on a Image Node is clicked.
      // this.wrapper.setTransform(absoluteBounds?.x!, absoluteBounds?.y!, scale?.scaleX,
      // scale?.scaleY, rotation , 0, 0,this.displayObject.width!/2,
      // this.displayObject.height!/2);--> his line is BUGGY
      // eslint-disable-next-line function-paren-newline
      this.wrapper.setTransform(
        absoluteBounds?.x!, absoluteBounds?.y!, scale?.scaleX, scale?.scaleY, rotation);
      // shape, markup, comment, and reaction should NOT be interactive
      this.displayObject.interactive = this.app.checkIfNodesShouldBeInteractive();
      this.displayObject.name = 'IMAGE';
      if (prevWrapper) this.app.viewport.removeChild(prevWrapper);
      this.app.viewport.addChild(this.wrapper);
      this.displayObject.on('click', this.onClick);
      this.displayObject.on('mousedown', this.onMouseDown);
      this.displayObject.on('mouseup', this.onMouseUp);
      this.displayObject.on('mouseover', this.onMouseOver);
      this.displayObject.on('mouseout', this.onMouseOut);
      this.displayObject.on('rightclick', this.rightClick);
      // this.render();
    }
  };
}

export default Image;
