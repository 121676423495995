import { store } from 'src';
import { CustomDispatch } from 'src/redux/actions/types';
import {
	EIntegrationType,
	TIntegrationDetails,
	TIntegrations
} from 'src/redux/reducers/root.types';
import { renewGoogleAccessToken, renewMiroAccessToken } from '.';

/**
 * Function to refresh access token
 * @param integrationType
 * @param refreshToken
 */
async function refreshAccessToken(integrationType: TIntegrations, refreshToken?: string | null) {
	try {
		switch (integrationType) {
			case 'GOOGLE':
				(store.dispatch as CustomDispatch)(
					renewGoogleAccessToken({ googleToken: refreshToken })
				);
				break;
			case 'MIRO':
				(store.dispatch as CustomDispatch)(
					renewMiroAccessToken({ miroToken: refreshToken })
				);
				break;
			default:
				console.info(`${EIntegrationType[integrationType]} integration not expired`);
		}
	} catch (error: any) {
		console.error('Error refreshing access token:', error.message);
	}
}

/**
 * Function to check token expiration and refresh if necessary
 * @param integrationDetails
 * @param integrationType
 * @param refreshToken
 */
export default async function checkTokenExpiration(
	integrationDetails: TIntegrationDetails,
	integrationType: TIntegrations,
	refreshToken?: string | null
) {
	const { expires_in, createdAt } = integrationDetails;
	const now = Date.now();
	// Check if the token is expired or will expire in the next five minute
	if (createdAt + expires_in * 1000 < now + 300000) {
		// Token is expired or about to expire, refresh it
		await refreshAccessToken(integrationType, refreshToken);
	}
}
