import { useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';
import {
	EJobType,
	EAIGenerationType,
	ITTIGenerationJob,
	IBlock,
	IImage,
	IGroup,
	IUser,
	IText,
	ICanvas,
	ILink,
	IPdf,
	IVideo,
	IFile,
	I3D,
	IProject
} from '@naya_studio/types';
import {
	TAIBlocksData,
	TAIGroupsData,
	TAddMultipleBlockArgs,
	TAddStageThunkArg,
	TBatchCreateGroupAndBlocks,
	TDeleteGroupThunkArg,
	TEditBlockArgs,
	TEditProjectThunkArg
} from 'src/types/argTypes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
	publishJob,
	getJob,
	generatePMJourney,
	createJob,
	generate3DPreview,
	get3DTaskStatus,
	generate3DRefined,
	generateAiText,
	handleAIRename,
	getAISuggestion,
	handleAIOrganize
} from 'src/redux/reduxActions/aiGeneration';
import { addMultipleBlocks, bulkUpdateBlocks, editBlock } from 'src/redux/reduxActions/block';
import {
	generateIdsFromUrl,
	generateOptimisticBlock,
	generateOptimisticStage,
	getStageAndBlockIdsOfProject,
	getStagesAndBlocksOfProject
} from 'src/redux/reduxActions/util';
import { useDispatch } from 'react-redux';
import {
	scrollToView,
	generateAIPromptKeywords,
	getStructureOfPreviousPhasesBlocks,
	PM_AI_RESULTS,
	TPrompt
} from 'src/util/block/util';
import mongoose from 'mongoose';
import { Progress, getSiteMetaData, SiteDetailsT } from '@naya_studio/radix-ui';
import { AppDispatch, store } from 'src';
import axios from 'axios';
import { addNote } from 'src/redux/reduxActions/notes';
import {
	addStage,
	batchCreateGroupAndBlocks,
	bulkUpdateGroups,
	deleteStage
} from 'src/redux/reduxActions/stage';
import useUser from 'src/redux/hooks/user';
import { CustomDispatch } from 'src/redux/actions/types';
import { TGDriveFile, ISnackBar, TDriveData } from 'src/redux/reducers/root.types';
import { plainTextToLexicalState } from '@naya_studio/viewers';
import {
	findBlockWithBlockId,
	findStageWithStageId,
	getStageAndChildren
} from 'src/redux/actions/util';
import { editProject } from 'src/redux/reduxActions/project';
import { onDeleteBlock } from 'src/components/journeyContainer/JourneyContainerCallbacks';
import {
	PathParamsType,
	TLDFlags
} from '../../components/collaborationTool/CollaborationTool.types';
import { ReactComponent as CancelSnackbar } from '../../assets/cancel-snackbar.svg';
import trackEvent from '../analytics/analytics';
import { BlockEvents } from '../analytics/events';
import getUserFromRedux from '../helper/user';
import {
	checkElementsInSamePositions,
	create3DBlock,
	createAIPoweredGroupsData,
	editThreeDBlockPayload
} from './utilAI';
import uploadToGDrive from '../sync/uploads';
import { getProjectFromRedux } from '../helper/project';
import { getGroupById } from '../helper/stage';
import getBlockFromReduxById from '../helper/block';
import { TGenAIEventData } from '../analytics/analytic.types';
import useBlockActions from './useBlockActions';

// params for onGenerateWithAI fn
export type TOnGenerateWithAIParams = {
	type: keyof typeof EJobType;
	aiType: keyof typeof EAIGenerationType;
	prompt: string;
	projectId?: string;
	phaseName?: string;
	phaseId?: string;
	blockId?: string;
	newBlockIndex?: number;
	newPhaseIndex?: number;
	phaseIndex?: number;
	triggerFromPMJOurney?: boolean;
};

// type for generating blocks with ai results
export type TGenerateWithAIOptions = {
	job: ITTIGenerationJob;
	options: {
		type: keyof typeof EJobType;
		prompt: string;
		aiType: keyof typeof EAIGenerationType;
		projectId?: string;
		phaseName?: string;
		phaseId?: string;
		blockId?: string;
		newBlockIndex?: number;
		newPhaseIndex?: number;
		triggerFromPMJOurney?: boolean;
	};
};

// type for block update
type TBlockUpdate = {
	blockId: string;
	updates: Partial<IBlock | ICanvas | ILink | IImage | IPdf | IVideo | IFile | I3D>;
};

// type for group update
type TGroupUpdate = {
	groupId: string;
	updates: Partial<IGroup>;
};

// type for new group from ai
type TNewGroup = {
	group: TAIGroupsData;
	groupIndex: number;
};

export enum ESnackbarsAI {
	JOURNEY_OUTLINE = 'JOURNEY_OUTLINE',
	PM_JOURNEY_COMPLETE = 'PM_JOURNEY_COMPLETE',
	TEXT_TO_TEXT_PROGRESS = 'TEXT_TO_TEXT_PROGRESS',
	TEXT_TO_TEXT_COMPLETE = 'TEXT_TO_TEXT_COMPLETE',
	DES_AI_PROG = 'DES_AI_PROG',
	THREE_D_PROG = 'THREE_D_PROG',
	THREE_D_COMP = 'THREE_D_COMP',
	TEXT_TO_TEXT_ERROR = 'TEXT_TO_TEXT_ERROR',
	AI_RENAME_PROGRESS = 'AI_RENAME_PROGRESS',
	AI_RENAME_COMPLETE = 'AI_RENAME_COMPLETE',
	AI_RENAME_ERROR = 'AI_RENAME_ERROR',
	AI_ORGANIZE_PROGRESS = 'AI_ORGANIZE_PROGRESS',
	AI_ORGANIZE_COMPLETE = 'AI_ORGANIZE_COMPLETE',
	AI_ORGANIZE_ERROR = 'AI_ORGANIZE_ERROR'
}

export const AiIcon =
	'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
	'ai-animation.gif?alt=media&token=a5d24f5f-c141-4ae8-8fde-45cc826088b0';

/**
 * Used to keep track of AI Generated blocks and requests
 * @returns
 */
const useAiGeneration = () => {
	const dispatch = useDispatch<AppDispatch>();
	const history = useHistory();
	const params = useParams<PathParamsType>();
	const aiBlocksCreated = useRef<string[]>([]);
	const reqCancelAI = useRef(axios.CancelToken.source());
	const isAIProcessInProgress = useRef(false);
	const showAIMessage = useRef(false);
	const { user } = useUser();
	const PMAIProgress = useRef(0); // Keeps track of PM AI task progress only
	const TextAIProgress = useRef(0); // Keeps track of PM AI task progress only
	const AIRenameProgress = useRef(0);
	const AIOrganizeProgress = useRef(0);
	const numOfImageGenJobs = useRef(0); // Number of Image Gen Tasks created by prompt from PM-AI
	const totalProgressOfImageGenJobs = useRef(0); // Combined progress of image gen in PM-AI
	const pmJourneyPhases = useRef<string[]>([]); // Ids of Phases added by PM-AI results
	const is3DModelCreationInProgress = useRef<boolean>(false);
	const isOutputRegeneratedRef = useRef(false);
	const outputRegeneratedDataRef = useRef<string[]>([]);
	let onGenerateWithAIParams = {};
	const { undoDeleteBlocks } = useBlockActions();

	const { createInNaya, isSyncStorageEnabled, isSyncDriveEnabled } = useFlags<TLDFlags>();

	// store block and group original states for undo purposes
	const aiRenameUpdates = useRef<{ blocks: IBlock[]; groups: IGroup[] }>({
		blocks: [],
		groups: []
	});

	// store block, group and project original states for undo purposes in ai organize
	const aiOrganizeUpdates = useRef<{
		blocks: IBlock[];
		groups: IGroup[];
		projectChildren: string[];
	}>({
		blocks: [],
		groups: [],
		projectChildren: []
	});
	const addBlocksToGDrive = (uploadToDrive: TDriveData) => {
		if (!createInNaya || !isSyncStorageEnabled || !isSyncDriveEnabled) return;
		uploadToGDrive(params.projectId, uploadToDrive);
	};

	/**
	 * Function to update bulk data for blocks and groups
	 * @param { TBlockUpdate[] } blockUpdates - Blocks data for update
	 * @param { TGroupUpdate[] } groupUpdates - Groups data for update
	 * @param { IBlock[] } prevStateBlocks - Previous state of blocks
	 * @param { IGroup[] } prevStateGroups - Previous state of groups
	 */
	const updateBlocksAndGroup = async (
		blockUpdates: TBlockUpdate[],
		groupUpdates: TGroupUpdate[],
		prevStateBlocks: IBlock[],
		prevStateGroups: IGroup[]
	) => {
		// update blocks
		if (blockUpdates.length > 0)
			await dispatch(
				bulkUpdateBlocks({
					data: { blocks: blockUpdates },
					prevState: { blocks: prevStateBlocks }
				})
			);
		// update groups
		if (groupUpdates.length > 0)
			await dispatch(
				bulkUpdateGroups({
					data: { groups: groupUpdates },
					prevState: { groups: prevStateGroups }
				})
			);
	};

	/**
	 * Function to undo AI Renames
	 */
	const handleUndoAIRename = () => {
		const updatedBlocks = [];
		const updatedGroups = [];
		const prevStateBlocks = [];
		const prevStateGroups = [];

		// extracting original blocks and groups before original update happened
		const { blocks, groups } = aiRenameUpdates.current;

		// create payload for block bulk updates
		for (let i = 0; i < blocks.length; i++) {
			const blockId = blocks[i]?._id as string;
			const prevBlock = findBlockWithBlockId(blockId) as IBlock;
			updatedBlocks.push({ blockId, updates: { name: blocks[i]?.name } });
			prevStateBlocks.push(prevBlock);
		}

		// create payload for group bulk updates
		for (let i = 0; i < groups.length; i++) {
			const groupId = groups[i]?._id as string;
			const prevGroup = findStageWithStageId(groupId) as IGroup;
			updatedGroups.push({ groupId, updates: { name: groups[i]?.name } });
			prevStateGroups.push(prevGroup);
		}

		// update blocks and groups
		updateBlocksAndGroup(updatedBlocks, updatedGroups, prevStateBlocks, prevStateGroups);
	};

	/**
	 * Handle Regeneration of Concepts
	 * @param blockType
	 */
	const handleRegenerate = async (blockType: string) => {
		try {
			isOutputRegeneratedRef.current = true;
			// nested dependency on the functions
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			onGenerateWithAI(onGenerateWithAIParams as TOnGenerateWithAIParams, true);
			// Track generate concepts event
			const eventProps: TGenAIEventData = {
				blockType: (blockType as string).toLocaleUpperCase(),
				isRegenerated: true
			};
			trackEvent(BlockEvents.GENERATIVE_AI, eventProps);
		} catch (error) {
			console.error('handleRegenerate error', error);
		}
	};

	/**
	 * Handle replace older
	 * @param groupId
	 * @param type
	 * @param isUndoRedo
	 */
	const handleReplaceOlder = (groupId: string, type: string, isUndoRedo: boolean = false) => {
		const blocks = store.getState().blocks.data;
		const groups = store.getState().stages.data;

		const groupToUndo = groups[groupId];
		const projects = store.getState().projects.data;
		const project = projects[params.projectId];

		const blocksToUndo = [] as IBlock[];
		if (groupToUndo) {
			groupToUndo.children.forEach((b) => {
				blocksToUndo.push(blocks[b as string] as IBlock);
			});
		}
		// Remove the older blocks
		onDeleteBlock(outputRegeneratedDataRef.current, [groupId]);

		addSnackbar({
			show: true,
			type: 'NORMAL',
			actionButtonData: [
				{
					buttonData: 'Undo',
					onClick: () => {
						removeSnackbar(0);
						undoDeleteBlocks(
							blocksToUndo,
							[groupToUndo as IGroup],
							undefined,
							project?.children as string[]
						);
					},
					className: 'snackbar-gothere',
					show: true
				}
			],
			text: (
				<p
					style={{
						display: 'flex',
						alignItems: 'center',
						textAlign: 'start',
						margin: 0
					}}
				>
					Replaced older {type}
				</p>
			)
		});
		removeSnackbar(5000);

		// Save history when user removes older block
		if (!isUndoRedo) {
			window.Naya.handleUndoRedo({
				type: 'ADD',
				payload: {
					originalAction: onDeleteBlock,
					oppositeAction: undoDeleteBlocks,
					originalActionPayload: [outputRegeneratedDataRef.current, [groupId]],
					oppositeActionPayload: [
						blocksToUndo,
						[groupToUndo],
						undefined,
						project?.children
					]
				}
			});
		}
		isOutputRegeneratedRef.current = false;
		outputRegeneratedDataRef.current = [];
	};

	/**
	 * Function to undo AI Organize
	 */
	const handleUndoAIOrganise = async (
		blocks: IBlock[],
		groups: IGroup[],
		projectChildren: string[]
	) => {
		const updatedBlocks = [];
		const updatedGroups = [];
		const prevStateBlocks = [];
		const prevStateGroups = [];

		// create payload for block bulk updates
		for (let i = 0; i < blocks.length; i++) {
			const block = blocks[i] as IBlock;
			const blockId = block?._id as string;
			const prevBlock = findBlockWithBlockId(blockId) as IBlock;
			updatedBlocks.push({ blockId, updates: { parentId: block.parentId } });
			prevStateBlocks.push(prevBlock);
		}

		// create payload for group bulk updates
		for (let i = 0; i < groups.length; i++) {
			const group = groups[i] as IGroup;
			const groupId = group?._id as string;
			const prevGroup = findStageWithStageId(groupId) as IGroup;
			updatedGroups.push({ groupId, updates: { children: group.children } });
			prevStateGroups.push(prevGroup);
		}

		// update blocks
		dispatch(
			bulkUpdateBlocks({
				data: { blocks: updatedBlocks },
				prevState: { blocks: prevStateBlocks }
			})
		);
		// update groups
		dispatch(
			bulkUpdateGroups({
				data: { groups: updatedGroups },
				prevState: { groups: prevStateGroups }
			})
		);

		// Update project children
		const reduxProject = getProjectFromRedux(params.projectId);
		if (
			projectChildren.length > 0 &&
			!checkElementsInSamePositions(
				projectChildren,
				(reduxProject.children as string[]) || []
			)
		) {
			const apiPayload: TEditProjectThunkArg = {
				payload: {
					_id: params.projectId,
					update: {
						children: projectChildren
					}
				},
				prevState: { prevProject: reduxProject as IProject }
			};
			await dispatch(editProject(apiPayload));
		}
	};

	/**
	 * This functions handle snackbars of Des-AI, PM-AI
	 * @param option - type of the snackbar
	 * @param data - optional data required to render snackbar
	 */
	const handleAISnackBars = (option: ESnackbarsAI, data?: any) => {
		switch (option) {
			case ESnackbarsAI.THREE_D_COMP: {
				addSnackbar({
					show: true,
					type: 'NORMAL',
					actionButtonData: [
						{
							buttonData: 'Go there',
							onClick: () => {
								removeSnackbar(0);
								setTimeout(() => {
									history.push(
										`/project/${data.projectId}/${data.phaseId}/${data.blockId}`
									);
									removeSnackbar(0);
								}, 500);
							},
							className: 'snackbar-gothere',
							// show Go There only when not at 3D block in expanded mode
							show:
								window.location.pathname.split('/').length < 5 ||
								(window.location.pathname.split('/').length === 5 &&
									window.location.pathname.split('/')[4] !== data?.blockId)
						},
						{
							buttonData: !isOutputRegeneratedRef.current
								? 'Regenerate'
								: 'Replace older',
							onClick: () => {
								if (!isOutputRegeneratedRef.current) {
									handleRegenerate('3D');
								} else {
									handleReplaceOlder(data.phaseId, '3D model.');
								}
							},

							className: 'snackbar-gothere',
							show: true
						}
					],
					text: (
						<p
							style={{
								display: 'flex',
								alignItems: 'center',
								textAlign: 'start',
								margin: 0
							}}
						>
							{isOutputRegeneratedRef.current ? `Regenerated` : 'Generated'} 3D{' '}
							{data.isPreview ? 'Preview' : 'Model'}
						</p>
					)
				});
				break;
			}
			case ESnackbarsAI.THREE_D_PROG: {
				let message;
				if (isOutputRegeneratedRef.current) {
					message = data.isPreview ? 'Regenerating 3D Preview' : 'Refining 3D Model';
				} else {
					message = data.isPreview ? 'Generating 3D Preview' : 'Refining 3D Model';
				}
				addSnackbar({
					show: true,
					type: 'NORMAL',
					actionButtonData: [],
					text: (
						<p
							style={{
								display: 'flex',
								alignItems: 'center',
								textAlign: 'start',
								margin: 0
							}}
						>
							{message}
							&nbsp;
							<Progress
								variant="PERCENT_LOADER"
								style={{
									width: '176px',
									borderRadius: '16px',
									marginBottom: '1px',
									marginLeft: '5px'
								}}
								percent={data.progress}
							/>
							&nbsp;
							<img src={AiIcon} alt="ai-icon" height={32} width={32} />
						</p>
					)
				});
				break;
			}
			case ESnackbarsAI.JOURNEY_OUTLINE: {
				addSnackbar({
					show: true,
					type: 'NORMAL',
					actionButtonData: [
						{
							buttonData: <CancelSnackbar />,
							onClick: () => {
								removeSnackbar(0);
								// eslint-disable-next-line @typescript-eslint/no-use-before-define
								abortController();
							},
							className: 'snackbar-cross',
							show: true
						}
					],
					text: (
						<p
							style={{
								display: 'flex',
								alignItems: 'center',
								textAlign: 'start',
								margin: 0
							}}
						>
							Generating journey outline &nbsp;
							<Progress
								variant="PERCENT_LOADER"
								style={{
									width: '176px',
									borderRadius: '16px',
									marginBottom: '1px',
									marginLeft: '5px'
								}}
								percent={PMAIProgress.current}
							/>
							&nbsp;
							<img src={AiIcon} alt="ai-icon" height={32} width={32} />
						</p>
					)
				});
				break;
			}
			case ESnackbarsAI.PM_JOURNEY_COMPLETE: {
				const htmlElement = document.querySelectorAll(
					`[data-rbd-drag-handle-draggable-id="${pmJourneyPhases.current[2]}"]`
				)[0] as HTMLElement;
				const isJourneyVisible =
					htmlElement?.getBoundingClientRect().x < 0
						? false
						: htmlElement?.getBoundingClientRect().x < window.innerWidth || false;

				addSnackbar({
					show: true,
					type: 'NORMAL',
					text: <div className="sentence-case">Journey generated.</div>,
					actionButtonData: [
						{
							buttonData: 'Go there',
							onClick: () => {
								removeSnackbar(0);
								setTimeout(() => {
									if (window.location.pathname.split('/').length === 5) {
										history.push(`/project/${params.projectId}`);
									}
									removeSnackbar(0);
									setTimeout(() => {
										if (!isJourneyVisible) {
											scrollToView(
												`[data-rbd-drag-handle-draggable-id="${pmJourneyPhases.current[2]}"]` as string
											);
										}
									}, 500);
								}, 500);
							},
							className: 'snackbar-gothere',
							show:
								!isJourneyVisible ||
								window.location.pathname.split('/').length === 5
						},
						{
							buttonData: 'Undo',
							onClick: () => {
								removeSnackbar(0);
								setTimeout(() => {
									for (let i = 0; i < pmJourneyPhases.current.length; i++) {
										const groupId = pmJourneyPhases.current[i] as string;
										const { stages } = store.getState();
										const group = stages.data[groupId] as IGroup;

										const paylaod: TDeleteGroupThunkArg = {
											payload: {
												projectId: data?.projectId as string,
												stageId: groupId,
												childrens: group.children as string[]
											}
										};
										dispatch(deleteStage(paylaod));
									}
								}, 500);
							},
							className: 'snackbar-gothere',
							show: true
						},
						{
							buttonData: <CancelSnackbar />,
							onClick: () => {
								removeSnackbar(0);
								// eslint-disable-next-line @typescript-eslint/no-use-before-define
								abortController();
							},
							className: 'snackbar-cross',
							show: true
						}
					]
				});
				break;
			}
			case ESnackbarsAI.TEXT_TO_TEXT_PROGRESS: {
				addSnackbar({
					show: true,
					type: 'NORMAL',
					actionButtonData: [
						{
							buttonData: <CancelSnackbar />,
							onClick: () => {
								removeSnackbar(0);
								isOutputRegeneratedRef.current = false;
								// eslint-disable-next-line @typescript-eslint/no-use-before-define
								abortController();
							},
							className: 'snackbar-cross',
							show: true
						}
					],
					text: (
						<p
							style={{
								display: 'flex',
								alignItems: 'center',
								textAlign: 'start',
								margin: 0
							}}
						>
							{isOutputRegeneratedRef.current
								? 'Regenerating Text'
								: 'Generating Text'}
							&nbsp;
							<Progress
								variant="PERCENT_LOADER"
								style={{
									width: '176px',
									borderRadius: '16px',
									marginBottom: '1px',
									marginLeft: '5px'
								}}
								percent={TextAIProgress.current}
							/>
							&nbsp;
							<img src={AiIcon} alt="ai-icon" height={32} width={32} />
						</p>
					)
				});
				break;
			}
			case ESnackbarsAI.TEXT_TO_TEXT_COMPLETE: {
				const textGenCompleteData: ISnackBar = {
					show: true,
					type: 'NORMAL',
					text: (
						<div className="sentence-case">
							Text {isOutputRegeneratedRef.current ? `regenerated` : 'generated'}.
						</div>
					)
				};
				textGenCompleteData.actionButtonData = [];

				if (window.location.pathname.split('/').length === 5) {
					textGenCompleteData.actionButtonData.push({
						buttonData: 'Go there',
						onClick: () => {
							removeSnackbar(0);
							setTimeout(() => {
								if (window.location.pathname.split('/').length === 5) {
									history.push(`/project/${params.projectId}`);
								}
								removeSnackbar(0);
							}, 500);
						},
						className: 'snackbar-gothere',
						show: true
					});
				} else {
					textGenCompleteData.actionButtonData.push({
						buttonData: !isOutputRegeneratedRef.current
							? 'Regenerate'
							: 'Replace older',
						onClick: () => {
							if (!isOutputRegeneratedRef.current) {
								handleRegenerate('text');
							} else {
								handleReplaceOlder(data.phaseId, 'text');
							}
						},
						className: 'snackbar-gothere',
						show: true
					});
				}

				addSnackbar(textGenCompleteData);
				break;
			}
			case ESnackbarsAI.TEXT_TO_TEXT_ERROR: {
				addSnackbar({
					show: true,
					type: 'ERROR',
					text: (
						<div className="sentence-case">
							Please provide more information to generate text.
						</div>
					)
				});
				break;
			}
			case ESnackbarsAI.DES_AI_PROG: {
				let message;
				if (numOfImageGenJobs.current) {
					message = 'Generating journey images';
				} else if (isOutputRegeneratedRef.current) {
					message = `Regenerating ${data.snackbarMsg}`;
				} else {
					message = `Generating ${data.snackbarMsg}`;
				}
				addSnackbar({
					show: true,
					type: 'NORMAL',
					actionButtonData: [
						{
							buttonData: 'Go there',
							onClick: () => {
								removeSnackbar(0);
								setTimeout(() => {
									history.push(`/project/${data.projectId || params.projectId}`);
								}, 500);
							},
							className: 'snackbar-gothere',
							show: window.location.pathname.split('/').length === 5
						},
						{
							buttonData: <CancelSnackbar />,
							onClick: () => {
								// eslint-disable-next-line @typescript-eslint/no-use-before-define
								abortController(data?.triggerFromPMJOurney, data?.projectId);
								if (!data?.triggerFromPMJOurney) removeSnackbar(0);
							},
							className: 'snackbar-cross',
							show: true
						}
					],
					text: (
						<p
							style={{
								display: 'flex',
								alignItems: 'center',
								textAlign: 'start',
								margin: 0
							}}
						>
							{message}
							<Progress
								variant="PERCENT_LOADER"
								style={{
									width: '176px',
									borderRadius: '16px',
									marginBottom: '1px',
									marginLeft: '5px'
								}}
								percent={
									numOfImageGenJobs.current
										? totalProgressOfImageGenJobs.current
										: data.progress
								}
							/>
							&nbsp;
							<img src={AiIcon} alt="ai-icon" height={32} width={32} />
						</p>
					)
				});
				break;
			}
			case ESnackbarsAI.AI_RENAME_PROGRESS: {
				addSnackbar({
					show: true,
					type: 'NORMAL',
					actionButtonData: [
						{
							buttonData: <CancelSnackbar />,
							className: 'snackbar-cross',
							show: true,
							onClick: () => {
								removeSnackbar(0);
								// eslint-disable-next-line @typescript-eslint/no-use-before-define
								abortController();
							}
						}
					],
					text: (
						<p
							style={{
								display: 'flex',
								alignItems: 'center',
								textAlign: 'start',
								margin: 0
							}}
						>
							{data.snackBarMsg}
							<Progress
								variant="PERCENT_LOADER"
								style={{
									width: '176px',
									borderRadius: '16px',
									marginBottom: '1px',
									marginLeft: '5px'
								}}
								percent={AIRenameProgress.current}
							/>
							&nbsp;
							<img src={AiIcon} alt="ai-icon" height={32} width={32} />
						</p>
					)
				});
				break;
			}
			case ESnackbarsAI.AI_RENAME_COMPLETE: {
				const textGenCompleteData: ISnackBar = {
					show: true,
					type: 'NORMAL',
					text: <div className="sentence-case">{data.snackBarMsg}</div>,
					actionButtonData: [
						{
							buttonData: 'Undo',
							onClick: () => {
								removeSnackbar(0);
								handleUndoAIRename();
							},
							className: 'snackbar-gothere',
							show: true
						}
					]
				};
				addSnackbar(textGenCompleteData);
				break;
			}
			case ESnackbarsAI.AI_RENAME_ERROR: {
				addSnackbar({
					show: true,
					type: 'ERROR',
					text: <div className="sentence-case">Failed to AI rename.</div>
				});
				break;
			}
			case ESnackbarsAI.AI_ORGANIZE_PROGRESS: {
				addSnackbar({
					show: true,
					type: 'NORMAL',
					actionButtonData: [
						{
							buttonData: <CancelSnackbar />,
							className: 'snackbar-cross',
							show: true,
							onClick: () => {
								removeSnackbar(0);
								// eslint-disable-next-line @typescript-eslint/no-use-before-define
								abortController();
							}
						}
					],
					text: (
						<p
							style={{
								display: 'flex',
								alignItems: 'center',
								textAlign: 'start',
								margin: 0
							}}
						>
							{data.snackBarMsg}
							<Progress
								variant="PERCENT_LOADER"
								style={{
									width: '144px',
									borderRadius: '16px',
									marginBottom: '1px',
									marginLeft: '5px'
								}}
								percent={AIOrganizeProgress.current}
							/>
							&nbsp;
							<img src={AiIcon} alt="ai-icon" height={32} width={32} />
						</p>
					)
				});
				break;
			}
			case ESnackbarsAI.AI_ORGANIZE_COMPLETE: {
				const { blocks, groups, projectChildren } = aiOrganizeUpdates.current;
				const aiOrganizeSnackbar: ISnackBar = {
					show: true,
					type: 'NORMAL',
					text: <div className="sentence-case">{data.snackBarMsg}</div>,
					actionButtonData: [
						{
							buttonData: 'Undo',
							onClick: () => {
								removeSnackbar(0);
								handleUndoAIOrganise(blocks, groups, projectChildren);
							},
							className: 'snackbar-gothere',
							show: true
						}
					]
				};

				// scroll to view group/block
				if (data.isSingleGroup || data.isSingleBlock)
					aiOrganizeSnackbar.actionButtonData?.push({
						buttonData: 'Go there',
						onClick: () => {
							scrollToView(
								data.isSingleGroup
									? (`[data-phaseid="${data.groupIds[0]}"]` as string)
									: (`[data-blockid="${data.blocksIds[0]}"]` as string)
							);
						},
						className: 'snackbar-gothere',
						show: true
					});

				aiOrganizeSnackbar.actionButtonData?.push({
					buttonData: <CancelSnackbar />,
					className: 'snackbar-cross',
					show: true,
					onClick: () => {
						removeSnackbar(0);
						// eslint-disable-next-line @typescript-eslint/no-use-before-define
						abortController();
					}
				});

				addSnackbar(aiOrganizeSnackbar);
				break;
			}
			case ESnackbarsAI.AI_ORGANIZE_ERROR: {
				addSnackbar({
					show: true,
					type: 'ERROR',
					text: <div className="sentence-case">Failed to AI organize.</div>
				});
				break;
			}
			default: {
				console.info('No Case for handling snackbar');
			}
		}
	};
	/**
	 * Function to abort the process
	 */
	const abortController = (triggerFromPMJOurney?: boolean, projectId?: string) => {
		reqCancelAI.current.cancel();
		isAIProcessInProgress.current = false;
		if (triggerFromPMJOurney) {
			// If img gen task is aborted while in PM-AI task, then show PM Journey complete snackbar
			handleAISnackBars(ESnackbarsAI.PM_JOURNEY_COMPLETE, { projectId });
			removeSnackbar(5000);
		}
		// Resetting img gen related refs
		numOfImageGenJobs.current = 0;
		totalProgressOfImageGenJobs.current = 0;
	};

	// function to create blocks for ai links and show ai progress
	const handleGenerateWithAI = async ({ job, options }: TGenerateWithAIOptions) => {
		let snackbarMsg = '';
		switch (options.aiType) {
			case 'IMAGE':
				snackbarMsg = 'images';
				break;
			case 'MOOD_BOARD':
				snackbarMsg = 'mood boards';
				break;
			case 'SKETCH':
				snackbarMsg = 'sketches';
				break;
			case 'THREE_D':
				snackbarMsg = '3D files';
				break;
			case 'RENDER':
				snackbarMsg = 'renders';
				break;
			default:
				snackbarMsg = '';
		}
		if (options.triggerFromPMJOurney) {
			// when creating imgs for PM-AI
			snackbarMsg = 'Journey';
		}
		if (job.links && job.links.length) {
			const allBlocks = [] as IBlock[];
			const ids = [] as string[];
			const userData: IUser = getUserFromRedux();
			const gDriveFiles: TGDriveFile[] = [];

			for (let i = 0; i < job.links.length; i++) {
				if (!aiBlocksCreated.current.includes(job.links[i] as string)) {
					const url = job.links[i];

					let fileName;
					const fileNameRegex = /\/([^/]+)$/;

					if (!url) return;
					const match = url.match(fileNameRegex);

					if (match) {
						// eslint-disable-next-line prefer-destructuring
						fileName = match[1];
					} else {
						// eslint-disable-next-line no-await-in-loop
						const { title } = (await getSiteMetaData(
							job.links[i] as string
						)) as SiteDetailsT;
						fileName = title;
					}

					if (!fileName) return;

					aiBlocksCreated.current.push(job.links[i] as string);
					const blockName = `Concept ${aiBlocksCreated.current.length}`;

					const newId = new mongoose.Types.ObjectId().toString() as string;
					if (!isOutputRegeneratedRef.current)
						outputRegeneratedDataRef.current.push(newId);
					ids.push(newId);
					const imageBlock = generateOptimisticBlock(
						newId,
						options.phaseId as string,
						params.projectId ?? options.projectId,
						blockName || 'UNDEFINED',
						'IMAGE'
					);
					(imageBlock as IImage).link = job.links[i] as string;
					(imageBlock as IImage).originalLink = job.links[i] as string;
					imageBlock.thumbnail = {
						src: job.links[i] as string,
						originalSrc: job.links[i] as string,
						isCustom: false
					};
					(imageBlock as IImage).uploadedBy = userData._id as string;
					(imageBlock as IImage).fileName = fileName as string;
					allBlocks.push(imageBlock);
					gDriveFiles.push({
						id: newId,
						file: job.links[i] as string,
						fileName: blockName || 'UNDEFINED'
					});
				}
			}
			if (allBlocks.length) {
				// payload for add multiple blocks action
				const apiPayload: TAddMultipleBlockArgs = {
					data: {
						blocks: allBlocks,
						stageId: options.phaseId as string,
						projectId: params.projectId ?? options.projectId,
						newBlockIndex:
							(options.newBlockIndex || 0) +
							(aiBlocksCreated.current.length - allBlocks.length)
					}
				};
				await dispatch(addMultipleBlocks(apiPayload));
				scrollToView(`[data-itemId="${apiPayload.data.blocks[0]?._id}"]` as string);
				for (let i = 0; i < ids.length; i++)
					dispatch(
						addNote({
							payload: {
								_id: new mongoose.Types.ObjectId().toString() as string,
								text: `<strong>AI generated from :</strong><br/>${options.prompt}`,
								color: '#C6C0C4',
								createdBy: userData._id as string,
								lastUpdatedBy: user._id as string,
								createdAt: new Date(),
								updatedAt: new Date(),
								projectId: params.projectId ?? options.projectId,
								parentId: ids[i] as string,
								parentType: 'BLOCK'
							}
						})
					);
				addBlocksToGDrive({
					data: [
						{
							name: 'Untitled',
							id: options.phaseId as string,
							children: gDriveFiles
						}
					],
					uploadCount: gDriveFiles.length
				});
			}

			// Track generating ai content event
			const eventProps: TGenAIEventData = {
				blockType: options.aiType,
				prompt: options.prompt
			};
			trackEvent(BlockEvents.GENERATIVE_AI, eventProps);
		}
		if (!showAIMessage.current) {
			// Periodically updates snackbar progress
			totalProgressOfImageGenJobs.current =
				totalProgressOfImageGenJobs.current +
					(job.progress || 0) / numOfImageGenJobs.current <=
				100
					? totalProgressOfImageGenJobs.current +
					  (job.progress || 0) / numOfImageGenJobs.current
					: 100;
			handleAISnackBars(ESnackbarsAI.DES_AI_PROG, {
				progress: job.progress, // progress of single job (Not of PM_AI image gen)
				projectId: options.projectId,
				snackbarMsg,
				triggerFromPMJOurney: options.triggerFromPMJOurney
			});
		}

		if (job.status === 'COMPLETED') {
			if (numOfImageGenJobs.current > 0) {
				// reduce the number of img gen of PM-AI tasks
				numOfImageGenJobs.current -= 1;
			}
			const htmlElement = document.querySelectorAll(
				`[data-rbd-drag-handle-draggable-id="${pmJourneyPhases.current[2]}"]`
			)[0] as HTMLElement;
			const isJourneyVisible =
				htmlElement?.getBoundingClientRect().x < window.innerWidth || false;
			// Add snackbar for completion of any type of AI task
			if (
				(options.triggerFromPMJOurney && numOfImageGenJobs.current === 0) ||
				!options.triggerFromPMJOurney
			) {
				addSnackbar({
					show: true,
					type: 'NORMAL',
					text: <div className="sentence-case">{snackbarMsg} generated.</div>,
					actionButtonData: [
						{
							buttonData: 'Go there',
							onClick: () => {
								if (window.location.pathname.split('/').length === 5) {
									history.push(
										`/project/${params.projectId ?? options.projectId}`
									);
								}
								removeSnackbar(0);
								setTimeout(() => {
									if (!isJourneyVisible && options?.triggerFromPMJOurney) {
										scrollToView(
											`[data-rbd-drag-handle-draggable-id="${pmJourneyPhases.current[2]}"]` as string
										);
									}
								}, 500);
							},
							className: 'snackbar-gothere',
							show:
								window.location.pathname.split('/').length === 5 ||
								((options?.triggerFromPMJOurney as boolean) && !isJourneyVisible)
						},
						{
							buttonData: !isOutputRegeneratedRef.current
								? 'Regenerate'
								: 'Replace older',
							onClick: () => {
								if (!isOutputRegeneratedRef.current) {
									handleRegenerate(snackbarMsg);
								} else {
									handleReplaceOlder(options.phaseId as string, snackbarMsg);
								}
							},

							className: 'snackbar-gothere',
							show: true
						},
						{
							buttonData: 'Undo',
							onClick: () => {
								removeSnackbar(0);
								setTimeout(() => {
									for (let i = 0; i < pmJourneyPhases.current.length; i++) {
										const groupId = pmJourneyPhases.current[i] as string;
										const { stages } = store.getState();
										const group = stages.data[groupId] as IGroup;

										const paylaod: TDeleteGroupThunkArg = {
											payload: {
												projectId: options.projectId as string,
												stageId: groupId,
												childrens: group.children as string[]
											}
										};
										dispatch(deleteStage(paylaod));
									}
								}, 500);
							},
							className: 'snackbar-gothere',
							show: options?.triggerFromPMJOurney as boolean
						},
						{
							buttonData: <CancelSnackbar />,
							onClick: () => {
								removeSnackbar(0);
								abortController();
							},
							className: 'snackbar-cross',
							show: true
						}
					]
				});
				if (options?.triggerFromPMJOurney) {
					// remove the snackbar after 10 sec
					removeSnackbar(10000);
				}

				// At the 11th sec, clean up PM-AI refs as these fields are need for snackbar
				setTimeout(() => {
					pmJourneyPhases.current = [];

					// isAIProcessInProgress.current = false;
				}, 11000);

				// after removing snackbar, cleanup of PM-ai img gen refs
				if (numOfImageGenJobs.current === 0) {
					totalProgressOfImageGenJobs.current = 0;
					numOfImageGenJobs.current = 0;
				}
				isAIProcessInProgress.current = false;
			}
		} else if (job.status === 'FAILED') {
			abortController();
			addSnackbar({ show: true, type: 'ERROR', text: 'Failed to generate renders' });
			removeSnackbar(5000);
		} else if (isAIProcessInProgress.current) {
			setTimeout(() => {
				dispatch(
					getJob({
						id: job._id as string,
						handleGenerateWithAI,
						reqCancelAI,
						options
					})
				);
			}, 3000);
		}
	};

	// handle creation of 3D Model (Preview + Refined)
	const handle3DGeneration = async (options: TOnGenerateWithAIParams) => {
		if (!is3DModelCreationInProgress.current) {
			// allow creation of only one 3D model at a time
			is3DModelCreationInProgress.current = true;
			let previewGenerated = false;
			let refinedGenerated = false;

			// send api to create preview 3D model
			handleAISnackBars(ESnackbarsAI.THREE_D_PROG, { isPreview: true, progress: 0 });

			// start generation of 3D preview and gets its jobId
			const jobId = await (dispatch as CustomDispatch)(
				generate3DPreview({ prompt: options.prompt })
			).unwrap();
			// TODO: add failure snackbar
			// interval to get the progress of the preview job
			const threeDPreviewInterval = setInterval(async () => {
				// get status of of preview
				const results = await (dispatch as CustomDispatch)(
					get3DTaskStatus({ jobId })
				).unwrap();

				// if progress if 100 of preview job, then create a new block
				if (results.progress === 100 && !previewGenerated && results.final_link) {
					previewGenerated = true;
					clearInterval(threeDPreviewInterval);

					// add a block of preview
					const previewBlock = (
						(await create3DBlock(
							options,
							params.projectId,
							results.final_link,
							results?.thumbnail_url as string,
							user._id as string
						)) as TAddMultipleBlockArgs
					).data.blocks[0] as IBlock;

					scrollToView(`[data-itemId="${previewBlock._id}"]` as string);

					// Snackbar - 3D Preview generated
					handleAISnackBars(ESnackbarsAI.THREE_D_COMP, {
						isPreview: true,
						projectId: params.projectId,
						phaseId: options.phaseId,
						blockId: previewBlock._id
					});

					setTimeout(async () => {
						removeSnackbar(0);

						/** Start the process of creating refined 3D Model* */

						// add snackbar
						handleAISnackBars(ESnackbarsAI.THREE_D_PROG, {
							isPreview: false,
							progress: 0
						});

						// start generation of Refined 3D Model and gets its jobId
						const refinedJobId = await (dispatch as CustomDispatch)(
							generate3DRefined({ jobId })
						).unwrap();

						// interval to get the progress of the refined 3D Model job
						const refined3DInterval = setInterval(async () => {
							// get status of refined 3D Model
							const final3DResults = await (dispatch as CustomDispatch)(
								get3DTaskStatus({ jobId: refinedJobId })
							).unwrap();

							// Refined 3D Model created, update preview block with link of refined model
							if (final3DResults.progress === 100 && !refinedGenerated) {
								refinedGenerated = true;
								clearInterval(refined3DInterval);
								let retries = 0;
								// fetch url to see if the final_link is working
								const refined3DUrlInterval = setInterval(async () => {
									const refined3DUrl = await fetch(
										final3DResults.final_link as string
									);
									const {
										final_link: finalLinkRefined,
										thumbnail_url: thumbnailUrlRefined
									} = final3DResults;
									if (
										refined3DUrl.status === 200 &&
										finalLinkRefined &&
										thumbnailUrlRefined
									) {
										clearInterval(refined3DUrlInterval);
										// edit the preview block to store the link of final model
										const apiPayloadEdit: TEditBlockArgs =
											editThreeDBlockPayload(
												finalLinkRefined,
												thumbnailUrlRefined,
												previewBlock._id as string,
												options.phaseId as string
											);

										// edit block dispatch
										await (store.dispatch as CustomDispatch)(
											editBlock(apiPayloadEdit)
										);
										addBlocksToGDrive({
											data: [
												{
													name: 'Untitled',
													id: options.phaseId as string,
													children: [
														{
															id: previewBlock._id as string,
															file: finalLinkRefined,
															fileName: previewBlock.name
														}
													]
												}
											],
											uploadCount: 1
										});
										scrollToView(
											`[data-itemId="${previewBlock._id}"]` as string
										);
										handleAISnackBars(ESnackbarsAI.THREE_D_COMP, {
											isPreview: false,
											projectId: params.projectId,
											phaseId: previewBlock.parentId || options.phaseId,
											blockId: previewBlock._id
										});
										if (!isOutputRegeneratedRef.current)
											outputRegeneratedDataRef.current.push(
												previewBlock._id as string
											);
										removeSnackbar(10000);
										is3DModelCreationInProgress.current = false;
										// TODO: uncomment when backend PR schemas change is merged
										dispatch(
											createJob({
												data: {
													type: 'THREE_D_GENERATION',
													status: 'COMPLETED',
													_id: new mongoose.Types.ObjectId().toString() as string,
													prompt: options.prompt,
													aiType: 'REFINED_3D',
													progress: 100,
													blockId: previewBlock._id as string,
													thumbnailUrl: final3DResults.thumbnail_url,
													link: final3DResults.final_link as string
												}
											})
										);
									} else if (retries === 30) {
										addSnackbar({
											type: 'ERROR',
											text: 'Something went wrong',
											show: true
										});
										clearInterval(refined3DInterval);
										clearInterval(refined3DUrlInterval);
										is3DModelCreationInProgress.current = false;
										removeSnackbar(5000);
									} else {
										console.error(
											'Error fetching the url of refined 3D model.Retrying..'
										);
										retries += 1;
									}
								}, 1000);
							} else if (final3DResults.progress !== 100) {
								// Update progress of Refined 3D Model generation
								handleAISnackBars(ESnackbarsAI.THREE_D_PROG, {
									isPreview: false,
									progress: final3DResults.progress
								});
							}
						}, 1000);
					}, 2000);
				} else if (results.progress !== 100) {
					// Update progress of Preview 3D Model generation
					handleAISnackBars(ESnackbarsAI.THREE_D_PROG, {
						isPreview: true,
						progress: results.progress
					});
				}
			}, 1000);
		} else {
			// Shown when user tried to create another 3D Model when previous one is in progress
			const { snackBar } = store.getState();
			showAIMessage.current = true;
			addSnackbar({
				show: true,
				type: 'ERROR',
				text: 'Please wait for AI Generation to complete!'
			});
			removeSnackbar(3000, () => {
				showAIMessage.current = false;
				addSnackbar(snackBar);
			});
		}
	};
	// callback for generating ai results
	const onGenerateWithAI = async (options: TOnGenerateWithAIParams, isRegenerate?: boolean) => {
		onGenerateWithAIParams = options;
		if (!isRegenerate) {
			isOutputRegeneratedRef.current = false;
			outputRegeneratedDataRef.current = [];
		}
		switch (options.aiType) {
			case 'THREE_D': {
				handle3DGeneration(options);
				break;
			}
			case 'PM_JOURNEY': {
				let progressInterval: null | ReturnType<typeof setTimeout> = null;
				try {
					/** Note: PM Journey is not task based, it uses normal API */
					isAIProcessInProgress.current = true;
					PMAIProgress.current = 0;
					const { projectId } = generateIdsFromUrl();

					// get structure of journey till the PM-AI text block
					const previousStructure = getStructureOfPreviousPhasesBlocks(
						projectId,
						options.phaseId as string
					);

					// Update progress of PM-AI task (journey outline only)
					progressInterval = setInterval(() => {
						PMAIProgress.current = // calculate progress
							PMAIProgress.current + 4 <= 100
								? PMAIProgress.current + 4
								: PMAIProgress.current;
						handleAISnackBars(ESnackbarsAI.JOURNEY_OUTLINE); // update snackbar
					}, 300);

					reqCancelAI.current = axios.CancelToken.source(); // cancel token for PM-AI api abort

					const results: any = await (dispatch as CustomDispatch)(
						// Dispatch API call for super-power api
						generatePMJourney({ reqCancelAI, options, previousStructure })
					).unwrap();
					// get stages from the api result - AI sometimes sends stages and sometimes results.stages
					const pm_ai_results: PM_AI_RESULTS = results?.results || results;
					const { stages } = pm_ai_results;
					// create job for PM-AI - used to store prompt and response only
					dispatch(
						createJob({
							data: {
								type: 'PM_JOURNEY_GENERATION',
								status: 'COMPLETED',
								_id: options.phaseId as string,
								prompt: options.prompt,
								aiType: options.aiType,
								progress: 100,
								journey: stages,
								previousJourney: previousStructure
							}
						})
					);

					// Creation of prompts for PM-AI image gen
					const prompts: TPrompt[] = [];
					const regexSketchRenderPhase = /(?:sketch|render|designs)/i;

					const groupsToCreate: IGroup[] = [];
					const blocksToCreate: IBlock[] = [];

					for (let i = 0; i < stages?.length; i++) {
						const newPhaseName = (stages[i] as Partial<IGroup>).name as string;
						const phaseId = new mongoose.Types.ObjectId().toString() as string;
						pmJourneyPhases.current.push(phaseId);
						const phase = generateOptimisticStage(phaseId, newPhaseName, {
							id: projectId,
							type: 'JOURNEY'
						});
						phase.isPhaseCreated = true;
						const blockIdsToAdd = [];
						const blocks = stages[i]?.blocks;
						const blocksToAdd = [];
						let promptAddedForPhase = false;

						if (blocks) {
							for (let j = 0; j < blocks?.length; j++) {
								const blockId = new mongoose.Types.ObjectId().toString() as string;
								blockIdsToAdd.push(blockId);
								const optimisticBlock = generateOptimisticBlock(
									blockId,
									phaseId,
									projectId,
									blocks[j]?.name as string,
									'EMPTY'
								);
								blocksToAdd.push(optimisticBlock);
								if (blocks) {
									const sketchRenderPhase = regexSketchRenderPhase.test(
										blocks[j]?.name as string
									);

									if (
										blocks &&
										sketchRenderPhase &&
										blocks[j] &&
										!promptAddedForPhase
									) {
										promptAddedForPhase = true;
										prompts.push({
											prompt: `${blocks[j]?.name as string}`,
											phaseId,
											newBlockIndex: blocks.length,
											phaseIndex: options?.phaseIndex
												? options.phaseIndex + i + 1
												: 1000,
											aiType: /sketch/i.test(newPhaseName)
												? 'SKETCH'
												: 'RENDER'
										} as TPrompt);
									}
								}
							}
						}
						phase.children = blockIdsToAdd;

						if (stages.length === i + 1) {
							// if last stage, take the progress to 95
							PMAIProgress.current = 95;
						} else {
							PMAIProgress.current += 5;
						}

						groupsToCreate.push(phase);
						blocksToCreate.push(...blocksToAdd);
					}

					// Construct dispatch payload
					const dispatchPayload: TBatchCreateGroupAndBlocks = {
						parent: { id: projectId, type: 'JOURNEY' },
						groups: groupsToCreate,
						blocks: blocksToCreate,
						startGroupIndex: Number.isInteger(options?.phaseIndex)
							? (options.phaseIndex as number) + 1
							: 1000,
						children: groupsToCreate.map((group) => group._id.toString())
					};

					dispatch(batchCreateGroupAndBlocks(dispatchPayload));

					if (window.__RUNTIME_CONFIG__.REACT_APP_MOCK_AI === 'true') prompts.length = 0;

					if (prompts.length) {
						// if prompts are created for img gen from PM-AI, then create TTI flow for each prompt
						for (let i = 0; i < prompts.length; i++) {
							if (prompts && prompts[i]) {
								onGenerateWithAI({
									type: 'TEXT_TO_IMAGE_GENERATION',
									prompt: ((prompts[i]?.prompt || '') + options.prompt) as string,
									phaseId: prompts[i]?.phaseId,
									newBlockIndex: prompts[i]?.newBlockIndex,
									phaseIndex: prompts[i]?.phaseIndex,
									aiType: prompts[i]?.aiType as keyof typeof EAIGenerationType,
									triggerFromPMJOurney: true
								});
								numOfImageGenJobs.current += 1;
							}
						}
					}

					setTimeout(() => {
						// Added timeout so that progress of 100 is visible
						if (progressInterval) clearInterval(progressInterval);
						if (prompts.length === 0 && isAIProcessInProgress.current) {
							handleAISnackBars(ESnackbarsAI.PM_JOURNEY_COMPLETE, {
								projectId: options.projectId
							});
							isAIProcessInProgress.current = false;
							removeSnackbar(10000);
							setTimeout(() => {
								pmJourneyPhases.current = [];
								isAIProcessInProgress.current = false;
								numOfImageGenJobs.current = 0;
								totalProgressOfImageGenJobs.current = 0;
							}, 11000);
							PMAIProgress.current = 0;
						} else {
							removeSnackbar(0);
						}
					}, 100);
				} catch (error) {
					console.error('Error generating PM Journey : ', error);
					if (progressInterval) clearInterval(progressInterval);
					abortController();
					addSnackbar({ show: true, type: 'ERROR', text: 'Failed to generate journey' });
					removeSnackbar(5000);
				}
				break;
			}
			case 'TEXT': {
				// Initialize the progressInterval
				let progressInterval: null | ReturnType<typeof setTimeout> = null;
				try {
					// Extract projectId from the URL
					const { projectId } = generateIdsFromUrl();
					/** Note: Tet generation is not task based, it uses normal API */
					isAIProcessInProgress.current = true;
					TextAIProgress.current = 0;

					// Set up an interval to update progress of text generation every 300ms
					progressInterval = setInterval(() => {
						if (isAIProcessInProgress.current) {
							TextAIProgress.current = // calculate progress
								TextAIProgress.current + 4 <= 100
									? TextAIProgress.current + 4
									: TextAIProgress.current;
							handleAISnackBars(ESnackbarsAI.TEXT_TO_TEXT_PROGRESS); // Update the snackbar with the current progress
						}
					}, 200);

					reqCancelAI.current = axios.CancelToken.source(); // Create a cancel token for the API request

					// Dispatch an API call to generate text using the provided prompt
					const generatedString = await (dispatch as CustomDispatch)(
						generateAiText({ prompt: options.prompt, reqCancelAI })
					).unwrap();

					// if data provided is not enough, show error snackbar
					if (generatedString === 'NO ENOUGH DATA TO PROVIDE MORE CONTEXT') {
						if (progressInterval) clearInterval(progressInterval);
						handleAISnackBars(ESnackbarsAI.TEXT_TO_TEXT_ERROR, {
							projectId: options.projectId
						});
						isAIProcessInProgress.current = false;
						removeSnackbar(5000);
						TextAIProgress.current = 0;
					}
					// else create text block with received data
					else {
						// Generate an optimistic block for the text
						const newId = new mongoose.Types.ObjectId().toString() as string;
						const textBlock = generateOptimisticBlock(
							newId,
							options.phaseId as string,
							projectId,
							'UNDEFINED',
							'TEXT'
						);
						// Convert plain text to lexical state
						const lexicalText = await plainTextToLexicalState(
							generatedString as string
						);
						(textBlock as IText).text = lexicalText;

						// Dispatch an action to create a job indicating text generation is completed
						dispatch(
							createJob({
								data: {
									type: 'TEXT_GENERATION',
									status: 'COMPLETED',
									_id: new mongoose.Types.ObjectId().toString() as string,
									prompt: options.prompt,
									aiType: options.aiType,
									text: lexicalText,
									progress: 100
								}
							})
						);

						const apiPayload: TAddMultipleBlockArgs = {
							data: {
								blocks: [textBlock],
								stageId: options.phaseId as string,
								projectId,
								newBlockIndex: options.newBlockIndex || 0
							}
						};
						await dispatch(addMultipleBlocks(apiPayload));

						// Adding a note to the generated block
						dispatch(
							addNote({
								payload: {
									_id: new mongoose.Types.ObjectId().toString() as string,
									text: `<strong>AI generated from :</strong><br/>${options.prompt}`,
									color: '#C6C0C4',
									createdBy: user._id as string,
									lastUpdatedBy: user._id as string,
									createdAt: new Date(),
									updatedAt: new Date(),
									projectId: params.projectId,
									parentId: textBlock._id as string,
									parentType: 'BLOCK'
								}
							})
						);
						TextAIProgress.current = 100;
						if (!isOutputRegeneratedRef.current)
							outputRegeneratedDataRef.current.push(newId);
						// Set a timeout to ensure progress of 100 is visible before clearing interval
						setTimeout(() => {
							// Added timeout so that progress of 100 is visible
							if (progressInterval) clearInterval(progressInterval);
							if (isAIProcessInProgress.current) {
								handleAISnackBars(ESnackbarsAI.TEXT_TO_TEXT_COMPLETE, {
									projectId: options.projectId,
									phaseId: options.phaseId as string
								});
								isAIProcessInProgress.current = false;
								if (!isOutputRegeneratedRef.current) {
									removeSnackbar(5000);
								}
								// Ensure the process flag is reset after a short delay
								setTimeout(() => {
									isAIProcessInProgress.current = false;
								}, 100);
								TextAIProgress.current = 0;
							} else {
								removeSnackbar(0);
							}
						}, 100);
					}
				} catch (error) {
					console.error('Error generating Text : ', error);
					if (progressInterval) clearInterval(progressInterval);
					abortController();
					addSnackbar({ show: true, type: 'ERROR', text: 'Failed to generate text' });
					removeSnackbar(5000);
				}
				break;
			}
			default: {
				if (
					isAIProcessInProgress.current &&
					!options?.triggerFromPMJOurney &&
					is3DModelCreationInProgress.current
				) {
					const { snackBar } = store.getState();
					showAIMessage.current = true;
					addSnackbar({
						show: true,
						type: 'ERROR',
						text: 'Please wait for AI Generation to complete!'
					});
					removeSnackbar(3000, () => {
						showAIMessage.current = false;
						addSnackbar(snackBar);
					});
					return;
				}
				if (options.prompt.trim().length > 0) {
					isAIProcessInProgress.current = true;
					reqCancelAI.current = axios.CancelToken.source();
					const jobId = new mongoose.Types.ObjectId().toString() as string;
					const prompt = options.prompt + generateAIPromptKeywords(options.aiType);
					aiBlocksCreated.current = [];
					dispatch(
						publishJob({
							data: {
								type: 'TEXT_TO_IMAGE_GENERATION',
								status: 'QUEUED',
								_id: jobId,
								prompt,
								aiType: options.aiType
							},
							handleGenerateWithAI,
							reqCancelAI,
							options: { ...options, prompt }
						})
					);
				}
			}
		}
	};

	/**
	 * Function to update journey children throughout
	 * @param { string[] } projectChildren
	 * @param { IGroup[] } groups
	 * @param { IBlock[] } blocks
	 */
	const updateJourney = async (projectChildren: string[], groups: IGroup[], blocks: IBlock[]) => {
		// preparing data for handle redo
		const prevStateBlocks: IBlock[] = [];
		const prevStateGroups: IGroup[] = [];

		const blockUpdates: TBlockUpdate[] = [];
		const groupUpdates: TGroupUpdate[] = [];

		groups.forEach((group) => {
			groupUpdates.push({
				groupId: group._id as string,
				updates: { children: group.children }
			});
			prevStateGroups.push(findStageWithStageId(group._id as string) as IGroup);
		});

		blocks.forEach((block) => {
			blockUpdates.push({
				blockId: block._id as string,
				updates: {
					parentId: block.parentId
				}
			});
			prevStateBlocks.push(findBlockWithBlockId(block._id as string) as IBlock);
		});

		const apiPayload: TEditProjectThunkArg = {
			payload: {
				_id: params.projectId,
				update: {
					children: projectChildren
				}
			},
			prevState: { prevProject: getProjectFromRedux(params.projectId) }
		};
		await updateBlocksAndGroup(blockUpdates, groupUpdates, prevStateBlocks, prevStateGroups);
		await dispatch(editProject(apiPayload));
	};
	/**
	 * Callback to AI organise blocks and groups
	 * @param { string[] } blocksIds - Blocks to rename
	 * @param { string[] } groupIds - Groups to rename
	 */
	const onAIOrganize = async (blocksIds: string[], groupIds: string[]) => {
		let progressInterval: null | ReturnType<typeof setTimeout> = null;
		const { blockIds: reduxBlockIds } = getStageAndBlockIdsOfProject(params.projectId);
		window.sessionStorage.setItem('isInteractionDisabled', 'true');
		try {
			let snackBarMsg = '';
			// snackbar message criterias
			const isSingleGroup = groupIds.length === 1 && blocksIds.length === 0;
			const isSingleBlock = blocksIds.length === 1 && groupIds.length === 0;
			const isMultipleGroups = groupIds.length > 1 && blocksIds.length === 0;
			const isMultipleBlocks = blocksIds.length > 1 && groupIds.length === 0;
			const isJourneyOrganize = blocksIds.length === reduxBlockIds.length;
			let snackbarBlockOrGroupName = '';
			if (isSingleGroup) {
				const group = getGroupById(groupIds[0] as string);
				snackbarBlockOrGroupName = group.name;
				snackBarMsg = `Organizing blocks inside of "${
					group.name === 'UNDEFINED' ? 'Untitled' : group.name
				}".`;
			} else if (isSingleBlock) {
				const blockName = getBlockFromReduxById(blocksIds[0] as string)?.name as string;
				snackbarBlockOrGroupName = blockName;
				snackBarMsg = `Finding a good spot for "${
					blockName === 'UNDEFINED' ? 'Untitled' : blockName
				}"`;
			} else if (isJourneyOrganize) {
				snackBarMsg = 'Organizing the journey';
			} else if (isMultipleGroups) {
				snackBarMsg = 'Organizing groups';
			} else if (isMultipleBlocks) {
				snackBarMsg = 'Organizing blocks';
			} else {
				snackBarMsg = 'Organizing groups and blocks';
			}

			// show AI Rename in progress snackbar
			handleAISnackBars(ESnackbarsAI.AI_ORGANIZE_PROGRESS, { snackBarMsg });

			// store project data to undo ai organize
			aiOrganizeUpdates.current = { blocks: [], groups: [], projectChildren: [] };
			if (isSingleGroup) {
				const { stages, blocks } = getStageAndChildren(groupIds[0] as string, 'NORMALIZED');
				stages.forEach((stage) => {
					aiOrganizeUpdates.current.groups.push(stage);
				});
				blocks.forEach((block) => {
					aiOrganizeUpdates.current.blocks.push(block);
				});
			} else {
				aiOrganizeUpdates.current.projectChildren = getProjectFromRedux(params.projectId)
					.children as string[];

				const { stages, blocks } = getStagesAndBlocksOfProject(params.projectId);
				stages.forEach((stage) => {
					aiOrganizeUpdates.current.groups.push(stage);
				});
				blocks.forEach((block) => {
					aiOrganizeUpdates.current.blocks.push(block);
				});
			}

			// reset AI Rename related refs
			isAIProcessInProgress.current = true;
			AIOrganizeProgress.current = 0;

			// Set up an interval to update progress of AI Rename every 200ms
			const maxProgress = 95;
			const duration = 60000; // 1 minute in milliseconds
			const interval = 300; // Interval in milliseconds
			const increment = maxProgress / (duration / interval);
			progressInterval = setInterval(() => {
				if (isAIProcessInProgress.current) {
					AIOrganizeProgress.current = Math.min(
						AIOrganizeProgress.current + increment,
						maxProgress
					);
					handleAISnackBars(ESnackbarsAI.AI_ORGANIZE_PROGRESS, { snackBarMsg }); // Update the snackbar with the current progress
				}
			}, interval);
			// Step: 1 - Create payload for ai orgainze from blocks and groups
			const payload = await createAIPoweredGroupsData({
				blocksIds,
				groupIds,
				type: 'AI_ORGANIZE'
			});

			reqCancelAI.current = axios.CancelToken.source(); // Create a cancel token for the API request

			// send request to rename blocks and groups with AI
			const organizedGroups = await handleAIOrganize({ data: payload, reqCancelAI });
			// if request is cancelled
			if (!organizedGroups) {
				if (progressInterval) clearInterval(progressInterval);
				isAIProcessInProgress.current = false;
				removeSnackbar(0);
				return;
			}

			const prevStateBlocks: IBlock[] = []; // store prev states of blocks in case of blocks update failure
			const prevStateGroups: IGroup[] = []; // store prev states of groups in case of groups update failure

			const projectChildren: string[] = []; // stores project children to update groups indexing

			const blockUpdates: TBlockUpdate[] = []; // bulk block updates to make
			const groupUpdates: TGroupUpdate[] = []; // bulk group updates to make
			const newGroups: TNewGroup[] = []; // new groups to be created

			// Step: 2 - Create payload for existing blocks and groups position update
			const updateExistingChildren = (list: TAIGroupsData[], level: number = 0) => {
				// store all nested groups
				const nested: TAIGroupsData[] = [];
				list.forEach((group, groupIndex) => {
					// handle existing groups
					if (!group.newGroup) {
						const { children } = group;
						const groupChildren: string[] = [];

						// only groups at level 0 can be project children
						if (level === 0) projectChildren.push(group.groupId);

						children.forEach((child: TAIBlocksData | TAIGroupsData, index) => {
							// group child can be block/group
							const childBlock = child as TAIBlocksData;
							const childGroup = child as TAIGroupsData;

							// if child has blockType its a block
							if (childBlock.blockType) {
								groupChildren.push(childBlock.blockId);
								const blockData = findBlockWithBlockId(
									childBlock.blockId
								) as IBlock;
								// update block if parent has changed
								if (blockData.parentId !== group.groupId) {
									blockUpdates.push({
										blockId: childBlock.blockId,
										updates: { parentId: group.groupId }
									});
									prevStateBlocks.push(blockData);
								}
							} else if (!childGroup.newGroup) {
								groupChildren.push(childGroup.groupId);
								const groupData = findStageWithStageId(
									childGroup.groupId
								) as IGroup;
								// update group if its parent has changed
								if (groupData?.parentId !== group.groupId) {
									groupUpdates.push({
										groupId: childGroup.groupId,
										updates: { parentId: group.groupId }
									});
									prevStateGroups.push(groupData);
								}
								// addd child group to nested collection
								nested.push(childGroup);
							} else
								newGroups.push({
									group: { ...childGroup, parentId: group.groupId },
									groupIndex: index
								});
						});
						// update group children
						groupUpdates.push({
							groupId: group.groupId,
							updates: {
								children: groupChildren,
								...(groupChildren.length === 0 && { isPhaseCreated: true })
							}
						});
						prevStateGroups.push(findStageWithStageId(group.groupId) as IGroup);
					} else {
						newGroups.push({ group, groupIndex });
					}
				});
				// update exisiting children in nested collection
				if (nested.length > 0) updateExistingChildren(nested, level + 1);
			};
			updateExistingChildren(organizedGroups);

			// Step: 3 - Update blocks and group in redux and DB
			await updateBlocksAndGroup(
				blockUpdates,
				groupUpdates,
				prevStateBlocks,
				prevStateGroups
			);

			blockUpdates.length = 0;
			groupUpdates.length = 0;
			prevStateBlocks.length = 0;
			prevStateGroups.length = 0;

			// Step: 4 - Update project in redux and DB
			if (!isSingleGroup) {
				const reduxProject = getProjectFromRedux(params.projectId);
				const apiPayload: TEditProjectThunkArg = {
					payload: {
						_id: params.projectId,
						update: {
							children: projectChildren
						}
					},
					prevState: { prevProject: reduxProject as IProject }
				};
				await dispatch(editProject(apiPayload));
			}

			// Step: 5 - Create new groups
			const handleNewGroups = (newGroupsList: TNewGroup[]): Promise<any>[] => {
				const newGroupPromises = [];
				const nestedGroups: TNewGroup[] = [];
				newGroupsList.forEach(({ group, groupIndex }: TNewGroup) => {
					const newGroupId = new mongoose.Types.ObjectId().toString() as string;
					// add only existing blockIds and groupIds as group children
					const groupChildren: string[] = [];
					// new groups can be added in journey/phase
					const apiPayload: TAddStageThunkArg = {
						payload: {
							target: {
								id: group.parentId,
								type: group.parentId === params.projectId ? 'JOURNEY' : 'PHASE'
							},
							newPhaseIndex: groupIndex,
							stage: {
								...generateOptimisticStage(
									newGroupId,
									group.groupName || 'Untitled',
									{ id: params.projectId, type: 'JOURNEY' }
								),
								isPhaseCreated: true
							}
						}
					};

					// update children for new group and their parentIds
					group.children.forEach((child: TAIBlocksData | TAIGroupsData, index) => {
						const childBlock = child as TAIBlocksData;
						const childGroup = child as TAIGroupsData;
						if (childBlock.blockType) {
							groupChildren.push(childBlock.blockId);
							blockUpdates.push({
								blockId: childBlock.blockId,
								updates: { parentId: newGroupId }
							});
							prevStateBlocks.push(
								findBlockWithBlockId(childBlock.blockId) as IBlock
							);
						} else if (childGroup.newGroup) {
							nestedGroups.push({
								group: { ...childGroup, parentId: newGroupId },
								groupIndex: index
							});
						} else {
							groupChildren.push(childGroup.groupId);
							groupUpdates.push({
								groupId: childGroup.groupId,
								updates: {
									parentId: newGroupId
								}
							});
							prevStateGroups.push(
								findStageWithStageId(childGroup.groupId) as IGroup
							);
						}
					});
					groupUpdates.push({
						groupId: newGroupId,
						updates: { children: groupChildren }
					});

					newGroupPromises.push(dispatch(addStage(apiPayload)));
				});
				// handle nested groups found in new groups
				if (nestedGroups.length > 0)
					newGroupPromises.push(...handleNewGroups(nestedGroups));
				return newGroupPromises;
			};

			await Promise.all(handleNewGroups(newGroups));

			// Step: 6 - Update new groups with children and blocks with parent id
			await updateBlocksAndGroup(
				blockUpdates,
				groupUpdates,
				prevStateBlocks,
				prevStateGroups
			);

			// reset ai rename changes for undo
			// aiOrganizeUpdates.current = { blocks: [], groups: [], projectChildren: [] };
			// aiOrganizeUpdates.current.blocks = prevStateBlocks;
			// aiOrganizeUpdates.current.groups = prevStateGroups;
			// aiOrganizeUpdates.current.projectChildren = prevProjectChildren;

			// update snackbar message to show completed AI rename
			if (isSingleGroup) snackBarMsg = `${snackbarBlockOrGroupName}" organized.`;
			else if (isSingleBlock)
				snackBarMsg = `Blocks inside of "${snackbarBlockOrGroupName}" organized.`;
			else if (isJourneyOrganize) snackBarMsg = 'Journey organized.';
			else if (isMultipleGroups) snackBarMsg = 'Groups organized.';
			else if (isMultipleBlocks) snackBarMsg = 'Blocks organized.';
			else snackBarMsg = 'Groups and blocks organized.';

			AIOrganizeProgress.current = 100;
			handleAISnackBars(ESnackbarsAI.AI_ORGANIZE_PROGRESS, { snackBarMsg });

			// add undo task only if AI req not cancelled
			if (isAIProcessInProgress.current) {
				const {
					blocks,
					groups,
					projectChildren: projectGroups
				} = aiOrganizeUpdates.current;

				// extract current journey status for redo
				const currentProjectChildren = getProjectFromRedux(params.projectId)
					.children as string[];
				const { stages: currentStages, blocks: currentBlocks } =
					getStagesAndBlocksOfProject(params.projectId);
				window.Naya.handleUndoRedo({
					type: 'ADD',
					payload: {
						originalAction: updateJourney,
						oppositeAction: handleUndoAIOrganise,
						originalActionPayload: [
							currentProjectChildren,
							currentStages,
							currentBlocks
						],
						oppositeActionPayload: [blocks, groups, projectGroups]
					}
				});
			}

			// Set a timeout to ensure progress of 100 is visible before clearing interval
			setTimeout(() => {
				// Added timeout so that progress of 100 is visible
				if (progressInterval) clearInterval(progressInterval);
				if (isAIProcessInProgress.current) {
					handleAISnackBars(ESnackbarsAI.AI_ORGANIZE_COMPLETE, {
						snackBarMsg,
						isSingleBlock,
						isSingleGroup,
						blocksIds,
						groupIds
					});
					isAIProcessInProgress.current = false;
					removeSnackbar(15000);
					// Ensure the progress is reset after a short delay
					setTimeout(() => {
						isAIProcessInProgress.current = false;
					}, 100);
					AIOrganizeProgress.current = 0;
				} else {
					removeSnackbar(0);
					AIOrganizeProgress.current = 0;
				}
			}, 500);
		} catch (err) {
			console.error(err);
			handleAISnackBars(ESnackbarsAI.AI_ORGANIZE_ERROR);
			if (progressInterval) clearInterval(progressInterval);
			abortController();
			removeSnackbar(5000);
			const { blocks, groups, projectChildren } = aiOrganizeUpdates.current;
			handleUndoAIOrganise(blocks, groups, projectChildren);
		} finally {
			window.sessionStorage.removeItem('isInteractionDisabled');
		}
	};

	/**
	 * Callback to AI rename blocks and groups
	 * @param { string[] } blocksIds - Blocks to rename
	 * @param { string[] } groupIds - Groups to rename
	 */
	const onAIRename = async (
		blocksIds: string[],
		groupIds: string[],
		isUndoRedo: boolean = false
	) => {
		let progressInterval: null | ReturnType<typeof setTimeout> = null;
		try {
			let snackBarMsg = '';
			// snackbar message criterias
			const isSingleGroup = groupIds.length === 1 && blocksIds.length === 0;
			const isSingleBlock = blocksIds.length === 1 && groupIds.length === 0;
			const isMultipleGroups = groupIds.length > 1 && blocksIds.length === 0;
			const isMultipleBlocks = blocksIds.length > 1 && groupIds.length === 0;

			if (isSingleGroup) {
				snackBarMsg = 'Renaming group and blocks with AI.';
			} else if (isSingleBlock) {
				snackBarMsg = 'Renaming block with AI';
			} else if (isMultipleGroups) {
				snackBarMsg = 'Renaming groups and blocks with AI.';
			} else if (isMultipleBlocks) {
				snackBarMsg = 'Renaming blocks with AI';
			} else {
				snackBarMsg = 'Renaming groups and blocks with AI.';
			}

			// show AI Rename in progress snackbar
			handleAISnackBars(ESnackbarsAI.AI_RENAME_PROGRESS, { snackBarMsg });

			// reset AI Rename related refs
			isAIProcessInProgress.current = true;
			AIRenameProgress.current = 0;

			// Set up an interval to update progress of AI Rename every 200ms
			progressInterval = setInterval(() => {
				if (isAIProcessInProgress.current) {
					AIRenameProgress.current = // calculate progress
						AIRenameProgress.current + 2 <= 100
							? AIRenameProgress.current + 2
							: AIRenameProgress.current;
					handleAISnackBars(ESnackbarsAI.AI_RENAME_PROGRESS, { snackBarMsg }); // Update the snackbar with the current progress
				}
			}, 200);

			const payload = await createAIPoweredGroupsData({
				blocksIds,
				groupIds,
				type: 'AI_RENAME'
			});

			reqCancelAI.current = axios.CancelToken.source(); // Create a cancel token for the API request

			// send request to rename blocks and groups with AI
			const output = await handleAIRename({ data: payload, reqCancelAI });
			const renamedGroups = output.groups;
			const renamedBlocks = output.blocks;

			// create payload to bulk update groups and blocks
			const updateBlocks = [];
			const updateGroups = [];
			const prevStateBlocks: IBlock[] = []; // store prev states of blocks in case of blocks update failure
			const prevStateGroups: IGroup[] = []; // store prev states of groups in case of groups update failure

			for (let i = 0; i < renamedGroups.length; i++) {
				// handle groups update payload
				if (renamedGroups[i].renameGroup || groupIds.includes(renamedGroups[i].groupId)) {
					updateGroups.push({
						groupId: renamedGroups[i].groupId,
						updates: { name: renamedGroups[i].groupName || 'UNDEFINED' }
					});
					prevStateGroups.push(findStageWithStageId(renamedGroups[i].groupId) as IGroup);
				}
			}
			for (let j = 0; j < renamedBlocks.length; j++)
				if (renamedBlocks[j].rename || blocksIds.includes(renamedBlocks[j].blockId)) {
					updateBlocks.push({
						blockId: renamedBlocks[j].blockId,
						updates: { name: renamedBlocks[j].name || 'UNDEFINED' }
					});
					prevStateBlocks.push(findBlockWithBlockId(renamedBlocks[j].blockId) as IBlock);
				}

			// reset ai rename changes for undo
			aiRenameUpdates.current = { blocks: [], groups: [] };
			aiRenameUpdates.current.blocks = prevStateBlocks;
			aiRenameUpdates.current.groups = prevStateGroups;
			updateBlocksAndGroup(updateBlocks, updateGroups, prevStateBlocks, prevStateGroups);

			if (!isUndoRedo) {
				// handle undo-redo
				window.Naya.handleUndoRedo({
					type: 'ADD',
					payload: {
						originalAction: updateBlocksAndGroup,
						oppositeAction: handleUndoAIRename,
						originalActionPayload: [
							updateBlocks,
							updateGroups,
							prevStateBlocks,
							prevStateGroups
						],
						oppositeActionPayload: []
					}
				});
			}
			// update snackbar message to show completed AI rename
			if (isSingleBlock) snackBarMsg = 'AI name generated.';
			else snackBarMsg = 'AI names generated.';

			AIRenameProgress.current = 100;

			// Set a timeout to ensure progress of 100 is visible before clearing interval
			setTimeout(() => {
				// Added timeout so that progress of 100 is visible
				if (progressInterval) clearInterval(progressInterval);
				if (isAIProcessInProgress.current) {
					handleAISnackBars(ESnackbarsAI.AI_RENAME_COMPLETE, {
						snackBarMsg
					});
					isAIProcessInProgress.current = false;
					removeSnackbar(5000);
					// Ensure the progress is reset after a short delay
					setTimeout(() => {
						isAIProcessInProgress.current = false;
					}, 100);
					AIRenameProgress.current = 0;
				} else {
					removeSnackbar(0);
				}
			}, 100);
		} catch (err) {
			console.error(err);
			handleAISnackBars(ESnackbarsAI.AI_RENAME_ERROR);
			if (progressInterval) clearInterval(progressInterval);
			abortController();
			removeSnackbar(5000);
		}
	};

	/**
	 * Callback to get AI suggestion for blocks and groups
	 * @param { string } id - Block/Group id
	 * @param { 'BLOCK'| 'GROUP' } type - suggestion for BLOCK/GROUP
	 */
	const onAISuggestion = async (id: string, type: 'BLOCK' | 'GROUP') => {
		try {
			const blocksIds = type === 'BLOCK' ? [id] : [];
			const groupIds = type === 'GROUP' ? [id] : [];

			// Create payload for superpower ai
			const payload = await createAIPoweredGroupsData({
				blocksIds,
				groupIds,
				type: 'AI_RENAME',
				isAISuggestion: true
			});

			reqCancelAI.current = axios.CancelToken.source(); // Create a cancel token for the API request

			// send request to get AI suggestion
			const suggestions = await getAISuggestion({ data: payload, reqCancelAI });

			return suggestions;
		} catch (err) {
			console.error(err);
			abortController();
			return null;
		}
	};

	// Runs on mount
	useEffect(() => {
		// Function to handle beforeunload
		const handleBeforeUnload = (event: BeforeUnloadEvent) => {
			if (!isAIProcessInProgress.current && !is3DModelCreationInProgress.current) return true;
			// Display a warning message to the user
			const message =
				'AI generation is in progress. Are you sure you want to leave the page?';
			event.returnValue = message; // Standard for most browsers
			return message; // For some older browsers
		};
		window.addEventListener('beforeunload', handleBeforeUnload);
		// On unmount stop the process
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, []);

	return {
		onGenerateWithAI,
		handleGenerateWithAI,
		onAIRename,
		onAISuggestion,
		onAIOrganize,
		abortController,
		updateBlocksAndGroup
	};
};
export default useAiGeneration;
