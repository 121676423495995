import {
	createContext,
	Dispatch,
	ReactElement,
	SetStateAction,
	useCallback,
	useEffect,
	useState
} from 'react';
import { checkIfAuthRedirect, checkIfUserIsStillAuthenticated } from 'src/util/auth/authActions';
import { useLDClient, LDContext, useFlags } from 'launchdarkly-react-client-sdk';
import useUser from 'src/redux/hooks/user';
import { loadStorylane } from 'src/util/collaboration/util';
import { stopAllDriveChannels } from 'src/redux/reduxActions/integrations/googleIntegrations';
import { TGuestAccessTo, TLDFlags } from '../collaborationTool/CollaborationTool.types';

export const AuthContext = createContext<{
	status: AuthStatus;
	setStatus: Dispatch<SetStateAction<AuthStatus>>;
	completedAuthCheck: boolean;
	hasGuestAccessTo: TGuestAccessTo;
	updateHasGuestAccessTo: (guestAccessTo: TGuestAccessTo) => void;
}>({
	status: 'SIGNED_OUT',
	setStatus: () => {},
	completedAuthCheck: false,
	hasGuestAccessTo: false,
	updateHasGuestAccessTo: () => {}
});

export type AuthProviderProps = {
	children: ReactElement;
};

export type AuthStatus = 'LOADING' | 'AUTHENTICATED' | 'SIGNED_OUT' | 'UNAPPROVED' | 'GUEST';

const AuthProvider = ({ children }: AuthProviderProps) => {
	const [checkingAuth, setCheckingAuth] = useState<boolean>(false);
	const [hasGuestAccessTo, setHasGuestAccessTo] = useState<TGuestAccessTo>(false);
	const [status, setStatus] = useState<AuthStatus>('LOADING');
	const { user: currentUser } = useUser();
	const ldClient: any = useLDClient();

	const { guestAccess, isSyncStorageEnabled, isSyncDriveEnabled } = useFlags<TLDFlags>();

	useEffect(() => {
		async function fetchData() {
			setCheckingAuth(false);
			setStatus('LOADING');
			if (!(await checkIfAuthRedirect())) {
				await checkIfUserIsStillAuthenticated(
					(authStatus: AuthStatus, hasGuestAccessValue: TGuestAccessTo = false) => {
						const isI2R = window.sessionStorage.getItem('i2r');
						setHasGuestAccessTo(hasGuestAccessValue);
						setStatus(isI2R && authStatus === 'UNAPPROVED' ? 'SIGNED_OUT' : authStatus);
						if (authStatus === 'AUTHENTICATED' && isI2R) {
							if ((window as any).gtag) {
								(window as any).gtag('event', 'CONTEST_LOGIN_COMPLETED', {
									event_category: 'CONTEST_LOGIN_COMPLETED',
									event_label: 'Login is complete by user via i2r'
								});
							}
						}
					},
					guestAccess
				);
				setCheckingAuth(true);
			}
			return null;
		}
		fetchData();
	}, []);

	/**
	 * Tiggered on sync storage flag updates
	 */
	useEffect(() => {
		/**
		 * Disconnect google drive channels for all users if flag is off
		 */
		if (!isSyncDriveEnabled || !isSyncStorageEnabled) {
			stopAllDriveChannels();
		}
	}, [isSyncDriveEnabled, isSyncStorageEnabled]);

	/**
	 * callback function to update the HasGuestAccessTo
	 */
	const updateHasGuestAccessTo = useCallback(
		(guestAccessTo: TGuestAccessTo) => {
			setHasGuestAccessTo(guestAccessTo);
		},
		[status]
	);

	useEffect(() => {
		try {
			const { _id: uniqueId, userName: name, email } = currentUser;
			const isI2r = window.sessionStorage.getItem('ai-launch');
			if (uniqueId && name && email) {
				const ldContext: LDContext = {
					kind: 'user',
					key: uniqueId?.toString(),
					name,
					email,
					'ai-launch': !!isI2r
				};
				ldClient?.identify(ldContext);
			}
		} catch (error) {
			console.warn('Launch Darkly Context Init failed.', error);
		}
	}, [status, currentUser, ldClient]);

	useEffect(() => {
		loadStorylane();
	}, []);

	return (
		<AuthContext.Provider
			value={{
				status,
				setStatus,
				completedAuthCheck: checkingAuth,
				hasGuestAccessTo,
				updateHasGuestAccessTo
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthProvider;
