import { Button, FeedbackInput, ReactionPanel, UserT } from '@naya_studio/radix-ui';
import {
	EAiIngestStatusType,
	EBlockType,
	IFeedback,
	IUser,
	TReferenceOrObject,
	TUserAndRole
} from '@naya_studio/types';
import { useContext, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'src/redux/reducers/root.types';
import { addComment, addCommentsNotification } from 'src/redux/reduxActions/feedbacks';
import { getAllUsersBasedOnIds, getFeedbackBasedOnBlockType } from 'src/redux/reduxActions/util';
import { TAddCommentNotificationArg, TAddCommentThunkArg } from 'src/types/argTypes';
import useFeedbackActions from 'src/util/feedback/useFeedbackActions';
import { useParams } from 'react-router';
import { PathParamsType } from 'src/types/pathParams';
import { store } from 'src';
import { CustomDispatch } from 'src/redux/actions/types';
import useUser, { useAIUser } from 'src/redux/hooks/user';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TLDFlags } from 'src/components/collaborationTool/CollaborationTool.types';
import {
	addAIFeedbackReply,
	EAIReplyType,
	EMultiselectAIOpt,
	FeedbackAISnackbars,
	handleUnindentifiedIntent,
	identifyAIIntent
} from 'src/util/block/utilAI';
import { CollaborationToolContext } from 'src/components/collaborationTool/CollaborationTool';
import trackEvent from 'src/util/analytics/analytics';
import { CustomEvents } from 'src/util/analytics/events';
import { toggleAIStatus } from 'src/redux/reduxActions/project';
import useProject from 'src/redux/hooks/useProject';
import { getFormattedUsersData } from '../util';
import reactionIcon from '../../../assets/icons/toolbar/reaction.svg';

// props for new reply component
export type NewReplyProps = {
	parentId: string;
	actionItemAssignees: string[];
	setActionItemAsignees: (userIds: string[]) => void;
	parentFeedback: IFeedback;
};

// New reply component for feedback
export const NewReply = ({
	parentId,
	actionItemAssignees,
	setActionItemAsignees,
	parentFeedback
}: NewReplyProps) => {
	const [feedbackText, setFeedbackText] = useState(''); // holds the reply text
	const [showReplyOptions, setShowReplyOptions] = useState(false); // toggles reply options visibility
	const [showEmojisPanel, setShowEmojiPanel] = useState(false); // holds state for showing emoji panel

	// Feedback input ref
	const feedbackInputRef = useRef<{ onAppendText: (text: string) => void }>();

	// extract current user
	const { user } = useUser();
	const { aiUser } = useAIUser();
	const { journeyRtc, isFeedbackAiEnabled } = useFlags<TLDFlags>();

	// extracting ids from url
	const { canvasId: blockId, projectId, stageOrDashboard: stageId } = useParams<PathParamsType>();
	const { handleMultiselectAIWrapper, updateMultiselectAiProgressSnackbar, isGuestUser } =
		useContext(CollaborationToolContext);

	const { project } = useProject(projectId);

	// extract all blocks from redux
	const allBlocks = useSelector((state) => (state as ReduxState).blocks.data, shallowEqual);

	// extract all user roles from current block
	const userRoles = allBlocks[blockId]?.users as TUserAndRole[];

	// extract all populatd ysers based on ids
	const allUsers = getAllUsersBasedOnIds(userRoles.map((x: TUserAndRole) => x.user as string));

	const dispatch = useDispatch<typeof store.dispatch>();

	// extarcting functions from feedback actions hook
	const {
		generateOptimisticFeedback,
		getTaggedUsersFromHtmlString,
		extractTextFromHTML,
		sendEditFeedback
	} = useFeedbackActions();

	/**
	 * Function to append/add emoji to input field
	 * @param emoji string
	 */
	const handleEmoji = (emoji: string) => {
		if (feedbackInputRef.current) {
			feedbackInputRef.current.onAppendText(emoji);
		}
	};

	/**
	 * Function checks if the reply should mark the parent comment as complete/incomplete
	 * @returns boolean
	 */
	const checkIfFeedbackCanBeMarkedComplete = () => {
		// check if the reply user is same as parent comment user
		if ((parentFeedback.createdBy as IUser)._id === user._id) {
			// if its the first reply and by the same user
			if (parentFeedback.replies?.length === 0) {
				return false;
			}
			// check if the replies have some other user
			let otheruserFound = false;
			parentFeedback.replies?.forEach((rep: TReferenceOrObject<IFeedback>) => {
				if (((rep as IFeedback).createdBy as IUser)._id !== user._id) {
					otheruserFound = true;
				}
			});
			if (otheruserFound) return true;
		} else {
			return true;
		}
		return false;
	};

	// send out api call to save the reply
	const saveReply = async () => {
		// extracting old comments to revert if api fails
		const oldComments = getFeedbackBasedOnBlockType(blockId);

		const reply: IFeedback = generateOptimisticFeedback();
		reply.statement = feedbackText;
		const tempTaggedUsers = getTaggedUsersFromHtmlString(feedbackText);
		const isAIUserTagged = tempTaggedUsers.includes(aiUser._id as string);
		const taggedUsers = tempTaggedUsers.filter((x) => x !== aiUser._id);
		reply.taggedUsers = taggedUsers;
		reply.createdBy = aiUser;
		reply.createdAt = new Date();

		// payload
		const apiPayload: TAddCommentThunkArg = {
			payload: {
				feedback: reply,
				blockId,
				stageId,
				projectId,
				userId: user._id,
				parentCommentId: parentId,
				currentUser: user,
				isJourneyRtcEnabled: journeyRtc
			},
			prevState: {
				oldComments
			}
		};

		// If user/s has been tagged in the new reply then checkoff the action item for those users
		let pActionItemFor = parentFeedback.actionItemFor;
		if (parentFeedback.actionItemFor && !isAIUserTagged) {
			if (taggedUsers.length) {
				taggedUsers.forEach((id: string) => {
					pActionItemFor = { ...pActionItemFor, [id]: false };
				});
				sendEditFeedback({ _id: parentFeedback._id, actionItemFor: pActionItemFor });
			}
		}

		setFeedbackText('');
		dispatch(addComment(apiPayload)).then(async (response) => {
			if (isAIUserTagged) {
				// Track event for AI tracking
				trackEvent(CustomEvents.TAGGED_AI);
				// if content training is off, add appropriate feedback reply
				if (
					!project?.aiIngestStatus ||
					project?.aiIngestStatus === EAiIngestStatusType.INACTIVE
				) {
					addAIFeedbackReply(
						generateOptimisticFeedback(),
						projectId,
						stageId,
						blockId,
						EAIReplyType.CONTENT_TRAINING_OFF,
						journeyRtc,
						parentId
					);

					// show contet training off snackbar with an option to enable it
					updateMultiselectAiProgressSnackbar({
						start: 0,
						end: 0,
						msg: FeedbackAISnackbars.CONTENT_TRAINING_INACTIVE,
						snackbarType: 'ERROR',
						buttonMsg: 'Enable',
						forceAdd: true,
						showAiIconAtStart: false,
						buttonAction: () => {
							dispatch(
								toggleAIStatus({
									projectId,
									isContentTraningEnabled: true,
									isAIFeaturesEnabled: true
								})
							);
							window.sessionStorage.setItem(
								'ingestion-triggered-from-feedback',
								projectId
							);
							updateMultiselectAiProgressSnackbar({
								start: 0,
								end: 0,
								snackbarType: 'LOADER',
								msg: FeedbackAISnackbars.CONTENT_TRAINING_STARTED,
								forceAdd: true
							});
						}
					});
				}
				// if ai ingest is in progress, add appropriate reply and show loading snackbar
				else if (project?.aiIngestStatus === EAiIngestStatusType.IN_PROGRESS) {
					addAIFeedbackReply(
						generateOptimisticFeedback(),
						projectId,
						stageId,
						blockId,
						EAIReplyType.CONTENT_TRAINING_IN_PROGRESS,
						journeyRtc,
						parentId
					);
					updateMultiselectAiProgressSnackbar({
						start: 0,
						end: 0,
						msg: FeedbackAISnackbars.CONTENT_TRAINING_INPROGRESS,
						snackbarType: 'LOADER',
						forceAdd: true
					});
				}
				// if ai ingest is ready, identify the ai intent and perform action acoordingly
				else {
					const trimmedText = extractTextFromHTML(feedbackText).replace('@AI', '').trim();
					const intent = await identifyAIIntent(
						trimmedText,
						allBlocks[blockId]?.blockType as EBlockType
					);
					if (intent.includes('NO ACTION FOUND')) {
						let type = EAIReplyType.EMPTY;
						if (trimmedText) type = EAIReplyType.UNCLEAR;
						handleUnindentifiedIntent(
							generateOptimisticFeedback(),
							projectId,
							stageId,
							blockId,
							journeyRtc,
							type,
							parentId
						);
					} else {
						handleMultiselectAIWrapper(
							intent as EMultiselectAIOpt,
							[blockId],
							[],
							undefined,
							1,
							trimmedText
						);
						window.sessionStorage.setItem(
							'ai-feedback-reply',
							JSON.stringify({
								parent: parentId,
								aiType: intent,
								blockId,
								stageId
							})
						);
					}
				}
			} else {
				// send out notifications
				(dispatch as CustomDispatch)(
					addCommentsNotification(response.payload as TAddCommentNotificationArg)
				);
			}
			// scroll to new reply
			const chatContainer = document.querySelector('#chat-container');
			if (chatContainer) {
				chatContainer.scrollTop = chatContainer.scrollHeight;
			}
		});

		// user replying to own comment immediately should not mark feedback as complete
		if (checkIfFeedbackCanBeMarkedComplete() && !isAIUserTagged) {
			dispatch({
				type: 'MARK_FB_NOTIF_COMP',
				payload: { markFeedbackAsComplete: parentFeedback._id, isCompleted: true }
			});
		}
	};

	// function to modify action item asigneed
	const onTagUser = (selectedIds: string[]) => {
		const tempList = actionItemAssignees;
		for (let i = 0; i < selectedIds.length; i++) {
			if (!actionItemAssignees.includes(selectedIds[i] as string)) {
				tempList.push(selectedIds[i] as string);
			}
		}
		setActionItemAsignees(tempList);
	};

	return (
		<div>
			{/* input */}
			<FeedbackInput
				id="new"
				style={{ minHeight: 64, marginTop: 8 }}
				className="reply-input tw-bg-[#FAFAFA] tw-rounded-lg"
				text={feedbackText}
				placeHolder="Add a reply, or @AI with a command like “summarize”, “evaluate”, or “generate concepts”. "
				onTagUser={onTagUser}
				users={
					getFormattedUsersData(allUsers, isFeedbackAiEnabled && !isGuestUser)
						.blockUsers as UserT[]
				}
				currentUser={
					getFormattedUsersData(allUsers, isFeedbackAiEnabled && !isGuestUser).currentUser
				}
				variant="BORDERLESS"
				onSubmit={saveReply}
				onEsc={() => setFeedbackText('')}
				onInputChange={(value: string) => {
					// This is to close my task panel when user starts typing
					if (feedbackText.length === 0 && document.querySelector('.my-task-wrapper')) {
						(document.querySelector('.my-tasks') as HTMLButtonElement)?.click();
					}
					setFeedbackText(value);
				}}
				onShowReplyBtn={() => {
					setShowReplyOptions(true);
				}}
				ref={feedbackInputRef}
				key="new"
			/>
			{/* Action buttons */}
			{showReplyOptions && (
				<div className="tw-flex tw-justify-between tw-items-center tw-mt-4">
					{/* Emojis panel */}
					<img
						role="presentation"
						className="tw-cursor-pointer"
						src={reactionIcon}
						alt="reaction"
						onClick={(e: any) => {
							e.stopPropagation();
							setShowEmojiPanel(!showEmojisPanel);
						}}
						onMouseUp={(e: any) => {
							e.stopPropagation();
						}}
						height={15}
					/>
					<div className="tw-flex">
						<Button
							variant="SECONDARY-GREY"
							text="Cancel"
							disabled={false}
							onBtnClick={() => {
								setFeedbackText('');
								setShowReplyOptions(false);
							}}
							className="tw-mr-2"
						/>
						<Button
							variant="PRIMARY"
							text="Reply"
							disabled={extractTextFromHTML(feedbackText).length === 0}
							onBtnClick={saveReply}
						/>
					</div>
				</div>
			)}
			{showEmojisPanel && (
				<ReactionPanel
					onEmojiSelect={(emoji: string) => {
						handleEmoji(emoji);
					}}
					className="tw-mt-4"
				/>
			)}
		</div>
	);
};
