import { IAction, CommunityAITypes } from '../root.types';

export interface CommunityAIData {
  communityAIData : [],
  isAiLoading : boolean,
}

const initialPrediction : CommunityAIData = {
  communityAIData: [],
  isAiLoading: false,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const communityAI = (state = initialPrediction, action: IAction<CommunityAITypes | keyof typeof CommunityAITypes>) => {
  let newState: CommunityAIData;
  const dataAi : CommunityAIData = action.payload;

  switch (action.type) {
    case CommunityAITypes.DISPLAY_AI_RESULTS:
      newState = { ...state, ...dataAi };
      break;
    case CommunityAITypes.EDIT_AI_RESULTS:
      newState = { ...state, ...dataAi };
      break;
    default:
      newState = { ...state, ...dataAi };
      break;
  }
  return newState;
};

export default communityAI;
