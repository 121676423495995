import React from 'react';
import { connect } from 'react-redux';
import {
  RouteComponentProps,
  StaticContext,
  withRouter,
} from 'react-router';
import {
  OverlayTrigger, Tooltip,
} from 'react-bootstrap';
import './Colors.scss';
import { colors } from 'src/components/canvas/toolbar/toolbar.config';
import { SketchPicker } from 'react-color';
import { ENodeType } from '@naya_studio/types';
import localforage from 'localforage';
import { getColorProperties } from 'src/components/canvas/app/nodes/utils/helper';
import { ReactComponent as MulticolorIcon } from 'src/assets/icons/toolbar/multicolor-icon.svg';
import { ColorsOptionProps, ColorsOptionDispatchProps, PathParamsType } from './Colors.types';
// import noFillIcon from '../../../../../assets/icons/toolbar/no_fill.svg';
import addIcon from '../../../../../assets/icons/toolbar/add_icon.svg';
import eyeDropIcon from '../../../../../assets/icons/toolbar/eyedrop.svg';
import Opacity from '../Opacity/Opacity';

class ColorsOption extends React.Component<
ColorsOptionProps & ColorsOptionDispatchProps & RouteComponentProps<PathParamsType>
> {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    activeFillColor: '#ffffff',
    activeBkgColor: '#ffffff',
    multiColorSelect: [],
    colorOptions: colors,
    colorPicked: '#FF0000',
    showColorPicker: false,
    eyeDropperActive: false,
    showEyedropper: true,
    hexValue: '',
    showMultiColorIcon: false,
    projectId: window.location.href.split('/')[4],
  };

  async componentDidMount() {
    const {
      // textData,
      app,
    } = this.props;
    let localColorsOptions;

    // Used for text fill
    // if (textData) {
    //   const text = textData?.textNodeData.text;

    //   if (text && text.style && text.style.fill) {
    //     return this.setState({ activeFillColor: text.style.fill });
    //   }
    // }

    this.setState({ activeBkgColor: app.bkgColor });
    if (!(window as any).EyeDropper) {
      this.setState({ showEyedropper: false });
    }
    const { projectId } = this.state;
    if (projectId) {
      localColorsOptions = await localforage.getItem(`${projectId}-fill`) as string;
      localColorsOptions = JSON.parse(localColorsOptions);
    }
    this.setState(
      {
        colorOptions: localColorsOptions && localColorsOptions.length > 0 ? localColorsOptions : colors,
      },
    );
    const allNodes = app.selectedNodes;
    if (allNodes.length > 1) {
      let fillColors = [];
      for (let i = 0; i < 2; i++) {
        const nodeData = allNodes[i]?.nodeData;
        if (nodeData?.nodeType === ENodeType.TEXT) {
          fillColors.push(nodeData?.text?.style?.fill || nodeData?.fill?.fillColor);
        } else {
          fillColors.push(nodeData?.fill?.fillColor);
        }
      }
      if (fillColors.includes('NO_FILL')) {
        if (fillColors[0] === 'NO_FILL' && fillColors[1] === 'NO_FILL') {
          this.setState({ activeFillColor: fillColors[0] });
        } else {
          fillColors = fillColors.filter((x) => x !== 'NO_FILL');
          this.setState({ activeFillColor: fillColors[0] });
        }
      } else if (fillColors.every((val, _i, arr) => val === arr[0])) {
        this.setState({ activeFillColor: fillColors[0] });
      } else {
        this.setState({ multiColorSelect: fillColors });
      }
    } else {
      const nodeData = app.selectedNodes[0]?.nodeData;
      if (nodeData) {
        if (nodeData?.text?.style?.fill || nodeData.fill?.fillColor) {
          const temp = localColorsOptions && localColorsOptions.length > 0 ? localColorsOptions : colors;
          if (nodeData.nodeType === ENodeType.STICKY_NOTE && nodeData.fill?.fillColor) {
            this.setState({ activeFillColor: nodeData.fill.fillColor }, () => {
              if (nodeData.fill?.fillColor) {
                if (!temp.includes(nodeData.fill.fillColor.toString())) {
                  temp.push(nodeData.fill.fillColor.toString());
                  this.setState({ colorOptions: temp });
                }
              }
            });
          } else {
            this.setState({ activeFillColor: nodeData?.text?.style?.fill || nodeData.fill?.fillColor }, () => {
              if (nodeData.fill?.fillColor) {
                if (!temp.includes(nodeData.fill.fillColor.toString())) {
                  temp.push(nodeData.fill.fillColor.toString());
                  this.setState({ colorOptions: temp });
                }
              }
              if (nodeData?.text?.style?.fill) {
                if (!temp.includes(nodeData?.text?.style?.fill.toString())) {
                  temp.push(nodeData?.text?.style?.fill.toString());
                  this.setState({ colorOptions: temp });
                }
              }
            });
          }
        }
      }
    }
    return undefined;
  }

  componentDidUpdate(
    prevProps: Readonly<ColorsOptionProps & RouteComponentProps<PathParamsType, StaticContext, unknown>>,
    prevState: any,
  ): void {
    const { activeTextProperties, app } = this.props;
    const { showMultiColorIcon, activeFillColor } = this.state;
    const nodeData = app.selectedNodes[0]?.nodeData;
    if (nodeData && nodeData.nodeType === ENodeType.TEXT) {
      if (app.editText) {
        if (activeTextProperties.length === 1 && prevProps.activeTextProperties.length === 1) {
          const { foreColor: prevColor } = prevProps.activeTextProperties[0];
          if (showMultiColorIcon) {
            this.setState({ showMultiColorIcon: false });
          }
          const { foreColor } = activeTextProperties[0];
          if (foreColor && (foreColor !== prevState.activeFillColor)) {
            this.setState({ activeFillColor: foreColor });
          } else if (!foreColor && (foreColor !== prevColor)) {
            this.setState({ activeFillColor: nodeData?.text?.style?.fill });
          }
        } else if (activeTextProperties.length > 0) {
          if (activeTextProperties.some((d: any) => d.foreColor && (d.foreColor !== '#000000')) && !showMultiColorIcon) {
            this.setState({ showMultiColorIcon: true });
          } else if (prevState.activeFillColor !== nodeData?.text?.style?.fill) {
            this.setState({ activeFillColor: nodeData?.text?.style?.fill });
          }
        }
      } else if (showMultiColorIcon !== prevState.showMultiColorIcon || activeFillColor !== prevState.activeFillColor) {
        const allColors = getColorProperties(nodeData?.text?.value as string);
        const existingColor = nodeData?.text?.style?.fill || '#000000';
        if (allColors && allColors?.some((c) => c !== (existingColor as string).toLowerCase())) {
          this.setState({ showMultiColorIcon: true });
        } else {
          this.setState({ activeFillColor: nodeData?.text?.style?.fill });
        }
      } else if (prevState.activeFillColor !== nodeData?.text?.style?.fill) {
        this.setState({ activeFillColor: nodeData?.text?.style?.fill });
      }
    } else if (prevState.activeFillColor !== nodeData?.fill?.fillColor) {
      this.setState({ activeFillColor: nodeData?.fill?.fillColor });
    }
  }

  async componentWillUnmount() {
    const { projectId, colorOptions } = this.state;
    if (projectId) {
      const localColors = JSON.parse(await localforage.getItem(`${projectId}-fill`) as string);
      const localColorsLength = localColors ? localColors.length : 0;
      if (localColorsLength < colorOptions.length) {
        localforage.setItem(`${projectId}-fill`, JSON.stringify(colorOptions));
      }
    }
  }

  RGBToHex = (r: any, g: any, b: any) => {
    let red = parseInt(r, 10).toString(16);
    let green = parseInt(g, 10).toString(16);
    let blue = parseInt(b, 10).toString(16);
    console.log(this.RGBToHex);
    if (red.length === 1) red = `0${red}`;
    if (green.length === 1) green = `0${green}`;
    if (b.length === 1) blue = `0${blue}`;
    return `#${red}${green}${blue}`;
  };

  /**
   * Toggle color picker
   */
  toggleColorPicker = async () => {
    const { app } = this.props;
    const { colorOptions, showColorPicker, colorPicked } = this.state;
    if (!showColorPicker) {
      this.setState({ colorPicked: '#FF0000', hexValue: '#FF0000' });
      app.isColourPickerOpen = true;
      const tempColors: string[] = colorOptions;
      let c: number = 0;
      for (let i = 0; i < tempColors.length; i++) {
        if (tempColors[i] === '#FF0000') {
          c++;
        }
      }
      if (c <= 1) {
        tempColors.push('#FF0000');
        this.setState({ colorOptions: tempColors });
        const allNodes = app.selectedNodes;
        // looping over all the selected nodes to edit the nodeData in BaseNode
        for (let i = 0; i < allNodes.length; i++) {
          app.selectedNodes[i]?.edit('FILL', '#FF0000', false);
        }
        // saving all the edited nodes data to db
        app.saveAllNodes([]);
      }
      this.setState({
        showColorPicker: !showColorPicker,
        activeFillColor: colorPicked,
        activeBkgColor: colorPicked,
      });
    } else {
      app.isColourPickerOpen = false;
      this.removeDuplicateColors();
      this.setState({ showColorPicker: !showColorPicker, colorPicked: '#FF0000' });
    }
  };

  /**
   * handles color change - color picker
   * @param color
   */
  handleChange = (color: any, e: React.ChangeEvent) => {
    const { colorOptions } = this.state;
    const { setCanvasBkgColor } = this.props;
    const tempColors: string[] = colorOptions;
    if (!tempColors.includes(color.hex)) {
      tempColors[tempColors.length - 1] = color.hex;
      this.setState({ colorOptions: tempColors });
    }
    this.setState({
      colorPicked: color.hex,
      activeFillColor: color.hex,
      hexValue: color.hex,
      activeBkgColor: color.hex,
    });
    if (setCanvasBkgColor) {
      return setCanvasBkgColor(color.hex);
    }

    this.setFillColorForAllNodes(color.hex);
    e.stopPropagation();
    return undefined;
  };

  /**
   * Remove duplicate color values
   */
  removeDuplicateColors = () => {
    const { colorOptions } = this.state;
    const arr: string[] = colorOptions.filter((item, index, inputArray) => inputArray.indexOf(item) === index);
    this.setState({ colorOptions: arr });
  };

  useEyeDropper = () => {
    const { colorOptions } = this.state;
    const { setCanvasBkgColor } = this.props;
    const arr: string[] = colorOptions.filter((item, index, inputArray) => inputArray.indexOf(item) === index);
    this.setState({ eyeDropperActive: true });
    const eyeDropper = new (window as any).EyeDropper();
    let hexDecimalColor : string;
    eyeDropper.open().then((result: any) => {
      if (result.sRGBHex[0] !== '#') {
        const RGBValues = result.sRGBHex.slice(4, result.sRGBHex.length - 1).split(',');
        hexDecimalColor = this.RGBToHex(RGBValues[0], RGBValues[1], RGBValues[2]).toUpperCase();
      } else hexDecimalColor = result.sRGBHex;
      const tempColors: string[] = arr;
      if (!tempColors.includes(hexDecimalColor)) {
        tempColors.push(hexDecimalColor);
      }
      this.setState({
        activeFillColor: hexDecimalColor,
        eyeDropperActive: false,
        hexValue: hexDecimalColor,
        colorPicked: hexDecimalColor,
        colorOptions: tempColors,
        activeBkgColor: hexDecimalColor,
      });

      if (setCanvasBkgColor) {
        return setCanvasBkgColor(hexDecimalColor);
      }

      this.setFillColorForAllNodes(hexDecimalColor);
      return undefined;
    }).catch((e: any) => {
      console.log(e);
    });
  };

  getActiveColorClassName = (color: string, index: number) => {
    const { activeFillColor, colorOptions } = this.state;
    const newArr = colorOptions.filter((colour) => colour === color);
    if (activeFillColor === '#FF0000' && color === '#FF0000') {
      if (newArr.length > 1) {
        if (index === (colorOptions.length - 1)) return 'color-container active-color';
      } else if (newArr.length === 1 && color === '#FF0000') {
        return 'color-container active-color';
      }
    }
    if (color !== '#FF0000' && color === activeFillColor) {
      return 'color-container active-color';
    }
    return 'color-container';
  };

  handleHexInput = (e: any) => {
    const { colorOptions } = this.state;
    const { setCanvasBkgColor } = this.props;
    this.setState({ hexValue: e.target.value });
    if (e.target.value.length <= 6 && e.target.value.length >= 3) {
      this.setState({ colorPicked: `#${e.target.value}` });
      // if (app.editText && textNodeInput) {console.log("in if textNodeInput ")
      //   this.setState({ activeFillColor: `#${e.target.value}` });
      //   return textNodeInput({ fill: `#${e.target.value}` });
      // }else console.log("in else textNodeInput ")
      if (setCanvasBkgColor) {
        this.setState({ activeBkgColor: `#${e.target.value}` });
        return setCanvasBkgColor(`#${e.target.value}`);
      }
      const colorArray: string[] = colorOptions;
      if (!colorArray.includes(`#${e.target.value}`)) {
        colorArray[colorArray.length - 1] = `#${e.target.value}`;
        this.setState({
          colorOptions: colorArray,
        });
      }
      this.setState({ activeFillColor: `#${e.target.value}`, multiColorSelect: [], activeBkgColor: `#${e.target.value}` });
      this.setFillColorForAllNodes(`#${e.target.value}`);
    }
    return undefined;
  };

  /**
   * Function to check if node has Editable Fill and Stroke
   * @returns boolean
   */
  checkIfNodeHasFill = () => {
    const { app } = this.props;
    const node = app.selectedNodes[0]?.nodeData;

    if (node?.nodeType === ENodeType.SHAPE) {
      const shape = node.shapeType;
      if (shape === 'SOLID_LINE' || shape === 'DASHED_LINE' || shape === 'ARROW') return false;
    }
    return true;
  };

  /**
   * Function to check if node opacity is allowed or not
   * @returns boolean
   */
  checkIfNodeHasOpacity = () => {
    const { app } = this.props;
    const nodeType = app.selectedNodes[0]?.nodeData.nodeType;

    return nodeType === ENodeType.SHAPE
    || nodeType === ENodeType.IMAGE
    || nodeType === ENodeType.SAMPLE_IMAGE
    || nodeType === ENodeType.ONBOARDING_IMAGE
    || nodeType === ENodeType.MARKUP
    || nodeType === ENodeType.TEXT;
  };

  /**
   * render all the color options
   * @returns
   */
  renderColorOptions = () => {
    const {
      app, selectedOption, setSelectedOption, setCanvasBkgColor,
    } = this.props;

    const {
      colorOptions,
      // activeFillColor,
      showEyedropper,
      eyeDropperActive, showColorPicker, colorPicked, hexValue,
    } = this.state;

    return (
      <>

        {
          this.checkIfNodeHasOpacity()
          && (
          <>
            <Opacity
              app={app}
              setSelectedOption={setSelectedOption}
              selectedOption={selectedOption}
              isStroke={false}
              isFill={this.checkIfNodeHasFill()}
            />
            <hr className="divider-horizontal" />
          </>
          )
        }

        {' '}
        {colorOptions.map((color, index: number) => (
          <div
            role="presentation"
            // eslint-disable-next-line react/no-array-index-key
            key={color + index}
            className={this.getActiveColorClassName(color, index)}
            onClick={() => {
              // if (textNodeInput && app.editText) {
              //   this.setState({ activeFillColor: color });
              //   return textNodeInput({ fill: color });
              // }
              if (setCanvasBkgColor) {
                this.setState({ activeBkgColor: color });
                return setCanvasBkgColor(color);
              }
              this.setState({
                activeFillColor: color,
                activeBkgColor: color,
                multiColorSelect: [],
                hexValue: color,
                colorPicked: color,
              });
              this.setFillColorForAllNodes(color);
              return undefined;
            }}
          >
            <div style={{ backgroundColor: color }} className="color" />
          </div>
        ))}
        {
          showEyedropper && (
            <div
              role="presentation"
              className={eyeDropperActive ? 'color-container active-eyedrop' : 'color-container'}
              onClick={this.useEyeDropper}
            >
              <img className="color" src={eyeDropIcon} alt="eye-drop-icon" style={{ borderRadius: 0 }} />
            </div>
          )
        }
        <div
          id="color-picker"
          className="color-container"
          role="presentation"
          onClick={this.toggleColorPicker}
          style={{ background: showColorPicker ? '#EDEDED' : '', borderRadius: '4px' }}
        >
          <img className="color" src={addIcon} alt="add-icon" />
        </div>
        {
          showColorPicker && (
            <div className="chrome-picker-wrapper">
              <SketchPicker
                color={colorPicked}
                onChangeComplete={this.handleChange}
              />
              <div className="hex-input-container">
                <div className={`eye-drop-container ${eyeDropperActive ? 'active' : ''}`}>
                  <img
                    src={eyeDropIcon}
                    alt="eye-drop-icon"
                    role="presentation"
                    onClick={this.useEyeDropper}
                    style={{ width: '15px', height: '15px' }}
                  />
                </div>
                <div className="input-container">
                  <span>
                    #
                  </span>
                  <input
                    type="text"
                    value={`${hexValue[0] === '#' ? hexValue.slice(1) : hexValue}`}
                    onChange={this.handleHexInput}
                    placeholder="Enter hex code"
                    maxLength={6}
                  />
                </div>
              </div>
            </div>
          )
        }
      </>
    );
  };

  getFillColor = () => {
    const { activeFillColor } = this.state;
    return activeFillColor || '#FF0000';
  };

  getCanvasColor = () => {
    const { activeBkgColor } = this.state;
    return activeBkgColor || '#FF0000';
  };

  setFillColorForAllNodes = async (color: string) => {
    const { app } = this.props;
    const allNodes = app.selectedNodes;
    // looping over all the selected nodes to edit the nodeData in BaseNode
    for (let i = 0; i < allNodes.length; i++) {
      app.selectedNodes[i]?.edit('FILL', color, !(allNodes.length > 1 && i !== 0));
    }
    // saving all the edited nodes data to db
    app.saveAllNodes([]);
  };

  getStyle = () => {
    const style: any = {};
    const { multiColorSelect } = this.state;
    if (multiColorSelect.length > 1) {
      style.background = `linear-gradient( -45deg, 
                          ${multiColorSelect[0]}, 
                          ${multiColorSelect[0]} 49%, white 49%, white 51%, 
                          ${multiColorSelect[1]} 51% )
                          `;
    } else {
      style.backgroundColor = this.getFillColor();
    }
    return style;
  };

  renderColorIcon = () => {
    const { setCanvasBkgColor } = this.props;
    const { showMultiColorIcon, multiColorSelect } = this.state;
    if (showMultiColorIcon) {
      return <div className="flex-container"><MulticolorIcon /></div>;
    } if (setCanvasBkgColor) {
      return (
        <div
          className="fill-option-circle"
          style={multiColorSelect.length > 0 ? this.getStyle() : { backgroundColor: this.getCanvasColor() }}
        />
      );
    }
    return (
      <div
        className="fill-option-circle"
        style={multiColorSelect.length > 0 ? this.getStyle() : { backgroundColor: this.getFillColor() }}
      />
    );
  };

  render() {
    let style = {};
    const {
      parentContainer, selectedOption, setSelectedOption,
    } = this.props;
    const { showColorPicker } = this.state;
    if (parentContainer && window.innerHeight / parentContainer.getBoundingClientRect().y < 2) {
      style = {
        marginTop: `-${parentContainer.getBoundingClientRect().height - 40}px`,
        // transform: 'translateY(-100%)',
      };
    }
    return (
      <>
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 1000, hide: 0 }}
          overlay={(
            <Tooltip
              id="button-tooltip-2"
              style={{
                fontSize: '12px',
                borderRadius: '2px',
                lineHeight: '16px',
                marginTop: '10px',
              }}
            >
              Color
            </Tooltip>
          )}
        >
          <div
            role="presentation"
            className="icon-container noselect"
            style={{ background: selectedOption === 'FILL' ? '#EDEDED' : 'transparent' }}
            onClick={(e: any) => {
              e.stopPropagation();
              this.removeDuplicateColors();
              if (selectedOption === 'FILL') {
                setSelectedOption('');
                this.setState({ showColorPicker: false, colorPicked: '#FF0000' });
              } else {
                setSelectedOption('FILL');
              }
              if (showColorPicker) {
                this.toggleColorPicker();
              }
            }}
          >
            <div style={{ width: '40px', display: 'flex', justifyContent: 'center' }}>
              {this.renderColorIcon()}
            </div>
          </div>
        </OverlayTrigger>
        {
          selectedOption === 'FILL' && (
            <div className="sub-option-container noselect" style={{ ...style, minWidth: '152px' }}>
              {this.renderColorOptions()}
            </div>
          )
        }
      </>
    );
  }
}

/**
 * Redux state mapped to canvas props
 * @param state
 * @returns
 */
// @ts-ignore
const mapStateToProps = () => ({
});

/**
 * Redux actions mapped to canvas props
 * @param dispatch
 * @returns
 */
// @ts-ignore
const mapDispatchToProps = () => ({
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ColorsOption));
