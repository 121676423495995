import { useEffect } from "react"
import { useHistory } from "react-router"

// sets up a listener for URL changes (handled by react router)
// pushes to google tag as that happens
const useTracking = (
  trackingId: string | undefined
) => {
  
  const { listen } = useHistory()

  useEffect(() => {
    const unlisten = listen((location: { pathname: string }) => {
      if (!(window as any).gtag)
        return

      if (!trackingId) {
        console.log("GTag disabled, no id provided")
        return
      };

      (window as any).gtag('config', trackingId, {page_path: location.pathname})
    })

    return unlisten
  }, [trackingId, listen])
}

export default useTracking;