/* eslint-disable max-len */
import { EBlockType } from '@naya_studio/types';
import { TI2RData } from './i2rData';

const toothbrush: TI2RData = {
	text: `{"root":{"children":[{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":"Design a wavy and colorful slim wood ergonomic toothbrush with square edges and no branding","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}`,
	projectTitle: 'Example - Toothbrush',
	groups: [
		{
			name: 'Example - Toothbrush Inspiration',
			blocks: [
				{
					name: 'Nest All-In-One Toothbrush',
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/toothbrush/mx0pcuvhtho9hjloynsj',
					blockType: EBlockType.IMAGE
				},
				{
					name: 'Bogobrush bamboo toothbrush',
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/toothbrush/j0yqmyhczxtxxno0dicq',
					blockType: EBlockType.IMAGE
				},
				{
					name: 'Landor & Fitch Toothbrush',
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/toothbrush/yff0r3vkkt6xsppk5qkb',
					blockType: EBlockType.IMAGE
				},
				{
					name: 'Sensodyne Toothbrush',
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/toothbrush/d3g6chae8wk1dcfgvewa',
					blockType: EBlockType.IMAGE
				}
			]
		},
		{
			name: 'Example - Toothbrush Form Inspiration',
			blocks: [
				{
					name: 'Indentation Handle',
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/toothbrush/uy877wgwgitbn8zoaxjp',
					blockType: EBlockType.IMAGE
				},
				{
					name: 'Round Handle',
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/toothbrush/ogjqkwd4le7mx9kzao5i',
					blockType: EBlockType.IMAGE
				},
				{
					name: 'Squiggle Handle',
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/toothbrush/hiqlbmwyqhpe3ymqjz8b',
					blockType: EBlockType.IMAGE
				}
			]
		}
	]
};

export default toothbrush;
