import { IAction } from '../root.types'
import { UploadingStatusTypes } from '../root.types'

// collab.naya.studio/project/:id

export interface IUploadingStatus {
  text:string,
  type:'LOADER'|'NORMAL'|'ERROR',
  showActionButton?:boolean,
  actionButtonText?:string,
  loaderColor?: string,
  firebaseUploadTask?: any
}

const initialOpenComment:IUploadingStatus = {
  text: '',
  type: 'NORMAL',
  showActionButton: false
}

const uploadingStatusReducer = (state = initialOpenComment, action: IAction<UploadingStatusTypes | keyof typeof UploadingStatusTypes>) => {

  let newState: IUploadingStatus
  const uplaodingStatus: IUploadingStatus = action.payload

  switch (action.type) {
    case 'SET_SNACKBAR_PROPS':
      newState = {...uplaodingStatus}
      break
    default:
      newState = state
      break
  }

  return newState
}

export default uploadingStatusReducer