import axios from 'axios';
import { ESTIMATE_ENDPOINT } from 'src/endpoints/api';
import { prepEstimateDisplay } from 'src/components/homebase/estimate/AI/EstimateAIForm';
import {
  AxiosResponseWrapper, ThunkDispatchWrapper,
} from './types';
import { EstimationAITypes } from '../reducers/root.types';
import { EstimateAIData } from '../reducers/estimationAI/estimationAI';

export type EstimationPayload = {
  category: string[];
  dimensions: number[];
  materials: string[];
  quantity: number;
};

/**
 *
 * @param payload Display prediction using EstimationAI tool
 * @returns
 */
export const DisplayPrediction = (payload: EstimationPayload) => async (dispatch: ThunkDispatchWrapper) => {
  try {
    // create stage first
    const res = await axios.post<AxiosResponseWrapper<EstimateAIData>>(`${ESTIMATE_ENDPOINT}`, { payload });
    // need to handle errror
    if (res.status !== 200) throw new Error('Error creating the prediction');

    const { predictedPrice, minPrice, maxPrice } = res.data.response as any;

    dispatch({
      type: EstimationAITypes.DISPLAY_PREDICTION,
      payload: {
        predictedPrice: prepEstimateDisplay(predictedPrice),
        minPrice: prepEstimateDisplay(minPrice),
        maxPrice: prepEstimateDisplay(maxPrice),
      },
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: EstimationAITypes.EDIT_PREDICTION,
      payload: {
        predictedPrice: 0,
        minPrice: 0,
        maxPrice: 0,
        isEstimationLoading: false,
      },
    });
  }
};

export const CancelPrediction = () => async (dispatch: ThunkDispatchWrapper) => {
  try {
    dispatch({
      type: EstimationAITypes.EDIT_PREDICTION,
      payload: {
        predictedPrice: 0,
        minPrice: 0,
        maxPrice: 0,
        isEstimationLoading: false,
      },
    });
  } catch (e) {
    console.log(e);
  }
};

export const toggleEstimateSnackBar = (showSnackBar: boolean) => async (dispatch: ThunkDispatchWrapper) => {
  dispatch({
    type: EstimationAITypes.EDIT_PREDICTION,
    payload: {
      isEstimationLoading: showSnackBar,
    },
  });
};
