import { TSearchQuery } from '@naya_studio/radix-ui';
import { Collaborator, CustomProject, IUser } from '@naya_studio/types';
import Fuse from 'fuse.js';

/**
 * check if the project matched a particular filter
 * @param searchQueryKeys
 * @param query
 * @param project
 * @returns
 */
const checkIfProjectMatches = (
	searchQueryKeys: string[],
	query: TSearchQuery,
	project: CustomProject
) => {
	/**
	 * Fuzzy search
	 * eg: query: 'Block' will match blockName: 'blck'
	 */
	const fuzzy =
		query.text &&
		new Fuse([project.projectName], { threshold: 0.3, includeScore: true }).search(query.text);

	const isMatched = searchQueryKeys.every((key) => {
		switch (key) {
			case 'text': {
				return query.text && fuzzy && fuzzy.length > 0;
			}
			case 'types': {
				if (
					query.types?.length &&
					query.types?.length === 1 &&
					query.types.includes('FAVORITE')
				) {
					return project.isPinned;
				}
				return false;
			}
			case 'sharedWith': {
				return (
					query.sharedWith &&
					project?.collaborators &&
					!!project.collaborators.find(
						(user) =>
							((user as Collaborator).id as string) ===
							(query.sharedWith as Partial<IUser>)._id
					)
				);
			}
			case 'createdBy': {
				return (
					query.createdBy &&
					(project.createdBy as Partial<IUser>)?._id === query[key]?._id
				);
			}
			default:
				return true;
		}
	});

	return { isMatched };
};

/**
 * Filter project data according to the search filter query
 * @param query
 * @param projects
 * @returns
 */
const filterProjectData = (
	query: TSearchQuery,
	projects: CustomProject[],
	pinnedProjects: CustomProject[]
) => {
	try {
		let projectsTofilter = projects;
		let filteredProjects: CustomProject[] = [];

		if (query?.types?.includes('FAVORITE')) {
			projectsTofilter = [...projects, ...pinnedProjects];
		}

		// Keys of the query object
		const searchQueryKeys = Object.keys(query);
		projectsTofilter.forEach((project) => {
			const matchedResult = checkIfProjectMatches(searchQueryKeys, query, project);
			if (matchedResult.isMatched) {
				filteredProjects.push(project);
			}
		});
		if (query.lastUpdated) {
			filteredProjects = filteredProjects.sort(
				(a, b) => new Date(a.lastUpdatedAt).getTime() - new Date(b.lastUpdatedAt).getTime()
			);
		}

		return filteredProjects;
	} catch (error) {
		return undefined;
	}
};

export default filterProjectData;
