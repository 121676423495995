import React, { Dispatch } from 'react';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import BaseNode from 'src/components/canvas/app/nodes/BaseNode';
import { ReduxState } from 'src/redux/reducers/root.types';
import './Order.scss';
import { OrderOptionDispatchProps, OrderOptionProps, PathParamsType } from './Order.types';

class OrderOption extends React.Component<OrderOptionProps & OrderOptionDispatchProps & RouteComponentProps<PathParamsType>> {
  render() {
    return (
      <Dropdown
        id="order-dropdown-basic-button"
        show={this.props.selectedOption === 'ORDER'}
      >

        <div>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 1000, hide: 0 }}
            overlay={(
              <Tooltip
                id="button-tooltip-2"
                style={{
                  fontSize: '12px',
                  borderRadius: '2px',
                  lineHeight: '16px',
                  marginTop: '4px',
                }}
              >
                Order
              </Tooltip>
          )}
          >
            {({ ref, ...triggerHandler }) => (
              <Dropdown.Toggle
                id="View-basic-button"
                variant="success"
                ref={ref}
                {...triggerHandler}
                onClick={() => {
                  if (this.props.selectedOption === 'ORDER') {
                    this.props.setSelectedOption('');
                  } else this.props.setSelectedOption('ORDER');
                }}
              >
                Order
              </Dropdown.Toggle>
            )}
          </OverlayTrigger>

          <Dropdown.Menu>

            <Dropdown.Item
              onClick={(e) => {
                e.preventDefault();
                const selectedNode = this.props.app.selectedNodes[0]!;
                const zIndex = this.props.app.getNewZindex(selectedNode, false, false);
                selectedNode.edit('ZINDEX', zIndex, false);
                this.props.app.render();
                this.props.setSelectedOption('');
              }}
            >
              Bring Forward
            </Dropdown.Item>

            <Dropdown.Item
              onClick={(e) => {
                e.preventDefault();
                const allNodes = this.props.app.selectedNodes;
                allNodes.forEach((node: BaseNode, i: number) => {
                  const max = this.props.app.getNewZindex(node, false, true);
                  node?.edit('ZINDEX', max, !!(allNodes.length > 1 && i !== 0));
                });
                this.props.app.render();
                this.props.setSelectedOption('');
              }}
            >
              {' '}
              Bring to Front
            </Dropdown.Item>

            <Dropdown.Item
              onClick={(e) => {
                e.preventDefault();
                const selectedNode = this.props.app.selectedNodes[0]!;
                const zIndex = this.props.app.getNewZindex(selectedNode, true, false);
                selectedNode.edit('ZINDEX', zIndex, false);
                this.props.app.render();
                this.props.setSelectedOption('');
              }}
            >
              {' '}
              Send Backward
            </Dropdown.Item>

            <Dropdown.Item onClick={(e) => {
              e.preventDefault();
              const allNodes = this.props.app.selectedNodes;
              console.log('send back', allNodes);
              allNodes.forEach((node: BaseNode, i: number) => {
                const min = this.props.app.getNewZindex(node, true, true);
                console.log('min zindex', min);
                node?.edit('ZINDEX', min, !!(allNodes.length > 1 && i !== 0));
              });
              this.props.app.render();
              this.props.setSelectedOption('');
            }}
            >
              Send to Back
            </Dropdown.Item>

          </Dropdown.Menu>
        </div>

      </Dropdown>
    );
  }
}

/**
 * Redux state mapped to canvas props
 * @param state
 * @returns
 */
// @ts-ignore
const mapStateToProps = (state: ReduxState) => ({
});

/**
 * Redux actions mapped to canvas props
 * @param dispatch
 * @returns
 */
// @ts-ignore
const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderOption));
