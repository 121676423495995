import { MouseEvent, useContext, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import './App.scss';
import { IUser } from '@naya_studio/types';
import * as Sentry from '@sentry/react';
import { CollaborationTool } from './components/collaborationTool/CollaborationTool';
import NavBar from './components/utilities/navbar/LandingPageNavbar';
import LandingPageLayout from './components/landingPage/landingPageLayout';
import WhyNaya from './components/landingPage/whyNaya/WhyNaya';
import GetInTouch from './components/landingPage/getInTouch/GetInTouch';
import PartnerPopup from './components/landingPage/utilities/partnerPopup/PartnerPopup';
import { SideBarProps } from './components/landingPage/side-nav/SideNav';
import PrivacyPolicy from './components/privacyPolicy/PrivacyPolicy';
import HomebaseProject from './components/homebase/project/HomebaseProject';
import HomebaseTransaction from './components/homebase/transaction/HomebaseTransaction';
import Unsubscribe from './components/unsubscribe';
import Register from './components/register/Register';
import { AuthContext } from './components/auth/AuthProvider';
import LoginComponent from './components/login/Login';
import ScheduleDemoComponent from './components/scheduleDemo/ScheduleDemo';
import UserProfile from './components/homebase/profile/userProfile/UserProfile';
import ProtectedRoute from './components/utilities/auth/ProtectedRoute';
import AppIntegrationModal from './components/appIntegrations/AppIntegrationModal';
import CountDown from './components/landingPage/countDown/CountDown';
import Error from './components/error/Error';

export type AppProps = {
	user: IUser;
	getUserWithCookies: () => void;
	loadUserGroupByUserId: (user: string, next?: () => void) => void;
	loading: boolean;
};

const App = () => {
	const { status, completedAuthCheck, hasGuestAccessTo } = useContext(AuthContext);

	// Used to show/hide hamburger in small screen
	const [isShowHamburgerMenu, setShowHamburgerMenu] = useState(false);
	// used for landing Page
	const [showPartnerPopup, setShowPartnerPopup] = useState(false);

	/**
	 * Function to toggle nav bar between expanded state and collapsed state
	 */
	const toggleHamburgerMenu = () => {
		setShowHamburgerMenu((prev) => !prev);
	};

	const sidebarProps: SideBarProps = {
		expanded: isShowHamburgerMenu,
		toggleHamburgerMenu,
		showPartnerPopup: () => setShowPartnerPopup(true)
	};

	return (
		<>
			{!window.location.pathname.includes('/project') &&
				!window.location.pathname.includes('/studio') && (
					<NavBar
						toggleHamburgerMenu={(e: MouseEvent) => {
							e.preventDefault();
							e.stopPropagation();
							toggleHamburgerMenu();
						}}
					/>
				)}
			<PartnerPopup show={showPartnerPopup} close={() => setShowPartnerPopup(false)} />
			<AppIntegrationModal />
			<Switch>
				{/* APP ROUTES */}
				{/* Route to Expanded block */}
				<Route path="/error">
					<Error />
				</Route>
				<Route
					path="/project/:projectId?/:stageOrDashboard?/:canvasId?"
					render={() => {
						const url = encodeURIComponent(window.location.pathname);
						// @ts-ignore
						return status === 'SIGNED_OUT' &&
							completedAuthCheck &&
							!hasGuestAccessTo ? (
							<Redirect to={`/login/${url}`} />
						) : (
							<CollaborationTool />
						);
					}}
				/>
				{/* Route to projects */}
				<ProtectedRoute exact path="/studio">
					<HomebaseProject />
				</ProtectedRoute>

				{/* AUTH ROUTES */}
				{/* Link based login page route with redirect url after successful login */}
				<Route path="/login/:redirectTo?">
					<LoginComponent type="LOGIN" />
				</Route>
				{/* Password based login page route with redirect url after successful login */}
				<Route path="/login-pwd/:redirectTo?">
					<LoginComponent type="LOGIN-PWD" />
				</Route>
				{/* With out password register page route */}
				<Route exact path="/register">
					<Register type="REGISTER" />
				</Route>
				{/* OTHER ROUTES */}
				{/* User profile page route */}
				<ProtectedRoute exact path="/profile">
					<UserProfile />
				</ProtectedRoute>
				{/* Route to unsubscribe from mails related to project */}
				<Route exact path="/account/:projectId?/unsubscribe">
					<Unsubscribe />
				</Route>
				{/* Route to payment page */}
				<Route exact path="/payment/:extension?">
					<HomebaseTransaction />
				</Route>
				{/* Route to privacy policy page */}
				<Route exact path="/privacy-policy">
					<PrivacyPolicy />
				</Route>
				{/* Route to schedule demo */}
				<Route exact path="/scheduleDemo">
					<ScheduleDemoComponent />
				</Route>
				<Route exact path="/counter">
					<CountDown />
				</Route>
				{/* LADING PAGE ROUTES */}
				{/* Lading page with WhyNaya side page */}
				<Route exact path="/why-naya">
					<LandingPageLayout {...sidebarProps} mainContent={<WhyNaya />} />
				</Route>
				{/* Lading page with GetInTouch side page */}
				<Route exact path="/get-in-touch">
					<LandingPageLayout {...sidebarProps} mainContent={<GetInTouch />} />
				</Route>
				{/* Lading page with GetStarted side page */}
				<Route path="/">
					{/* 					
						<LandingPageLayout
						{...sidebarProps}
						mainContent={<GetStarted {...sidebarProps} />}
					/> */}
					<HomebaseProject />
				</Route>
			</Switch>
		</>
	);
};

export default Sentry.withProfiler(App);
