/* eslint-disable prefer-destructuring */
import { FC, useState, useEffect, KeyboardEvent, useRef } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { INode, ENodeType } from '@naya_studio/types';
import "./Opacity.scss";
import BaseNode from 'src/components/canvas/app/nodes/BaseNode';
import opacity from '../../../../../assets/icons/edit-menu/opacity.svg';
import { OpacityOptionProps } from './Opacity.types';


/**
...
...
Wireframes:
https://www.figma.com/file/qeY1lU4bE4yjfduIa2uCz9/Transparency - 22/11/2022
...
...
**/
const Opacity: FC<OpacityOptionProps> = ({app, selectedOption ,setSelectedOption, isStroke, isFill}) => {
  const inputRef = useRef(null);
  let selectedNodesCopy = app.selectedNodes as BaseNode[];

  /**
   * Function to get relative opacity to all selected nodes
   * @returns Relative Opacity
   */
  const getOpacityRelativeToAllNodes = () => {
    const allNodes = app.selectedNodes as BaseNode[];
    const nodeOpacities = {} as {[key: number]: 1};
    for(let i = 0; i < allNodes.length; i++) {
      const node = allNodes[i] as BaseNode;

      if(isFill && node.nodeData.fill?.fillOpacity) {
        nodeOpacities[node.nodeData.fill.fillOpacity] = 1;
      }
      else if(isStroke && node.nodeData.stroke?.strokeOpacity) {
        nodeOpacities[node.nodeData.stroke.strokeOpacity] = 1;
      }
      else {
        nodeOpacities[node.nodeData.opacity || 0] = 1;
      }
    }

    const allOpacities = Object.keys(nodeOpacities);
    if(allOpacities.length === 1 && allOpacities[0]) {
      // return the only opacity i.e. all selected nodes have same opacity
      return allOpacities[0] as unknown as number;
    };

    return -1;
  }
  
  let initOpacity: number | string = 'Mixed';
  if(app.selectedNodes.length === 1) {
    const nodeData = app.selectedNodes[0]?.nodeData as INode;
    const nodeType = nodeData.nodeType as ENodeType;

    if(nodeType === ENodeType.SHAPE){
      if(isFill) initOpacity = (nodeData.fill?.fillOpacity !== undefined ? nodeData.fill.fillOpacity : 1);
      else if(isStroke) initOpacity = (nodeData.stroke?.strokeOpacity !== undefined ? nodeData.stroke.strokeOpacity : 1);
      else initOpacity = (nodeData?.opacity !== undefined ? nodeData.opacity : 1);
    }
    else{
      initOpacity = (app.selectedNodes[0]?.nodeData.opacity !== undefined ? app.selectedNodes[0]?.nodeData.opacity : 1);
    }
  };

  const [nodeOpacity, setNodeOpacity] = useState(typeof initOpacity === 'string' ? initOpacity : `${initOpacity * 100}%`);
  // For nodeType'IMAGE' : State to show the opacity input only when the user clicks on the Opacity Edit Menu Option
  const [isActive, setIsActive] = useState(false);

  /**
   * Function to round off decimals to nearest int, 
   * and to revert values > 100 to 100
   * and revert negative values to 0
   */
  const filterOpacityValue = (value:string) => {
    return Math.max(Math.min(Math.round(+value) / 100, 1), 0);
  }

  /**
   * Function to send edit call respective to the node
   */
  const updateAllSelectedNodes = () => {
    try{
      const filteredOpacity = filterOpacityValue(nodeOpacity);
      const currentNode = app.selectedNodes.length ? app.selectedNodes : selectedNodesCopy;
      for (let i = 0; i < currentNode.length; i++) {
        if(currentNode[i]?.nodeData.nodeType !== ENodeType.SHAPE) {
          currentNode[i]?.edit('OPACITY', filteredOpacity, !!(currentNode.length > 1 && i !== 0));
        }
        else {
          if(isFill){
            currentNode[i]?.edit('FILL_OPACITY', filteredOpacity, !!(currentNode.length > 1 && i !== 0));
          }
          else if(isStroke){
            currentNode[i]?.edit('STROKE_OPACITY', filteredOpacity, !!(currentNode.length > 1 && i !== 0));
          }
          else{
            currentNode[i]?.edit('OPACITY', filteredOpacity, !!(currentNode.length > 1 && i !== 0));
          }
        }
      }

      app.saveAllNodes([]);
    }
    catch(error){
      console.error(error);
    }
  }


  /**
   * Function to toggle Opacity Input Bar
   */
  const toggleIsActive = () => {
    setIsActive(prevIsActive=>!prevIsActive);
  }

  /**
   * Function to handle submit event
   */
  const applyOpacity = () => {
    if(!Number.isNaN(+nodeOpacity)){
      updateAllSelectedNodes();
    }

    if(app.selectedNodes.length) {
      if(app.selectedNodes.length > 1) {
        const relativeOpacity = getOpacityRelativeToAllNodes() as number;
        if(relativeOpacity !== -1) {
          setNodeOpacity(`${parseInt(`${relativeOpacity * 100}`, 10)}%`)
        }
        else {
          setNodeOpacity('Mixed');
        }
      }
      else {
        const nodeData = app.selectedNodes[0]?.nodeData as INode;
        const nodeType = nodeData.nodeType as ENodeType;

        if(nodeType === ENodeType.SHAPE){
          if(isFill && nodeData?.fill?.fillOpacity !== undefined){
            setNodeOpacity(`${parseInt(`${nodeData.fill.fillOpacity * 100}`, 10)}%`);
          }
          else if(isStroke && nodeData?.stroke?.strokeOpacity !== undefined){
            setNodeOpacity(`${parseInt(`${nodeData.stroke.strokeOpacity * 100}`, 10)}%`);
          }
          else{
            if(nodeData?.opacity !== undefined){
              setNodeOpacity(`${parseInt(`${nodeData.opacity * 100}`, 10)}%`);
            }
          }
        }
        else setNodeOpacity(`${parseInt(`${nodeData.opacity! * 100}`, 10)}%`);
      }
    }
  }


  /**
   * Function to Capture Live Input Change
   * @param event Event
   */
  const changeNodeOpacity = (event:any) => {
    setNodeOpacity(event.target.value.replace("%", ""));
  }


  /**
   * Function to handle Key Down Event(i.e Keyboard Button Clicked)
   * @param event Event
   */
  const checkPressedKey = (event:KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === 'NumpadEnter') {
      applyOpacity();
    }
  };


  /**
   * Function to check if node is an Image
   * @returns Either node is Image or Not
   */
  const checkIfImage = () => {
    const nodeData = app.selectedNodes[0]?.nodeData as INode;
    const nodeType = nodeData.nodeType as ENodeType;

    return (nodeType === ENodeType.IMAGE || 
      nodeType === ENodeType.SAMPLE_IMAGE || 
      nodeType === ENodeType.ONBOARDING_IMAGE
      );
  }

  // If the node is not an Image then display the Opacity Input Bar along side the Opacity Image
  useEffect(() => {
    if (!checkIfImage()){
      setIsActive(true);
    }

    return () => {
      // Apply Opacity when user clicks on canvas to close the opacity component
      if(inputRef.current === null){
        applyOpacity();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeOpacity]);

  /**
   * Function to get appropriate classname 
   */
  const getClassName = () => {
	if(isActive) {
		return checkIfImage() ? 'icon icon-clicked' : 'expand-icon';
	};

	return 'icon';
  }

  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 1000, hide: 0 }}
      overlay={(
        <Tooltip
          id="button-tooltip-2"
          style={{
            fontSize: '12px',
            borderRadius: '2px',
            lineHeight: '16px',
            marginTop: '8px',
          }}
        >
          Opacity
        </Tooltip>
      )}
    >
      <div
        className={checkIfImage() ? "icon-container shrink" : "icon-container expand-opacity-menu"}
        onClick={(e)=>{
          e.stopPropagation();
          if(checkIfImage()) toggleIsActive();
          if(selectedOption !== 'OPACITY') setSelectedOption('OPACITY');
        }}
        role="presentation"
      >
      <div id="opacity">
        {
          isActive && (
              <div
              className={checkIfImage() ? "opacity-container" : "opacity-container expand-opacity-container"}
              role="presentation"
              onPointerDown={(e:any)=>{e.stopPropagation()}} 
              onKeyUp={(e:any)=>{e.stopPropagation()}} 
              onClick={(e:any)=>{e.stopPropagation()}}
            >
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label
                id="opacity-label"
                onClick={e=>e.stopPropagation()}
                className={checkIfImage() ? "" : "expand-opacity-label"}
                role="presentation"
              >Opacity</label>
              <input type="text" id="opacity-input" value={nodeOpacity}
                ref={inputRef}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={checkIfImage()}
                onChange={changeNodeOpacity}
                onKeyDown={(e)=>{
                  e.stopPropagation();
                  checkPressedKey(e);
                }}
                onFocus={e => e.target.select()}
                className={checkIfImage() ? "" : "expand-opacity-input"}
                onBlur={applyOpacity}
              />
            </div>
          )
        }
        <img
          src={opacity}
          alt="opacity"
          className={getClassName()}
          onClick={checkIfImage() ? (e)=>{e.preventDefault()} : (e)=>{e.stopPropagation()}}
          role="presentation"
        />
      </div>
      </div>
    </OverlayTrigger>
  );
};


export default Opacity;
