import { useEffect, useState } from 'react';
import { getWebSocket } from 'src/rtc/yjs/yjsConfig';
import { IUser } from '@naya_studio/types';
import { getUserFirstName, getUserLastName } from 'src/components/feedbackSuite/util';
import { checkAccess } from 'src/util/accessLevel/accessLevelActions';
import { generateIdsFromUrl } from '../reduxActions/util';
import useUser from './user';
import useProject from './useProject';
import { fetchBlockByIdFromRedux } from '../actions/util';

// Type to store drag history
export type TDragHistory = {
	[key: string]: {
		type: 'BLOCK' | 'PHASE';
		count: number;
		draggedBy: string;
		startedAt: number;
	};
};

// Type of Socket User in Navbar
export type ISocketUser = {
	userId: string;
	profile?: string;
	displayName?: string;
	userName: string;
	email: string;
	/** hex color string */
	color: string;
	projectId: string;
	dragHistory: TDragHistory | null;
	hasAccess: boolean;
};

/**
 * Hook to fetch socket users from yjs awareness
 * @returns users connected to socket
 */
const useSocketUsers = () => {
	const wsProvider = getWebSocket();
	const { projectId, blockId } = generateIdsFromUrl();
	const { user: currentUser } = useUser();
	const { project } = useProject(projectId);

	// State to manage connected users and trigger re-renders
	const [socketUsers, setSocketUsers] = useState<ISocketUser[]>([]);

	/**
	 * Function to return the first character from users details
	 * @param details User Details
	 * @param userName Username of user
	 * @returns first character which will be shown if no profile pic
	 */
	const getDisplayName = (user: IUser) => {
		const name = user.firstName || user.userName || '';

		return name?.substring(0, 1).toUpperCase();
	};

	/**
	 * Function to genrate random color for user
	 * @returns random hex color
	 */
	const generateRandomHexColor = () =>
		// Convert the decimal values to hex and concatenate
		`#${Math.floor(Math.random() * 0xffffff)
			.toString(16)
			.padStart(6, '0')}`;

	/**
	 * Function to handle awareness changes
	 */
	const handleAwarenessUpdate = () => {
		const filteredUsers = [] as ISocketUser[];
		const uniqueClients: { [key: string]: true } = {};

		wsProvider.awareness.getStates().forEach((awareness) => {
			const user = awareness as ISocketUser;
			const isValidUser =
				user.projectId === projectId && user.userId !== (currentUser._id as string);

			if (user.hasAccess) {
				if (isValidUser && !uniqueClients[user.userId]) {
					filteredUsers.push(user);
				}
				uniqueClients[user.userId] = true;
			}
		});

		setSocketUsers(filteredUsers.length > 0 ? filteredUsers : []);
	};

	/**
	 * Function to check if logged in user is devops on Production/Staging
	 * @returns boolean
	 */
	const isAdminAccount = () => currentUser._id && currentUser.userType?.includes('ADMIN');

	/**
	 * Function to add user to ujs awareness
	 */
	const addUserInYjsAwareness = () => {
		if (currentUser && currentUser._id && !isAdminAccount()) {
			const userId = currentUser._id as string;
			const profile = currentUser?.profilePic;
			const firstName = getUserFirstName(currentUser);
			const lastName = getUserLastName(currentUser);
			const fullName = `${firstName} ${lastName}`;

			const userName = (fullName || currentUser.email?.split('@')[0]) as string;

			const hasAccessToProject = Boolean(
				checkAccess(projectId, 'PROJECT', true, userId) || project?.hasGuestAccess
			);
			const expandedBlock = blockId
				? fetchBlockByIdFromRedux(blockId)
				: { hasGuestAccess: false };
			const hasAccessToExpandedBlock = Boolean(blockId && expandedBlock?.hasGuestAccess);

			// Construct the data for user connected
			const userAwareness: ISocketUser = {
				userId,
				profile,
				displayName: getDisplayName(currentUser),
				userName,
				email: currentUser.email as string,
				color: generateRandomHexColor(),
				projectId,
				dragHistory: null,
				hasAccess: hasAccessToExpandedBlock || hasAccessToProject
			};

			// Update the data in awareness
			wsProvider.awareness.setLocalState(userAwareness);
		}
	};

	// Re-runs when websocket connection status is changed
	useEffect(() => {
		if (wsProvider?.wsconnected) {
			wsProvider.awareness.on('change', handleAwarenessUpdate);
			// Set local awareness for the current user
			addUserInYjsAwareness();
		}

		return () => {
			// Unsubscribe from awareness updates
			if (wsProvider) wsProvider.awareness.off('change', handleAwarenessUpdate);
		};
	}, [currentUser, wsProvider?.wsconnected]);

	return socketUsers;
};

export default useSocketUsers;
