import { ChangeEvent, FC, useContext, useMemo, useState } from 'react';
import { notificationRouter } from 'src/endpoints/projects-api';
import { validateEmail } from 'src/util/helper/email-validation';
import { getGatewayKey } from 'src/util/helper/queryString';
import { ReactComponent as NayaLogo } from 'src/assets/brand/logo.svg';
import Select from 'react-select';
import axios from 'axios';

import './ScheduleDemo.scss';
import { SyncLoader } from 'react-spinners';
import { useHistory } from 'react-router';
import { ScheduleDemoProps } from './ScheduleDemo.types';
import { AuthComponentContext } from '../../AuthComponent';

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

const qs = `?${getGatewayKey()}`;

export interface DefaultScheduleDemoForm {
	email: string;
	name: string;
	orgSize: string;
	roleAndInterest: string;
	receiveEmailUpdates: boolean;
}

export const orgSizeOptions = [
	{ value: 'Individual', label: 'Individual' },
	{ value: 'Small Business (<10 employees)', label: 'Small Business (<10 employees)' },
	{ value: 'Medium Business (10 - 50 employees)', label: 'Medium Business (10 - 50 employees)' },
	{ value: 'Enterprise (50+ employees)', label: 'Enterprise (50+ employees)' }
];

type Views = 'scheduleDemo' | 'scheduleSuccess' | 'reEnterEmail';

export const ScheduleDemo: FC<ScheduleDemoProps> = () => {
	const [form, setForm] = useState<DefaultScheduleDemoForm>({
		email: '',
		name: '',
		orgSize: '',
		roleAndInterest: '',
		receiveEmailUpdates: false
	});
	const [view, setView] = useState<Views>('scheduleDemo');
	const [loading, setLoading] = useState(false);

	const history = useHistory();

	const authContext = useContext(AuthComponentContext);
	const { setAuthView, error, setError } = authContext;

	function onChange(e: ChangeEvent<HTMLInputElement>) {
		e.preventDefault();
		e.stopPropagation();
		if (!e.target) {
			return;
		}

		setForm((prev) => ({
			...prev,
			[e.target.dataset.property!]: e.target.value
		}));
	}

	const onSubmit = async (e: any) => {
		e.preventDefault();
		setLoading(true);
		const payload = {
			data: {
				...form,
				time: new Date().toDateString()
			},
			eventType: 'SCHEDULE_DEMO'
		};
		await axios.post(`${notificationRouter}/schedule-demo${qs}`, { payload });
		setLoading(false);
		setView('scheduleSuccess');
		setAuthView('SCHEDULE_DEMO_DONE');
	};

	const resendEmail = () => {
		setView('scheduleSuccess');
	};

	const validForm = useMemo(
		() => validateEmail(form.email) && !!form.name && !!form.roleAndInterest && !!form.orgSize,
		[form]
	);

	const renderDemoForm = () => (
		<>
			<div className="logo-container">
				<NayaLogo />
			</div>
			<h3
				className="view-header"
				style={{
					fontSize: '18px'
				}}
			>
				Sign up for early access!
			</h3>
			<div
				style={{
					fontSize: '13px',
					fontWeight: '500'
				}}
			>
				We&apos;re thrilled that you&apos;re interested in Naya!
				<p>
					Due to the high demand for our software we are currently invite only, but don’t
					worry.
				</p>
				<p>
					We are adding you to our exclusive waitlist, so you can be among the first to
					access our platform. Please fill in the information below and we will reach out
					if you are accepted early.
				</p>
			</div>
			<form autoComplete="new-password" className="login" onSubmit={onSubmit}>
				<input
					placeholder="Your Name*"
					title="Name"
					aria-label="Name"
					type="name"
					required
					data-property="name"
					onChange={onChange}
					autoComplete="new-password"
					value={form.name}
				/>
				<input
					placeholder="Your Email*"
					title="Email"
					aria-label="Email"
					type="email"
					required
					data-property="email"
					onChange={onChange}
					autoComplete="new-password"
					value={form.email}
				/>
				<Select
					options={orgSizeOptions}
					isSearchable={false}
					classNamePrefix="react-select"
					onChange={(e) => {
						setForm((prev) => ({
							...prev,
							orgSize: e!.value
						}));
					}}
					placeholder="Select your organization size*"
				/>
				<input
					placeholder="Your role and interest in Naya"
					title="roleAndInterest"
					aria-label="Your role and interest in Naya"
					type="text"
					data-property="roleAndInterest"
					onChange={onChange}
					value={form.roleAndInterest}
				/>
				<div style={{ fontSize: '12px', display: 'flex', justifyContent: 'center' }}>
					<input
						type="checkbox"
						style={{ height: '14px', width: '14px', accentColor: '#5714ac' }}
						onClick={() => {
							setForm((prev) => ({
								...prev,
								receiveEmailUpdates: !form.receiveEmailUpdates
							}));
						}}
					/>
					&nbsp;Receive email updates about Naya
				</div>
				<button
					className="primary-btn tw-text-sm"
					type="submit"
					onClick={onSubmit}
					disabled={!validForm || loading}
				>
					{loading ? <SyncLoader size={5} color="#fff" /> : 'Continue'}
				</button>
			</form>
			{error && <p className="error">{error}</p>}

			<div className="other-options">
				<span>
					Already have an account? &nbsp;
					<button
						type="button"
						data-type="underline"
						onClick={() => {
							history.push('/login');
							setAuthView('LOGIN');
							setError('');
						}}
					>
						Log in
					</button>
				</span>
			</div>
		</>
	);

	const renderSuccessData = () => (
		<>
			<div className="logo-title-container">
				<div className="logo-container">
					<NayaLogo />
				</div>
				<h3 className="view-header" style={{ fontSize: '18px' }}>
					You&apos;re set!
				</h3>
			</div>
			{/* <p className='success-text'>We will reach out to you at <span>{form.email}</span>
       very soon to set up a demo and get your set up on Naya.</p> */}
			<p className="success-text">
				We periodically onboard new teams and we will reach out to you at{' '}
				<span> {form.email} </span> to set up a demo and get you onboarded as soon as we
				can. Thanks for your patience!
			</p>
			<button
				type="button"
				className="primary-btn tw-text-sm"
				onClick={() => {
					// setView('scheduleDemo');
					window.location.href = '/'; // Change requested by Jimmy
					// close();
				}}
			>
				Back to home
			</button>
			{/* <p className="wrong-email-text">
        Wrong email?&nbsp;
        <span
          role="presentation"
          onClick={() => { setView('reEnterEmail'); }}
        >
          Re-enter your email address
        </span>
        .
      </p> */}
		</>
	);

	const renderReEnterEmailData = () => (
		<>
			<div className="logo-title-container">
				<div className="logo-container">
					<NayaLogo />
				</div>
				<h3
					className="view-header"
					style={{
						fontSize: '18px',
						fontWeight: '700'
					}}
				>
					Re-enter your email address.
				</h3>
			</div>
			<input placeholder="Re-enter your email" />
			<button className="primary-btn tw-text-sm" type="button" onClick={resendEmail}>
				{loading ? <SyncLoader size={5} color="#fff" /> : 'Resend email'}
			</button>
			<p className="wrong-email-text">
				Already have an account?&nbsp;
				<span>Log in instead</span>.
			</p>
		</>
	);

	const renderModalData = () => {
		switch (view) {
			case 'scheduleSuccess':
				return renderSuccessData();
			case 'reEnterEmail':
				return renderReEnterEmailData();
			default:
				return renderDemoForm();
		}
	};

	return <div id="schedule-demo">{renderModalData()}</div>;
};
