import { IUser } from '@naya_studio/types';
import { Views } from '../AuthComponent.types';

// fucntion to check if user has filled the form with baisc detail
const checkIfUserFormDetailsAreFilled = (user: IUser) => {
	if (user.organizationSize || user.roleAndInterest || user.profession) {
		return true;
	}
	return false;
};

// fucntion to set value related to user form in local storage
export const setUserFormValueToLocalStorage = (
	redirectView?: Views | undefined,
	userId?: string | undefined,
	showUserForm?: string | undefined
) => {
	if (redirectView) {
		window.localStorage.setItem('redirect-from-userform', redirectView);
	}
	if (userId) {
		window.localStorage.setItem('registeredUnappovedUserId', userId as string);
	}
	if (showUserForm) {
		window.localStorage.setItem('showUserForm', '1');
	}
};

export default checkIfUserFormDetailsAreFilled;
