import { IAction, SearchCommentTypes } from '../root.types'

// collab.naya.studio/project/:id

export interface ISearchComment {
  commentId ?:string,
  keyword : string
}

const intitialSearchComment:ISearchComment = {
  keyword : "",
  commentId:''
}

const searchCommentReducer = (state = intitialSearchComment, action: IAction<SearchCommentTypes | keyof typeof SearchCommentTypes>) => {

  let newState: ISearchComment
  const searchComment: ISearchComment = action.payload

  switch (action.type) {
    case 'SEARCH_COMMENT':
      newState = {...searchComment}
      break
    default:
      newState = state
      break
  }

  return newState
}

export default searchCommentReducer