import './Font.scss';
import {
  FC, ReactElement, useState, useEffect,
} from 'react';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import fontSelectSvg from 'src/assets/icons/edit-menu/font_select.svg';
import down_chevron_svg from 'src/assets/icons/toolbar/down_chevron.svg';
import { getFontProperties } from 'src/components/canvas/app/nodes/utils/helper';
import { FontProps, Fonts } from './Font.types';
import { fontTypes } from './constants';

const Font: FC<FontProps> = ({
  app, setSelectedOption, selectedOption, activeTextProperties, nodes,
}): ReactElement => {
  const [fontData, setFontData] = useState(fontTypes.rand);

  const getFontFamily = (fontFamily: string) => {
    const fontKeys = Object.keys(fontTypes) as [Fonts];
    const currentFontKey = fontKeys.find((ft: Fonts) => fontTypes[ft].font === fontFamily);
    return currentFontKey ? fontTypes[currentFontKey] : fontTypes.rand;
  };

  useEffect(() => {
    const { nodeData } = nodes[0];
    if (nodeData) {
      const existingFontFamily = nodeData.text?.style?.fontFamily as string || fontTypes.rand.font;
      if (app.editText) {
        if (activeTextProperties.length === 1) {
          const { fontFamily } = activeTextProperties[0];
          if (fontFamily) {
            setFontData(getFontFamily(fontFamily));
          } else {
            setFontData(getFontFamily(existingFontFamily));
          }
        } else if (activeTextProperties.some((d: any) => d.fontFamily && (d.fontFamily !== 'Rand-Regular'))) {
          setFontData({ font: 'Mixed', placeholder: 'Mixed' });
        } else {
          setFontData(getFontFamily(existingFontFamily));
        }
      } else {
        const allFont = getFontProperties(nodeData?.text?.value as string);
        if (allFont && allFont?.some((f) => f !== existingFontFamily)) {
          setFontData({ font: 'Mixed', placeholder: 'Mixed' });
        } else {
          setFontData(getFontFamily(existingFontFamily));
        }
      }
    }
  }, [activeTextProperties, nodes, app.editText]);

  const handleOptions = (font: Fonts) => {
    setFontData(fontTypes[font]);
    const currentFont = fontTypes[font].font;
    const currentNode = nodes;
    for (let i = 0; i < currentNode.length; i++) {
      currentNode[i]?.edit('FONT', currentFont, !!(currentNode.length > 1 && i !== 0));
    }
    // if (currentNode) currentNode.edit('FONT', currentFont, false);
    app.saveAllNodes([]);
  };

  const renderDropDownItems = () => {
    const fontKeys = Object.keys(fontTypes) as [Fonts];
    return fontKeys.map((ft: Fonts) => {
      const isSelected = fontTypes[ft].font === fontData.font;
      return (
        <Dropdown.Item onClick={() => handleOptions(ft)}>
          <div className="font-select">
            {isSelected && <img src={fontSelectSvg} alt="sel" />}
          </div>
          <div className="font-name" style={{ fontFamily: fontTypes[ft].font }}>
            {fontTypes[ft].placeholder}
          </div>
        </Dropdown.Item>
      );
    });
  };

  return (
    <Dropdown
      bsPrefix="Font-Dropdown"
      show={selectedOption === 'Font'}
      onClick={() => {
        if (selectedOption === 'Font') setSelectedOption('');
        else setSelectedOption('Font');
      }}
    >
      <div>
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 1000, hide: 400 }}
          overlay={(
            <Tooltip
              id="tooltip-bottom"
              style={{
                fontSize: '12px',
                borderRadius: '2px',
                lineHeight: '16px',
                marginTop: '10px',
              }}
            >
              Font
            </Tooltip>
          )}
        >
          <Dropdown.Toggle id="Font-basic-button" variant="success">
            <div className="font-placeholder">
              {fontData.placeholder}
            </div>
            <div className="down-icon">
              <img src={down_chevron_svg} alt="select element" />
            </div>
          </Dropdown.Toggle>
        </OverlayTrigger>
        <Dropdown.Menu className="fontList">
          {renderDropDownItems()}
        </Dropdown.Menu>
      </div>
    </Dropdown>
  );
};

export default Font;
