import { ChangeEvent, FC, FormEvent, ReactElement, useState } from 'react'
import { SyncLoader } from 'react-spinners'
import { validateEmail } from 'src/util/helper/email-validation'
import './ZendeskForm.scss'

export type ZendeskFormProps = {
}

export const zendeskUploadURL = 'https://naya-studio.zendesk.com/api/v2/uploads'
export const zendeskRequestURL = 'https://naya-studio.zendesk.com/api/v2/requests.json'

export const toBase64 = (file: File) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

const handleZendeskUploads = async (files: FileList | undefined) => {
  if (!files || files.length < 1)
    return

  let parentToken = ''

  for (let i = 0; i < files.length; i++) {
    const url = `${zendeskUploadURL}?filename=${files[i]?.name}${parentToken ? `&token=${parentToken}` : ''}`

    const stringFile = await toBase64(files[i]!) as string
    const formData = new FormData();
    formData.append("file", stringFile);

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/binary'
        }
      })

      const zendeskData = await response.json()

      const token = zendeskData.upload.token

      if (!parentToken)
        parentToken = token

    } catch (e) {
      console.log(e)
    }
  }

  return [parentToken]
}

const ZendeskForm: FC<ZendeskFormProps> = (): ReactElement => {
  const [ name, setName ] = useState('')
  const [ email, setEmail ] = useState('')
  const [ statusMessage, setStatusMessage ] = useState('')
  // true after submit
  const [ sent, setSent ] = useState(false)
  // true when submitting and false after response(s)
  const [ submitting, setSubmitting ] = useState(false)
  const [ body, setBody ] = useState('')
  const [ files, setFiles ] = useState<FileList>()

  /**
   * Handles filling in the inputs
   */
  const onChange = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.currentTarget

    if (!name)
      return

    switch(name) {
      case 'name-input':
        setName(value)
        break;
      case 'email-input':
        setEmail(value)
        break;
      case 'message-text-area':
        setBody(value)
        break;
      default:
        break;
    }
  }

  /**
   * Handle onChange for upload
   */
  const onUploadChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    e.stopPropagation()

    if (e.currentTarget.files && e.currentTarget.files.length > 0) {
      setFiles(e.currentTarget.files)
    }
  }

  /**
   * Submit form to Zendesk
   */
  const submit = async (e: FormEvent<HTMLElement>) => {
    e.preventDefault()

    // Checks if required fields are filled
    if (!name || !validateEmail(email) || !body) {
      if (!name)
        return setStatusMessage('Please provide a name')
      if (!validateEmail(email))
        return setStatusMessage('Please provide a valid email address.')
      if (!body)
        return setStatusMessage('Please provide details about your issue.')
    }

    try {
      setSubmitting(true)

      const uploads = await handleZendeskUploads(files)

      const data = {
        request: {
          requester: {
            name,
            email
          },
          subject: 'Help from landing page',
          comment: {
            body,
            uploads
          }
        }
      }

      const response = await fetch(zendeskRequestURL, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          "Content-type": 'application/json',
        }
      })

      await response.json()
      
      setSent(true)
      setStatusMessage('Thank you! A representative will be in touch shortly')

    } catch (e) {
      console.log(e)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <form 
      id='zendesk-custom-form'
      onSubmit={submit}
    >
      <span className={sent ? 'success-message' : 'error-message'}>{statusMessage}</span>
      <input 
        placeholder='Name *'
        name='name-input'
        required 
        type='text'
        value={name}
        onChange={onChange}
      />
      <input
        placeholder='Email *'
        name='email-input'
        required
        type='email'
        value={email}
        onChange={onChange}
      />
      <textarea
        autoCapitalize='sentences'
        placeholder='Message *'
        name='message-text-area'
        required
        value={body}
        onChange={onChange}
      />
      <div className='attach-submit-container' >
        <label htmlFor='upload-zendesk' onClick={(e) => e.stopPropagation()}><input
          id='upload-zendesk'
          type='file'
          multiple
          onChange={onUploadChange}
        /><span>{(files && files.length > 0) ? `Uploading ${files.length} file${files.length > 1 ? 's' : ''}` : 'Add up to 5 files'}</span></label>
        <button 
          type='submit'
          onClick={submit}
          disabled={sent}
        > 
          {submitting ? <SyncLoader size={5} /> : ''}
          {!submitting && (!sent ? 'Send' : 'Sent!')} 
        </button>
      </div>
    </form>
  )
}

export default ZendeskForm