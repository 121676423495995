import { IBlock, IGroup, TUserAndRole } from '@naya_studio/types';
import {
	checkIfCurrentUserIsAdmin,
	checkIfUserExistsInList
} from 'src/util/accessLevel/accessLevelActions';

/**
 * Function to check if user has project level access
 */
export const checkIfUserHasProjectLevelAccess = (users: TUserAndRole[], currentUserId: string) =>
	checkIfCurrentUserIsAdmin() || checkIfUserExistsInList(users, currentUserId);

/**
 * Function to check if user has stage level access
 */
export const checkIfUserHasStageLevelAccess = (stages: IGroup[], currentUserId: string) => {
	if (stages) {
		for (let i = 0; i < stages.length; i++) {
			const currentStage = stages[i] as IGroup;
			const users = currentStage.users as TUserAndRole[];
			if (
				(checkIfCurrentUserIsAdmin() || checkIfUserExistsInList(users, currentUserId)) &&
				(currentStage.children?.length as number) > 0
			) {
				return true;
			}
		}
	}
	return false;
};

/**
 * Function to check if user has block level access
 */
export const checkIfUserHasBlockLevelAccess = (blocks: IBlock[], currentUserId: string) => {
	if (blocks) {
		for (let i = 0; i < blocks.length; i++) {
			const currentBlock = blocks[i] as IBlock;
			const users = currentBlock.users as TUserAndRole[];
			if (checkIfCurrentUserIsAdmin() || checkIfUserExistsInList(users, currentUserId)) {
				return true;
			}
		}
	}
	return false;
};

/**
 * Function to check if user has access the provided stage
 */
export const checkIfUserHasAccessToGivenStage = (stage: IGroup, currentUserId: string) => {
	const stageUsers = stage.users as TUserAndRole[];
	for (let i = 0; i < stageUsers?.length; i++) {
		if (stageUsers[i]?.user === currentUserId) {
			return true;
		}
	}
	return false;
};

/**
 * Function to get first block of given stage
 */
export const getFirstBlockIdByPhaseId = (stages: IGroup[], currentUserId: string) => {
	if (stages && stages.length > 0) {
		for (let i = 0; i < stages.length; i++) {
			const initBlocks = stages[i]?.children as string[];
			if (
				(checkIfCurrentUserIsAdmin() ||
					checkIfUserHasAccessToGivenStage(stages[i] as IGroup, currentUserId)) &&
				initBlocks.length > 0
			) {
				return initBlocks[0];
			}
			if (initBlocks.length > 0) {
				return initBlocks[0];
			}
		}
	}
	return undefined;
};
