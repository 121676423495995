import { NotificationTabs } from 'src/components/actionItemNotifications/actionItemNotifications.types';
import { IAction, IOpenComment, OpenCommentTypes } from '../root.types';

const initialOpenComment: IOpenComment = {
	commentId: '',
	tab: NotificationTabs.ACTION_ITEMS,
	searchVal: '',
	markFeedbackAsComplete: '',
	isCompleted: false
};

const openCommentReducer = (
	// eslint-disable-next-line @typescript-eslint/default-param-last
	state = initialOpenComment,
	action: IAction<OpenCommentTypes | keyof typeof OpenCommentTypes>
) => {
	let newState: IOpenComment;
	const openComment: IOpenComment = action.payload;

	switch (action.type) {
		case 'OPEN_COMMENT':
			newState = {
				...state,
				commentId: openComment.commentId
			};
			break;
		case 'ACTIVITY_LOG_UPDATE':
			newState = {
				...state,
				...openComment
			};
			break;
		case 'MARK_FB_NOTIF_COMP':
			newState = {
				...state,
				markFeedbackAsComplete: openComment.markFeedbackAsComplete,
				isCompleted: openComment.isCompleted
			};
			break;
		default:
			newState = state;
			break;
	}

	return newState;
};

export default openCommentReducer;
