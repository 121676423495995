import { ChangeEvent, FC, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { ControlModal, isThumbnailColorString, PROXY_API } from '@naya_studio/radix-ui';
import './EditThumbnailModal.scss';
import { storage } from 'src/util/storage/firebaseStorage';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import classNames from 'classnames';
import { SyncLoader } from 'react-spinners';
import trackEvent from 'src/util/analytics/analytics';
import { CustomEvents } from 'src/util/analytics/events';
import Skeleton from '@mui/material/Skeleton';
import { cleanFileName } from 'src/util/collaboration/util';
import { TEditThumbnailEventData } from 'src/util/analytics/analytic.types';
import { generateIdsFromUrl } from 'src/redux/reduxActions/util';
import { EditThumbnailModalProps, TCropFrameDimensions } from './EditThumbnailModal.types';
import { ReactComponent as Cancel } from '../../assets/icons/details/cancel.svg';
import { ReactComponent as CheckMark } from '../../assets/icons/edit-thumbnail-modal/checkmark.svg';

/**
 * Union type for drag handle
 */
type TDragHandle =
	| 'MOVE'
	| 'TOP-LEFT'
	| 'TOP-RIGHT'
	| 'BOTTOM-LEFT'
	| 'BOTTOM-RIGHT'
	| 'TOP'
	| 'RIGHT'
	| 'BOTTOM'
	| 'LEFT';

/**
 * Component to render Edit thumbnail modal
 */
const EditThumbnailModal: FC<EditThumbnailModalProps> = ({
	id,
	variant,
	name,
	thumbnail,
	originalThumbnail,
	isCustom,
	onClose,
	onThumbnailChange
}) => {
	// Firebase url for upload svg
	const uploadSvg =
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2F1689573842224_design_system_uploadFile.svg?alt=media&token=7d07e9ec-a560-4111-85f2-2bef694aa693';

	// Array of all available colors in palette
	const paletteColors = [
		'#FFFFFF',
		'#BBBBBB',
		'#B27DFF',
		'#27B1F5',
		'#93D94E',
		'#FFE818',
		'#FFA722',
		'#FF494A'
	];

	// State to store reference to thumbnail
	const [localThumbnail, setLocalThumbnail] = useState<string>(thumbnail);
	// State to store loading state
	const [loaderState, setLoaderState] = useState<'UPDATING' | 'LOADING' | null>(null);
	// State to force re-render
	const [, forceRerender] = useState(false);

	// Memoized value to check if thumbnail is color string or not
	const isLocalThumbnailColor = useMemo(
		() => isThumbnailColorString(localThumbnail),
		[localThumbnail]
	);
	// Memoized value to check if incoming thumbnail is color string or not
	const isThumbnailColor = useMemo(() => isThumbnailColorString(thumbnail), [thumbnail]);

	// To create crop frame for project cards based on its dimensions
	const newCardContainer = document.querySelector<HTMLDivElement>('.new-card-container');
	// Aspect ratio to scale the crop frame
	const aspectRatio =
		variant === 'BLOCK'
			? 288 / 230
			: (newCardContainer?.clientWidth || 346) / (newCardContainer?.clientHeight || 307);
	// Minimum dimensions based on aspect ratio
	const minWidth = 150;
	// Minimum height based on aspect ratio
	const minHeight = minWidth / aspectRatio;

	// Ref to store active drag handle dragged by user
	const dragHandle = useRef<TDragHandle | null>(null);
	// Ref to store crop dimensions and it's position
	const cropFrameDimensionsRef = useRef<TCropFrameDimensions>({
		x: 0,
		y: 0,
		width: variant === 'PROJECT' ? 234 : 150,
		height: (variant === 'PROJECT' ? 234 : 150) / aspectRatio
	});
	// Ref to store thumbnail element
	const thumbnailRef = useRef<HTMLImageElement>(null);
	// Ref to store hidden canvas element
	const hiddenCanvasRef = useRef<HTMLCanvasElement>(null);
	// Ref to store thumbnail's parent element
	const thumbnailParentRef = useRef<HTMLDivElement>(null);
	// Ref to store display of crop window on image
	const displayCropWindowRef = useRef<boolean>(isCustom && !isLocalThumbnailColor);
	// Ref to store container of color palette
	const colorPaletteContainerRef = useRef<HTMLDivElement>(null);
	// Ref to store if mouse is down on color palette
	const mouseDownOnColorPaletteRef = useRef<boolean>(false);
	// Ref to store modal container
	const modalContainerRef = useRef<HTMLDivElement>(null);
	// Ref to store uploaded files name
	const uploadedFileNameRef = useRef<string | null>(name);
	// Ref to store if string is base64 image string
	const isBase64ImageStringRef = useRef<boolean>(false);

	/**
	 * Function to handle on mouse down in crop frame
	 * @param type Type of drag handle
	 */
	const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, type: TDragHandle) => {
		e.preventDefault();
		dragHandle.current = type;
	};

	/**
	 * Function to handle on mouse move in crop frame
	 */
	const onMouseMove = (e: MouseEvent) => {
		// If there's no drag handle selected, or no thumbnail parent ref then return
		if (!dragHandle.current || !thumbnailParentRef.current || !thumbnailRef.current) return;

		const image = thumbnailRef.current;
		const imageRect = image.getBoundingClientRect();
		const imageContainer = thumbnailParentRef.current;
		const imageContainerRect = imageContainer.getBoundingClientRect();
		const x = e.clientX - imageContainerRect.left;
		const y = e.clientY - imageContainerRect.top;

		// Calculate the horizontal gap between container and image i.e. for horizontal images
		const horizontalGap = Math.floor(
			image.width < imageContainer.clientWidth ? imageRect.x - imageContainerRect.x : 0
		);
		// Calculate the vertical gap between container and image i.e. for vertical images
		const verticalGap = Math.floor(
			image.height < imageContainer.clientHeight ? imageRect.y - imageContainerRect.y : 0
		);

		const cropFrameDimensions = cropFrameDimensionsRef.current;
		const newCrop = { ...cropFrameDimensions };
		const activeHandle = dragHandle.current;
		const frameAspectRatio = cropFrameDimensions.width / cropFrameDimensions.height;

		// Logic for different drag handles
		if (activeHandle === 'MOVE') {
			// Calculate new position
			const newX = x - cropFrameDimensions.width / 2;
			const newY = y - cropFrameDimensions.height / 2;

			// Ensure the crop frame stays within the bounds
			if (
				newX >= horizontalGap &&
				newX + cropFrameDimensions.width <= horizontalGap + imageRect.width
			) {
				newCrop.x = newX;
			}
			if (
				newY >= verticalGap &&
				newY + cropFrameDimensions.height <= verticalGap + imageRect.height
			) {
				newCrop.y = newY;
			}
		} else {
			let newWidth = cropFrameDimensions.width;
			let newHeight = cropFrameDimensions.height;
			let deltaX = 0;
			let deltaY = 0;

			if (activeHandle === 'TOP-LEFT' || activeHandle === 'TOP-RIGHT') {
				deltaY = cropFrameDimensions.y - y;
				newHeight = Math.max(minHeight, cropFrameDimensions.height + deltaY);
				newWidth = newHeight * frameAspectRatio;
			} else if (activeHandle === 'BOTTOM-LEFT' || activeHandle === 'BOTTOM-RIGHT') {
				deltaY = y - (cropFrameDimensions.y + cropFrameDimensions.height);
				newHeight = Math.max(minHeight, cropFrameDimensions.height + deltaY);
				newWidth = newHeight * frameAspectRatio;
			}

			if (activeHandle === 'TOP-LEFT' || activeHandle === 'BOTTOM-LEFT') {
				deltaX = cropFrameDimensions.x - x;
				newWidth = Math.max(minWidth, cropFrameDimensions.width + deltaX);
				newHeight = newWidth / frameAspectRatio;
			} else if (activeHandle === 'TOP-RIGHT' || activeHandle === 'BOTTOM-RIGHT') {
				deltaX = x - (cropFrameDimensions.x + cropFrameDimensions.width);
				newWidth = Math.max(minWidth, cropFrameDimensions.width + deltaX);
				newHeight = newWidth / frameAspectRatio;
			}

			// Ensure new dimensions are within the bounds of the image
			if (newCrop.x + newWidth > horizontalGap + imageRect.width) {
				newWidth = horizontalGap + imageRect.width - newCrop.x;
				newHeight = newWidth / frameAspectRatio;
			}
			if (newCrop.y + newHeight > verticalGap + imageRect.height) {
				newHeight = verticalGap + imageRect.height - newCrop.y;
				newWidth = newHeight * frameAspectRatio;
			}

			newCrop.width = newWidth;
			newCrop.height = newHeight;

			// Adjust x and y positions for diagonal handles
			if (activeHandle === 'TOP-LEFT' || activeHandle === 'BOTTOM-LEFT') {
				newCrop.x = cropFrameDimensions.x + cropFrameDimensions.width - newWidth;
			}
			if (activeHandle === 'TOP-LEFT' || activeHandle === 'TOP-RIGHT') {
				newCrop.y = cropFrameDimensions.y + cropFrameDimensions.height - newHeight;
			}
		}

		// Ensure crop frame stays within image bounds
		if (newCrop.x < horizontalGap) newCrop.x = horizontalGap;
		if (newCrop.y < verticalGap) newCrop.y = verticalGap;
		if (newCrop.x + newCrop.width > horizontalGap + imageRect.width)
			newCrop.x = horizontalGap + imageRect.width - newCrop.width;
		if (newCrop.y + newCrop.height > verticalGap + imageRect.height)
			newCrop.y = verticalGap + imageRect.height - newCrop.height;

		cropFrameDimensionsRef.current = newCrop;
		forceRerender((prev) => !prev);
	};

	/**
	 * Function to handle on mouse up event on crop frame
	 */
	const onMouseUp = () => {
		if (dragHandle.current) dragHandle.current = null;
	};

	// Runs when component is mounted on screen
	useEffect(() => {
		const activeColorTile = document.querySelector<HTMLDivElement>('.applied-thumbnail-color');
		if (activeColorTile) {
			const checkMarkSvg = activeColorTile.querySelector('svg');
			if (checkMarkSvg) {
				// If there is a selected thumbnail color, scroll it into view
				// To scroll end tiles into view if they're selected as thumbnails
				checkMarkSvg.scrollIntoView({
					behavior: 'smooth',
					block: 'end'
				});
			}
		}

		document.addEventListener('mouseup', onMouseUp);
		return () => {
			document.removeEventListener('mouseup', onMouseUp);
		};
	}, []);

	/**
	 * Re-runs when crop framw is displayed, handles crop frame position adjustment
	 */
	useEffect(() => {
		if (
			displayCropWindowRef.current &&
			!loaderState &&
			thumbnailRef.current &&
			thumbnailParentRef.current
		) {
			const image = thumbnailRef.current;
			const imageRect = image.getBoundingClientRect();
			const imageContainer = thumbnailParentRef.current;
			const imageContainerRect = imageContainer.getBoundingClientRect();

			// Calculate the horizontal gap between container and image i.e. for horizontal images
			let horizontalGap =
				image.width < imageContainer.clientWidth ? imageRect.x - imageContainerRect.x : 0;

			// Calculate the vertical gap between container and image i.e. for vertical images
			let verticalGap =
				image.height < imageContainer.clientHeight ? imageRect.y - imageContainerRect.y : 0;

			// If difference is less than 3px, ignore it
			if (horizontalGap < 3) horizontalGap = 0;
			if (verticalGap < 3) verticalGap = 0;

			let frameWidth = horizontalGap
				? thumbnailRef.current.clientWidth
				: thumbnailRef.current.clientHeight * aspectRatio;

			// If there's no gap, reduce the crop frame width by 25px to prevent it from wrapping entire image
			if (!horizontalGap && !verticalGap) frameWidth -= 25;

			// If calculated frame height based on image width exceeds image,
			// Then use height as base and change frame width
			if (frameWidth / aspectRatio > thumbnailRef.current.clientHeight) {
				frameWidth = thumbnailRef.current.clientHeight * aspectRatio;
			}

			// Removing 2px for border of crop frame
			frameWidth -= 2;

			cropFrameDimensionsRef.current = {
				x: horizontalGap + 1,
				y: verticalGap + 1,
				width: frameWidth,
				height: frameWidth / aspectRatio
			};
			forceRerender((prev) => !prev);
		}
	}, [displayCropWindowRef.current, localThumbnail, loaderState]);

	/**
	 * Re-runs when localThumbnail is changed
	 */
	useEffect(() => {
		if (!isLocalThumbnailColor && loaderState !== 'LOADING') setLoaderState('LOADING');
		else if (isLocalThumbnailColor && loaderState === 'LOADING') setLoaderState(null);
	}, [isLocalThumbnailColor]);

	// Re-runs when thumbnail parent ref is updated
	useEffect(() => {
		let thumbnailContainer: HTMLDivElement | null = null;
		// Making it async to make sure thumbnailContainer is assigned element in dom
		setTimeout(() => {
			thumbnailContainer = thumbnailParentRef.current;
			if (thumbnailContainer) {
				thumbnailContainer.addEventListener('mousemove', onMouseMove);
				thumbnailContainer.addEventListener('mouseup', onMouseUp);
			}
		}, 0);

		return () => {
			if (thumbnailContainer) {
				thumbnailContainer.removeEventListener('mousemove', onMouseMove);
				thumbnailContainer.removeEventListener('mouseup', onMouseUp);
			}
		};
	}, [thumbnailParentRef.current]);

	// Re-runs when modal container ref is updated
	useEffect(() => {
		let modalContainer: HTMLDivElement | null = null;
		// Making it async to make sure modalContainer is assigned element in dom
		setTimeout(() => {
			modalContainer = modalContainerRef.current;
			if (modalContainer) modalContainer.addEventListener('mouseup', onMouseUp);
		}, 0);

		return () => {
			if (modalContainer) modalContainer.removeEventListener('mouseup', onMouseUp);
		};
	}, [modalContainerRef.current]);

	/**
	 * Function to handle on wheel event on color palette
	 */
	const onWheel = (event: WheelEvent) => {
		// Scroll the color palette when someone uses mouse to scroll
		if (colorPaletteContainerRef.current) {
			const scrollAmount = event.deltaY;
			colorPaletteContainerRef.current.scrollLeft += scrollAmount;
		}
	};

	// Re-runs when color palette container ref is updated
	useEffect(() => {
		let colorPaletteContainer: HTMLDivElement | null = null;
		// Making it async to make sure colorPaletteContainer is assigned element in dom
		setTimeout(() => {
			colorPaletteContainer = colorPaletteContainerRef.current;
			if (colorPaletteContainer)
				colorPaletteContainer.addEventListener('wheel', onWheel, { passive: false });
		}, 0);

		return () => {
			if (colorPaletteContainer) colorPaletteContainer.removeEventListener('wheel', onWheel);
		};
	}, [colorPaletteContainerRef.current]);

	/**
	 * Function to track thumbnail edit event
	 * @param type type of Edit
	 */
	const trackThumbnailEdit = (type: 'COLOR' | 'IMAGE') => {
		const eventProps: TEditThumbnailEventData = {
			elementId: id,
			elementType: variant === 'PROJECT' ? 'JOURNEY' : 'BLOCK',
			editType: type
		};
		trackEvent(CustomEvents.EDIT_THUMBNAIL, eventProps);
	};

	/**
	 * Function to handle saving of thumbnail edit
	 * @param url Url of new thumbnail
	 */
	const onThumbnailEdit = (url?: string) => {
		onThumbnailChange(id, url || localThumbnail, originalThumbnail, () => {
			if (loaderState) setLoaderState(null);
			trackThumbnailEdit(url ? 'IMAGE' : 'COLOR');
			onClose();
		});
	};

	/**
	 * Function to render Modal Header
	 * @returns Modal Header Component
	 */
	const renderModalHeader = () => (
		<div className="header-container tw-flex tw-justify-between tw-items-center tw-max-h-4 tw-h-4 tw-w-full">
			<p className="tw-m-0 tw-flex-1 tw-text-sm tw-leading-4 tw-font-randMedium tw-text-black">
				{variant === 'PROJECT' ? 'Change' : 'Select'} {variant.toLowerCase()} thumbnail
			</p>
			<div
				role="presentation"
				className="close-icon tw-flex tw-items-center tw-justify-center tw-p-2 
				hover:tw-bg-[#f5f5f5] tw-rounded-full tw-cursor-pointer tw-relative -tw-right-2"
				onClick={() => onClose()}
			>
				<Cancel width={16} height={16} />
			</div>
		</div>
	);

	/**
	 * Function to render Thumbnail in Edit Modal
	 * @returns Thumbnail Component
	 */
	const renderThumbnailComponent = () => {
		/**
		 * Function to render crop frame on an image
		 * @returns Crop Frame Component
		 */
		const renderCropFrame = () => (
			<Fragment key="crop-frame">
				<div
					className="crop-frame tw-absolute tw-border-[1px] tw-border-solid tw-border-[#CDAAFF] tw-rounded-3xl"
					style={{
						top: `${cropFrameDimensionsRef.current.y}px`,
						left: `${cropFrameDimensionsRef.current.x}px`,
						width: `${cropFrameDimensionsRef.current.width}px`,
						height: `${cropFrameDimensionsRef.current.height}px`
					}}
				>
					<div
						role="presentation"
						className="crop-handle move-handle tw-absolute tw-w-full tw-h-full tw-bg-transparent tw-cursor-move"
						onMouseDown={(e) => onMouseDown(e, 'MOVE')}
					/>
					<div
						role="presentation"
						className="crop-handle top-left-handle tw-absolute tw-bg-transparent 
						tw-cursor-nwse-resize tw-z-2"
						onMouseDown={(e) => onMouseDown(e, 'TOP-LEFT')}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="19"
							height="19"
							viewBox="0 0 19 19"
							fill="none"
						>
							<path
								d="M19 3V3C10.1634 3 3 10.1634 3 19V19"
								stroke="#4F00C1"
								strokeWidth="5"
							/>
						</svg>
					</div>
					<div
						role="presentation"
						className="crop-handle top-right-handle tw-absolute tw-bg-transparent 
						tw-cursor-nesw-resize tw-z-2"
						onMouseDown={(e) => onMouseDown(e, 'TOP-RIGHT')}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="19"
							height="19"
							viewBox="0 0 19 19"
							fill="none"
						>
							<path
								d="M3.57628e-07 3V3C8.83656 3 16 10.1634 16 19V19"
								stroke="#4F00C1"
								strokeWidth="5"
							/>
						</svg>
					</div>
					<div
						role="presentation"
						className="crop-handle bottom-left-handle tw-absolute tw-bg-transparent 
						tw-cursor-nesw-resize tw-z-2"
						onMouseDown={(e) => onMouseDown(e, 'BOTTOM-LEFT')}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="19"
							height="19"
							viewBox="0 0 19 19"
							fill="none"
						>
							<path
								d="M19 16V16C10.1634 16 3 8.83656 3 3.57628e-07V3.57628e-07"
								stroke="#4F00C1"
								strokeWidth="5"
							/>
						</svg>
					</div>
					<div
						role="presentation"
						className="crop-handle bottom-right-handle tw-absolute 
						tw-cursor-nwse-resize tw-bg-transparent tw-z-2"
						onMouseDown={(e) => onMouseDown(e, 'BOTTOM-RIGHT')}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="19"
							height="19"
							viewBox="0 0 19 19"
							fill="none"
						>
							<path
								d="M3.57628e-07 16V16C8.83656 16 16 8.83656 16 3.57628e-07V3.57628e-07"
								stroke="#4F00C1"
								strokeWidth="5"
							/>
						</svg>
					</div>
				</div>

				{/* Render the black screen working as mask */}
				<div
					className="crop-mask tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-pointer-events-none tw-z-[1] tw-rounded-2xl"
					style={{
						background: 'rgba(128, 128, 128, 0.5)',
						clipPath: `
								polygon(
									0px 0px,
									${cropFrameDimensionsRef.current.x}px 0px,
									${cropFrameDimensionsRef.current.x}px 100%,
									0px 100%,
									0px 0px,

									0px 0px,
									100% 0px,
									100% ${cropFrameDimensionsRef.current.y}px,
									100% ${cropFrameDimensionsRef.current.y + 19}px,
									${cropFrameDimensionsRef.current.x + cropFrameDimensionsRef.current.width}px ${
							cropFrameDimensionsRef.current.y + 19
						}px,
									${cropFrameDimensionsRef.current.x + cropFrameDimensionsRef.current.width - 6}px ${
							cropFrameDimensionsRef.current.y + 6
						}px,
									${cropFrameDimensionsRef.current.x + cropFrameDimensionsRef.current.width - 19}px ${
							cropFrameDimensionsRef.current.y
						}px,
									${cropFrameDimensionsRef.current.x + 19}px ${cropFrameDimensionsRef.current.y}px,
									${cropFrameDimensionsRef.current.x + 6}px ${cropFrameDimensionsRef.current.y + 6}px,
									${cropFrameDimensionsRef.current.x}px ${cropFrameDimensionsRef.current.y + 19}px,
									0px ${cropFrameDimensionsRef.current.y + 19}px,
									0px 0px,

									0px 0px,
									100% 0px,
									100% 100%,
									${cropFrameDimensionsRef.current.x + cropFrameDimensionsRef.current.width}px 100%,
									${cropFrameDimensionsRef.current.x + cropFrameDimensionsRef.current.width}px 0px,
									0px 0px,

									0px 0px,
									0px ${cropFrameDimensionsRef.current.y + cropFrameDimensionsRef.current.height}px,
									${cropFrameDimensionsRef.current.x}px ${
							cropFrameDimensionsRef.current.y +
							cropFrameDimensionsRef.current.height -
							19
						}px,
									${cropFrameDimensionsRef.current.x}px ${
							cropFrameDimensionsRef.current.y +
							cropFrameDimensionsRef.current.height -
							19
						}px,
									${cropFrameDimensionsRef.current.x + 6}px ${
							cropFrameDimensionsRef.current.y +
							cropFrameDimensionsRef.current.height -
							6
						}px,
									${cropFrameDimensionsRef.current.x + 19}px ${
							cropFrameDimensionsRef.current.y + cropFrameDimensionsRef.current.height
						}px,
									${cropFrameDimensionsRef.current.x + cropFrameDimensionsRef.current.width - 19}px ${
							cropFrameDimensionsRef.current.y + cropFrameDimensionsRef.current.height
						}px,
											${cropFrameDimensionsRef.current.x + cropFrameDimensionsRef.current.width - 6}px ${
							cropFrameDimensionsRef.current.y +
							cropFrameDimensionsRef.current.height -
							6
						}px,
											${cropFrameDimensionsRef.current.x + cropFrameDimensionsRef.current.width}px ${
							cropFrameDimensionsRef.current.y +
							cropFrameDimensionsRef.current.height -
							19
						}px,
									100% ${cropFrameDimensionsRef.current.y + cropFrameDimensionsRef.current.height - 19}px,
									100% 100%,
									0px 100%,
									0px 0px
								)
								
							`
					}}
				/>
			</Fragment>
		);

		/**
		 * Function to render Image as Thumbnail
		 * @returns Image Component as Thumbnail
		 */
		const renderImageAsThumbnail = () => (
			<div
				ref={thumbnailParentRef}
				className="image-thumbnail-card tw-flex tw-justify-center tw-items-center tw-w-full tw-h-full"
			>
				<canvas ref={hiddenCanvasRef} className="tw-hidden" />
				<img
					src={
						isBase64ImageStringRef.current
							? localThumbnail
							: `${PROXY_API}${localThumbnail}`
					}
					ref={thumbnailRef}
					alt={name}
					className={`tw-max-h-full tw-max-w-full ${classNames({
						'tw-hidden': loaderState === 'LOADING'
					})}`}
					draggable="false"
					onLoad={() => {
						if (loaderState === 'LOADING') setLoaderState(null);
					}}
					crossOrigin="anonymous" // To prevent canvas tainting issue
				/>
				{loaderState === 'LOADING' && (
					<Skeleton
						variant="rounded"
						width={thumbnailParentRef.current?.clientWidth}
						height={thumbnailParentRef.current?.clientHeight}
						className="tw-rounded-2xl"
					/>
				)}
				{displayCropWindowRef.current && loaderState !== 'LOADING' && renderCropFrame()}
			</div>
		);
		/**
		 * Function to render Color as Thumbnail
		 * @returns Color Component as Thumbnail
		 */
		const renderColorAsThumbnail = () => (
			<div
				data-testid="thumbnail-color"
				className={`color-thumbnail-card tw-w-full tw-h-full 
				tw-bg-[${localThumbnail}] tw-flex tw-justify-center tw-items-center tw-rounded-2xl`}
			>
				<p
					className={`tw-m-0 tw-ml-6 tw-flex-1 tw-text-xl tw-leading-6 
					tw-font-randMedium tw-truncate tw-title-clamp tw-title-clamp2 
					${classNames({
						'!tw-text-white': !['#FFFFFF', '#FAFAFA'].includes(localThumbnail),
						'!tw-text-naya': ['#FFFFFF', '#FAFAFA'].includes(localThumbnail)
					})}`}
				>
					{name}
				</p>
			</div>
		);

		/**
		 * Function to render title
		 * @returns Title Component
		 */
		const renderTitle = () => (
			<p
				className="editable-title tw-m-0 tw-absolute tw-top-4 
			tw-right-4 tw-px-2 tw-py-3 tw-flex-1 tw-text-sm tw-leading-4 
			tw-font-randMedium tw-text-black tw-bg-white tw-rounded-xl"
			>
				{name}
			</p>
		);

		return (
			<div
				className={`thumbnail-container tw-overflow-hidden tw-relative tw-rounded-2xl ${classNames(
					{
						'thumbnail-container-project tw-w-full': variant === 'PROJECT',
						'thumbnail-container-block': variant === 'BLOCK'
					}
				)}`}
			>
				{variant === 'PROJECT' && !displayCropWindowRef.current && renderTitle()}
				{isLocalThumbnailColor ? renderColorAsThumbnail() : renderImageAsThumbnail()}
			</div>
		);
	};

	/**
	 * Function to reset modal refs
	 */
	const resetModalRefs = () => {
		cropFrameDimensionsRef.current = {
			x: 0,
			y: 0,
			width: variant === 'PROJECT' ? newCardContainer?.clientWidth || 346 : 150,
			height:
				variant === 'PROJECT' ? newCardContainer?.clientHeight || 307 : 119.79166666666666
		};
		displayCropWindowRef.current = false;
		dragHandle.current = null;
		mouseDownOnColorPaletteRef.current = false;
		isBase64ImageStringRef.current = false;
		forceRerender((prev) => !prev);
	};

	/**
	 * Function to render Upload And Color Palette Component
	 * @returns Upload and Color Palette Component
	 */
	const renderUploadPicker = () => {
		/**
		 * Function to handle image upload on input
		 */
		const onImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
			if (e.target.files && e.target.files.length > 0) {
				const uploadedFile = e.target.files[0];
				const cleanedName = cleanFileName(uploadedFile?.name, true).split(
					/\.(?=[^.]*$)/
				)[0];
				if (cleanedName) uploadedFileNameRef.current = cleanedName;
				const reader = new FileReader();
				reader.addEventListener('load', () => {
					if (displayCropWindowRef.current) resetModalRefs();
					isBase64ImageStringRef.current = true;
					setLocalThumbnail(reader.result as string);
					displayCropWindowRef.current = true;

					// Reset files from input so that if we upload same image again it would work
					const emptyFileList = new DataTransfer().files;
					e.target.files = emptyFileList;
				});
				reader.readAsDataURL(uploadedFile as Blob);
			}
		};

		/**
		 * Function to render Info in upload
		 * @returns Info Component
		 */
		const renderInfo = () => (
			<p className="options-info tw-m-0 tw-flex-1 tw-text-sm tw-leading-8 tw-font-randMedium tw-text-black tw-w-full">
				Upload a file or pick from below
			</p>
		);

		/**
		 * Funuction to render Input to upload thumbnails
		 * @returns Input Component
		 */
		const renderUploader = () => (
			<div
				data-testid="upload-thumbnail-btn"
				className="upload-input tw-flex tw-justify-center tw-items-center tw-gap-2 tw-rounded-2xl 
			tw-bg-white tw-border-[1px] tw-border-[#e2e2e2] tw-border-solid 
			tw-w-[100px] tw-h-[100px] hover:tw-border-[#CDAAFF] hover:tw-bg-[#F8F5FD] 
			tw-flex-col tw-cursor-pointer tw-relative tw-py-[5px]"
			>
				<label
					htmlFor="fileInput-edit-thumbnail"
					className="tw-w-full tw-h-full tw-cursor-pointer tw-absolute tw-rounded-2xl"
				>
					<input
						type="file"
						id="fileInput-edit-thumbnail"
						hidden
						multiple={false}
						accept="image/*"
						onChange={onImageUpload}
					/>
				</label>
				<img
					src={uploadSvg}
					alt="File icon"
					className="tw-bg-cover tw-w-6 tw-h-6"
					draggable="false"
				/>
				<p className="tw-m-0 tw-text-xs tw-leading-4 tw-font-randMedium tw-text-naya">
					Upload
				</p>
			</div>
		);

		/**
		 * Function to render Color Palette
		 * @returns Color Palette Component
		 */
		const renderColorPalette = () => {
			/**
			 * Function to render color tile in color palette component
			 * @param color color of the tile
			 * @returns Color Tile Component
			 */
			const renderColorTile = (color: string) => (
				<div
					role="presentation"
					key={color}
					data-testid={`thumbnail-color-card-${color}`}
					className={`color-card tw-flex tw-justify-center tw-items-center tw-min-w-[100px] tw-w-[100px] 
					tw-h-[100px] tw-rounded-2xl tw-bg-[${color}] tw-border-[1px] 
					tw-border-[#e2e2e2] tw-border-solid hover:tw-border-[#CDAAFF] 
					hover:tw-brightness-105 tw-relative tw-cursor-pointer 
					${classNames({
						'!tw-border-[1px] !tw-border-[#4F00C1] applied-thumbnail-color':
							isLocalThumbnailColor && color === localThumbnail
					})}`}
					onClick={(e) => {
						isBase64ImageStringRef.current = false;
						setLocalThumbnail(color);
						if (displayCropWindowRef.current) displayCropWindowRef.current = false;

						const checkMarkSvg = e.currentTarget.querySelector('svg');
						if (checkMarkSvg) {
							checkMarkSvg.scrollIntoView({
								behavior: 'smooth',
								block: 'end'
							});
						}
					}}
				>
					<p
						className={`tw-m-0 tw-px-3 tw-flex-1 tw-text-sm tw-leading-4 
						tw-font-randMedium tw-truncate tw-title-clamp tw-title-clamp2 
						${classNames({
							'!tw-text-white': !['#FFFFFF', '#FAFAFA'].includes(color),
							'!tw-text-naya': ['#FFFFFF', '#FAFAFA'].includes(color)
						})}`}
					>
						{name}
					</p>
					{color === localThumbnail && isLocalThumbnailColor && (
						<CheckMark
							width={24}
							height={24}
							className="check-mark tw-absolute -tw-top-1 -tw-right-1"
						/>
					)}
				</div>
			);

			/**
			 * Fnunction to render custom thumbnail tile in scrollable color palette
			 * @returns Custom Thumbnail Tile Component
			 */
			const renderCustomThumbnailTile = () => (
				<div
					role="presentation"
					key="custom-thumbnail-tile"
					data-testid="custom-thumbnail-card"
					className={`custom-thumbnail-card tw-flex tw-justify-center tw-items-center 
					tw-min-w-[100px] tw-min-h-[100px] tw-w-[100px] tw-h-[100px] 
					tw-rounded-2xl tw-border-[1px] tw-border-[#e2e2e2] tw-border-solid hover:tw-border-[#CDAAFF] 
					hover:tw-brightness-105 tw-relative tw-cursor-pointer ${classNames({
						'!tw-border-[1px] !tw-border-[#4F00C1] applied-thumbnail-color':
							localThumbnail === thumbnail
					})}`}
					onClick={(e) => {
						isBase64ImageStringRef.current = false;
						setLocalThumbnail(thumbnail);
						displayCropWindowRef.current = true;
						e.stopPropagation();
					}}
				>
					<img
						src={thumbnail}
						alt={name}
						className={`tw-min-w-full tw-min-h-full tw-rounded-2xl ${classNames({
							'tw-hidden': loaderState === 'LOADING' && localThumbnail === thumbnail
						})}`}
						draggable="false"
					/>
					{loaderState === 'LOADING' && localThumbnail === thumbnail && (
						<Skeleton
							variant="rounded"
							width={100}
							height={100}
							className="!tw-rounded-2xl"
						/>
					)}
					{localThumbnail === thumbnail && (
						<CheckMark
							width={24}
							height={24}
							className="check-mark tw-absolute -tw-top-1 -tw-right-1"
						/>
					)}
				</div>
			);

			return (
				<div
					role="presentation"
					className="color-palette tw-max-w-[350px] tw-flex tw-flex-1
					tw-justify-start tw-items-center tw-gap-4 tw-py-[5px]"
					ref={colorPaletteContainerRef}
					onMouseDown={() => {
						mouseDownOnColorPaletteRef.current = true;
					}}
					onMouseMove={(event) => {
						if (
							mouseDownOnColorPaletteRef.current &&
							colorPaletteContainerRef.current
						) {
							// Calculate the distance to scroll based on the mouse movement
							const { movementX } = event;
							colorPaletteContainerRef.current.scrollLeft -= movementX;
						}
					}}
					onMouseUp={() => {
						mouseDownOnColorPaletteRef.current = false;
					}}
					onMouseLeave={() => {
						mouseDownOnColorPaletteRef.current = false;
					}}
				>
					{isCustom && !isThumbnailColor && renderCustomThumbnailTile()}
					{paletteColors.map((color) => renderColorTile(color))}
				</div>
			);
		};

		return (
			<div
				className="thumbnail-options-container tw-flex tw-justify-center tw-items-center 
			tw-gap-2 tw-flex-col tw-mt-2 tw-w-full"
			>
				{renderInfo()}

				<div className="tw-flex tw-justify-center tw-items-center tw-gap-4 tw-w-full">
					{renderUploader()}
					<div className="divider tw-h-full tw-w-[1px] tw-border-[1px] tw-border-[#E2E2E2] tw-border-solid" />
					{renderColorPalette()}
				</div>
			</div>
		);
	};

	/**
	 * Function to render CTA buttons in Modal
	 * @returns CTA Buttons Component
	 */
	const renderCTAButtons = () => {
		/**
		 * Function to handle click event on cancel button
		 */
		const onCancel = () => onClose();

		/**
		 * Function to handle click event on reset button
		 */
		const onReset = () => {
			resetModalRefs();
			setLocalThumbnail(originalThumbnail);
		};

		/**
		 * Function to handle click event on save button
		 */
		const onSave = () => {
			/**
			 * Function to return without making any change
			 */
			const onReturnWithoutChange = () => {
				setLoaderState(null);
				onClose();
			};

			setLoaderState('UPDATING');

			// If there's no change in thumbnail
			if (localThumbnail === thumbnail && !displayCropWindowRef.current) {
				onReturnWithoutChange();
				return;
			}

			// If crop window is visible that means image is uploaded and edited
			if (displayCropWindowRef.current) {
				if (
					!hiddenCanvasRef.current ||
					!thumbnailRef.current ||
					!thumbnailParentRef.current
				) {
					onReturnWithoutChange();
					return;
				}

				const canvas = hiddenCanvasRef.current;
				const ctx = canvas.getContext('2d');

				if (!ctx) {
					onReturnWithoutChange();
					return;
				}

				const image = thumbnailRef.current;
				const imageRect = image.getBoundingClientRect();
				const imageContainer = thumbnailParentRef.current;
				const imageContainerRect = imageContainer.getBoundingClientRect();

				// Calculate the scaling factors
				const scaleX = image.naturalWidth / image.width;
				const scaleY = image.naturalHeight / image.height;

				// Get the crop frame dimensions
				const cropFrame = cropFrameDimensionsRef.current;
				const cropWidth = cropFrame.width;
				const cropHeight = cropFrame.height;

				const canvasResolutionMultiplier = 5;
				// Set the canvas dimensions to match the cropped area
				canvas.width = cropWidth * canvasResolutionMultiplier;
				canvas.height = cropHeight * canvasResolutionMultiplier;

				// Calculate the correct source coordinates and dimensions
				const sourceX = cropFrame.x * scaleX;
				const sourceY = cropFrame.y * scaleY;
				const sourceWidth = cropWidth * scaleX;
				const sourceHeight = cropHeight * scaleY;

				// Clear the canvas before drawing
				ctx.clearRect(0, 0, canvas.width, canvas.height);
				// Set the fill color to white
				ctx.fillStyle = 'white';
				// Fill the entire canvas with white color
				ctx.fillRect(0, 0, canvas.width, canvas.height);

				// Calculate the horizontal gap between container and image i.e. for horizontal images
				const horizontalGap =
					(image.width < imageContainer.clientWidth
						? imageRect.x - imageContainerRect.x
						: 0) * scaleX;
				// Calculate the vertical gap between container and image i.e. for vertical images
				const verticalGap =
					(image.height < imageContainer.clientHeight
						? imageRect.y - imageContainerRect.y
						: 0) * scaleY;

				// Draw the cropped image onto the canvas
				ctx.drawImage(
					image,
					sourceX - horizontalGap,
					sourceY - verticalGap,
					sourceWidth,
					sourceHeight,
					0,
					0,
					cropWidth * canvasResolutionMultiplier,
					cropHeight * canvasResolutionMultiplier
				);

				// Create a blob object from the canvas
				canvas.toBlob((blob) => {
					if (blob) {
						const fileName = `${uploadedFileNameRef.current}-${Date.now()}`;
						// Convert blob to file
						const file = new File([blob], `${fileName}.png`, {
							type: blob.type
						});
						const { projectId } = generateIdsFromUrl();
						const thumbnailLocation =
							variant === 'PROJECT'
								? `${id}/${fileName}.png`
								: `${projectId}/THUMBNAIL/${fileName}_thumbnail.png`;
						// Upload the blob to Firebase
						const storageRef = ref(storage, thumbnailLocation);
						const uploadTask = uploadBytesResumable(storageRef, file);
						uploadTask.then(() => {
							getDownloadURL(storageRef)
								.then((url: string) => {
									// Use new Firebase URL and send it to the callback
									onThumbnailEdit(url);
								})
								.catch((e: Error) => {
									console.error(
										'Failed to upload cropped image to Firebase: ',
										e
									);
								});
						});
					}
				}, 'image/jpeg');
			} else {
				// Else a color is selected from the palette
				onThumbnailEdit();
			}
		};

		return (
			<div className="cta-buttons-container tw-flex tw-justify-between tw-items-center tw-w-full">
				<div
					role="presentation"
					className="reset-button tw-flex tw-justify-center tw-items-center tw-px-4 tw-py-2 tw-rounded-2xl 
					hover:tw-bg-[#F8F5FD] tw-text-black hover:tw-text-naya tw-cursor-pointer 
					tw-text-sm tw-leading-4 tw-font-randMedium"
					onClick={() => onReset()}
					data-testid="reset-thumbnail-btn"
				>
					Reset
				</div>

				<div className="tw-flex tw-justify-center tw-items-center tw-gap-2">
					<div
						role="presentation"
						className="cancel-button tw-flex tw-justify-center tw-items-center tw-px-4 tw-py-2 tw-rounded-2xl 
						hover:tw-bg-[#F8F5FD] tw-text-black hover:tw-text-naya tw-cursor-pointer 
						tw-flex-1 tw-text-sm tw-leading-4 tw-font-randMedium"
						onClick={() => onCancel()}
						data-testid="cancel-thumbnail-btn"
					>
						Cancel
					</div>
					<div
						role="presentation"
						className={`save-button tw-flex tw-justify-center tw-items-center tw-px-4 tw-py-2 tw-rounded-2xl 
						tw-bg-[#4F00C1] hover:tw-bg-[#560AC3] tw-cursor-pointer tw-flex-1 
						tw-text-sm tw-leading-4 tw-font-randMedium tw-text-white 
						${classNames({
							'tw-opacity-50': loaderState === 'UPDATING',
							'disabled !tw-cursor-pointer': loaderState === 'LOADING'
						})}`}
						onClick={() => onSave()}
						data-testid="save-thumbnail-btn"
					>
						{loaderState === 'UPDATING' ? <SyncLoader size={5} color="#fff" /> : 'Save'}
					</div>
				</div>
			</div>
		);
	};

	return (
		<ControlModal open>
			<ControlModal.ContentWrap
				id="edit-thumbnail-modal"
				data-testid="edit-thumbnail-modal"
				close={() => onClose()}
				width={variant === 'PROJECT' ? 498 : 482}
			>
				<ControlModal.Content>
					<div
						ref={modalContainerRef}
						className="tw-flex tw-justify-center tw-items-center tw-flex-col 
					tw-px-4 tw-py-4 tw-rounded-2xl tw-bg-white tw-gap-4"
					>
						{renderModalHeader()}
						{renderThumbnailComponent()}
						{renderUploadPicker()}
						{renderCTAButtons()}
					</div>
				</ControlModal.Content>
			</ControlModal.ContentWrap>
		</ControlModal>
	);
};

export default EditThumbnailModal;
