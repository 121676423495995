import React, { ChangeEvent, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src';
import { validateEmail } from 'src/util/helper/email-validation';
import { loginPWD } from 'src/redux/actions/auth';
import { AuthComponentContext } from '../../AuthComponent';
import { DefaultLoginForm } from '../../AuthComponent.types';
import { LoginProps } from './Login.types';
import './Login.scss';

const Login: React.FC<LoginProps> = () => {
	const [form, setForm] = useState<DefaultLoginForm>({
		email: '',
		password: ''
	});
	const history = useHistory();
	const authContext = useContext(AuthComponentContext);
	const { setAuthView, setError, error, close } = authContext;
	const dispatch = useDispatch<AppDispatch>();

	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		e.stopPropagation();
		if (!e.target) return;

		setForm((prev) => ({
			...prev,
			[e.target.dataset.property!]: e.target.value
		}));
	};

	const onSubmit = async () => {
		setAuthView('LOADING');
		const { email, password } = form;
		const payload = {
			data: {
				email,
				password
			},
			next: () => {
				window.location.reload();
			}
		};
		dispatch(loginPWD(payload))
			.unwrap()
			.catch(() => {
				console.log('in catch error');
				history.push('/error?msg=login failed. Please try again');
			});
	};

	const validForm = useMemo(() => validateEmail(form.email) && !!form.password, [form]);

	const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if ((e.key === 'Enter' || e.keyCode === 13) && validForm) onSubmit();
	};

	return (
		<div id="login">
			<div className="form-group">
				<input
					placeholder="Email"
					title="Email"
					aria-label="Email"
					type="email"
					required
					data-property="email"
					onChange={onChange}
					onKeyDown={onKeyDown}
				/>
				<input
					placeholder="Password"
					type="password"
					onChange={onChange}
					title="Password"
					aria-label="Password"
					required
					data-property="password"
					onKeyDown={onKeyDown}
				/>

				<button
					className="auth-primary-btn"
					type="submit"
					onClick={onSubmit}
					disabled={!validForm}
				>
					Sign In
				</button>
			</div>
			<p className="error">{error}</p>
			<div className="other-options">
				<span>
					Don&apos;t have an account?&nbsp;
					<button
						type="button"
						data-type="underline"
						onClick={() => {
							if (window.location.pathname === '/register') {
								setAuthView('REGISTER');
								setError('');
							} else {
								close();
								history.push('/scheduleDemo');
							}
						}}
					>
						{window.location.pathname === '/register'
							? 'Create one'
							: 'Join the Waitlist'}
					</button>
				</span>
			</div>
		</div>
	);
};

export default Login;
