import { IconWithTooltip } from '@naya_studio/radix-ui';
import { ReactComponent as BrandLogo } from 'src/assets/icons/navbar/Brand.svg';
import { useHistory } from 'react-router-dom';
import { EUserType } from '@naya_studio/types';
import useUser from 'src/redux/hooks/user';
import { useContext } from 'react';
import { CollaborationToolContext } from '../../CollaborationTool';

/**
 * Component to render Naya Logo in Journey Navbar
 * @renders Naya Logo
 */
const NayaLogo = () => {
	const { onCollabUnMount } = useContext(CollaborationToolContext);
	const { user: currentUser } = useUser();
	const history = useHistory();

	const isGuestUser = Boolean(currentUser._id && currentUser.userType?.includes(EUserType.GUEST));

	/**
	 * Function to handle logo click
	 */
	const onLogoClick = () => {
		if (currentUser._id && !isGuestUser) {
			history.push('/studio');
		} else {
			window.location.href = '/';
		}
	};

	return (
		<div
			className="naya-logo tw-h-10 tw-cursor-pointer"
			role="presentation"
			onClick={() => onCollabUnMount(onLogoClick)}
		>
			<IconWithTooltip
				tooltipText={isGuestUser ? 'Naya Studio' : 'Go to Studio'}
				side="bottom"
				delayDuration={200}
				classNames="naya-logo-tooltip tw-relative tw-left-4"
			>
				<BrandLogo width={40} height={40} />
			</IconWithTooltip>
		</div>
	);
};

NayaLogo.defaultProps = {
	isGuestUser: false
};

export default NayaLogo;
