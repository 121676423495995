import { IBlock } from '@naya_studio/types';
import { store } from 'src';

/**
 * Function to get Block from redux
 * @param blockId - ID of the Block
 * @returns Block
 */
const getBlockFromReduxById = (blockId: string) => {
	const { blocks } = store.getState();
	const block = blocks.data[blockId] as IBlock;
	return block;
};

export default getBlockFromReduxById;
