import { INotification, ISentNotification } from '@naya_studio/types';
import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';
import { notificationRouter } from 'src/endpoints/projects-api';
import { ReduxState } from 'src/redux/reducers/root.types';
import { getGatewayKey } from 'src/util/helper/queryString';

const qs = `?${getGatewayKey()}`;

export type TNotifState = {
	[key: string]: INotification | ISentNotification;
};
export type TNotifRTKData = {
	notifs: TNotifState;
	currentPage: number;
	allNotifsLength: number;
};

export const notificationSlice = createApi({
	reducerPath: 'notifications',
	baseQuery: fetchBaseQuery({ baseUrl: `${notificationRouter}`, credentials: 'include' }),
	tagTypes: ['Notifs'],
	endpoints: (builder) => ({
		/**
		 * Fetches all notifs for the user
		 */
		getNotifs: builder.query({
			query: ({ userId, page }) => `/user/${userId}/${page}${qs}`,
			transformResponse: ({
				notifs: notifResponse,
				currentPage,
				allNotifsLength
			}: {
				notifs: INotification[];
				currentPage: number;
				allNotifsLength: number;
			}) => {
				const notifData = {} as { [key: string]: INotification };
				notifResponse.forEach((notif: INotification) => {
					const notifId = notif._id as string;
					notifData[notifId] = notif;
				});
				const allNotifData: TNotifRTKData = {
					notifs: notifData,
					currentPage,
					allNotifsLength
				};
				return allNotifData;
			},
			providesTags: ['Notifs']
		}),
		/**
		 * Creates a new notification
		 */
		createNotif: builder.mutation({
			query: (notifData) => ({
				url: `/${qs}`,
				method: 'POST',
				body: notifData
			})
		}),
		/**
		 * Marks notif as complete/incomplete
		 */
		toggleNotifComplete: builder.mutation({
			query: (notifData) => ({
				url: `/toggle-complete${qs}`,
				method: 'PUT',
				body: {
					payload: notifData
				}
			}),
			onQueryStarted: async (
				{ isComplete, feedbackId, notifId },
				{ dispatch, queryFulfilled, getState }
			) => {
				const { _id: userId } = (getState() as ReduxState).user.data;
				const getNotifsData = notificationSlice.endpoints.getNotifs.select({
					userId,
					page: 1
				})(getState());
				const notifsArr = Object.values(getNotifsData.data?.notifs || {});
				const notifsToUpdate = feedbackId
					? notifsArr.filter((n) => n.data?.feedbackId === feedbackId)
					: notifsArr.filter((n) => n._id === notifId);

				try {
					const patchResult = dispatch(
						notificationSlice.util.updateQueryData(
							'getNotifs',
							{ userId, page: 1 },
							(draft) => {
								notifsToUpdate.forEach((n) => {
									Object.assign(draft.notifs[n._id as string] as INotification, {
										...n,
										sent: {
											...n.sent,
											inApp: isComplete
										},
										completedAt: isComplete ? new Date().toISOString() : null
									});
								});
							}
						)
					);
					try {
						await queryFulfilled;
					} catch {
						patchResult.undo();
					}
				} catch (error) {
					console.log('Error', error);
				}
			}
		}),
		updateFeebackNotif: builder.mutation({
			query: (notifData) => ({
				url: `/notif-feedback${qs}`,
				method: 'PUT',
				body: {
					payload: notifData
				}
			}),
			transformResponse: ({ notif }) => notif
		}),
		updateMultipleNotifs: builder.mutation({
			query: (notifData) => ({
				url: `/multiple${qs}`,
				method: 'PUT',
				body: {
					payload: notifData
				}
			}),
			onQueryStarted: async (
				{ searchBy, update, operation },
				{ dispatch, queryFulfilled, getState }
			) => {
				const { _id: userId } = (getState() as ReduxState).user.data;
				const getNotifsData = notificationSlice.endpoints.getNotifs.select({
					userId,
					page: 1
				})(getState());
				const notifsArr = Object.values(getNotifsData.data?.notifs || {});

				try {
					const patchResult = dispatch(
						notificationSlice.util.updateQueryData(
							'getNotifs',
							{ userId, page: 1 },
							(draft) => {
								switch (operation) {
									case 'PROJECT_RENAME': {
										const projectId = searchBy['data.projectId'];
										const notifsToUpdate = notifsArr.filter(
											(n) => n.data?.projectId === projectId
										);

										notifsToUpdate.forEach((n) => {
											Object.assign(
												draft.notifs[n._id as string] as INotification,
												{
													...n,
													data: {
														...n.data,
														projectName: update['data.projectName']
													}
												}
											);
										});
										break;
									}
									case 'BLOCK_RENAME': {
										const blockId = searchBy['data.blockId'];
										const notifsToUpdate = notifsArr.filter(
											(n) => n.data?.blockId === blockId
										);

										notifsToUpdate.forEach((n) => {
											Object.assign(
												draft.notifs[n._id as string] as INotification,
												{
													...n,
													data: {
														...n.data,
														blockName: update['data.canvasName'],
														canvasName: update['data.canvasName']
													}
												}
											);
										});
										break;
									}
									case 'NOTIFICATION_COMPLETE': {
										const blockId = searchBy['data.blockId'];
										const notifsToUpdate = notifsArr.filter(
											(n) =>
												n.data?.blockId === blockId &&
												n.eventType === searchBy.eventType
										);

										notifsToUpdate.forEach((n) => {
											Object.assign(
												draft.notifs[n._id as string] as INotification,
												{
													...n,
													sent: {
														...n.sent,
														inApp: update['sent.inApp']
													},
													completedAt: update.completedAt
												}
											);
										});
										break;
									}
									default:
										break;
								}
							}
						)
					);
					try {
						await queryFulfilled;
					} catch {
						patchResult.undo();
					}
				} catch (error) {
					console.error('Error updating multiple notifications: ', error);
				}
			}
		}),
		deleteMultipleNotifs: builder.mutation({
			query: (notifData) => ({
				url: `/del-multiple${qs}`,
				method: 'PUT',
				body: {
					payload: notifData
				}
			})
		})
	})
});
export const {
	useGetNotifsQuery,
	useCreateNotifMutation,
	useToggleNotifCompleteMutation,
	useUpdateFeebackNotifMutation,
	useUpdateMultipleNotifsMutation,
	useDeleteMultipleNotifsMutation
} = notificationSlice;
