import { IFeedback, IUser, TUserAndRole } from '@naya_studio/types';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { getAllUsersBasedOnIds } from 'src/redux/reduxActions/util';
import {
	Button,
	HtmlTextViewer,
	FeedbackInput,
	FeedbackWrapper,
	UserT,
	WarningModal
} from '@naya_studio/radix-ui';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'src/redux/reducers/root.types';
import useFeedbackActions from 'src/util/feedback/useFeedbackActions';
import { useParams } from 'react-router';
import { PathParamsType } from 'src/types/pathParams';
import useUser from 'src/redux/hooks/user';
import { ActionItemPanel } from '../actionItemPanel/ActionItemPanel';
import { FeedbackKebabMenuProps, FeedbackUserMenu } from '../feedbackUserMenu/FeedbackUserMenu';
import { getFormattedUsersData } from '../util';

// props for action item
export type ActionItemProps = {
	data: IFeedback;
	onClose: () => void;
};

// Action item of feedback
export const ActionItem = ({ data, onClose }: ActionItemProps) => {
	const [isEditing, setIsEditing] = useState(false); // handles the edit state
	const [feedbackText, setFeedbackText] = useState(''); // holds the text for the particular chat
	const [isCompleted, setIsCompleted] = useState(false); // handles the toggle of mark complete action
	const [selectedUsers, setSelectedUsers] = useState<string[]>([]); // holds the list of all the users selected for action item assignees

	// extract current user
	const { user } = useUser();

	// extarcting functions from feedback actions hook
	const {
		sendEditFeedback,
		sendDeleteFeedback,
		removeActionItemAssignee,
		addActionItemAssignee,
		extractTextFromHTML
	} = useFeedbackActions();

	// handles the visibility of delete warning modal
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	// extracting ids from url
	const { canvasId: blockId } = useParams<PathParamsType>();

	// extract all blocks from redux
	const allBlocks = useSelector((state) => (state as ReduxState).blocks.data, shallowEqual);

	// extract all user role objs in block
	const userRoles = allBlocks[blockId]?.users as TUserAndRole[];

	// extract all populated users based on ids
	const allUsers = getAllUsersBasedOnIds(userRoles.map((x: TUserAndRole) => x.user as string));

	const dispatch = useDispatch();

	// setting data to state variables
	useEffect(() => {
		if (data.statement) setFeedbackText(data.statement);

		if (data.actionItemFor) {
			if (!_.isUndefined(data.actionItemFor[user._id as string])) {
				setIsCompleted(data.actionItemFor[user._id as string] as boolean);
			}

			const allActionItemUsers = Object.keys(data.actionItemFor);
			setSelectedUsers(allActionItemUsers);
		}
	}, [data]);

	// extract user data based o feedback's created by
	const getFeedbackCreatorData = () => {
		const creator = data.createdBy as IUser;
		return {
			name: creator.userName,
			profilePic: creator.profilePic
		};
	};

	// Send out an api call to delete action item
	const onDeleteActionItem = () => {
		setShowDeleteModal(false);

		sendDeleteFeedback(data._id as string);
	};

	// send out an api call to update action item
	const updateActionItem = () => {
		setIsEditing(false);
		sendEditFeedback({
			_id: data._id,
			statement: feedbackText
		});
	};

	// sends out an API call to mark complete
	const onMarkComplete = (val: boolean) => {
		setIsCompleted(val);

		dispatch({
			type: 'MARK_FB_NOTIF_COMP',
			payload: { markFeedbackAsComplete: data._id, isCompleted: val }
		});
	};

	// Sends out an api call updated selected users for action item
	const onModifySelectedUsers = (id: string) => {
		let tempUsers = selectedUsers;
		if (selectedUsers.includes(id)) {
			tempUsers = tempUsers.filter((x) => x !== id);

			removeActionItemAssignee(data._id as string, id);
		} else {
			tempUsers.push(id);

			addActionItemAssignee(data._id as string, id);
		}
		setSelectedUsers(tempUsers);
	};

	// function to modify action item asigneed
	const onTagUser = (selectedIds: string[]) => {
		const tempList = selectedUsers;
		for (let i = 0; i < selectedIds.length; i++) {
			if (!selectedUsers.includes(selectedIds[i] as string)) {
				tempList.push(selectedIds[i] as string);
			}
		}
		setSelectedUsers(tempList);
	};

	// reset feedback text
	const resetfeedbackText = () => {
		if (data.statement) {
			setFeedbackText(data.statement);
		} else {
			setFeedbackText('');
		}
	};

	/**
	 * Renders -
	 * 1. User's profile pic
	 * 2. user's display name
	 * 3. Created at time
	 * 4. Kebab menu
	 */
	const renderUserDetails = () => {
		// generating dropdown options to be passes to kebab menu
		const dropdownOptions: { key: string; callback: () => void }[] = [
			{
				key: 'Edit Action item',
				callback: () => {
					setIsEditing(true);
				}
			},
			{
				key: 'Delete action item',
				callback: () => {
					setShowDeleteModal(true);
				}
			}
		];

		const props: FeedbackKebabMenuProps = {
			options: (data.createdBy as IUser)._id === user._id ? dropdownOptions : undefined,
			profilePic: getFeedbackCreatorData().profilePic || '',
			name: (getFeedbackCreatorData().name as string) || '',
			createdAt: data.createdAt as string
		};
		return <FeedbackUserMenu {...props} />;
	};

	return (
		<FeedbackWrapper className="tw-bg-white">
			<>
				<WarningModal
					show={showDeleteModal}
					onClose={() => setShowDeleteModal(false)}
					onModalSubmit={onDeleteActionItem}
					title="Are you sure?"
					bodyText={<div>Are you sure you want to delete the action item?</div>}
					cancelBtnText="Cancel"
					submitBtnText="Delete"
					showDAA={false}
				/>
				{/* render action item panel */}
				<ActionItemPanel
					markCompleteDisabled={isEditing}
					isCompleted={isCompleted}
					onMarkComplete={onMarkComplete}
					users={allUsers}
					selectedUsers={selectedUsers}
					onModifySelectedUsers={onModifySelectedUsers}
					onClose={onClose}
				/>
				<div className="tw-p-4">
					{renderUserDetails()}
					{isEditing ? (
						<>
							{/* showing input + save and cancel button in edit state */}
							<FeedbackInput
								className="tw-pt-4 tw-pb-4"
								id={data._id as string}
								text={feedbackText}
								placeHolder="Add action item. Use @ to mention"
								onTagUser={onTagUser}
								users={getFormattedUsersData(allUsers, false).blockUsers as UserT[]}
								currentUser={getFormattedUsersData(allUsers, false).currentUser}
								variant="BORDERED"
								onSubmit={updateActionItem}
								onEsc={resetfeedbackText}
								onInputChange={(value: string) => {
									setFeedbackText(value);
								}}
								onShowReplyBtn={() => {}}
								key={data._id as string}
							/>
							<div className="tw-flex tw-mb-4">
								<Button
									variant="PRIMARY"
									text="Save"
									disabled={extractTextFromHTML(feedbackText).length === 0}
									onBtnClick={updateActionItem}
								/>
								<Button
									variant="SECONDARY-GREY"
									text="Cancel"
									disabled={false}
									onBtnClick={() => {
										setIsEditing(false);
										resetfeedbackText();
									}}
								/>
							</div>
						</>
					) : (
						// just text in non edit state
						<HtmlTextViewer text={feedbackText} className="tw-pt-4 tw-text-xs" />
					)}
				</div>
			</>
		</FeedbackWrapper>
	);
};
