import { FC, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { loginEndpoint } from 'src/endpoints/authentication-api';
import { WorkOS } from '@workos-inc/node';
import { isLocalhost } from 'src/util/collaboration/util';
import { AuthComponent } from '../authComponent/AuthComponent';
import { LoginComponentProps } from './Login.types';
import './Login.scss';
import { AuthContext } from '../auth/AuthProvider';
import { getGatewayKey } from '../../util/helper/queryString';

const qs = `?${getGatewayKey()}`;

const LoginComponent: FC<LoginComponentProps> = ({ type }) => {
	const auth = useContext(AuthContext);
	const { status } = auth;

	useEffect(() => {
		// If status is SIGNED_OUT and route is LOGIN, then redirect to login workOs auth screen
		if (['SIGNED_OUT', 'GUEST'].includes(status) && type === 'LOGIN') {
			const isI2R = window.sessionStorage.getItem('i2r');
			const workos = new WorkOS(process.env.REACT_APP_WORKOS_API_KEY);
			const clientId = process.env.REACT_APP_WORKOS_CLIENT_ID || '';
			const authorizationUrl = workos.userManagement.getAuthorizationUrl({
				// Specify that we'd like AuthKit to handle the authentication flow
				provider: 'authkit',
				// The callback endpoint that WorkOS will redirect to after a user authenticates
				redirectUri: `${loginEndpoint}${!isLocalhost ? qs : ''}`,
				clientId,
				state: encodeURIComponent(`${window.location.href}${isI2R ? '?isI2R=true' : ''}`)
			});
			window.location.href = authorizationUrl;
		}
	}, [status]);

	return (
		<>
			{/* Set the title that is shown in browser tab */}
			<Helmet>
				<title>Login - Naya</title>
			</Helmet>
			<div id="login-component">
				<AuthComponent
					show
					close={() => {}}
					view={type}
					redirectTo={window.location.href}
				/>
			</div>
		</>
	);
};

export default LoginComponent;
