import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ReduxState } from 'src/redux/reducers/root.types';
import './Delete.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { DeleteOptionProps, DeleteOptionDispatchProps, PathParamsType } from './Delete.types';
import deleteIcon from '../../../../../assets/icons/toolbar/delete.svg';

class DeleteOption extends React.Component<DeleteOptionProps & DeleteOptionDispatchProps & RouteComponentProps<PathParamsType>> {
  render() {
    return (
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 1000, hide: 0 }}
        overlay={(
          <Tooltip
            id="button-tooltip-2"
            style={{
              fontSize: '12px',
              borderRadius: '2px',
              lineHeight: '16px',
              marginTop: '8px',
            }}
          >
            Delete
          </Tooltip>
      )}
      >
        <div className="icon-container">
          <img
            src={deleteIcon}
            alt="delete"
            className="icon"
            onClick={(e: any) => {
              e.stopPropagation();
              this.props.app.deleteNodes();
            }}
          />
        </div>
      </OverlayTrigger>

    );
  }
}

/**
 * Redux state mapped to canvas props
 * @param state
 * @returns
 */
// @ts-ignore
const mapStateToProps = (state: ReduxState) => ({
});

/**
 * Redux actions mapped to canvas props
 * @param dispatch
 * @returns
 */
// @ts-ignore
const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteOption));
