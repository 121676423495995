import { ActionReducerMapBuilder, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IBlock, IGroup, INote, IUser, TInvitedEmail, TUserAndRole } from '@naya_studio/types';
import {
	TActionType,
	TAddMultipleBlockArgs,
	TAddSingleBlockArgs,
	TAddStageFulfill,
	TAddStageThunkArg,
	TAddUserToProjectFulfill,
	TAddUserToProjectThunkArg,
	TAddUserToStageFulfill,
	TAddUserToStageThunkArg,
	TCreateNewStageFromBlockThunkArg,
	TBatchCreateGroupAndBlocks,
	TCreateStageWithBlocksFulfill,
	TDeleteBlockArgs,
	TDeleteGroupThunkArg,
	TDuplicateBlockArgs,
	TDuplicateProjectFulfill,
	TDuplicateProjectOptimisticallyThunkArg,
	TDuplicateStageFulfill,
	TDuplicateStageThunkArg,
	TEditStageFulfill,
	TEditStageThunkArg,
	TEditUserRoleForProjectFulfill,
	TEditUserRoleForProjectThunkArg,
	TEditUserRoleForStageFulfill,
	TEditUserRoleForStageThunkArg,
	TLoadProjectByIdFulfill,
	TLoadStageThunkArg,
	TRemoveUserFromBlockBlockArgs,
	TRemoveUserFromProjectFulfill,
	TRemoveUserFromProjectThunkArg,
	TRemoveUserFromStageFulfill,
	TRemoveUserFromStageThunkArg,
	TReorderBlocksThunkArg,
	TUndoRedoStageArgs,
	TUndoRedoArgs,
	TAddNoteArg,
	TDeleteNoteArg,
	TBulkUpdateGroupArgs,
	TUndoDeleteFufill,
	TNestingThunkArgs,
	TLoadTemplateByIdFulfill
} from 'src/types/argTypes';
import { isNumber } from 'lodash';
import {
	addStage,
	addUserToStage,
	createNewStageFromOrphanBlock,
	batchCreateGroupAndBlocks,
	deleteStage,
	duplicateStage,
	editStage,
	editUserRoleForStage,
	loadStage,
	removeUserFromStage,
	reorderBlocks,
	undoRedoStage,
	bulkUpdateGroups
} from '../reduxActions/stage';
import { IGroupsInitState } from '../reducers/root.types';
import {
	addUserToProject,
	duplicateProject,
	duplicateProjectOptimistically,
	editUserRoleForProject,
	loadProjectById,
	loadProjectForGuestAction,
	loadTemplateById,
	nestingAction,
	removeUserFromProject
} from '../reduxActions/project';
import {
	addMultipleBlocks,
	addSingleBlock,
	deleteBlock,
	duplicateBlock,
	removeUserFromBlock,
	undoDelete
} from '../reduxActions/block';

import { addUserInArray, removeUserFromArray } from './util';
import { addNote, deleteNote } from '../reduxActions/notes';

/**
 * Initial State of Stages in Redux
 */
const initialState: IGroupsInitState = {
	data: {},
	loading: {},
	error: {}
};

/**
 * Stages Reducer Slice
 */
const stagesSlice = createSlice({
	name: 'stages',
	initialState,
	reducers: {
		/**
		 * Reducer to add a stage
		 */
		addStagesToRedux: (state: IGroupsInitState, action: PayloadAction<IGroup[]>) => {
			const stages = action.payload;
			stages.forEach((s: IGroup) => {
				state.data[s._id as string] = {
					...state.data[s._id as string],
					...s
				};
			});
		},
		/**
		 * Reducer to edit a stage by id
		 */
		editStageById: (state: IGroupsInitState, action: PayloadAction<IGroup>) => {
			state.data[action.payload._id as string] = {
				...state.data[action.payload._id as string],
				...action.payload
			};
		},
		/**
		 * Reducer to delete a stage using id
		 */
		deleteStageById: (state: IGroupsInitState, action: PayloadAction<string>) => {
			delete state.data[action.payload as string];
		},
		/**
		 * Reducer to unload stages from redux
		 */
		unloadStages: (state: IGroupsInitState, action: PayloadAction<string>) => {
			const projectId = action.payload;

			const stagesToDelete = [];
			const keys = Object.keys(state.data);
			for (let i = 0; i < keys.length; i++) {
				const stageId = keys[i] as string;
				if (state.data[stageId]!.projectId === projectId) stagesToDelete.push(stageId);
			}

			stagesToDelete.forEach((id) => delete state.data[id]);
		},

		/**
		 * Add temporary notes to blocks - while adding note to blocks in multiselect
		 */
		addTempNotesOnGroups: (state: IGroupsInitState, action: PayloadAction<string[]>) => {
			const selectedGroupIds = action.payload;
			selectedGroupIds.forEach((selGroupId) => {
				if (state.data[selGroupId] && !state.data[selGroupId]?.notes) {
					state.data[selGroupId]!.notes = [`NEW_NOTE_MULTI_${selGroupId}`];
				} else if (
					!state.data[selGroupId]?.notes?.includes(`NEW_NOTE_MULTI_${selGroupId}`)
				) {
					state.data[selGroupId]?.notes?.push(`NEW_NOTE_MULTI_${selGroupId}`);
				}
			});
		},
		/**
		 * Remove temporary notes to groups - while removing notes from groups in multiselect
		 */
		removeTempNotesOnGroups: (state: IGroupsInitState, action: PayloadAction<string[]>) => {
			const selectedGroupIds = action.payload;
			selectedGroupIds.forEach((selGroup) => {
				state.data[selGroup]!.notes = state.data[selGroup]?.notes?.filter(
					(n) => !(n as string)?.includes('NEW_NOTE')
				);
			});
		}
	},
	extraReducers: (builder: ActionReducerMapBuilder<IGroupsInitState>) => {
		/** ---- LOAD STAGE ---- */
		// PENDING
		builder.addCase(
			loadStage.pending,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TLoadStageThunkArg>['pendingMeta']
				>
			) => {
				const { _id } = action.meta.arg.payload;
				// reset the error just in case previous call has some error
				state.error[_id] = null;
				state.loading[_id] = true;
			}
		);
		// FULFILLED = loading nodes to redux once API is fulfilled
		builder.addCase(
			loadStage.fulfilled,
			(
				state: IGroupsInitState,
				action: PayloadAction<TActionType<{ stage: IGroup }, {}>['payload']>
			) => {
				const stage = action.payload.stage as IGroup;
				state.data[stage._id as string] = stage;
				state.loading[stage._id as string] = false;
				state.error[stage._id as string] = null;
			}
		);
		// REJECTED
		builder.addCase(
			loadStage.rejected,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TLoadStageThunkArg>['rejectedMeta']
				>
			) => {
				const { _id } = action.meta.arg.payload;
				state.error[_id] = action.payload as string;
				state.loading[_id] = false;
			}
		);

		/** ---- ADD STAGE ---- */
		// FULFILLED
		builder.addCase(
			addStage.fulfilled,
			(
				state: IGroupsInitState,
				action: PayloadAction<TActionType<TAddStageFulfill, {}>['payload']>
			) => {
				const { payload } = action;

				if (payload) {
					const stage = payload.stage as IGroup;
					const stageId = stage._id as string;

					if (!state.data[stageId]) state.data[stageId] = stage;
					state.loading[stageId] = false;
					state.error[stageId] = null;
				}
			}
		);

		// PENDING
		builder.addCase(
			addStage.pending,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TAddStageThunkArg>['pendingMeta']
				>
			) => {
				const {
					payload: { stage, target, newPhaseIndex }
				} = action.meta.arg;

				if (stage) {
					const stageId = stage._id as string;
					state.data[stageId] = stage;
					state.loading[stageId] = true;
					state.error[stageId] = null;
				}
				// If a new nested phase is added inside a phase, add the new phase id to the target phase.
				if (target.type === 'PHASE' && target.id && stage._id && state.data[target.id]) {
					state.data[target.id]?.children.splice(newPhaseIndex, 0, stage._id.toString());
					state.data[target.id]!.isPhaseCreated = true;
				}
			}
		);
		// REJECTED
		builder.addCase(
			addStage.rejected,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TAddStageThunkArg>['rejectedMeta']
				>
			) => {
				const {
					payload: { stage, target, newPhaseIndex }
				} = action.meta.arg;
				const { payload: error } = action;

				if (stage) {
					const stageId = stage._id as string;

					if (state.data[stageId]) {
						delete state.data[stageId];
					}

					state.error[stageId] = error as string;
					state.loading[stageId] = false;
				}

				// If a new nested phase was add but the action got rejected remove the added phase._id from targetPhase children.
				if (target.type === 'PHASE' && target.id && stage._id) {
					state.data[target.id]?.children.splice(newPhaseIndex, 1);
				}
			}
		);

		/** ---- EDIT STAGE ---- */
		// FULFILLED
		builder.addCase(
			editStage.fulfilled,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					TActionType<TEditStageFulfill, {}>['payload'],
					string,
					TActionType<{}, TEditStageThunkArg>['fulfilledMeta']
				>
			) => {
				const { id, update } = action.meta.arg.payload;
				const { stage } = action.payload;

				if (update) {
					const reduxStage = state.data[id];
					const updatedAfter =
						new Date(stage!.updatedAt as string) >
						new Date(reduxStage!.updatedAt as string);
					if (reduxStage && updatedAfter) {
						const stageWithUpdate = {
							...reduxStage,
							...update
						};
						state.data[id] = stageWithUpdate;
					}

					state.loading[id] = false;
					state.error[id] = null;
				}
			}
		);
		// PENDING
		builder.addCase(
			editStage.pending,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TEditStageThunkArg>['pendingMeta']
				>
			) => {
				const { payload } = action.meta.arg;

				if (payload) {
					const { id, update } = payload;

					if (state.data[id]) {
						state.data[id] = {
							...(state.data[id] as IGroup),
							isPhaseCreated: state.data[id]?.isPhaseCreated || false,
							...update
						};
						state.loading[id] = true;
						state.error[id] = null;
					}
				}
			}
		);
		// REJECTED
		builder.addCase(
			editStage.rejected,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TEditStageThunkArg>['rejectedMeta']
				>
			) => {
				const { payload, prevState } = action.meta.arg;
				const { payload: error } = action;

				if (payload) {
					const { id } = payload;
					const { prevStage } = prevState;

					if (state.data[id]) {
						state.data[id] = prevStage;
					}

					state.error[id] = error as string;
					state.loading[id] = false;
				}
			}
		);

		/** ---- DUPLICATE STAGE ---- */
		// PENDING
		builder.addCase(
			duplicateStage.pending,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TDuplicateStageThunkArg>['pendingMeta']
				>
			) => {
				const {
					clonedItems,
					parent,
					insertIndex: position,
					clonedGroupId: newStageId
				} = action.meta.arg.payload;
				const { groups: clonedStages } = clonedItems;

				if (clonedStages) {
					clonedStages.forEach((clonedStage) => {
						const stageId = clonedStage._id as string;
						state.data[stageId] = clonedStage;
						state.error[stageId] = null;
					});
				}

				// Add the duplicated phase
				if (parent.type === 'PHASE' && isNumber(position)) {
					state.data[parent.id]?.children.splice(position, 0, newStageId);
				}
			}
		);
		// FULFILLED
		builder.addCase(
			duplicateStage.fulfilled,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					TActionType<TDuplicateStageFulfill, {}>['payload'],
					string,
					TActionType<{}, TDuplicateStageThunkArg>['fulfilledMeta']
				>
			) => {
				const {
					parent,
					insertIndex: position,
					clonedGroupId: newStageId
				} = action.meta.arg.payload;
				const { position: finalPosition } = action.payload;
				// if finalPosition received from back-end is not same as the position in the front-end
				// then remove the duplicated phase from it's old position and move to final position.
				if (
					isNumber(position) &&
					isNumber(finalPosition) &&
					parent.type === 'PHASE' &&
					position !== finalPosition
				) {
					state.data[parent.id]?.children.splice(position, 1);
					state.data[parent.id]?.children.splice(finalPosition, 0, newStageId);
				}
			}
		);
		// REJECTED
		builder.addCase(
			duplicateStage.rejected,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TDuplicateStageThunkArg>['rejectedMeta']
				>
			) => {
				const { payload: error } = action;
				const { clonedItems, parent, clonedGroupId: newStageId } = action.meta.arg.payload;
				const { groups: clonedStages } = clonedItems;

				if (clonedStages) {
					clonedStages.forEach((clonedStage) => {
						const stageId = clonedStage._id as string;

						if (state.data[stageId]) delete state.data[stageId];

						state.loading[stageId] = false;
						state.error[stageId] = error as string;
					});
				}

				// Add the duplicated phase
				if (parent.type === 'PHASE' && state.data[parent.id]) {
					const position = state.data[parent.id]?.children.indexOf(newStageId);
					if (position && position !== -1) {
						state.data[parent.id]?.children.splice(position, 1);
					}
				}
			}
		);
		/** ---- DUPLICATE PHASE ---- */

		/** ---- DELETE STAGE ---- */
		// FULFILLED
		builder.addCase(
			deleteStage.fulfilled,
			(
				state: IGroupsInitState,
				action: PayloadAction<TActionType<TDeleteGroupThunkArg['payload'], {}>['payload']>
			) => {
				const { stageId } = action.payload;

				if (stageId) {
					state.loading[stageId] = false;
					state.error[stageId] = null;
				}
			}
		);
		// PENDING
		builder.addCase(
			deleteStage.pending,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TDeleteGroupThunkArg>['pendingMeta']
				>
			) => {
				const { stageId, childrens } = action.meta.arg.payload;

				if (state.data[stageId]) delete state.data[stageId];
				state.loading[stageId] = true;
				state.error[stageId] = null;

				childrens?.forEach((id) => {
					const isGroup = state.data[id];

					if (isGroup) {
						state.data[id]!.isVisible = false;
						state.loading[id] = false;
						state.error[id] = null;
					}
				});
			}
		);
		// REJECTED
		builder.addCase(
			deleteStage.rejected,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TDeleteGroupThunkArg>['rejectedMeta']
				>
			) => {
				const { payload, prevState } = action.meta.arg;
				const { payload: error } = action;

				if (payload && prevState) {
					const { stageId } = payload;
					const { group } = prevState;
					if (group) state.data[stageId] = group;
					state.loading[stageId] = false;
					state.error[stageId] = error as string;
				}
			}
		);

		/** ---- ADD USER TO STAGE ---- */
		// FULFILLED
		builder.addCase(
			addUserToStage.fulfilled,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					TActionType<TAddUserToStageFulfill, {}>['payload'],
					string,
					TActionType<{}, TAddUserToStageThunkArg>['fulfilledMeta']
				>
			) => {
				const { payload } = action.meta.arg;
				if (payload) {
					const { email, userId, isInvitedUser, stageIds, role } = payload;
					stageIds.forEach((stageId) => {
						if (isInvitedUser) {
							const invitedEmails = state.data[stageId]
								?.invitedEmails as TInvitedEmail[];
							const updatedInvitedEmails = addUserInArray(
								isInvitedUser,
								email,
								role,
								invitedEmails
							);
							state.data[stageId]!.invitedEmails =
								updatedInvitedEmails as TInvitedEmail[];
						} else {
							const users = state.data[stageId]?.users as TUserAndRole[];
							const updatedUsers = addUserInArray(
								isInvitedUser,
								userId as string,
								role,
								users
							);
							state.data[stageId]!.users = updatedUsers as TUserAndRole[];
						}
					});
				}
			}
		);
		// PENDING
		builder.addCase(
			addUserToStage.pending,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TAddUserToStageThunkArg>['pendingMeta']
				>
			) => {
				const { payload } = action.meta.arg;
				if (payload) {
					const { email, userId, isInvitedUser, stageIds, role } = payload;
					stageIds.forEach((stageId) => {
						if (isInvitedUser) {
							const invitedEmails = state.data[stageId]
								?.invitedEmails as TInvitedEmail[];
							const updatedInvitedEmails = addUserInArray(
								isInvitedUser,
								email,
								role,
								invitedEmails
							);
							state.data[stageId]!.invitedEmails =
								updatedInvitedEmails as TInvitedEmail[];
						} else {
							const users = state.data[stageId]?.users as TUserAndRole[];
							const updatedUsers = addUserInArray(
								isInvitedUser,
								userId as string,
								role,
								users
							);
							state.data[stageId]!.users = updatedUsers as TUserAndRole[];
						}

						state.error[stageId] = null;
					});
				}
			}
		);
		// REJECTED
		builder.addCase(
			addUserToStage.rejected,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TAddUserToStageThunkArg>['rejectedMeta']
				>
			) => {
				const { payload } = action.meta.arg;
				if (payload) {
					const { email, userId, isInvitedUser, stageIds } = payload;
					stageIds.forEach((stageId) => {
						if (isInvitedUser) {
							state.data[stageId]!.invitedEmails = removeUserFromArray(
								isInvitedUser,
								email,
								state.data[stageId]!.invitedEmails as TInvitedEmail[]
							) as TInvitedEmail[];
						} else {
							state.data[stageId]!.users = removeUserFromArray(
								isInvitedUser,
								userId as string,
								state.data[stageId]!.users as TUserAndRole[]
							) as TUserAndRole[];
						}

						state.loading[stageId] = false;
						state.error[stageId] = null;
					});
				}
			}
		);

		/** ---- REMOVE USER FROM STAGE ---- */
		// FULFILLED
		builder.addCase(
			removeUserFromStage.fulfilled,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					TActionType<TRemoveUserFromStageFulfill, {}>['payload'],
					string,
					TActionType<{}, TRemoveUserFromStageThunkArg>['fulfilledMeta']
				>
			) => {
				const { payload } = action.meta.arg;

				if (payload) {
					const { invitedUserRole, userId, stageIds } = payload;

					const isInvitedUser = !!invitedUserRole;
					stageIds.forEach((stageId) => {
						if (isInvitedUser) {
							state.data[stageId]!.invitedEmails = removeUserFromArray(
								isInvitedUser,
								userId,
								state.data[stageId]!.invitedEmails as TInvitedEmail[]
							) as TInvitedEmail[];
						} else {
							state.data[stageId]!.users = removeUserFromArray(
								isInvitedUser,
								userId,
								state.data[stageId]!.users as TUserAndRole[]
							) as TUserAndRole[];
						}

						state.error[stageId] = null;
					});
				}
			}
		);
		// PENDING
		builder.addCase(
			removeUserFromStage.pending,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TRemoveUserFromStageThunkArg>['pendingMeta']
				>
			) => {
				const { payload } = action.meta.arg;

				if (payload) {
					const { invitedUserRole, userId, stageIds } = payload;

					const isInvitedUser = !!invitedUserRole;
					stageIds.forEach((stageId) => {
						if (isInvitedUser) {
							state.data[stageId]!.invitedEmails = removeUserFromArray(
								isInvitedUser,
								userId,
								state.data[stageId]!.invitedEmails as TInvitedEmail[]
							) as TInvitedEmail[];
						} else {
							state.data[stageId]!.users = removeUserFromArray(
								isInvitedUser,
								userId,
								state.data[stageId]!.users as TUserAndRole[]
							) as TUserAndRole[];
						}

						state.error[stageId] = null;
					});
				}
			}
		);
		// REJECTED
		builder.addCase(
			removeUserFromStage.rejected,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TRemoveUserFromStageThunkArg>['rejectedMeta']
				>
			) => {
				const { payload } = action.meta.arg;

				if (payload) {
					const { invitedUserRole, userRole, stageIds } = payload;

					const isInvitedUser = !!invitedUserRole;
					stageIds.forEach((stageId) => {
						if (isInvitedUser)
							state.data[stageId]!.invitedEmails!.push(invitedUserRole);
						else if (userRole) state.data[stageId]!.users!.push(userRole);
						state.loading[stageId] = false;
						state.error[stageId] = null;
					});
				}
			}
		);

		/** ---- EDIT USER ROLE FOR STAGE ---- */
		// FULFILLED
		builder.addCase(
			editUserRoleForStage.fulfilled,
			(
				state: IGroupsInitState,
				action: PayloadAction<TActionType<TEditUserRoleForStageFulfill, {}>['payload']>
			) => {
				if (action.payload) {
					const { stageIds } = action.payload;
					stageIds.forEach((stageId) => {
						state.loading[stageId] = false;
						state.error[stageId] = null;
					});
				}
			}
		);
		// PENDING
		builder.addCase(
			editUserRoleForStage.pending,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TEditUserRoleForStageThunkArg>['pendingMeta']
				>
			) => {
				const { payload } = action.meta.arg;

				if (payload) {
					const { invitedUser, userId, role, stageIds } = payload;
					const isInvitedUser = !!invitedUser;
					stageIds.forEach((stageId) => {
						if (stageId && state.data[stageId]) {
							// check user exists in the payload - meaning existing user
							if (isInvitedUser) {
								const invitedEmails = state.data[stageId]
									?.invitedEmails as TInvitedEmail[];
								if (invitedEmails) {
									for (let i = 0; i < invitedEmails.length; i++) {
										if (
											invitedEmails[i] &&
											invitedEmails[i]?.email === userId
										) {
											invitedEmails[i]!.role = role;
										}
									}
								}
								state.data[stageId]!.invitedEmails = invitedEmails;
							} else {
								const users = state.data[stageId]?.users as TUserAndRole[];
								if (users) {
									for (let i = 0; i < users.length; i++) {
										if (users[i] && users[i]?.user === userId) {
											users[i]!.role = role;
										}
									}
								}
								state.data[stageId]!.users = users;
							}
						}

						state.error[stageId] = null;
					});
				}
			}
		);
		// REJECTED
		builder.addCase(
			editUserRoleForStage.rejected,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TEditUserRoleForStageThunkArg>['rejectedMeta']
				>
			) => {
				const { payload, prevRole } = action.meta.arg;

				if (payload) {
					const { invitedUser, userId, stageIds } = payload;
					const isInvitedUser = !!invitedUser;
					stageIds.forEach((stageId) => {
						if (isInvitedUser) {
							const invitedEmails = state.data[stageId]
								?.invitedEmails as TInvitedEmail[];
							if (invitedEmails) {
								for (let i = 0; i < invitedEmails.length; i++) {
									if (invitedEmails[i] && invitedEmails[i]?.email === userId) {
										invitedEmails[i]!.role = prevRole;
									}
								}
							}
							state.data[stageId]!.invitedEmails = invitedEmails;
						} else {
							const users = state.data[stageId]?.users as TUserAndRole[];
							if (users) {
								for (let i = 0; i < users.length; i++) {
									if (users[i] && users[i]?.user === userId) {
										users[i]!.role = prevRole;
									}
								}
							}
							state.data[stageId]!.users = users;
						}

						state.loading[stageId] = false;
						state.error[stageId] = null;
					});
				}
			}
		);

		/** ---- REORDER BLOCKS ---- */
		// PENDING
		builder.addCase(
			reorderBlocks.pending,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TReorderBlocksThunkArg>['pendingMeta']
				>
			) => {
				const { payload } = action.meta.arg;

				// If a nested phase is reordered, update the parent id.
				if (payload.type === 'PHASE') {
					const phase = state.data[payload.reorderedBlock.blockId];
					if (phase) {
						phase.parentId = payload.destinationPhaseId;
					}
				}

				if (payload) {
					const {
						sourceBlocks,
						sourcePhaseId,
						destinationBlocks,
						destinationPhaseId,
						isPhaseCreated
					} = payload;
					if (sourcePhaseId === destinationPhaseId) {
						state.data[sourcePhaseId]!.children = sourceBlocks;
						state.loading[sourcePhaseId] = true;
						state.error[sourcePhaseId] = null;
					} else {
						if (sourceBlocks.length <= 0 && !isPhaseCreated) {
							delete state.data[sourcePhaseId];
						} else {
							state.data[sourcePhaseId]!.children = sourceBlocks;
						}
						state.data[destinationPhaseId]!.children = destinationBlocks;
						// TODO: CHECK THIS LATER
						state.loading[sourcePhaseId] = true;
						state.error[sourcePhaseId] = null;
						state.loading[destinationPhaseId] = true;
						state.error[destinationPhaseId] = null;
					}
					state.data[destinationPhaseId]!.isPhaseCreated = true;
				}
			}
		);
		// REJECTED
		builder.addCase(
			reorderBlocks.rejected,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TReorderBlocksThunkArg>['rejectedMeta']
				>
			) => {
				const { prevState, payload } = action.meta.arg;
				const { payload: error } = action;

				if (prevState) {
					const { sourcePhaseId } = payload;
					state.data = prevState.prevStages;
					state.loading[sourcePhaseId] = false;
					state.error[sourcePhaseId] = error as string;
				}
			}
		);

		/** ---- CREATE NEW STAGE FROM BLOCK ---- */
		// PENDING
		builder.addCase(
			createNewStageFromOrphanBlock.pending,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TCreateNewStageFromBlockThunkArg>['pendingMeta']
				>
			) => {
				const { payload } = action.meta.arg;

				if (payload) {
					const {
						stageData,
						sourcePhaseId,
						blockIds,
						orphanGroupIds,
						parent,
						newPhaseIndex
					} = payload;

					sourcePhaseId.forEach((groupId) => {
						state.data[groupId]!.children = state.data[groupId]!.children?.filter(
							(b) => !blockIds.includes(b as string)
						);
						state.loading[groupId] = true;
						state.error[groupId] = null;
					});

					if (orphanGroupIds && orphanGroupIds?.length > 0) {
						orphanGroupIds.forEach((bId) => {
							delete state.data[bId];
						});
					}

					if (parent.type === 'PHASE' && state.data[parent.id] && stageData._id) {
						state.data[parent.id]?.children.splice(
							newPhaseIndex,
							0,
							stageData._id.toString()
						);
					}

					state.data[stageData._id as string] = stageData;
					state.data[stageData._id as string]!.children = blockIds;
				}
			}
		);
		// REJECTED
		builder.addCase(
			createNewStageFromOrphanBlock.rejected,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TCreateNewStageFromBlockThunkArg>['rejectedMeta']
				>
			) => {
				const { prevState, payload } = action.meta.arg;
				const { payload: error } = action;

				if (prevState) {
					const { sourcePhaseId, stageData } = payload;
					const { prevStages } = prevState;
					state.data = prevStages;

					sourcePhaseId.forEach((groupId) => {
						state.error[groupId] = error as string;
						state.loading[groupId] = false;
					});
					if (stageData._id) delete state.data[stageData._id.toString()];
				}
			}
		);

		/** ---- UNDO REDO Stage ---- */
		// PENDING
		builder.addCase(
			undoRedoStage.pending,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TUndoRedoStageArgs>['pendingMeta']
				>
			) => {
				const stage = action.meta.arg.data.stage as IGroup;
				if (stage.isVisible) {
					state.data[stage._id as string] = stage;
				} else {
					delete state.data[stage._id as string];
				}
			}
		);
		// REJECTED
		builder.addCase(
			undoRedoStage.rejected,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TUndoRedoStageArgs>['rejectedMeta']
				>
			) => {
				const { prevStage } = action.meta.arg.prevState;
				const stage = action.meta.arg.data.stage as IGroup;
				state.data[stage._id as string] = prevStage;
			}
		);

		/** ---- ADD SINGLE BLOCKS ---- */
		// PENDING
		builder.addCase(
			addSingleBlock.pending,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TAddSingleBlockArgs>['pendingMeta']
				>
			) => {
				const { data } = action.meta.arg;

				if (data) {
					const { stageId, blockData, isGroupCreated } = data;

					if (stageId && state.data[stageId]) {
						state.data[stageId]!.children?.push(blockData._id as string);
						state.data[stageId]!.isPhaseCreated = isGroupCreated;
						state.loading[stageId] = true;
						state.error[stageId] = null;
					}
				}
			}
		);
		// FULFILLED- set the loading state
		builder.addCase(
			addSingleBlock.fulfilled,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					TActionType<TAddSingleBlockArgs, {}>['payload'],
					string,
					TActionType<{}, TAddSingleBlockArgs>['fulfilledMeta']
				>
			) => {
				const { data } = action.payload;
				const { blockData } = action.meta.arg.data;

				if (data && blockData) {
					const { parentId } = blockData;
					const stage = state.data[parentId];

					if (parentId && stage) {
						const stageBlocks = stage.children;

						if (!stageBlocks!.includes(blockData._id as string))
							state.data[parentId]!.children!.push(blockData._id as string);
						state.loading[parentId] = false;
						state.error[parentId] = null;
					}
				}
			}
		);
		// REJECTED - remove the block from redux if action fails, set error and loading
		builder.addCase(
			addSingleBlock.rejected,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TAddSingleBlockArgs>['rejectedMeta']
				>
			) => {
				const { data, prevState } = action.meta.arg;
				const { payload: error } = action;

				if (data) {
					const { stageId } = data;
					const { group } = prevState;

					if (stageId && state.data[stageId]) {
						state.data[stageId] = group;
						state.loading[stageId] = false;
						state.error[stageId] = error as string;
					}
				}
			}
		);

		/** ---- ADD MULTIPLE BLOCKS ---- */
		// FULFILLED
		builder.addCase(
			addMultipleBlocks.fulfilled,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					TActionType<TAddMultipleBlockArgs, {}>['payload'],
					string,
					TActionType<{}, TAddMultipleBlockArgs>['fulfilledMeta']
				>
			) => {
				const { blocks, stageId, newBlockIndex } = action.payload.data;
				const stage = state.data[stageId];

				if (stage) {
					const blockIds = blocks.map((b) => b._id as string);
					const stageBlocks = stage.children as string[];
					const overwrittenIds = [] as string[];

					blockIds.forEach((id) => {
						if (!stageBlocks.includes(id)) overwrittenIds.push(id);
					});

					overwrittenIds.forEach((id, ind) =>
						state.data[stageId]!.children!.splice(newBlockIndex + ind, 0, id)
					);
					state.data[stageId]!.isPhaseCreated = true;
				}
			}
		);
		// PENDING
		builder.addCase(
			addMultipleBlocks.pending,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TAddMultipleBlockArgs>['pendingMeta']
				>
			) => {
				const { stageId, blocks, newBlockIndex } = action.meta.arg.data;

				if (state.data[stageId] && state.data[stageId]?.children) {
					const blockIds = blocks.map((b) => b._id as string);
					const allBlocks = state.data[stageId]!.children as IBlock[];
					const withNewBlocks = [
						...allBlocks.slice(0, newBlockIndex),
						...blockIds,
						...allBlocks.slice(newBlockIndex)
					];
					state.data[stageId]!.children = withNewBlocks;
					state.data[stageId]!.isPhaseCreated = true;
				}

				state.loading[stageId] = false;
				state.error[stageId] = null;
			}
		);
		// REJECTED-remove the block from redux if action fails
		builder.addCase(
			addMultipleBlocks.rejected,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TAddMultipleBlockArgs>['rejectedMeta']
				>
			) => {
				const { blocks, stageId } = action.meta.arg.data;

				if (state.data[stageId] && state.data[stageId]?.children) {
					const blockIds = blocks.map((b) => b._id as string);
					state.data[stageId]!.children = state.data[stageId]!.children!.filter(
						(b) => !blockIds.includes(b as string)
					);
				}

				state.loading[stageId] = false;
				state.error[stageId] = action.payload as string;
			}
		);

		/** ---- DELETE BLOCK ---- */
		// PENDING
		builder.addCase(
			deleteBlock.pending,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TDeleteBlockArgs>['pendingMeta']
				>
			) => {
				const { data } = action.meta.arg;

				if (data) {
					const { blockIds, groupIds, orphanGroupIds } = data;

					groupIds.forEach((groupId) => {
						state.data[groupId]!.children = state.data[groupId]!.children?.filter(
							(b) => !blockIds.includes(b as string)
						);
						state.loading[groupId] = true;
						state.error[groupId] = null;
					});

					orphanGroupIds.forEach((groupId) => {
						delete state.data[groupId];
						state.loading[groupId] = true;
						state.error[groupId] = null;
					});
				}
			}
		);
		// REJECTED
		builder.addCase(
			deleteBlock.rejected,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TDeleteBlockArgs>['rejectedMeta']
				>
			) => {
				const { data, prevState } = action.meta.arg;
				const { payload: error } = action;

				if (prevState && data) {
					const { prevStages } = prevState;
					const { groupIds } = data;

					state.data = prevStages;
					groupIds.forEach((groupId) => {
						state.loading[groupId] = false;
						state.error[groupId] = error as string;
					});
				}
			}
		);

		/** ---- ADD USER TO PROJECT ---- */
		// FULFILLED
		builder.addCase(
			addUserToProject.fulfilled,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					TActionType<TAddUserToProjectFulfill, {}>['payload'],
					string,
					TActionType<{}, TAddUserToProjectThunkArg>['fulfilledMeta']
				>
			) => {
				if (action.meta.arg) {
					const { payload, stageIds } = action.meta.arg;
					const { isInvitedUser, email, userId, role, override } = payload;

					if (stageIds) {
						stageIds.forEach((stageId) => {
							if (isInvitedUser) {
								const invitedEmails = state.data[stageId]
									?.invitedEmails as TInvitedEmail[];
								const updatedInvitedEmails = addUserInArray(
									isInvitedUser,
									email,
									role,
									invitedEmails,
									override
								);
								state.data[stageId]!.invitedEmails =
									updatedInvitedEmails as TInvitedEmail[];
							} else {
								const users = state.data[stageId]?.users as TUserAndRole[];
								const updatedUsers = addUserInArray(
									isInvitedUser,
									userId as string,
									role,
									users,
									override
								);
								state.data[stageId]!.users = updatedUsers as TUserAndRole[];
							}

							state.loading[stageId] = false;
							state.error[stageId] = null;
						});
					}
				}
			}
		);
		// PENDING
		builder.addCase(
			addUserToProject.pending,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TAddUserToProjectThunkArg>['pendingMeta']
				>
			) => {
				if (action.meta.arg) {
					const { payload, stageIds } = action.meta.arg;

					if (payload) {
						const { isInvitedUser, email, userId, role, override } = payload;

						// Add user to users/invitedEmails in redux
						if (stageIds) {
							stageIds.forEach((stageId) => {
								if (isInvitedUser) {
									const invitedEmails = state.data[stageId]
										?.invitedEmails as TInvitedEmail[];
									const updatedInvitedEmails = addUserInArray(
										isInvitedUser,
										email,
										role,
										invitedEmails,
										override
									);
									state.data[stageId]!.invitedEmails =
										updatedInvitedEmails as TInvitedEmail[];
								} else {
									const users = state.data[stageId]?.users as TUserAndRole[];
									const updatedUsers = addUserInArray(
										isInvitedUser,
										userId as string,
										role,
										users,
										override
									);
									state.data[stageId]!.users = updatedUsers as TUserAndRole[];
								}

								state.error[stageId] = null;
							});
						}
					}
				}
			}
		);
		// REJECTED
		builder.addCase(
			addUserToProject.rejected,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TAddUserToProjectThunkArg>['rejectedMeta']
				>
			) => {
				if (action.meta.arg) {
					const { payload, stageIds } = action.meta.arg;
					const { payload: error } = action;

					if (payload) {
						const { isInvitedUser, email, userId } = payload;

						// Remove added user from users/invitedEmails in redux
						if (stageIds) {
							stageIds.forEach((s) => {
								if (isInvitedUser) {
									const invitedEmails = state.data[s]?.invitedEmails;
									state.data[s]!.invitedEmails = invitedEmails?.filter(
										(u) => (u as TInvitedEmail).email !== email
									) as TInvitedEmail[];
								} else {
									const users = state.data[s]?.users;
									if (users) {
										state.data[s]!.users = users.filter(
											(u) => (u as TUserAndRole).user !== userId
										);
									}
								}

								state.loading[s] = false;
								state.error[s] = error as string;
							});
						}
					}
				}
			}
		);

		/** ---- REMOVE USER FROM PROJECT ---- */
		// FULFILLED
		builder.addCase(
			removeUserFromProject.fulfilled,
			(
				state: IGroupsInitState,
				action: PayloadAction<TActionType<TRemoveUserFromProjectFulfill, {}>['payload']>
			) => {
				const { payload } = action;

				if (payload) {
					const { stageIds } = payload;

					stageIds.forEach((s) => {
						state.loading[s] = false;
						state.error[s] = null;
					});
				}
			}
		);
		// PENDING
		builder.addCase(
			removeUserFromProject.pending,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TRemoveUserFromProjectThunkArg>['pendingMeta']
				>
			) => {
				const { payload, stageIds } = action.meta.arg;

				if (payload) {
					const { invitedUserRole, userId } = payload;

					const isInvitedUser = !!invitedUserRole;
					stageIds.forEach((s) => {
						if (isInvitedUser) {
							state.data[s]!.invitedEmails = state.data[s]!.invitedEmails?.filter(
								(x) => (x as TInvitedEmail).email !== userId
							);
						} else {
							state.data[s]!.users = state.data[s]!.users?.filter(
								(x) => ((x as TUserAndRole).user as IUser) !== userId
							);
						}

						state.error[s] = null;
					});
				}
			}
		);
		// REJECTED
		builder.addCase(
			removeUserFromProject.rejected,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TRemoveUserFromProjectThunkArg>['rejectedMeta']
				>
			) => {
				const { payload, stageIds } = action.meta.arg;

				if (payload) {
					const { invitedUserRole, userRole } = payload;

					const isInvitedUser = !!invitedUserRole;
					stageIds.forEach((s) => {
						if (isInvitedUser) state.data[s]!.invitedEmails!.push(invitedUserRole);
						else if (userRole) state.data[s]!.users!.push(userRole);
						state.loading[s] = false;
						state.error[s] = null;
					});
				}
			}
		);

		/** ---- EDIT USER ROLE FOR PROJECT ---- */
		// FULFILLED
		builder.addCase(
			editUserRoleForProject.fulfilled,
			(
				state: IGroupsInitState,
				action: PayloadAction<TActionType<TEditUserRoleForProjectFulfill, {}>['payload']>
			) => {
				if (action.payload) {
					const { stageIds } = action.payload;
					if (stageIds) {
						stageIds.forEach((b) => {
							state.loading[b] = false;
							state.error[b] = null;
						});
					}
				}
			}
		);
		// PENDING
		builder.addCase(
			editUserRoleForProject.pending,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TEditUserRoleForProjectThunkArg>['pendingMeta']
				>
			) => {
				const { payload, stageIds } = action.meta.arg;

				if (payload) {
					const { invitedUser, userId, role } = payload;
					const isInvitedUser = !!invitedUser;

					if (stageIds) {
						stageIds.forEach((b) => {
							// check user exists in the payload - meaning existing user
							if (isInvitedUser) {
								const invitedEmails = state.data[b]
									?.invitedEmails as TInvitedEmail[];
								if (invitedEmails) {
									for (let i = 0; i < invitedEmails.length; i++) {
										if (
											invitedEmails[i] &&
											invitedEmails[i]?.email === userId
										) {
											invitedEmails[i]!.role = role;
										}
									}
								}
								state.data[b]!.invitedEmails = invitedEmails;
							} else {
								const users = state.data[b]?.users as TUserAndRole[];
								if (users) {
									for (let i = 0; i < users.length; i++) {
										if (users[i] && users[i]?.user === userId) {
											users[i]!.role = role;
										}
									}
								}
								state.data[b]!.users = users;
							}

							state.error[b] = null;
						});
					}
				}
			}
		);
		// REJECTED
		builder.addCase(
			editUserRoleForProject.rejected,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TEditUserRoleForProjectThunkArg>['rejectedMeta']
				>
			) => {
				const { payload, stageIds, prevRole } = action.meta.arg;

				if (payload) {
					const { invitedUser, userId } = payload;
					const isInvitedUser = !!invitedUser;

					if (stageIds) {
						stageIds.forEach((b) => {
							// check user exists in the payload - meaning existing user
							if (isInvitedUser) {
								const invitedEmails = state.data[b]
									?.invitedEmails as TInvitedEmail[];
								if (invitedEmails) {
									for (let i = 0; i < invitedEmails.length; i++) {
										if (
											invitedEmails[i] &&
											invitedEmails[i]?.email === userId
										) {
											invitedEmails[i]!.role = prevRole;
										}
									}
								}
								state.data[b]!.invitedEmails = invitedEmails;
							} else {
								const users = state.data[b]?.users as TUserAndRole[];
								if (users) {
									for (let i = 0; i < users.length; i++) {
										if (users[i] && users[i]?.user === userId) {
											users[i]!.role = prevRole;
										}
									}
								}
								state.data[b]!.users = users;
							}

							state.loading[b] = false;
							state.error[b] = null;
						});
					}
				}
			}
		);

		/** ---- LOAD PROJECT ---- */
		// FULFILLED
		builder.addCase(
			loadProjectById.fulfilled,
			(
				state: IGroupsInitState,
				action: PayloadAction<TActionType<TLoadProjectByIdFulfill, {}>['payload']>
			) => {
				const { payload } = action;

				if (payload) {
					const stages = payload.stages as IGroup[];

					stages.forEach((stage: IGroup) => {
						const stageId = stage._id as string;
						let overwriteStage = true;

						if (state.data[stageId]) {
							const oldStage = state.data[stageId];

							// Only update the stage if stage from api have been updated later
							// to prevent the scenario where a user refreshes and someone edits a stage
							if (
								new Date(oldStage?.updatedAt as string) >
								new Date(stage.updatedAt as string)
							)
								overwriteStage = false;
						}

						if (overwriteStage) state.data[stageId] = stage;
						state.loading[stageId] = false;
						state.error[stageId] = null;
					});
				}
			}
		);

		/** ---- LOAD TEMPLATE ---- */
		// FULFILLED
		builder.addCase(
			loadTemplateById.fulfilled,
			(
				state: IGroupsInitState,
				action: PayloadAction<TActionType<TLoadTemplateByIdFulfill, {}>['payload']>
			) => {
				const { template } = action.payload;
				template?.groups?.forEach((group) => {
					const id = group._id as string;

					state.data[id] = group;
					state.error[id] = null;
				});
			}
		);

		/** ---- LOAD PROJECT FOR GUEST ---- */
		// FULFILLED
		builder.addCase(
			loadProjectForGuestAction.fulfilled,
			(
				state: IGroupsInitState,
				action: PayloadAction<TActionType<TLoadProjectByIdFulfill, {}>['payload']>
			) => {
				const { payload } = action;

				if (payload) {
					const stages = payload.stages as IGroup[];

					stages.forEach((stage: IGroup) => {
						const stageId = stage._id as string;
						state.data[stageId] = stage;
						state.loading[stageId] = false;
						state.error[stageId] = null;
					});
				}
			}
		);

		/** ---- DUPLICATE PROJECT ---- */
		// FULFILLED
		builder.addCase(
			duplicateProject.fulfilled,
			(
				state: IGroupsInitState,
				action: PayloadAction<TActionType<TDuplicateProjectFulfill, {}>['payload']>
			) => {
				const { payload } = action;

				if (payload) {
					const stages = payload.stages as IGroup[];

					stages.forEach((stage: IGroup) => {
						const stageId = stage._id as string;
						state.data[stageId] = stage;
						state.loading[stageId] = false;
						state.error[stageId] = null;
					});
				}
			}
		);

		/** ---- DUPLIACTE BLOCK ---- */
		// PENDING
		builder.addCase(
			duplicateBlock.pending,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TDuplicateBlockArgs>['pendingMeta']
				>
			) => {
				const { data } = action.meta.arg;

				if (data) {
					const { clonedItems, stageId, blockIndex } = data;
					const { block: clonedBlock } = clonedItems;

					if (stageId && state.data[stageId]) {
						state.data[stageId]!.children?.splice(
							blockIndex,
							0,
							clonedBlock._id as string
						);
						state.data[stageId]!.isPhaseCreated = true;
						state.loading[stageId] = true;
						state.error[stageId] = null;
					}
				}
			}
		);
		// REJECTED
		builder.addCase(
			duplicateBlock.rejected,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TDuplicateBlockArgs>['rejectedMeta']
				>
			) => {
				const { data } = action.meta.arg;
				const { payload: error } = action;

				if (data) {
					const { stageId, blockIndex } = data;

					if (stageId && state.data[stageId]) {
						state.data[stageId]!.children!.splice(blockIndex, 1);
						state.data[stageId]!.isPhaseCreated = false;
						state.loading[stageId] = false;
						state.error[stageId] = error as string;
					}
				}
			}
		);
		// FULFILLED
		builder.addCase(
			duplicateBlock.fulfilled,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					TActionType<TDuplicateBlockArgs, {}>['payload'],
					string,
					TActionType<{}, TDuplicateBlockArgs>['fulfilledMeta']
				>
			) => {
				const { stageId, clonedItems, blockIndex } = action.meta.arg.data;
				const { block: clonedBlock } = clonedItems;

				const stage = state.data[stageId];

				if (stage) {
					const stageBlocks = stage.children! as string[];
					const duplicatedBlockId = clonedBlock._id as string;

					if (!stageBlocks!.includes(duplicatedBlockId))
						state.data[stageId]!.children!.splice(blockIndex, 0, duplicatedBlockId);
				}
				state.loading[stageId] = false;
			}
		);

		/** --- CREATE PHASE WITH EMPTY BLOCKS ---- */
		// FULFILLED
		builder.addCase(
			batchCreateGroupAndBlocks.fulfilled,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					TActionType<TCreateStageWithBlocksFulfill, {}>['payload'],
					string,
					TActionType<{}, TBatchCreateGroupAndBlocks>['fulfilledMeta']
				>
			) => {
				const localGroups = action.meta.arg.groups;

				localGroups.forEach((group) => {
					const groupId = group._id as string;
					state.data[groupId] = group;
					state.loading[groupId] = false;
					state.error[groupId] = null;
				});
			}
		);
		// PENDING
		builder.addCase(
			batchCreateGroupAndBlocks.pending,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TBatchCreateGroupAndBlocks>['pendingMeta']
				>
			) => {
				const { groups: newGroups, parent, startGroupIndex, children } = action.meta.arg;

				newGroups.forEach((group) => {
					const groupId = group._id as string;
					state.data[groupId] = group;
					state.data[groupId]!.isPhaseCreated =
						group.isPhaseCreated || group.children.length > 1;
					state.loading[groupId] = false;
					state.error[groupId] = null;
				});

				// Add nested groups to phase.
				if (parent.type === 'PHASE' && state.data[parent.id]?.children) {
					state.data[parent.id]!.children = [
						...state.data[parent.id]!.children.slice(0, startGroupIndex),
						...children,
						...state.data[parent.id]!.children.slice(startGroupIndex)
					];
					state.data[parent.id]!.isPhaseCreated = true;
				}
			}
		);
		// REJECTED
		builder.addCase(
			batchCreateGroupAndBlocks.rejected,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TBatchCreateGroupAndBlocks>['rejectedMeta']
				>
			) => {
				const { groups: newGroups, parent, children } = action.meta.arg;
				const { payload: error } = action;

				newGroups.forEach((group) => {
					const groupId = group._id as string;

					if (state.data[groupId]) delete state.data[groupId];

					state.loading[groupId] = false;
					state.error[groupId] = error as string;
				});

				// On API fail remove the added children.
				if (parent.type === 'PHASE' && state.data[parent.id]?.children) {
					state.data[parent.id]!.children = state.data[parent.id]!.children.filter(
						(gId) => !children.includes(gId as string)
					);
				}
			}
		);

		/** ---- DUPLICATE PROJECT OPTIMISTICALLY---- */

		// FULFILLED
		builder.addCase(
			duplicateProjectOptimistically.fulfilled,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					TActionType<TDuplicateProjectOptimisticallyThunkArg['payload'], {}>['payload'],
					string,
					TActionType<{}, TDuplicateProjectOptimisticallyThunkArg>['fulfilledMeta']
				>
			) => {
				const { clonedItems } = action.meta.arg.payload;
				const { groups: clonedGroups } = clonedItems;

				if (clonedGroups) {
					clonedGroups.forEach((stage) => {
						const stageId = stage._id.toString();
						state.loading[stageId] = false;
						state.error[stageId] = null;
					});
				}
			}
		);

		// PENDING
		builder.addCase(
			duplicateProjectOptimistically.pending,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TDuplicateProjectOptimisticallyThunkArg>['pendingMeta']
				>
			) => {
				const { payload } = action.meta.arg;

				if (payload) {
					const { clonedItems } = action.meta.arg.payload;
					const { groups: stages } = clonedItems;
					stages.forEach((stage: IGroup) => {
						const stageId = stage._id as string;

						state.data[stageId] = stage;
						state.loading[stageId] = true;
						state.error[stageId] = null;
					});
				}
			}
		);

		// REJECTED
		builder.addCase(
			duplicateProjectOptimistically.rejected,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TDuplicateProjectOptimisticallyThunkArg>['rejectedMeta']
				>
			) => {
				const { payload } = action.meta.arg;
				const { payload: error } = action;

				if (payload) {
					const { clonedItems } = action.meta.arg.payload;
					const { groups: clonedGroups } = clonedItems;
					clonedGroups.forEach((stage) => {
						const stageId = stage._id.toString();
						if (state.data[stageId]) delete state.data[stageId];
						state.loading[stageId] = false;
						state.error[stageId] = error as string;
					});
				}
			}
		);

		/** ---- REMOVE USER FROM BLOCK ---- */
		// PENDING
		builder.addCase(
			removeUserFromBlock.pending,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TRemoveUserFromBlockBlockArgs>['pendingMeta']
				>
			) => {
				const { data } = action.meta.arg;
				if (data) {
					const { stageId, invitedUserRole, userId } = data;
					const isInvitedUser = !!invitedUserRole;
					const stage = state.data[stageId];

					if (stage?.children?.length === 1 && !stage.isPhaseCreated) {
						if (isInvitedUser) {
							state.data[stageId]!.invitedEmails = state.data[
								stageId
							]?.invitedEmails?.filter(
								(e) => (e as TInvitedEmail).email !== userId
							) as TInvitedEmail[];
						} else {
							state.data[stageId]!.users = state.data[stageId]?.users?.filter(
								(e) => (e as TUserAndRole).user !== userId
							) as TUserAndRole[];
						}
					}

					// reset the error just in case previous call has some error
					state.error[stageId] = null;
				}
			}
		);
		// REJECTED
		builder.addCase(
			removeUserFromBlock.rejected,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TRemoveUserFromBlockBlockArgs>['rejectedMeta']
				>
			) => {
				const { data } = action.meta.arg;
				if (data) {
					const { stageId, invitedUserRole, userRole } = data;
					const isInvitedUser = !!invitedUserRole;
					const stage = state.data[stageId];

					if (stage?.children?.length === 1 && !stage.isPhaseCreated) {
						if (isInvitedUser)
							state.data[stageId]!.invitedEmails?.push(invitedUserRole);
						else if (userRole) state.data[stageId]!.users?.push(userRole);
					}

					// reset the error just in case previous call has some error
					state.error[stageId] = action.payload as string;
					state.loading[stageId] = false;
				}
			}
		);

		// Undo delete
		// PENDING
		builder.addCase(
			undoDelete.pending,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TUndoRedoArgs>['pendingMeta']
				>
			) => {
				const { groups } = action.meta.arg.data;
				if (groups.length > 0) {
					groups.forEach((group) => {
						const groupId = group._id as string;
						state.data[groupId] = group;
					});
				}
			}
		);
		// FULFILLED
		builder.addCase(
			undoDelete.fulfilled,
			(
				state: IGroupsInitState,
				action: PayloadAction<TActionType<TUndoDeleteFufill, {}>['payload']>
			) => {
				const { payload } = action;

				if (payload) {
					const { groups } = payload;
					if (groups.length > 0) {
						groups.forEach((group) => {
							const groupId = group._id as string;
							state.data[groupId] = group;
						});
					}
				}
			}
		);
		// REJECTED
		builder.addCase(
			undoDelete.rejected,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TUndoRedoArgs>['rejectedMeta']
				>
			) => {
				const { prevStateGroups } = action.meta.arg.prevState;
				state.data = prevStateGroups;
			}
		);

		// PENDING - add note
		builder.addCase(
			addNote.fulfilled,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					TActionType<INote, {}>['payload'],
					string,
					TActionType<{}, TAddNoteArg>['fulfilledMeta']
				>
			) => {
				const { payload } = action.meta.arg;
				if (payload) {
					const { parentId, parentType } = payload;
					if (parentType === 'GROUP') {
						state.loading[parentId] = false;

						state.error[parentId as string] = null;
					}
				}
			}
		);
		builder.addCase(
			addNote.pending,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TAddNoteArg>['pendingMeta']
				>
			) => {
				const { payload } = action.meta.arg;
				if (payload) {
					const { parentId, parentType } = payload;
					if (parentType === 'GROUP') {
						if (state.data[parentId as string]) {
							const group = state.data[parentId as string] as IGroup;

							const notes = group?.notes
								? [...group.notes, payload._id as string]
								: [payload._id as string];
							group.notes = notes;
						}
					}

					state.error[parentId as string] = null;
				}
			}
		);
		// REJECTED
		builder.addCase(
			addNote.rejected,
			(
				state: IGroupsInitState,
				action: PayloadAction<unknown, string, TActionType<{}, TAddNoteArg>['rejectedMeta']>
			) => {
				const { payload } = action.meta.arg;
				const { payload: error } = action;

				if (payload) {
					const { _id: noteId, parentId, parentType } = payload;

					if (parentType === 'GROUP') {
						if (state.data[parentId as string]) {
							const block = state.data[parentId as string] as IGroup;
							block.notes = block.notes?.filter((nid) => nid !== noteId);
						}

						state.loading[parentId as string] = false;
						state.error[parentId as string] = error as string;
					}
				}
			}
		);

		// PENDING - delete note
		builder.addCase(
			deleteNote.fulfilled,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					TActionType<{ noteId: string; parentId: string }, {}>['payload'],
					string,
					TActionType<{}, TDeleteNoteArg>['fulfilledMeta']
				>
			) => {
				const { payload } = action.meta.arg;

				if (payload) {
					const { noteId, parentId: blockId, parentType } = payload;

					if (parentType === 'GROUP') {
						if (state.data[blockId as string]) {
							const block = state.data[blockId as string] as IGroup;
							if (block.notes!.includes(noteId))
								block.notes = block.notes?.filter((nid) => nid !== noteId);
						}

						state.error[blockId as string] = null;
					}
				}
			}
		);
		builder.addCase(
			deleteNote.pending,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TDeleteNoteArg>['pendingMeta']
				>
			) => {
				const { payload } = action.meta.arg;

				if (payload) {
					const { noteId, parentId: groupId, parentType } = payload;
					if (
						state.data[groupId as string] &&
						parentType === 'GROUP' &&
						(state.data[groupId as string] as IGroup).notes
					) {
						const group = state.data[groupId as string] as IGroup;
						(state.data[groupId as string] as IGroup).notes = (
							group.notes as INote[]
						).filter((nid: INote) => nid._id !== noteId);
					}

					state.error[groupId as string] = null;
				}
			}
		);
		// REJECTED
		builder.addCase(
			deleteNote.rejected,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TDeleteNoteArg>['rejectedMeta']
				>
			) => {
				const { payload } = action.meta.arg;
				const { payload: error } = action;

				if (payload) {
					const { noteId, parentId: blockId } = payload;

					if (state.data[blockId as string]) {
						const block = state.data[blockId as string] as IGroup;
						const notes = block?.notes
							? [...block.notes, noteId as string]
							: [noteId as string];
						block.notes = notes;
					}

					state.loading[blockId as string] = false;
					state.error[blockId as string] = error as string;
				}
			}
		);
		/** ------- BULK UPDATE GROUPS ----------- */
		// PENDING
		builder.addCase(
			bulkUpdateGroups.pending,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TBulkUpdateGroupArgs>['pendingMeta']
				>
			) => {
				const groups = action.meta.arg.data.groups || [];
				for (let i = 0; i < groups.length; i++) {
					const groupId = groups[i]?.groupId;

					if (groupId) {
						const data = {
							...state.data[groupId],
							...groups[i]?.updates,
							updatedAt: new Date().toISOString()
						};
						state.data[groupId] = data as IGroup;
						state.error[groupId] = '';
					}
				}
			}
		);
		// REJECTED
		builder.addCase(
			bulkUpdateGroups.rejected,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TBulkUpdateGroupArgs>['rejectedMeta']
				>
			) => {
				const { groups } = action.meta.arg.prevState;
				for (let i = 0; i < groups.length; i++) {
					const groupId = (groups[i] as IGroup)._id.toString();
					state.data[groupId] = groups[i] as IGroup;
					state.error[groupId] = action.payload as string;
				}
			}
		);

		/** ---- NESTING ---- */
		builder.addCase(
			nestingAction.pending,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TNestingThunkArgs>['pendingMeta']
				>
			) => {
				const { groups } = action.meta.arg.payload;

				if (groups) {
					groups.forEach((group) => {
						if (group.groupId)
							state.data[group.groupId] = {
								...state.data[group.groupId],
								...group.updates
							} as IGroup;
					});
				}
			}
		);
		builder.addCase(
			nestingAction.rejected,
			(
				state: IGroupsInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TNestingThunkArgs>['rejectedMeta']
				>
			) => {
				const { groups } = action.meta.arg.prevState;
				for (let i = 0; i < groups.length; i++) {
					const groupId = (groups[i] as IGroup)._id.toString();
					state.data[groupId] = groups[i] as IGroup;
					state.error[groupId] = action.payload as string;
				}
			}
		);
		/** ---- NESTING ---- */
	}
});

export const {
	addStagesToRedux,
	editStageById,
	deleteStageById,
	unloadStages,
	addTempNotesOnGroups,
	removeTempNotesOnGroups
} = stagesSlice.actions;

export default stagesSlice.reducer;
