import { TStroke } from '@naya_studio/types';
import _ from 'lodash';
import * as PIXI from 'pixi.js';
import BaseNode from './BaseNode';
import Shape from './Shape';

class SelectHighlight extends Shape {
  /**
     * Draw the shape as per the selected shape type
     */
  draw = () => {
    if (this.displayObject) {
      this.app.viewport.removeChild(this.displayObject);
    }

    const container = new PIXI.Container();
    const border = new PIXI.Graphics();

    const shape = new PIXI.Graphics();
    shape.name = 'HIGHLIGHT';
    if (this.nodeData) {
      const { absoluteBounds, fill, zIndex } = this.nodeData;

      if (!absoluteBounds!.width || !absoluteBounds!.height || !absoluteBounds) { return; }

      if (absoluteBounds.height < 10) {
        absoluteBounds.height = 10;
      }

      if (absoluteBounds.width < 10) {
        absoluteBounds.width = 10;
      }
      
      const strokeData = _.cloneDeep(this.nodeData.stroke) as TStroke;

      shape.beginFill(BaseNode.getColorValue(fill?.fillColor));

      if (strokeData.strokeColor !== 'NO_STROKE') {
        border.beginFill(BaseNode.getColorValue(fill?.fillColor), 1e-4);
        border.lineStyle(
          strokeData.strokeWidth!,
          BaseNode.getColorValue(strokeData.strokeColor),
          1,
          0,
        );
      } 

      if (absoluteBounds) {
        shape.drawRect(0, 0, absoluteBounds.width!, absoluteBounds.height!);
        shape.endFill();
        border.drawRect(0, 0, absoluteBounds.width!, absoluteBounds.height!);
        border.endFill();
      }

      if (this.nodeData.opacity) {
        shape.alpha = this.nodeData.opacity;
      }

      this.displayObject = container;
      this.displayObject.addChild(shape);
      this.displayObject.addChild(border);
      this.displayObject.setTransform(absoluteBounds.x, absoluteBounds.y, 0, 0, 0);
      this.displayObject.zIndex = zIndex! || 10;

      this.displayObject.name = 'SHAPE';

      this.displayObject.interactive = false;

      this.app.viewport.addChild(this.displayObject);
    }
  };
}

export default SelectHighlight;
