import * as PIXI from 'pixi.js';
import { INode, TPath } from '@naya_studio/types';
import BaseNode from './BaseNode';
import App from '../App';

class Markup extends BaseNode {
  static defaultNodeData: INode = {
    nodeType: 'MARKUP',
    isVisible: true,
    absoluteBounds: { x: 0, y: 0 },
    scale: { scaleX: 1, scaleY: 1 },
    rotation: 0,
  };

  static getSqDist(p1: [number, number], p2: [number, number]) {
    const dx = p1[0] - p2[0];
    const dy = p1[1] - p2[1];

    return dx * dx + dy * dy;
  }

  // basic distance-based simplification
  static simplifyRadialDist(points: ([number, number] | undefined)[], tolerance?: number) {
    if (points.length <= 1) { return points; }
    const toleranceFactor = typeof tolerance === 'number' ? tolerance : 1;
    const sqTolerance = toleranceFactor * toleranceFactor;

    let prevPoint = points[0];
    const newPoints = [prevPoint];
    let point: [number, number] = [0, 0];

    for (let i = 1, len = points.length; i < len; i++) {
      point = points[i]!;

      if (prevPoint && Markup.getSqDist(point, prevPoint) > sqTolerance) {
        newPoints.push(point);
        prevPoint = point;
      }
    }

    if (prevPoint !== point) newPoints.push(point);

    return newPoints;
  }

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(app: App, nodeData: INode) {
    super(app, nodeData);
  }

  /**
   * Edit the node
   * @param {string} type -- defines the edit type
   * @param data -- data to be changed
   */
  edit = (type:string, data:any) => {
    this.app.removeTransformer();
    this.prevNodeData = JSON.parse(JSON.stringify(this.nodeData));
    switch (type) {
      case 'ZINDEX':
        this.nodeData.zIndex = data;
        break;
      case 'STROKE_COLOR':
        if (this.nodeData.stroke) {
          this.nodeData.stroke.strokeColor = data;
        }
        break;
      case 'STROKE_WIDTH':
        if (this.nodeData.stroke) {
          this.nodeData.stroke.strokeWidth = data.strokeWidth;
        }
        break;
      case 'OPACITY':
        this.nodeData.opacity = data;
        break;
      default:
        break;
    }
    this.draw();
  };

  /**
   * Adding point to the existing path
   * @param x
   * @param y
   */
  addPoint(x: number, y: number) {
    this.nodeData?.paths!.push({ x, y });
    // this.app.viewport.removeChild(this.displayObject!);
    this.draw();
  }

  static gradient(a: { x: number, y: number }, b: { x: number, y: number }) {
    return (b.y - a.y) / (b.x - a.x);
  }

  static bzCurve(points: TPath[], f: number, t: number, path: PIXI.Graphics) {
    // f = 0, will be straight line
    // t suppose to be 1, but changing the value can control the smoothness too
    let fFactor = 0.3;
    let tFactor = 0.6;

    if (f) fFactor = f;
    if (t) tFactor = t;

    // ctx.beginPath();
    path.moveTo(points[0]!.x!, points[0]!.y!);

    let m = 0;
    let dx1 = 0;
    let dy1 = 0;
    let dx2 = 0;
    let dy2 = 0;

    let preP = points[0];
    for (let i = 1; i < points.length - 1; i++) {
      const curP = points[i];
      const nexP = points[i + 1];
      if (nexP) {
        // console.log('nexP', nexP);
        m = Markup.gradient({ x: preP!.x!, y: preP!.y! }, { x: nexP!.x!, y: nexP!.y! });
        dx2 = (nexP!.x! - curP!.x!) * -fFactor;
        if (preP!.x === nexP!.x) {
          dy2 = 0;
        } else {
          dy2 = dx2 * m * tFactor;
        }
      } else {
        dx2 = 0;
        dy2 = 0;
      }
      path.bezierCurveTo(
        preP!.x! - dx1,
        preP!.y! - dy1,
        curP!.x! + dx2,
        curP!.y! + dy2,
        curP!.x!,
        curP!.y!,
      );
      dx1 = dx2;
      dy1 = dy2;
      preP = curP;
    }
  }

  /**
   * Draw the markup as per the path points
   */
  draw = () => {
    if (this.displayObject) {
      this.app.viewport.removeChild(this.displayObject);
    }
    if (this.nodeData) {
      const path = new PIXI.Graphics();
      if (this.nodeData.stroke?.strokeColor !== 'NO_STROKE') {
        path.lineStyle(
          this.nodeData.stroke?.strokeWidth!,
          BaseNode.getColorValue(this.nodeData.stroke?.strokeColor),
          1,
        );
      }
      const points = this.nodeData.paths!;
      let simplifiedPoints: ([number, number] | undefined)[] = [];
      for (let i = 0; i < points.length; i++) {
        const x = points[i]?.x;
        const y = points[i]?.y;
        if (x && y) {
          simplifiedPoints.push([x, y]);
        }
      }
      simplifiedPoints = Markup.simplifyRadialDist(simplifiedPoints);
      const simplifiedPointsDict = [];
      for (let i = 0; i < simplifiedPoints.length; i++) {
        const point = simplifiedPoints[i];
        if (point) {
          simplifiedPointsDict.push({ x: point[0], y: point[1] });
        }
      }
      Markup.bzCurve(simplifiedPointsDict, 0.3, 1, path);

      const container = new PIXI.Container();
      container.zIndex = this.nodeData.zIndex! || 10;

      container.addChild(path);
      // shape, markup, comment, and reaction should NOT be interactive
      container.interactive = this.app.checkIfNodesShouldBeInteractive();

      // Setting the hitarea for the container
      container.hitArea = new PIXI.Rectangle(
        path.getLocalBounds().x,
        path.getLocalBounds().y,
        path.width,
        path.height,
      );
      this.displayObject = container;
      if(this.displayObject?.children) {
        const child = this.displayObject.children[0];
        if(child) child.alpha = this.nodeData.opacity !== undefined ? this.nodeData.opacity : 1;
      }

      const { absoluteBounds, scale, rotation } = this.nodeData;
      this.displayObject.setTransform(
        absoluteBounds?.x,
        absoluteBounds?.y,
        scale?.scaleX,
        scale?.scaleY,
        rotation,
      );
      this.displayObject.name = 'MARKUP';
      this.displayObject.on('click', this.onClick);
      this.displayObject.on('mousedown', this.onMouseDown);
      this.displayObject.on('mouseup', this.onMouseUp);
      this.displayObject.on('mouseover', this.onMouseOver);
      this.displayObject.on('mouseout', this.onMouseOut);
      this.displayObject.on('rightclick', this.rightClick);
      this.app.viewport.addChild(container);
    }
  };
}

export default Markup;
