import * as PIXI from 'pixi.js';
import Image from './Image';

class Reaction extends Image {
  /**
     * Get the scale for the initial image draw.
     * This is done to avoid large image textures taking over the screen
     * @param texture Pass the texture to get the original size from
     * @returns {scaleX, scaleY} to set for the initial drawing
     */
  static getInitScale(texture: PIXI.Texture) {
    let scaleX = 1; let
      scaleY = 1;
    const { height, width } = texture;
    scaleX = 32 / width;
    scaleY = 32 / height;
    return { scaleX, scaleY };
  }

  /**
     * Reactions cant be cropped
     */
  setCropMode = () => {
    this._cropMode = false;
  };

  /**
   * Draw image
   */
  draw = async () => {
    // Remove container before re-drawing
    if (this.displayObject) {
      this.app.viewport.removeChild(this.displayObject);
    }

    if (this.nodeData.image) {
      // Don't need to reload texture if it already exists
      if (!this.texture) {
        if (this.nodeData.image?.src! in PIXI.Loader.shared.resources) {
          this.texture = PIXI.Loader.shared.resources[this.nodeData.image?.src!]?.texture;
        }
      }
      if (!this.texture) {
        this.texture = await PIXI.Texture.fromURL(this.nodeData.image?.src!);
      }

      let {
        scale, rotation,
      } = this.nodeData;

      const {
        absoluteBounds,
      } = this.nodeData;

      rotation = rotation || 0;

      // Limit the maxWidth or maxHeight
      if (!scale) scale = Reaction.getInitScale(this.texture!);

      // Base Image is a Sprite
      this.baseImage = new PIXI.Sprite(this.texture);
      // const fitScale = (this.nodeData.absoluteBounds?.width! || 300) / this.texture.width;
      // this.baseImage.scale.set(fitScale, fitScale);

      // Add both baseImage and cropMask to wrapper
      // console.log("newzindex", this.nodeData.zIndex);
      this.baseImage.zIndex = this.nodeData.zIndex as number;
      this.baseImage.setTransform(
        absoluteBounds?.x!,
        absoluteBounds?.y!,
        scale?.scaleX,
        scale?.scaleY,
        rotation,
      );
      // shape, markup, comment, and reaction should NOT be interactive
      this.displayObject = this.baseImage;
      this.displayObject.interactive = this.app.checkIfNodesShouldBeInteractive();
      this.displayObject.name = 'IMAGE';
      this.app.viewport.addChild(this.displayObject);
      this.displayObject.on('click', this.onClick);
      this.displayObject.on('mousedown', this.onMouseDown);
      this.displayObject.on('mouseup', this.onMouseUp);
      this.displayObject.on('mouseover', this.onMouseOver);
      this.displayObject.on('mouseout', this.onMouseOut);
      this.displayObject.on('rightclick', this.rightClick);
    }
  };
}

export default Reaction;
