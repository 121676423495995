import { HtmlTextViewer, Progress } from '@naya_studio/radix-ui';
import { TPollOption, TPollVote } from '@naya_studio/types';
import { useContext } from 'react';
import { FeedbackContext } from 'src/components/expandedBlockView/ExpandedBlockView';
import './PollResults.scss';

// props for poll results
export type PollResultsProps = {
	pollOptions: TPollOption[];
	text: string;
	feedbackId: string;
};

/**
 * Component dsplaying the poll results - option with percent progress indicator
 */
export const PollResults = ({ pollOptions, text, feedbackId }: PollResultsProps) => {
	const { activeFeedbackId, feedbackView } = useContext(FeedbackContext);
	// function to extract the vote count for all the options in the poll
	const getTotalVoteCount = () => {
		let count = 0;
		if (pollOptions) {
			for (let i = 0; i < pollOptions?.length; i++) {
				if (pollOptions[i] && pollOptions[i]?.votes)
					count += (pollOptions[i]?.votes as TPollVote[]).length;
			}
		}
		return count;
	};

	return (
		<div id="poll-results">
			<HtmlTextViewer
				text={text}
				className={`tw-pt-2 tw-pb-2 tw-mb-2 tw-mt-2 tw-text-xs tw-font-['Rand-medium']`}
			/>
			{(activeFeedbackId === feedbackId || feedbackView === 'DEFAULT') && (
				<>
					{pollOptions?.map((opt: TPollOption) => (
						<div className="tw-mb-4">
							<div className="tw-mb-2 tw-flex tw-justify-between">
								<p className={`tw-m-0 tw-text-xs tw-font-['Rand-medium']`}>
									{opt.option}
								</p>
								<p
									className={`tw-m-0 tw-text-xs tw-font-['Rand-medium'] percent-indicator`}
								>
									{(opt.votes.length / getTotalVoteCount()) * 100 || 0}%
								</p>
							</div>
							<Progress
								percent={(opt.votes.length / getTotalVoteCount()) * 100 || 0}
								variant="PERCENT_LOADER"
								className="tw-rounded-2xl"
								style={{ height: '6px', backgroundColor: '#FBF8FF' }}
							/>
						</div>
					))}
					<p className="text-right tw-mb-0 tw-mt-4 tw-text-xs vote-count">
						{getTotalVoteCount()} vote
					</p>
				</>
			)}
		</div>
	);
};
