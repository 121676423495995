import {
	IProject,
	IProjectDetail,
	IGroup,
	TInvitedEmail,
	TUserAndRole,
	IObserver
} from '@naya_studio/types';
import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	TActionType,
	TAddStageThunkArg,
	TAddUserToProjectFulfill,
	TCreateNewStageFromBlockThunkArg,
	TAddUserToProjectThunkArg,
	TCreateProjectFulfill,
	TCreateProjectThunkArg,
	TDeleteProjectThunkArg,
	TDeleteGroupThunkArg,
	TDuplicateProjectFulfill,
	TDuplicateStageThunkArg,
	TEditProjectDetailsThunkArg,
	TEditProjectThunkArg,
	TEditUserRoleForProjectFulfill,
	TEditUserRoleForProjectThunkArg,
	TLoadProjectByIdFulfill,
	TLoadProjectThunkArg,
	TRemoveUserFromProjectFulfill,
	TRemoveUserFromProjectThunkArg,
	TBatchCreateGroupAndBlocks,
	TAddStageFulfill,
	TDuplicateStageFulfill,
	TDeleteBlockArgs,
	TReorderBlocksThunkArg,
	TGetProjectForGuest,
	TUndoRedoArgs,
	TSyncProjectWithDriveThunkArg,
	TNestingThunkArgs,
	TCreateStageWithBlocksFulfill,
	TProjectAIStatusThunkArg,
	TLoadTemplateByIdFulfill
} from 'src/types/argTypes';
import { isNumber } from 'lodash';
import {
	addUserToProject,
	createProject,
	deleteProject,
	duplicateProject,
	editProject,
	editProjectDetails,
	editUserRoleForProject,
	loadProjectById,
	loadProjectForGuestAction,
	loadTemplateById,
	nestingAction,
	removeUserFromProject,
	syncProjectWithDrive,
	toggleAIStatus
} from '../reduxActions/project';
import {
	addStage,
	createNewStageFromOrphanBlock,
	batchCreateGroupAndBlocks,
	deleteStage,
	duplicateStage,
	reorderBlocks
} from '../reduxActions/stage';
import { IProjectInitState, TEditObserverPayload } from '../reducers/root.types';
import { deleteBlock, undoDelete } from '../reduxActions/block';
import { addUserInArray, removeUserFromArray } from './util';
import {
	createFolderWatch,
	createObserver,
	renewFolderWatch,
	stopFolderWatch,
	updateObserver
} from '../reduxActions/observer';

/**
 * Project Initial State
 */
const initialState: IProjectInitState = {
	data: {},
	loading: {},
	error: {}
};

/**
 * Project Slice to Handle Project related Redux Updates
 */
const projectSlice = createSlice({
	name: 'project',
	initialState,
	reducers: {
		/**
		 * Reducer to load project to redux
		 */
		loadProject: (state: IProjectInitState, action: PayloadAction<IProject>) => {
			const { payload } = action;

			state.data[payload._id as string] = {
				...state.data[payload._id as string],
				...payload
			};
		},
		/**
		 * Reducer to update a project
		 */
		updateProject: (state: IProjectInitState, action: PayloadAction<Partial<IProject>>) => {
			const { payload } = action;

			state.data[payload._id as string] = {
				...(state.data[payload._id as string] as IProject),
				...payload
			};
		},
		/**
		 * Reducer to update restricted access blocks/stages
		 */
		addIdToRestrictedAccess: (
			state: IProjectInitState,
			action: PayloadAction<{
				_id: string;
				type: 'BLOCK' | 'PHASE';
				removedFrom: string;
			}>
		) => {
			const { _id, type, removedFrom } = action.payload;
			const project = state.data[_id];
			const restrictedAccess = project?.restrictedAccess || { blocks: [], stages: [] };

			if (type === 'BLOCK')
				restrictedAccess!.blocks = [...restrictedAccess!.blocks, removedFrom];
			if (type === 'PHASE')
				restrictedAccess!.stages = [...restrictedAccess!.stages, removedFrom];

			state.data[_id] = {
				...(project as IProject),
				restrictedAccess
			};
		},
		/**
		 * Reducer to update restricted access blocks/stages
		 */
		removeIdFromRestrictedAccess: (
			state: IProjectInitState,
			action: PayloadAction<{
				_id: string;
				type: 'BLOCK' | 'PHASE';
				invitedTo: string;
			}>
		) => {
			const { _id, type, invitedTo } = action.payload;
			const project = state.data[_id];
			const restrictedAccess = project?.restrictedAccess || { blocks: [], stages: [] };

			if (type === 'BLOCK')
				restrictedAccess!.blocks = restrictedAccess!.blocks.filter(
					(blockId) => blockId !== invitedTo
				);
			if (type === 'PHASE')
				restrictedAccess!.stages = restrictedAccess!.stages.filter(
					(stageId) => stageId !== invitedTo
				);

			state.data[_id] = {
				...(project as IProject),
				restrictedAccess
			};
		},
		/**
		 * Reducer to unload a project
		 */
		unloadProject: (state: IProjectInitState, action: PayloadAction<string>) => {
			const { payload: projectId } = action;

			delete state.data[projectId as string];
		}
	},

	extraReducers: (builder: ActionReducerMapBuilder<IProjectInitState>) => {
		/** ---- CREATE PROJECT ---- */
		// FULFILLED
		builder.addCase(
			createProject.fulfilled,
			(
				state: IProjectInitState,
				action: PayloadAction<TActionType<TCreateProjectFulfill, {}>['payload']>
			) => {
				const { payload } = action;

				if (payload) {
					const { projectId, projectGroup } = payload;

					state.data[projectId] = {
						...(state.data[projectId] as IProject),
						projectGroup: projectGroup || state.data[projectId]?.projectGroup,
						restrictedAccess: {
							blocks: [],
							stages: []
						}
					};
					state.loading[projectId] = false;
					state.error[projectId] = null;
				}
			}
		);
		// PENDING
		builder.addCase(
			createProject.pending,
			(
				state: IProjectInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TCreateProjectThunkArg>['pendingMeta']
				>
			) => {
				const { _id: projectId, optimisticProject } = action.meta.arg.payload;

				if (projectId && optimisticProject) {
					state.data[projectId] = {
						...optimisticProject
					};
				}

				state.loading[projectId] = true;
				state.error[projectId] = null;
			}
		);

		/** ---- DELETE BLOCK ---- */
		// PENDING
		builder.addCase(
			deleteBlock.pending,
			(
				state: IProjectInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TDeleteBlockArgs>['pendingMeta']
				>
			) => {
				const { data } = action.meta.arg;

				if (data) {
					const { orphanGroupIds, projectId } = data;
					if (orphanGroupIds.length > 0) {
						state.data[projectId]!.children = state.data[projectId]!.children?.filter(
							(b) => !orphanGroupIds.includes(b as string)
						);
					}
				}
			}
		);
		// REJECTED
		builder.addCase(
			deleteBlock.rejected,
			(
				state: IProjectInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TDeleteBlockArgs>['rejectedMeta']
				>
			) => {
				const { data, prevState } = action.meta.arg;
				const { payload: error } = action;
				const { projectId } = data;

				state.data[projectId]!.children = prevState.prevProjectChildren;

				state.loading[projectId] = false;
				state.error[projectId] = error as string;
			}
		);
		// REJECTED
		builder.addCase(
			createProject.rejected,
			(
				state: IProjectInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TCreateProjectThunkArg>['rejectedMeta']
				>
			) => {
				const { payload: error } = action;
				const { _id: projectId } = action.meta.arg.payload;

				if (projectId && state.data[projectId]) {
					delete state.data[projectId];
					state.loading[projectId] = false;
				}

				state.error[projectId] = error as string;
			}
		);

		/** ---- LOAD PROJECT BY ID ---- */
		// FULFILLED
		builder.addCase(
			loadProjectById.fulfilled,
			(
				state: IProjectInitState,
				action: PayloadAction<TActionType<TLoadProjectByIdFulfill, {}>['payload']>
			) => {
				const { payload } = action;

				if (payload?.project) {
					const { project, restrictedAccess } = payload;
					const projectId = project._id as string;
					let overwriteProject = true;

					if (state.data[projectId]) {
						const oldProject = state.data[projectId];

						// Only update the project if project from api have been updated later
						// to prevent the scenario where a user refreshes and someone edits a project
						if (
							new Date(oldProject?.updatedAt as string) >
							new Date(project.updatedAt as string)
						)
							overwriteProject = false;
					}

					if (overwriteProject) {
						state.data[projectId] = {
							...project,
							restrictedAccess
						};
					}

					state.loading[projectId] = false;
					state.error[projectId] = null;
				}
			}
		);
		// REJECTED
		builder.addCase(
			loadProjectById.rejected,
			(
				state: IProjectInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TLoadProjectThunkArg>['rejectedMeta']
				>
			) => {
				const { _id: id } = action.meta.arg.payload;
				const { payload: error } = action;

				if (error) {
					state.error = {
						...state.error,
						[id]: error as string
					};

					state.loading[id] = false;
				}
			}
		);
		// PENDING
		builder.addCase(
			loadProjectById.pending,
			(
				state: IProjectInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TLoadProjectThunkArg>['pendingMeta']
				>
			) => {
				const { _id: id } = action.meta.arg.payload;

				state.loading[id] = true;
				state.error[id] = '';
			}
		);

		/** ---- LOAD TEMPLATE ---- */
		// FULFILLED
		builder.addCase(
			loadTemplateById.fulfilled,
			(
				state: IProjectInitState,
				action: PayloadAction<TActionType<TLoadTemplateByIdFulfill, {}>['payload']>
			) => {
				const { payload } = action;

				if (payload?.template?.project) {
					const { project } = payload.template;
					const projectId = project._id as string;

					state.data[projectId] = project;
					state.loading[projectId] = false;
					state.error[projectId] = null;
				}
			}
		);

		/** ---- EDIT PROJECT ---- */
		// FULFILLED
		builder.addCase(
			editProject.fulfilled,
			(
				state: IProjectInitState,
				action: PayloadAction<TActionType<TEditProjectThunkArg['payload'], {}>['payload']>
			) => {
				const { _id: projectId } = action.payload;

				const project = state.data[projectId];
				if (project) {
					state.loading[projectId] = false;
					state.error[projectId] = null;
				}
			}
		);
		// PENDING
		builder.addCase(
			editProject.pending,
			(
				state: IProjectInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TEditProjectThunkArg>['pendingMeta']
				>
			) => {
				const { _id: id, update } = action.meta.arg.payload;

				const project = state.data[id];
				if (update && project) {
					state.data[id] = {
						...project,
						...update
					};
					state.loading[id] = false;
					state.error[id] = null;
				}
			}
		);
		// REJECTED
		builder.addCase(
			editProject.rejected,
			(
				state: IProjectInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TEditProjectThunkArg>['rejectedMeta']
				>
			) => {
				const { prevProject } = action.meta.arg.prevState;
				const { payload: error } = action;

				if (prevProject && prevProject._id) {
					const project = state.data[prevProject._id as string];

					if (project) {
						state.data[prevProject._id as string] = prevProject;
						state.error[prevProject._id as string] = error as string;
						state.loading[prevProject._id as string] = false;
					}
				}
			}
		);

		/** ---- DUPLICATE PROJECT ---- */
		// FULFILLED
		builder.addCase(
			duplicateProject.fulfilled,
			(
				state: IProjectInitState,
				action: PayloadAction<TActionType<TDuplicateProjectFulfill, {}>['payload']>
			) => {
				const { project } = action.payload;

				state.data[project._id as string] = project as IProject;
				state.loading[project._id as string] = false;
				state.error[project._id as string] = null;
			}
		);

		/** ---- DELETE PROJECT ---- */
		// FULFILLED
		builder.addCase(
			deleteProject.fulfilled,
			(
				state: IProjectInitState,
				action: PayloadAction<TActionType<TDeleteProjectThunkArg['payload'], {}>['payload']>
			) => {
				const { _id: id } = action.payload;

				if (state.data[id]) {
					delete state.data[id];
					state.loading[id] = false;
					state.error[id] = null;
				}
			}
		);

		/** ---- EDIT PROJECT DETAILS ---- */
		// FULFILLED
		builder.addCase(
			editProjectDetails.fulfilled,
			(
				state: IProjectInitState,
				action: PayloadAction<
					TActionType<TEditProjectDetailsThunkArg['payload'], {}>['payload']
				>
			) => {
				const { payload } = action;

				if (payload) {
					const { projectId } = payload;

					state.loading[projectId] = false;
					state.error[projectId] = null;
				}
			}
		);
		// PENDING
		builder.addCase(
			editProjectDetails.pending,
			(
				state: IProjectInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TEditProjectDetailsThunkArg>['pendingMeta']
				>
			) => {
				const { payload } = action.meta.arg;
				const { projectId, updates: projectDetails } = payload;

				const project = state.data[projectId];
				const details = project?.projectDetails;

				if (project && details) {
					state.data = {
						...state.data,
						[projectId]: {
							...project,
							projectDetails: {
								...(details as IProjectDetail),
								...projectDetails
							}
						}
					};
				}

				state.loading[projectId] = true;
				state.error[projectId] = null;
			}
		);
		// REJECTED
		builder.addCase(
			editProjectDetails.rejected,
			(
				state: IProjectInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TEditProjectDetailsThunkArg>['rejectedMeta']
				>
			) => {
				const { payload: error } = action;
				const { payload, prevState } = action.meta.arg;
				const { projectId } = payload;

				const project = state.data[projectId];
				const details = project?.projectDetails;

				if (projectId) {
					state.data = {
						...state.data,
						[projectId]: {
							...(state.data[projectId] as IProject),
							projectDetails: {
								...details,
								name: prevState?.name
							}
						}
					};

					state.error = {
						...state.error,
						[projectId]: error as string
					};
					state.loading[projectId] = false;
				}
			}
		);

		/** ---- ADD USER TO PROJECT ---- */
		// FULFILLED
		builder.addCase(
			addUserToProject.fulfilled,
			(
				state: IProjectInitState,
				action: PayloadAction<
					TActionType<TAddUserToProjectFulfill, {}>['payload'],
					string,
					TActionType<{}, TAddUserToProjectThunkArg>['fulfilledMeta']
				>
			) => {
				if (action.meta.arg) {
					const { payload } = action.meta.arg;

					if (payload) {
						const { isInvitedUser, email, userId, role, projectId } = payload;

						// Add user to users/invitedEmails in redux
						if (projectId && state.data[projectId]) {
							if (isInvitedUser) {
								const invitedEmails = state.data[projectId]
									?.invitedEmails as TInvitedEmail[];
								const updatedInvitedEmails = addUserInArray(
									isInvitedUser,
									email,
									role,
									invitedEmails
								);
								state.data[projectId]!.invitedEmails =
									updatedInvitedEmails as TInvitedEmail[];
							} else {
								const users = state.data[projectId]?.users as TUserAndRole[];
								const updatedUsers = addUserInArray(
									isInvitedUser,
									userId as string,
									role,
									users
								);
								state.data[projectId]!.users = updatedUsers as TUserAndRole[];
							}

							state.error[projectId] = null;
						}
					}
				}
			}
		);
		// PENDING
		builder.addCase(
			addUserToProject.pending,
			(
				state: IProjectInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TAddUserToProjectThunkArg>['pendingMeta']
				>
			) => {
				if (action.meta.arg) {
					const { payload } = action.meta.arg;

					if (payload) {
						const { isInvitedUser, email, userId, role, projectId } = payload;

						// Add user to users/invitedEmails in redux
						if (projectId && state.data[projectId]) {
							if (isInvitedUser) {
								const invitedEmails = state.data[projectId]
									?.invitedEmails as TInvitedEmail[];
								const updatedInvitedEmails = addUserInArray(
									isInvitedUser,
									email,
									role,
									invitedEmails
								);
								state.data[projectId]!.invitedEmails =
									updatedInvitedEmails as TInvitedEmail[];
							} else {
								const users = state.data[projectId]?.users as TUserAndRole[];
								const updatedUsers = addUserInArray(
									isInvitedUser,
									userId as string,
									role,
									users
								);
								state.data[projectId]!.users = updatedUsers as TUserAndRole[];
							}

							state.error[projectId] = null;
						}
					}
				}
			}
		);
		// REJECTED
		builder.addCase(
			addUserToProject.rejected,
			(
				state: IProjectInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TAddUserToProjectThunkArg>['rejectedMeta']
				>
			) => {
				if (action.meta.arg) {
					const { payload } = action.meta.arg;
					const { payload: error } = action;

					if (payload) {
						const { isInvitedUser, email, userId, projectId } = payload;

						// Remove added user from users/invitedEmails in redux
						if (projectId && state.data[projectId]) {
							if (isInvitedUser) {
								const invitedEmails = state.data[projectId]?.invitedEmails;
								if (invitedEmails) {
									state.data[projectId]!.invitedEmails = invitedEmails.filter(
										(u) => (u as TInvitedEmail).email !== email
									);
								}
							} else {
								const users = state.data[projectId]?.users;
								if (users) {
									state.data[projectId]!.users = users.filter(
										(u) => (u as TUserAndRole).user !== userId
									);
								}
							}

							state.loading[projectId] = false;
							state.error[projectId] = error as string;
						}
					}
				}
			}
		);

		/** ---- REMOVE USER FROM PROJECT ---- */
		// FULFILLED
		builder.addCase(
			removeUserFromProject.fulfilled,
			(
				state: IProjectInitState,
				action: PayloadAction<TActionType<TRemoveUserFromProjectFulfill, {}>['payload']>
			) => {
				const { payload } = action;

				if (payload) {
					const { projectId } = payload;

					if (projectId && state.data[projectId]) {
						state.loading[projectId] = false;
						state.error[projectId] = null;
					}
				}
			}
		);
		// PENDING
		builder.addCase(
			removeUserFromProject.pending,
			(
				state: IProjectInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TRemoveUserFromProjectThunkArg>['pendingMeta']
				>
			) => {
				const { payload } = action.meta.arg;

				if (payload) {
					const { invitedUserRole, projectId, userId } = payload;

					const isInvitedUser = !!invitedUserRole;
					if (projectId && state.data[projectId]) {
						if (isInvitedUser) {
							state.data[projectId]!.invitedEmails = removeUserFromArray(
								isInvitedUser,
								userId,
								state.data[projectId]!.invitedEmails as TInvitedEmail[]
							) as TInvitedEmail[];
						} else {
							state.data[projectId]!.users = removeUserFromArray(
								isInvitedUser,
								userId,
								state.data[projectId]!.users as TUserAndRole[]
							) as TUserAndRole[];
						}

						state.error[projectId] = null;
					}
				}
			}
		);
		// REJECTED
		builder.addCase(
			removeUserFromProject.rejected,
			(
				state: IProjectInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TRemoveUserFromProjectThunkArg>['rejectedMeta']
				>
			) => {
				const { payload } = action.meta.arg;

				if (payload) {
					const { projectId, invitedUserRole, userRole } = payload;

					const isInvitedUser = !!invitedUserRole;
					if (projectId && state.data[projectId]) {
						if (isInvitedUser) {
							state.data[projectId]!.invitedEmails!.push(invitedUserRole);
						} else {
							state.data[projectId]!.users!.push(userRole);
						}
					}

					state.loading[projectId] = false;
					state.error[projectId] = action.payload as string;
				}
			}
		);

		/** ---- EDIT USER ROLE FOR PROJECT ---- */
		// FULFILLED
		builder.addCase(
			editUserRoleForProject.fulfilled,
			(
				state: IProjectInitState,
				action: PayloadAction<TActionType<TEditUserRoleForProjectFulfill, {}>['payload']>
			) => {
				if (action.payload) {
					const { projectId } = action.payload;
					if (projectId && state.data[projectId]) {
						state.loading[projectId] = false;
						state.error[projectId] = null;
					}
				}
			}
		);
		// PENDING
		builder.addCase(
			editUserRoleForProject.pending,
			(
				state: IProjectInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TEditUserRoleForProjectThunkArg>['pendingMeta']
				>
			) => {
				const { payload } = action.meta.arg;

				if (payload) {
					const { projectId, invitedUser, userId, role } = payload;
					const isInvitedUser = !!invitedUser;

					if (projectId && state.data[projectId]) {
						// check user exists in the payload - meaning existing user
						if (isInvitedUser) {
							const invitedEmails = state.data[projectId]
								?.invitedEmails as TInvitedEmail[];
							if (invitedEmails) {
								for (let i = 0; i < invitedEmails.length; i++) {
									if (invitedEmails[i] && invitedEmails[i]?.email === userId) {
										invitedEmails[i]!.role = role;
									}
								}
							}
							state.data[projectId]!.invitedEmails = invitedEmails;
						} else {
							const users = state.data[projectId]?.users as TUserAndRole[];
							if (users) {
								for (let i = 0; i < users.length; i++) {
									if (users[i] && users[i]?.user === userId) {
										users[i]!.role = role;
									}
								}
							}
							state.data[projectId]!.users = users;
						}

						state.error[projectId] = null;
					}
				}
			}
		);
		// REJECTED
		builder.addCase(
			editUserRoleForProject.rejected,
			(
				state: IProjectInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TEditUserRoleForProjectThunkArg>['rejectedMeta']
				>
			) => {
				const { payload, prevRole } = action.meta.arg;

				if (payload) {
					const { projectId, invitedUser, userId } = payload;
					const isInvitedUser = !!invitedUser;

					if (projectId && state.data[projectId]) {
						// check user exists in the payload - meaning existing user
						if (isInvitedUser) {
							const invitedEmails = state.data[projectId]
								?.invitedEmails as TInvitedEmail[];
							if (invitedEmails) {
								for (let i = 0; i < invitedEmails.length; i++) {
									if (invitedEmails[i] && invitedEmails[i]?.email === userId) {
										invitedEmails[i]!.role = prevRole;
									}
								}
							}
							state.data[projectId]!.invitedEmails = invitedEmails;
						} else {
							const users = state.data[projectId]?.users as TUserAndRole[];
							if (users) {
								for (let i = 0; i < users.length; i++) {
									if (users[i] && users[i]?.user === userId) {
										users[i]!.role = prevRole;
									}
								}
							}
							state.data[projectId]!.users = users;
						}

						state.loading[projectId] = false;
						state.error[projectId] = null;
					}
				}
			}
		);

		/** ---- ADD NEW STAGE ---- */
		// FULFILLED
		builder.addCase(
			addStage.fulfilled,
			(
				state: IProjectInitState,
				action: PayloadAction<
					TActionType<TAddStageFulfill, {}>['payload'],
					string,
					TActionType<{}, TAddStageThunkArg>['fulfilledMeta']
				>
			) => {
				const { meta } = action;

				if (meta) {
					const { target, stage, newPhaseIndex } = meta.arg.payload;

					if (target.type !== 'JOURNEY' || !target.id) return;
					const projectId = target.id;
					if (projectId && state.data[projectId]) {
						const project = state.data[projectId] as IProject;
						const stageId = stage._id as string;

						if (!project.children!.includes(stageId))
							project.children!.splice(newPhaseIndex, 0, stageId);

						state.data[projectId] = project;
						state.loading[projectId] = false;
						state.error[projectId] = null;
					}
				}
			}
		);
		// PENDING
		builder.addCase(
			addStage.pending,
			(
				state: IProjectInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TAddStageThunkArg>['pendingMeta']
				>
			) => {
				const { payload } = action.meta.arg;

				if (payload) {
					const { target, stage, newPhaseIndex } = payload;

					if (target.type !== 'JOURNEY' || !target.id) return;
					const projectId = target.id;
					if (projectId && state.data[projectId]) {
						((state.data[projectId] as IProject).children as string[]).splice(
							newPhaseIndex,
							0,
							stage._id as string
						);

						state.loading[projectId] = true;
						state.error[projectId] = null;
					}
				}
			}
		);
		// REJECTED
		builder.addCase(
			addStage.rejected,
			(
				state: IProjectInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TAddStageThunkArg>['rejectedMeta']
				>
			) => {
				const { target, stage } = action.meta.arg.payload;
				if (target.type !== 'JOURNEY' || !target.id) return;
				const projectId = target.id;
				const { payload: error } = action;

				if (projectId && state.data[projectId]) {
					if (state.data[projectId]?.children) {
						state.data[projectId]!.children = state.data[projectId]!.children!.filter(
							(s) => s !== (stage as IGroup)._id
						);
					}

					state.loading[projectId] = false;
					state.error[projectId] = error as string;
				}
			}
		);

		/** ---- DUPLICATE STAGE ---- */
		// PENDING
		builder.addCase(
			duplicateStage.pending,
			(
				state: IProjectInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<TDuplicateStageThunkArg, TDuplicateStageThunkArg>['pendingMeta']
				>
			) => {
				const {
					projectId,
					insertIndex: position,
					clonedGroupId: newStageId,
					parent
				} = action.meta.arg.payload;
				if (parent.type !== 'JOURNEY') return;

				if (newStageId) {
					if (projectId && state.data[projectId]) {
						const newStages = state.data[projectId]?.children || [];
						const reOrderedStages = [
							...newStages.slice(0, position || 0),
							newStageId,
							...newStages.slice(position || 0)
						];

						state.data[projectId] = {
							...(state.data[projectId] as IProject),
							children: reOrderedStages as string[]
						};

						state.error[projectId] = null;
					}
				}
			}
		);
		// FULFILLED
		builder.addCase(
			duplicateStage.fulfilled,
			(
				state: IProjectInitState,
				action: PayloadAction<
					TActionType<TDuplicateStageFulfill, {}>['payload'],
					string,
					TActionType<{}, TDuplicateStageThunkArg>['fulfilledMeta']
				>
			) => {
				const {
					parent,
					insertIndex: position,
					clonedGroupId: newStageId
				} = action.meta.arg.payload;
				const { position: finalPosition } = action.payload;
				// if finalPosition received from back-end is not same as the position in the front-end
				// then remove the duplicated phase from it's old position and move it to final position.
				if (
					isNumber(position) &&
					isNumber(finalPosition) &&
					parent.type === 'PHASE' &&
					position !== finalPosition &&
					state.data[parent.id]?.children
				) {
					state.data[parent.id]!.children?.splice(position, 1);
					state.data[parent.id]!.children?.splice(finalPosition, 0, newStageId);
				}
			}
		);
		// REJECTED
		builder.addCase(
			duplicateStage.rejected,
			(
				state: IProjectInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TDuplicateStageThunkArg>['rejectedMeta']
				>
			) => {
				const { projectId, clonedGroupId: newStageId, parent } = action.meta.arg.payload;
				if (parent.type !== 'JOURNEY') return;
				const { payload: error } = action;

				if (projectId && state.data[projectId]) {
					if (state.data[projectId]?.children) {
						state.data[projectId]!.children = state.data[projectId]!.children!.filter(
							(s) => s !== newStageId
						);
					}

					state.loading[projectId] = false;
					state.error[projectId] = error as string;
				}
			}
		);
		/** ---- DUPLICATE STAGE ---- */

		/** ---- DELETE STAGE ---- */
		// FULFILLED
		builder.addCase(
			deleteStage.fulfilled,
			(
				state: IProjectInitState,
				action: PayloadAction<TActionType<TDeleteGroupThunkArg['payload'], {}>['payload']>
			) => {
				const { payload } = action;

				if (payload) {
					const { projectId } = payload;
					state.loading[projectId] = false;
					state.error[projectId] = null;
				}
			}
		);
		// PENDING
		builder.addCase(
			deleteStage.pending,
			(
				state: IProjectInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TDeleteGroupThunkArg>['pendingMeta']
				>
			) => {
				const { payload } = action.meta.arg;

				if (payload) {
					const { stageId, projectId } = payload;
					if (state.data[projectId]) {
						state.data[projectId]!.children = state.data[projectId]!.children?.filter(
							(s) => s !== stageId
						);
					}

					state.loading[projectId] = true;
					state.error[projectId] = null;
				}
			}
		);
		// REJECTED
		builder.addCase(
			deleteStage.rejected,
			(
				state: IProjectInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TDeleteGroupThunkArg>['rejectedMeta']
				>
			) => {
				const { payload, prevState } = action.meta.arg;
				const { payload: error } = action;

				if (payload) {
					const { projectId } = payload;

					if (state.data[projectId] && prevState) {
						const { groupIds } = prevState;
						if (groupIds) state.data[projectId]!.children = groupIds;
					}

					state.loading[projectId] = false;
					state.error[projectId] = error as string;
				}
			}
		);

		/** ---- REORDER BLOCKS ---- */
		// PENDING
		builder.addCase(
			reorderBlocks.pending,
			(
				state: IProjectInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TReorderBlocksThunkArg>['pendingMeta']
				>
			) => {
				const { payload } = action.meta.arg;

				if (payload) {
					const {
						sourceBlocks,
						sourcePhaseId,
						destinationPhaseId,
						isPhaseCreated,
						projectId
					} = payload;
					if (sourcePhaseId !== destinationPhaseId) {
						if (sourceBlocks.length <= 0 && !isPhaseCreated) {
							state.data[projectId]!.children = state.data[
								projectId
							]!.children?.filter((s) => s !== sourcePhaseId);
						}
					}
				}
			}
		);
		// REJECTED
		builder.addCase(
			reorderBlocks.rejected,
			(
				state: IProjectInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TReorderBlocksThunkArg>['rejectedMeta']
				>
			) => {
				const { payload, prevState } = action.meta.arg;
				const { payload: error } = action;

				if (payload && prevState) {
					const { prevStageIds } = prevState;
					const { projectId } = payload;
					if (projectId && state.data[projectId]) {
						state.data[projectId]!.children = prevStageIds;
						state.loading[projectId] = false;
						state.error[projectId] = error as string;
					}
				}
			}
		);

		/** ---- CREATE NEW STAGE FROM BLOCK ---- */
		// FULFILLED
		builder.addCase(
			createNewStageFromOrphanBlock.fulfilled,
			(
				state: IProjectInitState,
				action: PayloadAction<
					TActionType<true | Error, {}>['payload'],
					string,
					TActionType<{}, TCreateNewStageFromBlockThunkArg>['fulfilledMeta']
				>
			) => {
				const { payload } = action.meta.arg;

				if (payload.projectId) {
					const { stageData, projectId, newPhaseIndex, orphanGroupIds, parent } = payload;
					if (parent.type !== 'JOURNEY') return;

					if (projectId && state.data[projectId]) {
						const project = state.data[projectId] as IProject;
						const stageId = stageData._id as string;

						if (!project.children!.includes(stageId))
							project.children!.splice(newPhaseIndex, 0, stageId);

						if (orphanGroupIds && orphanGroupIds?.length > 0) {
							project.children = (project.children as string[]).filter(
								(s) => !orphanGroupIds.includes(s)
							);
						}

						state.data[projectId] = project;
						state.loading[projectId] = false;
						state.error[projectId] = null;
					}
				}
			}
		);
		// PENDING
		builder.addCase(
			createNewStageFromOrphanBlock.pending,
			(
				state: IProjectInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TCreateNewStageFromBlockThunkArg>['pendingMeta']
				>
			) => {
				const { payload } = action.meta.arg;
				if (payload) {
					const { stageData, projectId, newPhaseIndex, orphanGroupIds, parent } = payload;
					if (parent.type !== 'JOURNEY') return;

					if (state.data[projectId] && state.data[projectId]?.children) {
						((state.data[projectId] as IProject).children as string[]).splice(
							newPhaseIndex,
							0,
							stageData._id as string
						);

						if (orphanGroupIds && orphanGroupIds?.length > 0) {
							(state.data[projectId] as IProject).children = (
								(state.data[projectId] as IProject).children as string[]
							).filter((s) => !orphanGroupIds.includes(s));
						}
					}
				}
			}
		);
		// REJECTED
		builder.addCase(
			createNewStageFromOrphanBlock.rejected,
			(
				state: IProjectInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TCreateNewStageFromBlockThunkArg>['rejectedMeta']
				>
			) => {
				const { payload, prevState } = action.meta.arg;

				if (payload) {
					const { projectId, parent } = payload;
					if (parent.type !== 'JOURNEY') return;

					const { prevStageIds } = prevState;

					if (state.data[projectId] && state.data[projectId]?.children) {
						((state.data[projectId] as IProject).children as string[]) = prevStageIds;
					}
				}
			}
		);

		/** --- CREATE PHASE WITH EMPTY BLOCKS ---- */
		// FULFILLED
		builder.addCase(
			batchCreateGroupAndBlocks.fulfilled,
			(
				state: IProjectInitState,
				action: PayloadAction<
					TActionType<TCreateStageWithBlocksFulfill, {}>['payload'],
					string,
					TActionType<{}, TBatchCreateGroupAndBlocks>['fulfilledMeta']
				>
			) => {
				const { children, parent } = action.payload;
				if (parent.type !== 'JOURNEY') return;

				const projectId = parent.id;

				if (projectId && children) {
					state.data[projectId]!.children = children;
					state.loading[projectId] = false;
					state.error[projectId] = null;
				}
			}
		);
		// PENDING
		builder.addCase(
			batchCreateGroupAndBlocks.pending,
			(
				state: IProjectInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TBatchCreateGroupAndBlocks>['pendingMeta']
				>
			) => {
				const { children, parent, startGroupIndex } = action.meta.arg;
				if (parent.type !== 'JOURNEY') return;

				const projectId = parent.id;

				if (projectId && state.data[projectId]) {
					children.forEach((id, ind) => {
						state.data[projectId]?.children?.splice(startGroupIndex + ind, 0, id);
					});

					state.loading[projectId] = true;
					state.error[projectId] = null;
				}
			}
		);
		// REJECTED
		builder.addCase(
			batchCreateGroupAndBlocks.rejected,
			(
				state: IProjectInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TBatchCreateGroupAndBlocks>['rejectedMeta']
				>
			) => {
				const { parent, children } = action.meta.arg;

				if (parent.type !== 'JOURNEY') return;

				const projectId = parent.id;

				const { payload: error } = action;

				if (projectId && state.data[projectId]) {
					if (state.data[projectId]?.children) {
						state.data[projectId]!.children = state.data[projectId]!.children!.filter(
							(s) => !children.includes(s as string)
						);
					}

					state.loading[projectId] = false;
					state.error[projectId] = error as string;
				}
			}
		);

		/** ---- LOAD PROJECT FOR GUEST ---- */
		// FULFILLED
		builder.addCase(
			loadProjectForGuestAction.fulfilled,
			(
				state: IProjectInitState,
				action: PayloadAction<TActionType<TLoadProjectByIdFulfill, {}>['payload']>
			) => {
				const { payload } = action;

				if (payload?.project) {
					const { project } = payload;
					state.data[project._id as string] = project;
					state.loading[project._id as string] = false;
					state.error[project._id as string] = null;
				}
			}
		);
		// REJECTED
		builder.addCase(
			loadProjectForGuestAction.rejected,
			(
				state: IProjectInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TGetProjectForGuest>['rejectedMeta']
				>
			) => {
				const { projectId } = action.meta.arg.payload;
				const { payload: error } = action;

				if (error) {
					state.error = {
						...state.error,
						[projectId]: error as string
					};

					state.loading[projectId] = false;
				}
			}
		);
		// Undo delete

		// PENDING
		builder.addCase(
			undoDelete.pending,
			(
				state: IProjectInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TUndoRedoArgs>['pendingMeta']
				>
			) => {
				const { projectChildrenIds, parent } = action.meta.arg.data;
				if (parent.type !== 'JOURNEY') return;

				if (projectChildrenIds.length > 0 && state.data[parent.id]) {
					state.data[parent.id]!.children = projectChildrenIds as string[];
				}
			}
		);

		// REJECTED
		builder.addCase(
			undoDelete.rejected,
			(
				state: IProjectInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TUndoRedoArgs>['rejectedMeta']
				>
			) => {
				const { data, prevState } = action.meta.arg;
				const { parent } = data;
				if (parent.type !== 'JOURNEY') return;
				const projectId = parent.id;
				const { prevProjectChildrenIds } = prevState;

				const { payload: error } = action;

				if (state.data[projectId] && prevProjectChildrenIds) {
					state.data[projectId]!.children = prevProjectChildrenIds;
					state.loading[projectId] = false;
					state.error[projectId] = error as string;
				}
			}
		);
		/** ----- CREATE PROJECT OBSERVER ----- */
		// PENDING
		builder.addCase(
			createObserver.pending,
			(
				state: IProjectInitState,
				action: PayloadAction<undefined, string, TActionType<{}, IObserver>['pendingMeta']>
			) => {
				const { projectId } = action.meta.arg;
				(state.data[projectId]?.observers || []).push(action.meta.arg);
				state.error[projectId] = null;
			}
		);
		// REJECTED
		builder.addCase(
			createObserver.rejected,
			(
				state: IProjectInitState,
				action: PayloadAction<unknown, string, TActionType<{}, IObserver>['rejectedMeta']>
			) => {
				const { projectId, _id } = action.meta.arg;
				state.data[projectId]!.observers =
					state.data[projectId]?.observers?.filter(
						(item) => (item as IObserver)._id !== _id
					) || [];
				state.error[projectId] = action.payload as string;
			}
		);
		/** ----- UPDDATE PROJECT OBSERVER ------ */
		builder.addCase(
			updateObserver.fulfilled,
			(
				state: IProjectInitState,
				action: PayloadAction<TActionType<TEditObserverPayload, {}>['payload']>
			) => {
				const { observerId, projectId, data } = action.payload;
				state.data[projectId]!.observers = (state.data[projectId]?.observers || []).map(
					(item) =>
						(item as IObserver)._id === observerId
							? { ...(item as IObserver), ...data }
							: item
				);
			}
		);
		/** ----- CREATE OBSERVER CHANNEL ------- */
		builder.addCase(
			createFolderWatch.fulfilled,
			(
				state: IProjectInitState,
				action: PayloadAction<TActionType<IObserver, {}>['payload']>
			) => {
				const { _id, projectId } = action.payload;
				state.data[projectId]!.observers = (state.data[projectId]?.observers || []).map(
					(item) => ((item as IObserver)._id === _id ? action.payload : item)
				);
			}
		);
		/** ------ RENEW OBSERVER CHANNEL ------- */
		builder.addCase(
			renewFolderWatch.fulfilled,
			(
				state: IProjectInitState,
				action: PayloadAction<TActionType<IObserver, {}>['payload']>
			) => {
				const { _id, projectId } = action.payload;
				state.data[projectId]!.observers = (state.data[projectId]?.observers || []).map(
					(item) => ((item as IObserver)._id === _id ? action.payload : item)
				);
			}
		);
		/** ------ STOP OBSERVER CHANNEL -------- */
		builder.addCase(
			stopFolderWatch.fulfilled,
			(
				state: IProjectInitState,
				action: PayloadAction<TActionType<IObserver, {}>['payload']>
			) => {
				const { _id, projectId } = action.payload;
				state.data[projectId]!.observers = (state.data[projectId]?.observers || []).map(
					(item) => ((item as IObserver)._id === _id ? action.payload : item)
				);
			}
		);
		builder.addCase(
			syncProjectWithDrive.fulfilled,
			(
				state: IProjectInitState,
				action: PayloadAction<TActionType<TSyncProjectWithDriveThunkArg, {}>['payload']>
			) => {
				const { projectId } = action.payload;
				state.data[projectId]!.observers = (state.data[projectId]?.observers || []).map(
					(item) =>
						(item as IObserver).observerType === 'GOOGLE_DRIVE'
							? { ...(item as IObserver), isSyncInProgress: false }
							: item
				);
			}
		);

		/** ---- NESTING ---- */
		builder.addCase(
			nestingAction.pending,
			(
				state: IProjectInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TNestingThunkArgs>['pendingMeta']
				>
			) => {
				const { project } = action.meta.arg.payload;

				if (project && project._id) {
					state.data[project._id]!.children = project.update.children;
				}
			}
		);
		builder.addCase(
			nestingAction.rejected,
			(
				state: IProjectInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TNestingThunkArgs>['rejectedMeta']
				>
			) => {
				const {
					project: { prevProject }
				} = action.meta.arg.prevState;
				const { payload: error } = action;

				if (prevProject && prevProject._id) {
					const project = state.data[prevProject._id as string];

					if (project) {
						state.data[prevProject._id as string] = prevProject;
						state.error[prevProject._id as string] = error as string;
						state.loading[prevProject._id as string] = false;
					}
				}
			}
		);
		/** ---- NESTING-END ---- */
		builder.addCase(
			toggleAIStatus.fulfilled,
			(
				state: IProjectInitState,
				action: PayloadAction<TActionType<TProjectAIStatusThunkArg, {}>['payload']>
			) => {
				const { projectId, isAIFeaturesEnabled, isContentTraningEnabled } = action.payload;
				state.data[projectId]!.isAIFeatureEnabled = isAIFeaturesEnabled;
				state.data[projectId]!.aiIngestStatus = isContentTraningEnabled
					? 'IN_PROGRESS'
					: 'INACTIVE';
			}
		);
	}
});

export const {
	loadProject,
	updateProject,
	unloadProject,
	addIdToRestrictedAccess,
	removeIdFromRestrictedAccess
} = projectSlice.actions;

export default projectSlice.reducer;
