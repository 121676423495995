/** Libraries */
import axios from 'axios';

/** Naya Types */
import { ENodeType, IBlock, INode, IProject, IUser, TextStyleExtended } from '@naya_studio/types';

/** Pixi App */

/** Redux */

/** Endpoints */
import { CustomDispatch } from 'src/redux/actions/types';
import { Container, DisplayObject, Graphics, InteractionEvent } from 'pixi.js';
import { undoAction } from 'src/redux/actions/undoRedoActions';
import { EActionType } from 'src/redux/reducers/undoRedo/undoActionHistory.types';

import { v1 as uuidv1 } from 'uuid';
import _ from 'lodash';
import * as PIXI from 'pixi.js';
import { addNodes, editNodes } from 'src/redux/reduxActions/node';
import { TAddNodesArg, TEditNodesArgs } from 'src/types/argTypes';
import { generateIdsFromUrl } from 'src/redux/reduxActions/util';
import { ReduxState } from 'src/redux/reducers/root.types';
import getUserFromRedux from 'src/util/helper/user';
import App from '../App';
import { reduxManager, store } from '../../../../index';
import { nodeRouter } from '../../../../endpoints/projects-api';
import { getGatewayKey } from '../../../../util/helper/queryString';

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
const qs = `?${getGatewayKey()}`;

/**
 * Base class for all nodes in collaboration platform
 */
class BaseNode {
	static textNodeDefaults: { padding: any; style: TextStyleExtended } = {
		padding: {
			top: 2,
			left: 4,
			right: 4,
			bottom: 6
		},
		style: {
			fontSize: 16,
			fontFamily: 'Rand-Regular',
			fill: '#000000',
			align: 'left',
			fontStyle: 'normal',
			fontWeight: 'normal',
			textDecoration: 'none'
		}
	};

	nodeData: INode;

	app: App;

	displayObject?: Container;

	render: any;

	withoutTextContainer: PIXI.Container = new PIXI.Container();

	project: IProject;

	_cropMode: boolean = false;

	_editMode: boolean = false;

	prevNodeData?: INode;

	_defaultTransformerType: typeof this.app.transformerType = 'default';

	_currentTransformerType: typeof this.app.transformerType = 'default';

	_doubleTimer: any;

	_interactive: boolean = true;

	/** FIXME: Needs to be stored in Node Data * */
	_isLocked: boolean = false;

	_lastTransformType: string | undefined;

	outline?: Graphics;

	protected authorSprites: { [key: string]: PIXI.Texture } = {};

	author?: { image: PIXI.Sprite; circularMask: PIXI.Graphics }; // for nodes with author icon

	pixiAuthor?: { graphics: PIXI.Graphics; text: PIXI.Text }; // for nodes with author icon

	isAuthorImage?: boolean;

	modelRendererIndex: number = 0;

	editMode: boolean = false;

	canvasContext: any;

	threeTexture?: PIXI.BaseTexture;

	moveMode: boolean = false;

	isFrameHover: boolean = false;

	isMouseDown: boolean = false;

	isMouseMove: boolean = false;

	isFrameMouseDown: boolean = false;

	cubeContainer: PIXI.Container = new PIXI.Container();

	transformChangeStarted: boolean = false;

	_transformerChanged: boolean = false; // --> pulled from Image

	/**
	 * Base Node constructor
	 * @constructor
	 * @param {App} app Pixi App
	 * @param {INode} nodeData Node data
	 */
	constructor(app: App, nodeData: INode) {
		this.app = app;
		this.nodeData = nodeData;
		if (!this.nodeData._id) this.nodeData._id = uuidv1();
		this.render = app.render;
		this._isLocked = nodeData.isLocked ? nodeData.isLocked : false;
		const { projectId } = generateIdsFromUrl();
		this.project = store.getState().projects.data[projectId] as IProject;
		// this.app.viewport.off(nodeData._id as string);
		// this.app.viewport.on(nodeData._id as string, this.updateNode.bind(this));

		// Subscribing redux manager to list to the node changes
		reduxManager.subscribe(
			this.nodeData._id as string,
			this.eventSelector,
			(reduxState: ReduxState) => {
				if (this.nodeData._id) {
					const { nodes } = reduxState;
					const { data } = nodes;
					const node = data[this.nodeData._id];
					if (node) {
						// isEqual documentation - https://lodash.com/docs/#isEqual
						const isEqual = _.isEqual(
							_.omit(this.nodeData, ['version']),
							_.omit(node, ['version'])
						);
						this.nodeData = _.cloneDeep(node);
						if (!isEqual) {
							this.app.redrawNode(this.nodeData);
							this.app.removeTransformer();
							this.app.addSelectedNodesToTransformer();
							this.removeOutline();
						}
					}
				}
			}
		);
	}

	/**
	 * Function to remove the listeners on the node
	 */
	unsubscribeFromListeners = () => {
		reduxManager.unsubscribe(this.nodeData._id as string);
	};

	// Function to return the node versuon --> selector for the subscribe event
	eventSelector = (reduxState: ReduxState) => {
		if (this.nodeData._id) {
			const { nodes } = reduxState;
			const { data } = nodes;
			const node = data[this.nodeData._id];
			if (node) return node.version;
		}
		return null;
	};

	transformerClick = (e: any) => {
		console.error('transformerClick not implemented', this.nodeData.nodeType, e);
		// throw new Error('Method not implemented.');
	};

	transformerMouseMove = (e: any) => {
		console.error('transformer mouse move not implemented', this.nodeData.nodeType, e);
		// throw new Error('Method not implemented.');
	};

	/**
	 * Converts string based hex string colors to pixi compatible hex colors
	 * @function
	 * @param {string} color
	 * @returns {number} converted hex value in the required format - 0xFF0000
	 */
	static getColorValue = (color: any) => {
		if (color) {
			const tempColor = `0x${color.substring(1, color.length + 1)}`;
			return parseInt(tempColor, 16);
		}
		return 0x000000;
	};

	/**
	 *
	 * @returns {DisplayObject}
	 */
	getDisplayObject() {
		return this.displayObject;
	}

	edit(type: string, data: any, appendToPrevious: boolean) {
		// implemented in the child nodes
		console.error('edit not implemented', this.nodeData.nodeType, type, data, appendToPrevious);
	}

	/**
	 * Can be used to implement drag to draw functionality
	 * @function
	 * @param {number} x The x coordinate of the node
	 * @param {number} y The y coordinate of the node
	 * @param {number} width The width of the node
	 * @param {number} height The height of the node
	 */

	resize = (_x: number, _y: number, _width: number, _height: number) => {
		// implemented in the shape node
		console.error('resize not implemented', this.nodeData.nodeType, _x, _y, _width, _height);
	};

	/** removes outline added around the node on mouse hovering */
	removeOutline = () => {
		if (this.outline) {
			this.app.viewport?.removeChild(this.outline);
			this.outline = undefined;
		}
	};

	/**
	 * Default onDoubleClick handler. Override for different behavior
	 * @param e The PIXI interaction event
	 */
	onDoubleClick = (e: any) => {
		// implemented in the child nodes
		console.error('onDoubleClick not implemented', this.nodeData.nodeType, e);
	};

	onMouseOver = () => {
		if (!this.app.editText && !this._editMode && !this._cropMode) {
			const corners = App.calculateTransformedCorners(this.displayObject as DisplayObject);
			let bounds: number[] = [];
			// conveting the points to array
			corners.forEach((item) => {
				bounds = [...bounds, item.x, item.y];
			});
			const reduxState = store.getState();
			if (this.outline) {
				this.outline.clear();
			} else {
				this.outline = new PIXI.Graphics();
			}
			this.outline.beginFill(0x000000, 1e-4);
			this.outline.lineStyle(1, BaseNode.getColorValue(reduxState.theme.color), 1, 0);
			this.outline.drawPolygon(bounds);
			this.outline.endFill();
			this.outline.zIndex = this.app.getHighestZIndex();
			this.outline.interactive = false;
			this.app.viewport?.addChild(this.outline);
		}
	};

	onMouseOut = () => {
		this.removeOutline();
	};

	onMouseDown = (e: InteractionEvent) => {
		// return if mouse middle button is used and emitting mousedown on frame, so it will handle
		// the middle button
		if (e.data.button === 1) {
			this.app.frame?.displayObject.emit('mousedown', e);
			return;
		}
		e.stopPropagation();
		this.app.resetFileIconColor(); // for resetting the file icon color to base color
		this.app.disableHighlighting();

		if (!this.app.isPanSelected) {
			this.selectNode();
			if (
				this.app.transformer.group.length === 1 &&
				!this.app.checkIfAnySelectedNodeIsLocked()
			) {
				if (this.app.transformer.group[0] === this.displayObject) {
					this.app.transformer.emit('pointerdown', e);
				} else if (
					this.nodeData.nodeType === 'TEXT' &&
					this.app.transformer.group[0] === this.displayObject?.children[0] &&
					!this.app.showLinkContainer
				) {
					this.app.transformer.emit('pointerdown', e);
				}
			}
		}
	};

	onMouseUp = (e: PIXI.InteractionEvent) => {
		this.app.transformer.emit('pointerup', e);
		e.stopPropagation();
	};

	onEditStart = () => {
		console.error('onEditStart not implemented', this.nodeData.nodeType);
	};

	onEditEnd = (e: Event) => {
		e.preventDefault();
		console.error('onEditStart not implemented', this.nodeData.nodeType);
		//  Implemented in child classes
	};

	/**
	 * function to set transformer type based upon selected nodes
	 */
	setTransformerType = () => {
		const allNodes = this.app.selectedNodes;
		let stickyNoteExixts = false;
		let textExixts = false;
		let isAnyNodeLocked = false;
		for (let i = 0; i < allNodes.length; i++) {
			const currentNode = allNodes[i] as BaseNode;
			if (currentNode._isLocked) {
				isAnyNodeLocked = true;
				break;
			} else if (currentNode.nodeData.nodeType === 'STICKY_NOTE') {
				stickyNoteExixts = true;
				break;
			} else if (currentNode.nodeData.nodeType === 'TEXT') {
				textExixts = true;
			} else if (currentNode.nodeData.nodeType === ENodeType.FILE_PLACEHOLDER) {
				textExixts = true;
			}
		}

		if (textExixts) {
			if (allNodes.length === 1) this.app.transformerType = 'text';
			else this.app.transformerType = 'sticky';
		} else if (stickyNoteExixts || isAnyNodeLocked || textExixts) {
			this.app.transformerType = 'sticky';
		} else {
			this.app.transformerType = 'default';
		}
		// this.app.render();
	};

	selectNode = () => {
		// Removing the outline if exists
		if (this.outline) {
			this.app.viewport?.removeChild(this.outline);
			this.outline = undefined;
		}
		if (this.app.canvasSelected) {
			this.app.canvasSelected = false;
			this.app.toggleCanvasEditMenu(false);
		}
		// Set as selected node in application
		this.app.setSelected(this);
		this.setTransformerType();
		this.app.nodeSaveIndex = 0;
	};

	/**
	 * Default onClick handler. Adds transformer to element. Override for different behavior
	 * @param e The PIXI interaction event
	 */
	onClick = () => {
		if (this.displayObject?.name === 'STICKY_NOTE') {
			this.app.stickyNoteData = this;
			this.app.stickyNoteHeight = this.displayObject?.height;
			this.app.stickyNoteWidth = this.displayObject?.width;
		}
	};

	rightClick = (e: any) => {
		this.app.pauseDragPlugin();
		this.app.rightClickedNode = this;
		this.app.toggle3dContext(true);
		this.app.toggleCanvasMenu(false);
		this.displayObject!.cursor = 'context-menu';
		e.stopPropagation();
	};

	setCropMode = () => {
		console.error('setCropMode not implemented', this.nodeData.nodeType);
		// Implemented in Image.tsx
	};

	revertCropChanges = () => {
		console.error('revertCropChanges not implemented', this.nodeData.nodeType);
		// Implemented in Image.tsx
	};

	exitCropMode = () => {
		console.error('exitCropMode not implemented', this.nodeData.nodeType);
		// Implemented in Image.tsx
	};

	saveCropMode = () => {
		console.error('saveCropMode not implemented', this.nodeData.nodeType);
		// Implemented in Image.tsx
	};

	setInteractive = (value: boolean) => {
		this._interactive = value;
		this.draw();
	};

	exitModelEditMode = () => {
		console.error('exitModelEditMode not implemented', this.nodeData.nodeType);
		// Implemented in Model.tsx
	};

	toggleControls = () => {
		console.error('toggleControls not implemented', this.nodeData.nodeType);
		// Implemented in Model.tsx
	};

	rotate = () => {
		console.error('rotate not implemented', this.nodeData.nodeType);
	};

	updateAuthorRotation = () => {
		const { rotation } = this.displayObject!.transform;
		if (this.author && this.author.image) this.author.image.rotation = -rotation;
		if (this.pixiAuthor && this.pixiAuthor.text) this.pixiAuthor.text.rotation = -rotation;
	};

	/**
	 * Override method to contain logic to draw node
	 */
	draw = () => {
		//  Implemented in child classes
		console.error('Not implemented', this.nodeData.nodeType);
	};

	/**
	 * Checks whether node has been persisted in the database
	 * @returns true if object has been saved to database, false otherwise
	 */
	isPersistedOnline = async () => {
		try {
			if (this.nodeData._id) {
				const response = await axios.get(`${nodeRouter}/${this.nodeData._id}${qs}`);

				return response.status === 200;
			}
			return false;
		} catch (e: any) {
			return false;
		}
	};

	/**
	 *
	 * @param id Getting the node id for newly added node and then adding to the undo stack
	 */
	setNodeId = (id: string) => {
		if (this.nodeData.nodeType !== 'ONBOARDING_IMAGE') {
			this.nodeData._id = id;
			undoAction(
				'NEW_NODE' as EActionType,
				{ ...this.nodeData, isVisible: false },
				_.cloneDeep(this.nodeData) as INode,
				false
			);
			if (this.displayObject) {
				if (this.app.selectedNodes.indexOf(this) !== -1) {
					// Remove the node from selectedNodes
					this.app.selectedNodes.splice(this.app.selectedNodes.indexOf(this), 1);

					// Find node in app.allNodes with the same id
					const node = this.app.allNodes?.find((n) => n.nodeData._id === id);
					// Add the node to selectedNodes
					if (node) {
						this.app.selectedNodes.push(node);
					}
					this.app.addSelectedNodesToTransformer();
				}
				this.app.viewport.removeChild(this.displayObject);
			}
		}
	};

	/**
	 * Adding the edit to the undo stack
	 */
	addUndoAction = () => {
		if (this.prevNodeData) {
			this.app.hasCanvasUpdatedForThumbnail = true;
			const appendToPrevious = this.app.nodeSaveIndex !== 0;
			undoAction(
				'EDIT' as EActionType,
				_.cloneDeep(this.prevNodeData) as INode,
				_.cloneDeep(this.nodeData) as INode,
				appendToPrevious
			);
			this.app.nodeSaveIndex += 1;
			if (
				this.app.nodeSaveIndex === this.app.selectedNodes.length ||
				(this.nodeData.nodeType === ENodeType.MODEL && this.app.selectedNodes.length === 0)
			) {
				this.app.nodeSaveIndex = 0;
			}
			this.prevNodeData = undefined;
		}
	};

	/**
	 * Function to toggle lock status of a node
	 */
	toggleLock = async () => {
		this._isLocked = !this._isLocked;
		this.nodeData.isLocked = this._isLocked;
		this.setTransformerType();
		if (this._isLocked) {
			this.app.transformer.translateEnabled = false;
		} else {
			this.app.transformer.translateEnabled = true;
		}
		this.render();
	};

	/**
	 * Saves the edited node
	 * @param nodeData
	 */
	saveEditNode = async (nodeData: INode) => {
		let isPersisted = false;
		const payload = JSON.parse(JSON.stringify(nodeData)) as INode;
		if (payload.version || payload.version === 0) {
			payload.version += 1;
		} else payload.version = 0;

		// check if online
		const reduxState = store.getState();
		const user: IUser = getUserFromRedux();
		const online = !!(user._id && user.email && this.app.getCanvasId());

		payload.lastUpdatedBy = user._id as string;

		// online mode
		if (online) {
			isPersisted = await this.isPersistedOnline();
		}

		// item exists in database
		if (isPersisted) {
			// generating payload for edit nodes action
			const apiPayload: TEditNodesArgs = {
				data: {
					nodes: [payload],
					...generateIdsFromUrl()
				},
				prevState: {
					prevBlocks: reduxState.nodes.data
				},
				next: () => {
					this.app.hasCanvasUpdatedForThumbnail = true;
				}
			};
			(store.dispatch as CustomDispatch)(editNodes(apiPayload));
		}
	};

	/**
	 * Save node to database
	 */
	save = async () => {
		let isPersisted: boolean;
		const nodeData = JSON.parse(JSON.stringify(this.nodeData));
		// check if online
		const reduxState = store.getState();
		const user: IUser = getUserFromRedux();
		const online = !!(user._id && user.email && this.app.getCanvasId());
		this.app.hasCanvasUpdatedForThumbnail = true;
		// online mode
		if (online) {
			isPersisted = await this.isPersistedOnline();

			// item exists in database
			if (isPersisted) {
				// generating payload for edit nodes action
				const apiPayload: TEditNodesArgs = {
					data: {
						nodes: [
							{
								...nodeData,
								createdBy: user._id as string,
								lastUpdatedBy: user._id as string,
								version: nodeData.version + 1
							}
						],
						...generateIdsFromUrl()
					},
					prevState: {
						prevBlocks: reduxState.nodes.data
					}
				};

				(store.dispatch as CustomDispatch)(editNodes(apiPayload))
					.unwrap()
					.then(() => {
						this.addUndoAction();
					});
			} else {
				const { blockId } = generateIdsFromUrl();
				if (!nodeData?._id) nodeData._id = uuidv1();
				this.setNodeId(nodeData._id as string);
				// generating payload for add nodes api
				const payload: TAddNodesArg = {
					data: {
						nodes: [
							{
								...nodeData,
								createdBy: user._id as string,
								lastUpdatedBy: user._id as string,
								version: 0
							}
						],
						...generateIdsFromUrl()
					},
					prevState: {
						prevNodes: reduxState.nodes.data,
						prevBlock: reduxState.blocks.data[blockId as string] as IBlock
					}
				};
				// item does not exist in database
				(store.dispatch as CustomDispatch)(addNodes(payload));
			}

			// offline mode
		}
	};

	updateNode = async (nodeData: INode) => {
		// Update this node only if its on screen
		if (this.displayObject && this.app.viewport.getChildIndex(this.displayObject) !== -1) {
			this.nodeData = { ...this.nodeData, ...nodeData };
			let index = -1;

			// If this object is currently selected, find the index of this node in the selected nodes
			for (let i = 0; i < this.app.selectedNodes.length; i++) {
				if (this.app.selectedNodes[i]?.nodeData._id === this.nodeData._id) {
					index = i;
					break;
				}
			}

			// If this object is currently selected, update the selected nodes
			if (index !== -1) {
				this.app.selectedNodes.splice(index, 1);
			}

			// Remove transformer from screen as it will lose context of the display object
			this.app.removeTransformer();
			const tempDisplayObject = this.displayObject;

			// Draw display object
			await this.draw();
			this._isLocked = nodeData.isLocked ? nodeData.isLocked : false;

			// If this object was previously selected, add it back to the selected nodes
			if (index !== -1) {
				this.app.selectedNodes.push(this);
			}
			this.app.addSelectedNodesToTransformer();

			// Destroy previous display object to avoid memory leaks
			tempDisplayObject.destroy({ children: true });
		}
	};
}

export default BaseNode;
