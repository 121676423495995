import { FC, ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { useFlags } from 'launchdarkly-react-client-sdk';
import BadgeContainer from 'src/components/badge/BadgeContainer';
import { UserProfileProps } from './UserProfile.types';
import './UserProfile.scss';
import ProfileCard from './profileCard/ProfileCard';

const UserProfile: FC<UserProfileProps> = (): ReactElement => {
	const { isProfileBadgeEnabled } = useFlags();
	return (
		<>
			{/* Set the title that is shown in browser tab */}
			<Helmet>
				<title>Profile - Naya</title>
			</Helmet>
			<div id="user-profile">
				<ProfileCard />
				{isProfileBadgeEnabled && <BadgeContainer />}
			</div>
		</>
	);
};

export default UserProfile;
