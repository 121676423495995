import { FC, ReactElement } from 'react';
import checkmark from 'src/assets/icons/sharing-modal/checkmark.svg';
import dropdownIcon from 'src/assets/icons/sharing-modal/dropdown.svg';
import { IBlock, IGroup, IProject, TUserAndRole } from '@naya_studio/types';
import {
	checkIfCurrentUserIsAdmin,
	checkIfUserExistsInList,
	checkUserAccessLevel
} from 'src/util/accessLevel/accessLevelActions';
import { getStageBlocks, getStagesBlocksNodesOfProject } from 'src/redux/reduxActions/util';
import { DropDown } from '@naya_studio/radix-ui';
import { fetchBlockByIdFromRedux } from 'src/redux/actions/util';
import useUser from 'src/redux/hooks/user';
import { store } from 'src';
import { MilestoneDropdownProps } from './MilestoneDropdown.types';
import './MilestoneDropdown.scss';
import { getFirstBlockIdByPhaseId } from '../../sharingUtil';

/**
 * Component to render Milestone Dropdown in Sharing Modal Header
 */
const MilestoneDropdown: FC<MilestoneDropdownProps> = ({
	activeTab,
	projectId,
	actionableBlock,
	actionablePhase,
	setActionableBlock,
	setActionablePhase
}): ReactElement => {
	const { user: currentUser } = useUser();
	const { stages } = getStagesBlocksNodesOfProject(projectId as string);
	const activeId = activeTab === 'BLOCK' ? actionableBlock._id : actionablePhase._id;

	/**
	 * Function to check if user has access to provided stage
	 */
	const checkIfUserHasStageLevelAccess = (stage: IGroup) => {
		const stageUsers = stage.users as TUserAndRole[];
		if (checkIfCurrentUserIsAdmin()) {
			return true;
		}
		if (checkIfUserExistsInList(stageUsers, currentUser._id as string)) {
			return true;
		}
		return false;
	};

	/**
	 * Function to render appropriate name in sharing modal dropdown
	 * @param name name
	 * @param index index
	 * @returns updated name
	 */
	const getCleanName = (name: string, index: number) => {
		if (name === 'UNDEFINED') return `Untitled ${index + 1}`;
		return name;
	};

	/**
	 * Function to get Dropdown items
	 */
	const getItems = (type: 'MAIN' | 'SUB', block: IBlock, stage: IGroup, index: number) => {
		const activeBlockOrStage = activeTab === 'BLOCK' ? block?._id : stage._id;
		const isItemDisable =
			(activeTab === 'PHASE' && type === 'MAIN') || (activeTab === 'BLOCK' && type === 'SUB');

		return (
			<DropDown.DropDownItem>
				<div
					role="presentation"
					className={`kebab-items tw-flex tw-items-center tw-text-xs tw-font-randRegular 
						tw-leading-6 tw-pt-1 tw-pb-1 tw-px-2 ${type === 'SUB' ? 'tw-mx-6' : 'disable-hover'}
						${!isItemDisable ? 'disable-hover tw-cursor-default tw-pointer-events-none' : 'tw-cursor-pointer'}`}
					onClick={() => {
						// If the phase block is empty, set 'actionable' as the first block of the first accessible phase.
						if (block) {
							setActionableBlock(block);
						} else {
							const blockId = getFirstBlockIdByPhaseId(
								stages,
								currentUser._id as string
							);
							setActionableBlock(
								fetchBlockByIdFromRedux(blockId as string) as IBlock
							);
						}
						setActionablePhase(stage);
					}}
				>
					<span className="item-box tw-flex tw-items-center">
						{activeBlockOrStage === activeId && isItemDisable && (
							<img src={checkmark} alt="checkmark" />
						)}
					</span>
					<span className="item-box tw-flex tw-items-center tw-justify-center tw-mx-2">
						<div className={`bullet ${isItemDisable ? 'main' : 'sub'}`} />
					</span>
					<div className={`tw-truncate flex-1 ${!isItemDisable ? 'disabled-text' : ''}`}>
						{type === 'MAIN'
							? getCleanName(stage.name, index)
							: getCleanName(block.name, index)}
					</div>
				</div>
			</DropDown.DropDownItem>
		);
	};

	/**
	 * Function to render blocks in dropdown
	 */
	const renderBlocks = (blocks: IBlock[], stage: IGroup) =>
		blocks.map((block, index) => getItems('SUB', block, stage, index));

	/**
	 * Function to render milestone menu in dropdown
	 */
	const renderMilestoneMenu = () =>
		stages.map((stage) => {
			// Block for which user has editor access
			const blocks = getStageBlocks(stage._id as string).filter((block) =>
				checkUserAccessLevel(block?.users as TUserAndRole[], currentUser?._id as string, [
					'EDITOR',
					'OWNER'
				])
			);
			if (
				checkIfUserHasStageLevelAccess(stage) &&
				(blocks.length > 0 || stage.isPhaseCreated)
			) {
				return (
					<div className={`${activeTab === 'PHASE' ? 'phase-hover' : ''}`}>
						<div>{getItems('MAIN', blocks[0] as IBlock, stage, 1)}</div>
						{renderBlocks(blocks, stage)}
					</div>
				);
			}
			return <div>{renderBlocks(blocks, stage)}</div>;
		});

	/**
	 * Function to access title for the dropdown based on active tab
	 */
	const getDropdownTitle = () => {
		switch (activeTab) {
			case 'PHASE': {
				if (actionablePhase.name !== 'UNDEFINED') return actionablePhase.name;

				const project = store.getState().projects.data[projectId] as IProject;
				const index = project.children?.findIndex(
					(grp) => (grp as string) === (actionablePhase._id as string)
				);

				return getCleanName(
					actionablePhase.name,
					index !== undefined && index !== -1 ? index : 0
				);
			}
			default: {
				if (actionableBlock.name !== 'UNDEFINED') return actionableBlock.name;

				const groupId = actionableBlock.parentId;
				const group = store.getState().stages.data[groupId] as IGroup;
				const index = group.children?.findIndex(
					(grp) => (grp as string) === (actionableBlock._id as string)
				);

				return getCleanName(
					actionableBlock.name,
					index !== undefined && index !== -1 ? index : 0
				);
			}
		}
	};

	return (
		<div className="milestone-dropdown tw-truncate" style={{ maxWidth: 375 }}>
			<DropDown>
				<DropDown.DropDownTrigger>
					<div className="sharing-milestone-dropdown tw-rounded tw-flex tw-items-center tw-mr-1">
						<span className="tw-mr-2 tw-text-lg tw-font-randBold tw-truncate">
							{getDropdownTitle()}
						</span>
						<img src={dropdownIcon} alt="dropdown" />
					</div>
				</DropDown.DropDownTrigger>
				<DropDown.DropDownContent classNames="tw-p-2 dropdown-content width-180 tw-rounded-2xl tw-bg-white tw-right-0">
					<div style={{ overflowY: 'auto', maxHeight: 180 }}>{renderMilestoneMenu()}</div>
				</DropDown.DropDownContent>
			</DropDown>
		</div>
	);
};

export default MilestoneDropdown;
