import { FC, ReactElement } from 'react';
import { ReactComponent as AlertIcon } from 'src/assets/icons/sharing-modal/alert.svg';
import { ShareInfoProps } from './Info.types';
import './Info.scss';

/**
 * Component for Info in Sharing Modal
 */
const Info: FC<ShareInfoProps> = ({
	activeTab,
	checkIfCurrentUserHasEditorAccess
}): ReactElement => {
	/**
	 * Returns alert data based upon the active tab
	 * @returns alert text
	 */
	const getAlertData = () => {
		if (checkIfCurrentUserHasEditorAccess()) {
			switch (activeTab) {
				case 'PHASE':
					return 'Collaborators added here will have access to all content within the group.';
				case 'PROJECT':
					return 'Collaborators will have access to all groups and blocks in the project.';
				case 'BLOCK':
					return 'Collaborators will only have access to the selected block.';
				default:
					break;
			}
		} else {
			switch (activeTab) {
				case 'PHASE':
					return 'Collaborators have access to the project?. Only editors can share with others.';
				case 'PROJECT':
					return 'Collaborators have access to the selected page. Only editors can share with others.';
				case 'BLOCK':
					return 'Collaborators have access to the selected milestone. Only editors can share with others.';
				default:
					break;
			}
		}
		return '';
	};

	return (
		<div className="alert-data">
			<AlertIcon />
			<p>{getAlertData()}</p>
		</div>
	);
};

export default Info;
