import { FC, ReactElement } from 'react';
import { ShareDetailsProps } from './Details.types';
import './Details.scss';

/**
 * Component to render the sharing details
 */
const Details: FC<ShareDetailsProps> = ({ activeTab, title, children, isEmpty }): ReactElement => (
	<div
		className="sharing-detail-wrapper tw- tw-flex tw-items-center tw-truncate"
		data-testid={`share-modal-${activeTab.toLowerCase()}-name`}
	>
		{!isEmpty ? (
			<>
				<span className="tw-text-lg tw-font-randBold tw-py-05">Share </span>
				<span className="tw-text-lg tw-font-randBold tw-mx-0.5 first-letter-capital tw-py-05 tw-mx-1">
					{activeTab === 'PHASE' ? 'GROUP' : activeTab} -
				</span>
				{title && (
					<span className="tw-text-lg tw-font-randBold tw-truncate flex-1">{title}</span>
				)}
				{children}
			</>
		) : (
			<span className="tw-text-lg tw-font-randBold tw-py-05">Request file upload </span>
		)}
	</div>
);

export default Details;
