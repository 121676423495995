import { getGuestAccessDataEndpoint, sessionEndpoint } from 'src/endpoints/authentication-api';
import { store } from 'src';
import axios from 'axios';
import { EUserType, IUser } from '@naya_studio/types';
import { generateIdsFromUrl } from 'src/redux/reduxActions/util';
import { userRouter } from 'src/endpoints/accounts-api';
import { AuthStatus } from 'src/components/auth/AuthProvider';
import { setUser } from 'src/redux/features/user';
import checkIfUserFormDetailsAreFilled, {
	setUserFormValueToLocalStorage
} from 'src/components/authComponent/views/authComponentUtils';
import { setAIUser } from 'src/redux/features/aiUser';
import {
	loadAIUserFromLocalForage,
	loadUserFromLocalForage,
	saveUserInLocalForage
} from '../storage/indexedDBStorage';
import { getGatewayKey } from '../helper/queryString';

const qs = `?${getGatewayKey()}`;

/** *
 * function to check if its an auth redirect
 */
export const checkIfAuthRedirect = async () => {
	if (window.location.href.includes('response=')) {
		return true;
	}
	return false;
};

export const checkIfUserExistsInDatabase = async (email: string, userId?: string) => {
	const checkUserRes = await axios.get(`${userRouter}/get-user${qs}`, {
		params: { email, userId },
		withCredentials: true
	});
	if (checkUserRes.status === 200 && checkUserRes.data.status) {
		return checkUserRes.data.user;
	}
	return false;
};

// function to check if user is still authenticated
// checks if the session cookies still exist/not expired
export const checkIfUserIsStillAuthenticated = async (
	next?: (status: AuthStatus, hasGuestAccessTo?: 'BLOCK' | 'PROJECT' | false) => void,
	checkGuestAccess?: boolean
) => {
	let hasGuestAccessTo: 'BLOCK' | 'PROJECT' | false = false;
	try {
		let email: string | null = null;
		let getGuestAccessDataApi = getGuestAccessDataEndpoint + qs;

		const { projectId, blockId } = generateIdsFromUrl();

		if (checkGuestAccess) {
			if (blockId) getGuestAccessDataApi += `&blockId=${blockId}`;
			if (projectId) getGuestAccessDataApi += `&projectId=${projectId}`;
		}

		const guestAccessDataRes = await axios.get(getGuestAccessDataApi, {
			withCredentials: true
		});

		if (guestAccessDataRes) {
			const { hasGuestAccessTo: guestAccessTo, email: guestUserEmail } =
				guestAccessDataRes.data;
			if (guestUserEmail) email = guestUserEmail;
			hasGuestAccessTo = guestAccessTo;
		}
		if (next) {
			next('LOADING', hasGuestAccessTo);
		}

		// 1. Normal login flow will have hasGuestAccessTo: false and and email: null
		// 2. Normal login flow with project url(project having guest access)
		// 	 will have hasGuestAccessTo: true and and email: null
		// 3. Guest access flow will have hasGuestAccessTo: true and and email: null
		if (!hasGuestAccessTo || !email) {
			const url = sessionEndpoint + qs;
			const res1 = await axios.get(url, { withCredentials: true });
			email = res1.data?.email;
		}
		if (email) {
			const userLoadedFromLocal = await loadUserFromLocalForage(email);
			await loadAIUserFromLocalForage();
			if (userLoadedFromLocal) {
				if (next) {
					next('AUTHENTICATED', hasGuestAccessTo);
				}
			}
			const getUserApi = `${userRouter}/get-user-by-email/${email}${qs}`;
			const getUserAPIRes = await axios.get<{ user?: IUser; aiUser?: IUser }>(getUserApi, {
				withCredentials: true
			});

			const { user, aiUser } = getUserAPIRes.data;
			if (aiUser) {
				store.dispatch(setAIUser(aiUser));
			}
			if (user) {
				if (user.userType?.includes('GUEST') || user.isAdminApproved)
					store.dispatch(setUser(user));
				if (next) {
					if (user?.userType?.includes(EUserType.GUEST)) {
						next('GUEST', hasGuestAccessTo);
					} else if (!user.isAdminApproved) {
						if (user._id && !checkIfUserFormDetailsAreFilled(user)) {
							setUserFormValueToLocalStorage(
								'APPROVAL_PENDING',
								user?._id as string,
								'1'
							);
							next('UNAPPROVED', hasGuestAccessTo);
						} else {
							next('UNAPPROVED', hasGuestAccessTo);
						}
					} else {
						next('AUTHENTICATED', hasGuestAccessTo);
					}
				}
			} else if (next) {
				next('UNAPPROVED', hasGuestAccessTo);
			}
			if (user) await saveUserInLocalForage(user);
			if (aiUser) await saveUserInLocalForage(aiUser);
			return true;
		}

		if (next) {
			if (hasGuestAccessTo) next('GUEST', hasGuestAccessTo);
			else next('SIGNED_OUT');
		}
		return false;
	} catch (e: unknown) {
		console.error(e);
		if (next) {
			if (hasGuestAccessTo) next('GUEST', hasGuestAccessTo);
			else next('SIGNED_OUT');
		}
		return false;
	}
};
