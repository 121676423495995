import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	TAddNotificationThunkArg,
	TAddStageFulfill,
	TAddStageThunkArg,
	TAddUserToStageFulfill,
	TAddUserToStageThunkArg,
	TCreateNewStageFromBlockThunkArg,
	TBatchCreateGroupAndBlocks,
	TCreateStageWithBlocksFulfill,
	TDeleteGroupThunkArg,
	TDuplicateStageThunkArg,
	TEditStageFulfill,
	TEditStageThunkArg,
	TEditUserRoleForStageFulfill,
	TEditUserRoleForStageThunkArg,
	TInviteToStageNotifArgs,
	TLoadStageThunkArg,
	TRemoveUserFromStageFulfill,
	TRemoveUserFromStageThunkArg,
	TReorderBlocksThunkArg,
	TUndoRedoStageArgs,
	TBulkUpdateGroupArgs
} from 'src/types/argTypes';
import {
	EEvent,
	EUserRole,
	IBlock,
	INotification,
	INotificationPayload,
	IProject,
	IProjectDetail,
	IGroup,
	IUser,
	TInvitedEmail,
	TNotificationData,
	TUserAndRole,
	IFile,
	IFileSubtype,
	ILink,
	ILinkSubtype
} from '@naya_studio/types';
import { stageRouter } from 'src/endpoints/projects-api';
import { getGatewayKey } from 'src/util/helper/queryString';
import axios from 'axios';
import {
	AddStagePayload,
	AddUserToStagePayload,
	EditStagePayload,
	EditUserRoleForStagePayload,
	RemoveUserFromStagePayload,
	ReorderBlocksPayload,
	TCreateStageByReorderingBlockPayload
} from 'src/types/payloadTypes';
import { DUMMY_IMG } from 'src/util/helper/constants';
import trackEvent from 'src/util/analytics/analytics';
import { BlockEvents, CustomEvents, GroupEvents } from 'src/util/analytics/events';
import getUserFromRedux from 'src/util/helper/user';
import { isProjectUrl } from 'src/util/collaboration/util';
import { TAddBlockEventData, TDuplicateElementEventData } from 'src/util/analytics/analytic.types';
import { getFileLinkSubType } from 'src/components/utilities/navbar/utils';
import { fetchBlockByIdFromRedux, getHostName } from '../actions/util';
import { AxiosResponseWrapper } from '../actions/types';
import { ReduxState } from '../reducers/root.types';
import { addNotification } from './project';
import { TRtkResponse } from './util';

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
const qs = `?${getGatewayKey()}`;

/**
 * Function to load a stage to redux
 */
export const loadStage = createAsyncThunk(
	'stages/loadStage',
	async ({ payload, next }: TLoadStageThunkArg, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();
			const { _id: userId, email } = user;
			const { _id: stageId } = payload;
			const response = await axios.get<AxiosResponseWrapper<IGroup>>(
				`${stageRouter}/${stageId}/${userId as string}/${email as string}/${qs}`
			);
			if (response.status !== 200) throw new Error('Error fetching staging');

			const stage = response.data.payload as IGroup;

			if (next && typeof next === 'function') next();

			return {
				stage
			};
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * Function to add new stage
 */
export const addStage = createAsyncThunk(
	'stages/addStage',
	async ({ payload, next }: TAddStageThunkArg, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();
			const { stage, newPhaseIndex, target } = payload;
			const userId = user._id as string;

			const apiPayload: AddStagePayload = {
				userId,
				stage,
				newPhaseIndex,
				lastUpdatedInfo: {
					lastUpdatedBy: user._id as string
				},
				target
			};

			// API call to add stage
			const response = await axios.post<AxiosResponseWrapper<string>>(
				`${stageRouter}/${qs}`,
				{
					payload: {
						...apiPayload
					}
				}
			);

			if (response.status !== 200) throw new Error('Error creating a new stage');

			// Track creating group event
			trackEvent(GroupEvents.ADD_GROUP);

			if (next && typeof next === 'function') {
				next(stage._id as string);
			}

			const fulfillValue: TAddStageFulfill = {
				target,
				stage
			};

			return fulfillValue;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * Function to edit a stage
 */
export const editStage = createAsyncThunk(
	'stages/editStage',
	async ({ payload, next }: TEditStageThunkArg, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();
			const { id, update, projectId } = payload;

			const apiPayload: EditStagePayload = {
				stageId: id,
				user: {
					id: user._id?.toString() as string,
					email: user.email
				},
				update,
				isAdmin: user.userType?.includes('ADMIN'),
				lastUpdatedInfo: {
					lastUpdatedBy: user._id as string
				}
			};

			const response = await axios.put<AxiosResponseWrapper<IGroup>>(`${stageRouter}/${qs}`, {
				payload: apiPayload
			});
			if (response.status !== 200) throw new Error(`Error editing stage at ${id}`);

			if (next && typeof next === 'function') next();

			const fulfillValue: TEditStageFulfill = {
				projectId,
				stage: response.data.payload
			};

			return fulfillValue;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * Function to duplicate a stage
 */
export const duplicateStage = createAsyncThunk(
	'stages/duplicateStage',
	async ({ payload }: TDuplicateStageThunkArg, thunkApi) => {
		try {
			const { originalGroupId, clonedItems, clonedGroupId, insertIndex, parent } = payload;
			const user: IUser = getUserFromRedux();

			const apiPayload = {
				clonedGroupId,
				clonedItems,
				insertIndex,
				parentData: {
					id: parent.id,
					type: parent.type === 'JOURNEY' ? 'JOURNEY' : 'GROUP'
				},
				lastUpdatedInfo: {
					lastUpdatedBy: user._id as string
				}
			};

			const response = await axios.put<{ position: number }>(
				`${stageRouter}/duplicate/${qs}`,
				{
					payload: apiPayload
				}
			);

			if (response.status !== 200) throw new Error('Error duplicating group');

			// Track duplicating group event
			const eventProps: TDuplicateElementEventData = {
				elementId: originalGroupId,
				elementType: 'GROUP'
			};
			trackEvent(CustomEvents.DUPLICATE_ELEMENT, eventProps);

			return { position: response.data.position };
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * Function to delete a stage
 */
export const deleteStage = createAsyncThunk(
	'stages/deleteStage',
	async ({ payload }: TDeleteGroupThunkArg, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();

			const response = await axios.delete<AxiosResponseWrapper<any>>(`${stageRouter}/${qs}`, {
				data: {
					payload: {
						...payload,
						lastUpdatedInfo: {
							lastUpdatedBy: user._id as string
						}
					}
				}
			});
			if (response.status !== 200) throw new Error('Error sending invite');

			return payload;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * FUnction to send notification on stage invute
 */
export const sendInviteToStageNotif = createAsyncThunk(
	'stages/inviteUserToStage',
	async ({ payload }: TInviteToStageNotifArgs, thunkApi) => {
		try {
			const { stageId, email, inviteNote, projectId, requestType } = payload;
			const user: IUser = getUserFromRedux();
			const { stages, projects } = thunkApi.getState() as ReduxState;
			const project = projects.data[projectId] as IProject;
			const thumbnailUrl = isProjectUrl(project?.thumbnail?.src || '')
				? project?.thumbnail?.src
				: '';

			const invitedStage = stages.data[stageId] as IGroup;
			const firstBlock = fetchBlockByIdFromRedux(
				(invitedStage.children as string[])[0] as string
			) as IBlock;
			const linkUrl = `${getHostName()}/project/${project._id}`;

			const notifPayload: INotificationPayload = {
				eventType: requestType || ('STAGE_INVITE' as EEvent),
				data: {
					projectId: project._id,
					uniqueIdentifier: invitedStage._id,
					user: email.split('@')[0], // holds the username
					link: firstBlock ? `${linkUrl}/${stageId}/${firstBlock._id}` : linkUrl,
					projectName: (project.projectDetails as IProjectDetail).name,
					inviterName: user.firstName || user.userName,
					inviterEmail: user.email,
					inviteNote,
					profilePic: user.profilePic || DUMMY_IMG,
					userName: user.firstName || user.userName,
					fromUserId: user._id,
					stageName: invitedStage.name,
					thumbnailUrl
				} as TNotificationData,
				toUser: email.toLowerCase()
			};

			if (inviteNote !== '') {
				((notifPayload as INotificationPayload)!.data as TNotificationData).inviteNote =
					inviteNote;
			}

			const apiPayload: TAddNotificationThunkArg = {
				payload: {
					notification: notifPayload,
					userId: user._id as string
				}
			};
			const notifResponse = (await thunkApi.dispatch(
				addNotification(apiPayload)
			)) as TRtkResponse;
			return notifResponse.payload.data.payload;
		} catch (error) {
			console.error('Notification Error:', error);
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * Function to add a user to stage
 */
export const addUserToStage = createAsyncThunk(
	'stages/addUserToStage',
	async ({ payload, next, blockIds, requestType }: TAddUserToStageThunkArg, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();
			const {
				projectId,
				stageIds,
				email,
				role,
				inviteNote,
				override,
				isInvitedUser,
				userId
			} = payload;

			const apiPayload: AddUserToStagePayload = {
				projectId,
				stageIds,
				blockIds,
				userId: isInvitedUser ? email : (userId as string),
				role,
				override,
				isInvitedUser,
				lastUpdatedInfo: {
					lastUpdatedBy: user._id as string,
					projectId
				}
			};

			const response = await axios.post<
				AxiosResponseWrapper<{
					user: IUser | string;
				}>
			>(`${stageRouter}/invite-user/${qs}`, {
				payload: apiPayload
			});
			if (response.status !== 200) throw new Error('Error adding user to stage');

			// send notification
			const notifPayload: TInviteToStageNotifArgs = {
				payload: {
					email,
					role,
					stageId: stageIds[0] as string,
					inviteNote,
					projectId,
					requestType
				}
			};
			const notifResponse = await thunkApi
				.dispatch(sendInviteToStageNotif(notifPayload))
				.unwrap();

			if (next && typeof next === 'function') next();

			const fulfillValue: TAddUserToStageFulfill = {
				user: response.data.payload?.user as IUser | string,
				isInvitedUser,
				projectId,
				stageIds,
				blockIds,
				role: role as EUserRole,
				override,
				notification: notifResponse as INotification
			};

			return fulfillValue;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * Function to remove a user from stage
 */
export const removeUserFromStage = createAsyncThunk(
	'stages/removeUserFromStage', // project -- ref to slice name
	async ({ payload, next, blockIds }: TRemoveUserFromStageThunkArg, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();
			const { stageIds, invitedUserRole, userRole, projectId, existOnOtherLevels } = payload;
			const isInvitedUser = !!invitedUserRole;

			if (!userRole && !invitedUserRole)
				throw new Error('Can not find user in stage user list, please contact an admin');

			if (!isInvitedUser && userRole?.role === 'OWNER')
				throw new Error('Cannot remove owner');

			const apiPayload: RemoveUserFromStagePayload = {
				...payload,
				blockIds,
				isInvitedUser,
				lastUpdatedInfo: {
					lastUpdatedBy: user._id as string,
					projectId
				}
			};

			const response = await axios.put<
				AxiosResponseWrapper<{
					users: TUserAndRole[] | TInvitedEmail[];
				}>
			>(`${stageRouter}/remove-user/${qs}`, {
				payload: apiPayload
			});

			if (response.status !== 200) throw new Error('Error removing user from stage');

			if (next && typeof next === 'function') next();

			const fulfillValue: TRemoveUserFromStageFulfill = {
				stageIds,
				user: payload.userId,
				isInvitedUser,
				blockIds,
				existOnOtherLevels
			};

			return fulfillValue;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * Function to edit a user role in stage
 */
export const editUserRoleForStage = createAsyncThunk(
	'stages/editUserRoleForStage', // project -- ref to slice name
	async ({ payload, next, blockIds }: TEditUserRoleForStageThunkArg, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();
			const { stageIds, invitedUser } = payload;

			const isInvitedUser = !!invitedUser;
			const apiPayload: EditUserRoleForStagePayload = {
				...payload,
				isInvitedUser,
				blockIds,
				lastUpdatedInfo: {
					lastUpdatedBy: user._id as string
				}
			};

			const response = await axios.put<
				AxiosResponseWrapper<{
					users: TUserAndRole[] | TInvitedEmail[];
					isInvitedUser: boolean;
				}>
			>(`${stageRouter}/edit-user/${qs}`, {
				payload: apiPayload
			});

			if (response.status !== 200) throw new Error('Error sending invite');

			if (next && typeof next === 'function') next();

			const fulfillValue: TEditUserRoleForStageFulfill = {
				...payload,
				stageIds,
				blockIds
			};

			return fulfillValue;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * Function to reorder blocks
 */
export const reorderBlocks = createAsyncThunk(
	'stages/reorderBlocks',
	async ({ payload }: TReorderBlocksThunkArg, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();
			const {
				sourcePhaseId,
				destinationPhaseId,
				sourceBlocks,
				destinationBlocks,
				isPhaseCreated,
				projectId,
				reorderedBlock,
				type
			} = payload;

			const apiPayload: ReorderBlocksPayload = {
				sourceGroup: {
					id: sourcePhaseId,
					children: sourceBlocks,
					isPhaseCreated
				},
				destinationGroup: {
					id: destinationPhaseId,
					children: destinationBlocks
				},
				projectId,
				reorderedChild: {
					id: reorderedBlock.blockId,
					type,
					newParentId: reorderedBlock.newStageId,
					index: reorderedBlock.index
				},
				lastUpdatedInfo: {
					lastUpdatedBy: user._id as string
				}
			};

			await axios.put(`${stageRouter}/reorder-blocks/${qs}`, {
				payload: apiPayload
			});

			return payload;
		} catch (error: any) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue(error);
		}
	}
);

/**
 * Function to create a new stage from block
 */
export const createNewStageFromOrphanBlock = createAsyncThunk(
	'stages/createNewStageFromOrphanBlock',
	async ({ payload, next }: TCreateNewStageFromBlockThunkArg, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();
			const {
				sourcePhaseId,
				stageData,
				projectId,
				blockIds,
				newPhaseIndex,
				orphanGroupIds,
				parent
			} = payload;

			const apiPayload: TCreateStageByReorderingBlockPayload = {
				projectId,
				sourcePhase: sourcePhaseId,
				movedBlocks: blockIds,
				newPhaseIndex,
				destinationPhase: stageData,
				orphanGroupIds,
				lastUpdatedInfo: {
					lastUpdatedBy: user._id as string
				},
				parent
			};

			// API call to add stage
			const response = await axios.put<AxiosResponseWrapper<IGroup>>(
				`${stageRouter}/create-stage-for-orphan-block/${qs}`,
				{
					payload: apiPayload
				}
			);

			if (response.status !== 200) return new Error('Error creating a new stage');

			if (next && typeof next === 'function') next();

			return true;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * API action to undo redo a block
 */
export const undoRedoStage = createAsyncThunk(
	'stage/undoRedoStage',
	async (payload: TUndoRedoStageArgs, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();
			const { stage } = payload.data;
			const stageId = stage._id as string;

			const apiPayload: EditStagePayload = {
				stageId,
				user: {
					id: user._id?.toString() as string,
					email: user.email
				},
				update: stage,
				isAdmin: user.userType?.includes('ADMIN'),
				lastUpdatedInfo: {
					lastUpdatedBy: user._id as string
				}
			};

			const response = await axios.put<AxiosResponseWrapper<IBlock>>(`${stageRouter}/${qs}`, {
				payload: apiPayload
			});

			if (response.status !== 200) {
				return thunkApi.rejectWithValue(`Error editing canvas at ${stageId}`);
			}

			const blockData = response.data.payload as IBlock;
			return blockData;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * API action to create empty blocks and add them to a new phase
 */
export const batchCreateGroupAndBlocks = createAsyncThunk(
	'stages/batchCreateGroupAndBlocks',
	async (
		{ parent, groups, blocks, startGroupIndex, children }: TBatchCreateGroupAndBlocks,
		thunkApi
	) => {
		try {
			const user: IUser = getUserFromRedux();

			const apiPayload = {
				parent,
				groups,
				blocks,
				children,
				startGroupIndex,
				lastUpdatedInfo: {
					lastUpdatedBy: user._id as string
				}
			};

			const response = await axios.put<{
				project: IProject;
				groups: IGroup[];
				blocks: IBlock[];
				children: string[];
			}>(`${stageRouter}/with-blocks/${qs}`, {
				payload: apiPayload
			});

			const newGroups = response.data.groups;
			const newBlocks = response.data.blocks;
			const updatedChildren = response.data.children;

			if (response.status !== 200) throw new Error('Error creating phase and blocks');

			// Track creating blocks and groups event
			for (let i = 0; i < groups.length; i++) trackEvent(GroupEvents.ADD_GROUP);
			for (let i = 0; i < blocks.length; i++) {
				const eventProps: TAddBlockEventData = {
					blockType: blocks[i]!.blockType
				};
				if (blocks[i]!.blockType === 'FILE')
					eventProps.fileType = getFileLinkSubType(
						(blocks[i] as IFile).fileName,
						'FILE'
					) as keyof typeof IFileSubtype;
				if (blocks[i]!.blockType === 'LINK')
					eventProps.linkType = getFileLinkSubType(
						(blocks[i] as ILink).link,
						'LINK'
					) as keyof typeof ILinkSubtype;
				trackEvent(BlockEvents.ADD_BLOCK, eventProps);
			}

			const fulfillValue: TCreateStageWithBlocksFulfill = {
				groups: newGroups,
				blocks: newBlocks,
				children: updatedChildren,
				parent
			};
			return fulfillValue;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * API action to bulk update groups
 */
export const bulkUpdateGroups = createAsyncThunk(
	'groups/bulk-edit',
	async (payload: TBulkUpdateGroupArgs, thunkApi) => {
		try {
			const apiPayload = {
				payload: payload.data
			};
			await axios.put(`${stageRouter}/bulk-edit${qs}`, apiPayload);
			return payload;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred in bulkUpdateGroups');
		}
	}
);
