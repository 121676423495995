import * as PIXI from 'pixi.js';
// import { InteractionEvent } from "@pixi/interaction";
import { INode, TextDecorationValue, TText } from '@naya_studio/types';
import _ from 'lodash';
import TaggedText from '@naya_studio/pixi-tagged-text';
import { TextStyleSet } from '@naya_studio/pixi-tagged-text/dist/types';
import urlRegexSafe from 'url-regex-safe';
import TextInputNode, { DOMStyleExtended } from './TextInputNode';
import BaseNode from './BaseNode';
import { removeAttributesAtRanges, updateTags } from './utils/helper';
import { alignTypes } from '../../editMenu/MenuOptions/Align/constants';

class Text extends BaseNode {
  static nodeData = {
    absoluteBounds: {
      x: window.innerWidth / 2,
      y: window.innerHeight / 2,
      width: 200,
      height: 200,
    },
    scale: { scaleX: 1, scaleY: 1 },
    text: 'Hello World',
  };

  _textInputNode: TextInputNode = this.app.textInputNode;

  _textNode: TaggedText | undefined;

  /**
     * Change the text in the sticky note
     * @param text Text to be set
     */
  setText = async (text: string) => {
    this.app.isColourPickerOpen = false;
    const nodeData = { ...this.nodeData };
    // eslint-disable-next-line no-control-regex
    const textParsed = text; // .replace(/[^\x00-\xFF]/g, '*');
    const newText = { value: textParsed, style: { ...this.nodeData?.text?.style } };
    nodeData.text = newText;
    this.nodeData = nodeData;
    // this.selectNode();
    // this.app.toggleEditMenu(false);
    // this.app.toggleEditMenu(true);
    this.app.keyboardLock = true;
    // await this.draw()
    // this._textInputNode?.draw()
  };

  /// ////////////////////////////////
  // TEXT EVENT PROCESSORS ///
  /// ////////////////////////////////

  /**
     * Function to start the edit mode
     */
  onEditStart = () => {
    this.app.isColourPickerOpen = false;
    if (this._editMode) { return; }
    this.app.disableHighlighting();
    this.app.toggleEditMenu(false);
    this.app.toggleEditMenu(true);
    this.app.keyboardLock = true;
    this.onMouseOut();
    // this.onMouseOver()

    this.app.removeTransformer();
    this.app.editText = true;
    this.app.disablePaste = true;
    this._editMode = true;
    this.displayObject!.visible = false;
    this._textInputNode.setParentNode(this);
    this.app.viewport.on('mousedown', this.onEditEnd);
    this.app.viewport.on('node-selected', (nodesSelected: BaseNode[]) => {
      if (nodesSelected.filter((node) => node.nodeData._id === this.nodeData._id).length === 0) {
        this.onEditEnd(new Event('click'));
      }
    });
    this._textInputNode.on('keyup', this.setText);
    this.prevNodeData = JSON.parse(JSON.stringify(this.nodeData));
    this._textInputNode.draw();
    // this._textInputNode.selectAllText();
  };

  /**
     * Function to end the edit mode
     * @param _ Ignored mouse event
     */
  onEditEnd = (e: Event) => {
    if (!this._editMode) { return; }
    this.app.enableHighlighting();
    this.setText(this._textInputNode!.getDOMInputText());
    // this.app.setSelected(this)
    this.app.keyboardLock = false;
    this.app.resumeDragPlugin();
    this.onMouseOut();
    this.app.viewport.emit('mouseup', e);
    this.app.onTextSelectionChange([]);
    this.app.editText = false;
    this.app.disablePaste = false;
    this.app.showLinkContainer = false;
    this._editMode = false;
    this.displayObject!.visible = true;
    this.app.textLinkTooltip.url = '';
    this.app.textLinkTooltip.index = undefined;

    this.app.viewport.off('mousedown', this.onEditEnd);
    this.app.viewport.off('node-selected');

    this._textInputNode.off('keyup', this.setText);
    this._textInputNode?.removeDOMElement();
    this.app.setSelected(this);
    if (this.nodeData.text
      && (!this.nodeData.text.value || this.nodeData.text.value.length === 0)) {
      this.app.deleteNodes();
    } else {
      this.draw();
      this.save();
      this.app.removeTransformer();
      this.app.addSelectedNodesToTransformer();
    }
  };

  edit = (type: string, data: any) => {
    this.app.removeTransformer();

    this.prevNodeData = _.cloneDeep(this.nodeData);
    const currentText = this.nodeData.text?.value;
    const domStyle: DOMStyleExtended = {};
    const trixNode = this.app.textInputNode._domInput;
    // @ts-ignore
    const trixEditor = trixNode?.editor;
    const [start, end] = trixEditor.getSelectedRange();
    const isEntireTextSelected = !!(this._editMode && (start === 0) && (end === trixEditor.getDocument().getLength() - 1));
    // const trixDoc = trixEditor?.getDocument();
    switch (type) {
      case 'ZINDEX':
        this.nodeData.zIndex = data;
        break;
      case 'FILL':
        // if (this.nodeData.text?.style) this.nodeData.text.style.fill = data;
        // domStyle.color = data;
        if (!this._editMode && currentText) {
          // this.nodeData.text!.value = currentText.replace(/color: rgb\([0-9]{1,}, [0-9]{1,}, [0-9]{1,}\);/gm, '');
          removeAttributesAtRanges(trixNode, this.nodeData, [{ isBlock: false, value: 'foreColor' }]);
          if (this.nodeData.text?.style) {
            this.nodeData.text.style.fill = data;
          }
        } else {
          // document.execCommand("foreColor", false, data);
          trixEditor.activateAttribute('foreColor', data);
          this.app.onTextSelectionChange([{ foreColor: data }]);
          if (isEntireTextSelected && this.nodeData.text?.style) {
            this.nodeData.text.style.fill = data;
          }
          // if (trixDoc) {
          //   const selectedRange = trixEditor.getSelectedRange();
          //   const selectedText = trixDoc.getStringAtRange(selectedRange);
          //   console.log('editmode fill', selectedText, selectedRange);
          //   trixEditor.insertHTML(`<span style="color: ${data};">${selectedText}</span>`);
          //   this._textInputNode?.onTextStyleChange();
          // }
          // this._textInputNode?.onTextStyleChange();
        }
        break;
      case 'LIST':
        if (!this._editMode && currentText) {
          removeAttributesAtRanges(
            trixNode,
            this.nodeData,
            ['bullet', 'number']
              .map((a) => ({ isBlock: true, value: a })) as [{ isBlock: boolean, value: string }],
          );
          if (this.nodeData.text?.style) this.nodeData.text.style.listType = data;
          domStyle.listType = data;
        } else {
          // @ts-ignore
          const currentAttributes = trixNode?.editorController.composition.currentAttributes;
          let currentListStyle;
          if (currentAttributes.bullet) currentListStyle = 'bullet';
          else if (currentAttributes.number) currentListStyle = 'number';
          if (!currentListStyle) {
            const topListType = this.nodeData.text?.style?.listType;
            if (!topListType || topListType === 'none') trixEditor.activateAttribute('bullet');
            else if (topListType === 'unordered') trixEditor.activateAttribute('number');
            else {
              trixEditor.deactivateAttribute('bullet');
              trixEditor.deactivateAttribute('number');
            }
          } else if (currentListStyle === 'bullet') {
            trixEditor.activateAttribute('number');
          } else if (currentListStyle === 'number') {
            trixEditor.deactivateAttribute('number');
          }
        }
        break;
      case 'ALIGN':
        if (!this._editMode && currentText) {
          removeAttributesAtRanges(
            trixNode,
            this.nodeData,
            alignTypes.map((at) => ({ isBlock: true, value: `${at}Align` })) as [{ isBlock: boolean, value: string }],
          );
          // trixNode.innerHTML = this._textInputNode.getUpdatedText(this.nodeData.text?.value)
          // alignTypes.forEach(a => {
          //   const trixAlignAttribute = `${a}Align`;
          //   const existingBlocks = trixDoc?.findRangesForBlockAttribute(trixAlignAttribute);
          //   if (existingBlocks && existingBlocks.length > 0) {
          //     removeAttributeAtRanges(trixEditor, existingBlocks, trixAlignAttribute);
          //   }
          // });
          // this.nodeData.text!.value = trixNode.innerHTML;
          if (this.nodeData.text?.style) this.nodeData.text.style.align = data;
          domStyle.textAlign = data;
        } else {
          // @ts-ignore
          const currentAttributes = trixNode?.editorController.composition.currentAttributes;
          let currentAlign;
          if (currentAttributes.rightAlign) currentAlign = 'right';
          else if (currentAttributes.leftAlign) currentAlign = 'left';
          else if (currentAttributes.centerAlign) currentAlign = 'center';
          if (!currentAlign) {
            trixEditor.activateAttribute(`${data}Align`);
          } else if (currentAlign === 'left') {
            trixEditor.activateAttribute('centerAlign');
            trixEditor.deactivateAttribute('leftAlign');
          } else if (currentAlign === 'right') {
            trixEditor.activateAttribute('leftAlign');
            trixEditor.deactivateAttribute('rightAlign');
          } else if (currentAlign === 'center') {
            trixEditor.activateAttribute('rightAlign');
            trixEditor.deactivateAttribute('centerAlign');
          }
          this._textInputNode?.onTextStyleChange();
        }
        break;
      case 'FONT_SIZE':
        if (!this._editMode && currentText) {
          // this.nodeData.text!.value = currentText.replace(/style="font-size:\s[0-9.]+px;"/gm, '');
          removeAttributesAtRanges(trixNode, this.nodeData, [{ isBlock: false, value: 'fontSize' }]);
          if (this.nodeData.text?.style) this.nodeData.text.style.fontSize = data;
          domStyle.fontSize = `${data}px`;
        } else {
          const zoomLevel = this.app.viewport.scale._x;
          let updatedFontSize = data;
          if (zoomLevel) updatedFontSize *= zoomLevel;
          // @ts-ignore
          // if (selectionText && selectionText?.toString() === selectionText.anchorNode.parentNode.innerText) {
          //   console.log("setFontSizeForAllNodes selectionText inside if");
          //   // @ts-ignore
          //   selectionText.anchorNode.parentNode.style.fontSize = `${updatedFontSize}px`;
          // } else {
          //   console.log("setFontSizeForAllNodes selectionText inside else");
          //   document.execCommand("insertHTML", false, `<span style="font-size: 
					// 	${updatedFontSize}px;">${window.getSelection()}</span>`);
          // }
          trixEditor.activateAttribute('fontSize', `${updatedFontSize}px`);
          this._textInputNode?.onTextStyleChange();
          if (isEntireTextSelected && this.nodeData.text?.style) {
            this.nodeData.text.style.fontSize = data;
          }
        }
        break;
      case 'LINK':
        if (!this._editMode && currentText) {
          removeAttributesAtRanges(
            trixNode,
            this.nodeData,
            ['foreColor', 'textDeco', 'href'].map((a) => ({ isBlock: false, value: a })) as [{ isBlock: boolean, value: string }],
          );
          // const textAttributes = ['foreColor', 'textDeco', 'href'];
          // trixNode.innerHTML = this._textInputNode.getUpdatedText(this.nodeData.text?.value)
          // textAttributes.forEach(a => {
          //   const existingFormats = trixDoc?.findRangesForTextAttribute(a);
          //   if (existingFormats && existingFormats.length > 0) {
          //     removeAttributeAtRanges(trixEditor, existingFormats, a);
          //     this.nodeData.text!.value = trixNode.innerHTML;
          //   }
          // })
          if (this.nodeData.text?.style) {
            if (data && data !== '') {
              // this.nodeData.text.style.fill = this.app.theme.color;
              // this.nodeData.text.style.textDecoration = 'underline';
              // this.nodeData.text.style.linkURL = data;
              domStyle.color = this.app.theme.color;
              domStyle.textDecoration = 'underline';
            } else {
              this.nodeData.text.style.fill = '#000000';
              this.nodeData.text.style.textDecoration = 'none';
              this.nodeData.text.style.linkURL = '';
              domStyle.color = '#000000';
              domStyle.textDecoration = 'none';
            }
            this.nodeData.text.style.linkURL = data;
            // trixNode.innerHTML = this.nodeData.text.value;
            // trixEditor.setSelectedRange([0, trixDoc.getLength()])
            // trixEditor.activateAttribute('href', data);
            // this.nodeData.text.value = `<div><a href="${data}">
						// ${removeAllAnchorTag(this.nodeData.text.value as string)}</a></div>`
          }
        } else {
          trixEditor.activateAttribute('href', data);
          this._textInputNode?.onTextStyleChange();
          if (this.nodeData.text?.style?.linkURL) {
            this.nodeData.text.style.linkURL = '';
          }
        }
        // } else if (this.nodeData.text?.style) {
        //   this.nodeData.text.style.linkURL = data;
        //   this.nodeData.text.value = `<div><a href="${data}">
				// 		${removeAllAnchorTag(this.nodeData.text.value as string)}</a></div>`
        // }
        // if (currentText) {
        //   // this.nodeData.text!.value = currentText.replace(/face="[a-zA-Z-_]+"/gm, "");
        //   // if (this.nodeData.text?.style) {
        //   //   if (data && data !== '') {
        //   //     this.nodeData.text.style.fill = this.app.theme.color;
        //   //     this.nodeData.text.style.textDecoration = 'underline';
        //   //     this.nodeData.text.style.linkURL = data;
        //   //     domStyle.color = this.app.theme.color;
        //   //     domStyle.textDecoration = 'underline';
        //   //   } else {
        //   //     this.nodeData.text.style.fill = '#000000';
        //   //     this.nodeData.text.style.textDecoration = 'none';
        //   //     this.nodeData.text.style.linkURL = '';
        //   //     domStyle.color = '#000000';
        //   //     domStyle.textDecoration = 'none';
        //   //   }
        //   // }
        // } else {
        //   trixNode?.editor.activateAttribute('href', data);
        //   trixDoc?.editor.setSelectedRange(0);
        //   // document.execCommand("fontName", false, data);
        //   this._textInputNode?.onTextStyleChange();
        // }
        break;
      case 'FONT_FORMAT':
        if (!this._editMode && currentText) {
          // const regexOpenTag = `<${data.htmlTag}(\\sstyle="font-size:\\s[0-9.]+px;\\s?")*\\s?>`;
          // const regexCloseTag = `<\\${data.htmlTag}>`;
          // currentText = currentText.replace(new RegExp(regexCloseTag), "");
          // this.nodeData.text!.value = currentText.replace(new RegExp(regexOpenTag, "gm"), "");
          removeAttributesAtRanges(trixNode, this.nodeData, [{ isBlock: false, value: data.cmd }]);
          // trixNode.innerHTML = this._textInputNode.getUpdatedText(this.nodeData.text?.value)
          // const existingFormats = trixDoc?.findRangesForTextAttribute(data.cmd);
          // console.log('FONT_FORMAT', existingFormats, data.cmd, trixNode.innerHTML);
          // if (existingFormats && existingFormats.length > 0) {
          //   removeAttributeAtRanges(trixEditor, existingFormats, data.cmd);
          //   this.nodeData.text!.value = trixNode.innerHTML;
          // }
          if (this.nodeData.text?.style) {
            const { fontStyle, fontWeight, textDecoration } = this.nodeData.text.style;
            if (data.htmlTag === 'u') {
              const domValue = (!textDecoration || textDecoration === 'none') ? 'underline' : 'none';
              domStyle.textDecoration = domValue;
              this.nodeData.text.style.textDecoration = domValue as TextDecorationValue;
            } else if (data.htmlTag === 'i') {
              const domValue = (!fontStyle || fontStyle === 'normal') ? 'italic' : 'normal';
              domStyle.fontStyle = domValue;
              this.nodeData.text.style.fontStyle = domValue as PIXI.TextStyleFontStyle;
            } else {
              const domValue = (!fontWeight || fontWeight === 'normal') ? 'bold' : 'normal';
              domStyle.fontWeight = domValue;
              this.nodeData.text.style.fontWeight = domValue as PIXI.TextStyleFontWeight;
            }
          }
        } else {
          // @ts-ignore
          const currentAttribute = trixNode?.editorController.composition.currentAttributes[data.cmd];
          if (currentAttribute && currentAttribute === data.value) {
            trixEditor.deactivateAttribute(data.cmd);
          } else {
            trixEditor.activateAttribute(data.cmd, data.value);
          }
          this._textInputNode?.onTextStyleChange();
        }
        break;
      case 'FONT':
        if (!this._editMode && currentText) {
          // this.nodeData.text!.value = currentText.replace(/font-family: [a-zA-Z-_]+/gm, '');
          removeAttributesAtRanges(
            trixNode,
            this.nodeData,
            [{ isBlock: false, value: 'fontFamily' }]
          );
          if (this.nodeData.text?.style) this.nodeData.text.style.fontFamily = data;
          domStyle.fontFamily = data;
        } else {
          // document.execCommand("fontName", false, data);
          trixEditor.activateAttribute('fontFamily', data);
          this._textInputNode?.onTextStyleChange();
          if (isEntireTextSelected && this.nodeData.text?.style) {
            this.nodeData.text.style.fontFamily = data;
          }
        }
        break;
      case 'OPACITY': {
        this.nodeData.opacity = data;
        break;
      }
      default:
        break;
    }

    if (!this._editMode) {
      this.draw();
    } else {
      // this._textInputNode?.draw();
      this._textInputNode?.updateDOMNodeStyle(domStyle);
    }
    // this.selectNode()
  };

  // onTransformChange = () => {
  //   console.log("updated bounds", this.displayObject.children[0].getBounds());
  // };

  hideDisplayObject = () => {
    if (this.displayObject) this.app.viewport.removeChild(this.displayObject);
  };

  showDisplayObject = (nodeData: INode) => {
    this.nodeData = nodeData;
    this.draw();
  };

  onDoubleClick = () => {
    if (!this._isLocked) { this.onEditStart(); }
  };

  drawText = (textData: TText, bounds: any) => {
    /** adding text value */
    const { value } = textData;
    if (!value || value.length === 0) return undefined;
    let linkURL = this.nodeData.text?.style?.linkURL;
    let finalText = `${updateTags(value)}`;
    if ((!linkURL || linkURL === '') && urlRegexSafe({ exact: true, strict: true }).test(finalText)) {
      linkURL = finalText;
      this.nodeData.text!.style!.linkURL = finalText;
    }
    if (linkURL && linkURL !== '') finalText = `<a href="${linkURL}">${finalText}</a>`;
    const {
      left, right, top, bottom,
    } = BaseNode.textNodeDefaults.padding;
    const textWidth = bounds.width - right;// - left - right;
    const textHeight = bounds.height - bottom;// - left - right;
    const defaultStyle = {
      lineHeight: 1.4,
      align: this.nodeData.text?.style?.align,
      wordWrapWidth: textWidth,
      wordWrapHeight: textHeight,
    };
    const tagStyles: TextStyleSet = {
      default: defaultStyle,
      // @ts-ignore
      topLevel: { ...Text.textNodeDefaults.style, ...this.nodeData.text?.style },
      a: { fill: this.app.theme.color, textDecoration: 'underline' },
      i: { fontStyle: 'italic' },
      b: { fontWeight: 'bold' },
      u: { textDecoration: 'underline' },
      font: {},
      ul: { listType: 'unordered' },
      ol: { listType: 'ordered' },
      li: {},
      span: {},
      em: { fontStyle: 'italic' },
      strong: { fontWeight: 'bold' },
      leftalign: { align: 'left' },
      rightalign: { align: 'right' },
      centeralign: { align: 'center' },
    };
    console.log('finalText', finalText);
    let text = new TaggedText(
      `<topLevel>${finalText}</topLevel>`,
      tagStyles,
      {
        drawWhitespace: true,
        // debug: true,
        // debugConsole: true
      },
    );
    text.x += left;
    text.y += top;
    const { textContainer } = text;
    if (textContainer.width > textWidth || textContainer.height > textHeight) {
      defaultStyle.wordWrapWidth = textContainer.width;
      this.nodeData.absoluteBounds!.width = textContainer.width + right;
      defaultStyle.wordWrapHeight = textContainer.height;
      this.nodeData.absoluteBounds!.height = textContainer.height + bottom;
      text = new TaggedText(
        `<topLevel>${finalText}</topLevel>`,
        tagStyles,
        {
          drawWhitespace: true,
        },
      );
    }
    const linkContainers = (text.textContainer as PIXI.Container).children.filter((c: any) => c.name === 'Link');
    const { linkURLs } = text;
    linkContainers.forEach((lc: PIXI.DisplayObject, index: number) => {
      lc.interactive = true;
      lc.on('mouseover', () => {
        if (this.app.textLinkTooltip.timeoutId !== -1) clearTimeout(this.app.textLinkTooltip.timeoutId);
        // if (this.app.textLinkTooltip.activeNode) return;
        const { pixiContainer } = this.app.textLinkTooltip;
        // let { url } = this.app.textLinkTooltip
        if (linkURLs && linkURLs.length > 0 && index < linkURLs.length) {
          this.app.textLinkTooltip.url = linkURLs[index] || '';
          this.app.textLinkTooltip.index = index;
        }
        const { x, y } = this.nodeData.absoluteBounds!;
        const {
          x: linkX, y: linkY, width: linkWidth, height: linkHeight,
        } = lc.getLocalBounds();
        pixiContainer.x = x! + linkX + linkWidth / 2 - pixiContainer.width / 2;
        // to use this when there are links in between text
        pixiContainer.y = y! + linkY + linkHeight + top + bottom;
        // pixiContainer.y = y! + height! + 1;
        if (!pixiContainer.parent) this.app.viewport.addChild(pixiContainer);
        pixiContainer.visible = true;
        this.app.textLinkTooltip.activeNode = this;
        this.app.textLinkTooltip.isPixiContainerHovered = true;
      });
      lc.on('mouseout', () => {
        this.app.textLinkTooltip.isPixiContainerHovered = false;
        this.app.textLinkTooltip.timeoutId = setTimeout(() => {
          if (!this.app.textLinkTooltip.isHovered) {
            this.app.textLinkTooltip.pixiContainer.visible = false;
            this.app.textLinkTooltip.activeNode = null;
            this.app.textLinkTooltip.url = '';
            this.app.textLinkTooltip.isHovered = false;
          }
        }, 3000);
      });
    });
    return text;
  };

  copyText = () => {
    let returnText = '';
    const tokens = this._textNode?.tokens;
    if (tokens) {
      tokens.forEach((token) => {
        token.forEach((t) => {
          t.forEach((tt) => {
            returnText += tt.content;
          });
        });
      });
    }
    return returnText;
  };

  /**
   * Function to apply the opacity to the sprite child
   */
  applyOpacity = () => {
    if (this?.displayObject?.children) {
      for (let i = 0; i < this.displayObject.children.length; i++) {
        const child = this.displayObject.children[i];
        if (child && child.isSprite) {
          child.alpha = this.nodeData.opacity !== undefined ? this.nodeData.opacity : 1;
          break;
        }
      }
    }
  };

  draw = async () => {
    try {
      if (this.displayObject) {
        this.app.viewport.removeChild(this.displayObject);
      }

      if (this.nodeData) {
        if (!this.nodeData.scale) this.nodeData.scale = { scaleX: 1, scaleY: 1 };
        const { absoluteBounds, scale } = this.nodeData;

        if (scale && absoluteBounds) {
          if (scale.scaleX && absoluteBounds.width) {
            absoluteBounds.width *= scale.scaleX!;
          }
          this.nodeData.scale!.scaleX = 1;
          if (scale.scaleY && absoluteBounds.height) {
            absoluteBounds.height *= scale.scaleY!;
          }
          this.nodeData.scale!.scaleY = 1;
          const textData = this.nodeData.text || Text.nodeData.text;
          const text = this.drawText(textData as TText, absoluteBounds);
          // const { top, bottom } = Text.textNodeDefaults.padding;
          // if (text && absoluteBounds) {
          //   absoluteBounds.height = text.textContainer.height + top + bottom;
          // }
          const { right } = BaseNode.textNodeDefaults.padding;

          if (text && absoluteBounds && !absoluteBounds.width) {
            absoluteBounds.width = text.textContainer.width + right; // + left + right;
          }
          const container = new PIXI.Container(); // holds both graphics and text
          const bg = new PIXI.Graphics()
            .beginFill(0xFF0000)
            .drawRect(0, 0, absoluteBounds.width || 200, absoluteBounds.height || 200)
            .endFill();
          bg.alpha = 0;

          container.addChild(bg);
          if (text) container.addChild(text);
          container.setTransform(absoluteBounds.x, absoluteBounds.y);
          container.zIndex = this.nodeData.zIndex as number || 10;
          // if (text) text.zIndex = this.nodeData.zIndex as number || 10;

          if (this._editMode) {
            container.visible = false;
            // text.visible = false
          }
          /** Prepare to add to container */
          this.displayObject = container;
          this.applyOpacity();
          this._textNode = text;
          this.displayObject.name = 'TEXT';
          this.displayObject.interactive = this.app.checkIfNodesShouldBeInteractive();
          // this.displayObject.setTransform(absoluteBounds?.x!, absoluteBounds?.y!);
          this.app.viewport.addChild(this.displayObject);
          // this.displayObject.on('click', this.onClick)
          if (this._interactive) {
            this.displayObject.on('mousedown', this.onMouseDown);
            this.displayObject.on('mouseup', this.onMouseUp);
            this.displayObject.on('mouseover', this.onMouseOver);
            this.displayObject.on('mouseout', this.onMouseOut);
            this.displayObject.on('rightclick', this.rightClick);
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  };
}

export default Text;
