import alignLeft from '../../assets/icons/align/left-align.svg';
import alignHorizontal from '../../assets/icons/align/Horizontal_align.svg';
import alignRight from '../../assets/icons/align/RightAlign.svg';
import alignTop from '../../assets/icons/align/Top_align.svg';
import alignVertical from '../../assets/icons/align/Vertical_Align.svg';
import alignBottom from '../../assets/icons/align/Bottom_align.svg';
import distributeHorizontal from '../../assets/icons/align/Horizontal_distribution.svg';
import distributeVertical from '../../assets/icons/align/Vertical_distribution.svg';
import smartAlign from '../../assets/icons/align/Smart_Align.svg';

const AlignmentIcons = [
  {
    name: 'AlignLeft',
    icon: alignLeft,
    tooltip: 'Left Align',
    type: 'LEFT',
  },
  {
    name: 'AlignHorizontal',
    icon: alignHorizontal,
    tooltip: 'Horizontal Align',
    type: 'CENTER',
  },
  {
    name: 'AlignRight',
    icon: alignRight,
    tooltip: 'Right Align',
    type: 'RIGHT',
  },
  {
    name: 'AlignTop',
    icon: alignTop,
    tooltip: 'Top Align',
    type: 'TOP',
  },
  {
    name: 'AlignVertical',
    icon: alignVertical,
    tooltip: 'Vertical Align',
    type: 'MIDDLE',
  },
  {
    name: 'AlignBottom',
    icon: alignBottom,
    tooltip: 'Bottom Align',
    type: 'BOTTOM',
  },
  {
    name: 'DistributeHorizontal',
    icon: distributeHorizontal,
    tooltip: 'Distribute Horizontal',
    type: 'HDISTRIBUTE',
  },
  {
    name: 'DistributeVertical',
    icon: distributeVertical,
    tooltip: 'Distribute Vertical',
    type: 'VDISTRIBUTE',
  },
  {
    name: 'SmartAlign',
    icon: smartAlign,
    tooltip: 'Smart Align',
    type: 'SMART',
  },

];

const AlignmentIconsSingleNode = [
  {
    name: 'AlignLeft',
    icon: alignLeft,
    tooltip: 'Left Align',
    type: 'LEFT',
  },
  {
    name: 'AlignHorizontal',
    icon: alignHorizontal,
    tooltip: 'Horizontal Align',
    type: 'CENTER',
  },
  {
    name: 'AlignRight',
    icon: alignRight,
    tooltip: 'Right Align',
    type: 'RIGHT',
  },
  {
    name: 'AlignTop',
    icon: alignTop,
    tooltip: 'Top Align',
    type: 'TOP',
  },
  {
    name: 'AlignVertical',
    icon: alignVertical,
    tooltip: 'Vertical Align',
    type: 'MIDDLE',
  },
  {
    name: 'AlignBottom',
    icon: alignBottom,
    tooltip: 'Bottom Align',
    type: 'BOTTOM',
  },
];

export { AlignmentIcons, AlignmentIconsSingleNode };

export default AlignmentIcons;
