import {
	I3D,
	I3DSubtype,
	IBlock,
	IFile,
	IFileSubtype,
	IImage,
	ILink,
	ILinkSubtype,
	IPdf,
	IProject,
	IGroup,
	IUser,
	IVideo,
	TUserAndRole,
	EUserRole,
	INote,
	IText,
	IProjectGroup,
	IProjectDetail,
	TInvitedEmail,
	ITodo
} from '@naya_studio/types';
import mongoose from 'mongoose';
import { AppDispatch, store } from 'src';
import { CustomDispatch } from 'src/redux/actions/types';
import { findBlockWithBlockId, findStageWithBlockId } from 'src/redux/actions/util';
import {
	createProject,
	editProject,
	editProjectDetails,
	nestingAction
} from 'src/redux/reduxActions/project';
import {
	TEditProjectThunkArg,
	TAddStageThunkArg,
	TCreateNewStageFromBlockThunkArg,
	TDeleteBlockArgs,
	TDeleteGroupThunkArg,
	TDuplicateBlockArgs,
	TDuplicateStageThunkArg,
	TEditStageThunkArg,
	TReorderBlocksThunkArg,
	TEditProjectDetailsThunkArg,
	TBulkUpdateGroupArgs,
	TNestingThunkArgs,
	TBulkUpdateBlockArgs,
	TGenerateOptimisticProjectArg,
	TCreateProjectThunkArg
} from 'src/types/argTypes';
import { bulkUpdateBlocks, deleteBlock, duplicateBlock } from 'src/redux/reduxActions/block';
import {
	addStage,
	bulkUpdateGroups,
	createNewStageFromOrphanBlock,
	deleteStage,
	duplicateStage,
	editStage,
	reorderBlocks
} from 'src/redux/reduxActions/stage';
import {
	generateIdsFromUrl,
	generateOptimisticProject,
	generateOptimisticStage
} from 'src/redux/reduxActions/util';
import { checkUserAccessLevel } from 'src/util/accessLevel/accessLevelActions';
import { getSiteMetaData, SiteDetailsT } from '@naya_studio/radix-ui';
import { editBlocksById } from 'src/redux/features/blocks';
import trackEvent from 'src/util/analytics/analytics';
import { CustomEvents, GroupEvents } from 'src/util/analytics/events';
import { notificationSlice } from 'src/redux/features/api/notification';
import { TMyTaskItem } from 'src/util/notifications/useNotifications';
import { cancelTaskOnBlockOrPhaseDelete } from 'src/hooks/useFileConverter';
import getUserFromRedux from 'src/util/helper/user';
import { capitalize, clone, isNumber } from 'lodash';
import { loadGroupFromLocalForage, updateLocalForageData } from 'src/util/storage/indexedDBStorage';
import { updateGroup } from 'src/redux/features/groupDetail';
import { updateUser } from 'src/redux/features/user';
import { findAndUpdateJourneyInGroup } from 'src/redux/features/util';
import { loadProjectGroupById } from 'src/redux/actions/user';
import { TAddColorEventData, TCustomEventData } from 'src/util/analytics/analytic.types';
import { checkIfUserHasAccess } from 'src/redux/hooks/observers/utils';
import { getRestrictedBlocks } from 'src/util/helper/project';
import {
	onDuplicateBlock as onBlockDuplicate,
	onDuplicateGroup
} from 'src/redux/actions/cloneUtils';
import { ISnackBar } from 'src/redux/reducers/root.types';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';
import {
	TCombinedBlock,
	TGroupParent,
	TGuestAccessTo
} from '../collaborationTool/CollaborationTool.types';
import { getFileLinkSubType } from '../utilities/navbar/utils';
import { getUserDetail } from '../canvas/feedback/feedbackUtil';
import { TOnNestingArgs, TUpdateProjectArgs } from './JourneyContainer.types';
import { getUserFirstName, getUserLastName } from '../feedbackSuite/util';

/**
 * Function to rename phase
 * @param phaseId
 * @param newName
 */
export const onGroupEdit = (phaseId: string, newName?: string, color?: string) => {
	const dispatch = store.dispatch as CustomDispatch;
	const { projectId } = generateIdsFromUrl();
	const { stages } = store.getState();
	const payload: TEditStageThunkArg = {
		payload: {
			id: phaseId,
			update: {
				...(newName && { name: newName }),
				...(color && { color })
			},
			projectId
		},
		prevState: {
			prevStage: stages.data[phaseId] as IGroup
		}
	};
	dispatch(editStage(payload))
		.unwrap()
		.then(() => {
			if (newName) {
				/** --- Update Stage Name in all notifications of that stage ---*/
				dispatch(
					notificationSlice.endpoints.updateMultipleNotifs.initiate({
						// using uniqueIdentifier as we dont store stageId
						searchBy: { 'data.uniqueIdentifier': phaseId },
						update: { 'data.stageName': newName }
					})
				);

				// Track rename group events
				const eventProps: TCustomEventData = {
					elementId: phaseId
				};
				trackEvent(GroupEvents.RENAME_GROUP, eventProps);
			}

			if (color) {
				// Track adding custom color to group events
				const eventProps: TAddColorEventData = {
					elementId: phaseId,
					elementType: 'GROUP',
					color
				};
				trackEvent(CustomEvents.ADD_CUSTOM_COLOR, eventProps);
			}
		});
};

/**
 * Functiom to reorder blocks - either within a phase or between the phases
 * @param sourcePhaseId
 * @param destinationPhaseId
 * @param sourceIndex
 * @param destinationIndex
 */
export const onReorderBlocks = (
	sourcePhaseId: string,
	destinationPhaseId: string,
	sourceIndex: number,
	destinationIndex: number,
	movedBlockId: string,
	type: 'BLOCK' | 'PHASE'
) => {
	const dispatch = store.dispatch as CustomDispatch;
	const { projectId } = generateIdsFromUrl();
	const { projects, stages: reduxStages } = store.getState();

	const stages = reduxStages.data;
	const prevStageIds = projects.data[projectId]?.children as string[];

	const sPhase = stages[sourcePhaseId];
	const sourceBlockIdsBeforeReorder = sPhase?.children as string[];
	const dPhase = stages[destinationPhaseId];
	const sCanvases = [...(sPhase?.children as string[])];
	const dCanvases = [...(dPhase?.children as string[])];
	if (sourcePhaseId === destinationPhaseId) {
		if (sCanvases) {
			const temp = sCanvases[sourceIndex];
			if (temp) {
				if (temp === movedBlockId) {
					sCanvases.splice(sourceIndex, 1);
					sCanvases.splice(destinationIndex, 0, temp);
				} else {
					const blockIndex = sCanvases.findIndex((b) => b === movedBlockId);
					if (blockIndex !== -1) {
						sCanvases.splice(blockIndex, 1);
						sCanvases.splice(destinationIndex, 0, temp);
					}
				}

				if (sCanvases) {
					const apiPayload: TReorderBlocksThunkArg = {
						payload: {
							sourcePhaseId,
							sourceBlocks: sCanvases,
							destinationPhaseId,
							destinationBlocks: [],
							isPhaseCreated: sPhase?.isPhaseCreated || false,
							projectId,
							reorderedBlock: {
								blockId: temp,
								newStageId: destinationPhaseId,
								index: sourceIndex
							},
							sourceBlockIdsBeforeReorder,
							type
						},
						prevState: {
							prevStages: stages,
							prevStageIds
						}
					};
					dispatch(reorderBlocks(apiPayload))
						.unwrap()
						.catch(() => {
							const failureSnackbar: ISnackBar = {
								text: `${capitalize(
									type
								)} you're trying to re-order has been already moved. Please refresh and try again.`,
								type: 'ERROR',
								show: true
							};

							addSnackbar(failureSnackbar);
							removeSnackbar(3000);
						});
				}
			}
		}
	} else if (sCanvases && dCanvases) {
		const movedBlock = sCanvases[sourceIndex];
		if (movedBlock) {
			dCanvases.splice(destinationIndex, 0, movedBlock);
			sCanvases.splice(sourceIndex, 1);
			const apiPayload: TReorderBlocksThunkArg = {
				payload: {
					sourcePhaseId,
					sourceBlocks: sCanvases,
					destinationPhaseId,
					destinationBlocks: dCanvases,
					isPhaseCreated: sPhase?.isPhaseCreated || false,
					projectId,
					reorderedBlock: {
						blockId: movedBlock,
						newStageId: destinationPhaseId,
						index: sourceIndex
					},
					sourceBlockIdsBeforeReorder,
					type
				},
				prevState: {
					prevStages: stages,
					prevStageIds
				}
			};
			dispatch(reorderBlocks(apiPayload))
				.unwrap()
				.catch(() => {
					const failureSnackbar: ISnackBar = {
						text: `${capitalize(
							type
						)} you're trying to re-order has been already moved. Please refresh and try again.`,
						type: 'ERROR',
						show: true
					};

					addSnackbar(failureSnackbar);
					removeSnackbar(3000);
				});
		}
	}
};

/**
 * Adds a new phase/nested_phase to a project or phase.
 *
 * @param {string} phaseName - The name of the new phase to be added.
 * @param {string} _id - The unique identifier for the new phase.
 * @param {number} newPhaseIndex - The index position where the new phase will be inserted.
 * @param {boolean} isPhaseCreated - Flag indicating whether the phase has been successfully created.
 * @param {string[]} [blockIds] - (Optional) An array of block IDs to be associated with the new phase.
 * @param {string[]} [sourcePhaseId] - (Optional) An array of source phase IDs from which the new phase is derived.
 * @param {Object} [parent] - (Optional) The target details where the new phase should be added.
 * @param {string} [target.type] - The type of target, either 'JOURNEY' or 'PHASE'.
 * @param {string} [target.id] - (Optional) The unique identifier of the target.
 *
 */
export const onAddNewPhase = (
	phaseName: string,
	_id: string,
	newPhaseIndex: number,
	isPhaseCreated: boolean,
	blockIds?: string[],
	sourcePhaseId?: string[],
	parent: {
		id: string;
		type: TGroupParent['type'];
	} = { id: generateIdsFromUrl().projectId, type: 'JOURNEY' }
) => {
	const dispatch = store.dispatch as CustomDispatch;
	const { projectId } = generateIdsFromUrl();
	const reduxState = store.getState();
	const eventProps: TCustomEventData = {
		elementId: _id
	};

	if (blockIds && blockIds?.length > 0 && sourcePhaseId) {
		const orphanGroupIds: string[] = [];
		sourcePhaseId.forEach((groupId) => {
			if (
				reduxState.stages.data[groupId]?.children.length === 1 &&
				!reduxState.stages.data[groupId]?.isPhaseCreated
			)
				orphanGroupIds.push(groupId);
		});
		const dPhase = generateOptimisticStage(_id, phaseName, parent);
		dPhase.children = [...blockIds];
		dPhase.isPhaseCreated = isPhaseCreated;
		dPhase.parentId = parent?.id || projectId;

		const apiPayload: TCreateNewStageFromBlockThunkArg = {
			payload: {
				projectId,
				stageData: dPhase,
				sourcePhaseId,
				blockIds,
				newPhaseIndex,
				orphanGroupIds,
				parent: {
					id: parent?.id || projectId,
					type: parent?.type || 'JOURNEY'
				}
			},
			prevState: {
				prevBlocks: reduxState.blocks.data,
				prevStages: reduxState.stages.data,
				prevStageIds: reduxState.projects.data[projectId]?.children as string[]
			}
		};

		dispatch(createNewStageFromOrphanBlock(apiPayload))
			.unwrap()
			.then(() => {
				// Track adding group event
				if (isPhaseCreated) trackEvent(GroupEvents.ADD_GROUP, eventProps);
			});
	} else {
		const newGroup = {
			...generateOptimisticStage(_id, phaseName, parent),
			isPhaseCreated,
			// set the parentId
			parentId: parent?.type === 'PHASE' && parent?.id ? parent.id : projectId
		};
		const apiPayload: TAddStageThunkArg = {
			payload: {
				newPhaseIndex,
				stage: newGroup,
				target: {
					id: parent?.id || projectId,
					type: parent?.type || 'JOURNEY'
				}
			}
		};
		dispatch(addStage(apiPayload))
			.unwrap()
			.then(() => {
				// Track adding group event
				if (isPhaseCreated) trackEvent(GroupEvents.ADD_GROUP, eventProps);
			});
	}
};

/**
 * Function to duplicate phase
 * @param originalPhaseId
 */
export const onDuplicatePhase = async (originalPhaseId: string) => {
	const dispatch = store.dispatch as CustomDispatch;
	const { projectId } = generateIdsFromUrl();
	const project = store.getState().projects.data[projectId] as IProject;
	const { stages } = store.getState();
	const originalPhase = stages.data[originalPhaseId];

	if (originalPhase) {
		const parentType = originalPhase.parentId === projectId ? 'JOURNEY' : 'PHASE';
		const originalPhaseIndex =
			parentType === 'JOURNEY'
				? (project.children as string[])?.indexOf(originalPhaseId)
				: stages.data[originalPhase.parentId]?.children.indexOf(originalPhaseId);
		if (!isNumber(originalPhaseIndex) || originalPhaseIndex === -1) return undefined;

		const response = await onDuplicateGroup(originalPhaseId, {
			newName: `${
				originalPhase.name === 'UNDEFINED'
					? `Untitled ${originalPhaseIndex}`
					: originalPhase.name
			} Copy`,
			newProjectId: projectId,
			newParentId: originalPhase.parentId,
			newUsers: originalPhase.users as Array<TUserAndRole>,
			shouldCloneChildrenNotes: true,
			shouldCloneSelfNotes: true
		});

		if (response) {
			const { clonedGroup, clonedBlocks, clonedNestedGroups, clonedNodes, clonedNotes } =
				response;
			const payload: TDuplicateStageThunkArg = {
				payload: {
					projectId,
					originalGroupId: originalPhaseId,
					clonedGroupId: clonedGroup._id as string,
					insertIndex: originalPhaseIndex + 1,
					clonedItems: {
						groups: clonedNestedGroups.concat(clonedGroup),
						blocks: clonedBlocks,
						nodes: clonedNodes,
						notes: clonedNotes
					},
					parent: {
						id: originalPhase.parentId,
						type: parentType
					}
				}
			};
			dispatch(duplicateStage(payload));

			return {
				newStageId: clonedGroup._id as string,
				clonedStage: clonedGroup,
				clonedStages: clonedNestedGroups.concat(clonedGroup),
				clonedBlocks,
				parent: {
					id: originalPhase.parentId,
					type: parentType as 'JOURNEY' | 'PHASE'
				},
				originalPhaseIndex,
				clonedNodes,
				clonedNotes
			};
		}
	}
	return undefined;
};

/**
 * Function on duplicate block
 * @param originalBlockId
 * @param phaseId
 */
export const onDuplicateBlock = async (originalBlockId: string, phaseId: string) => {
	const dispatch = store.dispatch as CustomDispatch;
	const { projectId } = generateIdsFromUrl();

	const phase = findStageWithBlockId(originalBlockId);
	const originalBlock = findBlockWithBlockId(originalBlockId) as IBlock;
	let originalBlockPosition =
		phase?.children?.findIndex((blockId) => blockId === originalBlockId) || 0;
	if (originalBlockPosition !== undefined) originalBlockPosition += 1;
	else return undefined;

	const canvasName = `${
		originalBlock.name === 'UNDEFINED'
			? `Untitled ${originalBlockPosition}`
			: originalBlock.name
	} Copy`;

	const response = await onBlockDuplicate(originalBlockId, {
		newName: canvasName,
		newParentId: phaseId,
		newProjectId: projectId,
		newUsers: originalBlock.users as Array<TUserAndRole>,
		shouldCloneNodes: true,
		shouldCloneNotes: true
	});

	if (response && originalBlockPosition !== -1) {
		const { clonedBlock, clonedNodes, clonedNotes } = response;
		const apiPayload: TDuplicateBlockArgs = {
			data: {
				blockId: originalBlockId,
				stageId: phaseId,
				projectId,
				clonedItems: {
					block: clonedBlock,
					nodes: clonedNodes,
					notes: clonedNotes
				},
				blockIndex: originalBlockPosition
			}
		};

		dispatch(duplicateBlock(apiPayload));
		return { newBlockId: clonedBlock._id as string, newBlock: clonedBlock };
	}

	return undefined;
};

/**
 * Function to delete a phase
 * @param phaseId
 */
export const onDeletePhase = (phaseId: string) => {
	const dispatch = store.dispatch as CustomDispatch;
	const { projectId } = generateIdsFromUrl();
	const { projects, stages } = store.getState();
	const stage = stages.data[phaseId];

	if (stage?.children) {
		cancelTaskOnBlockOrPhaseDelete(stage.children as string[], 'PHASE');
	}
	const project = projects.data[projectId] as IProject;

	const paylaod: TDeleteGroupThunkArg = {
		payload: {
			projectId: project._id as string,
			stageId: phaseId,
			childrens: stage?.children as Array<string>
		},
		prevState: {
			groupIds: project.children as string[],
			group: stage as IGroup
		}
	};
	dispatch(deleteStage(paylaod));
};

/**
 * Function to delete a block
 * @param blockId
 * @param phaseId
 */
export const onDeleteBlock = (blockIds: string[], groupIds: string[]) => {
	cancelTaskOnBlockOrPhaseDelete(blockIds);
	const reduxState = store.getState();
	const dispatch = store.dispatch as CustomDispatch;

	const { projectId } = generateIdsFromUrl();
	// Array of groupIds which will not be visible after deletion of block
	const orphanGroupIds: string[] = [];
	groupIds.forEach((groupId) => {
		if (
			reduxState.stages.data[groupId]?.children.length === 1 &&
			!reduxState.stages.data[groupId]?.isPhaseCreated
		)
			orphanGroupIds.push(groupId);
	});

	// generate for delete block action
	const apiPayload: TDeleteBlockArgs = {
		data: {
			blockIds,
			groupIds,
			projectId,
			orphanGroupIds
		},
		prevState: {
			prevProjectChildren: reduxState.projects.data[projectId]?.children as string[],
			prevStages: reduxState.stages.data as { [key: string]: IGroup },
			prevBlocks: reduxState.blocks.data as { [key: string]: IBlock }
		}
	};
	dispatch(deleteBlock(apiPayload))
		.unwrap()
		.then(() => {
			updateLocalForageData('BLOCKS');
		});
};

// This function retrieves user information from the Redux store
export const getFormattedUser = () => {
	// Get the user object from the Redux store
	const user: IUser = getUserFromRedux();

	// Destructure relevant properties from the user object
	// eslint-disable-next-line @typescript-eslint/naming-convention
	const { _id, userName, profilePic, email, userType, userPreferences } = user;

	// Create a new object to store the formatted user information
	const formattedUser = {} as IUser;

	// Assign the extracted properties to the formattedUser object
	formattedUser._id = _id;
	formattedUser.userType = userType;
	formattedUser.userName = userName;
	formattedUser.profilePic = profilePic;
	formattedUser.email = email;
	formattedUser.firstName = getUserFirstName(user);
	formattedUser.lastName = getUserLastName(user);
	formattedUser.userPreferences = userPreferences;

	// Return the formattedUser object containing user information
	return formattedUser;
};

/**
 *
 * Function to check if the user is project editor
 */
export const isProjectEditor = () => {
	const { projectId } = generateIdsFromUrl();
	const project = store.getState().projects.data[projectId] as IProject;
	const users = project?.users as TUserAndRole[];
	const user = getUserFromRedux();

	if (user.userType?.includes('ADMIN')) return true;
	return checkUserAccessLevel(users, user._id as string, ['OWNER', 'EDITOR']);
};

// Function to update redux with latest site title
export const updateSiteDataInRedux = async () => {
	const blocks = { ...store.getState().blocks.data };
	const tempBlocks: IBlock[] = [];
	const reduxBlocks = Object.values(blocks) as IBlock[];

	for (let i = 0; i < reduxBlocks.length; i++) {
		const block = reduxBlocks[i] as IBlock;
		if (
			block.blockType === 'LINK' &&
			block.name.toUpperCase() === 'UNDEFINED' &&
			(block as ILink).link
		) {
			// eslint-disable-next-line no-await-in-loop
			const { title } = (await getSiteMetaData((block as ILink).link)) as SiteDetailsT;
			if (title) {
				tempBlocks.push({ ...block, name: title });
			}
		}
	}
	if (tempBlocks.length) store.dispatch(editBlocksById(tempBlocks));
};

/**
 * Function to load active group in redux after project is loaded
 */
export const loadActiveGroupInRedux = async () => {
	const dispatch = store.dispatch as AppDispatch;
	const user = getUserFromRedux();
	const reduxGroup = store.getState().projectGroup.data;
	const activeGroupStringified = window.sessionStorage.getItem('activeGroupId');

	if (!activeGroupStringified) return;
	const activeGroup = JSON.parse(activeGroupStringified);

	// Load group from indexed db
	const shouldNotLoadFromApi = reduxGroup._id
		? true
		: await loadGroupFromLocalForage(activeGroup.id);

	// If group is not found in indexed db then load it from api
	if (!shouldNotLoadFromApi) {
		// Load group from api
		dispatch(
			loadProjectGroupById({
				projectGroupId: activeGroup.id,
				userId: user._id as string
			})
		);
	}
};

export const getUserById = (userId: string, projectUsers: IUser[]) =>
	projectUsers.find((user) => user._id === userId);

// This fundtion gets all the users of a project
const getPopulatedUsers = (userRoles: TUserAndRole[]) => {
	const { projectUsers } = store.getState();
	const allUsers = [] as TUserAndRole[];
	for (let i = 0; i < userRoles.length; i++) {
		if (userRoles[i])
			allUsers.push({
				role: userRoles[i]?.role as EUserRole,
				user: getUserDetail(userRoles[i]?.user as string, projectUsers.data)
			});
	}
	return allUsers;
};

// Format the phases to skim the phase data
export const getFormattedData = (
	phases: IGroup[],
	blocks: { [key: string]: IBlock },
	reduxNotes: { [key: string]: INote },
	getFormattedTasksForBlock?: (id: string) => TMyTaskItem[],
	hasGuestAccessTo?: TGuestAccessTo
) => {
	const data = phases;
	const formattedPhases: Partial<IGroup>[] = [];
	const formattedBlocks: { [key: string]: Partial<IBlock> } = {};
	const tempCreatedByUsers: { [key: string]: Partial<IBlock> } = {};
	const user: IUser = getUserFromRedux();
	const restrictedBlockIds = getRestrictedBlocks();
	// Get all block available block types
	const availableTypes: string[] = [];
	// Get all sticky notes available
	const stickyNotes: string[] = [];

	// iterating over phases to format it
	for (let i = 0; i < data.length; i++) {
		const phase = data[i] as IGroup;
		// Check if user has access to this group
		const hasAccessToGroup =
			checkIfUserHasAccess(
				user,
				phase.users as TUserAndRole[],
				phase.invitedEmails as TInvitedEmail[]
			) ||
			user.userType?.includes('ADMIN') ||
			hasGuestAccessTo === 'PROJECT';

		// Check if user has access to any child in this group
		const hasAccessToAChild =
			!hasAccessToGroup &&
			!phase?.children.every((child) => restrictedBlockIds.includes(child as string));
		// If a stage does not have blocks and isPhaseCreated, its not a valid stage
		const isValidStage = !((phase.children?.length || 0) === 0 && !phase.isPhaseCreated);
		if (isValidStage) {
			const tempPhase = {} as IGroup;
			tempPhase._id = phase._id;
			tempPhase.children = phase.children;
			tempPhase.users = phase.users as TUserAndRole[];
			tempPhase.name = phase.name || '';
			tempPhase.color = phase.color || '#F5F5F5';
			tempPhase.isPhaseCreated = phase.isPhaseCreated;
			tempPhase.notes = [];
			tempPhase.dates = phase?.dates;
			tempPhase.parentId = phase.parentId;
			tempPhase.projectId = phase.projectId;
			tempPhase.createdBy = phase.createdBy;
			tempPhase.isHidden = phase.isHidden;
			// notes on phases
			(phase.notes as string[])?.forEach((id) => {
				const note = reduxNotes[id as string] as INote;

				if (note) {
					if (note.color && !stickyNotes.includes(note.color))
						stickyNotes.push(note.color);
					(tempPhase.notes as INote[]).push(note);
				}
			});

			// iterating over blocks to format it
			(phase.children as string[]).forEach((b: string, index: number) => {
				const c = blocks[b] as IBlock;
				if (c) {
					const tempBlock = {} as TCombinedBlock;
					tempBlock._id = c._id;
					tempBlock.name = c.name === 'UNDEFINED' ? `Untitled ${index + 1}` : c.name;
					tempBlock.color = c.color;
					tempBlock.blockType = c.blockType;
					tempBlock.users = getPopulatedUsers(c.users as TUserAndRole[]);
					tempBlock.createdBy = c.createdBy;
					tempBlock.updatedAt = c.updatedAt;
					tempBlock.notes = [] as INote[];
					tempBlock.thumbnail = c.thumbnail;
					tempBlock.isHidden = c.isHidden;
					tempBlock.hasGuestAccess =
						hasGuestAccessTo === 'PROJECT' ? true : c.hasGuestAccess;
					tempBlock.parentId = c.parentId || (phase._id as string);
					tempBlock.googleResourceId = c.googleResourceId;
					tempBlock.password = c.password;
					tempBlock.isCollapsed = c.isCollapsed;
					(c.notes as string[])?.forEach((id) => {
						const note = reduxNotes[id as string] as INote;

						if (note) {
							if (note.color && !stickyNotes.includes(note.color))
								stickyNotes.push(note.color);
							(tempBlock.notes as INote[]).push(note);
						}
					});
					tempBlock.dates = c?.dates;
					tempBlock.deliverableStatus = c?.deliverableStatus;
					if (getFormattedTasksForBlock)
						tempBlock.tasks = getFormattedTasksForBlock(c?._id as string) || [];
					tempBlock.isFavorite = c.likedBy?.includes(user?._id as string) as boolean;
					tempCreatedByUsers[tempBlock.createdBy as string] = getUserDetail(
						tempBlock.createdBy as string,
						store.getState().projectUsers.data
					) as IUser;
					switch (tempBlock.blockType) {
						case 'PDF':
							(tempBlock as IPdf).link = (c as IPdf).link;
							tempBlock.fileName = (c as IPdf).fileName;
							tempBlock.hasFeedback = (c as IPdf)?.feedbacks?.length > 0 || false;
							break;
						case 'VIDEO':
							(tempBlock as IVideo).link = (c as IVideo).link;
							tempBlock.fileName = (c as IVideo).fileName;
							tempBlock.hasFeedback = (c as IVideo)?.feedbacks?.length > 0 || false;

							break;
						case 'IMAGE':
							if (
								tempBlock.thumbnail.src === '#FFFFFF' &&
								(c as IImage).link &&
								!c.thumbnail.isCustom
							) {
								tempBlock.thumbnail = {
									src: (c as IImage).link,
									originalSrc: (c as IImage).link,
									isCustom: c.thumbnail.isCustom
								};
							}
							(tempBlock as IImage).link = (c as IImage).link;
							tempBlock.fileName = (c as IImage).fileName;
							tempBlock.hasFeedback = (c as IImage)?.feedbacks?.length > 0 || false;
							if (
								(c as IImage).link &&
								// Use replace to check for images-generated-by-ai bucket and not .includes
								// If we use .includes then even files having images-generated-by-ai in their names
								// would have isGeneratedByAI true
								(c as IImage).link
									.replace('https://storage.googleapis.com/', '')
									.startsWith('images-generated-by-ai')
							) {
								tempBlock.isGeneratedByAI = true;
							}
							break;
						case 'LINK':
							(tempBlock as ILink).link = (c as ILink).link as string;
							if ((c as ILink).link) {
								// This will be used to show type as [GMAIL, FIGMA, MIRO] as such
								(tempBlock as ILink).subType = getFileLinkSubType(
									(c as ILink).link,
									'LINK'
								) as keyof typeof ILinkSubtype;
							}
							tempBlock.hasFeedback = (c as ILink)?.feedbacks?.length > 0 || false;
							break;
						case 'THREE_D':
							tempBlock.fileName = (c as I3D).fileName;
							(tempBlock as I3D).link = (c as I3D).link as string[];
							(tempBlock as I3D).originalLink = (c as I3D).originalLink as string[];
							if ((c as I3D).fileName) {
								// This will be used to show type as [ILLUSTRATOR, RHINO, ETC...] as such
								(tempBlock as I3D).subType = getFileLinkSubType(
									(c as I3D).fileName,
									'THREE_D'
								) as keyof typeof I3DSubtype;
							}
							tempBlock.hasFeedback = (c as I3D)?.feedbacks?.length > 0 || false;
							break;
						case 'FILE':
							(tempBlock as IFile).link = (c as IFile).link;
							tempBlock.fileName = (c as IFile).fileName;
							if ((c as IFile).fileName) {
								(tempBlock as IFile).subType = getFileLinkSubType(
									(c as IFile).fileName,
									'FILE'
								) as keyof typeof IFileSubtype;
							}
							tempBlock.hasFeedback = (c as IFile)?.feedbacks?.length > 0 || false;
							break;
						case 'TEXT': {
							tempBlock.text = (c as IText).text;
							break;
						}
						case 'TODO': {
							tempBlock.todos = (c as ITodo)?.todos || [];
							break;
						}
						default:
							break;
					}
					// Add the block to formatted only if block.stageId is same as parent stage
					if (
						checkIfUserHasAccess(
							user,
							c.users as TUserAndRole[],
							c.invitedEmails as TInvitedEmail[]
						) ||
						user.userType?.includes('ADMIN') ||
						hasGuestAccessTo === 'PROJECT' ||
						tempBlock.hasGuestAccess
					)
						formattedBlocks[tempBlock._id as string] = tempBlock;
					if (tempBlock.blockType !== 'EMPTY') {
						// Show the default types at the beginning in filter options but
						// on the second option if there is a favorite block
						if (tempBlock.blockType && !availableTypes.includes(tempBlock.blockType)) {
							if (!availableTypes.includes('FAVORITE')) {
								availableTypes.unshift(tempBlock.blockType);
							} else {
								// doing this because we always want the favorite block filter option at the 0th index
								availableTypes.splice(1, 0, tempBlock.blockType);
							}
						}
						if (tempBlock.subType && !availableTypes.includes(tempBlock.subType)) {
							availableTypes.push(tempBlock.subType);
						}
						if (
							tempBlock.blockType === 'IMAGE' &&
							tempBlock.isGeneratedByAI &&
							!availableTypes.includes('AI_GENERATED')
						) {
							availableTypes.unshift('AI_GENERATED');
						}
					}
					// if we have any favorite block, add it to the available types
					if (
						c?.likedBy?.includes(user?._id as string) &&
						!availableTypes.includes('FAVORITE')
					) {
						availableTypes.unshift('FAVORITE');
					}
				}
			});

			// If user has access to this group or any child inside the group push it in formatted phases
			if (hasAccessToAChild || hasAccessToGroup) formattedPhases.push(tempPhase);
		}
	}

	return {
		formattedBlocks,
		formattedPhases,
		createdByUsers: Object.values(tempCreatedByUsers),
		availableTypes,
		stickyNotes
	};
};

/**
 * Used to update the project from journey
 * Currently used to update:
 * 1. Thumbnail of the project
 * 2. Reorder Phases
 */
export const onUpdateProject = (updateArgs: TUpdateProjectArgs) => {
	const { editType, payload } = updateArgs;

	const dispatch = store.dispatch as AppDispatch;
	const reduxState = store.getState();
	const { projectId } = generateIdsFromUrl();
	const { projects, projectGroup, user } = reduxState;

	const prevProject = projects.data[projectId] as IProject;
	const activeGroupFromRedux = projectGroup.data;
	const update = {
		_id: projectId
	} as Partial<IProject>;

	switch (editType) {
		case 'EDIT_THUMBNAIL':
			update.thumbnail = {
				src: payload.thumbnail as string,
				originalSrc: payload.thumbnail as string,
				isCustom: false
			};
			break;
		case 'REORDER_PHASES':
			update.children = payload.children;
			break;
		default: {
			console.error('Add correct case to update the project');
		}
	}
	const apiPayload: TEditProjectThunkArg = {
		payload: {
			_id: projectId,
			update,
			sourceStageIdsBeforeReorder: prevProject.children as string[]
		},
		prevState: {
			prevProject
		}
	};

	dispatch(editProject(apiPayload))
		.unwrap()
		.then(() => {
			// If group is opened, only update the group data in redux
			if (activeGroupFromRedux._id) {
				const activeGroup = clone(activeGroupFromRedux);
				const updatedGroup = findAndUpdateJourneyInGroup(
					activeGroup,
					projectId,
					update,
					false
				);

				// Update project with new thumbnail in redux project group
				dispatch(updateGroup(updatedGroup));

				const projectGroups = clone(user.data.projectGroups) as IProjectGroup[];
				const activeGroupIndex = projectGroups.findIndex(
					(grp) => grp._id === activeGroupFromRedux._id
				);

				if (activeGroupIndex !== -1) {
					const userActiveGroup = clone(projectGroups[activeGroupIndex]) as IProjectGroup;
					const allJourneys = clone(userActiveGroup.projects) as IProject[];
					const journeyIndex = allJourneys.findIndex((jou) => jou._id === projectId);

					// Update the user only if updated project appears in one of the 4 projects of groups
					if (journeyIndex !== -1) {
						const userUpdatedGroup = findAndUpdateJourneyInGroup(
							userActiveGroup,
							projectId,
							update,
							false
						);

						projectGroups[activeGroupIndex] = userUpdatedGroup;

						const updatedUser = {
							...user,
							projectGroups
						};

						// Update project with new thumbnail in user's project groups
						dispatch(updateUser(updatedUser));
					}
				}
			} else {
				const parentGroupId = user.data.defaultProjectGroup as string;
				const projectGroups = clone(user.data.projectGroups) as IProjectGroup[];
				const activeGroupIndex = projectGroups.findIndex(
					(grp) => grp._id === parentGroupId
				);

				if (activeGroupIndex !== -1) {
					const activeGroup = clone(projectGroups[activeGroupIndex]) as IProjectGroup;
					const allJourneys = clone(activeGroup.projects) as IProject[];
					const sharedProjects = clone(user.data.sharedProjects) as IProject[];
					const journeyIndex = allJourneys.findIndex((jou) => jou._id === projectId);

					if (journeyIndex !== -1) {
						const updatedGroup = findAndUpdateJourneyInGroup(
							activeGroup,
							projectId,
							update,
							false
						);

						projectGroups[activeGroupIndex] = updatedGroup;

						const updatedUser = {
							...user.data,
							projectGroups
						};
						// Update project with new thumbnail in user's project groups
						dispatch(updateUser(updatedUser));
					} else {
						const sharedJourneyIndex = sharedProjects.findIndex(
							(jou) => jou._id === projectId
						);

						if (sharedJourneyIndex !== -1) {
							const sharedJourney = sharedProjects[sharedJourneyIndex] as IProject;
							const updatedJourney = {
								...sharedJourney,
								...update
							};

							sharedProjects[sharedJourneyIndex] = updatedJourney;
							const updatedUser = {
								...user.data,
								sharedProjects
							};

							// Update project with new thumbnail in user's project groups
							dispatch(updateUser(updatedUser));
						}
					}
				}
			}
		});
};

/**
 * Used to update the project details like color
 */
export const onUpdateProjectDetails = (color: string) => {
	const { projectId } = generateIdsFromUrl();
	const project = store.getState().projects.data[projectId] as IProject;

	const apiPayload: TEditProjectDetailsThunkArg = {
		payload: {
			projectId,
			updates: {
				_id: (project?.projectDetails as IProjectDetail)?._id,
				color
			}
		},
		prevState: {
			color: (project?.projectDetails as IProjectDetail).color
		}
	};

	(store.dispatch as CustomDispatch)(editProjectDetails(apiPayload))
		.unwrap()
		.then(() => {
			// Track changing journey color event
			const eventProps: TAddColorEventData = {
				elementId: projectId,
				elementType: 'JOURNEY',
				color
			};
			trackEvent(CustomEvents.ADD_CUSTOM_COLOR, eventProps);
		});
};

/**
 * Function to handle nesting and un-nesting of phases.
 * @param {TOnNestingArgs} param0
 */
export const onNesting = ({ phase, projectChildren, type, reorderedPhaseId }: TOnNestingArgs) => {
	const { projectId } = generateIdsFromUrl();
	if (!projectId || !phase || !reorderedPhaseId) return;

	const dispatch = store.dispatch as AppDispatch;
	const reduxState = store.getState();
	const { projects, stages } = reduxState;

	const prevProject = projects.data[projectId] as IProject;
	const prevPhase = stages.data[phase._id as string];
	const prevReorderedPhase = stages.data[reorderedPhaseId];

	if (!prevPhase || !prevProject || !prevReorderedPhase) return;

	const projectUpdate = {
		_id: projectId,
		children: projectChildren
	} as Partial<IProject>;
	const phaseUpdate = phase as Partial<IGroup>;
	const reorderedPhaseUpdate = {
		_id: reorderedPhaseId
	} as Partial<IGroup>;
	// Phase from journey is moved to another phase
	if (type === 'NESTING') {
		reorderedPhaseUpdate.parentId = phase._id as string;
	} else {
		// Nested phase is moved to journey
		reorderedPhaseUpdate.parentId = projectId;
	}

	const projectApiPayload: TEditProjectThunkArg = {
		payload: {
			_id: projectId,
			update: projectUpdate,
			sourceStageIdsBeforeReorder: prevProject.children as string[]
		},
		prevState: {
			prevProject
		}
	};

	const groupPayload: TBulkUpdateGroupArgs = {
		prevState: { groups: [prevPhase, prevReorderedPhase] },
		data: {
			groups: [
				{
					groupId: phase._id as string,
					updates: phaseUpdate
				},
				{
					groupId: reorderedPhaseId,
					updates: reorderedPhaseUpdate
				}
			]
		}
	};

	const nestingActionPayload: TNestingThunkArgs = {
		payload: { groups: groupPayload.data.groups, project: projectApiPayload.payload },
		prevState: {
			groups: groupPayload.prevState.groups,
			project: projectApiPayload.prevState
		}
	};

	dispatch(nestingAction(nestingActionPayload));
};

/**
 * Function to update multiple groups
 * @param {Array<Partial<IGroup>>} groupsUpdate - Array containing groups updates
 */
export const onGroupsEdit = (groupsUpdate: Array<Partial<IGroup>>) => {
	const reduxGroups = store.getState().stages.data;
	const prevGroups: IGroup[] = [];
	const groupsAndUpdates: TBulkUpdateGroupArgs['data']['groups'] = [];

	const dispatch = store.dispatch as AppDispatch;
	groupsUpdate.forEach((group) => {
		if (group._id) {
			const foundGroup = reduxGroups[group._id.toString()];
			if (foundGroup) {
				prevGroups.push(foundGroup);
			}
			groupsAndUpdates.push({ groupId: group._id.toString(), updates: group });
		}
	});

	const groupsPayload: TBulkUpdateGroupArgs = {
		prevState: { groups: prevGroups },
		data: {
			groups: groupsAndUpdates
		}
	};

	dispatch(bulkUpdateGroups(groupsPayload));

	return { prevGroups };
};

/**
 * Function to update multiple blocks
 * @param {Array<Partial<IBlock>>} blocksUpdate - Array containing blocks updates
 */
export const onBlocksEdit = (blocksUpdate: Array<Partial<IBlock>>) => {
	const reduxBlocks = store.getState().blocks.data;
	const prevBlocks: IBlock[] = [];
	const blocksAndUpdates: TBulkUpdateBlockArgs['data']['blocks'] = [];

	const dispatch = store.dispatch as AppDispatch;

	blocksUpdate.forEach((block) => {
		if (block._id) {
			const foundBlock = reduxBlocks[block._id.toString()];
			if (foundBlock) {
				prevBlocks.push(foundBlock);
			}
			blocksAndUpdates.push({ blockId: block._id.toString(), updates: block });
		}
	});

	const blocksPayload: TBulkUpdateBlockArgs = {
		prevState: { blocks: prevBlocks },
		data: {
			blocks: blocksAndUpdates
		}
	};

	dispatch(bulkUpdateBlocks(blocksPayload));

	return {
		prevBlocks
	};
};

/**
 * Function to create project and add that project in journey as a block
 */
export const createProjectInJourney = (onNext: (id: string) => void) => {
	const reduxState = store.getState();
	const {
		user: { data: userData }
	} = reduxState;
	const { _id: createdBy, defaultProjectGroup: parentGroupId } = userData;

	const pid = new mongoose.Types.ObjectId().toString() as string;
	const payload: TGenerateOptimisticProjectArg = {
		_id: pid,
		createdBy,
		parentGroupId: parentGroupId as string,
		projectType: 'REGULAR',
		name: 'Untitled',
		isAIGenerated: false
	};

	// Generate the optimistic project
	const { optimisticProject } = generateOptimisticProject(payload);
	// Prepare the payload for the API call to create a project
	const apiPayload: TCreateProjectThunkArg = {
		payload: { ...payload, optimisticProject },
		user: userData,
		next: async (id: string) => {
			onNext(id);
		}
	};

	// Dispatch the action to create the project
	(store.dispatch as CustomDispatch)(createProject(apiPayload));
};
