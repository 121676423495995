import { FC, useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import leftSvg from 'src/assets/icons/toolbar/left_align.svg';
import centerSvg from 'src/assets/icons/toolbar/middle_align.svg';
import rightSvg from 'src/assets/icons/toolbar/right_align.svg';
import { alignTypes } from './constants';
import { AlignProps } from './Align.types';
import './Align.scss';

const TextAlign: FC<AlignProps> = ({
  app,
}) => {
  const [alignIndex, setAlignIndex] = useState<number>(-1);
  const [alignSvg, setAlignSvg] = useState(leftSvg);

  const getAlignSvg = (index: number) => {
    if (index === 0 || index === -1) return leftSvg;
    if (index === 1) return centerSvg;
    return rightSvg;
  };

  useEffect(() => {
    const nodeData = app.selectedNodes[0]?.nodeData;
    if (nodeData) {
      const align = nodeData.text?.style?.align;
      if (align) {
        const currIndex = alignTypes.findIndex((at) => at === align);
        setAlignIndex(currIndex);
        setAlignSvg(getAlignSvg(currIndex));
      }
    }
  });

  const handleClick = () => {
    let newAlignIndex;
    if ([-1, 0, 1].includes(alignIndex)) newAlignIndex = alignIndex + 1;
    else newAlignIndex = 0;
    setAlignIndex(newAlignIndex);
    setAlignSvg(getAlignSvg(newAlignIndex));
    // const currentNode = app.selectedNodes && app.selectedNodes[0];
    // if (currentNode) currentNode.edit('ALIGN', alignTypes[newAlignIndex], false);
    const currentNode = app.selectedNodes;
    for (let i = 0; i < currentNode.length; i++) {
      currentNode[i]?.edit('ALIGN', alignTypes[newAlignIndex], !!(currentNode.length > 1 && i !== 0));
    }

    app.saveAllNodes([]);
  };

  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 1000, hide: 0 }}
      overlay={(
        <Tooltip
          id="button-tooltip-2"
          style={{
            fontSize: '12px',
            borderRadius: '2px',
            lineHeight: '16px',
            marginTop: '10px',
          }}
        >
          Text Alignment
        </Tooltip>
      )}
    >
      {({ ref, ...triggerHandler }) => (
        <div className="alignContainer" onClick={handleClick} role="presentation">
          <img
            src={alignSvg}
            className="alignImage"
            ref={ref}
            alt=""
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...triggerHandler}
          />
        </div>
      )}
    </OverlayTrigger>
  );
};
export default TextAlign;
