/* eslint-disable react/no-unused-state */
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import pan from '../../../assets/icons/pan.svg';
import panWhite from '../../../assets/icons/pan-white.svg';
import { ControlProps, ControlState } from './Controls.types';
import './Controls.scss';
import arrow from '../../../assets/icons/arrow.png';

class Controls extends React.Component<ControlProps, ControlState> {
  /**
   * The upward arrow next to zoom percent
   */
  // eslint-disable-next-line react/no-unstable-nested-components
  CustomToggle = React.forwardRef(({ children, onClick }: any, ref: any) => (
    <a
      href="/"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <div style={{ display: 'inline', marginRight: '1rem' }}>
        <img src={arrow} alt="" />
      </div>

      {children}
    </a>
  ));

  constructor(props: any) {
    super(props);
    const { app } = this.props;
    this.state = {
      zoomLevels: [10, 25, 50, 75, 100, 125, 150, 175, 200, 225, 250, 275, 300, 325, 350, 400],
      zoomLevelIndex: 4,
      zoomPercent: app.zoomPercent,
      longPressDuration: 0,
      longPressInterval: null,
      longPress: false,
    };
  }

  selectPan = () => {
    const { app } = this.props;
    if (app.isPanSelected) {
      app.isPanSelected = false;
      app.toggleShowActiveTool(true);
      if (app.activeTool !== 'SELECT') app.pauseDragPlugin();
      app.setActiveTool(app.activeTool);
      app.throughPanTool = false;
    } else {
      app.isPanSelected = true;
      app.resumeDragPlugin();
      app.setActiveTool('PAN');
      app.toggleShowActiveTool(false);
      app.throughPanTool = true;
    }
  };

  render() {
    let mousePressInterval : any = null;
    const { app } = this.props;
    const { longPressDuration, longPressInterval, longPress } = this.state;
    return (
      <div
        id="canvas-controls"
        role="presentation"
        style={{ zIndex: '106' }}
        onClick={(e) => { e.stopPropagation(); }}
        onMouseDown={(e) => { e.stopPropagation(); }}
      >
        <div role="presentation" className={app.isPanSelected ? 'pan active' : 'pan'} onClick={this.selectPan}>
          <img src={app.isPanSelected ? panWhite : pan} alt="pan" style={{ cursor: 'pointer', width: '24px', height: '24px' }} />
        </div>
        <div className="zoom-controls-container">
          {/* for - */}
          <div
            role="presentation"
            className="control"
            onMouseDown={() => {
              mousePressInterval = setInterval(() => {
                this.setState({ longPressDuration: longPressDuration + 1 });
                if (longPressDuration > 50) {
                  this.setState({ longPress: true });
                  if (app.viewport.scale._x - 0.01 > 0.05) {
                    /** Code from center viewport */
                    app.viewport.position.x = (app.viewport.screenWidth - (app.width * app.viewport.scale._x)) / 2;
                    app.viewport.position.y = (app.viewport.screenHeight - (app.height * app.viewport.scale._y)) / 2;

                    app.viewport.setZoom(app.viewport.scale._x - 0.01, true);
                    app.notifyZoom();
                  } else {
                    clearInterval(longPressInterval!);
                  }
                }
              }, 10);
              this.setState({ longPressInterval: mousePressInterval });
            }}
            onMouseUp={() => {
              clearInterval(longPressInterval!);
              this.setState({ longPressDuration: 0 });
              if (!longPress) {
              /** If not a long press, should behave same as onclick */
                if (app.zoomLevelIndex > 0) {
                  app.zoomLevelIndex -= 1;
                  app.viewport.setZoom((app.zoomLevels[app.zoomLevelIndex]!) / 100, true);
                  app.notifyZoom();
                  app.zoomPercent = app.viewport.scale._x;
                }
              } else {
                this.setState({ longPress: false });
              }
            }}
          >
            -
          </div>

          <div style={{ backgroundColor: '#ededed', width: '1px', height: '2rem' }} />

          {/* Zoom Percent */}
          <div style={{ marginLeft: '1rem' }}>
            {Math.round(app.viewport.scale._x * 100)}
            %
          </div>

          <Dropdown>
            <Dropdown.Toggle as={this.CustomToggle} id="dropdown-custom-components" />

            <Dropdown.Menu style={{ marginBottom: '1.5rem', fontSize: '14px', right: '3rem' }}>
              <Dropdown.Item eventKey="2" onClick={() => app.resizeToFit()}>
                Fit to screen
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {/* </div> */}
          <div style={{ backgroundColor: '#ededed', width: '1px', height: '2rem' }} />

          {/* For + */}
          <div
            className="control"
            role="presentation"
            onMouseDown={() => {
              mousePressInterval = setInterval(() => {
                this.setState({ longPressDuration: longPressDuration + 1 });
                if (longPressDuration > 50) {
                  this.setState({ longPress: true });
                  if (app.viewport.scale._x + 0.01 < 4) {
                    /** Code from center viewport */
                    app.viewport.position.x = (app.viewport.screenWidth - (app.width * app.viewport.scale._x)) / 2;
                    app.viewport.position.y = (app.viewport.screenHeight - (app.height * app.viewport.scale._y)) / 2;

                    app.viewport.setZoom(app.viewport.scale._x + 0.01, true);
                    app.notifyZoom();
                  } else {
                    clearInterval(longPressInterval!);
                  }
                }
              }, 10);
              this.setState({ longPressInterval: mousePressInterval });
            }}
            onMouseUp={() => {
              clearInterval(longPressInterval!);
              this.setState({ longPressDuration: 0 });
              if (!longPress) {
              /** If not a long press, should behave same as onclick */
                if (app.zoomLevelIndex < 16) {
                  app.zoomLevelIndex += 1;
                  app.viewport.setZoom((app.zoomLevels[app.zoomLevelIndex]!) / 100, true);
                  app.zoomPercent = app.viewport.scale._x;
                  app.notifyZoom();
                }
              } else {
                this.setState({ longPress: false });
              }
            }}
          >
            +
          </div>
        </div>
      </div>
    );
  }
}

export default Controls;
