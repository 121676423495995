import { useContext } from 'react';
import './UnauthorizedOnline.scss';
// import LoginModal from "../loginModal/LoginModal"
import { AuthContext } from 'src/components/auth/AuthProvider';
// import { ScheduleDemo } from "../navbar/scheduleDemo/ScheduleDemo"
// import { AuthComponent } from "src/components/authComponent/AuthComponent"
import { useHistory } from 'react-router';
import AppLoader from '../appLoader/AppLoader';

export type UnauthorizedOnlineProps = {
	progress?: number | undefined;
};

const UnauthorizedOnline = ({ progress }: UnauthorizedOnlineProps) => {
	// const [ show, setShow ] = useState(false)
	const history = useHistory();
	// const [showScheduleDemoModal, setShowScheduleDemoModal] = useState(false)
	const auth = useContext(AuthContext);
	return (
		<div id="Unauthorized-online">
			{!auth.completedAuthCheck || auth.status === 'LOADING' ? (
				<AppLoader progress={progress} />
			) : (
				auth.completedAuthCheck && (
					<>
						<p className="warning">You need to be logged in to access this page.</p>
						<button
							type="button"
							className="chat-bubble_text chat-answer"
							onClick={() => {
								history.push('/login');
							}}
						>
							Log In
						</button>
					</>
				)
			)}
			{/* <AuthComponent show={show} close={() => setShow(false)} view={'LOGIN'} redirectTo={window.location.pathname} /> */}
		</div>
	);
};

UnauthorizedOnline.defaultProps = {
	progress: 0
};

export default UnauthorizedOnline;
