import { Button, IntegrationModal } from '@naya_studio/radix-ui';
import { setShowConfirmationModal, setShowSyncStorageModal } from 'src/redux/features/integration';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ReduxState, TSyncStorage } from 'src/redux/reducers/root.types';
import { generateIdsFromUrl } from 'src/redux/reduxActions/util';
import useProject from 'src/redux/hooks/useProject';
import useSync from 'src/util/sync/useSync';
import { getProjectSyncObserver } from 'src/util/helper/project';
import { Modal } from 'react-bootstrap';
import { ReactComponent as CloseModal } from 'src/assets/icons/close.svg';
import './SyncStorageModal.scss';
import moment from 'moment';
import { TFlags } from '@naya_studio/types';
import { syncStorageOptions } from '../utilities/navbar/utils';

const SyncStorageModal = ({ flags }: { flags: TFlags }) => {
	const dispatch = useDispatch();
	const { projectId } = generateIdsFromUrl();
	const { project } = useProject(projectId);
	const { toggleSync } = useSync();
	const { isSyncDriveEnabled } = flags;

	// extracting google drive observer for the project
	const googleObserver = getProjectSyncObserver(projectId, 'GOOGLE_DRIVE');
	const googleFolderId = googleObserver?.folderId;

	// storage will be considered synced if it has a channel watching over project folder
	const isGoogleSynced = !!googleObserver?.channelDetails?.channelId;

	// tracking sync storage modals
	const { isSyncStorageModalOpen, isConfirmationModelOpen } = useSelector(
		(state) => (state as ReduxState).integrations,
		shallowEqual
	);

	// Function to handle connecting or revoking access to apps
	const onConnectOrRemove = (active: boolean | undefined, name: string) => {
		toggleSync(name as TSyncStorage, active || false);
	};
	// List of storage apps we support
	const appOptions = [
		{
			name: 'GOOGLE_DRIVE',
			title: 'Google Drive',
			icon: syncStorageOptions.GOOGLE_DRIVE as string,
			isActive: isSyncDriveEnabled,
			isConnected: isGoogleSynced
		},
		{
			name: 'DROPBOX',
			title: 'Dropbox',
			icon: syncStorageOptions.DROPBOX as string
		},
		{
			name: 'ICOUD',
			title: 'iCloud',
			icon: syncStorageOptions.ICLOUD as string
		},
		{
			name: 'SHARE_POINT',
			title: 'SharePoint',
			icon: syncStorageOptions.SHARE_POINT as string
		},
		{
			name: 'SLACK',
			title: 'Slack',
			icon: syncStorageOptions.SLACK as string
		},
		{
			name: 'LOCAL',
			title: 'Local',
			icon: syncStorageOptions.LOCAL as string
		}
	];

	return (
		<>
			<IntegrationModal
				title={
					!isGoogleSynced
						? `Sync “${project?.projectDetails?.name}” with drive`
						: 'Storage sync'
				}
				description={
					!isGoogleSynced ? (
						'Connect this project with a drive to sync files, create backups, and more.'
					) : (
						<div className="tw-font-normal">
							<span className="tw-font-bold">{project?.projectDetails?.name}</span>{' '}
							Last synced to Google Drive{' '}
							{moment(googleObserver.lastSynced).fromNow()}
						</div>
					)
				}
				onClose={() => dispatch(setShowSyncStorageModal(false))}
				onConnectOrRemove={onConnectOrRemove}
				show={isSyncStorageModalOpen}
				appOptions={appOptions}
				actionButton={
					isGoogleSynced ? (
						<a
							className="drive-link tw-font-randMedium tw-font-medium tw-text-xs tw-flex tw-gap-2 tw-items-center"
							href={`https://drive.google.com/drive/folders/${googleFolderId}`}
							target="_blank"
							rel="noreferrer"
						>
							Show in drive{' '}
							<img
								src={
									'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/integration-modal-link.svg' +
									'?alt=media&token=f42487d7-7b68-4dcb-ad67-549979e036cd'
								}
								alt=""
							/>
						</a>
					) : (
						<div />
					)
				}
			/>
			<Modal show={isConfirmationModelOpen} dialogClassName="integration-confirmation-modal">
				<Modal.Body className="tw-p-4">
					<div className="tw-flex tw-justify-between tw-mb-4">
						<div className="tw-font-bold tw-text-lg">Disconnect project sync?</div>
						<CloseModal
							className="tw-cursor-pointer"
							onClick={() => {
								dispatch(setShowSyncStorageModal(true));
								dispatch(setShowConfirmationModal(false));
							}}
						/>
					</div>

					<div className="tw-mb-6 tw-font-normal tw-text-xs">
						Are you sure you want to disconnect{' '}
						<span className="tw-font-bold">{project?.projectDetails?.name}</span> from
						Google Drive? Future changes to the project will not be reflected in the
						drive folder.
					</div>
					<div className="tw-flex tw-justify-end tw-gap-2">
						<Button
							disabled={false}
							onBtnClick={() => {
								dispatch(setShowSyncStorageModal(true));
								dispatch(setShowConfirmationModal(false));
							}}
							text="Cancel"
							variant="SECONDARY-GREY"
							className="text-editor-button cancel tw-bg-white"
						/>
						<Button
							disabled={false}
							onBtnClick={() => {
								toggleSync('GOOGLE_DRIVE', isGoogleSynced, true);
								dispatch(setShowSyncStorageModal(true));
								dispatch(setShowConfirmationModal(false));
							}}
							text="Disconnect"
							variant="PRIMARY"
						/>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default SyncStorageModal;
