import {
	EIntegrationType,
	TIntegrationDetails,
	TIntegrations
} from 'src/redux/reducers/root.types';
import { store } from 'src';
import { getFileLinkSubType } from 'src/components/utilities/navbar/utils';
import { TCreateIntegrationFile, TGoogleSubtype } from 'src/util/integration/useIntegration';
import { renameMiroBoard, duplicateMiroBoard, createMiroBoard } from './miroIntegrations';
import { createGoogleFile, duplicateGoogleFile, renameGoogleFile } from './googleIntegrations';

export const NAYA_FOLDER_NAME = 'Naya Studio Sync';

// integration popup options
const popupHeight = 450;
const popupWidth = 376;
const popupOptions = `height=${popupHeight},width=${popupWidth},left=${
	(window.innerWidth - popupWidth) / 2
},top=${(window.innerHeight - popupHeight) / 2}`;
export const authenticationModalConfig = popupOptions;

export const integrationSubTypeValue: { [key in TGoogleSubtype]: string } = {
	DOC: 'document',
	SHEET: 'spreedsheet',
	SLIDE: 'presentation'
};

/**
 * Function to get access token based on integration type
 * @param integrationType
 * @returns
 */
export const getIntegrationBasedToken = (integrationType: TIntegrations) => {
	const { miro, google } = store.getState().integrations;

	switch (integrationType) {
		case 'MIRO':
			return miro.access_token;
		case 'GOOGLE':
			return google.access_token;
		default:
			return '';
	}
};
export const createIntegrationFile = async (
	{ integrationType, subType, projectId, folderId, fileName }: TCreateIntegrationFile,
	access_token: string
) => {
	try {
		let createdFileLink;

		// create new file based on integration type
		switch (integrationType) {
			case 'GOOGLE':
				createdFileLink = await createGoogleFile(
					subType as TGoogleSubtype,
					access_token,
					folderId as string,
					fileName || `Untitled ${integrationSubTypeValue[subType as TGoogleSubtype]}`,
					projectId as string
				);
				break;
			case 'MIRO':
				createdFileLink = await createMiroBoard(access_token, 'Untitled board');
				break;
			default:
				break;
		}
		return createdFileLink;
	} catch (e) {
		console.error('createIntegrationFile error: ', e);
		return { status: false, link: '', id: '', error: '' };
	}
};
/**
 * Function rename link asset
 * @param link
 * @param name
 */
export const renameLinkAsset = (link: string, name: string) => {
	const linkType = getFileLinkSubType(link, 'LINK');
	switch (linkType) {
		case 'MIRO':
			renameMiroBoard(getIntegrationBasedToken('MIRO'), link, name);
			break;
		case 'GOOGLE_DRIVE':
		case 'GOOGLE_SLIDES':
		case 'GOOGLE_SHEETS':
		case 'GOOGLE_DOCS':
			renameGoogleFile(getIntegrationBasedToken('GOOGLE'), link, name);
			break;
		default:
	}
};

/**
 * Function to duplicate link asset
 * @param link - link of the asset to be duplicated
 * @param name - name of the duplicated asset
 * @param folderId - id of the folder to store the duplicated file
 * @returns - link of the duplicated asset
 */
export const duplicateLinkAsset = async (link: string, name: string, folderId: string) => {
	const linkType = getFileLinkSubType(link, 'LINK');
	switch (linkType) {
		case 'MIRO':
			return duplicateMiroBoard(getIntegrationBasedToken('MIRO'), link, name);
		case 'GOOGLE_DRIVE':
		case 'GOOGLE_SLIDES':
		case 'GOOGLE_SHEETS':
		case 'GOOGLE_DOCS': {
			if (!folderId) return { status: false, link: '', id: '' };
			return duplicateGoogleFile(getIntegrationBasedToken('GOOGLE'), folderId, link, name);
		}
		default:
			return { status: false, link: '', id: '' };
	}
};

/**
 * Function to save integration access token details in local storage
 * @param integrationType - type of integration
 * @param tokenData - access token details
 * @returns - true on saving data
 */
export const saveIntegrationAuthInLocalStrorage = (
	integrationType: TIntegrations,
	tokenData: TIntegrationDetails
) => {
	// encrypt and save token data
	localStorage.setItem(
		`${EIntegrationType[integrationType]}_integration`,
		window.btoa(JSON.stringify(tokenData))
	);
	return true;
};

/**
 * Function to get integration access token details in local storage
 * @param integrationType - type of integration
 * @returns - access token details based on integration type
 */
export const getIntegrationAuthFromLocalStrorage = (integrationType: TIntegrations) => {
	// decrypt and return token data
	if (localStorage.getItem(`${EIntegrationType[integrationType]}_integration`))
		return JSON.parse(
			window.atob(
				localStorage.getItem(`${EIntegrationType[integrationType]}_integration`) as string
			)
		);
	return null;
};
