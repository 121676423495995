import React, { Dispatch } from 'react';
import { Rectangle } from 'pixi.js';
import { ENodeType, IBlock, INodeAdditionalPropertiesReact } from '@naya_studio/types';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import Align from 'src/features/align/components/Align';
import { generateIdsFromUrl } from 'src/redux/reduxActions/util';
import { TEditNodesArgs } from 'src/types/argTypes';
import { editNodes } from 'src/redux/reduxActions/node';
import { ReduxState } from 'src/redux/reducers/root.types';
import getUserFromRedux from 'src/util/helper/user';
import Order from './MenuOptions/Order/Order';
import Stroke from './MenuOptions/Stroke/Stroke';
import Colors from './MenuOptions/Colors/Colors';
import Download from './MenuOptions/Download/Download';
import Crop from './MenuOptions/Crop/Crop';
import RemoveBg from './MenuOptions/RemoveBg/RemoveBg';
import Delete from './MenuOptions/Delete/Delete';
import FontSize from './MenuOptions/FontSize/FontSize';
import Info from './MenuOptions/Info/Info';
import Rotate from './MenuOptions/Rotate/Rotate';
import Lock from './MenuOptions/Lock/Lock';
import Author from './MenuOptions/Author/Author';
import Text from '../app/nodes/Text';
import './EditMenu.scss';
import View from './MenuOptions/View/View';
import Snapshot from './MenuOptions/Snapshot/Snapshot';
import OrderPrint from './MenuOptions/OrderPrint/OrderPrint';
import Appearance from './MenuOptions/Appearance/Appearance';
import {
	EditMenuDispatchProps,
	EditMenuProps,
	MenuOptions,
	PathParamsType,
	EditMenuState
} from './EditMenu.types';
import TextAlign from './MenuOptions/Align/Align';
import List from './MenuOptions/List/List';
import FontFormat from './MenuOptions/FontFormat/FontFormat';
import Link from './MenuOptions/Link/Link';
import Font from './MenuOptions/Font/Font';
import Opacity from './MenuOptions/Opacity/Opacity';

const menuOptions: MenuOptions = {
	SHAPE: ['STROKE', 'FILL', 'LOCK'],
	MARKUP: ['STROKE', 'LOCK'],
	REACTION: ['LOCK'],
	MODEL: ['APPEARANCE', 'VIEW', 'SNAPSHOT', 'AUTHOR', '3DPRINT', 'LOCK'],
	IMAGE: ['CROP', 'OPACITY', 'REMOVE_BG', 'AUTHOR', 'LOCK'],
	FILE_PLACEHOLDER: ['AUTHOR', 'LOCK'],
	ONBOARDING_IMAGE: ['LOCK'],
	SAMPLE_IMAGE: ['LOCK'],
	TEXT: ['FILL', 'FONT', 'FONT_SIZE', 'FONT_FORMAT', 'LIST', 'TEXT_ALIGN', 'LINK', 'LOCK'],
	STICKY_NOTE: [
		'FILL',
		'FONT',
		'FONT_SIZE',
		'FONT_FORMAT',
		'LIST',
		'TEXT_ALIGN',
		'LINK',
		'AUTHOR',
		'LOCK'
	],
	STICKY_NOTE_EDIT: ['FONT', 'FONT_SIZE', 'FONT_FORMAT', 'LIST', 'TEXT_ALIGN', 'LINK', 'AUTHOR']
};

class EditMenu extends React.Component<
	EditMenuProps & EditMenuDispatchProps & RouteComponentProps<PathParamsType>,
	EditMenuState
> {
	container: HTMLDivElement | null = null;

	constructor(
		props: EditMenuProps & EditMenuDispatchProps & RouteComponentProps<PathParamsType>
	) {
		super(props);
		this.container = null;

		this.state = {
			selectedOption: '',
			width: undefined,
			height: undefined
		};
	}

	componentDidMount() {
		this.setState({
			width: this.container?.offsetWidth,
			height: this.container?.offsetHeight
		});
	}

	componentDidUpdate() {
		const { width, height } = this.state;
		if (width !== this.container?.offsetWidth || height !== this.container?.offsetHeight) {
			this.setState({
				width: this.container?.offsetWidth,
				height: this.container?.offsetHeight
			});
		}
	}

	/**
	 * sets the option to be selected
	 * @param {string} option
	 */
	setSelectedOption = (option: string) => {
		this.setState({ selectedOption: option });
	};

	/**
	 *
	 * @param type
	 * @returns edit menu option's component
	 */
	getEditMenuActionableItems = (type: string) => {
		const { selectedOption } = this.state;
		const { app, activeTextProperties, nodes } = this.props;

		switch (type) {
			case 'AUTHOR':
				return <Author app={app} />;

			case 'STROKE':
				return (
					<Stroke
						app={app}
						setSelectedOption={this.setSelectedOption}
						selectedOption={selectedOption}
						parentContainer={this.container}
						nodes={nodes}
					/>
				);
			case 'DOWNLOAD':
				return (
					<Download
						app={app}
						setSelectedOption={this.setSelectedOption}
						selectedOption={selectedOption}
					/>
				);

			case 'FILL':
				return (
					<Colors
						app={app}
						setSelectedOption={this.setSelectedOption}
						selectedOption={selectedOption}
						parentContainer={this.container}
						activeTextProperties={activeTextProperties}
					/>
				);

			case 'CROP':
				return (
					<Crop
						app={app}
						setSelectedOption={this.setSelectedOption}
						selectedOption={selectedOption}
					/>
				);

			case 'REMOVE_BG':
				return (
					<RemoveBg
						app={app}
						setSelectedOption={this.setSelectedOption}
						selectedOption={selectedOption}
					/>
				);

			case 'OPACITY':
				return (
					<Opacity
						app={app}
						setSelectedOption={this.setSelectedOption}
						selectedOption={selectedOption}
						isStroke={false}
						isFill={false}
					/>
				);

			case 'ZINDEX':
				return (
					<Order
						app={app}
						parentContainer={this.container}
						setSelectedOption={this.setSelectedOption}
						selectedOption={selectedOption}
					/>
				);

			case 'INFO':
				return (
					<Info
						app={app}
						setSelectedOption={this.setSelectedOption}
						selectedOption={selectedOption}
					/>
				);

			case 'DELETE':
				return (
					<Delete
						app={app}
						setSelectedOption={this.setSelectedOption}
						selectedOption={selectedOption}
					/>
				);

			case 'ROTATE':
				return (
					<Rotate
						app={app}
						setSelectedOption={this.setSelectedOption}
						selectedOption={selectedOption}
					/>
				);

			case 'TEXT_ALIGN':
				return <TextAlign app={app} />;

			case 'LIST':
				return <List app={app} />;

			case 'FONT_FORMAT':
				return (
					<FontFormat
						app={app}
						activeTextProperties={activeTextProperties}
						nodes={nodes}
					/>
				);

			case 'FONT_SIZE':
				return (
					<FontSize
						app={app}
						// textData={textData}
						setSelectedOption={this.setSelectedOption}
						selectedOption={selectedOption}
						parentContainer={this.container}
						activeTextProperties={activeTextProperties}
						nodes={nodes}
					/>
				);

			case 'LOCK':
				return <Lock app={app} nodes={nodes} />;

			case 'LINK':
				// eslint-disable-next-line jsx-a11y/anchor-is-valid
				return <Link app={app} />;

			case 'FONT':
				return (
					<Font
						app={app}
						setSelectedOption={this.setSelectedOption}
						selectedOption={selectedOption}
						activeTextProperties={activeTextProperties}
						nodes={nodes}
					/>
				);

			case 'VIEW':
				return (
					<View
						app={app}
						setSelectedOption={this.setSelectedOption}
						selectedOption={selectedOption}
					/>
				);

			case 'SNAPSHOT':
				return <Snapshot app={app} />;

			case '3DPRINT':
				if (app.selectedNodes.length === 1) return <OrderPrint app={app} />;
				return null;
			case 'APPEARANCE':
				return (
					<Appearance
						app={app}
						setSelectedOption={this.setSelectedOption}
						selectedOption={selectedOption}
					/>
				);
			case 'ALIGN':
				return <Align app={app} />;
			default:
				return null;
		}
	};

	/**
	 *
	 * @returns all the menu options relevant to the selected nodes
	 */
	getFinalDisplayOptions = () => {
		const { app, userLoggedIn } = this.props;
		// const {  textNodeData } = textData;
		let finalDisplayOptions: string[] = [];
		const menu = menuOptions;
		const allOptions: string[][] = [];
		const allNodes = app.selectedNodes;
		// If in edit mode for texts or sticky notes, do not show lock option
		if (app.editText) {
			finalDisplayOptions = menu.TEXT as string[]; // TODO - fix
			finalDisplayOptions = finalDisplayOptions.filter((x) => x !== 'LOCK');
		} else {
			// Getting edit options for all the selected nodes
			for (let i = 0; i < allNodes.length; i++) {
				const node = app.selectedNodes[i];
				const nodeType = app.selectedNodes[i]?.nodeData.nodeType as ENodeType;
				let options: string[] = [];
				if (nodeType === 'SHAPE') {
					const shapeType = allNodes[i]?.nodeData.shapeType;
					if (['SOLID_LINE', 'ARROW', 'DASHED_LINE'].includes(shapeType!)) {
						options = menu.MARKUP!;
					} else {
						options = menu[nodeType]!;
					}
				} else if (nodeType === ENodeType.STICKY_NOTE) {
					if (node?._editMode) {
						options = menu.STICKY_NOTE_EDIT!;
					} else {
						options = menu[nodeType]!;
					}
				} else {
					options = menu[nodeType]!;
				}

				let finalOptions: string[] = [];
				// If node type is image, and if additional properties
				// exisxt for that image, only then display INFO option
				if (nodeType === 'IMAGE' && allNodes[i]?.nodeData.additionalProperties) {
					const nodeProperties = allNodes[i]?.nodeData
						.additionalProperties as INodeAdditionalPropertiesReact;
					if (
						nodeProperties &&
						nodeProperties.imageCredits &&
						!options?.includes('INFO')
					) {
						finalOptions = options.splice(3, 0, 'INFO');
					} else {
						finalOptions = options!;
					}
				} else {
					finalOptions = options!;
				}
				// Filtering and keeping only the edit options that are common to all the selected nodes
				allOptions.push(finalOptions);
				if (allOptions) {
					finalDisplayOptions = allOptions.reduce((a, b) =>
						a.filter((c) => b.includes(c))
					);
				}
			}
		}
		// Keep options INFO, CROP, & REMOVE_BG only for single select
		if (app.selectedNodes.length > 1) {
			if (finalDisplayOptions.includes('INFO')) {
				finalDisplayOptions = finalDisplayOptions.filter((x) => x !== 'INFO');
			}
			if (finalDisplayOptions.includes('CROP')) {
				finalDisplayOptions = finalDisplayOptions.filter((x) => x !== 'CROP');
			}
			if (finalDisplayOptions.includes('REMOVE_BG')) {
				finalDisplayOptions = finalDisplayOptions.filter((x) => x !== 'REMOVE_BG');
			}
			if (!finalDisplayOptions.includes('ALIGN')) {
				// check if all nodes are of same type
				const { nodeType } = app.selectedNodes[0]!.nodeData;
				const allNodesSameType = app.selectedNodes.every(
					(node) => node.nodeData.nodeType === nodeType
				);
				if (allNodesSameType) {
					switch (nodeType) {
						case ENodeType.IMAGE:
							// Insert ALIGN option before REMOVE_BG
							finalDisplayOptions.splice(
								finalDisplayOptions.indexOf('AUTHOR'),
								0,
								'ALIGN'
							);
							break;
						case ENodeType.TEXT:
						case ENodeType.SHAPE:
							// eslint-disable-next-line no-lone-blocks
							{
								// Insert ALIGN option befor LOCK option
								const index = finalDisplayOptions.indexOf('LOCK');
								finalDisplayOptions.splice(index, 0, 'ALIGN');
							}
							break;
						case ENodeType.MODEL:
						case ENodeType.STICKY_NOTE:
							// Insert ALIGN option befor AUTHOR option
							finalDisplayOptions.splice(
								finalDisplayOptions.indexOf('AUTHOR'),
								0,
								'ALIGN'
							);
							break;
						default:
							// eslint-disable-next-line no-lone-blocks
							{
								// Insert ALIGN option befor LOCK option
								const index = finalDisplayOptions.indexOf('LOCK');
								finalDisplayOptions.splice(index, 0, 'ALIGN');
							}
							break;
					}
				}
			}
		} else if (app.selectedNodes.length === 1 && !finalDisplayOptions.includes('ALIGN')) {
			// Insert ALIGN option befor LOCK option
			const index = finalDisplayOptions.indexOf('LOCK');
			finalDisplayOptions.splice(index, 0, 'ALIGN');
		}

		// If any node is locked, display only LOCK option
		if (this.checkIfAnyNodeIsLocked()) {
			finalDisplayOptions = ['LOCK'];
			// If all the selected nodes are images, show DOWNLOAD option too
			if (this.checkIfAllSelectedNodesAreImages()) {
				finalDisplayOptions.push('DOWNLOAD');
			}
		}

		// If the selected nodes includes sticky notes and rest
		// of the nodes aren't sticky notes - do npt show FILL option
		// Reason - fill color options are different for sticky notes
		if (
			(this.checkIfSpecificNodeTypeIsSelected('SHAPE' as ENodeType) &&
				this.checkIfSpecificNodeTypeIsSelected('STICKY_NOTE' as ENodeType)) ||
			(this.checkIfSpecificNodeTypeIsSelected('TEXT' as ENodeType) &&
				this.checkIfSpecificNodeTypeIsSelected('STICKY_NOTE' as ENodeType))
		) {
			finalDisplayOptions = finalDisplayOptions.filter((x) => x !== 'FILL');
		}

		if (!userLoggedIn) {
			finalDisplayOptions = finalDisplayOptions.filter((option) => option !== 'AUTHOR');
		}
		return finalDisplayOptions;
	};

	/**
	 * Checks if a specific node type is selected or noe
	 * @param nodeType
	 * @returns {boolean}
	 */
	checkIfSpecificNodeTypeIsSelected = (nodeType: ENodeType) => {
		const { app } = this.props;
		const allNodes = app.selectedNodes;
		for (let i = 0; i < allNodes.length; i++) {
			if (allNodes[i]?.nodeData.nodeType === nodeType) {
				return true;
			}
		}
		return false;
	};

	/**
	 * Checks if any node (from all the selected nodes) is locked
	 * @returns {boolean}
	 */
	checkIfAnyNodeIsLocked = () => {
		const { app } = this.props;
		const allNodes = app.selectedNodes;
		for (let i = 0; i < allNodes.length; i++) {
			if (allNodes[i]?._isLocked) {
				return true;
			}
		}
		return false;
	};

	/**
	 * Checks if all the selected nodes are images
	 * @returns {voolean}
	 */
	checkIfAllSelectedNodesAreImages = () => {
		const { app } = this.props;
		const allNodes = app.selectedNodes;
		for (let i = 0; i < allNodes.length; i++) {
			if (allNodes[i]?.nodeData.nodeType !== 'IMAGE') {
				return false;
			}
		}
		return true;
	};

	/**
	 *
	 * @returns main edit options
	 */
	renderMainOptions = () => (
		<div className={this.getFinalDisplayOptions()?.length > 0 ? 'main-menu-container' : ''}>
			{this.getFinalDisplayOptions()?.map((option: string, index: number) => (
				<div key={option} style={{ display: 'flex' }}>
					{this.getEditMenuActionableItems(option)}
					{index !== this.getFinalDisplayOptions()!.length - 1 && (
						<div className="divider" />
					)}
				</div>
			))}
		</div>
	);

	/**
	 *
	 * @returns values reuired to determine the positioning of the edit menu
	 */
	getEditMenuPositionValues = () => {
		const { app } = this.props;
		const allNodes = app.selectedNodes;
		let minX = 0;
		let minY = 0;
		let maxX = 0;
		let maxY = 0;
		if (allNodes.length > 0) {
			const firstNodeBounds = allNodes[0]?.displayObject?.getBounds() as Rectangle;
			if (firstNodeBounds) {
				minX = firstNodeBounds.x;
				minY = firstNodeBounds.y;
			}
			for (let i = 0; i < allNodes.length; i++) {
				const bounds = allNodes[i]?.displayObject?.getBounds() as Rectangle;
				if (bounds) {
					if (bounds.x < minX) {
						minX = bounds.x;
					}
					if (bounds.y < minY) {
						minY = bounds.y;
					}
					if (bounds.x + bounds.width > maxX) {
						maxX = bounds.x + bounds.width;
					}
					if (bounds.y + bounds.height > maxY) {
						maxY = bounds.y + bounds.height;
					}
				}
			}
		}
		const data = {
			x: minX,
			y: minY,
			width: maxX - minX,
			height: maxY - minY
		};
		return data;
	};

	render() {
		const { app } = this.props;
		const { width: stateWidth, height: stateHeight } = this.state;
		// used for text node due to the fact that they don't get 'selected' in pixi app when editing
		// const {
		//   // showTextEdit,
		//   textNodeData,
		//   // inputDimensions
		// } = this.props.textData;

		// if using text input for text node
		let btnBottomY = 0;
		let btnTopY = 0;
		let btnHorizontalBtn1Y = 0;
		let btnVertX = 0;
		let btnHorizontalLeftX = 0;
		let btnHorizontalRightX = 0;
		let objectBounds = this.getEditMenuPositionValues();
		if (app.selectedNodes.length === 1 && app.selectedNodes[0]?._editMode) {
			if ((app.selectedNodes[0] as Text)._textInputNode) {
				const { x, y, width, height } = (
					app.selectedNodes[0] as Text
				)._textInputNode!.getDOMPosition()!;
				objectBounds = {
					x,
					y,
					width,
					height
				};
			}
		}

		const { x: objectX, y: objectY, width: objectWidth, height: objectHeight } = objectBounds;
		let editMenuPosX = objectX + objectWidth / 2 - (stateWidth || 0) / 2;
		btnHorizontalRightX = objectX + objectWidth + 5;
		btnHorizontalLeftX = objectX - 28;
		btnVertX = objectX + objectWidth / 2 + 18;
		if (editMenuPosX < 20) {
			editMenuPosX = 20;
			// btnHorizontalRightX=window.innerWidth- 20 - (stateWidth || 0)+40;
		}
		if (editMenuPosX + (stateWidth || 0) > window.innerWidth - 20) {
			editMenuPosX = window.innerWidth - 20 - (stateWidth || 0);
		}
		let editMenuPosY;
		const nodeType = app.selectedNodes[0]?._defaultTransformerType;
		if (nodeType === 'sticky' || nodeType === 'crop' || nodeType === 'default') {
			editMenuPosY = objectY - 75;
		} else if (nodeType === 'text') editMenuPosY = objectY;
		else editMenuPosY = objectY - 100;
		app.editMenuPosition = 'top';
		btnHorizontalBtn1Y = objectY + objectHeight / 2 - 44;
		btnBottomY = objectY + objectHeight + 5;
		btnTopY = objectY - (stateHeight || 0) - 55;
		if (editMenuPosY < 75) {
			app.editMenuPosition = 'bottom';
			editMenuPosY = objectY + objectHeight + 20;
			btnTopY = objectY - 30;
			btnBottomY = objectY + objectHeight + 75;
		}
		if (objectHeight > window.innerHeight - 120) {
			app.editMenuPosition = 'bottom';
			editMenuPosY = objectHeight - 20;
		}

		switch (app.btnPos) {
			case 'BOTTOM':
				app.btn1Y = btnBottomY;
				app.btn1X = btnVertX - 56;
				app.btn2Y = btnBottomY;
				app.btn2X = btnVertX;
				break;
			case 'TOP':
				app.btn1Y = btnTopY;
				app.btn1X = btnVertX - 56;
				app.btn2Y = btnTopY;
				app.btn2X = btnVertX;
				break;
			case 'LEFT':
				app.btn1Y = btnHorizontalBtn1Y;
				app.btn1X = btnHorizontalLeftX;
				app.btn2Y = btnHorizontalBtn1Y + 68;
				app.btn2X = btnHorizontalLeftX;
				break;
			case 'RIGHT':
				app.btn1Y = btnHorizontalBtn1Y;
				app.btn1X = btnHorizontalRightX;
				app.btn2Y = btnHorizontalBtn1Y + 68;
				app.btn2X = btnHorizontalRightX;

				break;
			default:
				break;
		}
		return (
			<div
				id="edit-menu"
				role="presentation"
				style={{
					// left: editMenuPosX,
					// top: editMenuPosY,
					transform: `translate3d(${editMenuPosX}px, ${editMenuPosY}px, 0)`
				}}
				ref={(el) => {
					this.container = el;
				}}
				onClick={(e) => {
					e.stopPropagation();
				}}
				onMouseDown={(e) => {
					e.stopPropagation();
				}}
			>
				{this.renderMainOptions()}
			</div>
		);
	}
}

/**
 * Redux state mapped to canvas props
 * @param state
 * @returns
 */
const mapStateToProps = (state: ReduxState) => {
	const { blockId } = generateIdsFromUrl();
	const block = state.blocks.data[blockId] as IBlock;

	return {
		block,
		theme: state.theme,
		userLoggedIn: !!(getUserFromRedux()._id && getUserFromRedux().email)
	};
};

/**
 * Redux actions mapped to canvas props
 * @param dispatch
 * @returns
 */
const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
	editNodes: (payload: TEditNodesArgs) => dispatch(editNodes(payload))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditMenu));
