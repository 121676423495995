import { IFeedback, INode } from '@naya_studio/types';
import _ from 'lodash';
import { store } from 'src';
import { EActionType, TActionItem } from '../reducers/undoRedo/undoActionHistory.types';
import { UndoRedoActionTypes } from '../reducers/root.types';

export const undoAction = (actionType:EActionType, prevNodeData: any, newNodeData: any, appendToPrevious:boolean) => {
  const data:TActionItem = {
    changeType: actionType,
    newNodeData: _.cloneDeep(newNodeData),
    prevNodeData: _.cloneDeep(prevNodeData),
  };

  const prevUndos:any = store.getState().undoActions as Array<Array<TActionItem>>;
  if (!prevUndos) {
    prevUndos[0] = [data as TActionItem];
  } else if (prevUndos && appendToPrevious) {
    const lastData = prevUndos.pop() as Array<TActionItem>;
    lastData.push(data as TActionItem);
    prevUndos[prevUndos.length] = lastData;
  } else {
    prevUndos.push([data]);
  }

  store.dispatch({
    type: UndoRedoActionTypes.ADD_ACTION,
    payload: prevUndos,
  });

  if (['NEW_NODE', 'NAVIGATION'].includes(actionType)) {
    store.dispatch({
      type: UndoRedoActionTypes.EMPTY_REDO_ACTIONS,
    });
  }
};

export const addBatchUndoActions = (
  actionType: EActionType, 
  data: Array<{ 'prevNodeData': INode | IFeedback, 'newNodeData': INode | IFeedback }>,
  ) => {
  const actionData = data.map((nodeData: any) => {
    return {
      changeType: actionType,
      newNodeData: _.cloneDeep(nodeData.newNodeData),
      prevNodeData: _.cloneDeep(nodeData.prevNodeData),
    } as TActionItem;
  }) as Array<TActionItem>;

  if(actionData.length){
    const prevUndos: any = store.getState().undoActions as Array<Array<TActionItem>>;
    prevUndos.push(actionData);
  
    store.dispatch({
      type: UndoRedoActionTypes.ADD_ACTION,
      payload: prevUndos,
    });
  }
};

export const addActionToRedo = (
  actionType:EActionType,
  prevNodeData:INode | IFeedback,
  newNodeData:INode | IFeedback,
  appendToPrevious:boolean,
) => {
  const data: TActionItem = {
    changeType: actionType,
    prevNodeData: _.cloneDeep(prevNodeData),
    newNodeData: _.cloneDeep(newNodeData),
  };
  const prevRedos:any = store.getState().redoActions as Array<Array<TActionItem>>;
  if (!prevRedos) {
    prevRedos[0] = [data as TActionItem];
  } else if (prevRedos && appendToPrevious) {
    const lastData = prevRedos.pop() as Array<TActionItem>;
    lastData.push(data as TActionItem);
    prevRedos[prevRedos.length] = lastData;
  } else {
    prevRedos.push([data]);
  }
  store.dispatch({
    type: UndoRedoActionTypes.ADD_REDO_ACTION,
    payload: prevRedos,
  });
};


export const addBatchRedoActions = (
  actionType: EActionType,
  data: Array<{ 'prevNodeData': INode | IFeedback, 'newNodeData': INode | IFeedback }>,
) => {
  const actionData = data.map((nodeData: any) => {
    return {
      changeType: actionType,
      newNodeData: _.cloneDeep(nodeData.newNodeData),
      prevNodeData: _.cloneDeep(nodeData.prevNodeData),
    } as TActionItem;
  }) as Array<TActionItem>;

  if(actionData.length){
    const prevRedos: any = store.getState().redoActions as Array<Array<TActionItem>>;
    prevRedos.push(actionData);
  
    store.dispatch({
      type: UndoRedoActionTypes.ADD_REDO_ACTION,
      payload: prevRedos,
    });
  }
};