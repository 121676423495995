import { IFeedback, IUser, TUserAndRole } from '@naya_studio/types';
import { useEffect, useRef, useState } from 'react';
import { getAllUsersBasedOnIds, getFeedbackBasedOnBlockType } from 'src/redux/reduxActions/util';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { TDeleteCommentThunkArg } from 'src/types/argTypes';
import { deleteComment } from 'src/redux/reduxActions/feedbacks';
import {
	Button,
	HtmlTextViewer,
	FeedbackInput,
	FeedbackTag,
	FeedbackTagT,
	UserT,
	WarningModal
} from '@naya_studio/radix-ui';
import { ReduxState } from 'src/redux/reducers/root.types';
import useFeedbackActions from 'src/util/feedback/useFeedbackActions';
import { useParams } from 'react-router';
import { PathParamsType } from 'src/types/pathParams';
import { FeedbackEvents } from 'src/util/analytics/events';
import trackEvent from 'src/util/analytics/analytics';
import useUser from 'src/redux/hooks/user';
import { TTagCommentEventData } from 'src/util/analytics/analytic.types';
import { FeedbackKebabMenuProps, FeedbackUserMenu } from '../feedbackUserMenu/FeedbackUserMenu';
import { feedbackTags, getFormattedUsersData } from '../util';

// feedback props
export type FeedbackProps = {
	data: IFeedback;
	setParentEditState: (val: boolean) => void;
	actionItemAssignees: string[];
	setActionItemAsignees: (userIds: string[]) => void;
	isParent: boolean;
	parentId?: string;
};

/**
 * Component to render existing feedback
 */
export const Feedback = ({
	data,
	setParentEditState,
	isParent,
	parentId,
	actionItemAssignees,
	setActionItemAsignees
}: FeedbackProps) => {
	const [isEditing, setIsEditing] = useState(false); // handles the edit state of the chat
	const [feedbackText, setFeedbackText] = useState(''); // holds the text for the particular chat
	const [showDeleteModal, setShowDeleteModal] = useState(false); // holds the text for the particular chat
	const [showUserTag, setShowUserTag] = useState(false); // holds state for showing tags and dropdown
	const [tag, setTag] = useState<FeedbackTagT>();

	// extarcting functions from feedback actions hook
	const { sendEditFeedback, getTaggedUsersFromHtmlString, extractTextFromHTML } =
		useFeedbackActions();

	// extracting ids from url
	const { canvasId: blockId } = useParams<PathParamsType>();

	// Hold ref to the comment
	const commentRef = useRef<HTMLDivElement>(null);

	// extracting old comments based to revert in case api fails
	const oldComments = getFeedbackBasedOnBlockType(blockId);

	// extract currnet user
	const { user } = useUser();

	// extract all blocks from redux
	const allBlocks = useSelector((state) => (state as ReduxState).blocks.data, shallowEqual);

	// extract al user roles of current block
	const userRoles = allBlocks[blockId]?.users as TUserAndRole[];

	// extract all populated users based on ids
	const allUsers = getAllUsersBasedOnIds(userRoles?.map((x: TUserAndRole) => x.user as string));

	const dispatch = useDispatch();

	// use effect to set initial feedback text
	useEffect(() => {
		if (data.statement) setFeedbackText(data.statement);
	}, [data]);

	// extract user data based o feedback's created by
	const getFeedbackCreatorData = () => {
		const creator = data.createdBy as IUser;
		return {
			name: creator?.userName,
			profilePic: creator?.profilePic
		};
	};

	// sends out an api call to delete chat
	const onDeleteChat = () => {
		// hiding delete modal if visible
		if (showDeleteModal) {
			setShowDeleteModal(false);
		}

		// generating api payload for delete comment action
		const apiPayload: TDeleteCommentThunkArg = {
			payload: {
				_id: data._id as string,
				blockId
			},
			prevState: {
				oldComments
			}
		};
		// if its a reply, setting parent id
		if (!isParent && parentId) {
			apiPayload.payload.parentCommentId = parentId;
		}

		dispatch(deleteComment(apiPayload));
	};

	// Function to initiate delete
	// if its a parent chat with replies, show delete confirmation modal
	// else, delete directly
	const initiateDelete = () => {
		if (isParent && data.replies && data.replies.length > 0) {
			// show delete modal
			setShowDeleteModal(true);
		} else {
			// else delete chat
			onDeleteChat();
		}
	};

	// const update chat api call
	const updateChat = () => {
		setParentEditState(false);
		setIsEditing(false);

		if (feedbackText === '' || feedbackText === '<p><br></p>') {
			return;
		}

		const payloadData: IFeedback = {
			_id: data._id as string,
			statement: feedbackText,
			taggedUsers: getTaggedUsersFromHtmlString(feedbackText)
		};

		sendEditFeedback(payloadData, parentId as string);
	};

	//   function to reset feedback text to original
	const resetfeedbackText = () => {
		setParentEditState(false);
		if (data.statement) {
			setFeedbackText(data.statement);
		} else {
			setFeedbackText('');
		}
	};

	// function to modify action item asigneed
	const onTagUser = (selectedIds: string[]) => {
		const tempList = actionItemAssignees;
		for (let i = 0; i < selectedIds.length; i++) {
			if (!actionItemAssignees.includes(selectedIds[i] as string)) {
				tempList.push(selectedIds[i] as string);
			}
		}
		setActionItemAsignees(tempList);
	};

	/**
	 * Renders -
	 * 1. User's profile pic
	 * 2. user's display name
	 * 3. Created at time
	 * 4. Kebab menu with tags
	 */
	const renderFeedbackTags = () => {
		// Function to handle tag select
		const onAddTag = (ftag: {
			name: string;
			backgroundColor: string;
			commentColor: string;
		}) => {
			setShowUserTag(false);

			sendEditFeedback(
				{
					_id: data._id as string,
					tags: [ftag.name]
				},
				parentId as string
			);

			// Track tagging a feedback event
			const feedbackEventProperties: TTagCommentEventData = {
				tag: ftag.name,
				blockType: allBlocks[blockId]!.blockType
			};
			trackEvent(FeedbackEvents.TAG_COMMENT, feedbackEventProperties);
		};

		// Dropdown options
		const dropdownOptions = feedbackTags.map((ftag) => ({
			key: ftag.name,
			callback: () => onAddTag(ftag),
			tag: ftag
		}));

		// when the tag dropdown is closed with out any tag being selected
		const onClose = () => {
			setShowUserTag(false);
		};

		const props: FeedbackKebabMenuProps = {
			options: dropdownOptions,
			profilePic: getFeedbackCreatorData().profilePic || '',
			name: (getFeedbackCreatorData().name as string) || '',
			createdAt: data.createdAt as string,
			open: true,
			type: 'TAG',
			onClose
		};

		return <FeedbackUserMenu {...props} key="feedback-tag" />;
	};

	// function to remove tag
	const onRemoveTag = () => {
		sendEditFeedback(
			{
				_id: data._id as string,
				tags: []
			},
			parentId as string
		);
	};

	/**
	 * Renders -
	 * 1. User's profile pic
	 * 2. user's display name
	 * 3. Created at time
	 * 4. Kebab menu
	 */
	const renderUserDetails = () => {
		// generating dropdown options to be passes to kebab menu
		let dropdownOptions: { key: string; callback: () => void }[] = [
			{
				key: 'Tag Comment',
				callback: () => {
					setShowUserTag(true);
				}
			}
		];

		if ((data.createdBy as IUser)?._id === user?._id) {
			dropdownOptions = dropdownOptions.concat([
				{
					key: 'Edit Comment',
					callback: () => {
						setParentEditState(true);
						setIsEditing(true);
					}
				},
				{
					key: `Delete Comment${
						data.replies && data.replies.length > 0 ? ' Thread' : ''
					}`,
					callback: initiateDelete
				}
			]);
		}
		if (data.tags && data.tags.length > 0) {
			dropdownOptions.splice(2, 0, {
				key: 'Remove Tag',
				callback: onRemoveTag
			});
		}

		const props: FeedbackKebabMenuProps = {
			options: dropdownOptions,
			profilePic: getFeedbackCreatorData().profilePic || '',
			name: (getFeedbackCreatorData().name as string) || '',
			createdAt: data.createdAt as string
		};

		return <FeedbackUserMenu {...props} key="feedback-dropdown" />;
	};

	// Trigged on mount and on data change
	useEffect(() => {
		if (data.tags && data.tags?.length) {
			const t = data.tags[0];
			if (t) {
				const foundTag = feedbackTags.find((ftag) => ftag.name === t);
				setTag(foundTag);
			}
		} else {
			setTag(undefined);
		}
	}, [data]);

	// If the comment is set to edit mode, scroll it to view
	useEffect(() => {
		setTimeout(() => {
			if (isEditing && commentRef.current)
				commentRef.current.scrollIntoView({ behavior: 'smooth' });
		}, 50);
	}, [isEditing]);

	return (
		<div
			style={{ backgroundColor: tag ? tag.commentColor : 'transparent' }}
			className={`tw-px-2 tw-pt-2 tw-rounded-[16px] ${tag ? 'tw-mb-4 tw-pb-2' : 'tw-mb-2'}`}
			ref={commentRef}
		>
			<WarningModal
				show={showDeleteModal}
				onClose={() => setShowDeleteModal(false)}
				onModalSubmit={onDeleteChat}
				title="Delete Comment Thread"
				bodyText={
					<div className="warning-model">
						<span>
							Are you sure you want to delete this comment thread? This action will
							delete all the replies in the thread.
						</span>
					</div>
				}
				cancelBtnText="Cancel"
				submitBtnText="Delete"
				showDAA
			/>
			{/* USER DETAILS */}
			{showUserTag ? renderFeedbackTags() : renderUserDetails()}
			{isEditing ? (
				<div className="edit-comment">
					{/* 
              if edit state - rendering inoput with save and cancel buttons
            */}
					<FeedbackInput
						className="tw-pt-4 tw-pb-4"
						style={{ marginLeft: '-1rem' }}
						id={data._id as string}
						text={feedbackText}
						placeHolder="Add comment. Use @ to mention"
						onTagUser={onTagUser}
						users={getFormattedUsersData(allUsers, false).blockUsers as UserT[]}
						currentUser={getFormattedUsersData(allUsers, false).currentUser}
						variant="BORDERLESS"
						onSubmit={updateChat}
						onEsc={resetfeedbackText}
						onInputChange={(value: string) => {
							setFeedbackText(value);
						}}
						onShowReplyBtn={() => {}}
						key={data._id as string}
					/>
					<div className="tw-flex tw-mb-4">
						<Button
							variant="PRIMARY"
							text="Save"
							className="tw-mr-2"
							disabled={extractTextFromHTML(feedbackText).length === 0}
							onBtnClick={updateChat}
						/>
						<Button
							variant="SECONDARY-GREY"
							text="Cancel"
							disabled={false}
							onBtnClick={() => {
								resetfeedbackText();
								setIsEditing(false);
							}}
						/>
					</div>
				</div>
			) : (
				<>
					{/* if not in edit state, just displaying comment text */}
					<HtmlTextViewer
						text={feedbackText}
						className={`tw-py-4 tw-text-xs ${tag && 'with-tag'}`}
					/>
					{tag && (
						<FeedbackTag
							text={tag.name}
							style={{ backgroundColor: tag.backgroundColor }}
							onTagClick={() => {}}
						/>
					)}
				</>
			)}
		</div>
	);
};

Feedback.defaultProps = {
	parentId: ''
};
