import React from 'react'

/**
 * Component to render Sharing Content
 */
const SharingContent = ({children} : {children: React.ReactNode}) => {
  return (
    <div className='sharing-gap'>{children}</div>
  )
}

export default SharingContent