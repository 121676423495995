import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ReduxState } from 'src/redux/reducers/root.types';
import './Download.scss';
import { INode, INodeAdditionalPropertiesReact } from '@naya_studio/types';
import axios from 'axios';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import download from '../../../../../assets/icons/toolbar/download.svg';
import { DownloadOptionProps, DownloadOptionDispatchProps, PathParamsType } from './Download.types';

class DownloadOption extends React.Component<DownloadOptionProps & DownloadOptionDispatchProps & RouteComponentProps<PathParamsType>> {
  /**
   * Download File
   */
  downloadFile = (node : INode) => {
    const downloadURL = (node?.additionalProperties as INodeAdditionalPropertiesReact)?.downloadLink || node.image?.src;
    // let fileExtension = node.image?.src?.split('.').pop()
    let fileExtension : string = node.image?.src?.split('.').pop() as string;
    if (fileExtension?.length > 4) { fileExtension = 'png'; }
    axios({
      url: downloadURL,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', node?.text?.value as string || `image.${fileExtension}`); // or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  render() {
    let downloadableCount = 0;
    const allNodes = this.props.app.selectedNodes;
    for (let i = 0; i < allNodes.length; i++) {
      const nodeType = allNodes[i]?.nodeData.nodeType;
      const node = allNodes[i]?.nodeData;
      if (!nodeType) return <></>;
      if ((node?.additionalProperties || node?.nodeType === 'IMAGE')) {
        downloadableCount++;
      }
    }
    if (downloadableCount === allNodes.length) {
      return (
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 1000, hide: 0 }}
          overlay={(
            <Tooltip
              id="button-tooltip-2"
              style={{
                fontSize: '12px',
                borderRadius: '2px',
                lineHeight: '16px',
                marginTop: '8px',
              }}
            >
              Download
            </Tooltip>
        )}
        >
          <div
            className="icon-container"
            onClick={(e: any) => {
              e.stopPropagation();
              for (let i = 0; i < allNodes.length; i++) {
                this.downloadFile(allNodes[i]?.nodeData as INode);
              }
            }}
          >
            <img
              src={download}
              alt="download"
              className="icon"
            />
          </div>
        </OverlayTrigger>

      );
    }

    return <></>;
  }
}

/**
 * Redux state mapped to canvas props
 * @param state
 * @returns
 */
// @ts-ignore
const mapStateToProps = (state: ReduxState) => ({
});

/**
 * Redux actions mapped to canvas props
 * @param dispatch
 * @returns
 */
// @ts-ignore
const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DownloadOption));
