/* eslint-disable @typescript-eslint/no-shadow */
import * as PIXI from 'pixi.js';
import {
	ENodeType,
	IBlock,
	INode,
	IUser,
	TAbsoluteBoundingBox,
	TUserAndRole
} from '@naya_studio/types';
import { InteractionEvent, Sprite } from 'pixi.js';
import { SpriteH } from 'pixi-heaven';
import { store } from 'src';
import {
	checkIfCurrentUserIsAdmin,
	checkUserAccessLevel,
	getCurrentBlock
} from 'src/util/accessLevel/accessLevelActions';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { Rhino3dmLoader } from 'three/examples/jsm/loaders/3DMLoader';
import { TDSLoader } from 'three/examples/jsm/loaders/TDSLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
// import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module.js';
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader';
import { isEqual } from 'lodash';
import { Material } from 'three';
import { CustomDispatch } from 'src/redux/actions/types';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';
import { ISnackBar } from 'src/redux/reducers/root.types';
import { storage } from 'src/util/storage/firebaseStorage';
import { TEditNodesArgs } from 'src/types/argTypes';
import { generateIdsFromUrl } from 'src/redux/reduxActions/util';
import { editNodes } from 'src/redux/reduxActions/node';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import getUserFromRedux from 'src/util/helper/user';
import loading_3d from '../../../../assets/loader/loading_3d.svg';
import move_3d_32 from '../../../../assets/icons/nodes/Move32.png';
import orbit_3d_32 from '../../../../assets/icons/nodes/Orbit32.png';
import cube_3d_32 from '../../../../assets/icons/nodes/Cube32.png';
import { positionAuthorImage, positionAuthorFill } from './utils/helper';
import attachGraphicsLines from '../utils/attachLines';
import App from '../App';
import BaseNode from './BaseNode';

class Model extends BaseNode {
	modelHover: boolean;

	moveMode: boolean;

	orbitMode: boolean;

	inActiveColor: number = 0x7e7e7e;

	moveSpriteContainer: PIXI.Container = new PIXI.Container();

	moveSpriteRect: PIXI.Graphics;

	moveSprite: any = new SpriteH(PIXI.Texture.from(move_3d_32));

	orbitSpriteContainer: PIXI.Container = new PIXI.Container();

	orbitSpriteRect: PIXI.Graphics;

	orbitSprite: any = new SpriteH(PIXI.Texture.from(orbit_3d_32));

	borderContainer?: any;

	cubeSprite: any = new SpriteH(PIXI.Texture.from(cube_3d_32));

	frameRect?: any;

	userEditAccess: boolean;

	isFrameMouseMove: boolean = false;

	cubeTextStyle: PIXI.TextStyle;

	ppsContainer?: PIXI.Container;

	isPerspective: boolean = true;

	loadedModel?: boolean = false;

	threeModel?: any;

	threeScene: THREE.Scene;

	controls?: any;

	camera?: any;

	directionalLight?: THREE.Light;

	ambientLight?: THREE.Light;

	modelSize?: any;

	modelCenter?: any;

	modelLoader?: any;

	threeSprite?: PIXI.Sprite;

	materialColor?: string = '';

	materialWireframe: boolean = false;

	lightColor: number;

	hoverTimeOutId: number = -1;

	upVector?: THREE.Vector3;

	// used to store the current cursor before changing the cursor to other
	prevCursor?: string;

	static defaultNodeData: INode = {
		nodeType: ENodeType.MODEL,
		isVisible: true,
		absoluteBounds: {
			x: window.innerWidth / 2,
			y: window.innerHeight / 2,
			width: 400,
			height: 400
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(app: App, nodeData: INode) {
		super(app, nodeData);
		// Initial modes
		this.modelHover = false;
		this.moveMode = false;
		this.orbitMode = true;
		this.editMode = false;
		this.userEditAccess = Model.checkUserHasEditAccess();

		this.cubeTextStyle = new PIXI.TextStyle({
			fontFamily: 'Rand Regular',
			fontSize: 12,
			wordWrap: true,
			wordWrapWidth: 115,
			align: 'left',
			fill: this.inActiveColor
		});

		// Canvas used to render three js model, modelCanvas and modelrenderer should have same dimensions at all times
		const modelCanvas = document.createElement('canvas');
		const canvasContext = modelCanvas.getContext('2d');
		const canvasWidth =
			(window.innerWidth / (100 + window.innerWidth)) * this.nodeData.absoluteBounds!.width!;
		const canvasHeight =
			(window.innerHeight / (window.innerHeight + 100)) *
			this.nodeData.absoluteBounds!.height!;
		const canvasLength = Math.min(canvasWidth, canvasHeight) * this.app.viewport.scale._x;
		this.modelRendererIndex = Model.getRendererIndex(canvasLength);
		const currentSize = new THREE.Vector2();
		this.app.modelrenderer[this.modelRendererIndex]!.getSize(currentSize);
		canvasContext!.canvas.width = currentSize.x;
		canvasContext!.canvas.height = currentSize.y;
		canvasContext!.globalCompositeOperation = 'copy';
		this.canvasContext = canvasContext;

		// Base texture used to create image from three render
		this.threeTexture = PIXI.BaseTexture.from(modelCanvas, {
			scaleMode: PIXI.SCALE_MODES.LINEAR,
			resolution: window.devicePixelRatio,
			anisotropicLevel: 16
		});

		// three js initial scene setup
		this.threeScene = new THREE.Scene();
		this.modelLoader = nodeData.model?.extension
			? this.getLoader(nodeData.model?.extension)
			: new GLTFLoader();
		this.lightColor = nodeData.model?.extension
			? Model.getLightColor(nodeData.model?.extension)
			: 0x888888;

		// setup of 3D tag of the frame
		const { cubeContainer } = this;
		cubeContainer.zIndex = 11;
		cubeContainer.interactive = this.userEditAccess;
		cubeContainer.visible = false;
		const cubeRectangle = new PIXI.Rectangle(0, 0, 100, 30);
		cubeContainer.hitArea = cubeRectangle;
		if (this.userEditAccess || this.app.ptMode) cubeContainer.cursor = 'move';
		cubeContainer.on('mouseover', this.frameMouseover);
		cubeContainer.on('mouseout', this.frameMouseout);
		cubeContainer.on('mousedown', this.frameMousedown);
		cubeContainer.on('mousemove', this.frameMousemove);
		cubeContainer.on('mouseup', this.frameMouseup);
		Model.placeSpriteH(this.cubeSprite, {
			width: 22,
			height: 22,
			x: 8,
			y: 8
		});
		const cubeText = new PIXI.Text('3D Model', this.cubeTextStyle);
		cubeText.x = 38;
		cubeText.y = 11;
		// fixes bluriness
		cubeText.resolution = 3;
		// @ts-ignore
		cubeContainer.addChild(this.cubeSprite);
		cubeContainer.addChild(cubeText);

		// setup of orbit control
		const { orbitSpriteContainer } = this;
		orbitSpriteContainer.zIndex = 11;
		orbitSpriteContainer.interactive = true;
		orbitSpriteContainer.visible = false;
		orbitSpriteContainer.on('click', () => {
			this.toggleControls();
		});
		orbitSpriteContainer.on('mouseover', () => this.handleControlsHover(false, true));
		orbitSpriteContainer.on('mouseout', () => this.handleControlsHover(false, false));
		const orbitSpriteRect = new PIXI.Graphics();
		orbitSpriteRect.lineStyle(2, 0xff00ff, 0);
		orbitSpriteRect.beginFill(0xededed, 1);
		orbitSpriteRect.drawRoundedRect(0, 0, 32, 32, 2);
		orbitSpriteRect.endFill();
		orbitSpriteRect.alpha = 0;
		this.orbitSprite.width = 32;
		this.orbitSprite.height = 32;
		this.orbitSpriteRect = orbitSpriteRect;
		orbitSpriteContainer.addChild(orbitSpriteRect);
		orbitSpriteContainer.addChild(this.orbitSprite);

		// setup of move control
		const { moveSpriteContainer } = this;
		moveSpriteContainer.zIndex = 11;
		moveSpriteContainer.interactive = true;
		moveSpriteContainer.visible = true;
		moveSpriteContainer.on('click', () => {
			this.toggleControls();
		});
		moveSpriteContainer.on('mouseover', () => this.handleControlsHover(true, true));
		moveSpriteContainer.on('mouseout', () => this.handleControlsHover(true, false));
		const moveSpriteRect = new PIXI.Graphics();
		moveSpriteRect.lineStyle(2, 0xff00ff, 0);
		moveSpriteRect.beginFill(0xededed, 1);
		moveSpriteRect.drawRoundedRect(0, 0, 32, 32, 2);
		moveSpriteRect.endFill();
		moveSpriteRect.alpha = 0;
		this.moveSprite.width = 32;
		this.moveSprite.height = 32;
		this.moveSpriteRect = moveSpriteRect;
	}

	static getRendererIndex = (modelLength: number) => {
		const maxLength = Math.max(window.innerWidth, window.innerHeight);
		const ratio = modelLength / maxLength;
		let tempScale = 0;
		// let finalIndex = 0;
		for (let i = 0; i < 8; i++) {
			if (ratio > tempScale && ratio <= tempScale + 0.25) return i;
			tempScale += 0.25;
		}
		return 7;
	};

	/**
	 * Set light color based on extension
	 * @param {string} extension
	 * @returns hexColor
	 */
	static getLightColor = (extension: string) => {
		switch (extension.toUpperCase()) {
			case 'OBJ':
			case 'FBX':
			case '3DS':
				return 0x888888;
			case 'GLB':
			case 'GLTF2':
			case '3DM':
				return 0xffffff;
			default:
				return 0xffffff;
		}
	};

	/**
	 * checks wether the user has edit access
	 * @returns
	 */
	static checkUserHasEditAccess = () => {
		const user: IUser = getUserFromRedux();
		if (user && user._id) {
			const block = getCurrentBlock() as IBlock;
			if (
				block &&
				(checkUserAccessLevel(block.users as TUserAndRole[], user._id as string, [
					'EDITOR',
					'OWNER'
				]) ||
					checkIfCurrentUserIsAdmin())
			) {
				return true;
			}
		}
		return false;
	};

	/**
	 * place the orbit, move, 3d tags in the frame
	 * @param pixiSpriteH
	 * @param placement
	 */
	static placeSpriteH = (
		pixiSpriteH: PIXI.Container | SpriteH,
		placement: { width: number; height: number; x: number; y: number }
	) => {
		const { width, height, x, y } = placement;
		// @ts-ignore
		pixiSpriteH.width = width;
		// @ts-ignore
		pixiSpriteH.height = height;
		// @ts-ignore
		pixiSpriteH.x = x;
		// @ts-ignore
		pixiSpriteH.y = y;
	};

	/**
	 * setup loader based on the extension of the model file
	 * @param {string} extention
	 * @returns Three js loader
	 */
	getLoader = (extention: string) => {
		const THREE_PATH = `https://unpkg.com/three@0.${THREE.REVISION}.x`;
		const DRACO_LOADER = new DRACOLoader().setDecoderPath(
			`${THREE_PATH}/examples/js/libs/draco/gltf/`
		);
		const KTX2_LOADER = new KTX2Loader().setTranscoderPath(
			`${THREE_PATH}/examples/js/libs/basis/`
		);
		switch (extention.toUpperCase()) {
			case 'FBX':
				return new FBXLoader();
			case 'GLB': {
				return (
					new GLTFLoader()
						.setCrossOrigin('anonymous')
						.setDRACOLoader(DRACO_LOADER)
						// .setMeshoptDecoder( MeshoptDecoder );
						.setKTX2Loader(
							KTX2_LOADER.detectSupport(
								this.app.modelrenderer[this.modelRendererIndex]!
							)
						)
				);
			}
			case 'OBJ':
				return new OBJLoader();
			case 'GLTF2':
				return new GLTFLoader();
			case 'STL': {
				this.upVector = new THREE.Vector3(0, 0, 1);
				return new STLLoader();
			}
			case '3DS': {
				this.upVector = new THREE.Vector3(0, 0, 1);
				return new TDSLoader();
			}
			case '3DM': {
				const threeDMLoader = new Rhino3dmLoader();
				threeDMLoader.setLibraryPath('https://cdn.jsdelivr.net/npm/rhino3dm@7.11.1/');
				return threeDMLoader;
			}
			default:
				return new GLTFLoader();
		}
	};

	/**
	 * Edit the node
	 * @param {string} type -- defines the edit type
	 * @param data -- data to be changed
	 */
	public edit(type: string, data: any) {
		this.prevNodeData = JSON.parse(JSON.stringify(this.nodeData));
		switch (type) {
			case 'ZINDEX':
				this.nodeData.zIndex = data;
				this.draw();
				break;
			case 'AUTHOR':
				this.nodeData.showAuthor = data;
				this.draw();
				break;
			case 'ANGLE': {
				this.camera!.position.copy(this.modelCenter);
				if (data.isPerspective) {
					this.camera!.position.x += this.modelSize / 1.7;
					this.camera!.position.y += this.modelSize / 1.7;
					this.camera!.position.z += this.modelSize / 1.7;
				} else {
					this.camera!.position[data.axis] += data.isPositive
						? this.modelSize
						: -this.modelSize;
				}
				this.camera!.lookAt(this.modelCenter);
				this.renderModel();
				const { x, y, z } = this.camera!.position;
				this.nodeData.model!.position = { x, y, z };
				this.nodeData.model!.view = data.view;
				if (this.nodeData.model!.angles) delete this.nodeData.model!.angles;
				break;
			}
			case 'FILL':
				if (this.nodeData.model!.appearance) {
					if (data !== 'toggle') this.nodeData.model!.appearance.fill = data;
					this.nodeData.model!.appearance.isWireframe = false;
				} else {
					this.nodeData.model!.appearance = { fill: data, isWireframe: false };
				}
				this.updateModelMaterial();
				this.renderModel();
				// this.saveEditNode(this.nodeData);
				break;
			case 'WIREFRAME':
				if (this.nodeData.model!.appearance)
					this.nodeData.model!.appearance!.isWireframe =
						!this.nodeData.model!.appearance!.isWireframe;
				else this.nodeData.model!.appearance = { isWireframe: true, fill: '#ffffff' };
				this.updateModelMaterial();
				// this.draw();
				this.renderModel();
				// this.saveEditNode(this.nodeData);
				break;
			case 'SNAPSHOT': {
				const metadata = {
					contentType: 'image/webp'
				};
				const snapUrls = this.app.selectedNodes.map(
					// @ts-ignore
					(node: BaseNode) =>
						this.app.app.renderer.plugins.extract.base64(
							// TODO: check why this throws error
							// @ts-ignore
							node.ppsContainer,
							metadata.contentType
						)
				);
				const snapNames = this.app.selectedNodes.map(
					(node: BaseNode) => `${node.nodeData.model!.name.split('.')[0]}_${Date.now()}`
				);

				if (this.app.upload3DSnapshot) {
					this.app.upload3DSnapshot(snapUrls, snapNames);
				}
				break;
			}
			default:
				break;
		}
		if (this.moveMode) {
			this.app.removeTransformer();
			this.app.addSelectedNodesToTransformer();
		}
	}

	/**
	 * handle mouse click on the model
	 * @param e
	 */
	onMouseDown = (e: InteractionEvent) => {
		this.app.disableHighlighting();
		if (!this.app.isPanSelected) {
			if (this.editMode && !this.isFrameMouseDown) this.toggleControls(true);
			this.selectNode();
			if ((this.moveMode || this.isFrameHover) && !this.editMode) {
				this.app.transformer.emit('pointerdown', e);
			}
		}
	};

	/**
	 * changes to done with mouse move when transfromer is present
	 */
	transformerMouseMove = (e: InteractionEvent) => {
		if (!this.app.transformChanged) {
			const mousePoint = e.data.global;
			const orbitBounds = this.orbitSpriteContainer.getBounds();
			const moveBounds = this.moveSpriteContainer.getBounds();
			if (
				mousePoint.x > orbitBounds.x &&
				mousePoint.x < orbitBounds.x + orbitBounds.width &&
				mousePoint.y > orbitBounds.y &&
				mousePoint.y < orbitBounds.y + orbitBounds.height
			) {
				this.app.transformer.cursor = 'default';
				this.moveSpriteContainer.emit('mouseout');
				this.orbitSpriteContainer.emit('mouseover');
			} else if (
				mousePoint.x > moveBounds.x &&
				mousePoint.x < moveBounds.x + moveBounds.width &&
				mousePoint.y > moveBounds.y &&
				mousePoint.y < moveBounds.y + moveBounds.height
			) {
				this.app.transformer.cursor = 'default';
				this.orbitSpriteContainer.emit('mouseout');
				this.moveSpriteContainer.emit('mouseover');
			} else {
				this.app.transformer.cursor = 'move';
				this.orbitSpriteContainer.emit('mouseout');
				this.moveSpriteContainer.emit('mouseout');
			}
		}
	};

	/**
	 * changes to be done with click on top of transformer
	 */
	transformerClick = (e: InteractionEvent) => {
		if (this.transformChangeStarted) return;
		const mousePoint = e.data.global;
		const orbitBounds = this.orbitSpriteContainer.getBounds();
		const moveBounds = this.moveSpriteContainer.getBounds();
		if (
			mousePoint.x > orbitBounds.x &&
			mousePoint.x < orbitBounds.x + orbitBounds.width &&
			mousePoint.y > orbitBounds.y &&
			mousePoint.y < orbitBounds.y + orbitBounds.height
		) {
			this.toggleControls();
		} else if (
			mousePoint.x > moveBounds.x &&
			mousePoint.x < moveBounds.x + moveBounds.width &&
			mousePoint.y > moveBounds.y &&
			mousePoint.y < moveBounds.y + moveBounds.height
		) {
			this.app.transformer.cursor = 'default';
			this.orbitSpriteContainer.emit('mouseout');
			this.moveSpriteContainer.emit('mouseover');
			this.toggleControls();
		}
	};

	/**
	 * render the three model and update the texture of the image
	 */
	renderModel = () => {
		const currentRenderer = this.app.modelrenderer[this.modelRendererIndex];
		currentRenderer!.render(this.threeScene, this.camera!);
		this.canvasContext!.drawImage(currentRenderer!.domElement, 0, 0);
		if (this.threeTexture) {
			this.threeTexture.update();
		}
	};

	/**
	 * change the model material based on fill and wireframe
	 */
	updateModelMaterial = () => {
		let newMaterial = false;
		if (this.nodeData.model!.appearance) {
			const { isWireframe, fill } = this.nodeData.model!.appearance;
			if (isWireframe || fill) {
				this.lightColor = 0xf2f2f2;
				if (this.ambientLight) this.ambientLight.color.set(this.lightColor);
				if (this.directionalLight) this.directionalLight.color.set(this.lightColor);
				this.materialColor = isWireframe ? 'black' : fill;
				this.materialWireframe = isWireframe || false;
				newMaterial = true;
			}
		}
		this.threeModel.traverse((child: any) => {
			if (child.material) {
				if (newMaterial) {
					child.material = new THREE.MeshStandardMaterial({
						color: this.materialColor,
						wireframe: this.materialWireframe
					});
				}
				child.material.metalness = 0.33;
				child.material.roughness = 0.45;
			}
		});
	};

	/**
	 * triggered when model is removed from selected nodes
	 */
	exitModelEditMode = () => {
		if (this.editMode && !this.transformChangeStarted) {
			this.editMode = false;
		}
		if (this.app.modelAnimationId !== -1) {
			cancelAnimationFrame(this.app.modelAnimationId);
			this.app.modelAnimationId = -1;
		}
		this.updateControls();
	};

	/**
	 * change color orbit and move icons based on the mode
	 */
	updateModeColors = () => {
		const activeColor = parseInt(
			`0x${this.app.theme.color.substring(1, this.app.theme.color.length + 1)}`,
			10
		);
		// const activeColor = PIXI.utils.string2hex(this.app.theme);
		const moveSpriteColor = this.moveMode ? activeColor : this.inActiveColor;
		const orbitSpriteColor = this.orbitMode ? activeColor : this.inActiveColor;
		if (this.moveSprite && this.moveSprite.tint !== moveSpriteColor) {
			this.moveSprite.tint = moveSpriteColor;
			this.moveSprite.color.dark[0] = this.moveSprite.color.light[0] as number;
			this.moveSprite.color.dark[1] = this.moveSprite.color.light[1] as number;
			this.moveSprite.color.dark[2] = this.moveSprite.color.light[2] as number;
			// dont forget to invalidate, after you changed dark DIRECTLY
			this.moveSprite.color.invalidate();
		}
		if (this.orbitSprite && this.orbitSprite.tint !== orbitSpriteColor) {
			this.orbitSprite.tint = orbitSpriteColor;
			this.orbitSprite.color.dark[0] = this.orbitSprite.color.light[0] as number;
			this.orbitSprite.color.dark[1] = this.orbitSprite.color.light[1] as number;
			this.orbitSprite.color.dark[2] = this.orbitSprite.color.light[2] as number;
			// dont forget to invalidate, after you changed dark DIRECTLY
			this.orbitSprite.color.invalidate();
		}
	};

	/**
	 * change 3d tag and frame color based on the hover state
	 */
	updateFrameColor = () => {
		const activeColor = PIXI.utils.string2hex(this.app.theme.color);
		const cubeContainerColor = this.isFrameHover ? activeColor : this.inActiveColor;
		if (this.cubeContainer) {
			const { cubeSprite } = this;
			const cubeText = this.cubeContainer.children[1];
			if (cubeSprite && cubeSprite.tint !== cubeContainerColor) {
				cubeSprite.tint = cubeContainerColor;
				cubeSprite.color.dark[0] = cubeSprite.color.light[0] as number;
				cubeSprite.color.dark[1] = cubeSprite.color.light[1] as number;
				cubeSprite.color.dark[2] = cubeSprite.color.light[2] as number;
				// dont forget to invalidate, after you changed dark DIRECTLY
				cubeSprite.color.invalidate();
			}
			if (cubeText) {
				this.cubeTextStyle.fill = cubeContainerColor;
			}
			if (this.frameRect) {
				const newAlpha = this.isFrameHover ? 1 : 0.3;
				this.frameRect.geometry.graphicsData[0].lineStyle.alpha = newAlpha;
				this.frameRect.geometry.invalidate();
			}
		}
	};

	/**
	 * change controls based on the modes -- editmode, orbitmode and movemode
	 */
	updateControls = () => {
		if (!this.modelHover && !this.editMode) {
			this.borderContainer.visible = false;
			this.moveSpriteContainer.visible = false;
			this.orbitSpriteContainer.visible = false;
			this.cubeContainer.visible = false;
			if (this.controls) this.controls.enabled = false;
			if (this.app.modelAnimationId !== -1) {
				cancelAnimationFrame(this.app.modelAnimationId);
				this.app.modelAnimationId = -1;
			}
			return;
		}
		if (this.modelHover && this._isLocked) {
			this.borderContainer.visible = true;
			this.moveSpriteContainer.visible = false;
			this.orbitSpriteContainer.visible = false;
			this.cubeContainer.visible = true;
			if (this.controls) this.controls.enabled = false;
			return;
		}
		if (this.editMode) {
			if (this.controls) {
				this.controls.enabled = this.orbitMode;
				if (this.orbitMode && this.app.modelAnimationId === -1) {
					this.app.modelAnimate();
				} else if (this.moveMode && this.app.modelAnimationId !== -1) {
					cancelAnimationFrame(this.app.modelAnimationId);
					this.app.modelAnimationId = -1;
				}
			}
			this.borderContainer.visible = true;
			this.moveSpriteContainer.visible = true;
			this.orbitSpriteContainer.visible = true;
			this.cubeContainer.visible = true;
		} else if (!this.editMode && this.moveMode) {
			if (this.controls) this.controls.enabled = false;
			this.borderContainer.visible = true;
			this.moveSpriteContainer.visible = true;
			this.orbitSpriteContainer.visible = true;
			this.cubeContainer.visible = true;
		} else if (!this.editMode && this.orbitMode) {
			if (this.controls) this.controls.enabled = true;
			if (this.app.modelAnimationId === -1) {
				this.app.modelAnimate();
			}
			this.borderContainer.visible = true;
			this.moveSpriteContainer.visible = true;
			this.orbitSpriteContainer.visible = true;
			this.cubeContainer.visible = true;
		}
	};

	/**
	 * toggle control modes
	 * @param isMoveMode
	 */
	toggleControls = (isMoveMode?: boolean) => {
		if (isMoveMode !== undefined) {
			this.moveMode = isMoveMode;
			this.orbitMode = !isMoveMode;
		} else {
			this.moveMode = !this.moveMode;
			this.orbitMode = !this.orbitMode;
		}
		if (this.editMode && this.orbitMode) this.app.removeTransformer();
		if (this.editMode && this.moveMode) this.app.addSelectedNodesToTransformer();
		if (this.displayObject) {
			this.borderContainer.cursor = this.moveMode ? 'move' : 'orbit_3d';
		}
		this.updateModeColors();
		this.updateControls();
	};

	/**
	 * changes to be done when on hovering the contols -- move icon, orbit icon
	 * @param isMoveControl
	 * @param active
	 */
	handleControlsHover = (isMoveControl: boolean, active: boolean) => {
		const controlRect = isMoveControl ? this.moveSpriteRect : this.orbitSpriteRect;
		if (controlRect) controlRect.alpha = active ? 1 : 0;
		let modelTooltipContainer: PIXI.Container;
		let modelTooltipShortcut: PIXI.Text;
		let modelTooltipText: PIXI.Text;
		if (this.editMode) {
			modelTooltipContainer = this.app.modelTopTooltipContainer;
			modelTooltipShortcut = this.app.modelTopTooltipShortcut;
			modelTooltipText = this.app.modelTopTooltipText;
		} else {
			modelTooltipContainer = this.app.modelTooltipContainer;
			modelTooltipShortcut = this.app.modelTooltipShortcut;
			modelTooltipText = this.app.modelTooltipText;
		}
		if (!active) modelTooltipContainer.visible = false;
		if (this.hoverTimeOutId === -1 && active) {
			this.hoverTimeOutId = window.setTimeout(() => {
				let hoverSpriteContainer: PIXI.Container;
				if (isMoveControl) {
					hoverSpriteContainer = this.moveSpriteContainer;
					modelTooltipShortcut.text = 'M';
					modelTooltipText.text = 'Move 3D Frame';
				} else {
					hoverSpriteContainer = this.orbitSpriteContainer;
					modelTooltipShortcut.text = 'O';
					modelTooltipText.text = 'Orbit 3D Frame';
				}
				modelTooltipContainer.visible = true;
				modelTooltipContainer.x =
					this.nodeData.absoluteBounds!.x! +
					hoverSpriteContainer.x +
					hoverSpriteContainer.width / 2 -
					modelTooltipContainer.width / 2;
				modelTooltipContainer.y =
					this.nodeData.absoluteBounds!.y! + this.nodeData.absoluteBounds!.height!;
				if (this.editMode) {
					modelTooltipContainer.y =
						modelTooltipContainer.y -
						modelTooltipContainer.height -
						hoverSpriteContainer.height -
						8;
				}
				if (!modelTooltipContainer.parent)
					this.app.viewport.addChild(modelTooltipContainer);
			}, 1000);
		}
		if (this.hoverTimeOutId !== -1 && !active) {
			window.clearTimeout(this.hoverTimeOutId);
			this.hoverTimeOutId = -1;
		}
	};

	// Interactions witn frame + 3d tag
	frameMouseover = () => {
		if (
			!this.userEditAccess ||
			this.app.transformChanged ||
			this.isFrameMouseDown ||
			this.app.ptMode
		)
			return;
		this.isFrameHover = true;
		if (this.controls) this.controls.enabled = false;
		this.updateFrameColor();
	};

	frameMouseout = () => {
		if (
			!this.userEditAccess ||
			this.app.transformChanged ||
			this.isFrameMouseDown ||
			this.app.ptMode
		)
			return;
		this.isFrameHover = false;
		if (!this._isLocked) {
			if (this.controls) this.controls.enabled = true;
		}
		this.updateFrameColor();
	};

	frameMousedown = () => {
		if (!this._isLocked && this.isFrameHover) {
			this.isFrameMouseDown = true;
		}
	};

	frameMousemove = (e: InteractionEvent) => {
		if (this.isFrameMouseDown && !this.isFrameMouseMove) {
			this.isFrameMouseMove = true;
			if (this.controls) this.controls.enabled = false;
			if (this.editMode) {
				this.app.addSelectedNodesToTransformer();
				this.app.transformer.emit('pointerdown', e);
			} else {
				this.onMouseDown(e);
			}
			if (!this.editMode) this.app.toggleEditMenu(false);
		}
	};

	frameMouseup = (e: InteractionEvent) => {
		if (this.isFrameMouseDown) {
			if (this.isFrameMouseMove) {
				if (this.editMode) {
					this.app.removeTransformer();
					this.updateControls();
				}
			} else {
				this.editMode = true;
				this.isFrameMouseDown = false;
				this.updateControls();
				this.onMouseDown(e);
			}
		}
		this.isFrameMouseDown = false;
		this.isFrameMouseMove = false;
	};

	/**
	 * get the model data after checking the local browser cache
	 * @returns
	 */
	resolveModelUrl = async () => {
		const modelSrc = this.nodeData.model?.src;
		const modelName = this.nodeData.model?.name;
		if ('caches' in window && (modelName || modelSrc)) {
			const appCache = await caches.open('naya-app');
			let matchedResponse;
			if (modelName) matchedResponse = await appCache.match(modelName);
			if (!matchedResponse) {
				if (modelSrc) {
					matchedResponse = await appCache.match(modelSrc);
				} else {
					return 'Invalid Model';
				}
			}
			if (matchedResponse) {
				// get it from cache
				const blobData = await matchedResponse.blob();
				if (modelName && !modelSrc) {
					const payload: ISnackBar = {
						text: 'Uploading Files',
						show: true,
						type: 'LOADER',
						loaderColor: 'var(--theme-color-1)'
					};

					await addSnackbar(payload);
					const { extension } = this.nodeData.model!;
					const storageRef = ref(storage, `${modelName}.${extension}`);
					const uploadTask = uploadBytesResumable(storageRef, blobData);
					uploadTask.on(
						'state_changed',
						() => {
							// percentage can also be calculated here!
						},
						() => {
							const payload: ISnackBar = {
								text: 'Failed to upload. Please try again!',
								show: true,
								type: 'ERROR'
							};

							addSnackbar(payload);
							removeSnackbar(2000);
						},
						() => {
							/** As the upload is successful, get its download url */
							const storageRef = ref(storage, `${modelName}.${extension}`);
							getDownloadURL(storageRef).then(async (url: string) => {
								// const nodeData = getNodeData(canvasId, nodeId as string) as INode;
								const nodeData = JSON.parse(JSON.stringify(this.nodeData)) as INode;
								if (nodeData.version) {
									nodeData.version += 1;
								} else nodeData.version = 0;

								nodeData.model!.src = url;

								// extracting redux
								const reduxState = store.getState();

								nodeData.lastUpdatedBy = getUserFromRedux()._id as string;

								// generating payload for edit nodes action
								const apiPayload: TEditNodesArgs = {
									data: {
										nodes: [nodeData],
										...generateIdsFromUrl()
									},
									prevState: {
										prevBlocks: reduxState.nodes.data
									}
								};
								(store.dispatch as CustomDispatch)(editNodes(apiPayload));
							});

							const payload: ISnackBar = {
								text: 'Files Uploaded',
								show: true,
								type: 'NORMAL'
							};

							addSnackbar(payload);
							removeSnackbar(2000);
						}
					);
				}
				return URL.createObjectURL(blobData);
			}
			// load and store it to cache
			if (modelSrc) await appCache.add(modelSrc);
			const cachedUrl: any = await this.resolveModelUrl();
			return cachedUrl;
		}
		return modelSrc || 'Invalid Model';
	};

	/**
	 * place the camera based on the position provided
	 * @param pos
	 */
	placeCamera = (pos: { x: number; y: number; z: number } | undefined) => {
		const { camera } = this;
		const center = this.modelCenter;
		const size = this.modelSize;
		if (pos) {
			const { x, y, z } = pos;
			camera.position.copy(new THREE.Vector3(x, y, z));
		} else {
			camera.position.copy(center);
			camera.position.x += size / 1.7;
			camera.position.y += size / 1.7;
			camera.position.z += size / 1.7;
		}
		camera.lookAt(center);
	};

	disposeScene = () => {
		const parentPromises: any = [];
		if (this.threeScene && this.threeScene.children.length) {
			this.threeScene.traverse((child: any) => {
				if (child.geometry) child.geometry.dispose();
				if (child.material)
					([] as Material[]).concat(child.material).forEach((m) => m.dispose());
				parentPromises.push(
					Promise.resolve().then(() => {
						// if we remove children in the same tick then we can't continue traversing,
						// so we defer to the next microtask
						if (child.parent) {
							child.parent.remove(child);
						}
					})
				);
			});
		}
		return parentPromises;
	};

	/**
	 * load the three model using the loader and setup the initial scene
	 * @param modelUrl
	 * @returns
	 */
	loadThreeModel = (modelUrl: any) =>
		new Promise((resolve, reject) => {
			if (modelUrl) {
				this.modelLoader.load(
					modelUrl,
					(result: any) => {
						Promise.all(this.disposeScene()).then(() => {
							// TODO: need to add valid model checks before adding three scene
							let model;
							if (
								this.nodeData.model &&
								this.nodeData.model.extension &&
								!['GLB', 'GLTF', 'STL'].includes(
									this.nodeData.model.extension.toUpperCase()
								)
							) {
								model = result;
							} else if (
								this.nodeData.model &&
								this.nodeData.model.extension &&
								['STL'].includes(this.nodeData.model.extension.toUpperCase())
							) {
								const material = new THREE.MeshPhongMaterial({ color: 0x888888 });
								const mesh = new THREE.Mesh(result, material);
								mesh.scale.set(0.5, 0.5, 0.5);
								model = mesh;
							} else {
								model = result.scene || result.scenes[0];
							}
							if (
								this.nodeData.model &&
								this.nodeData.model.extension &&
								this.nodeData.model.extension.toLocaleUpperCase() === '3DS'
							) {
								if (!this.nodeData.model.appearance)
									this.nodeData.model.appearance = { fill: '#ffffff' };
							}
							if (this.upVector) {
								const rotation = new THREE.Quaternion().setFromAxisAngle(
									new THREE.Vector3(1.0, 0.0, 0.0),
									-Math.PI / 2.0
								);
								model.quaternion.multiply(rotation);
							}
							this.threeModel = model;
							this.updateModelMaterial();
							const box = new THREE.Box3().setFromObject(model);
							const size = box.getSize(new THREE.Vector3()).length();
							this.modelSize = size;
							const center = box.getCenter(new THREE.Vector3());
							this.modelCenter = center;

							const camera = new THREE.PerspectiveCamera(
								60,
								1,
								size / 100,
								size * 100
							);
							const controls = new OrbitControls(camera, this.app.app.view);
							// controls.enableDamping = true;
							// controls.autoRotate = true;
							controls.enablePan = false;
							controls.enableZoom = false;
							controls.maxDistance = size * 10;
							controls.enabled = false;
							controls.target = center;
							controls.addEventListener('start', () => {
								this.prevNodeData = JSON.parse(JSON.stringify(this.nodeData));
								if (this.app.allNodes) {
									this.app.allNodes.forEach((an: BaseNode) => {
										if (
											an.nodeData._id !== this.nodeData._id &&
											an.displayObject
										)
											an.displayObject.interactive = false;
									});
								}
							});
							controls.addEventListener('end', () => {
								if (this.app.allNodes) {
									this.app.allNodes.forEach((an: BaseNode) => {
										if (
											an.nodeData._id !== this.nodeData._id &&
											an.displayObject &&
											(this.userEditAccess || !this.app.ptMode)
										) {
											an.displayObject.interactive = true;
										}
									});
									this.app.resetInteractivity();
								}
								// const angles = this.nodeData.model!.angles;
								const { position } = this.nodeData.model!;
								// const newAngles = this.camera.rotation;
								const newPos = this.camera.position;
								let dataUpdated = false;
								// if (!angles || angles.x !== newAngles.x || angles.y !== newAngles.y || angles.z !== newAngles.z) {
								//   this.nodeData.model!.angles = { x: newAngles.x, y: newAngles.y, z: newAngles.z };
								//   console.log("newAngles", newAngles.x, newAngles.y,  newAngles.z, this.nodeData.model?.angles)
								//   dataUpdated = true;
								// }
								if (
									!position ||
									position.x !== newPos.x ||
									position.y !== newPos.y ||
									position.z !== newPos.z
								) {
									this.nodeData.model!.position = {
										x: newPos.x,
										y: newPos.y,
										z: newPos.z
									};
									this.nodeData.model!.view = 'Perspective';
									dataUpdated = true;
								}
								if (dataUpdated) {
									this.saveEditNode(this.nodeData);
									this.addUndoAction();
								}
							});
							this.camera = camera;
							this.controls = controls;

							this.placeCamera(this.nodeData.model!.position);

							this.threeScene.add(model);

							const light = new THREE.AmbientLight(this.lightColor); // fill with the color selected
							this.threeScene.add(light);
							this.ambientLight = light;
							const directionalLight = new THREE.DirectionalLight(
								this.lightColor,
								0.5
							);
							directionalLight.position.set(0.5, 0, 0.866);
							this.directionalLight = directionalLight;
							this.camera.add(directionalLight);
							this.threeScene.add(this.camera);

							this.renderModel();
							resolve('loaded the model');
						});
					},
					(xhr: any) => {
						console.info(`${(xhr.loaded / xhr.total) * 100}% loaded`);
					},
					(error: any) => {
						console.error('error', error);
					}
				);
			} else {
				reject(new Error('no src to load'));
			}
		});

	/**
	 * create the pixi js sprite using the texture created from three model renderer
	 * @param bounds
	 * @returns
	 */
	getThreeSprite = (bounds: TAbsoluteBoundingBox) => {
		const minLength = Math.min(bounds.width!, bounds.height!) - 32;
		if (this.threeTexture) {
			const threeSprite = PIXI.Sprite.from(new PIXI.Texture(this.threeTexture));
			threeSprite.anchor.set(0.5, 0.5);
			threeSprite.width = minLength;
			threeSprite.height = minLength;
			threeSprite.x = bounds.width! / 2;
			threeSprite.y = bounds.height! / 2;
			return threeSprite;
		}
		return null;
	};

	/**
	 * draw the model to the canvas
	 */
	draw = async () => {
		if (
			this.nodeData &&
			this.nodeData.model &&
			(this.nodeData.model.src || this.nodeData.model.name)
		) {
			// changing the bounds to include the scale
			const { absoluteBounds, scale } = this.nodeData;
			let { rotation } = this.nodeData;
			rotation = rotation || 0;
			if (scale && absoluteBounds) {
				if (scale.scaleX && absoluteBounds.width) {
					const newWidth = absoluteBounds.width * scale.scaleX!;
					absoluteBounds.width = newWidth >= 296 ? newWidth : 296;
					this.nodeData.scale!.scaleX = 1;
				}
				if (scale.scaleY && absoluteBounds.height) {
					const newHeight = absoluteBounds.height * scale.scaleY!;
					absoluteBounds.height = newHeight >= 296 ? newHeight : 296;
					this.nodeData.scale!.scaleY = 1;
				}
			}
			// creating the required pixi containers
			const ppsModelContainer = new PIXI.Container(); // top level container
			const borderContainer = new PIXI.Container(); // frame and icons container
			const ppsContainer = new PIXI.Container(); // model sprite container
			const loadingContainer = new PIXI.Container(); // loading container
			ppsModelContainer.addChild(loadingContainer);
			ppsModelContainer.addChild(borderContainer);
			ppsModelContainer.addChild(ppsContainer);
			borderContainer.interactive = true;
			if (this.app.activeTool === 'SELECT') ppsModelContainer.interactive = true;
			ppsModelContainer.sortableChildren = true;
			borderContainer.zIndex = 10;
			borderContainer.visible = false;
			borderContainer.cursor = this.moveMode ? 'move' : 'orbit_3d';
			loadingContainer.zIndex = 100;
			ppsContainer.zIndex = 1;
			this.ppsContainer = ppsContainer;
			// hitArea of the container
			const pixiRectangle = new PIXI.Rectangle(
				0,
				0,
				absoluteBounds?.width,
				absoluteBounds?.height
			);
			borderContainer.hitArea = pixiRectangle;

			// loading placeholder until model is downloaded
			// TODO: should find a way to redraw every time.
			const loadingImage = PIXI.Sprite.from(PIXI.Texture.from(loading_3d));
			const loadingImageLength =
				Math.min(absoluteBounds!.width!, absoluteBounds!.height!) * 0.5;
			loadingImage.width = loadingImageLength;
			loadingImage.height = loadingImageLength;
			loadingImage.x = (absoluteBounds!.width! - loadingImageLength) / 2;
			loadingImage.y = (absoluteBounds!.height! - loadingImageLength) / 2;
			loadingContainer.addChild(loadingImage);
			const loadingText = new PIXI.Text('Loading 3D model...', {
				fontFamily: 'Rand Regular',
				fontSize: 12,
				wordWrap: true,
				wordWrapWidth: 115,
				align: 'center',
				fill: this.inActiveColor
			});
			loadingText.anchor.set(0.5, 0);
			loadingText.position.set(
				absoluteBounds!.width! / 2,
				loadingImage.y + loadingImageLength
			);
			loadingText.y = loadingImage.y + loadingImageLength;
			loadingContainer.addChild(loadingText);

			// creating the outer frame
			const outerRectWidth = absoluteBounds!.width as number;
			const outerRectHeight = absoluteBounds!.height as number;
			const ppsGraphics: PIXI.Graphics = new PIXI.Graphics();
			ppsGraphics.lineStyle(1, PIXI.utils.string2hex(this.app.theme.color), 0.3);
			ppsGraphics.drawRect(0, 0, outerRectWidth, outerRectHeight);
			this.frameRect = ppsGraphics;
			borderContainer.addChild(ppsGraphics);

			// entering and exiting the model area
			ppsModelContainer.on('mouseover', () => {
				if (this.app.showFilesHover || this.app.transformChanged) return;
				if (this.app.activeTool === 'SELECT') {
					this.modelHover = true;
					this.app.modelActiveNode = this;
					this.app.disableHighlighting();
					this.updateControls();
					if (this._isLocked) {
						this.borderContainer.cursor = 'default';
					} else {
						this.borderContainer.cursor = this.moveMode ? 'move' : 'orbit_3d';
					}
					this.app.pauseDragPlugin();
				}
			});
			ppsModelContainer.on('mouseout', () => {
				if (this.app.showFilesHover || this.app.transformChanged) return;
				if (this.app.activeTool === 'SELECT') {
					this.modelHover = false;
					if (!this.editMode) this.app.modelActiveNode = undefined;
					this.app.enableHighlighting();
					this.updateControls();
					this.app.resumeDragPlugin();
				}
			});
			// interactions with the model
			borderContainer.on('rightclick', (e: any) => {
				this.app.rightClickedNode = this;
				this.app.toggleCanvasMenu(false);
				this.app.toggle3dContext(true);
				borderContainer.cursor = 'context-menu';
				e.stopPropagation();
			});
			borderContainer.on('mousedown', (e: InteractionEvent) => {
				// if mouse middle button is usedstore the current cursor and change the cursor
				// to default and emit mousedown on frame and return
				if (e.data.button === 1) {
					this.prevCursor = this.borderContainer.cursor;
					this.borderContainer.cursor = 'default';
					this.app.frame?.displayObject.emit('mousedown', e);
					return;
				}
				if (!this.userEditAccess || this.app.ptMode) return;
				this.isMouseDown = true;
			});
			borderContainer.on('mousemove', (e: InteractionEvent) => {
				if (!this.userEditAccess || this.app.ptMode) return;
				if (this.isMouseDown && !this.isMouseMove) {
					this.isMouseMove = true;
					if (!this._isLocked && this.moveMode) {
						this.onMouseDown(e);
						this.app.toggleEditMenu(false);
					}
				}
			});
			borderContainer.on('mouseup', (e: any) => {
				// if the mouse middle button is used sets the cursor to prevCursor and emit mouseup on
				// frame and return
				if (e.data.button === 1) {
					this.app.frame?.displayObject.emit('mouseup', e);
					this.borderContainer.cursor = this.prevCursor;
					return;
				}
				if (!this.userEditAccess || this.app.ptMode) return;
				if (this.isMouseDown && !this.isMouseMove) {
					if (this.editMode) {
						this.toggleControls();
					} else {
						this.editMode = true;
						this.updateControls();
						this.onMouseDown(e);
						this.onMouseUp(e);
					}
				}
				this.isMouseDown = false;
				this.isMouseMove = false;
			});
			this.borderContainer = borderContainer;

			if (!this.app.ptMode) {
				this.moveSpriteContainer.addChild(this.moveSpriteRect);
				this.moveSpriteContainer.addChild(this.moveSprite);
			}
			// placing the orbit and move icons inside the frame
			Model.placeSpriteH(this.orbitSpriteContainer, {
				width: 32,
				height: 32,
				x: this.app.ptMode
					? absoluteBounds!.width! - 32 - 8
					: absoluteBounds!.width! - 64 - 8,
				y: absoluteBounds!.height! - 32 - 8
			});
			if (!this.app.ptMode) {
				Model.placeSpriteH(this.moveSpriteContainer, {
					width: 32,
					height: 32,
					x: absoluteBounds!.width! - 32 - 8,
					y: absoluteBounds!.height! - 32 - 8
				});
			}

			// adding 3d tag, orbit and move to the frame
			ppsModelContainer.addChild(this.cubeContainer);
			if (this.userEditAccess || this.app.ptMode) {
				// @ts-ignore
				ppsModelContainer.addChild(this.orbitSpriteContainer);
				// @ts-ignore
				ppsModelContainer.addChild(this.moveSpriteContainer);
				attachGraphicsLines(
					absoluteBounds,
					borderContainer,
					this.frameMouseover,
					this.frameMouseout,
					this.frameMousedown,
					this.frameMousemove,
					this.frameMouseup
				);
			}

			if (!this.app.transformChanged) {
				this.updateModeColors();
				this.updateControls();
			}

			//  Add author if required
			const createdBy = this.nodeData.createdBy as any;
			const profilePicStatus =
				typeof createdBy === 'object' &&
				'profilePic' in createdBy &&
				createdBy.profilePic !== '';
			const userNameStatus =
				typeof createdBy === 'object' &&
				'userName' in createdBy &&
				createdBy.userName !== '';
			const mScaleX = scale?.scaleX || 1;
			const mScaleY = scale?.scaleY || 1;
			const currnetHeight = absoluteBounds?.height!;
			if (this.nodeData.showAuthor && profilePicStatus) {
				const authorImage = PIXI.Sprite.from(createdBy.profilePic);
				const circularMask = new PIXI.Graphics();
				positionAuthorImage(
					authorImage,
					circularMask,
					{
						width: 60,
						height: currnetHeight,
						x: 0,
						y: 0,
						scaleX: mScaleX,
						scaleY: mScaleY,
						rotation: -rotation
					},
					0.1
				);
				ppsModelContainer.addChild(authorImage);
				ppsModelContainer.addChild(circularMask);
				authorImage.mask = circularMask;
				this.isAuthorImage = true;
				this.author = {
					image: authorImage,
					circularMask
				};
			} else if (
				this.nodeData.nodeType === 'MODEL' &&
				this.nodeData.showAuthor &&
				userNameStatus
			) {
				// TODO: pixi graphics to be replaced with corresponding letter picture
				const circleFill = new PIXI.Graphics();
				const circleText = new PIXI.Text(createdBy.userName.toUpperCase()[0]);
				positionAuthorFill(
					circleFill,
					circleText,
					{
						width: 60,
						height: currnetHeight * mScaleY,
						x: 0,
						y: 0,
						scaleX: mScaleX!,
						scaleY: mScaleY!,
						rotation: -rotation
					},
					0.1
				);
				ppsModelContainer.addChild(circleFill);
				this.isAuthorImage = false;
				this.pixiAuthor = {
					graphics: circleFill,
					text: circleText
				};
			}

			if (this.displayObject) {
				this.app.viewport.removeChild(this.displayObject);
			}
			this.displayObject = ppsModelContainer;
			this.displayObject.zIndex = this.nodeData.zIndex as number;
			this.displayObject.name = 'MODEL';
			this.displayObject.setTransform(absoluteBounds?.x, absoluteBounds?.y, 0, 0, rotation);
			this.app.viewport.addChild(this.displayObject);

			// loading the three model, creating the pixi sprite using the three renderer texture
			if (this.nodeData.model.name || this.nodeData.model.src) {
				if (!this.loadedModel) {
					const modelUrl = await this.resolveModelUrl();
					if (modelUrl !== 'Invalid Model') {
						this.loadThreeModel(modelUrl).then(() => {
							this.loadedModel = true;
							this.threeSprite = this.getThreeSprite(absoluteBounds!) as Sprite;
							if (this.threeSprite) {
								ppsContainer.addChild(this.threeSprite);
							}
							loadingContainer.visible = false;
						});
					} else {
						loadingText.text = 'Invalid Model! please upload again';
					}
				} else {
					if (!isEqual(this.camera.position, this.nodeData.model!.position)) {
						this.placeCamera(this.nodeData.model!.position);
						this.renderModel();
					}
					const { appearance } = this.nodeData.model!;
					if (
						appearance &&
						(appearance.fill !== this.materialColor ||
							appearance.isWireframe !== this.materialWireframe)
					) {
						this.updateModelMaterial();
						this.renderModel();
					}
					this.threeSprite = this.getThreeSprite(absoluteBounds!) as Sprite;
					if (this.threeSprite) {
						ppsContainer.addChild(this.threeSprite);
					}
					loadingContainer.visible = false;
				}
			}
		}
	};
}

export default Model;
