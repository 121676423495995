import { UserListT, UserT } from '@naya_studio/radix-ui';
import { IUser, TPoint, TUserAndRole } from '@naya_studio/types';
import { DUMMY_IMG } from 'src/util/helper/constants';
import getUserFromRedux, { getAIUserFromRedux } from 'src/util/helper/user';
import { TPaperImageRef, TPaperView } from '../collaborationTool/CollaborationTool.types';

// Function to extract the user's first name
export const getUserFirstName = (user: IUser) => user?.firstName || user?.userName || '';

// Function to extract the user's last name name
export const getUserLastName = (user: IUser) => user?.lastName || '';

/**
 * Function to access the role of active user
 */
export const isActiveUserViewer = (user: IUser, blockUsers: TUserAndRole[]) => {
	if (user && blockUsers) {
		for (let i = 0; i < blockUsers?.length; i++) {
			if ((blockUsers[i] as TUserAndRole)?.user === user._id) {
				return (blockUsers[i] as TUserAndRole)?.role === 'VIEWER';
			}
		}
	}
	return false;
};

// Function to extract formatted users data for feedbackInput
export const getFormattedUsersData = (allUsers: IUser[], pushAiUser: boolean) => {
	const user: IUser = getUserFromRedux();
	const aiUser: IUser = getAIUserFromRedux();
	const blockUsers: UserT[] | UserListT[] = [];
	let currentUser = {} as UserT;
	for (let i = 0; i < allUsers.length; i++) {
		const tempUser = allUsers[i] as IUser;
		const temp: UserT = {
			id: allUsers[i]?._id as string,
			firstName: getUserFirstName(tempUser),
			lastName: getUserLastName(tempUser),
			email: tempUser.email as string,
			userName: tempUser.userName || (getUserFirstName(tempUser) as string),
			profilePic: tempUser.profilePic || (DUMMY_IMG as string)
		};
		blockUsers.push(temp as UserT & UserListT);
		if (user._id === tempUser._id) {
			currentUser = temp;
		}
	}
	if (pushAiUser) {
		blockUsers.push({
			id: aiUser._id as string,
			firstName: getUserFirstName(aiUser),
			lastName: getUserLastName(aiUser),
			email: aiUser.email as string,
			userName: aiUser.userName || (getUserFirstName(aiUser) as string),
			profilePic: aiUser.profilePic || (DUMMY_IMG as string)
		} as UserT & UserListT);
	}
	return { blockUsers, currentUser };
};

// Tags with name, backgroundColor, commentColor
export const feedbackTags = [
	{
		name: 'Urgent',
		backgroundColor: '#F19F99',
		commentColor: '#FDF1F0'
	},
	{
		name: 'FYI',
		backgroundColor: '#FFEDA0',
		commentColor: '#FFFDF3'
	},
	{
		name: 'Action item',
		backgroundColor: '#FFD1AF',
		commentColor: '#FFEFE2'
	},
	{
		name: 'Project Management',
		backgroundColor: '#80E5F7',
		commentColor: '#D3F5FA'
	},
	{
		name: 'Design',
		backgroundColor: '#DFDFDF',
		commentColor: '#F2F3F4'
	},
	{
		name: 'Sustainability',
		backgroundColor: '#CFE9B6',
		commentColor: '#F8FFF1'
	}
];

/**
 * Get feedback position in viewers, used to get position in zoom and pan
 * @param view reference to the image or paperjs project view
 * @param fbBounds 0-1 value of x,y pos - mapped to image/project view width
 * @returns position of feedback - x,y
 */
export const getFeedbackPostionInViewer = (view: TPaperImageRef | TPaperView, fbBounds: TPoint) => {
	const { bounds, view: view1 } = view as TPaperImageRef;
	const projToViewVal = view1?.projectToView({
		x: bounds.x,
		y: bounds.y
	}) || { x: 0, y: 0 };

	const viewZoom = view1?.zoom || 1;
	const posTemp = {
		x: projToViewVal.x + (fbBounds.x as number) * bounds.width * viewZoom || 350,
		y: projToViewVal.y + (fbBounds.y as number) * bounds.height * viewZoom + 49 || 400
	} as TPoint;
	return posTemp;
};

/**
 * Set position of the feedback when the fb is dragged and placed into different position
 * @param view reference to the image or project view
 * @param x co-ord of cursor
 * @param y co-ord of cursor
 * @returns
 */
export const setFeedbackPostionInViewer = (
	view: TPaperImageRef | TPaperView,
	x: number,
	y: number
) => {
	const { bounds, view: view1 } = view as TPaperImageRef;

	const projToViewVal = view1?.projectToView({
		x: bounds.x,
		y: bounds.y
	}) || { x: 0, y: 0 };

	const imgX = x - projToViewVal.x;
	const imgY = y - 50 - projToViewVal.y;

	const viewZoom = view1?.zoom || 1;

	const imgWidth = bounds.width * viewZoom;
	const imgHeight = bounds.height * viewZoom;

	return { x: imgX / imgWidth, y: imgY / imgHeight };
};
