/* eslint-disable max-len */
import { EBlockType } from '@naya_studio/types';
import { TI2RData } from './i2rData';

const plantPot: TI2RData = {
	text: `{"root":{"children":[{"children":[{"type":"linebreak","version":1},{"detail":0,"format":0,"mode":"normal","style":"","text":"design a colorful, modern, and geometrically appealing plant pot","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}`,
	projectTitle: 'Example - Plant pot',
	groups: [
		{
			name: 'Example - Plant Pot Inspiration',
			blocks: [
				{
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/plantPot/n4mollijwvfskaq8dpdq',
					name: 'Morandi Abstract Vase by Prima Folia',
					blockType: EBlockType.IMAGE
				},
				{
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/plantPot/ah7fcrngercld48myymc',
					name: 'Texture Inspiration',
					blockType: EBlockType.IMAGE
				},
				{
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/plantPot/nkocww35tz02uptzpbzt',
					name: 'Vases with Voids',
					blockType: EBlockType.IMAGE
				},
				{
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/plantPot/ksufybqndyi91o0qulq1',
					name: 'Vivid Color Inspiration',
					blockType: EBlockType.IMAGE
				}
			]
		}
	]
};

export default plantPot;
