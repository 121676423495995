import * as PIXI from 'pixi.js';
import { ENodeType, INode } from '@naya_studio/types';
import _ from 'lodash';
import { store } from 'src';
import { onPasteInPlace } from 'src/features/copyPaste/paste';
import { onRedo, onUndo } from 'src/features/undoRedo/undoRedo';
import App from './App';
// import BaseNode from './nodes/BaseNode';
import SelectHighlight from './nodes/SelectHighlight';
import Shape from './nodes/Shape';
import moveNodes from './utils/moveNode';
import resizeNodes from './utils/resizeNode';
// RTC

/**
 * Handle keydown --> features -
 * lock aspect ratio for resize, copy paste, undo & redo,ESC functionalities, ENTER functionalities
 * @param e
 * @param app
 * @returns
 */
export const appKeyDownEvent = async (e: KeyboardEvent) => {
	// if keyboard is locked, lock keys
	if (!window.pixiApp) return;
	const app = window.pixiApp;
	if (app.keyboardLock) return;
	// check if online
	const target = e.target as HTMLElement;
	const isTextFields = !(target.tagName === 'BODY' || target.tagName === 'CANVAS');
	switch (e.key) {
		// ALT - pan tool select
		case ' ':
		case 'Alt': {
			const htmlElement = e.target as HTMLElement;
			if (htmlElement.tagName && htmlElement.tagName.toLowerCase() !== 'body') return;
			if (!app.isPanSelected) {
				if (app.canvasSelected) {
					app.transformer.centeredScaling = true;
				} else if (app.cropModeActive) {
					app.transformer.centeredScaling = true;
					app.transformer.lockAspectRatio = app.shiftPressed;
				} else {
					app.isPanSelected = true;
					app.resumeDragPlugin();
					app.setActiveTool('PAN');
					app.toggleShowActiveTool(false);
					app.throughPanTool = true;
				}
			}
			break;
		}
		// SHIFT - lock aspect ratio of the transformer
		case 'Shift':
			app.shiftPressed = true;
			if (app.activeTool === 'SELECT') {
				// app.transformer.interactive = false;
				app.pauseDragPlugin();
				if (
					(app.selectedNodes.length === 1 &&
						app.selectedNodes[0]?.nodeData.nodeType === 'IMAGE') ||
					app.selectedNodes.length > 1
				) {
					if (app.cropModeActive) app.transformer.lockAspectRatio = true;
					else if (app.transformerType !== 'text')
						app.transformer.lockAspectRatio = false;
					else app.transformer.lockAspectRatio = true;
				} else if (app.transformerType !== 'text') app.transformer.lockAspectRatio = true;
				else app.transformer.lockAspectRatio = false;
			}
			break;

		// case 'ArrowLeft':
		//   if (store.getState().storyboardFullScreen.index > 0) {
		//     await new Promise<void>((resolve) => {
		//       store.dispatch({
		//         type: 'EDIT_STORYBOARD',
		//         payload: {
		//           index: store.getState().storyboardFullScreen.index - 1,
		//         },
		//       });
		//       resolve();
		//     });
		//   }
		//   break;

		// case 'ArrowRight':
		//   if ((store.getState().storyboardFullScreen.storyboard.boards?.length
		//     && store.getState().storyboardFullScreen.index
		//     < ((store.getState().storyboardFullScreen.storyboard.boards!.length - 1)))) {
		//     await new Promise<void>((resolve) => {
		//       store.dispatch({
		//         type: 'EDIT_STORYBOARD',
		//         payload: {
		//           index: store.getState().storyboardFullScreen.index + 1,
		//         },
		//       });
		//       resolve();
		//     });
		//   }

		//   break;
		case 'Enter':
			app.resetEditMenu();
			break;
		// ESC
		case 'Escape':
			// Reset the tool to select tool
			if (!['SELECT'].includes(app.activeTool)) {
				app.selectTool('SELECT', {} as INode);
			}
			// Revert crop changes
			if (app.selectedNodes.length > 0) {
				const selectedNode = app.selectedNodes[0];
				if (selectedNode?.nodeData.nodeType === 'IMAGE' && selectedNode._cropMode) {
					app.selectedNodes[0]?.revertCropChanges();
				}
				app.removeTransformer();
				app.toggleEditMenu(false);
			}
			app.canvasSelected = false;
			app.removeAllSelectedNodes();
			app.removeTransformer();
			app.toggleEditMenu(false);
			app.render();
			app.toggleCanvasEditMenu(false);
			app.toggle3dContext(false);
			break;
		// Toggle MoveMode, OrbitMode of model node - Key Code - M, O
		case 'o':
		case 'm':
			if (app.modelActiveNode) app.modelActiveNode.toggleControls();
			break;
		// CNTRL
		case 'Meta':
			app.cntrlPressed = true;
			break;
		// handles undo action in cmd or cntrl is pressed
		case 'z':
			if (e.metaKey || e.ctrlKey) {
				onUndo(app);
			}
			e.stopImmediatePropagation();
			break;
		// handles redo action in cmd or cntrl is pressed
		case 'y':
			if (e.metaKey || e.ctrlKey) {
				e.preventDefault();
				onRedo(app);
			}
			e.stopImmediatePropagation();
			break;
		default:
			break;
	}

	// For node position and width changes
	let delta = 1;
	if (e.shiftKey) delta *= 10;
	switch (e.key) {
		// Keyboard shortcuts for moving/resizing nodes
		case 'ArrowLeft':
			if (app.selectedNodes.length > 0 && !isTextFields) {
				if (e.metaKey || e.ctrlKey) {
					resizeNodes({ x: -delta, y: 0 }, app);
				} else {
					moveNodes({ x: -delta, y: 0 }, app);
				}
				e.preventDefault();
			}
			break;
		case 'ArrowRight':
			if (app.selectedNodes.length > 0 && !isTextFields) {
				if (e.metaKey || e.ctrlKey) {
					resizeNodes({ x: delta, y: 0 }, app);
				} else {
					moveNodes({ x: delta, y: 0 }, app);
				}
				e.preventDefault();
			}
			break;
		case 'ArrowUp':
			if (app.selectedNodes.length > 0 && !isTextFields) {
				if (e.metaKey || e.ctrlKey) {
					resizeNodes({ x: 0, y: -delta }, app);
				} else {
					moveNodes({ x: 0, y: -delta }, app);
				}
				e.preventDefault();
			}
			break;
		// Keyboard shortcuts for zooming in and out
		case 'ArrowDown':
			if (app.selectedNodes.length > 0 && !isTextFields) {
				if (e.metaKey || e.ctrlKey) {
					resizeNodes({ x: 0, y: delta }, app);
				} else {
					moveNodes({ x: 0, y: delta }, app);
				}
				e.preventDefault();
			}
			break;
		case '=':
			if (e.metaKey || e.ctrlKey) {
				app.zoomLevelIndex += 1;
				if (app.zoomLevelIndex > app.zoomLevels.length - 1) {
					app.zoomLevelIndex = app.zoomLevels.length - 1;
				}
				app.viewport.setZoom((app.zoomLevels[app.zoomLevelIndex] || 100) / 100, true);
				app.notifyZoom();
				e.preventDefault();
			}
			break;
		case '-':
			if (e.metaKey || e.ctrlKey) {
				app.zoomLevelIndex -= 1;
				if (app.zoomLevelIndex < 0) {
					app.zoomLevelIndex = 0;
				}
				app.viewport.setZoom((app.zoomLevels[app.zoomLevelIndex] || 100) / 100, true);
				app.notifyZoom();
				e.preventDefault();
			}
			break;
		case 'V':
		case 'v':
			if (!e.metaKey && !e.ctrlKey && !isTextFields) {
				app.selectTool('SELECT', app.nodeData as INode);
			} else if ((e.metaKey || e.ctrlKey) && e.shiftKey) {
				onPasteInPlace(localStorage.getItem('nayaClipboardData'), app);
			}
			break;
		case 'D':
		case 'd':
			if (!e.metaKey && !e.ctrlKey && !isTextFields) {
				app.selectTool('3D', app.nodeData as INode);
			}
			break;
		case 'I':
		case 'i':
			if (!e.metaKey && !e.ctrlKey && !isTextFields) {
				app.selectTool('IMAGE', app.nodeData as INode);
			}
			break;
		case 'S':
		case 's':
			if (!e.metaKey && !e.ctrlKey && !isTextFields) {
				app.selectTool('SHAPE', app.nodeData as INode);
			}
			break;
		case 'P':
		case 'p':
			if (!e.metaKey && !e.ctrlKey && !isTextFields) {
				app.selectTool('MARKUP', app.nodeData as INode);
			}
			break;
		case 'C':
		case 'c':
			if (!e.metaKey && !e.ctrlKey && !isTextFields) {
				app.selectTool('COMMENT', app.nodeData as INode);
			}
			break;
		case 'T':
		case 't':
			if (!e.metaKey && !e.ctrlKey && !isTextFields) {
				app.selectTool('TEXT', app.nodeData as INode);
			}
			break;
		case 'N':
		case 'n':
			if (!e.metaKey && !e.ctrlKey && !isTextFields) {
				app.selectTool('STICKY_NOTE', app.nodeData as INode);
			}
			break;
		case 'E':
		case 'e':
			if (!e.metaKey && !e.ctrlKey && !isTextFields) {
				app.selectTool('REACTION', app.nodeData as INode);
			}
			break;
		case 'M':
		case 'm':
			if (!e.metaKey && !e.ctrlKey && !isTextFields) {
				app.selectTool('SAMPLE_TOOL', app.nodeData as INode);
			}
			break;
		default:
			break;
	}
};

export const appKeyUpEvent = async (e: any) => {
	// if the target is input then return
	if (['input', 'textarea', 'trix-editor'].includes(e.target.localName)) return;
	// if keyboard is locked, lock keys
	if (!window.pixiApp) return;
	const app = window.pixiApp;
	if (app.keyboardLock) return;
	// }
	switch (e.keyCode) {
		case 13:
			app.render();
			app.notifyZoom();
			if (!app.isPanSelected && app.activeTool === 'SELECT') {
				const { x: worldX, y: worldY } = app.viewport.toLocal({
					x: e.clientX,
					y: e.clientY
				});
				const endPos = { x: worldX, y: worldY };
				// console.log(app.isMouseDown)

				if (app.startPos) {
					const delta = {
						delX: endPos.x - app.startPos.x,
						delY: endPos.y - app.startPos.y
					};

					// app.isMouseDown = e.data.buttons === 1;
					if (app.isMouseDown && app.highlightNode && app.startPos) {
						const { delX, delY } = delta;
						// if(app.startPos.x>endPos.x && app.startPos.y>endPos.y){
						//   console.log('here')
						//   app.highlightNode.resize(endPos.x, endPos.y,
						//  app.startPos.x - endPos.x, app.startPos.y - endPos.y);
						// }
						if (app.startPos.x > endPos.x) {
							if (app.startPos.y > endPos.y) {
								app.highlightNode.resize(
									endPos.x,
									endPos.y,
									app.startPos.x - endPos.x,
									app.startPos.y - endPos.y
								);
							} else {
								app.highlightNode.resize(
									endPos.x,
									app.startPos.y,
									app.startPos.x - endPos.x,
									endPos.y - app.startPos.y
								);
							}
						} else if (app.startPos.y > endPos.y) {
							app.highlightNode.resize(
								app.startPos.x,
								endPos.y,
								endPos.x - app.startPos.x,
								app.startPos.y - endPos.y
							);
						} else {
							app.highlightNode.resize(app.startPos.x, app.startPos.y, delX, delY);
						}
						app.checkIfAnyNodeIsWithinBounds(
							app.highlightNode?.nodeData.absoluteBounds
						);
					} else if (
						app.highlightNode?.nodeData.nodeType === 'SHAPE' &&
						app.highlightNode
					) {
						app.highlighting = false;
						app.resetInteractivity();
						app.checkIfAnyNodeIsWithinBounds(
							app.highlightNode?.nodeData.absoluteBounds
						);
						app.removeHighlightNode();
					}
				}
			}
			break;
		// ALT - deselect pan tool
		case 32:
		case 18:
			if (app.activeTool !== 'SELECT') app.pauseDragPlugin();
			if (app.isPanSelected) {
				app.isPanSelected = false;
				app.setActiveTool(app.activeTool);
				app.throughPanTool = false;
				app.resolveImageResolution();
				app.toggleShowActiveTool(true);
				app.transformer.centeredScaling = false;
			} else if (app.cropModeActive) {
				app.transformer.centeredScaling = false;
				app.transformer.lockAspectRatio = app.shiftPressed;
			}
			break;
		case 8:
			if (
				app.selectedNodes[0]?.nodeData.nodeType === ENodeType.STICKY_NOTE &&
				app.selectedNodes[0]?._editMode
			) {
				app.toggleEditMenu(true);
			} else {
				await app.deleteNodes();
				app.removeTransformer();
			}
			break;
		case 46:
			await app.deleteNodes();
			app.removeTransformer();
			app.render();
			break;
		case 16:
			app.shiftPressed = false;
			if (app.activeTool === 'SELECT') {
				// app.transformer.interactive = true;
				app.resumeDragPlugin();
				app.transformer.lockAspectRatio = true;
				if (
					(app.selectedNodes.length === 1 &&
						app.selectedNodes[0]?.nodeData.nodeType === 'IMAGE') ||
					app.selectedNodes.length > 1
				) {
					if (app.cropModeActive) app.transformer.lockAspectRatio = false;
					else if (app.transformerType !== 'text') app.transformer.lockAspectRatio = true;
					else app.transformer.lockAspectRatio = false;
				} else if (app.transformerType !== 'text') app.transformer.lockAspectRatio = false;
				else app.transformer.lockAspectRatio = true;
			}
			break;
		case 91:
		case 224:
			app.cntrlPressed = false;
			break;
		default:
			break;
	}
	// }
};

export const appMouseMoveEvent = (e: any, app: App) => {
	if (app.isPanSelected) {
		if (!app.throughPanTool) {
			if (!e.altKey) {
				app.pauseDragPlugin();
				app.setActiveTool('SELECT');
				app.notifyZoom();
				app.firstMouseDown = true;
			}
		}
	} else {
		// app.notifyZoom();
	}
	if (!app.isPanSelected && app.activeTool === 'SELECT') {
		const { x: worldX, y: worldY } = app.viewport.toLocal({ x: e.clientX, y: e.clientY });
		const endPos = { x: worldX, y: worldY };

		if (app.startPos) {
			const delta = {
				delX: endPos.x - app.startPos.x,
				delY: endPos.y - app.startPos.y
			};

			// app.isMouseDown = e.data.buttons === 1;
			if (app.isMouseDown && app.highlightNode && app.startPos) {
				const { delX, delY } = delta;
				// if(app.startPos.x>endPos.x && app.startPos.y>endPos.y){
				//   app.highlightNode.resize(endPos.x,
				// endPos.y, app.startPos.x - endPos.x, app.startPos.y - endPos.y);
				// }
				if (app.startPos.x > endPos.x) {
					if (app.startPos.y > endPos.y) {
						app.highlightNode.resize(
							endPos.x,
							endPos.y,
							app.startPos.x - endPos.x,
							app.startPos.y - endPos.y
						);
					} else {
						app.highlightNode.resize(
							endPos.x,
							app.startPos.y,
							app.startPos.x - endPos.x,
							endPos.y - app.startPos.y
						);
					}
				} else if (app.startPos.y > endPos.y) {
					app.highlightNode.resize(
						app.startPos.x,
						endPos.y,
						endPos.x - app.startPos.x,
						app.startPos.y - endPos.y
					);
				} else {
					app.highlightNode.resize(app.startPos.x, app.startPos.y, delX, delY);
				}
				app.checkIfAnyNodeIsWithinBounds(app.highlightNode?.nodeData.absoluteBounds);
			} else if (app.highlightNode?.nodeData.nodeType === 'SHAPE' && app.highlightNode) {
				app.highlighting = false;
				app.resetInteractivity();
				app.checkIfAnyNodeIsWithinBounds(app.highlightNode?.nodeData.absoluteBounds);
				app.removeHighlightNode();
			}
		}
	}
};

// @ts-ignore
export const appMouseUpEvent = (e: any, app: App) => {
	if (app.activeTool === 'SELECT' && app.allowHighlighting && app.isMouseDown) {
		app.highlighting = false;
		app.isMouseDown = false;
		app.resetInteractivity();
		app.checkIfAnyNodeIsWithinBounds(app.highlightNode?.nodeData.absoluteBounds);
		app.removeHighlightNode();
		app.toggleEditMenu(true);
		app.precomputedBounds = {};
	}
};

export const appMouseDownEvent = (e: any, app: App) => {
	if (app.canvasSelected) {
		app.canvasSelected = false;
		app.removeFrameHighlight();
		app.transformer.group = [];
		app.toggleCanvasEditMenu(false);
	}
	if (e.button === 1 && app.selectedNodes[0]?.nodeData.nodeType === 'IMAGE') {
		app.setActiveTool('PAN');
	}

	// remove transformer and selected nodes
	app.resetEditMenu();
	app.removeTransformer();
	app.toggleCanvasEditMenu(false);
	app.removeAllSelectedNodes();
	app.toggleEditMenu(false);

	const { x: worldX, y: worldY } = app.viewport.toLocal({ x: e.clientX, y: e.clientY });
	app.startPos = { x: worldX, y: worldY };
	if (app.textLinkTooltip.activeNode) {
		app.textLinkTooltip.activeNode = null;
		app.textLinkTooltip.pixiContainer.visible = false;
	}
	if (
		!app.isPanSelected &&
		app.checkIfNodesShouldBeInteractive() &&
		app.activeTool === 'SELECT' &&
		app.allowHighlighting &&
		!app.editText
	) {
		app.isMouseDown = true;
		app.highlighting = true;
		app.resetInteractivity();
		const reduxState = store.getState();
		// eslint-disable-next-line @typescript-eslint/no-shadow
		app.transformer.on('mouseup', (e: PIXI.InteractionEvent) => appMouseUpEvent(e, app));
		app.pauseDragPlugin();
		const defaultShapeData = _.cloneDeep(Shape.defaultNodeData);
		const highlightData = { ...defaultShapeData, x: worldX, y: worldY };
		if (highlightData.absoluteBounds) {
			highlightData.absoluteBounds.x = worldX;
			highlightData.absoluteBounds.y = worldY;
			highlightData.absoluteBounds.width = 0;
			highlightData.absoluteBounds.height = 0;
		}
		if (highlightData.stroke) {
			highlightData.stroke.strokeColor = reduxState.theme.color;
			highlightData.stroke.strokeWidth = 1.5;
		}
		highlightData.opacity = 0.1;
		const highlighColor = reduxState.theme.color;
		// let highlighColor = reduxState.theme.colorWithOpacity
		if (highlightData.fill) highlightData.fill.fillColor = highlighColor;
		highlightData.shapeType = 'RECTANGLE';
		highlightData.zIndex = app.getHighestZIndex() + 1;
		app.highlightNode = new SelectHighlight(app, highlightData);
		app.highlightNode?.draw();
		app.toggleEditMenu(false);
	}
};
