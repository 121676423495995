import { ReactNode, useMemo } from 'react'
import { useParams } from 'react-router'
import { ReactComponent as Brain} from 'src/assets/icons/toolbar/brain.svg'
import EstimateAI from './AI/EstimateAI'
import './HomebaseEstimate.scss'

const estimateTabs = [
  {
    extension: 'ai',
    name: 
    <>
      <Brain className="mr-1" />
      AI Estimation
    </>,
  }
]

export default function HomebaseEstimate() {
  const params = useParams<{ extension: string }>();

  const currentComponent: ReactNode = useMemo(() => {
    const { extension } = params

    switch (extension) {
      case 'ai':
        return <EstimateAI />
      default:
        return <EstimateAI />
    }
  }, [params])

  return <div id='homebase-estimate' className='homebase-main-content_layout'>
    <ul className='navigation-tabs'>
      {estimateTabs.map((tab) => <li key={tab.extension}>{tab.name}</li>)}
    </ul>
    {currentComponent}
  </div>
};