import { IUser } from '@naya_studio/types';
import mixpanel from 'mixpanel-browser';

let mixpanelInitialized = false;

/**
 * Function to initialize mixpanel
 * @param user actively logged in user
 */
const mixpanelInit = (user: IUser) => {
	if (!mixpanelInitialized) {
		try {
			const { _id: userId, userName, email: userEmail, firstName, lastName } = user;
			let fullName = userName || userEmail?.split('@')[0];
			if (firstName) {
				if (lastName) fullName = `${firstName} ${lastName}`;
				else fullName = firstName;
			}

			// Enabling the debug mode flag is useful during implementation,
			// but it's recommended you remove it for production
			const mixpanelOptions = {
				debug: process.env.NODE_ENV !== 'production',
				api_host: 'https://analytics-mixpanel.naya.studio',
				ignore_dnt: true // Override browsers Do not track setting
			};

			if (mixpanel.init) {
				// This function initializes a new instance of the Mixpanel tracking object.
				mixpanel.init('eeab86a04e9378bfab2b0a1817be7942', mixpanelOptions);

				const isGuestUser = user.userType?.includes('GUEST');
				const uniqueId = isGuestUser ? 'guest@naya.studio' : (userId as string);
				const email = userEmail as string;

				// Register a set of super properties, which are included with all events.
				// This will overwrite previous super property values.
				mixpanel.register({
					distinct_id: uniqueId, // The current user identifier.
					Name: fullName, // The current users name.
					Email: email
				});

				// Identify a user with a unique ID to track user activity across devices,
				// tie a user to their events, and create a user profile.
				// If you never call this method, unique visitors are tracked using a
				// UUID generated the first time they visit the site.
				mixpanel.identify(uniqueId as string);

				mixpanelInitialized = true;
			}
		} catch (error) {
			console.warn(
				'Mixpanel initialization failed. Early initialization or ad blocker detected',
				error
			);
		}
	}
};

const isMixpanelInitialized = () => mixpanelInitialized;

export { isMixpanelInitialized };
export default mixpanelInit;
