import { ENodeType } from '@naya_studio/types';
import * as PIXI from 'pixi.js';
import { store } from 'src';
import BaseNode from './BaseNode';
import StickyNote from './StickyNote';
import TextNode from './Text';
import fontFormatTypes from '../../editMenu/MenuOptions/FontFormat/constants';
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Trix = require('trix');

export interface DOMStyleExtended extends Partial<CSSStyleDeclaration> {
  listType?: 'ordered' | 'unordered' | 'none';
}

/**
 * @class TextInputNode
 * Creates a HTML DOM Element to enable editing of text
 */
class TextInputNode extends PIXI.Container {
  /** The DOM Element that is superimposed on the Canvas Node */
  _domInput: HTMLInputElement | HTMLTextAreaElement | HTMLDivElement | HTMLElement;

  /** The reference Canvas Node */
  private _parentNode?: TextNode | StickyNote | undefined;

  /**
     *
     * @param parentNode The reference canvas node
     */
  constructor(parentNode?: TextNode | StickyNote) {
    super();
    // Trix.config.textAttributes.foreColor = {
    //   styleProperty: 'color',
    //   inheritable: true,
    // };

    this._parentNode = parentNode;
    // this._domInput = document.createElement('div');

    const existingTrixElement = document.querySelector('trix-editor');
    if (existingTrixElement) {
      this._domInput = existingTrixElement as HTMLElement;
    } else {
      const newElement = document.createElement('trix-editor');
      newElement.onkeydown = 
        (e: any) => {
          if(e.ctrlKey &&  (e.code === "KeyB" || e.code === "KeyI" || e.code === "KeyU"))
            {e.preventDefault(); e.stopPropagation()}};
      newElement.onkeyup = 
        (e: any) => {
          if(e.ctrlKey &&  (e.code === "KeyB" || e.code === "KeyI" || e.code === "KeyU"))
            {e.preventDefault(); e.stopPropagation()}};
      newElement.className = 'trix-content';
      newElement.style.display = 'none';
      document.body.appendChild(newElement);
      this._domInput = newElement;
    }
    // this._domInput.type = "text"

    // @ts-ignore
    const toolbarId = this._domInput.attributes.toolbar.nodeValue;
    const toolbarElement = document.getElementById(toolbarId);
    toolbarElement?.remove();
    // if (toolbarElement) toolbarElement.style.display = 'none';
    // this._domInput.setAttribute('id', this._parentNode?.nodeData._id || 'text-node-input');
    document.body.appendChild(this._domInput);
    this._addListeners(this._domInput);

    // const newDivElement = document.createElement('div');
    // newDivElement.className = 'trix-content';
    // document.body.appendChild(newDivElement);

    // Trix.addEditor(newDivElement);

    // this._domInput.setAttribute('style', "display: 'inline-block");
    // this._domInput.contentEditable = 'true';
    // this._domInput.addEventListener('keydown', (event: any) => {
    //   const listType = this._parentNode.nodeData?.text?.style?.listType;
    //   if (event.key === 'Enter' && (!listType || listType === 'none')) {
    //     event.preventDefault();
    //     document.execCommand('insertLineBreak');
    //   }
    // });
    // }
    // else {
    //     this._parentNode = parentNode as TextNode
    //     this._domInput = document.createElement('input')
    //     this._domInput.type = "text"
    // }
  }

  setParentNode(parentNode: TextNode | StickyNote) {
    this._parentNode = parentNode;
  }

  getDOMPosition() {
    return this._domInput.getBoundingClientRect();
  }

  /**
     * Adds event listeners to the DOM Element
     * @param domElement The DOM Element to add listeners to
     */
  _addListeners = (domElement: HTMLElement) => {
    domElement.addEventListener('keyup', this._onInputKeyUp.bind(this));
    domElement.addEventListener('scroll', (e) => { e.stopPropagation(); });
    domElement.addEventListener('keydown', (e) => {
      let type: "bold" | "italic" | "underline" | '' = '';
      if(e.ctrlKey){
        switch(e.code){
          case "KeyB":{
            type = 'bold'
            break;
          }
          case "KeyI": {
            type = 'italic'
            break;
          }
          case "KeyU":{
            type = 'underline'
            break;
          }
          default:
            break;
        }
        const currentNode = this._parentNode?.app.selectedNodes;
        if(currentNode && type){
          for (let i = 0; i < currentNode.length; i++) {
            currentNode[i]?.edit('FONT_FORMAT', fontFormatTypes[type], !!(currentNode.length > 1 && i !== 0));
          }
        }
      }
      // @ts-ignore
      const currentBlock = domElement.editor.composition.getBlock();
      if (currentBlock) {
        const nestableAttr = currentBlock.getLastNestableAttribute();
        // @ts-ignore
        const blockAttrs = window.Trix.config.blockAttributes;
        if (blockAttrs[nestableAttr]?.listAttribute && e.key === 'Tab' && currentBlock.getNestingLevel() >= 2) {
          e.preventDefault();
          // @ts-ignore
          domElement.editor.decreaseNestingLevel();
        }
      }
    });
  };

  /// //////////////////////////////////
  ///    TEXT INPUT NODE METHODS    ///
  /// //////////////////////////////////
  /**
     * Removes the DOM Element from the DOM
     */
  removeDOMElement() {
    // const trixAttributes = this._domInput.attributes;
    // //@ts-ignore
    // const inputId = trixAttributes.input.nodeValue;
    // //@ts-ignore
    // const toolbarId = trixAttributes.toolbar.nodeValue;
    // // this._domInput.remove();
    // const trixInput = document.getElementById(inputId);
    // const trixToolbar = document.getElementById(toolbarId);
    // // trixInput?.remove();
    // // trixToolbar?.remove();
    this._domInput.style.display = 'none';
    this._parentNode = undefined;
  }

  /**
   * to get dom input text
   */
  getDOMInputText() {
    // return this._domInput.innerText;
    return this.getUpdatedText(this._domInput.innerHTML, {
      fontSize: {
        update: true,
        revert: true,
      },
    });
  }

  /**
     * Selects the text inside the DOM Element
     */
  // selectAllText() {
  //   // const selection = window.getSelection();
  //   // selection?.removeAllRanges();
  //   // const newRange = new Range();
  //   // newRange.selectNodeContents(this._domInput);
  //   // selection?.addRange(newRange);
  //   // this._domInput.setSelectionRange(0, this._domInput.value.length)
  // }

  /**
     * updates the text of nodeData
     */
  onTextStyleChange() {
    if (this._parentNode) this._parentNode.nodeData!.text!.value = this.getUpdatedText(this._domInput.innerHTML);
  } // uncomment to have text selection based updates

  /**
     * Redraws the text input node
     */
  redraw() {
    this.draw();
  }

  // Function to extract the parent node's id
  getParentNodeId = () => {
    return this._parentNode?.nodeData._id;
  }

  getUpdatedText(textHtml: string, options?: any) {
    if (!this._parentNode) return textHtml;
    // const textStyle = this._parentNode.nodeData.text?.style;
    const listLines = textHtml.split('\n');
    let updatedText = '';
    // if (textStyle
    //     && textStyle.listType
    //     && ['ordered', 'unordered'].includes(textStyle.listType)) {
    //   listLines.forEach((line) => {
    //     updatedText += `<li>${line}</li>`;
    //   });
    //   let listTag = 'ul';
    //   let paddingLeft = 1;
    //   if (textStyle.listType === 'ordered') {
    //     listTag = 'ol';
    //     paddingLeft = 1.7;
    //   }
    //   updatedText = `<${listTag} style='padding-left: ${paddingLeft}em'>${updatedText}</${listTag}>`;
    //   return updatedText;
    // }
    if (listLines.length > 1) {
      listLines.forEach((line, index) => {
        if (index === listLines.length - 1) updatedText += `${line}`;
        else updatedText += `${line}<br>`;
      });
      return updatedText;
    }
    if (options) {
      const { fontSize } = options;
      if (fontSize && fontSize.update) {
        updatedText = textHtml.replace(/font-size:\s[0-9.]+px;/gm, (x) => {
          let newFontSize = parseInt(x.match(/[0-9]+/)![0]!, 10);
          const zoomLevel = this._parentNode?.app.viewport.scale.x;
          if (zoomLevel) {
            newFontSize = fontSize.revert ? Math.ceil(newFontSize / zoomLevel) : newFontSize * zoomLevel;
          }
          // return `font-size="${newFontSize}px"`;
          return `font-size: ${newFontSize}px;`;
        });
        return updatedText;
      }
    }
    return textHtml;
  }

  /// /////////////////////////////////////
  /// TEXT INPUT NODE EVENT PROCESSORS ///
  /// /////////////////////////////////////

  /**
     * Emits a keyup event
     * @param e KeyUp Event
     */
  _onInputKeyUp = (e: Event) => {
    e.preventDefault();
    this._domInput.style.height = 'auto';

    this._domInput.style.height = `${this._domInput.scrollHeight + 20}px`;

    if (this._parentNode && this._parentNode.nodeData.nodeType === ENodeType.STICKY_NOTE) {
      (this._parentNode as StickyNote).updateHeight();
    }

    this.emit('keyup', this._domInput.innerText);
  };

  /**
   * set style of input node
   */
  setDOMNodeStyle = () => {
    if (!this._parentNode) return;
    let graphicBounds;
    if (this._parentNode.nodeData.nodeType === 'STICKY_NOTE') {
      const stickyNoteGraphic = this._parentNode.displayObject;
      // let stickyNoteText = (this._parentNode as StickyNote).textNode
      // let textObject = (stickyNoteText?.displayObject as PIXI.BitmapText)
      graphicBounds = stickyNoteGraphic?.getBounds();
    } else {
      const textObject = this._parentNode.displayObject;
      graphicBounds = textObject?.getBounds();
    }

    const reduxState = store.getState();

    /**
     * The DOM Element is positioned based on the position of the TextNode
     * and the size of the TextNode
     */
    const topLevelStyle = {
      ...TextNode.textNodeDefaults.style,
      ...this._parentNode.nodeData.text?.style,
    };
    let DOMFontSize = topLevelStyle.fontSize as number;
    const zoomLevel = this._parentNode.app.viewport.scale.x;
    if (zoomLevel) DOMFontSize *= zoomLevel;
    this._domInput.setAttribute('placeholder', 'Add Text');
    if (this._parentNode.nodeData.nodeType === 'STICKY_NOTE') {
      const { padding } = StickyNote.defaultStickyNodeProps;
      const { x: left } = padding;
      this._domInput.setAttribute(
        'style',
        `
        position: absolute; 
        top: ${graphicBounds!.y - 3}px; 
        left: ${graphicBounds!.x}px; 
        opacity: 1;
        color: ${topLevelStyle.fill};
        background: transparent;
        border: 2px solid ${reduxState.theme.colorWithOpacity};
        font-family: ${topLevelStyle.fontFamily};
        font-size: ${DOMFontSize}px;
        line-height: ${1.6};
        resize: unset;
        word-break: keep-all;
        overflow: hidden;
        width: ${graphicBounds!.width}px;
        min-height: ${graphicBounds!.height}px;
        height: auto;
        text-align: ${topLevelStyle.align};
        padding: ${(left * 2 - 2) * zoomLevel}px;
        padding-top: ${(left * 2 - 5) * zoomLevel}px;
        rows: 10;
        outline: none;
        scrollbar-width: none;
        font-weight: ${topLevelStyle.fontWeight};
        font-style: ${topLevelStyle.fontStyle};
        text-decoration: ${topLevelStyle.textDecoration}
        `,
      );
    } else {
      const { left } = BaseNode.textNodeDefaults.padding;
      this._domInput.setAttribute(
        'style',
        `
        position: absolute; 
        top: ${graphicBounds!.y - 5}px;
        left: ${graphicBounds!.x - 2}px; 
        opacity: ${this._parentNode.nodeData.opacity || 1};
        color: ${topLevelStyle.fill};
        background: transparent; 
        border: 2px solid ${reduxState.theme.colorWithOpacity};
        font-family: ${topLevelStyle.fontFamily};
        font-size: ${DOMFontSize}px;
        resize: unset;
        line-height: ${1.5};
        word-break: keep-all;
        overflow: hidden;
        width: ${graphicBounds!.width + 4}px;
        min-height: ${graphicBounds!.height}px;
        text-align: ${topLevelStyle.align};
        padding-left: ${left * zoomLevel}px;
        padding-top: 0px;
        outline: none;
        height: auto;
        rows: 500;
        overflow: visible;
        scrollbar-width: none;
        -ms-overflow-style: none;
        font-weight: ${topLevelStyle.fontWeight};
        font-style: ${topLevelStyle.fontStyle};
        text-decoration: ${topLevelStyle.textDecoration};
        padding-right: 4px
        `,
      );
    }
  };

  /**
   * update style of input node
   */
  updateDOMNodeStyle = (domStyle: DOMStyleExtended) => {
    const domKeys = Object.keys(domStyle);
    if (domKeys && domKeys.length > 0) {
      domKeys.forEach((domKey) => {
        if (domKey === 'listType') {
          // const oldRange = window.getSelection()?.getRangeAt(0).cloneRange();
          this._domInput.innerHTML = this.getUpdatedText(this._parentNode?.nodeData.text?.value || '') || '';
          // if (oldRange) {
          //   window.getSelection()?.removeAllRanges();
          //   window.getSelection()?.addRange(oldRange);
          // }
        } else if (domKey === 'fontSize') {
          const zoomLevel = this._parentNode?.app.viewport.scale.x;
          if (zoomLevel) this._domInput.style[domKey] = `${parseInt(domStyle[domKey]!, 10) * zoomLevel}px`;
        } else {
          // @ts-ignore
          this._domInput.style[domKey] = domStyle[domKey];
        }
      });
    }
    // this._domInput.style.height = `${this._domInput.scrollHeight + 10}px`;
    this._domInput.focus();
  };

  /**
     * Re-draws the DOM Element on viewport change
     */
  _onViewportChange = () => {
    this.setDOMNodeStyle();
    if (this._parentNode) {
      this._domInput.innerHTML = this.getUpdatedText(this._parentNode?.nodeData.text?.value || '', {
        fontSize: {
          update: true,
          revert: false,
        },
      });
    }
  };

  /// /////////////////////////////////////
  ///   TEXT INPUT NODE RENDER METHOD  ///
  /// /////////////////////////////////////

  /**
     * Draws the DOM Element on screen
     */
  draw = () => {
    if (!this._parentNode) return;
    this._domInput.style.display = 'block';
    /** Font Size is calculated based on fontSize of TextNode and zoom Level
         *
        */
    // let fontSize = _.get(this._parentNode, 'nodeData.text.style.fontSize', 14)
    this.setDOMNodeStyle();
    this._domInput.className = 'text-input-node';
    this._domInput.innerHTML = this.getUpdatedText(this._parentNode.nodeData.text?.value || '', {
      fontSize: {
        update: true,
        revert: false,
      },
    }) || '';
    /** Adds focus to the textarea when its drawn */
    this._domInput.focus();
  };
}

export default TextInputNode;
