import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';
import { userRouter } from 'src/endpoints/accounts-api';
import { getGatewayKey } from 'src/util/helper/queryString';

const qs = `?${getGatewayKey()}`;

// Search slice to handle db searches
export const searchSlice = createApi({
	reducerPath: 'search',
	baseQuery: fetchBaseQuery({ baseUrl: `${userRouter}`, credentials: 'include' }),
	tagTypes: ['searchHomebase'],
	endpoints: (builder) => ({
		/**
		 * Gets the search results on the homebase
		 */
		searchHomebase: builder.query({
			query: ({ userId, query, payload }) => ({
				url: `/search/${userId}${qs}&query=${query}`,
				method: 'PUT',
				body: payload
			}),
			providesTags: ['searchHomebase']
		})
	})
});
export const { useLazySearchHomebaseQuery } = searchSlice;
