import { FC, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { WorkOS } from '@workos-inc/node';
import { loginEndpoint } from 'src/endpoints/authentication-api';
import { AuthContext } from '../auth/AuthProvider';
import { AuthComponent } from '../authComponent/AuthComponent';
import './Register.scss';
import { RegisterTypes } from './Register.types';
import { getGatewayKey } from '../../util/helper/queryString';

const qs = `?${getGatewayKey()}`;

const Register: FC<RegisterTypes> = ({ type }) => {
	const auth = useContext(AuthContext);
	const { status } = auth;

	useEffect(() => {
		// If status is SIGNED_OUT and route is REGISTER, then redirect to login workOS auth screen
		if (['SIGNED_OUT', 'GUEST'].includes(status) && type === 'REGISTER') {
			const isI2R = window.sessionStorage.getItem('i2r');
			const workos = new WorkOS(process.env.REACT_APP_WORKOS_API_KEY);
			const clientId = process.env.REACT_APP_WORKOS_CLIENT_ID || '';
			const authorizationUrl = workos.userManagement.getAuthorizationUrl({
				// Specify that we'd like AuthKit to handle the authentication flow
				provider: 'authkit',
				// The callback endpoint that WorkOS will redirect to after a user authenticates
				redirectUri: loginEndpoint + qs,
				clientId,
				state: encodeURIComponent(`${window.location.href}${isI2R ? '?isI2R=true' : ''}`),
				screenHint: 'sign-up'
			});
			window.location.href = authorizationUrl;
		}
	}, [status]);

	return (
		<>
			{/* Set the title that is shown in browser tab */}
			<Helmet>
				<title>Register - Naya</title>
			</Helmet>
			<div id="register-component">
				<AuthComponent show close={() => {}} view={type} />
			</div>
		</>
	);
};

export default Register;
