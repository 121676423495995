import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	TAddNodesArg,
	TEditNodesArgs,
	TLoadNodeArgs,
	TRemoveImageBackground,
	TUploadTransparentBackground
} from 'src/types/argTypes';
import axios from 'axios';
import { getGatewayKey } from 'src/util/helper/queryString';
import { nodeRouter } from 'src/endpoints/projects-api';
import { TAddNodesPayload, TEditNodesPayload } from 'src/types/payloadTypes';
import { EDisplayImageType, IBlock, IImageNode, INode, IUser, TImage } from '@naya_studio/types';
import { REMOVE_BACKGROUND_ENDPOINT } from 'src/endpoints/upload-endpoints';
import { storage } from 'src/util/storage/firebaseStorage';
import _ from 'lodash';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import getUserFromRedux from 'src/util/helper/user';
import { AxiosResponseWrapper } from '../actions/types';
import { ReduxState } from '../reducers/root.types';
import { undoAction } from '../actions/undoRedoActions';
import { EActionType } from '../reducers/undoRedo/undoActionHistory.types';
import { generateIdsFromUrl } from './util';

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
const qs = `?${getGatewayKey()}`;

/**
 * Load node API call by id
 */
export const loadNode = createAsyncThunk(
	'nodes/loadNode',
	async (payload: TLoadNodeArgs, thunkApi) => {
		try {
			const response = await axios.get(`${nodeRouter}/${payload.data.id}${qs}`);
			const node = response.data.payload as INode;

			return node;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * API to add multiple new nodes
 */
// NOTE: PASS CREATEDBY AND UPDATED BY IN NODES
export const addNodes = createAsyncThunk(
	'nodes/addNodes',
	async (payload: TAddNodesArg, thunkApi) => {
		try {
			const reduxState = thunkApi.getState() as ReduxState;
			const user: IUser = getUserFromRedux();
			const { blocks: allBlocks } = reduxState;
			const { projectId, stageId, blockId } = payload.data;
			const userId = user._id as string;
			const { blockType } = allBlocks.data[blockId] as IBlock;

			const data: TAddNodesPayload = {
				allNodesToSave: payload.data.nodes,
				blockId,
				stageId,
				projectId,
				user: userId,
				blockType,
				lastUpdatedInfo: {
					lastUpdatedBy: user._id as string
				}
			};

			const response = await axios.post<AxiosResponseWrapper<INode[]>>(
				`${nodeRouter}/add-nodes/${qs}`,
				{
					payload: data
				}
			);

			if (response.status !== 200) throw new Error(response.statusText);

			if (payload.next && typeof payload.next === 'function') {
				payload.next();
			}

			return {
				blockId,
				nodes: response.data.payload
			};
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * API to edit multiple nodes
 */
// NOTE: nodes that would be passed here - make sure lastUpdated by and version is incremented before hand
export const editNodes = createAsyncThunk(
	'nodes/editNodes',
	async (payload: TEditNodesArgs, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();
			const { projectId, stageId, blockId, nodes } = payload.data;
			const nodeIds = nodes.map((node) => node._id as string);

			const data: TEditNodesPayload = {
				allNodesToUpdate: nodes,
				nodeIds,
				blockId,
				stageId,
				projectId,
				user: user._id as string,
				lastUpdatedInfo: {
					lastUpdatedBy: user._id as string
				}
			};

			const response = await axios.put<AxiosResponseWrapper<string[]>>(
				`${nodeRouter}/edit-nodes/${qs}`,
				{
					payload: data
				}
			);

			if (response.status !== 200) throw new Error('Failed to edit node.');

			if (payload.next && typeof payload.next === 'function') {
				payload.next();
			}

			return {
				blockId,
				nodeIds: response.data.payload
			};
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * Upload the file and get the uploaded file data
 */
export const handleUploadTransparentBackgroundImage = createAsyncThunk(
	'nodes/handleUploadTransparentBackgroundImage',
	async (argData: TUploadTransparentBackground, thunkApi) => {
		try {
			const { fileData, originalImg, nodeData } = argData.data;
			const dateFileName = `${Date.now()}.${fileData.name.split('.').pop()}`;
			const storageRef = ref(storage, `${dateFileName}`);
			await uploadBytesResumable(storageRef, fileData);
			getDownloadURL(storageRef)
				.then((url) => {
					const prevData = _.cloneDeep(nodeData);
					const payload = nodeData;
					const imageData = (payload as IImageNode).image as TImage;
					if (payload as IImageNode) {
						if (imageData!.alternateURLs) {
							imageData!.alternateURLs!.transparentBackground = url as string;
							imageData!.alternateURLs!.original = originalImg;
						} else {
							imageData.alternateURLs = {
								transparentBackground: url as string,
								original: originalImg
							};
						}
						imageData.displayImageType = 'TRANSPARENT_BACKGROUND' as EDisplayImageType;
						imageData.src = url as string;
						payload.image = imageData;

						undoAction('EDIT' as EActionType, prevData, payload, false);

						// extracting url params to access ids
						const { blockId, stageId, projectId } = generateIdsFromUrl();
						// extracting redux
						const reduxState = thunkApi.getState() as ReduxState;

						// generating API payload for edit nodes action
						const apiPayload: TEditNodesArgs = {
							data: {
								nodes: [payload],
								blockId,
								projectId,
								stageId
							},
							prevState: {
								prevBlocks: reduxState.nodes.data
							},
							next: argData.next
						};

						thunkApi.dispatch(editNodes(apiPayload));
					}
				})
				.catch((e: any) => {
					console.error('upload error', e);
				});
			return true;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

// Function to remove backgrounnd of an image
export const removeImageBackground = createAsyncThunk(
	'nodes/removeImageBackground',
	async (payload: TRemoveImageBackground, thunkApi) => {
		try {
			const { img, canvasId, nodeData } = payload.data;

			const { projectId } = generateIdsFromUrl();
			const response = await axios.post<AxiosResponseWrapper<any>>(
				`${REMOVE_BACKGROUND_ENDPOINT}`,
				{
					payload: {
						imageLink: img,
						projectId
					}
				}
			);
			const blob = new Blob([new Uint8Array(response.data.fileData.data).buffer]);

			const file = new File([blob], 'transparent_background.png');
			const apiPayload = {
				data: {
					fileData: file,
					originalImg: img,
					nodeData,
					canvasId
				},
				next: payload.next
			};
			await thunkApi.dispatch(handleUploadTransparentBackgroundImage(apiPayload));

			if (response.status !== 200) {
				throw new Error('Error removing background node');
			}
			return {
				blockId: canvasId
			};
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);
