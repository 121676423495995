// import { INode } from "@naya_studio/types";
// import App from "../App";
import * as PIXI from 'pixi.js';
import BaseNode from './BaseNode';

class OnboardingImage extends BaseNode {
  // constructor(app: App, nodeData: INode) {
  //   super(app, nodeData)
  // }

  /**
   * Draws a node object with image
   *
   */
  draw = async () => {
    try {
      if (this.displayObject) {
        this.app.viewport.removeChild(this.displayObject);
      }

      if (this.nodeData) {
        const container = new PIXI.Container();

        // start draw
        const background = new PIXI.Graphics();
        background.beginFill(BaseNode.getColorValue(this.nodeData.fill?.fillColor));
        background.lineStyle(1, 0x696969);

        background.scale.set(1);

        const {
          absoluteBounds: nodeDataBounds,
          scale,
          rotation,
          image,
          text: textData,
          zIndex,
        } = this.nodeData;

        const defaultAbsoluteBounds = {
          x: window.innerWidth / 2,
          y: window.innerHeight / 2,
          height: 150,
          width: 150,
        };

        if (!textData || !textData.value) { return; }

        // onboarding answer
        const { value } = textData;

        // used for multi-selects
        const answers = value.split(',');

        // default absoluteBounds
        const absoluteBounds = nodeDataBounds || defaultAbsoluteBounds;

        container.zIndex = zIndex as number || 10;

        if (image && image.src) {
          // draw one rectangle
          background.drawRoundedRect(0, 0, absoluteBounds.width!, absoluteBounds.height!, 13);
          // finish background and add
          background.endFill();
          container.addChild(background);

          // create text
          const text = new PIXI.Text(value, {
            fontFamily: 'Arial',
            fontSize: 14,
            wordWrap: true,
            wordWrapWidth: 115,
            align: 'left',
          });
          text.setTransform(75, 130);
          // fixes bluriness
          text.resolution = 3;
          // sets origin center so text is always centered
          text.anchor.set(0.5);

          // create image
          const texture = await PIXI.Texture.fromURL(image.src);
          const sprite = new PIXI.Sprite(texture);
          sprite.x = 30;
          sprite.y = 10;
          sprite.height = 90;
          sprite.width = 90;

          container.addChild(sprite);
          container.addChild(text);
        } else {
          const { length } = answers;

          // create big rectangle based on length of answers
          background.drawRoundedRect(
            0,
            0,
            absoluteBounds.width! * length,
            absoluteBounds.height!,
            13,
          );
          // finish background and add
          background.endFill();
          container.addChild(background);

          // create PIXI.text for each word and addChild
          answers.forEach((a, i) => {
            // create text
            const text = new PIXI.Text(a, {
              fontFamily: 'Arial',
              fontSize: 14,
              wordWrap: true,
              wordWrapWidth: 115,
              align: 'left',
            });
            // 150*i is the number of rectangles there are
            text.setTransform(75 + (150 * i), 75);
            // fixes bluriness
            text.resolution = 3;
            // sets origin center so text is always centered
            text.anchor.set(0.5);

            container.addChild(text);
          });
        }

        container.setTransform(
          absoluteBounds.x!,
          absoluteBounds.y!,
          scale?.scaleX,
          scale?.scaleY,
          rotation,
        );

        this.displayObject = container;
        // shape, markup, comment, and reaction should NOT be interactive
        this.displayObject.interactive = this.app.checkIfNodesShouldBeInteractive();
        this.displayObject.name = 'ONBOARDING_IMAGE';
        this.app.viewport.addChild(this.displayObject);
        this.displayObject.on('click', this.onClick);
        this.displayObject.on('mousedown', this.onMouseDown);
        this.displayObject.on('mouseup', this.onMouseUp);
        this.displayObject.on('mouseover', this.onMouseOver);
        this.displayObject.on('mouseout', this.onMouseOut);
        this.displayObject.on('rightclick', this.rightClick);
        this.render();
      }
    } catch (e) {
      console.error(e);
    }
  };
}

export default OnboardingImage;
