import {
	EUserRole,
	IProject,
	IProjectDetail,
	IProjectGroup,
	IUser,
	TInvitedEmail,
	TUserAndRole
} from '@naya_studio/types';
import { clone } from 'lodash';

/**
 * Function to update the array and insert the user in it, also handles override case
 * @param isInvitedUser if user is invited or he exist in database
 * @param userId user id or email of user to invite
 * @param role role of user
 * @param existingUsers array of existing users or invited emails
 * @param override if we're to override
 * @returns updated array
 */
export const addUserInArray = (
	isInvitedUser: boolean,
	userId: string,
	role: EUserRole | keyof typeof EUserRole | '',
	existingUsers: (TUserAndRole | TInvitedEmail)[],
	override: boolean = false
) => {
	try {
		if (isInvitedUser) {
			const user = {
				email: userId,
				role
			} as TInvitedEmail;
			const userToAdd = user as TInvitedEmail;
			let invitedEmails = existingUsers as TInvitedEmail[];
			const userFound = invitedEmails?.findIndex(
				(u) => (u as TInvitedEmail).email === userToAdd.email
			);
			if (userFound !== -1) {
				if (override) invitedEmails![userFound as number] = userToAdd;
			} else {
				invitedEmails = [...invitedEmails, userToAdd];
			}

			return invitedEmails;
		}
		const user = {
			user: userId,
			role
		} as TUserAndRole;

		const userToAdd = user as TUserAndRole;
		let users = existingUsers as TUserAndRole[];
		const userFound = users?.findIndex((u) => (u as TUserAndRole).user === userToAdd.user);
		if (userFound !== -1) {
			if (override) users![userFound as number] = userToAdd;
		} else {
			users = [...users, userToAdd];
		}

		return users;
	} catch (error) {
		console.error('Failed to update user array: ', error);
		return existingUsers;
	}
};

/**
 * Function to remove user from array
 * @param isInvitedUser  if user is invited or he exist in database
 * @param userId user id or email of user to remove
 * @param existingUsers array of existing users or invited emails
 * @returns updated array
 */
export const removeUserFromArray = (
	isInvitedUser: boolean,
	userId: string,
	existingUsers: (TUserAndRole | TInvitedEmail)[]
) => {
	try {
		if (isInvitedUser) {
			return existingUsers?.filter((x) => (x as TInvitedEmail).email !== userId);
		}
		return existingUsers?.filter((x) => ((x as TUserAndRole).user as string) !== userId);
	} catch (error) {
		console.error('Failed to update user array: ', error);
		return existingUsers;
	}
};

/**
 * Function to delete the project from users project group and return update user
 * @param state IUser in redux
 * @param projectId id of the project
 * @param groupId id of the active group
 * @returns updated user
 */
export const deleteProjectFromUsersRedux = (state: IUser, projectId: string, groupId?: string) => {
	try {
		const { projectGroups, defaultProjectGroup } = state;
		if (!projectGroups) return state;

		const activeGroupIndex = projectGroups.findIndex(
			(group) => ((group as IProjectGroup)._id as string) === (groupId || defaultProjectGroup)
		);

		if (activeGroupIndex !== -1) {
			const activeGroup = projectGroups[activeGroupIndex] as IProjectGroup;
			if (activeGroup && activeGroup.projects) {
				const index = (activeGroup.projects as IProject[]).findIndex(
					(p) => (p._id as string) === projectId
				);

				if (index !== -1) {
					const projectToDelete = activeGroup.projects[index] as IProject;
					activeGroup.projects[index] = {
						...projectToDelete,
						statuses: (projectToDelete.statuses || []).concat({
							status: 'DELETED'
						})
					};
				}

				state.projectGroups![activeGroupIndex] = activeGroup;
			}
		}

		return state;
	} catch (error) {
		console.error('Failed to update state: ', error);
		return state;
	}
};

/**
 * Function to revert the deleted project from users project group and return update user
 * @param state IUser in redux
 * @param projectId id of the project
 * @returns updated user
 */
export const revertDeletedProjectFromUsersRedux = (state: IUser, projectId: string) => {
	try {
		const { projectGroups, defaultProjectGroup } = state;
		if (!projectGroups) return state;

		const defaultGroupIndex = projectGroups.findIndex(
			(group) => ((group as IProjectGroup)._id as string) === defaultProjectGroup
		);

		if (defaultGroupIndex !== -1) {
			const activeGroup = projectGroups[defaultGroupIndex] as IProjectGroup;
			if (activeGroup && activeGroup.projects) {
				const index = (activeGroup.projects as IProject[]).findIndex(
					(p) => (p._id as string) === projectId
				);

				if (index !== -1) {
					const projectToDelete = activeGroup.projects[index] as IProject;
					if (projectToDelete.statuses) projectToDelete.statuses.pop();
					activeGroup.projects[index] = projectToDelete;
				}

				state.projectGroups![defaultGroupIndex] = activeGroup;
			}
		}

		return state;
	} catch (error) {
		console.error('Failed to update state: ', error);
		return state;
	}
};

/**
 * Function to update the user redux data updatedAt field
 * @param state
 * @param projectId
 */
export const updateLastUpdatedAtToUserData = (
	state: IUser,
	projectId: string,
	isGroupOpened?: boolean
) => {
	try {
		const { projectGroups, sharedProjects } = state;
		if (projectGroups) {
			if (isGroupOpened) {
				projectGroups.forEach((group) => {
					const projectGroup = group as IProjectGroup;
					if (projectGroup.projects && projectGroup.name !== 'DEFAULT') {
						const { projects } = projectGroup;
						const projectInGroup = projects.find(
							(p) => (p as IProject)._id === projectId
						);
						if (projectInGroup) {
							projectGroup.updatedAt = new Date().toISOString();
						}
					}
				});
			} else {
				// Find the project group with name "DEFAULT"
				const defaultProjectGroup = projectGroups.find(
					(group) => (group as IProjectGroup).name === 'DEFAULT'
				) as IProjectGroup;
				if (defaultProjectGroup && defaultProjectGroup.projects) {
					// Find project in default project group's projects array
					const foundProjectInDefaultGroup = defaultProjectGroup.projects.find(
						(proj) => (proj as IProject)._id === projectId
					) as IProject;
					if (foundProjectInDefaultGroup) {
						foundProjectInDefaultGroup.updatedAt = new Date().toISOString();
					} else if (sharedProjects) {
						// Find project in sharedProjects array
						const foundProjectInShared = sharedProjects.find(
							(proj) => (proj as IProject)._id === projectId
						) as IProject;
						if (foundProjectInShared) {
							foundProjectInShared.updatedAt = new Date().toISOString();
						}
					}
				}
			}
		}
	} catch (error) {
		console.error('Failed to update last updated at in user: ', error);
	}
};

/**
 * Function to update project/group details in user
 * @param state user in redux
 * @param projectId id of the project to update
 * @param parentGroupId id of the group
 * @param isGroup whether the record to update is group or not
 * @param updates updates to apply
 * @returns updated user
 */
export const updateProjectDetailsForUser = (
	state: IUser,
	projectId: string,
	parentGroupId: string,
	isGroup: boolean,
	updates: Partial<IProjectDetail>
) => {
	try {
		const allGroups = state.projectGroups as IProjectGroup[];
		const parentGroupIndex = allGroups.findIndex((grp) => grp._id === parentGroupId);

		if (parentGroupIndex !== -1) {
			const parentGroup = allGroups[parentGroupIndex] as IProjectGroup;
			if (isGroup) {
				const updatedGroup = {
					...parentGroup,
					...updates
				};

				allGroups[parentGroupIndex] = updatedGroup;
			} else {
				const allJourneys = parentGroup.projects as IProject[];
				const journeyIndex = allJourneys.findIndex((jou) => jou._id === projectId);

				if (journeyIndex !== -1) {
					const journey = allJourneys[journeyIndex] as IProject;
					const updatedJourney: IProject = {
						...journey,
						projectDetails: {
							...journey.projectDetails,
							...updates
						}
					};

					allJourneys[journeyIndex] = updatedJourney;
				} else {
					const sharedProjects = state.sharedProjects as IProject[];
					const sharedJourneyIndex = sharedProjects.findIndex(
						(jou) => jou._id === projectId
					);

					if (sharedJourneyIndex !== -1) {
						const journey = sharedProjects[sharedJourneyIndex] as IProject;
						const updatedJourney: IProject = {
							...journey,
							projectDetails: {
								...journey.projectDetails,
								...updates
							}
						};

						sharedProjects[sharedJourneyIndex] = updatedJourney;
						state.sharedProjects = sharedProjects;
					}
				}

				parentGroup.projects = allJourneys;
				allGroups[parentGroupIndex] = parentGroup;
			}
		}

		state.projectGroups = allGroups;
		return state;
	} catch (error) {
		console.error('Error updating project details for user', error);
		return state;
	}
};

/**
 * Function to find a journey in a group and update it
 * @param activeGroup group to look into
 * @param projectId id of the project
 * @param updates updates to apply
 * @returns updated group
 */
export const findAndUpdateJourneyInGroup = (
	activeGroup: IProjectGroup,
	projectId: string,
	updates: Partial<IProject | IProjectDetail>,
	isProjectDetails: boolean
) => {
	try {
		const allJourneys = clone(activeGroup.projects) as IProject[];

		const journeyIndex = allJourneys.findIndex((jou) => jou._id === projectId);
		if (journeyIndex !== -1) {
			const journey = allJourneys[journeyIndex] as IProject;

			if (isProjectDetails) {
				allJourneys[journeyIndex]!.projectDetails = {
					...allJourneys[journeyIndex]!.projectDetails,
					...updates
				};
			} else {
				allJourneys[journeyIndex] = {
					...journey,
					...updates
				};
			}

			activeGroup.projects = allJourneys;
		}
		return activeGroup;
	} catch (error) {
		console.error('Failed to update group', error);
		return activeGroup;
	}
};
