import { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router';
import useUser from 'src/redux/hooks/user';
import { getUserBlocksCount } from 'src/util/helper/user';
import './CountDown.scss';

type CounterList = {
	key: number;
	value: number;
	isActive: boolean;
};

const formatNumber = (number: number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const CountDown = () => {
	const history = useHistory();
	const { user } = useUser();
	const [blocksCreated, setBlocksCreated] = useState<number>(0);
	const [counterList, setCounterList] = useState<CounterList[]>();
	const TARGET_BLOCKS = '1000000';

	// Function to handle page to redirect user to on button click
	const handleLoginRedirect = () => {
		if (user?._id) {
			history.push('/studio');
		} else {
			const currentUrl = encodeURIComponent(window.location.pathname);
			history.push(`/login/${currentUrl}`);
		}
	};

	// Function to get the block tiles
	const getTotal = (nayaBlocks: number) => {
		const numDigits = TARGET_BLOCKS.length;
		let newNumber = '';

		// If nayaBlocks is equal to TARGET_BLOCKS, set newNumber to TARGET_BLOCKS
		if (nayaBlocks >= Number(TARGET_BLOCKS)) {
			newNumber = TARGET_BLOCKS;
		} else {
			// Extract inactive part of the target number
			let inactivePart = TARGET_BLOCKS.slice(0, numDigits - nayaBlocks.toString().length);
			if (inactivePart.length > 0) {
				inactivePart = `0${inactivePart.slice(1)}`;
			}

			// Create the new number with active and inactive parts
			newNumber = inactivePart + nayaBlocks.toString();
		}

		// Map each digit to an object with isActive field
		const resultArray = newNumber.split('').map((digit, index) => ({
			key: index,
			value: Number(digit),
			isActive: index >= newNumber.length - nayaBlocks.toString().length
		}));

		setCounterList(resultArray);
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await getUserBlocksCount(user._id as string);
				if (data.totalBlocksByUser) {
					setBlocksCreated(data.totalBlocksByUser);
				}
				if (data.totalBlocksInNaya) {
					getTotal(data.totalBlocksInNaya);
				}
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		// Function to fetch data initially and then repeatedly every minute
		const fetchDataAndRepeat = () => {
			fetchData();
			const id = setInterval(fetchData, 10000);
			return id;
		};

		const intervalId = fetchDataAndRepeat();

		// Clean up function to clear the interval on component unmount
		return () => clearInterval(intervalId);
	}, [user._id]);

	// Returns the user's current level depending on the blocks added
	const getBadgeLevel = (userBlocks: number) => {
		switch (true) {
			case userBlocks >= 10 && userBlocks <= 50:
				return 'Block Baron';
			case userBlocks >= 51 && userBlocks <= 100:
				return 'Wall Weaver';
			case userBlocks >= 101 && userBlocks <= 300:
				return 'Master Mason';
			case userBlocks >= 301 && userBlocks <= 1000:
				return 'High Rise Hero';
			case userBlocks >= 1001 && userBlocks <= 2500:
				return 'Skyline Sculptor';
			case userBlocks >= 2501 && userBlocks <= 7500:
				return 'Vertical Visionary';
			case userBlocks >= 7501 && userBlocks <= 15000:
				return 'City Builder';
			case userBlocks >= 15001:
				return 'World Wonder';
			default:
				return '';
		}
	};

	// Returns random congratulation exclamation
	const getRandomIntroText = (userBlocks: number) => {
		switch (true) {
			case userBlocks >= 10 && userBlocks <= 50:
				return 'High five, ';
			case userBlocks >= 51 && userBlocks <= 100:
				return 'Drumroll please...';
			case userBlocks >= 101 && userBlocks <= 300:
				return 'Woohoo! Amazing work ';
			case userBlocks >= 301 && userBlocks <= 1000:
				return 'Wow, ';
			case userBlocks >= 1001 && userBlocks <= 2500:
				return 'It’s time to celebrate! ';
			case userBlocks >= 2501 && userBlocks <= 7500:
				return 'Guess what ';
			case userBlocks >= 7501 && userBlocks <= 15000:
				return 'It’s time to celebrate! ';
			case userBlocks >= 15001:
				return "Party time! We're celebrating you, ";
			default:
				return '';
		}
	};

	// Random text generated based on user's level
	const renderText = useMemo(() => {
		if (user?._id) {
			if (getBadgeLevel(blocksCreated) === 'World Wonder') {
				return (
					<div>
						<p className="countdown-content">
							Wow,
							<span className="tw-text-[#4F00C1]"> {user.email}</span>, you have
							reached our highest achievement.
						</p>
						<p className="countdown-content">
							{' '}
							You’ve contributed{' '}
							<span className="tw-text-[#4F00C1] tw-font-randBold">
								{' '}
								{formatNumber(blocksCreated || 0)} blocks{' '}
							</span>
							so far and are now a
							<span className="tw-text-[#4F00C1] tw-font-randBold">
								{' '}
								{getBadgeLevel(blocksCreated)}
							</span>
							!.
						</p>
					</div>
				);
			}
			return (
				<div>
					{blocksCreated >= 10 && (
						<p className="countdown-content">
							{getRandomIntroText(blocksCreated)}
							<span className="tw-text-[#4F00C1]">{user.email}</span>, you’re a
							<button
								type="button"
								onClick={() => {
									history.push('/profile');
								}}
							>
								<span className="tw-text-[#4F00C1] tw-font-randBold">
									{' '}
									{getBadgeLevel(blocksCreated)}
								</span>
							</button>
							.
						</p>
					)}
					<p className="countdown-content">
						You’ve contributed{' '}
						<span className="tw-text-[#4F00C1] tw-font-randBold">
							{' '}
							{formatNumber(blocksCreated || 0)} block{blocksCreated > 1 && 's'}{' '}
						</span>{' '}
						so far!
					</p>
				</div>
			);
		}

		return (
			<p className="countdown-content">It looks like you haven’t made any blocks recently!</p>
		);
	}, [user, blocksCreated]);

	return (
		<article className="tw-flex tw-items-center tw-flex-col tw-justify-center tw-h-screen tw-text-center countdown">
			<h2 className="countdown-title tw-text-4xl">Total Blocks on Naya</h2>
			<div className="tw-flex tw-mt-6 tw-mb-8 countdown-container">
				{counterList?.map((block) => (
					<p
						className={`countdown-number tw-rounded-xl tw-flex tw-items-center tw-justify-center  ${
							block.isActive ? 'countdown-active' : ''
						}`}
						key={block.value}
					>
						{block.value}
					</p>
				))}
			</div>
			{renderText}
			<div
				className="cta-btn-wrapper tw-flex tw-items-center tw-justify-center tw-cursor-pointer redirect-btn-wrapper tw-mt-4"
				role="presentation"
				onClick={handleLoginRedirect}
			>
				<button className="redirect-btn tw-font-randMedium tw-text-sm" type="button">
					Add more Blocks today!
				</button>
			</div>
		</article>
	);
};

export default CountDown;
