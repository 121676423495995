import { IObserver } from '@naya_studio/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { observerRouter } from 'src/endpoints/projects-api';
import { TWatchObserver } from 'src/types/argTypes';
import { getGatewayKey } from 'src/util/helper/queryString';
import { TEditObserverPayload } from '../reducers/root.types';

const qs = `?${getGatewayKey()}`;

/**
 * API action to create project observer
 */
export const createObserver = createAsyncThunk(
	'project/create-observer',
	async (payload: IObserver, thunkApi) => {
		try {
			await axios.post(`${observerRouter}${qs}`, payload);

			return payload;
		} catch (error) {
			console.error('createObserver error: ', error);
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('Failed to create observer');
		}
	}
);

/**
 * API action to update project observer
 */
export const updateObserver = createAsyncThunk(
	'project/edit-observer',
	async (payload: TEditObserverPayload, thunkApi) => {
		try {
			await axios.put(`${observerRouter}${qs}`, payload);

			return payload;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('Failed to create observer');
		}
	}
);

/**
 * API action to create observer channel
 */
export const createFolderWatch = createAsyncThunk(
	'google/create-watch',
	async (payload: TWatchObserver, thunkApi) => {
		try {
			const response = await axios.post(
				`${observerRouter}/google/create-watch${qs}`,
				payload
			);
			if (response.status === 200) {
				return response.data.data as IObserver;
			}
			return thunkApi.rejectWithValue('create watch error');
		} catch (error) {
			console.error('createFolderWatch error: ', error);
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('Failed to create observer');
		}
	}
);

/**
 * API action to remove observer channel
 */
export const stopFolderWatch = createAsyncThunk(
	'project/stop-folder-watch',
	async (payload: TWatchObserver, thunkApi) => {
		try {
			const res = await axios.put(`${observerRouter}/google/delete-watch${qs}`, payload);
			if (res.status === 200) return res.data.data as IObserver;
			return thunkApi.rejectWithValue('Failed to delete watch');
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('Failed to delete observer');
		}
	}
);

/**
 * API action to renew observer channels
 */
export const renewFolderWatch = createAsyncThunk(
	'project/renew-folder-watch',
	async (payload: TWatchObserver, thunkApi) => {
		try {
			const res = await axios.put(`${observerRouter}/google/renew-watch${qs}`, payload);
			if (res.status === 200) return res.data.data as IObserver;
			return thunkApi.rejectWithValue('Failed to delete watch');
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('Failed to delete observer');
		}
	}
);
