import { FC } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { SnapshotProps } from './Snapshot.types';
import './Snapshot.scss';
import snapshot from '../../../../../assets/icons/Snapshot.svg';

const Snapshot : FC<SnapshotProps> = ({
  app,
}) => {
  const handleClick = () => {
    const currentNode = app.selectedNodes && app.selectedNodes[0];
    if (currentNode) currentNode.edit('SNAPSHOT', {}, false);
  };

  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 1000, hide: 0 }}
      overlay={(
        <Tooltip
          id="button-tooltip-2"
          style={{
            fontSize: '12px',
            borderRadius: '2px',
            lineHeight: '16px',
            marginTop: '8px',
          }}
        >
          Snapshot
        </Tooltip>
    )}
    >
      <img src={snapshot} className="snapshotImage" alt="" onClick={handleClick} role="presentation" />
    </OverlayTrigger>

  );
};
export default Snapshot;
