import { combineReducers } from 'redux';
import loaderReducer from './loader/loader';
import projectSlice from '../features/projects';
import themeReducer from './theme/theme';
import userSlice from '../features/user';
import integrationSlice from '../features/integration';
import openCommentReducer from './openComment/openComment';
import searchCommentReducer from './searchComment/searchComment';
import unodActionReducer from './undoRedo/undoActionHistory';
import redoActionReducer from './undoRedo/redoActionHistory';
import userGroupReducer from './userGroup/userGroup';
import uploadingStatusReducer from './uploadingStatus/uploadingStatus';
import textToImageReducer from './textToImage/textToImage';
import estimationAI from './estimationAI/estimationAI';
import communityAI from './communityAI/communityAI';
import snackBarReducer from './snackBar/snackBar';
import canvasReducer from './canvas/canvas';
import { prototypingReducer } from './prototyping/prototyping';
import blocksSlice from '../features/blocks';
import stagesSlice from '../features/stages';
import nodesSlice from '../features/nodes';
import feedbackSlice from '../features/feedbacks';
import projectUserSlice from '../features/projectUsers';
import { notificationSlice } from '../features/api/notification';
import newCommentSlice from '../features/newComment';
import notesSlice from '../features/notes';
import { sentNotificationSlice } from '../features/api/sentNotification';
import groupDetailSlice from '../features/groupDetail';
import { searchSlice } from '../features/api/search';
import aiUserSlice from '../features/aiUser';

const rootReducer = combineReducers({
	user: userSlice,
	aiUser: aiUserSlice,
	integrations: integrationSlice,
	userGroup: userGroupReducer,
	projects: projectSlice,
	loader: loaderReducer,
	theme: themeReducer,
	openComment: openCommentReducer,
	newComment: newCommentSlice,
	searchComment: searchCommentReducer,
	undoActions: unodActionReducer,
	redoActions: redoActionReducer,
	uploadingStatus: uploadingStatusReducer,
	brainTool: textToImageReducer,
	estimateAI: estimationAI,
	communityAI,
	projectUsers: projectUserSlice,
	snackBar: snackBarReducer,
	stages: stagesSlice,
	canvases: canvasReducer,
	prototyping: prototypingReducer,
	blocks: blocksSlice,
	nodes: nodesSlice,
	feedbacks: feedbackSlice,
	notes: notesSlice,
	[notificationSlice.reducerPath]: notificationSlice.reducer,
	[sentNotificationSlice.reducerPath]: sentNotificationSlice.reducer,
	[searchSlice.reducerPath]: searchSlice.reducer,
	projectGroup: groupDetailSlice
});

export default rootReducer;
