import { Popover } from '@naya_studio/radix-ui';
import React, { MutableRefObject, useRef, useState } from 'react';
import { TPaperImageRef, TPaperUnsupportedRef } from '../collaborationTool/CollaborationTool.types';
import { TPoint } from '@naya_studio/types';

type FeedbackTriggerProps = {
	// Position of the bubble
	position: { x: number; y: number; z?: number };
	// user has edit access
	hasEditAccess: boolean;
	// Callback to handle bubble drag state
	onBubbleDrag: (isDragging: boolean) => void;
	// Callback to handle the bubble transform
	onTransform: (x: number, y: number, z?: number) => void;
	// Children to use as trigger
	children: React.ReactElement;
	view?: MutableRefObject<TPaperImageRef | TPaperUnsupportedRef | null>;
	feedbackPos: TPoint;
};

/**
 * @Component Create popover trigger for feedback bubble
 * @param {FeedbackTriggerProps} props
 * @returns ReactElement
 */
const FeedbackTrigger = ({
	position,
	hasEditAccess,
	onBubbleDrag,
	onTransform,
	children,
	view,
	feedbackPos
}: FeedbackTriggerProps) => {
	// True if bubble is being dragged
	const [dragging, setDragging] = useState<boolean>(false);
	// True if mouse down happened on the bubble
	const [isMouseDown, setIsMouseDown] = useState<boolean>(false);
	// Hold starting x position of mouse down
	const [mouseStartX, setMouseStartX] = useState(0);
	// Hold starting y position of mouse down
	const [mouseStartY, setMouseStartY] = useState(0);
	// Difference between current bubble position and previous position
	const [xDiff, setXDiff] = useState(0);
	const [yDiff, setYDiff] = useState(0);
	// Bubble component ref
	const bubbleRef = useRef<HTMLButtonElement>(null);
	return (
		<Popover.Trigger asChild>
			<button
				type="button"
				className="feedback-trigger hover:tw-cursor-pointer tw-flex tw-outline-none tw-p-0 tw-absolute"
				style={{
					transform: `translate3d(${position.x}px, ${position.y}px, 0px)`,
					zIndex: 1
				}}
				data-translateX={feedbackPos.x}
				data-translateY={feedbackPos.y}
				ref={bubbleRef}
				onPointerDown={(e) => {
					if (hasEditAccess) {
						if (!bubbleRef.current || e.button === 2) return;
						setIsMouseDown(true);
						setMouseStartX(e.clientX as number);
						setMouseStartY(e.clientY as number);
						setXDiff(
							(e.clientX as number) -
								(bubbleRef.current?.getBoundingClientRect().left as number)
						);
						setYDiff(
							(e.clientY as number) -
								(bubbleRef.current?.getBoundingClientRect().top as number)
						);
						// this sets the comment bubble as the mouse
						bubbleRef.current.setPointerCapture(e.pointerId);
					}
				}}
				onPointerMove={(e) => {
					if (hasEditAccess) {
						if (isMouseDown) {
							if (!dragging)
								// Send off the callback
								onBubbleDrag(true);
							setDragging(true);
							let x = e.clientX - xDiff;
							let y = e.clientY - yDiff;

							// max position of x allowed that doesnt go out of screen
							const xLimit =
								view?.current && view.current?.view
									? view.current.view.bounds.width * view.current.view.zoom - 45
									: window.innerWidth - 50;

							// max position of y allowed that doesnt go out of screen
							const yLimit =
								view?.current && view.current?.view
									? 37 + view.current.view.bounds.height * view.current.view.zoom
									: window.innerHeight - 30;
							// adjust values of x and y if they are out of limits
							if (x < 0) x = 0;
							if (x > xLimit) x = xLimit;
							if (y < 72) y = 72;
							if (y > yLimit) y = yLimit;
							if (bubbleRef.current && isMouseDown) {
								bubbleRef.current.style.transform = `translate3d(${x}px, ${y}px, 0px)`;
							}
						}
					}
				}}
				onPointerUp={(e) => {
					e.preventDefault();
					e.stopPropagation();
					if (hasEditAccess) {
						if (!bubbleRef.current) return;
						// release the captured pointer
						bubbleRef.current.releasePointerCapture(e.pointerId);
						// if mouse has not moved since mouse down, we should toggle comment
						if (e.clientX === mouseStartX && e.clientY === mouseStartY) {
							setIsMouseDown(false);
						}

						if (dragging) {
							const x = e.clientX - xDiff;
							const y = e.clientY - yDiff;
							setXDiff(x);
							setYDiff(y);
							setMouseStartX(0);
							setMouseStartY(0);
							// Sendoff the callback
							onTransform(x, y);
						}
						setIsMouseDown(false);
						if (dragging) {
							setDragging(false);
							// Send off the callback
							onBubbleDrag(false);
						}
					}
				}}
			>
				{children}
			</button>
		</Popover.Trigger>
	);
};

export default FeedbackTrigger;

FeedbackTrigger.defaultProps = {
	view: undefined
};
