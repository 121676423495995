import React from 'react';

class PrivacyPolicy extends React.Component{
  componentDidMount(){
    window.location.replace("https://docs.google.com/document/u/2/d/e/2PACX-1vSXCyp-WC5_J1oYGAcQOwtiVhXH6eDhgzoVB1c3hngn4ZOagmnyCG3s9PlHGvZOog/pub");
  }
  render(): React.ReactNode {
      return(
        <div>
          Redirecting...
        </div>
      )
  }
}

export default PrivacyPolicy;