import { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { CustomDispatch } from 'src/redux/actions/types';
import {
	SnackBar,
	TSearchQuery,
	useOnceOnDepChange,
	isThumbnailColorString,
	SessionStorageKeys
} from '@naya_studio/radix-ui';
import {
	addProjectGroup,
	loadUser,
	ungroupProjects,
	loadProjectGroupById,
	editUserPreferences
} from 'src/redux/actions/user';
import { ESharingTabs } from 'src/components/sharingModal/SharingModal.types';
import {
	EBlockType,
	IBlock,
	IProject,
	IProjectDetail,
	IProjectGroup,
	IGroup,
	IUser,
	IUserPreferenceNode,
	TUserAndRole,
	EJobType,
	EAIGenerationType,
	IText,
	CustomProject
} from '@naya_studio/types';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { ISnackBar, ReduxState } from 'src/redux/reducers/root.types';
import { AppDispatch, store } from 'src';
import './HomebaseProject.scss';
import LoadingModal from 'src/components/utilities/loadingModal/LoadingModal';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';
import { AuthContext } from 'src/components/auth/AuthProvider';
import SharingModal from 'src/components/sharingModal/SharingModal';
import UnauthorizedOnline from 'src/components/utilities/unauthorized/UnauthorizedOnline';
import ReactMapboxGl from 'react-mapbox-gl';
import {
	generateOptimisticBlock,
	generateOptimisticProject,
	generateOptimisticStage,
	highlightAndScrollToView,
	ignoreRemindersForJourney
} from 'src/redux/reduxActions/util';
import {
	createProject,
	deleteProject,
	duplicateProject,
	duplicateProjectOptimistically,
	editGroupProjectDetails,
	editProject,
	editProjectDetails,
	loadTemplates,
	loadTemplateById,
	loadProjectById
} from 'src/redux/reduxActions/project';
import mongoose from 'mongoose';
import {
	TBatchCreateGroupAndBlocks,
	TCreateProjectFulfill,
	TCreateProjectThunkArg,
	TDeleteProjectThunkArg,
	TDuplicateProjectFulfill,
	TDuplicateProjectOptimisticallyThunkArg,
	TDuplicateProjectThunkArg,
	TEditProjectDetailsThunkArg,
	TEditProjectThunkArg,
	TEditUserPreferenceThunkArgs,
	TGenerateOptimisticProjectArg
} from 'src/types/argTypes';
import { useNotifications } from 'src/util/notifications/useNotifications';
import loadProjectUsers from 'src/redux/reduxActions/projectUsers';
import trackEvent from 'src/util/analytics/analytics';
import { CustomEvents, TemplatesEvents } from 'src/util/analytics/events';
import { useGetProjectProgressMutation } from 'src/redux/features/api/sentNotification';
import { getWebSocket } from 'src/rtc/yjs/yjsConfig';
import { useUpdateMultipleNotifsMutation } from 'src/redux/features/api/notification';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { handleFilterOption } from 'src/components/utilities/navbar/utils';
import { Homebase } from '@naya_studio/homebase';
import useUser from 'src/redux/hooks/user';
import getUserFromRedux, { onEditUserPreference } from 'src/util/helper/user';
import { logout } from 'src/redux/actions/auth';
import {
	updateUser,
	setDefaultGroup,
	deleteProjectForUser,
	revertDeletedProjectForUser
} from 'src/redux/features/user';
import {
	deleteGroup,
	updateGroup,
	deleteProjectFromGroup,
	revertDeletedProjectFromGroup
} from 'src/redux/features/groupDetail';
import {
	getTemplatesFromLocalForage,
	loadGroupFromLocalForage,
	saveGroupInLocalForage,
	saveTemplatesInLocalForage,
	saveUserInLocalForage
} from 'src/util/storage/indexedDBStorage';
import useWebSocketConnection from 'src/rtc/yjs/useWebSocketConnection';
import { checkIfUserOnHomebase } from 'src/util/collaboration/util';
import { generatePMJourney } from 'src/redux/reduxActions/aiGeneration';
import { PM_AI_RESULTS, TPrompt } from 'src/util/block/util';
import { batchCreateGroupAndBlocks } from 'src/redux/reduxActions/stage';
import axios, { CancelTokenSource } from 'axios';
import useAiGeneration from 'src/util/block/useAiGeneration';
import { setShowAppIntegrationModal } from 'src/redux/features/integration';
import { useLazySearchHomebaseQuery } from 'src/redux/features/api/search';
import EditThumbnailModal from 'src/components/editThumbnailModal/EditThumbnailModal';
import { TEditThumbnailDetails } from 'src/components/editThumbnailModal/EditThumbnailModal.types';
import { clone, debounce } from 'lodash';
import validator from 'validator';
import { findAndUpdateJourneyInGroup } from 'src/redux/features/util';
import { TLDFlags } from 'src/components/collaborationTool/CollaborationTool.types';
import {
	TMarkFavoriteEventData,
	TUseSearchEventData,
	TUseTemplateEventData
} from 'src/util/analytics/analytic.types';
import { onDuplicateProject } from 'src/redux/actions/cloneUtils';
import { CallbackFunction, ToolType } from './HomebaseProject.types';
import DeleteModal from '../../deleteModal/DeleteModal';
import {
	aggregateProjectData,
	findProject,
	formatProjectData,
	getDefaultGroup,
	removeGroupThumbnail
} from './aggregateProjectData';
import HomebaseEstimate from '../estimate/HomebaseEstimate';
import SustainabilityForm from '../sustainability/SustainabilityForm';
import HomebasePrototyping from '../../../features/components/homebase/prototyping/HomebasePrototyping';
import AppModal from './AppModal';
import CommunityAI from '../community/AI/CommunityAI';
import filterProjectData from './filterProjectData';

export enum DuplicateAction {
	DUPLICATE = 'DUPLICATE',
	WITH_ASSETS = 'WITH_ASSETS',
	WITHOUT_ASSETS = 'WITHOUT_ASSETS',
	USE_TEMPLATE = 'USE_TEMPLATE'
}

export const Map = ReactMapboxGl({
	accessToken:
		'pk.eyJ1IjoiamFzc3UzMzMiLCJhIjoiY2wyaDY3eDNmMGF5YTNlcXdhNGo4aWV5ciJ9.A7xSShWP4jiS9PO2xDvouA',
	dragPan: false,
	scrollZoom: false
});

export default function HomebaseProject() {
	const [totalGroups, setTotalGroups] = useState<number>(0);
	const aiGeneratedJourney = useRef<string | null>(null);
	const visualDeleteProject = useRef<string | null>(null);
	const preventedDeleteOnProject = useRef<string[]>([]);
	const [showSharingModal, setShowSharingModal] = useState<boolean>(false);
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const [actionableProject, setActionableProject] = useState<CustomProject | undefined>(
		undefined
	);
	const [loader, setloader] = useState<boolean>(false);
	const [selectedTool, setSelectedTool] = useState<ToolType | undefined>();
	const [showBrainToolModal, setShowBrainToolModal] = useState<boolean>(false);
	const sessionGroup = window.sessionStorage.getItem(SessionStorageKeys.ACTIVE_GROUP);
	// State to check if group is opened
	const [isGroupOpened, setIsGroupOpened] = useState<boolean>(Boolean(sessionGroup));
	// State to check if group is loading
	const [isGroupLoading, setIsGroupLoading] = useState<boolean>(false);
	const [aggregatedData, setAggregatedData] = useState<CustomProject[]>([]);
	const [groupAggregatedData, setGroupAggregatedData] = useState<CustomProject[]>([]);
	const [projectToShare, setProjectToShare] = useState<string>('');
	const [projectProgress, setProgress] = useState<{ [key: string]: number }>();
	const [searchFilters, setSearchFilters] = useState<TSearchQuery>({});
	const [aggregatedSearchResult, setAggregatedSearchResult] = useState<CustomProject[]>([]);
	const [editThumbnailDetails, setEditThumbnailDetails] = useState<
		TEditThumbnailDetails | undefined
	>(undefined);
	// Holds templates to show in templates gallery
	const [templates, setTemplates] = useState<{
		data: CustomProject[];
		isFetching: boolean;
		isError: boolean;
	}>({ data: [], isFetching: true, isError: false });
	const [isTemplateGalleryOpen, setIsTemplateGalleryOpen] = useState(false);

	const { user } = useUser();
	// Memoized value for favorited projects
	const favoriteProjects = useMemo(
		() => (user.userPreferences as IUserPreferenceNode).pinnedProjects as IProject[],
		[(user?.userPreferences as IUserPreferenceNode)?.pinnedProjects]
	);
	const snackBarData = useSelector((state) => (state as ReduxState).snackBar, shallowEqual);
	const noNotificationsFor = useSelector(
		(state: ReduxState) =>
			(state.user.data.userPreferences as IUserPreferenceNode)?.noNotificationsFor || [],
		shallowEqual
	);
	const [getProjectProgress] = useGetProjectProgressMutation();
	const { onGenerateWithAI } = useAiGeneration();

	const { tasks, handleTask } = useNotifications();

	const [updateMultipleNotifs] = useUpdateMultipleNotifsMutation();

	const [searchHomebase, { isLoading: isGlobalSearch }] = useLazySearchHomebaseQuery();

	const flags = useFlags<TLDFlags>();

	const history = useHistory();
	const dispatch = useDispatch<AppDispatch>();
	const wsConnected = useWebSocketConnection();
	const currentParentGroup = useSelector((state) => (state as ReduxState).projectGroup.data);
	const reqCancelAI = useRef(axios.CancelToken.source());
	// Request cancel token ref to handle group requests cancellation
	const reqCancelGroup = useRef<CancelTokenSource | null>(null);

	const authContext = useContext(AuthContext);
	const { setStatus, completedAuthCheck } = authContext;
	let loaderProgress = 0;

	/**
	 * Runs on users login activity change
	 */
	useEffect(() => {
		if (completedAuthCheck && !user._id) {
			const url = encodeURIComponent(window.location.pathname);
			history.push(`/login/${url}`);
		}
	}, [completedAuthCheck, history, user._id]);

	/**
	 * Runs when user preference i.e. notification status, pinned projects or user login status changes
	 */
	useEffect(() => {
		const { data, groupCount } = aggregateProjectData(projectProgress);
		setAggregatedData(data);
		setTotalGroups(groupCount);
		if (isGroupOpened && currentParentGroup._id) {
			const updatedData = formatProjectData(
				(currentParentGroup?.projects as IProject[]) || [],
				projectProgress
			);
			setGroupAggregatedData(updatedData);
		}
	}, [projectProgress, currentParentGroup, isGroupOpened]);

	/** Runs when socket connectivity is changed on homebase */
	useEffect(() => {
		const onHomebase = checkIfUserOnHomebase();

		if (onHomebase && wsConnected) {
			const webSocket = getWebSocket();
			if (webSocket) {
				// Disconnect from socket when on homebase
				webSocket.disconnect();
				// Disconnect from broadcast channel on when homebase
				webSocket.disconnectBc();
			}
		}
	}, [wsConnected]);

	/**
	 * Function to handle getting a group detail
	 */
	const handleGroupDetails = async (groupId: string | null) => {
		setGroupAggregatedData([]);

		// If a group id is sent and it's not same as the group in redux, then load it
		if (groupId && currentParentGroup._id !== groupId) {
			setIsGroupLoading(true);
			setIsGroupOpened(true);
			const isGroupFoundInLocalForage = await loadGroupFromLocalForage(groupId);

			if (!isGroupFoundInLocalForage) {
				// If a group is already loading and we try to load another group, then cancel the previous load
				if (reqCancelGroup.current) reqCancelGroup.current.cancel();
				reqCancelGroup.current = axios.CancelToken.source();

				(store.dispatch as CustomDispatch)(
					loadProjectGroupById({
						projectGroupId: groupId,
						userId: user._id as string,
						cancelToken: reqCancelGroup.current
					})
				)
					.unwrap()
					// When group is loaded into redux set the cancel token ref to null
					.then((response: IProjectGroup) => {
						setIsGroupLoading(false);
						reqCancelGroup.current = null;
						saveGroupInLocalForage(response);
					});
			} else setIsGroupLoading(false);
		} else if (!groupId) {
			window.sessionStorage.removeItem(SessionStorageKeys.ACTIVE_GROUP);
			// If a group is loading and someone cancels it, then cancel the request
			if (reqCancelGroup.current) reqCancelGroup.current.cancel();
			setIsGroupOpened(false);
			dispatch(deleteGroup());
		}
	};

	const handleProjectGroups = async (
		groupProjectId: string,
		projectIds: string[],
		type: 'NEW_GROUP' | 'ADD_TO_GROUP' | 'REMOVE_FROM_GROUP',
		isFromContextMenu?: boolean
	) => {
		const { _id: createdBy, sharedProjects, projectGroups } = getUserFromRedux();
		if (!isFromContextMenu) {
			// Scroll and highlight
			setTimeout(() => highlightAndScrollToView(groupProjectId), 200);
		}

		if (type === 'NEW_GROUP' || type === 'ADD_TO_GROUP') {
			const projectDetails: TGenerateOptimisticProjectArg = {
				_id: groupProjectId,
				createdBy,
				parentGroupId: groupProjectId,
				projectType: 'REGULAR',
				name: `Group ${totalGroups + 1}`
			};

			// Dispatch addProjectGroup action
			(store.dispatch as CustomDispatch)(
				addProjectGroup({
					projectDetails,
					projectIds,
					type,
					prevState: {
						projectGroups: projectGroups as IProjectGroup[],
						sharedProjects: sharedProjects as IProject[]
					}
				})
			);
		} else {
			const isLastProjectInGroup = isGroupOpened && groupAggregatedData.length <= 1;

			if (projectIds.length === 0) {
				const optimisticGroups = aggregatedData.filter(
					(data) => data._id !== groupProjectId
				);
				setAggregatedData(optimisticGroups);
			} else {
				if (isLastProjectInGroup) {
					setGroupAggregatedData([]);
					window.sessionStorage.removeItem(SessionStorageKeys.ACTIVE_GROUP);
					setIsGroupOpened(false);
				} else {
					// Optimisitic Remove Project from Groups
					const optimisticProjectGroups = groupAggregatedData.filter(
						(projectInGroup) =>
							!projectIds.includes((projectInGroup as CustomProject)?._id as string)
					);
					setGroupAggregatedData(optimisticProjectGroups);
				}
				removeGroupThumbnail(groupProjectId, projectIds[0] as string, dispatch);
			}

			// Dispatch ungroupProjects action
			(store.dispatch as CustomDispatch)(
				ungroupProjects(dispatch, groupProjectId, projectIds, setIsGroupOpened)
			);
			if (isLastProjectInGroup) dispatch(deleteGroup());
		}
	};

	/**
	 * Function to reset delete states
	 */
	const resetDeleteStates = useCallback(() => {
		visualDeleteProject.current = null;
		setActionableProject(undefined);
	}, [showDeleteModal]);

	/**
	 * Function to handle delete project action
	 */
	const onDeleteProject = useCallback(() => {
		if (actionableProject) {
			const id = actionableProject._id;
			const projectTitle = actionableProject.projectName;
			const isLastProjectInGroup = isGroupOpened && groupAggregatedData.length <= 1;
			const deleteDispatch = isGroupOpened ? deleteProjectFromGroup : deleteProjectForUser;
			const revertDispatch = isGroupOpened
				? revertDeletedProjectFromGroup
				: revertDeletedProjectForUser;

			const snackbarActionData = [
				{
					buttonData: 'Undo',
					onClick: () => {
						preventedDeleteOnProject.current.push(id);
						dispatch(
							revertDispatch({
								projectId: id
							})
						);
						resetDeleteStates();
						removeSnackbar(0);
					},
					show: !isLastProjectInGroup && !(Object.keys(searchFilters).length > 0)
				}
			];

			const payload: ISnackBar = {
				text: `"${projectTitle}" deleted`,
				show: true,
				type: 'NORMAL',
				actionButtonData: snackbarActionData
			};

			addSnackbar(payload);

			if (isLastProjectInGroup) {
				// Delete the group
				handleProjectGroups(currentParentGroup._id as string, [], 'REMOVE_FROM_GROUP');
				window.sessionStorage.removeItem(SessionStorageKeys.ACTIVE_GROUP);
				setIsGroupOpened(false);
			}

			// Update filtered database result and remove deleted journey from it
			// Do this so that if journey is deleted after applying search it is removed from view
			const filteredDbResult = aggregatedSearchResult.filter(
				(journey) => (journey._id as string).toString() !== id.toString()
			);
			setAggregatedSearchResult(filteredDbResult);

			dispatch(
				deleteDispatch({
					projectId: id,
					groupId: isGroupOpened ? (currentParentGroup._id as string) : undefined
				})
			);

			if (isGroupOpened) {
				// Dispatch to update project in active user as well to update group thumbnail and remove project from it
				dispatch(
					deleteProjectForUser({
						projectId: id,
						groupId: currentParentGroup._id as string
					})
				);
			}

			const apiPayload: TDeleteProjectThunkArg = {
				payload: {
					_id: id
				}
			};

			setTimeout(() => {
				if (!preventedDeleteOnProject.current.includes(apiPayload.payload._id)) {
					(store.dispatch as CustomDispatch)(deleteProject(apiPayload))
						.unwrap()
						.then((response: TDeleteProjectThunkArg['payload']) => {
							removeSnackbar(0, () => {
								if (visualDeleteProject.current === response._id)
									resetDeleteStates();
							});
						})
						.catch((error) => {
							dispatch(
								revertDispatch({
									projectId: id
								})
							);

							console.error('Failed to delete journey : ', error);
						});
				} else {
					preventedDeleteOnProject.current = preventedDeleteOnProject.current.filter(
						(pid) => pid !== apiPayload.payload._id
					);
				}
			}, 3000);
		}
	}, [
		actionableProject,
		preventedDeleteOnProject.current,
		visualDeleteProject.current,
		searchFilters,
		aggregatedSearchResult
	]);

	/**
	 * Function to toggle loader
	 */
	const toggleLoader = () => {
		setloader(!loader);
	};

	/** toggle sharingModal */
	const toggleSharingModal = () => {
		setShowSharingModal(!showSharingModal);
	};

	/** toggle delete modal */
	const toggleDeleteModal = () => {
		setShowDeleteModal(!showDeleteModal);
	};

	/**
	 * Search for projects in a group
	 * @param val string
	 */
	const handleSearch = debounce((val: string) => {
		searchHomebase({
			userId: user._id,
			query: val,
			payload: {
				createdBy: searchFilters && searchFilters.createdBy && searchFilters.createdBy._id,
				sharedWith:
					searchFilters && searchFilters.sharedWith && searchFilters.sharedWith._id,
				lastUpdated: searchFilters && searchFilters?.lastUpdated
			}
		})
			.unwrap()
			.then((res) => {
				if (res.data) {
					const updatedData = formatProjectData(
						(res.data as IProject[]) || [],
						projectProgress
					);

					if (searchFilters && searchFilters.types?.includes('FAVORITE')) {
						const favoritedResults = updatedData.filter((project) =>
							favoriteProjects.some((p) => (p as IProject)?._id === project._id)
						);
						setAggregatedSearchResult(favoritedResults);
					} else {
						setAggregatedSearchResult(updatedData);
					}
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, 200);

	/**
	 * Function to generate journey using prompt
	 * @param pId project id
	 * @param prompt prompt given by user
	 */
	const generateJourneyByPrompt = async (pId: string, prompt?: string) => {
		// get structure of journey till the PM-AI text block
		const previousStructure = { stages: [] };

		reqCancelAI.current = axios.CancelToken.source();

		const options = {
			type: EJobType.PM_JOURNEY_GENERATION,
			aiType: EAIGenerationType.PM_JOURNEY,
			prompt: prompt as string
		};
		const results: any = await (dispatch as CustomDispatch)(
			// Dispatch API call for super-power api
			generatePMJourney({ reqCancelAI, options, previousStructure })
		).unwrap();

		// If journey generation is past this point, we'll disable the cancel option
		const cancelReqBtnParent = document.querySelector<HTMLDivElement>(
			'[data-testid="cancel-req-btn"]'
		);
		if (cancelReqBtnParent) {
			const cancelReqBtn = cancelReqBtnParent.querySelector<HTMLSpanElement>('span');
			if (cancelReqBtn) cancelReqBtn.style.display = 'none';
		}

		// get stages from the api result - AI sometimes sends stages and sometimes results.stages
		const pm_ai_results: PM_AI_RESULTS = results?.results || results;

		const { stages } = pm_ai_results;
		stages.splice(0, 0, {
			name: 'Untitled 1',
			blocks: []
		});

		const groupsToCreate: IGroup[] = [];
		const blocksToCreate: IBlock[] = [];

		// Creation of prompts for PM-AI image gen
		const prompts: TPrompt[] = [];
		const regexSketchRenderPhase = /(?:sketch|render|designs)/i;
		for (let i = 0; i < stages?.length; i++) {
			const newPhaseName = (stages[i] as Partial<IGroup>).name as string;
			const phaseId = new mongoose.Types.ObjectId().toString() as string;
			// pmJourneyPhases.current.push(phaseId);
			const phase = generateOptimisticStage(phaseId, newPhaseName, {
				id: pId,
				type: 'JOURNEY'
			});
			phase.isPhaseCreated = true;
			const blockIdsToAdd = [];
			const blocks = stages[i]?.blocks;
			const blocksToAdd = [];
			let promptAddedForPhase = false;

			if (i === 0) {
				const blockId = new mongoose.Types.ObjectId().toString() as string;
				blockIdsToAdd.push(blockId);
				const optimisticBlock = generateOptimisticBlock(
					blockId,
					phaseId,
					pId,
					'Untitled 1',
					'TEXT'
				);

				const formattedPrompt = prompt?.replaceAll('\n', ' ');
				const updatedText =
					`{"root":{"children":[{"children":[{"detail":0,"format":0,` +
					`"mode":"normal","style":"","text":"${formattedPrompt}","type":"text","version":1}],` +
					`"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1}],"direction":` +
					`"ltr","format":"","indent":0,"type":"root","version":1}}`;
				(optimisticBlock as IText).text = updatedText;

				blocksToAdd.push(optimisticBlock);
			} else if (blocks) {
				for (let j = 0; j < blocks?.length; j++) {
					const blockId = new mongoose.Types.ObjectId().toString() as string;
					blockIdsToAdd.push(blockId);
					const optimisticBlock = generateOptimisticBlock(
						blockId,
						phaseId,
						pId,
						blocks[j]?.name as string,
						'EMPTY'
					);
					blocksToAdd.push(optimisticBlock);
					if (blocks) {
						const sketchRenderPhase = regexSketchRenderPhase.test(
							blocks[j]?.name as string
						);

						if (blocks && sketchRenderPhase && blocks[j] && !promptAddedForPhase) {
							promptAddedForPhase = true;
							prompts.push({
								prompt: `${blocks[j]?.name as string}`,
								phaseId,
								newBlockIndex: blocks.length,
								phaseIndex: 0 + i + 1,
								aiType: /sketch/i.test(newPhaseName) ? 'SKETCH' : 'RENDER'
							} as TPrompt);
						}
					}
				}
			}
			phase.children = blockIdsToAdd;

			groupsToCreate.push(phase);
			blocksToCreate.push(...blocksToAdd);
		}

		// Construct dispatch payload
		const dispatchPayload: TBatchCreateGroupAndBlocks = {
			parent: { id: pId, type: 'JOURNEY' },
			groups: groupsToCreate,
			blocks: blocksToCreate,
			startGroupIndex: 0,
			children: groupsToCreate.map((group) => group._id.toString())
		};

		dispatch(batchCreateGroupAndBlocks(dispatchPayload));

		const isCancelled = window.sessionStorage.getItem(
			SessionStorageKeys.AI_GENERATION_CANCELLED_ON_STUDIO
		);

		if (prompts.length && !isCancelled) {
			// if prompts are created for img gen from PM-AI, then create TTI flow for each prompt
			for (let i = 0; i < prompts.length; i++) {
				if (prompts && prompts[i]) {
					onGenerateWithAI({
						type: 'TEXT_TO_IMAGE_GENERATION',
						prompt: ((prompts[i]?.prompt || '') + options.prompt) as string,
						phaseId: prompts[i]?.phaseId,
						projectId: pId,
						newBlockIndex: prompts[i]?.newBlockIndex,
						phaseIndex: prompts[i]?.phaseIndex,
						aiType: prompts[i]?.aiType as keyof typeof EAIGenerationType,
						triggerFromPMJOurney: true
					});
				}
			}
		}
		if (!isCancelled) {
			history.push(`/project/${pId}`);
		}
		window.sessionStorage.removeItem(SessionStorageKeys.AI_GENERATION_CANCELLED_ON_STUDIO);
	};

	/**
	 * Function to create a new project
	 */
	const createNewProject = async (prompt?: string) => {
		const { _id: createdBy, defaultProjectGroup: parentGroupId } = user;

		const pid = new mongoose.Types.ObjectId().toString() as string;
		const payload: TGenerateOptimisticProjectArg = {
			_id: pid,
			createdBy,
			parentGroupId: parentGroupId as string,
			projectType: 'REGULAR',
			name: 'Untitled',
			isAIGenerated: !!prompt
		};

		if (prompt) aiGeneratedJourney.current = pid;
		else toggleLoader();

		const { optimisticProject } = generateOptimisticProject(payload);

		const apiPayload: TCreateProjectThunkArg = {
			payload: {
				...payload,
				optimisticProject
			},
			user
		};

		(store.dispatch as CustomDispatch)(createProject(apiPayload))
			.unwrap()
			.then((response: TCreateProjectFulfill) => {
				const id = response.projectId;
				if (prompt) generateJourneyByPrompt(id, prompt);
				else history.push(`/project/${id}`);
			})
			.catch(() => {
				const snackbarPayload: ISnackBar = {
					text: 'Failed to create project.',
					show: true,
					type: 'ERROR'
				};

				addSnackbar(snackbarPayload);
				removeSnackbar(3000);
			});
	};

	/**
	 * Function to cancel the ai journey generation request
	 */
	const onCancelAIJourney = () => {
		addSnackbar({
			show: true,
			type: 'ERROR',
			text: 'Oops! Failed to generate the journey. Please try again.'
		});
		removeSnackbar(3000);
		reqCancelAI.current.cancel();

		// Delete the project which was created
		if (aiGeneratedJourney.current) {
			const apiPayload: TDeleteProjectThunkArg = {
				payload: {
					_id: aiGeneratedJourney.current
				}
			};
			visualDeleteProject.current = aiGeneratedJourney.current;
			(store.dispatch as CustomDispatch)(deleteProject(apiPayload))
				.unwrap()
				.then(() => {
					visualDeleteProject.current = null;
					aiGeneratedJourney.current = null;
				});
		}
	};

	/**
	 * Callback function to handle project rename from studio
	 * @param newName updated name of journey or group
	 * @param id if of the journey or group to rename
	 * @param renamedCardType
	 */
	const onRenameJourneyOrGroup = (
		newName: string,
		id: string,
		renamedCardType: 'GROUP' | 'JOURNEY'
	) => {
		// If a group is renamed use aggregated data
		// If a journey is renamed but it is inside a group then use group aggregated data else use aggregatedData
		const data =
			renamedCardType === 'GROUP' || (renamedCardType === 'JOURNEY' && !isGroupOpened)
				? aggregatedData
				: groupAggregatedData;

		const activeJourneyOrGroupIndex = data.findIndex((proj) => proj._id === id);
		// Find the active journey or group from state
		const activeJourneyOrGroup = data[activeJourneyOrGroupIndex] as CustomProject;

		if (activeJourneyOrGroup) {
			// Update the state locally whether it's a group or journey and scroll
			activeJourneyOrGroup.projectName = newName.trim();
			data[activeJourneyOrGroupIndex] = activeJourneyOrGroup;
			if (isGroupOpened && renamedCardType !== 'GROUP') setGroupAggregatedData(data);
			else setAggregatedData(data);
		}

		// Find the group id if journey is renamed
		const groupId = isGroupOpened
			? currentParentGroup._id
			: (user.defaultProjectGroup as string);
		const parentGroup = (user.projectGroups as IProjectGroup[])?.find(
			(grp) => grp._id === groupId
		);

		// Construct the payload
		const apiPayload: TEditProjectDetailsThunkArg = {
			payload: {
				projectId: id,
				updates: {
					name: newName.trim()
				},
				parentGroupId: groupId as string,
				isGroupOpened
			}
		};

		// If a journey is renamed
		if (parentGroup && renamedCardType === 'JOURNEY') {
			// Find the journey from users redux state
			const project = (parentGroup.projects as IProject[])?.find((p) => p._id === id);
			if (project) {
				// Add the old project details to the payload
				apiPayload.payload.updates = {
					...project.projectDetails,
					...apiPayload.payload.updates
				};

				// Add the old state for rejected case
				apiPayload.prevState = {};
				apiPayload.prevState.name = (project?.projectDetails as IProjectDetail).name;
			}
		} else if (renamedCardType === 'GROUP') {
			// If a group is renamed then add the id in updates id
			apiPayload.payload.updates._id = id;
		}

		/**
		 * Function to update redux state, display snackbar and update notifs after rename completion
		 */
		const onSuccess = () => {
			setTimeout(() => highlightAndScrollToView(id), 200);

			const snackbarPayload: ISnackBar = {
				text: `${renamedCardType === 'GROUP' ? 'Group' : 'Journey'} Renamed`,
				show: true,
				type: 'NORMAL'
			};

			addSnackbar(snackbarPayload);
			removeSnackbar(2000);

			/** --- Update Project Name in all notifications of that project ---*/
			updateMultipleNotifs({
				searchBy: { 'data.projectId': id },
				update: { 'data.projectName': newName.trim() },
				operation: 'PROJECT_RENAME'
			});
		};

		/**
		 * Function to display snackbar on rename failure
		 */
		const onFailure = () => {
			const snackbarPayload: ISnackBar = {
				text: `Failed to rename ${renamedCardType === 'GROUP' ? 'Group' : 'Journey'}.`,
				show: true,
				type: 'ERROR'
			};

			addSnackbar(snackbarPayload);
			removeSnackbar(2000);
		};

		// If group is renamed dispatch editGroupProjectDetails
		// else dispatch editProjectDetails
		(store.dispatch as CustomDispatch)(
			renamedCardType === 'GROUP'
				? editGroupProjectDetails(apiPayload)
				: editProjectDetails(apiPayload)
		)
			.unwrap()
			.then((response: TEditProjectDetailsThunkArg['payload']) => {
				onSuccess();

				// If someone renames the journey and sets it as Untitled, ignore it as well
				// Considering it is renamed
				if (
					renamedCardType === 'JOURNEY' &&
					response.updates.name?.toLowerCase() === 'untitled'
				)
					ignoreRemindersForJourney(id);
			})
			.catch(() => onFailure());
	};

	/**
	 * Handles Duplication snackbar
	 * @param isTemplate : boolean
	 * @param projectId : string
	 */
	const handleDuplicateSuccessSnackbar = (isTemplate: boolean, projectIdToRedirect: string) => {
		const payload: ISnackBar = {
			text: isTemplate ? 'Journey template created.' : 'Journey duplicated.',
			show: true,
			type: 'NORMAL',
			actionButtonData: [
				{
					buttonData: 'Open',
					onClick: () => {
						if (!isTemplate) {
							// remove snackbar only if the project is not a template
							removeSnackbar(0);
						}
						history.push(`/project/${projectIdToRedirect}`);
					},
					show: true
				}
			]
		};

		addSnackbar(payload);

		setTimeout(() => {
			// remove snackbar after 5 sec only if the user is still on the /studio page
			if (window.location.pathname.includes('/studio')) {
				removeSnackbar(0);
			}
		}, 10000);
	};

	/**
	 * Callback to handle favorite change operation
	 * @param id of the document to favorite
	 * @param showSnackbar whether to display snackbar or not
	 * @param isFavorited current favorite status of project/group
	 * @param isGroup if favorited document is group
	 */
	const onFavoriteChange = (
		id: string,
		showSnackbar: boolean,
		isFavorited: boolean,
		isGroup?: boolean
	) => {
		const updatedPinnedProjects: string[] = [];

		// Push the existing pinned ids in array
		for (let i = 0; i < favoriteProjects.length; i++) {
			const pid = favoriteProjects[i]?._id as string;
			if (isFavorited && pid !== id) updatedPinnedProjects.push(pid);
			else if (!isFavorited) updatedPinnedProjects.push(pid);
		}

		// Push the newly pinned project to array
		if (!isFavorited) updatedPinnedProjects.push(id);

		const actionPayload: TEditUserPreferenceThunkArgs = {
			payload: {
				pinnedProjects: updatedPinnedProjects
			},
			prevState: {
				preferences: user.userPreferences as IUserPreferenceNode
			}
		};

		if (showSnackbar) {
			const pendingSnackbar: ISnackBar = {
				text: `${isFavorited ? 'Unfavoriting ' : 'Favoriting '} ${
					isGroup ? 'Group' : 'Project'
				}`,
				show: true,
				type: 'LOADER'
			};
			addSnackbar(pendingSnackbar);
		}

		dispatch(editUserPreferences(actionPayload))
			.unwrap()
			.then(() => {
				if (showSnackbar) {
					const successSnackbar: ISnackBar = {
						text: `${isGroup ? 'Group ' : 'Project '} ${
							isFavorited ? 'Unfavorited ' : 'Favorited '
						}`,
						show: true,
						type: 'NORMAL'
					};

					addSnackbar(successSnackbar);
				}

				if (isFavorited) {
					// Track marking project favorite event
					const eventProps: TMarkFavoriteEventData = {
						elementId: id,
						elementType: 'JOURNEY'
					};

					trackEvent(CustomEvents.MARK_FAVORITE, eventProps);
				}
			})
			.catch(() => {
				if (showSnackbar) {
					const failureSnackbar: ISnackBar = {
						text: `Unable to ${isFavorited ? 'Unfavorite ' : 'Favorite '} ${
							isGroup ? 'Group' : 'Project'
						}. Please try again.`,
						show: true,
						type: 'ERROR'
					};

					addSnackbar(failureSnackbar);
				}
			})
			.finally(() => {
				if (showSnackbar) removeSnackbar(3000);
			});
	};

	/**
	 * Function to duplicate a project using API (not optimistic)
	 * Note: Called when project to duplicate has a Canvas and the data of nodes is not available
	 * @param id id of the project to duplicate
	 */
	const duplicateProjectByID = (
		id: string,
		isTemplate: boolean,
		withAssets: boolean,
		isPinned: boolean,
		next?: (duplicatedProject?: CustomProject) => any
	) => {
		// Custom project object of the project to duplicate
		const projectToDuplicate: CustomProject | undefined = (
			isGroupOpened ? groupAggregatedData : (aggregatedData as CustomProject[])
		).find((proj: CustomProject) => proj._id === id);

		if (projectToDuplicate) {
			setActionableProject(projectToDuplicate);

			// --- Intermediate Snackbar payload - Duplicating Journey
			const snackbarPayload: ISnackBar = {
				text: 'Duplicating Journey',
				show: true,
				type: 'LOADER',
				loaderColor: '#fff'
			};
			addSnackbar(snackbarPayload);
			//-----

			// Payload for Duplicate API
			const apiPayload: TDuplicateProjectThunkArg = {
				payload: {
					_id: id,
					isTemplate,
					withAssets
				}
			};

			// Dispatch duplicateProject API
			(store.dispatch as CustomDispatch)(duplicateProject(apiPayload))
				.unwrap()
				.then((response: TDuplicateProjectFulfill) => {
					if (isGroupOpened) {
						setGroupAggregatedData([...groupAggregatedData, response.customProject]);
					} else {
						setAggregatedData([...aggregatedData, response.customProject]);
					}

					if (isPinned) {
						onFavoriteChange(response.customProject._id, false, false, false);
					}
					(store.dispatch as CustomDispatch)(
						loadUser({ email: getUserFromRedux().email as string })
					).then(() => {
						// --- Final Snackbar indicating success Journey template created/Journey duplicated
						handleDuplicateSuccessSnackbar(isTemplate, response.customProject._id);
						// ----
						if (next && typeof next === 'function') {
							next(response.customProject);
						}
					});

					/** Load updated users to redux */
					(store.dispatch as CustomDispatch)(
						loadProjectUsers({
							payload: {
								projectId: response.customProject._id as string
							}
						})
					);
				})
				.catch((error: any) => {
					const dupSnackbarPayload: ISnackBar = {
						text: 'Duplicating Journey Failed! Please try again.',
						show: true,
						type: 'ERROR'
					};

					addSnackbar(dupSnackbarPayload);
					removeSnackbar(3000);

					console.error('Error Duplicating : ', error);
				})
				.finally(() => setActionableProject(undefined));
		}
	};

	/**
	 * Function to display the share modal to user
	 * @param id id of the project to share
	 */
	const shareProjectByID = (id: string) => {
		setProjectToShare(id);
		setShowSharingModal(true);
	};

	/**
	 * Function to display the delete modal
	 * @param projectToDelete id of the project to delete
	 */
	const onDeleteProjectCallback = (projectToDelete: CustomProject, next?: () => any) => {
		visualDeleteProject.current = projectToDelete._id;
		setActionableProject(projectToDelete);
		setShowDeleteModal(true);

		if (next && typeof next === 'function') next();
	};

	/**
	 * Function to Toggle Notifications of a Project
	 * @param id id of the project to toggle notifications
	 * @param isPinned CustomProject.isSubscribed
	 */
	const onSubscriptionChange = (id: string, isSubscribed: boolean) => {
		const updatedNoNotificationsFor = noNotificationsFor ? noNotificationsFor.slice() : [];
		const actionPayload: TEditUserPreferenceThunkArgs = {
			payload: {
				noNotificationsFor: updatedNoNotificationsFor
			},
			prevState: {
				preferences: user.userPreferences as IUserPreferenceNode
			}
		};

		const allProjects = (
			isGroupOpened
				? currentParentGroup
				: (user.projectGroups?.find(
						(grp) => (grp as IProjectGroup)._id === user.defaultProjectGroup
				  ) as IProjectGroup)
		)?.projects as IProject[];

		let journey = allProjects?.find((jou) => (jou as IProject)._id === id) as IProject;
		if (!journey)
			journey = user.sharedProjects?.find(
				(project) => (project as IProject)._id === id
			) as IProject;

		const projectName =
			((journey as IProject)?.projectDetails as IProjectDetail)?.name || 'Untitled';

		if (isSubscribed) {
			actionPayload.payload.noNotificationsFor = updatedNoNotificationsFor.concat(id);
		} else {
			const index = updatedNoNotificationsFor.indexOf(id);
			if (index > -1) actionPayload.payload.noNotificationsFor!.splice(index, 1);
		}

		if (isSubscribed) {
			const pendingSnackbar: ISnackBar = {
				text: `Disabling email notifications for ${projectName}`,
				show: true,
				type: 'LOADER'
			};
			addSnackbar(pendingSnackbar);
		}

		dispatch(editUserPreferences(actionPayload))
			.unwrap()
			.then(() => {
				if (isSubscribed) {
					const successSnackbar: ISnackBar = {
						text: `Email notifications disabled for ${projectName}.`,
						show: true,
						type: 'NORMAL'
					};

					addSnackbar(successSnackbar);
				}
			})
			.catch(() => {
				if (isSubscribed) {
					const failureSnackbar: ISnackBar = {
						text: `Unable to disable email notifications for ${projectName}. Please try again.`,
						show: true,
						type: 'ERROR'
					};

					addSnackbar(failureSnackbar);
				}
			})
			.finally(() => {
				if (isSubscribed) removeSnackbar(3000);
			});
	};

	/**
	 * Function to handle selected brain tool
	 */
	const handleLabs: CallbackFunction = (tool: string) => {
		setShowBrainToolModal(true);

		switch (tool) {
			case 'ESTIMATES':
				setSelectedTool(ToolType.ESTIMATES);
				break;
			case 'MATCHMAKING':
				setSelectedTool(ToolType.MATCHMAKING);
				break;
			case 'SUSTAINABILITY':
				setSelectedTool(ToolType.SUSTAINABILITY);
				break;
			case '3DPRINTING':
				setSelectedTool(ToolType.THREEDPRINTING);
				break;
			default:
				setSelectedTool(undefined);
				break;
		}
	};

	/**
	 * Function to close modal and clear component
	 */
	const handleCloseModal = () => {
		setShowBrainToolModal(false);
		setSelectedTool(undefined);
	};

	/**
	 * Function to render Naya superpower tool options
	 */
	const renderTool = () => {
		switch (selectedTool) {
			case ToolType.ESTIMATES:
				return <HomebaseEstimate />;
			case ToolType.MATCHMAKING:
				return <CommunityAI />;
			case ToolType.SUSTAINABILITY:
				return <SustainabilityForm />;
			case ToolType.THREEDPRINTING:
				return <HomebasePrototyping />;
			default:
				return <> {}</>;
		}
	};

	/**
	 * Function to log user out
	 */
	const logoutActiveUser = () => {
		setStatus('SIGNED_OUT');
		dispatch(
			logout({
				email: user.email as string,
				next: () => {
					window.sessionStorage.removeItem('zoom');
					window.location.href = '/';
				}
			})
		);
	};

	/**
	 * Turns template project into non template projects
	 * @param projectIdToUnmark : string
	 */
	const onUnmarkTemplate = (projectIdToUnmark: string) => {
		const { sharedProjects } = user;

		const { projectFound, projects } = findProject(
			projectIdToUnmark,
			isGroupOpened,
			currentParentGroup,
			sharedProjects as IProject[]
		);

		// Edit Project API payload
		const apiPayload: TEditProjectThunkArg = {
			payload: {
				_id: projectIdToUnmark,
				update: {
					isTemplate: false
				}
			},
			prevState: { prevProject: projectFound as IProject }
		};

		// Edit Project dispatch action
		dispatch(editProject(apiPayload));

		// ---Search Project in User and update the redux User to unmark the template project
		let projectFoundUser = false;
		const newProjects = (projects as IProject[]).map((p) => {
			if (p._id === projectIdToUnmark) {
				projectFoundUser = true;
				return {
					...p,
					isTemplate: false
				};
			}
			return p;
		}) as IProject[];

		if (!projectFoundUser) {
			const newSharedProjects = (sharedProjects as IProject[]).map((p) => {
				if (p._id === projectIdToUnmark) {
					projectFoundUser = true;
					return {
						...p,
						isTemplate: false
					};
				}
				return p;
			}) as IProject[];

			dispatch(updateUser({ sharedProjects: newSharedProjects }));
		} else if (isGroupOpened) {
			dispatch(updateGroup({ projects: newProjects }));
		} else {
			dispatch(setDefaultGroup({ projects: [...newProjects] }));
		}
	};

	/**
	 * Format project to remove unnecessary stages and
	 * check if the project has canvas
	 * Used for Duplication
	 * @param project
	 * @returns {hasCanvas : boolean; project:IProject}
	 */
	const formatProject = (projectToFormat: IProject) => {
		const reduxStages = store.getState().stages.data;
		const reduxBlocks = store.getState().blocks.data;
		const { children: stages } = projectToFormat;
		let hasCanvas = false;
		const newStages = [];

		for (let i = 0; i < ((stages as string[])?.length || 0); i++) {
			const stage = reduxStages[(stages as string[])[i] as string];
			if (stage) {
				if (!hasCanvas) {
					// check for canvas
					const hasCanvasTemp = stage.children.find((blockId) => {
						if (!blockId) return false;
						return reduxBlocks[blockId.toString()]?.blockType === EBlockType.CANVAS;
					});
					if (hasCanvasTemp) hasCanvas = true;
				}
				// find user in stage
				const userFoundInStage = (stage.users as TUserAndRole[]).find(
					(u: TUserAndRole) => u.user === user._id
				);

				if (!((stage.children as IBlock[]).length === 0 && !userFoundInStage)) {
					// Add stage only if the stage has blocks and the stage also has that user
					newStages.push(stage);
				}
			}
		}

		return {
			hasCanvas,
			project: {
				...projectToFormat,
				stages: newStages
			}
		};
	};

	/**
	 * Finds project in the users defaultProjects or shared projects and
	 * returns the user specific project that can be used for duplication
	 * @param projectId string
	 */
	const findAndFormatProject = async (projectIdToFind: string) => {
		let foundProject: IProject | null = null;
		await dispatch(
			loadProjectById({ payload: { _id: projectIdToFind, preventLocalForageLoad: true } })
		)
			.unwrap()
			.then((data) => {
				if (data.project) foundProject = data.project;
			});
		if (!foundProject) return null;
		const formattedProject = formatProject(foundProject as IProject);
		return formattedProject;
	};

	const showDuplicateStartedSnackbar = () => {
		addSnackbar({
			show: true,
			type: 'LOADER',
			text: isTemplateGalleryOpen ? 'Creating template' : 'Duplicating journey'
		});
	};

	// Add Error snackbar
	const showDuplicateFailedSnackbar = () => {
		addSnackbar({
			show: true,
			type: 'ERROR',
			text: isTemplateGalleryOpen ? 'Template creation failed' : 'Journey Duplication Failed'
		});
		removeSnackbar(5000);
		history.push('/studio');
	};

	/**
	 * Entry point of Duplication
	 * @param action : 'WITH_ASSETS'| 'USE_TEMPLATE'|'DUPLICATE'| 'WITHOUT_ASSETS':
	 * @param actionableProjectId : project that needs to be duplicatated
	 * @param next () => any
	 * @param projectFound IPoject
	 */
	const onDuplicate = async (
		action: DuplicateAction,
		actionableProjectId: string,
		next?: (duplicatedProject?: CustomProject) => any,
		projectFound?: IProject
	) => {
		try {
			let hasCanvas = false;
			if (!projectFound) {
				showDuplicateStartedSnackbar();
				// Check if project contains canvas
				const data = await findAndFormatProject(actionableProjectId);

				if (!data) {
					showDuplicateFailedSnackbar();
					return;
				}
				projectFound = data.project;
				hasCanvas = data.hasCanvas;
			}

			// Check if the DUPLICATED project is going to be a template or not
			const isTemplate =
				!!(action === 'WITH_ASSETS' || action === 'WITHOUT_ASSETS') &&
				!isTemplateGalleryOpen;
			// Check if the DUPLICATED project is going to have assets or not
			const withAssets = action !== 'WITHOUT_ASSETS';
			// Check if the project to be duplicated is pinned

			const isPinned = favoriteProjects.some(
				(p) => (p as IProject)?._id === actionableProjectId
			);

			if (hasCanvas && action !== 'WITHOUT_ASSETS') {
				// Duplicate via API - not optimistic
				duplicateProjectByID(actionableProjectId, isTemplate, withAssets, isPinned, next); // replace this
			} else {
				// Set the name of the new duplicated project
				// If duplicating a template remove Template word and add (Copy)
				let { name = 'Untitled' } = projectFound?.projectDetails as IProjectDetail;
				if (!isTemplateGalleryOpen || !name) {
					name =
						action === 'USE_TEMPLATE'
							? (`${`${(
									(projectFound?.projectDetails as IProjectDetail).name as string
							  ).replace('Template - ', '')}(Copy)`}` as string)
							: (`${isTemplate ? 'Template - ' : ''}${
									(projectFound?.projectDetails as IProjectDetail).name
							  }${isTemplate ? '' : ' (Copy)'}` as string);
				}

				const parentGroupId = isGroupOpened
					? (currentParentGroup._id as string)
					: (user.defaultProjectGroup as string);

				const response = await onDuplicateProject(actionableProjectId, {
					parentGroupId,
					isTemplate,
					newName: name,
					shouldCloneAssets: action !== 'WITHOUT_ASSETS'
				});

				if (response) {
					const { clonedProject, clonedGroups, clonedBlocks, clonedNotes, clonedNodes } =
						response;
					const newProjectId = clonedProject._id as string;

					const apiPayload: TDuplicateProjectOptimisticallyThunkArg = {
						payload: {
							parentGroupId,
							clonedItems: {
								project: clonedProject,
								groups: clonedGroups,
								blocks: clonedBlocks,
								notes: clonedNotes,
								nodes: clonedNodes
							},
							originalProjectId: actionableProjectId,
							populatedUser: {
								userDetails: user.userDetails,
								_id: user._id,
								userName: user.userName,
								email: user.email,
								profilePic: user.profilePic,
								userPreferences: user.userPreferences
							}
						}
					};

					(store.dispatch as CustomDispatch)(duplicateProjectOptimistically(apiPayload))
						.unwrap()
						.then(() => {
							/** Load updated users to redux */
							(store.dispatch as CustomDispatch)(
								loadProjectUsers({
									payload: {
										projectId: newProjectId
									}
								})
							);
						})
						.catch((error) => {
							console.error('Error: ', error);
							// Add Error snackbar
							showDuplicateFailedSnackbar();
						});
					if (isPinned) {
						onFavoriteChange(newProjectId, false, false, false);
					}
					// Create a custom project data as required by Homebase
					const newDate = new Date();
					const duplicatedCustomProject: CustomProject = {
						_id: newProjectId,
						projectName: name,
						lastUpdatedAt: newDate.toDateString(),
						thumbnail: {
							src: projectFound?.thumbnail.src || '#FFFFFF',
							originalSrc: projectFound?.thumbnail.src || '#FFFFFF',
							isCustom: false
						},
						yourRole: 'OWNER',
						isPinned: false,
						isSubscribed: true,
						link: `/project/${newProjectId}`,
						accessLevel: 'PROJECT',
						size: 0,
						collaborators: [],
						noOfBlocks: clonedBlocks.length,
						isTemplate,
						projectProgress: projectProgress?.[newProjectId] || 0,
						isGroup: false,
						isAIGenerated: false
					};

					// Creating journey from template
					if (isTemplateGalleryOpen) {
						// NOTE:
						// There is a race condition here. In onTemplateSelect fn, we are adding a snackbar, and we are removing it here.
						// Since duplication doesn't take time, the removeSnackbar function will be invoked even
						// before the snackbar data is updated in Redux.
						// Therefore, a 1-second setTimeout has been added.
						setTimeout(() => {
							removeSnackbar(0, () => {
								sessionStorage.setItem(
									SessionStorageKeys.TEMPLATE_SUCCESS_SNACKBAR,
									'true'
								);
								history.push(`/project/${newProjectId}`);
							});
						}, 1000);
					} else {
						// Scroll and highlight
						setTimeout(() => highlightAndScrollToView(newProjectId), 200);

						// Add snackbar
						handleDuplicateSuccessSnackbar(isTemplate, newProjectId);
						// NOTE: setTimeout required so to control rerendering which was removing the selected project
						setTimeout(() => {
							if (next && typeof next === 'function') next(duplicatedCustomProject);
						}, 900);
					}
				} else showDuplicateFailedSnackbar();
			}
		} catch (error) {
			console.error('Error : ', error);
			showDuplicateFailedSnackbar();
		}
	};

	/**
	 * Callback to handle click on Logo in Homebase
	 */
	const onLogoClick = () => {
		history.push('/studio');
	};

	/**
	 * Function to handle opening and closing of templates gallery
	 * @param action - string
	 */
	const handleTemplatesAction = useCallback(
		async (action: 'OPEN' | 'CLOSE') => {
			if (action === 'OPEN') {
				window.sessionStorage.setItem(SessionStorageKeys.TEMPLATES_GALLERY_OPEN, 'true');
				setIsTemplateGalleryOpen(true);
				// Get the templates from indexed db and set them to the state
				const cachedTemplates = await getTemplatesFromLocalForage();
				const formattedTemplates = formatProjectData(cachedTemplates);

				setTemplates((prev) => ({ ...prev, data: formattedTemplates }));
				// Track viewing templates gallery event
				trackEvent(TemplatesEvents.TEMPLATES_GALLERY);
			} else {
				window.sessionStorage.removeItem(SessionStorageKeys.TEMPLATES_GALLERY_OPEN);
				setIsTemplateGalleryOpen(false);
				setTemplates((prev) => ({ ...prev, data: [] }));
			}
		},
		[templates]
	);

	/**
	 * Function to handle template selection
	 * @param id - string
	 */
	const onTemplateSelect = useCallback(
		async (id: string) => {
			addSnackbar({
				text: 'Creating new journey from template',
				show: true,
				type: 'LOADER'
			});
			const { template: selectedTemplate } = await dispatch(
				loadTemplateById({ payload: { id } })
			).unwrap();

			if (selectedTemplate?.project) {
				const { project } = selectedTemplate;
				const selectedProjectName = (project?.projectDetails as IProjectDetail)?.name;
				// This will duplicate the template optimistically and add the user to it.
				onDuplicate(DuplicateAction.WITH_ASSETS, id, undefined, project);

				// Track using template event
				const eventProps: TUseTemplateEventData = {
					elementId: id,
					name: selectedProjectName as string
				};
				trackEvent(TemplatesEvents.USE_TEMPLATE, eventProps);
			} else {
				showDuplicateFailedSnackbar();
			}
		},
		[templates]
	);

	/**
	 * Get project progress
	 */
	useEffect(() => {
		const projectIds: string[] = [];
		const defaultProjects = (getDefaultGroup() as IProjectGroup)?.projects as IProject[];
		const sharedProjects = user.sharedProjects as IProject[];
		if (defaultProjects) {
			projectIds.push(
				...defaultProjects.map((defaultProject) => defaultProject?._id as string)
			);
		}

		if (sharedProjects) {
			projectIds.push(...sharedProjects.map((sharedProject) => sharedProject._id as string));
		}

		if (projectIds) {
			getProjectProgress(projectIds)
				.unwrap()
				.then((res) => {
					// Summing up progress values of all projects
					if (res) {
						type ProjectProgress = {
							[key: string]: number;
						};
						const totalProgress = Object.values<number>(res.projectProgress).reduce(
							(acc: number, curr: number) => acc + curr,
							0
						);
						loaderProgress = Math.min(
							100,
							Math.max(0, totalProgress / projectIds.length)
						);
						setProgress(res.projectProgress as ProjectProgress);
					}
				});
		}
	}, [user]);

	// Handles the templates error
	useEffect(() => {
		if (isTemplateGalleryOpen && templates.isError) {
			// If failed to fetch templates or there are not templates show the snackbar and go back to studio
			addSnackbar({ text: 'No templates available', show: true });
			removeSnackbar(3000, () => {
				handleTemplatesAction('CLOSE');
			});
		}
	}, [templates, isTemplateGalleryOpen]);

	/**
	 * Triggers when someone uses studio search
	 */
	useEffect(() => {
		// If search filters present, then track studio search event
		if (Object.keys(searchFilters).length) {
			// Track using search on studio event
			const eventProps: TUseSearchEventData = {
				searchQuery: searchFilters,
				searchType: 'STUDIO'
			};
			trackEvent(CustomEvents.USE_SEARCH, eventProps);
		}
	}, [searchFilters]);

	/** Runs upon initial mount of Homebase Project */
	useEffect(() => {
		/**
		 * Function to handle reload
		 */
		const onBeforeUnload = (event: BeforeUnloadEvent) => {
			handleTemplatesAction('CLOSE');
			if (visualDeleteProject.current || actionableProject) {
				// Display a warning message to the user
				const message = `A ${
					visualDeleteProject.current ? 'delete' : 'duplicate'
				} operation is in progress. Are you sure you want to leave the page?`;
				event.returnValue = message; // Standard for most browsers
				return message; // For some older browsers
			}
			saveUserInLocalForage(getUserFromRedux());

			return true;
		};

		/**
		 * Function to remove context menu from dom on scroll
		 */
		const onScroll = () => {
			const contextMenu = document.querySelector<HTMLDivElement>(
				'[data-radix-popper-content-wrapper] .context-content'
			);

			if (contextMenu) contextMenu.remove();
		};

		window.addEventListener('beforeunload', onBeforeUnload);
		window.addEventListener('scroll', onScroll);

		return () => {
			handleTemplatesAction('CLOSE');
			saveUserInLocalForage(getUserFromRedux());
			window.removeEventListener('beforeunload', onBeforeUnload);
			window.removeEventListener('scroll', onScroll);
		};
	}, []);

	/**
	 * Runs when parent group in redux is changed
	 */
	useEffect(() => {
		const isGroupPresent = Boolean(currentParentGroup._id);
		if (isGroupPresent && !isGroupOpened) setIsGroupOpened(true);
		else if (isGroupOpened && !isGroupPresent) {
			const sessionGroupId = window.sessionStorage.getItem(SessionStorageKeys.ACTIVE_GROUP);
			if (sessionGroupId) handleGroupDetails(JSON.parse(sessionGroupId).id);
		}

		/**
		 * Function to handle reload
		 */
		const onBeforeUnload = () => {
			if (isGroupPresent) {
				window.sessionStorage.setItem(
					SessionStorageKeys.ACTIVE_GROUP,
					JSON.stringify({
						id: currentParentGroup._id as string,
						name: currentParentGroup.name as string
					})
				);
				saveGroupInLocalForage(currentParentGroup);
			}
		};

		window.addEventListener('beforeunload', onBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', onBeforeUnload);
		};
	}, [currentParentGroup, isGroupOpened]);

	// Runs on mount and on isTemplatesEnabled change
	useEffect(() => {
		if (flags.isTemplatesEnabled) {
			// Fetch the templates
			dispatch(loadTemplates())
				.unwrap()
				.then((data) => {
					saveTemplatesInLocalForage(data.templates).then(() => {
						setTemplates((prev) => ({ ...prev, isFetching: false }));
					});
				})
				.catch(() => {
					setTemplates((prev) => ({ ...prev, isFetching: false, isError: true }));
				});
		}
	}, [flags.isTemplatesEnabled]);

	useOnceOnDepChange(() => {
		// This will take care of template gallery being opened even before the templates are fetched.
		if (
			!templates.isFetching &&
			isTemplateGalleryOpen &&
			templates.data.length === 0 &&
			!templates.isError
		) {
			handleTemplatesAction('OPEN');
		}
	}, templates.isFetching);

	const processProjectData = useCallback(() => {
		let journeyData = aggregatedData;

		if (isGroupOpened) journeyData = groupAggregatedData;
		else if (isTemplateGalleryOpen) journeyData = templates.data;

		// This includes all pinned projects including the ones in a group
		const pinnedProjectsData = formatProjectData(favoriteProjects, projectProgress);
		const filterByPinned = isGroupOpened ? [] : pinnedProjectsData;
		let filteredJourneyData = journeyData;

		if (Object.keys(searchFilters).length > 0) {
			filteredJourneyData =
				filterProjectData(searchFilters, journeyData, filterByPinned) || [];
		}

		const createdByUsers: Partial<IUser>[] = [];
		const sharedWithUsers: Partial<IUser>[] = [];

		journeyData.forEach((filterProject) => {
			if (filterProject.createdBy?._id) {
				const existingCreatedByUser = createdByUsers.find(
					(createdUser) => createdUser?._id === filterProject?.createdBy?._id
				);
				if (!existingCreatedByUser) {
					createdByUsers.push(filterProject.createdBy);
				}
			}

			filterProject.collaborators?.forEach((collaborator) => {
				if (collaborator?.id) {
					const existingSharedWithUser = sharedWithUsers.find(
						(sharedUser) => sharedUser?._id === collaborator?.id
					);
					if (!existingSharedWithUser) {
						sharedWithUsers.push({ ...collaborator, _id: collaborator.id });
					}
				}
			});
		});

		// Retrieve existing data from localStorage
		const storedSharedWithUsers = localStorage.getItem('sharedWithUsers');
		const parsedStoredSharedWithUsers = storedSharedWithUsers
			? JSON.parse(storedSharedWithUsers)
			: [];

		// Add only new sharedWithUsers to localStorage
		const newSharedWithUsers = sharedWithUsers.filter(
			(newUser) =>
				!parsedStoredSharedWithUsers.some(
					(storedUser: { _id: string | mongoose.Schema.Types.ObjectId | undefined }) =>
						storedUser._id === newUser._id
				) && newUser._id !== user._id
		);

		// Merge new users with existing ones
		const updatedSharedWithUsers = [...parsedStoredSharedWithUsers, ...newSharedWithUsers];

		// Store merged data back to localStorage
		localStorage.setItem('sharedWithUsers', JSON.stringify(updatedSharedWithUsers));

		return { filteredJourneyData, createdByUsers, sharedWithUsers };
	}, [
		isGroupOpened,
		groupAggregatedData,
		aggregatedData,
		searchFilters,
		isTemplateGalleryOpen,
		templates,
		favoriteProjects
	]);

	/**
	 * Callback to handle click on edit thumbnail context menu option
	 * @param id id of the project
	 * @param name name of the project
	 * @param thumbnail existing thumbnail of project
	 */
	const onEditThumbnail = (
		id: string,
		name: string,
		thumbnail: string,
		originalThumbnail: string,
		isCustom: boolean = false
	) => {
		// For projects with no thumbnail we're storing project name in custom project's thumbnail
		// So to distinguis that case we'll check if incoming thumbnail is either a url or color
		// If none is true, that means we can say thumbnail is journey name, in that case pass thumbnail as white color
		const isValidUrl = validator.isURL(thumbnail, { protocols: ['https'] });
		const isValidColor = isThumbnailColorString(thumbnail);
		setEditThumbnailDetails({
			id,
			name,
			thumbnail: isValidUrl || isValidColor ? thumbnail : '#FFFFFF',
			originalThumbnail,
			isCustom
		});
	};

	/**
	 * Callback to handle on saving changed thumbnail
	 * @param projectId id of the project
	 * @param thumbnail new thumbnail
	 * @param onComplete callback to handle request completion
	 */
	const onThumbnailChange = (
		projectId: string,
		thumbnail: string,
		originalThumbnail: string,
		onComplete: () => void
	) => {
		try {
			const data = isGroupOpened ? groupAggregatedData : aggregatedData;

			const activeJourneyIndex = data.findIndex((proj) => proj._id === projectId);
			const activeJourney = data[activeJourneyIndex] as CustomProject;

			if (activeJourney) {
				// Update the state locally whether it's a group or journey and scroll
				activeJourney.thumbnail.src = thumbnail;
				data[activeJourneyIndex] = activeJourney;
				if (isGroupOpened) setGroupAggregatedData(data);
				else setAggregatedData(data);
			}

			// Find the group id if journey is inside a group
			const groupId = isGroupOpened
				? currentParentGroup._id
				: (user.defaultProjectGroup as string);
			// Find respective group of journey
			const parentGroup = (
				isGroupOpened
					? currentParentGroup
					: (user.projectGroups as IProjectGroup[])?.find((grp) => grp._id === groupId)
			) as IProjectGroup;

			// Find the journey from group
			const oldProject = (parentGroup.projects as IProject[]).find(
				(p) => (p._id as string) === projectId
			);

			// Construct update object with updates
			const update = {
				thumbnail: {
					src: thumbnail,
					isCustom: originalThumbnail !== thumbnail,
					originalSrc: originalThumbnail
				}
			} as Partial<IProject>;

			// Construct the payload
			const actionPayload: TEditProjectThunkArg = {
				payload: {
					_id: projectId,
					update
				},
				prevState: {
					prevProject: oldProject as IProject
				}
			};

			/**
			 * Function to update redux state, display snackbar
			 */
			const onSuccess = () => {
				const snackbarPayload: ISnackBar = {
					text: 'Thumbnail changed.',
					show: true,
					type: 'NORMAL'
				};

				addSnackbar(snackbarPayload);
				removeSnackbar(2000);
			};

			/**
			 * Function to display snackbar on edit failure
			 */
			const onFailure = () => {
				const snackbarPayload: ISnackBar = {
					text: 'Failed to change thumbnaiil of journey.',
					show: true,
					type: 'ERROR'
				};

				addSnackbar(snackbarPayload);
				removeSnackbar(2000);
			};

			(store.dispatch as CustomDispatch)(editProject(actionPayload))
				.unwrap()
				.then(() => {
					onSuccess();

					// If group is opened, only update the group data in redux
					if (isGroupOpened) {
						const activeGroup = clone(currentParentGroup);
						const updatedGroup = findAndUpdateJourneyInGroup(
							activeGroup,
							projectId,
							update,
							false
						);

						// Update project with new thumbnail in redux project group
						dispatch(updateGroup(updatedGroup));

						const projectGroups = clone(user.projectGroups) as IProjectGroup[];
						const activeGroupIndex = projectGroups.findIndex(
							(grp) => grp._id === groupId
						);

						if (activeGroupIndex !== -1) {
							const userActiveGroup = clone(
								projectGroups[activeGroupIndex]
							) as IProjectGroup;
							const allJourneys = clone(userActiveGroup.projects) as IProject[];
							const journeyIndex = allJourneys.findIndex(
								(jou) => jou._id === projectId
							);

							// Update the user only if updated project appears in one of the 4 projects of groups
							if (journeyIndex !== -1) {
								const userUpdatedGroup = findAndUpdateJourneyInGroup(
									userActiveGroup,
									projectId,
									update,
									false
								);

								projectGroups[activeGroupIndex] = userUpdatedGroup;

								const updatedUser = {
									...user,
									projectGroups
								};

								// Update project with new thumbnail in user's project groups
								dispatch(updateUser(updatedUser));
							}
						}
					} else {
						const projectGroups = clone(user.projectGroups) as IProjectGroup[];
						const activeGroupIndex = projectGroups.findIndex(
							(grp) => grp._id === groupId
						);

						if (activeGroupIndex !== -1) {
							const activeGroup = clone(
								projectGroups[activeGroupIndex]
							) as IProjectGroup;
							const allJourneys = clone(activeGroup.projects) as IProject[];
							const journeyIndex = allJourneys.findIndex(
								(jou) => jou._id === projectId
							);

							if (journeyIndex !== -1) {
								const updatedGroup = findAndUpdateJourneyInGroup(
									activeGroup,
									projectId,
									update,
									false
								);

								projectGroups[activeGroupIndex] = updatedGroup;

								const updatedUser = {
									...user,
									projectGroups
								};

								// Update project with new thumbnail in user's project groups
								dispatch(updateUser(updatedUser));
							} else {
								const sharedProjects = clone(user.sharedProjects) as IProject[];
								const sharedJourneyIndex = sharedProjects.findIndex(
									(jou) => jou._id === projectId
								);

								if (sharedJourneyIndex !== -1) {
									const sharedJourney = sharedProjects[
										sharedJourneyIndex
									] as IProject;
									const updatedJourney = {
										...sharedJourney,
										...update
									};

									sharedProjects[sharedJourneyIndex] = updatedJourney;
									const updatedUser = {
										...user,
										sharedProjects
									};

									// Update project with new thumbnail in user's project groups
									dispatch(updateUser(updatedUser));
								}
							}
						}
					}
				})
				.catch(() => onFailure())
				.finally(() => onComplete());
		} catch (error) {
			console.error('Error editing thumbnail of Journey : ', error);
		}
	};

	// Homebase Props
	const homebaseProps = {
		journeys: processProjectData().filteredJourneyData,
		user,
		onEditUserPreference,
		tasks: tasks || [],
		hasProjects: true,
		isTaskActive: false,
		searchFilters,
		createdByUsers: processProjectData().createdByUsers,
		sharedWithUsers: processProjectData().sharedWithUsers,
		flags: {
			...flags,
			isCreateInNayaEnabled: flags.createInNaya,
			isCustomThumbnailsEnabled: flags.isCustomThumbnail
		},
		dbSearchResults: aggregatedSearchResult,
		isGlobalSearch,
		isGroupLoading,

		onAddJourney: createNewProject,
		onRename: onRenameJourneyOrGroup,
		onDuplicate,
		onPinToggle: onFavoriteChange,
		onSubscriptionToggle: onSubscriptionChange,
		onDelete: onDeleteProjectCallback,
		onShare: shareProjectByID,
		onLogout: logoutActiveUser,
		handleLabs,
		handleTask,
		onLogoClick,
		onUnmarkTemplate,
		handleFilterOption,
		setSearchFilters,
		handleProjectGroups,
		handleGroupDetails,
		setShowAppIntegrationModal: () => dispatch(setShowAppIntegrationModal(true)),
		onCancelAIJourney,
		handleGlobalSearch: handleSearch,
		onEditThumbnail,
		handleTemplatesAction,
		onTemplateSelect
	};

	return user._id ? (
		<>
			{/* Set the title that is shown in browser tab */}
			<Helmet>
				<title>Studio | Naya</title>
			</Helmet>
			<Fragment key="HOMEBASE">
				<LoadingModal show={loader} />
				{showSharingModal && (
					<SharingModal
						selectedTab={ESharingTabs.PROJECT}
						open={showSharingModal}
						sharingDetails={{
							projectId: projectToShare,
							phaseId: undefined,
							blockId: undefined
						}}
						onClose={toggleSharingModal}
						variant="WITH_TABS"
					/>
				)}
				{editThumbnailDetails?.id && (
					<EditThumbnailModal
						variant="PROJECT"
						id={editThumbnailDetails.id}
						name={editThumbnailDetails.name}
						thumbnail={editThumbnailDetails.thumbnail}
						originalThumbnail={editThumbnailDetails.originalThumbnail}
						isCustom={editThumbnailDetails.isCustom}
						onClose={() => setEditThumbnailDetails(undefined)}
						onThumbnailChange={onThumbnailChange}
					/>
				)}

				{showDeleteModal && actionableProject && (
					<DeleteModal
						show={showDeleteModal}
						close={() => {
							resetDeleteStates();
							toggleDeleteModal();
						}}
						projectName={actionableProject.projectName || 'Untitled'}
						onDelete={onDeleteProject}
					/>
				)}

				<AppModal
					open={showBrainToolModal}
					close={() => handleCloseModal()}
					width="1223px"
					height="698px"
				>
					{renderTool()}
				</AppModal>

				<Homebase {...homebaseProps} />
				<SnackBar
					show={snackBarData.show as boolean}
					snackbarMessage={snackBarData.text as string}
					variant={snackBarData.type as 'LOADER' | 'NORMAL' | 'ERROR'}
					loaderColor={snackBarData.loaderColor}
					actionButtonData={snackBarData.actionButtonData}
				/>
			</Fragment>
		</>
	) : (
		<>
			{/* Set the title that is shown in browser tab */}
			{completedAuthCheck && (
				<Helmet>
					<title>Unauthorized access | Naya</title>
				</Helmet>
			)}
			<UnauthorizedOnline progress={loaderProgress} />
		</>
	);
}
