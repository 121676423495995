import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { GET_FILE_CNVRT_TASK, PUBLISH_FILE_CNVRT_TASK } from 'src/endpoints/othersAPIs';

type TFileConverterPayload = {
	link: string;
	format: 'STEP';
	fileName: string;
	subTaskId: string;
	taskId: string;
};

export type TGetFCTaskResponse = {
	result: string;
	status: 'COMPLETED' | 'PROCESSING' | 'FAILED';
	sub_tasks: [
		{
			converted_link: string;
			id: string;
			original_link: string;
			status: 'COMPLETED' | 'FAILED' | 'PROCESSING' | 'UPLOADING' | 'DOWNLOADING';
		}
	];
	tasks_failed: number;
	tasks_succeeded: number;
	total_tasks: number;
	error?: string;
};

/**
 * Posts data to file converter api
 */
export const publishFCTask = createAsyncThunk(
	'file-converter/publish',
	async (payload: TFileConverterPayload, { rejectWithValue }) => {
		const { link, format, fileName, subTaskId, taskId } = payload;
		try {
			const response = await axios.post<{ id: string; result: string; status: string }>(
				PUBLISH_FILE_CNVRT_TASK,
				{
					file_url: link,
					input_format: format,
					file_name: fileName,
					sub_task_id: subTaskId,
					task_id: taskId
				}
			);
			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

/**
 * Get task from file converter
 */
export const getFCTask = createAsyncThunk(
	'file-converter/get',
	async (payload: { taskId: string }, { rejectWithValue }) => {
		const { taskId } = payload;
		if (!taskId) return false;
		try {
			const response = await axios.get<TGetFCTaskResponse>(
				`${GET_FILE_CNVRT_TASK}/${taskId}`
			);
			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);
