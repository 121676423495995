import './ModelPreview.scss';
import { useState, useEffect, useRef } from 'react';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import * as THREE from 'three';
import { useSelector } from 'react-redux';
import { ReduxState } from 'src/redux/reducers/root.types';
// loaders
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { ColladaLoader } from 'three/examples/jsm/loaders/ColladaLoader';
import { store } from 'src';
import { updatePrototypeState } from 'src/redux/actions/prototyping';
import { CustomDispatch } from 'src/redux/actions/types';
import { ReactComponent as ThreeDCubeBig } from '../../../../../assets/prototyping/3d-icon-outlined.svg';
import OrbitIconCursor, {
	ReactComponent as OrbitIcon
} from '../../../../../assets/icons/nodes/orbit_3d.svg';
import { ReactComponent as ThreeDCube } from '../../../../../assets/icons/nodes/cube_3d.svg';
import { ModelProps } from '../prototyping.types';
import { PrototypingHomeProps } from '../homepage/PrototypingHomeProps';

/**
 * setup loader based on the extension of the model file
 * @param {string} extention
 * @returns Three js loader
 */
const getLoader = (extention: string): OBJLoader | STLLoader | ColladaLoader => {
	// const THREE_PATH = `https://unpkg.com/three@0.${THREE.REVISION}.x`;
	// const DRACO_LOADER = new DRACOLoader().setDecoderPath(`${THREE_PATH}/examples/js/libs/draco/gltf/`);
	// const KTX2_LOADER = new KTX2Loader().setTranscoderPath(`${THREE_PATH}/examples/js/libs/basis/`);
	switch (extention.toUpperCase()) {
		case 'OBJ':
			return new OBJLoader();
		case 'STL': {
			return new STLLoader();
		}
		case 'DAE': {
			return new ColladaLoader();
		}
		default:
			return new OBJLoader();
	}
};

/**
 * Function for getting loader based on extension type
 * @param scene THREE.Scene
 * @param file {url: string, extension: string}
 * @param options {receiveShadow: boolean, castShadow: boolean}
 * @returns THREE loader
 */
function loadModel(
	scene: THREE.Scene,
	file: { url: string; extension: string },
	options: { receiveShadow: boolean; castShadow: boolean }
) {
	const loader = getLoader(file.extension);
	const { receiveShadow, castShadow } = options;
	return new Promise((resolve, reject) => {
		loader.load(
			file.url,
			(result: any) => {
				let model;
				if (
					file &&
					file.extension &&
					['OBJ', 'X3D'].includes(file.extension.toUpperCase())
				) {
					model = result;
				} else if (
					file &&
					file.extension &&
					['STL'].includes(file.extension.toUpperCase())
				) {
					const material = new THREE.MeshPhongMaterial({ color: 0x888888 });
					const mesh = new THREE.Mesh(result, material);
					mesh.scale.set(0.5, 0.5, 0.5);
					model = mesh;
				} else {
					model = result.scene || result.scenes[0];
				}
				const obj = model;
				obj.position.y = 0;
				obj.position.x = 0;
				obj.receiveShadow = receiveShadow;
				obj.castShadow = castShadow;

				scene.add(obj);

				obj.traverse((child: any) => {
					if (child.isMesh) {
						child.castShadow = castShadow;
						child.receiveShadow = receiveShadow;
					}
				});

				resolve(obj);
			},
			undefined,
			(error) => {
				console.error(error);
				reject(error);
			}
		);
	});
}

function easeOutCirc(x: number) {
	return Math.sqrt(1 - (x - 1) ** 4);
}

const Model = ({ file, color }: ModelProps) => {
	const refContainer = useRef<HTMLDivElement>(null);
	const [loading, setLoading] = useState(true);
	const [rendererData, setRenderer] = useState<THREE.WebGLRenderer>();
	const [ambientLight, setAmbientLight] = useState<THREE.AmbientLight>();
	const [directionalLight, setDirectionLight] = useState<THREE.DirectionalLight>();

	useEffect(() => {
		const { current: container } = refContainer;
		let req: any | null = null;
		if (container && !rendererData) {
			const scW = container.clientWidth!;
			const scH = container.clientHeight!;
			const renderer = new THREE.WebGLRenderer({
				antialias: true,
				alpha: true
			});
			renderer.setPixelRatio(window.devicePixelRatio);
			renderer.setSize(scW, scH);
			renderer.outputEncoding = THREE.sRGBEncoding;
			renderer.setClearColor(0xeaeaea, 1);
			container.appendChild(renderer.domElement);
			setRenderer(renderer);

			const scene = new THREE.Scene();
			let camera: THREE.PerspectiveCamera | null = null;
			let frame = 0;
			loadModel(scene, file, {
				receiveShadow: false,
				castShadow: false
			}).then((model: any) => {
				setLoading(false);
				const box = new THREE.Box3().setFromObject(model);
				const size = box.getSize(new THREE.Vector3()).length();
				const center = box.getCenter(new THREE.Vector3());
				camera = new THREE.PerspectiveCamera(30, 1, size / 100, size * 100);
				const initialCameraPosition = new THREE.Vector3(
					20 * Math.sin(0.2 * Math.PI),
					10,
					20 * Math.cos(0.2 * Math.PI)
				);
				if (camera) {
					const lightColor = 0x888888;
					const light = new THREE.AmbientLight(0x888888, 1); // fill with the color selected
					setAmbientLight(light);
					scene.add(light);
					const direcLight = new THREE.DirectionalLight(lightColor, 0.5);
					setDirectionLight(direcLight);
					direcLight.position.set(0.5, 0, 0.866);
					camera.add(direcLight);
					scene.add(camera);

					const controls = new OrbitControls(camera, renderer.domElement);
					controls.autoRotate = true;
					controls.target = center;

					const resizeCanvasToDisplaySize = () => {
						const canvas = renderer.domElement;
						// look up the size the canvas is being displayed
						const width = canvas.clientWidth;
						const height = canvas.clientHeight;

						// adjust displayBuffer size to match
						if (canvas.width !== width || canvas.height !== height) {
							// you must pass false here or three.js sadly fights the browser
							renderer.setSize(width, height, false);
							camera!.aspect = width / height;
							camera!.updateProjectionMatrix();
							// update any render target sizes here
						}
					};
					const animate = () => {
						resizeCanvasToDisplaySize();
						req = requestAnimationFrame(animate);
						frame = frame <= 100 ? frame + 1 : frame;

						if (frame <= 100) {
							const p = initialCameraPosition;
							const rotSpeed = -easeOutCirc(frame / 120) * Math.PI * 20;

							camera!.position.y = 10;
							camera!.position.x =
								p.x * Math.cos(rotSpeed) + p.z * Math.sin(rotSpeed);
							camera!.position.z =
								p.z * Math.cos(rotSpeed) - p.x * Math.sin(rotSpeed);
							camera!.lookAt(center);
						} else {
							controls.update();
						}
						renderer.render(scene, camera!);
					};
					animate();
				}
			});
			if (camera) renderer.render(scene, camera);
		}
		if (ambientLight && directionalLight) {
			if ((color === 15856365 || color === 16447989) && rendererData) {
				rendererData.setClearColor(0x7e7e7e, 1);
			} else {
				rendererData?.setClearColor(0xeaeaea, 1);
			}
			ambientLight.color.set(color); // set light color to selected color
			ambientLight.intensity = 1.0;
			directionalLight.color.set(color);
			directionalLight.intensity = 0.5;
		}
		return () => {
			cancelAnimationFrame(req);
			rendererData?.dispose();
		};
	}, [color, file, rendererData, ambientLight, directionalLight]);

	return (
		<div style={{ height: '100%', width: '100%', position: 'relative' }} ref={refContainer}>
			{loading && (
				<span
					style={{
						position: 'absolute',
						left: '50%',
						top: '50%',
						transform: 'translateX(-50%)'
					}}
				>
					Loading...
				</span>
			)}
		</div>
	);
};

const ModelPreview = ({ setPrototypePage }: PrototypingHomeProps) => {
	const { file, color } = useSelector((state: ReduxState) => state.prototyping);
	const isSupportedModel = ['OBJ', 'STL', 'DAE'].includes(file.extension.toUpperCase());

	const handleChangeModel = () => {
		(store.dispatch as CustomDispatch)(
			updatePrototypeState({ color: 0x888888, isReadyForOrder: false })
		);
		setPrototypePage('UPLOAD');
	};

	return (
		<div className="model-preview-container">
			<h5 className="heading">
				Preview
				{!isSupportedModel && ' not available'}
			</h5>
			<div
				className="preview"
				style={{
					borderColor: isSupportedModel ? 'var(--theme-color-1)' : '#9F9F9F',
					cursor: `url(${OrbitIconCursor}) 32 32, pointer`
				}}
			>
				{isSupportedModel ? <Model file={file} color={color} /> : <ThreeDCubeBig />}
				<div className="threed-icon">
					<ThreeDCube />
					<span>3D model</span>
				</div>
				<OrbitIcon
					color={isSupportedModel ? 'var(--theme-color-1)' : '#9F9F9F'}
					className="orbit-icon"
				/>
			</div>
			<div
				className="change-model"
				onClick={handleChangeModel}
				onKeyDown={() => {}}
				role="presentation"
			>
				change 3D model
			</div>
		</div>
	);
};

export default ModelPreview;
