import qs from 'qs'

/**
 * Example usage: `http://localhost:3000/project?${getGatewayKey()}`
 * @returns a query string of the auth key used for api gateway
 */
export const getGatewayKey = () => {
  const key = process.env.REACT_APP_GATEWAY_KEY

  if (!key)
    return ''

  return qs.stringify({ key })
}