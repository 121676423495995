import { IAction, EstimationAITypes } from '../root.types';

export interface EstimateAIData {
  predictedPrice : number,
  minPrice : number,
  maxPrice : number,
  isEstimationLoading: boolean,
}

const initialPrediction : EstimateAIData = {
  predictedPrice: 0,
  minPrice: 0,
  maxPrice: 0,
  isEstimationLoading: false,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const estimationAI = (state = initialPrediction, action: IAction<EstimationAITypes | keyof typeof EstimationAITypes>) => {
  let newState: EstimateAIData;
  const predictionData: EstimateAIData = action.payload;

  switch (action.type) {
    case EstimationAITypes.DISPLAY_PREDICTION:
      newState = { ...state, ...predictionData };
      break;
    case EstimationAITypes.EDIT_PREDICTION:
      newState = {
        ...state,
        ...predictionData,
      };
      break;
    default:
      newState = { ...state, ...predictionData };
      break;
  }
  return newState;
};

export default estimationAI;
