import { FC, ReactElement } from 'react';
import { TUserAndRole } from '@naya_studio/types';
import { getStagesBlocksNodesOfProject } from 'src/redux/reduxActions/util';
import useProject from 'src/redux/hooks/useProject';
import cn from 'classnames';
import useUser from 'src/redux/hooks/user';
import { IconWithTooltip } from '@naya_studio/radix-ui';
import { SharingTabsProps } from './Tabs.types';
import { ESharingTabs } from '../../SharingModal.types';
import './Tabs.scss';
import {
	checkIfUserHasBlockLevelAccess,
	checkIfUserHasProjectLevelAccess,
	checkIfUserHasStageLevelAccess
} from '../../sharingUtil';

/**
 * Available sharing options
 */
const sharingOptions = [
	{
		key: ESharingTabs.PROJECT,
		title: 'Entire Project'
	},
	{
		key: ESharingTabs.PHASE,
		title: 'By Group'
	},
	{
		key: ESharingTabs.BLOCK,
		title: 'By Block'
	}
];

/**
 * Component for Sharing Tabs in Header
 */
const Tabs: FC<SharingTabsProps> = ({
	activeTab,
	projectId,
	setActiveTab
}: SharingTabsProps): ReactElement => {
	const { project } = useProject(projectId);
	const { user: currentUser } = useUser();
	const { stages, blocks } = getStagesBlocksNodesOfProject(projectId);

	/**
	 * Function to filter sharing options based on access level
	 * @returns filtered sharing options
	 */
	const getSharingOptionsBasedOnAccessLevel = () => {
		let finalSharingOptions = sharingOptions;
		if (
			!checkIfUserHasProjectLevelAccess(
				project?.users as TUserAndRole[],
				currentUser._id as string
			)
		) {
			finalSharingOptions = finalSharingOptions.filter((x) => x.key !== ESharingTabs.PROJECT);
		}
		if (!checkIfUserHasStageLevelAccess(stages, currentUser._id as string)) {
			finalSharingOptions = finalSharingOptions.filter((x) => x.key !== ESharingTabs.PHASE);
		}
		if (!checkIfUserHasBlockLevelAccess(blocks, currentUser._id as string)) {
			finalSharingOptions = finalSharingOptions.filter((x) => x.key !== ESharingTabs.BLOCK);
		}
		return finalSharingOptions;
	};

	/**
	 * Function to update active tab
	 * @param tab clicked tab
	 */
	const onTabClick = (tab: keyof typeof ESharingTabs) => {
		setActiveTab(tab);
	};

	/**
	 * Function to check if current tab is selected or not
	 * @param key Key of tab
	 */
	const checkIfTabIsActive = (key: keyof typeof ESharingTabs) => activeTab === key;

	const generateToolTip = (key: 'PROJECT' | 'PHASE' | 'BLOCK') => {
		let toolTip = '';
		switch (key) {
			case 'PROJECT':
				toolTip = 'Share entire project';
				break;
			case 'PHASE':
				toolTip = 'Share only group';
				break;
			case 'BLOCK':
				toolTip = 'Share only a block';
				break;
			default:
				toolTip = '';
		}
		return toolTip;
	};

	return (
		<div className="sharing-tabs tw-flex tw-items-center tw-gap-6">
			{getSharingOptionsBasedOnAccessLevel().map((tab) => (
				<IconWithTooltip
					tooltipText={generateToolTip(tab.key)}
					side="bottom"
					delayDuration={200}
					classNames="naya-logo-tooltip tw-relative tw-left-4"
				>
					<div
						key={tab.key}
						role="presentation"
						className="tab-option-container tw-flex tw-justify-center tw-items-center tw-cursor-pointer tw-gap-2"
						onClick={() => {
							onTabClick(tab.key);
						}}
					>
						{tab.key !== 'PROJECT' && (
							<div
								className={`bullet tw-rounded-full tw-w-2 tw-h-2 ${cn({
									'active-tab': tab.key === activeTab
								})}`}
							/>
						)}
						<p
							className={`tab-title tw-font-randRegular tw-text-xs tw-m-0 ${
								checkIfTabIsActive(tab.key) ? 'active' : ''
							}`}
						>
							{tab.title}
						</p>
					</div>
				</IconWithTooltip>
			))}
		</div>
	);
};

export default Tabs;
