import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, StaticContext, withRouter } from 'react-router';
// import { ReduxState } from 'src/redux/reducers/root.types';
import './Lock.scss';
import BaseNode from 'src/components/canvas/app/nodes/BaseNode';
import _ from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  LockDispatchProps, LockProps, PathParamsType, LockState,
} from './Lock.types';
import lockInctive from '../../../../../assets/icons/edit-menu/lock_inactive.svg';
import lockActive from '../../../../../assets/icons/edit-menu/lock_active.svg';
import { editNodes } from 'src/redux/reduxActions/node';
import { TEditNodesArgs } from 'src/types/argTypes';

class Lock extends React.Component<LockProps & LockDispatchProps & RouteComponentProps<PathParamsType>, LockState> {
  constructor(props : LockProps & LockDispatchProps & RouteComponentProps<PathParamsType>) {
    super(props);
    this.state = {
      allNodesLocked: false,
    };
  }

  componentDidMount() {
    this.checkIfAllNodesLocked();
  }

  componentDidUpdate(prevProps:
  Readonly<
  LockProps & LockDispatchProps & RouteComponentProps<PathParamsType, StaticContext, unknown>
  >): void {
    const { nodes } = this.props;
    if (prevProps.nodes !== nodes) {
      this.checkIfAllNodesLocked();
    }
  }

  checkIfAllNodesLocked = () => {
    const { nodes: allNodes } = this.props;
    let count = 0;
    if (allNodes.length === 0) {
      this.setState({ allNodesLocked: false });
    } else {
      for (let i = 0; i < allNodes.length; i++) {
        if (allNodes[i]?._isLocked) {
          count++;
        }
      }
      if (count === allNodes.length) {
        this.setState({ allNodesLocked: true });
      } else {
        this.setState({ allNodesLocked: false });
      }
    }
  };

  lockAllNodes = async () => {
    const { app } = this.props;
    const { allNodesLocked } = this.state;
    const allNodes = app.selectedNodes as BaseNode[];
    if (allNodes) {
      if (allNodesLocked) {
        for (let i = 0; i < allNodes.length; i++) {
          const prevData = _.cloneDeep(allNodes[i]?.nodeData);
          allNodes[i]?.toggleLock();
          const payload = {
            ...allNodes[i]?.nodeData,
            isLocked: allNodes[i]?._isLocked,
          };

          allNodes[i]!.prevNodeData = prevData;
          allNodes[i]!.nodeData = payload;
        }
      } else {
        for (let i = 0; i < allNodes.length; i++) {
          if (!allNodes[i]?._isLocked) {
            const prevData = _.cloneDeep(allNodes[i]?.nodeData);
            allNodes[i]?.toggleLock();
            const payload = {
              ...allNodes[i]?.nodeData,
              isLocked: allNodes[i]?._isLocked,
            };

            allNodes[i]!.prevNodeData = prevData;
            allNodes[i]!.nodeData = payload;
          }
        }
      }
      app.saveAllNodes([]);
      allNodes[0]?.setTransformerType();
      if (app.checkIfAnySelectedNodeIsLocked()) {
        app.transformer.translateEnabled = false;
      }
    }
    this.checkIfAllNodesLocked();
  };

  render() {
    const { allNodesLocked } = this.state;
    return (
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 1000, hide: 0 }}
        overlay={(
          <Tooltip
            id="button-tooltip-2"
            style={{
              fontSize: '12px',
              borderRadius: '2px',
              lineHeight: '16px',
              marginTop: '8px',
            }}
          >
            Lock
          </Tooltip>
        )}
      >
        <div
          role="presentation"
          className={allNodesLocked ? 'locked-icon-container active' : 'icon-container inactive'}
          onClick={(e: any) => {
            e.stopPropagation();
            this.lockAllNodes();
          }}
        >
          <img
            src={allNodesLocked ? lockActive : lockInctive}
            // style={{width:'14px', height:'20px'}}
            alt="Lock"
            className="icon"
          />
        </div>
      </OverlayTrigger>

    );
  }
}

/**
 * Redux state mapped to canvas props
 * @param state
 * @returns
 */
// @ts-ignore
const mapStateToProps = () => ({
});

/**
 * Redux actions mapped to canvas props
 * @param dispatch
 * @returns
 */
// @ts-ignore
const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  editNodes: (payload:TEditNodesArgs) => dispatch(editNodes(payload)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Lock));
