import { FC, useEffect, useState } from 'react';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import down_chevron_svg from 'src/assets/icons/toolbar/down_chevron.svg';
import fontSelectSvg from 'src/assets/icons/edit-menu/font_select.svg';
import './FontSize.scss';
import { getFontSizeProperties } from 'src/components/canvas/app/nodes/utils/helper';
import { FontSizeProps } from './FontSize.types';
import { fontSizeOptions } from './constants';

export type FontSizeWordType = 'Normal' | 'Sub-Heading' | 'Heading' | 'Title';

const FontSize: FC<FontSizeProps> = ({
  app,
  setSelectedOption,
  selectedOption,
  // textData: {
  //   textNodeData,
  // },
  activeTextProperties,
  nodes,
}) => {
  const parseFontSize = (fs: number | string | undefined) => {
    if (!fs) return 16;
    if (typeof fs === 'number') {
      return fs;
    }
    return parseInt(fs.split('px')[0] || '16', 10);
  };

  const getFontSize = (): number => {
    const allNodes = nodes;
    const allSizes = [];
    for (let i = 0; i < allNodes.length; i++) {
      const nodeData = app.selectedNodes[i]?.nodeData;
      if (nodeData?.text?.style?.fontSize) {
        allSizes.push(nodeData?.text?.style?.fontSize);
      }
      //  else if (textNodeData?.text?.style?.fontSize) {
      //   allSizes.push(textNodeData.text.style.fontSize);
      // }
    }
    if (allSizes.every((val, _i, arr) => val === arr[0])) {
      return parseFontSize(allSizes[0]);
    }
    return 16;
  };
  const getIndex = () => (Math.random() * 10 > 5 ? 0 : 1);
  const [fontOptionsIndex, setFontOptionsIndex] = useState<number>(getIndex());
  const [showFontInput, setShowFontInput] = useState(false);
  const [fontSize, setFontSize] = useState<number | string>(getFontSize());

  useEffect(() => {
    const { nodeData } = nodes[0];
    if (nodeData) {
      const existingFontSize = nodeData?.text?.style?.fontSize || 16;
      if (app.editText) {
        if (activeTextProperties.length === 1) {
          const { fontSize: propsFontSize } = activeTextProperties[0];
          if (propsFontSize) {
            setFontSize(Math.round((parseFloat(propsFontSize.split('px')[0]) / app.viewport.scale.x)));
          } else {
            setFontSize(getFontSize());
          }
        } else if (activeTextProperties.some((d: any) => d.fontSize && (d.fontSize !== `${existingFontSize}px`))) {
          setFontSize('Mixed');
        } else {
          setFontSize(existingFontSize);
        }
      } else {
        const allFontSizes = getFontSizeProperties(nodeData?.text?.value as string);
        if (allFontSizes && allFontSizes?.some((f) => f !== `${existingFontSize}`)) {
          setFontSize('Mixed');
        } else {
          setFontSize(getFontSize());
        }
      }
    }
  }, [activeTextProperties, nodes]);

  useEffect(() => {
    if (showFontInput) {
      const fontInputEle = document.getElementById('fontSize-input') as HTMLInputElement;
      if (fontInputEle) {
        fontInputEle.focus();
        fontInputEle.select();
      }
    }
  }, [showFontInput]);
  // const selectionRef = useRef({});

  // uncomment to use for selection based updates
  // const handleInputClick = () => {
  //   // @ts-ignore
  //   const { textFragment, textSelection } = selectionRef.current;
  //   if (textFragment) {
  //     const span = document.createElement('span');
  //     span.style.backgroundColor = "dodgerblue";
  //     span.style.color = "white";
  //     // textSelection.deleteContents();
  //     // textSelection.insertNode(textFragment);
  //     console.log("textSelection handleInputClick before", textSelection, textFragment);
  //     textSelection.surroundContents(span);
  //     console.log("textSelection handleInputClick", textSelection, textFragment);
  //   }
  // }

  const setFontSizeForAllNodes = (fs: number) => {
    const allNodes = app.selectedNodes;
    for (let i = 0; i < allNodes.length; i++) {
      app.selectedNodes[i]?.edit('FONT_SIZE', fs, !!(allNodes.length > 1 && i !== 0));
    }

    app.saveAllNodes([]);
    // if (allNodes.length === 1) {
    //   const selectionText = window.getSelection();
    //   console.log("setFontSizeForAllNodes selectionText", selectionText);
    //   const updatedFontSize = getUpdatedFontSize(fontSize)
    //   // @ts-ignore
    //   if (selectionText && selectionText?.toString() === selectionText.anchorNode.parentNode.innerText) {
    //     console.log("setFontSizeForAllNodes selectionText inside if");
    //     // @ts-ignore
    //     selectionText.anchorNode.parentNode.style.fontSize = `${updatedFontSize}px`;
    //   } else {
    //     console.log("setFontSizeForAllNodes selectionText inside else");
    //     document.execCommand("insertHTML", false, `<span style="font-size: ${updatedFontSize}px;">${window.getSelection()}</span>`);
    //   }
    //   app.selectedNodes[0]?.edit('FONT_SIZE', {
    //     fontSize, fontWeight
    //   }, false)
    // } else {
    //   for (let i = 0; i < allNodes.length; i++) {
    //     app.selectedNodes[i]?.edit('FONT_SIZE', {
    //       fontSize, fontWeight
    //     }, allNodes.length > 1 && i !== 0 ? true : false)
    //   }
    // }
  };

  const handleKeyDown = (e: any) => {
    e.stopPropagation();
    if (e.keyCode === 13) {
      e.preventDefault();

      setShowFontInput(false);
      app.disablePaste = false;
      // @ts-ignore
      // const { textFragment, textSelection, rangeClone } = selectionRef.current;
      // console.log("textSelection selection before", textSelection, textFragment, rangeClone)
      // if (textFragment) {
      //   textSelection.deleteContents();
      //   console.log("textSelection after deleting", textSelection, textFragment, rangeClone)
      //   rangeClone.insertNode(textFragment);
      //   // textSelection.selectNode(textFragment);
      // }
      // console.log("textSelection selection after", textSelection, textFragment, rangeClone)
      // const spanTag = document.createElement('span');
      // spanTag.style.fontSize = `${getUpdatedFontSize(fontSize as number)}px`; // verify for valid URL
      // console.log("textSelection test", textSelection?.toString() === textSelection.anchorNode.parentNode.innerText)
      // textSelection.surroundContents(spanTag);
      // const windowSelection = window.getSelection();
      // windowSelection?.removeAllRanges()
      // windowSelection?.addRange(rangeClone);
      if (fontSize) {
        if (fontSize > 200) {
          setFontSize(200);
          setFontSizeForAllNodes(200);
        } else {
          const roundedFontSize = Math.round(fontSize as number);
          setFontSize(roundedFontSize);
          setFontSizeForAllNodes(roundedFontSize);
        }
      }
    }
  };

  const handleFontClick = (e: any) => {
    if (e.detail === 2 && fontSize !== 'Mixed') {
      setShowFontInput(true);
      // uncomment to use selection based updates
      // console.log("Font Input clicked");
      // const sel = window.getSelection();
      // if (sel && sel.rangeCount) {
      //   const textSelection = sel.getRangeAt(0);
      //   const textFragment = textSelection.cloneContents();
      //   const rangeClone = textSelection.cloneRange();
      //   selectionRef.current = { textSelection, textFragment, rangeClone };
      //   console.log("textSelection", textFragment, textSelection);
      // }
    }
  };

  const handleOptions = (e: any, fs: number) => {
    e.stopPropagation();
    setFontSize(fs);
    setFontSizeForAllNodes(fs);
    setSelectedOption('');
    setShowFontInput(false);
  };

  const handleDownClick = () => {
    setShowFontInput(false);
    if (selectedOption === 'FONT_SIZE') {
      setSelectedOption('');
    } else {
      setFontOptionsIndex(getIndex());
      setSelectedOption('FONT_SIZE');
    }
  };

  // const getUpdatedFontSize = (fontSize: number) => {
  //   const zoomLevel = app.viewport.scale.x;
  //   let updatedFontSize = fontSize;
  //   if (zoomLevel) return updatedFontSize * zoomLevel
  //   else return updatedFontSize
  // }

  const renderDropDownItems = () => {
    const fontInformation = fontSizeOptions[fontOptionsIndex]!;
    const fontOptions = Object.keys(fontInformation!);
    return fontOptions.map((opt) => {
      const optionFontSize = fontInformation[opt]!.px;
      const isSelected = optionFontSize === fontSize;
      return (
        <Dropdown.Item onClick={(e) => handleOptions(e, optionFontSize)}>
          <div className="font-select">
            {isSelected && <img src={fontSelectSvg} alt="sel" />}
          </div>
          <div className="font-name" style={{ fontSize: fontInformation[opt]?.dropdownPx ?? 14 }}>
            {fontInformation[opt]?.placeholder}
          </div>
          <div className="font-size" style={{ fontSize: fontInformation[opt]?.dropdownPx ?? 14 }}>
            {fontInformation[opt]?.px}
          </div>
        </Dropdown.Item>
      );
    });
  };

  const renderFontData = () => {
    let fontDataJsx;
    if (showFontInput) {
      app.disablePaste = true;
      fontDataJsx = (
        <div className="fontInput">
          <input
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            id="fontSize-input"
            type="number"
            value={fontSize}
            // onClick={handleInputClick}
            onChange={(e: any) => {
              e.stopPropagation();
              setFontSize(e.target.value);
            }}
            onKeyDown={handleKeyDown}
            onKeyUp={(e: any) => {
              e.stopPropagation();
            }}
          />
        </div>
      );
    } else {
      fontDataJsx = (
        <div className="fontText">
          {fontSize}
        </div>
      );
    }
    return fontDataJsx;
    // return (
    //   <>
    //     { fontDataJsx }
    //     <div className='down-icon-container' onClick={handleDownClick}>
    //       <img src={down_chevron_svg} alt='select element' className='down-icon' />
    //     </div>
    //   </>
    // )
  };

  const getFontName = () => {
    if (showFontInput || selectedOption === 'FONT_SIZE') {
      const activeFontClass = showFontInput ? 'activeFontSize' : 'activeFontDropDown';
      return `font-size-container ${activeFontClass} ${fontSize === 'Mixed' ? 'mixed' : ''}`;
    }
    return `font-size-container ${fontSize === 'Mixed' ? 'mixed' : ''}`;
  };

  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 1000, hide: 0 }}
      overlay={(
        <Tooltip
          id="button-tooltip-2"
          style={{
            fontSize: '12px',
            borderRadius: '2px',
            lineHeight: '16px',
            marginTop: '10px',
          }}
        >
          Font size
        </Tooltip>
      )}
    >
      {({ ref, ...triggerHandler }) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <div role="presentation" className={getFontName()} ref={ref} {...triggerHandler} onClick={handleFontClick}>
          {renderFontData()}
          <Dropdown
            bsPrefix="Font-Size-Dropdown"
            show={selectedOption === 'FONT_SIZE'}
            onClick={handleDownClick}
          >
            <Dropdown.Toggle id="Font-basic-button" variant="success">
              <div className="down-icon-container">
                <img src={down_chevron_svg} alt="select element" className="down-icon" />
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="fontList">
              {renderDropDownItems()}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
    </OverlayTrigger>
  );
};

export default FontSize;
