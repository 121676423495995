const video = require('src/assets/brand/loader2.mp4');

const NayaLoader = () => (
	<div
		className="tw-w-full tw-h-full tw-flex"
		dangerouslySetInnerHTML={{
			__html: `
        <video width="500" height="auto" autoPlay loop playsinline muted id="myVideo">
          <source src="${video}" type="video/mp4" />
        </video>
      `
		}}
	/>
);

export default NayaLoader;
