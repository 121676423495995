import { EFeedbackType, IBlock, IFeedback, IUser, TUserAndRole } from '@naya_studio/types';
import { useContext, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'src/redux/reducers/root.types';
import { getAllUsersBasedOnIds } from 'src/redux/reduxActions/util';
import { isUndefined } from 'lodash';
import { ReactComponent as AlertIcon } from 'src/assets/icons/sharing-modal/alert.svg';
import { Button, FeedbackBubble, FeedbackWrapper, WarningModal } from '@naya_studio/radix-ui';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';
import useFeedbackActions from 'src/util/feedback/useFeedbackActions';
import { useParams } from 'react-router';
import { PathParamsType } from 'src/types/pathParams';
import { FeedbackContext } from 'src/components/expandedBlockView/ExpandedBlockView';
import formatTime from 'src/util/collaboration/util';
import trackEvent from 'src/util/analytics/analytics';
import { FeedbackEvents } from 'src/util/analytics/events';
import useUser from 'src/redux/hooks/user';
import { TMarkCompleteEventData } from 'src/util/analytics/analytic.types';
import { ActionItemPanel } from '../actionItemPanel/ActionItemPanel';
import { PollResults } from './PollResults';
import { Poll } from './Poll';
import { FeedbackUserMenu, FeedbackKebabMenuProps } from '../feedbackUserMenu/FeedbackUserMenu';
import { isActiveUserViewer } from '../util';

/** props for poll wrapper */
export type PollWrapperProps = {
	data: IFeedback;
	// Index of the feedback
	index: number;
	// Cb to handle the action item panel close
	onClose?: () => void;
};

/**
 * Component to render poll type feedback
 */
export const PollWrapper = ({ data, index, onClose }: PollWrapperProps) => {
	const [showResultsView, setShowResultsView] = useState(false); // handles the visibility of the resukts view
	const [isEditing, setIsEditing] = useState(false); // handles the edit state of the poll
	// const [isActionItem, setIsActionItem] = useState(true); // handles the toggle state of action item switch
	const [isCompleted, setIsCompleted] = useState(false); // handles the toggle of mark complete action
	const [selectedUsers, setSelectedUsers] = useState<string[]>([]); // holds the list of all the users selected for action item assignees
	// handles the visibility of action item toggle warning
	const [showActionItemWarning, setShowActionItemWarning] = useState(false);
	// handles the visibility of delete warning modal
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const { user } = useUser();

	const dispatch = useDispatch();

	// extarcting functions from feedback actions hook
	const {
		sendEditFeedback,
		checkIfUserTurnerOffActionItemToggleWarning,
		setNoActionItemToggleWarning,
		sendDeleteFeedback,
		removeActionItemAssignee,
		addActionItemAssignee
	} = useFeedbackActions();

	// extracting ids from url
	const { canvasId: blockId, projectId } = useParams<PathParamsType>();

	const { feedbackView } = useContext(FeedbackContext);

	// extract current block form redux
	const block = useSelector(
		(state) => (state as ReduxState).blocks.data[blockId],
		shallowEqual
	) as IBlock;

	// extract all user ids within the b,lock
	const userIds: string[] = (block?.users as TUserAndRole[]).map(
		(u: TUserAndRole) => u.user as string
	);

	// to check if user is viewer or not
	const isViewer = isActiveUserViewer(user, block?.users as TUserAndRole[]);

	// extract all populated users based on ids
	const allUsers = getAllUsersBasedOnIds(userIds);

	/**
	 * Function to check if user has casted vote,
	 * if no user id is provided it checks if any user has casted a vote
	 * @param optional userId id of the user to check, if not provided checks if any user has casted a vote
	 * @returns boolean
	 */
	const checkIfAnyUserHasVoted = (userId?: string) => {
		const allOptions = data.pollOptions;
		if (allOptions) {
			for (let i = 0; i < allOptions.length; i++) {
				const tempOpt = allOptions[i];
				if (tempOpt) {
					const { votes } = tempOpt;
					if (votes) {
						for (let j = 0; j < votes.length; j++) {
							if (votes[j] && votes[j]?.votedBy) {
								if (
									userId &&
									votes[j] &&
									votes[j]?.votedBy &&
									votes[j]?.votedBy === userId
								)
									return true;
								if (!userId) return true;
							}
						}
					}
				}
			}
		}
		return false;
	};

	// setting data to state variabkes
	useEffect(() => {
		if (checkIfAnyUserHasVoted(user?._id as string)) {
			setShowResultsView(true);
		}
		// When user is editing poll, and someones casts a vote
		if (isEditing && checkIfAnyUserHasVoted()) {
			setIsEditing(false);
			setShowResultsView(true);

			// If user tries to edit already submitted poll, then show the snackbar
			addSnackbar({
				show: true,
				text: 'You can not edit this poll now as users have already added their response to it.',
				type: 'NORMAL'
			});
			removeSnackbar(5000);
		}

		if (data.actionItemFor) {
			if (!isUndefined(data.actionItemFor[user?._id as string])) {
				setIsCompleted(data.actionItemFor[user._id as string] as boolean);
			}

			const allActionItemUsers = Object.keys(data.actionItemFor);
			setSelectedUsers(allActionItemUsers);
		}
	}, [data]);

	// send out an api call to delete poll
	const onDeletePoll = () => {
		setShowDeleteModal(false);
		sendDeleteFeedback(data._id as string);
	};

	// extract user data based o feedback's created by
	const getFeedbackCreatorData = () => {
		const creator = data.createdBy as IUser;
		return {
			name: creator.userName,
			profilePic: creator.profilePic
		};
	};

	/**
	 * Renders -
	 * 1. User's profile pic
	 * 2. user's display name
	 * 3. Created at time
	 * 4. Kebab menu
	 */
	const renderUserDetails = () => {
		// generating dropdown options to be passes to kebab menu
		const dropdownOptions: { key: string; callback: () => void }[] = [
			{
				key: 'Edit Poll',
				callback: () => {
					if (!checkIfAnyUserHasVoted()) setIsEditing(true);
					else {
						// If user tries to edit already submitted poll, then show the snackbar
						addSnackbar({
							show: true,
							text: 'You can not edit this poll now as users have already added their response to it.',
							type: 'NORMAL'
						});
						removeSnackbar(5000);
					}
				}
			},
			{
				key: 'Delete Poll',
				callback: () => {
					setShowDeleteModal(true);
				}
			}
		];

		const props: FeedbackKebabMenuProps = {
			options: (data.createdBy as IUser)?._id === user?._id ? dropdownOptions : undefined,
			profilePic: getFeedbackCreatorData().profilePic || '',
			name: (getFeedbackCreatorData().name as string) || '',
			createdAt: data.createdAt as string
		};
		return <FeedbackUserMenu {...props} />;
	};

	// sends out an API call to mark complete
	const onMarkComplete = (val: boolean) => {
		setIsCompleted(val);

		if (val) {
			// Track marking feedback complete event
			const feedbackEventProperties: TMarkCompleteEventData = {
				activityType: data.feedbackType as EFeedbackType,
				blockType: block?.blockType
			};
			trackEvent(FeedbackEvents.MARK_TASK_COMPLETE, feedbackEventProperties);
		}

		dispatch({
			type: 'MARK_FB_NOTIF_COMP',
			payload: { markFeedbackAsComplete: data._id, isCompleted: val }
		});
	};

	// send out an api call to save the action item value
	const saveActionItemToggle = (val: boolean, noWarningCheck: boolean) => {
		// setIsActionItem(val);

		// closing the modal if open
		if (showActionItemWarning) {
			setShowActionItemWarning(false);
		}

		sendEditFeedback({
			_id: data._id,
			isActionItem: val
		});

		if (noWarningCheck) {
			setNoActionItemToggleWarning(projectId);
		}
	};

	// sends out an api call to toggle action item
	const toggleActionItem = (val: boolean) => {
		if (!val && !checkIfUserTurnerOffActionItemToggleWarning(projectId)) {
			setShowActionItemWarning(true);
		} else {
			saveActionItemToggle(val, false);
		}
	};

	// Sends out an api call updated selected users for action item
	const onModifySelectedUsers = (id: string) => {
		let tempUsers = selectedUsers;
		if (selectedUsers.includes(id)) {
			tempUsers = tempUsers.filter((x) => x !== id);
			removeActionItemAssignee(data._id as string, id);
		} else {
			tempUsers.push(id);
			addActionItemAssignee(data._id as string, id);
		}
		setSelectedUsers(tempUsers);
	};

	// renders result view
	const renderResultsView = () => (
		<>
			<PollResults
				pollOptions={data.pollOptions!}
				feedbackId={data._id as string}
				text={data.statement as string}
			/>
			{!checkIfAnyUserHasVoted(user?._id as string) &&
				(!isViewer || block?.hasGuestAccess) && (
					<div className="tw-flex tw-justify-end tw-mt-4">
						<Button
							variant="SECONDARY"
							text="Add your response"
							disabled={false}
							onBtnClick={() => setShowResultsView(false)}
						/>
					</div>
				)}
		</>
	);

	// Triggered on isEditing change
	useEffect(() => {
		if (showResultsView && !checkIfAnyUserHasVoted()) setShowResultsView(false);
	}, [isEditing]);

	const checkIfCompleted = () => {
		if (user?._id && data.actionItemFor) {
			if (data.actionItemFor[user._id as string]) {
				return true;
			}
		}
		return false;
	};

	return (
		<FeedbackWrapper className="tw-bg-white">
			<>
				<WarningModal
					show={showActionItemWarning}
					onClose={() => setShowActionItemWarning(false)}
					onModalSubmit={(modalData: { isDaaChecked: boolean }) => {
						saveActionItemToggle(!toggleActionItem, modalData.isDaaChecked);
					}}
					title="Turn off action item?"
					bodyText={
						<div className="warning-model">
							{' '}
							<AlertIcon />{' '}
							<span>
								The following task will be removed as an action item for all the
								users in this project?. Continue?
							</span>
						</div>
					}
					cancelBtnText={"Don't remove"}
					submitBtnText="Turn off action item"
					showDAA
				/>
				<WarningModal
					show={showDeleteModal}
					onClose={() => setShowDeleteModal(false)}
					onModalSubmit={onDeletePoll}
					title="Are you sure?"
					bodyText={
						<div>
							Are you sure you want to delete the poll? This will delete all the
							responses to this poll as well.
						</div>
					}
					cancelBtnText="Cancel"
					submitBtnText="Delete"
					showDAA={false}
				/>
				{/* render action item panel */}
				<ActionItemPanel
					markCompleteDisabled={isEditing || isViewer}
					isCompleted={isCompleted}
					onMarkComplete={onMarkComplete}
					users={allUsers}
					selectedUsers={selectedUsers}
					onModifySelectedUsers={onModifySelectedUsers}
					onClose={() => {
						if (onClose) onClose();
					}}
				>
					{/* This bubble & time stamp is shown in actionItemPanel of feedback inside feedback panel */}
					{feedbackView === 'PANEL_VIEW' && (
						<>
							<FeedbackBubble
								isNew={!data._id}
								index={index}
								isCompleted={checkIfCompleted()}
								key={data._id as string}
								isActionItem={data.isActionItem as boolean}
								feedbackType={data.feedbackType as EFeedbackType}
							/>
							{/* Shows the feedback added time in HH:MM:SS */}
							{block?.blockType === 'VIDEO' && (
								<div className="time-stamp">
									<span className="tw-px-2 tw-py-1">time</span>
									<span className="tw-px-2 tw-py-1 tw-rounded">
										{formatTime(data.timeStamp || 0)}
									</span>
								</div>
							)}
						</>
					)}
				</ActionItemPanel>
				<div className="tw-p-4">
					{renderUserDetails()}
					{/* show results view or normal poll */}
					{showResultsView || isViewer ? (
						renderResultsView()
					) : (
						<Poll
							data={data}
							setIsEditing={setIsEditing}
							isEditing={isEditing}
							showResultsView={setShowResultsView}
							actionItemAssignees={selectedUsers}
							setActionItemAsignees={setSelectedUsers}
						/>
					)}
				</div>
			</>
		</FeedbackWrapper>
	);
};

PollWrapper.defaultProps = {
	onClose: () => {}
};
