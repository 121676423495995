import React, { FC, useEffect, useState, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { AuthContext } from 'src/components/auth/AuthProvider';
import { IconWithTooltip } from '@naya_studio/radix-ui';
import { ReactComponent as Logo } from 'src/assets/icons/navbar/Brand.svg';
import useUser from 'src/redux/hooks/user';
import { ReactComponent as HamburgerIcon } from '../../../assets/icons/navbar/NavbarHamburger.svg';
import CTA from './cta/CTA';
import UserProfileButton from './userProfile/UserProfileButton';
import './LandingPageNavbar.scss';

/**
 * Landing Page Navbar Props
 */
export interface LandingPageNavbarProps {
	toggleHamburgerMenu: React.MouseEventHandler<Element>;
}

/**
 * Component to render Landing Page Navbar
 */
const LandingPageNavbar: FC<LandingPageNavbarProps> = ({ toggleHamburgerMenu }) => {
	const location = useLocation();
	const history = useHistory();

	const authContext = useContext(AuthContext);
	const { completedAuthCheck, status } = authContext;
	const showUserForm = window.localStorage.getItem('showUserForm');

	// State to check if user is on the landing page
	const [inLandingPage, setInLandingPage] = useState<boolean>(true);
	// State to check if the hamburger icon is to be displayed
	const [showHamburgerIcon, setShowHamburgerIcon] = useState<boolean>(false);

	// Checks if the user is logged in
	const { user: currentUser } = useUser();
	const userLoggedIn = currentUser._id && currentUser.email;

	// Checks if the user is on the landing page for navbar background color
	useEffect(() => {
		const split = location.pathname.split('/');
		setInLandingPage(['', 'why-naya', 'get-in-touch'].includes(split[1]));
	}, [location]);

	// Runs on the first render
	useEffect(() => {
		const onResize = () => {
			setShowHamburgerIcon(window.innerWidth <= 800);
		};

		window.addEventListener('resize', onResize);

		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, []);

	/**
	 * Function to handle on logo click
	 */
	const onLogoClick = () => {
		if (currentUser._id) {
			history.push(currentUser._id ? '/studio' : '/');
		} else window.location.href = '/';
	};

	return (
		<nav
			role="presentation"
			id="top-navbar"
			className={`
				${inLandingPage ? 'transparent' : 'grey'} 
				tw-flex tw-justify-between tw-items-center tw-px-4 tw-py-2
			`}
			onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
				e.stopPropagation();
			}}
			onMouseDown={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
				e.stopPropagation();
			}}
		>
			<div className="navbar-items tw-flex tw-justify-between tw-items-center">
				<div
					onClick={() => onLogoClick()}
					className="grid-brand tw-w-10 tw-h-10 tw-m-0 tw-cursor-pointer"
					role="presentation"
				>
					<IconWithTooltip
						side="bottom"
						tooltipText="Naya Studio"
						classNames="naya-logo-tooltip"
					>
						<Logo width={40} height={40} />
					</IconWithTooltip>
				</div>
			</div>
			<div className="grid-color-user tw-flex tw-justify-center tw-items-center">
				{userLoggedIn || status === 'UNAPPROVED' ? (
					<UserProfileButton />
				) : (
					(completedAuthCheck || status === 'SIGNED_OUT') && !showUserForm && <CTA />
				)}
			</div>
			{/* Navbar Hamburger */}
			{(location.pathname === '/' ||
				location.pathname === '/why-naya' ||
				location.pathname === '/get-in-touch') &&
				showHamburgerIcon && <HamburgerIcon onClick={toggleHamburgerMenu} />}
		</nav>
	);
};

export default LandingPageNavbar;
