import { EAIGenerationType, TFlags } from '@naya_studio/types';
import { ContextMenu } from '@naya_studio/radix-ui';
import { handleDisabledAiMenu } from 'src/util/block/util';
import { ReactComponent as GenerateAiIcon } from '../../assets/icons/generate_with_ai.svg';

/**
 * AI related context menu
 */
const AIContextMenu = ({
	generateAiBlocks,
	flags
}: {
	generateAiBlocks: (aiType: keyof typeof EAIGenerationType, prompt?: string) => void;
	flags: TFlags;
}) => {
	const arrow =
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'context-arrow.svg?alt=media&token=e1bf22e0-71d9-4536-a7ee-bd7bbe2bd66f';

	// context menu option types for ai
	enum ContextOptionTypes {
		GENERATE_WITH_AI = 'GENERATE_WITH_AI',
		GENERATE_AI_IMAGE = 'GENERATE_AI_IMAGE',
		GENERATE_AI_MOOD_BOARD = 'GENERATE_AI_MOOD_BOARD',
		GENERATE_AI_TEXT = 'GENERATE_AI_TEXT',
		GENERATE_AI_SKETCH = 'GENERATE_AI_SKETCH',
		GENERATE_AI_RENDER = 'GENERATE_AI_RENDER',
		GENERATE_AI_3D_MODEL = 'GENERATE_AI_3D_MODEL',
		GENERATE_AI_VIDEO = 'GENERATE_AI_VIDEO',
		GENERATE_PM_JOURNEY = 'GENERATE_PM_JOURNEY'
	}

	// ai context menu options
	const ContextOptions = {
		GENERATE_WITH_AI: 'Use to AI generate',
		GENERATE_AI_IMAGE: 'Images',
		GENERATE_AI_MOOD_BOARD: 'Mood boards',
		GENERATE_AI_TEXT: 'Text',
		GENERATE_AI_SKETCH: 'Sketches',
		GENERATE_AI_RENDER: 'Renders',
		GENERATE_AI_3D_MODEL: '3D model',
		GENERATE_AI_VIDEO: 'Video',
		GENERATE_PM_JOURNEY: 'Journey'
	};

	// type for context menu
	type TContextOptionType = {
		option: keyof typeof ContextOptionTypes;
		icon?: React.FC<React.SVGProps<SVGSVGElement>>;
		hoverColor: string;
		isSubMenu: boolean;
		subOptions?: TContextOptionType[];
		showDivider: boolean;
		isDisabled?: boolean;
	};

	// functions for ai menu clicks
	const clickFunctions: { [key in ContextOptionTypes]?: () => void } = {
		GENERATE_AI_IMAGE: () => generateAiBlocks('IMAGE'),

		GENERATE_AI_MOOD_BOARD: () => generateAiBlocks('MOOD_BOARD'),

		GENERATE_AI_TEXT: () => generateAiBlocks('TEXT'),

		GENERATE_AI_SKETCH: () => generateAiBlocks('SKETCH'),

		GENERATE_AI_RENDER: () => generateAiBlocks('RENDER'),

		GENERATE_AI_3D_MODEL: () => {
			if (flags?.is3DGenEnabled) {
				generateAiBlocks('THREE_D');
			} else {
				handleDisabledAiMenu('THREE_D');
			}
		},

		GENERATE_AI_VIDEO: () => handleDisabledAiMenu('VIDEO'),

		GENERATE_PM_JOURNEY: () => generateAiBlocks('PM_JOURNEY')
	};

	// ai context options
	const generateWithAiOptions: TContextOptionType[] = [
		{
			option: ContextOptionTypes.GENERATE_AI_TEXT as keyof typeof ContextOptionTypes,
			icon: GenerateAiIcon,
			hoverColor: '',
			isSubMenu: false,
			subOptions: [],
			showDivider: false,
			isDisabled: !flags.isTextToTextAiEnabled
		},
		{
			option: ContextOptionTypes.GENERATE_AI_IMAGE as keyof typeof ContextOptionTypes,
			icon: GenerateAiIcon,
			hoverColor: '',
			isSubMenu: false,
			subOptions: [],
			showDivider: false
		},
		{
			option: ContextOptionTypes.GENERATE_AI_MOOD_BOARD as keyof typeof ContextOptionTypes,
			icon: GenerateAiIcon,
			hoverColor: '',
			isSubMenu: false,
			subOptions: [],
			showDivider: false
		},
		{
			option: ContextOptionTypes.GENERATE_AI_SKETCH as keyof typeof ContextOptionTypes,
			icon: GenerateAiIcon,
			hoverColor: '',
			isSubMenu: false,
			subOptions: [],
			showDivider: false
		},
		{
			option: ContextOptionTypes.GENERATE_AI_RENDER as keyof typeof ContextOptionTypes,
			icon: GenerateAiIcon,
			hoverColor: '',
			isSubMenu: false,
			subOptions: [],
			showDivider: false
		},
		{
			option: ContextOptionTypes.GENERATE_AI_3D_MODEL as keyof typeof ContextOptionTypes,
			icon: GenerateAiIcon,
			hoverColor: '',
			isSubMenu: false,
			subOptions: [],
			showDivider: false,
			isDisabled: !flags?.is3DGenEnabled
		},
		{
			option: ContextOptionTypes.GENERATE_AI_VIDEO as keyof typeof ContextOptionTypes,
			icon: GenerateAiIcon,
			hoverColor: '',
			isSubMenu: false,
			subOptions: [],
			showDivider: true,
			isDisabled: true
		},
		{
			option: ContextOptionTypes.GENERATE_PM_JOURNEY,
			icon: GenerateAiIcon,
			hoverColor: '',
			isSubMenu: false,
			subOptions: [],
			showDivider: false,
			isDisabled: false
		}
	];

	const options: TContextOptionType[] = [
		{
			option: ContextOptionTypes.GENERATE_WITH_AI,
			icon: GenerateAiIcon,
			hoverColor: '',
			isSubMenu: true,
			subOptions: generateWithAiOptions,
			showDivider: true
		}
	];

	// check if menu option is ai or not
	const isAiMenu = (option: string) => option.toLowerCase().includes('ai');

	return (
		<div>
			{options?.map((opt: TContextOptionType, index: number) =>
				opt.isSubMenu ? (
					<>
						<ContextMenu.Sub>
							<ContextMenu.SubTrigger
								className={`demo-items tw-text-xs tw-font-randRegular tw-leading-6 tw-pt-1 tw-pb-1 tw-px-2 tw-flex ${
									isAiMenu(opt?.option) && 'ai-menu'
								} ${opt.isDisabled && 'context-menu-item-disabled'}`}
							>
								{opt.icon && <opt.icon className="tw-mr-2 ai-icon" />}
								<div>{ContextOptions[opt.option]}</div>
								<div className="tw-ml-auto">
									{!opt.isDisabled && <img src={arrow} alt="context list" />}
								</div>
							</ContextMenu.SubTrigger>
							{!opt.isDisabled && (
								<ContextMenu.SubContent sideOffset={8} alignOffset={-5}>
									{opt.subOptions?.map((subOpt) => (
										<>
											<ContextMenu.ContextMenuItem
												className={`${
													subOpt.isDisabled
														? 'tw-cursor-default'
														: 'tw-cursor-pointer'
												}`}
												onClick={clickFunctions[subOpt.option]}
											>
												<div
													className={`demo-items tw-w-full tw-flex tw-justify-between tw-items-center 
													tw-gap-[5px] tw-text-xs tw-font-randRegular tw-leading-6 tw-pt-1 tw-pb-1 tw-px-2 ${
														isAiMenu(opt.option) && 'ai-menu'
													} ${
														subOpt.isDisabled &&
														'context-menu-item-disabled'
													}`}
												>
													<p className="tw-m-0">
														{ContextOptions[subOpt.option]}
													</p>{' '}
													{subOpt.icon && (
														<subOpt.icon
															className={`${
																isAiMenu(opt.option) && 'ai-icon'
															}`}
														/>
													)}
												</div>
											</ContextMenu.ContextMenuItem>
											{subOpt.showDivider && index !== options.length - 1 && (
												<ContextMenu.Separartor />
											)}
										</>
									))}
								</ContextMenu.SubContent>
							)}
						</ContextMenu.Sub>
						{opt.showDivider && index !== options.length - 1 && (
							<ContextMenu.Separartor />
						)}
					</>
				) : (
					<>
						<ContextMenu.ContextMenuItem
							className={`${
								opt.isDisabled ? 'tw-cursor-default' : 'tw-cursor-pointer'
							}`}
							onClick={clickFunctions[opt.option]}
						>
							<div
								className={`kebab-items tw-text-xs tw-font-randRegular 
					tw-leading-6 tw-pt-1 tw-pb-1 tw-px-2 ${isAiMenu(opt.option) && 'ai-menu'} 
								${opt.isDisabled && 'context-menu-item-disabled'}`}
							>
								<p className="tw-m-0">{opt.option}</p>{' '}
								{opt.icon && (
									<opt.icon className={`${isAiMenu(opt.option) && 'ai-icon'}`} />
								)}
								{/* Display the option text */}
							</div>
						</ContextMenu.ContextMenuItem>
						{opt.showDivider && index !== options.length - 1 && (
							<ContextMenu.Separartor />
						)}
					</>
				)
			)}
		</div>
	);
};
export default AIContextMenu;
