import { EEvent, TSentNotifForUsers } from '@naya_studio/types';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { store } from 'src';
import {
	TNotifRTKData,
	TNotifState,
	notificationSlice,
	useGetNotifsQuery,
	useToggleNotifCompleteMutation,
	useUpdateFeebackNotifMutation,
	useUpdateMultipleNotifsMutation
} from 'src/redux/features/api/notification';
import { ReduxState } from 'src/redux/reducers/root.types';
import { loadProjectById } from 'src/redux/reduxActions/project';
import loadProjectUsers from 'src/redux/reduxActions/projectUsers';
import { generateIdsFromUrl } from 'src/redux/reduxActions/util';
import {
	TSentNotifRTKData,
	sentNotificationSlice,
	useGetSentNotifsQuery,
	useToggleSentNotifCompleteMutation
} from 'src/redux/features/api/sentNotification';
import useUser from 'src/redux/hooks/user';
import { CollaborationToolContext } from 'src/components/collaborationTool/CollaborationTool';
import { getFormatedNotification } from './util';
import { isLocalhost } from '../collaboration/util';

export type TMyTaskItem = {
	notifId: string;
	isCompleted: boolean;
	createdAt: Date;
	projectName: string;
	name: string;
	avatar: string;
	text: string;
	projectId: string;
	feedbackId?: string;
	blockId?: string;
	link: string;
	andOthers: boolean;
	isPreviousTask?: boolean;
	isSent?: boolean;
	toUsers?: TSentNotifForUsers[];
	eventType?: EEvent;
	subtitle?: string | undefined;
	description?: string | undefined;
};

/**
 * Custom hook for all Notification related activities
 * Used for MyTask Functionality
 * @returns
 */
export const useNotifications = (control?: boolean) => {
	const { user } = useUser();
	const feedbacksRedux = useSelector((state) => (state as ReduxState).feedbacks.data);
	const { onCollabUnMount } = useContext(CollaborationToolContext);

	/** Used to Mark a notif as complete corresponding to the feedback */
	const { markFeedbackAsComplete, isCompleted } = useSelector(
		(state: ReduxState) => state.openComment
	);

	const history = useHistory();
	const dispatch = useDispatch();

	const [tasks, setTasks] = useState<TMyTaskItem[]>();
	const [projectId, setProjectId] = useState('');
	const [currPage] = useState(1); // used for paginated scroll
	const [isTaskActive, setIsTaskActive] = useState(false);

	// fetch notif related data only when user._id and currPage is present, else skip
	const { data } = useGetNotifsQuery(
		{ userId: user._id as string, page: currPage },
		{ pollingInterval: isLocalhost ? 0 : 10000 } // added polling
	);
	const { data: sentTasks } = useGetSentNotifsQuery(
		{ userId: user._id as string },
		{ pollingInterval: isLocalhost ? 0 : 10000 } // added polling
	);
	const [toggleNotifComplete] = useToggleNotifCompleteMutation(); // rtkq function to mark notification as complete/incomplete
	const [updateFeebackNotif] = useUpdateFeebackNotifMutation(); // rtkq function to find and update notification from feedbackId & userId
	const [toggleSentNotifComplete] = useToggleSentNotifCompleteMutation();
	const [updateMultipleNotifs] = useUpdateMultipleNotifsMutation();

	/**
	 * Filters notification and formats data as TMyTaskItem for current tasks
	 * set value of tasks(TMyTaskItem[])
	 */
	const getFormattedTasks = (received: TNotifState, sent: TNotifState) => {
		const formattedReceivedTask = getFormatedNotification(received, false) || [];
		const formattedSentTask = getFormatedNotification(sent || [], true) || [];
		// set tasks and number of incomplete tasks
		setTasks([...formattedReceivedTask, ...formattedSentTask] as TMyTaskItem[]);
	};

	/**
	 * Filters tasks data for current block
	 * @param blockId
	 * @returns Tasks for a particular block
	 */
	const getFormattedTasksForBlock = (blockId: string) =>
		tasks ? tasks.filter((task) => task.blockId === blockId) : [];

	/**
	 * A function to handle completing a task
	 * @param {string} notifId - This is the notification id for a task
	 * @param {boolean} isComplete - This is the new status for the task item
	 * @returns {void}
	 */
	const onCompleteToggle = async (
		notifId: string,
		isComplete: boolean,
		toggleFeedbackComplete: boolean,
		isSent: boolean
	) => {
		const notifFound = tasks?.find((t) => t.notifId === notifId);
		const feedbackId = notifFound?.feedbackId;
		const payload = {
			notifId,
			completedAt: isComplete ? Date.now() : null,
			isComplete,
			feedbackId,
			userId: user._id,
			toggleFeedbackComplete,
			feedbackActionItemFor: feedbackId
				? feedbacksRedux[feedbackId]?.actionItemFor
				: undefined,
			blockId: notifFound?.blockId,
			eventType: notifFound?.eventType
		};
		// mark the notification as complete (feedback too - from backend)
		if (isSent) {
			toggleSentNotifComplete(payload);
		} else {
			toggleNotifComplete(payload);
		}

		// ONLY FOR BLOCK DUE DATE as for this event, when receiver marks the task as comp/inc from Task panel,
		// it should be marked comp/inc for everyone
		if (notifFound?.eventType === EEvent.BLOCK_DUE_DATE && !isSent) {
			updateMultipleNotifs({
				searchBy: { 'data.blockId': notifFound.blockId, eventType: EEvent.BLOCK_DUE_DATE },
				update: {
					'sent.inApp': isComplete || false,
					completedAt: isComplete ? new Date().toISOString() : null
				},
				operation: 'NOTIFICATION_COMPLETE'
			});
		}
	};

	/**
	 * A function to redirecting user to their tasks and opening the feedback
	 * @param {TMyTaskItem} task - The task that the user would want to redirect
	 * @returns {void}
	 */
	const redirectTask = async (task: TMyTaskItem) => {
		const { projectId: prevProjectId } = generateIdsFromUrl();

		const { link } = task;
		const url = new URL(link).pathname;
		history.push(url);
		const { projectId: newProjectId } = generateIdsFromUrl();

		// unload project if the project has been changed
		if (prevProjectId !== newProjectId) {
			dispatch({
				type: 'UNLOAD_PROJECT',
				payload: undefined
			});
			setProjectId(newProjectId);
		}

		// open the feedback
		if (task.feedbackId) {
			const { commentId } = store.getState().openComment;
			if (commentId === task.feedbackId) {
				await dispatch({
					type: 'OPEN_COMMENT',
					payload: { commentId: '' }
				});
				dispatch({
					type: 'OPEN_COMMENT',
					payload: { commentId: task.feedbackId }
				});
			} else {
				dispatch({
					type: 'OPEN_COMMENT',
					payload: { commentId: task.feedbackId }
				});
			}
		}
	};
	/**
	 * Appends tempChanges to notiffs to get the updated data w/o api call
	 */
	// const refetchData = () => {
	// 	if (notifs) {
	// 		setNotifs({ ...notifs, ...(data as TNotifRTKData).notifs });
	// 	}
	// };

	/**
	 * A function to handle all tasks going into the myTask component
	 * @param {string} type - The task that the user would want to redirect
	 * @param {TMyTaskItem} task - The task that the user would want to redirect
	 * @returns {void}
	 */
	const handleTask = (type: string, task: TMyTaskItem | {}) => {
		switch (type) {
			case 'REDIRECT': {
				// Function to handle redirect
				const onRedirect = () => {
					redirectTask(task as TMyTaskItem);
					setIsTaskActive(true);
				};
				const urlProjectId = generateIdsFromUrl().projectId;

				if (
					urlProjectId &&
					(task as TMyTaskItem)?.projectId &&
					(task as TMyTaskItem).projectId !== urlProjectId
				)
					onCollabUnMount(onRedirect);
				else onRedirect();
				break;
			}
			case 'TOGGLE_TASK_STATUS':
				onCompleteToggle(
					(task as TMyTaskItem).notifId,
					(task as TMyTaskItem).isCompleted,
					true,
					(task as TMyTaskItem).isSent || false
				);
				break;
			case 'REFETCH':
				dispatch(notificationSlice.util.resetApiState());
				dispatch(sentNotificationSlice.util.resetApiState());
				break;
			default:
				break;
		}
	};

	/**
	 * Return the count for tasks in a particular block
	 */
	const getBlockTaskCount = useCallback(
		(blockId: string) => {
			const filterWithBlockId = tasks?.filter((t) => !t.isCompleted && t.blockId === blockId);
			return filterWithBlockId?.length || 0;
		},
		[tasks]
	);

	/**
	 * Set notifs whenever data received from Notification and Sent Notification APIs
	 */
	useEffect(() => {
		if (!tasks && (data || sentTasks)) {
			// First render where tasks are not set but partial data from notif or sent notif is received
			getFormattedTasks(
				(data as TNotifRTKData)?.notifs,
				(sentTasks as TSentNotifRTKData)?.tasks
			);
		} else if (tasks && data && sentTasks) {
			// Subsequent refetch where tasks are set. so wait for complete data from notif or sent notif is received
			getFormattedTasks(
				(data as TNotifRTKData)?.notifs,
				(sentTasks as TSentNotifRTKData)?.tasks
			);
		}
	}, [data, sentTasks]);

	/**
	 * load project and project users when the projectid in the url changes
	 */
	useEffect(() => {
		if (projectId) {
			dispatch(loadProjectById({ payload: { _id: projectId } }));
			dispatch(loadProjectUsers({ payload: { projectId } }));
		}
	}, [projectId]);

	/**
	 * Used for marking feedback and notifs as completed automatically
	 * eg: Responding to chat marks the feedback as completed, and sets in markFeedbackAsComplete in redux
	 * As markFeedbackAsComplete is set, we find its corresponding notification and mark that notif as complete
	 */
	useEffect(() => {
		if (markFeedbackAsComplete && control) {
			// find notif in the already loaded notif
			const notif = tasks?.find((t) => t.feedbackId === markFeedbackAsComplete);
			// if notif is found
			if (notif) {
				if (notif.isCompleted !== isCompleted && !notif.isSent) {
					onCompleteToggle(
						notif.notifId as string,
						isCompleted,
						true,
						notif.isSent || false
					);
				}
			} else {
				// notif not found, hence make an api call
				const payload = {
					userId: user._id,
					feedbackId: markFeedbackAsComplete,
					isCompleted,
					update: {
						'sent.inApp': true,
						completedAt: new Date().toISOString()
					}
				};
				updateFeebackNotif(payload);
			}

			// set markFeedbackAsComplete as ''
			dispatch({
				type: 'MARK_FB_NOTIF_COMP',
				payload: { markFeedbackAsComplete: '', isCompleted: false }
			});
		}
	}, [markFeedbackAsComplete]);

	/**
	 * This function handles mark complete of sent/received tab base on
	 * @param blockId block id
	 * @param eventType notification event type
	 */
	const markNotificationCompleteByBlockId = (
		blockId: string,
		eventType: EEvent,
		isComplete?: boolean
	) => {
		const notif = tasks?.find((t) => t.blockId === blockId && t.eventType === eventType);
		let searchBy;
		let update;
		switch (eventType) {
			case EEvent.REQUEST_FILE_UPLOAD: {
				searchBy = { 'data.blockId': blockId, eventType: EEvent.REQUEST_FILE_UPLOAD };
				update = { 'sent.inApp': true, completedAt: new Date().toISOString() }; // Always true coz file uplaoded marks complete
				setIsTaskActive(false);
				break;
			}
			case EEvent.BLOCK_DUE_DATE: {
				searchBy = { 'data.blockId': blockId, eventType: EEvent.BLOCK_DUE_DATE };
				update = {
					'sent.inApp': isComplete || false,
					completedAt: isComplete ? new Date().toISOString() : null
				};
				break;
			}
			default:
				console.info('No case for given event');
		}
		if (notif) {
			const payload = {
				notifId: notif?.notifId,
				completedAt: Date.now(),
				isComplete,
				userId: user._id
			};
			if (notif?.isSent) {
				toggleSentNotifComplete(payload);
			} else {
				toggleNotifComplete(payload);
				updateMultipleNotifs({
					searchBy,
					update,
					operation: 'NOTIFICATION_COMPLETE'
				});
			}
		}
	};

	return {
		tasks,
		handleTask,
		onCompleteToggle,
		getBlockTaskCount,
		isTaskActive,
		markNotificationCompleteByBlockId,
		getFormattedTasksForBlock
	};
};
