import mongoose from 'mongoose';
import { store } from 'src';
import {
	TGenerateOptimisticProjectArg,
	TCreateProjectThunkArg,
	TBatchCreateGroupAndBlocks
} from 'src/types/argTypes';
import { useHistory } from 'react-router';
import { useRef } from 'react';
import { TAddBlocksFnArgs } from 'src/util/block/blockAction.types';
import useBlockAction, { TOnAddBlocksParams } from 'src/util/block/useBlockActions';
import { IBlock, IGroup, ILink } from '@naya_studio/types';
import useAiGeneration from 'src/util/block/useAiGeneration';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TLDFlags } from 'src/components/collaborationTool/CollaborationTool.types';
import { useDispatch } from 'react-redux';
import { createBlockByType, createTextBlock } from 'src/util/block/util';
import useUser from './user';
import { generateOptimisticProject, generateOptimisticStage } from '../reduxActions/util';
import { createProject, toggleAIStatus } from '../reduxActions/project';
import { CustomDispatch } from '../actions/types';
import { dataEntryGuide, i2rData, TI2RData } from './i2r/i2rData';
import { batchCreateGroupAndBlocks } from '../reduxActions/stage';

/**
 * Custom hook for Naya Express functionality.
 * @returns {Object} Object containing functions for handling 3D redirects and triggering 3D generation.
 */
const useNayaExpress = () => {
	const { user } = useUser(); // User data
	const history = useHistory(); // React Router history
	const dispatch = useDispatch();
	const threeDTriggered = useRef<boolean>(false); // Ref to track if 3D generation has been triggered

	const { onGenerateWithAI } = useAiGeneration(); // Function for generating AI-based content
	const { updateBlockWithThumbnail } = useBlockAction();

	// Retrieve the 'isTextTo3DEnabled' flag from useFlags and check if 3D redirect is enabled
	const { isTextTo3DEnabled } = useFlags<TLDFlags>();

	// function to check if a 3D redirect is enabled
	const checkIfNayaExpressRedirect = (val: string) => {
		const is3DRedirect = window.sessionStorage.getItem(val);
		return is3DRedirect && isTextTo3DEnabled; // Return true if 3D redirect is enabled
	};

	/**
	 * Handles the redirection to a 3D project creation.
	 */
	const handleI2RRedirect = async () => {
		// Extract necessary user data
		const { _id: createdBy, defaultProjectGroup: parentGroupId } = user;

		// Generate a new project ID
		const newProjectId = new mongoose.Types.ObjectId().toString();

		// Randomly select a project template from the available I2R data
		const projectData = i2rData[Math.floor(Math.random() * i2rData.length)] as TI2RData;

		// Prepare the payload for generating an optimistic project
		const payload: TGenerateOptimisticProjectArg = {
			_id: newProjectId,
			createdBy,
			parentGroupId: parentGroupId as string,
			projectType: 'REGULAR',
			name: projectData.projectTitle
		};

		// Generate the optimistic project
		const { optimisticProject } = generateOptimisticProject(payload);

		// Prepare the payload for the API call to create a project
		const apiPayload: TCreateProjectThunkArg = {
			payload: { ...payload, optimisticProject },
			user,
			next: async (id: string) => {
				try {
					const phases: IGroup[] = [];
					const blocks: IBlock[] = [];

					// Create the guide block and phase
					const guidePhaseId = new mongoose.Types.ObjectId().toString();
					const guidePhase = generateOptimisticStage(
						guidePhaseId,
						'Idea to Product: How to Begin',
						{
							id,
							type: 'JOURNEY'
						}
					);

					const guideTextBlock = createTextBlock(
						new mongoose.Types.ObjectId().toString(),
						guidePhaseId,
						id,
						dataEntryGuide,
						'Design Entry Guide'
					);

					const guideVideoBlock = createBlockByType(
						'LINK',
						guidePhaseId,
						id,
						'https://youtu.be/EylnLF1E6RM',
						'Naya AI Demo'
					) as IBlock;

					// Assign users and add the text block to the guide phase
					guideTextBlock.users = guidePhase?.users || [];
					guideVideoBlock.users = guidePhase?.users || [];
					guidePhase.children = [
						guideTextBlock._id as string,
						guideVideoBlock._id as string
					];
					guidePhase.isPhaseCreated = true;

					// Add the guide phase and block to their respective arrays
					phases.push(guidePhase);
					blocks.push(guideTextBlock);
					blocks.push(guideVideoBlock);

					// Create the initial "Design brief" phase and associated text block
					const initialPhaseId = new mongoose.Types.ObjectId().toString();
					const initialPhase = generateOptimisticStage(
						initialPhaseId,
						'Example - Design brief',
						{
							id,
							type: 'JOURNEY'
						}
					);
					const textBlock = createTextBlock(
						new mongoose.Types.ObjectId().toString(),
						initialPhaseId,
						id,
						projectData.text,
						'Example - Design brief'
					);

					// Assign users and add the text block to the initial phase
					textBlock.users = initialPhase?.users || [];
					initialPhase.children = [textBlock._id as string];

					// Add the initial phase and block to their respective arrays
					phases.push(initialPhase);
					blocks.push(textBlock);

					// Create remaining phases and blocks based on the project data
					projectData.groups.forEach((group) => {
						const tempPhaseId = new mongoose.Types.ObjectId().toString();
						const tempPhase = generateOptimisticStage(tempPhaseId, group.name || '', {
							id,
							type: 'JOURNEY'
						});
						const blockIds: string[] = [];

						// Create blocks within each phase
						group.blocks.forEach((blockData, index) => {
							const block = createBlockByType(
								blockData.blockType,
								tempPhaseId,
								id,
								blockData.url || '',
								blockData.name || `Untitled ${index + 1}`
							);

							// Add blocks to the list if created successfully
							if (block) {
								block.users = tempPhase?.users || [];
								blocks.push(block);
								blockIds.push(block._id as string);
							}
						});

						// Add block IDs to the phase and add the phase to the phases array
						tempPhase.children = blockIds;
						phases.push(tempPhase);
					});

					// Prepare and dispatch the batch creation payload for phases and blocks
					const batchPayload: TBatchCreateGroupAndBlocks = {
						parent: {
							id,
							type: 'JOURNEY'
						},
						groups: phases,
						children: phases.map((phase) => phase._id.toString()),
						blocks,
						startGroupIndex: 0
					};

					// Dispatch the batch creation action
					await dispatch(batchCreateGroupAndBlocks(batchPayload));

					blocks.forEach((block) => {
						// Update pdf blocks thumbnail.
						if (
							block.blockType === 'PDF' &&
							(block as ILink).link &&
							(block as ILink).link.includes('.pdf')
						) {
							updateBlockWithThumbnail(
								block._id.toString(),
								{
									name: block.name,
									fileName: block.name,
									link: (block as ILink).link,
									originalLink: (block as ILink).link
								},
								undefined,
								false,
								'PDF'
							).catch((error: any) => {
								console.error(error);
							});
						}
					});

					// Clean up session storage and redirect to the new project
					window.sessionStorage.removeItem('i2r');
					// Enable AI features for the new project
					await dispatch(
						toggleAIStatus({
							projectId: id,
							isContentTraningEnabled: true,
							isAIFeaturesEnabled: true
						})
					);
					history.push(`/project/${id}`);
				} catch (e) {
					console.error('I2R project creation error!');
					window.sessionStorage.removeItem('i2r');
				}
			}
		};

		// Dispatch the action to create the project
		(store.dispatch as CustomDispatch)(createProject(apiPayload));
	};

	/**
	 * Handles the redirection to a 3D project creation.
	 */
	const handle3DRedirect = () => {
		const { _id: createdBy, defaultProjectGroup: parentGroupId } = user; // Extract user and project group data
		const is3DRedirect = window.sessionStorage.getItem('3D_redirect');

		// Generate a new project ID
		const pid = new mongoose.Types.ObjectId().toString() as string;

		// Prepare payload for optimistic project generation
		const payload: TGenerateOptimisticProjectArg = {
			_id: pid,
			createdBy,
			parentGroupId: parentGroupId as string,
			projectType: 'REGULAR',
			name: `TextTo3D_${is3DRedirect || 'Untitled'}`
		};

		// Generate optimistic project
		const { optimisticProject } = generateOptimisticProject(payload);

		// Prepare payload for creating a project
		const apiPayload: TCreateProjectThunkArg = {
			payload: {
				...payload,
				optimisticProject
			},
			user,
			next: (id: string) => {
				history.push(`/project/${id}`); // Redirect to the newly created project
			}
		};

		// Dispatch action to create the project
		(store.dispatch as CustomDispatch)(createProject(apiPayload));
	};

	/**
	 * Creates a text block if there's a 3D redirect.
	 * @param {Function} onAddBlocks Function to add blocks.
	 */
	const createTextBlockIf3DRedirect = (
		onAddBlocks: ({ blocksToAdd, options }: TOnAddBlocksParams) => void
	) => {
		const is3DRedirect = window.sessionStorage.getItem('3D_redirect');
		if (is3DRedirect && !threeDTriggered.current && isTextTo3DEnabled) {
			const newBlockId = new mongoose.Types.ObjectId().toString();

			// Prepare data for the new text block
			const newBlock: TAddBlocksFnArgs = {
				addType: 'TEXT',
				blockId: newBlockId,
				payload: is3DRedirect
			};

			// Add the new text block
			onAddBlocks({
				blocksToAdd: [newBlock],
				options: {}
			});

			// Mark 3D generation as triggered
			threeDTriggered.current = true;
		}
	};

	/**
	 * Triggers 3D generation if there's a 3D redirect.
	 * @param {string} projectId Project ID.
	 * @param {IGroup} phase Project phase.
	 * @param {string} blockId Block ID.
	 */
	const trigger3DGenerationIf3DRedirect = (projectId: string, phase: IGroup, blockId: string) => {
		const is3DRedirect = window.sessionStorage.getItem('3D_redirect');
		if (is3DRedirect && isTextTo3DEnabled) {
			// Trigger AI-based generation
			onGenerateWithAI({
				type: 'TEXT_TO_IMAGE_GENERATION',
				aiType: 'THREE_D',
				prompt: is3DRedirect,
				projectId,
				phaseName: phase.name,
				phaseId: phase._id as string,
				blockId,
				newBlockIndex: 1
			});

			// Remove 3D redirect from local storage
			window.sessionStorage.removeItem('3D_redirect');
		}
	};

	return {
		handle3DRedirect,
		createTextBlockIf3DRedirect,
		trigger3DGenerationIf3DRedirect,
		checkIfNayaExpressRedirect,
		handleI2RRedirect
	};
};

export default useNayaExpress;
