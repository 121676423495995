import {
	EBlockType,
	I3D,
	IBlock,
	IFeedback,
	IGroup,
	IImage,
	ILink,
	IText,
	IProject
} from '@naya_studio/types';
import mongoose from 'mongoose';
import {
	generateIdsFromUrl,
	generateOptimisticBlock,
	generateOptimisticStage,
	getFeedbackBasedOnBlockType
} from 'src/redux/reduxActions/util';
import {
	TAddCommentThunkArg,
	TAddMultipleBlockArgs,
	TAIBlocksData,
	TAIGroupsData,
	TBatchCreateGroupAndBlocks,
	TCreateAIPoweredGroupsData
} from 'src/types/argTypes';
import { store } from 'src';
import { CustomDispatch } from 'src/redux/actions/types';
import { addMultipleBlocks } from 'src/redux/reduxActions/block';
import { getSiteMetaData, isThumbnailColorString } from '@naya_studio/radix-ui';
import {
	findBlockWithBlockId,
	findStageWithBlockId,
	findStageWithStageId
} from 'src/redux/actions/util';
import { lexicalStateToPlainText, htmlToLexical } from '@naya_studio/viewers';
import { batchCreateGroupAndBlocks } from 'src/redux/reduxActions/stage';
import { TGroupParent } from 'src/components/collaborationTool/CollaborationTool.types';
import { getAIUserFromRedux } from 'src/util/helper/user';
import { addComment } from 'src/redux/reduxActions/feedbacks';
import { addNote } from 'src/redux/reduxActions/notes';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';
import { identifyAiIntent } from 'src/redux/reduxActions/aiGeneration';
import { TOnGenerateWithAIParams } from './useAiGeneration';
import { isValidAIImageUrl } from './util';
import { getProjectFromRedux } from '../helper/project';

// creates a Three D block
export const create3DBlock = async (
	options: TOnGenerateWithAIParams,
	projectId: string,
	link: string,
	thumbnail_url: string,
	userId: string
) => {
	const newId = new mongoose.Types.ObjectId().toString() as string;
	const threeDPreviewBlock = generateOptimisticBlock(
		newId,
		options.phaseId as string,
		projectId,
		options.prompt,
		'THREE_D'
	);
	(threeDPreviewBlock as I3D).link = [link as string];
	(threeDPreviewBlock as I3D).originalLink = [link as string];
	threeDPreviewBlock.thumbnail = {
		src: thumbnail_url as string,
		isCustom: false,
		originalSrc: thumbnail_url as string
	};
	(threeDPreviewBlock as I3D).uploadedBy = userId as string;
	(threeDPreviewBlock as I3D).fileName = `${options.prompt}.glb`; // extension of all Text to 3D is glb

	// payload for creation of new block
	const apiPayload: TAddMultipleBlockArgs = {
		data: {
			blocks: [threeDPreviewBlock],
			stageId: options.phaseId as string,
			projectId,
			newBlockIndex: options.newBlockIndex || 0
		}
	};

	const threeDBlock: TAddMultipleBlockArgs = await (store.dispatch as CustomDispatch)(
		addMultipleBlocks(apiPayload)
	).unwrap();

	return threeDBlock;
};

// edit a 3D block
export const editThreeDBlockPayload = (
	final_link: string,
	thumbnail_url: string,
	blockId: string,
	phaseId: string
) => {
	const allBlocksRedux = store.getState().blocks.data;
	return {
		data: {
			block: {
				link: [final_link as string],
				originalLink: [final_link as string],
				thumbnail: {
					src: thumbnail_url as string,
					isCustom: false,
					originalSrc: thumbnail_url as string
				},
				_id: blockId
			},
			...generateIdsFromUrl(),
			stageId: phaseId as string
		},
		prevState: {
			prevStateBlock: allBlocksRedux[blockId] as IBlock
		}
	};
};

enum TBlockField {
	'AI_RENAME' = 'rename',
	'AI_ORGANIZE' = 'organize'
}

enum TGroupField {
	'AI_RENAME' = 'renameGroup',
	'AI_ORGANIZE' = 'organizeGroup'
}

/**
 * Function to create payload to super power API for project modifications via AI
 * @param data
 * 	@param {string[]} data.blocksIds - IDs of blocks to be modified
 * 	@param {string[]} data.groupIds - IDs of groups to be modified
 * 	@param {boolean} data.isAIRename - Indicates if names are to be modified or not
 * @returns an array of groups with blocks data needed for AI operations
 */
export const createAIPoweredGroupsData = async ({
	blocksIds,
	groupIds,
	type,
	isAISuggestion
}: TCreateAIPoweredGroupsData) => {
	const isOnlyGroupOrganised = groupIds.length === 1 && blocksIds.length === 0;
	const { projectId } = generateIdsFromUrl();
	const reduxProject = getProjectFromRedux(projectId);
	// extract group IDs
	const groupSet = new Set(
		isOnlyGroupOrganised || type === 'AI_RENAME'
			? [...groupIds]
			: [...(reduxProject.children as string[])]
	);
	blocksIds.forEach((blockId) => {
		const stage = findStageWithBlockId(blockId);
		if (stage) groupSet.add(stage._id as string);
	});
	const groups = Array.from(groupSet);

	const processBlock = async (blockId: string, isRenameGroup: boolean) => {
		const blockData = findBlockWithBlockId(blockId) as IBlock;
		const temp: TAIBlocksData = {
			blockId: blockData._id as string,
			name: blockData.name,
			blockType: blockData.blockType,
			parentId: blockData.parentId,
			[TBlockField[type]]:
				isAISuggestion && isOnlyGroupOrganised
					? false
					: isRenameGroup || blocksIds.includes(blockId as string)
		};

		const blockLink = (blockData as ILink).link;

		if (blockLink) {
			const link =
				Array.isArray(blockLink) && blockLink.length > 0
					? (blockData as I3D).link[blockLink.length - 1]
					: blockLink;
			temp.link = link as string;
			temp.siteMetaData = await getSiteMetaData(temp.link);
		}

		if (blockData.blockType === 'TEXT' && (blockData as IText).text) {
			temp.text = await lexicalStateToPlainText((blockData as IText).text as string);
		}

		if (
			blockData.blockType === 'IMAGE' &&
			(await isValidAIImageUrl(
				`${window.__RUNTIME_CONFIG__.REACT_APP_NAYA_PROXY}/${(blockData as IImage).link}`
			))
		) {
			temp.thumbnail = (blockData as IImage).link;
		} else if (
			!isThumbnailColorString(blockData.thumbnail.src) &&
			(await isValidAIImageUrl(
				`${window.__RUNTIME_CONFIG__.REACT_APP_NAYA_PROXY}/${blockData.thumbnail.src}`
			))
		) {
			temp.thumbnail = blockData.thumbnail.src;
		} else if (
			temp.siteMetaData?.siteImageUrl &&
			(await isValidAIImageUrl(
				`${window.__RUNTIME_CONFIG__.REACT_APP_NAYA_PROXY}/${temp.siteMetaData?.siteImageUrl}`
			))
		) {
			temp.thumbnail = temp.siteMetaData.siteImageUrl;
		}

		return temp as TAIBlocksData;
	};

	const processGroup = async (groupId: string, isRenameGroup: boolean) => {
		const stage = findStageWithStageId(groupId) as IGroup;
		const groupName = stage.name as string;
		const childrenData = (await Promise.all(
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			stage.children?.map((childId) => processChildren(childId as string, isRenameGroup))
		)) as Array<TAIBlocksData | TAIGroupsData>;

		return {
			groupId,
			groupName,
			children: childrenData,
			parentId: stage.parentId,
			...(isOnlyGroupOrganised && type === 'AI_ORGANIZE'
				? { shuffle: true }
				: { [TGroupField[type]]: isRenameGroup })
		};
	};

	const processChildren = async (childId: string, isRenameGroup: boolean) => {
		const blockData = findBlockWithBlockId(childId);
		if (blockData) return processBlock(childId, isRenameGroup);
		return processGroup(childId, isRenameGroup);
	};

	const payload = await Promise.all(
		groups.map((groupId) => {
			const isRenameGroup = groupIds.includes(groupId);
			return processGroup(groupId, isRenameGroup);
		})
	);

	return payload as TAIGroupsData[];
};

/**
 * Function to check if both the array elements are at same position
 * @param data
 * 	@param {string[]} arr1 - array of string
 * 	@param {string[]} arr2 - array of string
 * @returns boolean if both the array elements are at same position
 */
export const checkElementsInSamePositions = (arr1: string[], arr2: string[]) => {
	if (arr1.length !== arr2.length) {
		return false;
	}

	for (let i = 0; i < arr1.length; i++) {
		if (arr1[i] !== arr2[i]) {
			return false;
		}
	}

	return true;
};

/**
 * Function to insert element at given index
 * 	@param {string[]} arr - array of string
 * 	@param {string} element - element
 * 	@param {number} index - index
 * @returns returns updated array
 */
export const insertElementAtIndex = (arr: string[], element: string, index: number) => {
	const copyArray = [...arr];

	// Ensure the index is within the valid range
	if (index < 0) {
		index = 0;
	}
	if (index > copyArray.length) {
		index = copyArray.length;
	}

	// Use the splice method to insert the element at the specified index
	copyArray.splice(index, 0, element);

	// Return the updated array
	return copyArray;
};

/**
 * Function to remove element from a given array
 * 	@param {string[]} arr - array of string
 * 	@param {string} element - element
 * @returns returns updated array
 */
export const removeElementFromArray = (arr: string[], element: string) => {
	const copyArray = [...arr];
	// Find the index of the element in the array
	const index = arr.indexOf(element);

	// If the element is found, remove it
	if (index !== -1) {
		copyArray.splice(index, 1);
	}

	// Return the remaining elements in the array
	return copyArray;
};

export type EMultiselectAIOpt =
	| 'GENERATE_3D_MODEL'
	| 'GENERATE_3D_MODEL_WITH_EDIT'
	| 'SUMMARIZE'
	| 'GENERATE_NEXT_STEPS'
	| 'GENERATE_CONCEPTS'
	| 'GENERATE_PRESENTATION'
	| 'GENERATE_ESTIMATE'
	| 'EVALUATE_MANUFACTURING'
	| 'EVALUATE_SUSTAINABILITY'
	| 'GENERATE_VIDEO'
	| 'GENERATE_RENDER'
	| 'EDIT_IMAGE_WITH_FEEDBACK';

export const outputOptionString = (option: EMultiselectAIOpt) => {
	switch (option) {
		case 'SUMMARIZE':
			return 'summary';
		case 'GENERATE_CONCEPTS':
			return 'concepts';
		case 'GENERATE_3D_MODEL':
			return '3D model';
		case 'GENERATE_PRESENTATION':
			return 'presentation';
		case 'GENERATE_ESTIMATE':
			return 'estimate';
		case 'EVALUATE_SUSTAINABILITY':
			return 'sustainability';
		case 'EVALUATE_MANUFACTURING':
			return 'manufacturing';
		case 'GENERATE_NEXT_STEPS':
			return 'next steps';
		case 'GENERATE_VIDEO':
			return 'video';
		default:
			return 'output';
	}
};

// All Below Interfaces are required for AiDigest
export type AIDigest = {
	aiDigests: Record<string, any>;
};
export type BlockAIDigest = {
	block_name: string;
	blockType: string;
	description?: Record<string, any>;
	link?: string;
	parentId?: string;
};
export type GroupedBlocksAIDigest = {
	[parentId: string]: BlockAIDigest[];
};
export type GroupAIDigest = {
	group_name: string;
	blocks: BlockAIDigest[];
};
export type OutputAIDigest = {
	project_name?: string;
	project_thumbnail?: string;
	groups?: GroupAIDigest[];
};

// ---ALL above Interfaces are required for AiDigest---

// Snackbar Payload for Multiselect AI
export type TupdateSnackbarPayload = {
	start: number;
	end: number;
	msg?: string;
	buttonMsg?: string;
	snackbarType?: 'NORMAL' | 'ERROR' | 'LOADER';
	buttonAction?: () => void;
	interval?: number;
	showAiIconAtStart?: boolean;
	forceAdd?: boolean;
};

export type TTripoOutputFormat = { model: string; rendered_image: string };

/**
 * Place aiDigest for each block as per OutputAIDigest structure
 */
export const generatePromptPayload = (
	aiDigest: Record<string, AIDigest>,
	blockIds: string[],
	includeBlockFields?: string[],
	option?: EMultiselectAIOpt
): OutputAIDigest => {
	try {
		const { projectId } = generateIdsFromUrl();
		const output: OutputAIDigest = {
			project_name: getProjectFromRedux(projectId)?.projectDetails?.name
		};
		if (option && option === 'GENERATE_PRESENTATION') {
			const projThumbnailLink = getProjectFromRedux(projectId)?.thumbnail?.src;
			if (projThumbnailLink) {
				output.project_thumbnail = projThumbnailLink;
			}
		}
		output.groups = [];
		const groupRedux = store.getState().stages.data;
		const blocksRedux = store.getState().blocks.data;
		const inputBlockData: BlockAIDigest[] = [];
		// Fetch block data for all blocks
		for (let i = 0; i < blockIds.length; i++) {
			const blockId = blockIds[i] as string;

			// Done this way so that empty fields are not added to the blockData object
			// and also to ensure that the fields are added in the correct order
			const blockData: BlockAIDigest = {
				block_name: blocksRedux[blockId]?.name as string,
				blockType: blocksRedux[blockId]?.blockType as string
			};

			if (aiDigest[blockId] && aiDigest[blockId]?.aiDigests) {
				blockData.description = {
					...(aiDigest[blockId]!.aiDigests || Object.values(aiDigest)[0]) // OR for mocking api
				};
			}

			if (includeBlockFields) {
				if (includeBlockFields.includes('link')) {
					const blockLink = (blocksRedux[blockId] as ILink).link;
					if (blockLink) {
						blockData.link = blockLink as string;
					}

					// For GENERATE_PRESENTATION, links have to be image links
					// So, if the block is not an image, we need to set the link as the thumbnail
					if (option && option === 'GENERATE_PRESENTATION') {
						if (blockData.blockType !== 'IMAGE') {
							const blockThumbnailLink = blocksRedux[blockId]?.thumbnail?.src;
							if (blockLink) {
								blockData.link = blockThumbnailLink as string;
							}
						}
					}
				}
			}

			blockData.parentId = blocksRedux[blockId]?.parentId as string;

			inputBlockData.push(blockData);
		}

		// Group the blocks as per group
		const groupedByParentId: GroupedBlocksAIDigest = inputBlockData.reduce(
			(acc: GroupedBlocksAIDigest, item: BlockAIDigest) => {
				if (!acc[item.parentId as string]) {
					acc[item.parentId as string] = [];
				}
				acc[item.parentId as string]?.push(item);
				return acc;
			},
			{}
		);

		// Push objects to the output[groups]
		const groupedBlocks = Object.values(groupedByParentId) as BlockAIDigest[][];
		for (let i = 0; i < groupedBlocks.length; i++) {
			const temp: GroupAIDigest = {
				group_name: groupRedux[groupedBlocks[i]![0]!.parentId as string]?.name as string,
				blocks: groupedBlocks[i] as BlockAIDigest[]
			};
			output.groups.push(temp);
		}

		return output;
	} catch (error) {
		console.error('error', error);
		throw error;
	}
};

/**
 * When multiple blocks are selected, find the index of the phase which contains the rightmost block
 */
export const getRightMostSelectedBlockGroupIndex = (
	blockIds: string[],
	projectId: string,
	is3d: boolean,
	isRegenerated: boolean,
	numOfOutputBlocks: number,
	aiType: EMultiselectAIOpt
) => {
	let maxIndex = 0;
	const groupIds: string[] = [];
	const reduxBlocks = store.getState().blocks.data;
	const reduxProject = store.getState().projects.data[projectId];
	blockIds.forEach((blockId) => {
		groupIds.push(reduxBlocks[blockId]?.parentId as string);
	});

	reduxProject?.children?.forEach((child, index) => {
		if (groupIds.includes(child?.toString() as string) && maxIndex < index) {
			maxIndex = index;
		}
	});

	if (numOfOutputBlocks === 1 && is3d) {
		if (isRegenerated) maxIndex += 2;
		else maxIndex += 1;
		return maxIndex;
	}
	if (isRegenerated) {
		if (is3d) {
			maxIndex += 4;
		} else if (aiType === 'GENERATE_3D_MODEL') {
			maxIndex += 3;
		} else {
			maxIndex += 2;
		}
	} else if (is3d) maxIndex += 2;
	else maxIndex += 1;
	return maxIndex;
};

/**
 * Create Group and blocks for multiselect AI
 */
export const multiselectAiCreateBlocks = async (
	links: TTripoOutputFormat[] | string[],
	aiType: EMultiselectAIOpt,
	userId: string,
	isOutputRegenerated: boolean,
	isMultiselectAIInProg: boolean,
	inputBlockIds: string[],
	summary: string,
	undoDeleteBlocks: (
		blocksToRevive: IBlock[],
		groups: IGroup[],
		parent: TGroupParent,
		projectChildrenIds?: string[]
	) => void,
	updateBlocksAndGroup: any,
	feedbackSummary?: string,
	shouldAddNote?: boolean,
	createNewPhase?: boolean
	// eslint-disable-next-line consistent-return
) => {
	try {
		if (isMultiselectAIInProg) {
			const groupsToCreate = [];
			const blocksToCreate = [];
			let newPhaseName = '';
			switch (aiType) {
				case 'GENERATE_3D_MODEL':
					newPhaseName =
						// @ts-ignore
						Array.isArray(links) && links.every((item: any) => typeof item === 'string')
							? 'AI generated concepts'
							: 'AI generated model';
					break;
				case 'GENERATE_CONCEPTS':
					newPhaseName = isOutputRegenerated
						? '(2) AI generated concepts'
						: 'AI generated concepts';
					break;
				case 'GENERATE_NEXT_STEPS':
					newPhaseName = 'AI generated journey';
					break;
				default:
					newPhaseName = '';
			}
			const phaseId = new mongoose.Types.ObjectId().toString() as string;
			const { projectId } = generateIdsFromUrl();
			const groups = store.getState().stages.data;
			const blocks = store.getState().blocks.data;
			const projectStages =
				(store.getState().projects.data[projectId] as IProject)?.children?.length || 0;

			const phase = generateOptimisticStage(
				phaseId,
				newPhaseName || `Untitled ${projectStages + 1}`,
				{
					id: generateIdsFromUrl().projectId,
					type: 'JOURNEY'
				}
			);
			const blockIdsToAdd = [];
			const blocksToAdd = [];
			const is3D = !!(links[0] as TTripoOutputFormat)?.model;
			let imgName = '';
			if (links) {
				for (let i = 0; i < links?.length; i++) {
					if (links[i]) {
						const blockId = new mongoose.Types.ObjectId().toString() as string;
						blockIdsToAdd.push(blockId);
						let optimisticBlock = {} as IImage | I3D;

						if (
							Array.isArray(links) &&
							// @ts-ignore
							links.every((item: any) => typeof item === 'string')
						) {
							// Added temporarily @MADHUMITA
							if (aiType === 'GENERATE_RENDER') imgName = 'Generated render';
							else if (aiType === 'EDIT_IMAGE_WITH_FEEDBACK')
								imgName = 'Edited with feedback';
							else imgName = `Concepts ${i + 1}`;
							// links are not for 3D
							if (links[i] !== '') {
								optimisticBlock = generateOptimisticBlock(
									blockId,
									phaseId,
									projectId,
									imgName,
									'IMAGE'
								) as IImage;
								optimisticBlock.link = links[i] as string;
								optimisticBlock.uploadedBy = userId as string;
								optimisticBlock.originalLink = links[i] as string;
								optimisticBlock.fileName = `Concept ${i + 1}.png`;
								optimisticBlock.thumbnail = {
									originalSrc: links[i] as string,
									src: links[i] as string,
									isCustom: false
								};
								if (optimisticBlock._id) blocksToAdd.push(optimisticBlock);
							}
						} else if (is3D) {
							optimisticBlock = generateOptimisticBlock(
								blockId,
								phaseId,
								projectId,
								`3D Model ${i + 1}`,
								'THREE_D'
							) as I3D;
							optimisticBlock.link = [
								(links[i] as TTripoOutputFormat)?.model as string
							];
							optimisticBlock.uploadedBy = userId as string;
							optimisticBlock.originalLink = [
								(links[i] as TTripoOutputFormat)?.model as string
							];
							optimisticBlock.thumbnail = {
								originalSrc: (links[i] as TTripoOutputFormat)
									?.rendered_image as string,
								src: (links[i] as TTripoOutputFormat)?.rendered_image as string,
								isCustom: false
							};
							optimisticBlock.fileName = `3D Model ${i + 1}.glb`;
							if (optimisticBlock._id && (links[i] as TTripoOutputFormat)?.model)
								blocksToAdd.push(optimisticBlock);
						}
					}
				}
			}

			// For creating single block
			switch (aiType) {
				case 'SUMMARIZE':
				case 'EVALUATE_SUSTAINABILITY':
				case 'EVALUATE_MANUFACTURING':
				case 'GENERATE_ESTIMATE': {
					let type: string;
					switch (aiType) {
						case 'EVALUATE_SUSTAINABILITY':
							type = 'sustainability';
							break;
						case 'GENERATE_ESTIMATE':
							type = 'estimate';
							break;
						case 'EVALUATE_MANUFACTURING':
							type = 'manufacturing';
							break;
						default:
							type = 'summary';
							break;
					}
					const blockId = new mongoose.Types.ObjectId().toString() as string;
					blockIdsToAdd.push(blockId);
					const textBlock = generateOptimisticBlock(
						blockId,
						phaseId,
						projectId,
						isOutputRegenerated ? `(2) AI generated ${type}` : `AI generated ${type}`,
						'TEXT'
					) as IText;
					// Convert plain text to lexical state
					const lexicalText = await htmlToLexical(summary as string);
					(textBlock as IText).text = lexicalText;
					if (textBlock._id) blocksToAdd.push(textBlock);
					break;
				}
				case 'GENERATE_PRESENTATION': {
					const blockId = new mongoose.Types.ObjectId().toString() as string;
					blockIdsToAdd.push(blockId);
					const linkBlock = generateOptimisticBlock(
						blockId,
						phaseId,
						projectId,
						isOutputRegenerated
							? '(2) AI generated presentation'
							: 'AI generated presentation',
						'LINK'
					) as ILink;
					(linkBlock as ILink).link = summary as string;
					(linkBlock as ILink).addedBy = userId as string;
					if (linkBlock._id) blocksToAdd.push(linkBlock);
					break;
				}
				default: {
					console.error('No case');
				}
			}

			phase.children = blockIdsToAdd;
			phase.isPhaseCreated = blockIdsToAdd.length > 1;

			groupsToCreate.push(phase);
			blocksToCreate.push(...blocksToAdd);

			if (
				aiType === 'EDIT_IMAGE_WITH_FEEDBACK' ||
				aiType === 'GENERATE_RENDER' ||
				!createNewPhase
			) {
				// get phaseId of the input block
				const inputBlock = blocks[inputBlockIds[0] as string];
				const inputBlockIndex = groups[inputBlock?.parentId as string]?.children.findIndex(
					(c: any) => c === (inputBlock!._id as string)
				);
				// update parent to match it with input block parentId
				for (let i = 0; i < blocksToAdd.length; i++) {
					blocksToAdd[i]!.parentId = inputBlock?.parentId as string;
				}
				// payload for add multiple blocks action
				const apiPayload: TAddMultipleBlockArgs = {
					data: {
						blocks: blocksToAdd,
						stageId: inputBlock?.parentId as string,
						projectId,
						newBlockIndex: (inputBlockIndex || 0) + 1
					}
				};
				await store.dispatch(addMultipleBlocks(apiPayload));
			} else {
				// Construct dispatch payload
				const dispatchPayload: TBatchCreateGroupAndBlocks = {
					parent: { id: projectId, type: 'JOURNEY' },
					groups: groupsToCreate,
					blocks: blocksToCreate,
					startGroupIndex: getRightMostSelectedBlockGroupIndex(
						inputBlockIds,
						projectId,
						is3D,
						isOutputRegenerated,
						blocksToAdd.length,
						aiType
					),
					children: groupsToCreate.map((group) => group._id.toString())
				};
				await store.dispatch(batchCreateGroupAndBlocks(dispatchPayload));
			}

			// add Note
			if (
				aiType === 'EDIT_IMAGE_WITH_FEEDBACK' ||
				aiType === 'GENERATE_RENDER' ||
				shouldAddNote
			) {
				const inputBlock = blocks[inputBlockIds[0] as string];
				let noteText = '';
				switch (aiType) {
					case 'GENERATE_RENDER':
						noteText =
							`This is a AI generated render of` +
							` <a href="${projectId}/${inputBlock?.parentId}/${inputBlock?._id}">this block</a>`;
						break;

					// default as well as EDIT_IMAGE_WITH_FEEDBACK case
					default: {
						noteText =
							`This image was generated based on the feedback on` +
							` <a href="${projectId}/${inputBlock?.parentId}/${inputBlock?._id}">this block</a>.` +
							` The feedback provided was: <i>${feedbackSummary}</i>`;
					}
				}
				store.dispatch(
					addNote({
						payload: {
							_id: new mongoose.Types.ObjectId().toString() as string,
							text: noteText,
							color: '#C6C0C4',
							createdBy: userId as string,
							lastUpdatedBy: userId as string,
							createdAt: new Date(),
							updatedAt: new Date(),
							projectId,
							parentId: blockIdsToAdd[0] as string,
							parentType: 'BLOCK'
						}
					})
				);
			}
			const projects = store.getState().projects.data;
			const project = projects[projectId];
			const blockUpdates = blocksToCreate.map((block) => ({
				blockId: block._id,
				updates: {
					...block,
					isVisible: false
				}
			}));
			const groupUpdates = groupsToCreate.map((group) => ({
				groupId: group._id,
				updates: {
					...group,
					isVisible: false
				}
			}));
			// Save history when user adds groups and blocks
			window.Naya.handleUndoRedo({
				type: 'ADD',
				payload: {
					originalAction: undoDeleteBlocks,
					oppositeAction: updateBlocksAndGroup,
					originalActionPayload: [
						blocksToCreate,
						groupsToCreate,
						undefined,
						project?.children
					],
					oppositeActionPayload: [
						blockUpdates,
						groupUpdates,
						Object.values(blocks),
						Object.values(groups)
					]
				}
			});

			if (
				aiType === 'EDIT_IMAGE_WITH_FEEDBACK' ||
				aiType === 'GENERATE_RENDER' ||
				!createNewPhase
			) {
				return {
					groupId: blocks[inputBlockIds[0] as string]?.parentId as string,
					blockIds: blockIdsToAdd
				};
			}

			return {
				groupId: phase._id as string,
				blockIds: blockIdsToAdd
			};
		}
	} catch (error) {
		console.error('multiselectAiCreateBlocks error', error);
		throw error;
	}
};

// multiselectAiCreateBlocks returns output of the type TMultiSelResultGroupAndBlocks
export type TMultiSelResultGroupAndBlocks = {
	groupId: string;
	blockIds: string[];
};

export type TGenPresentationType = 'PROJECT' | 'MULTISELECT';

/**
 * Returns the appropriate message based on the AI operation type and whether it is a regeneration.
 *
 * @param {EMultiselectAIOpt} opt - The AI operation type (e.g., GENERATE_3D_MODEL, SUMMARIZE).
 * @param {boolean} [isRegenerate=false] - Flag indicating whether the operation is a regeneration.
 * @returns {string} - The message indicating the operation's status.
 */
export const getMessageContent = (
	opt: EMultiselectAIOpt,
	isRegenerate: boolean = false
): string => {
	// Messages for different AI operations, customized for generation and regeneration scenarios.
	const messages: { [key: string]: string } = {
		GENERATE_3D_MODEL_WITH_FEEDBACK: isRegenerate
			? '3D model regenerated'
			: '3D model generated',
		SUMMARIZE: isRegenerate ? 'Summary regenerated' : 'Summary generated',
		GENERATE_NEXT_STEPS: isRegenerate ? 'Next steps regenerated' : 'Next steps generated',
		GENERATE_CONCEPTS: isRegenerate ? 'Concepts regenerated' : 'Concepts generated',
		GENERATE_PRESENTATION: isRegenerate ? 'Presentation regenerated' : 'Presentation generated',
		GENERATE_ESTIMATE: isRegenerate ? 'Estimate regenerated' : 'Estimate generated',
		EVALUATE_MANUFACTURING: isRegenerate
			? 'Manufacturing reevaluated'
			: 'Manufacturing evaluated',
		EVALUATE_SUSTAINABILITY: isRegenerate
			? 'Sustainability reevaluated'
			: 'Sustainability evaluated',
		GENERATE_VIDEO: isRegenerate ? 'Video regenerated' : 'Video generated',
		GENERATE_RENDER: isRegenerate ? 'Render regenerated' : 'Render generated',
		EDIT_IMAGE_WITH_FEEDBACK: 'Image edited'
	};

	// Returns the corresponding message based on the AI operation type,
	// or a default message for the 'SUMMARIZE' operation if the type is not found.
	return messages[opt] || (isRegenerate ? 'Summary regenerated' : 'Summary generated');
};

const FEEDBACK_AI_REPLIES = {
	AI_REPLY_TEXT: `<p><ADD_TEXT>! <a href="<ADD_URL>" class="AI-link">Go there</a></p>`,
	UNCLEAR_AI_REPLY:
		`<p>I’m having some trouble with your request. Could you provide more details or clarify what you need?` +
		` </p><p><br></p><p><em>You can ask me to generate summaries, concepts, renders, 3D models, sustainability evaluations,` +
		` and manufacturing evaluations. For example, “@AI help me summarize this.”</em></p>`,
	EMPTY_AI_REPLY:
		`<p>What's up? How can I help you? </p><p><br></p><p><em>You` +
		` can ask me to generate summaries, concepts, renders, 3D models, sustainability` +
		` evaluations, and manufacturing evaluations. For example, “@AI help me summarize this.”</em></p>`,
	CONTENT_TRAINING_OFF:
		`Oops! I need content training turned on to help.` +
		` Just enable it in AI settings, and we're good to go!`,
	CONTENT_TRAINING_IN_PROGRESS:
		`I’m currently setting things up to give you the best results.` +
		` Commands will work once content training is complete.` +
		` Thanks for your patience—I’ll be here to assist shortly!`
};

// Enum to represent different types of AI replies.
export enum EAIReplyType {
	EMPTY = 'EMPTY',
	UNCLEAR = 'UNCLEAR',
	CUSTOM = 'CUSTOM',
	CONTENT_TRAINING_OFF = 'CONTENT_TRAINING_OFF',
	CONTENT_TRAINING_IN_PROGRESS = 'CONTENT_TRAINING_IN_PROGRESS'
}

/**
 * Adds AI-generated feedback as a reply to a specific comment block.
 */
export const addAIFeedbackReply = (
	reply: IFeedback,
	projectId: string,
	groupId: string,
	blockId: string,
	type: EAIReplyType,
	journeyRtc: boolean,
	parent?: string
) => {
	// Retrieve the AI feedback reply string from session storage, if needed.
	const aiReplyStr =
		type === EAIReplyType.CUSTOM ? window.sessionStorage.getItem('ai-feedback-reply') : null;

	// Get the AI user details and the current user from Redux state.
	const aiUser = getAIUserFromRedux();
	// Retrieve existing comments based on the block type.
	const oldComments = getFeedbackBasedOnBlockType(blockId);
	// Set the creator and last updated by fields to the AI user.
	reply.createdBy = aiUser;
	reply.lastUpdatedBy = aiUser._id as string;
	reply.createdAt = new Date();

	let stageToBePassed = groupId;
	let parentToBePassed = parent;

	switch (type) {
		case EAIReplyType.EMPTY: {
			reply.statement = FEEDBACK_AI_REPLIES.EMPTY_AI_REPLY;
			break;
		}
		case EAIReplyType.UNCLEAR: {
			reply.statement = FEEDBACK_AI_REPLIES.UNCLEAR_AI_REPLY;
			break;
		}
		case EAIReplyType.CONTENT_TRAINING_OFF: {
			reply.statement = FEEDBACK_AI_REPLIES.CONTENT_TRAINING_OFF;
			break;
		}
		case EAIReplyType.CONTENT_TRAINING_IN_PROGRESS: {
			reply.statement = FEEDBACK_AI_REPLIES.CONTENT_TRAINING_IN_PROGRESS;
			break;
		}
		case EAIReplyType.CUSTOM:
		default: {
			if (type === EAIReplyType.CUSTOM && aiReplyStr) {
				const parsedData = JSON.parse(aiReplyStr);
				const { parent: parsedParent, stageId } = parsedData;
				stageToBePassed = stageId;
				parentToBePassed = parsedParent;
				const replyURl = `${window.location.origin}/project/${projectId}/${groupId}/${blockId}`;
				reply.statement = FEEDBACK_AI_REPLIES.AI_REPLY_TEXT.replace(
					'<ADD_TEXT>',
					getMessageContent(parsedData.aiType, false)
				).replace('<ADD_URL>', replyURl) as string;
				// Remove the AI feedback reply string from session storage.
				window.sessionStorage.removeItem('ai-feedback-reply');
			}
			break;
		}
	}

	// Prepare the API payload for adding the comment.
	const apiPayload: TAddCommentThunkArg = {
		payload: {
			feedback: reply,
			blockId,
			stageId: stageToBePassed,
			projectId,
			userId: aiUser._id,
			parentCommentId: parentToBePassed,
			currentUser: aiUser,
			isJourneyRtcEnabled: journeyRtc
		},
		prevState: {
			oldComments
		}
	};

	// Dispatch the action to add the comment.
	(store.dispatch as CustomDispatch)(addComment(apiPayload));
};

/**
 * Identifies the intent of a given prompt using an AI model.
 *
 * @param prompt - The prompt for which the intent is to be identified.
 * @returns The identified intent as a string.
 */
export const identifyAIIntent = async (prompt: string, blockType: EBlockType) => {
	// Show a loading snackbar indicating that feedback is being analyzed.
	addSnackbar({
		show: true,
		type: 'LOADER',
		text: 'Analyzing feedback'
	});
	// Dispatch the action to identify the AI intent and wait for the result.
	const intentRes = await (store.dispatch as CustomDispatch)(
		identifyAiIntent({
			prompt,
			blockType
		})
	);
	// Remove the loading snackbar.
	removeSnackbar(0);
	// Return the identified intent as a string.
	return intentRes.payload as string;
};

/**
 * Handles scenarios where the AI is unable to identify the user's intent.
 * Displays an error snackbar with a message suggesting alternative prompts.
 */
export const handleUnindentifiedIntent = (
	reply: IFeedback,
	projectId: string,
	groupId: string,
	blockId: string,
	journeyRtc: boolean,
	type: EAIReplyType,
	parent?: string
) => {
	addAIFeedbackReply(reply, projectId, groupId, blockId, type, journeyRtc, parent);

	// NOTE: need to remove the snackbar else this snackar gets removed because of ai intent snackbar
	removeSnackbar(0, () => {
		// Show an error snackbar with suggestions on how to phrase the prompt.
		addSnackbar({
			show: true,
			type: 'ERROR',
			text: 'Unable to generate a response. Provide more info for AI to assist.'
		});
		// Remove the error snackbar after 5 seconds.
		removeSnackbar(5000);
	});
};

/**
 * Removes AI-related session storage items used during feedback processes.
 */
export const removeFeedbackAiSessions = () => {
	window.sessionStorage.removeItem('ai-generate-data');
	window.sessionStorage.removeItem('ai-regenerate-data');
	window.sessionStorage.removeItem('ai-feedback-reply');
};

export const FeedbackAISnackbars = {
	CONTENT_TRAINING_INACTIVE: `Advanced AI features are locked until content training is enabled.`,
	CONTENT_TRAINING_STARTED: `I’m working on your data to provide better results. This process will take a few minutes.`,
	CONTENT_TRAINING_INPROGRESS:
		'Training is still in progress. Please wait a bit longer before using AI features.',
	INGESTION_COMPLETE: 'All set! Advanced AI features are now ready for use.'
};
