import { IUserPreferenceNode } from '@naya_studio/types';
import axios from 'axios';
import { store } from 'src';
import { ACCOUNTS_API_URL } from 'src/endpoints/api';
import { CustomDispatch } from 'src/redux/actions/types';
import { editUserPreferences } from 'src/redux/actions/user';
import { TEditUserPreferenceThunkArgs } from 'src/types/argTypes';
import { getGatewayKey } from 'src/util/helper/queryString';

const qs = `?${getGatewayKey()}`;

const getUserFromRedux = () => store.getState().user.data;
export const getAIUserFromRedux = () => store.getState().aiUser.data;
export const getMiroTokensFromRedux = () => store.getState().integrations.miro;
export const getGoogleTokensFromRedux = () => store.getState().integrations.google;

export const getUserBlocksCount = async (userId: string) => {
	try {
		const response = await axios.get(
			`${ACCOUNTS_API_URL}/user/get-blocks-count/${userId}${qs}`
		);
		if (response.status !== 200) return null;
		return response.data;
	} catch (e: any) {
		console.error(e);
		return null;
	}
};

/**
 * Function to edit user preferences
 * @param {Partial<IUserPreferenceNode>} payload - updates in user preferences
 * @returns
 */
export const onEditUserPreference = (payload: Partial<IUserPreferenceNode>) => {
	const { userType } = getUserFromRedux();

	// Ignore edit preference call for guest user since guest user won't have an _id allocated to it
	if (!userType?.includes('GUEST'))
		(store.dispatch as CustomDispatch)(
			editUserPreferences({
				payload,
				prevState: {
					preferences: getUserFromRedux().userPreferences as IUserPreferenceNode
				}
			} as TEditUserPreferenceThunkArgs)
		);
};

/**
 * Function to get journey zoom value based on projectID for current user
 * @param projectId
 * @returns
 */
export const getProjectZoomValue = (projectId: string) => {
	const zoomPreferences =
		(getUserFromRedux().userPreferences as IUserPreferenceNode).journeyZoom || [];
	const projectZoom = zoomPreferences.find((item) => item.project === projectId);
	return projectZoom?.zoom;
};

export default getUserFromRedux;
