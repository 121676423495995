import { getYDoc } from 'src/rtc/yjs/yjsConfig';
import { Map as YMap } from 'yjs';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src';
import { addBlock, deleteBlockById, editBlockById } from 'src/redux/features/blocks';
import { addStagesToRedux, deleteStageById, editStageById } from 'src/redux/features/stages';
import { updateProject } from 'src/redux/features/projects';
import { useHistory } from 'react-router';
import { addNotesInRedux, deleteNoteFromRedux, editNoteInRedux } from 'src/redux/features/notes';
import { addFeedback, deleteFeedbackById, editFeedback } from 'src/redux/features/feedbacks';
import { addNode, deleteNodeById, editNodeById } from 'src/redux/features/nodes';
import {
	addUsersToProjectUsers,
	removeUserFromProjectUsers
} from 'src/redux/features/projectUsers';
import useSyncStorageObserver from './useSyncStorageObserver';
import useProjectUsersObserver from './useProjectUsersObserver';
import useNoteObserver from './useNoteObserver';
import useProjectObserver from './useProjectObserver';
import useStageObserver from './useStageObserver';
import useBlockObserver from './useBlockObserver';
import useFeedbackObserver from './useFeedbackObserver';
import useNodeObserver from './useNodeObserver';

// Type of data which is broad casted for inter tab communication
export type TBroadCastType = {
	change: any;
	type: 'ADD' | 'EDIT' | 'DELETE';
	level: 'NODE' | 'FEEDBACK' | 'NOTE' | 'USER' | 'BLOCK' | 'GROUP' | 'JOURNEY';
};

/**
 * Hook to use initialize observers on maps
 * @returns function to initialize observers
 */
const useObserverInitializer = () => {
	const dispatch = useDispatch<AppDispatch>();
	const history = useHistory();

	const projectObserver = useProjectObserver();
	const stageObserver = useStageObserver();
	const blockObserver = useBlockObserver();
	const noteObserver = useNoteObserver();
	const feedbackObserver = useFeedbackObserver();
	const nodeObserver = useNodeObserver();
	const syncStorageObserver = useSyncStorageObserver();
	const { projectUsersObserver } = useProjectUsersObserver();

	/**
	 * Callback function to handle broadcast event
	 * Dispatch appropriate action based on the event type
	 * @param event Storage Event
	 */
	const onBroadCast = (event: StorageEvent) => {
		if (event.key === 'broadcast-self-change' && event.newValue) {
			const { change: payload, type, level } = JSON.parse(event.newValue) as TBroadCastType;

			// Fetch appropriate add dispatch callback function
			const fetchAddCallback = () => {
				switch (level) {
					case 'USER':
						return addUsersToProjectUsers;
					case 'NODE':
						return addNode;
					case 'FEEDBACK':
						return addFeedback;
					case 'NOTE':
						return addNotesInRedux;
					case 'BLOCK':
						return addBlock;
					case 'GROUP':
						return addStagesToRedux;
					default:
						return null;
				}
			};

			// Fetch appropriate edit dispatch callback function
			const fetchEditCallback = () => {
				switch (level) {
					case 'NODE':
						return editNodeById;
					case 'FEEDBACK':
						return editFeedback;
					case 'NOTE':
						return editNoteInRedux;
					case 'BLOCK':
						return editBlockById;
					case 'GROUP':
						return editStageById;
					case 'JOURNEY':
						return updateProject;
					default:
						return null;
				}
			};

			// Fetch appropriate delete dispatch callback function
			const fetchDeleteCallback = () => {
				switch (level) {
					case 'USER':
						return removeUserFromProjectUsers;
					case 'NODE':
						return deleteNodeById;
					case 'FEEDBACK':
						return deleteFeedbackById;
					case 'NOTE':
						return deleteNoteFromRedux;
					case 'BLOCK':
						return deleteBlockById;
					case 'GROUP':
						return deleteStageById;
					default:
						return null;
				}
			};

			switch (type) {
				case 'ADD': {
					const addDispatch = fetchAddCallback();
					if (addDispatch) dispatch(addDispatch(payload));
					break;
				}
				case 'EDIT': {
					const editDispatch = fetchEditCallback();
					if (editDispatch) dispatch(editDispatch(payload));
					break;
				}
				case 'DELETE':
					if (level === 'JOURNEY') history.push('/studio');
					else {
						const deleteDispatch = fetchDeleteCallback();
						if (deleteDispatch) dispatch(deleteDispatch(payload));
					}
					break;
				default:
					break;
			}
		}
	};

	/**
	 * Callback function to add storage listener on window
	 */
	const initializeLocalStorageBroadcast = () => {
		window.addEventListener('storage', onBroadCast);
	};

	/**
	 * Callback function to remove storage listener from window
	 */
	const stopLocalStorageBroadcast = () => window.removeEventListener('storage', onBroadCast);

	/**
	 * Function to initialize observers
	 * @param param0 initializers to observe, default to true
	 */
	const initializeObservers = ({
		project = false,
		stages = false,
		blocks = false,
		notes = false,
		feedbacks = false,
		nodes = false,
		projectUsers = false,
		syncStorage = false
	}) => {
		try {
			const doc = getYDoc();
			if (syncStorage) {
				(doc.getMap('observers') as YMap<string>)._eH.l = [];
				(doc.getMap('observers') as YMap<string>).observe(syncStorageObserver);
			}

			if (project) {
				(doc.getMap('project') as YMap<string>)._eH.l = [];
				(doc.getMap('project') as YMap<string>).observe(projectObserver);
			}

			if (stages) {
				(doc.getMap('pathways') as YMap<string>)._eH.l = [];
				(doc.getMap('pathways') as YMap<string>).observe(stageObserver);
			}

			if (blocks) {
				(doc.getMap('blocks') as YMap<string>)._eH.l = [];
				(doc.getMap('blocks') as YMap<string>).observe(blockObserver);
			}

			if (notes) {
				(doc.getMap('notes') as YMap<string>)._eH.l = [];
				(doc.getMap('notes') as YMap<string>).observe(noteObserver);
			}

			if (feedbacks) {
				(doc.getMap('feedbacks') as YMap<string>)._eH.l = [];
				(doc.getMap('feedbacks') as YMap<string>).observe(feedbackObserver);
			}

			if (nodes) {
				(doc.getMap('nodes') as YMap<string>)._eH.l = [];
				(doc.getMap('nodes') as YMap<string>).observe(nodeObserver);
			}

			if (projectUsers) {
				(doc.getMap('projectUsers') as YMap<string>)._eH.l = [];
				(doc.getMap('projectUsers') as YMap<string>).observe(projectUsersObserver);
			}
		} catch (error) {
			console.error('Failed to initialize observers : ', error);
		}
	};

	return { initializeObservers, initializeLocalStorageBroadcast, stopLocalStorageBroadcast };
};

export default useObserverInitializer;
