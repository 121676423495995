import axios from 'axios';
import { isLocalhost } from 'src/util/collaboration/util';
import { getGatewayKey } from 'src/util/helper/queryString';

const MIRO_API = `${window.__RUNTIME_CONFIG__.REACT_APP_NAYA_PROXY}/https://api.miro.com/v2/boards`;

export const MIRO_AUTH_URL =
	`https://miro.com/oauth/authorize?` +
	`response_type=code` +
	`&client_id=${process.env.REACT_APP_MIRO_OAUTH_CLIENT_ID}` +
	`&redirect_uri=${window.__RUNTIME_CONFIG__.REACT_APP_MIRO_OAUTH_REDIRECT_URI}${
		!isLocalhost ? `?${getGatewayKey()}` : ''
	}`;

const REVOKE_MIRO_AUTH_URL = `${window.__RUNTIME_CONFIG__.REACT_APP_NAYA_PROXY}/https://api.miro.com/v1/oauth/revoke`;

/**
 * Function to extract miro board ID
 * @param miroBoardLink
 * @returns - extracted board ID from miroBoardLink
 */
export const extractMiroBoardID = (miroBoardLink: string) => {
	const pattern =
		/(?:https?:\/\/)?(?:www\.)?miro\.com\/(?:app\/(?:live-embed\/)?|embed\/(?:live-embed\/|preview\/)?)(?:board\/)?([^/?#]+)/;

	// Use the match method to search for the board ID in the link
	const BOARD_ID_INDEX = miroBoardLink.match(pattern);
	if (BOARD_ID_INDEX) {
		// Return the board ID if found
		return BOARD_ID_INDEX[1];
	}
	// Return null if no match is found
	return null;
};

/**
 * Function to create a new miro board
 * @param accessToken
 * @param name
 * @returns - link of the newly created miro board
 */
export const createMiroBoard = async (accessToken: string, name: string) => {
	try {
		const fileData = {
			name,
			policy: {
				sharingPolicy: {
					access: 'view',
					teamAccess: 'edit'
				}
			}
		};
		const response = await axios
			.post(MIRO_API, fileData, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
					'Content-Type': 'application/json'
				}
			})
			.then((res) => res)
			.catch((err) => err.response);

		if (response.status !== 201)
			return { status: false, link: '', id: '', error: response.data.message };

		// Extract new board details from the response
		const newBoard = response.data;
		return { status: true, link: newBoard.viewLink, id: newBoard.id };
	} catch (error: any) {
		console.error('Error creating Miro board:', error);
		return { status: false, link: '', id: '' };
	}
};

/**
 * Function to rename miro board
 * @param accessToken
 * @param boardLink
 * @param name
 * @returns true if file was renamed successfully
 */
export const renameMiroBoard = async (accessToken: string, boardLink: string, name: string) => {
	try {
		// extract miro board ID
		const boardId = extractMiroBoardID(boardLink);

		if (!boardId) return false;

		const fileData = { name };
		const response = await axios.patch(`${MIRO_API}/${boardId}`, fileData, {
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		});
		if (response.status === 200) return true;
		return false;
	} catch (e) {
		console.error(e);
		return false;
	}
};

/**
 * Function to duplicate miro board
 * @param accessToken
 * @param boardLink
 * @param name - name of the duplicated board
 * @returns - link of the duplicated file
 */
export const duplicateMiroBoard = async (
	accessToken: string | null,
	boardLink: string,
	name: string
) => {
	try {
		// extract miro board ID from link
		const boardId = extractMiroBoardID(boardLink);

		if (!boardId) return { status: false, link: '', id: '' };
		const fileData = {
			name,
			policy: {
				sharingPolicy: {
					access: 'view',
					teamAccess: 'edit'
				}
			}
		};
		const response = await axios
			.put(`${MIRO_API}?copy_from=${boardId}`, fileData, {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			})
			.then((res) => res)
			.catch((err) => err.response);

		if (response.status !== 201)
			return { status: false, link: '', id: '', error: response.data.message };

		const duplicatedBoard = response.data;
		return { status: true, link: duplicatedBoard.viewLink, id: duplicatedBoard.id };
	} catch (e: any) {
		console.error('duplicateMiroBoard error: ', e);
		return { status: false, link: '', id: '' };
	}
};

/**
 * Revoke Miro token
 * @param token
 * @returns
 */
export const revokeMiro = async (token: string) => {
	try {
		const response = await axios.post(REVOKE_MIRO_AUTH_URL, null, {
			params: {
				access_token: token
			}
		});
		return response;
	} catch (error: any) {
		console.error('Error disconnecting miro:', error.message);
		return '';
	}
};
