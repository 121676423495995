import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'src/redux/reducers/root.types';
import { useParams } from 'react-router';
import { PathParamsType } from 'src/types/pathParams';
import { AuthContext } from 'src/components/auth/AuthProvider';
import { throttle } from 'lodash';
import { EUserType } from '@naya_studio/types';
import { AppDispatch, store } from 'src';
import {
	loadActiveGroupInRedux,
	updateSiteDataInRedux
} from 'src/components/journeyContainer/JourneyContainerCallbacks';
import loadProjectUsers from '../reduxActions/projectUsers';
import { loadProjectById, loadProjectForGuestAction } from '../reduxActions/project';
import { CustomDispatch } from '../actions/types';
import useUser from './user';

/**
 * Custom hook to access the journey project from redux
 * @param id - project id to be accessed
 * @returns Redux Project as project, and its loading and error state
 */
const useProject = (
	id: string,
	cb?: (err: { status: number; message: string } | string) => void
) => {
	const { hasGuestAccessTo } = useContext(AuthContext);

	const params = useParams<PathParamsType>();
	const { user } = useUser();
	const dispatch = useDispatch<AppDispatch>();

	const { project, isLoading, error } = useSelector((state: ReduxState) => ({
		project: state.projects.data[id],
		isLoading: state.projects.loading[id],
		error: state.projects.error[id]
	}));

	// Function to reset zoom and scroll properties
	// and load project from backend
	const loadProject = throttle(
		() => {
			window.sessionStorage.removeItem('savedNotes');
			window.sessionStorage.removeItem('zoom');
			window.sessionStorage.removeItem('journey-scroll');
			window.sessionStorage.removeItem('blocklist-scroll');
			if (
				hasGuestAccessTo === 'BLOCK' &&
				(!user._id || user.userType?.includes(EUserType.GUEST))
			) {
				dispatch(
					loadProjectForGuestAction({
						payload: {
							projectId: id,
							stageId: params.stageOrDashboard,
							blockId: params.canvasId,
							hasGuestAccessTo
						}
					})
				)
					.unwrap()
					.then(() => {
						// Update the redux data with latest link block data
						updateSiteDataInRedux();

						// When project is loaded into redux load the group from local storage
						loadActiveGroupInRedux();
					})
					.catch((err) => {
						if (cb) cb(err);
					});
			} else {
				dispatch(loadProjectById({ payload: { _id: id } }))
					.unwrap()
					.then(() => {
						// Update the redux data with latest link block data
						updateSiteDataInRedux();

						// When project is loaded into redux load the group from local storage
						loadActiveGroupInRedux();
					})
					.catch((err) => {
						if (cb) cb(err);
					});
			}
			// load project users even when guest access is block so that feedbacks created would have right data
			(store.dispatch as CustomDispatch)(loadProjectUsers({ payload: { projectId: id } }));
		},
		2500,
		{ leading: true, trailing: false }
	);

	// use effect called first when hook triggered
	useEffect(() => {
		// if project doesn't exist and user is not loaded - load project
		if (!project && id && ((user._id && !isLoading) || hasGuestAccessTo)) {
			loadProject();
		}

		// Including id in dependency to make sure this is triggered if we open a journey from inside of another journey
	}, [hasGuestAccessTo, id]);

	// we need this useEffect  because user can get loaded after this hook is called
	// if that happens, load project would never be called
	// use effect listens to user obj in redux
	// if user is loaded and project doesn't exist, load project
	useEffect(() => {
		// if project doesn't exist and user is not loaded - load project
		if (!project && user._id && id && !isLoading) {
			loadProject();
		}
	}, [user._id]);

	return {
		project,
		isLoading,
		error
	};
};

export default useProject;
