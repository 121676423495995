import { INote, IUser } from '@naya_studio/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { noteRouter } from 'src/endpoints/projects-api';
import { TAddNoteArg, TEditNoteArg, TDeleteNoteArg, TLoadNoteArg } from 'src/types/argTypes';
import trackEvent from 'src/util/analytics/analytics';
import { CustomEvents } from 'src/util/analytics/events';
import { getGatewayKey } from 'src/util/helper/queryString';
import getUserFromRedux from 'src/util/helper/user';
import { TAddNoteEventData } from 'src/util/analytics/analytic.types';
import { AxiosResponseWrapper } from '../actions/types';

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
const qs = `?${getGatewayKey()}`;

// Load a note by making an asynchronous GET request
export const loadNoteById = createAsyncThunk(
	'notes/loadNote',
	async ({ payload }: TLoadNoteArg, thunkApi) => {
		try {
			const { _id } = payload;
			const response = await axios.get<AxiosResponseWrapper<INote>>(
				`${noteRouter}/${_id}/${qs}`
			);

			const note = response.data.payload as INote;

			return note;
		} catch (error) {
			if (error instanceof TypeError) {
				// Rejects with a specific message if a TypeError occurs
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors generically
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

// Adds a note by making an asynchronous POST request
export const addNote = createAsyncThunk(
	'notes/addNote',
	async ({ payload }: TAddNoteArg, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();
			// Send a POST request to add a note using axios
			await axios.post<AxiosResponseWrapper<INote>>(`${noteRouter}/${qs}`, {
				payload: {
					note: payload,
					lastUpdatedInfo: {
						lastUpdatedBy: user._id as string
					}
				}
			});

			// Track creating note event
			const eventProps: TAddNoteEventData = {
				elementType: payload.parentType,
				elementId: payload.parentId
			};
			trackEvent(CustomEvents.ADD_NOTE, eventProps);

			return payload;
		} catch (error) {
			if (error instanceof TypeError) {
				// Rejects with a specific message if a TypeError occurs
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors generically
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

// Edits a note by making an asynchronous POST request
export const editNote = createAsyncThunk(
	'notes/editNote',
	async ({ payload }: TEditNoteArg, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();

			// Send a POST request to edit a note using axios
			await axios.post<AxiosResponseWrapper<INote>>(`${noteRouter}/${payload._id}/${qs}`, {
				payload: {
					note: {
						...payload
						// savedNoteReference : payload.savedNoteReference ? payload.savedNoteReference : null
					},
					lastUpdatedInfo: {
						lastUpdatedBy: user._id as string
					}
				}
			});
			return payload;
		} catch (error) {
			if (error instanceof TypeError) {
				// Rejects with a specific message if a TypeError occurs
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors generically
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

// Unsaves a note by making an asynchronous POST request
export const unsaveNote = createAsyncThunk(
	'notes/unsave',
	async ({ payload }: { payload: Partial<INote> }, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();

			// Send a POST request to edit a note using axios
			await axios.put<AxiosResponseWrapper<INote>>(`${noteRouter}/unsave/${qs}`, {
				payload: {
					...payload,
					lastUpdatedInfo: {
						lastUpdatedBy: user._id as string
					}
				}
			});
			return payload;
		} catch (error) {
			if (error instanceof TypeError) {
				// Rejects with a specific message if a TypeError occurs
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors generically
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

// Deletes a note by making an asynchronous DELETE request
export const deleteNote = createAsyncThunk(
	'notes/deleteNote',
	async ({ payload, next }: TDeleteNoteArg, thunkApi) => {
		try {
			const user = getUserFromRedux();

			// Send a DELETE request to delete a note using axios
			await axios.delete<AxiosResponseWrapper<INote>>(`${noteRouter}/${qs}`, {
				data: {
					payload: {
						...payload,
						lastUpdatedInfo: {
							lastUpdatedBy: user._id as string,
							noteId: payload.noteId as string
						}
					}
				}
			});
			if (next) {
				next();
			}
			return payload;
		} catch (error) {
			if (error instanceof TypeError) {
				// Rejects with a specific message if a TypeError occurs
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors generically
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);
