import { IBlock, IImage, I3D } from '@naya_studio/types';
import React from 'react';
import { store } from 'src';
import { findStageWithBlockId } from 'src/redux/actions/util';
import { isThumbnailColorString } from '@naya_studio/radix-ui';
import getUserFromRedux from 'src/util/helper/user';

/**
 * Custom hook to load thumbnails and optimize them
 */
const useLoader = () => {
	/**
	 * Splits a URL into its components.
	 *
	 * @param src The URL string to be split.
	 * @returns An array containing the components of the URL.
	 */
	const getUrlSplits = (src: string): string[] => {
		// Decode the provided source URL
		const decodedLink = decodeURIComponent(src);

		// Remove common prefixes from Google Cloud Storage URLs
		const baseUrl = decodedLink
			.replace('https://storage.googleapis.com/', '')
			.replace('https://firebasestorage.googleapis.com/v0/b/', '')
			.replace('/o/', '/');

		// Split the base URL into its components using "/"
		const splits = baseUrl.split('/');

		return splits;
	};

	/**
	 * Function to convert url to cloudinary url
	 * @param src asset url to convert
	 * @param subFolder sub folder name in cloudinary bucket
	 * @param query query to apply for optimization
	 * @returns Optimized cloudinary url
	 */
	const convertUrlToCloudinaryUrl = (src: string, query?: string) => {
		try {
			// Check if thumbnail is stored on firebase or google storage
			const isThumbnailStoredOnCloud =
				/(https:\/\/storage\.googleapis\.com\/|https:\/\/firebasestorage\.googleapis\.com\/v0\/b\/)/.test(
					src
				);

			// If not, return original src
			if (!isThumbnailStoredOnCloud) return src;

			const optimizationQuery = query || 'w_500/f_jpg/q_auto:eco';
			// Split the URL into parts
			const splits = getUrlSplits(src);
			// Determine the Cloudinary folder for the asset
			let bucket = splits[0];
			// Extract the file path from the splits
			const filePath = splits.slice(-1)[0];
			// Full path of firebase url
			const fullPath = splits.slice(1, -1).join('/');
			// Check if the URL indicates journey thumbnail or block assets and adjust the bucket accordingly
			bucket = `${bucket}${fullPath ? `/${fullPath}` : ''}`;

			// Extract the public ID and extension from the file path
			const [publicId, ext] = filePath!.split('?alt=media&token')[0]!.split(/\.(?=[^.]*$)/);

			// Construct the base Cloudinary asset URL
			let baseSrc = `https://res.cloudinary.com/dj0yxjzt0/image/upload/${optimizationQuery}/v1/${bucket}/${publicId}`;

			// If extension exists, append it to the base URL; otherwise, return the base URL
			if (ext) baseSrc = `${baseSrc}.${ext}`;

			return baseSrc;
		} catch (error) {
			console.error('Failed to optimize thumbnail url: ', error);
			return src;
		}
	};

	/**
	 * Preloads a Firebase thumbnail image by creating an Image object and loading the image from the provided source URL
	 * @param src The source URL of the thumbnail image to preload
	 * @param cb The callback function to be called after the image has finished loading or if an error occurs
	 */
	const preloadImagesFromUrls = (src: string, cb: () => void = () => {}): Promise<boolean> =>
		new Promise((resolve, reject) => {
			const img = new Image();
			img.src = convertUrlToCloudinaryUrl(src);

			/**
			 * Function to handle image load completion
			 */
			const handleLoad = () => {
				cb();
				resolve(true);
			};

			// Check if the image is already loaded
			if (!img.complete) {
				img.onload = handleLoad;
				// If cloudinary url fails, revert back to pre-load the firebase asset url
				img.onerror = () => {
					img.src = src;

					img.onerror = () => {
						cb();
						reject(new Error(`Failed to load thumbnail : ${src}`));
					};
				};
			} else cb();
		});

	/**
	 * Function to load project thumbnails while loader is still visible
	 * @param isGuestUser boolean to check if user is guest user
	 */
	const loadJourneyThumbnails = async (
		projectId: string,
		isGuestUser: boolean,
		setProgress: React.Dispatch<React.SetStateAction<number>>
	) => {
		try {
			const user = getUserFromRedux();

			if (!user._id && !isGuestUser) return;
			const urlsForImagesToPreload: string[] = [];
			let noOfImagesLoaded = 0;

			const { blocks: reduxBlocks, stages: reduxGroups } = store.getState();
			const blocks = Object.values(reduxBlocks.data);
			const groups = reduxGroups.data;

			if (blocks) {
				for (let k = 0; k < blocks.length; k++) {
					const block = blocks[k] as IBlock;
					const blockId = block._id as string;

					const blockSupportsImageThumbnail = [
						'CANVAS',
						'PDF',
						'VIDEO',
						'IMAGE',
						'THREE_D',
						'EXCALIDRAW_CANVAS',
						'LINK'
					].includes(block.blockType);
					const blockExistInProject = block.projectId === projectId;
					const blockIsVisible = block.isVisible;
					const isGif = (block as IImage)?.fileName?.endsWith('gif') || false;

					let parentGroupIsVisible = false;
					const parentGroup = block.parentId
						? groups[block.parentId]
						: findStageWithBlockId(blockId);
					if (parentGroup) parentGroupIsVisible = parentGroup.isVisible;

					if (
						blockSupportsImageThumbnail &&
						blockExistInProject &&
						blockIsVisible &&
						parentGroupIsVisible
					) {
						const link =
							block?.blockType === 'THREE_D'
								? (block as I3D)?.link[0]
								: (block as IImage)?.link;
						const thumbnail =
							block?.thumbnail?.src ??
							(block?.blockType === 'LINK' ? '#FFFFFF' : (link as string));
						const isThumbnailColor = isThumbnailColorString(thumbnail);

						if (thumbnail && !isGif && !isThumbnailColor)
							urlsForImagesToPreload.push(thumbnail);
					}
				}
			}

			const totalThumbnails = urlsForImagesToPreload.length;

			/**
			 * Updates the progress of image loading and saves optimized thumbnails to session storage when all thumbnails are loaded.
			 */
			const updateProgress = () => {
				++noOfImagesLoaded;
				setProgress((noOfImagesLoaded / totalThumbnails) * 100);
			};

			if (totalThumbnails === 0) setProgress(100);
			else {
				// Pre-load thumbnails and update progress
				urlsForImagesToPreload.forEach((image) => {
					preloadImagesFromUrls(image, updateProgress);
				});
			}
		} catch (error) {
			console.error('Error loading fonts and thumbnails : ', error);
		}
	};

	return {
		loadJourneyThumbnails,
		getUrlSplits,
		convertUrlToCloudinaryUrl
	};
};

export default useLoader;
