import axios from 'axios';
import {
	IProject,
	IProjectGroup,
	IUser,
	IUserDetailNode,
	IUserPreferenceNode
} from '@naya_studio/types';
import { getGatewayKey } from 'src/util/helper/queryString';
import { projectGroupRouter } from 'src/endpoints/projects-api';
import { userDetailsRouter, userPreferenceRouter, userRouter } from 'src/endpoints/accounts-api';
import { ISnackBar } from 'src/redux/reducers/root.types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	TAddProjectGroup,
	TEditUserArgs,
	TEditUserDetailsArgs,
	TEditUserPreferenceThunkArgs,
	TGetProjectGroup,
	TRemoveProjectGroup
} from 'src/types/argTypes';
import getUserFromRedux from 'src/util/helper/user';
import { AppDispatch, store } from 'src';
import { saveUserInLocalForage } from 'src/util/storage/indexedDBStorage';
import { findAndUpdateInArray } from './util';
import { AxiosResponseWrapper, CustomDispatch } from './types';
import { addSnackbar, removeSnackbar } from './snackBar';
import { deleteGroup, updateGroup } from '../features/groupDetail';
import { updateUser } from '../features/user';

/**
 * api key for api-gateway
 * all request urls to backend needs to be suffixed by qs
 */
const qs = `?${getGatewayKey()}`;

/**
 * Loads user by Id
 * @param userId ObjectId
 * @returns user
 */
export const loadUser = createAsyncThunk(
	'user/loadUserByEmail',
	async (payload: { email: string }, thunkApi) => {
		try {
			const response = await axios.get<AxiosResponseWrapper<IUser>>(
				`${userRouter}/get-user-by-email/${payload.email}${qs}`
			);

			if (response.status !== 200) return thunkApi.rejectWithValue('Error loading user');

			const user: IUser = response.data?.user as IUser;
			if (user) await saveUserInLocalForage(user);
			return user;
		} catch (e: any) {
			console.error(e);
			thunkApi.rejectWithValue(e.message);
		}
		return null;
	}
);

/**
 * Creates new Project Group and adds it to the user
 * @param projectGroup Initial data
 * @returns
 */
export const addProjectGroup = createAsyncThunk(
	'user/addProjectGroup',
	async (payload: TAddProjectGroup, thunkApi) => {
		const { projectDetails, projectIds } = payload;
		const user: IUser = getUserFromRedux();
		const { defaultProjectGroup: defaultProjectGroupId } = user;

		try {
			const response = await axios.post<AxiosResponseWrapper<IProjectGroup>>(
				`${projectGroupRouter}${qs}`,
				{ payload: projectDetails, projects: projectIds, defaultProjectGroupId }
			);

			if (response.status !== 200)
				return thunkApi.rejectWithValue('Error creating Project Group');
			if (user) await saveUserInLocalForage(user);
			return response.data;
		} catch (e: any) {
			console.error(e);
			return thunkApi.rejectWithValue(e.message);
		}
	}
);

/**
 * Creates new Project Group and adds it to the user
 * @param projectGroup Initial data
 * @returns
 */
export const removeProjectGroup = createAsyncThunk(
	'user/removeProjectGroup',
	async (payload: TRemoveProjectGroup, thunkApi) => {
		const { apiPayload } = payload;
		const user: IUser = getUserFromRedux();
		try {
			const response = await axios.put<AxiosResponseWrapper<IProjectGroup>>(
				`${projectGroupRouter}/remove${qs}`,
				apiPayload
			);

			const snackbarPayload: ISnackBar = {
				text: 'Projects Ungrouped!',
				show: true,
				type: 'NORMAL'
			};

			addSnackbar(snackbarPayload);
			removeSnackbar(2000);
			if (response.status !== 200) return new Error('Error Ungrouping Project Group');
			if (user) await saveUserInLocalForage(user);
			return response.data;
		} catch (e: any) {
			console.error(e);
			return thunkApi.rejectWithValue(e.message);
		}
	}
);

/**
 * Ungroups user's projects
 * @param groupProjectId This is the project group to delete
 * @param projectIds This is the list of projects to remove from the group
 * @returns
 */
export const ungroupProjects =
	(
		dispatch: AppDispatch,
		groupProjectId: string,
		projectIds: string[],
		setShowGroupDetail: (val: boolean) => void
	) =>
	async () => {
		try {
			const projectGroup: IProjectGroup = store.getState().projectGroup.data;
			const user: IUser = getUserFromRedux();
			const { projects: projectsInGroup } = projectGroup;
			const {
				_id: userId,
				projectGroups,
				defaultProjectGroup: defaultProjectGroupId,
				sharedProjects
			} = user;

			let projectsToUngroup: IProject[] = [];

			// If we have a projectid to ungroup, find the project
			if (projectIds.length > 0 && projectsInGroup) {
				const transformedProjects = projectsInGroup.filter((project) =>
					projectIds.includes((project as IProject)._id as string)
				) as IProject[];
				if (transformedProjects) {
					projectsToUngroup = transformedProjects;
				}
			} else {
				// find the project we want to ungroup
				const projectGroupList = await axios.get(
					`${projectGroupRouter}/${groupProjectId}/${user._id}${qs}`
				);

				if (projectGroupList) {
					projectsToUngroup = projectGroupList.data.payload.projects as IProject[];
				}
			}

			const newSharedProjectIds: string[] = [];
			const newSharedProjects: IProject[] = [];
			const newDefaultProjectIds: string[] = [];
			const newDefaultProjects: IProject[] = [];

			(projectsToUngroup as IProject[]).map(async (project: IProject) => {
				// check if project is owned by user
				if (project) {
					// if owned by user, push to default projects list
					if (userId?.toString() === ((project?.createdBy as IUser)?._id as string)) {
						newDefaultProjectIds.push(project?._id as string);
						newDefaultProjects.push(project);
					} else {
						// if not owned by user, push to the shared projects list
						newSharedProjectIds.push(project._id as string);
						newSharedProjects.push(project);
					}
				}
			});

			let filteredGroups: IProjectGroup[] = projectGroups as IProjectGroup[];
			// Check if we need to delete the project group as well
			const isDeleteProjectGroup =
				(projectIds.length > 0 &&
					(projectsInGroup?.length || 0) ===
						newSharedProjectIds.length + newDefaultProjectIds.length) ||
				projectIds.length === 0;

			// if we are not deleting all the projects, we are removing projects from the group
			if (isDeleteProjectGroup) {
				// If we're deleting the project group, filter out the group with the specified ID
				filteredGroups = (projectGroups as IProjectGroup[]).filter(
					(pG) => pG._id !== groupProjectId
				);
				dispatch(deleteGroup());
				setShowGroupDetail(false);
			} else if (projectGroup._id) {
				// Check if it's in the default or shared group
				const projectIdsInDefault = new Set(
					newDefaultProjects.map((project) => project._id)
				);
				const projectIdsInShared = new Set(newSharedProjects.map((project) => project._id));

				const updatedProjects = projectGroup.projects?.filter(
					(project) =>
						!projectIdsInDefault.has((project as IProject)?._id) &&
						!projectIdsInShared.has((project as IProject)?._id)
				);

				// Update the projects array of the project group
				dispatch(updateGroup({ projects: updatedProjects }));
			}

			const apiPayload = {
				userId: userId as string,
				projectGroupId: groupProjectId,
				isDeleteProjectGroup,
				defaultProjectGroupId,
				sharedProjects: newSharedProjectIds,
				defaultProjects: newDefaultProjectIds
			};

			// Dispatch function to remove projects from group
			(store.dispatch as CustomDispatch)(
				removeProjectGroup({
					apiPayload,
					newSharedProjects,
					filteredGroups,
					newDefaultProjects,
					prevState: {
						projectGroups: projectGroups as IProjectGroup[],
						sharedProjects: sharedProjects as IProject[]
					}
				})
			);
		} catch (e) {
			console.error(e);
		}
		return null;
	};

/**
 * Loads ProjectGroupById
 * @param projectGroupId ObjectId | String
 * @param userId ObjectId | String
 * @returns
 */
export const loadProjectGroupById = createAsyncThunk(
	'user/loadProjectGroupById',
	async (payload: TGetProjectGroup, thunkApi) => {
		const { projectGroupId, userId, cancelToken } = payload;
		try {
			const response = await axios.get(
				`${projectGroupRouter}/${projectGroupId}/${userId}${qs}`,
				{
					cancelToken: cancelToken?.token
				}
			);

			if (response.status !== 200) {
				return thunkApi.rejectWithValue(
					`Error fetching Project Group with id ${projectGroupId}`
				);
			}
			return response.data.payload;
		} catch (e: any) {
			console.error(e);
			return thunkApi.rejectWithValue(e.message);
		}
	}
);

/**
 *
 * @param projectGroup Must include the _id in this object
 * @returns
 */
export const editProjectGroup = (projectGroup: IProjectGroup) => async () => {
	try {
		const user: IUser = getUserFromRedux();

		// actual request object
		const projectGroupReq = {
			projectGroupId: projectGroup._id,
			update: {
				...projectGroup
			}
		};

		const response = await axios.put<AxiosResponseWrapper<IProjectGroup>>(
			`${projectGroupRouter}${qs}`,
			{ payload: projectGroupReq }
		);

		if (response.status !== 200) return new Error('Error editing projectGroup');

		const updatedProjectGroup = response.data.payload! as IProjectGroup;

		const oldProjectGroups = user.projectGroups as IProjectGroup[];

		const newProjectGroups = findAndUpdateInArray([updatedProjectGroup, oldProjectGroups]);

		store.dispatch(
			updateUser({
				projectGroups: newProjectGroups
			})
		);
	} catch (e) {
		console.error(e);
	}
	return null;
};

/**
 * Function to check if users with provided emails exists in database
 * @param emails array of emails
 * @returns key value pair of users email and IUser
 */
export const findUsersByEmail = async (emails: string[]): Promise<{ [key: string]: IUser }> => {
	try {
		const response = await axios.post<AxiosResponseWrapper<any>>(`${userRouter}/exists/${qs}`, {
			payload: { emails }
		});
		if (response.status === 200) {
			return response.data.payload;
		}

		return {};
	} catch (e) {
		console.error('Error finding users : ', e);
		return {};
	}
};

/**
 * Function edit user
 */
export const editUser = createAsyncThunk(
	'user/editUser',
	async (payload: TEditUserArgs, thunkApi) => {
		try {
			const { data } = payload;

			const response = await axios.put<AxiosResponseWrapper<IUser>>(`${userRouter}${qs}`, {
				payload: data
			});

			if (response.status !== 200)
				return thunkApi.rejectWithValue(`Error editing user at ${data.userId}`);

			return data.update;
		} catch (e: any) {
			console.error(e);
			return thunkApi.rejectWithValue(e.message);
		}
	}
);

/**
 * Function to edit user details
 */
export const editUserDetails = createAsyncThunk(
	'user/editUserDetails',
	async (payload: TEditUserDetailsArgs, thunkApi) => {
		try {
			const { data } = payload;
			const response = await axios.put<AxiosResponseWrapper<IUserDetailNode>>(
				`${userDetailsRouter}${qs}`,
				{ payload: data }
			);
			if (response.status !== 200)
				return thunkApi.rejectWithValue('Error editing userDetails');
			return response.data;
		} catch (e: any) {
			console.error(e);
			return thunkApi.rejectWithValue(e.message);
		}
	}
);

/**
 * Redux action to edit user preferences optimistically
 */
export const editUserPreferences = createAsyncThunk(
	'user/editUserPreferences',
	async ({ payload }: TEditUserPreferenceThunkArgs, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();

			const apiPayload = {
				userId: user._id,
				update: payload
			};

			const response = await axios.put<AxiosResponseWrapper<IUserPreferenceNode>>(
				`${userPreferenceRouter}/${qs}`,
				{ payload: apiPayload }
			);

			return response.data.payload as IUserPreferenceNode;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);
