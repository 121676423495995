import { FC, ReactElement, useMemo } from 'react';
import './UserList.scss';
import { EUserRole, IUser, TInvitedEmail, TUserAndRole } from '@naya_studio/types';
import { store } from 'src';
import { CustomDispatch } from 'src/redux/actions/types';
import { generateIdsFromUrl, getStageAndBlockIdsOfProject } from 'src/redux/reduxActions/util';
import useProject from 'src/redux/hooks/useProject';
import {
	TEditUserRoleForProjectThunkArg,
	TRemoveUserFromProjectThunkArg,
	TEditUserRoleForStageThunkArg,
	TEditUserRoleInBlockArgs,
	TRemoveUserFromBlockBlockArgs,
	TRemoveUserFromStageThunkArg
} from 'src/types/argTypes';
import { editUserRoleInBlock, removeUserFromBlock } from 'src/redux/reduxActions/block';
import { editUserRoleForProject, removeUserFromProject } from 'src/redux/reduxActions/project';
import { editUserRoleForStage, removeUserFromStage } from 'src/redux/reduxActions/stage';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';
import { ISnackBar } from 'src/redux/reducers/root.types';
import { checkUserAccessLevel } from 'src/util/accessLevel/accessLevelActions';
import { insertDocumentToYDoc } from 'src/redux/hooks/observers/utils';
import useProjectUsersObserver from 'src/redux/hooks/observers/useProjectUsersObserver';
import useUser from 'src/redux/hooks/user';
import { getWebSocket } from 'src/rtc/yjs/yjsConfig';
import { getStageAndChildren } from 'src/redux/actions/util';
import { useFlags } from 'launchdarkly-react-client-sdk';
import UserRow from './userRow/UserRow';
import { UserListProps } from './UserList.types';

/**
 * Component to render all users of the active tab
 */
const UserList: FC<UserListProps> = ({
	activeTab,
	projectId,
	users,
	actionableBlock,
	actionablePhase,
	showInviteNote
}): ReactElement => {
	const { projectId: projectIdUrl } = generateIdsFromUrl();
	const { project } = useProject(projectIdUrl || projectId);
	const webSocket = getWebSocket();
	const { removeUserFromProjectUsersYMap } = useProjectUsersObserver();
	const { user: currentUser } = useUser();

	/**
	 * Function for modifting user role based on active tab and dispatch the action
	 * @param userData Selected user whose role is modified
	 * @param role Updated role
	 */
	const modifyUserRole = (userData: TUserAndRole, role: EUserRole) => {
		try {
			const snackbarPayload: ISnackBar = {
				text: 'Failed to modify role of user.',
				show: true,
				type: 'ERROR'
			};

			const userId =
				((userData.user as IUser)._id as string) ||
				((userData.user as IUser).email as string);

			switch (activeTab) {
				case 'PROJECT': {
					const { blockIds, stageIds } = getStageAndBlockIdsOfProject(
						project?._id as string
					);
					const userRole =
						project?.users &&
						(project?.users as TUserAndRole[]).find((u) => u.user === userId);
					const invitedUser =
						project?.invitedEmails &&
						(project?.invitedEmails as TInvitedEmail[]).find((u) => u.email === userId);

					const apiPayload: TEditUserRoleForProjectThunkArg = {
						payload: {
							userId,
							projectId: project?._id as string,
							role: role as keyof typeof EUserRole,
							invitedUser
						},
						blockIds,
						stageIds,
						prevRole: invitedUser ? invitedUser.role : userRole!.role
					};

					(store.dispatch as CustomDispatch)(editUserRoleForProject(apiPayload))
						.unwrap()
						.then(() => {})
						.catch((error) => {
							addSnackbar(snackbarPayload);
							removeSnackbar(2000);

							throw new Error(error);
						});
					break;
				}
				case 'PHASE': {
					const { stages, blocks } = getStageAndChildren(
						actionablePhase._id as string,
						'NORMALIZED'
					);
					const blockIds: string[] = [];
					const stageIds: string[] = [];
					blocks.forEach((block) => blockIds.push(block._id as string));
					stages.forEach((stage) => stageIds.push(stage._id as string));
					const userRole =
						actionablePhase?.users &&
						(actionablePhase?.users as TUserAndRole[]).find(
							(u) => (u.user as string) === userId
						);
					const invitedUser =
						actionablePhase?.invitedEmails &&
						(actionablePhase?.invitedEmails as TInvitedEmail[]).find(
							(u) => u.email === userId
						);

					const apiPayload: TEditUserRoleForStageThunkArg = {
						payload: {
							userId,
							stageIds,
							role: role as keyof typeof EUserRole,
							invitedUser
						},
						blockIds,
						prevRole: invitedUser ? invitedUser.role : userRole!.role
					};
					(store.dispatch as CustomDispatch)(editUserRoleForStage(apiPayload))
						.unwrap()
						.then(() => {})
						.catch((error) => {
							addSnackbar(snackbarPayload);
							removeSnackbar(2000);

							throw new Error(error);
						});
					break;
				}
				case 'BLOCK': {
					const userRole =
						actionableBlock?.users &&
						(actionableBlock?.users as TUserAndRole[]).find(
							(u) => (u.user as string) === userId
						);
					const invitedUser =
						actionableBlock?.invitedEmails &&
						(actionableBlock?.invitedEmails as TInvitedEmail[]).find(
							(u) => u.email === userId
						);

					const apiPayload: TEditUserRoleInBlockArgs = {
						data: {
							blockId: actionableBlock._id as string,
							userId,
							role: role as keyof typeof EUserRole,
							invitedUser
						},
						prevRole: invitedUser ? invitedUser.role : userRole!.role
					};
					(store.dispatch as CustomDispatch)(editUserRoleInBlock(apiPayload))
						.unwrap()
						.then(() => {})
						.catch((error) => {
							addSnackbar(snackbarPayload);
							removeSnackbar(2000);

							throw new Error(error);
						});
					break;
				}
				default:
					break;
			}
		} catch (error) {
			console.error('Error modifying user role: ', error);
		}
	};

	/**
	 * Function to check if a user exists on another level of project
	 * @param userId id of the user to check
	 * @param ignoreRecord record to ignore while checking
	 * @returns boolean
	 */
	const checkIfUserInvitedOnOtherLevel = (userId: string, ignoreRecord: string) => {
		const {
			projects: reduxProjects,
			stages: reduxGroups,
			blocks: reduxBlocks
		} = store.getState();

		const activeProject = reduxProjects.data[projectId];
		if (activeProject) {
			const foundInProject = activeProject.users?.find(
				(u) => ((u as TUserAndRole).user as string) === userId
			);
			if (foundInProject) return true;
		}

		const groups = Object.values(reduxGroups.data);
		if (groups) {
			for (let i = 0; i < groups.length; i++) {
				const group = groups[i];

				if (
					group &&
					group.projectId === projectId &&
					(group._id as string) !== ignoreRecord
				) {
					const foundInGroup = group.users?.find(
						(u) => ((u as TUserAndRole).user as string) === userId
					);
					if (foundInGroup) return true;
				}
			}
		}

		const blocks = Object.values(reduxBlocks.data);
		if (blocks) {
			for (let i = 0; i < blocks.length; i++) {
				const block = blocks[i];

				if (
					block &&
					block.projectId === projectId &&
					(block._id as string) !== ignoreRecord &&
					block.parentId !== ignoreRecord
				) {
					const foundInBlock = block.users?.find(
						(u) => ((u as TUserAndRole).user as string) === userId
					);
					if (foundInBlock) return true;
				}
			}
		}

		return false;
	};

	/**
	 * Function to remove user based on active tab and dispatch the action
	 * @param data User to remove
	 */
	const removeUser = async (data: TUserAndRole) => {
		try {
			const snackbarPayload: ISnackBar = {
				text: 'Failed to remove user.',
				show: true,
				type: 'ERROR'
			};

			const userId =
				((data.user as IUser)._id as string) || ((data.user as IUser).email as string);

			switch (activeTab) {
				case 'PROJECT': {
					const { blockIds, stageIds } = getStageAndBlockIdsOfProject(
						project?._id as string
					);
					const userRole =
						project?.users &&
						(project?.users as TUserAndRole[]).find(
							(u) => (u.user as string) === userId
						);
					const invitedUserRole =
						project?.invitedEmails &&
						(project?.invitedEmails as TInvitedEmail[]).find((u) => u.email === userId);

					const apiPayload: TRemoveUserFromProjectThunkArg = {
						payload: {
							userId,
							projectId: project?._id as string,
							userRole,
							invitedUserRole
						},
						blockIds,
						stageIds,
						prevState: {
							prevProjectUsers: store.getState().projectUsers.data
						}
					};
					(store.dispatch as CustomDispatch)(removeUserFromProject(apiPayload))
						.unwrap()
						.then(() => {
							if (webSocket?.wsconnected) removeUserFromProjectUsersYMap(userId);
						})
						.catch((error) => {
							addSnackbar(snackbarPayload);
							removeSnackbar(2000);

							throw new Error(error);
						});
					break;
				}
				case 'PHASE': {
					const existOnOtherLevels = checkIfUserInvitedOnOtherLevel(
						userId,
						actionablePhase._id as string
					);
					const userRole =
						actionablePhase?.users &&
						(actionablePhase?.users as TUserAndRole[]).find(
							(u) => (u.user as string) === userId
						);
					const invitedUserRole =
						actionablePhase?.invitedEmails &&
						(actionablePhase?.invitedEmails as TInvitedEmail[]).find(
							(u) => u.email === userId
						);
					const { stages, blocks } = getStageAndChildren(
						actionablePhase._id as string,
						'NORMALIZED'
					);
					const blockIdsInvite: string[] = [];
					const stageIdsInvite: string[] = [];
					blocks.forEach((block) => blockIdsInvite.push(block._id as string));
					stages.forEach((stage) => stageIdsInvite.push(stage._id as string));

					const apiPayload: TRemoveUserFromStageThunkArg = {
						payload: {
							projectId,
							userId,
							stageIds: stageIdsInvite,
							userRole,
							invitedUserRole,
							existOnOtherLevels
						},
						blockIds: blockIdsInvite
					};

					// Fix for removing user from a new stage which does not exist in ydoc
					if (webSocket?.wsconnected)
						insertDocumentToYDoc('STAGE', actionablePhase._id as string);

					(store.dispatch as CustomDispatch)(removeUserFromStage(apiPayload))
						.unwrap()
						.then(() => {
							if (webSocket?.wsconnected && !existOnOtherLevels)
								removeUserFromProjectUsersYMap(userId);
						})
						.catch((error) => {
							addSnackbar(snackbarPayload);
							removeSnackbar(2000);

							throw new Error(error);
						});
					break;
				}
				case 'BLOCK': {
					const existOnOtherLevels = checkIfUserInvitedOnOtherLevel(
						userId,
						actionableBlock._id as string
					);
					const userRole =
						actionableBlock?.users &&
						(actionableBlock?.users as TUserAndRole[]).find(
							(u) => (u.user as string) === userId
						);
					const invitedUserRole =
						actionableBlock?.invitedEmails &&
						(actionableBlock?.invitedEmails as TInvitedEmail[]).find(
							(u) => u.email === userId
						);

					// generating payload for remove user from block
					const apiPayload: TRemoveUserFromBlockBlockArgs = {
						data: {
							userId,
							blockId: actionableBlock._id as string,
							stageId: actionablePhase._id as string,
							projectId,
							userRole,
							invitedUserRole,
							existOnOtherLevels
						}
					};

					// Fix for removing user from a new block which does not exist in ydoc
					if (webSocket?.wsconnected)
						insertDocumentToYDoc('BLOCK', actionableBlock._id as string);

					// dispatch remove user from block
					(store.dispatch as CustomDispatch)(removeUserFromBlock(apiPayload))
						.unwrap()
						.then(() => {
							if (webSocket?.wsconnected && !existOnOtherLevels)
								removeUserFromProjectUsersYMap(userId);
						})
						.catch((error) => {
							addSnackbar(snackbarPayload);
							removeSnackbar(2000);

							throw new Error(error);
						});
					break;
				}

				default:
					break;
			}
		} catch (error) {
			console.error('Error removing user: ', error);
		}
	};

	/**
	 * Callback on role change/remove from UserRow Dropdown
	 */
	const onEditRole = (type: 'CHANGE_ROLE' | 'REMOVE', user: TUserAndRole, role?: EUserRole) => {
		switch (type) {
			case 'CHANGE_ROLE':
				modifyUserRole(user, role as EUserRole);
				break;
			case 'REMOVE':
				removeUser(user);
				break;
			default:
				break;
		}
	};

	/**
	 * Memoized boolean value indicating whether the user row should be disabled.
	 * It checks the access level of the current user against the specified roles.
	 *
	 * @type {boolean}
	 * @example
	 * // Usage:
	 * const disabled = isUserRowDisabled;
	 */
	const isUserRowDisabled = useMemo(
		() => !checkUserAccessLevel(users, currentUser._id as string, ['EDITOR', 'OWNER']),
		[users]
	);

	const { isInviteEnabled } = useFlags();

	return (
		<div
			data-testid="share-modal-user-list"
			className="tw-overflow-auto tw-gap-4 tw-flex tw-flex-col tw-px-6"
			style={{ maxHeight: showInviteNote ? 140 : 170, scrollBehavior: 'smooth' }}
		>
			{users &&
				users
					.filter((data: TUserAndRole) => data.status !== 'PENDING')
					.map((data: TUserAndRole) => (
						<UserRow
							userData={data}
							onEditRole={onEditRole}
							disabled={isUserRowDisabled || !isInviteEnabled}
						/>
					))}
		</div>
	);
};

export default UserList;
