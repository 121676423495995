import axios from 'axios';
import { textToImageRouter } from '../../endpoints/projects-api';
import {
  AxiosResponseWrapper, ThunkDispatchWrapper,
} from './types';
import { ReduxState, TextToImageActionTypes } from '../reducers/root.types';
import { getGatewayKey } from '../../util/helper/queryString';
import { IPredictionData } from '../reducers/textToImage/textToImage';

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
const qs = `?${getGatewayKey()}`;

/**
 *
 * @param payload Create prediction using text to image tool
 * @returns
 */
export const createPrediction = (payload: { prompt: string }) => async (dispatch: ThunkDispatchWrapper) => {
  try {
    // create stage first
    const response = await axios.post<AxiosResponseWrapper<IPredictionData>>(`${textToImageRouter}${qs}`, {
      prompt: payload.prompt,
    });
    // need to handle errror
    if (response.status !== 201) throw new Error('Error creating the prediction');

    const { uuid, status } = response.data as any;
    dispatch({
      type: TextToImageActionTypes.CREATE_PREDICTION,
      payload: {
        prompt: payload.prompt,
        status,
        uuid,
      },
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: TextToImageActionTypes.CREATE_PREDICTION,
      payload: {
        prompt: payload.prompt,
        status: 'failed',
        uuid: '',
        error: 'Unable to create images',
      },
    });
  }
};

export const getPrediction = () => async (dispatch: ThunkDispatchWrapper, getState: () => ReduxState) => {
  try {
    const getPredictionInterval = setInterval(async () => {
      const currentState = getState();
      const { uuid, status: localStatus } = currentState.brainTool;
      if (localStatus === '' || uuid === '') {
        clearInterval(getPredictionInterval);
        return;
      }
      const response = await axios.get(`${textToImageRouter}/${uuid}${qs}`)
        .catch(() => {
          clearInterval(getPredictionInterval);
          dispatch({
            type: TextToImageActionTypes.EDIT_PREDICTION,
            payload: {
              status: 'failed',
              error: 'Unable to create images',
              output: [],
            },
          });
          throw new Error('Unalbe to create images');
        });
      if (response.status !== 200) {
        clearInterval(getPredictionInterval);
        dispatch({
          type: TextToImageActionTypes.EDIT_PREDICTION,
          payload: {
            status: 'failed',
            error: 'Unable to create images',
            output: [],
          },
        });
      }
      const { imageURLs, status, error } = response.data as any;
      if (status === 'succeeded') {
        clearInterval(getPredictionInterval);
        dispatch({
          type: TextToImageActionTypes.EDIT_PREDICTION,
          payload: {
            status,
            output: imageURLs,
            error,
          },
        });
      }
    }, 1500);
  } catch (e) {
    console.log(e);
    dispatch({
      type: TextToImageActionTypes.EDIT_PREDICTION,
      payload: {
        status: 'failed',
        error: 'Unable to create images',
        output: [],
      },
    });
  }
};

export const cancelPrediction = () => async (dispatch: ThunkDispatchWrapper, getState: () => ReduxState) => {
  try {
    dispatch({
      type: TextToImageActionTypes.EDIT_PREDICTION,
      payload: {
        status: '',
        uuid: '',
        output: [],
        error: '',
        prompt: '',
      },
    });
    const initialState = getState();
    const { uuid } = initialState.brainTool;
    if (uuid !== '') {
      const response = await axios.post(`${textToImageRouter}/cancel/${uuid}${qs}`);
      if (response.status !== 200) {
        throw new Error('Error canceling the prediction');
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export const toggleTTISnackBar = (showSnackBar: boolean) => async (dispatch: ThunkDispatchWrapper) => {
  dispatch({
    type: TextToImageActionTypes.EDIT_PREDICTION,
    payload: {
      showSnackBar,
    },
  });
};
