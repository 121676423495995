import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { INewCommentInitState } from "../reducers/root.types";
import { EFeedbackType, IFeedback } from "@naya_studio/types";

const initialState: INewCommentInitState = {
  data: {
    initialComment: null,
    initialType: null
  },
  loading: false,
  error: null
};

/**
 * Reducer for Project Users
 */
const newCommentSlice = createSlice({
  name: 'newComment',
  initialState,
  reducers: {
    addNewCommentData: (state: INewCommentInitState, action: PayloadAction<Partial<IFeedback>>) => {
      const { payload } = action;
      
      if(payload) {
        state.data.initialComment = payload;
        state.data.initialType = payload.feedbackType as 'CHAT' | 'POLL';
      }
    },
    addNewCommentType: (state: INewCommentInitState, action: PayloadAction<keyof typeof EFeedbackType | undefined>) => {
      const { payload } = action;

      if (payload) {
        state.data.initialType = payload;
      }
    },
    resetNewComment: (state: INewCommentInitState) => {
      state.data.initialType = null;
      state.data.initialComment = null;
    },
    resetNewCommentData: (state: INewCommentInitState) => {
      state.data.initialComment = null;
    },
  }
});

export const { 
  addNewCommentData, 
  addNewCommentType, 
  resetNewComment, 
  resetNewCommentData 
} = newCommentSlice.actions;

export default newCommentSlice.reducer;
