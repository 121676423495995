import { getGatewayKey } from 'src/util/helper/queryString';

let uploadEndpoint: string;
let pdfToImageEndpoint: string;
let removeBackgroundEndpoint: string;
let pdfThumbnailEndpoint: string;

if (window.__RUNTIME_CONFIG__.REACT_APP_STAGING === 'true' || window.__RUNTIME_CONFIG__.REACT_APP_UPLOAD_ENDPOINTS_STAGING === 'true') {
  uploadEndpoint = 'https://uploads-api-staging-2ba5cho72q-ue.a.run.app/upload';
  pdfToImageEndpoint = `https://image-processing-api-staging-2ba5cho72q-ue.a.run.app/pdfToImage?${getGatewayKey()}`;
  removeBackgroundEndpoint = `https://image-processing-api-staging-2ba5cho72q-ue.a.run.app/removeBg?${getGatewayKey()}`;
  pdfThumbnailEndpoint = `https://image-processing-api-staging-2ba5cho72q-ue.a.run.app/pdfThumbnail?${getGatewayKey()}`;
} else if (window.__RUNTIME_CONFIG__.REACT_APP_STAGING_CUSTOM === 'true') {
  uploadEndpoint = 'https://uploads-api-staging-2ba5cho72q-ue.a.run.app/upload';
  pdfToImageEndpoint = `${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_PROCESSING_API_URL}/pdfToImage?${getGatewayKey()}` || `https://staging-api.naya-app.com/pdfToImage?${getGatewayKey()}`;
  removeBackgroundEndpoint = `${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_PROCESSING_API_URL}/removeBg?${getGatewayKey()}` || `https://staging-api.naya-app.com/removeBg?${getGatewayKey()}`;
  pdfThumbnailEndpoint = `${window.__RUNTIME_CONFIG__.REACT_APP_IMAGE_PROCESSING_API_URL}/pdfThumbnail?${getGatewayKey()}` || `https://staging-api.naya-app.com/pdfThumbnail?${getGatewayKey()}`;
} else if (process.env.REACT_APP_PRODUCTION) {
  uploadEndpoint = 'https://naya-uploads-production-ziylmcndpq-ue.a.run.app/upload';
  pdfToImageEndpoint = `https://image-processing-api-production-awjyehnnha-ue.a.run.app/pdfToImage?${getGatewayKey()}`;
  removeBackgroundEndpoint = `https://image-processing-api-production-awjyehnnha-ue.a.run.app/removeBg?${getGatewayKey()}`;
  pdfThumbnailEndpoint = `https://image-processing-api-production-awjyehnnha-ue.a.run.app/pdfThumbnail?${getGatewayKey()}`;
} else {
  uploadEndpoint = 'http://localhost:3016/upload';
  pdfToImageEndpoint = 'http://localhost:3013/pdfToImage';
  removeBackgroundEndpoint = 'http://localhost:3013/removeBg';
  pdfThumbnailEndpoint = 'http://localhost:3013/pdfThumbnail';
}

export const UPLOAD_ENDPOINT = uploadEndpoint;
export const PDF_TO_IMAGE_ENDPOINT = pdfToImageEndpoint;
export const REMOVE_BACKGROUND_ENDPOINT = removeBackgroundEndpoint;
export const PDF_THUMBNAIL_ENDPOINT = pdfThumbnailEndpoint;
