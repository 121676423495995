import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { editUser } from 'src/redux/actions/user';
import { ReduxState } from 'src/redux/reducers/root.types';
import { TRenewAccessTokenArgs } from 'src/types/argTypes';
import { saveUserInLocalForage } from 'src/util/storage/indexedDBStorage';

/**
 * Function to renew google access token
 */
export const renewGoogleAccessToken = createAsyncThunk(
	'integrations/refresh-google-token',
	async (payload: TRenewAccessTokenArgs, thunkApi) => {
		const { googleToken } = payload;
		try {
			const refreshTokenLink =
				`${window.__RUNTIME_CONFIG__.REACT_APP_NAYA_PROXY}` +
				`/https://www.googleapis.com/oauth2/v4/token?access_type=offline`;

			const response = await axios.post(refreshTokenLink, {
				client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
				client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
				refresh_token: googleToken,
				grant_type: 'refresh_token',
				access_type: 'offline'
			});

			if (response.status !== 200)
				throw new Error(`Error while refreshing google auth token`);

			const { data } = response;
			return {
				tokenData: {
					access_token: data.access_token,
					expires_in: data.expires_in,
					createdAt: Date.now()
				}
			};
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			return thunkApi.rejectWithValue(`Error while refreshing google auth token`);
		}
	}
);

/**
 * Function to renew miro access token
 */
export const renewMiroAccessToken = createAsyncThunk(
	'integrations/refresh-miro-token',
	async (payload: TRenewAccessTokenArgs, thunkApi) => {
		const { miroToken } = payload;
		const user = (thunkApi.getState() as ReduxState).user.data;
		try {
			const refreshTokenLink =
				`${window.__RUNTIME_CONFIG__.REACT_APP_NAYA_PROXY}/https://api.miro.com/v1/oauth/token` +
				`?grant_type=refresh_token` +
				`&client_id=${process.env.REACT_APP_MIRO_OAUTH_CLIENT_ID}` +
				`&client_secret=${process.env.REACT_APP_MIRO_OAUTH_CLIENT_SECRET}` +
				`&refresh_token=${miroToken}`;

			const response = await axios.post(refreshTokenLink);

			if (response.status !== 200) throw new Error(`Error while refreshing miro auth token`);

			const { data } = response;

			thunkApi.dispatch(
				editUser({
					data: {
						userId: user._id as string,
						update: { miroToken: data.refresh_token }
					},
					prevState: user
				})
			);
			await saveUserInLocalForage({ ...user, miroToken: data.refresh_token });

			return {
				tokenData: {
					access_token: data.access_token,
					expires_in: data.expires_in,
					createdAt: Date.now()
				}
			};
		} catch (error) {
			thunkApi.dispatch(
				editUser({
					data: {
						userId: user._id as string,
						update: { miroToken: null }
					},
					prevState: user
				})
			);
			await saveUserInLocalForage({ ...user, miroToken: null });
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			return thunkApi.rejectWithValue(`Error while refreshing miro auth token`);
		}
	}
);
