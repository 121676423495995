import { IBlock, INote, IGroup, IUser, TUserAndRole } from '@naya_studio/types';
import Fuse from 'fuse.js';
import { fetchBlockByIdFromRedux } from 'src/redux/actions/util';
import {
	TSearchQuery,
	TCombinedBlock
} from '../../components/collaborationTool/CollaborationTool.types';

type LastUpdateDataType = {
	id: string;
	phaseIndex: number;
	lastUpdated: Date;
};

type TPhase = Partial<IGroup>;
type TBlock = Partial<IBlock>;
type TBlocks = { [key: string]: TBlock };

/**
 * Check if a given block matches specified search criteria.
 *
 * @param {string[]} searchCriteriaKeys - An array of keys to search within the block.
 * @param {SearchQueryT} query - The search query object containing filter criteria.
 * @param {RegExp | undefined} regex - Regular expression to match against certain keys.
 * @param {TBlock} block - The block object to compare with the search criteria.
 * @returns { isMatched: boolean, score?: number } isMatched True if the block matches all specified criteria, otherwise false.
 * Score - match result score
 */
const checkIfTheBlockMatches = (
	searchQueryKeys: string[],
	query: TSearchQuery,
	// fUse: Fuse<string | undefined>,
	block: TCombinedBlock,
	parentHasDate: boolean
): { isMatched: boolean; score?: number } => {
	/**
	 * Fuzzy search
	 * eg: query: 'Block' will match blockName: 'blck'
	 */
	const fuzzy =
		query.text &&
		new Fuse([block.name], { threshold: 0.3, includeScore: true }).search(query.text);

	let notesText = '';

	// Check if block.notes is an array and not empty
	if (block.notes && block.notes.length > 0 && query.text) {
		// Concatenate the text from all notes with a space separator
		notesText = (block.notes as INote[]).map((eachNote) => eachNote?.text).join(' ');
	}
	const notesfuzzy =
		block.notes &&
		query.text &&
		new Fuse([notesText.toString()], { threshold: 0.3, includeScore: true }).search(query.text);

	const isMatched = searchQueryKeys.every((key) => {
		switch (key) {
			case 'text': {
				return (
					query.text &&
					((fuzzy && fuzzy.length > 0) || (notesfuzzy && notesfuzzy.length > 0))
				);
			}
			case 'types': {
				let isFound = false;
				// check if we have the block type or favorite as a type
				if (
					query.types?.length &&
					query.types?.length === 1 &&
					query.types.includes('FAVORITE')
				) {
					isFound = block.isFavorite;
				} else if (
					query.types?.length &&
					query.types?.length === 1 &&
					query.types.includes('AI_GENERATED')
				) {
					isFound = block.isGeneratedByAI;
				} else {
					isFound = (query.types?.length &&
						block.blockType &&
						query.types.indexOf(block.blockType) !== -1) as boolean;
					if (!isFound) {
						// if not matched with the block type, then check the subtype
						// eg: subType of LINK block consists of 'FIGMA', 'NOTION', 'DRIVE', etc...
						isFound = (query.types?.length &&
							(block as TCombinedBlock).subType &&
							query.types.indexOf((block as TCombinedBlock).subType) !==
								-1) as boolean;
					}
					if (!isFound) {
						// If not matched with any blocks based on type and subtypes, check if filtered by gen ai
						isFound = (query.types?.length &&
							query.types?.includes('AI_GENERATED') &&
							block.isGeneratedByAI) as boolean;
					}

					// if we have to get favorite blocks as well, then return only blocks
					// that meet requirement and are favorites
					// Doing this separately because favourite is considered as AND operation
					// even though it exist in same filters row)
					if (isFound && query.types?.includes('FAVORITE')) {
						isFound = block.isFavorite;
					}
				}

				return isFound;
			}
			case 'stickyNotes': {
				let isFound = false;
				// check if at least one of the notes are the same color as the one on the search
				if (block.notes && block.notes.length > 0) {
					isFound = (block.notes as INote[]).some((note) =>
						query.stickyNotes?.includes(note?.color)
					);
				}
				return isFound;
			}
			case 'sharedWith': {
				return (
					query.sharedWith &&
					block?.users &&
					!!block.users.find((user) =>
						typeof (user as TUserAndRole).user === 'string'
							? ((user as TUserAndRole).user as string)
							: (((user as TUserAndRole).user as IUser)._id as string) ===
							  (query.sharedWith as Partial<IUser>)._id
					)
				);
			}
			case 'createdBy': {
				return query.createdBy && block.createdBy === query[key]?._id;
			}
			case 'dueDate':
			case 'timelineView': {
				return Boolean(block.dates) || parentHasDate;
			}
			// if lastedUpdated is key return true
			// last updated filter should be applied only if the block passes all the filters
			// So this case is handled later below
			default:
				return true;
		}
	});
	if (fuzzy) {
		return { isMatched, score: fuzzy[0]?.score };
	}

	return { isMatched };
};

/**
 * Factory function to create a block filtering function based on last updated.
 *
 * @returns {(blockMatchResult: boolean, tempPhase: TPhase, block: TBlock, filteredBlocks: TBlocks) => void}
 *   A filtering function that filter blocks based on last updated criteria.
 */
const filterBlocksByLastUpdate = () => {
	/**
	 * Data structure to track the last updated block(s).
	 * If type filter is present in the query:
	 * {
	 *   'TYPE': {
	 *     id: string; // Block id,
	 *     index: number; // Block index,
	 *     phaseIndex: number; // Index of the phase that block belongs to,
	 *     lastUpdated: Date; // Last updated among the type ('FILE', 'PDF', etc...)
	 *   }
	 * }
	 * else:
	 *   'BLOCK': {
	 *     id: string; // Block id,
	 *     index: number; // Block index,
	 *     phaseIndex: number; // Index of the phase that block belongs to,
	 *     lastUpdated: Date; // Last updated among all the blocks found
	 *   }
	 */
	const lastUpdatedData: { [key: string]: LastUpdateDataType } = {};

	return (
		query: TSearchQuery,
		block: TBlock,
		phaseIndex: number,
		tempPhase: TPhase,
		filteredPhases: TPhase[],
		filteredBlocks: TBlocks
	) => {
		const blockId = block._id as string;
		let blockToStore: TBlock | undefined;
		let lastUpdated: LastUpdateDataType | undefined = query.types
			? lastUpdatedData[block.blockType as string]
			: lastUpdatedData.block;
		// During the initial run, lastUpdatedData won't have any data, so we are assuming
		// the first block/block.type is the last updated one and storing it in lastUpdatedData
		if (!lastUpdated) {
			lastUpdated = {
				id: blockId,
				phaseIndex,
				lastUpdated: block.updatedAt as Date
			};
			if (tempPhase.children) tempPhase.children.push(blockId);
			blockToStore = block;
		} else if (new Date(lastUpdated.lastUpdated) < new Date(block.updatedAt as Date)) {
			// If the current block is latest than the block we found previously

			// If the previous thought block is in last phase/other phase
			// Remove that block from that phase's block list
			if (lastUpdated.phaseIndex !== phaseIndex && filteredPhases) {
				if (filteredPhases[lastUpdated.phaseIndex]) {
					const blockIndex = filteredPhases[lastUpdated.phaseIndex]?.children?.findIndex(
						(id) => id === lastUpdated!.id
					);
					filteredPhases[lastUpdated.phaseIndex]?.children?.splice(
						blockIndex as number,
						1
					);
				}
			} else {
				// If the previous thought block is in the same phase
				// Remove that block from the current phase blocks list
				const blockIndex = tempPhase?.children?.findIndex((id) => id === lastUpdated!.id);
				tempPhase?.children?.splice(blockIndex as number, 1);
			}
			// Delete the previous thought block from filteredBlocks
			delete filteredBlocks[lastUpdated.id];
			// Insert the latest block
			blockToStore = block;
			// Push the block id to phase's blocks list
			tempPhase?.children?.push(blockId);
			// Update the lastupdated data
			lastUpdated = {
				id: block._id as string,
				phaseIndex,
				lastUpdated: block.updatedAt as Date
			};
		}
		// Insert to filteredBlocks obj
		if (blockToStore) {
			filteredBlocks[blockId] = blockToStore;
		}
		// Update the stored last updated data
		if (lastUpdated) {
			if (query.types) {
				lastUpdatedData[block.blockType!] = lastUpdated;
			} else {
				lastUpdatedData.block = lastUpdated;
			}
		}
	};
};

/**
 * Factory function to create a block sorting function based on name matching.
 * If a name matches both the phase and the blocks inside it, move the matched block up.
 *
 * @returns {(blockMatchResult: boolean, tempPhase: TPhase, block: TBlock, filteredBlocks: TBlocks) => void}
 *   A sorting function that sorts blocks based on name matching criteria.
 */
const sortBlocksByNameMatched = () => {
	let sortIndex: number = 0;
	let score = Infinity;
	return (
		blockMatchResult: { isMatched: boolean; score?: number },
		tempPhase: TPhase,
		block: TBlock,
		filteredBlocks: TBlocks
	) => {
		const blockId = block._id;
		// If both phase name and block name matches with the entered string.
		// Move the matched block up in the block list
		if (
			blockMatchResult.isMatched &&
			typeof blockMatchResult.score === 'number' &&
			blockMatchResult.score <= score
		) {
			tempPhase?.children?.unshift(blockId as string);
			score = blockMatchResult.score;
			sortIndex++;
		} else if (
			blockMatchResult.isMatched &&
			blockMatchResult.score &&
			blockMatchResult.score > score
		) {
			tempPhase?.children?.splice(sortIndex, 0, blockId as string);
			sortIndex++;
		} else {
			tempPhase?.children?.push(blockId as string);
		}
		// Store the block in filteredBlocks
		filteredBlocks[blockId as string] = block;
	};
};

/**
 * Function to check if record is created by filtered user
 * @param query applied query
 * @param createdBy creator of the record
 */
const checkIfCreatedByUser = (query: TSearchQuery, createdBy?: string) =>
	query?.createdBy
		? Boolean(query?.createdBy && createdBy?.toString() === query?.createdBy?._id?.toString())
		: Boolean(query?.sharedWith);

/**
 * Function to check if record is shared with filtered user
 * @param query applied query
 * @param createdBy users of the record
 */
const checkIfSharedWithUser = (query: TSearchQuery, sharedWith?: Array<TUserAndRole>) =>
	query?.sharedWith
		? Boolean(
				query?.sharedWith &&
					sharedWith?.some(
						(user) => user.user?.toString() === query?.sharedWith?._id?.toString()
					)
		  )
		: Boolean(query?.createdBy);

/**
 * Filter phases and blocks based on the given query.
 * @param {SearchQueryT} query - Search query object.
 * @param {PhaseT[]} phases - Phases to filter.
 * @param {BlocksT} blocks - Blocks to filter.
 * @return {{ phases: PhaseT[], blocks: TBlocks }} - Filtered phases and blocks.
 */
const filterPhaseAndBlocks = (query: TSearchQuery, phases: TPhase[], blocks: TBlocks) => {
	try {
		// Group indice object
		const groupIndices: { [key: string]: number } = {};
		// Stores the filtered phases
		const filteredPhases: TPhase[] = [];
		// Holds Filtered blocks
		const filteredBlocks: TBlocks = {};
		// Keys of the query obj
		const searchQueryKeys = Object.keys(query);
		// Function to filter blocks by last updated
		const filterByLastUpdate = filterBlocksByLastUpdate();
		/**
		 * {phaseId: score}
		 * Used to sort the phases based on the block and phase name match
		 */
		const phaseMatchScore: { [key: string]: number } = {};

		// Allow empty groups in search result if user has applied created by or shared with filters or timeline view
		const shouldConsiderEmptyGroups = Boolean(
			query?.sharedWith ||
				query?.createdBy ||
				(query?.timelineView && Object.keys(query).length === 1)
		);

		// Iterate over all the phases
		phases.forEach((phase, phaseIndex) => {
			// Check if the phase name is matched with the text search
			/**
			 * Fuzzy search
			 * eg: text: 'phse' will match with phaseName: 'phase', 'pase', 'pse'
			 */
			let notesText = '';
			let noteFound = false;
			// Check if block.notes is an array and not empty
			if (phase.notes && phase.notes.length > 0 && query.text) {
				// Concatenate the text from all notes with a space separator
				notesText = (phase.notes as INote[]).map((eachNote) => eachNote?.text).join(' ');
			}
			const notesfuzzy =
				phase.notes &&
				query.text &&
				new Fuse([notesText.toString()], { threshold: 0.3, includeScore: true }).search(
					query.text
				);
			const phaseSearch =
				phase.name &&
				query.text &&
				Object.keys(query).length === 1 &&
				new Fuse([phase.name], { threshold: 0.3, includeScore: true }).search(query.text);

			if (
				((phaseSearch && phaseSearch.length === 0) || !phaseSearch) &&
				(notesfuzzy?.length ||
					(phase.notes as INote[]).some((note) =>
						query.stickyNotes?.includes(note?.color)
					))
			) {
				phaseMatchScore[phase._id as string] = 1;
				noteFound = true;
			}

			// Holds current phase data
			const tempPhase: TPhase = { ...phase, children: [], color: phase.color || '#F5F5F5' };
			// Function to sort the blocks by phase name
			const sortTheBlocks = sortBlocksByNameMatched();
			// Iterate over the blocks
			phase?.children?.forEach((blockId) => {
				const block = blocks[blockId as string] as TCombinedBlock;
				// Fetch block from redux
				const reduxBlock = fetchBlockByIdFromRedux(blockId as string);

				// If block from arg and from redux exists consider it
				// To prevent deleted block still being displayed in search
				if (block && reduxBlock && reduxBlock.isVisible) {
					// Holds true, if a block passed all the filter queries except lastupdated
					// .every basically performs AND operation
					const blockMatchResult = checkIfTheBlockMatches(
						searchQueryKeys,
						query,
						block,
						Boolean(phase.dates)
					);
					// If the block is matched, now filter by lasted(lastupdated) one
					if (blockMatchResult.isMatched && query.lastUpdated) {
						filterByLastUpdate(
							query,
							block,
							phaseIndex,
							tempPhase,
							filteredPhases,
							filteredBlocks
						);
					} else if (
						(phaseSearch && phaseSearch.length > 0) ||
						blockMatchResult.isMatched ||
						noteFound
					) {
						// sort the blocks based on the match score
						sortTheBlocks(blockMatchResult, tempPhase, block, filteredBlocks);
					}
					// Giving score based on block and phase match to use it while sorting phases
					if (
						typeof blockMatchResult.score === 'number' &&
						blockMatchResult.score === 0
					) {
						// If a block match score is 0, it means the match is 100%, so we assign a score of 2. During the sorting phase,
						// those blocks with a score of 2 will be shown first.
						phaseMatchScore[phase._id as string] = 2;
					} else if (
						phaseSearch &&
						typeof phaseSearch[0]?.score === 'number' &&
						phaseSearch[0]?.score === 0
					) {
						// If none of the blocks match and phase is matched then giving score of
						if (!phaseMatchScore[phase._id as string]) {
							phaseMatchScore[phase._id as string] = 1;
						}
					}
				}
			});
			// Store the filtered phase data
			filteredPhases.push(tempPhase);
			groupIndices[tempPhase._id as string] = phaseIndex;
		});

		// Stores sorted phases
		const sortedPhase: TPhase[] = [];
		let blockMatchedIndex = 0;
		let phaseMatchedIndex = 0;

		// Iterate over all filtered groups
		filteredPhases.forEach((phase) => {
			// Check if group is nested
			const isNested = phase.parentId !== phase.projectId;
			// If created by filter is applied, prevent empty groups from being displayed if user is not creator of them
			const isCreatedByUser = checkIfCreatedByUser(query, phase.createdBy as string);
			// If shared with filter is applied, prevent empty groups from being displayed if user they're not shared with user
			const isSharedWithUser = checkIfSharedWithUser(query, phase.users);
			// Check if group is to be filtered
			const shouldFilter =
				phase.children?.length ||
				(shouldConsiderEmptyGroups && isCreatedByUser && isSharedWithUser) ||
				query.timelineView;

			// If it is nested and should filter
			if (isNested && shouldFilter) {
				// Fetch the parent and nested group id
				const parentGroupId = phase.parentId as string;
				const parentGroupIndex = groupIndices[parentGroupId];

				if (typeof parentGroupIndex === 'number')
					// Insert the nested group id in parent group's children array
					filteredPhases[parentGroupIndex]!.children = filteredPhases[
						parentGroupIndex
					]?.children?.concat(phase._id as string);
			}
		});

		filteredPhases.forEach((phase) => {
			const score = phaseMatchScore[phase._id as string];
			// If created by filter is applied, prevent empty groups from being displayed if user is not creator of them
			const isCreatedByUser = checkIfCreatedByUser(query, phase.createdBy as string);
			// If shared with filter is applied, prevent empty groups from being displayed if user they're not shared with user
			const isSharedWithUser = checkIfSharedWithUser(query, phase.users);

			if (
				phase.children?.length ||
				(shouldConsiderEmptyGroups && isCreatedByUser && isSharedWithUser) ||
				query.timelineView
			) {
				switch (score) {
					case 2: {
						sortedPhase.splice(blockMatchedIndex, 0, phase);
						blockMatchedIndex++;
						break;
					}
					case 1: {
						sortedPhase.splice(blockMatchedIndex + phaseMatchedIndex, 0, phase);
						phaseMatchedIndex++;
						break;
					}
					default:
						sortedPhase.push(phase);
						break;
				}
			}
		});
		// Return the phases(which has blockIds) and blocks
		return {
			phases: sortedPhase,
			blocks: filteredBlocks
		};
	} catch (error) {
		return undefined;
	}
};

export default filterPhaseAndBlocks;
