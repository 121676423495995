import {
	IBlock,
	IFeedback,
	INode,
	INote,
	IProject,
	IGroup,
	IUser,
	IProjectGroup
} from '@naya_studio/types';
import localForage from 'localforage';
import { store } from 'src';
import { setAIUser } from 'src/redux/features/aiUser';
import { loadBlocks } from 'src/redux/features/blocks';
import { loadFeedback } from 'src/redux/features/feedbacks';
import { addGroup } from 'src/redux/features/groupDetail';
import { addNotesInRedux } from 'src/redux/features/notes';
import { loadProject } from 'src/redux/features/projects';
import { addStagesToRedux } from 'src/redux/features/stages';
import { setUser } from 'src/redux/features/user';
import { generateIdsFromUrl } from 'src/redux/reduxActions/util';

type TDestructuredProject = {
	project: IProject;
	stages: IGroup[];
	blocks: IBlock[];
	feedbacks: IFeedback[];
	notes: INote[];
};

/**
 * Active version of each store in local forage
 */
const localForageStoreVersions: { [key: string]: number } = {
	NODE: 1,
	PROJECT: 5,
	USER: 3,
	GROUP: 2,
	TEMPLATES: 3
};

localForage.config({
	driver: [localForage.INDEXEDDB, localForage.WEBSQL, localForage.LOCALSTORAGE],
	name: 'Naya'
});

export const nodeStore = localForage.createInstance({
	name: 'Naya',
	storeName: 'Nodes'
});

export const projectStore = localForage.createInstance({
	name: 'Naya',
	storeName: 'Projects'
});

export const userStore = localForage.createInstance({
	name: 'Naya',
	storeName: 'Users'
});

export const groupStore = localForage.createInstance({
	name: 'Naya',
	storeName: 'Groups'
});

export const versionStore = localForage.createInstance({
	name: 'Naya',
	storeName: 'Version'
});

/** Templates localForage store */
export const templatesStore = localForage.createInstance({
	name: 'Naya',
	storeName: 'Templates'
});

/**
 * Function to save a project to IndexedDB
 * @param project Project to Save to IndexedDB
 */
export const saveProjectInLocalForage = async (
	project: IProject,
	stages: IGroup[],
	blocks: IBlock[],
	feedbacks: IFeedback[],
	notes: INote[]
) => {
	if (project && project._id) {
		const destructuredProject: TDestructuredProject = {
			project,
			stages,
			blocks,
			feedbacks,
			notes
		};
		await projectStore.setItem(project._id as string, destructuredProject);

		return true;
	}

	return false;
};

/**
 * Function to load a project from indexedDB
 * @param projectId Id of the Project to load
 */
export const loadProjectFromLocalForage = async (projectId: string) => {
	try {
		const destructuredProject = await projectStore.getItem(projectId);

		if (destructuredProject) {
			const { project, stages, blocks, feedbacks, notes } =
				destructuredProject as TDestructuredProject;

			if (project) store.dispatch(loadProject(project));
			if (stages) store.dispatch(addStagesToRedux(stages));
			if (blocks) store.dispatch(loadBlocks(blocks));
			if (feedbacks) store.dispatch(loadFeedback(feedbacks));
			if (notes) store.dispatch(addNotesInRedux(notes));

			return true;
		}

		return false;
	} catch (error) {
		console.error('Error accessing Project ', error);
		return false;
	}
};

/**
 * Function to remove the Project from indexedDB
 * @param projectId Id of the Project to remove
 */
export const removeProjectFromLocalForage = async (projectId: string) => {
	try {
		await projectStore.removeItem(projectId);

		return true;
	} catch (error) {
		console.error('Error deleting project from local forage ', error);
		return false;
	}
};

/**
 * Function to save a INodes to IndexedDB
 * @param nodes Array of INodes to Save to IndexedDB
 */
export const saveNodesInLocalForage = async (nodes: INode[]) => {
	if (nodes.length) {
		nodes.forEach(async (node: INode) => {
			await nodeStore.setItem(node._id as string, node);
		});

		return true;
	}

	return false;
};

/**
 * Function to remove the Nodes from indexedDB
 * @param nodes Array of Nodes to remove
 */
export const removeNodesFromLocalForage = async (nodes: INode[]) => {
	try {
		if (nodes.length) {
			nodes.forEach(async (node: INode) => {
				await nodeStore.removeItem(node._id as string);
			});

			return true;
		}

		return false;
	} catch (error) {
		console.error('Error deleting nodes from local forage ', error);
		return false;
	}
};

/**
 * Function to save a User to IndexedDB
 * @param user User to Save to IndexedDB
 */
export const saveUserInLocalForage = async (user: IUser) => {
	if (user && user._id) {
		await userStore.setItem(user.email as string, user);

		return true;
	}

	return false;
};

/**
 * Function to load a User from indexedDB
 * @param userId Email Id of the User to load
 */
export const loadUserFromLocalForage = async (emailId: string) => {
	try {
		const user = (await userStore.getItem(emailId)) as IUser;
		if (user && user.isAdminApproved) {
			store.dispatch(setUser(user));

			return true;
		}

		return false;
	} catch (error) {
		console.error('Error accessing User ', error);
		return false;
	}
};

/**
 * Function to load ai User from indexedDB
 * @param userId Email Id of the User to load
 */
export const loadAIUserFromLocalForage = async () => {
	try {
		const user = (await userStore.getItem('ai@naya.studio')) as IUser;
		if (user) {
			store.dispatch(setAIUser(user));

			return true;
		}

		return false;
	} catch (error) {
		console.error('Error accessing User ', error);
		return false;
	}
};

/**
 * Function to remove the User from indexedDB
 * @param userId Id of the User to remove
 */
export const removeUserFromLocalForage = async (userId: string) => {
	try {
		await userStore.removeItem(userId);
		await userStore.removeItem('ai@naya.studio');
		return true;
	} catch (error) {
		console.error('Error deleting user from local forage ', error);
		return false;
	}
};

/**
 * Function to save a group to IndexedDB
 * @param activeGroup group to save to IndexedDB
 */
export const saveGroupInLocalForage = async (activeGroup: IProjectGroup) => {
	try {
		const activeGroupId = activeGroup._id as string;
		if (activeGroupId) {
			await groupStore.setItem(activeGroupId, activeGroup);

			return true;
		}

		return false;
	} catch (error) {
		console.error('Error saving group: ', error);
		return false;
	}
};

/**
 * Function to load a group from indexedDB
 * @param groupId group Id of the group to load
 */
export const loadGroupFromLocalForage = async (groupId: string) => {
	try {
		const group = (await groupStore.getItem(groupId)) as IProjectGroup;
		if (group && group._id) {
			store.dispatch(addGroup(group));

			return true;
		}

		return false;
	} catch (error) {
		console.error('Error accessing User ', error);
		return false;
	}
};

/**
 * Function to remove the group from indexedDB
 * @param groupId Id of the group to remove
 */
export const removeGroupFromLocalForage = async (groupId: string) => {
	try {
		await groupStore.removeItem(groupId);
		return true;
	} catch (error) {
		console.error('Error deleting group from local forage ', error);
		return false;
	}
};

/**
 * Updates localForage data with the corresponding data in Redux.
 * @param {string} type - Type of data to update: 'BLOCKS' | 'PHASES' | 'PROJECT'.
 * @param {string} projectID - Project ID to update. If not provided, it will use the currently opened project.
 */
export const updateLocalForageData = async (
	type: 'BLOCKS' | 'PHASES' | 'PROJECT',
	projectId = generateIdsFromUrl()?.projectId
) => {
	const destructuredProject = (await projectStore.getItem(projectId)) as TDestructuredProject;
	if (!projectId || !destructuredProject) return;
	let shouldUpdate = false;

	switch (type) {
		case 'BLOCKS': {
			const blocksData = store.getState().blocks.data;
			if (blocksData) {
				const blocks = Object.values(blocksData).filter(
					(block) => block.projectId === projectId
				);
				destructuredProject.blocks = blocks;
				shouldUpdate = true;
			}
			break;
		}
		case 'PHASES': {
			const stagesData = store.getState().stages.data;
			if (stagesData) {
				const stages = Object.values(stagesData).filter(
					(stage) => stage.projectId === projectId
				);
				destructuredProject.stages = stages;
				shouldUpdate = true;
			}
			break;
		}
		case 'PROJECT': {
			const projectsData = store.getState().projects.data;
			if (projectsData) {
				const project = projectsData[projectId];
				if (project) {
					destructuredProject.project = project;
					shouldUpdate = true;
				}
			}
			break;
		}
		default:
			return;
	}
	if (shouldUpdate) {
		projectStore.setItem(projectId, destructuredProject);
	}
};

/**
 * Function to save templates in localForage
 * @param templates IPoject[]
 */
export const saveTemplatesInLocalForage = async (templates: IProject[]) => {
	const templatesPromises: Promise<IProject>[] = [];

	templates.forEach((template) => {
		if (template && template._id) {
			const promise = templatesStore.setItem(template._id.toString(), template);
			templatesPromises.push(promise);
		}
	});

	await Promise.all(templatesPromises);
};

/**
 * Function to get all the templates from localForage
 * @returns IPoject[]
 */
export const getTemplatesFromLocalForage = async () => {
	const templatesPromises: Promise<IProject | null>[] = [];

	await (
		await templatesStore.keys()
	).forEach((key) => {
		const promise = templatesStore.getItem<IProject>(key);
		templatesPromises.push(promise);
	});

	const templates = await Promise.all(templatesPromises);

	const nonNullTemplates = templates.filter((template) => template !== null) as IProject[];

	return nonNullTemplates;
};

/**
 * Function to handle store clearing on schema changes
 * If for any of the release you want to clean indexed db of all users before they can use the new feature
 * increment the version of that store in active versions and this function will clear the store automatically
 */
localForage.ready().then(async () => {
	/**
	 * Function to clear store by its name
	 * @param storeName store name
	 */
	const clearStore = async (storeName: 'USER' | 'GROUP' | 'NODE' | 'PROJECT' | 'TEMPLATES') => {
		const oldStoreVersion = ((await versionStore.getItem(storeName)) as number) || 0;
		const activeStoreVersion = localForageStoreVersions[storeName] as number;

		if (!oldStoreVersion || oldStoreVersion < activeStoreVersion) {
			switch (storeName) {
				case 'USER':
					await userStore.clear();
					break;
				case 'GROUP':
					await groupStore.clear();
					break;
				case 'NODE':
					await nodeStore.clear();
					break;
				case 'PROJECT':
					await projectStore.clear();
					break;
				case 'TEMPLATES':
					await templatesStore.clear();
					break;
				default:
					break;
			}

			await versionStore.setItem(storeName, localForageStoreVersions[storeName] as number);
		}
	};

	await clearStore('USER');
	await clearStore('GROUP');
	await clearStore('NODE');
	await clearStore('PROJECT');
	await clearStore('TEMPLATES');
});
