import { IUser } from '@naya_studio/types';
import _debounce from 'lodash/debounce';
import { suggestPeople } from '../commentTile/CustomTextToolbar';
import userPlaceholder from '../../../assets/icons/placeholder_user.svg';

// function to extract user's object based upon id
export const getUserDetail = (userId: string, projectUsers: IUser[]) =>
	projectUsers.find((user) => user._id === userId);

// Function to extract the user's first name
export const getUserFirstName = (user: IUser): string => {
	const firstName = (user?.firstName as string) || (user?.userName as string);
	return firstName || '';
};

// Function to extract the user's first name
export const getUserLastName = (user: IUser) => user.lastName || '';

export const getQuillMention = (setIsTagging: (arg0: boolean) => void) => ({
	allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
	mentionDenotationChars: ['@', '#'],
	dataAttributes: ['id', 'value', 'email', 'profilePic'],
	// @ts-ignore
	async source(searchTerm: any, renderList: any) {
		// console.log('source called')
		const matchedPeople = await suggestPeople(searchTerm);
		renderList(matchedPeople);
	},
	// @ts-ignore
	renderItem: (item: any) => `
        <div class="cql-list-item-inner">
        <img class="list-item-pic" referrerPolicy="no-referrer" src=${
			item.profilePic ? item.profilePic : userPlaceholder
		} />
          ${item.value}
        </div>`,
	onOpen: () => {
		setIsTagging(true);
	},
	onClose: () => {
		setTimeout(() => {
			setIsTagging(false);
		}, 500);
	},
	// fixMentionsToQuill:true,
	positioningStrategy: 'fixed',
	spaceAfterInsert: false
});

const getTaggedUser = function (inputText: string, callback: (result: string[]) => void) {
	const htmlString: string = inputText;
	const doc = new DOMParser().parseFromString(htmlString, 'text/html');
	const elems = doc.getElementsByClassName('mention');
	const taggedUsers: string[] = [];
	if (elems) {
		for (let i = 0; i < elems.length; i++) {
			const elem = elems[i];
			taggedUsers.push(elem?.getAttribute('data-id') as string);
		}
	}
	callback(taggedUsers);
};

export const debouncedgetTaggedUser = _debounce(getTaggedUser, 250);
