import { IUser } from '@naya_studio/types';

let pendoInitialized = false;

/**
 * Function to initialize pendo
 * @param user actively logged in user
 */
const pendoInit = (user: IUser) => {
	// This function creates anonymous visitor IDs in
	// Pendo unless you change the visitor id field to use your app's values

	// This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for
	// account ID unless you change the account id field to use your app's values

	// Call this function in your authentication promise handler or
	// callback when your visitor and account id values are available
	// Please use Strings, Numbers, or Bools for value types.
	try {
		if (!pendoInitialized) {
			const { pendo } = window as any;
			const isGuestUser = user.userType?.includes('GUEST');
			const visitorId = isGuestUser ? 'guest@naya.studio' : (user._id as string);
			const visitorEmail = user.email as string;
			const visitorName = user.email?.split('@')[1];

			pendo.initialize({
				visitor: {
					id: visitorId, // Required if user is logged in, default creates anonymous ID
					email: visitorEmail // Recommended if using Pendo Feedback, or NPS Email
					// full_name:    // Recommended if using Pendo Feedback
					// role:         // Optional

					// You can add any additional visitor level key-values here,
					// as long as it's not one of the above reserved names.
				},

				account: {
					id: visitorName // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
					// name:         // Optional
					// is_paying:    // Recommended if using Pendo Feedback
					// monthly_value:// Recommended if using Pendo Feedback
					// planLevel:    // Optional
					// planPrice:    // Optional
					// creationDate: // Optional

					// You can add any additional account level key-values here,
					// as long as it's not one of the above reserved names.
				}
			});
			pendoInitialized = true;
		}
	} catch (error) {
		console.warn(
			'Pendo initialization failed. Early initialization or ad blocker detected',
			error
		);
	}
};

export default pendoInit;
