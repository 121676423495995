/* eslint-disable max-len */
import { EBlockType } from '@naya_studio/types';
import { TI2RData } from './i2rData';

const dress: TI2RData = {
	text: `{"root":{"children":[{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":"design a fashion forward dress for the met gala 2025 (3d model dress only)","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}`,
	projectTitle: 'Example - Dress',
	groups: [
		{
			name: 'Example - Fashion Inspiration',
			blocks: [
				{
					name: 'Elegant Dress',
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/dress/oy5rbm0hqblghl67wdsy',
					blockType: EBlockType.IMAGE
				},
				{
					name: 'Tulle Grace',
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/dress/eb7o745ucgllufym8jxk',
					blockType: EBlockType.IMAGE
				},
				{
					name: 'Sheer Shine',
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/dress/a0exksg1mdqwphvd7ozj',
					blockType: EBlockType.IMAGE
				},
				{
					name: 'Metallic Net',
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/dress/nnyj1vibbocfvyj9vgjn',
					blockType: EBlockType.IMAGE
				},
				{
					name: 'Bold Statement',
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/dress/dvz8pgomziipqyz0rcih',
					blockType: EBlockType.IMAGE
				},
				{
					name: 'Striking Cape',
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/dress/a9k2wfbppi89m4yszfyp',
					blockType: EBlockType.IMAGE
				},
				{
					name: 'Glamour Gown',
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/dress/sndditdotmllc4mo6dul',
					blockType: EBlockType.IMAGE
				},
				{
					name: 'Futuristic Armor',
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/dress/q7lcj2ja96mtfptcbzbi',
					blockType: EBlockType.IMAGE
				}
			]
		}
	]
};

export default dress;
