import { INode } from '@naya_studio/types';
import App from 'src/components/canvas/app/App';

/**
 * Checks if the string is a valid JSON
 * @param str string to be parsed
 * @returns Returns true if the string is a valid JSON
 */
const isJson = (str: string) => {
  try {
    JSON.parse(str);
  } catch (error) {
    return false;
  }
  return true;
};

/**
 * Function to generate text node data
 * @param x x coordinate of the node
 * @param y y coordinate of the node
 * @param text text to be pasted
 * @param app app instance
 * @returns text node data
 */
const DEFAULT_TEXT = (
  x: number,
  y: number,
  text: string,
  app: App,
) => ({
  nodeType: 'TEXT',
  absoluteBounds: { x, y },
  isVisible: true,
  rotation: 0,
  scale: { scaleX: 1, scaleY: 1 },
  zIndex: (app.getHighestZIndex() + 1),
  text: {
    style: {
      fill: '#000000', align: 'left', fontSize: 21, fontWeight: 'normal',
    },
    value: text,
  },
} as INode);

/**
 *  Function to generate stickynote node data
 * @param x x coordinate of the node
 * @param y y coordinate of the node
 * @param text text to be pasted
 * @param app app instance
 * @returns sticky node data
 */
const DEFAULT_STICKY_NOTE = (
  x: number,
  y: number,
  text: string,
  app: App,
) => ({
  nodeType: 'STICKY_NOTE',
  absoluteBounds: {
    x,
    y,
    height: 100,
  },
  isVisible: true,
  rotation: 0,
  scale: { scaleX: 1, scaleY: 1 },
  zIndex: (app.getHighestZIndex() + 1),
  showAuthor: false,
  text: {
    style: {
      fill: '#000000', align: 'left', fontSize: 21, fontWeight: 'normal',
    },
    value: text,
  },
} as INode);

export { isJson, DEFAULT_TEXT, DEFAULT_STICKY_NOTE };
