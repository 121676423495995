import { EUserRole, IBlock, IProject, IGroup, IUser, TUserAndRole } from '@naya_studio/types';
import { store } from 'src';
import { fetchBlockByIdFromRedux } from 'src/redux/actions/util';
import {
	generateIdsFromUrl,
	getStageBlocks,
	getStagesBlocksNodesOfProject
} from 'src/redux/reduxActions/util';
import getUserFromRedux from '../helper/user';

export const checkUserAccessLevel = (
	users: TUserAndRole[],
	userId: string,
	accessToBeChecked: Array<keyof typeof EUserRole>
) => {
	if (users) {
		for (let i = 0; i < users.length; i++) {
			const tempUser = users[i]?.user as IUser;
			if (
				(typeof users[i]?.user === 'string' ? tempUser : tempUser._id) === userId &&
				accessToBeChecked.includes(users[i]?.role as keyof typeof EUserRole)
			) {
				return true;
			}
		}
	}
	return false;
};

export const checkIfUserExistsInList = (users: TUserAndRole[], userId: string) => {
	if (users) {
		for (let i = 0; i < users.length; i++) {
			const tempUser = users[i]?.user;
			if (tempUser) {
				if (
					(typeof users[i]?.user === 'string' ? tempUser : (tempUser as IUser)._id) ===
					userId
				) {
					return true;
				}
			} else if (tempUser === userId) {
				return true;
			}
		}
	}
	return false;
};

export const checkIfCurrentUserIsAdmin = () => {
	const state = store.getState();
	const currentUser = state.user as IUser;
	if (currentUser && currentUser.userType?.includes('ADMIN')) {
		return true;
	}
	return false;
};

export const getCurrentBlock = () => {
	const state = store.getState();
	const { blockId } = generateIdsFromUrl();
	const block = state.blocks.data[blockId] as IBlock;

	return block;
};

export const checkIfUserIsProjectOwner = () => {
	if (checkIfCurrentUserIsAdmin()) {
		return true;
	}
	const currentUser: IUser = getUserFromRedux();
	const { projectId } = generateIdsFromUrl();
	const project = store.getState().projects.data[projectId] as IProject;

	const allProjectUsers = project.users as TUserAndRole[];
	for (let i = 0; i < allProjectUsers.length; i++) {
		if (
			currentUser._id === (allProjectUsers[i]?.user as IUser)._id &&
			['EDITOR', 'OWNER'].includes(allProjectUsers[i]?.role as string)
		) {
			return true;
		}
	}
	return false;
};

/**
 * Function to check the current user access
 * @param id projectId | canvasId | stageId
 * @param type 'PROJECT' | 'STAGE' | 'CANVAS'
 * @param deep If type is PROJECT and deep is true,
 * traverse through all stages and block to check for user existence
 * @returns {boolean}
 */
export const checkAccess = (
	id: string,
	type: 'PROJECT' | 'STAGE' | 'CANVAS',
	deep: boolean = false,
	userId?: string
) => {
	const { projectId } = generateIdsFromUrl();
	const user: IUser = getUserFromRedux();
	const { projects, stages } = store.getState();
	const project = projects.data[projectId] as IProject;
	let users: TUserAndRole[] = [];

	if (!user || !user?._id) return false;
	if (user.userType?.includes('ADMIN')) return true;

	const userToCheck = userId ?? (user._id as string);

	switch (type) {
		case 'PROJECT':
			if (project) {
				users = project?.users as TUserAndRole[];
				// Filter out users with status 'PENDING'
				users = users?.filter((projectUser) => projectUser?.status !== 'PENDING');
				if (deep) {
					const { stages: projectStages, blocks } = getStagesBlocksNodesOfProject(
						project._id as string
					);
					projectStages.forEach((s: IGroup) => {
						const stageUsers = s.users as TUserAndRole[];
						if (stageUsers?.length) {
							// Filter stage users with status 'PENDING'
							users = [
								...users,
								...stageUsers.filter((stageUser) => stageUser?.status !== 'PENDING')
							];
						}
					});
					blocks.forEach((b: IBlock) => {
						const blockUsers = b.users as TUserAndRole[];
						if (blockUsers) {
							// Filter block users with status 'PENDING'
							users = [
								...users,
								...blockUsers.filter((blockUser) => blockUser?.status !== 'PENDING')
							];
						}
					});
				}
			}
			break;
		case 'STAGE': {
			const stage = stages.data[id];
			if (stage) {
				users = stage.users as TUserAndRole[];
				const blocks = getStageBlocks(stage?._id as string);
				blocks.forEach((block: IBlock) => {
					const blockUsers = block.users as TUserAndRole[];
					users = [
						...users,
						...blockUsers.filter((blockUser) => blockUser?.status !== 'PENDING')
					];
				});
			}
			break;
		}
		case 'CANVAS': {
			const block = fetchBlockByIdFromRedux(id) as IBlock;
			if (block) {
				// Filter users with status not equal to 'PENDING'
				users = (block.users as TUserAndRole[])?.filter(
					(blockUser) => blockUser.status !== 'PENDING'
				);
			}
			break;
		}
		default:
			break;
	}
	// Call the generic function to check if user in userIDs
	return checkIfUserExistsInList(users, userToCheck);
};
