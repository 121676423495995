import { FC, ReactElement } from "react"
import { Col, Row } from "react-bootstrap"
import LandingPageFooter from "../footer/footer"
import ArticleCard from "../utilities/articleCard/ArticleCard"
import SwitchBox from "../utilities/typebox/SwitchBox"
import { whyNaya } from "./WhyNaya.copy"
import "./WhyNaya.scss"

export type WhyNayaProps = {

}

const {
  typer,
  mission,
  name,
  idea,
  news
} = whyNaya

const WhyNaya: FC<WhyNayaProps> = (): ReactElement => {

  return <div id="why-naya">
    <Typer />
    <Name />
    <Mission />
    <Idea />
    <News />
    {/* Waiting for updates, Design Principles */}
    {false && <Design />}
    <LandingPageFooter />
  </div>
}

export const Typer = () => <div className='typer grey-background landing-page-padding' >
  <div className='max-width-container'>
    <h1>
      {typer.header}
    </h1>
    <div className="switch-wrapper">
      <SwitchBox data={typer.descriptions} />
    </div>
  </div>
</div>

export const Mission = () => <div id={"name"} className='mission grey-background landing-page-padding' >
  <div className='max-width-container'>
    <h1>{mission.header}</h1>
    <p>{mission.text1}</p>
  </div>
</div>

export const Name = () => <div  className='name white-background landing-page-padding' >
  <div className='max-width-container'>
    <h1>{name.header}</h1>
    <p>{name.text1}</p>
    <div className='name-container'>
      {name.names.map((n, i) => <h1 key={n + i}>{n}</h1>)}
    </div>
  </div>
</div>


export const Idea = () => <div className='ideas grey-background landing-page-padding' >
  <div className='max-width-container'>
    <h1>{idea.header}</h1>
    <Row className='article-container'>
      {idea.articles.map((a, i) => (
        <Col sm={12} lg={6} key={a.url + i} className='article-card-container'>
          <ArticleCard
            {...a}
          />
        </Col>
      ))}
    </Row>
    {/* onClick on anchor tag necessary, not sure why anchor tag's default behavior is not working. Possible preventDefault() happening?*/}
    <a href='https://medium.com/naya-studio' target='_blank' rel='noopener noreferrer' className='medium-link' onClick={() => window.open('https://medium.com/naya-studio', '_blank', 'noreferrer noopener')}>
      Read more on our Medium
    </a>
  </div>
</div>

export const News = () => <div className='twitter-news grey-background landing-page-padding'>
  <div className='max-width-container'>
    <h1>{news.header}</h1>
    <div className='press-article-container'>
      {/* {news.articles.map((a, i) => <a key={`news-articles-${i}`} href={a.link ?? ''} target='_blank' rel='noreferrer noopener' className={`press_${a.name}`}>  */}
      {news.articles.map((a, i) => <span key={`news-articles-${i}`} className={`press_${a.name}`}> 
        <img src={a.src} alt='press-article' />
      </span>)}
    </div>
  </div>
</div>

const Design = () => <div className='white-background landing-page-padding'><h1>Design Principles</h1></div>

export default WhyNaya