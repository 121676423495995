/* eslint-disable max-len */
import { EBlockType } from '@naya_studio/types';
import { TI2RData } from './i2rData';

const shoe: TI2RData = {
	text: `{"root":{"children":[{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":"Design a two color futuristic running shoe, light blue and deep green gradient","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1},{"children":[],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}`,
	projectTitle: 'Example - Shoe',
	groups: [
		{
			name: 'Example - Shoe Inspiration',
			blocks: [
				{
					name: 'Image—Adidas Forum Low.jpg',
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/shoe/kg6had6toyjow1ugjali',
					blockType: EBlockType.IMAGE
				},
				{
					name: 'Image—Adidas Ultra Boost.jpg',
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/shoe/lumnp6fmbzd1nmeiz8nk',
					blockType: EBlockType.IMAGE
				},
				{
					name: 'Adidas Ultraboost Red IE3042.avif',
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/shoe/vuxjtgtusdqpcwelc82f',
					blockType: EBlockType.IMAGE
				}
			]
		}
	]
};

export default shoe;
