import React, { ReactElement, useEffect, useState } from 'react';
import './SharingModal.scss';
import {
	IBlock,
	IProjectDetail,
	IGroup,
	IUser,
	TInvitedEmail,
	TUserAndRole
} from '@naya_studio/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
	checkIfCurrentUserIsAdmin,
	checkUserAccessLevel
} from 'src/util/accessLevel/accessLevelActions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
	getStagesBlocksNodesOfProject,
	getUserRoleObjBasedOnIds
} from 'src/redux/reduxActions/util';
import useProject from 'src/redux/hooks/useProject';
import { fetchBlockByIdFromRedux } from 'src/redux/actions/util';
import useBlock from 'src/redux/hooks/useBlocks';
import useStage from 'src/redux/hooks/useStages';
import { ReduxState } from 'src/redux/reducers/root.types';
import { ControlModal } from '@naya_studio/radix-ui';
import { useParams } from 'react-router';
import { ReactComponent as CloseIcon } from 'src/assets/icons/sharing-modal/Close.svg';
import { TAddUserToProjectThunkArg, TAddUserToStageThunkArg } from 'src/types/argTypes';
import { PathParamsType } from 'src/types/pathParams';
import useUser from 'src/redux/hooks/user';
import { checkIfUserOnHomebase } from 'src/util/collaboration/util';
import { AppDispatch } from 'src';
import { unloadProject } from 'src/redux/features/projects';
import { unloadBlocks } from 'src/redux/features/blocks';
import { unloadFeedbacks } from 'src/redux/features/feedbacks';
import { unloadNodes } from 'src/redux/features/nodes';
import { unloadNotes } from 'src/redux/features/notes';
import { unloadStages } from 'src/redux/features/stages';
import { ESharingTabs, SharingModalProps } from './SharingModal.types';
import InviteUsers from './Content/inviteUsers/InviteUsers';
import Details from './Details/Details';
import UserList from './Content/userList/UserList';
import Info from './Content/Info/Info';
import MilestoneDropdown from './Details/MilestoneDropdown/MilestoneDropdown';
import Header from './Header/Header';
import ShareOptions from './Content/ShareOptions/ShareOptions';
import SharingSkeleton from './Skeleton/Skeleton';
import { OverrideContent, OverrideFooter, OverrideHeader } from './overrideRole/OverrideRole';
import { ShareDetailsProps } from './Details/Details.types';
import { SharingHeaderProps } from './Header/Header.types';
import { MilestoneDropdownProps } from './Details/MilestoneDropdown/MilestoneDropdown.types';
import { ShareInfoProps } from './Content/Info/Info.types';
import { InviteUserProps } from './Content/inviteUsers/InviteUsers.types';
import { UserListProps } from './Content/userList/UserList.types';
import { SharingOptionsProps } from './Content/ShareOptions/ShareOptions.types';
import SharingContent from './Content';
import { GuestAccessSwitch } from '../GuestAccess/GuestAccess';
import {
	checkIfUserHasAccessToGivenStage,
	checkIfUserHasBlockLevelAccess,
	checkIfUserHasProjectLevelAccess,
	checkIfUserHasStageLevelAccess,
	getFirstBlockIdByPhaseId
} from './sharingUtil';

/**
 * Component to render Sharing Modal
 */
const SharingModal: React.FC<SharingModalProps> & {
	Details: React.FC<ShareDetailsProps>;
	Header: React.FC<SharingHeaderProps>;
	MilestoneDropdown: React.FC<MilestoneDropdownProps>;
	Info: React.FC<ShareInfoProps>;
	InviteUsers: React.FC<InviteUserProps>;
	UserList: React.FC<UserListProps>;
	ShareOptions: React.FC<SharingOptionsProps>;
} = ({ open, selectedTab, sharingDetails, onClose, variant }: SharingModalProps): ReactElement => {
	const [activeTab, setActiveTab] = useState<keyof typeof ESharingTabs>('PROJECT');
	const [actionableUsers, setActionableUsers] = useState<Array<TUserAndRole>>(
		[] as TUserAndRole[]
	);
	const dispatch = useDispatch<AppDispatch>();
	const { user: currentUser } = useUser();
	const allUsers = useSelector((state) => (state as ReduxState).projectUsers, shallowEqual);

	const params = useParams<PathParamsType>();

	const [actionableBlock, setActionableBlock] = useState<IBlock | undefined>();
	const [actionablePhase, setActionablePhase] = useState<IGroup | undefined>();
	const [showOverrideRoleModal, setShowOverrideRoleModal] = useState(false);
	const [overrideRoleModalPayload, setOverrideRoleModalPayload] = useState<
		(TAddUserToStageThunkArg | TAddUserToProjectThunkArg)[]
	>([]);
	const [showInviteNote, setShowInviteNote] = useState(false);
	const {
		projectId,
		phaseId = params.stageOrDashboard !== 'journey' && params.stageOrDashboard,
		blockId = params.canvasId
	} = sharingDetails;

	const { guestAccess: isGuestAccessEnabled, isInviteEnabled } = useFlags();
	const { project, isLoading } = useProject(projectId);

	const { block } = useBlock(actionableBlock?._id as string);
	const { stage: activeStage } = useStage(actionablePhase?._id as string);
	const { stages, blocks, blocksJson } = getStagesBlocksNodesOfProject(project?._id as string);

	/**
	 * Update actionable phase/block when user is added/edited/removed
	 */
	useEffect(() => {
		if (block) setActionableBlock(block as IBlock);
		if (activeStage) setActionablePhase(activeStage as IGroup);
	}, [block, activeStage]);

	/**
	 * Set active tab based on tab that is being passes as props
	 */
	useEffect(() => {
		if (
			selectedTab === ESharingTabs.PROJECT &&
			!checkIfUserHasProjectLevelAccess(
				project?.users as TUserAndRole[],
				currentUser._id as string
			)
		) {
			if (checkIfUserHasStageLevelAccess(stages, currentUser._id as string))
				setActiveTab(ESharingTabs.PHASE);
			else if (checkIfUserHasBlockLevelAccess(blocks, currentUser._id as string))
				setActiveTab(ESharingTabs.BLOCK);
		} else {
			setActiveTab(selectedTab);
		}
	}, [selectedTab]);

	/**
	 * Set actionable phase based on phase id passed as props: used when right clicked on Phase
	 */
	useEffect(() => {
		if (phaseId) {
			const tempStage = stages.find((s) => s._id === phaseId) as IGroup;
			setActionablePhase(tempStage);
			// If the phase is shared directly, then set 'actionableBlock' as the first block of shared phase.
			if (!blockId) {
				// If blocks are not present, then set 'actionableBlock' as the first block of first phase.
				if (tempStage?.children && tempStage.children?.length > 0) {
					setActionableBlock(
						fetchBlockByIdFromRedux(tempStage.children[0] as string) as IBlock
					);
				} else {
					const firstblockId = getFirstBlockIdByPhaseId(
						stages,
						currentUser._id as string
					);
					setActionableBlock(fetchBlockByIdFromRedux(firstblockId as string) as IBlock);
				}
			}
		}
	}, [phaseId]);

	/**
	 * Set actionable block based on block id passed as props: used when right clicked on Block
	 */
	useEffect(() => {
		if (blockId) {
			setActionableBlock(blocks.find((b) => b._id === blockId) as IBlock);
		}
	}, [blockId]);

	/**
	 * Extracting default users
	 */
	useEffect(() => {
		const projectUsers = project?.users
			? getUserRoleObjBasedOnIds(project?.users as TUserAndRole[])
			: [];
		let combinedUsers = projectUsers;
		switch (activeTab) {
			case 'PROJECT': {
				let allProjectUsers: TUserAndRole[] = [];
				if (projectUsers) {
					allProjectUsers = allProjectUsers.concat(projectUsers as TUserAndRole[]);
				}
				if (project?.invitedEmails) {
					for (let i = 0; i < project?.invitedEmails.length; i++) {
						allProjectUsers = allProjectUsers.concat({
							user: {
								email: (project?.invitedEmails[i] as TInvitedEmail).email
							},
							role: (project?.invitedEmails[i] as TInvitedEmail).role
						});
					}
				}
				setActionableUsers(allProjectUsers as TUserAndRole[]);
				break;
			}
			case 'PHASE': {
				const actionablePhaseUser = stages.find(
					(s) => s._id === (actionablePhase?._id as string)
				)?.users;
				const actionableInvitedUser = stages.find(
					(s) => s._id === (actionablePhase?._id as string)
				)?.invitedEmails;
				const stageUsers = actionablePhaseUser
					? getUserRoleObjBasedOnIds(actionablePhaseUser as TUserAndRole[])
					: [];
				combinedUsers = stageUsers as TUserAndRole[];
				if (actionableInvitedUser && actionableInvitedUser.length) {
					for (let i = 0; i < actionableInvitedUser.length; i++) {
						const currentStageUser = (actionableInvitedUser[i] as TInvitedEmail).email;
						const found = combinedUsers.some(
							(el) => (el?.user as IUser).email === currentStageUser
						);
						if (!found) {
							combinedUsers = combinedUsers.concat({
								user: {
									email: (actionableInvitedUser[i] as TInvitedEmail).email
								},
								role: (actionableInvitedUser[i] as TInvitedEmail).role
							});
						}
					}
				}
				setActionableUsers(combinedUsers);
				break;
			}
			case 'BLOCK': {
				const actionableBlockInvitedUser = actionableBlock?.invitedEmails;
				const canvasUsers = actionableBlock?.users
					? getUserRoleObjBasedOnIds(actionableBlock?.users as TUserAndRole[])
					: [];
				combinedUsers = canvasUsers as TUserAndRole[];
				if (actionableBlockInvitedUser) {
					for (let i = 0; i < actionableBlockInvitedUser.length; i++) {
						const currentCanvasUser = (actionableBlockInvitedUser[i] as TInvitedEmail)
							?.email;
						if (currentCanvasUser) {
							const found = combinedUsers.some(
								(el: TUserAndRole) =>
									(el?.user as IUser).email === currentCanvasUser
							);
							if (!found) {
								combinedUsers = combinedUsers.concat({
									user: {
										email: (actionableBlockInvitedUser[i] as TInvitedEmail)
											.email
									},
									role: (actionableBlockInvitedUser[i] as TInvitedEmail).role
								});
							}
						}
					}
				}
				setActionableUsers(combinedUsers as TUserAndRole[]);
				break;
			}
			default:
				break;
		}
	}, [
		actionableBlock,
		actionableBlock?.users,
		actionablePhase?.users,
		actionablePhase?.invitedEmails,
		project?.users,
		project?.invitedEmails,
		activeTab,
		project,
		allUsers
	]);

	/**
	 * Function to set initial phase/block to which user has access
	 */
	const setIntialData = () => {
		if (stages && stages.length > 0) {
			for (let i = 0; i < stages.length; i++) {
				const initBlocks = stages[i]?.children as string[];
				if (
					(checkIfCurrentUserIsAdmin() ||
						checkIfUserHasAccessToGivenStage(
							stages[i] as IGroup,
							currentUser._id as string
						)) &&
					initBlocks.length > 0
				) {
					setActionablePhase(stages[i] as IGroup);
					setActionableBlock(fetchBlockByIdFromRedux(initBlocks[0] as string) as IBlock);
					break;
				}

				// set the first block that user has editor access to, as a actionable block
				for (let blockIdIndex = 0; blockIdIndex < initBlocks.length; blockIdIndex++) {
					const initBlockId = initBlocks[blockIdIndex];
					if (initBlockId) {
						const initBlock = blocksJson[initBlockId];
						if (
							initBlock &&
							checkUserAccessLevel(
								initBlock?.users as TUserAndRole[],
								currentUser._id as string,
								['OWNER', 'EDITOR']
							)
						) {
							setActionableBlock(initBlock);
							break;
						}
					}
				}
			}
		}
	};

	/**
	 * Set intial data when there is no phase and block id from props
	 */
	useEffect(() => {
		if (!phaseId && !blockId && !isLoading) {
			setIntialData();
		}
	}, [phaseId, blockId, isLoading]);

	/**
	 * Checks if the current user has edit access to milestone, project, page
	 * @returns boolean indicating users edit access
	 */
	const checkIfCurrentUserHasEditorAccess = () => {
		if (currentUser.userType?.includes('ADMIN')) {
			return true;
		}
		const projectUsers = getUserRoleObjBasedOnIds(project?.users as TUserAndRole[]);
		const stageUsers = getUserRoleObjBasedOnIds(actionablePhase?.users as TUserAndRole[]);
		const canvasUsers =
			actionableBlock && (actionableBlock.users as TUserAndRole[])
				? getUserRoleObjBasedOnIds(actionableBlock.users as TUserAndRole[])
				: [];
		switch (activeTab) {
			case 'PROJECT':
				if (
					checkUserAccessLevel(projectUsers, currentUser._id as string, [
						'OWNER',
						'EDITOR'
					])
				) {
					return true;
				}
				break;
			case 'PHASE':
				if (
					checkUserAccessLevel(stageUsers, currentUser._id as string, ['OWNER', 'EDITOR'])
				) {
					return true;
				}
				break;
			case 'BLOCK':
				if (
					checkUserAccessLevel(canvasUsers, currentUser._id as string, [
						'OWNER',
						'EDITOR'
					])
				) {
					return true;
				}
				break;
			default:
				break;
		}
		return false;
	};

	/**
	 * Function to unload a project on modal close, in homebase
	 */
	const unloadProjectFromRedux = () => {
		if (checkIfUserOnHomebase()) {
			dispatch(unloadProject(projectId));
			dispatch(unloadStages(projectId));
			dispatch(unloadBlocks(projectId));
			dispatch(unloadFeedbacks(projectId));
			dispatch(unloadNotes(projectId));
			dispatch(unloadNodes(projectId));
		}
		onClose();
	};

	/**
	 * Function to get title of Share Modal based on active tab
	 * @returns share modal title
	 */
	const getShareTitle = () => {
		switch (activeTab) {
			case 'PROJECT':
				return (project?.projectDetails as IProjectDetail)?.name;
			case 'BLOCK':
				return variant === 'DEFAULT' ? actionableBlock?.name : '';
			case 'PHASE':
				return variant === 'DEFAULT' ? actionablePhase?.name : '';
			default:
				return '';
		}
	};

	/**
	 * Function check for guest access
	 * @returns {boolean}
	 */
	const checkGuestAccess = () => {
		switch (activeTab) {
			case 'BLOCK':
				return !!block?.hasGuestAccess;
			case 'PROJECT':
				return !!project?.hasGuestAccess;
			default:
				return false;
		}
	};

	/**
	 * Function to determine whether to show guest access switch or not
	 * @returns {boolean}
	 */
	const shouldShowGuestAccessSwitch = () => {
		if (!isGuestAccessEnabled || !checkIfCurrentUserHasEditorAccess()) return false;
		if (activeTab === 'BLOCK') {
			return true;
		}
		if (activeTab === 'PROJECT') {
			return true;
		}
		return false;
	};

	/**
	 * Function to render header and content of modal based on override modal
	 */
	const renderHeaderAndContent = () =>
		showOverrideRoleModal ? (
			<div className="override-content-wrap sharing-gap tw-p-2">
				<OverrideHeader>
					<ControlModal.Close>
						<button
							type="button"
							onClick={unloadProjectFromRedux}
							className="tw-cursor-pointer tw-inline-flex tw-items-center tw-justify-center tw-outline-none"
							aria-label="Close"
							data-testid="share-modal-close-btn"
						>
							<CloseIcon />
						</button>
					</ControlModal.Close>
				</OverrideHeader>
				<OverrideContent overrideRolePayload={overrideRoleModalPayload} />
			</div>
		) : (
			<div className="sharing-gap tw-p-2" style={{ paddingBottom: '32px' }}>
				<SharingModal.Header
					activeTab={activeTab}
					setActiveTab={setActiveTab}
					projectId={project?._id as string}
					title={getShareTitle() as string}
					variant="DEFAULT"
					isEmpty={actionableBlock?.blockType === 'EMPTY' && activeTab === 'BLOCK'}
				>
					<ControlModal.Close>
						<button
							type="button"
							onClick={unloadProjectFromRedux}
							className="tw-cursor-pointer tw-inline-flex tw-items-center tw-justify-center tw-outline-none"
							aria-label="Close"
							data-testid="share-modal-close-btn"
						>
							<CloseIcon />
						</button>
					</ControlModal.Close>
				</SharingModal.Header>
				<hr />
				<SharingContent>
					{isInviteEnabled && (
						<>
							<div className="tw-flex tw-flex-col tw-gap-2 tw-px-6">
								<SharingModal.Info
									activeTab={activeTab}
									checkIfCurrentUserHasEditorAccess={
										checkIfCurrentUserHasEditorAccess
									}
								/>
								{checkIfCurrentUserHasEditorAccess() && (
									<SharingModal.InviteUsers
										activeTab={activeTab}
										projectId={project?._id as string}
										stageId={actionablePhase?._id as string}
										blockId={actionableBlock?._id as string}
										users={actionableUsers}
										setOverrideRoleModalPayload={setOverrideRoleModalPayload}
										setShowOverrideRoleModal={setShowOverrideRoleModal}
										setShowInviteNote={setShowInviteNote}
										variant={variant}
									/>
								)}
							</div>
							<SharingModal.ShareOptions
								activeTab={activeTab}
								projectId={project?._id as string}
								stageId={actionablePhase?._id as string}
								blockId={actionableBlock?._id as string}
							/>
							<hr />
						</>
					)}
					{shouldShowGuestAccessSwitch() && (
						<>
							<GuestAccessSwitch
								projectId={projectId}
								stageId={actionablePhase?._id as string}
								blockId={actionableBlock?._id as string}
								hasGuestAccess={checkGuestAccess()}
								activeTab={activeTab}
								key={activeTab}
							/>
							<hr />
						</>
					)}
					<SharingModal.UserList
						activeTab={activeTab}
						projectId={project?._id as string}
						actionableBlock={actionableBlock as IBlock}
						actionablePhase={actionablePhase as IGroup}
						users={actionableUsers}
						showInviteNote={showInviteNote}
					/>
				</SharingContent>
			</div>
		);

	return (
		<ControlModal open={open}>
			<ControlModal.ContentWrap
				width={598}
				close={unloadProjectFromRedux}
				data-testid="share-block-modal"
			>
				<ControlModal.Content>
					{isLoading ? <SharingSkeleton /> : renderHeaderAndContent()}
				</ControlModal.Content>
				{!isLoading && showOverrideRoleModal && (
					<ControlModal.Footer>
						<div className="sharing-gap tw-px-2 tw-pb-2">
							<OverrideFooter
								activeTab={activeTab}
								overrideRolePayload={overrideRoleModalPayload}
								onClose={onClose}
							/>
						</div>
					</ControlModal.Footer>
				)}
			</ControlModal.ContentWrap>
		</ControlModal>
	);
};

SharingModal.Details = Details;
SharingModal.Header = Header;
SharingModal.MilestoneDropdown = MilestoneDropdown;
SharingModal.Info = Info;
SharingModal.InviteUsers = InviteUsers;
SharingModal.UserList = UserList;
SharingModal.ShareOptions = ShareOptions;

export default SharingModal;
