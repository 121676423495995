import { ICanvas, INode } from '@naya_studio/types';
import {
  IAction, CanvasActionTypes, ICanvasesState, ProjectActionTypes
} from '../root.types';

// collab.naya.studio/canvas/:id

const initialCanvases: ICanvasesState = {
};

const addToCanvasState = (state: ICanvasesState, canvases: ICanvas[]) => {
  canvases.forEach((c: ICanvas) => {
    if (c._id) {
      const tempCanvasId = c._id as string;
      state[tempCanvasId] = c;
    }
  });
};

const addNodesToCanvasState = (state: ICanvasesState, canvasId: string, nodes: INode[]) => {
  const nodeIds = nodes.map((node: INode) => {
    return node._id;
  });

  if (state[canvasId]) {
    state[canvasId] = {
      ...state[canvasId],
      nodes: Array.from(new Set([...state[canvasId]?.nodes || [], ...nodeIds]))
    } as ICanvas
  }
}

const deleteNodesFromCanvasState = (state: ICanvasesState, canvasId: string, nodes: string[]) => {
  if (state[canvasId]) {
    (state[canvasId]!.nodes) = state[canvasId]?.nodes?.filter((id) => {
      return !nodes.includes(id as string);
    }) as INode[];
  }
}

const canvasReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = initialCanvases,
  action: IAction<CanvasActionTypes | keyof typeof CanvasActionTypes | ProjectActionTypes>,
) => {
  const { payload } = action;

  switch (action.type) {
    case CanvasActionTypes.ADD_CANVASES: {
      const newState: ICanvasesState = {
        ...state,
      };
      const { canvases } = payload;
      addToCanvasState(newState, canvases);
      return newState;
    }

    case CanvasActionTypes.ADD_NODES: {
      const newState: ICanvasesState = {
        ...state,
      };

      if (payload?.canvasId) {
      const { canvasId, nodes } = payload;
        addNodesToCanvasState(newState, canvasId, nodes);
        return newState;
      }

      return state;
    }

    case CanvasActionTypes.DELETE_NODES: {
      const newState = {
        ...state,
      };

      if(payload?.canvasId){
        const { nodes, canvasId } = payload;
        deleteNodesFromCanvasState(newState, canvasId, nodes);
      }
      return newState;
    }

    default:
      return state;
  }
};

export default canvasReducer;
