import { ActionReducerMapBuilder, PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
	IProject,
	IProjectGroup,
	IUser,
	IUserDetailNode,
	IUserPreferenceNode
} from '@naya_studio/types';
import {
	TActionType,
	TAddProjectGroup,
	TCreateProjectFulfill,
	TCreateProjectThunkArg,
	TDuplicateProjectOptimisticallyThunkArg,
	TEditProjectDetailsThunkArg,
	TEditUserArgs,
	TEditUserDetailsArgs,
	TEditUserPreferenceThunkArgs,
	TLoadProjectByIdFulfill,
	TLoginUserArgs,
	TRegisterUserArgs,
	TRemoveProjectGroup
} from 'src/types/argTypes';
import pendoInit from 'src/util/analytics/pendoInit';
import mixpanelInit from 'src/util/analytics/mixpanelInit';
import { cloneDeep } from 'lodash';
import { checkIfUserOnHomebase } from 'src/util/collaboration/util';
import { IUserInitState } from '../reducers/root.types';
import { login, logout, register } from '../actions/auth';
import {
	loadUser,
	editUser,
	editUserDetails,
	addProjectGroup,
	removeProjectGroup,
	editUserPreferences
} from '../actions/user';
import {
	createProject,
	duplicateProjectOptimistically,
	editGroupProjectDetails,
	editProjectDetails,
	loadProjectById
} from '../reduxActions/project';
import {
	deleteProjectFromUsersRedux,
	revertDeletedProjectFromUsersRedux,
	updateLastUpdatedAtToUserData,
	updateProjectDetailsForUser
} from './util';

const initialUser: IUser = {
	_id: '',
	userName: '',
	firstName: '',
	lastName: '',
	email: '',
	contactEmail: '',
	profilePic: '',
	isVerified: false,
	userType: [],
	projectGroups: [], // project within this will be populated
	archivedProjects: [],
	permissions: [],
	acceptedTermsOfService: {
		client: false,
		designer: false,
		maker: false,
		timestamps: {}
	},
	userPreferences: {
		_id: '',
		userId: '',
		pinnedProjects: [],
		noNotificationsFor: [],
		noActionItemToggleWArning: [],
		noReorderWarningModals: [],
		noPageReorderAllowAccess: [],
		color: '#4F00C1',
		studioView: 'GRID',
		journeyZoom: []
	},
	userDetails: [{}],
	defaultProjectGroup: '', // id of the default project group
	notifications: [],
	prototypeOrders: [],
	googleToken: null,
	miroToken: null
};
const initialState: IUserInitState = {
	data: initialUser,
	loading: false,
	error: null
};

/**
 * User Slice to Handle User related Redux Updates
 */
const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUser: (state: IUserInitState, action) => {
			pendoInit(action.payload as IUser);
			mixpanelInit(action.payload as IUser);
			state.data = action.payload;
			state.error = null;
			state.loading = false;
		},
		updateUser: (state: IUserInitState, action: PayloadAction<Partial<IUser>>) => {
			state.data = { ...state.data, ...action.payload };
			state.error = null;
			state.loading = false;
		},
		resetUser: (state: IUserInitState) => {
			state.data = initialUser;
			state.error = null;
			state.loading = false;
		},
		updateDefaultGroup: (state: IUserInitState, action) => {
			const { defaultProjectGroup: defaultProjectGroupId, projectGroups } = state.data;

			const updatedProjectGroups = [...(projectGroups || [])];

			// Find the index of the default project group
			const defaultProjectGroupIndex = updatedProjectGroups.findIndex(
				(pG) => (pG as IProjectGroup)._id === defaultProjectGroupId
			);

			// If it exists in default project group
			if (defaultProjectGroupIndex !== -1) {
				// Safely access the existing projects, ensuring it's an array, then append new projects
				const currentProjects =
					(updatedProjectGroups[defaultProjectGroupIndex] as IProjectGroup)?.projects ||
					[];
				(updatedProjectGroups[defaultProjectGroupIndex] as IProjectGroup).projects = [
					...currentProjects,
					...action.payload.project
				];
			}

			// Update the state with the modified projectGroups array
			state.data.projectGroups = updatedProjectGroups;
		},
		setDefaultGroup: (state: IUserInitState, action) => {
			const { defaultProjectGroup: defaultProjectGroupId, projectGroups } = state.data;

			const updatedProjectGroups = [...(projectGroups || [])];

			// Find the index of the default project group
			const defaultProjectGroupIndex = updatedProjectGroups.findIndex(
				(pG) => (pG as IProjectGroup)._id === defaultProjectGroupId
			);

			// If it exists in default project group
			if (defaultProjectGroupIndex !== -1) {
				(updatedProjectGroups[defaultProjectGroupIndex] as IProjectGroup).projects = [
					...action.payload.projects
				];
			}

			// Update the state with the modified projectGroups array
			state.data.projectGroups = updatedProjectGroups;
		},
		deleteProjectForUser: (
			state: IUserInitState,
			action: PayloadAction<{
				projectId: string;
				groupId?: string;
			}>
		) => {
			const { projectId, groupId } = action.payload;
			const updatedUser = deleteProjectFromUsersRedux(state.data, projectId, groupId);

			state.data = updatedUser;
		},
		revertDeletedProjectForUser: (
			state: IUserInitState,
			action: PayloadAction<{
				projectId: string;
			}>
		) => {
			const { projectId } = action.payload;
			const updatedUser = revertDeletedProjectFromUsersRedux(state.data, projectId);

			state.data = updatedUser;
		},
		removeGroup: (
			state: IUserInitState,
			action: PayloadAction<{
				groupId: string;
			}>
		) => {
			const { groupId } = action.payload;
			state.data.projectGroups = (state.data.projectGroups as IProjectGroup[])?.filter(
				(grp) => grp._id !== groupId
			);
		}
	},
	extraReducers: (builder: ActionReducerMapBuilder<IUserInitState>) => {
		builder
			/** ----REGISTER USER----- */
			// Pending
			.addCase(register.pending, (state: IUserInitState) => {
				state.loading = true;
				state.error = null;
			})
			// Rejected
			.addCase(
				register.rejected,
				(
					state: IUserInitState,
					action: PayloadAction<
						unknown,
						string,
						TActionType<{}, TRegisterUserArgs>['rejectedMeta']
					>
				) => {
					state.loading = false;
					state.error = action.payload as string;
				}
			)
			/** ------Login-------- */
			// Rejected
			.addCase(
				login.rejected,
				(
					state: IUserInitState,
					action: PayloadAction<
						unknown,
						string,
						TActionType<{}, TLoginUserArgs>['rejectedMeta']
					>
				) => {
					state.loading = false;
					state.error = action.payload as string;
				}
			)
			/** ---------Load User---- */
			// pending
			.addCase(loadUser.pending, (state: IUserInitState) => {
				state.loading = true;
				state.error = null;
			})
			// fulfilled
			.addCase(
				loadUser.fulfilled,
				(
					state: IUserInitState,
					action: PayloadAction<TActionType<IUser | null, {}>['payload']>
				) => {
					state.loading = false;
					if (action.payload) state.data = action.payload;
					state.error = null;
				}
			)
			// rejected
			.addCase(
				loadUser.rejected,
				(
					state: IUserInitState,
					action: PayloadAction<
						unknown,
						string,
						TActionType<{}, { email: string }>['rejectedMeta']
					>
				) => {
					state.loading = false;
					state.error = action.payload as string;
				}
			)
			/** -------Logout------ */
			// fulfilled
			.addCase(logout.fulfilled, (state: IUserInitState) => {
				state.data = initialUser;
				state.loading = false;
				state.error = null;
			})
			/** ------ Edit User------ */
			// pending - updating user optimistically
			.addCase(
				editUser.pending,
				(
					state: IUserInitState,
					action: PayloadAction<
						undefined,
						string,
						TActionType<{}, TEditUserArgs>['pendingMeta']
					>
				) => {
					const { update } = action.meta.arg.data;
					state.data = { ...state.data, ...update };
				}
			)
			// rejected - revert back changes to previous state
			.addCase(
				editUser.rejected,
				(
					state: IUserInitState,
					action: PayloadAction<
						unknown,
						string,
						TActionType<{}, TEditUserArgs>['rejectedMeta']
					>
				) => {
					const { prevState } = action.meta.arg;
					state.data = { ...state.data, ...prevState };
					state.error = action.payload as string;
				}
			)
			/** ----------- Edit user details --------- */
			// pending - updating user details optimistically
			.addCase(
				editUserDetails.pending,
				(
					state: IUserInitState,
					action: PayloadAction<
						undefined,
						string,
						TActionType<{}, TEditUserDetailsArgs>['pendingMeta']
					>
				) => {
					const userDetails: IUserDetailNode[] = (state.data
						.userDetails as IUserDetailNode[]) || [{}];
					userDetails[0] = { ...userDetails[0], ...action.meta.arg.data.update };
					state.data.userDetails = userDetails;
					state.error = null;
				}
			)
			// rejected - revert back changes to previous state
			.addCase(
				editUserDetails.rejected,
				(
					state: IUserInitState,
					action: PayloadAction<
						unknown,
						string,
						TActionType<{}, TEditUserDetailsArgs>['rejectedMeta']
					>
				) => {
					state.data.userDetails = action.meta.arg.prevState;
					state.error = action.payload as string;
				}
			)
			.addCase(
				addProjectGroup.pending,
				(
					state: IUserInitState,
					action: PayloadAction<
						undefined,
						string,
						TActionType<{}, TAddProjectGroup>['pendingMeta']
					>
				) => {
					const { projectDetails, projectIds, type } = action.meta.arg;
					const {
						defaultProjectGroup: defaultProjectGroupId,
						projectGroups,
						sharedProjects
					} = state.data;

					let defaultProjectsToGroup: IProject[] = [];
					let sharedProjectsToGroup: IProject[] = [];
					const updatedProjectGroups = [...(projectGroups || [])] as IProjectGroup[];

					// Find the position of the project in the group
					const defaultProjectGroupIndex: number = (
						projectGroups as IProjectGroup[]
					).findIndex((pG) => pG._id === defaultProjectGroupId);

					// if it exixts in default project Group
					if (defaultProjectGroupIndex !== -1) {
						// if any project in the default project group is among the projectIds, return that
						const defaultProjects =
							(
								(projectGroups as IProjectGroup[])[
									defaultProjectGroupIndex
								] as IProjectGroup
							)?.projects || [];
						defaultProjectsToGroup = defaultProjects?.filter((project) =>
							projectIds?.includes((project as IProject)?._id as string)
						) as IProject[];

						// Remove the project if found in the default group
						(updatedProjectGroups[defaultProjectGroupIndex] as IProjectGroup).projects =
							(
								(updatedProjectGroups[defaultProjectGroupIndex] as IProjectGroup)
									?.projects as IProject[]
							).filter((project) => !projectIds.includes(project._id as string));
						// Update the state with the modified projectGroups array
						state.data.projectGroups = updatedProjectGroups;
					}

					// Find all shared projects in the list if any
					sharedProjectsToGroup = (sharedProjects as IProject[])?.filter((project) =>
						projectIds?.includes(project._id as string)
					);
					// Remove the shared projects from the shared projects list
					state.data.sharedProjects = (state.data.sharedProjects as IProject[])?.filter(
						(project) => !projectIds.includes(project._id as string)
					);

					const newGroup = {
						createdAt: new Date(),
						updatedAt: new Date(),
						projects: [
							...(defaultProjectsToGroup || []),
							...(sharedProjectsToGroup || [])
						],
						...projectDetails
					};

					// if it's a new group, update the project groups list, otherwise, update the projects list for that project
					if (type === 'NEW_GROUP') {
						state.data.projectGroups =
							state.data && state.data.projectGroups
								? [...state.data.projectGroups, newGroup as IProjectGroup]
								: [newGroup as IProjectGroup];
					} else {
						// find the group to update
						const projectGroupToUpdate = (state.data.projectGroups || []).findIndex(
							(pG) => (pG as IProjectGroup)?._id === projectDetails?._id
						);

						if (projectGroupToUpdate !== -1) {
							(
								(updatedProjectGroups[projectGroupToUpdate] as IProjectGroup)
									.projects || []
							).push(...defaultProjectsToGroup, ...sharedProjectsToGroup);
							// Update the projectGroups array in state.data
							state.data.projectGroups = updatedProjectGroups;
						}
					}
				}
			)
			.addCase(
				addProjectGroup.rejected,
				(
					state: IUserInitState,
					action: PayloadAction<
						unknown,
						string,
						TActionType<{}, TAddProjectGroup>['rejectedMeta']
					>
				) => {
					state.data.projectGroups = action.meta.arg.prevState.projectGroups;
					state.data.sharedProjects = action.meta.arg.prevState.sharedProjects;
					state.error = action.payload as string;
				}
			)
			/** ----------- Remove Project Group --------- */
			// pending - remove project group optimistically
			.addCase(
				removeProjectGroup.pending,
				(
					state: IUserInitState,
					action: PayloadAction<
						undefined,
						string,
						TActionType<{}, TRemoveProjectGroup>['pendingMeta']
					>
				) => {
					const { defaultProjectGroup: defaultProjectGroupId, sharedProjects } =
						state.data;
					const { newSharedProjects, newDefaultProjects, filteredGroups } =
						action.meta.arg;

					const updatedProjectGroups = cloneDeep([...filteredGroups]);

					// Find the position of the project in the group
					const defaultProjectGroupIndex: number = (
						filteredGroups as IProjectGroup[]
					).findIndex((pG) => pG._id === defaultProjectGroupId);

					// if it exixts in default project Group
					if (defaultProjectGroupIndex !== -1) {
						// Get a reference to the target project group's projects
						const existingProjects =
							(updatedProjectGroups[defaultProjectGroupIndex] as IProjectGroup)
								.projects || [];

						// Combine the existing projects with the new default projects
						(updatedProjectGroups[defaultProjectGroupIndex] as IProjectGroup).projects =
							[...existingProjects, ...newDefaultProjects];
					}

					state.data.sharedProjects = [...(sharedProjects || []), ...newSharedProjects];
					state.data.projectGroups = [...updatedProjectGroups];
					state.error = null;
				}
			)
			// rejected - revert back changes to previous state
			.addCase(
				removeProjectGroup.rejected,
				(
					state: IUserInitState,
					action: PayloadAction<
						unknown,
						string,
						TActionType<{}, TRemoveProjectGroup>['rejectedMeta']
					>
				) => {
					state.data.projectGroups = action.meta.arg.prevState.projectGroups;
					state.data.sharedProjects = action.meta.arg.prevState.sharedProjects;
					state.error = action.payload as string;
				}
			)
			.addCase(
				loadProjectById.fulfilled,
				(
					state: IUserInitState,
					action: PayloadAction<TActionType<TLoadProjectByIdFulfill, {}>['payload']>
				) => {
					const { project, isGroupOpened } = action.payload;
					const projectId = project?._id as string;
					const onHomebase = checkIfUserOnHomebase();
					if (!onHomebase)
						updateLastUpdatedAtToUserData(state.data, projectId, isGroupOpened);
				}
			)
			.addCase(
				createProject.fulfilled,
				(
					state: IUserInitState,
					action: PayloadAction<
						TActionType<TCreateProjectFulfill, {}>['payload'],
						string,
						TActionType<{}, TCreateProjectThunkArg>['fulfilledMeta']
					>
				) => {
					const { optimisticProject: project } = action.meta.arg.payload;
					const user = state.data;

					// Create a partial user to insert in project.user, to avoid complete user insertion
					const partialUser = {
						_id: user._id,
						email: user.email,
						userName: user.userName,
						profilePic: user.profilePic,
						firstName: user.firstName,
						lastName: user.lastName,
						userPreferences: user.userPreferences
					};
					project.createdAt = new Date().toISOString();
					project.updatedAt = new Date().toISOString();
					project.users = [
						{
							user: partialUser,
							role: 'OWNER'
						}
					];
					const defaultProjectGroupId = user.defaultProjectGroup as string;
					const updatedProjectGroups = user.projectGroups as IProjectGroup[];

					// Find the index of the default project group
					const defaultProjectGroupIndex = updatedProjectGroups.findIndex(
						(pG) => (pG as IProjectGroup)._id === defaultProjectGroupId
					);

					// If it exists in default project group
					if (defaultProjectGroupIndex !== -1) {
						// Safely access the existing projects, ensuring it's an array, then append new projects
						const currentProjects =
							(updatedProjectGroups[defaultProjectGroupIndex] as IProjectGroup)
								?.projects || [];
						(updatedProjectGroups[defaultProjectGroupIndex] as IProjectGroup).projects =
							currentProjects.concat(project);
					}

					// Update the state with the modified projectGroups array
					state.data.projectGroups = updatedProjectGroups;
				}
			)
			/** ---- EDIT PROJECT DETAILS ---- */
			// FULFILLED
			.addCase(
				editProjectDetails.fulfilled,
				(
					state: IUserInitState,
					action: PayloadAction<
						TActionType<TEditProjectDetailsThunkArg['payload'], {}>['payload']
					>
				) => {
					const { payload } = action;

					if (payload) {
						const { projectId, parentGroupId, updates } = payload;

						if (parentGroupId) {
							// On project rename fulfillment, update the projects name in user's project groups in redux
							const updatedUser = updateProjectDetailsForUser(
								state.data,
								projectId,
								parentGroupId,
								false,
								updates
							);

							state.data = updatedUser;
						}
					}
				}
			)
			// REJECTED
			.addCase(
				editProjectDetails.rejected,
				(
					state: IUserInitState,
					action: PayloadAction<
						unknown,
						string,
						TActionType<{}, TEditProjectDetailsThunkArg>['rejectedMeta']
					>
				) => {
					const { payload, prevState } = action.meta.arg;

					if (payload) {
						const { projectId, parentGroupId, updates } = payload;

						if (parentGroupId && prevState && prevState.name) {
							// On project rename rejection, update the projects name in user's project groups in redux with old name
							const newUpdates = {
								...updates,
								name: prevState.name
							};

							const updatedUser = updateProjectDetailsForUser(
								state.data,
								projectId,
								parentGroupId,
								false,
								newUpdates
							);

							state.data = updatedUser;
						}
					}
				}
			)
			/** ---- EDIT GROUP DETAILS ---- */
			// FULFILLED
			.addCase(
				editGroupProjectDetails.fulfilled,
				(
					state: IUserInitState,
					action: PayloadAction<
						TActionType<TEditProjectDetailsThunkArg['payload'], {}>['payload']
					>
				) => {
					const { payload } = action;

					if (payload) {
						const { projectId, updates } = payload;

						if (updates._id) {
							// On group rename fulfillment, update the projects name in user's project groups in redux
							const updatedUser = updateProjectDetailsForUser(
								state.data,
								projectId,
								updates._id as string, // Pass updates._id as group id since parentGroupId
								// would be default id if a group on highest level is renamed
								true,
								updates
							);

							state.data = updatedUser;
						}
					}
				}
			)
			// REJECTED
			.addCase(
				editGroupProjectDetails.rejected,
				(
					state: IUserInitState,
					action: PayloadAction<
						unknown,
						string,
						TActionType<{}, TEditProjectDetailsThunkArg>['rejectedMeta']
					>
				) => {
					const { payload, prevState } = action.meta.arg;

					if (payload) {
						const { projectId, updates } = payload;

						if (updates._id && prevState && prevState.name) {
							// On group rename fulfillment, update the projects name in user's project groups in redux with old name
							const newUpdates = {
								...updates,
								name: prevState.name
							};

							const updatedUser = updateProjectDetailsForUser(
								state.data,
								projectId,
								updates._id as string, // Pass updates._id as group id since parentGroupId
								// would be default id if a group on highest level is renamed
								true,
								newUpdates
							);

							state.data = updatedUser;
						}
					}
				}
			)
			/** ---- EDIT USER PREFERENCE ---- */
			// FULFILLED
			.addCase(
				editUserPreferences.fulfilled,
				(
					state: IUserInitState,
					action: PayloadAction<TActionType<IUserPreferenceNode, {}>['payload']>
				) => {
					if (action?.payload)
						state.data.userPreferences = {
							...(state.data.userPreferences as IUserPreferenceNode),
							...action.payload
						};
				}
			)
			// PENDING
			.addCase(
				editUserPreferences.pending,
				(
					state: IUserInitState,
					action: PayloadAction<
						undefined,
						string,
						TActionType<{}, TEditUserPreferenceThunkArgs>['pendingMeta']
					>
				) => {
					const { payload } = action.meta.arg;

					if (payload?.journeyZoom || payload?.studioView)
						state.data.userPreferences = {
							...(state.data.userPreferences as IUserPreferenceNode),
							...action.meta.arg.payload
						};
				}
			)
			// REJECTED
			.addCase(
				editUserPreferences.rejected,
				(
					state: IUserInitState,
					action: PayloadAction<
						unknown,
						string,
						TActionType<{}, TEditUserPreferenceThunkArgs>['rejectedMeta']
					>
				) => {
					const { prevState } = action.meta.arg;

					state.data.userPreferences = prevState.preferences;
				}
			)

			/** ---- DUPLICATE PROJECT OPTIMISTICALLY---- */
			// FULFILLED
			.addCase(
				duplicateProjectOptimistically.fulfilled,
				(
					state: IUserInitState,
					action: PayloadAction<
						TActionType<
							TDuplicateProjectOptimisticallyThunkArg['payload'],
							{}
						>['payload']
					>
				) => {
					const { clonedItems, parentGroupId, populatedUser } = action.payload;
					const { project } = clonedItems;

					if (parentGroupId) {
						const allGroups = state.data.projectGroups as IProjectGroup[];
						const parentGroupIndex = allGroups.findIndex(
							(grp) => grp._id === parentGroupId
						);

						const activeGroup = allGroups[parentGroupIndex];
						activeGroup!.projects?.push({
							...project,
							users: [
								{
									user: populatedUser,
									role: 'OWNER'
								}
							]
						});
					}
				}
			);
	}
});

export const {
	setUser,
	updateUser,
	resetUser,
	updateDefaultGroup,
	setDefaultGroup,
	deleteProjectForUser,
	revertDeletedProjectForUser,
	removeGroup
} = userSlice.actions;

export default userSlice.reducer;
