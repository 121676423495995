import { useContext, useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ISnackBar, ReduxState } from 'src/redux/reducers/root.types';
import { getAllUsersBasedOnIds, getFeedbackBasedOnBlockType } from 'src/redux/reduxActions/util';
import {
	EAiIngestStatusType,
	EBlockType,
	EFeedbackType,
	IBlock,
	TActionItemFor,
	TPollOption,
	TUserAndRole
} from '@naya_studio/types';
import {
	TAddCommentFulfill,
	TAddCommentNotificationArg,
	TAddCommentThunkArg
} from 'src/types/argTypes';
import { addComment, addCommentsNotification } from 'src/redux/reduxActions/feedbacks';
import { CustomDispatch } from 'src/redux/actions/types';
import {
	Button,
	FeedbackInput,
	FeedbackWrapper,
	PollOption,
	PollOptionsWrapper,
	ReactionPanel,
	UserT
} from '@naya_studio/radix-ui';
import useFeedbackActions from 'src/util/feedback/useFeedbackActions';
import { useParams } from 'react-router';
import { PathParamsType } from 'src/types/pathParams';
import './NewFeedback.scss';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';
import { resetNewCommentData } from 'src/redux/features/newComment';
import _ from 'lodash';
import mongoose from 'mongoose';
import { FeedbackContext } from 'src/components/expandedBlockView/ExpandedBlockView';
import useUser, { useAIUser } from 'src/redux/hooks/user';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
	addAIFeedbackReply,
	EAIReplyType,
	EMultiselectAIOpt,
	FeedbackAISnackbars,
	handleUnindentifiedIntent,
	identifyAIIntent
} from 'src/util/block/utilAI';
import { CollaborationToolContext } from 'src/components/collaborationTool/CollaborationTool';
import trackEvent from 'src/util/analytics/analytics';
import { CustomEvents } from 'src/util/analytics/events';
import useProject from 'src/redux/hooks/useProject';
import { toggleAIStatus } from 'src/redux/reduxActions/project';
import { ActionItemPanel } from '../actionItemPanel/ActionItemPanel';
import { getFormattedUsersData } from '../util';
import reactionIcon from '../../../assets/icons/toolbar/reaction.svg';

// props for new feedback component
export type NewFeedbackProps = {
	text: string; // initial text to be displayed in the input
	resetFeedbackType: (text: string) => void; // callback to reset feedback type when user clicks cancel
	feedbackType: EFeedbackType;
	getNewFeedbackPosition: () => { x: number; y: number; z?: number };
};

/**
 * Renders new chat/Poll/Q&A component
 */
export const NewFeedback = ({
	text,
	resetFeedbackType,
	feedbackType,
	getNewFeedbackPosition
}: NewFeedbackProps) => {
	const [feedbackText, setFeedbackText] = useState(text); // to hold the feedback text typed by the user
	const [selectedUsers, setSelectedUsers] = useState<string[]>([]); // holds the list of users seleted as assignees for action item
	const [pollOptions, setPollOptions] = useState<{ [key: string]: string }>({
		[new mongoose.Types.ObjectId().toString()]: '',
		[new mongoose.Types.ObjectId().toString()]: '',
		[new mongoose.Types.ObjectId().toString()]: ''
	}); // default list of poll options to show
	const [showEmojisPanel, setShowEmojiPanel] = useState(false); // holds state for showing emoji panel
	// TODO: see if can remove this
	const newComment = useSelector((state) => (state as ReduxState).newComment);
	// Feedback input ref
	const feedbackInputRef = useRef<{ onAppendText: (text: string) => void }>();

	// extarcting current user from redux
	const { user } = useUser();

	const { aiUser } = useAIUser();

	// extracting ids from url
	const { canvasId: blockId, projectId, stageOrDashboard: stageId } = useParams<PathParamsType>();

	const { setActiveFeedbackId } = useContext(FeedbackContext);

	const { project } = useProject(projectId);

	const { isFeedbackAiEnabled, journeyRtc } = useFlags();

	// extracting current block
	const block = useSelector(
		(state) => (state as ReduxState).blocks.data[blockId],
		shallowEqual
	) as IBlock;

	// extract all TUserAndRoles
	const userRoles = block.users as TUserAndRole[];

	// extracting all the populated users based on ids
	const allUsers = getAllUsersBasedOnIds(userRoles.map((x: TUserAndRole) => x.user as string));

	// extarcting functions from feedback actions hook
	const { generateOptimisticFeedback, getTaggedUsersFromHtmlString, extractTextFromHTML } =
		useFeedbackActions();

	const { handleMultiselectAIWrapper, updateMultiselectAiProgressSnackbar, isGuestUser } =
		useContext(CollaborationToolContext);

	const dispatch = useDispatch();

	// setting only current user as selected by default
	useEffect(() => {
		if (user?._id) {
			setSelectedUsers([user?._id as string]);
		}
	}, [user]);

	const checkIfFeedbackHasAssignees = (createdBy: string, assignees: string[]) => {
		if (!assignees.length) return false;

		return _.some(assignees, (u) => u !== createdBy);
	};

	// send out an api call to create feedback based upon feedback type
	const createNewFeedback = async () => {
		const newActionItemFor = {} as TActionItemFor;
		selectedUsers.forEach((u: string) => {
			newActionItemFor[u] = false;
		});

		const tempTaggedUsers = getTaggedUsersFromHtmlString(feedbackText);
		const isAIUserTagged = tempTaggedUsers.includes(aiUser._id as string);
		const taggedUsers = tempTaggedUsers.filter((x) => x !== aiUser._id);

		const oldComments = getFeedbackBasedOnBlockType(blockId);
		// temporary feedback data
		const optimisticFeedbackData = generateOptimisticFeedback();
		optimisticFeedbackData.feedbackType = feedbackType;
		optimisticFeedbackData.statement = feedbackText;
		optimisticFeedbackData.actionItemFor = newActionItemFor;
		optimisticFeedbackData.taggedUsers = taggedUsers;
		optimisticFeedbackData.isActionItem = true;
		optimisticFeedbackData.absoluteBounds = {
			...getNewFeedbackPosition()
		};
		if (block.blockType === 'THREE_D') {
			optimisticFeedbackData.camera = newComment.data?.initialComment?.camera;
			setActiveFeedbackId(optimisticFeedbackData._id as string);
		}
		if (block.blockType === 'VIDEO') {
			optimisticFeedbackData.timeStamp = newComment.data?.initialComment?.timeStamp;
			setActiveFeedbackId(optimisticFeedbackData._id as string);
		}
		optimisticFeedbackData.createdBy = user._id as string;
		optimisticFeedbackData.createdAt = new Date();

		// if feedback type is poll, set poll pollOptions in payload
		if (feedbackType === 'POLL') {
			const tempOptions: TPollOption[] = [];
			const pollOptionVal = Object.values(pollOptions);
			for (let i = 0; i < pollOptionVal.length; i++) {
				const option = pollOptionVal[i];
				if (option && option.length)
					tempOptions.push({
						option,
						votes: []
					});
			}
			optimisticFeedbackData.pollOptions = tempOptions;
		}

		// payload
		const apiPayload: TAddCommentThunkArg = {
			payload: {
				feedback: optimisticFeedbackData,
				blockId,
				stageId,
				projectId,
				userId: user._id,
				currentUser: user
			},
			prevState: {
				oldComments
			}
		};
		// Remove the new comment bubble after posting feedback
		dispatch(resetNewCommentData());

		dispatch({
			type: 'OPEN_COMMENT',
			payload: { commentId: optimisticFeedbackData._id }
		});

		// add comment api call
		(dispatch as CustomDispatch)(addComment(apiPayload))
			.unwrap()
			.then((response: TAddCommentFulfill) => {
				if (
					checkIfFeedbackHasAssignees(user._id as string, selectedUsers) &&
					!isAIUserTagged
				) {
					const snackbarPayload: ISnackBar = {
						text: 'Comment successfully assigned as task.',
						show: true,
						type: 'NORMAL'
					};

					addSnackbar(snackbarPayload);
					removeSnackbar(2000);
				}

				if (!isAIUserTagged) {
					// send out notifications
					(dispatch as CustomDispatch)(
						addCommentsNotification(response as TAddCommentNotificationArg)
					);
				}
			})
			.catch((error) => {
				const snackbarPayload: ISnackBar = {
					text: 'Failed to add comment.',
					show: true,
					type: 'ERROR'
				};

				addSnackbar(snackbarPayload);
				removeSnackbar(2000);

				console.error('Failed to add comment : ', error);
			});

		if (isAIUserTagged) {
			// Track event for AI tracking
			trackEvent(CustomEvents.TAGGED_AI);
			const { aiIngestStatus } = project || {};
			// if content training is off, add appropriate feedback reply
			if (!aiIngestStatus || aiIngestStatus === EAiIngestStatusType.INACTIVE) {
				addAIFeedbackReply(
					generateOptimisticFeedback(),
					projectId,
					stageId,
					blockId,
					EAIReplyType.CONTENT_TRAINING_OFF,
					journeyRtc,
					optimisticFeedbackData._id as string
				);

				// show contet training off snackbar with an option to enable it
				updateMultiselectAiProgressSnackbar({
					start: 0,
					end: 0,
					msg: FeedbackAISnackbars.CONTENT_TRAINING_INACTIVE,
					snackbarType: 'ERROR',
					buttonMsg: 'Enable',
					forceAdd: true,
					showAiIconAtStart: false,
					buttonAction: () => {
						// turn on content training
						dispatch(
							toggleAIStatus({
								projectId,
								isContentTraningEnabled: true,
								isAIFeaturesEnabled: true
							})
						);
						window.sessionStorage.setItem(
							'ingestion-triggered-from-feedback',
							projectId
						);
						// show loader snackbar
						updateMultiselectAiProgressSnackbar({
							start: 0,
							end: 0,
							snackbarType: 'LOADER',
							msg: FeedbackAISnackbars.CONTENT_TRAINING_STARTED,
							forceAdd: true
						});
					}
				});
			}
			// if ai ingest is in progress, add appropriate reply and show loading snackbar
			else if (aiIngestStatus === EAiIngestStatusType.IN_PROGRESS) {
				addAIFeedbackReply(
					generateOptimisticFeedback(),
					projectId,
					stageId,
					blockId,
					EAIReplyType.CONTENT_TRAINING_IN_PROGRESS,
					journeyRtc,
					optimisticFeedbackData._id as string
				);
				updateMultiselectAiProgressSnackbar({
					start: 0,
					end: 0,
					msg: FeedbackAISnackbars.CONTENT_TRAINING_INPROGRESS,
					snackbarType: 'LOADER',
					forceAdd: true
				});
			}
			// if ai ingest is ready, identify the ai intent and perform action acoordingly
			else {
				const trimmedText = extractTextFromHTML(feedbackText).replace('@AI', '').trim();
				const intent = await identifyAIIntent(trimmedText, block.blockType as EBlockType);
				if (intent.includes('NO ACTION FOUND')) {
					let type = EAIReplyType.EMPTY;
					if (trimmedText) type = EAIReplyType.UNCLEAR;
					handleUnindentifiedIntent(
						generateOptimisticFeedback(),
						projectId,
						stageId,
						blockId,
						journeyRtc,
						type,
						optimisticFeedbackData._id as string
					);
				} else {
					handleMultiselectAIWrapper(
						intent as EMultiselectAIOpt,
						[blockId],
						[],
						undefined,
						1,
						trimmedText
					);
					window.sessionStorage.setItem(
						'ai-feedback-reply',
						JSON.stringify({
							parent: optimisticFeedbackData._id as string,
							aiType: intent,
							blockId,
							stageId
						})
					);
				}
			}
		}
	};

	// function to modify action item asigneed
	const onTagUser = (selectedIds: string[]) => {
		const tempList = selectedUsers;
		for (let i = 0; i < selectedIds.length; i++) {
			if (
				!selectedUsers.includes(selectedIds[i] as string) &&
				selectedIds[i] !== aiUser._id
			) {
				tempList.push(selectedIds[i] as string);
			}
		}
		setSelectedUsers(tempList);
	};

	// function to extract all dupliacte pollOptions list
	const getDuplicateOptions = () => {
		const duplicates = Object.values(pollOptions).filter(
			(opt: string, index: number) =>
				opt.length && Object.values(pollOptions).indexOf(opt) !== index
		);

		return duplicates;
	};

	// rendering poll pollOptions if feedback type is poll
	const getPollOptions = () => (
		<>
			<PollOptionsWrapper
				className="tw-pr-4"
				style={{ marginRight: '-1rem', maxHeight: '200px', marginLeft: '-4px' }}
			>
				{Object.keys(pollOptions).map((id: string, index: number) => (
					<PollOption
						placeHolder={`Option ${index + 1}`}
						text={pollOptions[id]!}
						className={
							getDuplicateOptions().includes(pollOptions[id]!.trim())
								? 'option-error'
								: ''
						}
						onTextChange={(val: string) => {
							const temp = { ...pollOptions };
							temp[id] = val.trim();
							setPollOptions(temp);
						}}
						onRemove={() => {
							const temp = { ...pollOptions };
							if (Object.keys(pollOptions).length < 3) {
								temp[id] = '';
							} else {
								delete temp[id];
							}
							setPollOptions(temp);
						}}
						key={id}
						onSelect={() => {}}
						isEditState
						isRequired={Object.keys(pollOptions).length === 2}
						isSelected={false}
					/>
				))}
			</PollOptionsWrapper>
			{/* Add new option button which just adds a new empty option */}
			<Button
				variant="SECONDARY"
				text="+ Add option"
				disabled={false}
				className="tw-mt-2"
				onBtnClick={() => {
					setPollOptions({
						...pollOptions,
						[new mongoose.Types.ObjectId().toString()]: ''
					});
				}}
			/>
		</>
	);

	/**
	 * Function to append/add emoji to input field
	 * @param emoji string
	 */
	const handleEmoji = (emoji: string) => {
		if (feedbackInputRef.current) {
			feedbackInputRef.current.onAppendText(emoji);
		}
	};

	// function to extract placeholder text based upon feedback type
	const getPlaceHolderTextBasedOnFeedbacktype = () => {
		switch (feedbackType) {
			case EFeedbackType.POLL:
				return 'Add a question to create poll';
			case EFeedbackType.QNA:
				return 'Add a question for short answer response';
			case EFeedbackType.ACT_ITEM:
				return 'Add action item. Use @ to mention';
			case EFeedbackType.CHAT:
			default:
				return 'Add a comment, or @AI with a command like “summarize”, “evaluate”, or “generate concepts”. ';
		}
	};

	// Sends out an api call updated selected users for action item
	const onModifySelectedUsers = (id: string) => {
		let tempUsers = selectedUsers;
		if (selectedUsers.includes(id)) {
			tempUsers = tempUsers.filter((x) => x !== id);
		} else if (id !== aiUser._id) {
			tempUsers.push(id);
		}
		setSelectedUsers(tempUsers);
	};

	useEffect(() => {
		// If there are duplicate poll option then show indication snackbar
		if (getDuplicateOptions().length) {
			addSnackbar({
				text: 'Each poll option must have a unique value',
				type: 'ERROR',
				show: true
			});
			removeSnackbar(5000);
		}
	}, [pollOptions]);

	return (
		<FeedbackWrapper className="tw-bg-white">
			<>
				{/* Action item panel */}
				<ActionItemPanel
					markCompleteDisabled={false}
					onMarkComplete={() => {}}
					users={allUsers}
					selectedUsers={selectedUsers}
					onModifySelectedUsers={onModifySelectedUsers}
					isCompleted={false}
					key="new-feedback"
					isNewFeedback
					onClose={() => {}}
				/>
				<div
					className={
						[EFeedbackType.CHAT].includes(feedbackType)
							? 'tw-pl-4 tw-pr-4 tw-pb-4'
							: 'tw-p-4'
					}
				>
					{/* feedback input */}
					<FeedbackInput
						className={`
							${
								[EFeedbackType.QNA, EFeedbackType.POLL].includes(feedbackType)
									? 'tw-pb-4 tw-ml-2'
									: 'tw-pb-2 tw-ml-2'
							}
						`}
						id="new"
						text={feedbackText}
						placeHolder={getPlaceHolderTextBasedOnFeedbacktype()}
						onTagUser={onTagUser}
						users={
							getFormattedUsersData(
								allUsers,
								feedbackType === EFeedbackType.CHAT
									? isFeedbackAiEnabled && !isGuestUser
									: false
							).blockUsers as UserT[]
						}
						currentUser={
							getFormattedUsersData(allUsers, isFeedbackAiEnabled && !isGuestUser)
								.currentUser
						}
						variant={
							[EFeedbackType.QNA, EFeedbackType.POLL].includes(feedbackType)
								? 'BORDERED'
								: 'BORDERLESS'
						}
						onSubmit={createNewFeedback}
						onEsc={() => resetFeedbackType(feedbackText)}
						onInputChange={(value: string) => {
							setFeedbackText(value);
						}}
						ref={feedbackInputRef}
						onShowReplyBtn={() => {}}
						key="new"
						style={{
							marginLeft: `${
								![EFeedbackType.QNA, EFeedbackType.POLL].includes(feedbackType)
									? '-1rem'
									: '0'
							}`,
							minHeight: 64
						}}
					/>
					{feedbackType === EFeedbackType.POLL && getPollOptions()}
					{/* Action */}
					<div className="tw-flex tw-items-center">
						{feedbackType === EFeedbackType.CHAT && (
							<>
								{/* Emojis panel */}
								<img
									role="presentation"
									className="pointer"
									src={reactionIcon}
									alt="reaction"
									onClick={(e: any) => {
										e.stopPropagation();
										setShowEmojiPanel(!showEmojisPanel);
									}}
									onMouseUp={(e: any) => {
										e.stopPropagation();
									}}
									height={15}
								/>
							</>
						)}
						<div className="tw-flex tw-items-center" style={{ marginLeft: 'auto' }}>
							{/* post and cancel action button */}
							<Button
								className="tw-mr-2"
								variant="SECONDARY-GREY"
								disabled={false}
								text="Cancel"
								onBtnClick={() => resetFeedbackType(feedbackText)}
							/>
							<Button
								variant="PRIMARY"
								text="Post"
								disabled={
									extractTextFromHTML(feedbackText).length === 0 ||
									(feedbackType === 'POLL' &&
										(getDuplicateOptions().length > 0 ||
											Object.values(pollOptions).filter((opt) => opt.length)
												.length < 2))
								}
								onBtnClick={createNewFeedback}
							/>
						</div>
					</div>
					{showEmojisPanel && (
						<ReactionPanel
							onEmojiSelect={(emoji: string) => {
								handleEmoji(emoji);
							}}
							className="tw-mt-4"
						/>
					)}
				</div>
			</>
		</FeedbackWrapper>
	);
};
