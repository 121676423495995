import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { store } from 'src';
import { updatePrototypeState } from 'src/redux/actions/prototyping';
import { CustomDispatch } from 'src/redux/actions/types';
import { ReactComponent as SadIcon } from '../../../../../assets/prototyping/space-with-sad-emoji.svg';
import { FailedModalProps } from './PrototypingHomeProps';

export default function FailedModal({
  handleShowModel, show, text, reset,
}: FailedModalProps) {
  const history = useHistory();
  const handleHideModal = () => {
    handleShowModel(false);
    if (reset) {
      (store.dispatch as CustomDispatch)(
        updatePrototypeState(null),
      );
      history.replace('/prototyping/upload');
    }
  };
  return (
    <Modal className="failedModal" show={show} animation={false} onHide={handleHideModal}>
      <Modal.Header closeButton>
        <Modal.Title>3D Print Order</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SadIcon color="var(--theme-color-2)" />
        <p>
          Sorry!
          <br />
          { text }
        </p>
      </Modal.Body>
      <Modal.Footer>
        <p>
          Connect me to Naya support team -
          <a
            onClick={handleHideModal}
            href="https://mail.google.com/mail/?view=cm&fs=1&to=info@naya.studio"
            target="_blank"
            rel="noreferrer"
            style={{
              color: 'var(--theme-color-1)',
              fontSize: '12px',
            }}
          >
            info@naya.studio
          </a>
        </p>
      </Modal.Footer>
    </Modal>
  );
}
