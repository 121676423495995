import { IProject, TUserAndRole, TInvitedEmail } from '@naya_studio/types';
import { useDispatch } from 'react-redux';
import { updateProject } from 'src/redux/features/projects';
import { TChangeType, getYDoc } from 'src/rtc/yjs/yjsConfig';
import * as Y from 'yjs';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';
import { ISnackBar } from 'src/redux/reducers/root.types';
import { useHistory } from 'react-router';
import { isEqual } from 'lodash';
import { loadProjectById } from 'src/redux/reduxActions/project';
import getUserFromRedux from 'src/util/helper/user';
import { getProjectFromRedux } from 'src/util/helper/project';
import { updateUser } from 'src/redux/features/user';
import { store } from 'src';
import { updateGroup } from 'src/redux/features/groupDetail';
import { broadcastSelfDispatch, checkIfUserHasAccess } from './utils';

/**
 * Hook to update project in redux after yjs listens to project change
 */
const useProjectObserver = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	/**
	 * Function to notify to other socket users about project leave
	 */
	const notifyProjectLeave = (message: string, pid: string) => {
		const reduxState = store.getState();
		const { projectGroup } = reduxState;
		const user = getUserFromRedux();
		const snackbarData: ISnackBar = {
			text: message,
			type: 'NORMAL',
			show: true
		};

		addSnackbar(snackbarData);
		removeSnackbar(3000, () => {
			if (projectGroup.data._id) {
				const updatedProjects = (projectGroup.data.projects as IProject[]).filter(
					(p) => (p._id as string) !== pid
				);

				dispatch(
					updateGroup({
						projects: updatedProjects
					})
				);
			} else {
				// If the code has reached notifyProjectLeave function
				// and it is not in group that means user was invited to project,
				// so find project in shared projects
				const updatedProjects = (user.sharedProjects as IProject[]).filter(
					(p) => (p._id as string) !== pid
				);

				dispatch(
					updateUser({
						sharedProjects: updatedProjects
					})
				);
			}

			history.push('/studio');
		});
	};

	/**
	 * Observer to listen to project changes in ymap
	 * @param update project change to do in redux
	 */
	const projectObserver = (update: Y.YMapEvent<string>) => {
		try {
			const doc = getYDoc();
			const user = getUserFromRedux();

			update.changes.keys.forEach((change: TChangeType<string>, key: string) => {
				// Check if change is for this project
				if (change.action === 'update') {
					const projectData = JSON.parse(
						doc.getMap('project').get(key) as string
					) as IProject;
					// console.info('RTC - Received project update', projectData);
					const oldProject = JSON.parse(change.oldValue as string) as IProject;

					// populate project observers
					const reduxProject = getProjectFromRedux(projectData._id as string);
					projectData.observers = reduxProject.observers;

					// Adding check in edit case because
					// Project could be updated for updates done via email, slack
					const originatedFromNaya = projectData.origin === 'NAYA';
					const selfDispatch = projectData.lastUpdatedBy === (user._id as string);
					const isIngestChanged =
						projectData.aiIngestStatus !== oldProject.aiIngestStatus;

					// If user has access to the updated block
					const hasAccess =
						checkIfUserHasAccess(
							user,
							projectData.users as TUserAndRole[],
							projectData.invitedEmails as TInvitedEmail[]
						) || projectData?.hasGuestAccess;
					// If user had access to the block before update
					const hadAccess =
						checkIfUserHasAccess(
							user,
							oldProject.users as TUserAndRole[],
							oldProject.invitedEmails as TInvitedEmail[]
						) || oldProject?.hasGuestAccess;

					if (selfDispatch && originatedFromNaya) {
						// For case when we receive self update but we're missing that update in redux
						const newStages = projectData.children as string[];
						const oldStages = oldProject.children as string[];

						if (!isEqual(newStages, oldStages) || isIngestChanged)
							dispatch(updateProject(projectData));
						else broadcastSelfDispatch(projectData, 'EDIT', 'JOURNEY');
						return;
					}

					if (hasAccess) {
						// If guest access has been turned on and user exists on project
						// load complete project for him
						// Fix for scenario when block has guest access and then project guest access is enabled
						if (!hadAccess) dispatch(loadProjectById({ payload: { _id: key } }));
						else dispatch(updateProject(projectData));
					} else if (hadAccess) {
						notifyProjectLeave(
							"You have been removed from project. You'll be redirected to Journey shortly",
							key
						);
					}
				} else if (change.action === 'delete') {
					const projectData = JSON.parse(change.oldValue) as IProject;
					// console.info('RTC - Received project delete', projectData);
					const selfDispatch = projectData.lastUpdatedBy === (user._id as string);

					if (!selfDispatch)
						notifyProjectLeave(
							`${projectData.projectDetails?.name} deleted. You'll be redirected to Journey shortly`,
							key
						);
					else broadcastSelfDispatch(key, 'DELETE', 'JOURNEY');
				}
			});
		} catch (error) {
			console.error('Error in Project Listener : ', error);
		}
	};

	return projectObserver;
};

export default useProjectObserver;
