import { IUser } from '@naya_studio/types';
import mixpanel from 'mixpanel-browser';
import { isMixpanelInitialized } from './mixpanelInit';
import AnalyticEvents from './events';
import { PendoEventData } from './analytic.types';
import getUserFromRedux from '../helper/user';

/**
 * Function to send pendo track requests
 * @param eventName Name of the event
 * @param data event data
 */
const trackPendo = (eventName: AnalyticEvents, data: PendoEventData) => {
	try {
		const { pendo } = window as any;
		const pendoInitSuccess = pendo && pendo.isReady && pendo.isReady();
		if (pendoInitSuccess) pendo.track(eventName, data);
	} catch (error) {
		console.warn('Pendo not initialized or AdBlocker is enabled');
	}
};

/**
 * Function to send mixpanel track requests
 * @param eventName Name of the event
 * @param data event data
 */
const trackMixpanel = (eventName: AnalyticEvents, data: any) => {
	try {
		const mixpanelInitSuccess = mixpanel && isMixpanelInitialized();
		if (mixpanelInitSuccess) mixpanel.track(eventName, data);
	} catch (error) {
		console.warn('Mixpanel not initialized or AdBlocker is enabled');
	}
};

export default function trackEvent<A extends AnalyticEvents, T>(event: A, customProps?: T) {
	const currentUser: IUser = getUserFromRedux();
	const { _id: userId, email } = currentUser;

	// Construct the pendo request data
	const pendoEventData: PendoEventData = {
		visitorId: userId as string,
		accountId: email as string,
		timestamp: new Date().getTime()
	};

	/**
	 * Function to check if call is from test email
	 */
	const isTestEmail = (userEmail?: string) => {
		if (!userEmail) return false;

		return ['devops@naya.studio', 'devopstest@gmail.com', 'qa-user@naya.studio'].includes(
			userEmail
		);
	};

	if (!isTestEmail(email) && process.env.REACT_APP_PRODUCTION) {
		trackPendo(event, pendoEventData);
		trackMixpanel(event, customProps);
	}
}
