import axios from 'axios';
import { TGoogleSubtype } from 'src/util/integration/useIntegration';
import { getGatewayKey } from 'src/util/helper/queryString';
import { isLocalhost } from 'src/util/collaboration/util';
import { getGoogleTokensFromRedux } from 'src/util/helper/user';
import { observerRouter } from 'src/endpoints/projects-api';

export const GOOGLE_AUTH_URL =
	`https://accounts.google.com/o/oauth2/v2/auth?` +
	`client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}` +
	`&redirect_uri=${window.__RUNTIME_CONFIG__.REACT_APP_GOOGLE_REDIRECT_URI}${
		!isLocalhost ? `?${getGatewayKey()}` : ''
	}` +
	`&access_type=offline` +
	`&scope=https://www.googleapis.com/auth/userinfo.email` +
	` https://www.googleapis.com/auth/drive` +
	`&prompt=consent` +
	`&response_type=code`;

const GOOGLE_DRIVE_API = `${window.__RUNTIME_CONFIG__.REACT_APP_NAYA_PROXY}/https://www.googleapis.com/drive/v3`;
const GOOGLE_DRIVE_UPLOAD_API = `${window.__RUNTIME_CONFIG__.REACT_APP_NAYA_PROXY}/https://www.googleapis.com/upload/drive/v3`;
const REVOKE_GOOGLE_AUTH_URL = `${window.__RUNTIME_CONFIG__.REACT_APP_NAYA_PROXY}/https://oauth2.googleapis.com/revoke`;

export const SHEET_MIMETYPE = 'application/vnd.google-apps.spreadsheet';
export const DOC_MIMETYPE = 'application/vnd.google-apps.document';
export const SLIDE_MIMETYPE = 'application/vnd.google-apps.presentation';

/**
 * Escapes single quotes in a given string
 * This is useful to avoid syntax errors when the string is used in contexts where
 * single quotes have a special meaning, such as in constructing queries.
 *
 * @param {string} text - The text string that may contain single quotes.
 * @returns {string} - The text string with single quotes escaped.
 */
const escapeSingleQuotes = (text: string): string => text.replace(/'/g, "\\'");

/**
 * Function to extract google file ID
 * @param link - link of the file to extract ID from
 * @returns - ID extracted from link
 */
export const extractGoogleFileId = (link: string) => {
	// Regular expression to match Google Drive file IDs in various formats
	const idRegex =
		/(?:\/document\/d\/|\/spreadsheets\/d\/|\/presentation\/d\/|\/file\/d\/|open\?id=|id=)([a-zA-Z0-9-_]+)/;

	// Extract ID using regex
	const match = link.match(idRegex);

	// If a match is found, return the ID
	if (match && match[1]) {
		return match[1];
	}
	// If no match is found, return null
	return null;
};

/**
 * Check if a folder with the given name exists
 * @param accessToken
 * @param folderName - folder name to check in drive
 * @returns
 */
export const folderExistsInGoogle = async (
	accessToken: string,
	folderName: string,
	parentFolderId?: string
) => {
	const response = await axios.get(`${GOOGLE_DRIVE_API}/files`, {
		params: {
			q: `${
				parentFolderId ? `'${parentFolderId}' in parents and ` : ''
			}name='${escapeSingleQuotes(
				folderName
			)}' and mimeType='application/vnd.google-apps.folder'`,
			fields: 'files(id,name,trashed)',
			access_token: accessToken
		}
	});

	return response.data.files.length > 0 && !response.data.files[0].trashed
		? response.data.files[0].id
		: null;
};

/**
 * Create folder in Google Drive
 * @param accessToken
 * @param folderName
 * @param parentFolderId - for folder inside a folder
 * @returns ID of the existing or newly created folder
 */
export const checkOrCreateGoogleFolder = async (
	accessToken: string,
	folderName: string,
	parentFolderId?: string
) => {
	const folderMetadata = {
		name: folderName,
		parents: [parentFolderId],
		mimeType: 'application/vnd.google-apps.folder'
	};

	try {
		// checking if folder already exists
		const existingFolderId = await folderExistsInGoogle(
			accessToken,
			folderName,
			parentFolderId
		);
		if (existingFolderId) return existingFolderId;

		// create new folder
		const response = await axios.post(`${GOOGLE_DRIVE_API}/files`, folderMetadata, {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json'
			}
		});

		return response.data.id;
	} catch (error) {
		console.error('Error creating folder:', error);
		return null;
	}
};

/**
 * Function to generate google file IDs
 *
 * @param {string} accessToken - The access token for authentication
 * @param {number} count - no of IDs to generate
 * @returns
 */
export const generateGoogleFileIDs = async (accessToken: string, count: number = 1) => {
	try {
		const response = await axios.get(`${GOOGLE_DRIVE_API}/files/generateIds`, {
			params: { count },
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		});
		return response.data.ids;
	} catch (e) {
		console.error('generateGoogleFileIDs error: ', e);
		return [];
	}
};

/**
 *
 * @param {string} link - file link to be uploaded
 * @param {string} fileName - file name
 * @param {string} folderId - folder ID where the file will be uploaded
 * @param {string} projectId
 * @param {string} accessToken - The access token for authentication
 * @returns
 */
export const uploadFileLinkToDrive = async (
	link: string,
	fileName: string,
	folderId: string,
	projectId: string,
	accessToken: string,
	googleResourceId?: string
) => {
	try {
		if (!link) return null;
		const fileResponse = await axios.get(link, {
			responseType: 'arraybuffer'
		});
		const fileContent = fileResponse.data;
		const metaData = {
			...(googleResourceId ? { id: googleResourceId } : {}),
			name: fileName,
			parents: [folderId],
			description: projectId
		};
		const formData = new FormData();

		const metaDataJson = JSON.stringify(metaData);

		// Create a Blob with the JSON-stringified metaData
		const metaDataBlob = new Blob([metaDataJson], { type: 'application/json' });

		// Assuming fileContent is a string or Uint8Array representing the file data
		const fileBlob = new Blob([fileContent], { type: 'application/octet-stream' });

		formData.append('metadata', metaDataBlob);
		formData.append('file', fileBlob);
		// Upload the multipart request to Google Drive
		const driveResponse = await axios.post(
			`${GOOGLE_DRIVE_UPLOAD_API}/files?uploadType=multipart`,
			formData,
			{
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			}
		);
		return driveResponse.data.id;
	} catch (e) {
		console.error(`Error uploading file link: ${fileName},${link} to drive: `, e);

		return null;
	}
};

/**
 * Function to upload file to Google Drive
 *
 * @param {string} accessToken - The access token for authentication
 * @param {File} file - File to be uploaded to Google Drive
 * @param {string} googleResourceId - pre-generated Google File ID
 * @param {string} folderId - folder ID where the file will be uploaded
 * @returns
 */
export const uploadFileToDrive = async (
	accessToken: string,
	file: File,
	googleResourceId?: string,
	folderId?: string
) => {
	try {
		const metadata = {
			...(googleResourceId ? { id: googleResourceId } : {}),
			name: file.name,
			mimeType: file.type,
			parents: [folderId]
		};
		const form = new FormData();
		form.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
		form.append('file', file);
		const response = await axios.post(
			`${GOOGLE_DRIVE_UPLOAD_API}/files?uploadType=multipart`,
			form,
			{
				headers: {
					Authorization: `Bearer ${accessToken}`,
					'Content-Type': 'application/json'
				}
			}
		);

		return response.data.id;
	} catch (error) {
		console.error(`Error uploading file: ${file} to drive:`, error);
		return null;
	}
};

/**
 * Function to make a google file public
 * @param link - google file link
 * @param access_token - google access token
 * @returns
 */
export const publishGoogleFile = async (link: string, access_token: string) => {
	try {
		if (!link || !access_token) return false;

		// extract file ID
		const fileId = extractGoogleFileId(link);

		// send request for public access
		const response = await axios.post(
			`${GOOGLE_DRIVE_API}/files/${fileId}/permissions`,
			{
				type: 'anyone',
				role: 'writer'
			},
			{
				headers: {
					Authorization: `Bearer ${access_token}`,
					'Content-Type': 'application/json'
				}
			}
		);
		if (response.status === 200) return true;
		return false;
	} catch (error) {
		console.error('publishGoogleFile error: ', error);
		return false;
	}
};

/**
 * Create a file in GoogleDrive in a specified folder
 * @param subType - type of google file
 * @param access_token - google access token
 * @param projectId
 * @param fileName
 * @returns link of the newly created file
 */
export const createGoogleFile = async (
	subType: TGoogleSubtype,
	access_token: string,
	folderId: string,
	fileName: string,
	projectId: string
) => {
	try {
		// create a folder for Naya in user's google drive

		if (!folderId) throw new Error('Project folder not found !');
		let mimeType = '';
		switch (subType) {
			case 'SHEET':
				mimeType = SHEET_MIMETYPE;
				break;
			case 'SLIDE':
				mimeType = SLIDE_MIMETYPE;
				break;
			case 'DOC':
				mimeType = DOC_MIMETYPE;
				break;
			default:
				break;
		}
		const fileData = {
			name: fileName,
			parents: [folderId],
			mimeType,
			description: projectId || ''
		};

		// create file
		const createFileResponse = await axios
			.post(`${GOOGLE_DRIVE_API}/files`, fileData, {
				params: {
					fields: 'id,name,webViewLink'
				},
				headers: {
					Authorization: `Bearer ${access_token}`,
					'Content-Type': 'application/json'
				}
			})
			.then((res) => res)
			.catch((err) => err.response);
		if (createFileResponse.data.error)
			return { status: false, link: '', error: createFileResponse.data.error.message };
		// give the file public access
		await publishGoogleFile(createFileResponse.data.webViewLink, access_token);
		return {
			status: true,
			link: createFileResponse.data.webViewLink,
			id: createFileResponse.data.id
		};
	} catch (error) {
		console.error('Error creating file:', error);
		return { status: false, link: '' };
	}
};

/**
 * Rename a file in GoogleDrive in a specified folder
 * @param accessToken
 * @param fileLink - link of the file to be renamed
 * @param fileName
 * @returns
 */
export const renameGoogleFile = async (
	accessToken: string | null,
	fileLink: string,
	fileName: string
) => {
	try {
		// extract google file ID  from link
		const fileId = extractGoogleFileId(fileLink);

		if (!fileId) return false;

		const fileData = {
			name: fileName
		};

		const response = await axios.patch(`${GOOGLE_DRIVE_API}/files/${fileId}`, fileData, {
			params: {
				fields: 'id,name,webViewLink'
			},
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json'
			}
		});
		if (response.status === 200) return true;
		return false;
	} catch (error) {
		console.error('Error creating file:', error);
		return false;
	}
};

/**
 * Duplicate a file in GoogleDrive
 * @param accessToken
 * @param folderId - folder ID where the file will be placed
 * @param fileLink - link of the file to be duplicated
 * @param fileName - name of the duplicated file
 * @returns
 */
export const duplicateGoogleFile = async (
	accessToken: string,
	folderId: string,
	fileLink: string,
	fileName: string
) => {
	try {
		// extract google file ID  from link
		const fileId = extractGoogleFileId(fileLink);
		if (!fileId) return { status: false, link: '', id: '' };

		const fileData = {
			name: fileName,
			parents: [folderId]
		};
		const response = await axios
			.post(`${GOOGLE_DRIVE_API}/files/${fileId}/copy`, fileData, {
				params: {
					fields: 'id,name,webViewLink'
				},
				headers: {
					Authorization: `Bearer ${accessToken}`,
					'Content-Type': 'application/json'
				}
			})
			.then((res) => res)
			.catch((err) => err.response);

		if (response.status !== 200)
			return { status: false, link: '', id: '', error: response.data.error.message };

		// give the file public access
		await publishGoogleFile(response.data.webViewLink, accessToken);
		const newFile = response.data;
		return { status: true, link: newFile.webViewLink, id: newFile.id };
	} catch (error) {
		console.error('Error duplicating file: ', error);
		return { status: false, link: '', id: '' };
	}
};

/**
 * Revoke Google token
 * @param token
 * @returns
 */
export const revokeGoogle = async (token: string) => {
	try {
		const response = await axios.post(REVOKE_GOOGLE_AUTH_URL, null, {
			params: {
				token
			}
		});
		return response;
	} catch (error) {
		console.error('Error disconnecting google: ', error);
		return '';
	}
};

/**
 * Function to get google file details
 * @param accessToken
 * @param fileLink
 * @returns
 */
export const getGoogleFileDetails = async (accessToken: string, fileLink: string) => {
	try {
		// extracting file ID
		const fileId = extractGoogleFileId(fileLink);

		if (fileId) {
			const response = await axios.get(`${GOOGLE_DRIVE_API}/files/${fileId}`, {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			});
			return { status: true, data: response.data };
		}
		return { status: false };
	} catch (error) {
		console.error('getGoogleFileDetails error: ', error);
		return { status: false, data: null };
	}
};

/**
 * Function to check if Google folder exists and is not in trash
 *
 * @param {string} accessToken - The access token for authentication
 * @param {string} folderId
 * @returns
 */
export const folderIdExistsInGoogle = async (accessToken: string, folderId: string) => {
	try {
		if (!folderId) return false;
		const response = await axios.get(`${GOOGLE_DRIVE_API}/files/${folderId}?fields=trashed`, {
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		});
		return !response.data.trashed;
	} catch (e) {
		console.error('folderIdExistsInGoogle error: ', e);
		return false;
	}
};

/**
 * Get google file basic details by fileId
 *
 * used in journey to get thumbnails
 *
 * @param {string} folderId - The ID of the folder to get
 * @returns folder details
 */
export const getGoogleFileById = async (fileId: string) => {
	try {
		const accessToken = getGoogleTokensFromRedux().access_token;
		if (!accessToken || !fileId) return null;

		const response = await axios.get(`${GOOGLE_DRIVE_API}/files/${fileId}`, {
			params: {
				fields: 'id, name, webViewLink, thumbnailLink, iconLink'
			},
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		});

		return response.data;
	} catch (e) {
		console.error('getGoogleFolderById error: ', e);
		return null;
	}
};

/**
 * Function to disconnect all google drive channels
 */
export const stopAllDriveChannels = async (userId?: string) => {
	try {
		await axios.delete(
			`${observerRouter}/google/stop-all-channels?${getGatewayKey()}${
				userId ? `&userId=${userId}` : ''
			}`
		);
	} catch (e) {
		console.error('stopAllDriveChannels error: ', e);
	}
};
