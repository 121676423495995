import { FC, ReactElement, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import './UserProfileButton.scss';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ProjectIcon } from 'src/assets/icons/appTray/projects-icon.svg';
import { ReactComponent as ProfileIcon } from 'src/assets/icons/appTray/profile.svg';
import { ReactComponent as SignoutIcon } from 'src/assets/icons/appTray/signout.svg';
import { ReactComponent as IntegrationsIcon } from 'src/assets/icons/appTray/integrations.svg';
import { ReactComponent as ProductTourIcon } from 'src/assets/icons/appTray/Product-tour.svg';
import { AuthContext } from 'src/components/auth/AuthProvider';
import { DropDown, IconWithTooltip, UserProfileIcon } from '@naya_studio/radix-ui';
import { EUserType } from '@naya_studio/types';
import App from 'src/components/canvas/app/App';
import { getUserFirstName } from 'src/components/feedbackSuite/util';
import { ReactComponent as TickIcon } from 'src/assets/icons/tick.svg';
import { capitalize } from 'lodash';
import classNames from 'classnames';
import useWebSocketConnection from 'src/rtc/yjs/useWebSocketConnection';
import { isGuestEmail } from 'src/util/collaboration/util';
import { logout } from 'src/redux/actions/auth';
import useUser from 'src/redux/hooks/user';
import { setShowAppIntegrationModal } from 'src/redux/features/integration';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TLDFlags } from 'src/components/collaborationTool/CollaborationTool.types';
import { CollaborationToolContext } from 'src/components/collaborationTool/CollaborationTool';

const UserProfileButton: FC = (): ReactElement => {
	const authContext = useContext(AuthContext);
	const collabContext = useContext(CollaborationToolContext);
	const dispatch = useDispatch();
	const { createInNaya, isProductTourEnabled, isProfileOptionEnabled } = useFlags<TLDFlags>();

	const { user } = useUser();

	const { status, setStatus, updateHasGuestAccessTo } = authContext;
	const { onCollabUnMount } = collabContext;

	const [isDropdownActive, setIsDropdownActive] = useState<boolean>(false);
	const [connectionStatus, setConnectionStatus] = useState<'CONNECTED' | 'DISCONNECTED'>(
		'DISCONNECTED'
	);

	const isGuestUser = user && user.userType?.includes(EUserType.GUEST);
	// To determine whether user is logged in but unapproved
	const isUnapprovedUser = status === 'UNAPPROVED';

	// used to denote if the user is logged in
	const online = (user._id && (user.email || isGuestUser)) || status;

	/** Single letter of user identity to show in profile icon */
	const userDisplayName = (getUserFirstName(user) || user?.email || user?.userName)
		?.split('')[0]
		?.toUpperCase();

	const wsConnected = useWebSocketConnection();

	// Runs on first mount of component
	useEffect(() => {
		// Event listener to set status to disconnected if user goes offline
		const onOffline = () => setConnectionStatus('DISCONNECTED');

		window.addEventListener('offline', onOffline);

		return () => {
			setConnectionStatus('DISCONNECTED');
			window.removeEventListener('offline', onOffline);
		};
	}, []);

	// Re-runs whenever connection to socket is changed
	useEffect(() => {
		if (wsConnected) setConnectionStatus('CONNECTED');

		return () => setConnectionStatus('DISCONNECTED');
	}, [wsConnected]);

	/**
	 * Function to remove the trix-editor when clicked on profile button
	 */
	const removeTextInputNode = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();

		if (window.pixiApp) {
			(window.pixiApp as App).viewport.emit('node-selected', []);
			(window.pixiApp as App).toggleEditMenu(false);
			(window.pixiApp as App).selectedNodes = [];
		}
	};

	// IUser object
	const history = useHistory();
	const activeTab = window.location.pathname;

	/**
	 * Function to dispatch logout action
	 */
	const onLogout = (email: string) => {
		dispatch(
			logout({
				email,
				next: () => {
					setStatus('SIGNED_OUT');
					window.sessionStorage.removeItem('zoom');
				}
			})
		);
	};

	// Function to render sign out button
	const renderSignOutOption = (isGuest: boolean = false) => (
		<DropDown.DropDownItem>
			<div
				role="presentation"
				data-testid="sign-out"
				className={`option-item tw-w-full tw-cursor-pointer tw-flex 
        tw-flex-row tw-items-center tw-content-center tw-gap-2 ${isGuest && 'tw-p-2'}`}
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					if (isUnapprovedUser) onLogout('');
					else
						onCollabUnMount(() => {
							updateHasGuestAccessTo(false);
							onLogout(user.email as string);
						});
				}}
			>
				<SignoutIcon />
				<p className="tw-leading-6 tw-text-xs tw-m-0 tw-p-0 color-red">Sign out</p>
			</div>
		</DropDown.DropDownItem>
	);

	const getGuestUserMenuItems = () => (
		<div className="guest-user-menu tw-px-2 tw-w-full tw-flex tw-flex-col tw-gap-1">
			<p className="title tw-text-sm tw-leading-4 tw-m-0" style={{ marginTop: '2px' }}>
				Collaborating as
			</p>
			<div className="profile tw-flex tw-gap-2 tw-p-2 tw-items-center">
				{user._id && (
					<UserProfileIcon
						url={user.profilePic}
						displayName={userDisplayName}
						color="#4F00C1"
						className="tw-h-10 tw-w-10 tw-font-randMedium"
						style={{ flexShrink: 0, fontSize: '24px', lineHeight: 0 }}
					/>
				)}
				<IconWithTooltip
					tooltipText={
						<p className="tw-m-0 tw-flex tw-flex-col">
							<span>{user && capitalize(getUserFirstName(user))}</span>
							{user?.email && !isGuestEmail(user.email) && <span>{user.email}</span>}
						</p>
					}
					side="bottom"
				>
					<div
						className="details tw-flex tw-flex-col tw-text-start"
						style={{ width: '185px' }}
					>
						<div className="tw-text-xs tw-m-0 tw-p-0 tw-flex">
							<span
								className="name tw-truncate tw-block"
								style={{ maxWidth: '136px' }}
							>
								{user && capitalize(getUserFirstName(user))}
							</span>
							<span className="tw-ml-1"> - Guest</span>
						</div>
						<div
							className="tw-text-xs tw-m-0 tw-p-0 tw-truncate tw-block"
							style={{ wordBreak: 'break-all' }}
						>
							{user?.email && !isGuestEmail(user.email) && user.email}
						</div>
					</div>
				</IconWithTooltip>
				<TickIcon style={{ marginLeft: 'auto', flexShrink: 0 }} />
			</div>
			<hr className="tw-m-0" />
			<DropDown.DropDownItem>
				<div
					role="presentation"
					data-testid="projects"
					className={`tw-flex
            tw-flex-row tw-items-center tw-content-center tw-gap-2 tw-p-2`}
					onClick={() => {
						history.push(`/register`);
					}}
				>
					<img
						className="user-icon tw-w-6"
						src={
							'https://firebasestorage.googleapis.com/v0/b/naya-assets' +
							'/o/1707404426015_profile_dark.svg?alt=media&token=69503609-c1e5-4880-9dd1-3d23d0a62f9d'
						}
						alt="Join wait list"
					/>
					<p className="tw-leading-6 tw-text-xs tw-m-0 tw-p-0 tw-h-5">
						Join the waitlist
					</p>
				</div>
			</DropDown.DropDownItem>
			<DropDown.DropDownItem>
				<div
					role="presentation"
					data-testid="projects"
					className={`tw-flex
            tw-flex-row tw-items-center tw-content-center tw-gap-2 tw-p-2`}
					onClick={() => {
						const url = encodeURIComponent(window.location.pathname);
						history.push(`/login/${url}`);
					}}
				>
					<img
						className="tw-w-6"
						src={
							'https://firebasestorage.googleapis.com/v0/b/naya' +
							'-assets/o/1707404466134_plus_dark.svg?alt=media&token=14db801e-97bf-4f74-b22d-be8843d9ca27'
						}
						alt="Login"
					/>
					<span className="tw-leading-6 tw-text-xs tw-m-0 tw-p-0 tw-h-5">Log in</span>
				</div>
			</DropDown.DropDownItem>
			{renderSignOutOption(true)}
		</div>
	);

	const getUserMenuItems = () => (
		<>
			<DropDown.DropDownItem>
				<div
					role="presentation"
					data-testid="projects"
					className={`option-item tw-w-full tw-cursor-pointer tw-flex 
          tw-flex-row tw-items-center tw-content-center tw-gap-2 ${classNames({
				disabled: isUnapprovedUser
			})}`}
					onClick={() => onCollabUnMount(() => history.push('/studio'))}
				>
					<ProjectIcon className={activeTab === '/studio' ? 'active-icon' : ''} />
					<p
						className={`tw-leading-6 tw-text-xs tw-m-0 tw-p-0 ${
							activeTab === '/studio' ? 'active-tab' : ''
						}`}
					>
						Studio
					</p>
				</div>
			</DropDown.DropDownItem>
			{createInNaya && (
				<DropDown.DropDownItem>
					<div
						role="presentation"
						data-testid="integrations"
						className={`option-item tw-w-full tw-cursor-pointer tw-flex tw-flex-row 
							tw-items-center tw-content-center tw-gap-2 ${classNames({
								disabled: isUnapprovedUser
							})}`}
						onClick={() => dispatch(setShowAppIntegrationModal(true))}
					>
						<IntegrationsIcon />
						<p className="tw-leading-6 tw-text-xs tw-m-0 tw-p-0">Apps & integrations</p>
					</div>
				</DropDown.DropDownItem>
			)}
			{isProfileOptionEnabled && (
				<DropDown.DropDownItem>
					<div
						role="presentation"
						data-testid="profile"
						className={`option-item tw-w-full tw-cursor-pointer tw-flex 
          tw-flex-row tw-items-center tw-content-center tw-gap-2 ${classNames({
				disabled: isUnapprovedUser
			})}`}
						onClick={() => onCollabUnMount(() => history.push('/profile'))}
					>
						<ProfileIcon
							style={{ stroke: '4f00c1' }}
							className={activeTab === '/profile' ? 'active-icon' : ''}
						/>
						<p
							className={`tw-leading-6 tw-text-xs tw-m-0 tw-p-0 ${
								activeTab === '/profile' ? 'active-tab' : ''
							}`}
						>
							Profile
						</p>
					</div>
				</DropDown.DropDownItem>
			)}
			{isProductTourEnabled && (
				<DropDown.DropDownItem>
					<div
						role="presentation"
						data-testid="integrations"
						className={`option-item tw-cursor-pointer tw-flex tw-flex-row tw-items-center 
							tw-content-center tw-gap-2 tw-w-full ${classNames({
								disabled: isUnapprovedUser
							})}`}
						onClick={() => {
							(window as any).Storylane?.Play({
								type: 'popup',
								demo_type: 'image',
								width: 2560,
								height: 1281.7414965986395,
								scale: '0.95',
								demo_url: 'https://app.storylane.io/demo/tmfztudk7vdl',
								padding_bottom: 'calc(50.07% + 27px)'
							});
						}}
					>
						<ProductTourIcon />
						<p className="tw-leading-6 tw-text-xs tw-m-0 tw-p-0">Product tour</p>
					</div>
				</DropDown.DropDownItem>
			)}
			{renderSignOutOption()}
		</>
	);

	return (
		<div role="presentation" id="user-profile-button_container" onClick={removeTextInputNode}>
			<DropDown modal={false} onOpenChange={(value: boolean) => setIsDropdownActive(value)}>
				<DropDown.DropDownTrigger disabled={!online}>
					<IconWithTooltip
						tooltipText="My account"
						side="bottom"
						delayDuration={0}
						classNames="tw-mr-2"
					>
						<div
							className={`profile-icon-wrapper ${classNames({
								active: isDropdownActive
							})}`}
						>
							{(user._id || isUnapprovedUser) && (
								<UserProfileIcon
									url={user.profilePic}
									displayName={userDisplayName}
									color="#4F00C1"
									className="tw-h-10 tw-w-10 tw-font-randMedium"
									style={{ flexShrink: 0, fontSize: '24px', lineHeight: 0 }}
								/>
							)}

							<div className="profile-overlay">
								{connectionStatus === 'CONNECTED' && (
									<div
										className={`rtc-indicator tw-w-3 tw-h-3 tw-absolute 
										tw-rounded-full tw-bottom-0 tw-right-0 green-fill`}
									/>
								)}
							</div>
						</div>
					</IconWithTooltip>
				</DropDown.DropDownTrigger>
				<DropDown.DropDownContent
					classNames={`${
						(user && !isGuestUser) || isUnapprovedUser
							? 'profile-dropdown-options'
							: 'guest-user-dropdown'
					} non-account-page tw-bg-white 
          tw-rounded-2xl tw-gap-2 tw-p-2 tw-flex tw-flex-col tw-items-center`}
				>
					{(user && !isGuestUser) || isUnapprovedUser
						? getUserMenuItems()
						: getGuestUserMenuItems()}
				</DropDown.DropDownContent>
			</DropDown>
		</div>
	);
};

export default UserProfileButton;
