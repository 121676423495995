import {
  FormEvent, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Form,
  InputGroup,
  FormControl,
  Button,
  Col,
  Row,
  Modal,
} from 'react-bootstrap';
import Select, { OptionsType, components } from 'react-select';
import { Oval } from 'react-loader-spinner';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { Layer, Feature } from 'react-mapbox-gl';
import FrameTwo from '../../../assets/Frame308.svg';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'mapbox-gl/dist/mapbox-gl.css';
import issue from '../../../assets/issue.png';
import DownArrow from '../../../assets/icons/DownArrow.svg';
import { ReactComponent as Cross } from '../../../assets/cross.svg';
import {
  defaultDimensions,
} from '../utils/EstimateAI.config';
import materialsList from './sustainability.config';
import { Map } from '../project/HomebaseProject';
import NumberInput from '../utils/NumberInput';
import '../utils/ESform.scss';
import './SustainabilityForm.scss';
import active_location from '../../../assets/icons/active_location-dot.svg';
import inactive_location from '../../../assets/icons/inactive_location-dot.svg';
import AlertIcon from '../../../assets/Alert.svg';
import loaderImage from '../../../assets/icons/Searching.svg';
import mapboxstyle from '../utils/mapboxstyles.json';
import carbon_c from '../../../assets/icons/carbon-c.svg';
import trackEvent from 'src/util/analytics/analytics';
import { SuperPowerEvents } from 'src/util/analytics/events';

function MyVerticallyCenteredModal(props: any) {
  const { onHide, show } = props;
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        className="modal-header border-0"
        style={{ marginTop: '1rem' }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          <h5> Report issue with sustainability results</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ marginBottom: '1.5rem' }}>
        <p style={{ fontWeight: '400', fontSize: '14px' }}>Do you want to report issue with sustainability results?</p>
      </Modal.Body>
      <Modal.Footer
        className="modal-footer border-0"
      >
        <Button
          style={{ backgroundColor: 'white', color: 'black', border: 'none' }}
          onClick={onHide}
        >
          <span className="ModalBtn">Cancel</span>

        </Button>
        <Button
          style={{
            backgroundColor: 'var(--theme-color-1)', color: 'white', padding: '8px 16px', border: 'none',
          }}
          onClick={onHide}
        >
          <span className="ModalBtn">Confirm report</span>

        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export function capitalizeWords(words: string | Array<string>) {
  if (typeof words === 'string') {
    return words.slice(0, 1).toUpperCase() + words.substring(1);
  }
  return words
    .map((word) => word.slice(0, 1).toUpperCase() + word.substring(1))
    .join(' ');
}

function getComponent(isFocus: boolean) {
  /* eslint-disable react/jsx-props-no-spreading */
  return function DropdownIndicator(props : any) {
    return (
      <components.DropdownIndicator {...props} className={`es-dropdown ${isFocus && 'active'}`}>
        <img src={DownArrow} alt="" />
      </components.DropdownIndicator>
    );
  };
}

const MultiValueRemove = (props: any) => (
  <components.MultiValueRemove {...props}>
    <Cross style={{ color: '#5D5D5D' }} />
  </components.MultiValueRemove>
);

export default function SustainabilityForm() {
  const [modalShow, setModalShow] = useState(false);
  const [estLocation, setEstLocation] = useState('');
  const [furniture, setFurniture] = useState('');
  const [coords, setCoords] = useState({
    lat: 0,
    long: 0,
  });
  const [materials, setMaterials] = useState<OptionsType<SelectOptions>>([]);
  const [estQuantity, setEstQuantity] = useState(0);
  const [estFormSubmit, setEstFormSubmit] = useState(false);
  const [materialFocus, setMaterialFocus] = useState(false);
  const [objectFocus, setObjectFocus] = useState(false);
  const [weightFocus, setWeightFocus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [weightUnit, setWeightUnit] = useState('kgs');
  const [weight, setWeight] = useState(0);
  const [estimatedCO2e, setEstimatedCO2e] = useState<number>(0);

  const prevWeightUnit = useRef('kgs');

  const selectStyles = {
    control: (base: any) => ({
      ...base,
      fontSize: '12px',
      color: '#161616',
      lineHeight: '16px',
      fontWeight: '400px',
      borderColor: estFormSubmit && materials.length <= 0 && materialFocus === false ? '#E34300' : '#D1D1D1',
      boxShadow: 'none',
      '&:hover': {
        cursor: 'pointer',
      },
      '&:focused': {
        borderColor: 'var(--theme-color-with-opacity-2)',
        boxShadow: '0 0 0 0.1rem var(--theme-color-with-opacity-2)',
      },
    }),
    option: (styles: any) => ({
      ...styles,
      fontSize: '12px',
      color: '#161616',
      lineHeight: '16px',
      fontWeight: '400px',
      borderColor: '#D1D1D1',
      textTransform: 'capitalize',
      '&:hover': {
        backgroundColor: '#EAEAEA;',
        cursor: 'pointer',
      },
      '&:active': {
        backgroundColor: '#EAEAEA;',
        checked: 'true',
      },
      '&:focused': {
        borderColor: 'var(--theme-color-with-opacity-2)',
        boxShadow: '0 0 0 0.1rem var(--theme-color-with-opacity-2)',
        font: '700, bold',
      },
    }),
    placeholder: (base:any) => ({
      ...base,
      position: 'absolute',
      left: '10px',
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '16px',
      color: '#9F9F9F',
    }),
    multiValue: (base: any) => ({
      ...base,
      textTransform: 'capitalize',
      fontSize: '12px',
    }),
    multiValueRemove: (styles: any) => ({
      ...styles,
      color: '#606060',
      ':hover': {
        backgroundColor: 'none',
      },
    }),
  };

  const selectObjectStyles = {
    control: (base: any) => ({
      ...base,
      fontSize: '12px',
      color: '#161616',
      lineHeight: '16px',
      fontWeight: '400px',
      borderColor: estFormSubmit && furniture.length <= 0 && objectFocus === false ? '#E34300' : '#D1D1D1',
      boxShadow: 'none',
      '&:hover': {
        cursor: 'pointer',
      },
      '&:focused': {
        borderColor: '#cdaaff',
        boxShadow: '0 0 0 0.1rem #cdaaff',
      },
    }),
    option: (styles: any) => ({
      ...styles,
      fontSize: '12px',
      color: '#161616',
      lineHeight: '16px',
      fontWeight: '400px',
      borderColor: '#D1D1D1',
      '&:hover': {
        backgroundColor: '#EAEAEA;',
        cursor: 'pointer',
      },
      '&:active': {
        backgroundColor: '#EAEAEA;',
        checked: 'true',
      },
      '&:focused': {
        borderColor: ' #cdaaff',
        boxShadow: '0 0 0 0.1rem #cdaaff',
        font: '700, bold',
      },
    }),
    placeholder: (base:any) => ({
      ...base,
      position: 'absolute',
      left: '10px',
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '16px',
      color: '#9F9F9F',
    }),
    multiValue: (base: any) => ({
      ...base,
      textTransform: 'capitalize',
    }),
  };

  const validForm = useMemo(() => {
    if (materials.length > 0
      && furniture
      && estQuantity > 0
      && weight > 0
    ) return true;

    return false;
  }, [weight, materials, furniture, estQuantity]);

  useEffect(() => {
    setEstimatedCO2e(parseFloat(sessionStorage.getItem('co2eValue') || '0'));
    trackEvent(SuperPowerEvents.OPEN_SUSTAINABILITY);
  }, []);

  function backgroundStyle() {
    if (estimatedCO2e === 0) {
      if (isLoading) {
        return 'none';
      }

      return `no-repeat url(${FrameTwo}) center`;
    }
    return 'none';
  }

  // All furniture selects
  const furnitureOptions = useMemo(() => {
    const options = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const key in defaultDimensions) {
      // eslint-disable-next-line no-prototype-builtins
      if (defaultDimensions.hasOwnProperty(key)) {
        const label = key.replace(/[_/-]/g, ' ').split(' ');
        const obj = {
          value: key,
          label: capitalizeWords(label),
        };
        options.push(obj);
      }
    }
    return options;
  }, []);

  useEffect(()=>{
    if(!coords.lat && !coords.long){
      navigator.geolocation.getCurrentPosition(position => {
        setCoords({
          lat: position.coords.latitude,
          long: position.coords.longitude
       })
      }, (error) => console.log(error), {maximumAge:60000, timeout:5000, enableHighAccuracy:true})
    }
  },[coords])

  const weightUnits = ['kgs', 'lbs'].map((unit) => ({ value: unit, label: unit }));

  const onSubmit = async (e: MouseEvent | FormEvent) => {
    e.preventDefault();
    setEstFormSubmit(true);
    if (validForm) {
      setIsLoading(true);
      const materialWeight = (weightUnit === 'lbs' ? weight / 0.453592 : weight) / materials.length;
      let co2eValue: number = 0;
      materials.forEach((material) => {
        co2eValue += material.co2e * materialWeight;
      });
      co2eValue = parseFloat(co2eValue.toFixed(2)) * estQuantity;
      setEstimatedCO2e(co2eValue);
      sessionStorage.setItem('co2eValue', JSON.stringify(co2eValue));
      setIsLoading(false);

      trackEvent(SuperPowerEvents.RUN_SUSTAINABILITY);
    }
  };

  const handleLocationFocus = () => {
    const Input = document.getElementById('addressField') as HTMLInputElement;
    Input.focus();
  };

  const estLocationChange = (location: string) => {
    setEstLocation(location);
  };

  const estSelect = (selectLocation: string) => {
    setEstLocation(selectLocation);
    geocodeByAddress(selectLocation)
      .then(async (results: any) => {
        const coordsResult = await getLatLng(results[0]);
        setCoords({
          long: (coordsResult.lng * 1000) / 1000,
          lat: (coordsResult.lat * 1000) / 1000,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleWeightUnit = (opt: any) => {
    const value = opt?.value;
    setWeightUnit(value);
    if (value === 'lbs' && prevWeightUnit.current === 'kgs') {
      setWeight(weight * 0.453592);
    } else if (value === 'kgs' && prevWeightUnit.current === 'lbs') {
      setWeight(weight / 0.453592);
    }
    prevWeightUnit.current = value;
  };

  return (
    <>
      <div className="estimateContainer">
        <Col
          lg={4}
          xs={12}
          className="estimate"
          style={{
            marginRight: '16px',
          }}
        >
          <h4 className="estimateHeader">Sustainability Criteria</h4>
          <hr />
          <Form onSubmit={onSubmit} noValidate>
            <Form.Group>
              <Row>
                <Col xl={4} style={{ paddingRight: '0' }}>
                  <Form.Label>Object Type</Form.Label>
                </Col>
                <Col xl={8}>
                  <Select
                    isClearable={false}
                    closeMenuOnSelect
                    options={furnitureOptions}
                    styles={selectObjectStyles}
                    className="react-select"
                    id="objectType"
                    onChange={(opt) => {
                      setFurniture(opt!.value);
                    }}
                    value={{
                      value: furniture || '',
                      label: furniture ? capitalizeWords(furniture) : '',
                    }}
                    onFocus={() => { setObjectFocus(true); }}
                    onBlur={() => { setObjectFocus(false); }}
                    classNamePrefix="react-select"
                    placeholder="Select object type"
                    components={{
                      DropdownIndicator: getComponent(objectFocus),
                      IndicatorSeparator: () => null,
                    }}
                  />
                  {estFormSubmit && furniture.length <= 0 && objectFocus === false && (
                  <div>
                    <img src={AlertIcon} alt="" />
                    <span className="FieldWarnings">Select object type to continue</span>
                  </div>
                  )}
                </Col>
              </Row>
            </Form.Group>
            <Form.Group>
              <Row>
                <Col xl={4}>
                  <Form.Label>Number of units</Form.Label>
                </Col>
                <Col xl={8}>
                  <NumberInput
                    name="number of units"
                    id="quantity"
                    onChange={(num) => { setEstQuantity(num > 0 ? num : 0); }}
                    value={estQuantity}
                    formSubmit={estFormSubmit}
                    placeholder="1"
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group style={{ margin: '0.5rem 0 1rem 0' }}>
              <Row>
                <Col xl={4}>
                  <Form.Label>Material</Form.Label>
                </Col>
                <Col xl={8}>
                  <Select
                    isClearable={false}
                    styles={selectStyles}
                    closeMenuOnSelect={false}
                    options={materialsList}
                    isMulti
                    onFocus={() => setMaterialFocus(true)}
                    onBlur={() => setMaterialFocus(false)}
                    hideSelectedOptions={false}
                    value={materials}
                    onChange={(opt) => setMaterials(opt)}
                    className="react-select"
                    classNamePrefix="react-select"
                    id="materials"
                    placeholder="Search or select from options"
                    components={{
                      DropdownIndicator: getComponent(materialFocus),
                      IndicatorSeparator: () => null,
                      MultiValueRemove,
                    }}
                  />
                  { estFormSubmit && materials.length <= 0 && materialFocus === false && (
                  <div>
                    <img src={AlertIcon} alt="" />
                    <span className="FieldWarnings">Select materials to continue</span>
                  </div>
                  )}
                </Col>
              </Row>
            </Form.Group>
            <hr />
            <Form.Group style={{ marginTop: '1rem' }}>
              <Row>
                <Col xl={4}><Form.Label>Weight</Form.Label></Col>
                <Col xl={8}>
                  <Row>
                    <Col xl={7} md={8} sm={6} className="s-weight">
                      <NumberInput
                        name="weight"
                        id="weight"
                        onChange={(num) => { setWeight(num > 0 ? num : 0); }}
                        value={parseFloat(weight.toFixed(2))}
                        formSubmit={estFormSubmit}
                        placeholder="0"
                        style={{ minWidth: '110px' }}
                      />
                    </Col>
                    <Col xl={5} md={4} sm={6}>
                      <Select
                        isClearable={false}
                        closeMenuOnSelect
                        options={weightUnits}
                        styles={selectObjectStyles}
                        className="react-select"
                        id="weight-units"
                        onChange={(opt) => {
                          if (opt) { handleWeightUnit(opt); }
                        }}
                        value={{
                          value: weightUnit || '',
                          label: weightUnit ? capitalizeWords(weightUnit) : '',
                        }}
                        onFocus={() => setWeightFocus(true)}
                        onBlur={() => setWeightFocus(false)}
                        classNamePrefix="react-select"
                        components={{
                          DropdownIndicator: getComponent(weightFocus),
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group style={{ marginTop: '1rem' }}>
              <Row>
                <Col xl={4}>
                  <Form.Label>Location</Form.Label>
                </Col>
                <Col xl={8}>
                  <PlacesAutocomplete
                    value={estLocation}
                    onChange={estLocationChange}
                    onSelect={estSelect}
                    searchOptions={{ types: ['locality', 'country'] }}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <InputGroup
                          id="locationField"
                          style={{
                            border: '1px solid #d1d1d1',
                          }}
                        >
                          <InputGroup.Text
                            style={{
                              backgroundColor: 'transparent',
                              borderRight: '0px',
                              borderRadius: '4px 0 0 4px',
                            }}
                            onClick={handleLocationFocus}
                          >
                            <img src={coords.lat && coords.long ? active_location : inactive_location} alt="" />

                          </InputGroup.Text>
                          <FormControl
                            style={{ borderLeft: '0px', paddingLeft: '0px' }}
                            className="textField"
                            id="addressField"
                            {...getInputProps({
                              placeholder: 'Search by address',
                            })}
                          />
                        </InputGroup>
                        <div
                          className={
                            loading || suggestions.length > 0
                              ? 'community-autocomplete-dropdown-container'
                              : ''
                          }
                        >
                          {suggestions.map((suggestion: any) => {
                            const className = 'community-suggestion-item';
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                })}
                              >
                                <span key={suggestion.description} className="suggestion">
                                  {suggestion.description}
                                </span>
                                <hr />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group>
              <Row>
                <Col xl={4} />
                <Col xl={8}>
                  <Map
                    center={coords.lat !== 0 ? [coords.long, coords.lat] : [37.0902, 85.7129]}
                    style={mapboxstyle.mapboxglstyle}
                    containerStyle={{
                      height: '200px',
                      width: '100%',
                    }}
                    zoom={[13]}
                  >
                    <Layer
                      type="symbol"
                      id="marker"
                      layout={{ 'icon-image': 'marker-15' }}
                    >
                      <Feature coordinates={[coords.long, coords.lat]} />
                    </Layer>
                  </Map>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group style={{ display: 'flex', justifyContent: 'end' }}>
              <Button
                className="calculateSustainability"
                style={{
                  background: 'var(--theme-color-1)',
                  border: '#F5F5F5',
                  borderRadius: '4px',
                }}
                onClick={onSubmit}
              >
                <span style={{
                  fontWeight: '700', color: 'white', fontSize: '14px', letterSpacing: '0.02em', lineHeight: '16px',
                }}
                >
                  Calculate
                </span>
              </Button>
            </Form.Group>
          </Form>
        </Col>
        <Col
          className="estimatePrice"
          lg={8}
          xs={12}
          style={{ background: backgroundStyle(), padding: '0' }}
        >
          <h4 className="estimateHeader" style={{ margin: '1.5rem 1rem 16px 3rem' }}>Your Sustainability Results</h4>
          <hr style={{ margin: '0 3rem 16px 3rem' }} />
          {isLoading && (
          <div style={{
            display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '50px',
          }}
          >
            <img src={loaderImage} alt="" />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Oval color="#6B04FF" height={16} width={16} />
              <span style={{ fontSize: '12px', color: '#161616', marginLeft: '6px' }}>Calculating estimate</span>
            </div>
          </div>
          )}
          { !isLoading && estimatedCO2e !== 0 && (
          <div className="sustainability-result">
            <div className="result">
              <div className="carbon-c-wrapper">
                <img src={carbon_c} alt="carbon c icon" />
              </div>
              <h3 style={{ margin: '6px 0px 0px 0px' }}>CO₂e</h3>
            </div>
            <p>
              Carbon dioxide equivalent or CO2e is the number of
              {' '}
              <br />
              metric tons of CO2 emissions with the same global warming
              <br />
              potential as one metric ton of another greenhouse gas
            </p>
            <p className="co2e-result">
              {parseFloat(estimatedCO2e.toFixed(3))}
              {' '}
              kg
            </p>
          </div>
          )}
        </Col>
      </div>
      {estimatedCO2e === 0 ? (
        ''
      ) : (
        <span className="reportIssue">
          <img src={issue} alt="" />
          <a
            href="."
            onClick={(e) => {
              e.preventDefault();
              setModalShow(true);
            }}
            style={{
              color: '#9F9F9F',
              textDecoration: '1px underline #9F9F9F',
              fontSize: '12px',
              lineHeight: '16px',
            }}
          >
            Report issue
          </a>
        </span>
      )}
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

type SelectOptions = {
  label: string;
  value: string;
  co2e: number
};
