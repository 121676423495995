import { store } from 'src';
import { ReduxState } from '../reducers/root.types';

// Type defining redux manager's each event object
export type TEventManager = {
  type:string, // defines the event name/type which will identify the publish trigger
  selector:(store:ReduxState)=>any, // defines the selector to extract a value that is used to compare with the prevValue
  cb:(data:ReduxState)=>any, // callback function --> called when a change occurs
  prevValue:any, // value to comapre with the new value extracted from selector to decide if some change has occurred
};

// Type defining redux manager's events
type TEvents = {
  [key: string]: TEventManager
};

/**
 * Central manager for watching the redux updates
 */
export default class ReduxManager {
  events: TEvents;

  constructor() {
    this.events = {} as TEvents;

    try {
      store.subscribe(() => {
        const keys:string[] = Object.keys(this.events);

        const state = store.getState() as ReduxState;

        // Looping through all the events
        for (let i = 0; i < keys.length; i++) {
          const eventData = this.events[keys[i] as string];

          if (eventData && typeof eventData.selector === 'function') {
            // Extracting the currentValue to be compared using the event's selector
            const currentValue = eventData.selector(state as ReduxState);

            // if the both the values are different, update the prevValue with the current value
            // and then notify the subscriber about the change
            if (currentValue !== eventData.prevValue) {
              eventData.prevValue = currentValue;
              this.publish(eventData.type, state);

              // updating the event in the events list
              this.events[keys[i] as string] = eventData;
            }
          }
        }
      });
    } catch (e:any) {
      console.error('Error connecting to the store:', e);
    }
  }

  /**
   * Function used to subscribe to an event
   * @param type
   * @param selector
   * @param cb
   */
  subscribe(type:string, selector:(store:ReduxState)=>any, cb:(store:ReduxState)=>any) {
    this.events[type] = {
      type, selector, cb, prevValue: selector(store.getState() as ReduxState),
    };
  }

  /**
   * Function to unsubscribe from an event
   * @param type
   */
  unsubscribe(type:string) {
    if (this.events[type]) {
      delete (this.events[type]);
    }
  }

  /**
   * Function to notify the subscriber about the change
   * @param type
   * @param data
   */
  publish(type:string, data:ReduxState) {
    const event = this.events[type];
    try{
      if (event && typeof event.cb === 'function') {
        this.events[type]?.cb(data);
      }
    }
    catch(e){
      console.error('Error occurred while publishing to the subscriber:', e)
    }
  }
}
