import { useMemo } from 'react';
import {
	ActionItemPanelWrapper,
	ActionItemUsers,
	FeedbackMarkComplete,
	UserItem,
	UserListT,
	UserT
} from '@naya_studio/radix-ui';
import { IBlock, IUser, TUserAndRole } from '@naya_studio/types';
import './ActionItemPanel.scss';
import { shallowEqual, useSelector } from 'react-redux';
import { ReduxState } from 'src/redux/reducers/root.types';
import { ReactComponent as Cross } from 'src/assets/icons/feedback/feedback-cross.svg';
import { useParams } from 'react-router';
import { PathParamsType } from 'src/types/pathParams';
import { checkUserAccessLevel } from 'src/util/accessLevel/accessLevelActions';
import useUser from 'src/redux/hooks/user';
import { getFormattedUsersData, getUserFirstName } from '../util';

// Props for action item panel component
export type ActionItemPanelProps = {
	// to determine if mark complete should be disabled. This is disabled when its in edit state or if user is a Viewer
	markCompleteDisabled: boolean;
	isCompleted: boolean; // determines if tark complete should be marked as completed or not
	onMarkComplete: (val: boolean) => void; // callback to handle toggle of mark complete
	users: IUser[]; // list of users to show in add users dropdown
	selectedUsers: string[]; // ids of the selected users
	onModifySelectedUsers: (id: string) => void; // callback when user modifies assignees for action item,
	isNewFeedback?: boolean;
	onClose: () => void; // Callback to close the feedback
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

/**
 * Action item panel includes
 * 1. Mark complete
 * 2. Action item toggle
 * 3. users dropdown to assign action item
 */
export const ActionItemPanel = ({
	markCompleteDisabled,
	users,
	selectedUsers,
	onModifySelectedUsers,
	isCompleted,
	onMarkComplete,
	isNewFeedback,
	onClose,
	children
}: ActionItemPanelProps) => {
	// extract current user
	const { user } = useUser();

	// extracting ids from url
	const { canvasId: blockId } = useParams<PathParamsType>();

	// extract current block form redux
	const block = useSelector(
		(state) => (state as ReduxState).blocks.data[blockId],
		shallowEqual
	) as IBlock;

	// True if need show feedbacks in feedback panel
	const isFeedbackPanelBlock = ['THREE_D', 'VIDEO'].includes(block?.blockType);

	/**
	 * True is user has access to block
	 */
	const hasEditAccessToBlock = useMemo(
		() =>
			checkUserAccessLevel(block?.users as TUserAndRole[], user?._id as string, [
				'EDITOR',
				'OWNER',
				'COMMENTER'
			]),
		[user?._id, block?._id, block?.users]
	);

	// function to check if the current user is action item assignee
	const checkIfUserIsActionItemAssignee = () => {
		if (selectedUsers.includes(user?._id as string)) {
			return true;
		}
		return false;
	};

	// function to extract formatted users
	const getFormattedUsers = () => {
		// NOTE: passing second parma false as we don't need AI user here
		const allUsers = getFormattedUsersData(users, false).blockUsers;
		for (let i = 0; i < allUsers.length; i++) {
			if (
				allUsers[i] &&
				allUsers[i]?.id &&
				selectedUsers.includes(allUsers[i]?.id as string)
			) {
				(allUsers[i] as UserT & UserListT).isSelected = true;
			} else {
				(allUsers[i] as UserT & UserListT).isSelected = false;
			}
		}
		return allUsers as UserListT[];
	};

	return (
		<ActionItemPanelWrapper
			className={`action-item-panel tw-items-center 
			${isNewFeedback || children ? 'tw-justify-between' : 'tw-justify-end'}`}
			style={{ height: isFeedbackPanelBlock ? 72 : 48, margin: '0 16px' }}
		>
			{children}
			{isNewFeedback && (
				<UserItem
					userName={user?.userName || (getUserFirstName(user) as string)}
					profilePic={user?.profilePic || ''}
				/>
			)}
			<div className="tw-flex tw-items-center tw-justify-end tw-gap-2">
				<div className="tw-flex">
					{/* Mark complete */}
					{checkIfUserIsActionItemAssignee() &&
						!isNewFeedback &&
						hasEditAccessToBlock && (
							<FeedbackMarkComplete
								isCompleted={isCompleted}
								disabled={markCompleteDisabled}
								onMarkComplete={onMarkComplete}
							/>
						)}
				</div>
				{/* Action item users */}
				<ActionItemUsers
					formattedUsers={getFormattedUsers() as UserListT[]}
					onModifySelectedUsers={onModifySelectedUsers}
					disabled={markCompleteDisabled}
				/>
				{!isNewFeedback && !isFeedbackPanelBlock && (
					<Cross
						onClick={(e: React.MouseEvent<SVGSVGElement>) => {
							e.stopPropagation();
							onClose();
						}}
						style={{ cursor: 'pointer' }}
					/>
				)}
			</div>
		</ActionItemPanelWrapper>
	);
};

ActionItemPanel.defaultProps = {
	isNewFeedback: false
};
