import { useEffect, useRef, useState } from 'react';
import { store } from 'src';
import { updatePrototypeState } from 'src/redux/actions/prototyping';
import { useSelector } from 'react-redux';
import { ReduxState } from 'src/redux/reducers/root.types';
import { SuperPowerEvents } from 'src/util/analytics/events';
import trackEvent from 'src/util/analytics/analytics';
import { CustomDispatch } from 'src/redux/actions/types';
import PrototypingHome from './homepage/PrototypingHome';
import Upload3DModel from './uploadpage/Upload3DModel';
import Checkout from './checkout/Checkout';

type PrototypePageType = 'HOME' | 'UPLOAD' | 'ORDER_DETAILS' | 'ORDER_SUMMARY';

function HomebasePrototyping() {
	const [prototypePage, setPrototypePage] = useState<PrototypePageType>('HOME');
	const gotRef = useRef<string>('');
	const { currentLocation } = useSelector((state: ReduxState) => state.prototyping);

	useEffect(() => {
		gotRef.current = currentLocation;
	}, [currentLocation]);

	useEffect(() => {
		const updateState = () => {
			(store.dispatch as CustomDispatch)(
				updatePrototypeState({
					placedOrderDetails: null,
					goToLocation: gotRef.current
				})
			);
		};

		updateState();

		trackEvent(SuperPowerEvents.OPEN_3D_PRINTING);
	}, []);

	const renderpage = () => {
		switch (prototypePage) {
			case 'HOME':
				return <PrototypingHome setPrototypePage={setPrototypePage} />;

			case 'UPLOAD':
				return <Upload3DModel setPrototypePage={setPrototypePage} />;

			case 'ORDER_DETAILS':
				return (
					<Checkout setPrototypePage={setPrototypePage} prototypePage={prototypePage} />
				);

			case 'ORDER_SUMMARY':
				return (
					<Checkout setPrototypePage={setPrototypePage} prototypePage={prototypePage} />
				);

			default:
				return null;
		}
	};

	return <div>{renderpage()}</div>;
}

export default HomebasePrototyping;
