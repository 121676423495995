import Modal from 'react-bootstrap/Modal';
import { CancelOrderPromptProps } from './CheckOutprops';

/**
 * Prompt modal
 * @param param0 CancelOrderPromptProps
 */
export default function PrototypePrompt(
  {
    showModel,
    onClose: closeModal,
    text,
    title,
    primaryBtnText,
    secondaryBtnText,
    onPrimaryBtnClick: handleConfirm,
    contactNaya,
  }: CancelOrderPromptProps,
) {
  return (
    <Modal className="pmodal-content" show={showModel} onHide={() => closeModal()} animation={false}>
      <div className="pmodal-content">
        <Modal.Header className="pmodal-header" closeButton>
          <Modal.Title className="pmodal-title">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pmodal-body">{text}</Modal.Body>
        <Modal.Footer className="pmodal-footer">
          <div
            onClick={() => closeModal()}
            role="presentation"
            className="secondary-btn"
            onKeyDown={() => {}}
            style={{
              marginRight: '26px', fontSize: '12px', cursor: 'pointer', fontFamily: 'rand bold',
            }}
          >
            {secondaryBtnText}
          </div>
          {contactNaya
            ? (
              <a
                onClick={handleConfirm}
                href="https://mail.google.com/mail/?view=cm&fs=1&to=info@naya.studio"
                target="_blank"
                className="btn btn-primary"
                rel="noreferrer"
                style={{
                  background: 'var(--theme-color-1)',
                  border: 'rgb(245, 245, 245)',
                  borderRadius: '4px',
                  fontSize: '12px',
                  height: '32px',
                  display: 'flex',
                  alignItems: 'center',
                  fontFamily: 'rand bold',
                }}
              >
                {primaryBtnText}
              </a>
            )
            : (
              <div
                onClick={handleConfirm}
                role="presentation"
                onKeyDown={() => {}}
                className="btn btn-primary"
                style={{
                  background: 'var(--theme-color-1)',
                  border: 'rgb(245, 245, 245)',
                  borderRadius: '4px',
                  fontSize: '12px',
                  cursor: 'pointer',
                  height: '32px',
                  display: 'flex',
                  alignItems: 'center',
                  fontFamily: 'rand bold',
                }}
              >
                {primaryBtnText}
              </div>
            )}
        </Modal.Footer>
      </div>
    </Modal>

  );
}
