import { FontSizeOption } from "./FontSize.types";

export const fontSizeOptions: FontSizeOption[] = [
    {
        pluto: {
            dropdownPx: 8,
            placeholder: "Pluto",
            px: 8
        },
        mercury: {
            dropdownPx: 10,
            placeholder: "Mercury",
            px: 16
        },
        venus: {
            dropdownPx: 12,
            placeholder: "Venus",
            px: 24
        },
        earth: {
            dropdownPx: 16,
            placeholder: "Earth",
            px: 40
        },
        saturn: {
            dropdownPx: 20,
            placeholder: "Saturn",
            px: 96
        },
        jupiter: {
            dropdownPx: 24,
            placeholder: "Jupiter",
            px: 128
        }
    },
    {
        ant: {
            dropdownPx: 8,
            placeholder: "Ant",
            px: 8
        },
        mouse: {
            dropdownPx: 10,
            placeholder: "Mouse",
            px: 16
        },
        dog: {
            dropdownPx: 12,
            placeholder: "Dog",
            px: 24
        },
        bear: {
            dropdownPx: 16,
            placeholder: "Bear",
            px: 40
        },
        elephant: {
            dropdownPx: 20,
            placeholder: "Elephant",
            px: 96
        },
        whale: {
            dropdownPx: 24,
            placeholder: "Whale",
            px: 128
        }
    }
]