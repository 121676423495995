import { store } from 'src';
import { ISnackBar, SnackbarActionTypes } from '../reducers/root.types';

/**
 * Snackbar action to add snackbar
 */
export const addSnackbar = async (payload: ISnackBar) => {
	try {
		store.dispatch({
			type: SnackbarActionTypes.SET_SNACKBAR_DATA,
			payload
		});

		return true;
	} catch (error) {
		console.error('Error adding snackbar : ', error);
		return false;
	}
};

/**
 * Snackbar action to remove snackbar
 */
export const removeSnackbar = async (timeout: number, next?: () => void) => {
	try {
		const { snackBar } = store.getState();

		if (snackBar.show) {
			setTimeout(() => {
				store.dispatch({
					type: SnackbarActionTypes.SET_SNACKBAR_DATA,
					payload: {
						show: false
					}
				});

				if (next && typeof next === 'function') {
					next();
				}
			}, timeout);
		}

		return true;
	} catch (error) {
		console.error('Error removing snackbar : ', error);
		return false;
	}
};
