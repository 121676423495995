import { IObserver } from '@naya_studio/types';
import * as Y from 'yjs';
import { TChangeType, getYDoc } from 'src/rtc/yjs/yjsConfig';
import { useDispatch } from 'react-redux';
import { findDifferenceInJSON } from 'src/redux/actions/util';
import { updateProject } from 'src/redux/features/projects';
import { getProjectFromRedux } from 'src/util/helper/project';

/**
 * Hook to project in redux after yjs listens to observer changes
 */
const useSyncStorageObserver = () => {
	const dispatch = useDispatch();

	/**
	 * Observer to listen to storage observer changes in ymap
	 * @param update sync storage observer change to do in redux
	 */
	const syncStorageObserver = (update: Y.YMapEvent<string>) => {
		try {
			const doc = getYDoc();

			update.changes.keys.forEach((change: TChangeType<string>, key) => {
				if (change.action === 'update') {
					const syncChannelAfterUpdate = JSON.parse(
						doc.getMap('observers').get(key) as string
					) as IObserver;

					const syncChannelBeforeUpdate = JSON.parse(change.oldValue) as IObserver;

					const channelDifference = findDifferenceInJSON(
						syncChannelAfterUpdate,
						syncChannelBeforeUpdate
					);

					if (
						channelDifference?.isSyncInProgress !== undefined ||
						channelDifference?.lastSynced
					) {
						const project = getProjectFromRedux(syncChannelAfterUpdate.projectId);

						const updatedProjectObservers = project.observers?.map((ob) =>
							(ob as IObserver)._id === syncChannelAfterUpdate._id
								? syncChannelAfterUpdate
								: ob
						);

						dispatch(updateProject({ ...project, observers: updatedProjectObservers }));
					}
				}
			});
		} catch (error) {
			console.error('Error in Observer Listener : ', error);
		}
	};

	return syncStorageObserver;
};

export default useSyncStorageObserver;
