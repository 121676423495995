import { useContext, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import 'react-dropzone-uploader/dist/styles.css';
import './Upload3DModel.scss';
import 'firebase/storage';
import { useDropzone } from 'react-dropzone';
import { CollaborationToolContext } from 'src/components/collaborationTool/CollaborationTool';
import { threeDModelFormats } from 'src/util/helper/constants';
import { UploadModalProps } from './Upload3DModel.types';

// @ts-ignore
const Upload3DModel = ({ app, show, closeModal }: UploadModalProps) => {
	const collaborationToolContext = useContext(CollaborationToolContext);
	const { onDropFilesInProjectPanel } = collaborationToolContext;

	useEffect(() => {
		if (show) {
			app.globalFilesDnD = false;
		}
	}, [show]);

	const { getRootProps, getInputProps } = useDropzone({
		// accept: ['.FBX','.OBJ', '.3DM', '.GLB', '.GLTF'],
		onDrop: (acceptedFiles) => {
			const threeDFiles = acceptedFiles.filter((f) =>
				threeDModelFormats.includes(f.name.split('.').pop()?.toUpperCase() as string)
			);
			const twoDFiles = acceptedFiles.filter(
				(f) =>
					!threeDModelFormats.includes(f.name.split('.').pop()?.toUpperCase() as string)
			);
			closeModal();
			app.globalFilesDnD = false;
			onDropFilesInProjectPanel(threeDFiles, twoDFiles);
		}
	});

	return (
		<Modal
			show={show}
			onHide={() => {
				closeModal();
				app.globalFilesDnD = true;
			}}
			centered
			id="upload3d-modal"
			onWheel={(e: any) => e.stopPropagation()}
		>
			<Modal.Header closeButton>
				<Modal.Title>Upload Files</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<section className="container">
					<div {...getRootProps({ className: 'dropzone' })}>
						<input {...getInputProps()} />
						<p className="drop-files">Drag and drop files here or</p>
						<div style={{ textAlign: 'center' }}>
							<Button variant="custom">Select a file</Button>
						</div>
						<p>3D file types: .3dm .3ds .fbx .glb .obj .stl</p>
						<p>
							{' '}
							<div> Maximum file size is 25 MB</div>{' '}
						</p>
					</div>
				</section>
			</Modal.Body>
		</Modal>
	);
};
export default Upload3DModel;
