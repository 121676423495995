import App from '../App';
import BaseNode from '../nodes/BaseNode';
import { onTransformCommit } from '../transformerActions';
import moveNodes from './moveNode';

const resizeNodes = (delta: { x: number, y: number }, app:App) => {
  const nodes = app.selectedNodes as BaseNode[];
  nodes.forEach((node) => {
    if (node.displayObject && (node.nodeData.nodeType === 'SHAPE' || node.nodeData.nodeType === 'IMAGE')) {
      node.displayObject.width += delta.x;
      node.displayObject.height += delta.y;
      node._transformerChanged = true;
      onTransformCommit(app);
      node.save();
    } else {
      moveNodes(delta, app);
    }
  });
};

// Path: src/components/canvas/app/utils/resizeNode.ts
export default resizeNodes;
