import * as PIXI from 'pixi.js';
import { InteractionEvent } from 'pixi.js';
/**
* adding inivisible lines to container to create the hitareas and allow us to target those areas when we hover our mouse over it
* bounds: This defines the dimensions of the node or the canvas
* parentContainer: This is the container for the node or the canvas
* frameMouseover: Defines what happens on mouse over event
* frameMousedown: Defines what happens on mouse down
* frameMousemove: Defines what happens on mouse move
* frameMouseup: Defines what happens on mouse up
*/
const attachGraphicsLines = (
  bounds: any,
  parentContainer: PIXI.Container,
  frameMouseover?: () => void,
  frameMouseout?: () => void,
  frameMousedown?: () => void,
  frameMousemove?: (e: InteractionEvent) => void,
  frameMouseup?: (e:InteractionEvent) => void,
) => {
  for (let i = 0; i < 4; i++) {
    let x = 0;
    let rectX = 0;
    let y = 0;
    let rectY = 0;
    let width;
    let rectWidth;
    let height;
    let rectHeight;
    if (i === 0 || i === 2) {
      x = 0;
      rectX = 0;
      width = bounds?.width || 1920;
      rectWidth = bounds?.width || 1920;
      height = 0.5;
      rectHeight = 16;
      if (i === 0) {
        y = 0;
        rectY = 0;
      } else {
        y = bounds.height;
        rectY = y - 16;
      }
    } else {
      y = 0;
      rectY = 0;
      width = 0.5;
      rectWidth = 16;
      height = bounds.height;
      rectHeight = bounds.height;
      if (i === 1) {
        x = bounds.width;
        rectX = x - 16;
      } else {
        x = 0;
        rectX = 0;
      }
    }
    const frameGraphics = new PIXI.Graphics();
    frameGraphics.lineStyle(0.5, 0xCDAAFF, 0);
    frameGraphics.drawRect(x, y, width, height);
    frameGraphics.cursor = 'move';
    frameGraphics.interactive = true;
    frameGraphics.zIndex = 13;
    frameGraphics.hitArea = new PIXI.Rectangle(rectX, rectY, rectWidth, rectHeight);
    if (frameMouseover) {
      frameGraphics.on('mouseover', frameMouseover);
    }
    if (frameMouseout) {
      frameGraphics.on('mouseout', frameMouseout);
    }
    if (frameMousedown) {
      frameGraphics.on('mousedown', frameMousedown);
    }
    if (frameMousemove) {
      frameGraphics.on('mousemove', frameMousemove);
    }
    if (frameMouseup) {
      frameGraphics.on('mouseup', frameMouseup);
    }
    parentContainer.addChild(frameGraphics);
  }
};

export default attachGraphicsLines;
