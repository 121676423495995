import { useHistory, useLocation } from 'react-router';
import { ReactComponent as ErrorSVG } from 'src/assets/error/error-boundary.svg';
import './Error.scss';

/**
 * Renders error screen with msg send via params
 * Used for error handling for login flow
 */
const Error = () => {
	const history = useHistory();
	const location = useLocation();
	const params = new URLSearchParams(location.search);
	const message = params.get('msg') || 'Something went wrong! Please try again.';

	return (
		<div className="tw-flex tw-items-center tw-justify-center tw-h-screen">
			<div className="tw-flex tw-items-center tw-justify-center tw-flex-col tw-gap-[32px]">
				<ErrorSVG className="tw-mb-7" />
				<div className="tw-text-base tw-font-randRegular" style={{ color: '#424242' }}>
					{message}
				</div>
				<button
					type="button"
					className={`back-btn tw-flex tw-items-center tw-justify-center 
				tw-font-randRegular tw-font-bold tw-rounded-lg`}
					onClick={() => history.push('/login')}
				>
					Back
				</button>
			</div>
		</div>
	);
};

export default Error;
