import { Button, IconWithTooltip, NayaCheckbox, ControlModal } from '@naya_studio/radix-ui';
import mongoose from 'mongoose';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { store } from 'src';
import { ReactComponent as NayaLogo } from 'src/assets/brand/logo.svg';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';
import { checkIfUserExistsInDatabase } from 'src/util/auth/authActions';
import validator from 'validator';
import { register } from 'src/redux/actions/auth';

import './GuestAccess.scss';
import { CustomEvents } from 'src/util/analytics/events';
import trackEvent from 'src/util/analytics/analytics';
import { generateIdsFromUrl } from 'src/redux/reduxActions/util';
import useProject from 'src/redux/hooks/useProject';
import { TJoinedAsGuestEventData } from 'src/util/analytics/analytic.types';
import { AuthContext } from '../auth/AuthProvider';

/**
 * Guest access modal
 * @returns ReactElement
 */
const GuestAccessModal = () => {
	// Holds modal data(name, joinAnonymously)
	const [guestDetails, setGuestDetails] = useState<{
		name?: string;
		joinAnonymously?: boolean;
		email?: string;
	}>({});
	// State to determine the form submission
	const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
	const { projectId, blockId } = generateIdsFromUrl();

	const createdBy = useProject(projectId).project?.createdBy as string;

	const { setStatus } = useContext(AuthContext);

	const isValidEmail = !!(guestDetails.email && validator.isEmail(guestDetails.email));

	const areValidInputValues =
		guestDetails?.name?.length || guestDetails.joinAnonymously || isValidEmail;

	/**
	 * Function to ge the valid email id
	 * @param id {string} unique identifier
	 * @returns email id
	 */
	const getValidEmail = (id: string) =>
		isValidEmail && !guestDetails.joinAnonymously
			? guestDetails.email!
			: `guest${id}@naya.studio`;

	/**
	 * Function to check if the submit button should be disabled or not
	 * @returns {Boolean}
	 */
	const shouldDisableButton = () =>
		(guestDetails.email?.length && !isValidEmail) || hasSubmitted || !areValidInputValues;

	/**
	 * Function to track guest access joinee
	 */
	const trackGuestJoinee = () => {
		// Track joining as guest user event
		const eventProps: TJoinedAsGuestEventData = {
			elementId: blockId.length ? blockId : projectId,
			elementType: blockId.length ? 'BLOCK' : 'JOURNEY',
			invitedBy:
				new URL(window.location.toString()).searchParams.get('invitedBy') || createdBy
		};

		trackEvent(CustomEvents.JOINED_AS_GUEST, eventProps);
	};

	/** Function to handle modal submit */
	const handleSubmit = async () => {
		if (!areValidInputValues) return;
		try {
			setHasSubmitted(true);

			const id = new mongoose.Types.ObjectId().toString();
			const email = getValidEmail(id);
			const name = guestDetails.joinAnonymously
				? 'Anonymous'
				: guestDetails.name || email?.split('@')[0] || 'Anonymous';

			// Payload to create guest user
			const payload = {
				email,
				userName: name,
				firstName: name,
				// Setting user type as just string here, as api need it in string
				userType: 'GUEST',
				isAdminApproval: false,
				id
			};

			let existingUser;
			if (payload.email) {
				existingUser = await checkIfUserExistsInDatabase(payload.email);
			}

			// If existingUser with email is not a guest user, that means they are authorized users
			if (payload.email && existingUser && !existingUser?.userType?.includes('GUEST')) {
				addSnackbar({
					text: 'User with email id already exists, please login!',
					show: true
				});
				removeSnackbar(5000);
				setHasSubmitted(false);
				trackGuestJoinee();
			} else {
				// Send out api call to create guest users
				store
					.dispatch(
						register({
							data: payload,
							isGuestUser: true
						})
					)
					.then(() => trackGuestJoinee());

				setStatus('GUEST');
			}
		} catch (error) {
			console.error(error);
			addSnackbar({
				text: 'Something went wrong, please try again!',
				show: true
			});
			removeSnackbar(5000);
			setHasSubmitted(false);
		}
	};

	return (
		<ControlModal open>
			<ControlModal.ContentWrap
				id="guest-access-modal"
				close={() => {}}
				width={362}
				onOpenAutoFocus={(e) => e.preventDefault()}
			>
				<ControlModal.Content>
					<div className="modal-content">
						<div className="tw-flex tw-justify-center tw-items-center modal-header">
							<NayaLogo width={94} height={68} />
						</div>
						<div className="modal-body">
							<form
								className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-4 tw-w-full"
								onSubmit={(e) => {
									e.preventDefault();
									handleSubmit();
								}}
							>
								<h6
									className="tw-text-lg tw-font-randBold tw-m-0"
									style={{ lineHeight: '18px' }}
								>
									Welcome to Naya
								</h6>
								{!guestDetails.joinAnonymously && (
									<p className="tw-m-0 tw-text-xs tw-flex tw-gap-2 tw-relative tw-w-full tw-justify-center">
										Add your name to start collaborating!{' '}
										<div className="tw-absolute tw-right-0">
											<IconWithTooltip
												tooltipText={
													<p
														className="tw-m-0 tw-text-center"
														style={{ width: '192px' }}
													>
														Added name and email will be visible to
														other collaborators
													</p>
												}
												side="right"
												sideOffset={8}
												classNames="guest-modal-tooltip"
											>
												<img
													src={
														'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/icons%' +
														'2F1707367181331_info_fill.svg?alt=media&token=149e80dd-512b-4c38-aea2-17ceec5a5872'
													}
													alt="info-icon"
													style={{ zIndex: 20, marginRight: '3px' }}
												/>
											</IconWithTooltip>
										</div>
									</p>
								)}
								<div className="tw-flex tw-flex-col tw-items-center tw-w-full tw-gap-2">
									{!guestDetails.joinAnonymously && (
										<>
											<input
												type="text"
												placeholder="Name"
												name="name"
												className="tw-w-full tw-text-xs tw-items-center tw-outline-none tw-rounded-2xl"
												onChange={(e) => {
													setGuestDetails({
														...guestDetails,
														name: e.target.value
													});
												}}
											/>
											<input
												type="email"
												placeholder="Email"
												name="email"
												className="tw-w-full tw-text-xs tw-items-center tw-outline-none tw-rounded-2xl"
												onChange={(e) => {
													setGuestDetails({
														...guestDetails,
														email: e.target.value
													});
												}}
											/>
										</>
									)}
									<NayaCheckbox
										label="Or join anonymously"
										onChange={(checked) => {
											setGuestDetails({
												joinAnonymously: checked
											});
										}}
									/>
								</div>
								<Button
									variant="PRIMARY"
									onBtnClick={handleSubmit}
									disabled={shouldDisableButton()}
									text="Access as guest"
									className="tw-w-full tw-flex tw-justify-center tw-p-4"
									style={{ width: '100%' }}
								/>
								<p className="tw-text-xs tw-m-0 tw-font-randMedium">
									Already have an account?{' '}
									<Link
										to={`/login/${window.encodeURIComponent(
											window.location.pathname
										)}`}
										className="tw-font-randMedium"
										onClick={() => {
											// Setting the status as SIGNED_OUT because when user opens a
											// guest access enabled project the status will be set the 'GUEST'
											// and when they try to login because of the checks it won't redirect to login page.
											setStatus('SIGNED_OUT');
										}}
									>
										Log in
									</Link>
								</p>
							</form>
						</div>
						<p className="tw-text-center tw-m-0" style={{ fontSize: '10px' }}>
							By continuing you agree to our
							<a
								href={
									'https://docs.google.com/document/u/1/d/e/' +
									'2PACX-1vRp1F6ekOvO8C0KNirQYE_xkT73hA_rK36UzgOD0daHtwqMsyHBFEWLnFOm-wJR6Q/pub'
								}
								target="_blank"
								rel="noreferrer"
							>
								&nbsp; terms and conditions
							</a>
							, privacy policy (
							<a
								href={
									'https://docs.google.com/document/u/1/d/e/2PACX-1vSXCyp-WC5_' +
									'J1oYGAcQOwtiVhXH6eDhgzoVB1c3hngn4ZOagmnyCG3s9PlHGvZOog/pub'
								}
								target="_blank"
								rel="noreferrer"
							>
								US & Intl
							</a>{' '}
							/
							<a
								href={
									'https://docs.google.com/document/u/1/d/e/2PACX-1vQnvDVhSWzeuISS' +
									'cfL8diO3V-yVMm9SBYh2nLjsYjOxLKjqE2Yqdb0ey0F0U6h0wQ/pub'
								}
								target="_blank"
								rel="noreferrer"
							>
								California
							</a>
							), and
							<a
								href={
									'https://docs.google.com/document/u/1/d/e/2PACX-1vRnc492iv5X5J' +
									'Tp3mPq6KotJktefEH8AvcXKa5RVfsvEPkK7zRj1uYg9mKmLKMOWA/pub'
								}
								target="_blank"
								rel="noreferrer"
							>
								{' '}
								NDA
							</a>
							.
						</p>
					</div>
				</ControlModal.Content>
			</ControlModal.ContentWrap>
		</ControlModal>
	);
};

export default GuestAccessModal;
