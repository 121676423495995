import { FC, useState } from 'react';
import './Author.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import authorPen from '../../../../../assets/icons/edit-menu/author_pen.svg';
import { AuthorProps } from './Author.types';

const Author: FC<AuthorProps> = ({
  app,
}) => {
  const [showAuthor, setShowAuthor] = useState(app.selectedNodes[0]?.nodeData.showAuthor);

  const handleClick = () => {
    const allNodes = app.selectedNodes;
    allNodes.forEach((node, i) => {
      const newShowAuthor: boolean = !node?.nodeData.showAuthor;
      setShowAuthor(newShowAuthor);
      node?.edit('AUTHOR', newShowAuthor, !!(allNodes.length > 1 && i !== 0));
    });

    app.saveAllNodes([]);
  }

  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 1000, hide: 0 }}
      overlay={(
        <Tooltip
          id="button-tooltip-2"
          style={{
            fontSize: '12px',
            borderRadius: '2px',
            lineHeight: '16px',
            marginTop: '4px',
          }}
        >
          Toggle author
        </Tooltip>
    )}
    >
      <div
        className={showAuthor ? 'author active' : 'author'}
        role='presentation'
        onClick={(e) => {
          e.preventDefault();
          handleClick();
        }}
      >
        <img
          src={authorPen}
          alt="a"
          className="icon"
        />
      </div>
    </OverlayTrigger>

  );
};

export default Author;