import {
	TUserAndRole,
	TInvitedEmail,
	IUser,
	IBlock,
	IFeedback,
	IProject,
	IGroup
} from '@naya_studio/types';
import { clone } from 'lodash';
import { store } from 'src';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';
import { ISnackBar } from 'src/redux/reducers/root.types';
import { getWebSocket, getYDoc } from 'src/rtc/yjs/yjsConfig';
import { Map as YMap } from 'yjs';
import { TBroadCastType } from './useObserverInitializer';

/**
 * Function to check if user in provided users or emails
 * @param invitedUsers Invited Users
 * @param invitedEmails Invited Emails
 * @returns boolean indicating user access
 */
export const checkIfUserHasAccess = (
	user: IUser,
	invitedUsers?: TUserAndRole[],
	invitedEmails?: TInvitedEmail[]
) => {
	if (user.userType?.includes('ADMIN')) return true;

	let isInvited = false;

	invitedUsers?.forEach((u) => {
		if (u.user === user._id) isInvited = true;
	});

	invitedEmails?.forEach((u) => {
		if (u && u.email === (user.email as string)) isInvited = true;
	});

	return isInvited;
};

/**
 * Function to access a block from ydoc
 * @param blockId id of the block to find
 * @returns IBlock
 */
export const getBlockFromYDoc = (blockId: string) => {
	const doc = getYDoc();
	const ydocBlock = (doc.getMap('blocks') as YMap<string>).get(blockId);
	const reduxState = store.getState();
	const reduxBlocks = reduxState.blocks.data;
	const reduxBlock = clone(reduxBlocks[blockId]);

	// If block is not present in ydoc take the redux block
	// this case might happen when we update note after server is just started
	// and block is not in ydoc
	return (ydocBlock ? JSON.parse(ydocBlock) : reduxBlock) as IBlock;
};

/**
 * Function to access a group from ydoc
 * @param groupId id of the group to find
 * @returns IGroup
 */
export const getGroupFromYDoc = (groupId: string) => {
	const doc = getYDoc();
	const ydocGroup = (doc.getMap('pathways') as YMap<string>).get(groupId);
	const reduxState = store.getState();
	const reduxStages = reduxState.stages.data;
	const reduxStage = clone(reduxStages[groupId]);

	// If group is not present in ydoc take the redux group
	// this case might happen when we update note after server is just started
	// and group is not in ydoc
	return (ydocGroup ? JSON.parse(ydocGroup) : reduxStage) as IGroup;
};

/**
 * Function to add the reply's parent feedback in ymap
 * @param replyId Id of the reply
 * @param parentFeedback parent feedback of relpy before change i.e. before adding reply to its replies
 */
export const insertReplyCommentToYMap = (replyId: string, parentFeedback: IFeedback) => {
	const doc = getYDoc();
	const feedbackToInsert = parentFeedback;

	if (parentFeedback?.replies) {
		const lastReply = parentFeedback.replies.slice(-1)[0] as IFeedback;
		if ((lastReply._id as string) === replyId) feedbackToInsert.replies?.pop();
	}

	(doc.getMap('replies') as YMap<string>).set(replyId, JSON.stringify(feedbackToInsert));
};

/**
 * Function to inform user to re-connect to socket in case they've been disconnected
 */
export const informUserToReconnect = (
	message: string,
	buttonText: string = 'Reconnect',
	forced: boolean = false
) => {
	const snackbarText = message;
	const { snackBar } = store.getState();
	const { show, text } = snackBar;

	if (show && text === snackbarText) return;
	if (show) removeSnackbar(0);

	const webSocket = getWebSocket();
	if ((!webSocket || webSocket?.awareness?.states?.size <= 1) && !forced) return;

	const snackbarPayload: ISnackBar = {
		show: true,
		type: 'NORMAL',
		text: snackbarText,
		actionButtonData: [
			{
				buttonData: buttonText,
				onClick: () => {
					removeSnackbar(0);
					window.location.reload();
				},
				className: 'custom-button',
				show: true
			},
			{
				// Unicode of cross mark
				buttonData: `\u2715`,
				onClick: () => removeSnackbar(0),
				className: 'dismiss',
				show: true
			}
		]
	};

	addSnackbar(snackbarPayload);
};

/**
 * Function to remove the socket re-connection snackbar
 */
export const removeSocketConnectionSnackbar = () => {
	const snackbarText =
		"You've been disconnected from server, please re-connect to receive or send updates.";

	const { snackBar } = store.getState();
	const { show, text } = snackBar;

	const webSocket = getWebSocket();
	if (!webSocket || webSocket?.awareness?.states?.size <= 1) return;

	if (show && text === snackbarText) removeSnackbar(0);
};

/**
 *
 * @param projectId Function to access project from ydoc if it is not present then from redux
 * @returns project
 */
export const getProjectFromYDocOrRedux = (projectId: string) => {
	const doc = getYDoc();
	const { projects } = store.getState();

	const ydocProject = doc.getMap('project').get(projectId) as string;
	const project = (ydocProject ? JSON.parse(ydocProject) : projects.data[projectId]) as IProject;

	return project;
};

/**
 * Function to insert document to ydoc
 * @param type type of document i.e. Block or Stage
 * @param record Data tp be inserted
 */
export const insertDocumentToYDoc = (type: 'BLOCK' | 'STAGE', documentId: string) => {
	const { blocks, stages } = store.getState();
	const doc = getYDoc();

	switch (type) {
		case 'BLOCK': {
			const block = blocks.data[documentId] as IBlock;
			const blocksMap = doc.getMap('blocks') as YMap<string>;

			if (blocksMap && !blocksMap.get(documentId)) {
				blocksMap.set(block._id as string, JSON.stringify(block));
			}
			break;
		}
		case 'STAGE': {
			const stage = stages.data[documentId] as IGroup;
			const stagesMap = doc.getMap('pathways') as YMap<string>;

			if (stagesMap && !stagesMap.get(documentId)) {
				stagesMap.set(stage._id as string, JSON.stringify(stage));
			}
			break;
		}
		default:
			break;
	}
};

/**
 * Function to broadcast self dispatch change to rest of the open tabs by same user
 * @param change change to be broadcasted
 * @param type type of change
 * @param level level of change
 */
export const broadcastSelfDispatch = (
	change: TBroadCastType['change'],
	type: TBroadCastType['type'],
	level: TBroadCastType['level']
) => {
	localStorage.setItem(
		'broadcast-self-change',
		JSON.stringify({
			change,
			type,
			level,
			timestamp: Date.now() // to ensure every change triggers the event
		})
	);

	// Optionally remove the broadcast message after a short delay
	setTimeout(() => localStorage.removeItem('broadcast-self-change'), 100);
};
