import { PROJECTS_API_URL, NOTIFICATION_API_URL, SUPER_POWER_API_URL } from './api';

export const projectRouter = `${PROJECTS_API_URL}/project`;
export const uploadRouter = `${PROJECTS_API_URL}/upload`;
export const shoppingCartRouter = `${PROJECTS_API_URL}/shoppingCart`;
export const shipmentRouter = `${PROJECTS_API_URL}/shipment`;
export const projectGroupRouter = `${PROJECTS_API_URL}/projectGroup`;
export const textToImageRouter = `${PROJECTS_API_URL}/textToImage`;
export const nodeRouter = `${PROJECTS_API_URL}/node`;
export const imageRouter = `${PROJECTS_API_URL}/image`;
export const canvasRouter = `${PROJECTS_API_URL}/canvas`;
export const commentRouter = `${PROJECTS_API_URL}/comment`;
export const feedbackRouter = `${PROJECTS_API_URL}/feedback`;
export const stageRouter = `${PROJECTS_API_URL}/stage`;
export const qnaRouter = `${PROJECTS_API_URL}/qna`;
export const notificationRouter = `${NOTIFICATION_API_URL}/notification`;
export const sentTaskRouter = `${NOTIFICATION_API_URL}/tasks`;
export const phaseTemplateRouter = `${PROJECTS_API_URL}/stageTemplate`;
export const storyBoardRouter = `${PROJECTS_API_URL}/storyboard`;
export const prototypingRouter = `${PROJECTS_API_URL}/shapeways`;
export const noteRouter = `${PROJECTS_API_URL}/note`;
export const jobRouter = `${PROJECTS_API_URL}/job`;
export const pmJourneyRouter = `${SUPER_POWER_API_URL}/pm-ai`;
export const textToTextRouter = `${SUPER_POWER_API_URL}/text-to-text`;
export const ThreeDModelGenRouter = `${SUPER_POWER_API_URL}/text-to-3d`;
export const aiRenameRouter = `${SUPER_POWER_API_URL}/ai-rename`;
export const aiOrganizeRouter = `${SUPER_POWER_API_URL}/ai-organize`;
export const aiIngestRouter = `${SUPER_POWER_API_URL}/ai-ingest`;
export const observerRouter = `${PROJECTS_API_URL}/observer`;
export const getTemplatesAPI = `${PROJECTS_API_URL}/templates`;
export const multiselectAIRouter = `${SUPER_POWER_API_URL}/multiselect`;
export const imageEnhancementAIRouter = `${SUPER_POWER_API_URL}/img-enhancements`;
export const aiIntentRouter = `${SUPER_POWER_API_URL}/ai-intent`;

// TODO: update this route base on backend changes
export const blockRouter = `${PROJECTS_API_URL}/block`;
