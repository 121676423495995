import {
	EEvent,
	INotification,
	ISentNotification,
	IUser,
	TNotificationData
} from '@naya_studio/types';
import { TNotifState } from 'src/redux/features/api/notification';
import { getUserFirstName } from 'src/components/feedbackSuite/util';
import { TMyTaskItem } from './useNotifications';

/**
 * Generates text that should be seen in the MyTaskItem
 * @param event event of the notification
 * @param feedbackType type of feedback
 */
export const getText = (event: string, feedbackType?: string) => {
	switch (event) {
		case EEvent.FBS_MENTION:
		case EEvent.FBS_MENTION_ANS:
		case EEvent.FBS_ACT_ITEM: {
			if (feedbackType) {
				switch (feedbackType) {
					case 'CHAT':
						return 'Respond to a chat';
					case 'QNA':
						return 'Respond to short Q&A';
					case 'POLL':
						return 'Respond to a poll';
					case 'ACT_ITEM':
						return 'Respond to action item';
					case 'QNA_ANSWER': {
						if (event === EEvent.FBS_MENTION) {
							return 'Respond to short Q&A';
						}
						return 'View short Q&A';
					}
					default:
						return 'Respond';
				}
			}
			break;
		}
		case EEvent.FBS_MENTION_REPLY:
			return 'Respond to a chat';
		case EEvent.REPLIED:
		case EEvent.FBS_UPDATE: {
			if (feedbackType) {
				switch (feedbackType) {
					case 'CHAT':
						return 'View a chat';
					case 'QNA':
						return 'View short Q&A';
					case 'POLL':
						return 'View poll';
					case 'ACT_ITEM':
						return 'View an action item';
					case 'QNA_ANSWER': {
						return 'Respond to short Q&A';
					}
					default:
						return 'View';
				}
			}
			break;
		}
		case EEvent.INVITE_USER_CANVAS:
			return 'Join a block';
		case EEvent.INVITE_USER:
			return 'Join a project';
		case EEvent.STAGE_INVITE:
			return 'Join a group';
		case EEvent.REQUEST_FILE_UPLOAD:
			return 'Upload a file';
		case EEvent.BLOCK_DUE_DATE:
			return 'Block is due';
		default:
			return 'No matching case';
	}
	return 'Respond';
};

/**
 * Generates text that should be seen in the MyTaskItem
 * @param event event of the notification
 * @param taskData TNotificationData
 */
const getSubtitle = (event: EEvent, taskData: TNotificationData) => {
	switch (event) {
		case EEvent.BLOCK_DUE_DATE:
		case EEvent.INVITE_USER_CANVAS:
			return taskData?.canvasName;
		case EEvent.REQUEST_FILE_UPLOAD:
			return !taskData?.inviteNote ? taskData?.canvasName : undefined;
		case EEvent.INVITE_USER:
			return taskData?.projectName;
		case EEvent.STAGE_INVITE:
			return taskData?.stageName;
		default:
			return undefined;
	}
};

/**
 * Generates text that should be seen in the MyTaskItem
 * @param event event of the notification
 * @param taskData TNotificationData
 */
const getDescription = (event: EEvent, taskData: TNotificationData) => {
	if (taskData?.commentText) {
		return taskData?.commentText;
	}
	if (event === EEvent.REQUEST_FILE_UPLOAD) {
		return taskData?.inviteNote;
	}
	return undefined;
};

/**
 * Generates text that should be seen in the MyTaskItem
 * @param notifs key value pair of notification
 * @param isSent notification are from received or sent
 */
export const getFormatedNotification = (notifs: TNotifState, isSent: boolean) => {
	if (notifs) {
		const notifsAsArray = Object.values(notifs as TNotifState);

		notifsAsArray.sort((taskA: INotification | ISentNotification, taskB: INotification) => {
			if (isSent) {
				return (
					Number((taskA as ISentNotification).isCompleted) -
						Number((taskB as ISentNotification).isCompleted) ||
					new Date(taskB.createdAt as string).getTime() -
						new Date(taskA.createdAt as string).getTime()
				);
			}
			if (taskA.completedAt && !taskB.completedAt) {
				return 1; // taskA should be placed after taskB
			}
			if (!taskA.completedAt && taskB.completedAt) {
				return -1; // taskA should be placed before taskB
			}
			return 0; // no change in ordering
		});

		// get the date 7 days before from today
		const sevenDaysAgo = new Date();
		sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

		// get the date 15 days before from today
		const fifteenDaysAgo = new Date();
		fifteenDaysAgo.setDate(fifteenDaysAgo.getDate() - 15);

		const tasksTemp = notifsAsArray.map((notif) => ({
			notifId: notif._id as string,
			isCompleted: isSent
				? (notif as ISentNotification).isCompleted
				: (notif.sent?.inApp as boolean),
			createdAt: notif.createdAt as Date,
			projectName: `${notif.data?.projectName}`,
			name: isSent
				? getUserFirstName((notif as ISentNotification).toUsers[0]!.user as IUser)
				: (notif.data as TNotificationData)?.userName ||
				  (notif.data?.inviterName as string),
			avatar: isSent
				? (((notif as ISentNotification).toUsers[0]!.user as IUser)
						?.profilePic as string) || ''
				: (notif.data?.profilePic as string),
			text: getText(
				notif.eventType as string,
				(notif.data as TNotificationData)?.feedbackType as string
			),
			description: getDescription(notif.eventType as EEvent, notif.data as TNotificationData),
			subtitle: getSubtitle(notif.eventType as EEvent, notif.data as TNotificationData),
			link: notif.data?.link as string,
			feedbackId: (notif.data as TNotificationData)?.feedbackId || '',
			isPreviousTask: !!(
				notif.completedAt &&
				sevenDaysAgo > new Date(notif.completedAt) &&
				new Date(notif.completedAt) > fifteenDaysAgo
			),
			toUsers: (notif as ISentNotification)?.toUsers,
			blockId: (notif.data as TNotificationData)?.blockId,
			projectId: (notif.data as TNotificationData)?.projectId as string,
			dueDate: (notif.data as TNotificationData)?.dueDate as string,
			eventType: notif.eventType as EEvent,
			isSent
		}));

		// Create an object to store unique feedbackId objects
		const uniqueObjects: { [key: string]: TMyTaskItem } = {};

		tasksTemp.forEach((task) => {
			if (task.feedbackId) {
				if (task.isCompleted) {
					if (uniqueObjects[`${task.feedbackId}-c`]) {
						if (task.text?.includes('Respond')) {
							uniqueObjects[`${task.feedbackId}-c` as string] = {
								...task,
								andOthers: uniqueObjects[`${task.feedbackId}-c`]!.name !== task.name
							};
						}
						if (uniqueObjects[`${task.feedbackId}-c`]!.name !== task.name)
							uniqueObjects[`${task.feedbackId}-c`]!.andOthers = true;
					} else {
						uniqueObjects[`${task.feedbackId}-c` as string] = {
							...task,
							andOthers: isSent ? task.toUsers.length > 1 : false
						};
					}
				} else if (uniqueObjects[`${task.feedbackId}-i`]) {
					if (task.text?.includes('Respond')) {
						uniqueObjects[`${task.feedbackId}-i` as string] = {
							...task,
							andOthers: uniqueObjects[`${task.feedbackId}-i`]!.name !== task.name
						};
					}
					if (uniqueObjects[`${task.feedbackId}-i`]!.name !== task.name)
						uniqueObjects[`${task.feedbackId}-i`]!.andOthers = true;
				} else {
					uniqueObjects[`${task.feedbackId}-i` as string] = {
						...task,
						andOthers: isSent ? task.toUsers.length > 1 : false
					};
				}
			} else {
				/* When task is not related to feedback - we don't need to club it, hence we add it to uniqueObj
				 * As no clubbing - andothers is false
				 * But if it is a sent task and the same kind of task is created for other users,
				 * then andOthers is true
				 */
				uniqueObjects[`${task.notifId}-c` as string] = {
					...task,
					andOthers: isSent ? task.toUsers.length > 1 : false
				};
			}
		});

		return Object.values(uniqueObjects);
	}
	return [];
};
