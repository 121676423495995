import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import App from './app/App';

type CanvasNameProps = {
  name: string | undefined,
  app: App,
  toggleCanvasEditMenu: Dispatch<SetStateAction<boolean>>,
  showCanvasEditMenu: boolean,
  canvasEdgeHover: boolean
};

function CanvasName({
  app, toggleCanvasEditMenu, canvasEdgeHover, showCanvasEditMenu, name,
}: CanvasNameProps) {
  const pageCoordinates = app.viewport.toGlobal({
    x: app.frameX || 1,
    y: app.frameY || 1,
  });
  const [pageNameCoords, setPageNameCoords] = useState(app ? pageCoordinates : { x: 0, y: 0 });

  useEffect(() => {
    if (app) {
      app.viewport.on('moved', () => {
        if (app) {
          const newPageCoordinates = app.viewport.toGlobal({
            x: app.frameX || 1,
            y: app.frameY || 1,
          });
          setPageNameCoords(newPageCoordinates);
        }
      });
    }
  }, [app]);

  if (pageCoordinates.x !== pageNameCoords.x || pageCoordinates.y !== pageNameCoords.y) { 
    let timeoutRef: any = undefined;
    if(!timeoutRef){
        timeoutRef = setTimeout(()=>{
            setPageNameCoords(pageCoordinates);
            clearTimeout(timeoutRef);
        }, 50) 
    }
  }

  return (
    <div
      role="presentation"
      id={(showCanvasEditMenu || canvasEdgeHover) ? 'page-name-active' : 'page-name'}
      style={{
        top: `${(pageNameCoords.y! - 22 || -22)}px`, left: pageNameCoords.x,
      }}
      onClick={(e: any) => {
        e.preventDefault();
        if (!(app as App).canvasSelected) (app as App).selectFrame();
        else {
          (app as App).removeTransformer(); (app as App).canvasSelected = false;
          toggleCanvasEditMenu(false);
        }
      }}
      onMouseMove={() => {
        (app as App).highlightFrame();
      }}
      onMouseOut={() => {
        (app as App).removeFrameHighlight();
      }}
      onBlur={() => { }}
    >
      {name || ''}
    </div>
  );
}

export default CanvasName;
