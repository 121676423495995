import { IUserGroup } from '@naya_studio/types';
import { IAction, UserGroupActionTypes } from '../root.types';

const initialUserGroup: Required<IUserGroup> = {
  _id: '',
  totalSeats: 0,
  seats: [],
  createdAt: '',
  updatedAt: '',
};

const userGroupReducer = (
  state = initialUserGroup,
  action: IAction<UserGroupActionTypes | keyof typeof UserGroupActionTypes>
) => {
  let newState: IUserGroup;

  const payload: IUserGroup = action.payload;

  switch (action.type) {
    case 'LOAD_USER_GROUP':
      newState = payload ? (payload as IUserGroup) : state;
      break;
    case 'EDIT_USER_GROUP':
      newState = {
        ...state,
        ...payload,
      };
      break;
    case 'RESET_USER_GROUP':
      newState = initialUserGroup
      break;
    default:
      newState = state;
      break;
  }

  return newState;
};

export default userGroupReducer;
