import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import * as PIXI from 'pixi.js';
import { RouteComponentProps, withRouter } from 'react-router';
import { v1 as uuidv1 } from 'uuid';
import './Plus.scss';
import {
  ENodeType, EShapeType, INode, TAbsoluteBoundingBox,
} from '@naya_studio/types';
import { ReactComponent as Add } from '../../../assets/icons/stickyNoteAdd.svg';
import { ReactComponent as RightArrow } from '../../../assets/icons/stickyRightArrow.svg';
import { ReactComponent as UpArrow } from '../../../assets/icons/up.svg';
import { ReactComponent as LeftArrow } from '../../../assets/icons/left.svg';
import { ReactComponent as DownArrow } from '../../../assets/icons/down.svg';
import BaseNode from '../app/nodes/BaseNode';
import StickyNote from '../app/nodes/StickyNote';
import Shape from '../app/nodes/Shape';
import { StickyNoteDispatchProps, StickyNoteBtnProps, PathParamsType } from './Plus.types';
import { editNodes } from 'src/redux/reduxActions/node';
import { TEditNodesArgs } from 'src/types/argTypes';

class Plus extends React.Component<
StickyNoteBtnProps & StickyNoteDispatchProps & RouteComponentProps<PathParamsType>
> {
  static distance = (p1: any, p2: any) => Math.hypot(p2.x - p1.x, p2.y - p1.y);

  static getColorValue = (color: any) => {
    if (color) {
      const tempColor = `0x${color.substring(1, color.length + 1)}`;
      return parseInt(tempColor, 16);
    }
    return 0x000000;
  };

  hoverNode: PIXI.Container | undefined;

  drawHoverArrow = (direction: String) => {
    const { app } = this.props;
    let xposition;
    let yposition;
    let width;
    let height;

    if ((app.stickyNoteData as BaseNode).nodeData.absoluteBounds as TAbsoluteBoundingBox) {
      xposition = ((app.stickyNoteData as BaseNode).nodeData.absoluteBounds as TAbsoluteBoundingBox)
        .x;
      yposition = ((app.stickyNoteData as BaseNode).nodeData.absoluteBounds as TAbsoluteBoundingBox)
        .y;
      height = app.stickyNoteHeight;
      width = app.stickyNoteWidth;
    }
    const smallArrow = new PIXI.Graphics();
    // getting the angle between the current line and user's mouse pointer
    smallArrow.lineStyle(2, Plus.getColorValue(app.hoveringArrowColor), 0.2, 1);
    const shape = new PIXI.Graphics();
    const p1 = { x: 0, y: 0 };
    const p2 = { x: 50, y: 0 };
    switch (direction) {
      case 'RIGHT':
        (xposition as number) += (width as number) - 5;
        (yposition as number) = (yposition as number) + (height as number) / 2;
        smallArrow.moveTo(0, 0);
        smallArrow.lineTo(0, 14);
        smallArrow.moveTo(0, 0);
        smallArrow.lineTo(-14, 0);
        smallArrow.x = 50;
        smallArrow.y = -1;
        break;
      case 'LEFT':
        (xposition as number) = (xposition as number) - 70;
        (yposition as number) = (yposition as number) + (height as number) / 2;
        smallArrow.moveTo(0, 0);
        smallArrow.lineTo(14, 0);
        smallArrow.moveTo(0, 0);
        smallArrow.lineTo(0, -14);
        smallArrow.x = 0;
        smallArrow.y = 0;
        break;
      case 'BOTTOM':
        (xposition as number) += (width as number) / 2 - 10;
        (yposition as number) += (app.stickyNoteHeight as number) - 10;
        p2.x = 0;
        p2.y = 50;
        smallArrow.moveTo(0, 0);
        smallArrow.lineTo(-14, 0);
        smallArrow.moveTo(0, 0);
        smallArrow.lineTo(0, -14);
        smallArrow.x = 0.9;
        smallArrow.y = 50;
        break;
      case 'TOP':
        (xposition as number) += (width as number) / 2 - 10;
        (yposition as number) = (yposition as number) - 65;
        p2.x = 0;
        p2.y = 50;
        smallArrow.moveTo(0, 0);
        smallArrow.lineTo(14, 0);
        smallArrow.moveTo(0, 0);
        smallArrow.lineTo(0, 14);
        smallArrow.x = 0;
        smallArrow.y = 0;
        break;
      default:
        break;
    }
    shape.lineStyle(2, Plus.getColorValue(app.hoveringArrowColor), 0.2, 1);
    shape.moveTo(p1.x, p1.y).lineTo(p2.x, p2.y);
    const dash = 5;
    const gap = 10;
    const len = Plus.distance(p1, p2);
    const norm = { x: (p2.x - p1.x) / len, y: (p2.y - p1.y) / len };
    shape.lineStyle(4, 0xffffff, 1);
    shape.moveTo(p1.x, p1.y).lineTo(p1.x + dash * norm.x, p1.y + dash * norm.y);
    let progress = dash + gap;
    while (progress < len) {
      shape.moveTo(p1.x + progress * norm.x, p1.y + progress * norm.y);
      progress += dash;
      shape.lineTo(p1.x + progress * norm.x, p1.y + progress * norm.y);
      progress += gap;
    }

    shape.x = xposition as number;
    shape.y = yposition as number;
    shape.addChild(smallArrow);

    // setting angle to the small arrow
    const angle = Math.atan2(80, 80);
    smallArrow.rotation = angle;
    shape.endFill();
    this.drawHoverNode(direction);
    this.hoverNode = new PIXI.Container();
    this.hoverNode.addChild(shape);
    this.hoverNode.setTransform(0, 0);
    app.viewport.addChild(this.hoverNode);
  };

  removeHoverArrow = () => {
    const { app } = this.props;
    if (this.hoverNode) {
      app.viewport.removeChild(this.hoverNode);
    }
    this.hoverNode = undefined;
  };

  drawnode = async (direction: String) => {
    const { app, frame } = this.props;
    let xposition = 0;
    let yposition = 0;
    let width = 0;
    let height = 0;
    if ((app.stickyNoteData as BaseNode).nodeData.absoluteBounds as TAbsoluteBoundingBox) {
      xposition = ((app.stickyNoteData as BaseNode).nodeData.absoluteBounds as TAbsoluteBoundingBox)
        .x as number;
      yposition = ((app.stickyNoteData as BaseNode).nodeData.absoluteBounds as TAbsoluteBoundingBox)
        .y as number;
      width = app.stickyNoteWidth as number;
      height = app.stickyNoteHeight as number;
    }
    switch (direction) {
      case 'RIGHT':
        xposition += width + 58;
        yposition = yposition + height / 2 - 85;
        break;
      case 'LEFT':
        xposition -= 222;
        yposition = yposition + height / 2 - 85;
        break;
      case 'TOP':
        xposition = xposition + width / 2 - 92;
        yposition -= 230;
        break;
      case 'BOTTOM':
        xposition = xposition + width / 2 - 80;
        yposition += height + 50;
        break;
      default:
        break;
    }

    const defaultData: INode = {
      nodeType: 'STICKY_NOTE',
      isVisible: true,
      absoluteBounds: {
        x: xposition as number,
        y: yposition as number,
      },
      fill: {
        fillColor: app.stickyNoteData ? app.stickyNoteData.nodeData.fill?.fillColor : '#FFDB41',
      },
      zIndex: Math.floor(app.getHighestZIndex() + 1),
      text: {
        value: '',
        style: {
          fill: '#000000',
          align: 'left',
          fontSize: 14,
          fontWeight: 'normal',
        },
      },
      showAuthor: app.stickyNoteData?.nodeData.showAuthor || false,
    };
    const stickyNoteNode = {
      ...defaultData,
    };
    stickyNoteNode._id = uuidv1() as string;
    const newStickyNote = new StickyNote(app, stickyNoteNode);
    await newStickyNote.save();
    const currentStickynote = app.allNodes?.find((an) => an.nodeData._id === stickyNoteNode._id);
    currentStickynote?.draw();
    frame.removeHoverNode();
    this.removeHoverArrow();
    app.togglePlus(false);
    currentStickynote?.onEditStart();
  };

  drawarrow = (direction: String) => {
    const { app } = this.props;
    let xposition;
    let yposition;
    let width;
    let height;
    let lineheight;
    let linewidth;
    if ((app.stickyNoteData as BaseNode).nodeData.absoluteBounds as TAbsoluteBoundingBox) {
      xposition = ((app.stickyNoteData as BaseNode).nodeData.absoluteBounds as TAbsoluteBoundingBox)
        .x;
      yposition = ((app.stickyNoteData as BaseNode).nodeData.absoluteBounds as TAbsoluteBoundingBox)
        .y;
      height = app.stickyNoteHeight;
      width = app.stickyNoteWidth;
    }
    switch (direction) {
      case 'RIGHT':
        (xposition as number) += (width as number) - 13;
        (yposition as number) = (yposition as number) + (height as number) / 2;
        linewidth = 62;
        lineheight = 0;
        break;
      case 'LEFT':
        (xposition as number) = (xposition as number) - 8;
        (yposition as number) = (yposition as number) + (height as number) / 2;
        linewidth = -62;
        lineheight = 0;
        break;
      case 'TOP':
        (xposition as number) += (width as number) / 2 - 10;
        (yposition as number) = (yposition as number) - 8;
        linewidth = 0;
        lineheight = -50;

        break;
      case 'BOTTOM':
        (xposition as number) += (width as number) / 2 - 10;
        (yposition as number) += (app.stickyNoteHeight as number) - 12;
        linewidth = 0;
        lineheight = 50;
        break;
      default:
        break;
    }

    const shapeData = {
      ...Shape.defaultNodeData,
      ...app.nodeData,
      x: -10,
      y: -10,
    };
    if (shapeData.absoluteBounds) {
      shapeData.absoluteBounds.x = xposition;
      shapeData.absoluteBounds.y = yposition;
      shapeData.absoluteBounds.width = linewidth;
      shapeData.absoluteBounds.height = lineheight;
    }
    if (shapeData.stroke) {
      shapeData.stroke.strokeWidth = 2;
      shapeData.stroke.strokeColor = '#040404';
    }
    shapeData.zIndex = Math.floor(app.getHighestZIndex() + 1);

    shapeData.shapeType = EShapeType.ARROW;
    const arrow = new Shape(app, shapeData);
    arrow?.draw();
    arrow.save();
    this.drawnode(app.btnPos);
  };

  drawHoverNode = (direction: String) => {
    const { app, frame } = this.props;
    const verticalHover = app.viewport.toWorld((app.btn1X as number) + 33, app.btn1Y as number);

    let { x: worldX, y: worldY } = app.viewport.toWorld(
      app.horizontalHoverStickyNodeX as number,
      app.horizontalHoverStickyNodeY as number,
    );
    switch (direction) {
      case 'LEFT':
        worldX -= 162;
        worldY -= 2;
        break;
      case 'RIGHT':
        worldX += (app.stickyNoteWidth as number) + 125;
        worldY -= 2;
        break;
      case 'TOP':
        worldX = verticalHover.x;
        // worldY=verticalHover.y -110
        worldY -= 250;
        if (app.editMenuPosition === 'bottom') {
          worldY += 30;
        }
        break;
      case 'BOTTOM':
        worldX = verticalHover.x;
        worldY = verticalHover.y + 115;
        if (app.editMenuPosition === 'bottom') {
          worldY = verticalHover.y + 60;
        }
        // worldY +=app.stickyNoteHeight as number +30;
        break;
      default:
        break;
    }

    frame.drawHoverNode(
      worldX,
      worldY,
      ENodeType.STICKY_NOTE,
      app.stickyNoteData?.nodeData.fill?.fillColor,
    );
  };

  render() {
    const { app, frame } = this.props;
    app.removeHoverArrow = this.removeHoverArrow;
    return (
      <>
        <div
          className="plus-container"
          style={{
            transform: `translate3d(${app.btn1X}px, ${app.btn1Y}px, 0)`,
          }}
        >
          <button
            type="button"
            id="plus-btn"
            onClick={() => {
              this.drawnode(app.btnPos);
            }}
            onMouseOver={() => this.drawHoverNode(app.btnPos)}
            onFocus={() => {}}
            onMouseOut={() => {
              frame.removeHoverNode();
            }}
            onBlur={() => {}}
          >
            <Add />
          </button>
        </div>
        <div
          className="plus-container"
          style={{
            transform: `translate3d(${app.btn2X}px, ${app.btn2Y}px, 0)`,
          }}
        >
          <button
            type="button"
            id="arrow"
            onClick={() => this.drawarrow(app.btnPos)}
            onMouseOver={() => this.drawHoverArrow(app.btnPos)}
            onFocus={() => {}}
            onMouseOut={() => {
              this.removeHoverArrow();
              frame.removeHoverNode();
            }}
            onBlur={() => {}}
          >
            {app.btnPos === 'RIGHT' && <RightArrow />}
            {app.btnPos === 'LEFT' && <LeftArrow />}
            {app.btnPos === 'TOP' && <UpArrow />}
            {app.btnPos === 'BOTTOM' && <DownArrow />}
          </button>
        </div>
      </>
    );
  }
}

/**
 * Redux actions mapped to canvas props
 * @param dispatch
 * @returns
 */
const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  editNodes: (
    payload:TEditNodesArgs,
  ) => dispatch(editNodes(payload)),
});

export default withRouter(connect(mapDispatchToProps)(Plus));
