import { Link, useHistory } from 'react-router-dom';
import {
  FC, FormEvent, SyntheticEvent, useEffect, useState,
} from 'react';
// import LoginModal from 'src/components/utilities/loginModal/LoginModal'
import './footer.scss';
import { validateEmail } from 'src/util/helper/email-validation';
import axios from 'axios';
import { OLD_API } from 'src/endpoints/api';
import footer from './footer.copy';
// import { ScheduleDemo } from 'src/components/utilities/navbar/scheduleDemo/ScheduleDemo'
// import { AuthComponent } from 'src/components/authComponent/AuthComponent'

const {
  account,
  company,
  learn,
  connect,
  newsletter,
} = footer;

export type FooterAccountProps = {
  showLogin: () => void
};

const Account: FC<FooterAccountProps> = ({
  showLogin,
}) => (
  <div className="footer-account">
    <h4>{account.header}</h4>
    <span className="clickable" role="presentation" onClick={showLogin}>{account.text1}</span>
    <span className="clickable">{account.text2}</span>
  </div>
);

const PrivacyPolicy = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button className="clickable privacy-container" type="button" onClick={() => setOpen((prev) => !prev)}>
        {company.text1}
      </button>
      {
      open && (
      <>
        <a
          className="clickable privacy-links"
          // eslint-disable-next-line max-len
          href="https://docs.google.com/document/d/e/2PACX-1vSXCyp-WC5_J1oYGAcQOwtiVhXH6eDhgzoVB1c3hngn4ZOagmnyCG3s9PlHGvZOog/pub"
          target="_blank"
          rel="noopener noreferrer"
        >
          US &amp; International
        </a>
        <a
          className="clickable privacy-links"
          // eslint-disable-next-line max-len
          href="https://docs.google.com/document/d/e/2PACX-1vQnvDVhSWzeuISScfL8diO3V-yVMm9SBYh2nLjsYjOxLKjqE2Yqdb0ey0F0U6h0wQ/pub"
          target="_blank"
          rel="noopener noreferrer"
        >
          California
        </a>
      </>
      )
    }
    </>
  );
};

const Company = () => {
  const nayaReleasePage = "https://naya-studio.notion.site/What-s-new-51ed00267bf842ab80d2f4ae3d8aa4d5?pvs=4";

  return (
    <div className="footer-company">
      <h4>{company.header}</h4>
      <a 
        className="clickable" 
        href={nayaReleasePage} 
        target='_blank'
        rel="noopener noreferrer"
      >
          What&apos;s new
      </a>
      <PrivacyPolicy />
      <a
        className="clickable"
        href="https://docs.google.com/document/d/e/2PACX-1vRp1F6ekOvO8C0KNirQYE_xkT73hA_rK36UzgOD0daHtwqMsyHBFEWLnFOm-wJR6Q/pub"
        target="_blank"
        rel="noopener noreferrer"
      >
        {company.text2}
      </a>
      <a className="clickable" href="mailto:info@naya.studio">{company.text3}</a>
    </div>
  );
}

const Learn = () => (
  <div className="footer-learn">
    <h4>{learn.header}</h4>
    <Link to="/get-in-touch" className="clickable">{learn.text1}</Link>
    <Link to="/get-in-touch" className="clickable">{learn.text2}</Link>
  </div>
);

const Connect = () => (
  <div className="footer-connect">
    <h4>{connect.header}</h4>
    <div className="social-container">
      {connect.social.map((icon) => (
        <a
          href={icon.href}
          target="_blank"
          rel="noreferrer"
          key={icon.alt}
        >
          <img src={icon.src} alt={icon.alt} />
        </a>
      ))}
    </div>
  </div>
);

const Newsletter: FC<FooterNewsletterProps> = ({
  value,
  onChange,
  onSubmit,
  disabled,
}) => (
  <div className="footer-newsletter">
    <h4>{newsletter.header}</h4>
    <span>{newsletter.text1}</span>
    <form onSubmit={onSubmit}>
      <input required type="email" value={value} onChange={onChange} placeholder={newsletter.input1} />
      <button type="submit" onSubmit={onSubmit} disabled={disabled}>
        {newsletter.button1}
      </button>
    </form>
  </div>
);

const LandingPageFooter = () => {
  // const [ showLogin, setShowLogin ] = useState(false)
  const [email, setEmail] = useState('');
  const [valid, setValid] = useState(false);
  // const [showScheduleDemoModal, setShowScheduleDemoModal] = useState(false)
  const history = useHistory();

  useEffect(() => {
    if (validateEmail(email)) setValid(true);
    else setValid(false);
  }, [email]);

  return (
    <div id="landing-page_footer">
      {false && (
      <Account
        showLogin={() => { history.push('/login'); }}
      />
      )}
      <Company />
      {false && <Learn />}
      <Connect />
      <Newsletter
        value={email}
        onChange={(e: FormEvent<HTMLInputElement>) => setEmail(e.currentTarget?.value)}
        onSubmit={(e: SyntheticEvent) => {
          e.preventDefault();
          e.stopPropagation();

          axios.post(`${OLD_API}/api/lead`, { email })
            .catch((err) => {
              console.log(err);
            });
        }}
        disabled={!valid}
      />
      {/* <LoginModal
      show={showLogin}
      close={() => setShowLogin(false)}
      navigateToScheduleDemo = {() => {
        setShowScheduleDemoModal(true)
        setShowLogin(false)
      }}
    />
    <ScheduleDemo
      show={showScheduleDemoModal}
      close={() => setShowScheduleDemoModal(false)}
      navigateToLogin={() => {
        setShowLogin(true)
        setShowScheduleDemoModal(false)
      }}
    /> */}
      {/* {
      <AuthComponent show={showLogin} close={() => setShowLogin(false)} view={'LOGIN'} />
    } */}
    </div>
  );
};

export type FooterNewsletterProps = {
  value: string,
  onChange: (e: FormEvent<HTMLInputElement>) => void,
  onSubmit: (e: SyntheticEvent) => void,
  disabled: boolean
};

export default LandingPageFooter;
