import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'src/redux/reducers/root.types';
import { loadStage } from '../reduxActions/stage';
import { generateIdsFromUrl } from '../reduxActions/util';

/**
 * Custom hook to access all the stages and state of stage with provided id
 * @param id - stage id to be accessed
 * @returns redux state of stage with provided id and all redux stages
 */
const useStages = (id?: string) => {
	const dispatch = useDispatch();
	const { stageId: urlStageId } = generateIdsFromUrl();
	const stageId = id || (urlStageId as string);

	const { reduxStages, stage, isLoading, error } = useSelector((state: ReduxState) => ({
		stage: state.stages.data[stageId],
		isLoading: state.stages.loading[stageId],
		error: state.stages.error[stageId],
		reduxStages: state.stages.data
	}));

	/**
	 * Re-runs when hook is mounted
	 */
	useEffect(() => {
		// If stage does not exist in redux, load it into redux
		// also load the blocks for the stage
		if (!stage && stageId) {
			dispatch(loadStage({ payload: { _id: stageId } }));
		}
	}, []);

	return {
		reduxStages,
		stage,
		isLoading,
		error
	};
};

export default useStages;
