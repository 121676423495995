/* eslint-disable import/order */
import {
	ENodeType,
	IBlock,
	ICanvas,
	IFeedback,
	INode,
	IUser,
	TCanvasBounds,
	TUserAndRole
} from '@naya_studio/types';
import { v1 as uuidv1 } from 'uuid';
import React, { useEffect, useRef, useState } from 'react';
import { store } from 'src';
import App from './app/App'; // Import of app should be above Frame, for successfull webpack initialization
import Frame from './app/nodes/Frame';
import { addBatchUndoActions, undoAction } from 'src/redux/actions/undoRedoActions';
import { EActionType } from 'src/redux/reducers/undoRedo/undoActionHistory.types';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { ISnackBar, ReduxState } from 'src/redux/reducers/root.types';
import './Canvas.scss';
import _ from 'lodash';
import { findStageWithBlockId } from 'src/redux/actions/util';
import { getFeedbackBasedOnBlockType } from 'src/redux/reduxActions/util';
import { resetNewComment } from 'src/redux/features/newComment';
import EditMenu from './editMenu/EditMenu';
import Plus from './plus/Plus';
import ThreeDContextMenu from '../3dContextMenu/3dContextMenu';
import CanvasMenu from '../canvasMenu/canvasMenu';
import Controls from './controls/Controls';
import CanvasEditMenu from './canvasEditMenu/CanvasEditMenu';
import { CanvasProps, PathParamsType } from './Canvas.types';
import CanvasName from './CanvasName';
import { TOOLS } from '../collaborationTool/CollaborationTool.types';
import useProject from 'src/redux/hooks/useProject';
import { TAddNodesArg } from 'src/types/argTypes';
import { addNodes } from 'src/redux/reduxActions/node';
import useBlock from 'src/redux/hooks/useBlocks';
import { CustomDispatch } from 'src/redux/actions/types';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';
import {
	checkIfCurrentUserIsAdmin,
	checkUserAccessLevel
} from 'src/util/accessLevel/accessLevelActions';
import useUser from 'src/redux/hooks/user';
import getUserFromRedux from 'src/util/helper/user';

const Canvas: React.FC<CanvasProps> = ({
	width: widthCanvas,
	height: heightCanvas,
	extractPixiApp,
	setSnackbarMsg,
	hideAllMenu,
	ptMode,
	toolComponent: Tool,
	setPTMode,
	setHideAllMenu,
	disabled,
	isDummy,
	onPlaceAll,
	snackbarMsg,
	createThumbnailOfPage,
	notifyZoom
}) => {
	const app = useRef<App>();
	const canvasJustLoaded = useRef<boolean>();
	const canvasId = useRef<string>();
	const frame = useRef<Frame>();

	const nextX = useRef<number>();
	const nextY = useRef<number>();

	canvasJustLoaded.current = false;
	const [tool, setTool] = useState('SELECT');
	const [showEditMenu, setShowEditMenu] = useState(false);
	const [showPlus, setShowPlus] = useState(false);
	const [show3dContext, toggle3dContext] = useState(false);
	const [rightX, setRightX] = useState(0);
	const [rightY, setRightY] = useState(0);
	const [showCanvasMenu, toggleCanvasMenu] = useState(false);
	const [showActiveTool, toggleShowActiveTool] = useState(true);
	const [showCanvasEditMenu, toggleCanvasEditMenu] = useState(false);
	const [showPageName, setShowPageName] = useState(true);
	const [canvasEdgeHover, setCanvasEdgeHover] = useState(false);
	const [maxObjHeight, setMaxObjHeight] = useState(0);
	const [initialX, setInitialX] = useState(0);
	const [activeTextProperties, setActiveTextProperties] = useState([{}]);
	const [feedbacks, setFeedbacks] = useState<IFeedback[]>([]);
	const [children, setChildren] = useState<INode[]>([]);
	const tools = TOOLS;
	const params = useParams<PathParamsType>();
	const history = useHistory();
	const dispatch = useDispatch();
	const { user: loggedinUser } = useUser();
	const [, setRerenderCanvas] = useState(false);

	const theme = useSelector((state) => (state as ReduxState).theme);
	const nodesRedux = useSelector((state) => (state as ReduxState).nodes.data);
	const allBlockFeedbacks = useSelector((state) => (state as ReduxState).feedbacks.data);
	const { project } = useProject(params.projectId);
	const stage = findStageWithBlockId(params.canvasId);
	//  typecasting direct to canvas as this is only canvas specific component
	const { block: canvas } = useBlock(params.canvasId as string) as { block: ICanvas };

	// Change Frame Color
	const changeFrameColor = (data: string) => {
		if (app.current) {
			app.current.setBackground(app.current.activeTexture, app.current.textureOpacity, data);
			app.current?.frame?.draw();
		}
	};

	const removeQuillMentionContainer = () => {
		// Removing the quill mention container if exists
		const quillMentionContainer = document.getElementsByClassName(
			'ql-mention-list-container'
		)[0];
		if (quillMentionContainer) {
			quillMentionContainer.parentNode?.removeChild(quillMentionContainer);
		}
		// setting boolean to false because if a reply is getting closed,
		// commentHasText variable is not set to false again
		if (app.current) app.current.commentHasText = false;
	};

	/**
	 * Function called when tool is changed, it sets the active tool
	 * and nodeData in app. This function controls the pixi app's mousedown tool
	 * Changed the tool in the Canvas's state
	 * @param {string} tool
	 * @param {BaseNode} nodeData
	 */
	const selectTool = (toolVal: string, nodeData: INode) => {
		if (app.current) {
			const { newComment } = store.getState();
			app.current.isPanSelected = false;
			app.current.setActiveTool(toolVal);
			app.current.nodeData = nodeData;
			if (tools.includes(toolVal)) setTool(toolVal);
			// if selected tool is not comment and there's some data in redux new comment reset it
			if (
				toolVal !== 'COMMENT' &&
				(newComment.data?.initialComment || newComment.data?.initialType)
			) {
				dispatch(resetNewComment());
			}
			// (app.current as App).saveTextEdit();
			if (toolVal === 'SELECT') {
				app.current.viewport.plugins.resume('drag');
			}
		}
	};

	// clears X, Y coordinates set by getNodeBounds so the objects will start spanning from center
	const clearXYCoordinates = () => {
		// setNextX(0);
		// setNextY(0);
		nextX.current = 0;
		nextY.current = 0;
		setMaxObjHeight(0);
		setInitialX(0);
	};

	const getNodeBounds = (objectWidth: number, objectHeight: number, scale: boolean = true) => {
		if (app.current) {
			const {
				height: vpHeight,
				width: vpWidth,
				x: vpX,
				y: vpY
			} = app.current.viewport.getVisibleBounds();

			// calculate the center of canvas
			let x = nextX.current || vpX + vpWidth / 2;
			let y = nextY.current || vpY + vpHeight / 2;

			if (scale) {
				let scaleX = 1;
				let scaleY = 1;
				if (objectWidth > objectHeight) {
					scaleX = 300 / objectWidth;
					scaleY = scaleX;
				} else {
					scaleY = 300 / objectHeight;
					scaleX = scaleY;
				}
				objectWidth *= scaleX;
				objectHeight *= scaleY;
			}
			if (initialX === 0) {
				setInitialX(x);
			}
			if (nextX.current && nextX.current + objectWidth > vpWidth + vpX) {
				y += maxObjHeight + 8;
				x = initialX;
				setMaxObjHeight(0);
			}
			if (objectHeight > maxObjHeight) {
				setMaxObjHeight(objectHeight);
			}
			nextX.current = x + objectWidth + 8;
			nextY.current = y;
			return { x, y };
		}
		return { x: 0, y: 0 };
	};

	const addImageToCanvas = (data: any) => {
		const { height, width } = data.absoluteBounds;
		if (app.current) {
			app.current.canvasEditing = true;
			app.current.hasCanvasUpdatedForThumbnail = true;
			data.absoluteBounds = { ...data.absoluteBounds, ...getNodeBounds(width, height) };
			const payloadData = JSON.parse(JSON.stringify(data));
			const nodeData = payloadData as INode;
			payloadData.zIndex = app.current.getHighestZIndex() + 1;
			// extracting redux state
			const user = getUserFromRedux();
			// generating payload for add nodes api call
			if (!nodeData?._id) nodeData._id = uuidv1();
			const payload: TAddNodesArg = {
				data: {
					nodes: [
						{
							...nodeData,
							version: 0,
							createdBy: user._id as string,
							lastUpdatedBy: user._id as string,
							opacity: 1
						}
					],
					blockId: canvas?._id as string,
					projectId: project?._id as string,
					stageId: stage?._id as string
				},
				prevState: {
					prevNodes: nodesRedux,
					prevBlock: canvas as IBlock
				}
			};
			// add nodes dispatch
			(store.dispatch as CustomDispatch)(addNodes(payload))
				.unwrap()
				.then((response?: { nodes?: INode[] }) => {
					if (response?.nodes) {
						if (response?.nodes?.length > 1) {
							const undoPayload: Array<{
								prevNodeData: INode | IFeedback;
								newNodeData: INode | IFeedback;
							}> = [];

							response?.nodes?.forEach((node: INode) => {
								if (node) {
									undoPayload.push({
										prevNodeData: node,
										newNodeData: { ...node, isVisible: false }
									});
								}
							});

							addBatchUndoActions('NEW_NODE' as EActionType, undoPayload);
						} else {
							undoAction(
								'NEW_NODE' as EActionType,
								{ ...(response?.nodes![0] as INode), isVisible: false },
								response?.nodes[0] as INode,
								false
							);
						}
					}
					(app.current as App).canvasEditing = true;
				})
				.catch((error) => {
					console.error('Error : ', error);

					const snackbarPayload: ISnackBar = {
						text: 'Failed to upload files.',
						show: true,
						type: 'ERROR'
					};

					addSnackbar(snackbarPayload);
					removeSnackbar(2000);
				});
		}
	};

	/**
	 * Function to handle pasted files
	 * @param nodeData //node data
	 * @param callback //callback to set snackbar after adding nodes
	 */
	const addThreeDModalToCanvas = (nodeData: INode, callback: () => void) => {
		const user: IUser = getUserFromRedux();
		const apiPayload: TAddNodesArg = {
			data: {
				nodes: [
					{
						...nodeData,
						version: 0,
						createdBy: user._id as string,
						lastUpdatedBy: user._id as string
					}
				],
				blockId: canvas?._id as string,
				projectId: project?._id as string,
				stageId: stage?._id as string
			},
			prevState: {
				prevNodes: nodesRedux,
				prevBlock: canvas as IBlock
			},
			next: callback
		};

		(store.dispatch as CustomDispatch)(addNodes(apiPayload))
			.unwrap()
			.then((response?: { nodes?: INode[] }) => {
				if (response?.nodes) {
					if (response?.nodes?.length > 1) {
						const data: Array<{
							prevNodeData: INode | IFeedback;
							newNodeData: INode | IFeedback;
						}> = [];

						response?.nodes?.forEach((node: INode) => {
							if (node) {
								data.push({
									prevNodeData: node,
									newNodeData: { ...node, isVisible: false }
								});
							}
						});

						addBatchUndoActions(EActionType.NEW_NODE, data);
					} else {
						undoAction(
							EActionType.NEW_NODE,
							{ ...(response?.nodes![0] as INode), isVisible: false },
							response?.nodes[0] as INode,
							false
						);
					}
				}
				(app.current as App).canvasEditing = false;
			})
			.catch((error) => {
				console.error('Error : ', error);

				const snackbarPayload: ISnackBar = {
					text: 'Failed to upload files.',
					show: true,
					type: 'ERROR'
				};

				addSnackbar(snackbarPayload);
				removeSnackbar(2000);
			});
	};

	const toggleEditMenu = (toggleValue: boolean) => {
		setShowEditMenu(toggleValue);

		if (!toggleValue && app.current) {
			app.current.keyboardLock = false;
		}
	};

	const togglePlus = (togglePlusVal: boolean) => {
		setShowPlus(togglePlusVal);
		// this is to make sure that ghost stickynote is not visible unnecessarily
		if (!showPlus) {
			(frame.current as Frame).removeHoverNode();
			if (app.current && app.current.removeHoverArrow) {
				app.current.removeHoverArrow!();
			}
		}
	};

	const toggleHideAllMenu = () => {
		setHideAllMenu(!hideAllMenu);
	};

	const checkContextMenuAccess = () => {
		if (
			canvas &&
			(checkUserAccessLevel(canvas.users as TUserAndRole[], loggedinUser._id as string, [
				'EDITOR',
				'OWNER'
			]) ||
				checkIfCurrentUserIsAdmin())
		) {
			return true;
		}
		return false;
	};

	const redirect = (url: string) => {
		history.push(url);
	};

	const onTextSelectionChange = (properties: any) => {
		if (!_.isEqual(properties, activeTextProperties)) {
			setActiveTextProperties(properties);
		}
	};

	const getCanvasClassName = () => {
		if (app.current) {
			if (app.current.isPanSelected) return 'canvas-grab';
			const uploadInProgress = [
				'Adding Files',
				'Adding Snapshot',
				'Click or drag to place files'
			].includes(snackbarMsg);
			if (uploadInProgress) {
				return '';
			}
			switch (app.current.activeTool) {
				case 'IMAGE':
					return 'canvas-image-gallery';
				case 'SHAPE':
				case 'TEXT':
					return 'canvas-crosshair';
				case 'MARKUP':
					return 'canvas-markup';
				case 'COMMENT':
					return 'canvas-comment-cursor';
				default:
					return '';
			}
		}
		return '';
	};

	const handleFullScreenChange = () => {
		if (document.fullscreenElement) {
			if (app.current) {
				app.current.ptMode = true;
				app.current.app.renderer.backgroundColor = 0x000000;
				app.current.viewport.plugins.pause('wheel');
				app.current.pauseDragPlugin();
				app.current.viewport.setZoom(4, true);
				app.current.firstRender = true;
				app.current.zoomPercent = 400;
				app.current.resizePixiApp();
				app.current.fullScreenZoom();
				app.current.render();
			}
		} else if (app.current) {
			app.current.viewport.mask = null;
			app.current.resizePixiApp();
			app.current.viewport.setZoom(4, true);
			app.current.zoomPercent = 400;
			setPTMode(false);
			app.current.app.renderer.backgroundColor = 0xf1f1f1;
			app.current.ptMode = false;
			app.current.initialZoom();
			app.current.resumeDragPlugin();
			app.current.viewport.plugins.resume('wheel');
			app.current.redrawNodesByType([ENodeType.MODEL, ENodeType.TEXT]);
			app.current.render();
		}
	};

	// Used to update feedback position on canvas pan and zoom
	const updateFeedbacks = _.throttle(() => {
		const feedbacksEles = document.querySelectorAll('.feedback-trigger');
		// Iterate over all the feedbacks and update the position
		if (feedbacksEles) {
			// Iterate through feedbacks and update their position on pan or zoom
			feedbacksEles.forEach((feedback) => {
				const x = feedback.getAttribute('data-translateX');
				const y = feedback.getAttribute('data-translateY');
				if (x && y) {
					const posTemp = window.pixiApp!.viewport.toGlobal({
						x: +x,
						y: +y
					});

					(
						feedback as HTMLButtonElement
					).style.transform = `translate3d(${posTemp.x}px, ${posTemp.y}px, 0px)`;
				}
			});
		}
	}, 50);

	/**
	 * Function to debounce resize calls for 500ms
	 */
	const debounceResize = _.debounce(() => {
		(app.current as App).resizePixiApp();
	}, 500);

	// render the component on zoom
	const notifyZoomWithRerender = () => {
		setRerenderCanvas((rerender) => !rerender);
		notifyZoom();
	};

	/**
	 * Initialise app, called only once
	 */
	useEffect(() => {
		canvasId.current = params.canvasId!;
		app.current = new App(
			widthCanvas,
			heightCanvas,
			notifyZoomWithRerender,
			updateFeedbacks,
			toggleEditMenu,
			togglePlus,
			canvasId.current,
			selectTool,
			toggle3dContext,
			toggleCanvasMenu,
			redirect,
			toggleShowActiveTool,
			toggleCanvasEditMenu,
			setShowPageName,
			setCanvasEdgeHover,
			onTextSelectionChange
		);

		if (extractPixiApp) extractPixiApp(app.current);
		frame.current = new Frame(app.current);
		app.current.initializeApp('#canvas');
		selectTool('SELECT', {});
	}, []);

	/**
	 * Runs every time the canvasId in url chnages
	 * Functions:
	 * 1. Canvas Resetting
	 * 2. Draw all nodes of that canvas
	 */
	useEffect(() => {
		if (params.canvasId && app.current && nodesRedux) {
			canvasId.current = params.canvasId;
			app.current.setCanvasId(params.canvasId);

			// 1. Canvas Reseting
			app.current.setCanvasId(canvasId.current as string);
			app.current.removeAllSelectedNodes();
			app.current.removeTransformer();
			app.current.toggleCanvasEditMenu(false);
			app.current.canvasEditing = false;
			removeQuillMentionContainer();

			// 2. Draw all nodes of that canvas
			if ((project?.children as string[])?.length > 0) {
				// setCanvas(canvasFound);
				/** Bounds */
				if (canvas && canvas.bounds) {
					const { x, y, width, height } = canvas.bounds as TCanvasBounds;
					app.current.initializeFrameDimentions(
						x as number,
						y as number,
						width as number,
						height as number
					);
				}

				let childrenArr: INode[] = [];
				if (canvas) {
					for (let i = 0; i < (canvas?.nodes as INode[])?.length; i++) {
						const node = nodesRedux[(canvas?.nodes as INode[])[i] as string];
						if (node) {
							childrenArr.push(node);
						}
					}
				}
				childrenArr = childrenArr.filter((node) => (node as INode)?.isVisible);
				setChildren(childrenArr);
				app.current.addNodes(childrenArr as INode[], true);
				// add seperate function to delete nodes
				app.current.resolveImageResolution();
				canvasJustLoaded.current = true;
			}
		}
	}, [params.canvasId]);

	useEffect(() => {
		setFeedbacks(getFeedbackBasedOnBlockType(canvas?._id as string) as IFeedback[]);
	}, [allBlockFeedbacks]);

	/**
	 * Runs every time nodes are changed in redux
	 * and draw the changed nodes
	 */
	useEffect(() => {
		if (!canvasJustLoaded.current) {
			if (app.current && canvas) {
				let childrenArr: INode[] = [];
				for (let i = 0; i < (canvas?.nodes as string[])?.length; i++) {
					if (canvas?.nodes[i]) {
						const reduxNode = nodesRedux[canvas.nodes[i] as string];
						if (reduxNode) childrenArr.push(reduxNode as INode);
					}
				}
				childrenArr = childrenArr.filter((node) => (node as INode)?.isVisible);
				if (childrenArr.length !== children.length) {
					setChildren([...childrenArr]);
					app.current.addNodes(childrenArr as INode[], false);
				}
			}
		} else {
			canvasJustLoaded.current = false;
		}
	}, [nodesRedux, canvas?.nodes]);

	useEffect(() => {
		if (canvas && !_.isEqual(feedbacks, canvas.feedbacks)) {
			setFeedbacks(getFeedbackBasedOnBlockType(canvas?._id as string) as IFeedback[]);
		}
	}, [canvas?.feedbacks]);
	/**
	 * Functions:
	 * 1. Listen to socket changes
	 * 2. Add Event Listeners
	 * 3. Store project and remove event listeners
	 */
	useEffect(() => {
		if (!window.location.pathname.includes('new')) {
			// initYDocWatchers(app.current as App);
		}

		const handleBeforeUnload = () => {
			createThumbnailOfPage(params.canvasId);
		};

		// 2. Add Event Listeners
		window.addEventListener('beforeunload', handleBeforeUnload);

		// prevents overflow in <html> tag, overflow occurs when dragging feedbacks to edge of screen.
		document.documentElement.style.overflow = 'hidden';

		window.addEventListener('resize', debounceResize);

		window.addEventListener('fullscreenchange', handleFullScreenChange);

		if (app.current) {
			app.current.setTransformerColor(theme.color);
		}

		// 3. Store project and remove event listeners
		return () => {
			document.documentElement.style?.removeProperty('overflow');

			window.removeEventListener('fullscreenchange', handleFullScreenChange);
			window.removeEventListener('resize', (app.current as App).resizePixiApp);
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, []);

	/**
	 * Updates on changes to theme color
	 */
	useEffect(() => {
		if (app.current) {
			const { color } = theme;
			app.current.theme = theme;
			app.current.setTransformerColor(color);
			app.current.redrawNodesByType([ENodeType.MODEL, ENodeType.TEXT]);
		}
	}, [theme.color]);

	// Runs on mount
	// If the window height is less than 715px to avoid overlapping of feedback button on toolbar
	// moving feedback button to 60px from left
	useEffect(() => {
		const feedbackButton = document.querySelector('#jsd-widget') as HTMLDivElement;
		if (feedbackButton && window.innerHeight < 714) {
			feedbackButton.style.left = '60px';
		}
	}, []);

	/** Set coordinates of Page name and Add Page (+) icons */
	// if (app.current) {
	// 	topAddPagePosition = app.current.viewport.toGlobal({
	// 		x: (app.current.frameX as number) + (app.current.width as number) / 2,
	// 		y: app.current.frameY as number
	// 	});
	// }
	return (
		<>
			{showPageName && app.current && (
				<CanvasName
					app={app.current}
					name={canvas?.name}
					toggleCanvasEditMenu={toggleCanvasEditMenu}
					canvasEdgeHover={canvasEdgeHover}
					showCanvasEditMenu={showCanvasEditMenu}
				/>
			)}
			<div
				id="canvas"
				className={getCanvasClassName()}
				onContextMenu={(e: React.MouseEvent) => {
					// Preventing right click menu opening up only if the user
					// right clicks on the canvas
					const DOMClickTarget = e.nativeEvent.target as HTMLElement;
					if (DOMClickTarget.tagName === 'CANVAS') {
						e.preventDefault();
					}
					setRightX(e.clientX);
					setRightY(e.clientY);
				}}
				onClick={(e: any) => {
					if (e.button === 0) {
						toggleCanvasMenu(false);
					}
				}}
				onPointerDown={() => {
					if ((app.current as App).rightClick === false) {
						toggle3dContext(false);
						(app.current as App).rightClick = false;
					}
					store.dispatch({
						type: 'OPEN_COMMENT',
						payload: ''
					});
					// Removing the quill mention container if exists
					removeQuillMentionContainer();
				}}
				role="presentation"
			>
				{(app.current as App)?.activeTool === 'REACTION' && (
					<div
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
						}}
						role="presentation"
					>
						<img
							src={(app.current as App).nodeData?.image?.src}
							alt="current-reaction"
							id="sticky-emoji"
							style={{
								position: 'absolute',
								left: -10,
								top: -10,
								pointerEvents: 'none',
								opacity: 0.5,
								maxWidth: 32,
								maxHeight: 32
							}}
						/>
					</div>
				)}
				{/* Edit menu */}
				{showEditMenu && (
					<EditMenu
						app={app.current as App}
						activeTextProperties={activeTextProperties}
						nodes={[...(app.current as App).selectedNodes]}
					/>
				)}
				{showPlus && <Plus app={app.current as App} frame={frame.current as Frame} />}
				{show3dContext && checkContextMenuAccess() && (
					<ThreeDContextMenu
						rightX={rightX}
						rightY={rightY}
						app={app.current as App}
						toggle3dContext={toggle3dContext}
						setSnackbarMsg={setSnackbarMsg}
					/>
				)}
				{showCanvasMenu && checkContextMenuAccess() && (
					<CanvasMenu
						rightX={rightX}
						rightY={rightY}
						app={app.current as App}
						toggleCanvasMenu={toggleCanvasMenu}
						toggleHideAllMenu={toggleHideAllMenu}
					/>
				)}
				{/* Toolbar creation, this method is not type-safe,
            when creating your own toolbar, please define your own props */}
				{!hideAllMenu &&
					!ptMode &&
					app.current &&
					React.cloneElement(<Tool />, {
						tools,
						selectTool,
						app: app.current as App,
						addImageToCanvas,
						tool: isDummy ? tool : (app.current as App)?.activeTool,
						disabled,
						isDummy,
						canvasId: canvas?._id,
						onPlaceAll,
						snackbarMsg,
						showActiveTool,
						clearXYCoordinates,
						getNodeBounds,
						addThreeDModalToCanvas
					})}
				{!hideAllMenu && !disabled && !ptMode && app.current && (
					<Controls app={app.current as App} selectTool={selectTool} tool={tool} />
				)}
				{showCanvasEditMenu && !ptMode && (
					<CanvasEditMenu
						app={app.current as App}
						canvas={canvas as ICanvas}
						setCanvasColor={changeFrameColor}
					/>
				)}
				{/* {showCanvasEditMenu && !ptMode && topAddPagePosition && (
					<AddPage
						position={{ x: topAddPagePosition.x! - 12, y: topAddPagePosition.y! - 43 }}
						buttonPosition="TOP"
					/>
				)} */}
			</div>
		</>
	);
};

export default Canvas;
