import { useEffect, useState } from 'react';
import { setMetaLinkData, getIcon, ISubType } from '@naya_studio/journey';
import { IBlock, IGroup, ILink } from '@naya_studio/types';
import { TimelineData, TimelineView, getDayDifference } from '@naya_studio/radix-ui';
import { defaultBlockIcon, groupIcon } from 'src/components/utilities/navbar/utils';
import { allShades } from 'src/redux/reduxActions/util';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';

export type TimelineWrapperProps = {
	filteredGroups: Partial<IGroup>[];
	filteredBlocks: { [key: string]: Partial<IBlock> };
	onBlockClick: (groupId: string, blockId: string) => void;
};

/**
 * Adds the percentage of white color to the given hex color
 * @param hexColor
 * @param percentage
 * @returns Hex color
 */
function addWhiteToHex(hexColor: string, percentage: number) {
	// Ensure the hex color starts with a "#"
	if (hexColor[0] !== '#') {
		hexColor = `#${hexColor}`;
	}

	// Convert hex to RGB
	let r = parseInt(hexColor.substring(1, 3), 16);
	let g = parseInt(hexColor.substring(3, 5), 16);
	let b = parseInt(hexColor.substring(5, 7), 16);

	// Calculate the new RGB values by blending with white (255, 255, 255)
	r = Math.round(r + ((255 - r) * percentage) / 100);
	g = Math.round(g + ((255 - g) * percentage) / 100);
	b = Math.round(b + ((255 - b) * percentage) / 100);

	// Convert RGB back to hex
	const toHex = (value: number) => {
		const hex = value.toString(16);
		return hex.length === 1 ? `0${hex}` : hex;
	};

	return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

export const TimelineWrapper = ({
	filteredGroups,
	filteredBlocks,
	onBlockClick
}: TimelineWrapperProps) => {
	const [timelineData, setTimelineData] = useState<TimelineData>([]);

	/**
	 * Function to get block's icon
	 * @param blockId string
	 * @param link string
	 * @param blockType string
	 * @returns icon url
	 */
	const getBlockIcon = async (blockId: string, link: string, blockType: string) => {
		let linkIcon = '';

		if (link && blockType === 'LINK') {
			try {
				const { favIconUrl } = await setMetaLinkData(blockId, link);
				linkIcon = favIconUrl || '';
			} catch (error) {
				console.error(`Error fetching meta link data for block ${blockId}:`, error);
			}
		}
		const iconUrl = getIcon(blockType, linkIcon);
		return iconUrl;
	};

	/**
	 * Generate the timeline data with blocks and groups
	 */
	const generateTimelineDate = async () => {
		const entries: TimelineData = [];
		const blocks = Object.values(filteredBlocks) as IBlock[];
		const groups = filteredGroups as IGroup[];

		if (blocks) {
			const blockPromises = blocks.map(async (block, index) => {
				if (!block?.dates) return null;

				const iconUrl = await getBlockIcon(
					block._id as string,
					(block as ILink)?.link,
					(block as ISubType).subType || block.blockType
				);

				const dueOrStartDate = (block.dates.dueDate || block.dates.startDate) as Date;
				// Keeping startDate and end as same and just changing the hours, because for multi date range
				// the library won't put the event in the day slot.
				const startDate = new Date(new Date(dueOrStartDate).setHours(0));
				const endDate = new Date((block.dates.dueDate || block.dates.endDate) as Date);
				const end = new Date(new Date(dueOrStartDate).setHours(1));

				const isMultiDay = getDayDifference(startDate, endDate) >= 1;

				return {
					title: block.name || `Untitled ${index}`,
					start: startDate,
					end,
					resource: {
						index,
						id: block._id as string,
						type: 'BLOCK',
						icon: iconUrl || defaultBlockIcon,
						onClick: () => onBlockClick(block.parentId, block._id as string),
						endDate,
						backgroundColor: isMultiDay ? '#ffffff' : 'transparent',
						borderColor:
							block.color.toLowerCase() === '#eaeaea' ? 'transparent' : block.color
					}
				};
			});

			// Resolve all promises concurrently
			const resolvedEntries = await Promise.all(blockPromises);

			// Filter out any null results
			entries.push(...(resolvedEntries.filter(Boolean) as TimelineData));
		} else {
			console.error('filteredBlocksPhases.blocks is undefined or null.');
		}

		if (groups) {
			const groupPromises = groups.map(async (group: IGroup, index) => {
				if (!group.dates) return null;

				const dueOrStartDate = group.dates.dueDate || (group.dates.startDate as Date);

				const startDate = new Date(new Date(dueOrStartDate).setHours(0));
				const endDate = new Date((group.dates.dueDate || group.dates.endDate) as Date);
				const end = new Date(new Date(dueOrStartDate).setHours(1));

				let groupColor = group.color || '';

				const isMultiDay = getDayDifference(startDate, endDate) >= 1;
				const isDefaultGroupColor = group.color.toUpperCase() === '#F5F5F5';

				if (isDefaultGroupColor) {
					if (isMultiDay) groupColor = '#ffffff';
					else groupColor = 'transparent';
				} else {
					const colorShades = allShades.find((shade) => shade.includes(groupColor));
					if (colorShades) {
						const colorIndex = colorShades.indexOf(groupColor);
						if (colorIndex > 5) {
							groupColor = addWhiteToHex(groupColor, 15);
						} else if (colorIndex > 2) {
							groupColor = addWhiteToHex(groupColor, 30);
						} else {
							groupColor = addWhiteToHex(groupColor, 40);
						}
					} else {
						groupColor = 'transparent';
					}
				}

				return {
					title: group.name || `Untitled ${index}`,
					start: startDate,
					end,
					resource: {
						index: timelineData.length,
						id: group._id as string,
						type: 'GROUP',
						icon: groupIcon,
						onClick: () => {
							if (group.children[0]) {
								onBlockClick(group._id as string, group.children[0] as string);
							} else {
								addSnackbar({
									show: true,
									type: 'NORMAL',
									text: `${group?.name} is an empty group, add files to view.`
								});
								removeSnackbar(3000);
							}
						},
						endDate,
						backgroundColor: groupColor,
						iconBgColor: !isMultiDay && !isDefaultGroupColor ? group.color : '#ffffff'
					}
				};
			});

			// Resolve all promises concurrently
			const resolvedGroups = await Promise.all(groupPromises);

			// Filter out any null results
			entries.push(...(resolvedGroups.filter(Boolean) as TimelineData));
		}

		// Sort the entries by the most recent start date
		entries.sort(
			(a, b) => new Date(a.start as Date).getTime() - new Date(b.start as Date).getTime()
		);

		// Update the timeline state only once after processing all entries
		entries.forEach((entry, index) => {
			entry.resource.index = index;
		});
		setTimelineData(entries);
	};

	// runs on mount and on filteredBlocks change
	useEffect(() => {
		generateTimelineDate();
	}, [filteredBlocks]);

	return (
		<TimelineView
			timelineData={timelineData}
			backgroundColor="#ffffff"
			weekendLineColor="#EAEAEA"
			currentDateColor="#5714ac"
		/>
	);
};
