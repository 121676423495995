import React, { createRef, RefObject } from 'react';
import { Slider } from '@mui/material';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BackgroundProps, TextureIconObject } from './Backgrounds.types';
import BlankIcon from '../../../assets/icons/backgrounds/blank.svg';
import DottedIcon from '../../../assets/icons/backgrounds/dotted.svg';
import GridIcon from '../../../assets/icons/backgrounds/grid.svg';
import IsosIcon from '../../../assets/icons/backgrounds/isos.svg';
import LinedIcon from '../../../assets/icons/backgrounds/lined.svg';
import Download from '../../../assets/icons/toolbar/download.svg';
import Colors from '../editMenu/MenuOptions/Colors/Colors';

import './Backgrounds.scss';
// import AddPage from '../addPage/AddPage';

type BackgroundsState = {
  showBgOptions:boolean,
  sliderValue:number,
  selectedOption: string,
};

class Backgrounds extends React.Component<BackgroundProps, BackgroundsState> {
  wrapperRef: RefObject<HTMLDivElement>;

  iconList: TextureIconObject;

  constructor(props: BackgroundProps) {
    super(props);
    this.wrapperRef = createRef();
    this.iconList = {
      '': BlankIcon, 'NONE': BlankIcon, DOT: DottedIcon, GRID: GridIcon, ISOS: IsosIcon, LINED: LinedIcon,
    };

    this.state = {
      showBgOptions: false,
      sliderValue: 40,
      selectedOption: '',
    };
  }

  componentDidMount() {
    const { app } = this.props;
    document.addEventListener('mousedown', this.handleClickOutside);
    this.setState({ sliderValue: app.textureOpacity * 100 });
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event: any) => {
    if (this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ showBgOptions: false });
    }
  };

  onBackGroundClick = (bgType: string) => {
    const { app } = this.props;
    app.setBackground(bgType, app.textureOpacity, app.bkgColor);
    app.removeTransformer();
    app.selectFrame();
  };

  // @ts-ignore
  onSliderChange = (e:any, newValue:any) => {
    const { app } = this.props;
    if (newValue >= 20) {
      this.setState({ sliderValue: newValue });
      app.setBackground(app.activeTexture, newValue / 100, app.bkgColor);
    }
  };

  /**
* sets the option to be selected
* @param {string} option
*/
  setSelectedOption = (option: string) => {
    this.setState({ selectedOption: option });
  };

  render() {
    const { showBgOptions, sliderValue, selectedOption } = this.state;
    const { app, setCanvasBkgColor } = this.props;
    const { downloadCanvas, app: { activeTexture } } = this.props;
    return (
      <div
        id="background-wrap"
        ref={this.wrapperRef}
        onClick={(e) => { e.stopPropagation(); }}
        onMouseDown={(e) => { e.stopPropagation(); }}
        role="presentation"
      >
        <div className="background-icon">
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 1000, hide: 0 }}
            overlay={(
              <Tooltip
                id="button-tooltip-2"
                style={{
                  fontSize: '12px',
                  borderRadius: '2px',
                  lineHeight: '16px',
                  marginTop: '8px',
                }}
              >
                Change Style
              </Tooltip>
                        )}
          >
            {({ ref, ...triggerHandler }) => (
              <span
                ref={ref}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...triggerHandler}
                className={`pattern ${showBgOptions ? 'active' : ''}`}
              >
                <img
                  src={this.iconList[activeTexture]}
                  alt="dotted"
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.setState((prevState) => ({ showBgOptions: !prevState.showBgOptions }))}
                  role="presentation"
                />
              </span>
            )}
          </OverlayTrigger>
          <div className="divider" />
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 1000, hide: 0 }}
            overlay={(
              <Tooltip
                id="button-tooltip-2"
                style={{
                  fontSize: '12px',
                  borderRadius: '2px',
                  lineHeight: '16px',
                  marginTop: '8px',
                }}
              >
                Download
              </Tooltip>
                        )}
          >
            {({ ref, ...triggerHandler }) => (
              <img
                role="presentation"
                ref={ref}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...triggerHandler}
                className="download"
                onClick={downloadCanvas}
                src={Download}
                alt="download"
              />
            )}
          </OverlayTrigger>
          <div className="divider" />
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 1000, hide: 0 }}
            overlay={(
              <Tooltip
                id="button-tooltip-2"
                style={{
                  fontSize: '12px',
                  borderRadius: '2px',
                  lineHeight: '16px',
                  marginTop: '8px',
                }}
              >
                Change Canvas Color
              </Tooltip>
            )}
          >
            <Colors
              app={app}
              setSelectedOption={this.setSelectedOption}
              selectedOption={selectedOption}
              setCanvasBkgColor={setCanvasBkgColor}
            />
          </OverlayTrigger>
        </div>
        {showBgOptions && (
        <div className="all-bg-wrap">
          <div className="heading">Change style</div>
          <div className="all-bg-icons">
            {Object.keys(this.iconList).map((key: string) => (
              <div key={key}>
                <img
                  role="presentation"
                  src={this.iconList[key]}
                  alt={key}
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.onBackGroundClick(key)}
                />
              </div>
            ))}
          </div>
          <hr />
          <div className="slider-wrap">
            <Slider
              value={sliderValue}
              onChange={this.onSliderChange}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              marks
              step={20}
              min={0}
              max={100}
            />
          </div>
        </div>
        )}
        {/* <AddPage position={showBgOptions ? { x: 42, y: 157 } : { x: 42, y: 12 }} buttonPosition="BOTTOM" /> */}

      </div>
    );
  }
}

export default Backgrounds;
