import { FC, ReactElement } from 'react';
import useTracking from './googleTag';

export type GTagTrackingHOCProps = {
	children: ReactElement;
};

const GTagTrackingHOC: FC<GTagTrackingHOCProps> = ({ children }) => {
	useTracking('UA-135193062-1');

	return children;
};

export default GTagTrackingHOC;
