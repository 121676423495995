/* eslint-disable prefer-destructuring */
import { useEffect, useRef } from 'react';
import { onINP, INPMetric, onCLS, CLSMetric } from 'web-vitals';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';
import { sleep } from 'src/util/collaboration/util';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TLDFlags } from 'src/components/collaborationTool/CollaborationTool.types';
import { SessionStorageKeys } from '@naya_studio/radix-ui';

const constrains = {
	internetSpeed: 1,
	browserVersion: {
		// Ref: https://www.browsers.fyi/
		chrome: 126,
		edge: 126,
		safari: 17.7,
		firefox: 126
	} as Record<string, number>,
	performanceThresholds: {
		CLS: 0.5,
		INP: 600
	}
};

type TWebVitalMetrics = CLSMetric | INPMetric;

type TBrowserInfo = { name: string; version: string | undefined };

type TMetrics = { times: number };

/** Function to save metric in session storage */
const saveMetricsInSession = (metrics: TMetrics) => {
	if (metrics)
		sessionStorage.setItem(SessionStorageKeys.PERFORMANCE_METRICS, JSON.stringify(metrics));
};

/** Function to get metric from session storage */
const getMetricsFromSession = () => {
	let metrics = null;
	const stringyFideMetrics = sessionStorage.getItem(SessionStorageKeys.PERFORMANCE_METRICS);
	if (stringyFideMetrics) {
		metrics = JSON.parse(stringyFideMetrics);
	}
	return metrics;
};

/**
 * Function to get internet speed
 * @returns speed in Mbps
 */
function getInternetSpeed(cb: (speedInMbps: number) => void) {
	const image = new Image();
	const imageUrl =
		'https://firebasestorage.googleapis.com/v0/b/naya-brand-content/o/naya-banner' +
		'.png?alt=media&token=ff1fe8a5-d6b7-412e-93df-953e850e7fce'; // Use a URL of a known file
	const imageSize = 421132; // Size of the image in bytes

	let startTime: number;
	let endTime: number;

	function calculateSpeed() {
		const duration = (endTime - startTime) / 1000; // Convert milliseconds to seconds
		const bitsLoaded = imageSize * 8; // Convert bytes to bits
		const speedBps = bitsLoaded / duration; // Speed in bits per second
		const speedKbps = speedBps / 1024; // Speed in kbps
		const speedMbps = speedKbps / 1024; // Speed in Mbps

		cb(speedMbps);
	}

	image.onload = function () {
		endTime = new Date().getTime();
		calculateSpeed();
	};

	image.onerror = function () {
		console.error('Error loading image.');
	};

	startTime = new Date().getTime();
	image.src = `${imageUrl}&nocache=${Math.random()}`; // Cache-busting to prevent cached image loading
}

/**
 * Function to get browser info(name, version)
 */
function getBrowserInfo() {
	const userAgent = navigator.userAgent;
	let browserName;
	let version;

	if (userAgent.indexOf('Chrome') > -1) {
		browserName = 'Chrome';
		const stringMatch = userAgent.match(/Chrome\/(\d+)/);
		if (stringMatch) {
			version = stringMatch[1];
		}
	} else if (userAgent.indexOf('Firefox') > -1) {
		browserName = 'Firefox';
		const stringMatch = userAgent.match(/Firefox\/(\d+)/);
		if (stringMatch) {
			version = stringMatch[1];
		}
	} else if (userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') === -1) {
		browserName = 'Safari';
		const stringMatch = userAgent.match(/Version\/(\d+)/);
		if (stringMatch) {
			version = stringMatch[1];
		}
	} else if (userAgent.indexOf('Edg') > -1) {
		browserName = 'Edge';
		const stringMatch = userAgent.match(/Edg\/(\d+)/);
		if (stringMatch) {
			version = stringMatch[1];
		}
	} else {
		browserName = 'Unknown';
		version = 'Unknown';
	}

	return { name: browserName, version };
}

/**
 * Function to monitor INP and CLS
 * @param onSlowPerformance callback function
 */
function monitorPerformance(onSlowPerformance: () => void) {
	const checkMetric = (metric: TWebVitalMetrics, threshold: number) => {
		if (metric.value > threshold) {
			onSlowPerformance();
		}
	};

	onINP((metric) => checkMetric(metric, constrains.performanceThresholds.INP), {
		reportAllChanges: true
	});
	onCLS((metric) => checkMetric(metric, constrains.performanceThresholds.CLS), {
		reportAllChanges: true
	});
}

/**
 * Monitors the App's performance and show appropriate snackbar
 */
const useMonitorPerformance = () => {
	const isSnackBarInViewRef = useRef(false);
	const canMonitorRef = useRef(false);
	const isFirstTimeRef = useRef(true);

	const { isPopUpsEnabled } = useFlags<TLDFlags>();

	canMonitorRef.current = !localStorage.getItem('DISABLE_PERFORMANCE_MONITORING');

	/**
	 * Adds snackbar
	 * @param message string
	 */
	const showSnackBar = (message: string) => {
		if (!canMonitorRef.current) return;
		isSnackBarInViewRef.current = true;
		addSnackbar({
			text: message,
			type: 'NORMAL',
			show: true,
			actionButtonData: [
				{
					// Unicode of cross mark
					buttonData: `\u2715`,
					onClick: () => {
						isSnackBarInViewRef.current = false;
						removeSnackbar(0);
					},
					className: 'dismiss',
					show: true
				}
			]
		});
	};

	const waitIfASnackbarIsInView = async () => {
		if (isSnackBarInViewRef.current) {
			await sleep(5000);
		}
	};

	/** Function to disable monitoring */
	const disableMonitoring = () => {
		localStorage.setItem('DISABLE_PERFORMANCE_MONITORING', 'true');
		canMonitorRef.current = false;
	};

	/**
	 * Function to show snackbar if the internet speed is slow
	 * @param speedInMbps internet speed in Mbps
	 */
	const checkInternetSpeed = async (speedInMbps: number) => {
		if (!canMonitorRef.current) return;
		if (speedInMbps && speedInMbps < constrains.internetSpeed) {
			await waitIfASnackbarIsInView();
			showSnackBar('Please check your connection for a better experience!');
			disableMonitoring();
		}
	};

	/**
	 * Checks browser compatibility and displays snackbar messages if necessary.
	 *
	 * @param {object} browserInfo - An object containing information about the user's browser.
	 * @param {string} browserInfo.name - The name of the browser (e.g., "chrome", "firefox", "safari", "edge").
	 * @param {string | undefined} browserInfo.version - The version of the browser (optional).
	 *
	 */
	const checkBrowserCompatibility = async (browserInfo: TBrowserInfo) => {
		if (!canMonitorRef.current) return;
		const browserName = browserInfo.name.toLowerCase();
		const requiredVersion = constrains.browserVersion[browserName];
		const supportedBrowsers = Object.keys(constrains.browserVersion);

		// Check if the browser is supported
		if (!supportedBrowsers.includes(browserName)) {
			await waitIfASnackbarIsInView();

			// Display a snackbar suggesting switching to a supported browser
			showSnackBar(
				'Try switching browser to chrome/firefox/safari or edge for optimal Naya experience'
			);
			disableMonitoring();
		} else if (
			browserInfo.version &&
			requiredVersion &&
			parseFloat(browserInfo.version) < requiredVersion
		) {
			await waitIfASnackbarIsInView();

			// Display a snackbar prompting the user to update their browser
			showSnackBar('Please update your browser for optimal Naya experience');
			disableMonitoring();
		}
	};

	/**
	 * Function to handle App's slow performance
	 */
	const onSlowPerformance = async () => {
		if (!canMonitorRef.current) return;
		const perfMetrics = getMetricsFromSession() as TMetrics;

		// !isFirstTimeRef.current prevents the pop-up from being displayed immediately when a project is opened.
		if (!isFirstTimeRef.current) {
			// Show the pop-up on 1st and every 30th time
			if (perfMetrics && perfMetrics.times % 30 === 0) {
				await waitIfASnackbarIsInView();
				showSnackBar('Thanks for your patience. We are improving platform speed shortly!');
			}
			perfMetrics.times++;
			saveMetricsInSession(perfMetrics);
			disableMonitoring();
		} else {
			isFirstTimeRef.current = false;
			const newMetrics = { times: 0 };
			saveMetricsInSession(newMetrics);
		}
	};

	/** Runs on mount */
	useEffect(() => {
		if (isPopUpsEnabled) {
			// Slow internet speed
			getInternetSpeed(checkInternetSpeed);

			// Check browser version
			checkBrowserCompatibility(getBrowserInfo());

			// Monitor app's performance
			monitorPerformance(onSlowPerformance);
		}
	}, [isPopUpsEnabled]);
};

export default useMonitorPerformance;
