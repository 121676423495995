export enum ProjectEvents {
	CREATE_NEW_PROJECT = 'CREATE_NEW_PROJECT',
	OPEN_PROJECT = 'OPEN_PROJECT',

	RENAMED_FROM_REMINDER = 'RENAMED_FROM_REMINDER'
}

export enum CustomEvents {
	ADD_NOTE = 'ADD_NOTE',
	ADD_DATE = 'ADD_DATE',
	ADD_RANGE = 'ADD_DATE_RANGE',

	ADD_CUSTOM_COLOR = 'ADD_CUSTOM_COLOR',
	DUPLICATE_ELEMENT = 'DUPLICATE_ELEMENT',
	EDIT_THUMBNAIL = 'EDIT_THUMBNAIL',
	EXPORT_ITEM = 'EXPORT_ITEM',
	MARK_FAVORITE = 'MARK_FAVORITE',

	GUEST_ACCESS_TOGGLED = 'GUEST_ACCESS_TOGGLED',
	JOINED_AS_GUEST = 'JOINED_AS_GUEST',

	JOURNEY_ALIGN = 'ALIGN_JOURNEY',
	REQUEST_ACCESS = 'REQUEST_ACCESS',

	USE_SEARCH = 'USE_SEARCH',
	SHARE_ITEM = 'SHARE_ITEM',

	TAGGED_AI = 'TAGGED_AI'
}

export enum GroupEvents {
	ADD_GROUP = 'ADD_GROUP',
	RENAME_GROUP = 'RENAME_GROUP'
}

export enum BlockEvents {
	ADD_BLOCK = 'ADD_BLOCK',
	RENAME_BLOCK = 'RENAME_BLOCK',
	BLOCK_RESET = 'BLOCK_RESET',
	GENERATIVE_AI = 'GENERATE_BLOCK',
	GENERATED_PROMPTS = 'GENERATED_PROMPTS'
}

export enum FeedbackEvents {
	POST_POLL = 'POST_POLL',
	POST_COMMENT = 'POST_COMMENT',
	TAG_COMMENT = 'TAG_COMMENT',
	MARK_TASK_COMPLETE = 'MARK_TASK_COMPLETE'
}

export enum SuperPowerEvents {
	OPEN_SUSTAINABILITY = 'OPEN_SUSTAINABILITY',
	RUN_SUSTAINABILITY = 'RUN_SUSTAINABILITY',
	OPEN_MATCHMAKING = 'OPEN_MATCHMAKING',
	RUN_MATCHMAKING = 'RUN_MATCHMAKING',
	OPEN_ESTIMATES = 'OPEN_ESTIMATES',
	RUN_ESTIMATES = 'RUN_ESTIMATES',
	OPEN_3D_PRINTING = 'OPEN_3D_PRINTING',
	ORDER_3D_PRINT = 'ORDER_3D_PRINT'
}

export enum TemplatesEvents {
	USE_TEMPLATE = 'USE_TEMPLATE',
	TEMPLATES_GALLERY = 'VIEW_TEMPLATES'
}

type AnalyticEvents =
	| CustomEvents
	| ProjectEvents
	| GroupEvents
	| BlockEvents
	| FeedbackEvents
	| SuperPowerEvents
	| TemplatesEvents;

export default AnalyticEvents;
