import instagram from 'src/assets/icons/social/instagram_small.svg';
import linkedin from 'src/assets/icons/social/linkedin_small.svg';
import medium from 'src/assets/icons/social/medium_small.svg';
import twitter from 'src/assets/icons/social/twitter_small.svg';
import pinterest from 'src/assets/icons/social/pinterest_small.svg';
import discord from 'src/assets/icons/social/discord.svg';
import './SocialLinks.scss';

// Social media links and icons
export const SOCIAL_LINKS = [
	{
		id: '1',
		link: 'https://www.instagram.com/naya__studio/?hl=en',
		name: 'instagram',
		url: instagram,
		ariaLabel: 'Naya Instagram page'
	},
	{
		id: '2',
		link: 'https://discord.gg/CEHf6tcb',
		name: 'discord',
		url: discord,
		ariaLabel: 'Naya Discord community'
	},
	{
		id: '3',
		link: 'https://www.linkedin.com/company/studio-naya/mycompany/',
		name: 'linkedin',
		url: linkedin,
		ariaLabel: 'Naya Linked page'
	},
	{
		id: '4',
		link: 'https://twitter.com/naya_app',
		name: 'twitter',
		url: twitter,
		ariaLabel: 'Naya Twitter page'
	},
	{
		id: '5',
		link: 'https://www.pinterest.ca/naya_studio',
		name: 'pinterest',
		url: pinterest,
		hoverUrl: '/assets/footer/pinterest-hover.svg',
		ariaLabel: 'Naya Pinterest page'
	},
	{
		id: '6',
		link: 'https://medium.com/naya-studio',
		name: 'medium',
		url: medium,
		ariaLabel: 'Naya Medium page'
	}
];

/**
 * Component used for social icon user in auth view
 */
const SocialLinks = () => (
	<div className="tw-flex tw-flex-col tw-gap-4 tw-items-center">
		<div className="social-heading tw-text-sm">In the meantime be a part of our community:</div>
		<div className="social-icon-wrapper tw-gap-6">
			{SOCIAL_LINKS.map((data) => (
				<a
					key={data.id}
					href={data.link}
					target="_blank"
					rel="noreferrer"
					className="tw-p-2 tw-rounded-full hover:tw-bg-[#F8F5FD]"
					aria-label={data.ariaLabel}
				>
					<img alt={data.name} src={data.url} />
				</a>
			))}
		</div>
	</div>
);

export default SocialLinks;
