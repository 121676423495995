import { FC, ReactElement, useState } from 'react';
import './OrderPrint.scss';
import { getFileInfoFromCache } from 'src/components/canvas/utils/helper';
import { store } from 'src';
import { uploadModelToShapeways } from 'src/redux/actions/prototyping';
import ClipLoader from 'react-spinners/ClipLoader';
import { useHistory } from 'react-router';
import { isSupportedModel } from 'src/features/components/homebase/prototyping/utils/helper';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';
import { CustomDispatch } from 'src/redux/actions/types';
import order_print from '../../../../../assets/icons/order_print.svg';
import { OrderPrintProps } from './OrderPrint.types';

const OrderPrint: FC<OrderPrintProps> = ({ app }): ReactElement => {
	const [printShow, setPrintShow] = useState(false);
	const history = useHistory();
	const handlePrint = async () => {
		setPrintShow(true);
		const selectedNode = app.selectedNodes[0];
		if (selectedNode) {
			const { nodeData } = selectedNode;
			const modelSrc = nodeData.model?.src;
			const modelName = nodeData.model?.name;
			const extension = nodeData.model?.extension;
			if (isSupportedModel(extension)) {
				if (modelName && modelSrc && extension) {
					const data = await getFileInfoFromCache(modelName, modelSrc, extension);
					if (data === 'Invalid Model') {
						addSnackbar({ show: true, type: 'ERROR', text: 'Invalid Model' });
						removeSnackbar(5000);
						setPrintShow(false);
					} else if (data) {
						(store.dispatch as CustomDispatch)(
							uploadModelToShapeways({
								...data,
								uploadedFrom: history.location.pathname
							})
						);
						history.push('/prototyping/upload');
					}
				}
			} else {
				addSnackbar({
					show: true,
					type: 'ERROR',
					text: 'Model type is not support for printing'
				});
				removeSnackbar(5000);
				setPrintShow(false);
			}
		}
	};

	if (printShow) {
		return (
			<div className="orderPrintLoader">
				<ClipLoader color="#5d5d5d" loading size={12} />
			</div>
		);
	}
	return (
		<img
			role="presentation"
			src={order_print}
			style={{ background: printShow ? '#EDEDED' : 'transparent' }}
			className="OrderPrintImage"
			alt=""
			onClick={handlePrint}
		/>
	);
};
export default OrderPrint;
