import { useRef, useState, useContext } from 'react';
import { FilterOptions, Popover, InputVariant } from '@naya_studio/radix-ui';
import { capitalize, debounce } from 'lodash';
import './SearchBar.scss';
import {
	TContent,
	fileLinkTypes,
	getCollaborators,
	timelineIcon,
	handleFilterOption,
	stickyColorNames
} from 'src/components/utilities/navbar/utils';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TLDFlags, TSearchQuery } from '../../CollaborationTool.types';
import { ReactComponent as Magnifier } from '../../../../assets/icons/homebase/magnifier.svg';
import { CollaborationToolContext } from '../../CollaborationTool';

type SearchBarProps = {} & React.DetailedHTMLProps<
	React.HTMLAttributes<HTMLDivElement>,
	HTMLDivElement
>;

const SearchBar = ({ ...htmlProps }: SearchBarProps) => {
	const collabContext = CollaborationToolContext;
	const {
		searchFilters,
		availableTypes,
		createdByUsers,
		stickyNotes,
		isGuestUser,
		pendingUsersId,
		setSearchFilters
	} = useContext(collabContext);

	// Holds reference to div element
	const searchBarRef = useRef<HTMLDivElement>(null);
	const filtersRef = useRef<HTMLUListElement>(null);
	// Holds
	const [showFilters, setShowFilters] = useState<boolean>();
	// Reset text search
	const [resetInput, setResetInput] = useState<boolean>(false);
	const [inputText, setInputText] = useState<string>(searchFilters?.text || '');

	// Function for updating filter query
	const updateQueryText = (text: string) => {
		setSearchFilters((prev: TSearchQuery) => {
			let temp = { ...prev };
			if (text.trim().length === 0) {
				delete temp.text;
			} else {
				temp = {
					...prev,
					text: text.trim()
				};
			}
			return temp;
		});
	};

	// function to handle input change
	const handleInputChange = debounce((value: string) => updateQueryText(value), 200);

	// close the search bar the input element on closing the search filter
	const closeTheSearchBar = () => {
		const inputEle = document.querySelector('.journey-search-bar input') as HTMLInputElement;
		if (inputEle) {
			inputEle.blur();
		}
		setShowFilters(false);
	};

	// Handle input submit
	const handleSubmit = (value: string, trigger?: 'BLUR' | 'ENTER') => {
		if (value.trim()) {
			setInputText(value);
			updateQueryText(value);
			// If the by pressing enter triggered the submit, then close the filters container
			if (trigger === 'ENTER') {
				closeTheSearchBar();
			}
		}
	};

	/**
	 * Function to handle filters change
	 * @param query {TSearchQuery} - applied filters object
	 */
	const handleFiltersChange = (variant: string, content: TContent) => {
		// Close the filters container on adding filter
		setShowFilters(false);
		const query = handleFilterOption(variant, content, searchFilters);
		if (query) {
			setSearchFilters(query);
		}
	};

	// Function to handle filters popover show
	const handleShowFilters = (isOpen: boolean) => {
		setShowFilters(isOpen);
	};

	// Function to clear all the filters
	const handleClearAll = () => {
		setInputText('');
		setResetInput(!resetInput);
		setShowFilters(false);
		setSearchFilters({});
	};

	const { isTimelineEnabled } = useFlags<TLDFlags>();

	return (
		<div
			{...htmlProps}
			className={`search-bar-wrapper tw-px-4 tw-relative ${htmlProps.className} ${classNames({
				active: showFilters
			})}`}
			ref={searchBarRef}
		>
			<Popover open={showFilters} onOpenChange={handleShowFilters}>
				<Popover.Trigger
					className="search-bar-trigger tw-w-full"
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
					}}
				>
					<InputVariant
						showCancelButton
						icon={<Magnifier width={24} height={24} />}
						placeHolder="Search or filter images, links, 3D files, integrations, etc"
						searchLag={50}
						className="search-bar-medium journey-search-bar"
						// @ts-ignore TODO : Fix these
						onSubmit={handleSubmit}
						// @ts-ignore TODO : Fix these
						onChange={handleInputChange}
						reset={resetInput}
						data-testid="search-block-input"
						onClear={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
							if (!showFilters) {
								e.stopPropagation();
								e.preventDefault();
							}
							setInputText('');
							updateQueryText('');
						}}
						text={inputText}
						onFocus={() => {
							setShowFilters(true);
						}}
					/>
				</Popover.Trigger>
				<Popover.Portal container={searchBarRef.current}>
					<Popover.Content
						asChild
						autoFocus={false}
						onOpenAutoFocus={(e) => e.preventDefault()}
						style={{
							zIndex: 200
						}}
						className="expanded-content tw-w-full"
						onEscapeKeyDown={() => {
							closeTheSearchBar();
						}}
					>
						<ul
							className="extended-search-bar tw-list-none tw-pt-2 
						tw-pl-4 tw-pr-4 tw-pb-4 tw-flex-col tw-items-start tw-grow"
							ref={filtersRef}
						>
							<li
								className="clear-all tw-text-xs tw-cursor-pointer"
								role="presentation"
								onClick={handleClearAll}
							>
								Clear all
							</li>
							{availableTypes && availableTypes.length > 0 && (
								<li className="type-container tw-flex tw-gap-4 tw-flex-wrap">
									{availableTypes &&
										availableTypes.length > 0 &&
										availableTypes.map((type) => (
											<FilterOptions
												key={type}
												variant="ICON"
												content={
													{
														icon: fileLinkTypes[type],
														text: type
													} as TContent & string
												}
												searchFilters={searchFilters}
												onClick={() => {
													handleFiltersChange('ICON', {
														icon: fileLinkTypes[type],
														text: capitalize(type)
													} as TContent & string);
												}}
											/>
										))}
								</li>
							)}
							{!isGuestUser && (
								<li className="tw-flex tw-gap-4">
									{isTimelineEnabled && (
										<FilterOptions
											variant="TIMELINE_VIEW"
											content={
												{
													text: 'Timeline (Date)',
													icon: timelineIcon
												} as TContent & string
											}
											searchFilters={searchFilters}
											onClick={() => {
												handleFiltersChange('TIMELINE_VIEW', {
													text: 'timelineView'
												} as TContent & string);
											}}
										/>
									)}
									<FilterOptions
										variant="LAST_UPDATED"
										content={{ text: 'Last updated' } as TContent & string}
										searchFilters={searchFilters}
										onClick={() => {
											handleFiltersChange('LAST_UPDATED', {
												text: 'Last updated'
											} as TContent & string);
										}}
									/>
									<FilterOptions
										variant="DUE_DATE"
										content={{ text: 'Has date' } as TContent & string}
										searchFilters={searchFilters}
										onClick={() => {
											handleFiltersChange('DUE_DATE', {
												text: 'Has date'
											} as TContent & string);
										}}
									/>
								</li>
							)}
							{stickyNotes && !isGuestUser && stickyNotes.length > 0 && (
								<span className="tw-text-xs">Sticky notes</span>
							)}
							{stickyNotes && !isGuestUser && stickyNotes.length > 0 && (
								<li className="type-container tw-flex tw-gap-4 tw-flex-wrap">
									{stickyNotes &&
										stickyNotes.length > 0 &&
										stickyNotes.map((color) => (
											<FilterOptions
												key={color}
												variant="STICKY_NOTE"
												content={
													{
														icon: color,
														text: stickyColorNames[color]
													} as TContent & string
												}
												searchFilters={searchFilters}
												onClick={() => {
													handleFiltersChange('STICKY_NOTE', {
														icon: color,
														text: capitalize(stickyColorNames[color])
													} as TContent & string);
												}}
											/>
										))}
								</li>
							)}
							{createdByUsers && !isGuestUser && createdByUsers.length > 0 && (
								<li className="created-by-users">
									<span className="tw-text-xs">Created by</span>
									<div
										className="tw-pt-2 tw-flex tw-flex-wrap"
										data-testid="sf-createdby-row"
									>
										{createdByUsers
											.filter((user) => user)
											.map((user) => (
												<FilterOptions
													key={user.email}
													variant="USER_CREATED_BY"
													content={
														{
															userDetail: user,
															text: user.email as string
														} as TContent & string
													}
													searchFilters={searchFilters}
													onClick={() => {
														handleFiltersChange('USER_CREATED_BY', {
															userDetail: user,
															text: user.email as string
														} as TContent & string);
													}}
												/>
											))}
									</div>
								</li>
							)}
							{getCollaborators().filter(
								(user) => !pendingUsersId.includes(user._id as string)
							).length > 0 &&
								!isGuestUser && (
									<li className="shared-with-users">
										<span className="tw-text-xs">Shared with</span>
										<div
											className="tw-pt-2 tw-flex tw-flex-wrap"
											data-testid="sf-sharedby-row"
										>
											{getCollaborators()
												.filter(
													(user) =>
														!pendingUsersId.includes(user._id as string)
												)
												.map((user) => (
													<FilterOptions
														key={user.email}
														variant="USER_SHARED_WITH"
														content={
															{
																userDetail: user,
																text: user.email as string
															} as TContent & string
														}
														searchFilters={searchFilters}
														onClick={() => {
															handleFiltersChange(
																'USER_SHARED_WITH',
																{
																	userDetail: user,
																	text: user.email as string
																} as TContent & string
															);
														}}
													/>
												))}
										</div>
									</li>
								)}
						</ul>
					</Popover.Content>
				</Popover.Portal>
			</Popover>
		</div>
	);
};

export default SearchBar;
