import { FC, ReactElement } from "react";
import { SharingHeaderProps } from "./Header.types";
import SharingTabs from "./Tabs/Tabs";
import ShareDetails from "../Details/Details";
import './Header.scss'

/**
 * Component to render Sharing Modal Header
 */
const Header: FC<SharingHeaderProps> = ({
  activeTab,
  setActiveTab,
  projectId,
  title,
  variant,
  children,
  isEmpty
}): ReactElement => {
  /**
   * Function to render content of Header based on variant
   */
  const renderHeaderContent = () => {
    return variant === 'WITH_TABS' ? (
      <SharingTabs activeTab={activeTab} setActiveTab={setActiveTab} projectId={projectId} />
    ) : (
      <ShareDetails activeTab={activeTab} title={title} isEmpty={isEmpty}/>
    );
  }

  return (
    <div className="sharing-header tw-flex tw-justify-between">
      {renderHeaderContent()}
      {children}
    </div>
  );
};

export default Header;