import { FC, ReactElement, useState, useEffect } from 'react';
import personIcon from 'src/assets/icons/sharing-modal/person.svg';
import './UserRow.scss';
import { EUserRole, IUser } from '@naya_studio/types';
import { DropDown } from '@naya_studio/radix-ui';
import dropdownIcon from 'src/assets/icons/sharing-modal/dropdown.svg';
import cn from 'classnames';
import useUser from 'src/redux/hooks/user';
import { UserRowProps } from './UserRow.types';

/**
 * Defined type that will be displayed in dropdowns
 */
type TRole = {
	key: EUserRole;
	title: string;
};

/**
 * All the roles we'll support for editing
 */
const roles: TRole[] = [
	{
		key: EUserRole.EDITOR,
		title: 'Editor'
	},
	{
		key: EUserRole.COMMENTER,
		title: 'Commenter'
	},
	{
		key: EUserRole.VIEWER,
		title: 'Viewer'
	}
];

/**
 * Component to render Invited User Row
 */
const UserRow: FC<UserRowProps> = ({ userData, onEditRole, disabled }): ReactElement => {
	const [selectedRole, setSelectedRole] = useState<TRole | undefined>();
	const { user: currentUser } = useUser();

	/**
	 * Update the selected role whenever new role is selected from dropdown
	 */
	useEffect(() => {
		const { role } = userData;
		setSelectedRole(roles.find((r) => r.key === role));
	}, [userData]);

	const user = userData.user as IUser;
	const { firstName } = user;
	const userName = user.userName || user.email?.substring(0, user.email.indexOf('@'));
	const isOwner = userData.role === 'OWNER';
	/** Check if this row is for logged in user */
	const isHimSelf = currentUser._id === user._id || currentUser.email === user.email;

	return (
		<div className="user-row-wrappper tw-flex tw-item-center tw-justify-between">
			<div className="tw-flex tw-item-center">
				<img
					src={user.profilePic ? user.profilePic : personIcon}
					alt="person"
					className="profile-pic"
				/>
				<div className="tw-ml-2">
					<div className="user-name tw-text-xs tw-font-randMedium">
						{firstName || userName}
					</div>
					<div
						data-testid="share-modal-user-list-email"
						className="user-email tw-text-xs tw-font-randRegular"
					>
						{user.email}
					</div>
				</div>
			</div>
			{!isOwner && (
				<DropDown>
					<DropDown.DropDownTrigger disabled={disabled || isHimSelf}>
						<div
							data-testid="share-modal-user-list-access-type"
							className="sharing-dropdown-role tw-flex tw-items-center tw-mr-1 tw-p-2 tw-py-1 tw-rounded"
						>
							<span className="tw-text-xs tw-font-randRegular tw-mr-2">
								{selectedRole?.title}
							</span>
							<img
								src={dropdownIcon}
								alt="dropdown"
								className={`${cn({ 'tw-hidden': disabled || isHimSelf })}`}
							/>
						</div>
					</DropDown.DropDownTrigger>
					<DropDown.DropDownContent classNames="tw-p-2 dropdown-content width-130 tw-rounded-2xl tw-bg-white tw-right-0">
						{roles.map((role: TRole) => (
							<DropDown.DropDownItem>
								<div
									role="presentation"
									data-testid={`share-modal-user-list-access-type-${role.title.toLowerCase()}`}
									className={`kebab-items tw-text-xs tw-font-randRegular 
                                            tw-leading-6 tw-pt-1 tw-pb-1 tw-px-2 tw-cursor-pointer`}
									onClick={() => {
										setSelectedRole(role);
										onEditRole('CHANGE_ROLE', userData, role.key);
									}}
								>
									{role.title}
								</div>
							</DropDown.DropDownItem>
						))}
						<DropDown.DropDownItem>
							<div
								role="presentation"
								data-testid="share-modal-user-list-access-type-remove"
								className={`kebab-items tw-text-xs tw-font-randRegular 
                                    tw-leading-6 tw-pt-1 tw-pb-1 tw-px-2 tw-cursor-pointer red-text`}
								onClick={() => {
									onEditRole('REMOVE', userData);
								}}
							>
								Remove
							</div>
						</DropDown.DropDownItem>
					</DropDown.DropDownContent>
				</DropDown>
			)}
		</div>
	);
};

export default UserRow;
