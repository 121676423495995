import { IUser } from '@naya_studio/types';
import * as Y from 'yjs';
import { TChangeType, getYDoc } from 'src/rtc/yjs/yjsConfig';
import { useDispatch } from 'react-redux';
import { Map as YMap } from 'yjs';
import {
	addUsersToProjectUsers,
	removeUserFromProjectUsers
} from 'src/redux/features/projectUsers';
import getUserFromRedux from 'src/util/helper/user';
import { broadcastSelfDispatch } from './utils';

type TUserInMap = {
	invitedBy?: string;
	removedBy?: string;
} & IUser;

/**
 * Hook to update project users in redux after yjs listens to project users change
 */
const useProjectUsersObserver = () => {
	const dispatch = useDispatch();

	/**
	 * Observer to listen to project users changes in ymap
	 * @param update project users change to do in redux
	 */
	const projectUsersObserver = (update: Y.YMapEvent<string>) => {
		try {
			const doc = getYDoc();
			const user = getUserFromRedux();

			update.changes.keys.forEach((change: TChangeType<string>, key: string) => {
				if (change.action === 'add') {
					const invitedUser = JSON.parse(
						doc.getMap('projectUsers').get(key) as string
					) as TUserInMap;
					const { invitedBy } = invitedUser;
					const selfDispatch = invitedBy === (user._id as string);

					if (!selfDispatch) dispatch(addUsersToProjectUsers([invitedUser]));
					else broadcastSelfDispatch([invitedUser], 'ADD', 'USER');
				} else if (change.action === 'delete') {
					const removedUser = JSON.parse(change.oldValue as string) as TUserInMap;
					const { removedBy } = removedUser;
					const selfDispatch = removedBy === (user._id as string);

					if (!selfDispatch) dispatch(removeUserFromProjectUsers(key));
					else broadcastSelfDispatch(key, 'DELETE', 'USER');
				}
			});
		} catch (error) {
			console.error('Error in Project Users Listener : ', error);
		}
	};

	/**
	 * Function to insert project users to ymap
	 * @param users project users
	 */
	const insertUsersToProjectUsersYMap = (users: IUser[]) => {
		try {
			const user = getUserFromRedux();
			const doc = getYDoc();
			const projectUsersMap = doc?.getMap('projectUsers') as YMap<string>;

			if (projectUsersMap) {
				users.forEach((u) => {
					if (!projectUsersMap.has(u._id as string))
						projectUsersMap.set(
							u._id as string,
							JSON.stringify({
								...u,
								invitedBy: user._id
							})
						);
				});
			}
		} catch (error) {
			console.error('Failed to access ydoc : ', error);
		}
	};

	/**
	 * Function to remove a user from project users ymap
	 * @param userId id of the user to remove
	 */
	const removeUserFromProjectUsersYMap = (userId: string) => {
		try {
			const user = getUserFromRedux();
			const doc = getYDoc();
			const projectUsersMap = doc?.getMap('projectUsers') as YMap<string>;

			if (projectUsersMap) {
				if (projectUsersMap.has(userId)) {
					const projectUser = JSON.parse(
						projectUsersMap.get(userId) as string
					) as TUserInMap;

					projectUsersMap.set(
						userId,
						JSON.stringify({ ...projectUser, removedBy: user._id as string })
					);
					projectUsersMap.delete(userId);
				}
			}
		} catch (error) {
			console.error('Failed to access ydoc : ', error);
		}
	};

	return {
		projectUsersObserver,
		insertUsersToProjectUsersYMap,
		removeUserFromProjectUsersYMap
	};
};

export default useProjectUsersObserver;
