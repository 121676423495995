import { ENodeType } from '@naya_studio/types';
import { Graphics } from 'pixi.js';
import App from 'src/components/canvas/app/App';
import BaseNode from 'src/components/canvas/app/nodes/BaseNode';


/**
 * Function to check if BaseNode is an Image
 * @param node BaseNode to check
 */
const isImage = (node: BaseNode) => {
  return node!.nodeData.nodeType === ENodeType.IMAGE
    || node!.nodeData.nodeType === ENodeType.SAMPLE_IMAGE
    || node!.nodeData.nodeType === ENodeType.ONBOARDING_IMAGE;
}

/**
 * Function to check if BaseNode Image is an Cropped Image
 * @param node BaseNode to check
 */
const isImageCropped = (node: BaseNode) => {
  return node.nodeData.image!.mask!.width! < 1 || node.nodeData.image!.mask!.height! < 1;
}

class GroupBounds {
  public minX: number | undefined = undefined;

  public minY: number | undefined = undefined;

  public maxX: number | undefined = undefined;

  public maxY: number | undefined = undefined;

  public widths: number[] = [];

  public heights: number[] = [];

  public centers: [number, number][] = [];

  public nodes: BaseNode[] = [];

  public getCenter: () => { x: number, y: number } = () => {
    if (this.minX && this.maxX && this.minY && this.maxY) {
      return {
        x: (this.minX + this.maxX) / 2,
        y: (this.minY + this.maxY) / 2,
      };
    }

    return {
      x: 0,
      y: 0,
    };
  };

  calculate() {
    this.widths = [];
    this.heights = [];
    this.centers = [];
    this.nodes.forEach((node) => {
      if (node && node.displayObject) {
        if(isImage(node) && isImageCropped(node)) {
          const croppedAreaX = node.displayObject.children[1]!.x * node.displayObject.scale.x!;
          const croppedAreaY = node.displayObject.children[1]!.y * node.displayObject.scale.y!;

          let minimumX = node.displayObject.x + croppedAreaX;
          if (!this.minX || minimumX < this.minX) {
            this.minX = minimumX;
          }

          let minimumY = node.displayObject.y + croppedAreaY;
          if (!this.minY || minimumY < this.minY) {
            this.minY = minimumY;
          }

          let maximumX = node.displayObject.x + node.displayObject.width + croppedAreaX;
          if (!this.maxX || maximumX > this.maxX) {
            this.maxX = maximumX;
          }

          let maximumY = node.displayObject.y + node.displayObject.height + croppedAreaY;
          if (!this.maxY || maximumY > this.maxY) {
            this.maxY = maximumY;
          }
        }
        else {
          if (!this.minX || node.displayObject.x < this.minX) {
            this.minX = node.displayObject.x;
          }
          if (!this.minY || node.displayObject?.y < this.minY) {
            this.minY = node.displayObject.y;
          }
          if (!this.maxX || (node.displayObject?.x || 0) + node.displayObject.width > this.maxX) {
            this.maxX = node.displayObject.x + node.displayObject.width;
          }
          if (!this.maxY || (node.displayObject?.y || 0) + node.displayObject.height > this.maxY) {
            this.maxY = node.displayObject.y + node.displayObject.height;
          }
        }


        this.widths.push(node.displayObject.getBounds().width);
        this.heights.push(node.displayObject.getBounds().height);
        this.centers.push(
          [
            node.displayObject.x
            + node.displayObject.width / 2, node.displayObject.y + node.displayObject.height / 2,
          ],
        );
      }
    });
  }

  public add(node: BaseNode) {
    if (node.displayObject) {
      this.nodes.push(node);
      this.calculate();
    }
  }

  public distanceFromCenter(node: BaseNode) {
    const center = this.getCenter();
    if (node.displayObject) {
      return Math.sqrt(
        (center.x - node.displayObject.x - node.displayObject.width / 2) ** 2
        + (center.y - node.displayObject.y - node.displayObject.height / 2) ** 2,
      );
    }
    return Number.MAX_VALUE;
  }

  public distanceFromCorner(node: BaseNode) {
    if (node.displayObject) {
      return Math.sqrt(
        ((this.minX || 0) - node.displayObject.x - node.displayObject.width / 2) ** 2
        + ((this.minY || 0) - node.displayObject.y - node.displayObject.height / 2) ** 2,
      );
    }
    return Number.MAX_VALUE;
  }

  public getTopLeftNode() {
    let topLeftNode = null;
    const center = this.getCenter();
    for (let i = 0; i < this.nodes.length; i++) {
      const node = this.nodes[i];
      if (node) {
        if (node.displayObject) {
          if (topLeftNode && topLeftNode.displayObject) {
            const deltaX = Math.abs(node.displayObject.x - (this.minX || 0));
            const topLeftDeltaX = Math.abs(topLeftNode.displayObject.x - (this.minX || 0));
            if (topLeftDeltaX > deltaX + node.displayObject.width) {
              topLeftNode = node;
            } else if (this.distanceFromCorner(node) < this.distanceFromCorner(topLeftNode)) {
              if (deltaX < topLeftDeltaX + topLeftNode.displayObject.width) {
                topLeftNode = node;
              }
            }
          } else {
            topLeftNode = node;
          }
        }
      }
    }
    if (topLeftNode) return topLeftNode;

    for (let i = 0; i < this.nodes.length; i++) {
      const node = this.nodes[i];
      if (node) {
        if (node.displayObject) {
          if (topLeftNode && topLeftNode.displayObject) {
            if (
              node.displayObject.x < center.x
              && node.displayObject.y < topLeftNode.displayObject.y
            ) {
              topLeftNode = node;
            }
          } else if (node.displayObject.x < center.x) {
            topLeftNode = node;
          }
        }
      }
    }

    return topLeftNode;
  }
}

const getGroupBounds = (nodes: BaseNode[]) => {
  const groupBounds = new GroupBounds();
  nodes.forEach((node) => {
    groupBounds.add(node);
  });
  return groupBounds;
};

/**
 * Function to align the node based on provided data
 * @param align Align to apply
 * @param groupBounds Group Bounds
 * @param node Node to align
 */
const updateNodesRelativePosition = (align: string, groupBounds: GroupBounds, node: BaseNode) => {
  switch (align) {
    case 'LEFT': {
      if (node.displayObject && groupBounds.minX) {
        if(isImage(node)) {
          if(isImageCropped(node)) {
            const croppedArea = node.displayObject.children[1]!.x * node.displayObject.scale.x!;

            if (groupBounds.minX < (node.displayObject.x + croppedArea)) {
              node.displayObject.x = node.displayObject.x - croppedArea - (node.displayObject.x - groupBounds.minX);
            }
          }
          else{
            node.displayObject.x = groupBounds.minX;
          }
        }
        else{
          node.displayObject.x = groupBounds.minX;
        }
      }
      
      break;
    }

    case 'RIGHT': {
      if (node.displayObject && groupBounds.maxX) {
        if (isImage(node)) {
          if (isImageCropped(node)) {
            const croppedArea = node.displayObject.children[1]!.x * node.displayObject.scale.x;
            const nodeArea = node.displayObject.x + node.displayObject.width;

            if (groupBounds.maxX > (nodeArea - croppedArea)) {
              node.displayObject.x = groupBounds.maxX - node.displayObject.width - croppedArea;
            }
          }
          else {
            node.displayObject.x = groupBounds.maxX - node.displayObject.width;
          }
        }
        else {
          node.displayObject.x = groupBounds.maxX - node.displayObject.width;
        }
      }

      break;
    }

    case 'TOP': {
      if (node.displayObject && groupBounds.minY) {
        if (isImage(node)) {
          if (isImageCropped(node)) {
            const croppedArea = node.displayObject.children[1]!.y * node.displayObject.scale.y!;
            if (groupBounds.minY < (node.displayObject.y + croppedArea)) {
              node.displayObject.y = node.displayObject.y - croppedArea - (node.displayObject.y - groupBounds.minY);
            }
          }
          else {
            node.displayObject.y = groupBounds.minY;
          }
        }
        else {
          node.displayObject.y = groupBounds.minY;
        }
      }

      break;
    }

    case 'BOTTOM': {
      if (node.displayObject && groupBounds.maxY) {
        if (isImage(node)) {
          if (isImageCropped(node)) {
            const croppedArea = node.displayObject.children[1]!.y * node.displayObject.scale.y;
            const nodeArea = node.displayObject.y + node.displayObject.height;

            if (groupBounds.maxY > (nodeArea - croppedArea)) {
              node.displayObject.y = groupBounds.maxY - node.displayObject.height - croppedArea;
            }
          }
          else {
            node.displayObject.y = groupBounds.maxY - node.displayObject.height;
          }
        }
        else {
          node.displayObject.y = groupBounds.maxY - node.displayObject.height;
        }
      }

      break;
    }

    case 'CENTER': {
      if (node.displayObject
        && groupBounds.minX
        && groupBounds.minY
        && groupBounds.maxX
        && groupBounds.maxY) {
        const totalWidth = groupBounds.maxX - groupBounds.minX;

        if (isImage(node) && isImageCropped(node)) {
          const croppedArea = node.displayObject.children[1]!.x * node.displayObject.scale.x;
          node.displayObject.x = groupBounds.minX + totalWidth / 2 - node.displayObject.width / 2 - croppedArea;
        }
        else{
          node.displayObject.x = groupBounds.minX + totalWidth / 2 - node.displayObject.width / 2 ;
        }
      }

      break;
    }

    case 'MIDDLE': {
      if (node.displayObject
        && groupBounds.minX
        && groupBounds.minY
        && groupBounds.maxX
        && groupBounds.maxY) {
        const totalWidth = groupBounds.maxY - groupBounds.minY;

        if(isImage(node) && isImageCropped(node)) {
          const croppedArea = node.displayObject.children[1]!.y * node.displayObject.scale.y;
          node.displayObject.y = groupBounds.minY + totalWidth / 2 - node.displayObject.height / 2 - croppedArea;
        }
        else{
          node.displayObject.y = groupBounds.minY + totalWidth / 2 - node.displayObject.height / 2;
        }
      }

      break;
    }

    default:
      break;
  }
}

export const leftAlign = (selectedNodes: BaseNode[], app?: App) => {
  const groupBounds = getGroupBounds(selectedNodes);
  if (selectedNodes.length > 1) {
    selectedNodes.forEach((node: BaseNode) => {
      updateNodesRelativePosition('LEFT', groupBounds, node);
    });
  } else if (app) {
    const node = selectedNodes[0];
    const { frame } = app;

    if (node?.displayObject) {
      // If node is an cropped Image consider the cropped part of the image and not the whole image
      if(isImage(node) && isImageCropped(node)) {
        const croppedArea = node.displayObject.children[1]!.x * node.displayObject.scale.x!;
        node.displayObject.x = (frame?.displayObject.x || 0) - croppedArea;
      }
      else {
        node.displayObject.x = frame?.displayObject.x || 0;
      }
    }
  }
};

export const rightAlign = (selectedNodes: BaseNode[], app?: App) => {
  const groupBounds = getGroupBounds(selectedNodes);
  if (selectedNodes.length > 1) {
    selectedNodes.forEach((node: BaseNode) => {
      updateNodesRelativePosition('RIGHT', groupBounds, node);
    });
  } else if (app) {
    const node = selectedNodes[0];
    const { frame } = app;
    if (node?.displayObject) {
      if(isImage(node) && isImageCropped(node)) {
        const croppedArea = node.displayObject.children[1]!.x * node.displayObject.scale.x!;
        const nodeArea = (node.displayObject.children[1]! as Graphics).width * node.displayObject.scale.x;

        node.displayObject.x = (frame?.displayObject.x || 0) + (frame?.displayObject.width || 0) 
        - nodeArea - croppedArea;
      }
      else {
        node.displayObject.x = (frame?.displayObject.x || 0) + (frame?.displayObject.width || 0) 
        - node.displayObject.width;
      }
    }
  }
};

export const topAlign = (selectedNodes: BaseNode[], app?: App) => {
  const groupBounds = getGroupBounds(selectedNodes);

  if (selectedNodes.length > 1) {
    selectedNodes.forEach((node: BaseNode) => {
      updateNodesRelativePosition('TOP', groupBounds, node);
    });
  } else if (app) {
    const node = selectedNodes[0];
    const { frame } = app;
    if (node?.displayObject) {
      if (isImage(node) && isImageCropped(node)) {
        const croppedArea = node.displayObject.children[1]!.y * node.displayObject.scale.y;
        node.displayObject.y = (frame?.displayObject.y || 0) - croppedArea;
      }
      else {
        node.displayObject.y = frame?.displayObject.y || 0;
      }
    }
  }
};

export const bottomAlign = (selectedNodes: BaseNode[], app?: App) => {
  const groupBounds = getGroupBounds(selectedNodes);

  if (selectedNodes.length > 1) {
    selectedNodes.forEach((node: BaseNode) => {
      updateNodesRelativePosition('BOTTOM', groupBounds, node);
    });
  } else if (app) {
    const node = selectedNodes[0];
    const { frame } = app;
    if (node?.displayObject) {
      if(isImage(node) && isImageCropped(node)) {
        const croppedArea = node.displayObject.children[1]!.y * node.displayObject.scale.y;
        const nodeArea = (node.displayObject.children[1]! as Graphics).height * node.displayObject.scale.y;

        node.displayObject.y = (frame?.displayObject.y || 0) + (frame?.displayObject.height || 0) 
        - nodeArea - croppedArea;
      }
      else {
        node.displayObject.y = (frame?.displayObject.y || 0) + (frame?.displayObject.height || 0) 
        - node.displayObject.height;
      }
    }
  }
};

export const centerAlign = (selectedNodes: BaseNode[], app?: App) => {
  const groupBounds = getGroupBounds(selectedNodes);

  if (selectedNodes.length > 1) {
    selectedNodes.forEach((node: BaseNode) => {
      updateNodesRelativePosition('CENTER', groupBounds, node);
    });
  } else if (app) {
    const node = selectedNodes[0];
    const { frame } = app;
    if (node?.displayObject) {
      if(isImage(node) && isImageCropped(node)) {
        const croppedArea = node.displayObject.children[1]!.x * node.displayObject.scale.x;
        node.displayObject.x = (frame?.displayObject.x || 0) + (frame?.displayObject.width || 0) / 2 
        - (node.displayObject.width / 2) - croppedArea;
      }
      else {
        node.displayObject.x = (frame?.displayObject.x || 0) + (frame?.displayObject.width || 0) / 2 
        - node.displayObject.width / 2;
      }
    }
  }
};

export const middleAlign = (selectedNodes: BaseNode[], app?: App) => {
  const groupBounds = getGroupBounds(selectedNodes);

  if (selectedNodes.length > 1) {
    selectedNodes.forEach((node: BaseNode) => {
      updateNodesRelativePosition('MIDDLE', groupBounds, node);
    });
  } else if (app) {
    const node = selectedNodes[0];
    const { frame } = app;
    if (node?.displayObject) {
      if (isImage(node) && isImageCropped(node)){
        const croppedArea = node.displayObject.children[1]!.y * node.displayObject.scale.y;
        node.displayObject.y = (frame?.displayObject.y || 0) + (frame?.displayObject.height || 0) / 2 
        - node.displayObject.height / 2 - croppedArea;
      }
      else {
      node.displayObject.y = (frame?.displayObject.y || 0) + (frame?.displayObject.height || 0) / 2 
      - node.displayObject.height / 2;
      }
    }
  }
};

export const distributeHorizontally = (selectedNodes: BaseNode[]) => {
  const groupBounds = getGroupBounds(selectedNodes);
  const sortedNodes = selectedNodes.sort((a, b) => {
    if (a.displayObject && b.displayObject) return a.displayObject.x - b.displayObject.x;
    return 0;
  });
  const { maxX, minX } = groupBounds;
  if (maxX && minX) {
    const totalWidth = maxX - minX;
    const totalSpacing = totalWidth - groupBounds.widths.reduce((a, b) => a + b, 0);
    const spacing = totalSpacing / (sortedNodes.length - 1);

    let x = groupBounds.minX!;

    sortedNodes.forEach((node) => {
      if (node.displayObject) {
        if (isImage(node) && isImageCropped(node)) {
          const croppedArea = node.displayObject.children[1]!.x * node.displayObject.scale.x;
          node.displayObject.x = x - croppedArea;
        }
        else {
          node.displayObject.x = x;
        }
        x += node.displayObject.width + spacing;
      }
    });
  }
};

export const distributeVertically = (selectedNodes: BaseNode[]) => {
  const groupBounds = getGroupBounds(selectedNodes);
  const sortedNodes = selectedNodes.sort((a, b) => {
    if (a.displayObject && b.displayObject) return a.displayObject.y - b.displayObject.y;
    return 0;
  });
  const { maxY, minY } = groupBounds;
  if (maxY && minY) {
    const totalHeight = maxY - minY;
    const totalSpacing = totalHeight - groupBounds.heights.reduce((a, b) => a + b, 0);
    const spacing = totalSpacing / (sortedNodes.length - 1);
    let y = groupBounds.minY!;
    sortedNodes.forEach((node) => {
      if (node.displayObject) {
        if (isImage(node) && isImageCropped(node)) {
          const croppedArea = node.displayObject.children[1]!.y * node.displayObject.scale.y;
          node.displayObject.y = y - croppedArea;
        }
        else {
          node.displayObject.y = y;
        }
        y += node.displayObject.height + spacing;
      }
    });
  }
};

export const smartAlign = (selectedNodes: BaseNode[]) => {
  const groupBounds = getGroupBounds(selectedNodes);

  const totalWidth = groupBounds.maxX! - groupBounds.minX!;
  const totalHeight = groupBounds.maxY! - groupBounds.minY!;
  // const sumWidths = groupBounds.widths.reduce((a, b) => a + b, 0) + selectedNodes.length * 8;
  const minRows = Math.floor(Math.sqrt(selectedNodes.length));
  let minCols = Math.ceil(selectedNodes.length / minRows);
  while (minRows * minCols < selectedNodes.length) {
    minCols++;
  }

  const rowHeights = [];
  const colWidths = [];
  for (let i = 0; i < selectedNodes.length; i++) {
    const node = selectedNodes[i];
    if (node?.displayObject) {
      const col = i % minCols;
      const row = Math.floor(i / minCols);
      let x;

      if(isImage(node) && isImageCropped(node)) {
        const croppedArea = node.displayObject.children[1]!.x * node.displayObject.scale.x;
        x = groupBounds.minX! + col * (totalWidth / minCols) - croppedArea;
      }
      else {
        x = groupBounds.minX! + col * (totalWidth / minCols);
      }

      if (row > 0) {
        let y;
        if (isImage(node) && isImageCropped(node)) {
          const croppedArea = node.displayObject.children[1]!.y * node.displayObject.scale.y;
          y = groupBounds.minY! - croppedArea + rowHeights.slice(0, row).reduce((a, b) => a + b, 0) + row * 8;
        }
        else {
          y = groupBounds.minY! + rowHeights.slice(0, row).reduce((a, b) => a + b, 0) + row * 8;
        }
        
        node.displayObject.y = y;
      } else {
        let y;
        if (isImage(node) && isImageCropped(node)) {
          const croppedArea = node.displayObject.children[1]!.y * node.displayObject.scale.y;
          y = groupBounds.minY! - croppedArea + row * (totalHeight / minRows);
        }
        else {
          y = groupBounds.minY! + row * (totalHeight / minRows);
        }

        node.displayObject.y = y;
      }
      node.displayObject.x = x;
      if (!rowHeights[row]) {
        rowHeights[row] = 0;
      }
      rowHeights[row] = Math.max(rowHeights[row]!, node.displayObject.height);
      if (!colWidths[col]) {
        colWidths[col] = 0;
      }
      colWidths[col] = Math.max(colWidths[col]!, node.displayObject.width);
    }
  }

  for (let i = 0; i < selectedNodes.length; i++) {
    const node = selectedNodes[i];
    if (node?.displayObject) {
      const col = i % minCols;
      if (col > 0) {
        const x = groupBounds.minX! + colWidths.slice(0, col).reduce((a, b) => a + b, 0) + col * 8;
        if(isImage(node) && isImageCropped(node)) {
          const croppedArea = node.displayObject.children[1]!.x * node.displayObject.scale.x;
          node.displayObject.x = x - croppedArea;
        }
        else{
          node.displayObject.x = x;
        }
      }
    }
  }
};
