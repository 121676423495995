import { FC, ReactElement } from 'react';
import { ReactComponent as CopyIcon } from 'src/assets/icons/sharing-modal/copyLink.svg';
import { ReactComponent as Whatsapp } from 'src/assets/icons/social/whatsapp.svg';
import { ReactComponent as Slack } from 'src/assets/icons/social/slack.svg';
import { ReactComponent as Teams } from 'src/assets/icons/social/teams.svg';
import { ReactComponent as Linkedin } from 'src/assets/icons/social/linkedin.svg';
import { ReactComponent as Pinterest } from 'src/assets/icons/social/pinterest.svg';
import { IconWithTooltip } from '@naya_studio/radix-ui';
import './ShareOptions.scss';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';
import { ISnackBar } from 'src/redux/reducers/root.types';
import { ESharingOptions, SharingOptionsProps } from './ShareOptions.types';

/**
 * All the Share Options Supported by Naya
 */
const sharingOptions = [
	ESharingOptions.COPY_LINK,
	ESharingOptions.WHATSAPP,
	ESharingOptions.TEAMS,
	ESharingOptions.LINKEDIN,
	ESharingOptions.PINTEREST,
	ESharingOptions.SLACK
];

/**
 * Component for Sharing Options
 */
const SharingOptions: FC<SharingOptionsProps> = ({
	activeTab,
	projectId,
	stageId,
	blockId
}): ReactElement => {
	const dummyThumbnail =
		'https://firebasestorage.googleapis.com/v0/b/naya-brand-content/o/' +
		'unnamed.png?alt=media&token=b5506114-4277-47f2-96ff-26cc943a6db9';

	/**
	 * Function to generate link to share to social media
	 */
	const getLinkToShare = () => {
		let link = `${window.location.origin}/project/${projectId}`;
		switch (activeTab) {
			case 'PHASE':
				link += `/${stageId}`;
				break;
			case 'BLOCK':
				link += `/${stageId}/${blockId}`;
				break;
			default:
				break;
		}
		return link;
	};

	/**
	 * Generate Params and Queries for Url
	 */
	const objectToGetParams = (object: { [key: string]: string | number | undefined | null }) => {
		const params = Object.entries(object)
			.filter(([, value]) => value !== undefined && value !== null)
			.map(
				([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
			);

		return params.length > 0 ? `?${params.join('&')}` : '';
	};

	/**
	 * Function to generate deepLink for WhatsApp
	 */
	const whatsappLink = (url: string) =>
		`https://api.whatsapp.com/send${objectToGetParams({
			text: `Naya Studio - The OS for product design:: ${url}`
		})}`;

	/**
	 * Function to generate deepLink for LinkedIn
	 */
	const linkedinLink = (url: string) =>
		`https://www.linkedin.com/share/${objectToGetParams({
			url,
			text: 'Naya Studio - The OS for product design'
		})}`;

	/**
	 * Function to generate deepLink for Teams
	 */
	const teamsLink = (url: string) =>
		`https://teams.microsoft.com/share${objectToGetParams({
			text: url
		})}`;

	/**
	 * Function to generate deepLink for Pinterest
	 */
	const pinterestLink = (url: string) =>
		`https://pinterest.com/pin/create/button/${objectToGetParams({
			url,
			media: dummyThumbnail
		})}`;

	/**
	 * Function to redirect user to selected social media platform
	 */
	const shareToSocialNetwork = (socialNetwork: keyof typeof ESharingOptions) => {
		const urlToShare = getLinkToShare();
		let windowUrl = '';
		switch (socialNetwork) {
			case 'WHATSAPP': {
				windowUrl = whatsappLink(urlToShare);
				break;
			}
			case 'LINKEDIN': {
				windowUrl = linkedinLink(urlToShare);
				break;
			}
			case 'TEAMS': {
				windowUrl = teamsLink(urlToShare);
				break;
			}
			case 'PINTEREST': {
				windowUrl = pinterestLink(urlToShare);
				break;
			}
			default: {
				windowUrl = whatsappLink(urlToShare);
				break;
			}
		}

		window.open(windowUrl);
	};

	/**
	 * Function to display snackbar
	 */
	const displaySnackbar = (msg: string, type: 'LOADER' | 'NORMAL') => {
		const payload: ISnackBar = {
			text: msg,
			show: true,
			type
		};

		addSnackbar(payload);
	};

	/**
	 * Function to render all social media options
	 */
	const renderSharingOptions = sharingOptions.map((option: keyof typeof ESharingOptions) => {
		switch (option) {
			case 'COPY_LINK': {
				return (
					<IconWithTooltip
						data-testid={`share-modal-${option.toLowerCase()}-btn`}
						tooltipText={option.split('_').join(' ')}
						delayDuration={0}
						side="bottom"
						classNames="first-letter-capital"
					>
						<CopyIcon
							width={24}
							height={24}
							className="copy-icon"
							onClick={() => {
								navigator.clipboard.writeText(getLinkToShare());
								displaySnackbar('Link copied to clipboard.', 'NORMAL');
								removeSnackbar(4000);
							}}
						/>
					</IconWithTooltip>
				);
			}
			case 'WHATSAPP': {
				return (
					<IconWithTooltip
						data-testid={`share-modal-${option.toLowerCase()}-btn`}
						tooltipText={option}
						delayDuration={0}
						side="bottom"
						classNames="first-letter-capital"
					>
						<Whatsapp
							width={24}
							height={24}
							onClick={() => shareToSocialNetwork(option)}
						/>
					</IconWithTooltip>
				);
			}
			case 'TEAMS': {
				return (
					<IconWithTooltip
						data-testid={`share-modal-${option.toLowerCase()}-btn`}
						tooltipText={option}
						delayDuration={0}
						side="bottom"
						classNames="first-letter-capital"
					>
						<Teams
							width={24}
							height={24}
							onClick={() => shareToSocialNetwork(option)}
						/>
					</IconWithTooltip>
				);
			}
			case 'PINTEREST': {
				return (
					<IconWithTooltip
						data-testid={`share-modal-${option.toLowerCase()}-btn`}
						tooltipText={option}
						delayDuration={0}
						side="bottom"
						classNames="first-letter-capital"
					>
						<Pinterest
							width={24}
							height={24}
							onClick={() => shareToSocialNetwork(option)}
						/>
					</IconWithTooltip>
				);
			}
			case 'LINKEDIN': {
				return (
					<IconWithTooltip
						data-testid={`share-modal-${option.toLowerCase()}-btn`}
						tooltipText={option}
						delayDuration={0}
						side="bottom"
						classNames="first-letter-capital"
					>
						<Linkedin
							width={24}
							height={24}
							onClick={() => shareToSocialNetwork(option)}
						/>
					</IconWithTooltip>
				);
			}
			case 'SLACK': {
				return (
					<IconWithTooltip
						data-testid={`share-modal-${option.toLowerCase()}-btn`}
						tooltipText="Not Available"
						delayDuration={0}
						side="bottom"
						classNames="first-letter-capital"
					>
						<Slack width={24} height={24} />
					</IconWithTooltip>
				);
			}
			default: {
				return <> {}</>;
			}
		}
	});

	return (
		<div className="sharing-options-container tw-flex tw-flex-col tw-gap-y-6 tw-px-6">
			<div className="sharing-options tw-flex tw-items-center tw-gap-12">
				{renderSharingOptions}
			</div>
		</div>
	);
};

export default SharingOptions;
