import React, { useContext, useRef, useState } from 'react';
import './userForm.scss';
import { ReactComponent as InputIcon } from 'src/assets/images/signupQuestion/input-up-arrow.svg';
import { ReactComponent as TickIcon } from 'src/assets/images/signupQuestion/tick.svg';
import { useDispatch } from 'react-redux';
import useUser from 'src/redux/hooks/user';
import { editUser } from 'src/redux/actions/user';
import { useHistory, useParams } from 'react-router';
import useNayaExpress from 'src/redux/hooks/useNayaExpress';
import { AuthComponentContext } from '../../AuthComponent';
import { Views } from '../../AuthComponent.types';

type ChipProps = {
	text: string;
	onClick: () => void;
	activeClass?: string;
	children?: React.ReactNode;
};

/**
 * Component to render Chip used in User form
 */
const Chip = ({ text, onClick, activeClass, children }: ChipProps) => (
	<div
		role="presentation"
		onClick={onClick}
		className={`chip tw-flex tw-items-center tw-justify-center tw-cursor-pointer 
		tw-select-none tw-text-sm tw-rounded-3xl ${activeClass}`}
	>
		{children}
		{text}
	</div>
);

Chip.defaultProps = {
	activeClass: '',
	children: null
};

type InputProps = {
	type: 'PROFESSION' | 'INTEREST' | 'SOURCE';
	onAddField: (
		type: 'PROFESSION' | 'INTEREST' | 'SOURCE',
		input: HTMLInputElement | null
	) => void;
};

/**
 * Component to handle input text on enter and on icon click
 */
const Input = ({ type, onAddField }: InputProps) => {
	// State to track focus state of input
	const [inputFocused, setInputFocused] = useState<boolean>(false);
	// Input ref to store value
	const inputRef = useRef<HTMLInputElement>(null);

	return (
		<div
			className={`chip-input-wrapper tw-flex tw-items-center ${
				inputFocused ? 'focused' : ''
			}`}
		>
			<input
				ref={inputRef}
				placeholder="Add other"
				className="chip-input tw-w-full"
				onFocus={() => setInputFocused(true)}
				onBlur={() => setInputFocused(false)}
				onKeyDown={(e) => {
					if (e.key === 'Enter') {
						onAddField(type, inputRef.current);
					}
				}}
			/>
			<InputIcon
				className="tw-cursor-pointer"
				onClick={() => onAddField(type, inputRef.current)}
			/>
		</div>
	);
};

const initialProfession = ['Professional', 'Educational', 'Freelance', 'Personal'];
const initialInterest = [
	'Industrial Design',
	'Graphic Design',
	'Film/Video',
	'Game Design',
	'Interior Design',
	'User Interface Design',
	'Hardware'
];
const initialSource = [
	'A colleague/friend',
	'LinkedIn',
	'Instagram',
	'Event',
	'Newsletter',
	'Podcast',
	'Search engine'
];
const teamSize = ['Individual', '2-5', '6-10', '11-25', '26-50', '50+'];

/**
 * Component to render User From
 */
const UserForm: React.FC = () => {
	const dispatch = useDispatch();
	const { user } = useUser();
	const authComponentContext = useContext(AuthComponentContext);
	const { setAuthView, redirectTo } = authComponentContext;
	const params: any = useParams();
	const history = useHistory();

	const { handle3DRedirect, checkIfNayaExpressRedirect, handleI2RRedirect } = useNayaExpress();

	// State to manage the all the professions
	const [profession, setProfession] = useState<string[]>(initialProfession);
	// State to manage the all the interest
	const [interest, setInterest] = useState<string[]>(initialInterest);
	// State to manage the all the interest
	const [source, setSource] = useState<string[]>(initialSource);
	// State to keep track of all the active/selected professions
	const [activeProfesion, setActiveProfesion] = useState<string[]>([]);
	// State to keep track of all the active/selected interest
	const [activeInterest, setActiveInterest] = useState<string[]>([]);
	// State to keep track of all the active/selected source
	const [activeSource, setActiveSource] = useState<string[]>([]);
	// State to keep track of active/selected teamSize
	const [activeTeam, setActiveTeam] = useState('');

	// function to handle active/selected 'PROFESSION' or 'INTEREST' or 'SOURCE'
	const onSelect = (name: string, type: 'PROFESSION' | 'INTEREST' | 'SOURCE') => {
		if (type === 'PROFESSION') {
			if (activeProfesion.includes(name)) {
				setActiveProfesion([...activeProfesion.filter((e) => e !== name)]);
			} else {
				setActiveProfesion([...activeProfesion, name]);
			}
		} else if (type === 'INTEREST') {
			if (activeInterest.includes(name)) {
				setActiveInterest([...activeInterest.filter((e) => e !== name)]);
			} else {
				setActiveInterest([...activeInterest, name]);
			}
		} else if (type === 'SOURCE') {
			if (activeSource.includes(name)) {
				setActiveSource([...activeSource.filter((e) => e !== name)]);
			} else {
				setActiveSource([...activeSource, name]);
			}
		}
	};

	// function to handle additional 'PROFESSION' or 'INTEREST' or 'SOURCE'
	const onAddField = (
		type: 'PROFESSION' | 'INTEREST' | 'SOURCE',
		inputRef: HTMLInputElement | null
	) => {
		if (inputRef && inputRef.value) {
			switch (type) {
				case 'PROFESSION':
					setProfession([...profession, inputRef.value]);
					break;
				case 'INTEREST':
					setInterest([...interest, inputRef.value]);
					break;
				case 'SOURCE':
					setSource([...source, inputRef.value]);
					break;
				default:
					break;
			}
			inputRef.value = '';
		}
	};

	// function that handles saving inform on click of SKIP or CONTINUE
	const onSkipOrContinue = async (type: 'SKIP' | 'CONTINUE') => {
		const userId = window.localStorage.getItem('registeredUnappovedUserId');
		const view = window.localStorage.getItem('redirect-from-userform');
		if (userId) {
			const userPayload =
				type === 'CONTINUE'
					? {
							roleAndInterest: activeInterest.join() || 'DEFAULT',
							profession: activeProfesion.join() || 'DEFAULT',
							organizationSize: activeTeam || 'DEFAULT',
							source: activeSource.join() || 'DEFAULT'
					  }
					: {
							roleAndInterest: 'DEFAULT',
							organizationSize: 'DEFAULT',
							profession: 'DEFAULT',
							source: 'DEFAULT'
					  };
			await dispatch(
				editUser({
					data: {
						userId: userId as string,
						update: { ...userPayload }
					},
					prevState: user
				})
			);
			if (view) {
				setAuthView(view as Views);
			} else {
				// Check if there's a 3D redirect stored in local storage
				if (checkIfNayaExpressRedirect('3D_redirect')) {
					// Call function to handle the 3D redirect
					handle3DRedirect();
					return;
				}
				// Check if there's a 3D redirect stored in local storage
				if (checkIfNayaExpressRedirect('i2r')) {
					// Call function to handle the 3D redirect
					handleI2RRedirect();
					return;
				}

				let redirectURL = decodeURIComponent(params.redirectTo)
					? decodeURIComponent(params.redirectTo)
					: redirectTo;
				if (!redirectURL || redirectURL === 'undefined') {
					redirectURL = '/studio';
				}
				history.push(redirectURL);
			}
		} else {
			// Check if there's a 3D redirect stored in local storage
			if (checkIfNayaExpressRedirect('3D_redirect')) {
				// Call function to handle the 3D redirect
				handle3DRedirect();
				return;
			}
			// Check if there's a 3D redirect stored in local storage
			if (checkIfNayaExpressRedirect('i2r')) {
				// Call function to handle the 3D redirect
				handleI2RRedirect();
				return;
			}
			let redirectURL = decodeURIComponent(params.redirectTo)
				? decodeURIComponent(params.redirectTo)
				: redirectTo;
			if (!redirectURL || redirectURL === 'undefined') {
				redirectURL = '/studio';
			}
			history.push(redirectURL);
		}
		window.localStorage.removeItem('showUserForm');
		window.localStorage.removeItem('redirect-from-userform');
		window.localStorage.removeItem('registeredUnappovedUserId');
	};

	return (
		<div className="tw-flex tw-flex-col">
			<div className="tw-font-bold tw-text-lg tw-mx-4">
				Also, just a few more questions to get to know you better.
			</div>
			<div className="user-question-wrapper tw-w-full tw-p-6 tw-rounded-3xl tw-flex tw-flex-col">
				<div className="tw-flex tw-gap-6 tw-flex-col">
					<div className="tw-flex tw-gap-4 tw-flex-col">
						<div className="tw-font-bold tw-text-base">What is your desired use?</div>
						<div className="tw-flex tw-gap-4 tw-flex-wrap">
							{profession.map((each, index) => (
								<Chip
									key={`${each}-${index.toString()}`}
									text={each}
									onClick={() => onSelect(each, 'PROFESSION')}
									activeClass={
										activeProfesion.includes(each) ? 'active-profession' : ''
									}
								>
									{activeProfesion.includes(each) && (
										<TickIcon className="tw-mr-2" />
									)}
								</Chip>
							))}
						</div>
						<Input type="PROFESSION" onAddField={onAddField} />
					</div>
					<div className="tw-flex tw-gap-4 tw-flex-col">
						<div className="tw-font-bold tw-text-base">
							What are type of projects you do ?
						</div>
						<div className="tw-flex tw-gap-4 tw-flex-wrap">
							{interest.map((each, index) => (
								<Chip
									key={`${each}-${index.toString()}`}
									text={each}
									onClick={() => onSelect(each, 'INTEREST')}
									activeClass={
										activeInterest.includes(each) ? 'active-category' : ''
									}
								>
									{activeInterest.includes(each) && (
										<TickIcon className="tw-mr-2" />
									)}
								</Chip>
							))}
						</div>
						<Input type="INTEREST" onAddField={onAddField} />
					</div>
					<div className="tw-flex tw-gap-4 tw-flex-col">
						<div className="tw-font-bold tw-text-base">How did you hear about us?</div>
						<div className="tw-flex tw-gap-4 tw-flex-wrap">
							{source.map((each, index) => (
								<Chip
									key={`${each}-${index.toString()}`}
									text={each}
									onClick={() => onSelect(each, 'SOURCE')}
									activeClass={
										activeSource.includes(each) ? 'active-category' : ''
									}
								>
									{activeSource.includes(each) && (
										<TickIcon className="tw-mr-2" />
									)}
								</Chip>
							))}
						</div>
						<Input type="SOURCE" onAddField={onAddField} />
					</div>
					<div className="tw-flex tw-gap-4 tw-flex-col">
						<div className="tw-font-bold tw-text-base">What is your team size?</div>
						<div className="tw-flex tw-gap-4 tw-flex-wrap">
							{teamSize.map((each, index) => (
								<Chip
									key={`${each}-${index.toString()}`}
									text={each}
									onClick={() => setActiveTeam(each)}
									activeClass={activeTeam === each ? 'active-team' : ''}
								>
									<div className="checkbox tw-flex tw-justify-center tw-items-center">
										<TickIcon />
									</div>
								</Chip>
							))}
						</div>
					</div>
					<div className="tw-flex tw-justify-end tw-gap-4">
						<button
							type="button"
							onClick={() => onSkipOrContinue('SKIP')}
							className="skip-btn tw-flex tw-items-center tw-justify-center tw-font-bold tw-text-sm"
						>
							Skip
						</button>
						<button
							type="button"
							onClick={() => onSkipOrContinue('CONTINUE')}
							className="continue-btn tw-flex tw-items-center tw-justify-center tw-font-bold tw-text-sm"
						>
							Continue
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UserForm;
