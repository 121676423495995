/** Currently this is used to describe a user's type */
export declare enum EUserRole {
	COMMENTER = 'COMMENTER',
	EDITOR = 'EDITOR',
	VIEWER = 'VIEWER',
	OWNER = 'OWNER',
	ADMIN = 'ADMIN'
}

export interface CallbackFunction {
	(tool: string): void;
}

export enum ToolType {
	ESTIMATES = 'ESTIMATES',
	MATCHMAKING = 'MATCHMAKING',
	SUSTAINABILITY = 'SUSTAINABILITY',
	THREEDPRINTING = '3DPRINTING'
}
