import {
	checkOrCreateGoogleFolder,
	generateGoogleFileIDs,
	uploadFileLinkToDrive,
	uploadFileToDrive
} from 'src/redux/reduxActions/integrations/googleIntegrations';
import { TDriveData, TDriveGroupData, TGDriveFile } from 'src/redux/reducers/root.types';
import { store } from 'src';
import { findStageWithStageId } from 'src/redux/actions/util';
import { IBlock, TBlockEdit } from '@naya_studio/types';
import { generateIdsFromUrl } from 'src/redux/reduxActions/util';
import { editBlock } from 'src/redux/reduxActions/block';
import { TEditBlockArgs } from 'src/types/argTypes';
import { getProjectSyncObserver, linkProjectToGoogleDrive } from '../helper/project';

/**
 * Asynchronously sets the Google resource ID for multiple blocks.
 *
 * @param {{ [key: string]: string }} map - A mapping of block IDs to their corresponding Google resource IDs.
 * @returns {Promise<void>} A promise that resolves when all blocks have been updated.
 */
const setGoogleResourceId = async (
	blockId: string,
	googleResourceId: string,
	googleResourcePath: string
) => {
	// Extracting necessary data
	const state = store.getState();
	const allBlocks = state.blocks.data;

	const resourceIdPayload = {
		data: {
			block: {
				_id: blockId,
				googleResourceId,
				googleResourcePath
			} as TBlockEdit,
			sendBlockTypeAsEmpty: true,
			...generateIdsFromUrl()
		},
		prevState: {
			prevStateBlock: allBlocks[blockId] as IBlock
		}
	};
	await store.dispatch(editBlock(resourceIdPayload));
};

/**
 * Asynchronously removes the Google resource ID from a specified block.
 *
 * @param {string} blockId - The ID of the block to update.
 * @returns {Promise<void>} A promise that resolves when the block has been updated.
 */
const removeGoogleResourceId = async (blockId: string) => {
	// Extracting necessary data
	const state = store.getState();
	const allBlocks = state.blocks.data;

	// Updating block with Google resource ID
	const resourceIdPayload: TEditBlockArgs = {
		data: {
			block: {
				_id: blockId,
				googleResourceId: '',
				googleResourcePath: ''
			} as TBlockEdit,
			sendBlockTypeAsEmpty: true,
			...generateIdsFromUrl()
		},
		prevState: {
			prevStateBlock: allBlocks[blockId] as IBlock
		}
	};
	store.dispatch(editBlock(resourceIdPayload));
};

const uploadToGDrive = async (projectId: string, uploadToDrive: TDriveData) => {
	const { data, uploadCount } = uploadToDrive;
	if (uploadCount === 0) return;
	const uploadPromises: Promise<{ blockId: string; path: string; response: string | null }>[] =
		[];

	if (uploadCount === 0) return;
	const googleObserver = getProjectSyncObserver(projectId, 'GOOGLE_DRIVE');

	if (!googleObserver?.channelDetails?.channelId) return;
	// Extracting necessary data
	const state = store.getState();
	const { access_token } = state.integrations.google;

	if (!access_token) {
		console.error('Google Access Token not available');
		return;
	}
	let googleFolderId = googleObserver?.folderId || '';

	if (!googleFolderId) {
		try {
			// check or create Naya Studio Sync folder
			// check or create project
			googleFolderId = await linkProjectToGoogleDrive(access_token);
		} catch (error) {
			console.error('Error creating or checking main Google folder:', error);
			return;
		}
	}
	// Generate Google file IDs for all the blocks
	const fileIds = await generateGoogleFileIDs(access_token, uploadCount);
	let fileCount = 0;

	async function uploadGroup(item: TDriveGroupData, path: string, parentFolder: string) {
		let groupFolderId = googleFolderId;
		const group = findStageWithStageId(item.id);
		if (group?.isPhaseCreated) {
			try {
				groupFolderId = await checkOrCreateGoogleFolder(
					access_token,
					group?.name || 'UNTITILED',
					parentFolder
				);
			} catch (error) {
				console.error('Error creating or checking Google folder:', error);
				return;
			}
		}
		if (groupFolderId) {
			for (let i = 0; i < item.children.length; i++) {
				const child = item.children[i];
				if ((child as TGDriveFile).file) {
					const fileItem = child as TGDriveFile;
					const resourceId = fileIds[fileCount];
					uploadPromises.push(
						new Promise((resolve) => {
							const response =
								typeof fileItem.file === 'string'
									? uploadFileLinkToDrive(
											fileItem.file,
											fileItem.fileName || 'Untitled',
											groupFolderId,
											projectId,
											access_token,
											resourceId
									  )
									: uploadFileToDrive(
											access_token,
											fileItem.file,
											resourceId,
											groupFolderId
									  );
							response
								.then((res) =>
									resolve({ blockId: fileItem.id, path, response: res })
								)
								.catch(() =>
									resolve({ blockId: fileItem.id, path, response: null })
								);
						})
					);

					fileCount++;
				}
				if ((child as TDriveGroupData).children?.length > 0) {
					// eslint-disable-next-line no-await-in-loop
					await uploadGroup(
						child as TDriveGroupData,
						`${path}/${groupFolderId}`,
						groupFolderId
					);
				}
			}
		}
	}
	for (let i = 0; i < data.length; i++) {
		const item = data[i];
		// eslint-disable-next-line no-await-in-loop
		if (item) await uploadGroup(item, `/${googleFolderId}`, googleFolderId);
	}
	const results = await Promise.all(uploadPromises);
	const updateBlockResourcePromises: Promise<void>[] = [];
	results.forEach((item) => {
		if (item.response)
			updateBlockResourcePromises.push(
				setGoogleResourceId(item.blockId, item.response, item.path)
			);
		else updateBlockResourcePromises.push(removeGoogleResourceId(item.blockId));
	});
	await Promise.all(updateBlockResourcePromises);
};
export default uploadToGDrive;
