import { DropDown, IconWithTooltip } from '@naya_studio/radix-ui';
import classNames from 'classnames';
import { useContext, useState } from 'react';
import { CollaborationToolContext } from '../../CollaborationTool';
import { IDeliverableTypes } from '../../CollaborationTool.types';

/**
 * Function to render Journey to Deliverables
 * @returns export button with dropdown
 */
const Exports = () => {
	const collabContext = CollaborationToolContext;
	const { deliverableType, setDeliverableType } = useContext(collabContext);

	// State to check if journey dropdown is active
	const [isDropdownActive, setIsDropdownActive] = useState(false);

	/**
	 * Function to set active deliverable type
	 * @param newType
	 */
	const setActiveDeliverableType = (newType: IDeliverableTypes) => {
		if (setDeliverableType) setDeliverableType(newType);
	};

	return (
		<DropDown modal={false} onOpenChange={(value: boolean) => setIsDropdownActive(value)}>
			<DropDown.DropDownTrigger>
				<IconWithTooltip tooltipText="Export assets" side="bottom">
					<div
						className={`exports-menu tw-flex tw-justify-center 
					tw-items-center tw-h-10 tw-text-[#4F00C1] tw-px-3
					tw-rounded-2xl tw-cursor-pointer tw-text-sm tw-font-randMedium
					${classNames({
						active: isDropdownActive
					})}    
				`}
						data-testid="btn-export-menu"
					>
						<img
							src={
								'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/export-journey.svg' +
								'?alt=media&token=8724e8b4-03d6-4adb-a5fa-5a98b88db8bc'
							}
							alt="export"
						/>
					</div>
				</IconWithTooltip>
			</DropDown.DropDownTrigger>

			<DropDown.DropDownContent classNames="tw-p-2 dropdown-content tw-rounded-2xl tw-bg-white tw-right-0">
				<DropDown.DropDownItem>
					<div
						role="presentation"
						data-testid="btn-download-journey-poster"
						className={`kebab-items tw-text-xs tw-font-randRegular 
						tw-leading-6 tw-pt-1 tw-pb-1 tw-px-2 tw-cursor-pointer ${classNames({
							disabled: deliverableType
						})}`}
						onClick={() =>
							!deliverableType && setActiveDeliverableType('DOWNLOAD_POSTER')
						}
					>
						Export poster
					</div>
				</DropDown.DropDownItem>
				<DropDown.DropDownItem>
					<div
						role="presentation"
						data-testid="btn-download-files"
						className={`kebab-items tw-text-xs tw-font-randRegular 
						tw-leading-6 tw-pt-1 tw-pb-1 tw-px-2 tw-cursor-pointer ${classNames({
							disabled: deliverableType
						})}`}
						onClick={() =>
							!deliverableType && setActiveDeliverableType('DOWNLOAD_FILES')
						}
					>
						Export links and files
					</div>
				</DropDown.DropDownItem>
			</DropDown.DropDownContent>
		</DropDown>
	);
};

export default Exports;
