import mongoose from 'mongoose';
import {
	generateOptimisticStage,
	generateOptimisticBlock,
	generateIdsFromUrl
} from 'src/redux/reduxActions/util';
import { IGroup, IBlock } from '@naya_studio/types';
import { TreeEntry } from '@naya_studio/radix-ui';
import { TGroupParent } from 'src/components/collaborationTool/CollaborationTool.types';
import { findStageWithStageId } from 'src/redux/actions/util';
import { TDriveData } from 'src/redux/reducers/root.types';
import { getProjectFromRedux } from '../helper/project';

export interface IFileOrLinkBlock extends IBlock {
	payload?: File | string;
	type?: 'FILE' | 'LINK';
}

export interface FormattedData {
	groups: IGroup[];
	blocks: IFileOrLinkBlock[];
	gdrive: TDriveData;
}

/**
 * Function to generate group
 * @param name group name
 * @param parent object containing parent id and type
 * @returns
 */
const generateGroup = (name: string, parent: TGroupParent) => {
	const groupId = new mongoose.Types.ObjectId().toString();
	return generateOptimisticStage(groupId, name, parent);
};

/**
 * Formats the tree data into groups and blocks.
 * @param tree The entire tree structure or root children array.
 * @returns An object containing formatted groups and blocks.
 */
export function formatFolderTreeData(
	tree: TreeEntry | TreeEntry[],
	parent: TGroupParent
): FormattedData {
	const groups: IGroup[] = [];
	const blocks: IFileOrLinkBlock[] = [];
	const gdrive: { data: any[]; uploadCount: number } = { data: [], uploadCount: 0 };

	let hasDroppedOnlyFiles = false;

	const { projectId } = generateIdsFromUrl();
	let projectStageCounter = (getProjectFromRedux(projectId).children || []).length + 1;
	function traverse(
		node: TreeEntry,
		parentId: string,
		dropIndex: number = 0
	): { id: string | null; uploadToDrive?: any } {
		if (node.type === 'directory') {
			if (node.children.length === 0) {
				return { id: null };
			}

			const group = generateGroup(node.name, parent);
			const uploadToDrive = [];
			const groupId = group._id.toString();
			group.parentId = parentId;
			// When user drops only files set the isPhaseCreated based on children count.
			group.isPhaseCreated = hasDroppedOnlyFiles ? node.children.length > 1 : true;

			for (let i = 0; i < node.children.length; i++) {
				const child = node.children[i];
				if (child) {
					const childData = traverse(child, groupId, dropIndex + 1);
					if (childData.id) {
						group.children.push(childData.id);
						if (childData.uploadToDrive) uploadToDrive.push(childData.uploadToDrive);
					}
				}
			}

			groups.push(group);
			if (group.parentId === projectId && uploadToDrive.length > 0)
				gdrive.data.push({ name: group.name, id: group._id, children: uploadToDrive });
			return {
				id: groupId,
				uploadToDrive:
					uploadToDrive.length > 0
						? { name: group.name, id: groupId, children: uploadToDrive }
						: null
			};
		}
		if (node.type === 'FILE' || node.type === 'LINK') {
			const blockId = new mongoose.Types.ObjectId().toString();
			const block: IFileOrLinkBlock = {
				...generateOptimisticBlock(blockId, parent.id, projectId, node.name, 'EMPTY'),
				parentId,
				payload: node.payload,
				type: node.type
			};

			if (node.type === 'LINK') {
				block.blockType = 'LINK';
				block.name = 'UNDEFINED';
			}
			if (node.type === 'FILE') gdrive.uploadCount++;

			if (dropIndex === 0 && parentId === projectId) {
				const tempStage = generateGroup(`Untitled ${projectStageCounter++}`, {
					id: projectId,
					type: 'JOURNEY'
				});
				tempStage.children.push(blockId);
				block.parentId = tempStage._id as string;
				groups.push(tempStage);
			}

			blocks.push(block);
			return {
				id: blockId,
				uploadToDrive:
					node.type === 'FILE'
						? { id: blockId, file: node.payload, fileName: node.name }
						: null
			};
		}

		return { id: parent.id };
	}

	if (Array.isArray(tree)) {
		// When files/link are dropped on journey create anonymous directory and add dropped files/link to children.
		if (parent.type === 'JOURNEY' && tree.every((data) => data.type !== 'directory')) {
			tree = {
				name: `Untitled ${(getProjectFromRedux(projectId).children || []).length + 1}`,
				type: 'directory',
				children: tree
			};
			hasDroppedOnlyFiles = true;
			traverse(tree, parent.id);
		} else {
			let group: any = null;
			const uploadToDrive = [];
			if (parent.type === 'PHASE') {
				const groupInfo = findStageWithStageId(parent.id) as IGroup;
				group = { name: groupInfo.name, id: groupInfo._id, children: [] };
			}
			for (let i = 0; i < tree.length; i++) {
				const item = tree[i];
				if (item) {
					const t = traverse(item, parent.id);

					if (t.uploadToDrive) uploadToDrive.push(t.uploadToDrive);
				}
			}
			if (group && uploadToDrive.length > 0)
				gdrive.data.push({ ...group, children: uploadToDrive });
		}
	} else {
		traverse(tree, parent.id);
	}
	return { groups, blocks, gdrive };
}
