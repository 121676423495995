import { useContext, useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { IProject, IGroup, IBlock, ILink, ILinkSubtype } from '@naya_studio/types';
import { ViewerPagination } from '@naya_studio/radix-ui';
import { ReduxState, IRestrictedAccess } from 'src/redux/reducers/root.types';
import filterPhaseAndBlocks from 'src/util/collaboration/searchAndSortAlgo';
import useUser from 'src/redux/hooks/user';
import { PathParamsType, TSearchQuery } from '../collaborationTool/CollaborationTool.types';
import { getFileLinkSubType } from '../utilities/navbar/utils';
import { CollaborationToolContext } from '../collaborationTool/CollaborationTool';

type ViewerPaginationWrapperProps = {
	project: (IProject & IRestrictedAccess) | undefined;
	params: PathParamsType;
	query: TSearchQuery;
};

/**
 * A wrapper function to render ViewerPagination by
 * retrieving props from the parent component, ExpandedBlockView
 * and create remaining props.
 *
 * @param project Naya project data
 * @param params React router URL params
 * @param query search query
 * @returns rendered React Node
 */
export default function ViewerPaginationWrapper({
	project,
	params,
	query
}: ViewerPaginationWrapperProps) {
	const history = useHistory();
	const location = useLocation();
	const allBlocks = useSelector((state) => (state as ReduxState).blocks.data, shallowEqual);
	const allStages = useSelector((state) => (state as ReduxState).stages.data, shallowEqual);
	const CollabContext = useContext(CollaborationToolContext);
	const { setRedirectModalDetails, isGuestUser } = CollabContext;
	const { user } = useUser();
	const projectZoomValue = !user._id || isGuestUser ? location.state?.projectZoomValue : null;

	/**
	 * Boolean that checks if there is a query
	 */
	const isQuery = Object.keys(query).length > 0;

	/**
	 * Creates a list of blockIds when there is a query, to be used for filtering blocks in
	 * blocksNameAndLink to display only the filtered blocks. It uses sessionStorage to handle
	 * the case where the divs from Journey view has been removed by render update.
	 */
	const queryBlockIds = useMemo(() => {
		if (isQuery) {
			const filteredIdsInSession = window.sessionStorage.getItem('filtered-blocks');
			if (filteredIdsInSession && JSON.parse(filteredIdsInSession).length)
				return JSON.parse(filteredIdsInSession);

			const filteredResult = filterPhaseAndBlocks(query, Object.values(allStages), allBlocks);
			if (filteredResult) {
				const { blocks } = filteredResult;
				return Object.keys(blocks);
			}
		}
		return [];
	}, [isQuery, allBlocks, allStages]);

	/**
	 * Function to get blocks metadata from the given phase and flatten the blocks of nested phases into their parent phase.
	 *
	 * @param {IGroup} group - The group from which blocks metadata needs to be extracted.
	 * @param {Array<{ blockId: string; stageId: string }>} orderBlockIds - The order of block IDs with their corresponding stage IDs.
	 * @param {Array<{ _id: string; name: string; parentId: string } | undefined>} [blocksData] - (Optional) The data used to flatten
	 * 	the nested phase's blocks.
	 *
	 */
	const getBlocks = (
		group: IGroup | undefined,
		orderBlockIds: {
			blockId: string;
			stageId: string;
		}[],
		blocksData?: {
			_id: string;
			name: string;
			parentId: string;
		}[]
	) => {
		if (!group) return [];
		if (!blocksData) blocksData = [];
		const isTimelineView = query.timelineView && Object.keys(query).length === 1;
		group.children?.forEach((childId, index) => {
			if (
				allBlocks[childId as string] &&
				!allBlocks[childId as string]?.isHidden &&
				!group?.isHidden &&
				(isQuery && !isTimelineView ? queryBlockIds.includes(childId as string) : true)
			) {
				orderBlockIds.push({
					blockId: childId as string,
					stageId: group._id as string
				});
				blocksData?.push({
					_id: childId as string,
					name:
						allBlocks[childId as string]!.name === 'UNDEFINED'
							? `Untitled ${index + 1}`
							: allBlocks[childId as string]!.name,
					parentId: group._id.toString()
				});
			} else if (allStages[childId as string]) {
				// If it's a nested phase, append its blocks to parent blocksData.
				getBlocks(allStages[childId as string]!, orderBlockIds, blocksData);
			}
		});
		return blocksData;
	};

	/**
	 * Create a list of group objects {id}, each with a list of block objects {id, name}
	 * Used to display the grouped blocks in expanded viewer pagination
	 */
	const blocksNameAndLink = useMemo(() => {
		const orderBlockIds: { blockId: string; stageId: string }[] = [];
		if (project?.children && Object.keys(allStages).length && Object.keys(allBlocks).length) {
			const getBlocksNameAndLink = project.children.map((groupId) => ({
				_id: groupId as string,
				items: getBlocks(allStages[groupId as string], orderBlockIds)
			}));
			const removeEmptyGroups = getBlocksNameAndLink.filter(
				(group) => group.items.length > 0
			);
			window.sessionStorage.setItem('ordered-blocks', JSON.stringify(orderBlockIds));
			return removeEmptyGroups;
		}

		return [];
	}, [project?.children, allStages, allBlocks, isQuery, queryBlockIds]);

	/**
	 * Memoized blocks dictionary with block ids as keys and parent ids as values
	 */
	const blocksDictionary = useMemo(() => {
		const blocksNameAndLinkReduced = blocksNameAndLink.reduce((accG, group) => {
			const bDict = group.items.reduce((accB, block) => {
				if (block) {
					accB[block._id] = block.parentId;
				}
				return accB;
			}, {} as Record<string, string>);
			return { ...accG, ...bDict };
		}, {} as Record<string, string>);
		return blocksNameAndLinkReduced;
	}, [blocksNameAndLink]);

	/**
	 * Fn to check if next of prev page exists wrt to current page
	 * @param type Page navigation direction
	 * @returns boolean of whether the page exists
	 */
	const checkIfPageExists = (type: 'PREV' | 'NEXT', activeBlockId: string = params.canvasId) => {
		const keys = Object.keys(blocksDictionary);
		if (activeBlockId) {
			const index = keys.indexOf(activeBlockId);
			if (type === 'NEXT') {
				if (index !== -1 && index < keys.length - 1) {
					return true;
				}
			} else if (index !== -1 && index > 0) {
				return true;
			}
		}
		return false;
	};

	/**
	 * Function to create the URL for page based on button action
	 * @param type Page navigation direction
	 * @returns URL of page to be navigated to
	 */
	const getPageURL = (type: 'PREV' | 'NEXT', activeBlockId: string = params.canvasId) => {
		const keys = Object.keys(blocksDictionary);
		let nextPageId = '';
		if (activeBlockId) {
			const index = keys.indexOf(activeBlockId);
			if (index !== -1) {
				if (type === 'NEXT') {
					nextPageId = keys[index + 1] as string;
				} else {
					nextPageId = keys[index - 1] as string;
				}
			}
		}

		return `/project/${params.projectId}/${blocksDictionary[nextPageId]}/${nextPageId}`;
	};

	/**
	 * Function to check if block should be redirected
	 * @param block Block
	 */
	const shouldBlockBeRedirected = (block?: IBlock) =>
		block &&
		block.blockType === 'LINK' &&
		['NAYA', 'DOCSEND'].includes(
			getFileLinkSubType((block as ILink).link, 'LINK') as keyof typeof ILinkSubtype
		);

	/**
	 * Function to handle navigation button click in viewer pagination
	 * @param type Differentiates 'PREV' and 'NEXT' button clicks from 'ITEM' button clicks
	 * @param itemId item that was clicked on, that the page needs to navigate to
	 * @returns For 'PREV' and 'NEXT', the id of the respective item. For 'ITEM', return empty
	 */
	const handleClickPaginationButton = (type: 'PREV' | 'NEXT' | 'ITEM', itemId?: string) => {
		if (type === 'PREV' || type === 'NEXT') {
			const goToPageURL = getPageURL(type);
			const goToBlockId = goToPageURL.split('/').pop() as string;
			const block = allBlocks[goToBlockId] as IBlock;
			const shouldOpenInNewTab = shouldBlockBeRedirected(block);

			// If next or prev block is Naya or Docsend block
			if (shouldOpenInNewTab) {
				// Check if any further block i.e. next or prev to naya block exists or not
				let doesPageExists = checkIfPageExists(type, goToBlockId);
				let nextPageUrl = `/project/${params.projectId}`;
				let nextBlockId = goToBlockId;

				// If a block exists past it, get url of that block
				if (doesPageExists) {
					nextPageUrl = getPageURL(type, goToBlockId);
					nextBlockId = nextPageUrl.split('/').pop() as string;

					while (
						shouldBlockBeRedirected(allBlocks[nextBlockId] as IBlock) &&
						doesPageExists
					) {
						doesPageExists = checkIfPageExists(type, nextBlockId);
						if (doesPageExists) {
							nextPageUrl = getPageURL(type, nextBlockId);
							nextBlockId = nextPageUrl.split('/').pop() as string;
						}
					}
				}

				// Set the data respectively
				setRedirectModalDetails({
					link: (block as ILink).link,
					canSkip: doesPageExists,
					onCancel: () => {
						// On skipping if block existed past the naya block, navigate to it
						if (doesPageExists) history.push(nextPageUrl);
						setRedirectModalDetails(null);
					}
				});

				return '';
			}
			// persisting project zoom value in params for guest user
			history.push({
				pathname: goToPageURL,
				state: {
					...(projectZoomValue && (!user._id || isGuestUser) ? { projectZoomValue } : {})
				}
			});
			return goToBlockId;
		}

		const block = allBlocks[itemId as string] as IBlock;
		const shouldOpenInNewTab = shouldBlockBeRedirected(block);

		// On clicking on a block in pagination check if it's a Naya or Docsend block
		if (shouldOpenInNewTab) {
			// Set the data respectively
			setRedirectModalDetails({
				link: (block as ILink).link,
				canSkip: false,
				onCancel: () => setRedirectModalDetails(null)
			});

			return '';
		}

		const groupId = blocksDictionary[itemId as string];
		// persisting project zoom value in params for guest user
		history.push({
			pathname: `/project/${params.projectId}/${groupId}/${itemId}`,
			state: {
				...(projectZoomValue && (!user._id || isGuestUser) ? { projectZoomValue } : {})
			}
		});

		return '';
	};

	return (
		<div className="tw-absolute tw-top-16 tw-z-[1] tw-flex tw-justify-center  tw-transform tw--translate-x-1/2 tw-left-1/2">
			{blocksNameAndLink.length > 0 && (
				<ViewerPagination
					handleClickPaginationButton={handleClickPaginationButton}
					itemsIdList={Object.keys(blocksDictionary)}
					checkIfPageExists={checkIfPageExists}
					itemsNameAndLink={blocksNameAndLink}
					currentItemId={params.canvasId}
				/>
			)}
		</div>
	);
}
