import { FC, ReactElement } from "react"
// import userIcon from 'src/assets/icons/user.svg'
import exampleMedium from 'src/assets/images/landingPage/whyNaya/example_medium.png'
// import redTable from 'src/assets/images/landingPage/whyNaya/red-table.png'
import "./ArticleCard.scss"

export type ArticleCardProps = {
  url: string,
  imageUrl: string,
  profilePic: string,
  userName: string,
  userPositionTitle: string,
  description: string,
  name: string,
  subject: string
}

const ArticleCard: FC<ArticleCardProps> = ({
  url,
  // description,
  imageUrl,
  name,
  subject,
  // profilePic,
  // userName,
  // userPositionTitle
}): ReactElement => <button className='article-card' onClick={() => window.open(url, 'noreferrer noopener')}>
  <span className='subject'>{subject}</span>
  <div className='image'>
    <img  alt={name + ' cover'} src={imageUrl || exampleMedium} />
    <div className='purple-tint' />
  </div>
  <h5>{name}</h5>
  {/* <p>{description}</p> */}
  {/* <div className='user-container'>
    <img src={profilePic || userIcon} alt='profile pic'/>
    <div className='name-title-container'>
      <span className='user-name'>{userName}</span>
      <span className='user-title'>{userPositionTitle}</span>
    </div>
  </div> */}
</button>

export default ArticleCard