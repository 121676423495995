import { useContext, useEffect, useState } from 'react';
import './SigninLinkSent.scss';
import { AuthComponentContext } from '../../AuthComponent';
import SocialLinks from '../socialLinks/SocialLinks';

const SigninLinkSent = () => {
	// State to manage the email address
	const [email, setEmail] = useState('');

	// Get the authentication context
	const authContext = useContext(AuthComponentContext);
	const { setAuthView, authView } = authContext;

	// Load the email from local storage when the component mounts
	useEffect(() => {
		const signinEmail = window.localStorage.getItem('signinEmail');
		if (signinEmail) {
			setEmail(signinEmail);
			window.localStorage.removeItem('signinEmail');
		}
	}, []);

	// Determine the text to display based on the authentication view
	const getTextBasedOnView = () => {
		switch (authView) {
			case 'SIGNIN_LINK_SENT':
				return (
					<p>
						A sign in link has been sent to <span className="email">{email}</span>.
						Please check your email for the link!
					</p>
				);
			case 'REGISTRATION_LINK_SENT':
				return (
					<p>
						We will reach out to you at <span className="email">{email}</span> very soon
						to set up a demo and get your set up on Naya.
					</p>
				);
			case 'USER_ALREADY_EXISTS':
				return (
					<p>
						Hey there! It looks like <span className="email">{email}</span> is already
						registered on the Naya platform. To sign in to your account, please check
						your email for a one-time sign in link.
					</p>
				);
			default:
				return '';
		}
	};

	return (
		<div className="tw-text-center tw-pt-2" id="link-sent-container">
			{/* Display the text based on the authentication view */}
			{getTextBasedOnView()}

			{/* Button to navigate back to the home page */}
			<button
				className="primary-btn tw-w-full tw-text-sm tw-mb-2 tw-rounded-3xl tw-max-w-[300px]"
				type="submit"
				onClick={() => {
					window.location.href = '/';
				}}
			>
				Back to homepage
			</button>

			{/* Other options section */}
			<div className="other-options tw-mt-2 tw-mb-4">
				<span>
					{/* Display different text based on the authentication view */}
					{['SIGNIN_LINK_SENT', 'USER_ALREADY_EXISTS'].includes(authView)
						? 'Didn’t get a link? '
						: 'Wrong email?'}
					<button
						type="button"
						data-type="underline"
						onClick={() => {
							// Toggle between 'LOGIN' and 'REGISTER' views
							setAuthView(
								['SIGNIN_LINK_SENT', 'USER_ALREADY_EXISTS'].includes(authView)
									? 'LOGIN'
									: 'REGISTER'
							);
						}}
					>
						{/* Display different text based on the authentication view */}
						{['SIGNIN_LINK_SENT', 'USER_ALREADY_EXISTS'].includes(authView)
							? 'let’s try it again.'
							: 'Re-enter your email address.'}
					</button>
				</span>
			</div>
			<SocialLinks />
		</div>
	);
};

export default SigninLinkSent;
