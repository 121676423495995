// Function to format a time value in seconds as "00:00:00" format
const formatTime = (seconds: number) => {
	// Calculate the hours by dividing the total seconds by 3600 (60 seconds/minute * 60 minutes/hour)
	const hours = Math.floor(seconds / 3600);

	// Calculate the remaining seconds after converting to hours
	const remainingSecondsAfterHours = seconds % 3600;

	// Calculate the minutes by dividing the remaining seconds by 60
	const minutes = Math.floor(remainingSecondsAfterHours / 60);

	// Calculate the remaining seconds (as whole numbers) after converting to minutes
	const remainingSeconds = Math.floor(remainingSecondsAfterHours % 60);

	// Format the time as "HH:MM:SS" using padStart to ensure two digits for hours, minutes, and seconds
	// Formatted time with minutes and seconds -> MM:SS
	let formattedTime = `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(
		2,
		'0'
	)}`;
	// If hours is present the prepend it to formattedTime -> HH:MM:SS
	if (hours) {
		formattedTime = `${String(hours).padStart(2, '0')}:${formattedTime}`;
	}
	return formattedTime;
};

/** *
 * Function to load script for Miro canvas
 */
export const loadMiroCanvas = () => {
	const script = document.createElement('script');
	script.async = true;
	script.src = 'https://miro.com/app/static/boardsPicker.1.0.js';
	document.body.appendChild(script);
};

/** *
 * Function to load script for Storylane
 */
export const loadStorylane = () => {
	const script = document.createElement('script');
	script.async = true;
	script.src = 'https://js.storylane.io/js/v1/storylane.js';
	document.body.appendChild(script);
};

/**
 * Function to check for block url
 * @param url url
 * @returns {boolean}
 */
export const isBlockUrl = (url: string) => {
	if (!url) return false;
	const blockUrlPattern = /^https?:\/\/[^/]+\/project\/[\w]{24}\/[\w]{24}\/[\w]{24}$/;
	return blockUrlPattern.test(window.location.href);
};

/**
 * Function to check for block url
 * @param url url
 * @returns {boolean}
 */
export const isProjectUrl = (url: string) => {
	if (!url) return false;
	const projectUrlPattern = /^https?:\/\/[^/]+\/project\/[\w]{24}$/;
	return projectUrlPattern.test(window.location.href);
};

/**
 * Function to check if the given email is guest email or not
 * @param email string
 * @returns email or false
 */
export const isGuestEmail = (email: string | undefined) => {
	if (email) {
		const regexPattern = /^guest[0-9a-f]{24}@naya\.studio$/;
		return !!regexPattern.test(email);
	}
	return false;
};

/**
 * Function to check if active url on user's screen is for Journey
 * @returns boolean
 */
export const checkIfUserOnJourney = () => {
	const split = window.location.pathname.split('/');
	const onJourney = split.length === 3 || split[split.length - 1] === 'journey';

	return onJourney;
};

/**
 * Function to check if active url on user's screen is for Journey
 * @returns boolean
 */
export const checkIfUserOnHomebase = () => {
	const homebaseWrap = document.querySelector<HTMLDivElement>('.homebase-wrap');
	const split = window.location.pathname.split('/');
	const onHomebase = split.includes('studio');

	return onHomebase && homebaseWrap;
};

/**
 * Variable to check if app is running on localhost
 * @returns boolean
 */
export const isLocalhost = Boolean(
	window.location.hostname === 'localhost' ||
		window.location.hostname === '[::1]' ||
		window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

/**
 * Function to remove the special(/[<>:"\/\\|?*]/) characters and emojis from given string
 * @param fileName {string}
 * @returns string
 */
export const cleanFileName = (fileName: string = 'Untitled', checkLength: boolean = false) => {
	try {
		// Define a regular expression pattern for invalid characters in Windows file names
		const invalidChars = /[<>:"/\\|?*]/g;

		// Replace invalid characters with an underscore
		let cleanedName = fileName.replace(invalidChars, '_');

		// This regular expression matches any Unicode emoji character
		const emojiRegex =
			/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g;

		// Remove emoji
		cleanedName = cleanedName.replace(emojiRegex, '');

		const nameSplit = cleanedName.split('.');
		const ext = nameSplit.pop();
		// If checkLength and name has only extension || contains UNDEFINED, then append date to the name
		if (
			checkLength &&
			nameSplit.length <= 1 &&
			(nameSplit[0] === 'UNDEFINED' || !nameSplit[0])
		) {
			cleanedName = `Untitled.${ext}`;
		}
		// Use replace method to replace emojis with an empty string
		return cleanedName.trim();
	} catch (error) {
		console.error('Failed to clean file name: ', error);
		return fileName;
	}
};

/**
 * This function creates a Promise that resolves after a specified delay,
 * simulating sleep behavior in JavaScript.
 *
 * @param {number} time - The delay in milliseconds.
 * @returns {Promise} A promise that resolves after the specified delay.
 */
export const sleep = (time: number) =>
	new Promise((resolve) => {
		/**
		 * Schedules the resolution of the promise after the specified delay.
		 * This effectively simulates a sleep for the duration of 'time' milliseconds.
		 */
		setTimeout(resolve, time);
	});

export default formatTime;
