import { EditableTitle, DropDown } from '@naya_studio/radix-ui';
import { IBlock, TUserAndRole } from '@naya_studio/types';
import classNames from 'classnames';
import { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { generateIdsFromUrl } from 'src/redux/reduxActions/util';
import { ReactComponent as DropdownIcon } from 'src/assets/icons/navbar/dropdown.svg';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';
import { shallowEqual, useSelector } from 'react-redux';
import { useUpdateMultipleNotifsMutation } from 'src/redux/features/api/notification';
import useUser from 'src/redux/hooks/user';
import { ReduxState } from 'src/redux/reducers/root.types';
import useBlockActions from 'src/util/block/useBlockActions';
import { onDuplicateBlock } from 'src/components/journeyContainer/JourneyContainerCallbacks';
import { ESharingTabs } from 'src/components/sharingModal/SharingModal.types';
import useProject from 'src/redux/hooks/useProject';
import { CollaborationToolContext } from '../../CollaborationTool';
import { PathParamsType } from '../../CollaborationTool.types';
import '../JourneyNavbar.scss';

/**
 * Component Prop types for Journey Name
 */
type JourneyNameProps = {
	blockName: string;
	setBlockName: React.Dispatch<React.SetStateAction<string>>;
	setSharingDetails: React.Dispatch<
		React.SetStateAction<{
			projectId: string;
			phaseId: string | undefined;
			blockId: string | undefined;
		}>
	>;
	setActiveTab: React.Dispatch<React.SetStateAction<keyof typeof ESharingTabs>>;
};

/**
 * Dropdown options for Block Name
 */
enum DropdownOptionKeys {
	SHARE_BLOCK = 'SHARE_BLOCK',
	RENAME_BLOCK = 'RENAME_BLOCK',
	DUPLICATE_BLOCK = 'DUPLICATE_BLOCK',
	RESET_BLOCK = 'RESET_BLOCK',
	DELETE_BLOCK = 'DELETE_BLOCK'
}

/**
 * Component to render Block Name in navbar in the BreadCrumb Format
 */
const JourneyNameBreadCrumb = ({
	blockName,
	setBlockName,
	setSharingDetails,
	setActiveTab
}: JourneyNameProps) => {
	const history = useHistory();
	const params = useParams<PathParamsType>();
	const { user } = useUser();
	const { projectId, stageId, blockId } = generateIdsFromUrl();
	const { project } = useProject(projectId);
	const [updateMultipleNotifs] = useUpdateMultipleNotifsMutation(); // TODO
	const { onBlockEdit, getBlockNameById } = useBlockActions();
	const collabContext = CollaborationToolContext;

	const { deliverableType, setShowShareModal, handleUndoRedo } = useContext(collabContext);

	const allBlocks = useSelector((state) => (state as ReduxState).blocks.data, shallowEqual);
	const allStages = useSelector((state) => (state as ReduxState).stages.data, shallowEqual);

	const currentBlock = allBlocks[blockId];
	// State to check if journey title is in edit state
	const [isEditState, setIsEditState] = useState(false);
	// State to check if journey dropdown is active
	const [isDropdownActive, setIsDropdownActive] = useState(false);

	/**
	 * If blockName is UNDEFINED, then get blockName in the form of Untitled N
	 */
	useEffect(() => {
		if (currentBlock?.name === 'UNDEFINED' && blockId) {
			setBlockName(getBlockNameById(blockId) as string);
		} else if (currentBlock?.name) setBlockName(currentBlock?.name);
	}, [currentBlock?.name]);

	/** Set block name edit state as fase whenever block id changes */
	useEffect(() => {
		setIsEditState(false);
	}, [blockId]);
	/**
	 * Function to check if user is the Project Owner
	 * @returns Boolean
	 */
	const isBlockOwner = () => currentBlock?.createdBy === user._id;

	/**
	 * Function to check if user has editor level access to Project
	 * @returns Boolean indicating user's access to Project
	 */
	const isBlockLevelEditor = () => {
		for (let i = 0; i < ((currentBlock as IBlock)?.users as TUserAndRole[])?.length; i++) {
			if (
				(currentBlock?.users as TUserAndRole[])[i]!.user === user._id &&
				((currentBlock?.users as TUserAndRole[])[i]!.role === 'EDITOR' ||
					(currentBlock?.users as TUserAndRole[])[i]!.role === 'OWNER')
			) {
				return true;
			}
		}
		return false;
	};

	/**
	 * Callback to handle block rename
	 * @param newTitle
	 */
	const onSubmit = (newTitle: string, isUndoRedo: boolean = false) => {
		const blockTitle = newTitle.trim() || currentBlock?.name;

		onBlockEdit({
			editType: 'BLOCK_EDIT',
			blockId: currentBlock?._id as string,
			payload: { name: blockTitle }
		}).then(() => {
			if (blockTitle) setBlockName(blockTitle);

			/** --- Update Block Name in all notifications of that project ---*/
			updateMultipleNotifs({
				searchBy: { 'data.blockId': currentBlock?._id },
				update: { 'data.canvasName': blockTitle },
				operation: 'BLOCK_RENAME'
			});
		});

		if (!isUndoRedo) {
			handleUndoRedo({
				type: 'ADD',
				payload: {
					originalAction: onSubmit,
					oppositeAction: onSubmit,
					originalActionPayload: [blockTitle],
					oppositeActionPayload: [currentBlock?.name]
				}
			});
		}
	};

	/**
	 * Function to handle rename dropdown option click
	 */
	const onRenameBlockStart = () => {
		setIsEditState(true);
	};

	/**
	 * Function to Duplicate/Make a template of a project from Journey Space
	 * @param action - variant of Duplication
	 * @param shouldOpenProject Make a copy
	 */
	const onDuplicateBlockWrapper = async () => {
		const response = await onDuplicateBlock(currentBlock?._id as string, stageId);
		if (response) {
			const duplicatedBlockId = response.newBlockId;
			addSnackbar({
				show: true,
				type: 'NORMAL',
				text: 'Block Duplicated',
				actionButtonData: [
					{
						buttonData: 'Go there',
						onClick: () => {
							removeSnackbar(0);
							setTimeout(() => {
								history.push(
									`/project/${projectId}/${stageId}/${duplicatedBlockId}`
								);
							}, 500);
						},
						className: 'custom-button',
						show: true
					}
				]
			});
			removeSnackbar(5000);
		}
	};

	/** True, if user has edit access */
	const hasEditAccess = useMemo(
		() => user.userType?.includes('ADMIN') || isBlockOwner() || isBlockLevelEditor(),
		[user._id, user.userType]
	);

	/**
	 * Dropdown options
	 */
	const dropdownOptions: {
		key: keyof typeof DropdownOptionKeys;
		value: string;
		onClick: () => void;
		isDisabled?: boolean;
		icon?: ReactNode;
		showDivider?: boolean;
		classNames?: string;
	}[] = [
		{
			key: DropdownOptionKeys.SHARE_BLOCK,
			value: 'Share block',
			onClick: () => {
				setSharingDetails({
					projectId: params.projectId,
					phaseId: params.stageOrDashboard,
					blockId: params.canvasId
				});

				setActiveTab(ESharingTabs.BLOCK);
				setShowShareModal(true);
			},
			showDivider: false,
			classNames: 'tw-flex tw-items-center tw-justify-between tw-w-[124px]'
		},
		{
			key: DropdownOptionKeys.RENAME_BLOCK,
			value: 'Rename block',
			showDivider: false,
			onClick: () => {
				onRenameBlockStart();
			}
		},
		{
			key: DropdownOptionKeys.DUPLICATE_BLOCK,
			value: 'Duplicate block',
			showDivider: false,
			onClick: () => {
				onDuplicateBlockWrapper();
			}
		},
		{
			key: DropdownOptionKeys.RESET_BLOCK,
			value: 'Reset block',
			showDivider: false,
			onClick: () => {
				onBlockEdit({
					editType: 'RESET_BLOCK',
					blockId: currentBlock?._id as string,
					payload: {}
				});
			}
		},
		{
			key: DropdownOptionKeys.DELETE_BLOCK,
			showDivider: false,
			value: 'Delete block',
			onClick: () => {
				onBlockEdit({
					blockId: currentBlock?._id as string,
					// @ts-ignore
					editType: 'DELETE_BLOCK',
					payload: { parentId: currentBlock?.parentId }
				});
			}
		}
	];

	/**
	 * Function to render dropdown items
	 * @returns
	 */
	const renderDropdownItems = () => {
		/**
		 * Function to check if user is allowed to rename journey
		 */
		const checkIfUserCanRename = () =>
			user.userType?.includes('ADMIN') || isBlockOwner() || isBlockLevelEditor();

		/**
		 * Function to check if user is allowed to delete journey
		 */
		const checkIfUserCanDelete = () => user.userType?.includes('ADMIN') || isBlockOwner();

		return (
			<>
				{dropdownOptions.map((option) => {
					if (option.key === 'RENAME_BLOCK' && !checkIfUserCanRename()) return null;
					if (option.key === 'DELETE_BLOCK' && !checkIfUserCanDelete()) return null;
					if (option.key === 'RESET_BLOCK' && currentBlock?.blockType === 'EMPTY')
						return null;

					return (
						<DropDown.DropDownItem key={option.key}>
							<div
								role="presentation"
								data-testid={`btn-${option.value.toLowerCase().replace(' ', '_')}`}
								className={`kebab-items tw-text-xs tw-font-randRegular 
                                tw-leading-6 tw-pt-1 tw-pb-1 tw-px-2 tw-cursor-pointer
								${option.classNames ? option.classNames : ''}
                                ${classNames({
									'red-text':
										option.key === 'DELETE_BLOCK' ||
										option.key === 'RESET_BLOCK',
									disabled:
										['DOWNLOAD_FILES', 'DOWNLOAD_JOURNEY_POSTER'].includes(
											option.key
										) && deliverableType,
									'context-menu-item-disabled': option.isDisabled
								})}
                            `}
								onClick={() => option.onClick()}
							>
								{option.value}
								{option.icon && option.icon}
							</div>
							{option.showDivider && <div className="context-menu-separator" />}
						</DropDown.DropDownItem>
					);
				})}
			</>
		);
	};

	/**
	 * Push projectName and groupName into breadCrumbElements
	 */
	const getBreadCrumbElements = useMemo(() => {
		const breadCrumbElements = [];
		breadCrumbElements.push(project?.projectDetails?.name);
		if (project?.children && allStages[stageId]?.isPhaseCreated) {
			breadCrumbElements.push(allStages[stageId]?.name);
		}
		return breadCrumbElements;
	}, [project, stageId, blockId]);

	return (
		<>
			{getBreadCrumbElements.map((elem, index) => (
				<>
					<div
						style={{
							fontSize: '14px',
							maxWidth: '150px',
							overflow: 'hidden',
							whiteSpace: 'nowrap',
							textOverflow: 'ellipsis',
							padding: '8px 8px',
							borderRadius: '8px',
							margin: '0px 4px'
						}}
						className="project-group-breadcrumb tw-cursor-pointer tw-font-randMedium hover:!tw-bg-[#F5F5F5]"
						data-testid={
							index === 0 ? 'expanded-view-project-name' : 'expanded-view-group-name'
						}
						role="presentation"
						onClick={() => {
							history.push(`/project/${projectId}`);
							if (index === 1) {
								// if clicking on groupName, scroll to the required group
								const container = document.querySelector('html');
								const retainedScroll =
									window.sessionStorage.getItem('journey-scroll');
								if (container) {
									// scroll to the phase from which the block was opened.
									if (retainedScroll) {
										setTimeout(() => {
											container.scrollTo({
												left: parseFloat(retainedScroll)
											});
										}, 250);
									}
								}
							}
						}}
					>
						{elem}
					</div>
					<span
						className="tw-font-randMedium tw-cursor-default "
						style={{ color: '#E2E2E2', fontSize: '14px' }}
					>
						/
					</span>
				</>
			))}
			<div className="tw-ml-1">
				<EditableTitle
					title={blockName}
					isEditState={isEditState && hasEditAccess}
					onEnter={onSubmit}
					setIsEditState={(args) => {
						if (hasEditAccess) setIsEditState(args);
					}}
					onRevert={() => setIsEditState(false)}
					variant="PROJECT"
					isSearchActive={false}
					color="none"
				/>
			</div>
			{hasEditAccess && (
				<DropDown
					modal={false}
					onOpenChange={(value: boolean) => setIsDropdownActive(value)}
				>
					<DropDown.DropDownTrigger>
						<div
							className={`journey-title-dropdown-trigger tw-flex tw-justify-center tw-items-center 
                        tw-w-6 tw-h-6 tw-rounded-xl ${classNames({
							'tw-bg-[#F1EBFA] active': isDropdownActive
						})}`}
						>
							<DropdownIcon data-testid="btn-export-menu" />
						</div>
					</DropDown.DropDownTrigger>
					<DropDown.DropDownContent classNames="tw-p-2 dropdown-content tw-rounded-2xl tw-bg-white tw-right-0 tw-top-2">
						{renderDropdownItems()}
					</DropDown.DropDownContent>
				</DropDown>
			)}
		</>
	);
};

export default JourneyNameBreadCrumb;
