import { IAction, TextToImageActionTypes } from '../root.types';

export interface IPredictionData {
  prompt: string,
  status : string,
  uuid : string,
  output?: string[],
  error?: string,
  showSnackBar: boolean
}

const initialPrediction:IPredictionData = {
  prompt: '',
  status: '',
  uuid: '',
  output: [],
  error: '',
  showSnackBar: false,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const textToImageReducer = (state = initialPrediction, action: IAction<TextToImageActionTypes | keyof typeof TextToImageActionTypes>) => {
  let newState: IPredictionData;
  const predictionData: IPredictionData = action.payload;
  switch (action.type) {
    case TextToImageActionTypes.CREATE_PREDICTION:
      newState = { ...predictionData };
      break;
    case TextToImageActionTypes.EDIT_PREDICTION:
      newState = {
        ...state,
        ...predictionData,
      };
      break;
    case TextToImageActionTypes.GET_PREDICTION:
      newState = {
        ...state,
        ...predictionData,
      };
      break;
    default:
      newState = { ...state };
      break;
  }
  return newState;
};

export default textToImageReducer;
