/* eslint-disable react/jsx-props-no-spreading */
import { FC, useEffect, useState } from 'react';
import linkSvg from 'src/assets/icons/edit-menu/link.svg';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ENodeType } from '@naya_studio/types';
import urlRegexSafe from 'url-regex-safe';
import { LinkProps } from './Link.types';
import './Link.scss';

const Link: FC<LinkProps> = ({
  app,
}) => {
  const getLinkURL = () => {
    const selectedNode = app.selectedNodes && app.selectedNodes.length === 1
      ? app.selectedNodes[0] : null;
    if (selectedNode && selectedNode?.nodeData.nodeType === ENodeType.TEXT) {
      return selectedNode.nodeData.text?.style?.linkURL;
    } return '';
  };

  const [showURLContainer, setShowURLContainer] = useState(app.showLinkContainer);
  const [URLInput, setURLInput] = useState(getLinkURL());
  const [copyToTextNode, setCopyToTextNode] = useState(false);
  const [trixSelectedRange, setTrixSelectedRange] = useState();
  // const linkRef = useRef({});

  useEffect(() => {
    const selectedNode = app.selectedNodes && app.selectedNodes.length === 1
      ? app.selectedNodes[0] : null;
    const nodeId = selectedNode?.nodeData._id || 'text-node-input';
    const inputEle = document.getElementById(nodeId);
    if (inputEle && inputEle.innerHTML === '') setCopyToTextNode(true);
  });

  useEffect(() => {
    if (showURLContainer) {
      const inputEle = document.getElementById('edit-url-input') as HTMLInputElement;
      if (inputEle) {
        inputEle.focus();
        inputEle.select();
      }
    }
  }, [showURLContainer]);

  useEffect(() => {
    setTimeout(() => {
      // @ts-ignore
      const trixEditor = app.textInputNode._domInput.editor;
      const { index, url } = app.textLinkTooltip;
      if (trixEditor && (app.textLinkTooltip.index !== undefined)) {
        const linkRanges = trixEditor?.getDocument().findRangesForTextAttribute('href');
        const [start, end] = trixEditor?.getSelectedRange() || [0, 0];
        if (linkRanges.length > 0 && index <= linkRanges.length - 1 && start === end) {
          trixEditor?.setSelectedRange(linkRanges[index]);
          trixEditor?.activateAttribute('frozen', true);
        }
        setURLInput(url);
      }
    }, 200);

    return () => {
      // @ts-ignore
      const trixEditor = app.textInputNode._domInput.editor;
      if (trixEditor) {
        trixEditor.deactivateAttribute('frozen');
      }
    };
  }, []);

  const handleClick = () => {
    app.disablePaste = !showURLContainer;
    setShowURLContainer(!showURLContainer);
    // @ts-ignore
    const trixEditor = app.textInputNode._domInput?.editor;
    // TODO: match it with theme color
    trixEditor?.activateAttribute('frozen', true);
    setTrixSelectedRange(trixEditor?.getSelectedRange());
    // const sel = window.getSelection();
    // if (sel && sel.rangeCount) {
    //   const anchorNode = sel.anchorNode;
    //   let textSelection;
    //   let textFragment;
    //   if (anchorNode && sel.anchorOffset === sel.focusOffset && sel.anchorOffset === 0) {
    //     textSelection = new Range()
    //     textSelection.setStart(anchorNode, sel.anchorOffset);
    //     textSelection
    //           .setEnd(anchorNode, sel.anchorOffset + (anchorNode.textContent ?? "").length);
    //     textFragment = anchorNode.cloneNode(true);
    //   } else {
    //     textSelection = sel.getRangeAt(0);
    //     textFragment = textSelection.cloneContents();
    //   }
    //   // const textSelection = sel.getRangeAt(0);
    //   // const textFragment = textSelection.cloneContents();
    //   linkRef.current = { textSelection, textFragment };
    //   console.log("textSelection", textFragment, textSelection);
    //   const tempSpan = document.createElement('span');
    //   tempSpan.style.backgroundColor = "dodgerblue";
    //   tempSpan.style.color = "white";
    //   textSelection.surroundContents(tempSpan);
    // }
  };

  const updateInputEle = (text: string) => {
    const selectedNode = app.selectedNodes && app.selectedNodes.length === 1
      ? app.selectedNodes[0] : null;
    const nodeId = selectedNode?.nodeData._id || 'text-node-input';
    const inputEle = document.getElementById(nodeId);
    if (inputEle) inputEle.innerHTML = text;
  };

  const handleURLInput = (e: any) => {
    setURLInput(e.target.value);
    if (copyToTextNode) updateInputEle(e.target.value);
  };

  const handleInputClick = () => {
    // @ts-ignore
    // const { textFragment, textSelection } = linkRef.current;
    // if (textFragment) {
    //   // const newNode = textSelection.extractContents();
    //   const tempSpan = document.createElement('span');
    //   tempSpan.style.backgroundColor = "dodgerblue";
    //   tempSpan.style.color = "white";
    //   // newNode.appendChild(tempSpan)
    //   // textSelection.deleteContents();
    //   // textSelection.insertNode(textFragment);
    //   textSelection.surroundContents(tempSpan);
    // }
  };

  const handleKeyDown = (e: any) => {
    e.stopPropagation();
    if (e.keyCode === 13) {
      e.preventDefault();
      setShowURLContainer(false);
      app.showLinkContainer = false;
      app.disablePaste = false;
      // document.execCommand("createLink", false, URLInput);
      // @ts-ignore
      // const { textFragment, textSelection } = linkRef.current;
      // if (textFragment) {
      //   textSelection.deleteContents();
      //   textSelection.insertNode(textFragment);
      //   //restoreSelection();
      // }
      const isValidURL = urlRegexSafe().test(URLInput || '');
      let finalURL = URLInput;
      if (isValidURL) {
        if (!urlRegexSafe({ strict: true }).test(URLInput!)) {
          finalURL = `https://${finalURL}`;
          setURLInput(finalURL);
          if (copyToTextNode) updateInputEle(finalURL);
        }
      }
      // console.log("tag children", textFragment.childNodes)
      // const linkTag = document.createElement('a');
      // linkTag.href = finalURL; // verify for valid URL
      // textSelection.surroundContents(linkTag);
      // window.getSelection()?.addRange(textSelection);
      // const nodeData = app.selectedNodes[0];
      // if (nodeData) nodeData.edit('LINK', finalURL, false);

      const currentNode = app.selectedNodes;
      for (let i = 0; i < currentNode.length; i++) {
        // @ts-ignore
        const trixEditor = app.textInputNode._domInput?.editor;
        if (trixEditor) {
          if (!app.textLinkTooltip.url) {
            trixEditor.setSelectedRange(trixSelectedRange);
          }
          trixEditor.deactivateAttribute('frozen');
        }
        currentNode[i]?.edit('LINK', finalURL, !!(currentNode.length > 1 && i !== 0));
      }

      app.saveAllNodes([]);
    }
  };

  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 1000, hide: 0 }}
      overlay={(
        <Tooltip
          id="button-tooltip-2"
          style={{
            fontSize: '12px',
            borderRadius: '2px',
            lineHeight: '16px',
            marginTop: '8px',
          }}
        >
          Create link
        </Tooltip>
      )}
    >
      {({ ref, ...triggerHandler }) => (
        <div className="linkContainer" style={{ background: showURLContainer ? '#EDEDED' : 'transparent' }}>
          <img role="presentation" src={linkSvg} className="linkImage" ref={ref} alt="" {...triggerHandler} onClick={handleClick} />
          <div className={showURLContainer ? 'urlContainer' : 'hideUrlContainer'}>
            <div className="urlText">URL</div>
            <input
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              id="edit-url-input"
              className="urlInput"
              onClick={handleInputClick}
              value={URLInput}
              onChange={handleURLInput}
              placeholder="Paste link"
              onKeyDown={handleKeyDown}
              onKeyUp={(e: any) => {
                e.stopPropagation();
              }}
            />
          </div>
        </div>
      )}
    </OverlayTrigger>
  );
};
export default Link;
