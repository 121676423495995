import { ReactComponent as AlertIcon } from 'src/assets/icons/sharing-modal/alert.svg';
import personIcon from 'src/assets/icons/sharing-modal/person.svg';
import './OverrideRole.scss';
import { store } from 'src';
import { CustomDispatch } from 'src/redux/actions/types';
import { addUserToProject } from 'src/redux/reduxActions/project';
import { TAddUserToProjectThunkArg, TAddUserToStageThunkArg } from 'src/types/argTypes';
import { addUserToStage } from 'src/redux/reduxActions/stage';
import trackEvent from 'src/util/analytics/analytics';
import { CustomEvents } from 'src/util/analytics/events';
import { TShareItemEventData } from 'src/util/analytics/analytic.types';
import {
	OverrideContentProps,
	OverrideFooterProps,
	OverrideHeaderProps
} from './OverrideRole.types';

/**
 * Component for Override Role Header
 */
export const OverrideHeader = ({ children }: OverrideHeaderProps) => (
	<div className="override-header tw-flex tw-items-center tw-justify-between tw-font-randMedium tw-text-lg tw-font-bold">
		<span>Override Permissions</span>
		{children}
	</div>
);

/**
 * Component for Override Role Body
 */
export const OverrideContent = ({ overrideRolePayload }: OverrideContentProps) => {
	/**
	 * Function to render list of all users who exist on lower levels
	 */
	const renderUsersToOverride = () =>
		overrideRolePayload?.map(
			(actionPayload: TAddUserToStageThunkArg | TAddUserToProjectThunkArg) => (
				<div
					className="user-details tw-flex tw-flex-1 tw-items-center tw-gap-4"
					key={actionPayload.payload.email}
				>
					<img
						referrerPolicy="no-referrer"
						src={
							actionPayload.payload.profilePic
								? actionPayload.payload.profilePic
								: personIcon
						}
						alt="person"
						className="profile-pic tw-rounded-full"
					/>
					<div className="name-email-container tw-ml-1">
						<p className="user-name tw-font-randMedium tw-text-xs tw-mb-0">
							{actionPayload.payload?.email?.substring(
								0,
								actionPayload.payload?.email?.indexOf('@')
							)}
						</p>
						<p className="user-email tw-font-randMedium tw-text-xs tw-mb-0">
							{actionPayload.payload.email}
						</p>
					</div>
				</div>
			)
		);

	return (
		<div className="override-content tw-flex tw-flex-col tw-items-start tw-justify-center tw-px-10">
			<div className="alert-container tw-flex tw-gap-1">
				<AlertIcon />
				<p className="tw-text-xs tw-m-0 tw-ml-4">
					The invited users already have access to portions of a project.
				</p>
			</div>
			<p className="tw-text-xs tw-m-0 tw-ml-10">Do you want to override those permissions?</p>

			<div className="user-details-container tw-flex tw-align-start tw-flex-col tw-gap-4 tw-ml-5 tw-mt-8">
				{renderUsersToOverride()}
			</div>
		</div>
	);
};

/**
 * Component for Override Role Footer
 */
export const OverrideFooter = ({
	activeTab,
	overrideRolePayload,
	onClose
}: OverrideFooterProps) => {
	/**
	 * Function to over ride the old roles with new ones
	 */
	const overrideRoles = (e: React.MouseEvent<HTMLButtonElement>) => {
		try {
			e.stopPropagation();

			/** Check for payloads and dispatch action to override */
			overrideRolePayload.forEach(
				(actionPayload: TAddUserToStageThunkArg | TAddUserToProjectThunkArg) => {
					switch (activeTab) {
						case 'PROJECT': {
							const apiPayload: TAddUserToProjectThunkArg = {
								payload: {
									...(actionPayload as TAddUserToProjectThunkArg).payload,
									override: true
								},
								blockIds: actionPayload.blockIds,
								stageIds: (actionPayload as TAddUserToProjectThunkArg).stageIds
							};

							(store.dispatch as CustomDispatch)(addUserToProject(apiPayload))
								.unwrap()
								.then(() => {
									// Track sharing of journey event
									const eventProps: TShareItemEventData = {
										elementId: actionPayload.payload.projectId,
										elementType: 'JOURNEY'
									};
									trackEvent(CustomEvents.SHARE_ITEM, eventProps);
								})
								.catch((error) => {
									console.error('Error overriding role : ', error);
								});
							break;
						}
						case 'PHASE': {
							const apiPayload: TAddUserToStageThunkArg = {
								payload: {
									...(actionPayload as TAddUserToStageThunkArg).payload,
									override: true
								},
								blockIds: actionPayload.blockIds
							};
							(store.dispatch as CustomDispatch)(addUserToStage(apiPayload))
								.unwrap()
								.then(() => {
									// Track sharing of group event
									const eventProps: TShareItemEventData = {
										elementId: (actionPayload as TAddUserToStageThunkArg)
											.payload.stageIds[0] as string,
										elementType: 'GROUP'
									};
									trackEvent(CustomEvents.SHARE_ITEM, eventProps);
								})
								.catch((error) => {
									console.error('Error overriding role : ', error);
								});
							break;
						}
						default:
							break;
					}
				}
			);

			/** Close the Modal */
			onClose();
		} catch (error) {
			console.error('Error overriding roles : ', error);
		}
	};

	/**
	 * Function to keep the existing roles and don't override
	 */
	const keepExistingRole = (e: React.MouseEvent<HTMLButtonElement>) => {
		try {
			e.stopPropagation();

			/** Check for payloads and dispatch action to not override */
			overrideRolePayload.forEach(
				(actionPayload: TAddUserToStageThunkArg | TAddUserToProjectThunkArg) => {
					switch (activeTab) {
						case 'PROJECT': {
							const apiPayload: TAddUserToProjectThunkArg = {
								payload: {
									...(actionPayload as TAddUserToProjectThunkArg).payload,
									override: false
								},
								blockIds: actionPayload.blockIds,
								stageIds: (actionPayload as TAddUserToProjectThunkArg).stageIds
							};

							(store.dispatch as CustomDispatch)(addUserToProject(apiPayload))
								.unwrap()
								.then(() => {
									// Track sharing of journy event
									const eventProps: TShareItemEventData = {
										elementId: actionPayload.payload.projectId,
										elementType: 'JOURNEY'
									};
									trackEvent(CustomEvents.SHARE_ITEM, eventProps);
								})
								.catch((error) => {
									console.error('Error adding user to project : ', error);
								});
							break;
						}
						case 'PHASE': {
							const apiPayload: TAddUserToStageThunkArg = {
								payload: {
									...(actionPayload as TAddUserToStageThunkArg).payload,
									override: false
								},
								blockIds: actionPayload.blockIds
							};

							(store.dispatch as CustomDispatch)(addUserToStage(apiPayload))
								.unwrap()
								.then(() => {
									// Track sharing of group event
									const eventProps: TShareItemEventData = {
										elementId: (actionPayload as TAddUserToStageThunkArg)
											.payload.stageIds[0] as string,
										elementType: 'GROUP'
									};
									trackEvent(CustomEvents.SHARE_ITEM, eventProps);
								})
								.catch((error) => {
									console.error('Error adding user to stage : ', error);
								});
							break;
						}
						default:
							break;
					}
				}
			);

			/** Close the Modal */
			onClose();
		} catch (error) {
			console.error('Error adding users : ', error);
		}
	};

	return (
		<div className="override-footer tw-flex tw-items-center tw-justify-end tw-pr-8 tw-pb-8 tw-gap-6">
			<button
				type="button"
				className="keep-btn tw-bg-white tw-border-none tw-font-randMedium tw-text-base tw-px-8 tw-py-2 tw-font-bold"
				onClick={keepExistingRole}
			>
				Keep Existing
			</button>

			<button
				type="button"
				className="primary-btn tw-font-randMedium tw-text-base tw-font-bold"
				onClick={overrideRoles}
			>
				Override
			</button>
		</div>
	);
};
