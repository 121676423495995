import { IBlock, INode, INodesBlock } from '@naya_studio/types';
import { ActionReducerMapBuilder, PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
	TActionType,
	TAddNodesArg,
	TDuplicateBlockArgs,
	TDuplicateProjectFulfill,
	TDuplicateProjectOptimisticallyThunkArg,
	TDuplicateStageThunkArg,
	TEditNodesArgs,
	TLoadNodeArgs,
	TLoadProjectByIdFulfill,
	TLoadTemplateByIdFulfill
} from 'src/types/argTypes';
import { addNodes, editNodes, loadNode } from '../reduxActions/node';

import { INodesInitState } from '../reducers/root.types';
import {
	duplicateProject,
	duplicateProjectOptimistically,
	loadProjectById,
	updateStaleCachedNodesInRedux,
	loadProjectForGuestAction,
	loadTemplateById
} from '../reduxActions/project';
import { duplicateStage } from '../reduxActions/stage';
import { duplicateBlock, loadBlockById } from '../reduxActions/block';

const initialState: INodesInitState = {
	data: {},
	loading: {},
	error: {}
};

const nodesSlice = createSlice({
	name: 'nodes',
	initialState,
	reducers: {
		// reducer to load nodes into redux
		loadNodes: (state: INodesInitState, action: PayloadAction<INode[]>) => {
			const nodes = action.payload;
			nodes.forEach((node) => {
				state.data[node._id as string] = {
					...state.data[node._id as string],
					...node
				};
			});
		},
		// reducer to add a node into redux
		addNode: (state: INodesInitState, action: PayloadAction<INode>) => {
			const node = action.payload;

			state.data[node._id as string] = node;
		},
		// Reducer to edit node by id
		editNodeById: (state: INodesInitState, action: PayloadAction<INode>) => {
			const node = action.payload;

			if (node._id) {
				state.data[node._id] = {
					...state.data[node._id],
					...node
				};
			}
		},
		// reducer to delete a node by id
		deleteNodeById: (state: INodesInitState, action: PayloadAction<string>) => {
			const nodeId = action.payload;

			delete state.data[nodeId as string];
		},
		/**
		 * Reducer to unload nodes from redux
		 */
		unloadNodes: (state: INodesInitState, action: PayloadAction<string>) => {
			const projectId = action.payload;

			const nodesToDelete = [];
			const keys = Object.keys(state.data);
			for (let i = 0; i < keys.length; i++) {
				const nodeId = keys[i] as string;
				if (state.data[nodeId]!.projectId === projectId) nodesToDelete.push(nodeId);
			}

			nodesToDelete.forEach((id) => delete state.data[id]);
		}
	},
	extraReducers: (builder: ActionReducerMapBuilder<INodesInitState>) => {
		/** ---- LOAD BLOCK BY ID ---- */
		// FULFILLED
		builder.addCase(
			loadBlockById.fulfilled,
			(
				state: INodesInitState,
				action: PayloadAction<TActionType<{ block: IBlock }, {}>['payload']>
			) => {
				const { payload } = action;

				if (payload) {
					const { block } = payload;
					const nodeBlock = block as INodesBlock;

					if (nodeBlock?.nodes) {
						const nodes = nodeBlock.nodes as INode[];
						nodes.forEach((node) => {
							state.data[node._id as string] = node;
							state.loading[node._id as string] = false;
							state.error[node._id as string] = null;
						});
					}
				}
			}
		);

		/** ---- LOAD NODE ---- */
		// PENDING
		builder.addCase(
			loadNode.pending,
			(
				state: INodesInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TLoadNodeArgs>['pendingMeta']
				>
			) => {
				const { id } = action.meta.arg.data;
				// reset the error just in case previous call has some error
				state.error[id] = '';
				state.loading[id] = true;
			}
		);
		// FULFILLED = loading nodes to redux once API is fulfilled
		builder.addCase(
			loadNode.fulfilled,
			(state: INodesInitState, action: PayloadAction<TActionType<INode, {}>['payload']>) => {
				const node = action.payload as INode;
				state.data[node._id as string] = node;
				state.loading[node._id as string] = false;
			}
		);
		// REJECTED
		builder.addCase(
			loadNode.rejected,
			(
				state: INodesInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TLoadNodeArgs>['rejectedMeta']
				>
			) => {
				const { id } = action.meta.arg.data;
				state.error[id] = action.payload as string;
				state.loading[id] = false;
			}
		);

		/** ---- ADD NODES ---- */
		// PENDING - adding nodes to redux while API is in pending state
		builder.addCase(
			addNodes.pending,
			(
				state: INodesInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TAddNodesArg>['pendingMeta']
				>
			) => {
				const { nodes } = action.meta.arg.data;
				nodes.forEach((x: INode) => {
					state.data[x._id as string] = x;
					// reset the error just in case previous call has some error
					state.error[x._id as string] = '';
					state.loading[x._id as string] = true;
				});
			}
		);
		// FULFILLED - reverting nodes in redux if API fails
		builder.addCase(
			addNodes.fulfilled,
			(
				state: INodesInitState,
				action: PayloadAction<
					TActionType<{ nodes: INode[] | undefined; blockId: string }, {}>['payload'],
					string,
					TActionType<{}, TAddNodesArg>['fulfilledMeta']
				>
			) => {
				// after rtc fulfilled case will work as validation
				// since we won't be receiving our own change from db (bcoz lastUpdatedBy would be current user)
				// there could be scenes where we would lose data updated in pending case
				// so that's why we'll use fulfilled cases to validate the data
				const { data } = action.meta.arg;
				const { nodes } = data;

				nodes.forEach((n: INode) => {
					const nodeId = n._id as string;

					if (!state.data[nodeId]) state.data[nodeId] = n;
					state.loading[nodeId] = false;
				});
			}
		);
		// REJECTED - reverting nodes in redux if API fails
		builder.addCase(
			addNodes.rejected,
			(
				state: INodesInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TAddNodesArg>['rejectedMeta']
				>
			) => {
				const { prevState, data } = action.meta.arg;
				state.data = prevState.prevNodes;
				data.nodes.forEach((x: INode) => {
					state.loading[x._id as string] = false;
					state.error[x._id as string] = action.payload as string;
				});
			}
		);

		/** ---- EDIT NODES ---- */
		// PENDING- updating the nodes while API is in pending state
		builder.addCase(
			editNodes.pending,
			(
				state: INodesInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TEditNodesArgs>['pendingMeta']
				>
			) => {
				const { nodes } = action.meta.arg.data;
				nodes.forEach((x: INode) => {
					state.data[x._id as string] = {
						...state.data[x._id as string],
						...x
					};
					// reset the error just in case previous call has some error
					state.error[x._id as string] = '';
					state.loading[x._id as string] = true;
				});
			}
		);
		// FULFILLED
		builder.addCase(
			editNodes.fulfilled,
			(
				state: INodesInitState,
				action: PayloadAction<
					TActionType<{ nodeIds: string[] | undefined; blockId: string }, {}>['payload'],
					string,
					TActionType<{}, TEditNodesArgs>['fulfilledMeta']
				>
			) => {
				const { nodes } = action.meta.arg.data;
				nodes.forEach((x: INode) => {
					const reduxNode = state.data[x._id as string] as INode;
					if (x.version! > reduxNode.version!)
						state.data[x._id as string] = {
							...state.data[x._id as string],
							...x
						};

					state.loading[x._id as string] = false;
				});
			}
		);
		// REJECTED - reverting the nodes in redux if API fails
		builder.addCase(
			editNodes.rejected,
			(
				state: INodesInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TEditNodesArgs>['rejectedMeta']
				>
			) => {
				const { prevState, data } = action.meta.arg;
				state.data = prevState.prevBlocks;
				data.nodes.forEach((x: INode) => {
					state.loading[x._id as string] = false;
					state.error[x._id as string] = action.payload as string;
				});
			}
		);

		/** ---- LOAD PROJECT ---- */
		// FULFILLED
		builder.addCase(
			loadProjectById.fulfilled,
			(
				state: INodesInitState,
				action: PayloadAction<TActionType<TLoadProjectByIdFulfill, {}>['payload']>
			) => {
				const { nodes } = action.payload;
				if (nodes) {
					nodes.forEach((node: INode) => {
						const nodeId = node._id as string;
						let overwriteNode = true;

						if (state.data[nodeId]) {
							const oldNode = state.data[nodeId];

							// Only update the node if node from api have been updated later
							// to prevent the scenario where a user refreshes and someone edits a node
							if (
								new Date(oldNode?.updatedAt as string) >
								new Date(node.updatedAt as string)
							)
								overwriteNode = false;
						}

						if (overwriteNode) state.data[nodeId] = node;
						// reset the error just in case previous call has some error
						state.error[nodeId] = null;
						state.loading[nodeId] = false;
					});
				}
			}
		);

		/** ---- LOAD TEMPLATE ---- */
		// FULFILLED
		builder.addCase(
			loadTemplateById.fulfilled,
			(
				state: INodesInitState,
				action: PayloadAction<TActionType<TLoadTemplateByIdFulfill, {}>['payload']>
			) => {
				const { template } = action.payload;
				template?.nodes?.forEach((node) => {
					const id = node._id as string;

					state.data[id] = node;
					state.error[id] = null;
				});
			}
		);

		/** ---- DUPLICATE PROJECT ---- */
		// FULFILLED
		builder.addCase(
			duplicateProject.fulfilled,
			(
				state: INodesInitState,
				action: PayloadAction<TActionType<TDuplicateProjectFulfill, {}>['payload']>
			) => {
				const { nodes } = action.payload;
				if (nodes) {
					nodes.forEach((x: INode) => {
						state.data[x._id as string] = x;
						// reset the error just in case previous call has some error
						state.error[x._id as string] = '';
						state.loading[x._id as string] = false;
					});
				}
			}
		);

		/** ---- UPDATE STALED CACHED NODES IN REDUX ---- */
		// FULFILLED
		builder.addCase(
			updateStaleCachedNodesInRedux.fulfilled,
			(
				state: INodesInitState,
				action: PayloadAction<TActionType<{ nodes: INode[] }, {}>['payload']>
			) => {
				const { nodes } = action.payload;
				nodes.forEach((x: INode) => {
					state.data[x._id as string] = x;
					// reset the error just in case previous call has some error
					state.error[x._id as string] = '';
					state.loading[x._id as string] = false;
				});
			}
		);

		/** DUPLICATE STAGE */
		// PENDING
		builder.addCase(
			duplicateStage.pending,
			(
				state: INodesInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TDuplicateStageThunkArg>['pendingMeta']
				>
			) => {
				const { clonedItems } = action.meta.arg.payload;
				const { nodes: clonedNodes } = clonedItems;

				if (clonedNodes) {
					clonedNodes.forEach((clonedNode) => {
						const nodeId = clonedNode._id as string;
						state.data[nodeId] = clonedNode;
						state.error[nodeId] = null;
					});
				}
			}
		);
		// REJECTED
		builder.addCase(
			duplicateStage.rejected,
			(
				state: INodesInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TDuplicateStageThunkArg>['rejectedMeta']
				>
			) => {
				const { clonedItems } = action.meta.arg.payload;
				const { nodes: clonedNodes } = clonedItems;

				if (clonedNodes) {
					clonedNodes.forEach((clonedNode) => {
						const nodeId = clonedNode._id as string;

						if (state.data[nodeId]) {
							delete state.data[nodeId];
						}

						state.error[nodeId] = action.payload as string;
						state.loading[nodeId] = false;
					});
				}
			}
		);

		/** DUPLICATE BLOCK */
		// PENDING
		builder.addCase(
			duplicateBlock.pending,
			(
				state: INodesInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TDuplicateBlockArgs>['pendingMeta']
				>
			) => {
				const { clonedItems } = action.meta.arg.data;
				const { nodes: clonedNodes } = clonedItems;

				if (clonedNodes) {
					clonedNodes.forEach((clonedNode) => {
						const nodeId = clonedNode._id as string;
						state.data[nodeId] = clonedNode;
						state.loading[nodeId] = true;
						state.error[nodeId] = null;
					});
				}
			}
		);
		// REJECTED
		builder.addCase(
			duplicateBlock.rejected,
			(
				state: INodesInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TDuplicateBlockArgs>['rejectedMeta']
				>
			) => {
				const { clonedItems } = action.meta.arg.data;
				const { nodes: clonedNodes } = clonedItems;

				if (clonedNodes) {
					clonedNodes.forEach((clonedNode) => {
						const nodeId = clonedNode._id as string;

						if (state.data[nodeId]) {
							delete state.data[nodeId];
						}

						state.error[nodeId] = action.payload as string;
						state.loading[nodeId] = false;
					});
				}
			}
		);

		/** ---- DUPLICATE PROJECT OPTIMISTICALLY---- */
		// PENDING
		builder.addCase(
			duplicateProjectOptimistically.pending,
			(
				state: INodesInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TDuplicateProjectOptimisticallyThunkArg>['pendingMeta']
				>
			) => {
				const { clonedItems } = action.meta.arg.payload;
				const { nodes: clonedNodes } = clonedItems;

				if (clonedNodes) {
					clonedNodes.forEach((node) => {
						const nodeId = node._id as string;
						state.data[nodeId] = node;
						state.loading[nodeId] = true;
						state.error[nodeId] = null;
					});
				}
			}
		);
		// FULFILLED
		builder.addCase(
			duplicateProjectOptimistically.fulfilled,
			(
				state: INodesInitState,
				action: PayloadAction<
					TActionType<{}, TDuplicateProjectOptimisticallyThunkArg['payload']>['payload'],
					string,
					TActionType<{}, TDuplicateProjectOptimisticallyThunkArg>['fulfilledMeta']
				>
			) => {
				const { clonedItems } = action.meta.arg.payload;
				const { nodes: clonedNodes } = clonedItems;

				if (clonedNodes) {
					clonedNodes.forEach((node) => {
						const nodeId = node._id as string;

						state.loading[nodeId] = false;
						state.error[nodeId] = null;
					});
				}
			}
		);
		// REJECTED
		builder.addCase(
			duplicateProjectOptimistically.rejected,
			(
				state: INodesInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TDuplicateProjectOptimisticallyThunkArg>['rejectedMeta']
				>
			) => {
				const { payload } = action.meta.arg;
				const { payload: error } = action;

				if (payload) {
					const { clonedItems } = payload;
					const { nodes: clonedNodes } = clonedItems;

					clonedNodes.forEach((node: INode) => {
						const nodeId = node._id as string;

						if (state.data[nodeId]) delete state.data[nodeId];
						state.loading[nodeId] = false;
						state.error[nodeId] = error as string;
					});
				}
			}
		);

		/** ---- LOAD PROJECT FOR GUEST ---- */
		// FULFILLED
		builder.addCase(
			loadProjectForGuestAction.fulfilled,
			(
				state: INodesInitState,
				action: PayloadAction<TActionType<TLoadProjectByIdFulfill, {}>['payload']>
			) => {
				const { nodes } = action.payload;
				if (nodes) {
					nodes.forEach((x: INode) => {
						state.data[x._id as string] = x;
						// reset the error just in case previous call has some error
						state.error[x._id as string] = '';
						state.loading[x._id as string] = false;
					});
				}
			}
		);
	}
});

export const { loadNodes, addNode, editNodeById, deleteNodeById, unloadNodes } = nodesSlice.actions;
export default nodesSlice.reducer;
