import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { getGatewayKey } from 'src/util/helper/queryString';
import {
	aiIngestRouter,
	getTemplatesAPI,
	projectGroupRouter,
	projectRouter
} from 'src/endpoints/projects-api';
import {
	TAddNotificationThunkArg,
	TAddUserToProjectFulfill,
	TAddUserToProjectThunkArg,
	TCreateProjectFulfill,
	TCreateProjectThunkArg,
	TDeleteProjectThunkArg,
	TDuplicateProjectFulfill,
	TDuplicateProjectOptimisticallyThunkArg,
	TDuplicateProjectThunkArg,
	TEditProjectDetailsThunkArg,
	TEditProjectThunkArg,
	TEditUserRoleForProjectFulfill,
	TEditUserRoleForProjectThunkArg,
	TGetProjectForGuest,
	TLoadProjectByIdFulfill,
	TLoadProjectThunkArg,
	TLoadTemplateByIdFulfill,
	TLoadTemplateByIdThunkArgs,
	TNestingThunkArgs,
	TProjectAIStatusThunkArg,
	TRemoveUserFromProjectFulfill,
	TRemoveUserFromProjectThunkArg,
	TRequestProjectThunkArg,
	TSyncProjectWithDriveThunkArg
} from 'src/types/argTypes';
import {
	EEvent,
	EUserRole,
	INode,
	INotification,
	IProject,
	IProjectDetail,
	IProjectGroup,
	IGroup,
	IUser,
	IUserPreferenceNode,
	TInvitedEmail,
	TUserAndRole,
	CustomProject,
	IBlock,
	INote,
	IFeedback
} from '@naya_studio/types';
import {
	TAddNotificationPayload,
	TAddUserToProjectPayload,
	TDuplicateProjectPayload,
	TEditGroupProjectDetailsPayload,
	TEditProjectDetailsPayload,
	TEditProjectPayload,
	TEditUserRoleForProjectPayload,
	TLoadProjectByIdPayload,
	TRemoveUserFromProjectPayload,
	TRequestAccessToProjectPayload
} from 'src/types/payloadTypes';
import {
	loadProjectFromLocalForage,
	removeProjectFromLocalForage,
	saveProjectInLocalForage
} from 'src/util/storage/indexedDBStorage';
import { getRoleName } from 'src/util/helper/stage';
import { DUMMY_IMG } from 'src/util/helper/constants';
import trackEvent from 'src/util/analytics/analytics';
import { CustomEvents, ProjectEvents } from 'src/util/analytics/events';
import getUserFromRedux from 'src/util/helper/user';
import { checkIfUserExistsInDatabase } from 'src/util/auth/authActions';
import {
	TDuplicateElementEventData,
	TOpenProjectEventData
} from 'src/util/analytics/analytic.types';
import { AxiosResponseWrapper } from '../actions/types';
import { getFormatedStagesBlocksNodes, getHostName } from '../actions/util';
import { ReduxState } from '../reducers/root.types';
import { notificationSlice } from '../features/api/notification';
import { loadUserOfProject } from '../features/projectUsers';
import { bulkUpdateGroups } from './stage';

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
const qs = `?${getGatewayKey()}`;

/**
 * Function to create a notification
 */
export const addNotification = createAsyncThunk(
	'project/addNotification',
	async ({ payload }: TAddNotificationThunkArg, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();
			const { notification } = payload;
			const fromUser = user.email as string;

			const apiPayload: TAddNotificationPayload = {
				...notification,
				fromUser
			};

			const notif = await thunkApi.dispatch(
				notificationSlice.endpoints.createNotif.initiate({ payload: apiPayload })
			);
			return notif;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * Function to create new project
 */
export const createProject = createAsyncThunk(
	'project/createProject',
	async ({ payload, next }: TCreateProjectThunkArg, thunkApi) => {
		try {
			const { _id: projectId, createdBy, optimisticProject } = payload;

			const optimisticProjectDb = {
				...optimisticProject,
				users: [
					{
						user: createdBy as string,
						role: 'OWNER'
					}
				]
			};

			const response = await axios.post<AxiosResponseWrapper<any>>(`${projectRouter}/${qs}`, {
				payload: {
					_id: projectId,
					createdBy: payload.createdBy,
					parentGroupId: payload.parentGroupId,
					projectType: payload.projectType,
					name: payload.name,
					project: optimisticProjectDb
				}
			});

			if (response.status !== 200) throw new Error('Error creating project');

			// Track creating new project event
			trackEvent(ProjectEvents.CREATE_NEW_PROJECT);

			if (next && typeof next === 'function') next(projectId as string);

			const fulfillValue: TCreateProjectFulfill = {
				projectId,
				projectGroup: response.data.payload.projectGroup as IProjectGroup
			};

			return fulfillValue;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * Function to load a project into redux using id
 */
export const loadProjectById = createAsyncThunk(
	'project/loadProject',
	async ({ payload, next }: TLoadProjectThunkArg, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();
			const reduxState = thunkApi.getState() as ReduxState;
			const { _id: id, preventLocalForageLoad = false } = payload;

			const apiPayload: TLoadProjectByIdPayload = {
				userId: user._id,
				email: user.email?.toLowerCase(),
				isAdmin: user.userType?.includes('ADMIN'),
				cache: true
			};

			if (!preventLocalForageLoad) await loadProjectFromLocalForage(id);
			const response = await axios.post<{
				payload: {
					project: IProject;
					groups: {
						[id: string]: IGroup;
					};
					blocks: {
						[id: string]: IBlock;
					};
					nodes: INode[];
					notes: INote[];
					feedbacks: IFeedback[];
				};
				restrictedAccess: { stages: string[]; blocks: string[] };
			}>(`${projectRouter}/${id}${qs}`, {
				payload: apiPayload
			});
			if (response.status !== 200) throw new Error('Error fetching project');

			const {
				payload: { project, groups, blocks, nodes, notes, feedbacks },
				restrictedAccess
			} = response.data;

			await saveProjectInLocalForage(
				project as IProject,
				Object.values(groups),
				Object.values(blocks),
				feedbacks,
				notes
			);

			// Track opening project event
			const eventProps: TOpenProjectEventData = {
				studioView:
					(window.sessionStorage.getItem('homebase_view') as
						| 'LIST'
						| 'GRID'
						| 'LARGER_GRID') || 'GRID'
			};
			trackEvent(ProjectEvents.OPEN_PROJECT, eventProps);

			if (next && typeof next === 'function') next();
			const fulfillValue: TLoadProjectByIdFulfill = {
				project,
				nodes,
				stages: Object.values(groups),
				blocks: Object.values(blocks),
				feedbacks,
				notes,
				restrictedAccess,
				isGroupOpened: Boolean(reduxState.projectGroup.data._id)
			};
			return fulfillValue;
		} catch (error: any) {
			console.error(error);
			const axiosError = error as AxiosError;
			if (axiosError?.response?.data?.message) {
				return thunkApi.rejectWithValue({
					status: axiosError.response.status,
					message: axiosError.response.data.message
				});
			}
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * Load project for guest users
 */
export const loadProjectForGuestAction = createAsyncThunk(
	'project/loadProjectForGuest',
	async ({ payload }: TGetProjectForGuest, thunkApi) => {
		try {
			const { projectId, stageId, blockId, hasGuestAccessTo } = payload;
			const api = `${projectRouter}/project-for-guest${qs}
					&projectId=${projectId}
					&stageId=${stageId}
					&blockId=${blockId}
					&hasGuestAccessTo=${hasGuestAccessTo}`;
			const response = await axios.get<{
				project: IProject;
				groups: IGroup[];
				blocks: IBlock[];
				feedbacks: IFeedback[];
				notes: INote[];
				nodes: INode[];
				restrictedAccess: { stages: string[]; blocks: string[] };
			}>(api);
			if (response.status !== 200) throw new Error('Error fetching project');
			const { project, groups, blocks, feedbacks, notes, nodes, restrictedAccess } =
				response.data;

			// Load the user of block to project
			blocks.forEach((block) => {
				const projectUsers: IUser[] = [];
				const blockUsers: TUserAndRole[] = [];
				block.users.forEach((user) => {
					projectUsers.push((user as TUserAndRole).user as IUser);
					blockUsers.push({
						user: ((user as TUserAndRole).user as IUser)._id as string,
						role: (user as TUserAndRole).role
					});
				});

				block.users = blockUsers;

				if (projectUsers && projectUsers.length) {
					thunkApi.dispatch(loadUserOfProject(projectUsers));
				}
			});

			const fulfillValue: TLoadProjectByIdFulfill = {
				project,
				nodes: nodes as INode[],
				stages: groups,
				blocks,
				feedbacks,
				notes,
				restrictedAccess
			};
			return fulfillValue;
		} catch (error) {
			console.error(error);
			const axiosError = error as AxiosError;
			if (axiosError?.response?.data?.message) {
				return thunkApi.rejectWithValue({
					status: axiosError.response.status,
					message: axiosError.response.data.message
				});
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * Function to edit project
 */
export const editProject = createAsyncThunk(
	'project/editProject',
	async ({ payload, next }: TEditProjectThunkArg, thunkApi) => {
		try {
			const { _id: id, update, sourceStageIdsBeforeReorder } = payload;
			const user: IUser = getUserFromRedux();

			const apiPayload: TEditProjectPayload = {
				projectId: id,
				update,
				currentEmail: user.email?.toLowerCase(),
				currentUserId: user._id,
				sourceStageIdsBeforeReorder,
				lastUpdatedInfo: {
					lastUpdatedBy: user._id as string
				}
			};

			const response = await axios.put<AxiosResponseWrapper<IProject>>(
				`${projectRouter}/${qs}`,
				{
					payload: apiPayload
				}
			);

			if (response.status !== 200) throw new Error(`Error editing project at ${id}`);

			if (next && typeof next === 'function') next();

			return payload;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * Function to edit project details
 */
export const editProjectDetails = createAsyncThunk(
	'project/editProjectDetails',
	async ({ payload }: TEditProjectDetailsThunkArg, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();
			const { projects } = thunkApi.getState() as ReduxState;
			const { updates: updatedDetails, projectId } = payload;

			const projectDetails = projects.data[projectId]?.projectDetails as IProjectDetail;
			const apiPayload: TEditProjectDetailsPayload = {
				projectId,
				update: {
					...projectDetails,
					...updatedDetails
				},
				lastUpdatedInfo: {
					lastUpdatedBy: user._id as string
				}
			};

			const response = await axios.put<AxiosResponseWrapper<IProject>>(
				`${projectRouter}/projectDetails/${qs}`,
				{
					payload: apiPayload
				}
			);

			if (response.status !== 200) throw new Error(`Error editing project ${projectId}`);

			return payload;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * Function to edit group project details
 */
export const editGroupProjectDetails = createAsyncThunk(
	'project/editGroupProjectDetails',
	async ({ payload }: TEditProjectDetailsThunkArg, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();
			const { projectId, updates: updatedDetails } = payload;

			const apiPayload: TEditGroupProjectDetailsPayload = {
				projectGroupId: projectId,
				update: {
					...updatedDetails
				},
				lastUpdatedInfo: {
					lastUpdatedBy: user._id as string,
					projectId
				}
			};

			const response = await axios.put<AxiosResponseWrapper<IProject>>(
				`${projectGroupRouter}/${qs}`,
				{
					payload: apiPayload
				}
			);

			if (response.status !== 200) throw new Error(`Error editing project at ${projectId}`);

			return payload;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * Function to add a user to Project
 */
export const addUserToProject = createAsyncThunk(
	'project/addUser',
	async (
		{ payload, blockIds, stageIds, requestType, next }: TAddUserToProjectThunkArg,
		thunkApi
	) => {
		try {
			const user: IUser = getUserFromRedux();

			const {
				projectId,
				email,
				role,
				override,
				projectName,
				username,
				inviteNote,
				isInvitedUser,
				userId,
				thumbnailUrl
			} = payload;
			const projectZoomLevel =
				(user.userPreferences as IUserPreferenceNode).journeyZoom?.find(
					(item) => item.project === projectId
				)?.zoom || 1;

			const apiPayload: TAddUserToProjectPayload = {
				projectId,
				stageIds,
				blockIds,
				userId: isInvitedUser ? email : (userId as string),
				role,
				override,
				isInvitedUser,
				lastUpdatedInfo: {
					lastUpdatedBy: user._id as string
				},
				...(projectZoomLevel !== 1 ? { projectZoomLevel } : {})
			};

			const response = await axios.put<
				AxiosResponseWrapper<{
					user: IUser | string;
				}>
			>(`${projectRouter}/invite-user/${qs}`, {
				payload: apiPayload
			});

			if (response.status !== 200) throw new Error('Error sending invite');

			if (next && typeof next === 'function') next();

			// Send an invite email to the respective email id
			const data = {
				eventType: requestType || ('INVITE_USER' as EEvent),
				data: {
					projectName,
					projectId,
					link: `${getHostName()}/project/${projectId}`,
					username,
					inviteNote,
					inviterName: user.userName,
					inviterEmail: user.email,
					profilePic: user.profilePic || DUMMY_IMG,
					fromUserId: user._id as string,
					uniqueIdentifier: projectId,
					thumbnailUrl
				},
				toUser: email.toLowerCase()
			};

			const notifResponse = await thunkApi
				.dispatch(
					addNotification({
						payload: {
							notification: data
						}
					})
				)
				.unwrap();
			const fulfillValue: TAddUserToProjectFulfill = {
				projectId,
				user: response.data.payload?.user as IUser | string,
				role: role as EUserRole,
				blockIds,
				stageIds,
				isInvitedUser,
				override,
				notification: (notifResponse as { data: { payload: INotification } })?.data
					?.payload as INotification
			};

			return fulfillValue;
		} catch (error) {
			console.error('Notification Error:', error);
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * Function to remove a user from Project
 */
export const removeUserFromProject = createAsyncThunk(
	'project/removeUserFromProject',
	async ({ payload, next, blockIds, stageIds }: TRemoveUserFromProjectThunkArg, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();

			const { userId, projectId, userRole, invitedUserRole } = payload;
			const isInvitedUser = !!invitedUserRole;

			if (!userRole && !invitedUserRole)
				throw new Error('Can not find user in project user list, please contact an admin');

			if (!isInvitedUser && (userRole as TUserAndRole).role === 'OWNER')
				throw new Error('Cannot remove owner');

			const apiPayload: TRemoveUserFromProjectPayload = {
				projectId,
				stageIds,
				blockIds,
				userId,
				isInvitedUser,
				lastUpdatedInfo: {
					lastUpdatedBy: user._id as string
				}
			};

			const response = await axios.put<AxiosResponseWrapper<any>>(
				`${projectRouter}/remove-user/${qs}`,
				{
					payload: apiPayload
				}
			);
			if (response.status !== 200) throw new Error('Error removing user');

			if (next && typeof next === 'function') next();

			const fulfillValue: TRemoveUserFromProjectFulfill = {
				projectId,
				isInvitedUser,
				userId,
				blockIds,
				stageIds
			};

			return fulfillValue;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * Function to edit user role for a Project
 */
export const editUserRoleForProject = createAsyncThunk(
	'project/editUserRoleForProject',
	async ({ payload, next, blockIds, stageIds }: TEditUserRoleForProjectThunkArg, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();
			const { invitedUser } = payload;
			const isInvitedUser = !!invitedUser;

			const apiPayload: TEditUserRoleForProjectPayload = {
				...payload,
				isInvitedUser,
				blockIds,
				stageIds,
				lastUpdatedInfo: {
					lastUpdatedBy: user._id as string
				}
			};

			const response = await axios.put<
				AxiosResponseWrapper<{
					users: TUserAndRole[] | TInvitedEmail[];
					isInvitedUser: boolean;
				}>
			>(`${projectRouter}/edit-user/${qs}`, {
				payload: apiPayload
			});
			if (response.status !== 200) throw new Error('Error changing the role of user');

			if (next && typeof next === 'function') next();

			const fulfillValue: TEditUserRoleForProjectFulfill = {
				...payload,
				isInvitedUser,
				blockIds,
				stageIds
			};

			return fulfillValue;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * Sends api request to check if cache has any stale nodes
 * Api returns updated nodes
 * Update stale nodes in redux
 */
export const updateStaleCachedNodesInRedux = createAsyncThunk(
	'project/updateStaleCachedNodesInRedux',
	async ({ payload }: TLoadProjectThunkArg, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();
			const { _id: projectId } = payload;

			const apiPayload: TLoadProjectByIdPayload = {
				userId: user._id,
				email: user.email?.toLowerCase(),
				isAdmin: user.userType?.includes('ADMIN'),
				cache: false
			};

			const response = await axios.post(`${projectRouter}/${projectId}${qs}`, {
				payload: apiPayload
			});

			if (response.status !== 200) throw new Error('Failed to fetch nodes');

			return {
				nodes: response.data.nodes as INode[]
			};
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * Function to duplicate a project
 */
export const duplicateProject = createAsyncThunk(
	'project/duplicateProject',
	async ({ payload, next }: TDuplicateProjectThunkArg, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();
			const apiPayload: TDuplicateProjectPayload = {
				projectId: payload._id,
				duplicatedBy: user._id,
				userId: user._id,
				email: user.email?.toLowerCase(),
				isAdmin: user.userType?.includes('ADMIN'),
				isTemplate: payload.isTemplate,
				withAssets: payload.withAssets,
				defaultProjectGroup: (user.defaultProjectGroup as IProjectGroup)._id as string
			};
			const response = await axios.put<AxiosResponseWrapper<any>>(
				`${projectRouter}/duplicate${qs}`,
				{
					payload: apiPayload
				}
			);

			if (response.status !== 200) {
				throw new Error('Error duplicating project');
			}

			const { payload: newProject, nodes } = response.data;
			const { stages, canvases, stageIds } = getFormatedStagesBlocksNodes(
				newProject?.stages as IGroup[]
			);
			if (newProject?.stages) newProject.stages = stageIds;

			// Track duplicating project event
			const eventProps: TDuplicateElementEventData = {
				elementId: payload._id,
				elementType: 'JOURNEY'
			};
			trackEvent(CustomEvents.DUPLICATE_ELEMENT, eventProps);

			if (next && typeof next === 'function') next();

			const customProject = {
				_id: newProject._id as string,
				projectName: (newProject?.projectDetails as IProjectDetail).name || '',
				lastUpdatedAt: newProject.updatedAt!,
				// TODO BRANCHING: set the below field correctly
				// thumbnailUrl: (newProject.currentStage as IGroup)?.thumbnailUrl,
				yourRole: getRoleName('OWNER')!,
				isPinned: false,
				isSubscribed: true,
				link: `/project/${newProject._id}`,
				accessLevel: 'Project',
				noOfBlocks: 0,
				size: 0,
				collaborators: [
					{
						profilePic: user.profilePic,
						displayName: user.userName,
						color: (user.userPreferences as IUserPreferenceNode).color
					}
				]
			};

			const fulfillValue: TDuplicateProjectFulfill = {
				project: newProject,
				nodes: nodes as INode[],
				stages,
				blocks: canvases,
				customProject: customProject as CustomProject
			};

			return fulfillValue;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * Function to delete a project
 */
export const deleteProject = createAsyncThunk(
	'project/deleteProject',
	async ({ payload, next }: TDeleteProjectThunkArg, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();
			const { _id } = payload;

			const response = await axios.patch<AxiosResponseWrapper<any>>(
				`${projectRouter}/${qs}`,
				{
					payload: {
						projectId: _id,
						lastUpdatedInfo: {
							lastUpdatedBy: user._id as string
						}
					}
				}
			);
			await removeProjectFromLocalForage(_id);

			if (response.status !== 200) {
				throw new Error('Error deleting project');
			}

			if (next && typeof next === 'function') next();

			return payload;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

export const duplicateProjectOptimistically = createAsyncThunk(
	'project/duplicateProjectOptimistically',
	async ({ payload }: TDuplicateProjectOptimisticallyThunkArg, thunkApi) => {
		try {
			const response = await axios.put<AxiosResponseWrapper<any>>(
				`${projectRouter}/duplicate-optimistically/${qs}`,
				{
					payload
				}
			);

			if (response.status !== 200) throw new Error('Error creating project');

			// Track duplicating project event
			const eventProps: TDuplicateElementEventData = {
				elementId: payload.originalProjectId,
				elementType: 'JOURNEY'
			};
			trackEvent(CustomEvents.DUPLICATE_ELEMENT, eventProps);

			return payload;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * Redux action to fetch template by id from backend
 */
export const loadTemplateById = createAsyncThunk(
	'project/getTemplates',
	async ({ payload }: TLoadTemplateByIdThunkArgs, thunkApi) => {
		try {
			// Get the templates
			const res = await axios.get<{
				template: {
					project: IProject;
					groups: IGroup[];
					blocks: IBlock[];
					feedbacks: IFeedback[];
					notes: INote[];
					nodes: INode[];
				};
			}>(`${getTemplatesAPI}/${payload.id}${qs}`);

			return res.data as TLoadTemplateByIdFulfill;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * Redux action to fetch templates from backend
 */
export const loadTemplates = createAsyncThunk('project/getTemplates', async (_, thunkApi) => {
	try {
		// Get the templates
		const res = await axios.get<{ templates: IProject[] }>(`${getTemplatesAPI}${qs}`);

		return res.data;
	} catch (error) {
		if (error instanceof TypeError) {
			return thunkApi.rejectWithValue(error.message);
		}
		// Handle other types of errors
		return thunkApi.rejectWithValue('An error occurred');
	}
});

/**
 * Redux action to sync project with drive folder connected
 */
export const syncProjectWithDrive = createAsyncThunk(
	'/google/drive-sync',
	async (payload: TSyncProjectWithDriveThunkArg, thunkApi) => {
		try {
			await axios.post(`${projectRouter}/google/sync-drive${qs}`, payload);
			return payload;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * Function to request access to a project
 */
export const requestAccess = createAsyncThunk(
	'project/access',
	async ({ payload, requestType, blockId, stageId, next }: TRequestProjectThunkArg, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();
			const { projectId, projectName, userId, projectOwnerId } = payload;
			const apiPayload: TRequestAccessToProjectPayload = {
				projectId,
				userId,
				lastUpdatedInfo: {
					lastUpdatedBy: user._id as string
				},
				requestType,
				blockId,
				stageId
			};

			const response = await axios.put<
				AxiosResponseWrapper<{
					user: IUser | string;
				}>
			>(`${projectRouter}/request-project-access/${qs}`, {
				payload: apiPayload
			});

			if (response.status !== 200) throw new Error('Error requesting access');

			if (next && typeof next === 'function') next();

			// Send email to the project owner if access is requested
			if (requestType === 'REQUEST_ACCESS') {
				const projectOwner = await checkIfUserExistsInDatabase(
					'',
					projectOwnerId as string
				);
				if (projectOwner) {
					const notifData = {
						eventType: requestType,
						data: {
							requestingUserEmail: user.email,
							projectOwner: projectOwner.email,
							projectName,
							requestingUserName: user.userName,
							allowAccessURL: `${getHostName()}/project/${projectId}${
								stageId ? `/${stageId}` : ''
							}${
								blockId ? `/${blockId}` : ''
							}?requestType=REQUEST_APPROVED&requestingUserEmail=${
								user.email
							}&requestingUserId=${user._id}`,
							rejectAccessURL: `${getHostName()}/project/${projectId}${
								stageId ? `/${stageId}` : ''
							}${
								blockId ? `/${blockId}` : ''
							}?requestType=REQUEST_DECLINED&requestingUserEmail=${
								user.email
							}&requestingUserId=${user._id}`,
							blockName: '',
							stageName: ''
						},
						toUser: projectOwner.email
					};

					await thunkApi
						.dispatch(
							addNotification({
								payload: {
									notification: notifData
								}
							})
						)
						.unwrap();
				}
			}

			// Return a value if necessary
			return response.data; // Adjust the return value as per your requirement
		} catch (error) {
			console.error('Notification Error:', error);
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			throw new Error('An error occurred');
		}
	}
);

/**
 * Function to handle nesting redux update
 * NOTE: created a separate action to avoid redux getting updated twice for project and stageS update.
 * 		 when redux is update in nestingAction.pending case, it batches the updates and rerender only once.
 */
export const nestingAction = createAsyncThunk(
	'project/nesting',
	async ({ payload, prevState }: TNestingThunkArgs, thunkApi) => {
		try {
			await thunkApi
				.dispatch(
					bulkUpdateGroups({
						data: { groups: payload.groups },
						prevState: { groups: prevState.groups }
					})
				)
				.unwrap();
			await thunkApi
				.dispatch(editProject({ payload: payload.project, prevState: prevState.project }))
				.unwrap();
			return true;
		} catch (error) {
			console.error('Nesting Error:', error);
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			throw new Error('An error occurred');
		}
	}
);

/**
 * Redux action to toggle AI feature status on project
 */
export const toggleAIStatus = createAsyncThunk(
	'project/aiStatus',
	async (payload: TProjectAIStatusThunkArg, thunkApi) => {
		try {
			const { projectId, isAIFeaturesEnabled, isContentTraningEnabled } = payload;
			await axios.post(`${aiIngestRouter}/project/${projectId}`, {
				isAIFeaturesEnabled,
				isContentTraningEnabled
			});
			return payload;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);
