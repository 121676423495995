import { IAction } from '../root.types'
import { ThemeActionTypes } from '../root.types'

// collab.naya.studio/project/:id

export interface ITheme {
  color:string,
  colorWithOpacity:string
}

const initialTheme:ITheme = {
  color:'#5714ac',
  colorWithOpacity:'#e2daec'
}

const themeReducer = (state = initialTheme, action: IAction<ThemeActionTypes | keyof typeof ThemeActionTypes>) => {

  let newState: ITheme
  const theme: ITheme = action.payload

  switch (action.type) {
    case 'LOAD_THEME':
      newState = theme
      break
    case 'EDIT_THEME':
      newState = {
        ...state,
        ...theme
      }
      break
    default:
      newState = state
      break
  }

  return newState
}

export default themeReducer