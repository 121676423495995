import * as PIXI from 'pixi.js';
import { INode } from '@naya_studio/types';
import photoIcon from '../../../../../assets/icons/fileIcon/photos.png';
import mediaIcon from '../../../../../assets/icons/fileIcon/media.png';
import documentIcon from '../../../../../assets/icons/fileIcon/document.png';
import graphicIcon from '../../../../../assets/icons/fileIcon/graphic.png';
import threeDIcon from '../../../../../assets/icons/fileIcon/3d.png';
import compressedIcon from '../../../../../assets/icons/fileIcon/compressed.png';
import otherIcon from '../../../../../assets/icons/fileIcon/other.png';

// to get the size and position of author and mask according to the size of node
export const getAuthorPosition = (
  width: number,
  height: number,
  scale: number = 0.1,
  transform: { scaleX: number; scaleY: number } = { scaleX: 1, scaleY: 1 },
) => {
  let minSize = Math.min(width, height) * scale;
  const transformedSize = Math.min(transform.scaleX, transform.scaleY) * minSize;
  if (transformedSize < 33) minSize = 33 / Math.min(transform.scaleX, transform.scaleY);
  const padding = {
    x: minSize / 3,
    y: minSize / 3,
  };
  const authorWidth = minSize;
  const authorHeight = minSize;
  const circleRadius = minSize / 2;
  const image = {
    width: authorWidth,
    height: authorHeight,
    x: width - authorWidth - padding.x,
    y: height - authorHeight - padding.y,
  };
  const circle = {
    radius: circleRadius,
    x: width - circleRadius - padding.x,
    y: height - circleRadius - padding.y,
  };
  return {
    image,
    circle,
  };
};

export const positionAuthorImage = (
  authorImage: PIXI.Sprite,
  circularMask: PIXI.Graphics,
  nodeDims: {
    x: number;
    y: number;
    width: number;
    height: number;
    scaleX: number;
    scaleY: number;
    rotation: number;
  },
  scaleAuthor: number,
) => {
  const {
    width, height, x, y, scaleX, scaleY, rotation,
  } = nodeDims;
  const { image, circle } = getAuthorPosition(width, height, scaleAuthor, { scaleX, scaleY });
  // eslint-disable-next-line prefer-const
  let authorImageCopy = authorImage;
  authorImageCopy.anchor.set(0.5, 0.5);
  authorImageCopy.width = image.width;
  authorImageCopy.height = image.height;
  authorImageCopy.x = x! + image.x + image.width / 2;
  authorImageCopy.y = y! + image.y + image.height / 2;
  authorImageCopy.rotation = rotation;
  circularMask.beginFill(0xffffff);
  circularMask.drawCircle(x! + circle.x, y! + circle.y, circle.radius);
  circularMask.endFill();
};

/**
 *
 * @param circularFill graphics added to the node
 * @param pixiText Letter to be added to the circular Fill
 * @param nodeDims current dimnesions of the node
 * @param scaleAuthor size of the fill compared to the node
 */
export const positionAuthorFill = (
  circularFill: PIXI.Graphics,
  pixiText: PIXI.Text,
  nodeDims: {
    x: number;
    y: number;
    width: number;
    height: number;
    scaleX: number;
    scaleY: number;
    rotation: number;
  },
  scaleAuthor: number,
) => {
  const {
    width, height, x, y, scaleX, scaleY, rotation,
  } = nodeDims;
  const { circle } = getAuthorPosition(width, height, scaleAuthor, { scaleX, scaleY });
  // eslint-disable-next-line prefer-const
  let pixiTextCopy = pixiText;
  pixiTextCopy.style = new PIXI.TextStyle({
    fontFamily: 'Arial',
    fontSize: 16 * (circle.radius / 16.5),
    align: 'center',
    fill: 'white',
  });
  pixiTextCopy.anchor.set(0.5);
  pixiTextCopy.x = x + circle.x;
  pixiTextCopy.y = y + circle.y;
  pixiTextCopy.rotation = rotation;
  circularFill.beginFill(0xe74c3c);
  circularFill.drawCircle(x + circle.x, y + circle.y, circle.radius);
  circularFill.endFill();
  circularFill.addChild(pixiTextCopy);
};

export const getFileIcon = (extension: string) => {
  const upperCaseExt = extension.toUpperCase();
  switch (true) {
    case ['AAC', 'MP3', 'WAV', 'WMA', 'DOLBY', 'DTS', 'MPEG-1',
      'MPEG-2', 'MPEG-4', 'AVI', 'MOV', 'AVCHD', 'AIFF', 'ASF',
      'FLAC', 'ADPCM', 'DSD', 'LPCM', 'OGG'].includes(upperCaseExt):
      return mediaIcon;
    case ['JPEG', 'GIF', 'TIFF', 'BMP', 'BMP', 'PNG', 'PSD'].includes(upperCaseExt):
      return photoIcon;
    case ['DOC', 'DOCX', 'HTML', 'HTM', 'ODT', 'PDF', 'XLS', 'XLSX', 'ODS', 'PPT', 'PPTX', 'TXT', 'CSV'].includes(upperCaseExt):
      return documentIcon;
    case ['AI', 'EPS', 'SVG', 'SKETCH'].includes(upperCaseExt):
      return graphicIcon;
    case ['USDZ/USD', 'USDZ', 'USD', 'STEP', 'STP', 'COLLADE'].includes(upperCaseExt):
      return threeDIcon;
    case ['RAR', 'ZIP', '7Z', 'MZP', 'TAR', 'ZIX', 'ARC', 'ARJ'].includes(upperCaseExt):
      return compressedIcon;
    case ['FIG', 'EXE', 'BIN', 'XML'].includes(upperCaseExt):
      return otherIcon;
    default:
      return otherIcon;
  }
};

export const getFileName = (name: string[], scaleX: number) => {
  const upto = parseInt(`${scaleX * 10}`, 10);
  const textValue = `${name[0]?.slice(0, upto > 0 ? upto : 1)}.${(name[name.length - 1] as string)}`;
  return textValue;
};

const getTaggedStyles = (cssText: string) => {
  const regex = /([\w-]*)\s*:\s*([^;]*)/g;
  let match: any; const
    properties: any = {};
  // eslint-disable-next-line no-cond-assign
  while (match = regex.exec(cssText)) properties[match[1]] = match[2].trim();

  const propertiesObject: any = {
    'font-size': 'fontSize',
    'font-family': 'fontFamily',
    'text-decoration': 'textDecoration',
    'font-style': 'fontStyle',
    'font-weight': 'fontWeight',
    'text-align': 'align',
    color: 'fill',
  };
  let styleString = '';
  Object.keys(properties).forEach((property: any) => {
    if (property === 'font-size') {
      const currentFontSize = properties[property].match(/[0-9]+/);
      if (currentFontSize && currentFontSize.length > 0) {
        styleString = `${styleString} ${propertiesObject[property]}="${parseInt(currentFontSize[0], 10)}"`;
      }
    } else {
      styleString = `${styleString} ${propertiesObject[property]}="${properties[property]}"`;
    }
  });
  return styleString;
};

export const rGBToHex = (r: any, g: any, b: any) => {
  let R = parseInt(r, 10).toString(16);
  let G = parseInt(g, 10).toString(16);
  let B = parseInt(b, 10).toString(16);

  if (R.length === 1) R = `0${r}`;
  if (G.length === 1) G = `0${g}`;
  if (B.length === 1) B = `0${b}`;

  return `#${R}${G}${B}`;
};

export const updateTags = (text: string) => {
  // let updatedText = text.replace(/style="font-size:\s[0-9.]+px;"/gm, (x) => {
  //   const currentFontsize = x.match(/[0-9.]+/);
  //   return `fontSize="${currentFontsize}"`;
  // });
  let updatedText = text;
  updatedText = updatedText.replace(/<!--block-->/gm, '');
  updatedText = updatedText.replace(/rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/gm, (m) => {
    const [r, g, b] = m.replace(/^rgba?\(|\s+|\)$/g, '').split(',');
    return rGBToHex(r, g, b);
  });
  // @ts-ignore
  updatedText = updatedText.replace(/style="([^"]*)"/gm, (m, x) => getTaggedStyles(x));

  const lastBrTag = /<br>$/gm;
  while (lastBrTag.test(updatedText)) updatedText = updatedText.replace(lastBrTag, '');
  // updatedText = updatedText.replace(/<div><br>/gm, '\n');
  updatedText = updatedText.replace(/<br><\/div>/gm, '</div>');
  updatedText = updatedText.replace(/<br><\/centeralign>/gm, '</centeralign>');
  updatedText = updatedText.replace(/<br><\/rightalign>/gm, '</rightalign>');
  updatedText = updatedText.replace(/<br><\/leftalign>/gm, '</leftalign>');
  updatedText = updatedText.replace(/<br><\/li>/gm, '</li>');
  updatedText = updatedText.replace(/<br>/gm, '\n');
  updatedText = updatedText.replace(/&nbsp;/gm, ' ');
  // updatedText = updatedText.replace(/<ul>(<li>.*<\/li>)+<\/ul>/gm, (x) => {
  //   console.log("unordered updatedText", x);
  //   let newX = x.replace(/<li>.+<\/li>/gm, (y) => {
  //     console.log("unordered updatedText Y", y);
  //     return y;
  //   })
  //   return x;
  // })
  updatedText = updatedText.replace(/<\/li><li>/gm, '\n</li><li>');
  updatedText = updatedText.replace(/<div>/gm, '\n');
  updatedText = updatedText.replace(/<\/div>/gm, '');
  updatedText = updatedText.replace(/<ul>/gm, '\n<ul>');
  updatedText = updatedText.replace(/<ol>/gm, '\n<ol>');
  updatedText = updatedText.replace(/<centeralign>/gm, '\n<centeralign>');
  updatedText = updatedText.replace(/<rightalign>/gm, '\n<rightalign>');
  updatedText = updatedText.replace(/<leftalign>/gm, '\n<leftalign>');
  updatedText = updatedText.replace(/&lt;/gm, '<');
  updatedText = updatedText.replace(/&gt;/gm, '>');
  updatedText = updatedText.replace(/&amp;/gm, '&');
  updatedText = updatedText.replace(/<li>\n<centeralign>/gm, '<li><centeralign>');
  updatedText = updatedText.replace(/<li>\n<rightalign>/gm, '<li><rightalign>');
  updatedText = updatedText.replace(/<li>\n<leftalign>/gm, '<li><leftalign>');
  updatedText = updatedText.replace(/<centeralign>\n<ul>/gm, '<centeralign><ul>');
  updatedText = updatedText.replace(/<rightalign>\n<ul>/gm, '<rightalign><ul>');
  updatedText = updatedText.replace(/<leftalign>\n<ul>/gm, '<leftalign><ul>');
  updatedText = updatedText.replace(/<centeralign>\n<ol>/gm, '<centeralign><ol>');
  updatedText = updatedText.replace(/<rightalign>\n<ol>/gm, '<rightalign><ol>');
  updatedText = updatedText.replace(/<leftalign>\n<ol>/gm, '<leftalign><ol>');
  updatedText = updatedText.replace(/\sstyle="">/gm, '>');
  const fontTags = updatedText.match(/<font ([a-zA-z]*="[^@?"\r\n]*"\s?)*>/gm);
  const updatedTags = fontTags?.map((ft: string) => {
    ft.replace('face', 'fontFamily');
    ft.replace('color', 'fill');
    ft.replace('size', 'fontSize');
    return ft.replace(/face|color|size/gi, (a) => {
      if (a === 'face') return 'fontFamily';
      if (a === 'color') return 'fill';
      if (a === 'size') return 'fontSize';
      return a;
    });
  });
  fontTags?.forEach((ft: string, i: number) => {
    if (updatedTags && updatedTags[i]) {
      updatedText = updatedText.replace(ft, updatedTags[i] as string);
    }
  });
  updatedText = updatedText.slice(0, 1) === '\n' ? updatedText.slice(1) : updatedText;
  return updatedText;
};

export const removeAttributesAtRanges = (trixNode: any, nodeData: INode, attributes: [{ isBlock: boolean, value: string }]) => {
  const trixEditor = trixNode?.editor;
  if (!trixEditor) return;
  trixNode.innerHTML = nodeData.text?.value;
  const trixDoc = trixEditor.getDocument();
  attributes.forEach((a) => {
    const ranges = a.isBlock ? trixDoc?.findRangesForBlockAttribute(a.value) : trixDoc?.findRangesForTextAttribute(a.value);
    if (ranges && ranges.length > 0) {
      ranges.forEach((r: any) => {
        trixEditor.setSelectedRange(r);
        trixEditor.deactivateAttribute(a.value);
      });
    }
    // trixEditor.setSelectedRange([0, trixDoc.getLength() - 1]);
    // trixEditor.deactivateAttribute(a.value);
  });
  trixEditor.setSelectedRange(0);
  nodeData.text!.value = trixNode.innerHTML;
};

export const getAuthorImageTexture = (authorImageURL: string) => {
  if (authorImageURL in PIXI.Loader.shared.resources) {
    const imageTexture = PIXI.Loader.shared.resources[authorImageURL]?.texture;
    if (imageTexture && imageTexture.valid) return imageTexture;
  }
  return null;
};

export const getFontSizeProperties = (value: string) => {
  const allFontSize = value.match(/(?:font-size:\s+).*?(?=px;)/gm);
  return allFontSize?.map((f) => f.replace('font-size: ', ''));
};

export const getFontProperties = (value: string) => {
  const allFontFamily = value.match(/(?:font-family:\s+).*?(?=;)/gm);
  return allFontFamily?.map((f) => f.replace('font-family: ', ''));
};

export const getColorProperties = (value: string) => {
  const allRGBColors = value.match(/(?:color:\s+).*?(?=;)/gm);
  const allHexColors: string[] = [];
  if (allRGBColors) {
    allRGBColors.forEach((c: string) => {
      const rgb = c.replace('color: ', '');
      const [r, g, b] = rgb.replace(/^rgba?\(|\s+|\)$/g, '').split(',');
      allHexColors.push(rGBToHex(r, g, b));
    });
    return allHexColors;
  }
  return null;
};

export const removeAllAnchorTag = (value: string) => {
  let updatedText = value.replace(/<!--block-->/gm, '');
  updatedText = value.replace(/<\/?div[^>]*>/g, '');
  return updatedText.replace(/<\/?a[^>]*>/g, '');
};
