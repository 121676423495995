import { useState, useEffect, useRef, FC } from 'react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import './SwitchBox.scss'

export type SwitchBoxProps = {
    data: string[]
}

/**
 * 
 * @param {string[]} data Array of strings to be displayed in the infobox
 */
const SwitchBox: FC<SwitchBoxProps> = ({ data }) => {
    const [active, setActive] = useState(0)
    const [displayText, setDisplayText] = useState(data[active])
    const timeoutRef = useRef<NodeJS.Timeout | null>(null)

    useEffect(() => {
        setDisplayText(data[active])
    }, [active, data])

    //handles timeout of auto-carousel
    useEffect(() => {
        if (timeoutRef.current !== null)
            clearTimeout(timeoutRef.current)

        timeoutRef.current = setTimeout(() => {
            timeoutRef.current = null
            setActive(prevActive => Math.abs(((prevActive + data.length) + 1) % data.length))
        }, 5000)

        return () => {
            if (timeoutRef.current !== null)
                clearTimeout(timeoutRef.current)
        }
    }, [active, data])
    
    return (
            <SwitchTransition mode='out-in' >
                <CSSTransition
                    key={active}
                    timeout={450}
                    classNames='switch-box__transition'
                >
                    <h1 className='transition-text'>{displayText}</h1>
                </CSSTransition>
            </SwitchTransition>
    )
}


export default SwitchBox