import { IBlock, IFeedback, TAnswer, TUserAndRole } from '@naya_studio/types';
import _ from 'lodash';
import { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { ReduxState } from 'src/redux/reducers/root.types';
import { getAllUsersBasedOnIds } from 'src/redux/reduxActions/util';
import { ReactComponent as AlertIcon } from 'src/assets/icons/sharing-modal/alert.svg';
import { Button, FeedbackWrapper, WarningModal } from '@naya_studio/radix-ui';
import useFeedbackActions from 'src/util/feedback/useFeedbackActions';
import { useParams } from 'react-router';
import { PathParamsType } from 'src/types/pathParams';
import useUser from 'src/redux/hooks/user';
import { ActionItemPanel } from '../actionItemPanel/ActionItemPanel';
import { NewAnswer } from './NewAnswer';
import { Answer } from './Answer';
import { Question } from './Question';

// Props for QNA wrapper
export type QNAWrapperProps = {
	data: IFeedback;
	onClose: () => void;
};

// QNA's parent component rendering question and answer
export const QNAWrapper = ({ data, onClose }: QNAWrapperProps) => {
	const [showResults, setShowResults] = useState(false); // handles the visibility of the results view
	// determines if the question is in edit state
	// we need this because when question is in edit state, answer should not be visible
	const [questionEditState, setQuestionEditState] = useState(false);
	// determines if the answer is in edit state
	// we need this because when we go into edit state from results view,
	// there is no easy way to find out if the answer is in edit state
	const [answerEditState, setAnswerEditState] = useState(false);
	const [isCompleted, setIsCompleted] = useState(false); // handles the toggle of mark complete action
	const [selectedUsers, setSelectedUsers] = useState<string[]>([]); // holds the list of all the users selected for action item assignees
	// handles the visibility of action item toggle warning
	const [showActionItemWarning, setShowActionItemWarning] = useState(false);

	// extracting ids from url
	const { canvasId: blockId, projectId } = useParams<PathParamsType>();

	// extract current block
	const block = useSelector(
		(state) => (state as ReduxState).blocks.data[blockId],
		shallowEqual
	) as IBlock;

	// extract current user
	const { user } = useUser();

	// extract all the user ids in the block
	const userIds: string[] = (block.users as TUserAndRole[]).map(
		(u: TUserAndRole) => u.user as string
	);

	// extract all the populated users based on ids
	const allUsers = getAllUsersBasedOnIds(userIds);

	const dispatch = useDispatch();

	// extarcting functions from feedback actions hook
	const {
		sendEditFeedback,
		checkIfUserTurnerOffActionItemToggleWarning,
		setNoActionItemToggleWarning,
		removeActionItemAssignee,
		addActionItemAssignee
	} = useFeedbackActions();

	// setting initial data
	useEffect(() => {
		if (data.actionItemFor) {
			if (!_.isUndefined(data.actionItemFor[user._id as string])) {
				setIsCompleted(data.actionItemFor[user._id as string] as boolean);
			}

			const allActionItemUsers = Object.keys(data.actionItemFor);
			setSelectedUsers(allActionItemUsers);
		}
	}, [data]);

	// sends out an API call to mark complete
	const onMarkComplete = (val: boolean) => {
		setIsCompleted(val);

		dispatch({
			type: 'MARK_FB_NOTIF_COMP',
			payload: { markFeedbackAsComplete: data._id, isCompleted: val }
		});
	};

	// sebd out an api call to save the action item value
	const saveActionItemToggle = (val: boolean, noWarningCheck: boolean) => {
		// setIsActionItem(val);

		// closing the modal if open
		if (showActionItemWarning) {
			setShowActionItemWarning(false);
		}

		sendEditFeedback({
			_id: data._id,
			isActionItem: val
		});

		if (noWarningCheck) {
			setNoActionItemToggleWarning(projectId);
		}
	};

	// sends out an api call to toggle action item
	const toggleActionItem = (val: boolean) => {
		if (!val && !checkIfUserTurnerOffActionItemToggleWarning(projectId)) {
			setShowActionItemWarning(true);
		} else {
			saveActionItemToggle(val, false);
		}
	};

	// Sends out an api call updated selected users for action item
	const onModifySelectedUsers = (id: string) => {
		let tempUsers = selectedUsers;
		if (selectedUsers.includes(id)) {
			tempUsers = tempUsers.filter((x) => x !== id);

			removeActionItemAssignee(data._id as string, id);
		} else {
			tempUsers.push(id);

			addActionItemAssignee(data._id as string, id);
		}
		setSelectedUsers(tempUsers);
	};

	// extracts the answer answered by current user
	const getCurrentUserAnswer = () => {
		const { answers } = data;
		if (answers) {
			for (let i = 0; i < answers.length; i++) {
				if (answers[i] && answers[i]?.answeredBy && answers[i]?.answeredBy === user._id) {
					return answers[i] as TAnswer;
				}
			}
		}
		return undefined;
	};

	//  renders results view
	const renderResultsView = () => (
		<>
			<Question
				data={data}
				setParentEditState={() => {
					setQuestionEditState(true);
					setShowResults(false);
				}}
				isEditState={questionEditState}
				actionItemAssignees={selectedUsers}
				setActionItemAsignees={setSelectedUsers}
			/>
			{data.answers &&
				data.answers.map((ans: TAnswer) => (
					<Answer
						answer={ans}
						setParentEditState={setAnswerEditState}
						actionItemAssignees={selectedUsers}
						setActionItemAsignees={setSelectedUsers}
						allAnswers={data.answers as TAnswer[]}
						feedbackId={data._id as string}
						key={ans._id}
					/>
				))}
			{!getCurrentUserAnswer() && (
				<div>
					<Button
						variant="SECONDARY"
						text="Add your answer"
						disabled={false}
						onBtnClick={() => setShowResults(false)}
					/>
				</div>
			)}
		</>
	);

	// renders the QNA view
	const renderQNAView = () => (
		// normal view
		<>
			{/* QUESTION */}
			<Question
				data={data}
				setParentEditState={setQuestionEditState}
				isEditState={questionEditState}
				actionItemAssignees={selectedUsers}
				setActionItemAsignees={setSelectedUsers}
			/>
			{getCurrentUserAnswer() ? (
				// if current user has answered, show answer with a button to view results
				<>
					<Answer
						answer={getCurrentUserAnswer() as TAnswer}
						setParentEditState={setAnswerEditState}
						actionItemAssignees={selectedUsers}
						setActionItemAsignees={setSelectedUsers}
						allAnswers={data.answers as TAnswer[]}
						feedbackId={data._id as string}
						key={getCurrentUserAnswer()?._id}
					/>
					<Button
						variant="SECONDARY"
						text="View results"
						disabled={false}
						onBtnClick={() => setShowResults(true)}
					/>
				</>
			) : (
				<div className="tw-pr-2">
					{!questionEditState && (
						// else, allow user to add answer
						<NewAnswer
							setShowResults={setShowResults}
							actionItemAssignees={selectedUsers}
							setActionItemAsignees={setSelectedUsers}
							data={data}
							answers={data.answers as TAnswer[]}
						/>
					)}
				</div>
			)}
		</>
	);

	return (
		<FeedbackWrapper className="tw-bg-white">
			<>
				<WarningModal
					show={showActionItemWarning}
					onClose={() => setShowActionItemWarning(false)}
					onModalSubmit={(modalData: { isDaaChecked: boolean }) => {
						saveActionItemToggle(!toggleActionItem, modalData.isDaaChecked);
					}}
					title="Turn off action item?"
					bodyText={
						<div className="warning-model">
							{' '}
							<AlertIcon />{' '}
							<span>
								The following task will be removed as an action item for all the
								users in this project?. Continue?
							</span>
						</div>
					}
					cancelBtnText={"Don't remove"}
					submitBtnText="Turn off action item"
					showDAA
				/>
				{/* render action item panel */}
				<ActionItemPanel
					markCompleteDisabled={questionEditState || answerEditState}
					isCompleted={isCompleted}
					onMarkComplete={onMarkComplete}
					users={allUsers}
					selectedUsers={selectedUsers}
					onModifySelectedUsers={onModifySelectedUsers}
					key={data._id as string}
					onClose={onClose}
				/>
				<div className="tw-px-2 tw-pb-2 tw-pt-4">
					{
						// results view
						showResults ? renderResultsView() : renderQNAView()
					}
				</div>
			</>
		</FeedbackWrapper>
	);
};
