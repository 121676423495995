import { IUser, TUserAndRole, IBlock } from '@naya_studio/types';
import moment from 'moment';
import { checkUserAccessLevel, getCurrentBlock } from 'src/util/accessLevel/accessLevelActions';

// eslint-disable-next-line import/prefer-default-export
export const getCommentText = (commentText: string) => {
	const hasNewLine =
		commentText.substring(commentText.length - 11, commentText.length) === '<p><br></p>';
	return hasNewLine ? commentText.substring(0, commentText.length - 11) : commentText;
};

export const checkIfaDayOld = (input: any) => {
	const yesterday = moment().subtract(1, 'd');
	return moment(input).isBefore(yesterday);
};

/**
 * Retrieves the user's name from the provided IUser object
 * @param user The IUser object from which to extract the user's name.
 * @returns The user's name as a string.
 */
export const getUserName = (user: IUser) => {
	const { userName, firstName } = user;
	return firstName || userName || '';
};

/**
 * Function to check if user has editor access or not
 * @param user active user
 * @returns boolean
 */
export const checkIfUserHasEditAccess = (user: IUser) => {
	const block = getCurrentBlock() as IBlock;
	if (user.userType?.includes('ADMIN')) return true;

	if (
		checkUserAccessLevel(block?.users as TUserAndRole[], user._id as string, [
			'EDITOR',
			'OWNER',
			'COMMENTER'
		])
	) {
		return true;
	}

	return false;
};

export const filterTextNodeStyles = (htmlNode: any) => {
	const styleSheet = htmlNode.style;
	const validStyles = ['font-size', 'color', 'font-style', 'text-decoration', 'font-weight'];
	if (styleSheet) {
		for (let i = styleSheet.length; i--; ) {
			const nameString = styleSheet[i];
			if (!validStyles.includes(nameString)) {
				styleSheet.removeProperty(nameString);
			} else if (nameString === 'font-weight' && styleSheet['font-weight'] >= 700) {
				styleSheet['font-weight'] = 'bold';
			} else if (nameString === 'font-size') {
				const fontSize = parseInt(styleSheet['font-size'], 10);
				if (fontSize) {
					const zoomLevel = window.pixiApp?.viewport.scale._x;
					styleSheet['font-size'] = `${zoomLevel ? fontSize * zoomLevel : fontSize}px`;
				} else {
					styleSheet.removeProperty(nameString);
				}
			}
		}
	}
	const pasteNodeChildren = htmlNode.childNodes;
	if (pasteNodeChildren && pasteNodeChildren.length > 0) {
		pasteNodeChildren.forEach((pn: any) => filterTextNodeStyles(pn));
	}
};

/**
 * Function to convert any type into base64
 * @param data data that needs to convert to to base64
 * @returns Base64 string
 */
export const convertToBase64 = (data: any) => {
	if (data) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(data);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	}
	return false;
};

/**
 *
 * @param modelName Name of the 3d model
 * @param modelSrc Src of the 3d model
 * @param extension Extension of the 3d model
 * @returns fileName: string, file: Blob, url: string, extension: string
 */
export const getFileInfoFromCache = async (
	modelName: string,
	modelSrc: string,
	extension: string
) => {
	const appCache = await caches.open('naya-app');
	let fileName: string = modelName;
	let matchedResponse;
	if (modelName) matchedResponse = await appCache.match(modelName);
	if (!matchedResponse) {
		if (modelSrc) {
			matchedResponse = await appCache.match(modelSrc);
		} else {
			return 'Invalid Model';
		}
	}
	const ext = modelName.split('.').pop();
	if (ext && !['obj', 'stl'].includes(ext)) {
		fileName = `${modelName}.${extension}`;
	}
	const blob = await matchedResponse?.blob();
	if (blob) {
		const url = URL.createObjectURL(blob);
		return {
			file: blob,
			fileName,
			url,
			extension
		};
	}
	return false;
};
