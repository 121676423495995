import { TUserAndRole, TInvitedEmail, IFeedback, IUser } from '@naya_studio/types';
import * as Y from 'yjs';
import { TChangeType, getWebSocket, getYDoc } from 'src/rtc/yjs/yjsConfig';
import { useDispatch } from 'react-redux';
import { editFeedback, addFeedback, deleteFeedbackById } from 'src/redux/features/feedbacks';
import { store } from 'src';
import { generateIdsFromUrl } from 'src/redux/reduxActions/util';
import getUserFromRedux from 'src/util/helper/user';
import {
	broadcastSelfDispatch,
	checkIfUserHasAccess,
	getBlockFromYDoc,
	getProjectFromYDocOrRedux
} from './utils';
import { ISocketUser } from '../useSocketUsers';

/**
 * Hook to update feedback in redux after yjs listens to feedback change
 */
const useFeedbackObserver = () => {
	const dispatch = useDispatch();

	/**
	 * Function to return complete from user id
	 * @param userId id of the user to find
	 * @returns IUser
	 */
	const findUserFromProjectUsers = (userId: string): IUser | string => {
		const { projectUsers, aiUser } = store.getState();
		let user = projectUsers.data.find((projectUser) => (projectUser._id as string) === userId);

		if (!user) {
			// If user does not exist in project users check socket users
			// In case the feedback was added by guest user
			const webSocket = getWebSocket();
			webSocket.awareness.getStates().forEach((awareness) => {
				const socketUser = awareness as ISocketUser;
				if (socketUser.userId === userId) user = socketUser;
			});
		}
		if (!user && aiUser.data._id === userId) {
			// If feedback was not created by one of the project users, and neither by a guest
			// check if it was created by AI user
			user = aiUser.data;
		}

		return user || userId;
	};

	/**
	 * Observer to listen to feedback changes in ymap
	 * @param update feedback change to do in redux
	 */
	const feedbackObserver = (update: Y.YMapEvent<string>) => {
		try {
			const user = getUserFromRedux();
			const doc = getYDoc();
			const { projectId: projectIdFromUrl } = generateIdsFromUrl();

			update.changes.keys.forEach((change: TChangeType<string>, key: string) => {
				if (change.action === 'update') {
					const feedbackAfterUpdate = JSON.parse(
						doc.getMap('feedbacks').get(key) as string
					) as IFeedback;
					// console.info('RTC - Received feedback update', feedbackAfterUpdate);
					const selfDispatch = feedbackAfterUpdate.lastUpdatedBy === (user._id as string);

					const reduxFeedback = store.getState().feedbacks.data[key] as IFeedback;
					if (typeof reduxFeedback.createdBy === 'string') {
						// createdby will be overwritten by id since it would be an id in ymap feedback
						feedbackAfterUpdate.createdBy = findUserFromProjectUsers(
							feedbackAfterUpdate.createdBy as string
						);
					}
					// if created by is already populate in redux delete the createdBy field to prevent override
					else delete feedbackAfterUpdate.createdBy;

					if (feedbackAfterUpdate.replies) {
						// Populate user for replies as well
						// only filter the populated replies from the all replies
						const feedbackReplies = feedbackAfterUpdate.replies;
						feedbackAfterUpdate.replies = [];
						feedbackReplies.forEach((reply) => {
							const replyBy = (reply as IFeedback)?.createdBy;
							if (replyBy)
								feedbackAfterUpdate.replies!.push({
									...(reply as IFeedback),
									createdBy: findUserFromProjectUsers(replyBy as string)
								});
						});
					}

					if (selfDispatch) {
						broadcastSelfDispatch(feedbackAfterUpdate, 'EDIT', 'FEEDBACK');
						return;
					}

					// If block is not present in ydoc take the redux block
					// this case might happen when we update note after server is just started
					// and block is not in ydoc
					const block = getBlockFromYDoc(feedbackAfterUpdate.blockId as string);
					let hasAccess = true;

					const projectId = feedbackAfterUpdate.projectId || projectIdFromUrl;
					const project = getProjectFromYDocOrRedux(projectId);
					const hasGuestAccess = block.hasGuestAccess || project?.hasGuestAccess || false;

					if (block?.users || block?.invitedEmails) {
						hasAccess =
							checkIfUserHasAccess(
								user,
								block.users as TUserAndRole[],
								block.invitedEmails as TInvitedEmail[]
							) || hasGuestAccess;
					}

					if (hasAccess) dispatch(editFeedback(feedbackAfterUpdate));
				} else if (change.action === 'add') {
					const feedbackToAdd = JSON.parse(
						doc.getMap('feedbacks').get(key) as string
					) as IFeedback;
					// console.info('RTC - Received feedback add', feedbackToAdd);
					const blockId = feedbackToAdd.blockId as string;
					const block = getBlockFromYDoc(blockId);
					const selfDispatch = feedbackToAdd.lastUpdatedBy === (user._id as string);

					const projectId = feedbackToAdd.projectId || projectIdFromUrl;
					const project = getProjectFromYDocOrRedux(projectId);
					const hasGuestAccess = block.hasGuestAccess || project?.hasGuestAccess || false;

					// createdby will be overwritten by id since it would be an id in ymap feedback
					feedbackToAdd.createdBy = findUserFromProjectUsers(
						feedbackToAdd.createdBy as string
					);

					if (feedbackToAdd.replies) {
						// Populate user for replies as well
						// only filter the populated replies from the all replies
						const feedbackReplies = feedbackToAdd.replies;
						feedbackToAdd.replies = [];
						feedbackReplies.forEach((reply) => {
							const replyBy = (reply as IFeedback)?.createdBy;
							if (replyBy)
								feedbackToAdd.replies!.push({
									...(reply as IFeedback),
									createdBy: findUserFromProjectUsers(replyBy as string)
								});
						});
					}

					if (selfDispatch) {
						broadcastSelfDispatch(feedbackToAdd, 'ADD', 'FEEDBACK');
						return;
					}

					let hasAccess = true;

					if (block?.users || block?.invitedEmails) {
						hasAccess =
							checkIfUserHasAccess(
								user,
								block.users as TUserAndRole[],
								block.invitedEmails as TInvitedEmail[]
							) || hasGuestAccess;
					}

					// Only load the block if user has access to it or it is originated from somewhere else
					if (hasAccess || feedbackToAdd.origin !== 'NAYA')
						dispatch(addFeedback(feedbackToAdd));
				} else if (change.action === 'delete') {
					// console.info('RTC - Received feedback delete', key);
					const feedbackBeforeDelete = JSON.parse(change.oldValue) as IFeedback;
					const blockId = feedbackBeforeDelete.blockId as string;
					const block = getBlockFromYDoc(blockId);
					const selfDispatch =
						feedbackBeforeDelete.lastUpdatedBy === (user._id as string);
					if (selfDispatch) {
						broadcastSelfDispatch(key, 'DELETE', 'FEEDBACK');
						return;
					}

					const projectId = feedbackBeforeDelete.projectId || projectIdFromUrl;
					const project = getProjectFromYDocOrRedux(projectId);
					const hasGuestAccess = block.hasGuestAccess || project?.hasGuestAccess || false;

					let hasAccess = true;

					if (block?.users || block?.invitedEmails) {
						hasAccess =
							checkIfUserHasAccess(
								user,
								block.users as TUserAndRole[],
								block.invitedEmails as TInvitedEmail[]
							) || hasGuestAccess;
					}

					// Only delete the node if user has access to it's parent block
					if (hasAccess) dispatch(deleteFeedbackById(key));
				}
			});
		} catch (error) {
			console.error('Error in Node Listener : ', error);
		}
	};

	return feedbackObserver;
};

export default useFeedbackObserver;
