import facebook from 'src/assets/icons/social/facebook.svg'
import instagram from 'src/assets/icons/social/instagram.svg'
import linkedin from 'src/assets/icons/social/linkedin.svg'
import medium from 'src/assets/icons/social/medium.svg'
import twitter from 'src/assets/icons/social/twitter.svg'



const footer = {
  account: {
    header: 'Account',
    // text1: 'My Account',
    text1: 'Login',
    text2: ''
  },
  company: {
    header: 'Company',
    text1: 'Privacy Policy',
    text2: 'Terms and Conditions',
    text3: 'info@naya.studio'
  },
  learn: {
    header: 'Learn More',
    text1: 'Schedule a Call',
    text2: 'Email Us'
  },
  connect: {
    header: 'Connect',
    social: [
      {
        src: instagram,
        alt: 'instagram social-icon',
        href: 'https://www.instagram.com/naya__studio/?hl=en'
      },
      {
        src: facebook,
        alt: 'facebook social-icon',
        href: 'https://www.facebook.com/Naya-Studio-538392739983745/'
      },
      {
        src: linkedin,
        alt: 'linkedin social-icon',
        href: 'https://www.linkedin.com/company/studio-naya/'
      },
      {
        src: twitter,
        alt: 'twitter social-icon',
        href: 'https://twitter.com/naya_app'
      },
      {
        src: medium,
        alt: 'medium social-icon',
        href: 'https://medium.com/naya-studio'
      },
    ],
  },
  newsletter: {
    header: 'Newsletter',
    text1: 'Subscribe to our newsletter to receive updates, articles, and more.',
    input1: 'Enter your email',
    button1: 'Subscribe'
  }
}

export default footer