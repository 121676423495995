import { FC } from 'react';
import './DeleteModal.scss';
import { ControlModal } from '@naya_studio/radix-ui';
import { ReactComponent as CloseModal } from 'src/assets/icons/close.svg';
import { DeleteModalProps } from './DeleteModal.types';

/**
 * Component to render Delete Modal
 */
const DeleteModal: FC<DeleteModalProps> = ({ show, close, projectName, onDelete }) => (
	<ControlModal open={show}>
		<ControlModal.ContentWrap id="delete-modal" close={() => close()} width={500}>
			<ControlModal.Content>
				<div className="tw-px-4 tw-pb-4 tw-pt-8">
					<p className="tw-mb-0 tw-font-randMedium tw-text-lg tw-font-semibold">
						Delete Project &quot;{projectName}&quot;
					</p>
					<CloseModal
						width={14}
						height={14}
						className="tw-absolute tw-cursor-pointer"
						onClick={() => close()}
					/>
				</div>
				<div className="modal-body">
					<p className="tw-mb-0 tw-font-randRegular tw-text-sm">
						Are you sure you want to delete
						<strong> &quot;{projectName}&quot;</strong> project? This will permanently
						delete all groups and blocks connected with this project.
					</p>
				</div>
				<div className="tw-flex tw-flex-1 tw-justify-end tw-items-center tw-gap-2 tw-p-4">
					<button
						type="button"
						className="tw-px-3 tw-py-2 tw-rounded-lg"
						onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
							e.stopPropagation();
							close();
						}}
					>
						Cancel
					</button>
					<button
						type="button"
						className="tw-px-3 tw-py-2 danger-btn tw-text-white tw-rounded-lg"
						onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
							e.stopPropagation();
							onDelete();
							close();
						}}
						data-testid="delete-project-btn"
					>
						Delete
					</button>
				</div>
			</ControlModal.Content>
		</ControlModal.ContentWrap>
	</ControlModal>
);

export default DeleteModal;
