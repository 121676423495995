import {
	IBlock,
	IExaclidrawCanvas,
	IFeedbackBlock,
	IGroup,
	ILink,
	INode,
	INodesBlock,
	INote,
	IProject,
	ITodo,
	TUserAndRole
} from '@naya_studio/types';
import getUserFromRedux from 'src/util/helper/user';
import { v1 as uuidv1 } from 'uuid';
import mongoose from 'mongoose';
import { duplicateFileContent } from '@naya_studio/excalidraw-viewer';
import { store } from 'src';
import {
	fetchBlockByIdFromRedux,
	fetchNodeByIdFromRedux,
	fetchNoteByIdFromRedux,
	findStageWithStageId
} from './util';
import { generateOptimisticBlock, generateOptimisticStage } from '../reduxActions/util';

/**
 * Clones a node by its ID and assigns new metadata to the cloned node.
 *
 * @param nodeId - The ID of the node to be cloned.
 * @param newMetadata - Metadata object containing the `newParentId` and `newProjectId` for the cloned node.
 * @returns The cloned node object with a new `_id`, `createdBy`, `blockId`, and `projectId`, or `null` in case of an error.
 *
 * @example
 * const clonedNode = onDuplicateNode('12345', {
 *   newParentId: 'parent-5678',
 *   newProjectId: 'project-7890'
 * });
 * if (clonedNode) {
 *   console.log('Node cloned successfully', clonedNode);
 * } else {
 *   console.log('Failed to clone node');
 * }
 */
export const onDuplicateNode = (
	nodeId: string,
	newMetadata: {
		newParentId: string;
		newProjectId: string;
	}
): INode | null => {
	try {
		// Get the current user ID from the state management (e.g., Redux)
		const { _id: currentUserId } = getUserFromRedux();

		// Fetch the original node by its ID from the state
		const originalNode = fetchNodeByIdFromRedux(nodeId) as INode;

		// Destructure the new metadata fields
		const { newParentId, newProjectId } = newMetadata;

		// Clone the original node and update the necessary fields with new metadata
		const clonedNode: INode = {
			...originalNode,
			_id: uuidv1(), // Generate a new unique ID for the cloned node
			createdBy: currentUserId as string, // Set the creator as the current user
			lastUpdatedBy: currentUserId as string, // Set the last updated user as the current user
			version: 0, // Reset the version to 0 for the new node
			blockId: newParentId, // Assign the new parent ID to the blockId field
			projectId: newProjectId // Assign the new project ID
		};

		// Return the cloned node object
		return clonedNode;
	} catch (error) {
		// Log any errors encountered during the cloning process
		console.error('Failed to clone node: ', error);
		return null;
	}
};

/**
 * Clones a note by its ID and assigns new metadata to the cloned note.
 *
 * @param noteId - The ID of the note to be cloned.
 * @param newMetadata - Metadata object containing the new `parent` information (ID and type) and `newProjectId`.
 * @returns The cloned note object with new `_id`, `createdBy`, `parentId`, `parentType`, `projectId`, or `null` in case of an error.
 *
 * @example
 * const clonedNote = onDuplicateNote('note-12345', {
 *   newParent: { id: 'parent-5678', type: 'BLOCK' },
 *   newProjectId: 'project-7890'
 * });
 * if (clonedNote) {
 *   console.log('Note cloned successfully', clonedNote);
 * } else {
 *   console.log('Failed to clone note');
 * }
 */
export const onDuplicateNote = (
	noteId: string,
	newMetadata: {
		newParent: {
			id: string;
			type: 'BLOCK' | 'GROUP';
		};
		newProjectId: string;
	}
): INote | null => {
	try {
		// Retrieve the current user ID from the state management (e.g., Redux)
		const { _id: currentUserId } = getUserFromRedux();

		// Fetch the original note by its ID from the state
		const originalNote = fetchNoteByIdFromRedux(noteId) as INote;

		// Destructure the new metadata fields
		const { newParent, newProjectId } = newMetadata;
		const { id: newParentId, type: newParentType } = newParent;

		// Clone the original note and assign new metadata
		const clonedNote: INote = {
			...originalNote,
			_id: new mongoose.Types.ObjectId().toString() as string, // Generate a new unique ID for the cloned note
			createdBy: currentUserId as string, // Set the creator as the current user
			parentId: newParentId, // Assign the new parent ID
			parentType: newParentType, // Assign the new parent type
			projectId: newProjectId, // Assign the new project ID
			savedNoteReference: null, // Reset saved note reference for the cloned note
			taggedUsers: [] // Clear tagged users for the cloned note
		};

		// Return the cloned note object
		return clonedNote;
	} catch (error) {
		// Log any errors encountered during the cloning process
		console.error('Failed to clone note: ', error);
		return null;
	}
};

/**
 * Asynchronously clones a block by its ID and optionally clones its associated nodes and notes.
 *
 * @param blockId - The ID of the block to be cloned.
 * @param newMetadata - Metadata object containing the `newName`, `newParentId`, `newProjectId`, `newUsers`
 * and options to clone nodes and notes.
 * @returns A promise resolving to an object containing the cloned block, cloned nodes, and cloned notes, or `null` in case of an error.
 *
 * @example
 * const result = await onDuplicateBlock('block-12345', {
 *   newName: 'Cloned Block',
 *   newParentId: 'parent-5678',
 *   newProjectId: 'project-7890',
 *   shouldCloneNodes: true,
 *   shouldCloneNotes: true
 * });
 *
 * if (result) {
 *   const { clonedBlock, clonedNodes, clonedNotes } = result;
 *   console.log('Block cloned successfully', clonedBlock, clonedNodes, clonedNotes);
 * } else {
 *   console.log('Failed to clone block');
 * }
 */
export const onDuplicateBlock = async (
	blockId: string,
	newMetadata: {
		newName: string;
		newParentId: string;
		newProjectId: string;
		newUsers?: Array<TUserAndRole>;
		shouldCloneNodes?: boolean;
		shouldCloneNotes?: boolean;
	}
): Promise<{ clonedBlock: IBlock; clonedNodes: INode[]; clonedNotes: INote[] } | null> => {
	try {
		const clonedNodes: INode[] = [];
		const clonedNotes: INote[] = [];

		// Retrieve the current user ID from the state management (e.g., Redux)
		const { _id: currentUserId } = getUserFromRedux();

		// Fetch the original block by its ID from the state
		const originalBlock = fetchBlockByIdFromRedux(blockId) as IBlock;
		const { blockType } = originalBlock;

		// Destructure the new metadata fields
		const {
			newParentId: parentId,
			newProjectId: projectId,
			newName: name,
			newUsers: users,
			shouldCloneNodes = true,
			shouldCloneNotes = true
		} = newMetadata;

		// Create a new list of users for the cloned block with the current user as the owner
		const newBlockUsers: TUserAndRole[] = users ?? [
			{
				user: currentUserId as string,
				role: 'OWNER'
			}
		];

		// Generate a new unique ID for the cloned block
		const newBlockId = new mongoose.Types.ObjectId().toString();

		// Clone the original block and update the necessary fields with new metadata
		const clonedBlock: IBlock = {
			...originalBlock,
			_id: newBlockId,
			name,
			dates: null, // Reset dates for the new block
			deliverableStatus: null, // Reset deliverable status
			createdBy: currentUserId as string, // Set the creator as the current user
			lastUpdatedBy: currentUserId as string, // Set the last updated user as the current user
			parentId,
			projectId,
			hasGuestAccess: false, // Disable guest access by default
			likedBy: [], // Clear likedBy list
			googleResourceId: undefined, // Reset Google resource ID
			googleResourcePath: undefined, // Reset Google resource path
			aiIngestStatus: undefined, // Reset AI ingest status
			aiDigest: undefined, // Reset AI digest
			users: newBlockUsers // Set the user list to the newBlockUsers array
		};

		// Handle specific block types with unique properties
		if (blockType === 'LINK') {
			(clonedBlock as ILink).addedBy = currentUserId as string;
		} else if (blockType === 'TODO') {
			(clonedBlock as ITodo).todos = (originalBlock as ITodo).todos.map((todo) => ({
				...todo,
				_id: new mongoose.Types.ObjectId().toString() // Generate new IDs for each todo item
			}));
		} else if (blockType === 'EXCALIDRAW_CANVAS') {
			(clonedBlock as IExaclidrawCanvas).link = await duplicateFileContent(
				originalBlock.projectId,
				blockId,
				projectId,
				newBlockId
			);
		}

		// Initialize empty arrays for notes and nodes
		clonedBlock.notes = [];
		(clonedBlock as INodesBlock).nodes = [];
		(clonedBlock as IFeedbackBlock).feedbacks = [];

		// Clone nodes if required
		if (shouldCloneNodes && (originalBlock as INodesBlock)?.nodes?.length) {
			const existingNodes = (originalBlock as INodesBlock).nodes;
			for (let i = 0; i < existingNodes.length; i++) {
				const nodeId = existingNodes[i] as string;
				const clonedNode = onDuplicateNode(nodeId, {
					newParentId: newBlockId,
					newProjectId: projectId
				});

				if (clonedNode) {
					clonedNodes.push(clonedNode);
					(clonedBlock as INodesBlock).nodes.push(clonedNode._id as string);
				}
			}
		}

		// Clone notes if required
		if (shouldCloneNotes && originalBlock?.notes?.length) {
			const existingNotes = originalBlock.notes;
			for (let i = 0; i < existingNotes.length; i++) {
				const noteId = existingNotes[i] as string;
				const clonedNote = onDuplicateNote(noteId, {
					newParent: {
						id: newBlockId,
						type: 'BLOCK'
					},
					newProjectId: projectId
				});

				if (clonedNote) {
					clonedNotes.push(clonedNote);
					clonedBlock.notes.push(clonedNote._id as string);
				}
			}
		}

		// Return the cloned block along with any cloned nodes and notes
		return { clonedBlock, clonedNotes, clonedNodes };
	} catch (error) {
		// Log any errors encountered during the cloning process
		console.error('Failed to clone block: ', error);
		return null;
	}
};

/**
 * Asynchronously clones a group by its ID and optionally clones its associated notes, blocks, and nested groups.
 *
 * @param groupId - The ID of the group to be cloned.
 * @param newMetadata - Metadata object containing the `newName`, `newProjectId`, `newParentId`, `newUsers`
 * and options to clone self notes and child notes.
 * @returns A promise resolving to an object containing the cloned group,
 * cloned blocks, notes, nodes, and nested groups, or `null` in case of an error.
 *
 * @example
 * const result = await onDuplicateGroup('group-12345', {
 *   newName: 'Cloned Group',
 *   newParentId: 'parent-8524',
 *   newProjectId: 'project-7890',
 *   shouldCloneSelfNotes: true,
 *   shouldCloneChildrenNotes: true
 * });
 *
 * if (result) {
 *   const { clonedGroup, clonedBlocks, clonedNodes, clonedNotes, clonedNestedGroups } = result;
 *   console.log('Group cloned successfully', clonedGroup, clonedBlocks, clonedNodes, clonedNotes, clonedNestedGroups);
 * } else {
 *   console.log('Failed to clone group');
 * }
 */
export const onDuplicateGroup = async (
	groupId: string,
	newMetadata: {
		newName: string;
		newParentId: string;
		newProjectId: string;
		newUsers?: Array<TUserAndRole>;
		shouldCloneSelfNotes?: boolean;
		shouldCloneChildrenNotes?: boolean;
	}
): Promise<{
	clonedGroup: IGroup;
	clonedBlocks: IBlock[];
	clonedNotes: INote[];
	clonedNodes: INode[];
	clonedNestedGroups: IGroup[];
} | null> => {
	try {
		const clonedNestedGroups: IGroup[] = [];
		const clonedBlocks: IBlock[] = [];
		const clonedNotes: INote[] = [];
		const clonedNodes: INode[] = [];

		// Retrieve the current user ID from the state management (e.g., Redux)
		const { _id: currentUserId } = getUserFromRedux();

		// Fetch the original group by its ID from the state
		const originalGroup = findStageWithStageId(groupId) as IGroup;

		// Destructure the new metadata fields
		const {
			newParentId: parentId,
			newProjectId: projectId,
			newName: name,
			newUsers: users,
			shouldCloneSelfNotes = true,
			shouldCloneChildrenNotes = true
		} = newMetadata;

		// Create a new list of users for the cloned group with the current user as the owner
		const newGroupUsers: TUserAndRole[] = users ?? [
			{
				user: currentUserId as string,
				role: 'OWNER'
			}
		];

		// Generate a new unique ID for the cloned group
		const newGroupId = new mongoose.Types.ObjectId().toString();

		// Clone the original group and update the necessary fields with new metadata
		const clonedGroup: IGroup = {
			...originalGroup,
			_id: newGroupId,
			name,
			dates: null, // Reset dates for the new group
			deliverableStatus: null, // Reset deliverable status
			createdBy: currentUserId as string, // Set the creator as the current user
			lastUpdatedBy: currentUserId as string, // Set the last updated user as the current user
			parentId,
			projectId,
			hasCompleteAccess: true, // Enable complete access
			users: newGroupUsers // Set the user list to the newGroupUsers array
		};

		// Initialize empty arrays for notes, blocks, nodes, and nested groups
		clonedGroup.notes = [];
		clonedGroup.children = [];

		// Clone notes if required
		if (shouldCloneSelfNotes && originalGroup?.notes?.length) {
			const existingNotes = originalGroup.notes;
			for (let i = 0; i < existingNotes.length; i++) {
				const noteId = existingNotes[i] as string;
				const clonedNote = onDuplicateNote(noteId, {
					newParent: {
						id: newGroupId,
						type: 'GROUP'
					},
					newProjectId: projectId
				});

				if (clonedNote) {
					clonedNotes.push(clonedNote);
					clonedGroup.notes.push(clonedNote._id as string);
				}
			}
		}

		// Clone children (blocks or nested groups) if present
		if (originalGroup?.children?.length) {
			const existingChildren = originalGroup.children;
			for (let i = 0; i < existingChildren.length; i++) {
				const childId = existingChildren[i] as string;
				let originalChild: IBlock | IGroup = fetchBlockByIdFromRedux(childId) as IBlock;

				if (originalChild) {
					// eslint-disable-next-line no-await-in-loop
					const response = await onDuplicateBlock(childId, {
						newName: originalChild.name,
						newParentId: newGroupId,
						newProjectId: projectId,
						newUsers: newGroupUsers,
						shouldCloneNotes: shouldCloneChildrenNotes
					});

					if (response) {
						const {
							clonedBlock,
							clonedNodes: duplicatedNodes,
							clonedNotes: duplicatedNotes
						} = response;

						clonedBlocks.push(clonedBlock);
						clonedNodes.push(...duplicatedNodes);
						clonedNotes.push(...duplicatedNotes);
						clonedGroup.children.push(clonedBlock._id as string);
					}
				} else {
					originalChild = findStageWithStageId(childId) as IGroup;

					// eslint-disable-next-line no-await-in-loop
					const response = await onDuplicateGroup(childId, {
						newName: originalChild?.name ?? 'Untitled',
						newParentId: newGroupId,
						newProjectId: projectId,
						newUsers: newGroupUsers,
						shouldCloneSelfNotes: shouldCloneChildrenNotes,
						shouldCloneChildrenNotes
					});

					if (response) {
						const {
							clonedGroup: nestedClonedGroup,
							clonedBlocks: duplicatedBlocks,
							clonedNodes: duplicatedNodes,
							clonedNotes: duplicatedNotes
						} = response;

						clonedBlocks.push(...duplicatedBlocks);
						clonedNodes.push(...duplicatedNodes);
						clonedNotes.push(...duplicatedNotes);
						clonedNestedGroups.push(nestedClonedGroup);

						clonedGroup.children.push(nestedClonedGroup._id as string);
					}
				}
			}
		}

		// Return the cloned group along with any cloned blocks, nodes, notes, and nested groups
		return { clonedGroup, clonedBlocks, clonedNotes, clonedNodes, clonedNestedGroups };
	} catch (error) {
		// Log any errors encountered during the cloning process
		console.error('Failed to clone group: ', error);
		return null;
	}
};

/**
 * Asynchronously duplicates a project by its ID and optionally clones its associated assets, blocks, and nested groups.
 *
 * @param projectId - The ID of the project to be duplicated.
 * @param metadata - Metadata object containing the `parentGroupId`, `isTemplate`, `newName`,
 * and options to clone assets.
 * @returns A promise resolving to an object containing the duplicated project,
 * cloned blocks, notes, nodes, and groups, or `null` in case of an error.
 *
 * @example
 * const result = await onDuplicateProject('project-12345', {
 *   parentGroupId: 'group-67890',
 *   isTemplate: false,
 *   newName: 'Cloned Project',
 *   shouldCloneAssets: true
 * });
 *
 * if (result) {
 *   const { clonedProject, clonedBlocks, clonedNodes, clonedNotes, clonedGroups } = result;
 *   console.log('Project cloned successfully', clonedProject, clonedBlocks, clonedNodes, clonedNotes, clonedGroups);
 * } else {
 *   console.log('Failed to clone project');
 * }
 */
export const onDuplicateProject = async (
	projectId: string,
	metadata: {
		parentGroupId: string;
		isTemplate: boolean;
		newName: string;
		shouldCloneAssets: boolean;
	}
): Promise<{
	clonedProject: IProject;
	clonedBlocks: IBlock[];
	clonedNotes: INote[];
	clonedNodes: INode[];
	clonedGroups: IGroup[];
} | null> => {
	try {
		const clonedGroups: IGroup[] = [];
		const clonedBlocks: IBlock[] = [];
		const clonedNotes: INote[] = [];
		const clonedNodes: INode[] = [];

		// Retrieve the current user ID from the state management (e.g., Redux)
		const { _id: currentUserId } = getUserFromRedux();
		const reduxState = store.getState();
		const { projects } = reduxState;

		// Fetch the original project by its ID from the state
		const originalProject = projects.data[projectId] as IProject;

		// Destructure the new metadata fields
		const { newName: name, shouldCloneAssets, parentGroupId, isTemplate } = metadata;

		// Create a new list of users for the cloned project with the current user as the owner
		const newProjectUsers: TUserAndRole[] = [
			{
				user: currentUserId as string,
				role: 'OWNER'
			}
		];

		// Generate a new unique ID for the cloned project
		const newProjectId = new mongoose.Types.ObjectId().toString();

		// Clone the original project and update the necessary fields with new metadata
		const clonedProject: IProject = {
			...originalProject,
			projectType: 'REGULAR',
			_id: newProjectId,
			shipments: [], // Reset shipments for the new project
			users: newProjectUsers,
			lastUpdatedBy: currentUserId as string,
			projectGroup: parentGroupId,
			createdBy: currentUserId as string,
			invitedEmails: [],
			projectDetails: {
				_id: new mongoose.Types.ObjectId().toString(),
				name: name || 'Untitled',
				color: '#FFFFFF'
			},
			isTemplate,
			statuses: [{ status: 'ACTIVE', createdAt: new Date().toISOString() }],
			updatedAt: new Date().toISOString(),
			hasCompleteAccess: true
		};

		clonedProject.children = [];

		// Clone assets (blocks and nested groups) if required
		if (shouldCloneAssets && originalProject?.children?.length) {
			const existingChildren = originalProject.children;
			for (let i = 0; i < existingChildren.length; i++) {
				const childId = existingChildren[i] as string;
				const originalChild = findStageWithStageId(childId) as IGroup;

				if (originalChild) {
					// eslint-disable-next-line no-await-in-loop
					const response = await onDuplicateGroup(childId, {
						newName: originalChild?.name ?? 'Untitled',
						newParentId: newProjectId,
						newProjectId,
						shouldCloneSelfNotes: true,
						shouldCloneChildrenNotes: true
					});

					if (response) {
						const {
							clonedGroup,
							clonedBlocks: duplicatedBlocks,
							clonedNodes: duplicatedNodes,
							clonedNotes: duplicatedNotes,
							clonedNestedGroups
						} = response;

						clonedBlocks.push(...duplicatedBlocks);
						clonedNodes.push(...duplicatedNodes);
						clonedNotes.push(...duplicatedNotes);
						clonedGroups.push(clonedGroup);
						clonedGroups.push(...clonedNestedGroups);

						clonedProject.children.push(clonedGroup._id as string);
					}
				}
			}
		} else if (!shouldCloneAssets && originalProject?.children?.length) {
			const existingChildren = originalProject.children;
			for (let i = 0; i < existingChildren.length; i++) {
				const childId = existingChildren[i] as string;
				const originalChild = findStageWithStageId(childId) as IGroup;

				if (originalChild && originalChild.isVisible) {
					const childBlocks = originalChild.children;
					const newGroupId = new mongoose.Types.ObjectId().toString();
					const newGroup = generateOptimisticStage(newGroupId, originalChild.name, {
						id: newProjectId,
						type: 'JOURNEY'
					});
					newGroup.users = newProjectUsers;

					for (let j = 0; j < childBlocks.length; j++) {
						const blockId = childBlocks[j] as string;
						const oldBlock = fetchBlockByIdFromRedux(blockId) as IBlock;

						if (oldBlock && oldBlock.isVisible) {
							const newBlockId = new mongoose.Types.ObjectId().toString();
							const newBlock = generateOptimisticBlock(
								newBlockId,
								newGroupId,
								newProjectId,
								oldBlock.name,
								'EMPTY',
								newProjectUsers
							);

							clonedBlocks.push(newBlock);
							newGroup.children.push(newBlockId);
						}
					}

					clonedGroups.push(newGroup);
					clonedProject.children.push(newGroupId);
				}
			}
		}

		// Return the cloned project along with any cloned blocks, nodes, notes, and nested groups
		return {
			clonedProject,
			clonedBlocks,
			clonedNotes,
			clonedNodes,
			clonedGroups
		};
	} catch (error) {
		// Log any errors encountered during the cloning process
		console.error('Failed to clone project: ', error);
		return null;
	}
};
