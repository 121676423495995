import { FormEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Form, InputGroup, FormControl, Button, Col, Row } from 'react-bootstrap';
import Select, { OptionsType, components } from 'react-select';
import { CustomDispatch } from 'src/redux/actions/types';

import PhoneInput from 'react-phone-input-2';
import { useSelector } from 'react-redux';
import { ISnackBar, OrderDetailsType, ReduxState } from 'src/redux/reducers/root.types';
import { useHistory } from 'react-router-dom';
import { store } from 'src';
import {
	addPrototypeError,
	changeColor,
	placeOrderToShapeways,
	setIsReadyToUpload,
	setOrderDetails,
	updatePrototypeState
} from 'src/redux/actions/prototyping';
import DownArrow from '../../../../../assets/icons/DownArrow.svg';
import '../../../../../components/homebase/utils/ESform.scss';
import '../../../../../components/homebase/sustainability/SustainabilityForm.scss';
import inactive_location from '../../../../../assets/icons/inactive_location-dot.svg';
import AlertIcon from '../../../../../assets/Alert.svg';
import { savedMaterialsList, savedFinishList, savedColorList } from './3DModel.config';
import {
	SavedColorsType,
	SavedColorType,
	SavedMaterialType,
	FinishT,
	PhoneDataType,
	InputFocusType,
	FinishNameType,
	MaterialNameType,
	MaterialType,
	ShippingType,
	PrototypeFormData,
	FinishType
} from '../prototyping.types';
import 'react-phone-input-2/lib/style.css';
import './ModelForm.scss';
import { ReactComponent as PhoneIcon } from '../../../../../assets/icons/phone-icon.svg';
import { addSnackbar, removeSnackbar } from '../../../../../redux/actions/snackBar';
import { CheckOutProps, MFormProps, OrderSummaryProps } from './CheckOutprops';
import PrototypePrompt from './PrototypePrompt';
import FailedModal from '../homepage/FailedModal';

/**
 * Function to get the react select's component
 * @param isFocus boolean
 * @returns Reactselect Component
 */
function getComponent(isFocus: boolean) {
	/* eslint-disable react/jsx-props-no-spreading */
	return function DropdownIndicator(props: any) {
		return (
			<components.DropdownIndicator
				{...props}
				className={`es-dropdown ${isFocus && 'active'}`}
			>
				<img src={DownArrow} alt="" />
			</components.DropdownIndicator>
		);
	};
}

/**
 * Return options for dropdown
 * @param props any
 * @returns
 */
const Option = (props: any) => {
	const { cx, className, isFocused, isSelected, label, value } = props;
	const {
		data: { isDisabled }
	} = props;
	return (
		<components.Option
			{...props}
			isDisabled
			className={cx(
				{
					option: true,
					'option--is-disabled': isDisabled,
					'option--is-focused': isFocused,
					'option--is-selected': isSelected
				},
				className
			)}
		>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<div
					style={{
						width: '24px',
						height: '24px',
						backgroundColor: `${isDisabled && '#F5F5F5'}`,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
					className="color-option"
				>
					<div
						style={{
							width: '12px',
							height: '12px',
							borderRadius: '50%',
							backgroundColor: `${value}`,
							border: '0.5px solid #E2E2E2',
							opacity: `${!isDisabled ? 1 : 0.2}`
						}}
					/>
				</div>
				<p
					className={`color-${label}`}
					style={{
						marginLeft: '16px',
						marginBottom: '0px',
						color: `${!isDisabled ? '#5D5D5D' : '#9F9F9F'}`
					}}
				>
					<span style={{ textTransform: 'capitalize' }}>{label} &nbsp;</span>
					{isDisabled && '(Unavailable for this size)'}
				</p>
			</div>
		</components.Option>
	);
};

/**
 * Single selected value
 * @param props any
 * @returns Styled single selected value
 */
const SingleValue = (props: any) => {
	const {
		data: { label, value }
	} = props;
	return (
		<components.SingleValue {...props}>
			<div style={{ display: 'flex', alignItems: 'center', padding: '13px' }}>
				<div
					style={{
						width: '12px',
						height: '12px',
						borderRadius: '50%',
						backgroundColor: `${value}`,
						border: '0.5px solid #E2E2E2'
					}}
				/>
				<span
					style={{
						marginLeft: '6px',
						marginTop: '2px',
						color: '#5D5D5D',
						textTransform: 'capitalize'
					}}
				>
					{label}
				</span>
			</div>
		</components.SingleValue>
	);
};

const initialFocus: InputFocusType = {
	materialFocus: false,
	firstnameFocus: false,
	lastnameFocus: false,
	colorsFocus: false,
	dimensionFocus: false,
	addressFocus: false,
	cityFocus: false,
	stateFocus: false,
	countryFocus: false,
	zipCodeFocus: false,
	phoneNumberFocus: false,
	finishFocus: false
};

function MForm({ setShowCancelModal, setPrototypePage }: MFormProps) {
	const {
		uploadedModel: model,
		isReadyForOrder,
		error,
		formData
	} = useSelector((state: ReduxState) => state.prototyping);

	const [material, setMaterial] = useState<SavedMaterialType | undefined>(formData.material);
	const [finish, setFinish] = useState<FinishT | null | undefined>(formData.finish);
	const [color, setColor] = useState<SavedColorType | null | undefined>(formData.color);
	const [colors, setColors] = useState<SavedColorType[] | undefined>(formData.colors);
	const [dimensions, setDimensions] = useState<{ mm: any; inch: any } | undefined>(
		formData.dimensions
	);
	const [shippingInfo, setShippingInfo] = useState<ShippingType>(formData.shippingInfo);
	const [phoneData, setPhoneData] = useState<PhoneDataType>(formData.phoneData);

	const [materialList, setMaterialList] = useState<OptionsType<SavedMaterialType>>();
	const [colorsList, setColorsList] = useState<SavedColorsType>();
	const [finishList, setFinishList] = useState<FinishT[]>();
	const [sFinishList, setSFinishList] = useState<FinishType>(savedFinishList);
	const [haveNoMaterials, setHaveNoMaterials] = useState<boolean>(false);
	const [isFormSubmit, setIsFormSubmit] = useState(false);

	const [inputFocus, setInputFocus] = useState<InputFocusType>(initialFocus);

	const [dimensionUnit, setDimensionUnit] = useState<'mm' | 'inch'>('mm');

	const formDataRef = useRef<PrototypeFormData>();
	const history = useHistory();

	function getSelectObjectStyles(data: any) {
		const selectObjectStyles = {
			control: (base: any) => ({
				...base,
				fontSize: '12px',
				color: '#161616',
				lineHeight: '16px',
				fontWeight: '400px',
				borderColor: isFormSubmit && !data ? '#E34300' : '#D1D1D1',
				boxShadow: 'none',
				'&:hover': {
					cursor: 'pointer'
				},
				'&:focused': {
					borderColor: '#cdaaff',
					boxShadow: '0 0 0 0.1rem #cdaaff'
				}
			}),
			option: (styles: any, { isDisabled }: { isDisabled: boolean }) => ({
				...styles,
				fontSize: '12px',
				color: isDisabled ? '#E2E2E2 !important' : '#161616',
				lineHeight: '16px',
				fontWeight: '400px',
				borderColor: '#D1D1D1',
				'&:active': {
					backgroundColor: '#EAEAEA;',
					checked: 'true'
				},
				'&:focused': {
					borderColor: ' #cdaaff',
					boxShadow: '0 0 0 0.1rem #cdaaff',
					font: '700, bold'
				}
			}),
			placeholder: (base: any) => ({
				...base,
				position: 'absolute',
				left: '10px',
				fontWeight: '400',
				fontSize: '12px',
				lineHeight: '16px',
				color: '#9F9F9F'
			}),
			multiValue: (base: any) => ({
				...base,
				textTransform: 'capitalize'
			})
		};
		return selectObjectStyles;
	}

	// validate the form values
	const validForm = useMemo(() => {
		if (
			material &&
			finish &&
			color &&
			shippingInfo.address1.length > 0 &&
			shippingInfo.firstName.length > 0 &&
			shippingInfo.lastName.length > 0 &&
			shippingInfo.city.length >= 2 &&
			shippingInfo.state.length >= 2 &&
			shippingInfo.country.length >= 2 &&
			shippingInfo.zipCode.length > 3 &&
			phoneData.phoneNumber.length > 9
		)
			return true;

		return false;
	}, [material, finish, color, shippingInfo, phoneData]);

	/**
	 * takes the colors and sort them by isDisabled, to show enabled color at the top
	 * @param colors
	 * @returns sorted ISavedColor[]
	 */
	function sortColors(clrs: SavedColorType[]) {
		return clrs.sort((a, b) => {
			if (a.isDisabled === b.isDisabled) return 0;
			if (a.isDisabled) return 1;
			return -1;
		}) as SavedColorType[];
	}

	const dimensionUnits = ['mm', 'inch'].map((unit) => ({
		value: unit,
		label: unit
	}));

	if (isReadyForOrder) {
		setPrototypePage('ORDER_SUMMARY');
		// history.push('/prototyping/order-summary');
	} else if (error.isError) {
		addSnackbar({ type: 'ERROR', text: error.msg, show: true });
		removeSnackbar(5000);
	}

	// handles the input focus
	const handleInputFocus = (type: string, value: boolean) => {
		setInputFocus((prev: InputFocusType) => ({ ...prev, [type]: value }));
	};

	// on submint check for valid form details and creates order details payload
	const onSubmit = async (e: MouseEvent | FormEvent) => {
		e.preventDefault();
		setIsFormSubmit(true);
		if (validForm && color?.id && material && finish && color.price && dimensions) {
			const data: OrderDetailsType = {
				items: [
					{
						materialId: color.id,
						modelId: model.modelId,
						quantity: 1
					}
				],
				color: color?.value,
				material: material.name,
				finish: finish.value,
				dimension: dimensions[dimensionUnit],
				price: color.price,
				...shippingInfo,
				phoneNumber: phoneData.phoneNumber,
				countryCode: phoneData.countryCode,
				shippingPrice: 0
			};
			(store.dispatch as CustomDispatch)(setOrderDetails(data));
			addSnackbar({
				type: 'LOADER',
				text: 'Calculating printing and shipping cost',
				show: true
			});
		} else {
			setTimeout(() => {
				const element = document.querySelector('.prototype-error') as HTMLElement;
				if (element) {
					if (element.classList.contains('material-select')) {
						element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
					} else {
						element.scrollIntoView({ behavior: 'smooth' });
					}
				}
			}, 100);
		}
	};

	/**
	 * set the colors list based on the material and finish type
	 * @param option
	 */
	const handleColorsChange = (option: any) => {
		if (colorsList && (finish || option.finish) && (material || option.material)) {
			const materialKey = option.material
				? (option.material as MaterialNameType)
				: (material?.value as MaterialNameType);
			const finishKey = option.finish
				? (option.finish as FinishNameType)
				: (finish?.value as FinishNameType);
			const newColors = Object.values(colorsList[materialKey!][finishKey!]);
			const sortedColors = sortColors(newColors);
			setColors(sortedColors);
			setColor(null);
		}
	};

	// sets the selected color and also update the redux with it
	const handlecolorChange = (opt: SavedColorType) => {
		setColor(opt);
		(store.dispatch as CustomDispatch)(changeColor(opt));
	};

	// handles shipping info changes
	const handleShippingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		if (name !== undefined && value !== undefined) {
			setShippingInfo({ ...shippingInfo, [name]: value });
		}
	};

	const handleMaterialChange = (opt: SavedMaterialType) => {
		setMaterial(opt);
		if (sFinishList) {
			setFinish(null);
			setFinishList(Object.values(sFinishList[opt.value]));
		}
	};

	useEffect(() => {
		let haveRequiredMateirals = false;
		if (model.materials && model.modelDimensions && !materialList) {
			// materials from shapeways
			const materials = model.materials as { [key: string]: MaterialType };
			// ids of the material we allow to print
			const ids = [
				'25',
				'208',
				'236',
				'240',
				'239',
				'242',
				'241',
				'237',
				'6',
				'207',
				'238',
				'144'
			];
			Object.values(materials).forEach((mMaterial: any) => {
				if (ids.includes(mMaterial.materialId)) {
					if (mMaterial.isPrintable) {
						haveRequiredMateirals = true;
					}
					if (mMaterial.name.includes('Versatile')) {
						const finishType = mMaterial.name
							.split(' ')[1]
							?.toLocaleLowerCase() as FinishNameType;
						savedColorList.versatile[finishType][mMaterial.materialId]!.isDisabled =
							!mMaterial.isPrintable;
						savedColorList.versatile[finishType][mMaterial.materialId]!.price =
							mMaterial.price;
						if (
							mMaterial.name.includes('Natural') &&
							sFinishList.versatile.natural?.isDisabled &&
							mMaterial.isPrintable
						) {
							sFinishList.versatile.natural.isDisabled = false;
						}
						if (
							mMaterial.name.includes('Smooth') &&
							sFinishList.versatile.smooth?.isDisabled &&
							mMaterial.isPrintable
						) {
							sFinishList.versatile.smooth.isDisabled = false;
						}
					} else {
						const mcolor = savedColorList.tpu.natural[mMaterial.materialId];
						mcolor!.isDisabled = !mMaterial.isPrintable;
						mcolor!.price = mMaterial.price;
						if (sFinishList.tpu.natural?.isDisabled && mMaterial.isPrintable) {
							sFinishList.tpu.natural.isDisabled = false;
						}
					}
					// enable the material if it is printable
					if (
						mMaterial.name.includes('Versatile') &&
						savedMaterialsList.versatile?.isDisabled &&
						mMaterial.isPrintable
					) {
						savedMaterialsList.versatile.isDisabled = false;
					}
					if (
						mMaterial.name.includes('TPU') &&
						savedMaterialsList.tpu?.isDisabled &&
						mMaterial.isPrintable
					) {
						savedMaterialsList.tpu.isDisabled = false;
					}
				}
			});

			if (!haveRequiredMateirals) {
				setHaveNoMaterials(true);
			} else {
				setMaterialList(Object.values(savedMaterialsList));
				setSFinishList(sFinishList);
				setColorsList(savedColorList);
				const modeldimensions = model.modelDimensions;
				const mm = {
					x: modeldimensions.widthMm,
					y: modeldimensions.heightMm,
					z: modeldimensions.depthMm
				};
				const inch = {
					x: modeldimensions.widthInch,
					y: modeldimensions.heightInch,
					z: modeldimensions.depthInch
				};
				setDimensions({ mm, inch });
			}
		}
	}, [model, color, materialList, setDimensions, sFinishList]);

	// store the form value in the ref
	useEffect(() => {
		formDataRef.current = {
			material,
			finish,
			shippingInfo,
			color,
			colors,
			phoneData,
			dimensions
		};
	}, [material, finish, shippingInfo, color, colors, phoneData, dimensions]);

	// on unmount remove snack bar present and save the formdata to redux
	// on mound add listener on flag-dropdown and scroll it's parent when clicked
	useEffect(() => {
		const shippingContainer = document.querySelector('.shipping-info');
		const phoneDropdown = document.querySelector('.flag-dropdown') as HTMLDivElement;
		if (phoneDropdown && shippingContainer) {
			phoneDropdown.addEventListener('click', () => {
				shippingContainer.scrollTop = 100;
			});
		}
		return () => {
			removeSnackbar(0);
			if (formDataRef.current) {
				(store.dispatch as CustomDispatch)(
					updatePrototypeState({ formData: formDataRef.current })
				);
			}
		};
	}, []);

	// sets the current url path
	useEffect(() => {
		(store.dispatch as CustomDispatch)(
			updatePrototypeState({ currentLocation: 'ORDER_DETAILS' })
		);
	}, [history]);

	return (
		<>
			<div className="estimateContainer model-form">
				<h4 className="estimateHeader">Order details</h4>
				<hr />
				<Form onSubmit={onSubmit} noValidate>
					<Form.Group
						className={`${
							isFormSubmit && !material && inputFocus.materialFocus === false
								? 'prototype-error material-select'
								: 'material-select'
						}`}
					>
						<Row>
							<Col xl={4} style={{ paddingRight: '0' }}>
								<Form.Label>Materials</Form.Label>
							</Col>
							<Col xl={8}>
								<Select
									isClearable={false}
									closeMenuOnSelect
									options={materialList}
									value={material}
									styles={getSelectObjectStyles(material)}
									isOptionDisabled={(option) => option.isDisabled}
									className="react-select"
									id="objectType"
									onChange={(opt) => {
										if (opt) {
											handleMaterialChange(opt);
											handleColorsChange({ material: opt.value });
										}
									}}
									placeholder="Select from option"
									onFocus={() => {
										handleInputFocus('materialFocus', true);
									}}
									onBlur={() => {
										handleInputFocus('materialFocus', false);
									}}
									classNamePrefix="react-select"
									components={{
										DropdownIndicator: getComponent(inputFocus.materialFocus),
										IndicatorSeparator: () => null
									}}
								/>
								{isFormSubmit &&
									!material &&
									inputFocus.materialFocus === false && (
										<div>
											<img src={AlertIcon} alt="" />
											<span className="FieldWarnings">
												Select material option to continue
											</span>
										</div>
									)}
							</Col>
						</Row>
					</Form.Group>
					<Form.Group
						className={`${
							isFormSubmit && !material && inputFocus.materialFocus === false
								? 'prototype-error finish-select'
								: 'finish-select'
						}`}
					>
						<Row>
							<Col xl={4} style={{ paddingRight: '0' }}>
								<Form.Label>Finish</Form.Label>
							</Col>
							<Col xl={8}>
								<Select
									isClearable={false}
									closeMenuOnSelect
									options={finishList}
									value={finish}
									isOptionDisabled={(option) => option.isDisabled}
									styles={getSelectObjectStyles(finish)}
									className="react-select"
									id="objectType"
									onChange={(opt) => {
										if (opt) {
											setFinish(opt);
											handleColorsChange({ finish: opt.value });
										}
									}}
									placeholder="Select finish type"
									noOptionsMessage={() => 'Select material type'}
									onFocus={() => {
										handleInputFocus('finishFocus', true);
									}}
									onBlur={() => {
										handleInputFocus('finishFocus', false);
									}}
									classNamePrefix="react-select"
									components={{
										DropdownIndicator: getComponent(inputFocus.finishFocus),
										IndicatorSeparator: () => null
									}}
								/>
								{isFormSubmit && !finish && inputFocus.finishFocus === false && (
									<div>
										<img src={AlertIcon} alt="" />
										<span className="FieldWarnings">
											Select finish option to continue
										</span>
									</div>
								)}
							</Col>
						</Row>
					</Form.Group>
					<Form.Group
						style={{ marginTop: '1rem' }}
						className={`${
							isFormSubmit && !material && inputFocus.materialFocus === false
								? 'prototype-error dimensions'
								: 'dimensions'
						}`}
					>
						<Row>
							<Col xl={4}>
								<Form.Label>Dimensions</Form.Label>
							</Col>
							<Col xl={8}>
								<Row>
									<Col xl={8} md={8} sm={6} className="s-weight">
										<div className="model-weight">
											<div>
												<span className="axis">X</span>
												<span>
													{dimensions && dimensions[dimensionUnit].x}
												</span>
											</div>
											<div>
												<span className="axis">Y</span>
												<span>
													{dimensions && dimensions[dimensionUnit].y}
												</span>
											</div>
											<div>
												<span className="axis">Z</span>
												<span>
													{dimensions && dimensions[dimensionUnit].z}
												</span>
											</div>
										</div>
									</Col>
									<Col xl={4} md={4} sm={6}>
										<Select
											isClearable={false}
											closeMenuOnSelect
											options={dimensionUnits as []}
											styles={getSelectObjectStyles('weight')}
											className="react-select"
											id="weight-units"
											onChange={(opt) => {
												if (opt?.value) setDimensionUnit(opt?.value);
											}}
											value={{
												value: dimensionUnit || '',
												label: dimensionUnit || ''
											}}
											onFocus={() => handleInputFocus('dimensionFocus', true)}
											onBlur={() => handleInputFocus('dimensionFocus', false)}
											classNamePrefix="react-select"
											components={{
												DropdownIndicator: getComponent(
													inputFocus.dimensionFocus
												),
												IndicatorSeparator: () => null
											}}
										/>
									</Col>
								</Row>
							</Col>
						</Row>
					</Form.Group>
					<Form.Group
						style={{ margin: '0.5rem 0 1rem 0' }}
						className={`${
							isFormSubmit && !material && inputFocus.materialFocus === false
								? 'prototype-error colors-select'
								: 'colors-select'
						}`}
					>
						<Row>
							<Col xl={4}>
								<Form.Label>Colors</Form.Label>
							</Col>
							<Col xl={8}>
								<Select
									isClearable={false}
									styles={getSelectObjectStyles(color)}
									closeMenuOnSelect
									options={colors}
									value={color}
									onFocus={() => handleInputFocus('colorsFocus', true)}
									onBlur={() => handleInputFocus('colorsFocus', false)}
									hideSelectedOptions={false}
									onChange={(opt: SavedColorType | null) => {
										if (opt) {
											handlecolorChange(opt);
										}
									}}
									isOptionDisabled={(option) => option?.isDisabled}
									className="react-select"
									classNamePrefix="react-select"
									placeholder="Select from options"
									noOptionsMessage={() => 'Select material and finish'}
									components={{
										DropdownIndicator: getComponent(inputFocus.colorsFocus),
										IndicatorSeparator: () => null,
										Option,
										SingleValue
									}}
								/>
								{isFormSubmit && !color && inputFocus.colorsFocus === false && (
									<div>
										<img src={AlertIcon} alt="" />
										<span className="FieldWarnings">
											Select color to continue
										</span>
									</div>
								)}
							</Col>
						</Row>
					</Form.Group>
					<hr />
					<h4 className="estimateHeader">Shipping Info</h4>
					<div className="shipping-info">
						<Form.Group
							className={`${
								isFormSubmit &&
								shippingInfo.firstName.length <= 0 &&
								inputFocus.firstnameFocus === false
									? 'prototype-error firstname-select'
									: 'firstname-select'
							}`}
						>
							<Row>
								<Col xl={4}>
									<Form.Label>First name</Form.Label>
								</Col>
								<Col xl={8}>
									<InputGroup
										id="firstnameField"
										className="prototypeInput"
										style={{
											border:
												isFormSubmit &&
												shippingInfo.firstName.length <= 0 &&
												inputFocus.firstnameFocus === false
													? '1px solid #E34300'
													: '1px solid #d1d1d1'
										}}
									>
										<InputGroup.Text
											style={{
												backgroundColor: 'transparent',
												borderRight: '0px',
												borderRadius: '4px 0 0 4px'
											}}
											onFocus={() => handleInputFocus('firstnameFocus', true)}
											onBlur={() => handleInputFocus('firstnameFocus', false)}
										/>
										<FormControl
											style={{ borderLeft: '0px', paddingLeft: '0px' }}
											className="textField"
											id="addressField"
											value={shippingInfo.firstName}
											name="firstName"
											onChange={handleShippingChange}
											placeholder="Enter your name"
										/>
									</InputGroup>
									{isFormSubmit &&
										shippingInfo.firstName.length <= 0 &&
										inputFocus.firstnameFocus === false && (
											<div>
												<img src={AlertIcon} alt="" />
												<span className="FieldWarnings">
													Add your name to continue
												</span>
											</div>
										)}
								</Col>
							</Row>
						</Form.Group>
						<Form.Group
							className={`${
								isFormSubmit &&
								shippingInfo.lastName.length <= 0 &&
								inputFocus.lastnameFocus === false
									? 'prototype-error lastname-select'
									: 'lastname-select'
							}`}
						>
							<Row>
								<Col xl={4}>
									<Form.Label>Last name</Form.Label>
								</Col>
								<Col xl={8}>
									<InputGroup
										id="lastNameField"
										className="prototypeInput"
										style={{
											border:
												isFormSubmit &&
												shippingInfo.firstName.length <= 0 &&
												inputFocus.lastnameFocus === false
													? '1px solid #E34300'
													: '1px solid #d1d1d1'
										}}
									>
										<InputGroup.Text
											style={{
												backgroundColor: 'transparent',
												borderRight: '0px',
												borderRadius: '4px 0 0 4px'
											}}
											onFocus={() => handleInputFocus('lastnameFocus', true)}
											onBlur={() => handleInputFocus('lastnameFocus', false)}
										/>
										<FormControl
											style={{ borderLeft: '0px', paddingLeft: '0px' }}
											className="textField"
											id="addressField"
											value={shippingInfo.lastName}
											name="lastName"
											onChange={handleShippingChange}
											placeholder="Enter your name"
										/>
									</InputGroup>
									{isFormSubmit &&
										shippingInfo.lastName.length <= 0 &&
										inputFocus.lastnameFocus === false && (
											<div>
												<img src={AlertIcon} alt="" />
												<span className="FieldWarnings">
													Add your name to continue
												</span>
											</div>
										)}
								</Col>
							</Row>
						</Form.Group>
						<Form.Group
							className={`${
								isFormSubmit &&
								phoneData.phoneNumber.length < 10 &&
								inputFocus.phoneNumberFocus === false
									? 'prototype-error contact-select'
									: 'contact-select'
							}`}
						>
							<Row>
								<Col xl={4}>
									<Form.Label>Contact</Form.Label>
								</Col>
								<Col xl={8}>
									<InputGroup
										id="contactField"
										placeholder="Enter your phone number"
										style={{
											border:
												isFormSubmit && phoneData.phoneNumber.length < 10
													? '1px solid #E34300'
													: '1px solid #d1d1d1'
										}}
										className="prototypeInput"
									>
										<PhoneInput
											value={phoneData.dialCode + phoneData.phoneNumber}
											onChange={(value: string, data: any) => {
												if (data.dialCode) {
													setPhoneData({
														dialCode: data.dialCode,
														phoneNumber: value.slice(
															data.dialCode.length
														),
														countryCode: data.countryCode
													});
												}
											}}
											country={phoneData.countryCode}
											enableSearch
											inputStyle={{ width: '100%', textAlign: 'left' }}
											searchClass="phone-search"
											dropdownClass="phone-dropdown"
											buttonClass="phone-dropdown-btn"
											placeholder="Enter you phone number"
											onFocus={() =>
												handleInputFocus('phoneNumberFocus', true)
											}
											onBlur={() =>
												handleInputFocus('phoneNumberFocus', false)
											}
										/>
									</InputGroup>
									{isFormSubmit &&
										phoneData.phoneNumber.length < 10 &&
										inputFocus.phoneNumberFocus === false && (
											<div>
												<img src={AlertIcon} alt="" />
												<span className="FieldWarnings">
													Add valid phone number to continue
												</span>
											</div>
										)}
								</Col>
							</Row>
						</Form.Group>
						<Form.Group
							className={`${
								isFormSubmit &&
								shippingInfo.address1.length <= 0 &&
								inputFocus.addressFocus === false
									? 'prototype-error address1-select'
									: 'address1-select'
							}`}
						>
							<Row>
								<Col xl={4}>
									<Form.Label>Address</Form.Label>
								</Col>
								<Col xl={8}>
									<InputGroup
										id="addressField"
										className="prototypeInput"
										style={{
											border:
												isFormSubmit &&
												shippingInfo.address1.length <= 0 &&
												inputFocus.addressFocus === false
													? '1px solid #E34300'
													: '1px solid #d1d1d1'
										}}
									>
										<InputGroup.Text
											style={{
												backgroundColor: 'transparent',
												borderRight: '0px',
												borderRadius: '4px 0 0 4px'
											}}
											onFocus={() => handleInputFocus('addressFocus', true)}
											onBlur={() => handleInputFocus('addressFocus', false)}
										/>
										<FormControl
											style={{ borderLeft: '0px', paddingLeft: '0px' }}
											className="textField"
											id="addressField"
											value={shippingInfo.address1}
											name="address1"
											onChange={handleShippingChange}
											placeholder="Enter your address"
										/>
									</InputGroup>
									{isFormSubmit &&
										shippingInfo.address1.length <= 0 &&
										inputFocus.addressFocus === false && (
											<div>
												<img src={AlertIcon} alt="" />
												<span className="FieldWarnings">
													Add address to continue
												</span>
											</div>
										)}
								</Col>
							</Row>
						</Form.Group>
						<Form.Group
							className={`${
								isFormSubmit &&
								shippingInfo.city.length < 2 &&
								inputFocus.cityFocus === false
									? 'prototype-error city-select'
									: 'city-select'
							}`}
						>
							<Row>
								<Col xl={4}>
									<Form.Label>City</Form.Label>
								</Col>
								<Col xl={8}>
									<InputGroup
										className="prototypeInput"
										style={{
											border:
												isFormSubmit &&
												shippingInfo.city.length < 2 &&
												inputFocus.cityFocus === false
													? '1px solid #E34300'
													: '1px solid #d1d1d1'
										}}
									>
										<InputGroup.Text
											style={{
												backgroundColor: 'transparent',
												borderRight: '0px',
												borderRadius: '4px 0 0 4px'
											}}
											onFocus={() => handleInputFocus('cityFocus', true)}
											onBlur={() => handleInputFocus('cityFocus', false)}
										/>
										<FormControl
											style={{ borderLeft: '0px', paddingLeft: '0px' }}
											className="textField"
											id="addressField"
											value={shippingInfo.city}
											name="city"
											onChange={handleShippingChange}
											placeholder="Enter your city"
										/>
									</InputGroup>
									{isFormSubmit &&
										shippingInfo.city.length < 2 &&
										inputFocus.cityFocus === false && (
											<div>
												<img src={AlertIcon} alt="" />
												<span className="FieldWarnings">
													Add your city to continue
												</span>
											</div>
										)}
								</Col>
							</Row>
						</Form.Group>
						<Form.Group
							className={`${
								isFormSubmit &&
								shippingInfo.state.length < 2 &&
								inputFocus.stateFocus === false
									? 'prototype-error state-select'
									: 'state-select'
							}`}
						>
							<Row>
								<Col xl={4}>
									<Form.Label>State</Form.Label>
								</Col>
								<Col xl={8}>
									<InputGroup
										className="prototypeInput"
										style={{
											border:
												isFormSubmit &&
												shippingInfo.state.length < 2 &&
												inputFocus.stateFocus === false
													? '1px solid #E34300'
													: '1px solid #d1d1d1'
										}}
									>
										<InputGroup.Text
											style={{
												backgroundColor: 'transparent',
												borderRight: '0px',
												borderRadius: '4px 0 0 4px'
											}}
											onFocus={() => handleInputFocus('stateFocus', true)}
											onBlur={() => handleInputFocus('stateFocus', false)}
										/>
										<FormControl
											style={{ borderLeft: '0px', paddingLeft: '0px' }}
											className="textField"
											id="addressField"
											value={shippingInfo.state}
											name="state"
											onChange={handleShippingChange}
											placeholder="Enter your state"
										/>
									</InputGroup>
									{isFormSubmit &&
										shippingInfo.state.length < 2 &&
										inputFocus.stateFocus === false && (
											<div>
												<img src={AlertIcon} alt="" />
												<span className="FieldWarnings">
													Add your state to continue
												</span>
											</div>
										)}
								</Col>
							</Row>
						</Form.Group>
						<Form.Group
							className={`${
								isFormSubmit &&
								shippingInfo.country.length < 2 &&
								inputFocus.countryFocus === false
									? 'prototype-error country-select'
									: 'country-select'
							}`}
						>
							<Row>
								<Col xl={4}>
									<Form.Label>Country</Form.Label>
								</Col>
								<Col xl={8}>
									<InputGroup
										id="countryField"
										className="prototypeInput"
										style={{
											border:
												isFormSubmit &&
												shippingInfo.country.length < 2 &&
												inputFocus.countryFocus === false
													? '1px solid #E34300'
													: '1px solid #d1d1d1'
										}}
									>
										<InputGroup.Text
											style={{
												backgroundColor: 'transparent',
												borderRight: '0px',
												borderRadius: '4px 0 0 4px'
											}}
											onFocus={() => handleInputFocus('countryFocus', true)}
											onBlur={() => handleInputFocus('countryFocus', false)}
										/>
										<FormControl
											style={{ borderLeft: '0px', paddingLeft: '0px' }}
											className="textField"
											id="addressField"
											value={shippingInfo.country}
											name="country"
											onChange={handleShippingChange}
											placeholder="Enter your country"
										/>
									</InputGroup>
									{isFormSubmit &&
										shippingInfo.country.length < 2 &&
										inputFocus.countryFocus === false && (
											<div>
												<img src={AlertIcon} alt="" />
												<span className="FieldWarnings">
													Add your country to continue
												</span>
											</div>
										)}
								</Col>
							</Row>
						</Form.Group>
						<Form.Group
							className={`${
								isFormSubmit &&
								shippingInfo.zipCode.length < 3 &&
								inputFocus.zipCodeFocus === false
									? 'prototype-error zip-select'
									: 'zip-select'
							}`}
						>
							<Row>
								<Col xl={4}>
									<Form.Label>ZIP code</Form.Label>
								</Col>
								<Col xl={8}>
									<InputGroup
										id="locationField"
										style={{
											border:
												isFormSubmit &&
												shippingInfo.zipCode.length < 3 &&
												inputFocus.zipCodeFocus === false
													? '1px solid #E34300'
													: '1px solid #d1d1d1'
										}}
									>
										<InputGroup.Text
											style={{
												backgroundColor: 'transparent',
												borderRight: '0px',
												borderRadius: '4px 0 0 4px'
											}}
											onFocus={() => handleInputFocus('zipCodeFocus', true)}
											onBlur={() => handleInputFocus('zipCodeFocus', false)}
										/>
										<FormControl
											style={{ borderLeft: '0px', paddingLeft: '0px' }}
											className="textField"
											id="addressField"
											value={shippingInfo.zipCode}
											name="zipCode"
											type="number"
											onChange={handleShippingChange}
											placeholder="Enter your zip code"
										/>
									</InputGroup>
									{isFormSubmit &&
										shippingInfo.zipCode.length < 3 &&
										inputFocus.zipCodeFocus === false && (
											<div>
												<img src={AlertIcon} alt="" />
												<span className="FieldWarnings">
													Add your zip code to continue
												</span>
											</div>
										)}
								</Col>
							</Row>
						</Form.Group>
					</div>
					<hr />
					<Form.Group className="footer">
						<div
							className="cancel-btn"
							role="presentation"
							onKeyDown={() => {}}
							onClick={() => setShowCancelModal(true)}
						>
							Cancel
						</div>
						<Button
							className="continue-btn"
							style={{
								background: 'var(--theme-color-1)',
								border: '#F5F5F5',
								borderRadius: '4px'
							}}
							onClick={onSubmit}
							role="button"
						>
							<span
								style={{
									fontWeight: '700',
									color: 'white',
									fontSize: '14px',
									letterSpacing: '0.02em',
									lineHeight: '16px'
								}}
							>
								Continue
							</span>
						</Button>
					</Form.Group>
				</Form>
			</div>
			<FailedModal
				handleShowModel={setHaveNoMaterials}
				show={haveNoMaterials}
				text="None of the material is applicable to the 3D model at this moment.
      Please contact our support team.
      "
				reset
			/>
		</>
	);
}

function OrderSummary({ orderDetails, setShowCancelModal, setPrototypePage }: OrderSummaryProps) {
	const [isOrderExpand, setIsOrderExpand] = useState<boolean>(false);
	const [isShippingExpand, setIsShippingExpand] = useState<boolean>(false);
	const { isOrderFailed, error } = useSelector((state: ReduxState) => state.prototyping);
	const [showFailedModal, setShowFailedModal] = useState<boolean>(false);
	const [showPlaceOrderModal, setShowPlaceOrderModal] = useState<boolean>(false);

	const history = useHistory();

	if (isOrderFailed && error.isError) {
		const payload: ISnackBar = {
			type: 'ERROR',
			text: error.msg,
			show: true
		};
		addSnackbar(payload);
		removeSnackbar(7000, () => {
			(store.dispatch as CustomDispatch)(addPrototypeError(false, ''));
		});
	} else if (isOrderFailed && !showFailedModal) {
		removeSnackbar(0);
		setShowFailedModal(true);
	}

	// shows placing order snackbar and places the order
	const handlePlaceOrder = () => {
		setShowPlaceOrderModal(false);
		const payload: ISnackBar = {
			type: 'LOADER',
			text: 'Placing your order',
			show: true
		};
		addSnackbar(payload);
		(store.dispatch as CustomDispatch)(placeOrderToShapeways(orderDetails));
	};

	const handleEditOrder = () => {
		(store.dispatch as CustomDispatch)(setIsReadyToUpload(false));
		setPrototypePage('ORDER_DETAILS');
		// history.push('/prototyping/order-details');
	};

	useEffect(() => {
		(store.dispatch as CustomDispatch)(
			updatePrototypeState({ currentLocation: 'ORDER_DETAILS' })
		);
	}, [history]);

	return (
		<>
			<div className="order-summary">
				<h6 className="heading">3D Print Order Summary</h6>
				<hr />
				<div className="payment-details">
					<h6>Payment details</h6>
					<div>
						<p>Hey! This is your first 3D print order and it is on us!</p>
						<p>
							Your 3D Print order payment will be completed by Naya Studio after you
							place your order.
						</p>
						<p>Thanks for ordering!</p>
					</div>
				</div>
				<hr />
				<div className="order-details">
					<h6>
						Order details
						<img
							src={DownArrow}
							className={`${isOrderExpand ? 'arrow up' : 'arrow'}`}
							alt="down arrow"
							onClick={() => setIsOrderExpand((prev) => !prev)}
							role="presentation"
							onKeyDown={() => {}}
						/>
					</h6>
					{isOrderExpand && (
						<>
							<div className="material-details">
								<div className="detail">
									<span>Material</span> - {orderDetails.material}
								</div>
								<div className="detail">
									<span>Finish</span> - {orderDetails.finish}
								</div>
								<div className="detail">
									<span>Color</span> - {orderDetails.color}
								</div>
								<div className="detail">
									<span>Size</span> - {orderDetails.dimension.x}x
									{orderDetails.dimension.y}x{orderDetails.dimension.z}
								</div>
							</div>
							<div className="item-details">
								<div className="detail">
									<span>Item(1)</span> - ${orderDetails.price}
								</div>
								<div className="detail">
									<span>Shipping</span> - ${orderDetails.shippingPrice}
								</div>
								<div className="detail">
									<span>Total</span> - $
									{(orderDetails.price + orderDetails.shippingPrice).toFixed(2)}
								</div>
							</div>
						</>
					)}
				</div>
				<hr />
				<div className="shipping-details">
					<h6>
						Shipping Info
						<img
							src={DownArrow}
							className={`${isShippingExpand ? 'arrow up' : 'arrow'}`}
							alt="down arrow"
							onClick={() => setIsShippingExpand((prev) => !prev)}
							role="presentation"
							onKeyDown={() => {}}
						/>
					</h6>
					{isShippingExpand && (
						<>
							<div className="address">
								<img src={inactive_location} alt="localtion" />{' '}
								<p>
									{orderDetails.address1},{orderDetails.city},
									{orderDetails.country},{orderDetails.zipCode}
								</p>
							</div>
							<div className="phone">
								<PhoneIcon /> <p>{orderDetails.phoneNumber}</p>
							</div>
						</>
					)}
				</div>
				<hr />
				<div className="footer">
					<div
						className="edit-btn"
						onClick={handleEditOrder}
						onKeyDown={() => {}}
						role="presentation"
					>
						Edit Order
					</div>
					<button
						className="cancel-btn"
						onKeyDown={() => {}}
						type="button"
						onClick={() => {
							setShowCancelModal(true);
						}}
					>
						cancel
					</button>
					<Button
						className="place-order-btn"
						style={{
							background: 'var(--theme-color-1)',
							border: '#F5F5F5',
							borderRadius: '4px'
						}}
						onClick={() => setShowPlaceOrderModal(true)}
						role="button"
					>
						<span
							style={{
								fontWeight: '700',
								color: 'white',
								fontSize: '14px',
								letterSpacing: '0.02em',
								lineHeight: '16px'
							}}
						>
							Place Order
						</span>
					</Button>
				</div>
			</div>
			<PrototypePrompt
				showModel={showPlaceOrderModal}
				onClose={() => setShowPlaceOrderModal(false)}
				text="Hey! You are ordering a prototype for the uploaded 3D model.
         Do you want to go ahead and place this 3D print order?"
				title="Place 3D print order confirmation"
				primaryBtnText="Place my order"
				secondaryBtnText="Cancel"
				onPrimaryBtnClick={handlePlaceOrder}
				contactNaya={false}
			/>

			<FailedModal
				reset
				show={showFailedModal}
				handleShowModel={setShowFailedModal}
				text="We can not place this order right now, please try again later"
			/>
		</>
	);
}

const ModelForm = ({ setPrototypePage, prototypePage }: CheckOutProps) => {
	const { orderDetails } = useSelector((state: ReduxState) => state.prototyping);

	const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
	const { isOrderPlaced } = useSelector((state: ReduxState) => state.prototyping);

	if (isOrderPlaced) {
		setPrototypePage('HOME');
	}

	const handleCancelOrder = () => {
		(store.dispatch as CustomDispatch)(updatePrototypeState(null));
		setPrototypePage('HOME');
	};

	// clears snackbar
	useEffect(
		() => () => {
			removeSnackbar(0);
		},
		[]
	);

	return (
		<>
			{/* {history.location.pathname === '/prototyping/order-details' */}
			{prototypePage === 'ORDER_DETAILS' ? (
				<MForm
					setShowCancelModal={setShowCancelModal}
					setPrototypePage={setPrototypePage}
				/>
			) : (
				<OrderSummary
					orderDetails={orderDetails as OrderDetailsType}
					setShowCancelModal={setShowCancelModal}
					setPrototypePage={setPrototypePage}
				/>
			)}
			<PrototypePrompt
				showModel={showCancelModal}
				onClose={() => setShowCancelModal(false)}
				text="Are you sure you want to cancel 3D Print Order?"
				title="Cancel 3D Print Order"
				primaryBtnText="Cancel Order"
				secondaryBtnText="Keep  Ordering"
				onPrimaryBtnClick={handleCancelOrder}
				contactNaya={false}
			/>
		</>
	);
};

export default ModelForm;
