import { REALTIME_API_WS } from 'src/endpoints/api';
import { WebsocketProvider } from 'y-websocket';
import * as Y from 'yjs';

export type TSocketEvent = {
	status: 'connecting' | 'connected' | 'disconnected';
};

export type TChangeType<oldValue> = {
	action: 'delete' | 'add' | 'update';
	oldValue: oldValue;
};

let wsProvider: WebsocketProvider;
let doc: Y.Doc;

/**
 * Function to connect to websocket with projectid doc
 */
const connectToWebSocket = (projectId: string, isJourneyRtcEnabled: boolean) => {
	// If socket is created, but for another project, destroy it
	if (wsProvider && wsProvider.roomname !== projectId) {
		wsProvider.destroy();
	}

	// If socket is already created for this project, connect to it
	if (wsProvider && wsProvider.roomname === projectId && isJourneyRtcEnabled) {
		if (!wsProvider.wsconnected) {
			wsProvider.connect();
			wsProvider.connectBc();

			wsProvider.doc.load();
		}
	} else {
		doc = new Y.Doc();
		wsProvider = new WebsocketProvider(REALTIME_API_WS, projectId, doc, {
			connect: isJourneyRtcEnabled
		});
	}
};

/**
 * Function to access websocket
 */
const getWebSocket = () => wsProvider;

/**
 * Function to access ydoc
 */
const getYDoc = () => doc;

export { getWebSocket, getYDoc, connectToWebSocket };
