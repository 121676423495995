import { ReactElement, FC, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Helmet } from 'react-helmet';
import SideNav from './side-nav/SideNav';
import './landingPageLayout.scss';

type PathParamsType = {
	scheduleDemo: string;
};

export type LandingPageLayoutProps = {
	expanded: boolean;
	toggleHamburgerMenu: () => void;
	showPartnerPopup: () => void;
	mainContent: ReactElement;
	rightMarginContent?: ReactElement;
};

const LandingPageLayout: FC<LandingPageLayoutProps & RouteComponentProps<PathParamsType>> = ({
	expanded,
	toggleHamburgerMenu,
	mainContent,
	rightMarginContent,
	showPartnerPopup
}): ReactElement => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			{/* Set the title that is shown in browser tab */}
			<Helmet>
				<title>Naya</title>
			</Helmet>
			<div id="Landing-Page-Container">
				<SideNav
					expanded={expanded}
					toggleHamburgerMenu={toggleHamburgerMenu}
					showPartnerPopup={showPartnerPopup}
				/>
				<div className="landing-page_content">{mainContent}</div>
				<div id="peek-a-boo-margin">{rightMarginContent}</div>
			</div>
		</>
	);
};

LandingPageLayout.defaultProps = {
	rightMarginContent: undefined
};

export default withRouter(LandingPageLayout);
