import { useSelector } from 'react-redux';
import { IUserInitState, ReduxState } from 'src/redux/reducers/root.types';

/**
 * Custom hook to access the node from redux
 * @param id - node id to be accessed
 * @returns
 */
const useUser = () => {
	const {
		data: user,
		loading,
		error
	}: IUserInitState = useSelector((state: ReduxState) => state.user);

	return {
		user,
		loading,
		error
	};
};

// Extracts the 'aiUser' data, loading state, and error from the Redux store
export const useAIUser = () => {
	const {
		data: aiUser,
		loading,
		error
	}: IUserInitState = useSelector((state: ReduxState) => state.aiUser);

	return {
		aiUser,
		loading,
		error
	};
};

export default useUser;
