import axios from 'axios';
import { OrderDetailsType, PrototypingActionTypes } from 'src/redux/reducers/root.types';
import { materialsHexColors } from 'src/features/components/homebase/prototyping/checkout/3DModel.config';
import {
	SavedColorType,
	MaterialIdType
} from 'src/features/components/homebase/prototyping/prototyping.types';
import { Schema } from 'mongoose';
import { isMaxOrderReached } from 'src/features/components/homebase/prototyping/utils/helper';
import { prototypingRouter } from 'src/endpoints/projects-api';

import { getGatewayKey } from 'src/util/helper/queryString';
import getUserFromRedux from 'src/util/helper/user';
import { IUser } from '@naya_studio/types';
import { store } from 'src';
import { PrototypeUploadPayloadd, ThunkDispatchWrapper, ThunkResults } from './types';
import { updateUser } from '../features/user';

const isSendDummy = !process.env.REACT_APP_PRODUCTION;
const qs = `?${getGatewayKey()}`;

export const addPrototypeError =
	(isError: boolean = false, msg: string = ''): ThunkResults =>
	async (dispatch: ThunkDispatchWrapper) => {
		dispatch({
			type: PrototypingActionTypes.UPDATE_PROTOTYPE_STATE,
			payload: { error: { isError, msg }, isUploading: false, isOrderFailed: false }
		});
	};

export const uploadModelToShapeways =
	(file: PrototypeUploadPayloadd): ThunkResults =>
	async (dispatch: ThunkDispatchWrapper) => {
		if (isMaxOrderReached()) {
			dispatch({
				type: PrototypingActionTypes.UPDATE_PROTOTYPE_STATE,
				payload: { uploadedFrom: file.uploadedFrom }
			});
			return;
		}
		const user: IUser = getUserFromRedux();
		const formData = new FormData();
		formData.append('file', file.file);
		formData.append('fileName', file.fileName);
		try {
			dispatch({
				type: PrototypingActionTypes.UPDATE_PROTOTYPE_STATE,
				payload: { isUploading: true }
			});
			const res = await axios.request({
				headers: {
					'Content-type': 'multipart/form-data'
				},
				url: `/upload-model${qs}&userId=${user._id}&sendDummy=${isSendDummy}`,
				method: 'post',
				baseURL: prototypingRouter,
				data: formData
			});
			if (res.data.payload.printable === 'yes') {
				const payload = {
					uploadedModel: res.data.payload,
					file: { extension: file.extension, url: file.url },
					uploadedFrom: file.uploadedFrom,
					isUploading: false,
					isUploaded: true
				};
				dispatch({ type: PrototypingActionTypes.UPDATE_PROTOTYPE_STATE, payload });
			} else {
				dispatch({
					type: PrototypingActionTypes.UPDATE_PROTOTYPE_STATE,
					payload: {
						error: {
							isError: true,
							msg: `Opps! Failed to select any of the materials available for this 3D model.
               Please try uploading another 3D model.`
						},
						isUploading: false
					}
				});
			}
		} catch (error: any) {
			console.error(error);
			if (error.response && error.response.status === 400) {
				dispatch({
					type: PrototypingActionTypes.UPDATE_PROTOTYPE_STATE,
					payload: {
						isUploading: false,
						error: { isError: true, msg: error.response.data.msg }
					}
				});
			} else {
				dispatch({
					type: PrototypingActionTypes.UPDATE_PROTOTYPE_STATE,
					payload: {
						isUploading: false,
						error: {
							isError: true,
							msg: 'Something went wrong processing your upload. Please try again'
						}
					}
				});
			}
		}
	};

export const getOrderDetailsFromShapeways =
	(id: string | Schema.Types.ObjectId): ThunkResults =>
	async (dispatch: ThunkDispatchWrapper) => {
		try {
			const { data } = await axios.get(
				`${prototypingRouter}/orders${qs}&userId=${id}&sendDummy=${isSendDummy}`
			);
			dispatch({
				type: PrototypingActionTypes.UPDATE_PROTOTYPE_STATE,
				payload: { placedOrderDetails: data, fetchOrders: false }
			});
		} catch (error: any) {
			console.error(error);
			if (error.response && error.response.status === 400) {
				dispatch({
					type: PrototypingActionTypes.UPDATE_PROTOTYPE_STATE,
					payload: {
						isUploading: false,
						error: { isError: true, msg: error.response.data.msg },
						fetchOrders: false
					}
				});
			} else {
				dispatch({
					type: PrototypingActionTypes.UPDATE_PROTOTYPE_STATE,
					payload: {
						isUploading: false,
						error: {
							isError: true,
							msg: 'Failed to fetch order details, please try again'
						},
						fetchOrders: false
					}
				});
			}
		}
	};

export const setOrderDetails =
	(payload: OrderDetailsType): ThunkResults =>
	async (dispatch: ThunkDispatchWrapper) => {
		try {
			const { data } = await axios.get(
				`${prototypingRouter}/get-shipping${qs}&countryCode=${payload.countryCode}`
			);
			payload.shippingPrice = data.price;
			dispatch({ type: PrototypingActionTypes.ORDER_DETAILS, payload });
		} catch (error: any) {
			console.error(error);
			dispatch({
				type: PrototypingActionTypes.UPDATE_PROTOTYPE_STATE,
				payload: {
					error: { isError: true, msg: 'Something went wrong, please try again' },
					isOrderFailed: false
				}
			});
		}
	};

export const placeOrderToShapeways =
	(payload: OrderDetailsType): ThunkResults =>
	async (dispatch: ThunkDispatchWrapper, getState) => {
		try {
			const {
				prototyping: { uploadedModel }
			} = getState();
			const user: IUser = getUserFromRedux();
			const modelImg = `${uploadedModel.defaultImage}?key=${uploadedModel.secretKey}`;
			const { data } = await axios.post(
				`${prototypingRouter}/place-order${qs}&userId=${user._id}&sendDummy=${isSendDummy}`,
				{ payload, modelImg },
				{
					headers: {
						'Content-type': 'application/json'
					}
				}
			);
			const prototypeOrders =
				user.prototypeOrders && user.prototypeOrders.length > 0 ? user.prototypeOrders : [];
			prototypeOrders.push(data);
			store.dispatch(
				updateUser({
					prototypeOrders
				})
			);
			dispatch({
				type: PrototypingActionTypes.UPDATE_PROTOTYPE_STATE,
				payload: { isOrderPlaced: true, isReadyForOrder: false, fetchOrders: true }
			});
		} catch (error: any) {
			if (error.response.status === 400) {
				dispatch({
					type: PrototypingActionTypes.UPDATE_PROTOTYPE_STATE,
					payload: {
						isOrderFailed: true,
						isReadyForOrder: true,
						error: { isError: true, msg: error.response.data.msg }
					}
				});
			} else {
				// show failed to place order modal
				dispatch({
					type: PrototypingActionTypes.UPDATE_PROTOTYPE_STATE,
					payload: {
						isOrderFailed: true,
						isReadyForOrder: true
					}
				});
			}
		}
	};

export const changeColor =
	(color: SavedColorType): ThunkResults =>
	(dispatch: ThunkDispatchWrapper) => {
		dispatch({
			type: PrototypingActionTypes.UPDATE_PROTOTYPE_STATE,
			payload: { color: materialsHexColors[color.id as MaterialIdType] }
		});
	};
export const setIsReadyToUpload =
	(payload: boolean): ThunkResults =>
	(dispatch: ThunkDispatchWrapper) => {
		dispatch({
			type: PrototypingActionTypes.UPDATE_PROTOTYPE_STATE,
			payload: { isReadyForOrder: payload }
		});
	};

export const updatePrototypeState =
	(payload: any): ThunkResults =>
	(dispatch: ThunkDispatchWrapper) => {
		dispatch({ type: PrototypingActionTypes.UPDATE_PROTOTYPE_STATE, payload });
	};
