/* eslint-disable max-len */
import { EBlockType } from '@naya_studio/types';
import { TI2RData } from './i2rData';

const car: TI2RData = {
	text: `{"root":{"children":[{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":"design a retro futuristic car that is deep blue, not shiny, modern, and has geometric edges","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}`,
	projectTitle: 'Example - Car',
	groups: [
		{
			name: 'Example - Car Inspiration',
			blocks: [
				{
					name: 'Ferrari 250 GTO',
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_auto,q_auto/v1/i2r/Car/ddgqawcwzcwf4oqi2z6r',
					blockType: EBlockType.IMAGE
				},
				{
					name: 'Mercedes Alloy 300 SL',
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_auto,q_auto/v1/i2r/Car/m9ra6ds1fsnmrw4viytl',
					blockType: EBlockType.IMAGE
				},
				{
					name: 'porsche-911',
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_auto,q_auto/v1/i2r/Car/nhpagx8itofshlwpxfmv',
					blockType: EBlockType.IMAGE
				},
				{
					name: 'Tesla Roadster',
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_auto,q_auto/v1/i2r/Car/x16zqm009ypgwmfimatn',
					blockType: EBlockType.IMAGE
				}
			]
		}
	]
};

export default car;
