import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import cn from 'classnames';

export type BadgeProps = {
	value: number;
	isDisabled?: boolean;
	pathColor: string;
	trailColor: string;
	strokeWidth?: number;
	title: string;
	subTitle: string;
	badgeContent: JSX.Element | null;
	counterClockwise?: boolean;
};

const Badge = ({
	value,
	isDisabled,
	pathColor,
	trailColor,
	strokeWidth,
	title,
	subTitle,
	badgeContent,
	counterClockwise
}: BadgeProps) => (
	<div className="tw-flex tw-flex-col profile-badge " style={{ width: '150px', gap: '12px' }}>
		<div style={{ height: '150px', width: '147px', overflow: 'hidden' }}>
			<CircularProgressbarWithChildren
				value={value}
				strokeWidth={strokeWidth}
				counterClockwise={counterClockwise}
				styles={buildStyles({
					pathColor,
					trailColor: value === 0 ? 'rgba(214, 214, 214, 0.3)' : trailColor
				})}
			>
				{badgeContent}
			</CircularProgressbarWithChildren>
		</div>

		<div
			className={cn('tw-flex tw-flex-col tw-justify-center tw-items-center ', {
				disabled: isDisabled
			})}
		>
			<div className="tw-font-bold">{title}</div>
			<div className="tw-font-normal">{subTitle}</div>
		</div>
	</div>
);
Badge.defaultProps = {
	strokeWidth: 4,
	counterClockwise: false,
	isDisabled: false
};
export default Badge;
