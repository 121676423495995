import { useEffect, useRef, useState } from 'react';
import { CustomDispatch } from 'src/redux/actions/types';
import { useHistory } from 'react-router-dom';
import './PrototypingHome.scss';
import { Modal, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { ReduxState } from 'src/redux/reducers/root.types';
import moment from 'moment';
import { store } from 'src';
import {
	addPrototypeError,
	getOrderDetailsFromShapeways,
	updatePrototypeState
} from 'src/redux/actions/prototyping';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';
import useUser from 'src/redux/hooks/user';
import {
	NewOrderProps,
	OrderDetailsProps,
	CongratulationModalProps,
	OrderListProps,
	FirstOrderProps,
	PrototypingHomeProps,
	PrototypePageType
} from './PrototypingHomeProps';
import { ReactComponent as CongratulationIcon } from '../../../../../assets/prototyping/rightmark-with-stars.svg';
import { ReactComponent as PlussIcon } from '../../../../../assets/cross.svg';
import { ReactComponent as WomanWithPc } from '../../../../../assets/prototyping/woman-with-pc.svg';
import { isMaxOrderReached } from '../utils/helper';
import PrototypePrompt from '../checkout/PrototypePrompt';

function FirstOrder({ setPrototypePage }: FirstOrderProps) {
	return (
		<div className="first-order">
			<WomanWithPc color="var(--theme-color-3)" />
			<div className="no-orders">
				<p>
					<strong>No Orders Yet!</strong> <br /> Bring your product to life, place your
					first 3D Print order today.
				</p>
				<div
					className="order-btn"
					role="presentation"
					onClick={() => setPrototypePage('UPLOAD')}
				>
					<PlussIcon />
					Order 3D Print
				</div>
			</div>
		</div>
	);
}

function NewOrder({ setShowMaxModel, setPrototypePage }: NewOrderProps) {
	const handleClick = () => {
		if (!isMaxOrderReached()) setPrototypePage('HOME');
		else setShowMaxModel(true);
	};
	return (
		<div
			className="new-order-container"
			onClick={handleClick}
			role="button"
			tabIndex={0}
			onKeyDown={handleClick}
		>
			<div className="new-order">
				<PlussIcon />
				<p>
					Place a new 3D
					<br /> Print order
				</p>
			</div>
		</div>
	);
}

function OrderList({ setIsOrderDetailsModal, id, url, orderedDate, isDisabled }: OrderListProps) {
	let date: string = '';
	if (orderedDate) {
		date = moment(orderedDate).format('ll');
	}
	return (
		<div className="order-container">
			<div
				className="model-img"
				style={{ backgroundImage: `url(${url})`, backgroundSize: '100%' }}
			/>
			<div className="order-footer">
				<div className="data">
					<p className="name">3D Print</p>
					<p className="date">
						Ordered &nbsp;
						<span>{date}</span>
					</p>
				</div>
				<Button
					disabled={isDisabled}
					className="continue-btn"
					style={{
						background: 'var(--theme-color-1)',
						border: '#F5F5F5',
						borderRadius: '4px'
					}}
					role="button"
					onClick={() => setIsOrderDetailsModal({ show: true, id })}
				>
					<span
						style={{
							fontWeight: '700',
							color: 'white',
							fontSize: '14px',
							letterSpacing: '0.02em',
							lineHeight: '16px'
						}}
					>
						Track Order
					</span>
				</Button>
			</div>
		</div>
	);
}

/**
 * Function to convert date format to DD MM, e.g. 2023-04-23 -> 23 Apr
 * @param date String
 * @returns String
 */
const getFormatedDate = (date: string) => moment(date).format('DD MMM');

function OrderDetailsModal({ setIsOrderDetailsModal, modalData, orderInfo }: OrderDetailsProps) {
	const datesRef = useRef<{ [key: string]: any }>();
	const widthRef = useRef<{ shipWidth: number; tragetWidth: number }>({
		shipWidth: 0,
		tragetWidth: 0
	});

	if (
		modalData &&
		orderInfo &&
		orderInfo.targetDeliveryDate &&
		orderInfo.targetShipDate &&
		widthRef.current.shipWidth === 0 &&
		widthRef.current.tragetWidth === 0
	) {
		const orderedDate = new Date(modalData?.date);
		const shippingDate = new Date(
			orderInfo.shipments ? orderInfo?.shipments[0].shipDate : orderInfo.targetShipDate
		);
		const targetDate = new Date(orderInfo.targetDeliveryDate);
		datesRef.current = { orderedDate, shippingDate, targetDate };
		const timeFromOrderedToShipping = shippingDate.getTime() - orderedDate.getTime();
		const timeFromCurrentToShipping = shippingDate.getTime() - Date.now();
		const timeFromShippedToTarget = targetDate.getTime() - shippingDate.getTime();
		const timeFromCurrentToTarget = targetDate.getTime() - Date.now();
		const percentageCompletedToShipping = Math.floor(
			((timeFromOrderedToShipping - timeFromCurrentToShipping) / timeFromOrderedToShipping) *
				100
		);
		widthRef.current = {
			shipWidth: percentageCompletedToShipping,
			tragetWidth: 0
		};
		if (Date.now() > shippingDate.getTime() && orderInfo?.shipments) {
			const percentageCompletedToTarget = Math.floor(
				((timeFromShippedToTarget - timeFromCurrentToTarget) / timeFromShippedToTarget) *
					100
			);
			widthRef.current = {
				...widthRef.current,
				tragetWidth: percentageCompletedToTarget
			};
		}
		if (shippingDate.getTime() < Date.now()) {
			widthRef.current = { ...widthRef.current, shipWidth: 100 };
		}
	}

	return (
		<div className="order-modal-bg">
			<div className="order-details-modal">
				<div className="header">
					<p>3D Model Print</p>
					<PlussIcon
						className="close-icon"
						onClick={() => setIsOrderDetailsModal({ show: false, id: 0 })}
						role="button"
					/>
				</div>
				<div className="trackbar-container">
					<div className="trackbar">
						<div className="circles">
							<div className="circle active" />
							<div
								className={`circle ${
									widthRef.current.shipWidth >= 100 && 'active'
								} `}
							/>
							<div
								className={`circle ${
									widthRef.current.tragetWidth >= 100 && 'active'
								}`}
							/>
						</div>
						<div className="progress-container">
							<div className="progress">
								<div
									className="progress-bar"
									style={{ width: `${widthRef.current.shipWidth}%` }}
								/>
							</div>
							<div className="progress">
								<div
									className="progress-bar"
									style={{ width: `${widthRef.current.tragetWidth}%` }}
								/>
							</div>
						</div>
					</div>
					<div className="date-container">
						<div className="date">
							<span>Requested</span>
							<span>{getFormatedDate(datesRef.current?.orderedDate)}</span>
						</div>
						<div className="date">
							<span>Target shipping date</span>
							<span>{getFormatedDate(datesRef.current?.shippingDate)}</span>
						</div>
						<div className="date">
							<span>Target delivery date</span>
							<span>{getFormatedDate(datesRef.current?.targetDate)}</span>
						</div>
					</div>
				</div>
				<div className="body">
					<div className="order-details">
						<h6>Order details</h6>
						<div className="item">
							<span className="type">Materials</span>
							<span>-&nbsp;</span>
							<span>{modalData?.material}</span>
						</div>
						<div className="item">
							<span className="type">Finish</span>
							<span>-&nbsp;</span>
							<span>{modalData?.finish}</span>
						</div>
						<div className="item">
							<span className="type">Color</span>
							<span>-&nbsp;</span>
							<span>{modalData?.color}</span>
						</div>
						<div className="item">
							<span className="type">Size</span>
							<span>-&nbsp;</span>
							<span>{`${modalData?.dimension.x}x${modalData?.dimension.y}x${modalData?.dimension.z}`}</span>
						</div>
						<div className="item">
							<span className="type">Items(1)</span>
							<span>-&nbsp;</span>
							<span>${modalData?.price}</span>
						</div>
						<div className="item">
							<span className="type">Shipping</span>
							<span>-&nbsp;</span>
							<span>${modalData && modalData.shippingPrice}</span>
						</div>
						<div className="item">
							<span className="type">Total</span>
							<span>-&nbsp;</span>
							<span>
								$
								{modalData &&
									(modalData.price + modalData.shippingPrice).toFixed(2)}
							</span>
						</div>
					</div>
					<div className="shipment-details">
						<h6>Shipment Information</h6>
						{orderInfo ? (
							<>
								<p>
									Carrier - &nbsp;
									{orderInfo?.shipments
										? orderInfo?.shipments[0].carrier
										: 'Order not shipped yet!'}
								</p>
								<p>
									Tracking no - &nbsp;
									{orderInfo?.shipments ? (
										<a
											href={orderInfo?.shipments[0].trackingLink}
											target="_blank"
											rel="noreferrer"
											style={{ color: 'var(--theme-color-1)' }}
										>
											{orderInfo?.shipments[0].trackingNumber}
										</a>
									) : (
										'Order not shipped yet!'
									)}
								</p>
								<p>
									Shipping to -
									<br />
									{modalData?.address1}
								</p>
								<p>
									Contact -
									<span>
										&nbsp;
										{modalData?.phoneNumber}
									</span>
								</p>
							</>
						) : (
							<p>Details not available</p>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

function CongratulationModal({ handleShowModel, show }: CongratulationModalProps) {
	const handleCloseModal = () => {
		handleShowModel(false);
	};
	return (
		<Modal
			className="congModal-content"
			show={show}
			animation={false}
			onHide={handleCloseModal}
		>
			<Modal.Header closeButton>
				<Modal.Title>3D Print Order</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<CongratulationIcon color="var(--theme-color-1)" />
			</Modal.Body>
			<Modal.Footer>
				<h6>Congratulations!</h6>
				<p>
					The order has been placed
					<br /> successfully.
				</p>
			</Modal.Footer>
		</Modal>
	);
}

const PrototypingHome = ({ setPrototypePage }: PrototypingHomeProps) => {
	const { fetchOrders, placedOrderDetails, error, goToLocation, isOrderPlaced } = useSelector(
		(state: ReduxState) => state.prototyping
	);
	const { prototypeOrders, _id: userId } = useUser().user;
	const [orderDetailsModal, setIsOrderDetailsModal] = useState<{
		show: boolean;
		id: number;
	}>({ show: false, id: 0 });
	const [showCongratulations, setShowCongratulations] = useState<boolean>(isOrderPlaced);
	const [showMaxOrderModal, setShowMaxOrderModal] = useState<boolean>(false);

	const history = useHistory();

	useEffect(() => {
		if (goToLocation.length > 0) {
			const location = goToLocation;
			(store.dispatch as CustomDispatch)(updatePrototypeState({ goToLocation: '' }));
			if (location !== 'HOME') setPrototypePage(location as PrototypePageType);
		}
	}, [goToLocation]);

	useEffect(() => {
		if (!placedOrderDetails || fetchOrders) {
			if (userId && prototypeOrders && prototypeOrders.length >= 1) {
				setTimeout(() => {
					addSnackbar({
						type: 'LOADER',
						text: 'Fetching order details',
						show: true
					});
					(store.dispatch as CustomDispatch)(getOrderDetailsFromShapeways(userId));
				}, 500);
			}
		} else {
			removeSnackbar(0);
		}
		(store.dispatch as CustomDispatch)(
			updatePrototypeState({ currentLocation: 'HOME', isOrderPlaced: false })
		);
	}, [placedOrderDetails, userId, prototypeOrders, history, fetchOrders]);

	useEffect(() => {
		if (error.isError) {
			addSnackbar({
				type: 'ERROR',
				text: "Couldn't fetch order details, please come back later",
				show: true
			});
			removeSnackbar(70000).then(() => {
				(store.dispatch as CustomDispatch)(addPrototypeError(false, ''));
			});
		}
	}, [error]);

	useEffect(
		() => () => {
			removeSnackbar(0);
		},
		[]
	);

	return (
		<div className="prototype-home">
			<h5 className="heading">Prototyping</h5>
			{!prototypeOrders || prototypeOrders?.length === 0 ? (
				<FirstOrder setPrototypePage={setPrototypePage} />
			) : (
				<div className="order-list">
					<NewOrder
						setShowMaxModel={setShowMaxOrderModal}
						setPrototypePage={setPrototypePage}
					/>
					{prototypeOrders?.map((val) => (
						<OrderList
							isDisabled={!(placedOrderDetails && placedOrderDetails[val.orderId])}
							key={val.orderId}
							url={val.modelImg}
							id={val.orderId}
							orderedDate={val.date}
							setIsOrderDetailsModal={setIsOrderDetailsModal}
						/>
					))}
				</div>
			)}
			{orderDetailsModal.show && placedOrderDetails && (
				<OrderDetailsModal
					orderInfo={placedOrderDetails[orderDetailsModal.id]}
					modalData={
						prototypeOrders &&
						prototypeOrders.find((order) => order.orderId === orderDetailsModal.id)
					}
					setIsOrderDetailsModal={setIsOrderDetailsModal}
				/>
			)}
			{showCongratulations && (
				<CongratulationModal
					show={showCongratulations}
					handleShowModel={setShowCongratulations}
				/>
			)}
			<PrototypePrompt
				text="You have reached the maximum limit to order a 3D print. Connect with us to request additional orders."
				title="Maximum order limit reached"
				onClose={() => setShowMaxOrderModal(false)}
				showModel={showMaxOrderModal}
				primaryBtnText="Contact Naya support"
				secondaryBtnText="Cancel"
				onPrimaryBtnClick={() => {}}
				contactNaya
			/>
		</div>
	);
};

export default PrototypingHome;
