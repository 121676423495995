import { TUserAndRole, TInvitedEmail, INote } from '@naya_studio/types';
import * as Y from 'yjs';
import { TChangeType, getYDoc } from 'src/rtc/yjs/yjsConfig';
import { useDispatch } from 'react-redux';
import { findDifferenceInJSON } from 'src/redux/actions/util';
import { editNoteInRedux, addNotesInRedux, deleteNoteFromRedux } from 'src/redux/features/notes';
import getUserFromRedux from 'src/util/helper/user';
import {
	broadcastSelfDispatch,
	checkIfUserHasAccess,
	getBlockFromYDoc,
	getGroupFromYDoc,
	getProjectFromYDocOrRedux
} from './utils';

/**
 * Hook to update note in redux after yjs listens to note change
 */
const useNoteObserver = () => {
	const dispatch = useDispatch();

	/**
	 * Observer to listen to note changes in ymap
	 * @param update note change to do in redux
	 */
	const noteObserver = (update: Y.YMapEvent<string>) => {
		try {
			const user = getUserFromRedux();
			const doc = getYDoc();

			update.changes.keys.forEach((change: TChangeType<string>, key) => {
				if (change.action === 'update') {
					const noteAfterUpdate = JSON.parse(
						doc.getMap('notes').get(key) as string
					) as INote;
					// console.info('RTC - Received note update', noteAfterUpdate);
					const noteBeforeUpdate = JSON.parse(change.oldValue) as INote;
					const selfDispatch = noteAfterUpdate.lastUpdatedBy === (user._id as string);
					if (selfDispatch) {
						broadcastSelfDispatch(noteAfterUpdate, 'EDIT', 'NOTE');
						return;
					}

					const noteDifference: Partial<INote> = findDifferenceInJSON(
						noteAfterUpdate,
						noteBeforeUpdate
					);
					if (
						noteDifference?.color ||
						noteDifference?.text ||
						noteDifference?.savedNoteReference
					) {
						const parentId = noteAfterUpdate.parentId as string; // can refer to groupId too

						// If parent is not present in ydoc take the redux
						// this case might happen when we update note after server is just started
						// and parent is not in ydoc
						const parent = getBlockFromYDoc(parentId) || getGroupFromYDoc(parentId);
						let hasAccess = true;

						const { projectId } = noteAfterUpdate;
						const project = getProjectFromYDocOrRedux(projectId);
						const hasGuestAccess =
							parent?.hasGuestAccess || project?.hasGuestAccess || false;

						if (parent?.users || parent?.invitedEmails) {
							hasAccess =
								checkIfUserHasAccess(
									user,
									parent.users as TUserAndRole[],
									parent.invitedEmails as TInvitedEmail[]
								) || hasGuestAccess;
						}

						if (hasAccess) dispatch(editNoteInRedux(noteAfterUpdate));
					}
				} else if (change.action === 'add') {
					const noteToAdd = JSON.parse(doc.getMap('notes').get(key) as string) as INote;
					// console.info('RTC - Received note add', noteToAdd);
					const parentId = noteToAdd.parentId as string;
					const parent = getBlockFromYDoc(parentId) || getGroupFromYDoc(parentId);
					const selfDispatch = noteToAdd.lastUpdatedBy === (user._id as string);
					if (selfDispatch) {
						broadcastSelfDispatch([noteToAdd], 'ADD', 'NOTE');
						return;
					}

					let hasAccess = true;
					const { projectId } = noteToAdd;
					const project = getProjectFromYDocOrRedux(projectId);
					const hasGuestAccess =
						parent?.hasGuestAccess || project?.hasGuestAccess || false;

					if (parent?.users || parent?.invitedEmails) {
						hasAccess =
							checkIfUserHasAccess(
								user,
								parent.users as TUserAndRole[],
								parent.invitedEmails as TInvitedEmail[]
							) || hasGuestAccess;
					}

					// Only load the block if user has access to it
					if (hasAccess) dispatch(addNotesInRedux([noteToAdd]));
				} else if (change.action === 'delete') {
					const noteBeforeDelete = JSON.parse(change.oldValue) as INote;
					// console.info('RTC - Received note delete', noteBeforeDelete);
					const parentId = noteBeforeDelete.parentId as string; // can refer to groupId too
					const parent = getBlockFromYDoc(parentId) || getGroupFromYDoc(parentId);
					const selfDispatch = noteBeforeDelete.lastUpdatedBy === (user._id as string);
					if (selfDispatch) {
						broadcastSelfDispatch(key, 'DELETE', 'NOTE');
						return;
					}

					let hasAccess = true;
					const { projectId } = noteBeforeDelete;
					const project = getProjectFromYDocOrRedux(projectId);
					const hasGuestAccess =
						parent?.hasGuestAccess || project?.hasGuestAccess || false;

					if (parent?.users || parent?.invitedEmails) {
						hasAccess =
							checkIfUserHasAccess(
								user,
								parent.users as TUserAndRole[],
								parent.invitedEmails as TInvitedEmail[]
							) || hasGuestAccess;
					}

					// Only load the block if user has access to it
					if (hasAccess) dispatch(deleteNoteFromRedux(key));
				}
			});
		} catch (error) {
			console.error('Error in Note Listener : ', error);
		}
	};

	return noteObserver;
};

export default useNoteObserver;
