import Switch from 'react-switch';
import { useEffect, useState } from 'react';
import { ReactComponent as CopyIcon } from 'src/assets/icons/sharing-modal/copyLink.svg';

import './GuestAccess.scss';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';
import { ISnackBar, ReduxState } from 'src/redux/reducers/root.types';
import { editBlock } from 'src/redux/reduxActions/block';
import { useDispatch, useSelector } from 'react-redux';
import { CustomDispatch } from 'src/redux/actions/types';
import { IBlock, IProject } from '@naya_studio/types';
import { editProject } from 'src/redux/reduxActions/project';
import { TEditProjectThunkArg } from 'src/types/argTypes';
import trackEvent from 'src/util/analytics/analytics';
import { CustomEvents } from 'src/util/analytics/events';
import { TToggleGuestAccessEventData } from 'src/util/analytics/analytic.types';
import { getProjectZoomValue } from 'src/util/helper/user';
import { ESharingTabs } from '../sharingModal/SharingModal.types';

export type GuestAccessProps = {
	projectId: string;
	stageId: string;
	blockId: string;
	hasGuestAccess: boolean;
	activeTab: keyof typeof ESharingTabs;
};

/**
 * @component For toggling the guest access
 * @param {GuestAccessProps} GuestAccessProps
 * @returns ReactComponent
 */
export const GuestAccessSwitch = ({
	projectId,
	stageId,
	blockId,
	hasGuestAccess,
	activeTab
}: GuestAccessProps) => {
	const [checked, setChecked] = useState<boolean>(hasGuestAccess);
	const [isSwitchDisabled, setIsSwitchDisabled] = useState<boolean>(false);
	const dispatch = useDispatch();
	const blocks = useSelector((state: ReduxState) => state.blocks.data);
	const projects = useSelector((state: ReduxState) => state.projects.data);
	const user = useSelector((state: ReduxState) => state.user.data);

	/**
	 * Function to get the description of the guest access
	 * @returns {string}
	 */
	const getGuestAccessDescription = () => {
		switch (activeTab) {
			case 'BLOCK':
				return 'Allow anyone with link to view block & leave feedback';
			case 'PROJECT':
				return 'Allow anyone with link to view blocks & leave feedback';
			default:
				return 'Allow anyone with link to view block & leave feedback';
		}
	};

	/**
	 * Function to generate link to copy
	 * @returns Link to Shared Project/Stage/Block
	 */
	const getCopyLink = () => {
		let link = `${window.location.origin}/project/${projectId}`;
		if (activeTab === 'PHASE') {
			link += `/${stageId}`;
		} else if (activeTab === 'BLOCK') {
			link += `/${stageId}/${blockId}`;
		}
		const zoomPreference = getProjectZoomValue(projectId) || 1;
		return `${link}?invitedBy=${user.email}${
			activeTab === 'PROJECT' ? `&zoom=${zoomPreference}` : ''
		}`;
	};

	/**
	 * Function to display snackbar
	 */
	const displaySnackbar = (msg: string, type: 'LOADER' | 'NORMAL') => {
		const payload: ISnackBar = {
			text: msg,
			show: true,
			type
		};

		addSnackbar(payload);
	};

	/**
	 * Function to update the block's guest access
	 * @param {boolean} isEnabled
	 */
	const updateBlockGuestAccess = (isEnabled: boolean = false) => {
		// edit block dispatch action
		(dispatch as CustomDispatch)(
			editBlock({
				data: {
					block: {
						_id: blockId,
						hasGuestAccess: isEnabled
					},
					stageId,
					projectId
				},
				prevState: {
					prevStateBlock: blocks[blockId] as IBlock
				}
			})
		)
			.unwrap()
			.then(() => {
				removeSnackbar(500, () => {
					setIsSwitchDisabled(false);
				});
			});
	};

	/**
	 * Function to update the project's guest access
	 * @param {boolean} isEnabled
	 */
	const updateProjectGuestAccess = (isEnabled: boolean = false) => {
		// Edit Project API payload
		const apiPayload: TEditProjectThunkArg = {
			payload: {
				_id: projectId,
				update: {
					hasGuestAccess: isEnabled
				}
			},
			prevState: { prevProject: projects[projectId] as IProject }
		};

		// Edit Project dispatch action
		(dispatch as CustomDispatch)(editProject(apiPayload))
			.unwrap()
			.then(() => {
				removeSnackbar(1000, () => {
					setIsSwitchDisabled(false);
				});
			});
	};

	/**
	 * Callback fn to update the guest access
	 */
	const updateGuestAccess = async (isEnabled: boolean) => {
		if (isEnabled) {
			// Track toggling guest access event
			const eventProps: TToggleGuestAccessEventData = {
				elementType: activeTab === 'BLOCK' ? 'BLOCK' : 'JOURNEY',
				elementId: activeTab === 'BLOCK' ? blockId : projectId
			};

			trackEvent(CustomEvents.GUEST_ACCESS_TOGGLED, eventProps);
		}

		setIsSwitchDisabled(true);
		displaySnackbar('Updating guest access.', 'LOADER');
		switch (activeTab) {
			case 'BLOCK':
				updateBlockGuestAccess(isEnabled);
				break;
			case 'PROJECT':
				updateProjectGuestAccess(isEnabled);
				break;
			default:
				setIsSwitchDisabled(false);
				break;
		}
		setChecked(isEnabled);
	};

	// Run on mount and on isLoadGuestData change
	useEffect(() => {
		setChecked(hasGuestAccess);
	}, [hasGuestAccess]);

	return (
		<div className="guest-access tw-flex tw-items-center">
			<Switch
				disabled={isSwitchDisabled}
				checked={checked}
				onChange={updateGuestAccess}
				offColor="#C0C0C0"
				className={`guest-switch ${
					checked ? 'guest-checkedSwitch' : 'guest-uncheckedSwitch'
				}`}
				handleDiameter={16}
				height={21}
				width={40}
				uncheckedIcon={false}
				checkedIcon={false}
				activeBoxShadow="0px 0px 0px 0px #ffffff"
			/>
			<div className="content tw-text-xs">
				<h6 className="tw-text-xs tw-font-randMedium">Guest access mode</h6>
				<p>{getGuestAccessDescription()}</p>
			</div>
			<div className={`copy-guest-link  ${hasGuestAccess && 'has-guest-access'}`}>
				<div
					data-testid="share-modal-guest-link"
					className="tw-flex tw-items-center tw-gap-2 tw-cursor-pointer"
					onClick={() => {
						if (hasGuestAccess) {
							navigator.clipboard.writeText(getCopyLink());
							displaySnackbar('Guest link copied to clipboard.', 'NORMAL');
							removeSnackbar(4000);
						}
					}}
					role="presentation"
				>
					<CopyIcon width={24} height={24} className="copy-icon" />
					<p className="tw-text-xs tw-font-randMedium tw-m-0">Copy guest link</p>
				</div>
			</div>
		</div>
	);
};
