import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {
	EFeedbackType,
	EUserRole,
	IBlock,
	IFeedback,
	INode,
	IUser,
	TUserAndRole
} from '@naya_studio/types';
import { RouteComponentProps, withRouter } from 'react-router';
import { ISnackBar, ReduxState } from 'src/redux/reducers/root.types';
import './Toolbar.scss';
import { v1 as uuidv1 } from 'uuid';
import { LegacyToolbar } from '@naya_studio/radix-ui';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';
import { createPrediction, getPrediction } from 'src/redux/actions/textToImage';
import { CustomDispatch } from 'src/redux/actions/types';
import { store } from 'src';
import { generateIdsFromUrl, getUserRoleObjBasedOnIds } from 'src/redux/reduxActions/util';
import { addNewCommentData, addNewCommentType } from 'src/redux/features/newComment';
import Model from '../app/nodes/Model';
import Upload3DModel from './upload3DModel/Upload3DModel';
import { ToolbarDispatchProps, ToolbarProps, PathParamsType } from './Toolbar.types';
import { getUnsplashImagesget, toolbarData } from './toolbar.config';
// import { FileIcon, defaultStyles } from 'react-file-icon';

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

class Toolbar extends React.Component<
	ToolbarProps & ToolbarDispatchProps & RouteComponentProps<PathParamsType>,
	{ [key: string]: any }
> {
	constructor(props: any) {
		super(props);

		this.state = {
			tools: [], // will hold the list of the tools which need to be rendered
			showSidePanel: true,
			// canvasURL: '',
			// stageURL: '',
			// projectURL: '',
			showUploadModal: false,
			activeTool: '',
			currentCanvasId: ''
		};
	}

	static getDerivedStateFromProps(
		nextProps: ToolbarProps & ToolbarDispatchProps & RouteComponentProps<PathParamsType>,
		prevState: any
	) {
		if (nextProps.block && prevState.currentCanvasId !== nextProps.block._id) {
			return { currentCanvasId: nextProps.block._id };
		}
		return null;
	}

	componentDidMount() {
		const { isDummy, tools, block, snackbarMsg, app } = this.props;
		if (isDummy) {
			this.extractTools(tools);
		}
		// this.setState({ canvasURL: match.params.canvasId,
		// stageURL: match.params.stageOrDashboard, projectURL: match.params.projectId });
		if (block && block.users) {
			this.getUserAccessLevel();
		}
		const toolbarElement = document.getElementById('toolbar') as HTMLDivElement;
		document.addEventListener('mousedown', (e: any) => {
			const sidePanelElement = document.getElementsByClassName(
				'right-panel'
			)[0] as HTMLDivElement;
			app.isColourPickerOpen = false;
			const { activeTool } = this.state;
			const stopDragDrop = [
				'Adding Files',
				'Adding Snapshot',
				'Click or drag to place files'
			].includes(snackbarMsg);
			if (
				toolbarElement &&
				!toolbarElement.contains(e.target) &&
				!sidePanelElement?.contains(e.target) &&
				!stopDragDrop &&
				['BRAIN', 'IMAGE', '3D', 'SAMPLE_TOOL'].includes(activeTool)
			) {
				this.setState({ showSidePanel: false });
				this.setActiveTool('SELECT');
			}
		});
	}

	componentDidUpdate(
		prevProps: ToolbarProps & ToolbarDispatchProps & RouteComponentProps<PathParamsType>
	) {
		let prevCanvasId = '';
		if (prevProps.block) {
			prevCanvasId = prevProps.block._id as string;
		}
		const { block } = this.props;
		if (block && block._id !== prevCanvasId) {
			this.getUserAccessLevel();
		}
	}

	/**
	 * Handles toggle of the upload progress modal
	 */
	toggleUploadModal = () => {
		const { showUploadModal } = this.state;
		this.setState({ showUploadModal: !showUploadModal });
	};

	extractTools = (tools: string[]) => {
		const tempTools = [] as { type: string; icon: string }[];
		for (let i = 0; i < tools.length; i++) {
			// @ts-ignore
			if (toolbarData[tools[i]]) {
				// @ts-ignore
				tempTools.push(toolbarData[tools[i]]);
			}
		}
		this.setState({ tools: tempTools });
	};

	getUserRole = () => {
		const { block, user } = this.props;
		const canvasUsers = getUserRoleObjBasedOnIds(block.users as TUserAndRole[]);
		for (let i = 0; i < canvasUsers.length; i++) {
			if ((canvasUsers[i]?.user as IUser)._id === user._id) {
				return canvasUsers[i]?.role;
			}
		}
		return '';
	};

	getUserAccessLevel = () => {
		const { tools, isDummy } = this.props;
		const role = this.getUserRole();
		if (isDummy) {
			this.extractTools(tools);
			return;
		}
		switch (role) {
			case EUserRole.COMMENTER:
				this.extractTools(['BRAIN', 'COMMENT']);
				this.selectTool('SELECT', {} as INode); // reseting the selected tool when clicked on new milestone for commenter role
				break;
			case EUserRole.VIEWER:
				this.extractTools(['BRAIN']);
				this.selectTool('SELECT', {} as INode); // reseting the selected tool when clicked on new milestone for viewer role
				break;
			case EUserRole.EDITOR:
			case EUserRole.OWNER:
			default:
				this.extractTools(tools);
				break;
		}
	};

	onThreeDModelSelect = (threedData: { name: string; src: string; extension: string }) => {
		const { app, getNodeBounds, addThreeDModalToCanvas } = this.props;
		const { name, src, extension } = threedData;
		app.isColourPickerOpen = false;
		app.removeTransformer();
		app.toggleEditMenu(false);
		app.canvasEditing = true;
		app.hasCanvasUpdatedForThumbnail = true;
		const nodeData = JSON.parse(JSON.stringify(Model.defaultNodeData));
		const { width, height } = nodeData.absoluteBounds;
		nodeData.absoluteBounds = {
			...nodeData.absoluteBounds,
			...getNodeBounds(width, height, false)
		};
		if (!nodeData?._id) nodeData._id = uuidv1();
		nodeData.model = {
			name,
			src,
			extension
		};
		nodeData.zIndex = Math.floor(app.getHighestZIndex()) + 1;

		const snackbarPayload: ISnackBar = {
			text: 'Uploading Files',
			show: true,
			type: 'LOADER',
			loaderColor: 'var(--theme-color-1)'
		};

		addSnackbar(snackbarPayload);

		addThreeDModalToCanvas(nodeData, () => {
			setTimeout(() => {
				const payload: ISnackBar = {
					text: 'Files Uploaded',
					show: true,
					type: 'NORMAL'
				};

				addSnackbar(payload);
				removeSnackbar(2000);
			}, 3000);
		});
	};

	// eslint-disable-next-line class-methods-use-this
	handleSubmit = async (searchValue: string) => {
		const payload = {
			prompt: searchValue
		};
		if (searchValue) {
			await (store.dispatch as CustomDispatch)(createPrediction(payload));
			await (store.dispatch as CustomDispatch)(getPrediction());
		}
	};

	/**
	 * Calls the selectTool function on Canvas to set the active tool and nodeData of the app
	 * @param tool
	 * @param nodeData
	 */
	selectTool = (tool: string, nodeData: INode) => {
		const { app, selectTool } = this.props;
		if (app.isPanSelected) {
			app.throughPanTool = false;
		}
		selectTool(tool, nodeData);
		this.setState({ activeTool: tool });
	};

	/**
	 * Function to set the active tool as per user selection
	 * @param tool
	 */
	setActiveTool = (toolArg: string, nodeData?: INode, feedbackType?: 'CHAT' | 'POLL') => {
		const { tool, onPlaceAll, snackbarMsg, app, updateNewCommentType, addNewComment } =
			this.props;
		// If the tool is already selected and user clicks on it, close the side panel
		if (toolArg === tool && !app.isPanSelected) {
			this.setState((prev) => ({ showSidePanel: !prev.showSidePanel }));
		}
		if (
			snackbarMsg === 'Click or drag to place files' &&
			!['SELECT', '3D', 'IMAGE'].includes(toolArg)
		) {
			onPlaceAll();
		}

		app.toggleShowActiveTool(true);
		// Pausing the drag action (to ensure its disabled)
		app.pauseDragPlugin();

		this.setState({
			showSidePanel: true
		});

		if (toolArg === 'COMMENT' && feedbackType && ['CHAT', 'POLL'].includes(feedbackType)) {
			const { newComment } = store.getState();
			const { initialComment, initialType } = newComment.data;
			// if new comment is already present and feedback option is changes,
			// update the new comment data
			if (initialComment && initialType !== feedbackType) {
				const data: IFeedback = {
					...newComment?.data.initialComment,
					feedbackType
				};
				addNewComment(data);
			} else {
				updateNewCommentType(feedbackType as EFeedbackType);
			}
		}

		// update pixi app with current tool (thru canvas.tsx)
		this.selectTool(toolArg, nodeData || ({} as INode));
	};

	render() {
		const {
			app,
			tool: propsActiveTool,
			showActiveTool,
			snackbarMsg,
			addImageToCanvas,
			brainTool,
			clearXYCoordinates
		} = this.props;
		const { tools, showUploadModal } = this.state;
		const stopDragDrop = [
			'Adding Files',
			'Adding Snapshot',
			'Click or drag to place files'
		].includes(snackbarMsg);

		return (
			<div id="toolbar" role="presentation" className="vertical-center">
				<div className="t-wrap" style={{ zIndex: 999 }}>
					<LegacyToolbar
						tools={tools}
						activetool={propsActiveTool}
						showActiveTool={showActiveTool}
						clearXYCoordinates={clearXYCoordinates}
						onToolSelect={this.setActiveTool}
						brainTool={brainTool}
						app={app}
						toggleUploadModal={this.toggleUploadModal}
						isSidePanelDisabled={stopDragDrop}
						onImageSelect={addImageToCanvas}
						onThreeDModelSelect={this.onThreeDModelSelect}
						onTextToImageSubmit={this.handleSubmit}
						getUnsplashImages={(searchTerm: string) => getUnsplashImagesget(searchTerm)}
					/>
				</div>
				<Upload3DModel
					app={app}
					show={showUploadModal}
					closeModal={this.toggleUploadModal}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state: ReduxState) => {
	const { blockId } = generateIdsFromUrl();
	const block = state.blocks.data[blockId] as IBlock;

	return {
		block,
		user: state.user,
		brainTool: state.brainTool
	};
};

/**
 * Redux actions mapped to canvas props
 * @param dispatch
 * @returns
 */
const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
	updateNewCommentType: (payload: keyof typeof EFeedbackType) =>
		dispatch(addNewCommentType(payload)),
	addNewComment: (payload: IFeedback) => dispatch(addNewCommentData(payload))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Toolbar));
