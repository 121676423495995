// import EstimateAIChat from "./EstimateAIChat";
import EstimateAIForm from './EstimateAIForm';
import '../../utils/ESform.scss';
// import { useState } from "react";
// import ConnectProject from "./subComponents/ConnectProject";

export default function EstimateAI() {
  // const [ estimate, setEstimate ] = useState<EstimateDisplay>({
  //   predictedPrice: '0',
  //   minPrice: '0',
  //   maxPrice: '0'
  // })
  // const [ loading, setLoading ] = useState(false)
  // const [ submitted, setSubmitted ] = useState(false)
  // const [ showConnectProject, setShowConnectProject ] = useState(false)
  // const [ connectedProject, setConnectedProject ] = useState<string>('')

  return (
    <div>
      {/* <EstimateAIChat
      submitted={submitted}
      loading={loading}
      estimate={estimate}
    /> */}
      {/* <div className='vertical-divider' /> */}
      <EstimateAIForm />
      {/* {showConnectProject ? <ConnectProject
      connectedProject={connectedProject}
      close={() => setShowConnectProject(false)}
      setConnectedProject={(proj) => setConnectedProject(proj)}
    /> : <></>} */}
    </div>
  );
}

export type EstimateDisplay = {
  predictedPrice: string;
  minPrice: string;
  maxPrice: string;
};
