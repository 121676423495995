import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import './Info.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { InfoOptionProps, InfoOptionDispatchProps, PathParamsType } from './Info.types';
import InfoIcon from '../../../../../assets/icons/toolbar/info.svg';

class InfoOption extends React.Component<InfoOptionProps & InfoOptionDispatchProps & RouteComponentProps<PathParamsType>> {
  getPortfolioLink = (data:any) => {
    console.log(this.state);
    const link = data.portfolioLink
              || data.user.portfolio_url;
    return link;
  };

  getInfoData = () => {
    const { app } = this.props;
    if (app.selectedNodes[0]?.nodeData.additionalProperties) {
      const nodeProperties = app.selectedNodes[0]?.nodeData.additionalProperties as any;
      if (nodeProperties.imageCredits) {
        return (
          <p>
            Photo by
            {' '}
            <a
              href={this.getPortfolioLink(nodeProperties.imageCredits)}
              className={this.getPortfolioLink(nodeProperties.imageCredits) ? '' : 'no-link-anchor'}
            >
              {typeof (nodeProperties.imageCredits.user) === 'string'
                ? nodeProperties.imageCredits.user : nodeProperties.imageCredits.user.name}
            </a>
            {' '}
            on
            {' '}
            <a href="https://unsplash.com">{nodeProperties.imageCredits.source}</a>
          </p>
        );
      }
    }
    return <div />;
  };

  render() {
    const { selectedOption, setSelectedOption } = this.props;
    return (
      <>
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 1000, hide: 0 }}
          overlay={(
            <Tooltip
              id="button-tooltip-2"
              style={{
                fontSize: '12px',
                borderRadius: '2px',
                lineHeight: '16px',
                marginTop: '8px',
              }}
            >
              Info
            </Tooltip>
        )}
        >
          <div className="icon-container" style={{ background: selectedOption === 'INFO' ? '#EDEDED' : 'transparent' }}>
            <img
              role="presentation"
              src={InfoIcon}
              alt="info"
              className="icon"
              onClick={(e: any) => {
                e.stopPropagation();
                if (selectedOption === 'INFO') {
                  setSelectedOption('');
                } else {
                  setSelectedOption('INFO');
                }
              }}
            />
          </div>
        </OverlayTrigger>

        {
        selectedOption === 'INFO' && (
          <div className="sub-option-container info-container">
            {this.getInfoData()}
          </div>
        )
      }
      </>
    );
  }
}

/**
 * Redux state mapped to canvas props
 * @param state
 * @returns
 */
// @ts-ignore
const mapStateToProps = () => ({
});

/**
 * Redux actions mapped to canvas props
 * @param dispatch
 * @returns
 */
// @ts-ignore
const mapDispatchToProps = () => ({
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InfoOption));
