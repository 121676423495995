import {
	Button,
	HtmlTextViewer,
	FeedbackInput,
	PollOption,
	PollOptionsWrapper,
	UserT
} from '@naya_studio/radix-ui';
import { IFeedback, TPollOption, TUserAndRole } from '@naya_studio/types';
import { isUndefined, cloneDeep } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'src/redux/reducers/root.types';
import './PollResults.scss';
import { getAllUsersBasedOnIds } from 'src/redux/reduxActions/util';
import useFeedbackActions from 'src/util/feedback/useFeedbackActions';
import { useParams } from 'react-router';
import { PathParamsType } from 'src/types/pathParams';
import './Poll.scss';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';
import mongoose from 'mongoose';
import { FeedbackContext } from 'src/components/expandedBlockView/ExpandedBlockView';
import useUser from 'src/redux/hooks/user';
import { getFormattedUsersData } from '../util';

/** props for poll component */
export type PollProps = {
	// Feedback data
	data: IFeedback;
	showResultsView: (val: boolean) => void; // callback to set results view
	setIsEditing: (val: boolean) => void; // callback to set edit state
	isEditing: boolean;
	actionItemAssignees: string[];
	setActionItemAsignees: (userIds: string[]) => void;
};

/**
 * Poll component
 */
export const Poll = ({
	data,
	showResultsView,
	setIsEditing,
	isEditing,
	actionItemAssignees,
	setActionItemAsignees
}: PollProps) => {
	const [pollOptions, setPollOptions] = useState<{ [key: string]: TPollOption }>({}); // list of pollOptions to be displayed
	// Use feedback state if exists, else use empty string
	// Fix for editing a poll, from results view
	const [feedbackText, setFeedbackText] = useState(data.statement || ''); // holds the text/ poll statement
	// index of selected option
	// taking this because if two pollOptions will have same text, we wont have any unique identifier
	const [selectedOptionId, setSelectedOptionId] = useState<string | undefined>();

	// extract current user
	const { user } = useUser();

	// extracting ids from url
	const { canvasId: blockId } = useParams<PathParamsType>();

	// extract all blocks from redux
	const allBlocks = useSelector((state) => (state as ReduxState).blocks.data, shallowEqual);

	// extract all user roles for current block
	const userRoles = allBlocks[blockId]?.users as TUserAndRole[];

	// extract all populated users based on ids
	const allUsers = getAllUsersBasedOnIds(userRoles.map((x: TUserAndRole) => x.user as string));

	const dispatch = useDispatch();

	const { activeFeedbackId, feedbackView } = useContext(FeedbackContext);

	// extarcting functions from feedback actions hook
	const { sendEditFeedback, getTaggedUsersFromHtmlString, sendVotePoll, extractTextFromHTML } =
		useFeedbackActions();

	/**
	 * Function to set poll options when component is mounted
	 */
	const setInitialPollOptions = () => {
		// Check if selected poll option is stored in session
		const selectedOptionIndex = Number(
			window.sessionStorage.getItem('selected-poll-option-index') || -1
		);
		if (!isUndefined(data.pollOptions)) {
			const tempPollOptions: { [key: string]: TPollOption } = {};
			data.pollOptions.forEach((option, index) => {
				const optionId = new mongoose.Types.ObjectId().toString();
				// If index is same as selected index, update the selected option id
				if (selectedOptionIndex === index) {
					setSelectedOptionId(optionId);
				}
				tempPollOptions[optionId] = option;
			});
			setPollOptions(tempPollOptions);
		}
	};

	// setting data to state variables
	useEffect(() => {
		setInitialPollOptions();
		if (data.statement) {
			setFeedbackText(data.statement);
		}
	}, [data]);

	/**
	 * Re-runs whenever poll component mounts
	 */
	useEffect(
		() =>
			// Clear the session selected option when options are closed
			() =>
				window.sessionStorage.removeItem('selected-poll-option-index'),
		[]
	);

	// send out an api call to update poll
	const updatePoll = () => {
		setIsEditing(false);

		if (feedbackText === '' || feedbackText === '<p><br></p>') {
			return;
		}
		// Filter the option who has length
		const tempOption = Object.values(pollOptions).filter((opt) => opt.option.length);
		// geenrating the api payload for edit action
		sendEditFeedback({
			_id: data._id as string,
			statement: feedbackText,
			taggedUsers: getTaggedUsersFromHtmlString(feedbackText),
			pollOptions: tempOption
		});
		setSelectedOptionId(undefined);

		// Clear the session selected option when options are submitted
		window.sessionStorage.removeItem('selected-poll-option-index');
	};

	// send out an api call to vote for poll
	const voteForPoll = () => {
		// adding vote to the pollOptions in state variable
		const tempOptions = cloneDeep(pollOptions);
		if (!isUndefined(selectedOptionId)) {
			const { votes, option } = tempOptions[selectedOptionId]!;
			votes.push({
				votedAt: new Date(),
				votedBy: user._id as string
			});
			tempOptions[selectedOptionId] = {
				option,
				votes
			};
		}

		const voteData = {
			option: tempOptions[selectedOptionId!]?.option as string,
			feedbackId: data._id as string
		};

		// Dispatch to redux and save to db
		sendVotePoll(voteData);

		setSelectedOptionId(undefined);
		setPollOptions(tempOptions);

		// Clear the session selected option when user casts vote
		window.sessionStorage.removeItem('selected-poll-option-index');

		dispatch({
			type: 'MARK_FB_NOTIF_COMP',
			payload: { markFeedbackAsComplete: data._id, isCompleted: true }
		});
	};

	// function to modify action item asigneed
	const onTagUser = (selectedIds: string[]) => {
		const tempList = actionItemAssignees;
		for (let i = 0; i < selectedIds.length; i++) {
			if (!actionItemAssignees.includes(selectedIds[i] as string)) {
				tempList.push(selectedIds[i] as string);
			}
		}
		setActionItemAsignees(tempList);
	};

	// Triggered when isEditing is changed
	useEffect(() => {
		// Clear all of the poll selection when it is in edit mode
		if (isEditing) {
			setSelectedOptionId(undefined);

			// Clear the session selected option when options when user starts editing a poll
			window.sessionStorage.removeItem('selected-poll-option-index');
		}
	}, [isEditing]);

	// function to extract all dupliacte pollOptions list
	const getDuplicateOptions = () => {
		const allOptions = Object.values(pollOptions).map((opt: TPollOption) => opt.option);
		const duplicates = allOptions.filter(
			(opt: string, index: number) => allOptions.indexOf(opt) !== index
		);

		return duplicates;
	};

	useEffect(() => {
		// If there are duplicate poll option then show indication snackbar
		if (getDuplicateOptions().length) {
			addSnackbar({
				text: 'Each poll option must have a unique value',
				type: 'ERROR',
				show: true
			});
			removeSnackbar(5000);
		}
	}, [pollOptions]);

	return (
		<>
			{isEditing ? (
				// showing input in edit state
				<FeedbackInput
					text={feedbackText}
					variant="BORDERLESS"
					onSubmit={updatePoll}
					onEsc={() => {
						setFeedbackText(data.statement || '');
					}}
					id={data._id as string}
					placeHolder="Add a question to create poll"
					onTagUser={onTagUser}
					users={getFormattedUsersData(allUsers, false).blockUsers as UserT[]}
					currentUser={getFormattedUsersData(allUsers, false).currentUser}
					onInputChange={(value: string) => {
						setFeedbackText(value);
					}}
					onShowReplyBtn={() => {}}
					key={data._id as string}
					className="input-poll-edit tw-pt-2"
					style={{ marginLeft: '-10px' }}
				/>
			) : (
				// showing normal text in non edit state
				<HtmlTextViewer
					text={feedbackText}
					className="tw-pt-4 tw-text-xs tw-mb-2"
					style={{ marginLeft: '6px' }}
				/>
			)}
			{
				/* This is added to collapse and expand the feedback in the feedback panel.
				 *  In collapse mode only question will be shown and in expanded mode full feedback wil be show.
				 */
				(activeFeedbackId === data._id || feedbackView === 'DEFAULT') && (
					<>
						{/* Render poll pollOptions */}
						<PollOptionsWrapper
							className="tw-pr-4"
							style={{ marginRight: '-1rem', marginLeft: '-4px' }}
						>
							{Object.keys(pollOptions).map((id: string, index: number) => (
								<PollOption
									placeHolder={`Option ${index + 1}`}
									className={
										getDuplicateOptions().includes(
											pollOptions[id]!.option.trim()
										)
											? 'option-error'
											: ''
									}
									text={pollOptions[id]!.option}
									onTextChange={(val: string) => {
										const temp = { ...pollOptions };
										temp[id] = {
											...temp[id],
											option: val.trim()
										} as TPollOption;
										setPollOptions(temp);
									}}
									onRemove={() => {
										const temp = { ...pollOptions };
										if (Object.keys(temp).length < 3) {
											temp[id] = { ...temp[id], option: '' } as TPollOption;
										} else {
											delete temp[id];
										}
										setPollOptions(temp);
									}}
									onSelect={() => {
										setSelectedOptionId(id);
										window.sessionStorage.setItem(
											'selected-poll-option-index',
											String(index)
										);
									}}
									isEditState={isEditing}
									isRequired={Object.keys(pollOptions).length === 2}
									isSelected={selectedOptionId === id}
									key={isEditing ? `poll-editing-${id}` : `poll-option-${id}`}
								/>
							))}
						</PollOptionsWrapper>
						{/* Show add pollOptions button if in edit state */}
						{isEditing && (
							<Button
								variant="SECONDARY"
								text="+ Add option"
								className="tw-mt-2"
								disabled={false}
								onBtnClick={() => {
									setPollOptions({
										...pollOptions,
										[new mongoose.Types.ObjectId().toString()]: {
											option: '',
											votes: []
										}
									});
								}}
							/>
						)}
						{/* if in edit state, show save and cancel pollOptions */}
						{isEditing ? (
							<div className="tw-flex tw-mb-4 tw-justify-end">
								<Button
									variant="SECONDARY-GREY"
									text="Cancel"
									disabled={false}
									className="tw-mr-2"
									onBtnClick={() => {
										setInitialPollOptions();
										setIsEditing(false);
										setFeedbackText(data.statement || '');
										setIsEditing(false);
									}}
								/>
								<Button
									variant="PRIMARY"
									text="Save"
									disabled={
										extractTextFromHTML(feedbackText).length === 0 ||
										getDuplicateOptions().length > 0 ||
										Object.values(pollOptions).filter(
											(opt) => opt.option.length
										).length < 2
									}
									onBtnClick={updatePoll}
								/>
							</div>
						) : (
							// if not i edit state, show vote, cancel and show results button
							<div className="tw-flex tw-justify-between tw-items-center tw-mt-4">
								<Button
									variant="SECONDARY"
									text="View results"
									disabled={false}
									onBtnClick={() => {
										showResultsView(true);
									}}
								/>
								<div className="tw-flex">
									<Button
										variant="SECONDARY-GREY"
										text="Cancel"
										disabled={false}
										className="tw-mr-2"
										onBtnClick={() => {
											setSelectedOptionId(undefined);
											window.sessionStorage.removeItem(
												'selected-poll-option-index'
											);
										}}
									/>
									<Button
										variant="PRIMARY"
										text="Vote"
										disabled={!(typeof selectedOptionId === 'string')}
										onBtnClick={voteForPoll}
									/>
								</div>
							</div>
						)}
					</>
				)
			}
		</>
	);
};
