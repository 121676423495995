import * as PIXI from 'pixi.js';
import {
  INode, IUser, TAbsoluteBoundingBox, TextDecorationValue, TextStyleExtended,
} from '@naya_studio/types';
import TaggedText from '@naya_studio/pixi-tagged-text';
// import Polygon from './Polygon';
// import Shape from './Shape';
import { TextStyleSet } from '@naya_studio/pixi-tagged-text/dist/types';
import App from '../App';
import BaseNode from './BaseNode';
import Text from './Text';
import TextInputNode, { DOMStyleExtended } from './TextInputNode';
import StickyNoteShadow from '../../../../assets/images/sticky-note-shadow.png';
import {
  positionAuthorImage, positionAuthorFill, updateTags, getAuthorImageTexture, removeAttributesAtRanges,
} from './utils/helper';
import { alignTypes } from '../../editMenu/MenuOptions/Align/constants';

/**
 * Sticky Note Node
 * The Sticky Note Node is a special type of node that can be used to create sticky notes.
 * It combines the functionality of a Shape and a Text node.
 * It uses a TextInputNode to enable editing.
 */
class StickyNote extends BaseNode {
  protected double?: ReturnType<typeof setTimeout>;

  private _clicked: boolean;

  // private _editMode: boolean
  private _textNode: TaggedText | undefined;

  private _shapeNode: PIXI.Sprite | PIXI.Graphics | undefined;

  private _shadowNode: PIXI.Sprite | undefined;

  _textInputNode: TextInputNode = this.app.textInputNode;

  _defaultTransformerType: 'default' | 'crop' | 'text' | 'sticky' = 'sticky';

  _currentTransformerType: 'default' | 'crop' | 'text' | 'sticky' = 'sticky';

  static defaultStickyNodeProps = {
    padding: {
      x: 10,
      y: 10,
    },
    width: 125,
    height: 125,
  };

  static shadowTexture: PIXI.Texture | undefined;

  /**
   * StickyNote constructor
   * @param app Canvas Application
   * @param nodeData Sticky Note Node Data
   */
  constructor(app: App, nodeData: INode) {
    super(app, nodeData);
    this._clicked = false;
    this._editMode = false;
    this.draw.bind(this);
  }

  /**
   * Returns the text node of the sticky note
   */
  get textNode() {
    return this._textNode;
  }

  /**
   * Returns the click state of the sticky note
   */
  get click() {
    return this._clicked;
  }

  /**
   * Allows to set the click state of the sticky note
   */
  set click(clicked: boolean) {
    this._clicked = clicked;
  }

  static async getShadowTexture() {
    if (!StickyNote.shadowTexture) {
      if ('stickyShadow' in PIXI.Loader.shared.resources) {
        StickyNote.shadowTexture = PIXI.Loader.shared.resources.stickyShadow?.texture;
      } else {
        StickyNote.shadowTexture = await PIXI.Texture.fromURL(StickyNoteShadow);
      }
    }
    return StickyNote.shadowTexture;
  }

  /// ////////////////////////////
  // STICKY NOTE NODE METHODS ///
  /// ////////////////////////////

  /**
   * Enables editing of the sticky note properties
   * @param type Type of edit
   * @param data Payload
   */
  edit = async (type: string, data: any) => {
    if (!this._editMode) this.app.removeTransformer();
    this.prevNodeData = JSON.parse(JSON.stringify(this.nodeData));
    const currentText = this.nodeData.text?.value;
    const domStyle: DOMStyleExtended = {};
    const trixNode = this.app.textInputNode._domInput;
    // @ts-ignore
    const trixEditor = trixNode?.editor;
    const [start, end] = trixEditor.getSelectedRange();
    const isEntireTextSelected = !!(this._editMode && (start === 0) && (end === trixEditor.getDocument().getLength() - 1));
    // const trixEditor = trixNode?.editor;
    switch (type) {
      case 'AUTHOR':
        this.nodeData.showAuthor = data;
        break;
      case 'ZINDEX':
        this.nodeData.zIndex = data;
        break;
      case 'FILL':
        if (this.nodeData.fill) {
          this.nodeData.fill.fillColor = data;
        }
        break;
      case 'FONT_SIZE':
        if (!this._editMode) {
          removeAttributesAtRanges(trixNode, this.nodeData, [{ isBlock: false, value: 'fontSize' }]);
          if (this.nodeData.text?.style) this.nodeData.text.style.fontSize = data;
          domStyle.fontSize = `${data}px`;
        } else {
          const zoomLevel = this.app.viewport.scale.x;
          let updatedFontSize = data;
          if (zoomLevel) updatedFontSize *= zoomLevel;
          trixEditor.activateAttribute('fontSize', `${updatedFontSize}px`);
          this._textInputNode?.onTextStyleChange();
          if (isEntireTextSelected && this.nodeData.text?.style) {
            this.nodeData.text.style.fontSize = data;
          }
        }
        break;
      case 'LIST':
        if (!this._editMode) {
          removeAttributesAtRanges(
            trixNode,
            this.nodeData,
            ['bullet', 'number']
              .map((a) => ({ isBlock: true, value: a })) as [{ isBlock: boolean, value: string }],
          );
          if (this.nodeData.text?.style) this.nodeData.text.style.listType = data;
          domStyle.listType = data;
        } else {
          // @ts-ignore
          const currentAttributes = trixNode?.editorController.composition.currentAttributes;
          let currentListStyle;
          if (currentAttributes.bullet) currentListStyle = 'bullet';
          else if (currentAttributes.number) currentListStyle = 'number';
          if (!currentListStyle) {
            const topListType = this.nodeData.text?.style?.listType;
            if (!topListType || topListType === 'none') trixEditor.activateAttribute('bullet');
            else if (topListType === 'unordered') trixEditor.activateAttribute('number');
            else {
              trixEditor.deactivateAttribute('bullet');
              trixEditor.deactivateAttribute('number');
            }
          } else if (currentListStyle === 'bullet') {
            trixEditor.activateAttribute('number');
          } else if (currentListStyle === 'number') {
            trixEditor.deactivateAttribute('number');
          }
        }
        break;
      case 'ALIGN':
        if (!this._editMode) {
          removeAttributesAtRanges(
            trixNode,
            this.nodeData,
            alignTypes.map((at) => ({ isBlock: true, value: `${at}Align` })) as [{ isBlock: boolean, value: string }],
          );
          if (this.nodeData.text?.style) this.nodeData.text.style.align = data;
          domStyle.textAlign = data;
        } else {
          // @ts-ignore
          const currentAttributes = trixNode?.editorController.composition.currentAttributes;
          let currentAlign;
          if (currentAttributes.rightAlign) currentAlign = 'right';
          else if (currentAttributes.leftAlign) currentAlign = 'left';
          else if (currentAttributes.centerAlign) currentAlign = 'center';
          if (!currentAlign) {
            trixEditor.activateAttribute(`${data}Align`);
          } else if (currentAlign === 'left') {
            trixEditor.activateAttribute('centerAlign');
            trixEditor.deactivateAttribute('leftAlign');
          } else if (currentAlign === 'right') {
            trixEditor.activateAttribute('leftAlign');
            trixEditor.deactivateAttribute('rightAlign');
          } else if (currentAlign === 'center') {
            trixEditor.activateAttribute('rightAlign');
            trixEditor.deactivateAttribute('centerAlign');
          }
          this._textInputNode?.onTextStyleChange();
        }
        break;
      case 'FONT_FORMAT':
        if (!this._editMode) {
          removeAttributesAtRanges(trixNode, this.nodeData, [{ isBlock: false, value: data.cmd }]);
          if (this.nodeData.text?.style) {
            const { fontStyle, fontWeight, textDecoration } = this.nodeData.text.style;
            if (data.htmlTag === 'u') {
              const domValue = (!textDecoration || textDecoration === 'none') ? 'underline' : 'none';
              domStyle.textDecoration = domValue;
              this.nodeData.text.style.textDecoration = domValue as TextDecorationValue;
            } else if (data.htmlTag === 'i') {
              const domValue = (!fontStyle || fontStyle === 'normal') ? 'italic' : 'normal';
              domStyle.fontStyle = domValue;
              this.nodeData.text.style.fontStyle = domValue as PIXI.TextStyleFontStyle;
            } else {
              const domValue = (!fontWeight || fontWeight === 'normal') ? 'bold' : 'normal';
              domStyle.fontWeight = domValue;
              this.nodeData.text.style.fontWeight = domValue as PIXI.TextStyleFontWeight;
            }
          }
        } else {
          // @ts-ignore
          const currentAttribute = trixNode?.editorController.composition.currentAttributes[data.cmd];
          if (currentAttribute && currentAttribute === data.value) {
            trixEditor.deactivateAttribute(data.cmd);
          } else {
            trixEditor.activateAttribute(data.cmd, data.value);
          }
          this._textInputNode?.onTextStyleChange();
        }
        break;
      case 'FONT':
        if (!this._editMode) {
          // this.nodeData.text!.value = currentText.replace(/font-family: [a-zA-Z-_]+/gm, '');
          removeAttributesAtRanges(trixNode, this.nodeData, [{ isBlock: false, value: 'fontFamily' }]);
          if (this.nodeData.text?.style) {
            this.nodeData.text.style.fontFamily = data;
          }

          domStyle.fontFamily = data;
        } else {
          // document.execCommand("fontName", false, data);
          trixEditor.activateAttribute('fontFamily', data);
          this._textInputNode?.onTextStyleChange();
          if (isEntireTextSelected && this.nodeData.text?.style) {
            this.nodeData.text.style.fontFamily = data;
          }
        }
        break;
      case 'LINK':
        if (!this._editMode && currentText) {
          removeAttributesAtRanges(
            trixNode,
            this.nodeData,
            ['foreColor', 'textDeco', 'href'].map((a) => ({ isBlock: false, value: a })) as [{ isBlock: boolean, value: string }],
          );
          if (this.nodeData.text?.style) {
            if (data && data !== '') {
              // this.nodeData.text.style.fill = this.app.theme.color;
              // this.nodeData.text.style.textDecoration = 'underline';
              // this.nodeData.text.style.linkURL = data;
              domStyle.color = this.app.theme.color;
              domStyle.textDecoration = 'underline';
            } else {
              this.nodeData.text.style.fill = '#000000';
              this.nodeData.text.style.textDecoration = 'none';
              this.nodeData.text.style.linkURL = '';
              domStyle.color = '#000000';
              domStyle.textDecoration = 'none';
            }
            this.nodeData.text.style.linkURL = data;
          }
        } else {
          trixEditor.activateAttribute('href', data);
          this._textInputNode?.onTextStyleChange();
          if (this.nodeData.text?.style?.linkURL) {
            this.nodeData.text.style.linkURL = '';
          }
        }
        break;
      default:
        break;
    }

    if (!this._editMode) {
      await this.draw();
    } else {
      this._textInputNode?.updateDOMNodeStyle(domStyle);
      this.updateHeight();
    }
  };

  /**
   * Change the text in the sticky note
   * @param text Text to be set
   */
  setText = async (text: string, id: string) => {
    this.app.isColourPickerOpen = false;
    if(id===this.nodeData._id){
      const nodeData = { ...this.nodeData };
      const newText = { value: text, style: { ...this.nodeData?.text?.style } };
      nodeData.text = newText;
      this.nodeData = nodeData;
      // this.selectNode();
      // this.app.toggleEditMenu(false);
      // this.app.toggleEditMenu(true);
      this.app.keyboardLock = true;
    }
    // if (this._textNode) {
    //   this._textNode.visible = true;
    //   this._textNode.text = text;

    //   const { textContainer } = this._textNode;
    //   const textHeight = textContainer.height || 0;
    //   const textWidth = textContainer.width || 0;
    //   if (textWidth > StickyNote.defaultStickyNodeProps.width) {
    //     this._shapeNode!.width = textWidth
    //       + StickyNote.defaultStickyNodeProps.padding.x * 2;
    //   }
    //   if (textHeight > StickyNote.defaultStickyNodeProps.height) {
    //     this._shapeNode!.height = textHeight
    //     + StickyNote.defaultStickyNodeProps.padding.y * 2;
    //   }
    //   // this.render();
    //   this._textInputNode?.redraw();
    //   this._textNode.visible = false;
    // }
    // await this.draw()
    // this._textInputNode?.draw()
    // // this.app.setSelected(this)
    // this.render()
    // this.onMouseOut()
  };

  /**
   * update the height of background
   * @returns
   */
  updateHeight = () => {
    const { scrollHeight, style } = this._textInputNode!._domInput!;
    const { paddingTop, paddingBottom } = style;
    const textInputHeight = scrollHeight - parseFloat(paddingTop) - parseFloat(paddingBottom);
    const currHeight = this._shapeNode?.getBounds().height;
    const zoomLevel = this.app.viewport.scale.x;
    if (currHeight
      && textInputHeight !== currHeight) {
      this._shapeNode!.height = (textInputHeight + 5) / zoomLevel;
      this._shadowNode!.height = ((textInputHeight + 5) / zoomLevel) + 20;
    }
  };

  /// ////////////////////////////////
  // STICKY NOTE EVENT PROCESSORS ///
  /// ////////////////////////////////

  /**
   * Function to start the edit mode
   */
  onEditStart = () => {
    this.app.isColourPickerOpen = false;
    if (this._editMode) { return; }
    // if (this.displayObject) this.displayObject.cacheAsBitmap = false;
    this.app.disableHighlighting();
    this.app.toggleEditMenu(true);
    this.app.keyboardLock = true;
    this.onMouseOut();
    this.app.removeTransformer();

    this.app.editText = true;
    this._editMode = true;
    this.app.disablePaste = true;
    this._textNode!.visible = false;
    this._textInputNode.setParentNode(this);
    this.app.viewport.on('mousedown', this.onEditEnd);
    this.app.viewport.on('node-selected', (nodesSelected: BaseNode[]) => {
      if (nodesSelected.filter((node) => node.nodeData._id === this.nodeData._id).length === 0) {
        this.onEditEnd(new Event('click'));
      }
    });
    this._textInputNode.on('keyup', this.setText);
    this.prevNodeData = JSON.parse(JSON.stringify(this.nodeData));
    this._textInputNode.draw();
    this.updateHeight();
    // this._textInputNode.selectAllText();
  };

  /**
   * Function to end the edit mode
   * @param _ Ignored mouse event
   */
  onEditEnd = (e: Event) => {
    if (!this._editMode) { return; }
    this.app.enableHighlighting();
    this.app.keyboardLock = false;
    this.setText(this._textInputNode!.getDOMInputText(), this._textInputNode.getParentNodeId() as string);
    this.app.resumeDragPlugin();
    this.onMouseOut();
    this.app.viewport.emit('mouseup', e);
    this.app.editText = false;
    this._editMode = false;
    this.app.disablePaste = false;
    this._textNode!.visible = true;

    this.app.viewport.off('click', this.onEditEnd);
    this.app.viewport.off('node-selected');
    this._textInputNode?.removeDOMElement();
    this.nodeData.version = (this.nodeData.version || 0) + 1 // version should increase when sticky note comes out of edit mode
    this.draw();
    this.save();
  };

  onMouseMove = (e: PIXI.InteractionEvent) => {
    if (this.app.selectedNodes.includes(this) && this.app.selectedNodes.length === 1) {
      // Gives position of mouse relative to the top left corner of the sticky note

      const mousePosition = this.app.viewport.toWorld(e.data.global.x, e.data.global.y);
      const displayObjectPosition = {
        x: this.displayObject?.position.x,
        y: this.displayObject?.position.y,
      };
      const relativePos = {
        x: mousePosition.x - (displayObjectPosition.x || 0),
        y: mousePosition.y - (displayObjectPosition.y || 0),
      };
      // To figure out what direction the mouse is relative to the sticky note
      let REL_HORIZONTAL: 'LEFT' | 'NEITHER' | 'RIGHT' = 'NEITHER';
      let REL_VERTICAL: 'TOP' | 'NEITHER' | 'BOTTOM' = 'NEITHER';

      if (relativePos) {
        if (relativePos.x < 0) {
          REL_HORIZONTAL = 'LEFT';
        } else if (relativePos.x > (this.displayObject?.width || 200)) {
          REL_HORIZONTAL = 'RIGHT';
        } else {
          REL_HORIZONTAL = 'NEITHER';
        }

        if (relativePos.y < 0) {
          REL_VERTICAL = 'TOP';
        } else if (relativePos.y > (this.displayObject?.height || 200)) {
          REL_VERTICAL = 'BOTTOM';
        } else {
          REL_VERTICAL = 'NEITHER';
        }
      }

      // Simplify direction for switch cases
      const BUTTON_POS = `${REL_HORIZONTAL !== 'NEITHER' ? REL_HORIZONTAL : ''}${REL_VERTICAL !== 'NEITHER' ? REL_VERTICAL : ''}`;
      this.app.btnPos = BUTTON_POS;
      // Set coordinates for horizontal hovering stickynote
      const x1 = (this.displayObject?.x || 0);
      const y1 = (this.displayObject?.y || 0) + (this.displayObject?.height || 0) / 2 - 20;
      const showButtons = true;
      if (['TOP', 'BOTTOM', 'LEFT', 'RIGHT'].includes(BUTTON_POS)) { this.app.togglePlus(true); } else { this.app.togglePlus(false); }

      // If it did not belong to any of the directions above, do not show the buttons
      if (!showButtons) return;
      const btnpos1 = this.app.viewport.toScreen(x1, y1);
      this.app.horizontalHoverStickyNodeX = btnpos1.x;
      this.app.horizontalHoverStickyNodeY = btnpos1.y;
    }
  };

  /**
   * Function to track double click events
   * @param e Mouse Event
   */
  onDoubleClick = () => {
    if (!this._isLocked) { this.onEditStart(); }
  };
  // transformerClick = (e: InteractionEvent) => {
  //   if (e)
  //     e.stopPropagation()
  //   if (this.app.selectedNodes.length === 1) {
  //     if (!this.click) {
  //       this.click = true

  //       this.double = setTimeout(() => {
  //         this.click = false
  //       }, 200)
  //     } else {
  //       this.click = false
  //       this.double && clearTimeout(this.double)
  //       if (!this._isLocked) {
  //         this.onEditStart()
  //         this.app.removeTransformer();
  //         this.click = false
  //         // this.app.removeTransformer({ events: ['click'] })
  //       }
  //     }
  //   }
  // }

  drawText = (value: string, fontData: TextStyleExtended) => {
    const { linkURL } = fontData;
    const { padding, width } = StickyNote.defaultStickyNodeProps;
    const { x: left, y: top } = padding;

    let finalText = `${updateTags(value)}`;
    if (linkURL && linkURL !== '') finalText = `<a href="${linkURL}">${finalText}</a>`;
    const defaultStyle = {
      lineHeight: 1.5,
      align: this.nodeData.text?.style?.align,
      wordWrapWidth: width,
    };
    const tagStyles: TextStyleSet = {
      default: defaultStyle,
      // @ts-ignore
      topLevel: { ...Text.textNodeDefaults.style, ...this.nodeData.text?.style },
      a: { fill: '#007bff', textDecoration: 'underline' },
      i: { fontStyle: 'italic' },
      b: { fontWeight: 'bold' },
      u: { textDecoration: 'underline' },
      font: {},
      ul: { listType: 'unordered' },
      ol: { listType: 'ordered' },
      li: {},
      span: {},
      em: { fontStyle: 'italic' },
      strong: { fontWeight: 'bold' },
      leftalign: { align: 'left' },
      rightalign: { align: 'right' },
      centeralign: { align: 'center' },
    };
    let text = new TaggedText(
      `<topLevel>${finalText}</topLevel>`,
      tagStyles,
      {
        drawWhitespace: true,
      },
    );

    text.x = left;
    text.y = top;
    const { textContainer } = text;
    if (textContainer.width > width) {
      defaultStyle.wordWrapWidth = textContainer.width;
      text = new TaggedText(
        `<topLevel>${finalText}</topLevel>`,
        tagStyles,
        {
          drawWhitespace: true,
        },
      );
    }
    const linkContainers = (textContainer as PIXI.Container).children
      .filter((c: any) => c.name === 'Link');
    const { linkURLs } = text;
    linkContainers.forEach((lc: PIXI.DisplayObject, index: number) => {
      lc.interactive = true;
      lc.on('mouseover', () => {
        if (this.app.textLinkTooltip.timeoutId !== -1) clearTimeout(this.app.textLinkTooltip.timeoutId);
        // if (this.app.textLinkTooltip.activeNode) return;
        const { pixiContainer } = this.app.textLinkTooltip;
        // let { url } = this.app.textLinkTooltip
        if (linkURLs && linkURLs.length > 0 && index < linkURLs.length) {
          this.app.textLinkTooltip.url = linkURLs[index] || '';
          this.app.textLinkTooltip.index = index;
        }
        const { x, y } = this.nodeData.absoluteBounds!;
        const {
          x: linkX, y: linkY, width: linkWidth, height: linkHeight,
        } = lc.getLocalBounds();
        pixiContainer.x = x! + left + linkX + linkWidth / 2 - pixiContainer.width / 2;
        // to use this when there are links in between text
        pixiContainer.y = y! + top + linkY + linkHeight + top + 6;
        // pixiContainer.y = y! + height! + 1;
        if (!pixiContainer.parent) this.app.viewport.addChild(pixiContainer);
        pixiContainer.visible = true;
        this.app.textLinkTooltip.activeNode = this;
        this.app.textLinkTooltip.isPixiContainerHovered = true;
      });
      lc.on('mouseout', () => {
        this.app.textLinkTooltip.isPixiContainerHovered = false;
        this.app.textLinkTooltip.timeoutId = setTimeout(() => {
          if (!this.app.textLinkTooltip.isHovered) {
            this.app.textLinkTooltip.pixiContainer.visible = false;
            this.app.textLinkTooltip.activeNode = null;
            this.app.textLinkTooltip.url = '';
            this.app.textLinkTooltip.isHovered = false;
            this.app.render();
          }
        }, 3000);
      });
    });
    return text;
  };

  copyText = () => {
    let returnText = '';
    const tokens = this._textNode?.tokens;
    if (tokens) {
      tokens.forEach((token) => {
        token.forEach((t) => {
          t.forEach((tt) => {
            returnText += tt.content;
          });
        });
      });
    }
    return returnText;
  };

  /// ///////////////////////////////
  // STICKY NOTE RENDER METHODS ///
  /// //////////////////////////////
  /**
   * Clears and renders the node
   */
  draw = async () => {
    try {
      if (this.displayObject) {
        this.app.viewport.removeChild(this.displayObject);
      }

      if (this.nodeData) {
        const container = new PIXI.Container();
        const { padding } = StickyNote.defaultStickyNodeProps;

        /// /////////////////////////
        /// Draw Text Node First ///
        /// /////////////////////////

        let stickyNoteText = 'Add Text';
        if (
          this.nodeData
          && this.nodeData.text
          && this.nodeData.text.value
          && this.nodeData.text.value.length > 0
        ) {
          stickyNoteText = this.nodeData.text.value;
        }

        const fontData: TextStyleExtended = {
          fill: '#888888',
          align: 'left',
          fontSize: 16,
          fontWeight: 'normal',
          ...this.nodeData.text?.style,
        };

        this._textNode = this.drawText(stickyNoteText, fontData);
        this._textNode.interactive = false;
        const { textContainer } = this._textNode;
        const textHeight = textContainer.height || 0;
        const textWidth = textContainer.width || 0;

        this._textNode.x = padding.y;
        this._textNode.y = padding.x;

        const defaultAbsoluteBounds = {
          x: 0,
          y: 0,

          height: Math.max(textHeight, StickyNote.defaultStickyNodeProps.height) + padding.y * 2
            + (this.nodeData.showAuthor ? 20 : 0),
          width: Math.max(textWidth, StickyNote.defaultStickyNodeProps.width) + padding.x * 2,
        };
        this._shapeNode = new PIXI.Sprite(PIXI.Texture.WHITE);
        this._shapeNode.tint = BaseNode.getColorValue(this.nodeData.fill ? this.nodeData.fill.fillColor : '#FFDB41');
        this._shapeNode.width = defaultAbsoluteBounds.width;
        this._shapeNode.height = defaultAbsoluteBounds.height;

        /// ////////////////////////////

        this._shapeNode.off('click');
        this._shapeNode.interactive = false;
        this._textNode.interactive = false;

        if (this._textNode) {
          this._textNode.off('click');
        }

        // ====================================================================

        if (this._editMode) {
          this._textNode!.visible = false;
        }

        // if (
        //   (this._textNode).textHeight
        //   > StickyNote.defaultStickyNodeProps.height
        // ) {
        //   this._shapeNode.displayObject!.height = this._textNode
        //     .textHeight + padding.y + extraPadding;
        // }

        // ====================================================================

        const shadowNode = StickyNote.shadowTexture
          ? new PIXI.Sprite(StickyNote.shadowTexture)
          : new PIXI.Sprite(await StickyNote.getShadowTexture());
        // shadowNode.setTransform(
        //   -10,
        //   -10,
        // );
        this._shapeNode.x += 10;
        this._shapeNode.y += 10;
        this._textNode.x += 10;
        this._textNode.y += 10;
        shadowNode.width = this._shapeNode!.width + 20;
        shadowNode.height = this._shapeNode!.height + 20;
        shadowNode.interactive = false;
        this._shadowNode = shadowNode;

        container.addChild(this._shadowNode);
        container.addChild(this._shapeNode!);
        container.addChild(this._textNode!);
        // ====================================================================

        // Add author if required
        if (this.nodeData.showAuthor) {
          const createdBy = this.nodeData.createdBy as IUser;
          const { profilePic: profilePicURL, userName } = createdBy;

          let showAuthorSprite = false;
          let showAuthorFill = false;
          if (this.author && this.author.image && this.author.circularMask) {
            showAuthorSprite = true;
            this.author.circularMask.clear();
          } else if (this.pixiAuthor && this.pixiAuthor.graphics) {
            showAuthorFill = true;
            this.pixiAuthor.graphics.clear();
          } else {
            const imageTexture = profilePicURL
              ? getAuthorImageTexture(profilePicURL.toString()) : null;
            if (imageTexture) {
              showAuthorSprite = true;
              const authorImage = new PIXI.Sprite(imageTexture);
              const circularMask = new PIXI.Graphics();
              // authorImage.mask = circularMask;
              this.author = {
                image: authorImage,
                circularMask,
              };
            } else if (userName && userName.length > 0) {
              const circleFill = new PIXI.Graphics();
              const circleText = new PIXI.Text(userName.toUpperCase()[0]!);
              this.pixiAuthor = {
                graphics: circleFill,
                text: circleText,
              };
              showAuthorFill = true;
            }
          }

          if (showAuthorSprite) {
            positionAuthorImage(
              this.author!.image,
              this.author!.circularMask,
              {
                width: defaultAbsoluteBounds.width,
                height: defaultAbsoluteBounds.height,
                x: 0,
                y: 0,
                scaleX: 1,
                scaleY: 1,
                rotation: 0,
              },
              0.1,
            );
            this.author!.image.zIndex = Number.MAX_VALUE;
            container.addChild(this.author!.image);
            // container.addChild(this.author!.circularMask);
          } else if (showAuthorFill) {
            positionAuthorFill(
              this.pixiAuthor!.graphics,
              this.pixiAuthor!.text,
              {
                width: defaultAbsoluteBounds.width,
                height: defaultAbsoluteBounds.height,
                x: 0,
                y: 0,
                scaleX: 1,
                scaleY: 1!,
                rotation: 0,
              },
              0.1,
            );
            this.pixiAuthor!.graphics.zIndex = Number.MAX_VALUE;
            container.addChild(this.pixiAuthor!.graphics);
          }
        }

        const absoluteBounds = this.nodeData.absoluteBounds as TAbsoluteBoundingBox;

        container.setTransform(absoluteBounds!.x!, absoluteBounds!.y!);

        if (this.nodeData.opacity) {
          container.alpha = this.nodeData.opacity;
        }

        container.zIndex = this.nodeData.zIndex || 1;

        /** Prepare to add to container */
        this.displayObject = container;
        // this.displayObject.cacheAsBitmap = true;
        // this.displayObject.name = textData.text?.value!
        this.displayObject.name = 'STICKY_NOTE';
        this.displayObject.interactive = this.app.checkIfNodesShouldBeInteractive();

        // this.displayObject.cacheAsBitmap = true;
        // this.displayObject.cacheAsBitmapResolution = 4;

        this.app.viewport.addChild(this.displayObject);
        // this.displayObject.on('click', this.onClick)
        this.app.stickyNoteWidth = this.displayObject.width;
        this.app.stickyNoteHeight = this.displayObject.height;
        this.app.stickyNoteData = this;
        this.displayObject.on('rightclick', this.rightClick);
        this.displayObject.hitArea = new PIXI.Rectangle(
          0,
          0,
          this.displayObject.width,
          this.displayObject.height,
        );
        this.displayObject.on('mousedown', this.onMouseDown);
        this.displayObject.on('mouseup', this.onMouseUp);
        this.displayObject.on('mouseover', this.onMouseOver);
        this.displayObject.on('mouseout', this.onMouseOut);
        this.displayObject.on('mousemove', this.onMouseMove);
      }
    } catch (e) {
      console.error('UNABLE TO DRAW STICKY NOTE', e);
    }
  };
}

export default StickyNote;
