import {
	ICanvas,
	IFeedback,
	IGroup,
	IUser,
	INode,
	IBlock,
	IImage,
	I3D,
	IProject,
	IFile,
	ILink,
	EBlockType,
	IVideo,
	IPdf,
	INote
} from '@naya_studio/types';
import { store } from 'src';
import { cloneDeep } from 'lodash';
import BaseNode from 'src/components/canvas/app/nodes/BaseNode';
import { detailedDiff } from 'deep-object-diff';
import getUserFromRedux from 'src/util/helper/user';
import { ReduxMongoStateUnion } from '../reducers/root.types';
import { FindAndActionWithParentId, FindInArrayAndActionTuple } from './types';
import { addSnackbar, removeSnackbar } from './snackBar';
import { generateIdsFromUrl, getStageBlocks } from '../reduxActions/util';

/**
 *
 * @param stages store.getState().project.stages
 * @param canvasId the canvasId used for searching for the stage
 * @returns the parent stage where canvasId lives
 */
export const findStageWithBlockId = (blockId: string) => {
	const reduxState = store.getState();
	const { stages, blocks } = reduxState;
	const { data: allGroups } = stages;
	const { data: allBlocks } = blocks;

	const block = allBlocks[blockId];

	return block ? allGroups[block.parentId] : null;
};

export const fetchBlockByIdFromRedux = (blockId: string) => {
	const state = store.getState();
	const { blocks } = state;
	const { data } = blocks;
	return data[blockId];
};

export const fetchNodeByIdFromRedux = (nodeId: string) => {
	const state = store.getState();
	const { nodes } = state;
	const { data } = nodes;
	return data[nodeId];
};

export const fetchNoteByIdFromRedux = (noteId: string) => {
	const state = store.getState();
	const { notes } = state;
	const { data } = notes;
	return data[noteId];
};

/**
 *
 * Secondary return function which looks for _id in the array and update the object
 * @returns new updated array if corresponding item exists within the array, else returns old array
 */
const findWithIdAndUpdateInArray = <T extends ReduxMongoStateUnion>([
	data,
	array
]: FindInArrayAndActionTuple<T>): T[] => {
	const id = data._id as string;

	// finds the old node and it's index using _id
	const dataIndex = array.findIndex((n) => n._id === id);

	// returns original array if no _id is found.
	if (dataIndex < 0) {
		array.push(data);
		return array;
	}

	const oldData = array[dataIndex];

	// update old data with new data
	const newData: T = {
		...oldData,
		...data
	};

	array[dataIndex] = newData;
	return array;
};

/**
 * @param Tuple Takes a tuple of [data, dataArray, index]
 * Where data is the data you want to insert, dataArray is the array to insert into, and index is where to insert into (mainly used with ).
 * @returns new array or error if no Id and index
 */
export const findAndUpdateInArray = <T extends ReduxMongoStateUnion>([data, array, index = -1]:
	| FindInArrayAndActionTuple<T>
	| [T, T[], number]): T[] => {
	if (index < 0 && data._id) {
		return findWithIdAndUpdateInArray<T>([data, array]);
	}
	if (!data._id) {
		return array;
	}

	// finds the old node and it's index using _id
	const oldData = array[index];

	// update old data with new data
	const newData: T = {
		...oldData,
		...data
	};

	array[index] = newData;
	return array;
};

/**
 * Update a specific stage
 */
export const findAndUpdateStageInArray = ([stage, allStages]: [IGroup, IGroup[]]): IGroup[] => {
	const stageId = stage._id;
	let stageIndex = 0;
	for (let i = 0; i < allStages.length; i++) {
		if (allStages[i]?._id === stageId) {
			stageIndex = i;
		}
	}

	allStages[stageIndex] = stage;

	const newArray = allStages;

	return newArray;
};

/**
 *
 * @param Tuple [ data, dataArray ] = data is the item to delete (must have ._id property) and dataArray is where the data lives
 * @returns new array
 */
export const findAndDeleteInArray = <T extends ReduxMongoStateUnion>([
	data,
	dataArray
]: FindInArrayAndActionTuple<T>): T[] => {
	const id = data._id as string;
	// create deep copy
	const array = [...dataArray];
	const dataIndex = array.findIndex((n) => n._id === id);

	// removes data from array
	array.splice(dataIndex, 1);

	return array;
};

/**
 * Find and update the reply with help from parentId
 * @param Tuple [ reply, canvasComments, parentId ] - reply to
 * update (must have ._id property) and canvasComments is ICanvas.comments,
 *  parentId to help the search
 */
export const findAndUpdateInCommentsWithParentId = ([
	reply,
	canvasComments,
	parentId
]: FindAndActionWithParentId<IFeedback>) => {
	const oldParent = canvasComments.find((c) => c._id === parentId);
	const oldReplies = oldParent?.replies as IFeedback[];
	const oldReply = oldReplies.find((c) => c._id === reply._id);

	// create update to reply
	const newReply = {
		...oldReply,
		...reply
	};

	// update new comment in replies
	const replies = findAndUpdateInArray([newReply, oldReplies]);

	// add new replies to parent
	const newParent = {
		...oldParent,
		replies
	};

	// update parent in comments
	const newCanvasComments = findAndUpdateInArray([newParent, canvasComments]);

	return newCanvasComments;
};

/**
 * Find and update any comment in canvasComments
 * @param Tuple [ comment, canvasComments ] = comment to update (must have ._id property) and canvasComments is ICanvas.comments
 * @returns An updated ICanvas.comments
 */
// TODO: not used anywhere. check before used
export const findAndUpdateInComments = ([
	comment,
	canvasComments
]: FindInArrayAndActionTuple<IFeedback>): IFeedback[] => {
	const commentId = comment._id as string;

	let oldComment: IFeedback = {};
	let parentReplies: IFeedback[] = [];
	let parentId: string = '';

	// find and search through every comment for a possible hit
	// eslint-disable-next-line
	parentLoop: for (const parentComment of canvasComments) {
		// if the parent comment matches, break
		if (parentComment._id === commentId) {
			oldComment = parentComment;
			break;
			// check if comment has replies
		} else if (parentComment.replies && parentComment.replies.length > 0) {
			// check the replies for a possible match
			// eslint-disable-next-line no-restricted-syntax
			for (const replyComment of parentComment.replies as IFeedback[]) {
				if (replyComment._id === commentId) {
					oldComment = replyComment;
					// save parent replies and ID if success and break entire loop
					parentReplies = parentComment.replies as IFeedback[];
					parentId = parentComment._id as string;
					// eslint-disable-next-line no-labels
					break parentLoop;
				}
			}
		}
	}

	// merge updates with oldComment
	const newComment: IFeedback = {
		...oldComment,
		...comment
	};

	let newCanvasComments = canvasComments;

	// if the updated comment was a reply
	if (parentId && parentReplies.length > 0) {
		// update comment and rerturn new replies
		const newParentReplies = findAndUpdateInArray([newComment, parentReplies]);
		// create new parent for insert into ICanvas.comments
		const newParent: IFeedback = {
			_id: parentId,
			replies: newParentReplies
		};

		// get new ICanvas.comments with reply added
		newCanvasComments = findAndUpdateInArray([newParent, canvasComments]);
		// if the updated comment was a parent
	} else {
		newCanvasComments = findAndUpdateInArray([newComment, canvasComments]);
	}

	return newCanvasComments;
};

/**
 * return Ids needed by backend to propagate the lastUpdatedBy field (node -> canvas -> stage -> project)
 * @param reduxState Redux state to pass in, such as store.getState()
 * @param ids Optional canvasId or stageId to pass in. If passed in canvasId, a search will be performed to find it's corresponding stageId
 * This function is primarily used for Comments and Node to propagate updates to it's parent
 */
export const getPropagatingIds = (ids?: {
	blockId?: string | undefined;
	stageId?: string | undefined;
}) => {
	const userObject: IUser = getUserFromRedux();
	const { projectId } = generateIdsFromUrl();

	// finds stageId if canvasId is provided
	if (ids?.blockId && !ids?.blockId) {
		const parentStage = findStageWithBlockId(ids.blockId);
		ids.stageId = parentStage?._id as string | undefined;
	}

	// Object Ids
	const user = userObject._id as string;
	const blockId = ids?.blockId as string;
	const stageId = ids?.stageId as string;

	// return Ids used to propogate the lastUpdatedBy field
	return {
		user,
		blockId,
		stageId,
		projectId
	};
};

/**
 * @param blockId of the block
 * @returns IBlock
 */
export const findBlockWithBlockId = (blockId: string) => {
	const reduxState = store.getState();
	const { blocks } = reduxState;
	const { data } = blocks;
	return data[blockId];
};

/**
 * @param stageId of the stage
 * @returns IGroup
 */
export const findStageWithStageId = (stageId: string) => {
	const reduxState = store.getState();
	const { stages } = reduxState;
	const { data } = stages;
	return data[stageId];
};

/**
 *
 * @param stages store.getState().project.stages
 * @param canvas new canvas to update in stages array
 * @param stageId optional and helps speed the process
 * @returns new stages for project.stages
 */
export const findAndUpdateStagesWithBlock = (block: IBlock, stages: IGroup[], stageId?: string) => {
	const stage = stageId
		? (stages.find((s) => s._id === stageId) as IGroup)
		: (findStageWithBlockId(block._id as string) as IGroup);
	if (stage) {
		const blocks = getStageBlocks(stage._id as string);
		// update canvas in stage.canvases
		const newBlocks = findAndUpdateInArray([block, blocks]);
		const newBlockIds = newBlocks.map((b) => b._id as string);

		// create new stage with new canvases
		const newStage: IGroup = {
			...stage,
			children: newBlockIds
		};

		// update project.stages with new stage
		const newStages = findAndUpdateInArray([newStage, stages]);

		return newStages;
	}
	return undefined;
};

/**
 * Function to check if user is project owner
 * @returns true if current user is project owner
 */
export const isProjectOwner = () => {
	const userId = getUserFromRedux()._id;
	const { projectId } = generateIdsFromUrl();
	const project = store.getState().projects.data[projectId] as IProject;
	return project?.createdBy === userId;
};

/**
 *
 * @param ids will delete canvas if all optional IDs are not provided, parentCommentId must be provided if deleting a reply
 * @param stages store.getState().project.stages
 * @returns new stages with updated delete
 */

export const getHostName = (): string => {
	const pathArray = window.location.href.split('/');
	const host = pathArray[2] as string;
	return `${pathArray[0]}//${host}`;
};

// NOTE: commented because not being used
// export const removeUserFromStageAndCanvases = (userId:string, stage:IGroup) => {
//   let allStageUsers = stage.users as TUserAndRole[];
//   allStageUsers = allStageUsers.filter((x) => (x.user as IUser)._id !== userId);
//   stage.users = allStageUsers;
//   const allCanvases = stage.canvases as ICanvas[];
//   for (let i = 0; i < allCanvases.length; i++) {
//     let allCanvasUsers = allCanvases[i]?.canvasUsers as TUserAndRole[];
//     if (allCanvasUsers) {
//       allCanvasUsers = allCanvasUsers.filter((x) => (x.user as IUser)._id !== userId);
//       allCanvases[i]!.canvasUsers = allCanvasUsers;
//     }
//   }
//   stage.canvases = allCanvases;
//   return stage;
// };

export const replaceUserFromStageAndBlocks = (newStage: any, stage: IGroup) => {
	if (newStage && newStage.users) {
		stage.users = newStage.users;
	}

	const allBlocks = getStageBlocks(stage._id as string);
	allBlocks.forEach((block: IBlock, index: number) => {
		if (newStage && newStage.blocks && newStage.blocks[index] && newStage.blocks[index].users) {
			block.users = newStage.blocks[index].users;
		}
	});
	return stage;
};

// export const replaceInvitedFromStageAndBlocks = (newStage: any, stage: IGroup) => {
// 	stage.invitedEmails = newStage.invitedEmails;
// 	const allBlocks = stage.blocks as string[];

// 	allBlocks.forEach((id: string, index: number) => {
// 		const block = fetchBlockByIdFromRedux(id) as IBlock;
// 		if (newStage.blocks
// 		&& newStage.blocks[index] && newStage.blocks[index].invitedEmails) block.invitedEmails = newStage.blocks[index].invitedEmails;
// 	});
// 	return stage;
// };

// NOTE: commented because not used
// export const updateUserFromStageCanvases = (updatedUser: TUserAndRole, stage: IGroup) => {
//   const allStageUsers = stage.users as TUserAndRole[];
//   const updatedUserDetails = updatedUser.user as IUser;

//   allStageUsers.forEach((u: TUserAndRole) => {
//     const currentUserDetails = u.user as IUser;
//     if (currentUserDetails._id === updatedUserDetails._id) Object.assign(u, updatedUser);
//   });
//   const allCanvases = stage.canvases as ICanvas[];
//   for (let i = 0; i < allCanvases.length; i++) {
//     const allCanvasUsers = allCanvases[i]?.canvasUsers as TUserAndRole[];
//     if (allCanvasUsers?.length) {
//       allCanvasUsers.forEach((u) => {
//         const currentCanvasUser = u.user as IUser;
//         if (currentCanvasUser._id === updatedUserDetails._id) Object.assign(u, updatedUser);
//       });
//     }
//   }
//   return stage;
// };

// NOTE: commented because not used
// export const removeInvitedFromStageCanvases = (userEmail:string, stage:IGroup) => {
//   let allStageUsers = stage.invitedEmails as TInvitedEmail[];
//   allStageUsers = allStageUsers.filter((x) => x.email !== userEmail);
//   stage.invitedEmails = allStageUsers;
//   const allCanvases = stage.canvases as ICanvas[];
//   for (let i = 0; i < allCanvases.length; i++) {
//     let allCanvasUsers = allCanvases[i]?.invitedEmails as TInvitedEmail[];
//     if (allCanvasUsers?.length) {
//       allCanvasUsers = allCanvasUsers.filter((x) => x.email !== userEmail);
//       allCanvases[i]!.invitedEmails = allCanvasUsers;
//     }
//   }
//   stage.canvases = allCanvases;
//   return stage;
// };

// NOTE: commented because not used
// export const updateInvitedFromStageCanvases = (user: TInvitedEmail, stage: IGroup) => {
//   const allStageUsers = stage.invitedEmails as TInvitedEmail[];
//   allStageUsers.forEach((u) => {
//     if (u.email === user.email) Object.assign(u, user);
//   });
//   const allCanvases = stage.canvases as ICanvas[];
//   for (let i = 0; i < allCanvases.length; i++) {
//     const allCanvasUsers = allCanvases[i]?.invitedEmails as TInvitedEmail[];
//     if (allCanvasUsers?.length) {
//       allCanvasUsers.forEach((u) => {
//         if (u.email === user.email) Object.assign(u, user);
//       });
//     }
//   }
//   return stage;
// };

// NOTE: commented because not used
// export const removeUserFromDashboard = (userId:string, dashboard:IDashboard) => {
//   const currentDashboardElements = dashboard.dashboardElements as IDashboardElement[];
//   const updatedDashboardElements = currentDashboardElements.map((delement: IDashboardElement) => {
//     const dashboardUsers = delement.users as TUserAndRole[];
//     if (dashboardUsers) {
//       delement.users = dashboardUsers.filter((u: TUserAndRole) => (u.user as IUser)._id !== userId);
//     }
//     return delement;
//   });
//   dashboard.dashboardElements = updatedDashboardElements;
//   return dashboard;
// };

// NOTE: commented because not used
// export const updateUserFromDashboard = (updatedUser:TUserAndRole, dashboard:IDashboard) => {
//   const currentDashboardElements = dashboard.dashboardElements as IDashboardElement[];
//   const updatedDashboardElements = currentDashboardElements.map((delement: IDashboardElement) => {
//     const dashboardUsers = delement.users as TUserAndRole[];
//     dashboardUsers.forEach((u: TUserAndRole) => {
//       if ((u.user as IUser)._id === (updatedUser.user as IUser)._id) Object.assign(u, updatedUser);
//     });
//     return delement;
//   });
//   dashboard.dashboardElements = updatedDashboardElements;
//   return dashboard;
// };

// NOTE: commented because not used
// export const removeInvitedFromDashboard = (userId:string, dashboard:IDashboard) => {
//   const currentDashboardElements = dashboard.dashboardElements as IDashboardElement[];
//   const updatedDashboardElements = currentDashboardElements.map((delement: IDashboardElement) => {
//     const dashboardUsers = delement.invitedEmails as TInvitedEmail[];
//     delement.invitedEmails = dashboardUsers.filter((u: TInvitedEmail) => u.email !== userId);
//     return delement;
//   });
//   dashboard.dashboardElements = updatedDashboardElements;
//   return dashboard;
// };

// NOTE: commented because not used
// export const updateInvitedFromDashboard = (user:TInvitedEmail, dashboard:IDashboard) => {
//   const currentDashboardElements = dashboard.dashboardElements as IDashboardElement[];
//   const updatedDashboardElements = currentDashboardElements.map((delement: IDashboardElement) => {
//     const dashboardUsers = delement.invitedEmails as TInvitedEmail[];
//     dashboardUsers.forEach((u: TInvitedEmail) => {
//       if (u.email === user.email) Object.assign(u, user);
//     });
//     return delement;
//   });
//   dashboard.dashboardElements = updatedDashboardElements;
//   return dashboard;
// };

export const findStageWithId = (stageId: string, stages: IGroup[]) => {
	for (let i = 0; i < stages.length; i++) {
		if (stages[i]?._id === stageId) {
			return stages[i] as IGroup;
		}
	}

	return null;
};

export const removeStageFromAllStages = (stageId: string, stages: IGroup[]) => {
	const returnData = stages.filter((x) => x._id !== stageId);
	return returnData;
};

export const getFormatedStagesBlocksNodes = (reduxStages: IGroup[]) => {
	const stages: IGroup[] = [];
	const canvases: IBlock[] = [];
	const stageIds: string[] = [];
	const feedbacks: IFeedback[] = [];
	const notes: INote[] = [];

	const stagesCopy = cloneDeep(reduxStages);
	stagesCopy?.forEach((stage) => {
		stageIds.push(stage._id as string);
		const canvasIds: string[] = [];
		const currentStage = stage as IGroup;

		// notes of stage
		const stageNoteIds =
			(currentStage?.notes as INote[])?.map((note) => {
				notes.push(note);
				return note._id as string;
			}) || [];
		currentStage.notes = stageNoteIds;

		currentStage.children?.forEach((block) => {
			const currentBlock = block as IBlock;
			canvasIds.push(currentBlock._id as string);
			let feedbackIds = [];

			const noteIds =
				(currentBlock?.notes as INote[])?.map((note) => {
					notes.push(note);
					return note._id as string;
				}) || [];
			currentBlock.notes = noteIds;

			// Get the feedback from the blocks
			switch (currentBlock?.blockType as EBlockType) {
				case 'CANVAS':
					if ((currentBlock as ICanvas)?.feedbacks) {
						feedbackIds = (currentBlock as ICanvas).feedbacks.map(
							(f) => (f as IFeedback)._id
						);
						feedbacks.push(...((currentBlock as ICanvas).feedbacks as IFeedback[]));
						(currentBlock as ICanvas).feedbacks = feedbackIds as string[];
					}
					break;
				case 'IMAGE':
					if ((currentBlock as IImage)?.feedbacks) {
						feedbackIds = (currentBlock as IImage).feedbacks.map(
							(f) => (f as IFeedback)._id
						);
						feedbacks.push(...((currentBlock as IImage)?.feedbacks as IFeedback[]));
						(currentBlock as IImage).feedbacks = feedbackIds as string[];
					}
					break;
				case 'THREE_D':
					if ((currentBlock as I3D)?.feedbacks) {
						feedbackIds = (currentBlock as I3D).feedbacks.map(
							(f) => (f as IFeedback)._id
						);
						feedbacks.push(...((currentBlock as I3D).feedbacks as IFeedback[]));
						(currentBlock as I3D).feedbacks = feedbackIds as string[];
					}
					break;
				case 'LINK':
					if ((currentBlock as ILink)?.feedbacks) {
						feedbackIds = (currentBlock as ILink).feedbacks.map(
							(f) => (f as IFeedback)._id
						);
						feedbacks.push(...((currentBlock as ILink).feedbacks as IFeedback[]));
						(currentBlock as ILink).feedbacks = feedbackIds as string[];
					}
					break;
				case 'FILE':
					if ((currentBlock as IFile)?.feedbacks) {
						feedbackIds = (currentBlock as IFile).feedbacks.map(
							(f) => (f as IFeedback)._id
						);
						feedbacks.push(...((currentBlock as IFile).feedbacks as IFeedback[]));
						(currentBlock as IFile).feedbacks = feedbackIds as string[];
					}
					break;
				case 'VIDEO':
					if ((currentBlock as IVideo)?.feedbacks) {
						feedbackIds = (currentBlock as IVideo).feedbacks.map(
							(f) => (f as IFeedback)._id
						);
						feedbacks.push(...((currentBlock as IVideo).feedbacks as IFeedback[]));
						(currentBlock as IVideo).feedbacks = feedbackIds as string[];
					}
					break;
				case 'PDF':
					if ((currentBlock as IPdf)?.feedbacks) {
						feedbackIds = (currentBlock as IPdf).feedbacks.map(
							(f) => (f as IFeedback)._id
						);
						feedbacks.push(...((currentBlock as IPdf).feedbacks as IFeedback[]));
						(currentBlock as IPdf).feedbacks = feedbackIds as string[];
					}
					break;
				default:
					break;
			}
			canvases.push(currentBlock);
		});
		currentStage.children = canvasIds;
		stages.push(currentStage);
	});
	return { stages, canvases, stageIds, feedbacks, notes };
};

// Functiomn to normal the children in the canvases within the stages
export const getStagesWithNormalizedChildren = (stages: IGroup[]) => {
	const stagesCopy = cloneDeep(stages);
	for (let i = 0; i < stagesCopy?.length; i++) {
		const blocks = stagesCopy[i]?.children as IBlock[];
		for (let j = 0; j < blocks?.length; j++) {
			let children = [] as INode[];
			switch (blocks[j]?.blockType) {
				case 'CANVAS':
					children = (blocks[j] as ICanvas).nodes as INode[];
					break;
				case 'IMAGE':
					children = (blocks[j] as IImage).nodes as INode[];
					break;
				case 'THREE_D':
					children = (blocks[j] as I3D).nodes as INode[];
					break;
				default:
					break;
			}
			const nodeIds = children?.map((c) => {
				if (typeof c === 'string') return c;
				return c?._id;
			});
			switch (blocks[j]?.blockType) {
				case 'CANVAS':
					(blocks[j] as ICanvas).nodes = nodeIds;
					break;
				case 'IMAGE':
					(blocks[j] as IImage).nodes = nodeIds;
					break;
				case 'THREE_D':
					(blocks[j] as I3D).nodes = nodeIds;
					break;
				case 'FILE':
					(blocks[j] as IFile).nodes = nodeIds;
					break;
				case 'LINK':
					(blocks[j] as ILink).nodes = nodeIds;
					break;
				default:
					break;
			}
		}
		(stagesCopy[i] as IGroup).children = blocks;
	}
	return stagesCopy;
};

/**
 * Function to return diffs between two JSON Objs
 * @param oldObj First JSON Object
 * @param newObj Second JSON Object
 * @returns Diffs
 */
export const findDifferenceInJSON = (oldObj: any, newObj: any) => {
	const temp = detailedDiff(oldObj, newObj);
	const result: any = {};

	const changedKeys = [...Object.keys(temp.added), ...Object.keys(temp.updated)];
	for (let i = 0; i < changedKeys.length; i++) {
		const key = changedKeys[i] as string;
		result[key] = newObj[key];
	}

	const deletedKeys = [...Object.keys(temp.deleted)];
	for (let i = 0; i < deletedKeys.length; i++) {
		const key = deletedKeys[i] as string;
		if (result[key]) delete result[key];
	}

	result.version = newObj.version;

	return result;
};

/**
 * Function to return diffs of all the base nodes nodeData
 * @param nodes Array of BaseNodes
 * @returns Diffs
 */
export const getNodeDataDifferences = (nodes: BaseNode[]) => {
	const nodeDiff: Partial<INode>[] = [];
	const allNodes = store.getState().nodes.data;
	const user: IUser = getUserFromRedux();
	nodes.forEach((node: BaseNode) => {
		const result = findDifferenceInJSON(allNodes[node.nodeData!._id!], node.nodeData);
		result._id = node.nodeData._id;
		if (result.version || result.version === 0) {
			result.version += 1;
		} else result.version = 0;

		if (result.lastUpdatedBy !== (user._id as string))
			result.lastUpdatedBy = user._id as string;
		delete result.updatedAt;
		if (Object.keys(result).length > 1) nodeDiff.push(cloneDeep(result));
	});

	return nodeDiff;
};

/**
 * Function to revert stages in redux if api fails.
 * @returns callback function with redux dipatch to revert to previous stages
 */
export const apiFailureFallback = () => {
	const { projectId } = generateIdsFromUrl();
	const project = store.getState().projects.data[projectId] as IProject;

	const originalStages = cloneDeep(project.children);
	return () => {
		store.dispatch({
			type: 'EDIT_PROJECT',
			payload: {
				stages: originalStages
			}
		});
		addSnackbar({
			text: 'Someting went wrong, please try again later',
			type: 'ERROR',
			show: true
		});
		removeSnackbar(5000);
	};
};

/**
 * Function to get complete stage data from redux
 * @param { string } stageId of the stage
 * @param type
 * @returns group and its nested stages, blocks
 */
export const getStageAndChildren = (stageId: string, type: 'POPULATED' | 'NORMALIZED') => {
	const { children, ...stageData } = findStageWithStageId(stageId) as IGroup;

	const data: any[] = [];
	const stages: IGroup[] = [];
	const blocks: IBlock[] = [];
	stages.push({ ...stageData, children: type === 'POPULATED' ? data : children });
	for (let i = 0; i < children.length; i++) {
		const block = findBlockWithBlockId(children[i] as string) as IBlock;
		if (type === 'POPULATED') {
			if (!block) data.push(...getStageAndChildren(children[i] as string, type).stages);
			else data.push(block);
		} else if (!block) {
			const { stages: nestedStages, blocks: nestedBlocks } = getStageAndChildren(
				children[i] as string,
				type
			);
			stages.push(...nestedStages);
			blocks.push(...nestedBlocks);
		} else blocks.push(block);
	}

	return { stages, blocks };
};
