import orbit_3d_32 from '../../../assets/icons/nodes/Orbit32.png';
import orbit_3d_64 from '../../../assets/icons/nodes/Orbit64.png';
import orbit_3d_128 from '../../../assets/icons/nodes/Orbit128.png';
import orbit_3d_256 from '../../../assets/icons/nodes/Orbit256.png';
import move_3d_32 from '../../../assets/icons/nodes/Move32.png';
import move_3d_64 from '../../../assets/icons/nodes/Move64.png';
import move_3d_128 from '../../../assets/icons/nodes/Move128.png';
import move_3d_256 from '../../../assets/icons/nodes/Move256.png';
import cube_3d_32 from '../../../assets/icons/nodes/Cube32.png';
import cube_3d_64 from '../../../assets/icons/nodes/Cube64.png';
import cube_3d_128 from '../../../assets/icons/nodes/Cube128.png';
import cube_3d_256 from '../../../assets/icons/nodes/Cube256.png';

import link_svg from '../../../assets/icons/edit-menu/linkTooltip.svg';

import orbit_3d_cursor from '../../../assets/icons/nodes/orbit_3d_cursor.svg';
import move from '../../../assets/icons/cursors/move.svg';
import ne_rotator from '../../../assets/icons/cursors/ne-rotate.svg';
import nw_rotator from '../../../assets/icons/cursors/nw-rotate.svg';
import se_rotator from '../../../assets/icons/cursors/se-rotate.svg';
import sw_rotator from '../../../assets/icons/cursors/sw-rotate.svg';
import nw_resize from '../../../assets/icons/cursors/nw-resize.svg';
import n_resize from '../../../assets/icons/cursors/n-resize.svg';
import ne_resize from '../../../assets/icons/cursors/ne-resize.svg';
import w_resize from '../../../assets/icons/cursors/w-resize.svg';
import e_resize from '../../../assets/icons/cursors/e-resize.svg';
import sw_resize from '../../../assets/icons/cursors/sw-resize.svg';
import s_resize from '../../../assets/icons/cursors/s-resize.svg';
import se_resize from '../../../assets/icons/cursors/se-resize.svg';

export const cursors = {
    orbit_3d_cursor, 
    move, 
    ne_rotator, 
    nw_rotator, 
    se_rotator, 
    sw_rotator, 
    nw_resize, 
    n_resize, 
    ne_resize, 
    w_resize, 
    e_resize, 
    sw_resize, 
    s_resize, 
    se_resize,
};

export const links = {
    link_svg,
}

export const orbits3D = {
    orbit_3d_32, 
    orbit_3d_64, 
    orbit_3d_128, 
    orbit_3d_256,
};

export const moves3D = {
    move_3d_32, 
    move_3d_64, 
    move_3d_128, 
    move_3d_256,
};

export const cubes3D = {
    cube_3d_32, 
    cube_3d_64, 
    cube_3d_128, 
    cube_3d_256,
};