import {
	EEvent,
	EFeedbackType,
	I3D,
	I3DSubtype,
	IBlock,
	IFeedback,
	IFile,
	IFileSubtype,
	ILink,
	ILinkSubtype,
	IProject,
	IProjectDetail,
	IUser,
	IUserPreferenceNode,
	TActionItemFor,
	TSentNotifForUsers,
	TUserAndRole
} from '@naya_studio/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	TAddCommentPayload,
	TDeleteCommentPayload,
	TEditActionItemPayload,
	TEditCommentPayload,
	TAddCommentNotificationPayload,
	TAddUserToActionItemPayload,
	TRemoveUserFromActionItemPayload,
	TAddAnswerForActionItemPayload,
	TVotePoll
} from 'src/types/payloadTypes';
import axios from 'axios';
import { feedbackRouter, notificationRouter } from 'src/endpoints/projects-api';
import { store } from 'src';
import { getGatewayKey } from 'src/util/helper/queryString';
import { getUserDetail, getUserFirstName } from 'src/components/canvas/feedback/feedbackUtil';
import { DUMMY_IMG } from 'src/util/helper/constants';
import {
	TLoadCommentThunkArg,
	TAddCommentThunkArg,
	TAddNotificationThunkArg,
	TEditCommentThunkArg,
	TDeleteCommentThunkArg,
	TEditActionItemThunkArg,
	TCreateAnswerNotificationThunkArg,
	TLoadCommentFulfill,
	TEditActionItemFulfill,
	TAddCommentNotificationArg,
	TAddCommentFulfill,
	TAddUserToActionItemThunkArg,
	TRemoveUserFromActionItemThunkArg,
	TAddAnswerForActionItemThunkArg,
	TVotePollThunkArgs
} from 'src/types/argTypes';
import trackEvent from 'src/util/analytics/analytics';
import { FeedbackEvents } from 'src/util/analytics/events';
import { getFileLinkSubType } from 'src/components/utilities/navbar/utils';
import { cloneDeep } from 'lodash';
import getUserFromRedux from 'src/util/helper/user';
import { TPostCommentEventData, TPostPollEventData } from 'src/util/analytics/analytic.types';
import { TRtkResponse, generateIdsFromUrl, getUserRoleObjBasedOnIds } from './util';
import { addNotification } from './project';
import { getHostName } from '../actions/util';
import { AxiosResponseWrapper, CustomDispatch } from '../actions/types';
import { ISnackBar, ReduxState } from '../reducers/root.types';
import { addSnackbar, removeSnackbar } from '../actions/snackBar';
import { sentNotificationSlice } from '../features/api/sentNotification';
import { insertReplyCommentToYMap } from '../hooks/observers/utils';

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
const qs = `?${getGatewayKey()}`;

export const getCanvasUserId = (canvasUser: TUserAndRole) => {
	const tempUser = canvasUser?.user as IUser;
	return typeof canvasUser?.user === 'string' ? tempUser : tempUser._id;
};

const getSubtypeFromBlockType = (block: IBlock) => {
	switch (block.blockType) {
		case 'FILE': {
			return getFileLinkSubType(
				(block as IFile).fileName,
				'FILE'
			) as keyof typeof IFileSubtype;
		}
		case 'THREE_D': {
			return getFileLinkSubType(
				(block as I3D).fileName,
				'THREE_D'
			) as keyof typeof I3DSubtype;
		}
		case 'LINK': {
			return getFileLinkSubType((block as ILink).link, 'LINK') as keyof typeof ILinkSubtype;
		}
		default:
			return block.blockType;
	}
};

const trackAddFeedbackEvent = (feedback: IFeedback, block: IBlock) => {
	switch (feedback.feedbackType) {
		case 'POLL': {
			// Track creating poll event
			const feedbackEventProperties: TPostPollEventData = {
				noOfoptions: feedback.pollOptions?.length || 1,
				blockType: block.blockType,
				subType: getSubtypeFromBlockType(block)
			};
			trackEvent(FeedbackEvents.POST_POLL, feedbackEventProperties);
			break;
		}
		case 'CHAT': {
			// Track creating comment event
			const feedbackEventProperties: TPostCommentEventData = {
				blockType: block.blockType,
				subType: getSubtypeFromBlockType(block)
			};
			trackEvent(FeedbackEvents.POST_COMMENT, feedbackEventProperties);
			break;
		}
		default:
			break;
	}
};

/**
 * Function to add comment notification
 */
export const addCommentsNotification =
	(allNotifData: TAddCommentNotificationArg) => async (dispatch: CustomDispatch) => {
		const { _id: currentUserId } = getUserFromRedux();
		const projectUsers = store.getState().projectUsers.data;
		const getSentNotifs = sentNotificationSlice.endpoints.getSentNotifs.select({
			userId: currentUserId
		})(store.getState());

		// get response of all FBS_MENTION
		const responseMentionItem = await Promise.all(
			allNotifData?.notifMentions?.map(async (notif: TAddCommentNotificationPayload) => {
				const apiPayload: TAddNotificationThunkArg = {
					payload: {
						notification: notif,
						userId: notif.toUser as string
					}
				};
				const notifResponse = (await (dispatch as CustomDispatch)(
					addNotification(apiPayload)
				)) as TRtkResponse;
				return notifResponse.payload.data.payload;
			})
		);

		// get response of all FBS_ACT_ITEM
		const responseNotifActItem = await Promise.all(
			allNotifData?.notifActionItems?.map(async (notif: TAddCommentNotificationPayload) => {
				const apiPayload: TAddNotificationThunkArg = {
					payload: {
						notification: notif,
						userId: notif.toUser as string
					}
				};

				const notifResponse = (await (dispatch as CustomDispatch)(
					addNotification(apiPayload)
				)) as TRtkResponse;
				return notifResponse.payload.data.payload;
			})
		);

		// get response of all FBS_UPDATE
		const responseUpdateItem = await Promise.all(
			allNotifData?.notifUpdates?.map(async (notif: TAddCommentNotificationPayload) => {
				const apiPayload: TAddNotificationThunkArg = {
					payload: {
						notification: notif,
						userId: notif.toUser as string
					}
				};

				const notifResponse = (await (dispatch as CustomDispatch)(
					addNotification(apiPayload)
				)) as TRtkResponse;
				// Create a sent task for the logged in user
				return notifResponse.payload.data.payload;
			})
		);
		// clubbing responses of all notifications
		responseNotifActItem.push(...responseMentionItem);
		responseNotifActItem.push(...responseUpdateItem);

		// Create a sent task for the logged in user
		let toUsers: TSentNotifForUsers[] = [];
		if (responseNotifActItem && responseNotifActItem?.length) {
			toUsers = responseNotifActItem.map((n) => ({
				notifId: n._id as string,
				isCompleted: false,
				user: projectUsers.find((p) => p._id === (n.user as IUser)._id)
			}));
		}

		// create sent notification
		if (responseNotifActItem?.length && responseNotifActItem[0]) {
			(dispatch as CustomDispatch)(
				sentNotificationSlice.util.upsertQueryData(
					'getSentNotifs',
					{ userId: currentUserId },
					{
						tasks: {
							[responseNotifActItem[0]._id as string]: {
								...responseNotifActItem[0],
								toUsers,
								isCompleted: false
							},
							...getSentNotifs.data?.tasks
						} as any
					}
				)
			);
		}
	};

/** Check for notifications preference for user
 * Return true/false accordingly
 */
export const checkIfNotificationsAreAllowed = (userId: string) => {
	const { projectUsers } = store.getState();
	const { projectId } = generateIdsFromUrl();
	const project = store.getState().projects.data[projectId] as IProject;

	if (!project) return false;

	const tempUser = projectUsers.data.find((user: IUser) => user._id === userId) as IUser;
	const preferences = tempUser?.userPreferences;
	if ((preferences as IUserPreferenceNode)?.noNotificationsFor) {
		const noNotifs = (preferences as IUserPreferenceNode).noNotificationsFor;
		if (noNotifs.includes((project as IProject)._id as string)) {
			console.info('Notification not allowed');
			return false;
		}
	}
	console.info('Notification allowed');
	return true;
};

/**
 * Function to get details about the action item
 */
export const getActionDetails = (name: string, feedbackType: string, type: string) => {
	if (type === 'UPDATE' || type === 'ACT_ITEM') {
		switch (feedbackType) {
			case EFeedbackType.CHAT:
				return `${name} has created a chat`;
			case EFeedbackType.POLL:
				return `${name} has created a poll`;
			case EFeedbackType.QNA:
				return `${name} has created a Q&A`;
			case EFeedbackType.ACT_ITEM:
				return `${name} has created an action item`;
			default:
				return `${name} has created feedback item`;
		}
	} else if (type === 'MENTION') {
		switch (feedbackType) {
			case EFeedbackType.CHAT:
				return `${name} has mentioned you in a chat`;
			case EFeedbackType.POLL:
				return `${name} has mentioned you in a poll`;
			case EFeedbackType.QNA:
				return `${name} has mentioned you in a Q&A`;
			case EFeedbackType.ACT_ITEM:
				return `${name} has mentioned you in an action item`;
			default:
				return `${name} has mentioned you`;
		}
	} else if (type === 'FBS_MENTION_REPLY') {
		return `${name} has mentioned you in a response to chat`;
	} else if (type === 'FBS_MENTION_ANS') {
		return `${name} has mentioned you in a response to Q&A`;
	}
	return 'Case not defined';
};

/**
 * @param payload - Initial comment payload
 * @param canvasId - corresponding canvasId where the comment lives
 * @param parentCommentId - if this field is defined, you are trying to add a reply. If undefined, you are adding a parent comment
 * @returns Orer of actions: axios, dispatch, next
 */
export const addComment = createAsyncThunk(
	'feedbacks/addComment',
	async ({ payload }: TAddCommentThunkArg, thunkApi) => {
		try {
			const { blocks, projectUsers, projects, feedbacks } = thunkApi.getState() as ReduxState;
			const user: IUser = getUserFromRedux();
			const { userId, parentCommentId, feedback, blockId, projectId, stageId } = payload;
			const block = blocks.data[blockId] as IBlock & { subType: string };
			const { blockType } = block;

			// create new comment for request
			const apiPayload: TAddCommentPayload = {
				feedbackData: {
					parentCommentId,
					...feedback,
					createdBy: userId as string
				},
				blockId,
				blockType,
				lastUpdatedInfo: {
					lastUpdatedBy: userId as string
				}
			};

			if (parentCommentId && payload?.isJourneyRtcEnabled) {
				const parentFeedback = feedbacks.data[parentCommentId] as IFeedback;
				insertReplyCommentToYMap(feedback._id as string, cloneDeep(parentFeedback));
			}

			// get _id from backend and initialize comment
			const response = await axios.post<AxiosResponseWrapper<IFeedback>>(
				`${feedbackRouter}/${qs}`,
				{
					payload: apiPayload
				}
			);

			if (response.status !== 200) throw new Error('Error creating comment');

			trackAddFeedbackEvent(feedback, block);

			/** Code for notifications starts */
			const feedbackCreatorName = user.firstName || user.userName;

			const project = projects.data[projectId] as IProject;
			const currentBlock = blocks.data[blockId] as IBlock;
			const allCanvasUsers = getUserRoleObjBasedOnIds(currentBlock.users as TUserAndRole[]);
			const feedbackCreator = user;

			const notifMentions: TAddCommentNotificationPayload[] = [];
			const notifActionItems: TAddCommentNotificationPayload[] = [];
			const notifUpdates: TAddCommentNotificationPayload[] = [];

			// TODO : Convert these into functions
			if (parentCommentId) {
				const { taggedUsers } = feedback;
				const payloadCreatedForUsers = [];
				if (taggedUsers && taggedUsers.length > 0) {
					for (let i = 0; i < taggedUsers?.length!; i++) {
						if (checkIfNotificationsAreAllowed(taggedUsers[i] as string)) {
							const data: TAddCommentNotificationPayload = {
								eventType: EEvent.FBS_MENTION_REPLY,
								data: {
									canvasName: currentBlock.name,
									feedbackByName: user.firstName || user.userName,
									feedbackByUserId: userId,
									fromUserId: userId as string,
									projectId,
									stageId,
									blockId,
									blockType,
									lastUpdatedBy: taggedUsers[i] as string,
									feedbackId: parentCommentId,
									commentText: response.data.payload?.statement,
									userName: user.userName,
									createdAt: response.data.payload?.createdAt
										?.toString()
										.substring(0, 10),
									profilePic:
										(response.data.payload?.createdBy as IUser).profilePic ||
										DUMMY_IMG,
									toUserName: getUserFirstName(
										getUserDetail(
											taggedUsers[i] as string,
											projectUsers.data
										) as IUser
									),
									link: `${getHostName()}/project/${projectId}/${stageId}/${blockId}`,
									projectName: (project.projectDetails as IProjectDetail).name,
									actionDetails: getActionDetails(
										feedbackCreatorName as string,
										feedback.feedbackType as string,
										'FBS_MENTION_REPLY'
									),
									env: getHostName(),
									feedbackType: feedback.feedbackType
								},
								toUser: (
									getUserDetail(
										taggedUsers[i] as string,
										projectUsers.data
									) as IUser
								).email?.toLowerCase()
							};
							payloadCreatedForUsers.push(taggedUsers[i]);
							notifMentions.push(data);
						}
					}
				}

				for (let i = 0; i < allCanvasUsers.length; i++) {
					if (
						(response.data.payload?.createdBy as IUser)._id !==
							getCanvasUserId(
								(allCanvasUsers as TUserAndRole[])[i] as TUserAndRole
							) &&
						!payloadCreatedForUsers.includes(
							getCanvasUserId(
								(allCanvasUsers as TUserAndRole[])[i] as TUserAndRole
							) as string
						) &&
						checkIfNotificationsAreAllowed(
							getCanvasUserId(
								(allCanvasUsers as TUserAndRole[])[i] as TUserAndRole
							) as string
						)
					) {
						const data: TAddCommentNotificationPayload = {
							eventType: EEvent.REPLIED,
							data: {
								canvasName: currentBlock.name,
								commentUser: user.firstName || user.userName,
								projectId,
								stageId,
								blockId,
								feedbackId: parentCommentId,
								commentUserId: userId,
								fromUserId: user._id as string,
								commentText: response.data.payload?.statement,
								userName: (response.data.payload?.createdBy as IUser).userName,
								createdAt: response.data.payload?.createdAt
									?.toString()
									.substring(0, 10),
								profilePic:
									(response.data.payload?.createdBy as IUser).profilePic ||
									DUMMY_IMG,
								toUserName: getUserFirstName(
									getUserDetail(
										getCanvasUserId(
											(allCanvasUsers as TUserAndRole[])[i] as TUserAndRole
										) as string,
										projectUsers.data
									) as IUser
								),
								link: `${getHostName()}/project/${projectId}/${stageId}/${blockId}`,
								projectName: (project.projectDetails as IProjectDetail).name,
								feedbackType: 'CHAT'
							},
							toUser: (
								getUserDetail(
									getCanvasUserId(
										(allCanvasUsers as TUserAndRole[])[i] as TUserAndRole
									) as string,
									projectUsers.data
								) as IUser
							).email
						};

						notifUpdates.push(data);
					}
				}
			} else {
				const payloadCreatedForUsers = [];

				/** Create notification for the users who are tagged */
				const { taggedUsers } = feedback;
				if (taggedUsers && taggedUsers.length > 0) {
					for (let i = 0; i < taggedUsers?.length!; i++) {
						if (checkIfNotificationsAreAllowed(taggedUsers[i] as string)) {
							const data: TAddCommentNotificationPayload = {
								eventType: EEvent.FBS_MENTION,
								data: {
									canvasName: currentBlock.name,
									feedbackByName: user.firstName || user.userName,
									feedbackByUserId: userId,
									fromUserId: userId as string,
									projectId,
									stageId,
									blockId,
									blockType,
									lastUpdatedBy: taggedUsers[i] as string,
									feedbackId: response.data.payload?._id as string,
									commentText: response.data.payload?.statement,
									userName: user.userName,
									createdAt: response.data.payload?.createdAt
										?.toString()
										.substring(0, 10),
									profilePic:
										(response.data.payload?.createdBy as IUser).profilePic ||
										DUMMY_IMG,
									toUserName: getUserFirstName(
										getUserDetail(
											taggedUsers[i] as string,
											projectUsers.data
										) as IUser
									),
									link: `${getHostName()}/project/${projectId}/${stageId}/${blockId}`,
									projectName: (project.projectDetails as IProjectDetail).name,
									actionDetails: getActionDetails(
										feedbackCreatorName as string,
										feedback.feedbackType as string,
										'MENTION'
									),
									env: getHostName(),
									feedbackType: feedback.feedbackType
								},
								toUser: (
									getUserDetail(
										taggedUsers[i] as string,
										projectUsers.data
									) as IUser
								).email?.toLowerCase()
							};
							payloadCreatedForUsers.push(taggedUsers[i]);
							notifMentions.push(data);
						}
					}
				}

				/** Create notification for the users for whom it is an action item */
				const { actionItemFor } = feedback;
				const actionItemForUsers = Object.keys(actionItemFor as TActionItemFor);

				if (actionItemFor && actionItemForUsers.length > 0) {
					for (let i = 0; i < actionItemForUsers?.length; i++) {
						const obj = actionItemForUsers[i];
						if (
							!payloadCreatedForUsers.includes(obj) &&
							obj !== feedbackCreator._id &&
							checkIfNotificationsAreAllowed(obj as string)
						) {
							const data: TAddCommentNotificationPayload = {
								eventType: EEvent.FBS_ACT_ITEM,
								data: {
									feedbackType: feedback.feedbackType,
									canvasName: currentBlock.name,
									feedbackByName: user.firstName || user.userName,
									feedbackByUserId: userId,
									projectId,
									stageId,
									blockId,
									blockType,
									lastUpdatedBy: obj as string,
									feedbackId: response.data.payload?._id as string,
									commentText: response.data.payload?.statement,
									userName: user.userName,
									createdAt: response.data.payload?.createdAt
										?.toString()
										.substring(0, 10),
									profilePic:
										(response.data.payload?.createdBy as IUser).profilePic ||
										DUMMY_IMG,
									toUserName: getUserFirstName(
										getUserDetail(obj as string, projectUsers.data) as IUser
									),
									link: `${getHostName()}/project/${projectId}/${stageId}/${blockId}`,
									projectName: (project.projectDetails as IProjectDetail).name,
									actionDetails: getActionDetails(
										feedbackCreatorName as string,
										feedback.feedbackType as string,
										'ACT_ITEM'
									),
									env: getHostName(),
									fromUserId: userId as string
								},
								toUser: (
									getUserDetail(obj as string, projectUsers.data) as IUser
								).email?.toLowerCase()
							};
							payloadCreatedForUsers.push(obj);

							notifActionItems.push(data);
						}
					}
				}

				/** Create notification for remaining users */

				for (let i = 0; i < allCanvasUsers.length; i++) {
					// eslint-disable-next-line @typescript-eslint/no-shadow
					const userId = getCanvasUserId(
						(allCanvasUsers as TUserAndRole[])[i] as TUserAndRole
					) as string;
					if (
						!payloadCreatedForUsers.includes(userId) &&
						userId !== feedbackCreator._id &&
						checkIfNotificationsAreAllowed(userId)
					) {
						const data: TAddCommentNotificationPayload = {
							eventType: EEvent.FBS_UPDATE,
							data: {
								feedbackType: feedback.feedbackType,
								canvasName: currentBlock.name,
								feedbackByName: feedbackCreatorName,
								feedbackByUserId: feedbackCreator._id,
								projectId,
								stageId,
								blockId,
								feedbackId: response.data.payload?._id as string,
								commentText: response.data.payload?.statement,
								userName: user.userName,
								createdAt: response.data.payload?.createdAt
									?.toString()
									.substring(0, 10),
								profilePic:
									(response.data.payload?.createdBy as IUser).profilePic ||
									DUMMY_IMG,
								toUserName: getUserFirstName(
									getUserDetail(userId as string, projectUsers.data) as IUser
								),
								link: `${getHostName()}/project/${projectId}/${stageId}/${blockId}`,
								projectName: (project.projectDetails as IProjectDetail).name,
								actionDetails: getActionDetails(
									feedbackCreatorName as string,
									feedback.feedbackType as string,
									'UPDATE'
								),
								env: getHostName(),
								fromUserId: feedbackCreator._id as string
							},
							toUser: (
								getUserDetail(userId as string, projectUsers.data) as IUser
							).email?.toLowerCase()
						};

						notifUpdates.push(data);
					}
				}
			}

			const fulfillValue: TAddCommentFulfill = await Promise.resolve({
				notifMentions,
				notifActionItems,
				notifUpdates,
				feedback: response.data.payload,
				blockId,
				parentCommentId
			});

			return fulfillValue;
		} catch (error) {
			console.error('Notification Error:', error);
			const snackbarPayload: ISnackBar = {
				text: 'Failed to add comment.',
				show: true,
				type: 'ERROR'
			};
			addSnackbar(snackbarPayload);
			removeSnackbar(2000);

			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			return thunkApi.rejectWithValue(`An error occurred : ${error}`);
		}
	}
);

/**
 * This function does not take a parentId and will search the entire comments for comment
 * @param _id - Comment _id
 * @param canvasId - canvasId where comment lives
 * @returns Order of actions: axios, dispatch, next
 */
export const loadComment = createAsyncThunk(
	'feedbacks/loadComment',
	async ({ payload }: TLoadCommentThunkArg, thunkApi) => {
		try {
			const { _id: commentId, blockId } = payload;
			const response = await axios.get<AxiosResponseWrapper<IFeedback>>(
				`${feedbackRouter}/${commentId}${qs}`
			);

			if (response.status !== 200) throw new Error(`Error loading comment at ${commentId}`);

			const fulfillValue: TLoadCommentFulfill = {
				_id: commentId,
				blockId,
				comment: response.data.payload as IFeedback
			};

			return fulfillValue;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			return thunkApi.rejectWithValue(`An error occurred : ${error}`);
		}
	}
);

/**
 *
 * @param payload must include an _id field.
 * @param parentCommentId - if defined, edit reply. Else edit parent comment
 * @returns Orer of actions: axios, dispatch, next
 */
export const editComment = createAsyncThunk(
	'feedbacks/editComment',
	async ({ payload, next }: TEditCommentThunkArg, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();
			const { projectId, stageId, blockId, feedback, notifData, userId, parentCommentId } =
				payload;

			const apiPayload: TEditCommentPayload = {
				feedbackId: feedback._id,
				update: feedback,
				notifData,
				projectId,
				stageId,
				blockId,
				user: userId,
				lastUpdatedInfo: {
					lastUpdatedBy: user._id as string,
					parentCommentId
				}
			};

			const response = await axios.put<AxiosResponseWrapper<IFeedback>>(
				`${feedbackRouter}${qs}`,
				{
					payload: apiPayload
				}
			);

			if (response.status !== 200)
				throw new Error(`Error editing comment at ${feedback._id}`);

			// Edit Notifications that were sent with previous text
			if (feedback.statement) {
				const payloadNotification = {
					feedbackId: feedback._id,
					update: {
						'data.commentText': feedback.statement
					}
				};

				await axios.put<AxiosResponseWrapper<string>>(`${notificationRouter}/${qs}`, {
					payload: payloadNotification
				});
			}

			if (next && typeof next === 'function') next();

			return {
				comment: response.data.payload as IFeedback,
				blockId
			};
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			return thunkApi.rejectWithValue(`An error occurred : ${error}`);
		}
	}
);

/**
 *
 * @param _id The corresponding comment _id  you want to delete
 * @param canvasId Corresponding canvasId whjere comment lives
 * @param parentCommentid if defined, deleting reply, else delete parent and it's child replies
 * @returns Orer of actions: axios, dispatch, next
 */
export const deleteComment = createAsyncThunk(
	'feedbacks/deleteComment',
	async ({ payload }: TDeleteCommentThunkArg, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();
			const reduxState = thunkApi.getState() as ReduxState;
			const { _id: feedbackId, blockId, parentCommentId } = payload;
			const { blockType } = reduxState.blocks.data[blockId] as IBlock;

			const apiPayload: TDeleteCommentPayload = {
				feedbackId,
				blockId,
				parentCommentId,
				blockType,
				lastUpdatedInfo: {
					lastUpdatedBy: user._id as string,
					feedbackId
				}
			};

			const response = await axios.delete<AxiosResponseWrapper<string>>(
				`${feedbackRouter}/${qs}`,
				{
					data: {
						payload: apiPayload
					}
				}
			);

			if (response.status !== 200) throw new Error('Error deleting comment');

			// delete all notifications
			const payloadNotification = {
				commentId: feedbackId
			};

			await axios.delete<AxiosResponseWrapper<string>>(`${notificationRouter}/${qs}`, {
				data: {
					payload: payloadNotification
				}
			});

			return {
				...payload
			};
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			return thunkApi.rejectWithValue(`An error occurred : ${error}`);
		}
	}
);

/**
 *
 * @param payload must include an _id field.
 * @returns Orer of actions: axios, dispatch, next
 */
export const editActionItem = createAsyncThunk(
	'feedbacks/editActionItem',
	async ({ payload, next }: TEditActionItemThunkArg, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();
			const { projectUsers } = thunkApi.getState() as ReduxState;
			const { feedbackId, userId, blockId, update } = payload;

			const apiPayload: TEditActionItemPayload = {
				update,
				userId,
				feedbackId,
				lastUpdatedInfo: {
					lastUpdatedBy: user._id as string
				}
			};

			const response = await axios.put<AxiosResponseWrapper<IFeedback>>(
				`${feedbackRouter}/action-item/${qs}`,
				{
					payload: apiPayload
				}
			);

			if (response.status !== 200) throw new Error(`Error editing comment at ${feedbackId}`);

			if (next && typeof next === 'function') next();

			const fulfillValue: TEditActionItemFulfill = {
				comment: response.data.payload,
				blockId,
				createdBy: getUserDetail(
					response.data.payload?.createdBy as string,
					projectUsers.data
				)
			};

			return fulfillValue;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			return thunkApi.rejectWithValue(`An error occurred : ${error}`);
		}
	}
);

/**
 * Function to create answer notification
 */
export const createAnswerNotification = createAsyncThunk(
	'feedbacks/createAnswerNotification',
	async ({ payload }: TCreateAnswerNotificationThunkArg, thunkApi) => {
		try {
			const reduxState = thunkApi.getState() as ReduxState;
			const user: IUser = getUserFromRedux();
			const {
				blockId,
				taggedUsers,
				commentText,
				projectId,
				stageId,
				questionText,
				feedbackType,
				feedbackId
			} = payload;
			const project = reduxState.projects.data[projectId] as IProject;
			const currentBlock = reduxState.blocks.data[blockId] as IBlock;
			const allBlockUsers = getUserRoleObjBasedOnIds(currentBlock.users as TUserAndRole[]);

			const notifUpdates: TAddCommentNotificationPayload[] = [];
			const notifMentions: TAddCommentNotificationPayload[] = [];
			const feedbackCreatorName = user.firstName || user.userName;

			const feedbackCreator = user;

			/** Create notification for remaining users */

			const { projectUsers } = reduxState;
			if (taggedUsers && taggedUsers.length > 0) {
				for (let i = 0; i < taggedUsers?.length!; i++) {
					const userId = taggedUsers[i];
					if (userId && checkIfNotificationsAreAllowed(userId)) {
						const data: TAddCommentNotificationPayload = {
							eventType: EEvent.FBS_MENTION_ANS,
							data: {
								feedbackType: 'CHAT',
								canvasName: currentBlock.name,
								feedbackByName: feedbackCreatorName,
								feedbackByUserId: feedbackCreator._id,
								projectId,
								stageId,
								blockId,
								commentText,
								questionText,
								userName: feedbackCreator.userName,
								profilePic:
									(
										getUserDetail(
											taggedUsers[i] as string,
											projectUsers.data
										) as IUser
									).profilePic || DUMMY_IMG,
								toUserName: getUserFirstName(
									getUserDetail(
										taggedUsers[i] as string,
										projectUsers.data
									) as IUser
								),
								link: `${getHostName()}/project/${projectId}/${stageId}/${blockId}`,
								projectName: (project.projectDetails as IProjectDetail).name,
								actionDetails: getActionDetails(
									feedbackCreatorName as string,
									feedbackType as string,
									'FBS_MENTION_ANS'
								),
								env: getHostName(),
								feedbackId,
								fromUserId: userId
							},
							toUser: (
								getUserDetail(taggedUsers[i] as string, projectUsers.data) as IUser
							).email?.toLowerCase()
						};
						notifMentions.push(data);
					}
				}
			}

			for (let i = 0; i < allBlockUsers.length; i++) {
				const userId = (
					((allBlockUsers as TUserAndRole[])[i] as TUserAndRole).user as IUser
				)._id as string;
				if (
					userId !== feedbackCreator._id &&
					!taggedUsers.includes(userId) &&
					checkIfNotificationsAreAllowed(userId)
				) {
					const data: TAddCommentNotificationPayload = {
						eventType: EEvent.FBS_UPDATE,
						data: {
							feedbackType: 'CHAT',
							canvasName: currentBlock.name,
							feedbackByName: feedbackCreatorName,
							feedbackByUserId: feedbackCreator._id,
							projectId,
							stageId,
							blockId,
							commentText,
							questionText,
							userName: feedbackCreator.userName,
							profilePic:
								(getUserDetail(userId as string, projectUsers.data) as IUser)
									.profilePic || DUMMY_IMG,
							toUserName: getUserFirstName(
								getUserDetail(userId as string, projectUsers.data) as IUser
							),
							link: `${getHostName()}/project/${projectId}/${stageId}/${blockId}`,
							projectName: (project.projectDetails as IProjectDetail).name,
							actionDetails: getActionDetails(
								feedbackCreatorName as string,
								feedbackType as string,
								'UPDATE'
							),
							env: getHostName(),
							feedbackId,
							fromUserId: userId
						},
						toUser: (
							getUserDetail(userId as string, projectUsers.data) as IUser
						).email?.toLowerCase()
					};

					notifUpdates.push(data);
				}
			}

			return await Promise.resolve({
				notifUpdates,
				notifMentions
			});
		} catch (error) {
			console.error('Notification Error:', error);
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			return thunkApi.rejectWithValue(`An error occurred : ${error}`);
		}
	}
);

/**
 * Function to add a user to action item
 */
export const addUserToActionItem = createAsyncThunk(
	'feedbacks/addUser',
	async ({ payload, next }: TAddUserToActionItemThunkArg, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();
			const { feedbackId, userId } = payload;

			const apiPayload: TAddUserToActionItemPayload = {
				userId,
				feedbackId,
				lastUpdatedInfo: {
					lastUpdatedBy: user._id as string
				}
			};

			const response = await axios.put<AxiosResponseWrapper<string>>(
				`${feedbackRouter}/assignee/${qs}`,
				{
					payload: apiPayload
				}
			);

			if (response.status !== 200)
				throw new Error(`Error adding user to action item ${feedbackId}`);

			if (next && typeof next === 'function') next();

			return payload;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			return thunkApi.rejectWithValue(`An error occurred : ${error}`);
		}
	}
);

/**
 * Function to remove a user from action item
 */
export const removeUserFromActionItem = createAsyncThunk(
	'feedbacks/removeUser',
	async ({ payload, next }: TRemoveUserFromActionItemThunkArg, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();
			const { feedbackId, userId } = payload;

			const apiPayload: TRemoveUserFromActionItemPayload = {
				userId,
				feedbackId,
				lastUpdatedInfo: {
					lastUpdatedBy: user._id as string
				}
			};

			const response = await axios.delete<AxiosResponseWrapper<string>>(
				`${feedbackRouter}/assignee/${qs}`,
				{
					data: {
						payload: apiPayload
					}
				}
			);

			if (response.status !== 200)
				throw new Error(`Error removing user from action item ${feedbackId}`);

			if (next && typeof next === 'function') next();

			return payload;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			return thunkApi.rejectWithValue(`An error occurred : ${error}`);
		}
	}
);

/**
 * Function to remove a user from action item
 */
// NOTE : Generate id of answer before dispatch for optimistic update
export const addAnswerForActionItem = createAsyncThunk(
	'feedbacks/addAnswer',
	async ({ payload, next }: TAddAnswerForActionItemThunkArg, thunkApi) => {
		try {
			const user: IUser = getUserFromRedux();
			const { feedbackId, answer } = payload;

			const apiPayload: TAddAnswerForActionItemPayload = {
				answer,
				feedbackId,
				lastUpdatedInfo: {
					lastUpdatedBy: user._id as string
				}
			};

			const response = await axios.put<AxiosResponseWrapper<string>>(
				`${feedbackRouter}/answer/${qs}`,
				{
					payload: apiPayload
				}
			);

			if (response.status !== 200)
				throw new Error(`Error adding answer for action item ${feedbackId}`);

			if (next && typeof next === 'function') next();

			return payload;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			return thunkApi.rejectWithValue(`An error occurred : ${error}`);
		}
	}
);

/**
 * Function for adding vote to a poll
 */
export const votePoll = createAsyncThunk(
	'feedbacks/votePoll',
	async ({ payload }: TVotePollThunkArgs, thunkApi) => {
		try {
			const { option, feedbackId } = payload;
			const userId = getUserFromRedux()._id as string;
			const apiPayload: TVotePoll = {
				option,
				userId,
				feedbackId,
				lastUpdatedInfo: {
					lastUpdatedBy: userId as string
				}
			};

			const response = await axios.post<AxiosResponseWrapper<IFeedback>>(
				`${feedbackRouter}/vote-poll${qs}`,
				{
					payload: apiPayload
				}
			);

			if (response.status !== 200) throw new Error(`Error voting poll at ${feedbackId}`);

			return {
				feedbackId
			};
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			return thunkApi.rejectWithValue(`An error occurred : ${error}`);
		}
	}
);
