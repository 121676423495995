const materialsList = [

    //src=https://www.climatiq.io/explorer?search=aluminium
    { value: "aluminum", label: "aluminum", co2e: 0.79},
    //src=https://www.climatiq.io/explorer?search=ash
    { value: "ash", label: "ash", co2e: 20},
    //src=https://www.climatiq.io/explorer?search=bamboo
    { value: "bamboo", label: "bamboo", co2e: 93.86},
    //src=
    { value: "birch", label: "birch", co2e: 1.80},
    //src= default
    { value: "brass", label: "brass", co2e: 0.42},
    //src= default
    { value: "bronze", label: "bronze", co2e: 0.42},
    //src= default
    { value: "cedar", label: "cedar", co2e: 0.42},
    //src=https://www.climatiq.io/explorer?search=concrete
    { value: "concrete",label: "concrete", co2e: 0.17},
    //src= https://www.climatiq.io/explorer?search=fabric
    { value: "fabric", label: "fabric", co2e: 0.48 },
    //src= https://www.climatiq.io/explorer?search=finished
    { value: "finished", label: "finished", co2e: 0.31},
    //src= 
    { value: "fir", label: "fir", co2e: 0.42},
    //src= https://www.climatiq.io/explorer?search=glass
    { value: "glass", label: "glass", co2e: 0.53},
    //src=
    { value: "granite", label: "granite", co2e: 0.42},
    //src= https://www.climatiq.io/explorer?search=hardwood
    { value: "hardwood", label: "hardwood", co2e: 93.86},
    //srcc= https://www.climatiq.io/explorer?search=iron
    { value: "iron", label: "iron", co2e: 1.66},
    //src= https://www.climatiq.io/explorer?search=leather
    { value: "leathers", label: "leathers", co2e: 0.80},
    //src=
    { value: "mahogany", label: "mahogany", co2e: 0.42},
    //src=
    { value: "marble", label: "marble", co2e: 0.42},
    //src= https://www.climatiq.io/explorer?search=metal
    { value: "metal", label: "metal", co2e: 0.42},
    //src= default
    { value: "oak", label: "oak", co2e: 0.42},
    //src = default
    { value: "patina", label: "patina", co2e: 0.42},
    //src= default
    { value: "pine", label: "pine", co2e: 0.42},
    //src= https://www.climatiq.io/explorer?search=plywood
    { value: "plywood", label: "plywood", co2e: 0.40},
    //src= default
    { value: "poplar", label: "poplar", co2e: 0.42},
    //src= default
    { value: "sanded", label: "sanded", co2e: 0.42},
    //src=https://www.climatiq.io/explorer?search=steel
    { value: "steel", label: "steel", co2e: 0.24},
    //src=https://www.climatiq.io/explorer?search=stone
    { value: "stone", label: "stone", co2e: 2.21},
    //src=https://www.climatiq.io/explorer?search=unfinished
    { value: "unfinished", label: "unfinished", co2e: 0.42},
    //src= 
    { value: "walnut", label: "walnut", co2e: 0.42},
    //src= https://www.climatiq.io/explorer?search=wood
    { value: "wood", label: "wood", co2e: 0.27},
];

export default materialsList;