import { isDarkShade, ISubType, Journey } from '@naya_studio/journey';
import { useHistory } from 'react-router';
import { Helmet } from 'react-helmet';
import { generateIdsFromUrl, getStagesBlocksNodesOfProject } from 'src/redux/reduxActions/util';
import useProject from 'src/redux/hooks/useProject';
import useBlockActions from 'src/util/block/useBlockActions';
import useGroupActions from 'src/util/group/useGroupActions';
import { useNotifications } from 'src/util/notifications/useNotifications';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
	I3D,
	IBlock,
	ICanvas,
	IFile,
	IImage,
	ILink,
	IPdf,
	IProjectDetail,
	IGroup,
	IVideo
} from '@naya_studio/types';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'src/redux/reducers/root.types';
import { handleDisabledAiMenu } from 'src/util/block/util';
import useBlocks from 'src/redux/hooks/useBlocks';
import useStages from 'src/redux/hooks/useStages';
import useNotes from 'src/redux/hooks/useNotes';
import { AppliedFiltersContainer } from '@naya_studio/radix-ui';
import useHighlightObserver from 'src/redux/hooks/observers/useHighlightObserver';
import useIntegration from 'src/util/integration/useIntegration';
import trackEvent from 'src/util/analytics/analytics';
import { CustomEvents } from 'src/util/analytics/events';
import { setShowSyncStorageModal } from 'src/redux/features/integration';
import { onINP } from 'web-vitals';
import { isProjectOwner } from 'src/redux/actions/util';
import { TAlignJourneyEventData } from 'src/util/analytics/analytic.types';
import { TAddBlocksFnArgs } from 'src/util/block/blockAction.types';
import getUserFromRedux, { onEditUserPreference } from 'src/util/helper/user';
import { ESharingTabs } from '../sharingModal/SharingModal.types';
import { CollaborationToolContext } from '../collaborationTool/CollaborationTool';
import {
	onGroupEdit,
	onReorderBlocks,
	onAddNewPhase,
	onDuplicatePhase,
	onDuplicateBlock,
	onDeletePhase,
	onDeleteBlock,
	isProjectEditor,
	getFormattedData,
	onUpdateProject,
	getFormattedUser,
	onUpdateProjectDetails,
	onNesting,
	onGroupsEdit,
	onBlocksEdit,
	createProjectInJourney
} from './JourneyContainerCallbacks';
import filterPhaseAndBlocks from '../../util/collaboration/searchAndSortAlgo';
import { JourneyContainerProps } from './JourneyContainer.types';
import { AuthContext } from '../auth/AuthProvider';
import { TimelineWrapper } from '../collaborationTool/timeline/TimelineWrapper';

/**
 * This is a journey container - wrapper for the journey component
 */
function JourneyContainer({
	setActiveTab,
	setSharingDetails,
	setEditThumbnailDetails,
	onGenerateWithAI,
	onAIRename,
	onAISuggestion,
	onAIOrganize,
	handleMultiselectAIWrapper,
	flags
}: JourneyContainerProps) {
	const dispatch = useDispatch();
	const history = useHistory();

	const { handleHighlightForDraggedElement } = useHighlightObserver();
	const [isSearchActive, setIsSearchActive] = useState(false);
	const [formattedBlocksPhases, setFormattedBlockPhases] = useState<{
		blocks: { [key: string]: Partial<IBlock> };
		phases: Partial<IGroup>[];
	}>({ blocks: {}, phases: [] });
	const [filteredBlocksPhases, setFilteredBlockPhases] = useState<{
		blocks: { [key: string]: Partial<IBlock> };
		phases: Partial<IGroup>[];
	}>({ blocks: {}, phases: [] });

	const { hasGuestAccessTo } = useContext(AuthContext);

	const collabContext = useContext(CollaborationToolContext);
	const {
		searchFilters,
		isUndoActive,
		isRedoActive,

		setRedirectModalDetails,
		setSearchFilters,
		setShowShareModal,
		setCreatedByUsers,
		setAvailableTypes,
		setDeliverableType,
		setStickyNotes,
		handleUndoRedo,
		goBack,
		isGuestUser
	} = collabContext;
	const { reduxUsers } = useSelector((state: ReduxState) => ({
		reduxUsers: state.projectUsers
	}));
	const { reduxBlocks } = useBlocks();
	const { reduxStages } = useStages();
	const { reduxNotes } = useNotes();
	const { projectId } = generateIdsFromUrl();
	const { project } = useProject(projectId);
	const { getBlockTaskCount, getFormattedTasksForBlock, handleTask } = useNotifications();

	const {
		onRenameBlock,
		onAddBlocks,
		onBlockEdit,
		getMiroBoard,
		undoDeleteBlocks,
		addTempNotes,
		removeTempNotes,
		onFolderOrLinkDrop,
		updateMultipleBlocksWithUndoRedo,
		updateMultipleGroupsWithUndoRedo
	} = useBlockActions();
	const { onEditGroup } = useGroupActions();
	const { onIntegration, onDuplicateLinkAsset } = useIntegration();

	/** Formatted user */
	const user = useMemo(() => getFormattedUser(), [getUserFromRedux().userPreferences]);

	// callback to check if user has access to all the assets in project
	// If not prevent reorder operation
	const hasAccessToAllAssets = useMemo(() => {
		// Triggered whenever user is invited/removed from block/phase
		if (project) {
			if (
				project.restrictedAccess?.blocks.length ||
				project.restrictedAccess?.stages.length
			) {
				return false;
			}
			return true;
		}

		return false;
	}, [project?.restrictedAccess]);

	/**
	 * Set the phases and blocks and status of isSearchActive when query is changed
	 */
	useEffect(() => {
		const searchActive =
			Object.keys(searchFilters).length > 0 && Object.values(searchFilters).length > 0;
		setIsSearchActive(searchActive);
		if (searchActive && formattedBlocksPhases.phases.length) {
			const filteredResult = filterPhaseAndBlocks(
				searchFilters,
				formattedBlocksPhases.phases,
				formattedBlocksPhases.blocks
			);

			if (filteredResult) {
				const { phases, blocks } = filteredResult;
				setFilteredBlockPhases({ blocks, phases });
			}
		} else {
			const { stages, blocksJson } = getStagesBlocksNodesOfProject(projectId);
			const {
				formattedBlocks: journeyBlocks,
				formattedPhases: journeyPhases,
				createdByUsers,
				availableTypes,
				stickyNotes
			} = getFormattedData(
				stages,
				blocksJson,
				reduxNotes,
				getFormattedTasksForBlock,
				hasGuestAccessTo
			);
			setFormattedBlockPhases({ phases: journeyPhases, blocks: journeyBlocks });
			setCreatedByUsers(createdByUsers);
			setAvailableTypes(availableTypes);
			setStickyNotes(stickyNotes);
		}

		if (!searchActive) setFilteredBlockPhases({ blocks: {}, phases: [] });
	}, [
		searchFilters,
		getBlockTaskCount,
		reduxBlocks,
		reduxStages,
		reduxUsers,
		project?.children,
		reduxNotes
	]);

	// Re-runs when filtered data is updated, to update filtered block ids in session
	// This will be used in Pagination
	useEffect(() => {
		setTimeout(() => {
			if (isSearchActive) {
				const blockIds: Array<string> = [];
				const allBlockElements =
					document.querySelectorAll<HTMLDivElement>('#block-wrapper');
				allBlockElements.forEach((element) => {
					const blockId = element.getAttribute('data-blockid');
					if (blockId) blockIds.push(blockId);
				});

				window.sessionStorage.setItem('filtered-blocks', JSON.stringify(blockIds));
			} else window.sessionStorage.removeItem('filtered-blocks');
		}, 0);
	}, [searchFilters, isSearchActive]);

	/**
	 * Function to check if the filtered result is empty
	 */
	const isSearchAndEmpty = () =>
		isSearchActive &&
		!filteredBlocksPhases.phases.length &&
		!Object.keys(filteredBlocksPhases.blocks).length;

	/**
	 * Function to generate no result found text
	 */
	const generateNoResultFoundText = () => {
		if (searchFilters.text)
			return (
				<span style={{ textAlign: 'center' }}>
					No result found matching:
					<br />
					{searchFilters.text}
				</span>
			);
		return <span>No result found</span>;
	};

	/**
	 * Function to render empty search screen
	 */
	const renderEmptySearchScreen = () => (
		<div
			className="tw-w-full tw-h-full tw-fixed tw-flex tw-justify-center tw-items-center tw-p-8"
			style={{ background: (project?.projectDetails as IProjectDetail)?.color }}
		>
			{generateNoResultFoundText()}
		</div>
	);

	/**
	 * Function to handle block click operation
	 * @param stageId stage id of block
	 * @param blockId block id of clicked block
	 */
	const onBlockClick = (
		stageId: string,
		blockId: string,
		shouldOpenInNewTab: boolean = false
	) => {
		if (shouldOpenInNewTab) {
			const activeBlock = reduxBlocks[blockId] as ILink;
			setRedirectModalDetails({
				link: activeBlock.link,
				canSkip: false,
				onCancel: () => setRedirectModalDetails(null)
			});
		} else {
			// persisting project zoom value in params for guest user
			const projectZoomValue = new URL(window.location.toString()).searchParams.get('zoom');
			history.push({
				pathname: `/project/${projectId}/${stageId}/${blockId}`,
				state: {
					...(projectZoomValue && (!user._id || isGuestUser) ? { projectZoomValue } : {})
				}
			});
			// Save history when block is redirected
			handleUndoRedo({
				type: 'ADD',
				payload: {
					originalAction: onBlockClick,
					oppositeAction: goBack,
					originalActionPayload: [stageId, blockId],
					oppositeActionPayload: []
				}
			});
		}
	};

	/**
	 * Function to handle share operation
	 * @param tab active sharing tab
	 * @param stageId stage id if shared stage
	 * @param blockId block id if shared block
	 */
	const onShare = (tab: keyof typeof ESharingTabs, stageId: string, blockId?: string) => {
		setActiveTab(tab);
		setSharingDetails({
			projectId,
			blockId: tab === 'BLOCK' ? blockId : undefined,
			phaseId: stageId
		});

		setShowShareModal(true);
	};

	const hasEditAccessToProject = useMemo(() => isProjectEditor(), [project]);

	const textToHighlight = useMemo(
		() => ({
			text: searchFilters.text || '',
			highlightPhase: (searchFilters.text &&
				searchFilters.text.length &&
				Object.keys(searchFilters).length === 1) as boolean
		}),
		[searchFilters.text]
	);

	/**
	 * Callback to track analytic events
	 * @param event event to track
	 */
	const trackAlignEvents = (alignType: 'TOP' | 'BOTTOM' | 'CENTER') => {
		// Track align journey events
		const eventProps: TAlignJourneyEventData = {
			alignType,
			elementId: projectId
		};
		trackEvent(CustomEvents.JOURNEY_ALIGN, eventProps);
	};

	/**
	 * Callback to handle click event on edit thumbnail context menu option
	 * @param id id of the block
	 * @param name name of the block
	 * @param thumbnail existing thumbnail of block
	 * @param originalThumbnail original thumbnail of block
	 */
	const onEditThumbnail = (
		id: string,
		name: string,
		thumbnail: string,
		originalThumbnail: string,
		isCustom: boolean = false
	) => {
		setEditThumbnailDetails({
			id,
			name,
			thumbnail,
			originalThumbnail,
			isCustom
		});
	};

	/**
	 * Adds temporary notes when in multiselect mode via redux
	 */
	const handleTempNotes = (
		option: 'ADD_TEMP_NOTES' | 'REMOVE_TEMP_NOTES',
		data: {
			groups: string[];
			blocks: string[];
			color: string;
		}
	) => {
		switch (option) {
			case 'ADD_TEMP_NOTES':
				addTempNotes(data);
				break;
			case 'REMOVE_TEMP_NOTES':
				removeTempNotes(data);
				break;
			default:
				console.error('Case not handled in handleTempNotes');
		}
	};

	// getting all necessary props to be passes
	const args = useMemo(
		() => ({
			projectId,
			color: ((project?.projectDetails as IProjectDetail)?.color as string) || '#FFFFFF',
			isAIFeaturesEnabled: project?.isAIFeatureEnabled ?? true,
			isContentTraningEnabled:
				window.__RUNTIME_CONFIG__.REACT_APP_MOCK_AI === 'true'
					? true
					: project?.aiIngestStatus === 'READY' ?? false,
			hasAccessToAllAssets,
			isProjectOwner: isProjectOwner(),
			phases: isSearchActive ? filteredBlocksPhases.phases : formattedBlocksPhases.phases,
			blocks: (isSearchActive
				? filteredBlocksPhases.blocks
				: formattedBlocksPhases.blocks) as Partial<
				IBlock & ICanvas & IPdf & ILink & IVideo & IImage & I3D & IFile & ISubType
			>,
			user,
			children: (project?.children as string[]) || [],
			onGroupEdit,
			onRenameBlock,
			onUpdateProject,
			onUpdateProjectDetails,
			onReorderBlocks,
			onAddNewPhase,
			onDuplicatePhase,
			onDuplicateBlock,
			onDeletePhase,
			onDeleteBlock,
			onDuplicateLinkAsset,
			onBlockClick,
			isProjectEditor: hasEditAccessToProject,
			isSearchActive,
			onAddBlocks,
			getMiroBoard,
			onEditBlock: onBlockEdit,
			onGenerateWithAI,
			onShare,
			textToHighlight,
			setDeliverableType,
			handleDisabledAiMenu,
			handleTask,
			flags,
			handleHighlightForDraggedElement,
			onIntegration,
			setShowSyncStorageModal: () => {
				dispatch(setShowSyncStorageModal(true));
			},
			trackAlignEvents,
			onINP,
			handleUndoRedo,
			isUndoActive,
			isRedoActive,
			onEditThumbnail,
			undoDeleteBlocks,
			handleTempNotes,
			onAIRename,
			onAISuggestion,
			onAIOrganize,
			onEditGroup,
			onNesting,
			handleMultiselectAIWrapper,
			onGroupsEdit,
			onBlocksEdit,
			onFolderOrLinkDrop,
			onCreateProject: () => {
				createProjectInJourney((id: string) => {
					const data = {
						blocksToAdd: [
							{ addType: 'LINK', payload: `${window.location.origin}/project/${id}` }
						] as TAddBlocksFnArgs[],
						options: { snackbarMessage: 'Creating naya journey block' }
					};
					onAddBlocks(data);
				});
			},
			onEditUserPreference,
			updateMultipleBlocksWithUndoRedo,
			updateMultipleGroupsWithUndoRedo,
			isGuestUser
		}),
		[
			formattedBlocksPhases,
			filteredBlocksPhases,
			isSearchActive,
			textToHighlight,
			(project?.projectDetails as IProjectDetail)?.color,
			project?.aiIngestStatus,
			project?.isAIFeatureEnabled,
			user,
			hasEditAccessToProject,
			flags,
			onIntegration,
			onDuplicateLinkAsset,
			isUndoActive,
			isRedoActive
		]
	);
	return (
		<>
			{isSearchActive && (
				<AppliedFiltersContainer
					searchFilters={searchFilters}
					filterResults={filteredBlocksPhases.blocks}
					setSearchFilters={setSearchFilters}
					isDarkBackground={isDarkShade(
						((project?.projectDetails as IProjectDetail)?.color as string) || '#FFFFFF'
					)}
				/>
			)}
			{searchFilters.timelineView ? (
				<TimelineWrapper
					filteredGroups={filteredBlocksPhases?.phases}
					filteredBlocks={filteredBlocksPhases?.blocks}
					onBlockClick={onBlockClick}
				/>
			) : (
				project &&
				args.children &&
				args.blocks &&
				args.phases &&
				(isSearchAndEmpty() ? renderEmptySearchScreen() : <Journey {...args} />)
			)}
			{/* Set the title that is shown in browser tab */}
			<Helmet>
				<title>
					{((project?.projectDetails as IProjectDetail)?.name as string) || 'Naya'}
				</title>
			</Helmet>
		</>
	);
}

export default JourneyContainer;
