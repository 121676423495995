import { useDispatch, useSelector } from 'react-redux';
import bcrypt from 'bcryptjs';
import { ISnackBar, ReduxState, TDriveData, TGDriveFile } from 'src/redux/reducers/root.types';
import { findStageWithBlockId, fetchBlockByIdFromRedux } from 'src/redux/actions/util';
import {
	generateIdsFromUrl,
	generateOptimisticBlock,
	generateOptimisticStage,
	getNodesBasedOnBlockType
} from 'src/redux/reduxActions/util';
import {
	addTempNotesOnBlocks,
	editBlockById,
	removeTempNotesOnBlocks
} from 'src/redux/features/blocks';
import {
	TAddMultipleBlockArgs,
	TAddNodesArg,
	TAddSingleBlockArgs,
	TConvertBlockArgs,
	TBatchCreateGroupAndBlocks,
	TEditBlockArgs,
	TEditNodesArgs,
	TResetBlockArgs,
	TUndoRedoArgs
} from 'src/types/argTypes';
import {
	EBlockType,
	EEvent,
	I3D,
	IBlock,
	ICanvas,
	IFeedback,
	IFile,
	IImage,
	ILink,
	ILinkSubtype,
	INode,
	INote,
	IPdf,
	IProject,
	IGroup,
	IUser,
	IText,
	IVideo,
	TUserAndRole,
	TEditBlockFnArgs,
	TAddNode,
	TBlockEdit,
	TCanvasEdit,
	TEditNode,
	TFileEdit,
	TLinkEdit,
	TAddColor,
	TInvitedEmail,
	TPasswordProtection,
	IExaclidrawCanvas,
	ITodo,
	ITodoItem,
	TEditTodo,
	TRemoveTodo
} from '@naya_studio/types';
import {
	addMultipleBlocks,
	addSingleBlock,
	convertBlock,
	editBlock,
	favoriteBlock,
	resetBlock,
	undoDelete
} from 'src/redux/reduxActions/block';
import { addNote, editNote, deleteNote, unsaveNote } from 'src/redux/reduxActions/notes';
import mongoose, { ObjectId } from 'mongoose';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';
import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import {
	PathParamsType,
	TGroupParent
} from 'src/components/collaborationTool/CollaborationTool.types';
import { addNodes, editNodes } from 'src/redux/reduxActions/node';
import { v1 as uuidv1 } from 'uuid';
import {
	getSiteMetaData,
	PROXY_API,
	SessionStorageKeys,
	SiteDetailsT,
	TreeEntry,
	useUpload
} from '@naya_studio/radix-ui';
import validator from 'validator';
import { batchCreateGroupAndBlocks } from 'src/redux/reduxActions/stage';
import { AppDispatch, store } from 'src';
import { CustomDispatch } from 'src/redux/actions/types';
import { addBatchUndoActions, undoAction } from 'src/redux/actions/undoRedoActions';
import { EActionType } from 'src/redux/reducers/undoRedo/undoActionHistory.types';
import { getFileLinkSubType } from 'src/components/utilities/navbar/utils';
import axios from 'axios';
import { useStpToGltfConverter } from 'src/hooks/useFileConverter';
import useUser from 'src/redux/hooks/user';
import { renameLinkAsset } from 'src/redux/reduxActions/integrations/utils';
import { CollaborationToolContext } from 'src/components/collaborationTool/CollaborationTool';
import {
	onBlocksEdit,
	onDeleteBlock,
	onDeletePhase,
	onGroupsEdit
} from 'src/components/journeyContainer/JourneyContainerCallbacks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { addTempNotesRedux } from 'src/redux/features/notes';
import { addTempNotesOnGroups, removeTempNotesOnGroups } from 'src/redux/features/stages';
import { capitalize, clone, isNumber } from 'lodash';
import {
	createExcalidrawFile,
	deleteExcalidrawFile,
	getFileUrl
} from '@naya_studio/excalidraw-viewer';
import { checkUserAccessLevel } from '../accessLevel/accessLevelActions';
import {
	convertLinkToPng,
	fetchFigmaFileTitle,
	generatePDFThumbnail,
	generateVideoThumbnail,
	getBlockType,
	scrollToView
} from './util';
import { TAddBlocksFnArgs } from './blockAction.types';
import { useNotifications } from '../notifications/useNotifications';
import trackEvent from '../analytics/analytics';
import { CustomEvents } from '../analytics/events';
import getUserFromRedux from '../helper/user';
import { updateLocalForageData } from '../storage/indexedDBStorage';
import uploadToGDrive from '../sync/uploads';
import {
	TAddColorEventData,
	TAddDateEventData,
	TMarkFavoriteEventData
} from '../analytics/analytic.types';
import { formatFolderTreeData } from '../collaboration/formatFolderTreeData';
import getBlockFromReduxById from '../helper/block';

// Holds blockId with blockType as value
const blocksToConvert: { [key: string]: keyof typeof EBlockType } = {};

// Type for the params of onAddBlocks fn
export type TOnAddBlocksParams = {
	blocksToAdd: TAddBlocksFnArgs[];
	options: {
		phaseName?: string;
		phaseId?: string;
		newBlockIndex?: number;
		newPhaseIndex?: number;
		existingBlockId?: string;
		snackbarMessage?: string;
	};
	next?: () => void;
};

export type TOnRemoveBlocksParams = {
	blocksToRemove: IBlock[];
	options: {
		phaseName?: string;
		phaseId?: string;
		newBlockIndex?: number;
		newPhaseIndex?: number;
		existingBlockId?: string;
	};
};

/**
 * Function that gets the decoded version of the MS links
 * @param initialUrl string - shortened MS url
 * @returns
 */
export const getMSUrl = async (initialUrl: string) => {
	try {
		// Pass the MS link to Naya Proxy and get the final url
		const response = await axios.get(`${PROXY_API}/${initialUrl}`, {
			maxRedirects: 0
		}); // Prevent automatic redirection

		let finalUrl = response.headers['x-final-url'];

		if (finalUrl.includes('edit.aspx')) {
			finalUrl = finalUrl.replace('edit.aspx', 'redir');
		}

		// Show Error Snackbar if onedrive link is user specific
		if (finalUrl.includes('login')) {
			addSnackbar({
				show: true,
				type: 'ERROR',
				text: 'To preview Microsoft assets: add link from "Share", not the URL bar.',
				actionButtonData: []
			});
			removeSnackbar(5000);
		}
		return await Promise.resolve(finalUrl);
	} catch (error) {
		console.error('Error:', error);
		return Promise.resolve('');
	}
};

/**
 * Hook to handle all the block related actions
 * @returns actions related to blocks
 */
const useBlockActions = (
	trigger3DGenerationIf3DRedirect?: (projectId: string, phase: IGroup, blockId: string) => void
) => {
	const dispatch = useDispatch<AppDispatch>();
	const [uploadIds, setUploadIds] = useState<Array<string>>([]);
	const blocks = useSelector((state: ReduxState) => state.blocks.data);
	const notes = useSelector((state: ReduxState) => state.notes.data);
	const nodes = useSelector((state: ReduxState) => state.nodes.data);
	const groupsRedux = useSelector((state: ReduxState) => state.stages.data);
	const userRef = useRef<IUser>();
	userRef.current = useUser().user;
	const params = useParams<PathParamsType>();
	const history = useHistory();
	const [startUploadForBlock, setStartUploadForBlock] = useState<Array<string>>([]);
	const { canvasId, projectId, stageOrDashboard } = params;
	const { markNotificationCompleteByBlockId } = useNotifications();
	const { convertStepToGltf } = useStpToGltfConverter();
	const collabContext = useContext(CollaborationToolContext);
	const { handleUndoRedo } = collabContext;

	const { createInNaya, isSyncStorageEnabled, isSyncDriveEnabled, isLinkEmbedsEnabled } =
		useFlags();

	/**
	 * Handle the image based on its extension and update the image source.
	 *
	 * @function handleImage
	 * @return {cloudinarySrc} returns the cloudinary url
	 */
	const handleImage = async (link: string, fileName: string) => {
		// Extract the file extension and filename without extension
		const ext = fileName.split('.').pop() as string;
		// Determine the image source based on the extension
		let cloudinarySrc = '';
		if (
			ext.toLowerCase() === 'heic' ||
			ext.toLowerCase() === 'psd' ||
			ext.toLowerCase() === 'ai'
		) {
			// Decode the link
			const decodedLink = decodeURIComponent(link);
			const regex = /\/([^/]+)(?=\.\w+\?alt=media&token=)/;
			const match = decodedLink.match(regex);
			if (match && match[1]) {
				const extractedPart = match[1];
				const cloudinaryUrl = process.env.REACT_APP_CLOUDINARY_URL;
				const bucket =
					window.__RUNTIME_CONFIG__.REACT_APP_FS_STORAGE_BUCKET || 'naya-uploads-dev';
				const imagePath = `${cloudinaryUrl}${bucket}/${projectId}/IMAGE/${extractedPart}`;
				// making this request to the image first with the heic, psd, or ai extension
				try {
					const imageResponse = await fetch(`${imagePath}.${ext}`);
					if (imageResponse.ok) {
						cloudinarySrc = `${imagePath}.png`;
					} else {
						cloudinarySrc = `${imagePath}.png`;
						console.error('Failed to download the image.');
					}
				} catch (error) {
					console.error('Error while fetching the image:', error);
				}
			} else {
				cloudinarySrc = link;
			}
		} else {
			// Use the original image source
			cloudinarySrc = link;
		}
		// Update the image source
		return cloudinarySrc;
	};

	/**
	 * Update a block with its corresponding thumbnail based on the block type.
	 * @param blockId The ID of the block to update.
	 * @param payload The payload containing block data.
	 * @param blockType Optional block type.
	 */
	const updateBlockWithThumbnail = async (
		blockId: string,
		payload: TEditBlockFnArgs['payload'],
		oldThumbnail?: string,
		isCustom?: boolean,
		blockType?: keyof typeof EBlockType,
		retryCount: number = 0
	) => {
		try {
			if (!blockType) return; // No block type specified, exit function

			let thumbnailUrl: string | undefined;

			switch (blockType) {
				case 'VIDEO': {
					const videoLink = (payload as IVideo)?.link;
					if (videoLink) {
						thumbnailUrl = await generateVideoThumbnail(videoLink);
					}
					break;
				}
				case 'PDF': {
					const pdfLink = (payload as IPdf)?.link;
					if (pdfLink) {
						thumbnailUrl = await generatePDFThumbnail(pdfLink);
					}
					break;
				}
				case 'IMAGE': {
					const imagePayload = payload as IImage;
					if (imagePayload?.link) {
						thumbnailUrl = await handleImage(
							imagePayload.link,
							imagePayload.fileName || imagePayload.name
						);
					}
					break;
				}
				default:
					break;
			}

			if (thumbnailUrl) {
				const thumbnailPayload: TEditBlockArgs = {
					data: {
						block: {
							thumbnail: {
								src: isCustom ? oldThumbnail : thumbnailUrl,
								originalSrc: thumbnailUrl,
								isCustom
							},
							_id: blockId,
							originalLink: (payload as IImage).link // Original link stored for reference
						} as TBlockEdit,
						...generateIdsFromUrl()
					},
					prevState: {
						prevStateBlock: blocks[blockId] as IBlock
					}
				};

				dispatch(editBlock(thumbnailPayload));
			}
		} catch (error) {
			if (retryCount < 3)
				await updateBlockWithThumbnail(
					blockId,
					payload,
					oldThumbnail,
					isCustom,
					blockType,
					retryCount + 1
				);
			else {
				console.error('Error updating block with thumbnail:', error);
				const errorSnackbar: ISnackBar = {
					text: `Failed to generate thumbnail for ${capitalize(blockType)} block.`,
					show: true,
					type: 'ERROR'
				};

				addSnackbar(errorSnackbar);
				removeSnackbar(3000);
			}
		}
	};

	const { uploadFileToBlock } = useUpload(
		canvasId,
		(urlLink: string, fileName: string, id?: string) => {
			const extension = fileName.split('.').pop();

			// TODO remove typecast to any
			let uploadObj = window.uploadQueue.getSnapshot().find((obj: any) => obj.id === id);

			if (!uploadObj) {
				uploadObj = window.uploadQueue
					.getSnapshot()
					.find((obj: any) => obj.url === urlLink);
			}

			const block = fetchBlockByIdFromRedux(uploadObj.id);
			const blockType = getBlockType(uploadObj.file.name);
			const finalLink = blockType === 'THREE_D' ? [urlLink] : urlLink;
			const convertBlockPayload: TConvertBlockArgs = {
				data: {
					blockData: {
						_id: uploadObj.id,
						blockType,
						link: finalLink,
						originalLink: finalLink,
						uploadedBy: userRef.current?._id as string,
						name:
							block?.name.startsWith('Untitled') || block?.name === 'UNDEFINED'
								? fileName
								: block?.name,
						fileName,
						extension
					},
					blockType,
					blockId: uploadObj.id,
					stageId: '',
					projectId
				},
				prevState: {
					prevStateBlock: blocks[uploadObj.id] as IBlock
				}
			};

			// TODO: see if there's a better solution
			const blockLoader = setInterval(() => {
				const blocksLoader = store.getState().blocks.loading;
				const targetBlock = blocksLoader[convertBlockPayload.data.blockId];
				if (!targetBlock) {
					clearInterval(blockLoader);
					const { blockData } = convertBlockPayload.data;
					dispatch(convertBlock(convertBlockPayload)).then(() => {
						// When files are dropped on journey they will go through this useUpload
						// Update the block thumbnail.
						if ((blockData as ILink).link && blockData?.blockType) {
							updateBlockWithThumbnail(
								blockData?._id as string,
								blockData,
								undefined,
								false,
								blockData?.blockType
							);
						}
					});
				}
			}, 300);

			const { blockFiles } = window;

			if (blockFiles) {
				window.blockFiles = blockFiles.filter(
					(b: { id: string; file: File }) => b.id !== uploadObj.id
				);
			}
		},
		(file: File, callback: () => void) => {
			if (file) {
				callback();
			}
		},
		projectId
	);

	/**
	 * This useEffect triggers upload of files on blocks which are not mounted
	 */
	useEffect(() => {
		if (startUploadForBlock) {
			const idFileObject = [];
			for (let i = 0; i < startUploadForBlock.length; i++) {
				const { blockFiles } = window;

				if (blockFiles) {
					const blockFound = blockFiles.find(
						(block: { id: string; file: File }) => block.id === startUploadForBlock[i]
					);
					if (blockFound) {
						idFileObject.push(blockFound);
						setTimeout(() => {
							window.blockFiles = blockFiles.filter(
								(block: { id: string; file: File }) =>
									block.id !== startUploadForBlock[i]
							);
						}, 1000);
					}
				}

				if (i === startUploadForBlock.length - 1) {
					uploadFileToBlock(idFileObject);
					break;
				}
			}
		}
	}, [startUploadForBlock]);

	// Function to start upload on file paste in expand state
	const startUploadOnPaste = (file: File, blockId: string) => {
		uploadFileToBlock([{ id: blockId, file }]);
	};

	/**
	 * Function to open miro boards picker and return miro board url on success
	 * @returns Promise
	 */
	const getMiroBoard = (): Promise<string> =>
		new Promise((resolve, reject) => {
			try {
				// @ts-ignore
				miroBoardsPicker.open({
					clientId: process.env.REACT_APP_MIRO_CLIENT_ID,
					action: 'access-link',
					success({ id }: { id: string }) {
						resolve(`https://miro.com/app/live-embed/${id}`);
					}
				});
			} catch (e) {
				reject(e);
			}
		});
	const addBlocksToGDrive = (uploadToDrive: TDriveData) => {
		if (!createInNaya || !isSyncStorageEnabled || !isSyncDriveEnabled) return;
		uploadToGDrive(projectId, uploadToDrive);
	};
	/**
	 * Function to handle undoing a delete
	 * @param blocksToRevive list of all the blocks that we are deleting
	 * @param groups list of groups with updated list of children
	 */
	const undoDeleteBlocks = (
		blocksToRevive: IBlock[],
		groups: IGroup[],
		parent: TGroupParent = { id: projectId, type: 'JOURNEY' },
		projectChildrenIds?: string[]
	) => {
		const prevGroups = store.getState().stages.data;
		const prevBlocks = store.getState().blocks.data;
		const project = store.getState().projects.data[projectId];
		const prevProjectChildrenIds = project?.children as string[];

		if (!projectChildrenIds && project && project.children) {
			projectChildrenIds = project.children as string[];
		}
		const resetBlockPayload: TUndoRedoArgs = {
			data: {
				blocks: blocksToRevive,
				groups,
				parent,
				projectChildrenIds: projectChildrenIds as string[]
			},
			prevState: {
				prevStateBlocks: prevBlocks,
				prevStateGroups: prevGroups,
				prevProjectChildrenIds
			}
		};

		dispatch(undoDelete(resetBlockPayload))
			.unwrap()
			.then(() => {
				updateLocalForageData('BLOCKS');
			});
	};

	/**
	 * Function to handle removing files from blocks when multiple blocks have been added
	 * @param blockId First block that was populated
	 * @param blockIds list of all the blockids to remove from blocks
	 * @param files list of files that were added
	 */
	const onRemoveFilesFromBlock = (
		blockId: string,
		blockIds: string[],
		files: File[],
		onAddFiles: (blockId: string, files: File[]) => void,
		isUndoRedo: boolean = false
	) => {
		const block = blocks[blockId] as IBlock;
		const group = findStageWithBlockId(blockId) as IGroup;
		const groupId = group?._id as string;
		let blockData = generateOptimisticBlock(
			blockId,
			groupId,
			projectId,
			`Untitled ${group.children.length}`,
			'EMPTY',
			block.users as TUserAndRole[],
			block.invitedEmails as TInvitedEmail[]
		);
		blockData = {
			...blockData,
			createdBy: block.createdBy,
			notes: block.notes
		};

		const resetBlockPayload: TResetBlockArgs = {
			data: {
				block: blockData
			},
			prevState: {
				prevStateBlock: block
			}
		};
		dispatch(resetBlock(resetBlockPayload));
		if (blockIds != null && blockIds.length > 0) {
			onDeleteBlock(blockIds, [groupId]);
		}
		if (!isUndoRedo) {
			// save history on remove file
			handleUndoRedo({
				type: 'ADD',
				payload: {
					originalAction: onRemoveFilesFromBlock,
					oppositeAction: onAddFiles,
					originalActionPayload: [blockId, blockIds, files],
					oppositeActionPayload: [blockId, files]
				}
			});
		}
	};
	// Function to display a snackbar notification indicating that a new asset has been added to the journey.
	// The snackbar includes two action buttons: 'Cancel' and 'Go there'.

	const showAssetsSnackbar = (blockId?: string | ObjectId, groupId?: string | ObjectId) => {
		const snackbarActionData = [
			{
				buttonData: 'Cancel',
				onClick: () => removeSnackbar(0),
				className: 'snackbar-cancel',
				show: true
			},
			{
				buttonData: 'Go there',
				onClick: () => {
					// if in expanded view
					if (canvasId && blockId && groupId) {
						history.push(`/project/${projectId}/${groupId}/${blockId}`);
					} else history.push(`/project/${projectId}`);
					removeSnackbar(0);
				},
				className: 'snackbar-gothere',
				show: true
			}
		];
		addSnackbar({
			show: true,
			type: 'NORMAL',
			text: 'New asset added to journey',
			actionButtonData: snackbarActionData
		});
		removeSnackbar(5000);
	};

	/**
	 * Function to add files to the block
	 * @param blockId
	 * @param files
	 */
	const onAddFilesToBlock = (blockId: string, files: File[], isUndoRedo: boolean = false) => {
		const newBlocksCreated = [];
		for (let i = 0; i < files.length; i++) {
			let blockType = getBlockType(files[i]?.name as string);
			// All blocks with file upload needs be converted
			if (!blocksToConvert[blockId]) {
				blocksToConvert[blockId] = blockType;
			}
			if (i === 0) {
				const blockFiles = {
					id: blockId,
					file: files[i]
				};
				window.blockFiles = (
					window.blockFiles ? [...window.blockFiles, blockFiles] : [blockFiles]
				) as {
					id: string;
					file: File;
				}[];

				dispatch(
					editBlockById({
						_id: blockId,
						blockType
					})
				);
			} else {
				const group = findStageWithBlockId(blockId) as IGroup;
				const groupId = group?._id as string;
				const isGroupCreated = (group?.children.length || 0) + 1 > 1;

				const stageUsers = store.getState().stages.data[groupId]?.users as TUserAndRole[];
				const userRedux: IUser = getUserFromRedux();
				if (
					checkUserAccessLevel(stageUsers, userRedux._id as string, ['EDITOR', 'OWNER'])
				) {
					const id = new mongoose.Types.ObjectId().toString() as string;
					newBlocksCreated.push(id);
					const blockFiles = {
						id,
						file: files[i]
					};

					window.blockFiles = (
						window.blockFiles ? [...window.blockFiles, blockFiles] : [blockFiles]
					) as {
						id: string;
						file: File;
					}[];
					blockType = EBlockType.EMPTY;
					const blockData = generateOptimisticBlock(
						id,
						groupId,
						projectId,
						'UNDEFINED',
						blockType
					);
					// generate payload for add single block action
					const apiPayload: TAddSingleBlockArgs = {
						data: {
							blockData,
							stageId: groupId,
							isGroupCreated,
							projectId
						},
						prevState: {
							group
						}
					};
					dispatch(addSingleBlock(apiPayload));
				}
			}
		}

		if (newBlocksCreated.length > 0) {
			setStartUploadForBlock(newBlocksCreated);
		}

		if (canvasId && files.length > 1) showAssetsSnackbar();
		if (!isUndoRedo) {
			// save history on add files
			handleUndoRedo({
				type: 'ADD',
				payload: {
					originalAction: onAddFilesToBlock,
					oppositeAction: onRemoveFilesFromBlock,
					originalActionPayload: [blockId, files],
					oppositeActionPayload: [blockId, newBlocksCreated, files, onAddFilesToBlock]
				}
			});
		}
	};

	/**
	 * Function to to get the block name by id.
	 * @param id Block Id
	 * @returns Block name || false
	 */
	const getBlockNameById = (id: string): string | false => {
		const block = blocks[id];
		if (block) {
			const name = block?.name;
			if (name === 'UNDEFINED') {
				const phase = findStageWithBlockId(block._id as string);
				if (phase) {
					const blockIndex = phase.children?.findIndex((bId) => bId === block._id);
					if (typeof blockIndex === 'number') return `Untitled ${blockIndex + 1}`;
				}
			} else {
				return name;
			}
		}
		return false;
	};

	// remove temporary notes that were added in multiselect
	const removeTempNotes = (data: { groups: string[]; blocks: string[]; color: string }) => {
		if (data.blocks) store.dispatch(removeTempNotesOnBlocks(data.blocks));
		if (data.groups) store.dispatch(removeTempNotesOnGroups(data.groups));
	};
	/**
	 * Function to handle block edit based upon edit type
	 */
	const onBlockEdit = async (
		{ editType, blockId, payload }: TEditBlockFnArgs,
		isUndoRedo: boolean = false
	) => {
		switch (editType) {
			case 'CONVERT_BLOCK': {
				const stage = findStageWithBlockId(blockId);
				const blockData: Partial<
					| IBlock
					| ICanvas
					| ILink
					| IImage
					| IPdf
					| IVideo
					| IFile
					| I3D
					| IText
					| IExaclidrawCanvas
				> = payload as Partial<I3D>;

				if (blockData.blockType === 'EXCALIDRAW_CANVAS') {
					createExcalidrawFile(projectId, blockId);
					(blockData as IExaclidrawCanvas).link = getFileUrl(blockId, projectId);
				}

				const convertBlockPayload: TConvertBlockArgs = {
					data: {
						blockData,
						blockType: (payload as TCanvasEdit).blockType,
						blockId,
						stageId: stage?._id as string,
						projectId
					},
					prevState: {
						prevStateBlock: blocks[blockId] as IBlock
					}
				};
				(store.dispatch as CustomDispatch)(convertBlock(convertBlockPayload));
				break;
			}

			case 'ADD_CANVAS': {
				const stage = findStageWithBlockId(blockId);
				const convertBlockPayload: TConvertBlockArgs = {
					data: {
						blockData: {
							_id: blockId,
							blockType: (payload as TCanvasEdit).blockType,
							bounds: { width: 1920, height: 1080, x: 0, y: 0 }
						} as ICanvas,
						blockType: (payload as TCanvasEdit).blockType,
						blockId,
						stageId: stage?._id as string,
						projectId
					},
					prevState: {
						prevStateBlock: blocks[blockId] as IBlock
					}
				};
				(store.dispatch as CustomDispatch)(convertBlock(convertBlockPayload))
					.unwrap()
					.then((response: TConvertBlockArgs) => {
						const {
							projectId: idOfProject,
							stageId: idOfStage,
							blockId: idOfBlock
						} = response.data;
						history.push(`/project/${idOfProject}/${idOfStage}/${idOfBlock}`);
					});
				break;
			}

			case 'ADD_TEXT': {
				const stage = findStageWithBlockId(blockId);
				// edit block of type TEXT and from promt to TEXT block
				const convertBlockPayload: TConvertBlockArgs = {
					data: {
						blockData: {
							_id: blockId,
							blockType: EBlockType.TEXT,
							text: (payload as IText).text
						} as IText,
						blockType: EBlockType.TEXT,
						blockId,
						stageId: stage?._id as string,
						projectId
					},
					prevState: {
						prevStateBlock: blocks[blockId] as IBlock
					}
				};
				(store.dispatch as CustomDispatch)(convertBlock(convertBlockPayload));
				if (!isUndoRedo) {
					// save history on add text
					handleUndoRedo({
						type: 'ADD',
						payload: {
							originalAction: onBlockEdit,
							oppositeAction: onBlockEdit,
							originalActionPayload: { editType, blockId, payload },
							oppositeActionPayload: {
								editType: 'RESET_BLOCK',
								blockId,
								payload
							}
						}
					});
				}
				break;
			}

			case 'ADD_TODO': {
				const oldBlock = blocks[blockId] as IBlock;
				const { parentId } = oldBlock;

				const convertBlockPayload: TConvertBlockArgs = {
					data: {
						blockData: {
							_id: blockId,
							blockType: EBlockType.TODO,
							todos: [] as Array<ITodoItem>
						} as ITodo,
						blockType: EBlockType.TODO,
						blockId,
						stageId: parentId,
						projectId
					},
					prevState: {
						prevStateBlock: oldBlock
					}
				};
				(store.dispatch as CustomDispatch)(convertBlock(convertBlockPayload));
				if (!isUndoRedo) {
					// save history on add text
					handleUndoRedo({
						type: 'ADD',
						payload: {
							originalAction: onBlockEdit,
							oppositeAction: onBlockEdit,
							originalActionPayload: { editType, blockId, payload },
							oppositeActionPayload: {
								editType: 'RESET_BLOCK',
								blockId,
								payload
							}
						}
					});
				}
				break;
			}

			case 'ADD_CANVAS_BLOCK': {
				// open Miro boards picker
				getMiroBoard().then(async (boardLink) => {
					const block = fetchBlockByIdFromRedux(blockId) as IBlock;
					const stageId = (block?.parentId ||
						findStageWithBlockId(blockId)?._id) as string;
					// extract board title
					const { title } = (await getSiteMetaData(boardLink as string)) as SiteDetailsT;
					const userRedux: IUser = getUserFromRedux();
					// edit block to type LINK with miro board link
					const convertBlockPayload: TConvertBlockArgs = {
						data: {
							blockData: {
								_id: blockId,
								blockType: 'LINK',
								link: boardLink,
								name:
									block.name.startsWith('Untitled') || block?.name === 'UNDEFINED'
										? title || 'UNDEFINED'
										: block.name,
								addedBy: userRedux._id as string
							} as ILink,
							blockType: 'LINK',
							blockId,
							stageId,
							projectId
						},
						prevState: {
							prevStateBlock: blocks[blockId] as IBlock
						}
					};
					(store.dispatch as CustomDispatch)(convertBlock(convertBlockPayload));
				});
				break;
			}

			case 'ADD_LINK': {
				const { link } = payload as TLinkEdit;
				if (validator.isURL(link)) {
					// Show a loading snackbar
					const waitingSnackbarPayload: ISnackBar = {
						text: 'Embedding your link into a block. Please hold on...',
						show: true,
						type: 'LOADER'
					};
					addSnackbar(waitingSnackbarPayload);

					const { title } = (await getSiteMetaData(link)) as SiteDetailsT;
					const figmaFileName = await fetchFigmaFileTitle(link);
					const userRedux: IUser = getUserFromRedux();
					const block = fetchBlockByIdFromRedux(blockId) as IBlock;

					/**
					 * To fetch appropriate name of the block
					 */
					const cleanBlockName = () => {
						if (block.name.startsWith('Untitled') || block?.name === 'UNDEFINED') {
							if (figmaFileName !== 'UNDEFINED') return figmaFileName;
							return title || 'UNDEFINED';
						}
						return block.name;
					};

					const convertBlockPayload: TConvertBlockArgs = {
						data: {
							blockData: {
								_id: blockId,
								blockType: 'LINK',
								link,
								name: cleanBlockName(),
								addedBy: userRedux._id as string
							} as ILink,
							blockType: 'LINK',
							blockId,
							stageId: block.parentId,
							projectId
						},
						prevState: {
							prevStateBlock: blocks[blockId] as IBlock
						}
					};

					// if a microsoft link
					if (link.includes('1drv') || link.includes('onedrive')) {
						const finalLink = await getMSUrl(link);
						(convertBlockPayload.data.blockData as ILink).link = finalLink as string;
					}

					const fileName = convertBlockPayload.data.blockData.name as string;
					await convertLinkToPng(
						link,
						isLinkEmbedsEnabled,
						fileName,
						(firebaseUrl: string) => {
							convertBlockPayload.data.blockData.thumbnail = {
								isCustom: false,
								originalSrc: firebaseUrl,
								src: firebaseUrl
							};
						}
					);

					const linkType = getFileLinkSubType(link, 'LINK') as ILinkSubtype;
					(convertBlockPayload.data.blockData as ILink).subType = linkType;

					dispatch(convertBlock(convertBlockPayload))
						.unwrap()
						.catch(() => {
							const errorSnackbarPayload: ISnackBar = {
								text: 'Failed to create link block. Please try again',
								show: true,
								type: 'ERROR'
							};
							addSnackbar(errorSnackbarPayload);
						})
						.finally(() => removeSnackbar(0));

					if (!isUndoRedo) {
						// save history on add link
						handleUndoRedo({
							type: 'ADD',
							payload: {
								originalAction: onBlockEdit,
								oppositeAction: onBlockEdit,
								originalActionPayload: { editType, blockId, payload },
								oppositeActionPayload: { editType: 'RESET_BLOCK', blockId, payload }
							}
						});
					}
				} else {
					addSnackbar({
						show: true,
						type: 'ERROR',
						text: 'Link is invalid. Please try again.'
					});
					removeSnackbar(5000);
				}
				break;
			}

			case 'ADD_FILE': {
				onAddFilesToBlock(blockId, (payload as TFileEdit).files);
				break;
			}

			case 'BLOCK_EDIT': {
				// generating edit block action payload
				const block = fetchBlockByIdFromRedux(blockId);
				// Check fot the .stp/.step files
				if (
					block?.blockType === 'FILE' &&
					(payload as IFile).link &&
					(payload as IFile).fileName
				) {
					const { link, fileName } = payload as IFile;
					const extension = fileName.split('.').pop();
					if (extension && ['stp', 'step'].includes(extension.toLowerCase())) {
						// Convert the stp files to gltf
						convertStepToGltf(link, fileName, blockId);
					}
				}
				if (block?.blockType === 'THREE_D') {
					if (typeof (payload as Partial<IFile | I3D>).link === 'string') {
						const { link } = payload as Partial<IFile>;
						(payload as Partial<I3D>).link = [link!];
						(payload as Partial<I3D>).originalLink = [link!];
						(payload as Partial<I3D>).appearance = {
							isWireframe: false,
							fill: 'UNSET'
						};
					}
				}
				// check if prevent empty block name
				if ((payload as IBlock)?.name?.trim() === '')
					(payload as IBlock).name = 'UNDEFINED';

				const editPayload: TEditBlockArgs = {
					data: {
						block: { ...payload, _id: blockId } as TBlockEdit,
						...generateIdsFromUrl()
					},
					prevState: {
						prevStateBlock: blocks[blockId] as IBlock
					}
				};

				if (
					(payload as TLinkEdit).link !== '' &&
					(payload as IFile).uploadedBy === '' &&
					userRef.current?._id
				) {
					(editPayload.data.block as IFile).uploadedBy = userRef.current?._id as string;
				}

				if (block?.blockType === 'IMAGE' && (payload as IFile).fileName) {
					(editPayload.data.block as IImage).link = await handleImage(
						(payload as IImage).link,
						(payload as IImage).fileName
					);
				}

				if (block?.blockType === 'TEXT' && (payload as Partial<IText>).text) {
					(editPayload.data.block as IText).text = (payload as Partial<IText>).text;
				}

				// Do this check only once when is uploaded to block
				if ((payload as IImage)?.fileName) {
					const updatedName = (payload as TBlockEdit).name as string;
					(editPayload.data.block as IBlock).name = (
						block?.name.startsWith('Untitled') || block?.name === 'UNDEFINED'
							? updatedName
							: block?.name
					) as string;
				}

				// renaming link asset if block title is edited and link type is integrated
				if (block?.blockType === 'LINK' && (payload as TBlockEdit).name) {
					const blockLink = (block as ILink).link;
					renameLinkAsset(blockLink, (payload as TBlockEdit).name as string);
				}
				const convertType = blocksToConvert[blockId];
				if (convertType && (payload as IFile)?.link) {
					delete blocksToConvert[blockId];
					const stage = findStageWithBlockId(blockId);
					const convertBlockPayload: TConvertBlockArgs = {
						data: {
							blockData: { ...editPayload.data.block, blockType: convertType },
							blockType: convertType,
							blockId,
							stageId: stage?._id as string,
							projectId
						},
						prevState: {
							prevStateBlock: blocks[blockId] as IBlock
						}
					};
					// Update REQUEST_FILE_UPLOAD notification as complete if there is any
					markNotificationCompleteByBlockId(blockId, EEvent.REQUEST_FILE_UPLOAD, true);
					dispatch(convertBlock(convertBlockPayload))
						.unwrap()
						.then(() =>
							updateBlockWithThumbnail(
								blockId,
								payload,
								block?.thumbnail.src || '#FFFFFF',
								block?.thumbnail.isCustom || false,
								block?.blockType
							)
						);
				} else {
					// edit block dispatch action
					dispatch(editBlock(editPayload))
						.unwrap()
						.then(() =>
							updateBlockWithThumbnail(
								blockId,
								payload,
								block?.thumbnail.src || '#FFFFFF',
								block?.thumbnail.isCustom || false,
								block?.blockType
							)
						);
				}
				break;
			}

			case 'ADD_DUE_DATE':
			case 'ADD_RANGE': {
				const editPayload: TEditBlockArgs = {
					data: {
						block: {
							...payload,
							_id: blockId
						} as TBlockEdit,
						...generateIdsFromUrl()
					},
					prevState: {
						prevStateBlock: blocks[blockId] as IBlock
					}
				};
				// if we have previous date, then it's an edit
				const prevDate = blocks[blockId]?.dates;
				let undoPayload;
				if (prevDate) {
					undoPayload = {
						editType: editType === 'ADD_DUE_DATE' ? 'ADD_DUE_DATE' : 'ADD_RANGE',
						blockId,
						payload: { ...payload, dates: prevDate }
					};
				} else {
					undoPayload = {
						editType: editType === 'ADD_DUE_DATE' ? 'REMOVE_DUE_DATE' : 'REMOVE_RANGE',
						blockId,
						payload
					};
				}
				if (!isUndoRedo) {
					handleUndoRedo({
						type: 'ADD',
						payload: {
							originalAction: onBlockEdit,
							oppositeAction: onBlockEdit,
							originalActionPayload: { editType, blockId, payload },
							oppositeActionPayload: undoPayload
						}
					});
				}

				// edit block dispatch action
				(store.dispatch as CustomDispatch)(editBlock(editPayload))
					.unwrap()
					.then(() => {
						if (!prevDate) {
							// Track adding date, range event
							const eventProps: TAddDateEventData = {
								elementType: 'BLOCK',
								elementId: blockId
							};
							trackEvent(
								editType === 'ADD_DUE_DATE'
									? CustomEvents.ADD_DATE
									: CustomEvents.ADD_RANGE,
								eventProps
							);
						}
					});
				break;
			}
			case 'REMOVE_DUE_DATE':
			case 'REMOVE_RANGE': {
				const prevBlock = blocks[blockId] as IBlock;
				const editPayload: TEditBlockArgs = {
					data: {
						block: {
							...payload,
							_id: blockId,
							dates: null
						} as TBlockEdit,
						...generateIdsFromUrl()
					},
					prevState: {
						prevStateBlock: prevBlock
					}
				};
				if (!isUndoRedo) {
					// Save history when due date is set
					handleUndoRedo({
						type: 'ADD',
						payload: {
							originalAction: onBlockEdit,
							oppositeAction: onBlockEdit,
							originalActionPayload: { editType, blockId, payload },
							oppositeActionPayload: {
								editType:
									editType === 'REMOVE_DUE_DATE' ? 'ADD_DUE_DATE' : 'ADD_RANGE',
								blockId,
								payload: {
									...payload,
									dates: prevBlock?.dates
								}
							}
						}
					});
				}

				// edit block dispatch action
				dispatch(editBlock(editPayload));
				break;
			}
			case 'TOGGLE_CHECKBOX': {
				const editPayload: TEditBlockArgs = {
					data: {
						block: {
							...payload,
							_id: blockId
						} as TBlockEdit,
						...generateIdsFromUrl()
					},
					prevState: {
						prevStateBlock: blocks[blockId] as IBlock
					}
				};

				const prevStatus = blocks[blockId]?.deliverableStatus;
				const undoPayload = {
					editType: 'TOGGLE_CHECKBOX',
					blockId,
					payload: { ...payload, deliverableStatus: prevStatus }
				};
				if (!isUndoRedo) {
					handleUndoRedo({
						type: 'ADD',
						payload: {
							originalAction: onBlockEdit,
							oppositeAction: onBlockEdit,
							originalActionPayload: { editType, blockId, payload },
							oppositeActionPayload: undoPayload
						}
					});
				}

				// edit block dispatch action
				dispatch(editBlock(editPayload));
				break;
			}

			case 'ADD_NODE': {
				const { stageId } = generateIdsFromUrl();
				const userId = userRef.current?._id as string;
				const nodeToAdd = {
					...(payload as TAddNode),
					createdBy: {
						_id: userId,
						profilePic: userRef.current?.profilePic,
						userName: userRef.current?.userName,
						email: userRef.current?.email
					},
					lastUpdatedBy: userId,
					blockId,
					projectId
				};

				if (!nodeToAdd._id) {
					nodeToAdd._id = uuidv1();
				}

				const apiPayload: TAddNodesArg = {
					data: {
						nodes: [nodeToAdd],
						blockId,
						stageId,
						projectId
					},
					prevState: {
						prevBlock: blocks[blockId] as IBlock,
						prevNodes: nodes
					}
				};

				(store.dispatch as CustomDispatch)(addNodes(apiPayload))
					.unwrap()
					.then((response?: { nodes?: INode[] }) => {
						if (response?.nodes) {
							if (response?.nodes?.length > 1) {
								const data: Array<{
									prevNodeData: INode | IFeedback;
									newNodeData: INode | IFeedback;
								}> = [];

								response?.nodes?.forEach((node: INode) => {
									if (node) {
										data.push({
											prevNodeData: node,
											newNodeData: { ...node, isVisible: false }
										});
									}
								});

								addBatchUndoActions(EActionType.NEW_NODE, data);
							} else {
								undoAction(
									EActionType.NEW_NODE,
									{ ...(response?.nodes![0] as INode), isVisible: false },
									response?.nodes[0] as INode,
									false
								);
							}
						}
					})
					.catch((error) => {
						console.error('Error : ', error);

						const snackbarPayload: ISnackBar = {
							text: 'Failed to upload files.',
							show: true,
							type: 'ERROR'
						};

						addSnackbar(snackbarPayload);
						removeSnackbar(2000);
					});
				break;
			}
			case 'EDIT_NODE': {
				const apiPayload: TEditNodesArgs = {
					data: {
						nodes: [
							{
								...(payload as TEditNode),
								lastUpdatedBy: userRef.current?._id as string
							}
						],
						blockId,
						stageId: stageOrDashboard,
						projectId
					},
					prevState: {
						prevBlocks: nodes
					}
				};

				dispatch(editNodes(apiPayload));
				break;
			}

			case 'ADD_BLOCKS_UPLOAD': {
				const newBlocksCreated = [];
				const { files } = payload as TFileEdit;
				for (let i = 0; i < files.length; i++) {
					let blockType = getBlockType(files[i]?.name as string);
					const id = new mongoose.Types.ObjectId().toString() as string;
					newBlocksCreated.push(id);
					const blockFiles = {
						id,
						file: files[i]
					};
					window.blockFiles = (
						window.blockFiles ? [...window.blockFiles, blockFiles] : [blockFiles]
					) as {
						id: string;
						file: File;
					}[];

					const group = findStageWithBlockId(blockId) as IGroup;
					const groupId = group?._id as string;
					const isGroupCreated = (group?.children.length || 0) + 1 > 1;

					blockType = EBlockType.EMPTY;
					const blockData = generateOptimisticBlock(
						id,
						groupId,
						projectId,
						'UNDEFINED',
						blockType
					);
					// generate payload for add single block action
					const apiPayload: TAddSingleBlockArgs = {
						data: {
							blockData,
							stageId: groupId,
							projectId,
							isGroupCreated
						},
						prevState: {
							group
						}
					};
					dispatch(addSingleBlock(apiPayload));
				}

				if (newBlocksCreated.length > 0) {
					setStartUploadForBlock(newBlocksCreated);
				}
				if (newBlocksCreated.length > 0) showAssetsSnackbar();
				return newBlocksCreated[0];
			}

			case 'NEW_BLOCK_ADD_LINK': {
				const { link } = payload as TLinkEdit;
				// Show a loading snackbar
				const waitingSnackbarPayload: ISnackBar = {
					text: 'Embedding your link into a block. Please hold on...',
					show: true,
					type: 'LOADER'
				};
				addSnackbar(waitingSnackbarPayload);

				const id = new mongoose.Types.ObjectId().toString() as string;
				const group = findStageWithBlockId(blockId) as IGroup;
				const groupId = group?._id as string;
				const isGroupCreated = (group?.children.length || 0) + 1 > 1;
				const figmaFileName = await fetchFigmaFileTitle(link);

				const blockData = generateOptimisticBlock(
					id,
					groupId,
					projectId,
					figmaFileName,
					'LINK'
				) as ILink;
				const userRedux: IUser = getUserFromRedux();

				blockData.addedBy = userRedux._id as string;

				// if a microsoft link, transform it to a url that can be opened
				if (link.includes('1drv') || link.includes('onedrive')) {
					const finalLink = await getMSUrl(link);
					blockData.link = finalLink as string;
				} else blockData.link = link;
				const apiPayload: TAddSingleBlockArgs = {
					data: {
						blockData,
						stageId: groupId,
						projectId,
						isGroupCreated
					},
					prevState: {
						group
					}
				};

				const fileName = blockData.name as string;
				await convertLinkToPng(
					link,
					isLinkEmbedsEnabled,
					fileName,
					(firebaseUrl: string) => {
						apiPayload.data.blockData.thumbnail = {
							isCustom: false,
							originalSrc: firebaseUrl,
							src: firebaseUrl
						};
					}
				);

				dispatch(addSingleBlock(apiPayload))
					.unwrap()
					.catch(() => {
						const errorSnackbarPayload: ISnackBar = {
							text: 'Failed to create link block. Please try again',
							show: true,
							type: 'ERROR'
						};
						addSnackbar(errorSnackbarPayload);
					})
					.finally(() => removeSnackbar(0));

				showAssetsSnackbar();
				return id;
			}

			case 'SNAPSHOT_BLOCK_UPLOAD': {
				const newBlocksCreated = [];
				const { files } = payload as TFileEdit;
				let blockType = getBlockType(files[0]?.name as string);
				const id = new mongoose.Types.ObjectId().toString() as string;
				newBlocksCreated.push(id);
				const blockFiles = {
					id,
					file: files[0]
				};
				window.blockFiles = (
					window.blockFiles ? [...window.blockFiles, blockFiles] : [blockFiles]
				) as {
					id: string;
					file: File;
				}[];

				const group = findStageWithBlockId(blockId) as IGroup;
				const groupId = group?._id as string;
				const isGroupCreated = (group?.children.length || 0) + 1 > 1;

				blockType = EBlockType.EMPTY;
				const blockData = generateOptimisticBlock(
					id,
					groupId,
					projectId,
					'UNDEFINED',
					blockType
				);
				// generate payload for add single block action
				const apiPayload: TAddSingleBlockArgs = {
					data: {
						blockData,
						stageId: groupId,
						projectId,
						isGroupCreated
					},
					prevState: {
						group
					}
				};
				dispatch(addSingleBlock(apiPayload));

				if (newBlocksCreated.length > 0) {
					setStartUploadForBlock(newBlocksCreated);
				}
				if (newBlocksCreated.length > 0) {
					const snackbarActionData = [
						{
							buttonData: 'Cancel',
							onClick: () => removeSnackbar(0),
							className: 'snackbar-cancel',
							show: true
						},
						{
							buttonData: 'Open snapshot',
							onClick: () => {
								history.push(`/project/${projectId}/${groupId}/${id}`);
								removeSnackbar(0);
							},
							className: 'snackbar-gothere',
							show: true
						}
					];
					addSnackbar({
						show: true,
						type: 'LOADER',
						text: 'Creating snapshot',
						actionButtonData: []
					});
					removeSnackbar(2000);
					setTimeout(async () => {
						addSnackbar({
							show: true,
							type: 'NORMAL',
							text: 'Snapshot created',
							actionButtonData: snackbarActionData
						});
						removeSnackbar(3000);
					}, 2100);
				}
				break;
			}

			case 'ADD_BLOCK_FAVORITE': {
				// Function to handle favouriting or unfavouriting a block
				const block = { ...fetchBlockByIdFromRedux(blockId) } as IBlock; // create a shallow copy
				const userId = userRef.current?._id as string;
				const { likedBy } = block;
				let trackFavorite = false;
				if (likedBy?.includes(userId)) {
					// User has already liked, so we remove the like
					block.likedBy = likedBy.filter((likedUserId) => likedUserId !== userId);
				} else {
					trackFavorite = true;
					// User has not liked, so we add the like
					block.likedBy = [...(likedBy || []), userId];
				}

				const editPayload: TEditBlockArgs = {
					data: {
						block: { ...payload, likedBy: block.likedBy, _id: blockId } as TBlockEdit, // create a shallow copy
						...generateIdsFromUrl()
					},
					prevState: {
						prevStateBlock: { ...blocks[blockId] } as IBlock // create a shallow copy
					}
				};

				// Favourite block dispatch action
				(store.dispatch as CustomDispatch)(favoriteBlock(editPayload))
					.unwrap()
					.then(() => {
						// Track favoriting block event
						const eventProps: TMarkFavoriteEventData = {
							elementId: blockId,
							elementType: 'BLOCK'
						};

						if (trackFavorite) trackEvent(CustomEvents.MARK_FAVORITE, eventProps);
					});
				if (!isUndoRedo) {
					// Save history when favorite is added
					handleUndoRedo({
						type: 'ADD',
						payload: {
							originalAction: onBlockEdit,
							oppositeAction: onBlockEdit,
							originalActionPayload: { editType, blockId, payload },
							oppositeActionPayload: { editType, blockId, payload }
						}
					});
				}
				break;
			}

			case 'ADD_NOTE': {
				// Destructure payload properties for adding a new note
				const {
					text,
					color,
					taggedUsers,
					savedNoteReference,
					parentType,
					parentId,
					_id,
					fromMultiSelect,
					type
				} = payload as INote & { fromMultiSelect: boolean };
				const reduxState = store.getState();
				const parent =
					(reduxState.stages.data[parentId] as IGroup) ||
					(reduxState.blocks.data[parentId] as IBlock);

				const alreadyAddedNotes =
					parent.notes?.filter((note) => !(note as string)?.includes('NEW_NOTE')) || [];
				// Before dispatch check if block already has 3 notes
				if (!parent?.notes || alreadyAddedNotes?.length < 3) {
					// Create new note data with necessary properties
					const noteData: INote = {
						_id,
						text,
						color,
						parentId,
						parentType,
						projectId,
						taggedUsers,
						createdBy: userRef.current?._id as string,
						lastUpdatedBy: userRef.current?._id as string,
						createdAt: new Date(),
						updatedAt: new Date(),
						savedNoteReference: savedNoteReference || null,
						type
					};

					dispatch(addNote({ payload: noteData }))
						.unwrap()
						.then(() => {
							if (_id === savedNoteReference) {
								// Show Snackbar only if a note is being saved
								const sanckbar: ISnackBar = {
									text: 'Note successfully saved for reuse.',
									show: true,
									type: 'NORMAL'
								};

								addSnackbar(sanckbar);
								removeSnackbar(5000);
							}
						})
						.catch(() => {
							const sanckbar: ISnackBar = {
								text: 'Error updating note',
								show: true,
								type: 'ERROR'
							};

							addSnackbar(sanckbar);
							removeSnackbar(5000);
						});
					if (!isUndoRedo) {
						// Dispatch an action to add the new note
						handleUndoRedo({
							type: 'ADD',
							payload: {
								originalAction: onBlockEdit,
								oppositeAction: onBlockEdit,
								originalActionPayload: { editType, blockId, payload },
								oppositeActionPayload: {
									editType: 'DELETE_NOTE',
									blockId,
									payload: { ...payload, _id: noteData._id }
								}
							}
						});
					}
				} else {
					const newNoteErrorSnackBarData: ISnackBar = {
						show: true,
						text: fromMultiSelect
							? 'Maximum number of notes already added to some of the selection.'
							: 'Maximum number of sticky note is reached',
						type: 'ERROR'
					};

					// Display snackbar
					addSnackbar(newNoteErrorSnackBarData);

					// Hide snackbar after 5 seconds
					removeSnackbar(5000);
					if (parentType === 'GROUP') {
						store.dispatch(removeTempNotesOnGroups([parentId]));
					} else {
						store.dispatch(removeTempNotesOnBlocks([parentId]));
					}
				}
				removeTempNotes({
					groups: parentType === 'GROUP' ? [parentId] : [],
					blocks: parentType === 'BLOCK' ? [parentId] : [],
					color: ''
				});
				break;
			}
			case 'EDIT_NOTE': {
				// Destructure payload properties for editing a note
				const {
					text,
					color,
					taggedUsers,
					_id: noteId,
					savedNoteReference
				} = payload as INote;

				// Find the old note based on _id within the specified blockId
				const oldNote = notes[noteId as string] as INote;

				// check if note is being saved or is it normal edit
				let isNoteBeingSaved = false;
				const noteFromRedux = store.getState().notes.data[noteId as string];
				if (!noteFromRedux?.savedNoteReference && savedNoteReference) {
					isNoteBeingSaved = true;
				}
				if (oldNote) {
					// Create updated note data based on changes
					const noteData: INote = {
						...oldNote,
						text,
						color,
						taggedUsers,
						lastUpdatedBy: userRef.current?._id as string,
						updatedAt: new Date(),
						savedNoteReference: savedNoteReference || null
					};

					// Dispatch an action to edit the note
					dispatch(
						editNote({
							payload: noteData,
							prevState: {
								noteBeforeUpdate: oldNote
							}
						})
					)
						.unwrap()
						.then(() => {
							if (isNoteBeingSaved) {
								// Show Snackbar only if a note is being saved
								const sanckbar: ISnackBar = {
									text: 'Note successfully saved for reuse.',
									show: true,
									type: 'NORMAL'
								};

								addSnackbar(sanckbar);
								removeSnackbar(5000);
							}
						})
						.catch(() => {
							const sanckbar: ISnackBar = {
								text: 'Error updating note',
								show: true,
								type: 'ERROR'
							};

							addSnackbar(sanckbar);
							removeSnackbar(5000);
						});
				}
				if (!isUndoRedo) {
					handleUndoRedo({
						type: 'ADD',
						payload: {
							originalAction: onBlockEdit,
							oppositeAction: onBlockEdit,
							originalActionPayload: { editType, blockId, payload },
							oppositeActionPayload: { editType, blockId, payload: oldNote }
						}
					});
				}
				break;
			}
			case 'UNSAVE_NOTE': {
				// Dispatch an action to edit the note
				dispatch(unsaveNote({ payload } as { payload: Partial<INote> }))
					.unwrap()
					.then(() => {
						const sanckbar: ISnackBar = {
							text: 'Note removed from saved notes.',
							show: true,
							type: 'NORMAL'
						};

						addSnackbar(sanckbar);
						removeSnackbar(2000);
					})
					.catch(() => {
						const sanckbar: ISnackBar = {
							text: 'Error removing notes from saved notes.',
							show: true,
							type: 'NORMAL'
						};

						addSnackbar(sanckbar);
						removeSnackbar(2000);
					});
				break;
			}
			case 'DELETE_NOTE': {
				// Destructure payload properties for deleting a note
				const { _id: noteId, parentId, parentType } = payload as INote;
				// Find the old note based on _id within the specified blockId
				const oldNote = notes[noteId as string] as INote;

				// Dispatch an action to delete the note
				dispatch(
					deleteNote({
						payload: {
							noteId: noteId as string,
							parentId: parentId as string,
							parentType
						},
						prevState: {
							noteBeforeUpdate: oldNote
						},
						next: () => {
							addSnackbar({
								show: true,
								type: 'ERROR',
								text: 'Sticky note deleted.'
							});
							removeSnackbar(5000);
						}
					})
				);
				if (!isUndoRedo) {
					handleUndoRedo({
						type: 'ADD',
						payload: {
							originalAction: onBlockEdit,
							oppositeAction: onBlockEdit,
							originalActionPayload: { editType, blockId, payload },
							oppositeActionPayload: {
								editType: 'ADD_NOTE',
								blockId,
								payload: oldNote
							}
						}
					});
				}
				break;
			}

			case 'ADD_COLOR': {
				// Add color to the block
				const editPayload: TEditBlockArgs = {
					data: {
						block: {
							...payload,
							_id: blockId
						} as TBlockEdit,
						...generateIdsFromUrl()
					},
					prevState: {
						prevStateBlock: blocks[blockId] as IBlock
					}
				};

				// edit block dispatch action
				(store.dispatch as CustomDispatch)(editBlock(editPayload))
					.unwrap()
					.then(() => {
						// Track adding custom color on block event
						const eventProps: TAddColorEventData = {
							elementId: blockId,
							elementType: 'BLOCK',
							color: (payload as TAddColor).color
						};
						trackEvent(CustomEvents.ADD_CUSTOM_COLOR, eventProps);
					});
				break;
			}

			case 'RESET_BLOCK': {
				const reduxState = store.getState();
				const block = blocks[blockId] as IBlock;
				const group = reduxState.stages.data[block.parentId] as IGroup;
				if (block.blockType === 'EXCALIDRAW_CANVAS') {
					deleteExcalidrawFile(projectId, blockId);
				}
				let blockData = generateOptimisticBlock(
					blockId,
					group?._id as string,
					projectId,
					block.name,
					'EMPTY',
					block.users as TUserAndRole[],
					block.invitedEmails as TInvitedEmail[]
				);
				blockData = {
					...blockData,
					createdBy: block.createdBy,
					notes: []
				};

				const resetBlockPayload: TResetBlockArgs = {
					data: {
						block: blockData
					},
					prevState: {
						prevStateBlock: block
					}
				};
				dispatch(resetBlock(resetBlockPayload));

				// save history on reset link or file
				if (!isUndoRedo) {
					handleUndoRedo({
						type: 'ADD',
						payload: {
							originalAction: onBlockEdit,
							oppositeAction: onBlockEdit,
							originalActionPayload: { editType, blockId, payload },
							oppositeActionPayload: {
								editType: 'BLOCK_EDIT',
								blockId,
								payload: block
							}
						}
					});
				}
				break;
			}

			case 'DELETE_BLOCK': {
				const projectChildrenIds = store.getState().projects.data[projectId]?.children;
				const parentGroup = store.getState().stages.data[(payload as IBlock).parentId];
				const parent = {
					id: (payload as IBlock).parentId,
					type: parentGroup?.parentId === projectId ? 'JOURNEY' : 'PHASE'
				};
				onDeleteBlock([blockId], [(payload as IBlock).parentId]);
				if (!isUndoRedo) {
					handleUndoRedo({
						type: 'ADD',
						payload: {
							originalAction: onDeleteBlock,
							oppositeAction: undoDeleteBlocks,
							originalActionPayload: [[blockId], [(payload as IBlock).parentId]],
							oppositeActionPayload: [
								[blocks[blockId]],
								Object.values(groupsRedux),
								parent,
								projectChildrenIds
							]
						}
					});
				}
				const sessionOrderBlocks = window.sessionStorage.getItem('ordered-blocks');
				if (typeof sessionOrderBlocks === 'string') {
					const orderedBlocks = JSON.parse(sessionOrderBlocks as string);
					for (let i = 0; i < orderedBlocks.length; i++) {
						if (orderedBlocks[i].blockId === blockId) {
							if (i < orderedBlocks.length - 1) {
								history.push(
									`/project/${projectId}/${orderedBlocks[i + 1].stageId}/${
										orderedBlocks[i + 1].blockId
									}`
								);
							} else if (i > 0) {
								history.push(
									`/project/${projectId}/${orderedBlocks[i - 1].stageId}/${
										orderedBlocks[i - 1].blockId
									}`
								);
							} else {
								history.push(`/project/${projectId}`);
							}
						}
					}

					const snackbarPayload: ISnackBar = {
						text: 'Block deleted successfully.',
						show: true,
						type: 'ERROR',
						actionButtonData: [
							{
								buttonData: 'Undo',
								onClick: () => {
									removeSnackbar(0);
									handleUndoRedo({ type: 'UNDO' });
									setTimeout(() => {
										history.push(
											`/project/${projectId}/${
												(payload as IBlock).parentId
											}/${blockId}`
										);
									}, 200);
								},
								className: 'snackbar-cancel',
								show: true
							}
						]
					};

					addSnackbar(snackbarPayload);
					removeSnackbar(5000);
				}
				break;
			}

			case 'ADD_PASSWORD': {
				// Add password to the block
				const { password } = payload as TPasswordProtection;
				const salt = await bcrypt.genSalt(10);
				const hashedPassword = password ? await bcrypt.hash(password, salt) : null;
				const editPayload: TEditBlockArgs = {
					data: {
						block: {
							password: hashedPassword,
							_id: blockId
						} as TBlockEdit,
						...generateIdsFromUrl()
					},
					prevState: {
						prevStateBlock: blocks[blockId] as IBlock
					}
				};
				if (!isUndoRedo) {
					handleUndoRedo({
						type: 'ADD',
						payload: {
							originalAction: onBlockEdit,
							oppositeAction: onBlockEdit,
							originalActionPayload: {
								editType,
								blockId,
								payload
							},
							oppositeActionPayload: {
								editType: 'REMOVE_PASSWORD',
								blockId,
								payload: { ...payload, password: null }
							}
						}
					});
				}
				// edit block dispatch action
				(store.dispatch as CustomDispatch)(editBlock(editPayload));
				addSnackbar({
					show: true,
					type: 'NORMAL',
					text: 'Password protection added.'
				});
				removeSnackbar(3000);
				break;
			}
			case 'REMOVE_PASSWORD': {
				const storedValue = sessionStorage.getItem(SessionStorageKeys.VERIFIED_BLOCK_IDS);
				let blockIds: string[] = storedValue ? JSON.parse(window.atob(storedValue)) : [];
				if (blockIds.length > 0) {
					blockIds = blockIds.filter((bckId) => bckId !== blockId);
					sessionStorage.setItem(
						SessionStorageKeys.VERIFIED_BLOCK_IDS,
						window.btoa(JSON.stringify(blockIds))
					);
				}
				// Remove password
				const editPayload: TEditBlockArgs = {
					data: {
						block: {
							...payload,
							_id: blockId
						} as TBlockEdit,
						...generateIdsFromUrl()
					},
					prevState: {
						prevStateBlock: blocks[blockId] as IBlock
					}
				};
				if (!isUndoRedo) {
					handleUndoRedo({
						type: 'ADD',
						payload: {
							originalAction: onBlockEdit,
							oppositeAction: onBlockEdit,
							originalActionPayload: {
								editType,
								blockId,
								payload
							},
							oppositeActionPayload: {
								editType: 'BLOCK_EDIT',
								blockId,
								payload: {
									...payload,
									password: fetchBlockByIdFromRedux(blockId)?.password
								}
							}
						}
					});
				}
				// edit block dispatch action
				(store.dispatch as CustomDispatch)(editBlock(editPayload));

				addSnackbar({
					show: true,
					type: 'NORMAL',
					text: 'Password protection removed'
				});
				removeSnackbar(3000);
				break;
			}

			case 'INSERT_TODO': {
				const { blocks: allBlocks } = store.getState();
				const oldBlock = allBlocks.data[blockId] as ITodo;

				const editPayload: TEditBlockArgs = {
					data: {
						block: {
							todos: (oldBlock.todos || []).concat(payload as ITodoItem),
							_id: blockId
						} as TBlockEdit,
						...generateIdsFromUrl()
					},
					prevState: {
						prevStateBlock: oldBlock
					}
				};
				if (!isUndoRedo) {
					handleUndoRedo({
						type: 'ADD',
						payload: {
							originalAction: onBlockEdit,
							oppositeAction: onBlockEdit,
							originalActionPayload: {
								editType,
								blockId,
								payload
							},
							oppositeActionPayload: {
								editType: 'REMOVE_TODO',
								blockId,
								payload: {
									index: (oldBlock.todos || []).length,
									id: (payload as ITodoItem)._id
								}
							}
						}
					});
				}

				// edit block dispatch action
				(store.dispatch as CustomDispatch)(editBlock(editPayload));
				break;
			}
			case 'EDIT_TODO': {
				const { blocks: allBlocks } = store.getState();
				const oldBlock = allBlocks.data[blockId] as ITodo;
				const oldTodos = clone(oldBlock.todos as Array<ITodoItem>);
				const todoEditPayload = payload as TEditTodo;

				const oldTodo = oldTodos[todoEditPayload.index];
				if (oldTodo?._id !== todoEditPayload.id) {
					const errorSnackbar: ISnackBar = {
						text: 'Failed to edit todo.',
						show: true,
						type: 'ERROR'
					};

					addSnackbar(errorSnackbar);
					removeSnackbar(3000);
					break;
				}

				const editedTodo = { ...oldTodo, ...todoEditPayload } as ITodoItem;
				oldTodos.splice(todoEditPayload.index, 1, editedTodo);

				const editPayload: TEditBlockArgs = {
					data: {
						block: {
							todos: oldTodos,
							_id: blockId
						} as TBlockEdit,
						...generateIdsFromUrl()
					},
					prevState: {
						prevStateBlock: oldBlock
					}
				};

				if (!isUndoRedo) {
					handleUndoRedo({
						type: 'ADD',
						payload: {
							originalAction: onBlockEdit,
							oppositeAction: onBlockEdit,
							originalActionPayload: {
								editType,
								blockId,
								payload
							},
							oppositeActionPayload: {
								editType: 'EDIT_TODO',
								blockId,
								payload: {
									...todoEditPayload,
									...oldTodo
								} as TEditTodo
							}
						}
					});
				}

				// edit block dispatch action
				(store.dispatch as CustomDispatch)(editBlock(editPayload));
				break;
			}
			case 'REMOVE_TODO': {
				const { blocks: allBlocks } = store.getState();
				const oldBlock = allBlocks.data[blockId] as ITodo;
				const oldTodos = clone(oldBlock.todos as Array<ITodoItem>);
				const todoRemovePayload = payload as TRemoveTodo;

				const oldTodo = oldTodos[todoRemovePayload.index];
				if (oldTodo?._id !== todoRemovePayload.id) {
					const errorSnackbar: ISnackBar = {
						text: 'Failed to delete todo from list.',
						show: true,
						type: 'ERROR'
					};

					addSnackbar(errorSnackbar);
					removeSnackbar(3000);
					break;
				}

				oldTodos.splice(todoRemovePayload.index, 1);

				const editPayload: TEditBlockArgs = {
					data: {
						block: {
							todos: oldTodos,
							_id: blockId
						} as TBlockEdit,
						...generateIdsFromUrl()
					},
					prevState: {
						prevStateBlock: oldBlock
					}
				};

				if (!isUndoRedo) {
					handleUndoRedo({
						type: 'ADD',
						payload: {
							originalAction: onBlockEdit,
							oppositeAction: onBlockEdit,
							originalActionPayload: {
								editType,
								blockId,
								payload
							},
							oppositeActionPayload: {
								editType: 'INSERT_TODO',
								blockId,
								payload: oldTodo
							}
						}
					});
				}

				// edit block dispatch action
				(store.dispatch as CustomDispatch)(editBlock(editPayload));
				break;
			}
			default:
				break;
		}

		return undefined;
	};

	/**
	 * Function to create empty blocks and a new phase and add files
	 * to the window object
	 * @param files
	 */
	const createPhaseAndBlocks = async (
		files: TAddBlocksFnArgs[],
		newPhaseIndex?: number,
		newPhaseName?: string,
		isUndoRedo: boolean = false,
		snackbarMessage?: string
	) => {
		const gDriveFiles: TGDriveFile[] = [];
		const projectStages =
			(store.getState().projects.data[projectId] as IProject)?.children?.length || 0;
		const phaseId = new mongoose.Types.ObjectId().toString() as string;
		let blocksToAdd: IBlock[];
		let phase: IGroup;
		if (newPhaseName) {
			phase = generateOptimisticStage(phaseId, newPhaseName, {
				id: projectId,
				type: 'JOURNEY'
			});
		} else {
			phase = generateOptimisticStage(phaseId, `Untitled ${projectStages + 1}`, {
				id: projectId,
				type: 'JOURNEY'
			});
		}
		if (files.length > 1 || newPhaseName) {
			phase.isPhaseCreated = true;
		}
		if (files[0] && files[0]?.addType === 'FILE') {
			// Files are being added
			const blockIds = files.map((fileObject) => {
				const blockId =
					fileObject.blockId || (new mongoose.Types.ObjectId().toString() as string);
				gDriveFiles.push({
					id: blockId,
					file: fileObject.payload
				});
				return blockId;
			});
			phase.children = blockIds;
			blocksToAdd = blockIds.map((blockId: string, i: number) => {
				const blockFiles = {
					id: blockId,
					file: (files[i] as TAddBlocksFnArgs).payload
				};
				window.blockFiles = (
					window.blockFiles ? [...window.blockFiles, blockFiles] : [blockFiles]
				) as {
					id: string;
					file: File;
				}[];
				const optimisticBlock = generateOptimisticBlock(
					blockId,
					phaseId,
					projectId,
					`Untitled ${i + 1}`,
					'EMPTY'
				);
				// Here phase with phaseId will not be present in redux
				optimisticBlock.users = phase && phase.users ? phase.users : [];
				return optimisticBlock;
			});
		} else if (files[0] && files[0]?.addType === 'EMPTY') {
			const tempBlockData = files[0] as TAddBlocksFnArgs;
			const blockId = tempBlockData.blockId
				? tempBlockData.blockId
				: (new mongoose.Types.ObjectId().toString() as string);
			const emptyBlock = generateOptimisticBlock(
				blockId,
				phaseId,
				projectId,
				'UNDEFINED',
				'EMPTY'
			) as IBlock;
			// Here phase with phaseId will not be present in redux
			emptyBlock.users = phase && phase.users ? phase.users : [];
			blocksToAdd = [emptyBlock];
			phase.children = [blockId];
		} else if (files[0] && files[0]?.addType === 'TEXT') {
			const tempBlockData = files[0] as TAddBlocksFnArgs;
			const blockId = tempBlockData.blockId
				? tempBlockData.blockId
				: (new mongoose.Types.ObjectId().toString() as string);
			const textBlock = generateOptimisticBlock(
				blockId,
				phaseId,
				projectId,
				'UNDEFINED',
				'TEXT'
			) as IBlock;
			const text = (files[0].payload as string) || '';
			const updatedText =
				`{"root":{"children":[{"children":[{"detail":0,"format":0,` +
				`"mode":"normal","style":"","text":"${text}","type":"text","version":1}],` +
				`"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1}],"direction":` +
				`"ltr","format":"","indent":0,"type":"root","version":1}}`;
			(textBlock as IText).text = updatedText;
			// Here phase with phaseId will not be present in redux
			textBlock.users = phase && phase.users ? phase.users : [];
			blocksToAdd = [textBlock];
			phase.children = [blockId];
		} else if (files[0] && files[0]?.addType === 'TODO') {
			const tempBlockData = files[0] as TAddBlocksFnArgs;
			const blockId = tempBlockData.blockId
				? tempBlockData.blockId
				: (new mongoose.Types.ObjectId().toString() as string);
			const todoBlock = generateOptimisticBlock(
				blockId,
				phaseId,
				projectId,
				'UNDEFINED',
				'TODO'
			) as IBlock;
			(todoBlock as ITodo).todos = [];
			// Here phase with phaseId will not be present in redux
			todoBlock.users = phase && phase.users ? phase.users : [];
			blocksToAdd = [todoBlock];
			phase.children = [blockId];
		} else if (files[0] && files[0]?.addType === 'CANVAS') {
			const canvasBlockId =
				files[0].blockId || (new mongoose.Types.ObjectId().toString() as string);
			const canvasBlock = generateOptimisticBlock(
				canvasBlockId,
				phaseId,
				projectId,
				'UNDEFINED',
				'EXCALIDRAW_CANVAS'
			);
			createExcalidrawFile(projectId, canvasBlock?._id as string);
			(canvasBlock as IExaclidrawCanvas).link = getFileUrl(
				canvasBlock?._id as string,
				projectId
			);
			canvasBlock.users = phase && phase.users ? phase.users : [];
			blocksToAdd = [canvasBlock];
			phase.children = [canvasBlock._id as string];
		} else {
			// Show a loading snackbar
			const waitingSnackbarPayload: ISnackBar = {
				text: snackbarMessage || 'Embedding your link into a block. Please hold on...',
				show: true,
				type: 'LOADER'
			};
			addSnackbar(waitingSnackbarPayload);

			// Link is being added
			const link = files[0]?.payload as string;
			if (validator.isURL(link)) {
				const blockId =
					files[0]?.blockId || (new mongoose.Types.ObjectId().toString() as string);
				// Get the title of the link
				const { title } = (await getSiteMetaData(link)) as SiteDetailsT;
				const figmaFileName = await fetchFigmaFileTitle(link);
				const linkBlock = generateOptimisticBlock(
					blockId,
					phaseId,
					projectId,
					title && title.toLowerCase() !== 'figma' ? title : figmaFileName,
					'LINK'
				) as ILink;
				// Here phase with phaseId will not be present in redux
				linkBlock.users = phase && phase.users ? phase.users : [];
				linkBlock.link = link;

				const linkType = getFileLinkSubType(link, 'LINK') as ILinkSubtype;
				linkBlock.subType = linkType;

				// add google resource id if its a google file link
				if (
					linkType?.includes('GOOGLE') &&
					files[0]?.googleResourceId &&
					files[0].googleResourcePath
				) {
					linkBlock.googleResourceId = files[0].googleResourceId;
					linkBlock.googleResourcePath = files[0].googleResourcePath;
				}
				linkBlock.addedBy = linkBlock.createdBy as string;

				const fileName = linkBlock.name as string;
				await convertLinkToPng(
					link,
					isLinkEmbedsEnabled,
					fileName,
					(firebaseUrl: string) => {
						linkBlock.thumbnail = {
							isCustom: false,
							originalSrc: firebaseUrl,
							src: firebaseUrl
						};
					}
				);

				blocksToAdd = [linkBlock];
				phase.children = [blockId];
			} else {
				addSnackbar({
					show: true,
					type: 'ERROR',
					text: 'Link is invalid. Please try again.'
				});
				removeSnackbar(5000);
				return;
			}
		}
		const apiPayload: TBatchCreateGroupAndBlocks = {
			parent: { id: projectId, type: 'JOURNEY' },
			groups: [phase],
			blocks: blocksToAdd,
			startGroupIndex: newPhaseIndex || projectStages,
			children: [phase._id.toString()]
		};

		await dispatch(batchCreateGroupAndBlocks(apiPayload))
			.unwrap()
			.catch(() => {
				if (files[0] && files[0]?.addType === 'LINK') {
					const errorSnackbarPayload: ISnackBar = {
						text: 'Failed to create link block. Please try again',
						show: true,
						type: 'ERROR'
					};
					addSnackbar(errorSnackbarPayload);
				}
			})
			.finally(() => removeSnackbar(0));
		addBlocksToGDrive({
			data: [
				{
					id: phase._id.toString(),
					name: phase.name,
					children: gDriveFiles
				}
			],
			uploadCount: gDriveFiles.length
		});

		// Trigger 3D generation if its a 3D redirect
		if (trigger3DGenerationIf3DRedirect)
			trigger3DGenerationIf3DRedirect(projectId, phase, blocksToAdd[0]?._id as string);

		scrollToView(`[data-phaseid="${apiPayload.groups[0]?._id}"]` as string);

		const projectChildrenIds = (store.getState().projects.data[projectId] as IProject)
			?.children;
		let projectGroups = projectChildrenIds;

		if (projectGroups && projectGroups.length > 0) {
			projectGroups = projectGroups?.map(
				(stage) => store.getState().stages.data[stage as string]
			);
		}

		let updatedChildren = [...(projectGroups || [])];
		if (isNumber(newPhaseIndex)) {
			updatedChildren.splice(newPhaseIndex, 0, phase);
		} else {
			updatedChildren = [...updatedChildren, phase];
		}

		if (!isUndoRedo) {
			// Save history when user adds a single block
			window.Naya.handleUndoRedo({
				type: 'ADD',
				payload: {
					originalAction: undoDeleteBlocks,
					oppositeAction: onDeletePhase,
					originalActionPayload: [
						blocksToAdd,
						projectGroups,
						undefined,
						projectChildrenIds
					],
					oppositeActionPayload: [phaseId]
				}
			});
		}
	};

	/**
	 * Removes multiple blocks to a phase.
	 * @param {TAddBlocksFnArgs[]} blocksToRemove - Array of blocks to remove.
	 * @param {Object} options - Additional options.
	 */
	const onRemoveBlocks = async (
		{ blocksToRemove, options }: TOnRemoveBlocksParams,
		isUndoRedo: boolean = false
	) => {
		const projectChildrenIds = (store.getState().projects.data[projectId] as IProject)
			?.children;
		let blockIdsToDelete = blocksToRemove.map((each) => each._id) as string[];
		// if we have an initial block, reset that to empty block
		if (options.existingBlockId) {
			const reduxState = store.getState();
			const block = blocks[options.existingBlockId] as IBlock;
			const group = reduxState.stages.data[block.parentId] as IGroup;
			let blockData = generateOptimisticBlock(
				options.existingBlockId,
				group?._id as string,
				projectId,
				block.name,
				'EMPTY',
				block.users as TUserAndRole[],
				block.invitedEmails as TInvitedEmail[]
			);
			blockData = {
				...blockData,
				createdBy: block.createdBy,
				notes: block.notes
			};

			const resetBlockPayload: TResetBlockArgs = {
				data: {
					block: blockData
				},
				prevState: {
					prevStateBlock: block
				}
			};
			dispatch(resetBlock(resetBlockPayload));
			blockIdsToDelete = blockIdsToDelete.filter(
				(blockId) => blockId !== options.existingBlockId
			);
		}

		if (blockIdsToDelete.length > 0) {
			// Remove all the other blocks apart from the existing block
			onDeleteBlock(blockIdsToDelete, [options.phaseId] as string[]);
		}
		let projectGroups = projectChildrenIds;

		if (projectGroups && projectGroups.length > 0) {
			projectGroups = projectGroups?.map(
				(stage) => store.getState().stages.data[stage as string]
			);
		}
		if (!isUndoRedo) {
			// Save history on remove blocks
			handleUndoRedo({
				type: 'ADD',
				payload: {
					originalAction: onRemoveBlocks,
					oppositeAction: undoDeleteBlocks,
					originalActionPayload: { blocksToRemove, options },
					oppositeActionPayload: [
						blocksToRemove,
						projectGroups,
						undefined,
						projectChildrenIds
					]
				}
			});
		}
	};

	/**
	 * Adds multiple blocks to a phase. If phaseId is not provided, the blocks are added to the last phase.
	 * @param {TAddBlocksFnArgs[]} blocksToAdd - Array of blocks to add.
	 * @param {Object} options - Additional options.
	 *   @param {string} options.phaseId - ID of the phase (optional).
	 *   @param {number} options.newBlockIndex - Index of the new block (optional).
	 *   @param {number} options.newPhaseIndex - Index of the new phase (optional).
	 */
	const onAddBlocks = async (
		{ blocksToAdd, options, next }: TOnAddBlocksParams,
		isUndoRedo: boolean = false
	) => {
		const newBlocksCreated: string[] = [];
		// array to store all the optimistoc bloxks
		const allBlocks = [] as IBlock[];
		let showSnackBar = false;
		const { blockId } = generateIdsFromUrl();
		if (blockId || (blockId && mongoose.Types.ObjectId.isValid(blockId))) {
			const currentBlock = blocks[blockId as string] as IBlock;

			// show snackbar if-
			// 1. block type is not empty
			// 2. or if multiple files are dropped in expanded view
			if (currentBlock?.blockType !== 'EMPTY' || blocksToAdd.length > 1) {
				showSnackBar = true;
			}
		}
		if (options.phaseId) {
			const ids = uploadIds;
			const reduxState = store.getState();
			const stage = reduxState.stages.data[options.phaseId];
			const gDriveFiles: TGDriveFile[] = [];
			// looping through the blocks data to generate optimistic blocks
			for (let i = 0; i < blocksToAdd.length; i++) {
				const tempBlockData = blocksToAdd[i] as TAddBlocksFnArgs;
				let newId = tempBlockData.blockId
					? tempBlockData.blockId
					: (new mongoose.Types.ObjectId().toString() as string);

				if (i === 0 && options.existingBlockId) {
					newId = options.existingBlockId;
				}

				if (options.existingBlockId && i !== 0) {
					newBlocksCreated.push(newId);
				}
				if (!options.existingBlockId) newBlocksCreated.push(newId);

				// generating block based on the block type
				switch (tempBlockData.addType) {
					case 'FILE': {
						const fileBlockData = tempBlockData.payload as File;
						// generating the data structure to set to window for upload
						const tempFileToUpload = {
							id: newId,
							file: fileBlockData
						};
						// set the file to window so that it can be uploaded against the given block if
						window.blockFiles = (
							window.blockFiles
								? [...window.blockFiles, tempFileToUpload]
								: [tempFileToUpload]
						) as {
							id: string;
							file: File;
						}[];
						gDriveFiles.push({ id: newId, file: fileBlockData });

						if (i === 0 && options.existingBlockId) {
							const blockType = getBlockType(tempFileToUpload.file.name as string);
							blocksToConvert[options.existingBlockId] = blockType;
							dispatch(
								editBlockById({
									_id: options.existingBlockId,
									blockType
								})
							);
						} else {
							const blockType = 'EMPTY';
							const fileBlock = generateOptimisticBlock(
								newId,
								options.phaseId,
								projectId,
								'UNDEFINED',
								blockType
							);

							allBlocks.push(fileBlock);

							ids.push(newId);
						}
						break;
					}
					case 'LINK': {
						// Show a loading snackbar
						const waitingSnackbarPayload: ISnackBar = {
							text: 'Embedding your link into a block. Please hold on...',
							show: true,
							type: 'LOADER'
						};
						addSnackbar(waitingSnackbarPayload);

						const link = tempBlockData.payload as string;
						const userRedux: IUser = getUserFromRedux();
						// eslint-disable-next-line no-await-in-loop
						const { title } = (await getSiteMetaData(link)) as SiteDetailsT;
						if (validator.isURL(link)) {
							// eslint-disable-next-line no-await-in-loop
							const figmaFileName = await fetchFigmaFileTitle(link);
							const linkBlock = generateOptimisticBlock(
								newId,
								options.phaseId,
								projectId,
								title && title.toLowerCase() !== 'figma' ? title : figmaFileName,
								'LINK'
							);
							(linkBlock as ILink).link = link;

							const linkType = getFileLinkSubType(link, 'LINK') as ILinkSubtype;
							(linkBlock as ILink).subType = linkType;

							// add google resource id and path if its a google file link
							if (
								linkType?.includes('GOOGLE') &&
								tempBlockData.googleResourceId &&
								tempBlockData.googleResourcePath
							) {
								linkBlock.googleResourceId = tempBlockData.googleResourceId;
								linkBlock.googleResourceId = tempBlockData.googleResourcePath;
							}

							// if a microsoft link transform it to a url that can be opened
							if (link.includes('1drv') || link.includes('onedrive')) {
								// eslint-disable-next-line no-await-in-loop
								const finalLink = await getMSUrl(link);
								(linkBlock as ILink).link = finalLink as string;
							}
							(linkBlock as ILink).addedBy = userRedux._id as string;

							const fileName = linkBlock.name as string;
							// eslint-disable-next-line no-await-in-loop
							await convertLinkToPng(
								link,
								isLinkEmbedsEnabled,
								fileName,
								(firebaseUrl: string) => {
									linkBlock.thumbnail = {
										isCustom: false,
										originalSrc: firebaseUrl,
										src: firebaseUrl
									};
								}
							);

							allBlocks.push(linkBlock);
						} else {
							addSnackbar({
								show: true,
								type: 'ERROR',
								text: 'Link is invalid. Please try again.'
							});
							removeSnackbar(5000);
						}
						break;
					}
					case 'EMPTY': {
						const emptyBlock = generateOptimisticBlock(
							newId,
							options.phaseId,
							projectId,
							'UNDEFINED',
							'EMPTY'
						);
						allBlocks.push(emptyBlock);
						break;
					}
					case 'CANVAS': {
						const emptyBlock = generateOptimisticBlock(
							newId,
							options.phaseId,
							projectId,
							'UNDEFINED',
							'EXCALIDRAW_CANVAS'
						);
						createExcalidrawFile(projectId, allBlocks[i]?._id as string);
						(emptyBlock as IExaclidrawCanvas).link = getFileUrl(newId, projectId);
						allBlocks.push(emptyBlock);
						break;
					}
					case 'TEXT': {
						const textBlock = generateOptimisticBlock(
							newId,
							options.phaseId,
							projectId,
							'UNDEFINED',
							'TEXT'
						);
						(textBlock as IText).text = tempBlockData.payload as string;
						allBlocks.push(textBlock);
						break;
					}
					case 'TODO': {
						const todoBlock = generateOptimisticBlock(
							newId,
							options.phaseId,
							projectId,
							'UNDEFINED',
							'TODO'
						);
						(todoBlock as ITodo).todos = [];
						allBlocks.push(todoBlock);
						break;
					}
					default:
						break;
				}
			}

			// setting the state var to handle the uploads for following block ids
			setUploadIds(ids);
			let newBlockIndexExtracted = 0;
			if (options.newBlockIndex) newBlockIndexExtracted = options.newBlockIndex;
			else if (options.existingBlockId) {
				newBlockIndexExtracted =
					(stage?.children as string[]).findIndex(
						(id: string) => id === options.existingBlockId
					) + 1;
			}

			if (newBlocksCreated.length > 0) {
				setStartUploadForBlock(newBlocksCreated);
			}

			// generating payload for add multiple blocks action
			const apiPayload: TAddMultipleBlockArgs = {
				data: {
					blocks: allBlocks,
					stageId: options.phaseId,
					projectId,
					newBlockIndex: newBlockIndexExtracted || 0
				}
			};
			if (!options.existingBlockId || (options.existingBlockId && blocksToAdd.length > 1)) {
				await dispatch(addMultipleBlocks(apiPayload))
					.unwrap()
					.then(() => {
						updateLocalForageData('BLOCKS');
					})
					.finally(() => removeSnackbar(3000));
			}
			if (next) next();
			addBlocksToGDrive({
				data: [
					{
						id: stage?._id as string,
						name: stage?.name as string,
						children: gDriveFiles
					}
				],
				uploadCount: gDriveFiles.length
			});
			scrollToView(`[data-rbd-draggable-id="${apiPayload.data.blocks[0]?._id}"]` as string);
			if (showSnackBar)
				showAssetsSnackbar(
					apiPayload.data.blocks[0]?._id,
					apiPayload.data.blocks[0]?.parentId
				);

			const blockIds = allBlocks.map((block) => block._id) as string[];

			const blockIndex = options.newBlockIndex;
			const updatedChildren = [...(stage?.children || [])];

			if (isNumber(blockIndex)) {
				updatedChildren.splice(blockIndex, 0, ...blockIds);
			} else {
				updatedChildren.push(...blockIds);
			}

			const updatedStage = {
				...stage,
				children: updatedChildren
			};
			const projectChildrenIds = (store.getState().projects.data[projectId] as IProject)
				?.children;

			const parent = {
				id: updatedStage.parentId,
				type: updatedStage.parentId === projectId ? 'JOURNEY' : 'PHASE'
			};

			if (!isUndoRedo) {
				// Save history when adding blocks
				if (options.existingBlockId) {
					handleUndoRedo({
						type: 'ADD',
						payload: {
							originalAction: onAddBlocks,
							oppositeAction: onRemoveBlocks,
							originalActionPayload: { blocksToAdd, options, next },
							oppositeActionPayload: { blocksToRemove: allBlocks, options, next }
						}
					});
				} else {
					handleUndoRedo({
						type: 'ADD',
						payload: {
							originalAction: undoDeleteBlocks,
							oppositeAction: onRemoveBlocks,
							originalActionPayload: [
								allBlocks,
								[updatedStage],
								parent,
								projectChildrenIds
							],
							oppositeActionPayload: { blocksToRemove: allBlocks, options, next }
						}
					});
				}
			}
		} else {
			// add the blocks to a newly phase created at the end
			await createPhaseAndBlocks(
				blocksToAdd,
				options.newPhaseIndex,
				options.phaseName,
				false,
				options.snackbarMessage
			);
			if (next) next();
		}
	};

	/**
	 * Function to rename a block
	 * @param canvasId
	 * @param newName
	 */
	const onRenameBlock = (blockId: string, newName: string, isUndoRedo: boolean = false) => {
		const stage = findStageWithBlockId(blockId);
		const oldName = (blocks[blockId] as IBlock).name;
		// generating payload for edit block action
		const apiPayload: TEditBlockArgs = {
			data: {
				block: {
					_id: blockId,
					name: newName
				} as IBlock,
				projectId,
				stageId: stage?._id as string
			},
			prevState: {
				prevStateBlock: blocks[blockId] as IBlock
			}
		};
		if (!isUndoRedo) {
			// Save history when block is renmaed
			handleUndoRedo({
				type: 'ADD',
				payload: {
					originalAction: onRenameBlock,
					oppositeAction: onRenameBlock,
					originalActionPayload: [blockId, newName],
					oppositeActionPayload: [blockId, oldName]
				}
			});
		}
		// edit block dispatch
		dispatch(editBlock(apiPayload));
	};

	/**
	 * Function to populate the nodes within a block
	 * @param blockId
	 * @returns block with populated nodes
	 */
	const getBlockWithPopulatedNodes = (blockId: string, hasGuestAccess: boolean = false) => {
		const reduxState = store.getState();
		let block = blocks[blockId] as IBlock;
		const stage = reduxState.stages.data[block.parentId];
		// Get the default name for the block to show in expanded mode
		const blockIndex = stage?.children?.findIndex((id) => id === (block._id as string));
		if (typeof blockIndex === 'number')
			block = {
				...block,
				name: block?.name === 'UNDEFINED' ? `Untitled ${blockIndex + 1}` : block.name
			};
		if (hasGuestAccess) block = { ...block, hasGuestAccess };
		const allNodes = getNodesBasedOnBlockType(block);
		switch (block.blockType) {
			case 'CANVAS':
				block = {
					...block,
					nodes: allNodes
				} as ICanvas;
				break;
			case 'IMAGE':
				block = {
					...block,
					nodes: allNodes
				} as IImage;
				break;
			case 'THREE_D':
				block = {
					...block,
					nodes: allNodes,
					subType: getFileLinkSubType((block as I3D).fileName, 'THREE_D') as string
				} as I3D & { subType: string };
				break;
			case 'LINK':
				block = {
					...block,
					nodes: allNodes,
					// This will be used to show type as [GMAIL, FIGMA, MIRO] as such
					subType: getFileLinkSubType((block as ILink).link, 'LINK') as string
				} as ILink & { subType: string };
				break;
			case 'FILE':
				block = {
					...block,
					nodes: allNodes,
					subType: getFileLinkSubType((block as I3D).fileName, 'FILE') as string
				} as IFile & { subType: string };
				break;
			default:
				break;
		}
		return block;
	};

	// add temporary notes when in multiselect via redux
	const addTempNotes = (data: { groups: string[]; blocks: string[]; color: string }) => {
		if (data.blocks) {
			if (data.blocks) store.dispatch(addTempNotesOnBlocks(data.blocks));
			if (data.groups) store.dispatch(addTempNotesOnGroups(data.groups));
			store.dispatch(
				addTempNotesRedux({ noteIds: [...data.blocks, ...data.groups], color: data.color })
			);
		}
	};

	/**
	 * Function to handle folder upload.
	 * Create groups, nested groups, and blocks.
	 * @param {TreeEntry[]} folderData Folder tree
	 * @param parent on which the drop happened.
	 * @param dropIndex drop index
	 * @param existingBlockId id of a block
	 */
	const onFolderOrLinkDrop = async (
		folderData: TreeEntry[],
		parent: TGroupParent,
		dropIndex: number,
		existingBlockId?: string
	) => {
		const newChildrenOfParent: string[] = [];

		const filesToUpload: {
			id: string;
			file: File;
		}[] = [];

		let firstFileOrLink: TreeEntry | null = null;

		const newBlockIds: string[] = [];
		const expandedBlock = canvasId ? getBlockFromReduxById(canvasId) : undefined;
		const isDefaultCanvas = expandedBlock && expandedBlock.blockType === 'EXCALIDRAW_CANVAS';

		// When a folder or file or link is dropped on an empty block.
		if (existingBlockId) {
			let files = folderData;
			// if item is a folder
			if (folderData[0]?.type === 'directory') {
				files = folderData[0].children;
			}
			// Find the first file in root folder.
			const fileOrLinkIndex = files.findIndex((child) =>
				['FILE', 'LINK'].includes(child.type)
			);
			if (isNumber(fileOrLinkIndex) && files[fileOrLinkIndex]) {
				const foundFileOrLink = files[fileOrLinkIndex];
				if (foundFileOrLink) {
					firstFileOrLink = foundFileOrLink;
					// remove it from folderData, as we use existing block.
					files.splice(fileOrLinkIndex, 1);
				}
			}
		}

		// Creates the block and groups optimistically and also links the parent and children.
		const {
			blocks: newBlocks,
			groups: newGroups,
			gdrive
		} = formatFolderTreeData(folderData, parent);

		const newChildren: IBlock[] | IGroup[] = newGroups.length ? newGroups : newBlocks;

		// Get the ids of direct children
		newChildren.forEach((child) => {
			if (child.parentId.toString() === parent.id.toString()) {
				newChildrenOfParent.push(child._id.toString());
			}
		});

		// Prepare upload data and get the link info.
		if (!isDefaultCanvas) {
			for (let i = 0; i < newBlocks.length; i++) {
				const block = newBlocks[i];
				if (block) {
					if (block.type === 'FILE' && block.payload) {
						const uploadData = {
							id: block._id.toString(),
							file: block.payload as File
						};

						filesToUpload.push(uploadData);
						newBlockIds.push(block._id.toString());
					} else if (block.type === 'LINK' && block.payload) {
						// Show a loading snackbar
						const waitingSnackbarPayload: ISnackBar = {
							text: 'Embedding your link into a block. Please hold on...',
							show: true,
							type: 'LOADER'
						};
						addSnackbar(waitingSnackbarPayload);

						const link = block.payload as string;
						const userRedux: IUser = getUserFromRedux();
						// eslint-disable-next-line no-await-in-loop
						const { title } = (await getSiteMetaData(link)) as SiteDetailsT;
						if (validator.isURL(link)) {
							// eslint-disable-next-line no-await-in-loop
							const figmaFileName = await fetchFigmaFileTitle(link);
							block.name =
								title && title.toLowerCase() !== 'figma' ? title : figmaFileName;
							(block as ILink).link = link;

							const linkType = getFileLinkSubType(link, 'LINK') as ILinkSubtype;
							(block as ILink).subType = linkType;
							block.blockType = 'LINK';

							// if a microsoft link transform it to a url that can be opened
							if (link.includes('1drv') || link.includes('onedrive')) {
								// eslint-disable-next-line no-await-in-loop
								const finalLink = await getMSUrl(link);
								(block as ILink).link = finalLink as string;
							}
							(block as ILink).addedBy = userRedux._id as string;

							const fileName = block.name as string;
							// eslint-disable-next-line no-await-in-loop
							await convertLinkToPng(
								link,
								isLinkEmbedsEnabled,
								fileName,
								(firebaseUrl: string) => {
									block.thumbnail = {
										isCustom: false,
										originalSrc: firebaseUrl,
										src: firebaseUrl
									};
								}
							);
						} else {
							addSnackbar({
								show: true,
								type: 'ERROR',
								text: 'Link is invalid. Please try again.'
							});
							removeSnackbar(5000);
						}
					}
					delete block.type;
					delete block.payload;
				}
			}
		}

		// Push the first file to filesToUpload array to upload the file.
		if (existingBlockId && firstFileOrLink?.type === 'FILE') {
			filesToUpload.push({ id: existingBlockId, file: firstFileOrLink.payload });
		}

		// set the file to be upload to window and state
		window.blockFiles = window.blockFiles
			? [...window.blockFiles, ...filesToUpload]
			: filesToUpload;

		// This is mostly be used to trigger upload when files are uploaded from expanded block.
		setStartUploadForBlock(newBlockIds);

		// If dropped on existing empty block.
		if (existingBlockId) {
			// Edit the empty block's blockType
			if (firstFileOrLink && firstFileOrLink.type === 'FILE') {
				const blockType = getBlockType(firstFileOrLink.payload.name);
				blocksToConvert[existingBlockId] = blockType;
				dispatch(
					editBlockById({
						_id: existingBlockId,
						blockType
					})
				);
			} else if (firstFileOrLink && firstFileOrLink?.type === 'LINK') {
				onBlockEdit({
					editType: 'ADD_LINK',
					blockId: existingBlockId,
					payload: {
						blockType: 'LINK',
						link: firstFileOrLink.payload
					} as TLinkEdit
				});
			}
		}

		// Dispatch batch create api
		if ((newBlocks.length >= 1 || newGroups.length >= 1) && !isDefaultCanvas) {
			await dispatch(
				batchCreateGroupAndBlocks({
					blocks: newBlocks,
					groups: newGroups,
					startGroupIndex: dropIndex,
					children: newChildrenOfParent,
					parent
				})
			).then(() => {
				if (!canvasId) removeSnackbar(0);
			});
		}
		// When multiple files are dropped in expanded block, show "go there" snackbar.
		if (
			canvasId &&
			(!existingBlockId || (existingBlockId && newBlocks.length >= 1)) &&
			!isDefaultCanvas &&
			folderData.length > 0
		) {
			setTimeout(() => {
				showAssetsSnackbar(newBlocks[0]?._id, newBlocks[0]?.parentId);
			}, 0);
		}

		// upload assets to google drive
		if (firstFileOrLink && firstFileOrLink.type === 'FILE' && existingBlockId) {
			gdrive.data.push({
				name: (findStageWithBlockId(existingBlockId) as IGroup).name,
				id: (findStageWithBlockId(existingBlockId) as IGroup)._id.toString(),
				children: [
					{
						id: existingBlockId,
						file: firstFileOrLink.payload,
						fileName: firstFileOrLink.name
					}
				]
			});
			gdrive.uploadCount++;
		}
		// upload should start after blocks and groups are created
		addBlocksToGDrive(gdrive);
	};

	/**
	 * Update Multiple blocks
	 * @param updatedBocks - array of objects where each object has blockId and things to update
	 * @param isUndoRedo - boolean
	 */
	const updateMultipleBlocksWithUndoRedo = (
		blocksUpdate: Array<Partial<IBlock>>,
		isUndoRedo: boolean = false
	) => {
		const { prevBlocks } = onBlocksEdit(blocksUpdate);

		// save history on update block
		if (!isUndoRedo) {
			handleUndoRedo({
				type: 'ADD',
				payload: {
					originalAction: updateMultipleBlocksWithUndoRedo,
					oppositeAction: updateMultipleBlocksWithUndoRedo,
					originalActionPayload: [blocksUpdate],
					oppositeActionPayload: [prevBlocks]
				}
			});
		}
	};

	/**
	 * Update Multiple groups
	 * @param updatedBocks - array of objects where each object has groupId and things to update
	 * @param isUndoRedo - boolean
	 */
	const updateMultipleGroupsWithUndoRedo = (
		groupsUpdate: Array<Partial<IGroup>>,
		isUndoRedo: boolean = false
	) => {
		const { prevGroups } = onGroupsEdit(groupsUpdate);

		if (!isUndoRedo) {
			// save history on update group
			handleUndoRedo({
				type: 'ADD',
				payload: {
					originalAction: updateMultipleGroupsWithUndoRedo,
					oppositeAction: updateMultipleGroupsWithUndoRedo,
					originalActionPayload: [groupsUpdate],
					oppositeActionPayload: [prevGroups]
				}
			});
		}
	};

	return {
		onBlockEdit,
		onAddBlocks,
		onRenameBlock,
		getBlockWithPopulatedNodes,
		startUploadOnPaste,
		getBlockNameById,
		getMiroBoard,
		undoDeleteBlocks,
		addBlocksToGDrive,
		addTempNotes,
		removeTempNotes,
		onFolderOrLinkDrop,
		updateBlockWithThumbnail,
		updateMultipleBlocksWithUndoRedo,
		updateMultipleGroupsWithUndoRedo
	};
};

export default useBlockActions;
