import './Appearance.scss';
import {
  FC, ReactElement, useState, useEffect,
} from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { SketchPicker } from 'react-color';
import { colors } from 'src/components/canvas/toolbar/toolbar.config';
import { AppearanceProps } from './Appearance.types';
import appearanceImage from '../../../../../assets/icons/Appearance.svg';
import wireframeIcon from '../../../../../assets/icons/wireframe.svg';
import addIcon from '../../../../../assets/icons/toolbar/add_icon.svg';
import eyeDropIcon from '../../../../../assets/icons/toolbar/eyedrop.svg';

const Appearance : FC<AppearanceProps> = ({ app, setSelectedOption, selectedOption }): ReactElement => {
  const appearanceOptions = {
    fill: 'FILL',
    wireframe: 'WIREFRAME',
  };
  const [activeAppearance, setActiveAppearance] = useState<string>(appearanceOptions.fill);
  const [colorOptions, setColorOptions] = useState<Array<string>>(colors);
  const [showPicker, setShowPicker] = useState(false);
  const [colorPicker, setColorPicker] = useState(false);
  const [colorPicked, setColorPicked] = useState('');
  const [dottedFrame, setDottedFrame] = useState(false);
  const [eyeDropperActive, setEyeDropperActive] = useState(false);
  const [hexValue, setHexValue] = useState('');

  useEffect(() => {
    const nodeData = app.selectedNodes[0]?.nodeData;
    if (nodeData?.model?.appearance) {
      const { fill, isWireframe } = nodeData.model.appearance;
      const option = isWireframe ? appearanceOptions.wireframe : appearanceOptions.fill;
      setActiveAppearance(option);
      setColorPicked(fill as string);
      if (fill) {
        const temp : Array<string> = colors;
        if (!temp.includes(fill)) {
          temp.push(fill);
          setColorOptions(temp);
        }
      }
    }
  }, []);

  const handleColorChange = async (color:any) => {
    setDottedFrame(false);
    setHexValue(color.hex);
    const tempColors: Array<string> = colorOptions;
    if (!tempColors.includes(color.hex)) {
      tempColors[tempColors.length - 1] = color.hex;
      setColorOptions(tempColors);
    }
    setColorPicked(color.hex);
    setActiveAppearance(appearanceOptions.fill);

    const allNodes = app.selectedNodes;
    // looping over all the selected nodes to edit the nodeData in BaseNode
    for (let i = 0; i < allNodes.length; i++) {
      app.selectedNodes[i]?.edit('FILL', color.hex, false);
    }
    // saving all the edited nodes data to db
    app.saveAllNodes([])
  };

  const handleHexInput = async (e : any) => {
    setHexValue(e.target.value);
    if (e.target.value.length <= 6 && e.target.value.length >= 3) {
      setColorPicked(`#${e.target.value}`);
      const colorArray : Array<string> = colorOptions;
      if (!colorArray.includes(`#${e.target.value}`)) {
        colorArray[colorArray.length - 1] = `#${e.target.value}`;
        setColorOptions(colorArray);
      }
      setActiveAppearance(appearanceOptions.fill);

      const allNodes = app.selectedNodes;
      // looping over all the selected nodes to edit the nodeData in BaseNode
      for (let i = 0; i < allNodes.length; i++) {
        app.selectedNodes[i]?.edit('FILL', `#${e.target.value}`, false);
      }
      // saving all the edited nodes data to db
      app.saveAllNodes([])
    }
  };

  const toggleActiveAppearance = () => {
    const newApperance = activeAppearance === appearanceOptions.fill ? appearanceOptions.wireframe : appearanceOptions.fill;
    setActiveAppearance(newApperance);

    const allNodes = app.selectedNodes;
    // looping over all the selected nodes to edit the nodeData in BaseNode
    for (let i = 0; i < allNodes.length; i++) {
      app.selectedNodes[i]?.edit(newApperance, 'toggle', false);
    }
    // saving all the edited nodes data to db
    app.saveAllNodes([])
  };

  const RGBToHex = (r: any, g: any, b: any) => {
    let red = parseInt(r, 10).toString(16);
    let green = parseInt(g, 10).toString(16);
    let blue = parseInt(b, 10).toString(16);

    if (red.length === 1) red = `0${red}`;
    if (green.length === 1) green = `0${green}`;
    if (blue.length === 1) blue = `0${blue}`;

    return `#${red}${green}${blue}`;
  };

  const removeDuplicateColors = () => {
    const arr: string[] = colorOptions.filter((item, index, inputArray) => inputArray.indexOf(item) === index);
    setColorOptions(arr);
  };

  const toggleAppearanceColor = () => {
    const tempColors: string[] = colorOptions;
    if (!colorPicker) {
      app.isColourPickerOpen = true;
      setColorPicked('#FF0000');
      setHexValue('#FF0000');
      let c : number = 0;
      for (let i = 0; i < tempColors.length; i++) {
        if (tempColors[i] === '#FF0000') {
          c++;
        }
      }
      if (c <= 1) {
        tempColors.push('#FF0000');
        setColorOptions(tempColors);

        const allNodes = app.selectedNodes;
        // looping over all the selected nodes to edit the nodeData in BaseNode
        for (let i = 0; i < allNodes.length; i++) {
          app.selectedNodes[i]?.edit('FILL', '#FF0000', false);
        }
        // saving all the edited nodes data to db
        app.saveAllNodes([])
      }
      setColorPicker(!colorPicker);
    } else {
      removeDuplicateColors();
      app.isColourPickerOpen = false;
      setColorPicker(!colorPicker);
    }
  };

  const useEyeDropper = () => {
    const arr: string[] = colorOptions.filter((item, index, inputArray) => inputArray.indexOf(item) === index);
    setEyeDropperActive(true);
    const eyeDropper = new (window as any).EyeDropper();
    let hexDecimalColor : string;
    eyeDropper.open().then((result:any) => {
      if (result.sRGBHex[0] !== '#') {
        const RGBValues = result.sRGBHex.slice(4, result.sRGBHex.length - 1).split(',');
        hexDecimalColor = RGBToHex(RGBValues[0], RGBValues[1], RGBValues[2]).toUpperCase();
      } else hexDecimalColor = result.sRGBHex;
      setHexValue(hexDecimalColor);
      const tempColors:Array<string> = arr;
      if (!tempColors.includes(hexDecimalColor)) {
        tempColors.push(hexDecimalColor);
        setColorOptions(tempColors);
      }
      setColorPicked(hexDecimalColor);
      setEyeDropperActive(false);  

      const allNodes = app.selectedNodes;
      // looping over all the selected nodes to edit the nodeData in BaseNode
      for (let i = 0; i < allNodes.length; i++) {
        app.selectedNodes[i]?.edit('FILL', hexDecimalColor, false);
      }
      // saving all the edited nodes data to db
      app.saveAllNodes([])

    }).catch((e:any) => {
      console.log(e);
    });
  };

  const getAppearanceClassName = (color: string, index: number) => {
    if (!dottedFrame) {
      const newArr = colorOptions.filter((colour) => colour === color);
      if (colorPicked === '#FF0000' && color === '#FF0000') {
        if (newArr.length > 1) {
          if (index === (colorOptions.length - 1)) return 'colorVariantContainer active-color';
        } else if (newArr.length === 1 && color === '#FF0000') {
          return 'colorVariantContainer active-color';
        }
      }
      if (color !== '#FF0000' && color === colorPicked) {
        return 'colorVariantContainer active-color';
      }
      return 'colorVariantContainer';
    }
    return '';
  };

  const renderColorPicker = () => (
    <div role="presentation">
      <div className="colorDivider" />
      <div className="colorPanel">
        {
              colorOptions?.map((color, index) => (
                <div
                  role="presentation"
                  // eslint-disable-next-line react/no-array-index-key
                  key={color + index}
                  className={getAppearanceClassName(color, index)}
                  onClick={async () => {
                    setHexValue(color);
                    setDottedFrame(false);
                    setColorPicked(color);

                    const allNodes = app.selectedNodes;
                    // looping over all the selected nodes to edit the nodeData in BaseNode
                    for (let i = 0; i < allNodes.length; i++) {
                      app.selectedNodes[i]?.edit('FILL', color, false);
                    }
                    // saving all the edited nodes data to db
                    app.saveAllNodes([])
                  }}
                >
                  <span className="colorVariant" style={{ background: color, border: '1px solid #E2E2E2' }} />
                </div>
              ))
            }
        <div
          className={eyeDropperActive ? 'color-container active-eyedrop' : 'color-container'}
          onClick={useEyeDropper}
          role="presentation"
        >
          <img className="color" src={eyeDropIcon} alt="eye-drop-icon" style={{ borderRadius: 0 }} />
        </div>
        <div
          className="color-container"
          style={{ background: colorPicker ? '#EDEDED' : '', borderRadius: '4px' }}
        >
          <img
            src={addIcon}
            alt=""
            onClick={() => {
              toggleAppearanceColor();
            }}
            role="presentation"
          />
        </div>
      </div>
      {
            colorPicker
              && (
              <div className="chrome-picker-wrapper" style={{ top: '0rem' }}>
                <SketchPicker
                  color={colorPicked}
                  onChangeComplete={handleColorChange}
                />
                <div className="hex-input-container">
                  <div className={`eye-drop-container ${eyeDropperActive ? 'active' : ''}`}>
                    <img
                      src={eyeDropIcon}
                      alt="eye-drop-icon"
                      onClick={useEyeDropper}
                      style={{ width: '15px', height: '15px' }}
                      role="presentation"
                    />
                  </div>
                  <div className="input-container">
                    <span>
                      #
                    </span>
                    <input
                      type="text"
                      value={`${hexValue[0] === '#' ? hexValue.slice(1) : hexValue}`}
                      onChange={handleHexInput}
                      placeholder="Enter hex code"
                    />
                  </div>
                </div>
              </div>
              )
        }
    </div>
  );

  const renderAppearanceIcon = () => {
    if (activeAppearance === appearanceOptions.fill && colorPicked !== '') {
      return (
        <div
          className="selectedContainer"
          role="presentation"
          onClick={() => setShowPicker(!showPicker)}
          style={{ backgroundColor: showPicker ? '#EDEDED' : 'transparent', borderRadius: '4px' }}
        >
          <span className="selectedColor" style={{ background: colorPicked }} />
        </div>
      );
    }
    return (
      <img
        role="presentation"
        className={showPicker ? 'appearanceImage activeAppearanceIcon' : 'appearanceImage'}
        src={activeAppearance === appearanceOptions.fill ? appearanceImage : wireframeIcon}
        alt="aIcon"
        onClick={() => setShowPicker(!showPicker)}
      />
    );
  };

  return (
    <>
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 1000, hide: 0 }}
        overlay={(
          <Tooltip
            id="button-tooltip-2"
            style={{
              fontSize: '12px',
              borderRadius: '2px',
              lineHeight: '16px',
              marginTop: '8px',
            }}
          >
            Appearance
          </Tooltip>
        )}
      >
        <div
          role="presentation"
          onClick={() => {
            removeDuplicateColors();
            if (selectedOption === 'APPEARANCE') setSelectedOption('');
            else setSelectedOption('APPEARANCE');
          }}
        >
          { renderAppearanceIcon() }
        </div>
      </OverlayTrigger>
      {
            selectedOption === 'APPEARANCE' && (
            <div className="colorPicker">
              <div className="styleRows">
                <img
                  role="presentation"
                  className={activeAppearance === appearanceOptions.fill ? 'activeAppearanceIcon mb-4' : ''}
                  src={appearanceImage}
                  alt=""
                  onClick={toggleActiveAppearance}
                  style={{ marginRight: 4 }}
                />
                <img
                  className={activeAppearance === appearanceOptions.wireframe ? 'activeAppearanceIcon' : ''}
                  src={wireframeIcon}
                  role="presentation"
                  onClick={toggleActiveAppearance}
                  alt=""
                />
              </div>
                { activeAppearance === appearanceOptions.fill && renderColorPicker() }
            </div>
            )
}

    </>
  );
};

export default Appearance;
