import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'src/redux/reducers/root.types';
import { AppDispatch } from 'src';
import { loadBlockById } from '../reduxActions/block';
import { generateIdsFromUrl } from '../reduxActions/util';

/**
 * Custom hook to access all the blocks and state of block with provided id
 * @param id - block id to be accessed
 * @returns redux state of block with provided id and all redux blocks
 */
const useBlocks = (
	id?: string,
	cb?: (err: { status: number; message: string } | string) => void
) => {
	const dispatch = useDispatch<AppDispatch>();
	const { blockId: urlBlockId } = generateIdsFromUrl();
	const blockId = id || (urlBlockId as string);

	const { reduxBlocks, block, isLoading, error } = useSelector((state: ReduxState) => ({
		block: state.blocks.data[blockId],
		isLoading: state.blocks.loading[blockId],
		error: state.blocks.error[blockId],
		reduxBlocks: state.blocks.data
	}));

	/**
	 * Re-runs when hook is mounted
	 */
	useEffect(() => {
		// If block does not exist send a dispatch to load it into redux
		if (!block && blockId) {
			dispatch(loadBlockById({ id: blockId }))
				.unwrap()
				.catch((err) => {
					console.log('catching errr here');
					if (cb) cb(err);
				});
		}
	}, []);

	return {
		reduxBlocks,
		block,
		isLoading,
		error
	};
};

export default useBlocks;
