import { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import './NumberInput.scss';
import AlertIcon from '../../../assets/Alert.svg';

export type NumberProps = {
  onChange: (value: number) => void,
  value: number,
  placeholder?: string,
  name?: string,
  id?:string,
  icon?: boolean,
  formSubmit?: boolean,
  style?: object
  numberSuffix?: string,
};

export default function NumberInput({
  onChange,
  value,
  placeholder,
  name,
  id,
  icon,
  numberSuffix,
  formSubmit,
  style = {},
}: NumberProps) {
  const [focus, setFocus] = useState(false);
  function handleFocus() {
    const Input = document.getElementById(`${id}`) as HTMLInputElement;
    Input.focus();
  }
  const inputStyle = focus ? '1px var(--theme-color-with-opacity-2) solid' : '1px #d1d1d1 solid';
  return (
    <>
      <div className="number-input_container">
        <div
          className="muted-input-border"
          style={{ border: !value && formSubmit && focus === false ? '1px #e34300 solid' : inputStyle, ...style }}
        >
          {icon ? (
            <InputGroup>
              <InputGroup.Text
                onClick={() => handleFocus}
                style={{
                  width: '32px', display: 'flex', justifyContent: 'center', cursor: 'text',
                }}
              >
                $
              </InputGroup.Text>
              <Form.Control
                required
                title=""
                min="0"
                id={id || ''}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                placeholder={placeholder || '0'}
                name={name}
                type="number"
                onChange={(e) => onChange(parseInt(e.currentTarget.value, 10))}
                value={value || ''}
                onWheel={(e: any) => e.target.blur()}
              />
            </InputGroup>
          )
            : (
              <Form.Control
                required
                title=""
                min="0"
                id={id || ''}
                placeholder={placeholder || '0'}
                name={name}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                type="number"
                onChange={(e) => onChange(parseFloat(e.currentTarget.value))}
                value={value || ''}
                onWheel={(e: any) => e.target.blur()}
              />
            )}
          <div
            className={numberSuffix ? 'inches-container' : ''}
            onClick={handleFocus}
            onKeyDown={handleFocus}
            role="none"
          >
            {numberSuffix && (
              <span className="numberSuffix">
                {numberSuffix}
              </span>
            )}
          </div>
        </div>
      </div>
      {formSubmit && value <= 0 && focus === false && (
      <div style={{ marginBottom: '8px', width: '120%' }}>
        <img src={AlertIcon} alt="" />
        <span className="FieldWarnings">
          Enter
          {' '}
          {' '}
          {name}
          {' '}
          to continue
        </span>
      </div>
      )}
    </>
  );
}

NumberInput.defaultProps = {
  placeholder: '',
  name: '',
  id: '',
  icon: false,
  formSubmit: false,
  style: {},
  numberSuffix: '',
};
