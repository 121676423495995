import { useEffect, useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import { store } from 'src';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TLDFlags } from '../CollaborationTool.types';

/**
 * Type for Download Journey Poster Journey Progress
 */
type TGeneratePosterProgress = {
	status: 'CLONING' | 'GENERATING' | 'GENERATED' | 'COMPLETED' | 'CLONING_FAILURE' | 'FAILED';
};

/**
 * Hook to handle the Download Journey Poster Deliverable Action
 * @returns Method to trigger download journey poster, and poster generation progress update
 */
const useDownloadJourneyPoster = (journeyName: string) => {
	const [posterGenerationProgress, setPosterGenerationProgress] =
		useState<TGeneratePosterProgress>({} as TGeneratePosterProgress);
	// Ref to handle the cancel event
	const isPosterGenerationCancelled = useRef<boolean>(false);

	const { isCollapseBlocksEnabled } = useFlags<TLDFlags>();
	/**
	 * Function to clean up / optimize the thumbnails for poster generation
	 * @param element journey element
	 * @param type Image quality type
	 */
	const cleanUpThumbnails = async (element: HTMLDivElement, type: 'BEST' | 'ECO') => {
		const blockWrappers = element.querySelectorAll<HTMLDivElement>('#block-wrapper');
		blockWrappers.forEach((blockWrapper) => {
			const thumbnailWrap = blockWrapper.querySelector<HTMLImageElement>('.thumbnail-wrap');

			if (thumbnailWrap && thumbnailWrap?.src && thumbnailWrap?.src.includes('cloudinary')) {
				const cloudinaryOptimizedUrl = thumbnailWrap.getAttribute('src') as string;
				const oldQuality = type === 'BEST' ? 'q_auto:eco' : 'q_auto:best';
				const newQuality = type === 'BEST' ? 'q_auto:best' : 'q_auto:eco';

				const oldWidth = type === 'BEST' ? 'upload/w_500/' : 'upload/';
				const newWidth = type === 'BEST' ? 'upload/' : 'upload/w_500/';

				const cloudinaryCleanedImageUrl = cloudinaryOptimizedUrl
					.replace(oldQuality, newQuality)
					.replace(oldWidth, newWidth);

				thumbnailWrap.src = cloudinaryCleanedImageUrl;
			}
		});
	};

	/**
	 * Function to verify if tailwind css has been applied to cloned journey
	 * @param element Cloned Journey element
	 * @returns boolean
	 */
	const verifyTailwindCSSOnClonedJourney = (element: HTMLElement) => {
		const newPhaseBtnContainer = element.querySelector<HTMLDivElement>(
			'[data-phaseid="NEW-PHASE"]'
		);
		const emptyJourneyWrapper = element.querySelector<HTMLDivElement>('.empty-journey-wrapper');

		if (newPhaseBtnContainer) {
			const parentDiv = newPhaseBtnContainer.parentElement;
			if (!parentDiv) return false;

			const btnContainer = parentDiv.closest('.tw-relative');
			if (!btnContainer) return false;

			const clonedBtn = btnContainer.cloneNode() as HTMLDivElement;
			if (newPhaseBtnContainer.parentElement)
				newPhaseBtnContainer.parentElement.appendChild(clonedBtn);
			clonedBtn.classList.add('tw-absolute', 'tw-hidden', 'tw-top-0', 'tw-left-0');

			const headerClientRect = clonedBtn.getBoundingClientRect();
			const isAtBeginning = headerClientRect.x === 0 && headerClientRect.y === 0;
			const isHidden = headerClientRect.width === 0 && headerClientRect.height === 0;

			return isAtBeginning && isHidden;
		}
		if (emptyJourneyWrapper) return true;

		return false;
	};

	/**
	 * Function to update the Journey Container DOM for Snapshot
	 * @param document Window Document
	 * @param clonedJourney Cloned Journey Container
	 */
	const updateJourneyDomForPoster = (document: Document, clonedJourney: HTMLElement) => {
		if (!document || !clonedJourney) {
			throw new Error('Journey Container not found.');
		}
		// Remove the padding (for navbar) before taking snapshot
		clonedJourney.style.paddingTop = '80px';
		// Set height to auto so phases expand when block position is set to static
		clonedJourney.style.height = 'auto';
		// Set height to auto so phases expand when block position is set to static
		clonedJourney.style.width = 'min-content';

		// Iterate over all the blocks
		const blocksListsArray = document.querySelectorAll<HTMLDivElement>('.inner-list');
		if (blocksListsArray) {
			blocksListsArray.forEach((innerList) => {
				// Update the blocks position, scale to expand the collapsed phases
				innerList.style.position = 'static';
				innerList.style.scale = '1';
				const blockId = innerList.getAttribute('data-blockid') as string;
				const block = store.getState().blocks.data[blockId];
				// --- if the block is collapsed, expand it
				const collapsedBlock = innerList.querySelector<HTMLDivElement>('.collapsed-block');
				if (collapsedBlock && isCollapseBlocksEnabled) {
					collapsedBlock?.classList.remove('collapsed-block');
					collapsedBlock?.classList.add('expanded-block');

					const collapseIcon = innerList.querySelector<HTMLDivElement>(
						'.collapse-block-icon-wrapper'
					);
					collapseIcon?.remove();

					const collapseBlockThumbnail = innerList.querySelector<HTMLDivElement>(
						'.render-collapse-block-thumbnail'
					);
					if (collapseBlockThumbnail) {
						collapseBlockThumbnail.style.display = 'block';
						collapseBlockThumbnail.style.position = 'absolute';
						collapseBlockThumbnail.style.top = '0';
						collapseBlockThumbnail.style.height = 'inherit';
						collapseBlockThumbnail.style.width = 'inherit';
						collapseBlockThumbnail.style.backgroundSize = 'cover';
						collapseBlockThumbnail.style.backgroundPosition = 'center';
						collapseBlockThumbnail.style.backgroundRepeat = 'no-repeat';
					}
				}
				// ---
				const isOrphan = innerList.getAttribute('data-isorphan') === 'true';
				const blockWrapper = innerList.querySelector<HTMLDivElement>('#block-wrapper');
				// If block is orphan add margin top of 8em to it, to make it drop a bit
				if (blockWrapper && isOrphan) blockWrapper.style.marginTop = '8em';

				// check if block is orphan
				const blockWrap = innerList.querySelector<HTMLDivElement>('.block-wrap');
				// Since html2canvas ignores outline
				// Add border to the block so it would be visible in posters
				if (blockWrap) blockWrap.style.border = `${isOrphan ? '2px' : '1px'} solid #d5d5d5`;

				const blockTitle = innerList.querySelector<HTMLDivElement>('.block-title-wrap');
				if (blockTitle) {
					// Make the editable title visible on poster
					blockTitle.style.display = 'flex';
					blockTitle.style.zIndex = '2';
					blockTitle.style.border = '1px solid #d5d5d5';
					blockTitle.style.borderRadius = '8em';

					const innerSpan = blockTitle.querySelector('span');
					const highlighterWrapper =
						blockTitle.querySelector<HTMLDivElement>('.highlighter-wrapper');

					// Remove truncates so entire title is visible in posters
					if (innerSpan) {
						innerSpan.classList.remove('tw-truncate');
						innerSpan.classList.remove('title-clamp');
					}
					if (highlighterWrapper) {
						highlighterWrapper.classList.remove('tw-truncate');
						highlighterWrapper.style.textAlign = 'start';
					}

					const emptyBlockWrap =
						innerList.querySelector<HTMLDivElement>('.empty-block-wrap');
					if (emptyBlockWrap) {
						const blockTitleThumbnail = emptyBlockWrap.querySelector('p');

						if (blockTitleThumbnail) {
							// If the block is empty, then expand the title displayed as thumbnail of empty block
							blockTitleThumbnail.classList.remove('tw-truncate');
						}
					}
				}

				// Select the thumbnail wrap from block
				const thumbnailWrap = innerList.querySelector<HTMLImageElement>('.thumbnail-wrap');
				innerList.querySelector('.MuiSkeleton-root')?.remove();
				if (thumbnailWrap && (!block?.isCollapsed || !isCollapseBlocksEnabled)) {
					// Make the block thumbnail visible
					thumbnailWrap.style.display = 'block';
					if (thumbnailWrap.src) {
						let isThumbnailLarge: undefined | 'WIDTH' | 'HEIGHT';

						// If image is wider in width as compared to block width
						if (!isThumbnailLarge) {
							// As object-fit applied to thumbnail won't work in html2canvas,
							// So manually making the image cover the width
							thumbnailWrap.style.width = 'auto';
							thumbnailWrap.style.height = '100%';
							if (thumbnailWrap.width > 288) {
								isThumbnailLarge = 'WIDTH';
								const left = (thumbnailWrap.width - 288) / 2;
								thumbnailWrap.style.position = 'absolute';
								thumbnailWrap.style.left = `-${left}px`;
								if (thumbnailWrap.parentElement)
									thumbnailWrap.parentElement.style.overflow = 'hidden';
							}
						}

						// If image is longer in height as compared to block height
						if (!isThumbnailLarge) {
							// As object-fit applied to thumbnail won't work in html2canvas,
							// So manually making the image cover the height
							thumbnailWrap.style.width = '100%';
							thumbnailWrap.style.height = 'auto';
							if (thumbnailWrap.height > 230) {
								isThumbnailLarge = 'HEIGHT';
								const top = (thumbnailWrap.height - 230) / 2;
								thumbnailWrap.style.position = 'absolute';
								thumbnailWrap.style.top = `-${top}px`;
								if (thumbnailWrap.parentElement)
									thumbnailWrap.parentElement.style.overflow = 'hidden';
							}
						}

						// If image is smaller/same size as block just take the thumbnail as it is
						if (!isThumbnailLarge) {
							thumbnailWrap.style.width = '100%';
							thumbnailWrap.style.height = '100%';
						}
					}
				}
			});
		}

		// Last add block button with extra padding
		const emptyAddBlockButton = document.querySelector<HTMLDivElement>(
			'[data-phaseid="NEW-PHASE"]'
		);
		if (emptyAddBlockButton) {
			// Remove the element from DOM to maintain 80px distance
			emptyAddBlockButton.style.display = 'none';
		}
		// hide all notes
		const allNotes = document.querySelectorAll<HTMLDivElement>('.note-tooltip-content');
		allNotes.forEach((note: HTMLDivElement) => {
			note.style.visibility = 'hidden';
		});
	};

	/**
	 * Function to cancel the download poster request
	 */
	const abortController = () => {
		isPosterGenerationCancelled.current = true;
	};

	/**
	 * Function to capture the Journey Container element from DOM
	 * and send it to html2canvas for Snapshot
	 * @returns Function to cancel the html2canvas request
	 */
	const downloadJourneyPoster = () => {
		try {
			if (!window.navigator.onLine) {
				throw new Error('Please check the internet connection and try again.');
			}

			setPosterGenerationProgress({
				status: 'CLONING'
			});

			const journeyContainer = document.querySelector<HTMLDivElement>('.journey-container');

			if (!journeyContainer) throw new Error('Journey Container not found.');
			// Update the thumbnails to best quality before cloning the journey
			cleanUpThumbnails(journeyContainer, 'BEST');
			const journeyColor = journeyContainer.getAttribute('data-journey-color');
			if (journeyColor) {
				journeyContainer.style.backgroundColor = journeyColor;
			}

			/**
			 * Capture the screenshot of the journey container, clone it and keep the clone hidden
			 * make the changes on clone to prevent changes in original element
			 */
			html2canvas(journeyContainer, {
				proxy: 'https://proxy.naya.studio/',
				// Provide callback to handle what happens to cloned journey container
				onclone: (document: Document, element: HTMLElement) => {
					if (!isPosterGenerationCancelled.current) {
						const isStyled = verifyTailwindCSSOnClonedJourney(element);

						if (isStyled) {
							setPosterGenerationProgress({
								status: 'GENERATING'
							});

							updateJourneyDomForPoster(document, element);
						} else throw new Error('Failed to style poster');
					}
				},
				imageTimeout: 45000
			})
				.then((canvas) => {
					if (!isPosterGenerationCancelled.current) {
						// Revert the thumbnails back to economical quality after poster is generated
						cleanUpThumbnails(journeyContainer, 'ECO');

						setPosterGenerationProgress({
							status: 'GENERATED'
						});

						// Convert canvas to data URL
						const dataUrl = canvas.toDataURL();

						// Create an anchor tag and add the dataUrl to its href
						const anchorElement = document.createElement('a');
						anchorElement.href = dataUrl;
						anchorElement.download = `${journeyName}.png`;

						// Append the anchor tag to the parent document
						window.document.body.appendChild(anchorElement);

						// Auto click the tag to start the download
						anchorElement.click();

						// Remove the tag from the document
						window.document.body.removeChild(anchorElement);

						setPosterGenerationProgress({
							status: 'COMPLETED'
						});

						if (journeyContainer) {
							journeyContainer.style.backgroundColor = '';
						}
					}
				})
				.catch((error) => {
					setPosterGenerationProgress({
						status: String(error).includes('Failed to style poster')
							? 'CLONING_FAILURE'
							: 'FAILED'
					});
					throw new Error(`Error capturing snapshot : ${error}`);
				});
		} catch (error) {
			console.error(error);
		}
	};

	// Runs on mount
	useEffect(() => {
		// Function to handle offline/internet disconnection
		const handleOffline = () => {
			isPosterGenerationCancelled.current = true;
			setPosterGenerationProgress({
				status: 'FAILED'
			});
		};
		// Function to handle beforeunload
		const handleBeforeUnload = (event: BeforeUnloadEvent) => {
			// Display a warning message to the user
			const message = 'A download is in progress. Are you sure you want to leave the page?';
			event.returnValue = message; // Standard for most browsers
			return message; // For some older browsers
		};
		window.addEventListener('offline', handleOffline);
		window.addEventListener('beforeunload', handleBeforeUnload);
		// On unmount stop the process
		return () => {
			window.removeEventListener('offline', handleOffline);
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, []);

	return { downloadJourneyPoster, abortController, posterGenerationProgress };
};

export default useDownloadJourneyPoster;
