import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { ReactComponent as NayaLogo } from 'src/assets/brand/Logo-filled.svg';
import { useHistory, useParams } from 'react-router';
import { store } from 'src';
import { IUser } from '@naya_studio/types';
import useNayaExpress from 'src/redux/hooks/useNayaExpress';
import { AuthComponentProps, Views } from './AuthComponent.types';
import './AuthComponent.scss';
import Login from './views/login/Login';
import { ScheduleDemo } from './views/scheduleDemo/ScheduleDemo';
import { AuthContext } from '../auth/AuthProvider';
import NayaLoader from '../loader/Loader';
import SigninLinkSent from './views/signinLinkSent/SigninLinkSent';
import UnapprovedUser from './views/unapprovedUser/UnapprovedUser';
import UserForm from './views/userForm/userForm';
import checkIfUserFormDetailsAreFilled from './views/authComponentUtils';

// Context for managing the state of the authentication component
export const AuthComponentContext = createContext<{
	setAuthView: (val: Views) => void;
	setError: (val: string) => void;
	setProviders: (val: string[]) => void;
	authView: Views;
	error: string;
	redirectTo: string | undefined;
	startAuthTimeOut: () => void;
	stopAuthTimeOut: () => void;
	close: () => void;
}>({
	setAuthView: () => {},
	setError: () => {},
	setProviders: () => {},
	authView: 'LOGIN',
	error: '',
	redirectTo: '',
	startAuthTimeOut: () => {},
	stopAuthTimeOut: () => {},
	close: () => {}
});

// Main component that handles user authentication
export const AuthComponent: React.FC<AuthComponentProps> = ({ close, view, redirectTo }) => {
	// State variables for authentication component
	const [authView, setAuthView] = useState<Views>('LOADING');
	const [error, setError] = useState<string>('');
	const [providers, setProviders] = useState<Array<string>>([]);
	const history = useHistory();
	const auth = useContext(AuthContext);
	const { setStatus, status, completedAuthCheck } = auth;

	const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
	const params: any = useParams();

	const { handle3DRedirect, checkIfNayaExpressRedirect, handleI2RRedirect } = useNayaExpress();

	// Handle authentication status changes
	useEffect(() => {
		// Set authentication view to 'UNAPPROVED' if the status is 'UNAPPROVED'
		if (status === 'UNAPPROVED') {
			const showUserForm = window.localStorage.getItem('showUserForm');
			if (showUserForm) {
				setAuthView('USER_QUESTIONS_FORM');
				window.localStorage.setItem('redirect-from-userform', 'UNAPPROVED');
			} else {
				setAuthView('UNAPPROVED');
			}
		}
		// Check if the status is 'LOADING'.
		if (status === 'LOADING') {
			// Set the authentication view to 'LOADING'.
			setAuthView('LOADING');
		} else if (status === 'AUTHENTICATED') {
			const user = store.getState().user.data as IUser;
			if (user._id && !checkIfUserFormDetailsAreFilled(user)) {
				setAuthView('USER_QUESTIONS_FORM');
				window.localStorage.setItem('registeredUnappovedUserId', user._id as string);
			} else {
				// Check if there's a 3D redirect stored in local storage
				if (checkIfNayaExpressRedirect('3D_redirect')) {
					// Call function to handle the 3D redirect
					handle3DRedirect();
					return;
				}
				// Check if there's a 3D redirect stored in local storage
				if (checkIfNayaExpressRedirect('i2r')) {
					// Call function to handle the 3D redirect
					handleI2RRedirect();
					return;
				}

				// Decode the redirect URL from parameters, if available, or use the provided redirectTo value.
				let redirectURL = decodeURIComponent(params.redirectTo)
					? decodeURIComponent(params.redirectTo)
					: redirectTo;

				// If there's no valid redirect URL, set a default redirect URL to '/account'.
				if (!redirectURL || redirectURL === 'undefined') {
					redirectURL = '/studio';
				}
				// Push the redirect URL to the history, effectively redirecting the user.
				history.push(redirectURL);
			}
		}
	}, [status]);

	// Use effect to show signed out only when the auth check is completed
	useEffect(() => {
		if (error && completedAuthCheck) {
			setStatus('SIGNED_OUT');
			setAuthView('LOGIN');
		}
	}, [completedAuthCheck, error]);

	// Handle authentication and redirection
	useEffect(() => {
		// Call redirect result check only if the user is not trying to login using an email link
		if (completedAuthCheck && ['LOGIN-PWD', 'SCHEDULE_DEMO'].includes(view)) {
			setStatus('SIGNED_OUT');
			setAuthView(view);
		}
	}, [view, completedAuthCheck]);

	// Start a timeout for authentication
	const startAuthTimeOut = () => {
		timeoutRef.current = setTimeout(() => {
			setAuthView('AUTH_TIME_OUT');
		}, 45000);
	};

	// Stop the authentication timeout if it's active
	const stopAuthTimeOut = () => {
		if (timeoutRef.current) clearTimeout(timeoutRef.current);
	};

	// Render the view data based on the authentication view
	const getViewData = () => {
		switch (authView) {
			case 'APPROVAL_PENDING':
			case 'UNAPPROVED':
				return <UnapprovedUser />;

			case 'USER_ALREADY_EXISTS':
			case 'REGISTRATION_LINK_SENT':
			case 'SIGNIN_LINK_SENT':
				return <SigninLinkSent />;

			case 'LOGIN-PWD':
				return <Login redirectTo={redirectTo} />;

			case 'SCHEDULE_DEMO':
			case 'SCHEDULE_DEMO_DONE':
				return <ScheduleDemo />;

			case 'USER_NOT_FOUND':
				return (
					<div className="user-not-found-container">
						<p>
							Hi. It looks like you haven&apos;t signed up for our platform yet.
							Please sign up using the button below.
						</p>
						<button
							className="primary-btn tw-text-sm tw-w-full"
							type="button"
							onClick={() => {
								setError('');
								history.push('/register');
							}}
						>
							Sign up
						</button>
						<div className="other-options tw-mt-2 tw-flex tw-flex-col tw-items-center tw-justify-center tw-text-xs">
							<span>
								Wrong email?&nbsp;
								<button
									type="button"
									data-type="underline"
									onClick={() => {
										setAuthView('LOGIN');
									}}
								>
									Re-enter your email address.
								</button>
							</span>
						</div>
					</div>
				);
			case 'LINK_ACCOUNTS':
				return (
					<div id="link-accounts">
						<p>
							{`
              We were not able to verify your email using this provider, but it seems like you have logged in using 
              ${providers[0]} before. Please try logging in using the ${providers[0]} option`}
						</p>
						<button
							type="button"
							className="primary-btn tw-text-sm"
							onClick={() => {
								setAuthView('LOGIN');
							}}
						>
							Go back
						</button>
					</div>
				);

			case 'AUTH_TIME_OUT':
				return (
					<div id="link-accounts">
						<p>
							Seems like it&apos;s taking too long to authenticate. Please try again.
						</p>
						<button
							type="button"
							className="primary-btn tw-text-sm"
							onClick={() => {
								setAuthView('LOGIN');
							}}
						>
							Go back
						</button>
					</div>
				);

			default:
				return <div />;
		}
	};

	// Get header text based on the authentication view
	const getHeaderText = () => {
		switch (authView) {
			case 'LOGIN':
			case 'LOGIN-PWD':
				return 'Log in';
			case 'REGISTRATION_LINK_SENT':
				return 'You’re Set!';
			case 'USER_ALREADY_EXISTS':
				return 'Already existing account!';
			case 'SIGNIN_LINK_SENT':
				return 'Sign in link sent!';
			case 'APPROVAL_PENDING':
			case 'UNAPPROVED':
				return 'Request submitted!';
			default:
				return <div />;
		}
	};

	const renderViewWithHeaderAndLogo = () =>
		authView === 'USER_QUESTIONS_FORM' ? (
			<UserForm />
		) : (
			<div
				className={
					authView === 'SCHEDULE_DEMO' ? 'auth-container schedule-demo' : 'auth-container'
				}
			>
				{!['SCHEDULE_DEMO', 'SCHEDULE_DEMO_DONE'].includes(authView) && (
					<>
						<div className="logo-container">
							<NayaLogo />
						</div>
						<h4 className="view-header">{getHeaderText()}</h4>
					</>
				)}
				{getViewData()}
				{['LOGIN', 'REGISTER', 'SCHEDULE_DEMO'].includes(authView) && (
					<p
						className={
							authView === 'SCHEDULE_DEMO'
								? 'tos-container schedule-demo'
								: 'tos-container'
						}
					>
						By signing in you agree to our&nbsp;
						<a
							href={
								`https://docs.google.com/document/u/1/d/e/` +
								`2PACX-1vRp1F6ekOvO8C0KNirQYE_xkT73hA_rK36UzgOD0daHtwqMsyHBFEWLnFOm-wJR6Q/pub`
							}
							target="_blank"
							rel="noopener noreferrer"
						>
							terms and conditions
						</a>
						, privacy policy (
						<a
							href={
								`https://docs.google.com/document/u/1/d/e/` +
								`2PACX-1vSXCyp-WC5_J1oYGAcQOwtiVhXH6eDhgzoVB1c3hngn4ZOagmnyCG3s9PlHGvZOog/pub`
							}
							target="_blank"
							rel="noopener noreferrer"
						>
							US &amp; Intl
						</a>
						&nbsp; /&nbsp;
						<a
							href={
								`https://docs.google.com/document/u/1/d/e/` +
								`2PACX-1vQnvDVhSWzeuISScfL8diO3V-yVMm9SBYh2nLjsYjOxLKjqE2Yqdb0ey0F0U6h0wQ/pub`
							}
							target="_blank"
							rel="noopener noreferrer"
						>
							California
						</a>
						), and&nbsp;
						<a
							href={
								`https://docs.google.com/document/u/1/d/e/` +
								`2PACX-1vRnc492iv5X5JTp3mPq6KotJktefEH8AvcXKa5RVfsvEPkK7zRj1uYg9mKmLKMOWA/pub`
							}
							target="_blank"
							rel="noopener noreferrer"
						>
							&nbsp; NDA
						</a>
					</p>
				)}
			</div>
		);

	return (
		<AuthComponentContext.Provider
			value={{
				authView,
				setAuthView,
				error,
				setError,
				redirectTo,
				setProviders,
				stopAuthTimeOut,
				startAuthTimeOut,
				close
			}}
		>
			<div id="auth-component">
				{!['LOADING', 'LOGIN', 'REGISTER'].includes(authView) ? (
					renderViewWithHeaderAndLogo()
				) : (
					<div className="auth-container-loader">
						<div className="animated-logo-container">
							<NayaLoader />
						</div>
					</div>
				)}
			</div>
		</AuthComponentContext.Provider>
	);
};
