import { IGroup } from '@naya_studio/types';
import { store } from 'src';

export const getStageName = (stageType: string) => {
	switch (stageType) {
		case 'DESIGN_BRIEF':
			return 'Design Brief';
		case 'MOOD_BOARD':
			return 'Mood Board';
		case 'SKETCHES':
			return 'Sketch';
		case 'WHITE_MODEL':
			return 'White Model';
		case 'CMF_RENDERING':
			return 'CMF Render';
		case 'SHOP_DRAWING':
			return 'Basic Shop';
		case 'TECHNICAL_DESIGN':
			return 'Technical Design';
		case 'BUILD':
			return 'Build';
		case 'FABRICATION_ESTIMATE':
			return 'Fabrication Estimate';
		case 'MATERIAL_SAMPLES':
			return 'Material Samples';
		case 'DELIVER':
			return 'Deliver';
		default:
			return '';
	}
};

export const getRoleName = (role: string) => {
	switch (role) {
		case 'OWNER':
			return 'Owner';
		case 'EDITOR':
			return 'Editor';
		case 'VIEWER':
			return 'Viewer';
		case 'DESIGNER':
			return 'Designer';
		case 'FABRICATOR':
			return 'Fabricator';
		case 'ADMIN':
			return 'Admin';
		case 'MAKER':
			return 'Maker';
		case 'COMMENTER':
			return 'Commenter';
		default:
			return '';
	}
};

/**
 * Function to get project from redux
 * @param projectId - ID of the project
 * @returns project
 */
export const getGroupById = (stageId: string) => {
	const { stages } = store.getState();
	const stage = stages.data[stageId] as IGroup;
	return stage;
};
