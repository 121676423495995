import { IBlock, IFeedback, INode, IGroup } from '@naya_studio/types';
import { store } from 'src';
import App from 'src/components/canvas/app/App';
import { CustomDispatch } from 'src/redux/actions/types';
import {
	addActionToRedo,
	addBatchRedoActions,
	addBatchUndoActions,
	undoAction
} from 'src/redux/actions/undoRedoActions';
import { EActionType, TActionItem } from 'src/redux/reducers/undoRedo/undoActionHistory.types';
import _ from 'lodash';
import { generateIdsFromUrl, getFeedbackBasedOnBlockType } from 'src/redux/reduxActions/util';
import { editComment } from 'src/redux/reduxActions/feedbacks';
import {
	TEditBlockArgs,
	TEditCommentThunkArg,
	TEditNodesArgs,
	TEditStageThunkArg,
	TUndoRedoStageArgs,
	TUndoredoBlockArgs
} from 'src/types/argTypes';
import { editBlock, undoRedoBlock } from 'src/redux/reduxActions/block';
import { editNodes } from 'src/redux/reduxActions/node';
import { editStage, undoRedoStage } from 'src/redux/reduxActions/stage';

export const onUndo = (app: App) => {
	const reduxState = store.getState();
	const actionsToUndo = reduxState.undoActions.pop();
	const allNodes = reduxState.nodes.data;
	if (actionsToUndo) {
		// firstUndoAction will be considered where we don't have batch operations like Page, Phase and Navigate Undo
		const firstUndoAction = actionsToUndo[0] as TActionItem;
		const changeType = firstUndoAction?.changeType;
		app.hasCanvasUpdatedForThumbnail = true;

		switch (changeType) {
			case 'NEW_NODE':
			case 'EDIT': {
				const redoActionData: Array<{
					prevNodeData: INode | IFeedback;
					newNodeData: INode | IFeedback;
				}> = [];
				const editPayload: INode[] = [];

				[...actionsToUndo].forEach((action: TActionItem) => {
					redoActionData.push({
						prevNodeData: action.newNodeData,
						newNodeData: action.prevNodeData
					});
					const version = (
						allNodes[(action.prevNodeData as INode)._id as string] as INode
					)?.version;
					const temp = action.prevNodeData as INode;
					if (version !== undefined) {
						temp.version = version + 1;
					} else temp.version = 0;

					editPayload.push(temp);
				});

				addBatchRedoActions(changeType as EActionType, redoActionData);

				// generating payload for edit nodes action
				const apiPayload: TEditNodesArgs = {
					data: {
						nodes: editPayload,
						...generateIdsFromUrl()
					},
					prevState: {
						prevBlocks: reduxState.nodes.data
					}
				};

				// dispatch edit nodes
				(store.dispatch as CustomDispatch)(editNodes(apiPayload));

				app.removeTransformer();
				app.removeAllSelectedNodes();
				app.toggleEditMenu(false);
				break;
			}
			case 'PAGE': {
				const { canvasId, stageId, isVisible, position } =
					firstUndoAction.prevNodeData as any;
				const { projectId } = generateIdsFromUrl();
				// generating payload for undo redo block
				const apiPayload: TUndoredoBlockArgs = {
					data: {
						block: { _id: canvasId as string, isVisible } as IBlock,
						stageId,
						projectId,
						position
					},
					prevState: {
						prevStateBlock: reduxState.blocks.data[canvasId] as IBlock
					}
				};
				// undo redo dispatch
				(store.dispatch as CustomDispatch)(undoRedoBlock(apiPayload));
				addActionToRedo(
					'PAGE' as EActionType,
					firstUndoAction.newNodeData,
					firstUndoAction.prevNodeData,
					false
				);
				break;
			}

			case 'PHASE': {
				const { stageId, position, isVisible } = firstUndoAction.prevNodeData as any;
				const { projectId } = generateIdsFromUrl();
				const apiPayload: TUndoRedoStageArgs = {
					data: {
						stage: { _id: stageId, isVisible },
						projectId,
						position
					},
					prevState: {
						prevStage: reduxState.stages.data[stageId] as IGroup
					},
					next: () => {
						addActionToRedo(
							'PHASE' as EActionType,
							firstUndoAction.newNodeData,
							firstUndoAction.prevNodeData,
							false
						);
					}
				};
				(store.dispatch as CustomDispatch)(undoRedoStage(apiPayload));
				break;
			}

			case 'RENAME_PAGE': {
				const renamePayload = firstUndoAction.prevNodeData;
				// generating payload for edit block action
				const apiPayload: TEditBlockArgs = {
					data: {
						block: renamePayload,
						...generateIdsFromUrl()
					},
					prevState: {
						prevStateBlock: reduxState.blocks.data[
							renamePayload._id as string
						] as IBlock
					}
				};
				// edit block dispatch
				(store.dispatch as CustomDispatch)(editBlock(apiPayload));
				addActionToRedo(
					'RENAME_PAGE' as EActionType,
					firstUndoAction.newNodeData,
					firstUndoAction.prevNodeData,
					false
				);
				break;
			}

			case 'RENAME_PHASE': {
				const { stageId, name } = firstUndoAction.prevNodeData as any;
				const { projectId } = generateIdsFromUrl();
				const { stages } = reduxState;
				const apipayload: TEditStageThunkArg = {
					payload: {
						id: stageId,
						update: { name },
						projectId
					},
					prevState: {
						prevStage: stages.data[stageId] as IGroup
					}
				};
				(store.dispatch as CustomDispatch)(editStage(apipayload));
				addActionToRedo(
					'RENAME_PHASE' as EActionType,
					firstUndoAction.newNodeData,
					firstUndoAction.prevNodeData,
					false
				);
				break;
			}

			case 'NAVIGATION': {
				const { projectId, stageId, canvasId } = firstUndoAction.prevNodeData as any;
				app.redirect(`/project/${projectId}/${stageId}/${canvasId}`);
				addActionToRedo(
					'NAVIGATION' as EActionType,
					firstUndoAction.newNodeData,
					firstUndoAction.prevNodeData,
					false
				);
				break;
			}

			case 'RESOLVE_COMMENT': {
				const { projectId, stageId, blockId } = generateIdsFromUrl();
				const oldComments = getFeedbackBasedOnBlockType(blockId);

				const apiPayload: TEditCommentThunkArg = {
					payload: {
						projectId,
						stageId,
						feedback: firstUndoAction.prevNodeData as IFeedback,
						blockId
					},
					prevState: {
						oldComments
					}
				};

				(store.dispatch as CustomDispatch)(editComment(apiPayload));
				addActionToRedo(
					'RESOLVE_COMMENT' as EActionType,
					firstUndoAction.newNodeData,
					firstUndoAction.prevNodeData,
					false
				);
				break;
			}

			default:
				break;
		}
	}
};

export const onRedo = (app: App) => {
	const reduxState = store.getState();
	const actionsToRedo = reduxState.redoActions.pop();
	const allNodes = reduxState.nodes.data;
	if (actionsToRedo) {
		// firstRedoAction will be considered where we don't have batch operations like Page, Phase and Navigate Redo
		const firstRedoAction = actionsToRedo[0] as TActionItem;
		const changeType = actionsToRedo[0]?.changeType;
		app.hasCanvasUpdatedForThumbnail = true;

		switch (changeType) {
			case 'EDIT':
			case 'NEW_NODE': {
				const undoActionData: Array<{
					prevNodeData: INode | IFeedback;
					newNodeData: INode | IFeedback;
				}> = [];
				const editPayload: INode[] = [];

				[...actionsToRedo].forEach((action: TActionItem) => {
					undoActionData.push({
						prevNodeData: _.cloneDeep(action.newNodeData),
						newNodeData: _.cloneDeep(action.prevNodeData)
					});

					const version = (
						allNodes[(action.prevNodeData as INode)._id as string] as INode
					)?.version;
					const temp = action.prevNodeData as INode;
					if (version !== undefined) {
						temp.version = version + 1;
					} else temp.version = 0;

					editPayload.push(temp);
				});

				addBatchUndoActions(changeType as EActionType, undoActionData);
				// generating payload for edit nodes action
				const apiPayload: TEditNodesArgs = {
					data: {
						nodes: editPayload,
						...generateIdsFromUrl()
					},
					prevState: {
						prevBlocks: reduxState.nodes.data
					}
				};
				// dispatch edit nodes
				(store.dispatch as CustomDispatch)(editNodes(apiPayload));

				app.removeTransformer();
				app.removeAllSelectedNodes();
				app.toggleEditMenu(false);
				break;
			}

			case 'PAGE': {
				const { canvasId, stageId, isVisible, position } =
					firstRedoAction.prevNodeData as any; // need to update stage id if stage is deleted
				const { projectId } = generateIdsFromUrl();
				// generating payload for undo redo block
				const apiPayload: TUndoredoBlockArgs = {
					data: {
						block: { _id: canvasId as string, isVisible } as IBlock,
						stageId,
						projectId,
						position
					},
					prevState: {
						prevStateBlock: reduxState.blocks.data[canvasId] as IBlock
					}
				};
				// undo redo dispatch
				(store.dispatch as CustomDispatch)(undoRedoBlock(apiPayload));
				undoAction(
					'PAGE' as EActionType,
					firstRedoAction.newNodeData,
					firstRedoAction.prevNodeData,
					false
				);
				break;
			}

			case 'RENAME_PAGE': {
				const renamePagePayload = firstRedoAction.prevNodeData;
				// generating payload for edit block action
				const apiPayload: TEditBlockArgs = {
					data: {
						block: renamePagePayload,
						...generateIdsFromUrl()
					},
					prevState: {
						prevStateBlock: reduxState.blocks.data[
							renamePagePayload._id as string
						] as IBlock
					}
				};
				// edit block dispatch
				(store.dispatch as CustomDispatch)(editBlock(apiPayload));
				undoAction(
					'RENAME_PAGE' as EActionType,
					firstRedoAction.newNodeData,
					firstRedoAction.prevNodeData,
					false
				);
				break;
			}

			case 'RENAME_PHASE': {
				const { stageId, name } = firstRedoAction.prevNodeData as any;
				const { projectId } = generateIdsFromUrl();
				const { stages } = reduxState;
				const apipayload: TEditStageThunkArg = {
					payload: {
						id: stageId,
						update: { name },
						projectId
					},
					prevState: {
						prevStage: stages.data[stageId] as IGroup
					}
				};
				(store.dispatch as CustomDispatch)(editStage(apipayload));
				undoAction(
					'RENAME_PHASE' as EActionType,
					firstRedoAction.newNodeData,
					firstRedoAction.prevNodeData,
					false
				);
				break;
			}

			case 'PHASE': {
				const { stageId, position, isVisible } = firstRedoAction.prevNodeData as any;
				const { projectId } = generateIdsFromUrl();

				const apiPayload: TUndoRedoStageArgs = {
					data: {
						stage: { _id: stageId, isVisible },
						projectId,
						position
					},
					prevState: {
						prevStage: reduxState.stages.data[stageId] as IGroup
					},
					next: () => {
						undoAction(
							'PHASE' as EActionType,
							firstRedoAction.newNodeData,
							firstRedoAction.prevNodeData,
							false
						);
					}
				};
				(store.dispatch as CustomDispatch)(undoRedoStage(apiPayload));
				break;
			}

			case 'NAVIGATION': {
				const { projectId, stageId, canvasId } = firstRedoAction.prevNodeData as any;
				app.redirect(`/project/${projectId}/${stageId}/${canvasId}`);
				undoAction(
					'NAVIGATION' as EActionType,
					firstRedoAction.newNodeData,
					firstRedoAction.prevNodeData,
					false
				);
				break;
			}

			case 'RESOLVE_COMMENT': {
				const { projectId, stageId, blockId } = generateIdsFromUrl();
				const oldComments = getFeedbackBasedOnBlockType(blockId);

				const apiPayload: TEditCommentThunkArg = {
					payload: {
						projectId,
						stageId,
						feedback: firstRedoAction.prevNodeData as IFeedback,
						blockId
					},
					prevState: {
						oldComments
					}
				};

				(store.dispatch as CustomDispatch)(editComment(apiPayload));
				undoAction(
					'RESOLVE_COMMENT' as EActionType,
					firstRedoAction.newNodeData,
					firstRedoAction.prevNodeData,
					false
				);

				break;
			}

			default:
				break;
		}
	}
};
