import { EFeedbackType, IFeedback, TPoint, TUserAndRole } from '@naya_studio/types';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { Popover, FeedbackBubble } from '@naya_studio/radix-ui';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'src/redux/reducers/root.types';
import { checkUserAccessLevel } from 'src/util/accessLevel/accessLevelActions';
import useFeedbackActions from 'src/util/feedback/useFeedbackActions';
import { useParams } from 'react-router';
import { resetNewCommentData } from 'src/redux/features/newComment';
import useUser from 'src/redux/hooks/user';
import { PollWrapper } from './poll/PollWrapper';
import { ActionItem } from './actionItem/ActionItem';
import { QNAWrapper } from './QNA/QNAWrapper';
import { NewFeedback } from './feedback/NewFeedback';
import { FeedbackTile } from './feedback/FeedbackTile';
import FeedbackTrigger from './FeedbackTrigger';
import {
	PathParamsType,
	TPaperImageRef,
	TPaperUnsupportedRef,
	TPaperView
} from '../collaborationTool/CollaborationTool.types';
import { getFeedbackPostionInViewer, setFeedbackPostionInViewer } from './util';

/** props for FBS wrapper component */
export type FBSProps = {
	feedbackData: IFeedback;
	type: 'NEW' | 'EXISTING'; // to identify if its a new feedback
	index: number; // Index of the feedback
	view: MutableRefObject<TPaperImageRef | TPaperUnsupportedRef | null>;
	// used to trigger rerender
	renderFBS: boolean;
};

// FBS wrapper component to render new or existing feedback based on type
export const FBSWrapper = ({ type, feedbackData, index, view, renderFBS }: FBSProps) => {
	// to pass the feedback text between feedback types when user switches between feedback types
	const [feedbackText, setFeedbackText] = useState('');
	const [feedbackType, setFeedbackType] = useState<EFeedbackType | undefined>(); // to hold the feedback type chossen by the user
	const [position, setPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
	// True if bubble is being dragged
	const bubbleDragging = useRef<boolean>(false);
	// Used to close the popover when bubble is dragged and show it back on drag end
	const [isPopoverOpened, setIsPopoverOpened] = useState(false);
	// For controlling popover content
	const [showPopover, setShowPopover] = useState(false);
	const newComment = useSelector((state) => (state as ReduxState).newComment);
	const [pos, setPos] = useState<TPoint>();
	const openComment = useSelector((state) => (state as ReduxState).openComment, shallowEqual);

	// extarcting functions from feedback actions hook
	const { sendEditFeedback } = useFeedbackActions();

	// extract current user
	const { user } = useUser();

	// extracting ids from url
	const { canvasId: blockId } = useParams<PathParamsType>();

	// extract all blocks from redux
	const blocks = useSelector((state) => (state as ReduxState).blocks.data, shallowEqual);

	// extract current block
	const currentBlock = blocks[blockId];

	const dispatch = useDispatch();

	// Function for handling popover
	const handleShowPopover = (isOpen: boolean) => {
		const isTagUserOpen = document.querySelector('.ql-mention-list-container') !== null;
		if (isTagUserOpen) {
			return;
		}
		// if the popover was opened before dragging the bubble, then after drag end show it back
		if (isPopoverOpened && !showPopover && bubbleDragging.current) {
			setShowPopover(true);
			setIsPopoverOpened(false);
		} else if (
			!bubbleDragging.current &&
			(openComment.commentId !== feedbackData._id || type === 'EXISTING')
		) {
			setShowPopover(isOpen);
		}
	};

	// use effect to se the initial position and expand state of the feedback
	useEffect(() => {
		// new feedback should be open by default
		if (type === 'NEW') {
			setShowPopover(true);
		}
		// setting initial position
		const { absoluteBounds } = feedbackData;
		if (window.pixiApp && absoluteBounds && absoluteBounds.x && absoluteBounds.y) {
			setPosition(absoluteBounds);
		}
	}, []);

	// setting feedback type based upon incoming props
	useEffect(() => {
		if (feedbackData.feedbackType) setFeedbackType(feedbackData.feedbackType as EFeedbackType);
	}, [feedbackData.feedbackType]);

	// Use effect to check if current feedback is open via action panel
	useEffect(() => {
		if (openComment.commentId === feedbackData._id) setShowPopover(true);
	}, [openComment.commentId]);

	// Function to get the newly added feedback position
	const getNewFeedbackPosition = () => position;

	// function to reset feedback type when user click cancel for new feedbacks
	const resetFeedbackType = (text: string) => {
		setFeedbackText(text);
		setFeedbackType(undefined);
		dispatch(resetNewCommentData());
	};

	// function to render new feedback based on type
	const renderNewFeedback = () => {
		if (feedbackType) {
			return (
				<NewFeedback
					text={feedbackText}
					resetFeedbackType={resetFeedbackType}
					feedbackType={feedbackType}
					getNewFeedbackPosition={getNewFeedbackPosition}
				/>
			);
		}

		return <div />;
	};

	// function to render existing feedbacks based on feedback types
	const renderExistingFeedback = () => {
		switch (feedbackType) {
			case EFeedbackType.CHAT:
				return (
					<FeedbackTile
						data={feedbackData}
						index={index}
						onClose={() => setShowPopover(false)}
					/>
				);
			case EFeedbackType.POLL:
				return (
					<PollWrapper
						data={feedbackData}
						index={index}
						onClose={() => setShowPopover(false)}
					/>
				);
			case EFeedbackType.QNA:
				return <QNAWrapper data={feedbackData} onClose={() => setShowPopover(false)} />;
			case EFeedbackType.ACT_ITEM:
				return <ActionItem data={feedbackData} onClose={() => setShowPopover(false)} />;
			default:
				return <div />;
		}
	};

	// function to handle the feedback bubble transform
	const onTransform = (x: number, y: number, z?: number) => {
		const blockType = currentBlock?.blockType;
		switch (blockType) {
			case 'CANVAS': {
				// TODO: check if we need to make any more changes while integration by referring old code
				const worldCoordinates = window.pixiApp?.viewport.toLocal({
					x,
					y
				});
				if (worldCoordinates) {
					// setting position to state variables
					setPosition({ x: worldCoordinates.x, y: worldCoordinates.y });

					if (type === 'EXISTING') {
						if (blockId) {
							sendEditFeedback({
								_id: feedbackData._id,
								absoluteBounds: { x: worldCoordinates.x, y: worldCoordinates.y, z }
							});
						}
					}
				}
				break;
			}
			case 'IMAGE':
			case 'LINK':
			case 'FILE':
			case 'PDF': {
				if (view.current) {
					// if block has image or if the link has an image
					if ((view.current as TPaperImageRef).bounds) {
						const updatedPos = setFeedbackPostionInViewer(
							view.current as TPaperImageRef,
							x,
							y
						);

						sendEditFeedback({
							_id: feedbackData._id,
							absoluteBounds: updatedPos
						});
					} else if (view.current.view) {
						// block that doeesn't have an image or is unsupported

						const updatedPos = setFeedbackPostionInViewer(
							view.current.view as TPaperView,
							x,
							y
						);

						sendEditFeedback({
							_id: feedbackData._id,
							absoluteBounds: updatedPos
						});
					}
				} else {
					// For integrations, pdf, video
					setPosition({ x, y });

					if (type === 'EXISTING') {
						if (blockId) {
							sendEditFeedback({
								_id: feedbackData._id,
								absoluteBounds: {
									x: x / window.innerWidth,
									y: y / window.innerHeight,
									z
								}
							});
						}
					}
				}
				break;
			}
			default:
				break;
		}
	};

	// function to determine if the feedbcak is completed by the current user
	const checkIfCompleted = () => {
		if (feedbackData.actionItemFor) {
			if (feedbackData.actionItemFor[user._id as string]) {
				return true;
			}
		}
		return false;
	};

	useEffect(() => {
		let posTemp: TPoint = { x: 0, y: 0 };
		const blockType = currentBlock?.blockType;
		switch (blockType) {
			case 'CANVAS': {
				if (window.pixiApp) {
					posTemp = window.pixiApp!.viewport.toGlobal({
						x: feedbackData.absoluteBounds?.x as number,
						y: feedbackData.absoluteBounds?.y as number
					});
				}
				break;
			}
			case 'FILE':
			case 'IMAGE':
			case 'LINK':
			case 'VIDEO':
			case 'PDF': {
				if (view?.current) {
					if ((view.current as TPaperImageRef).bounds) {
						// Image block
						posTemp = getFeedbackPostionInViewer(
							view.current as TPaperImageRef,
							feedbackData.absoluteBounds as TPoint
						);
					} else if (view.current.view) {
						// Link, Unsupported block
						posTemp = getFeedbackPostionInViewer(
							view.current.view as TPaperView,
							feedbackData.absoluteBounds as TPoint
						);
					}
				} else if (feedbackData.absoluteBounds) {
					// For integrations, pdf and video
					posTemp = {
						x: feedbackData.absoluteBounds.x * window.innerWidth,
						y: feedbackData.absoluteBounds.y * window.innerHeight
					};
				}
				break;
			}
			default:
				break;
		}

		setPos(posTemp as TPoint);
		if (type === 'NEW') {
			setPosition(feedbackData.absoluteBounds as TPoint);
			setShowPopover(true);
		} else setPosition(posTemp);
	}, [newComment, renderFBS]);

	return (
		<div className="tw-absolute">
			<Popover open={showPopover} onOpenChange={handleShowPopover}>
				<div>
					<FeedbackTrigger
						hasEditAccess={
							checkUserAccessLevel(
								currentBlock?.users as TUserAndRole[],
								user._id as string,
								['OWNER', 'EDITOR']
							) || !!currentBlock?.hasGuestAccess
						}
						position={pos || { x: 200, y: 300 }}
						onBubbleDrag={(isDragging) => {
							if (isDragging) {
								// If dragging and popover is opened then close the popover and setIsPopoverOpened to true
								if (showPopover) {
									setShowPopover(false);
									setIsPopoverOpened(true);
								}
							}
							// Added delay, so that the handleShowPopover fn is called before drag state is updated
							setTimeout(() => {
								bubbleDragging.current = isDragging;
							}, 50);
						}}
						onTransform={onTransform}
						key={feedbackData._id as string}
						view={view}
						feedbackPos={feedbackData.absoluteBounds || { x: 200, y: 300 }}
					>
						<FeedbackBubble
							isNew={!feedbackData._id}
							index={index}
							isCompleted={checkIfCompleted()}
							key={feedbackData._id as string}
							onClick={() => {}}
							isActionItem={feedbackData.isActionItem as boolean}
							feedbackType={feedbackData.feedbackType as EFeedbackType}
						/>
					</FeedbackTrigger>
					<Popover.Portal container={document.getElementById('collaboration-tool')}>
						<Popover.Content
							side="right"
							align="start"
							className="PopoverContent"
							sideOffset={8}
							onOpenAutoFocus={(e) => e.preventDefault()}
							collisionPadding={{ bottom: 20, top: 75 }}
						>
							{!feedbackData._id ? renderNewFeedback() : renderExistingFeedback()}
						</Popover.Content>
					</Popover.Portal>
				</div>
			</Popover>
		</div>
	);
};
