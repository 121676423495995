import { FC, useState } from 'react';
import App from 'src/components/canvas/app/App';
import './Align.scss';
import {
  DropdownButton, Dropdown, OverlayTrigger, Tooltip,
} from 'react-bootstrap';
// import BaseNode from 'src/components/canvas/app/nodes/BaseNode';
import AlignmentIcons, { AlignmentIconsSingleNode } from 'src/features/align/align.types';
import BaseNode from 'src/components/canvas/app/nodes/BaseNode';
// import align from '../../../assets/icons/align/align.png';
import { onTransformCommit } from 'src/components/canvas/app/transformerActions';
import _ from 'lodash';
import {
  bottomAlign,
  centerAlign,
  distributeHorizontally,
  distributeVertically,
  leftAlign,
  middleAlign,
  rightAlign,
  smartAlign,
  topAlign,
} from '../alignAlgorithms';

type AlignProps = {
  app: App;
};

const Align: FC<AlignProps> = ({ app }) => {
  const currentCanvas: App = app;
  const selectedOptions = currentCanvas.selectedNodes;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const onClick = (e: any, index : number) => {
    e.preventDefault();
    setSelectedIndex(index);
    const DOMTarget = e.target as HTMLElement;
    const ALIGN_TYPE = DOMTarget.getAttribute('data-align-type');

    switch (ALIGN_TYPE) {
      case 'LEFT':
        leftAlign(selectedOptions, app);
        break;
      case 'RIGHT':
        rightAlign(selectedOptions, app);
        break;
      case 'TOP':
        topAlign(selectedOptions, app);
        break;
      case 'BOTTOM':
        bottomAlign(selectedOptions, app);
        break;
      case 'CENTER':
        centerAlign(selectedOptions, app);
        break;
      case 'MIDDLE':
        middleAlign(selectedOptions, app);
        break;
      case 'HDISTRIBUTE':
        distributeHorizontally(selectedOptions);
        break;
      case 'VDISTRIBUTE':
        distributeVertically(selectedOptions);
        break;
      case 'SMART':
        smartAlign(selectedOptions);
        break;
      default:
        break;
    }

    for(let i = 0; i < selectedOptions.length; i++) {
      const node = selectedOptions[i] as BaseNode;
      node.prevNodeData = _.cloneDeep(node.nodeData);
      node.transformChangeStarted = true;
      node._transformerChanged = true;
    }

    onTransformCommit(app)
  };

  return (
    <div id="alignment-tool">
      <DropdownButton
        id="dropdown-basic-button"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        drop="up"
        title={(
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 1000, hide: 400 }}
            overlay={<Tooltip id="tooltip-bottom" style={{ marginTop: '6px' }}>Align Objects</Tooltip>}
          >
            <img
              style={{ background: dropdownOpen ? '#EDEDED' : 'transparent' }}
              src={AlignmentIcons[selectedIndex]?.icon}
              alt="Align"
            />
          </OverlayTrigger>
        )}
      >
        {selectedOptions.length > 1 && AlignmentIcons.map((icon: any, index: number) => (
          <Dropdown.Item key={icon.type}>
            <OverlayTrigger
              placement="right"
              delay={{ show: 1000, hide: 400 }}
              overlay={<Tooltip id="tooltip-right">{icon.tooltip}</Tooltip>}
            >
              <img
                role="presentation"
                width={50}
                src={icon.icon}
                alt={icon.type}
                onClick={(e) => onClick(e, index)}
                data-align-type={icon.type}
                style={{ userSelect: 'none', background: selectedIndex === index ? '#EDEDED' : 'transparent' }}
              />
            </OverlayTrigger>
          </Dropdown.Item>
        ))}
        {selectedOptions.length === 1 && AlignmentIconsSingleNode.map((icon: any, index: number) => (
          <Dropdown.Item key={icon.type}>
            <OverlayTrigger
              placement="right"
              delay={{ show: 1000, hide: 400 }}
              overlay={<Tooltip id="tooltip-right">{icon.tooltip}</Tooltip>}
            >
              <img
                role="presentation"
                width={50}
                src={icon.icon}
                alt={icon.type}
                onClick={(e) => onClick(e, index)}
                data-align-type={icon.type}
                style={{ userSelect: 'none' }}
              />
            </OverlayTrigger>
          </Dropdown.Item>
        ))}

      </DropdownButton>
    </div>
  );
};

export default Align;
