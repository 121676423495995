import { PayloadAction, createSlice, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { IBlock, IFeedback, IFeedbackBlock, TAnswer, TPollOption } from '@naya_studio/types';
import {
	TActionType,
	TAddAnswerForActionItemThunkArg,
	TAddCommentFulfill,
	TAddCommentThunkArg,
	TAddUserToActionItemThunkArg,
	TDeleteCommentThunkArg,
	TEditActionItemFulfill,
	TEditCommentThunkArg,
	TLoadCommentFulfill,
	TLoadProjectByIdFulfill,
	TLoadTemplateByIdFulfill,
	TRemoveUserFromActionItemThunkArg,
	TVotePollFulfill,
	TVotePollThunkArgs
} from 'src/types/argTypes';
import { IFeedBackInitState } from '../reducers/root.types';
import {
	loadProjectById,
	loadProjectForGuestAction,
	loadTemplateById
} from '../reduxActions/project';
import {
	addAnswerForActionItem,
	addComment,
	addUserToActionItem,
	deleteComment,
	editActionItem,
	editComment,
	loadComment,
	removeUserFromActionItem,
	votePoll
} from '../reduxActions/feedbacks';
import { notificationSlice } from './api/notification';
import { sentNotificationSlice } from './api/sentNotification';
import { loadBlockById } from '../reduxActions/block';

const initialState: IFeedBackInitState = {
	data: {},
	loading: {},
	error: {}
};

const feedbackSlice = createSlice({
	name: 'feedbacks',
	initialState,
	reducers: {
		// reducer to load feedback into redux
		loadFeedback: (state: IFeedBackInitState, action: PayloadAction<IFeedback[]>) => {
			const feedbacks = action.payload;
			feedbacks.forEach((feedback) => {
				state.data[feedback._id as string] = {
					...state.data[feedback._id as string],
					...feedback
				};
			});
		},
		// reducer to add a feedback into redux
		addFeedback: (state: IFeedBackInitState, action: PayloadAction<IFeedback>) => {
			const feedback = action.payload;

			state.data[feedback._id as string] = feedback;
		},
		// reducer to add a feedback into redux
		editFeedback: (state: IFeedBackInitState, action: PayloadAction<Partial<IFeedback>>) => {
			const feedback = action.payload;

			state.data[feedback._id as string] = {
				...state.data[feedback._id as string],
				...feedback
			};
		},
		// reducer to delete a feedback by id
		deleteFeedbackById: (state: IFeedBackInitState, action: PayloadAction<string>) => {
			const feedbackId = action.payload;

			delete state.data[feedbackId as string];
		},
		/**
		 * Reducer to unload feedbacks from redux
		 */
		unloadFeedbacks: (state: IFeedBackInitState, action: PayloadAction<string>) => {
			const projectId = action.payload;

			const feedbacksToDelete = [];
			const keys = Object.keys(state.data);
			for (let i = 0; i < keys.length; i++) {
				const feedbackId = keys[i] as string;
				if (state.data[feedbackId]!.projectId === projectId)
					feedbacksToDelete.push(feedbackId);
			}

			feedbacksToDelete.forEach((id) => delete state.data[id]);
		}
	},
	extraReducers: (builder: ActionReducerMapBuilder<IFeedBackInitState>) => {
		/** ---- LOAD BLOCK BY ID ---- */
		// FULFILLED
		builder.addCase(
			loadBlockById.fulfilled,
			(
				state: IFeedBackInitState,
				action: PayloadAction<TActionType<{ block: IBlock }, {}>['payload']>
			) => {
				const { payload } = action;

				if (payload) {
					const { block } = payload;
					const feedbackBlock = block as IFeedbackBlock;

					if (feedbackBlock?.feedbacks) {
						const feedbacks = feedbackBlock.feedbacks as IFeedback[];
						feedbacks.forEach((feedback) => {
							state.data[feedback._id as string] = feedback;
							state.loading[feedback._id as string] = false;
							state.error[feedback._id as string] = null;
						});
					}
				}
			}
		);

		/** ---- LOAD PROJECT BY ID ---- */
		// FULFILLED
		builder.addCase(
			loadProjectById.fulfilled,
			(
				state: IFeedBackInitState,
				action: PayloadAction<TActionType<TLoadProjectByIdFulfill, {}>['payload']>
			) => {
				const { feedbacks } = action.payload;

				feedbacks.forEach((feedback: IFeedback) => {
					const feedbackId = feedback._id as string;
					let overwriteFeedback = true;

					if (state.data[feedbackId]) {
						const oldFeedback = state.data[feedbackId];

						// Only update the feedback if feedback from api have been updated later
						// to prevent the scenario where a user refreshes and someone edits a feedback
						if (
							new Date(oldFeedback?.updatedAt as string) >
							new Date(feedback.updatedAt as string)
						)
							overwriteFeedback = false;
					}

					if (overwriteFeedback) state.data[feedbackId] = feedback;
					state.loading[feedbackId] = false;
					// reset the error just in case previous call has some error
					state.error[feedbackId] = null;
				});
			}
		);

		/** ---- LOAD TEMPLATE ---- */
		// FULFILLED
		builder.addCase(
			loadTemplateById.fulfilled,
			(
				state: IFeedBackInitState,
				action: PayloadAction<TActionType<TLoadTemplateByIdFulfill, {}>['payload']>
			) => {
				const { template } = action.payload;
				template?.feedbacks?.forEach((feedback) => {
					const id = feedback._id as string;

					state.data[id] = feedback;
					state.error[id] = null;
				});
			}
		);

		/** ---- LOAD PROJECT FOR GUEST ---- */
		// FULFILLED
		builder.addCase(
			loadProjectForGuestAction.fulfilled,
			(
				state: IFeedBackInitState,
				action: PayloadAction<TActionType<TLoadProjectByIdFulfill, {}>['payload']>
			) => {
				const { feedbacks } = action.payload;
				feedbacks.forEach((f: IFeedback) => {
					state.data[f?._id as string] = f;
					state.loading[f._id as string] = false;
					// reset the error just in case previous call has some error
					state.error[f._id as string] = '';
				});
			}
		);

		/** ---- LOAD COMMENT ---- */
		// FULFILLED
		builder.addCase(
			loadComment.fulfilled,
			(
				state: IFeedBackInitState,
				action: PayloadAction<TActionType<TLoadCommentFulfill, {}>['payload']>
			) => {
				const { payload } = action;
				if (payload) {
					state.data[payload._id as string] = payload.comment;
					state.loading[payload._id as string] = false;
					state.error[payload._id as string] = '';
				}
			}
		);
		/** ---- ADD COMMENT ---- */
		// PENDING
		builder.addCase(
			addComment.pending,
			(
				state: IFeedBackInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TAddCommentThunkArg>['pendingMeta']
				>
			) => {
				const { payload } = action.meta.arg;
				// If the new comment is a reply to an older comment,
				// then update the reply list
				if (payload) {
					const createdBy = {
						_id: payload.currentUser._id,
						userDetails: payload.currentUser.userDetails,
						profilePic: payload.currentUser.profilePic,
						userName: payload.currentUser.userName,
						email: payload.currentUser.email
					};

					if (payload.parentCommentId) {
						const parentComment = state.data[payload.parentCommentId];
						parentComment!.updatedAt = new Date();
						if (parentComment) {
							if (parentComment.replies) {
								parentComment.replies.push({ ...payload.feedback, createdBy });
							} else {
								parentComment.replies = [{ ...payload.feedback, createdBy }];
							}
						}
					} else {
						state.data[payload.feedback?._id as string] = {
							...payload.feedback,
							updatedAt: new Date(),
							createdBy
						};
					}
					state.loading[payload.feedback?._id as string] = true;
					state.error[payload.feedback?._id as string] = '';
				}
			}
		);

		// FULFILLED
		builder.addCase(
			addComment.fulfilled,
			(
				state: IFeedBackInitState,
				action: PayloadAction<
					TActionType<TAddCommentFulfill, {}>['payload'],
					string,
					TActionType<{}, TAddCommentThunkArg>['fulfilledMeta']
				>
			) => {
				const { feedback, parentCommentId, currentUser } = action.meta.arg.payload;
				// If the adding the comment was successful, then update the loading status
				if (feedback) {
					const createdBy = {
						_id: currentUser._id,
						userDetails: currentUser.userDetails,
						profilePic: currentUser.profilePic,
						userName: currentUser.userName,
						email: currentUser.email
					};

					if (parentCommentId) {
						const parentComment = state.data[parentCommentId];
						if (parentComment) {
							const replies = parentComment.replies as IFeedback[];
							const lastReply = replies.slice(-1)[0];

							if (lastReply?._id !== feedback._id) {
								state.data[parentCommentId]!.replies = [
									...replies,
									{ ...feedback, createdBy }
								];
							}
						}
					} else if (!state.data[feedback?._id as string])
						state.data[feedback?._id as string] = feedback;
					state.loading[feedback?._id as string] = false;
					state.error[feedback?._id as string] = '';
				}
			}
		);

		// REJECTED
		builder.addCase(
			addComment.rejected,
			(
				state: IFeedBackInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TAddCommentThunkArg>['rejectedMeta']
				>
			) => {
				const { payload } = action.meta.arg;

				if (payload.parentCommentId) {
					const parentComment = state.data[payload.parentCommentId];
					if (parentComment) {
						// If adding the new comment failed, remove it from the list
						// if it's a reply, remove the reply
						parentComment.replies = (parentComment.replies ?? []).filter((reply) => {
							if (
								typeof reply === 'object' &&
								'_id' in reply &&
								payload.feedback?._id
							) {
								return reply._id !== payload.feedback._id;
							}
							return true;
						});
					}
				} else {
					delete state.data[payload.feedback._id as string];
				}
				state.loading[payload.feedback._id as string] = false;
				state.error[payload.feedback._id as string] = '';
			}
		);

		/** ---- EDIT COMMENT ---- */
		// FULFILLED
		builder.addCase(
			editComment.fulfilled,
			(
				state: IFeedBackInitState,
				action: PayloadAction<
					TActionType<{ comment: IFeedback; blockId: string }, {}>['payload'],
					string,
					TActionType<{}, TEditCommentThunkArg>['fulfilledMeta']
				>
			) => {
				const { feedback, parentCommentId } = action.meta.arg.payload;
				// check if the comment to edit is a reply to another comment, if it is,
				// find and replace that, otherwise update the comment

				if (parentCommentId) {
					const parentComment = state.data[parentCommentId];
					if (parentComment) {
						const reply = (parentComment.replies as IFeedback[]).find(
							(r) => (r._id as string) === feedback._id
						) as IFeedback;

						if (
							new Date(reply.updatedAt as string) <
							new Date(feedback.updatedAt as string)
						)
							state.data[feedback._id as string]!.replies?.push(feedback);
					}
				} else {
					const reduxFeedback = state.data[feedback._id as string];

					if (reduxFeedback) {
						if (
							new Date(reduxFeedback.updatedAt as string) <
							new Date(feedback.updatedAt as string)
						)
							state.data[feedback._id as string] = {
								...state.data[feedback._id as string],
								...feedback,
								updatedAt: new Date()
							};
					}
				}
				state.loading[feedback._id as string] = false;
				state.error[feedback._id as string] = '';
			}
		);
		// PENDING
		builder.addCase(
			editComment.pending,
			(
				state: IFeedBackInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TEditCommentThunkArg>['pendingMeta']
				>
			) => {
				const { feedback, parentCommentId } = action.meta.arg.payload;
				// check if the comment to edit is a reply to another comment, if it is,
				// find and replace that, otherwise update the comment
				if (parentCommentId) {
					const parentComment = state.data[parentCommentId];
					parentComment!.updatedAt = new Date();
					if (parentComment) {
						const updatedReplies = (parentComment.replies as IFeedback[]).map(
							(reply) => {
								if (reply._id === feedback._id) {
									return {
										...reply,
										...feedback
									};
								}
								return reply;
							}
						);

						parentComment.replies = updatedReplies;
					}
				} else {
					state.data[feedback._id as string] = {
						...state.data[feedback._id as string],
						...feedback,
						updatedAt: new Date()
					};
				}
				state.loading[feedback._id as string] = false;
				state.error[feedback._id as string] = '';
			}
		);

		// REJECTED
		builder.addCase(
			editComment.rejected,
			(
				state: IFeedBackInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TEditCommentThunkArg>['rejectedMeta']
				>
			) => {
				const { payload, prevState } = action.meta.arg;

				if (payload) {
					if (payload.parentCommentId) {
						const parentComment = state.data[payload.parentCommentId];
						if (parentComment) {
							const updatedReplies = (parentComment.replies as IFeedback[]).filter(
								(reply) => reply._id !== payload.feedback._id
							);

							parentComment.replies = updatedReplies;
						}
					} else {
						const prevFeedback = prevState.oldComments.find(
							(comment) => comment._id === payload.feedback._id
						);
						state.data[payload.feedback._id as string] = prevFeedback as IFeedback;
					}
					state.loading[payload.feedback._id as string] = false;
					state.error[payload.feedback._id as string] = '';
				}
			}
		);
		/** ---- DELETE COMMENT ---- */
		// PENDING
		builder.addCase(
			deleteComment.pending,
			(
				state: IFeedBackInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TDeleteCommentThunkArg>['pendingMeta']
				>
			) => {
				const { payload } = action.meta.arg;

				if (payload) {
					if (payload.parentCommentId) {
						const parentComment = state.data[payload.parentCommentId];
						if (parentComment) {
							const updatedReplies = (parentComment.replies as IFeedback[]).filter(
								(reply) => reply._id !== payload._id
							);
							parentComment.replies = updatedReplies;
						}
					} else {
						delete state.data[payload._id];
					}

					state.error[payload._id] = '';
				}
			}
		);

		// REJECTED
		builder.addCase(
			deleteComment.rejected,
			(
				state: IFeedBackInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TDeleteCommentThunkArg>['rejectedMeta']
				>
			) => {
				const { payload, prevState } = action.meta.arg;
				// If deleting the comment didnt work, then put back the comment
				if (payload) {
					const prevFeedback = prevState.oldComments.find(
						(comment) => comment._id === payload._id
					);
					if (payload.parentCommentId) {
						const parentComment = state.data[payload.parentCommentId];
						if (parentComment) {
							const updatedReplies = (parentComment.replies as IFeedback[]).map(
								(reply) => {
									if (reply._id === payload._id) {
										return {
											...reply,
											...prevFeedback
										};
									}
									return reply;
								}
							);

							parentComment.replies = updatedReplies;
						}
					} else {
						state.data[payload._id as string] = prevFeedback as IFeedback;
					}
					state.loading[payload._id as string] = false;
					state.error[payload._id as string] = '';
				}
			}
		);
		/** ---- EDIT ACTION ITEM ---- */
		// FULFILLED
		builder.addCase(
			editActionItem.fulfilled,
			(
				state: IFeedBackInitState,
				action: PayloadAction<TActionType<TEditActionItemFulfill, {}>['payload']>
			) => {
				const { comment } = action.payload;
				if (comment) {
					state.data[comment._id as string] = comment as IFeedback;
					state.loading[comment._id as string] = false;
					state.error[comment._id as string] = '';
				}
			}
		);

		/** ---- ADD USER TO ACTION ITEM ---- */
		// FULFILLED
		builder.addCase(
			addUserToActionItem.fulfilled,
			(
				state: IFeedBackInitState,
				action: PayloadAction<
					TActionType<TAddUserToActionItemThunkArg['payload'], {}>['payload']
				>
			) => {
				const { payload } = action;

				if (payload) {
					const { feedbackId } = payload;

					state.loading[feedbackId] = false;
					state.error[feedbackId] = null;
				}
			}
		);
		// PENDING
		builder.addCase(
			addUserToActionItem.pending,
			(
				state: IFeedBackInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TAddUserToActionItemThunkArg>['pendingMeta']
				>
			) => {
				const { payload } = action.meta.arg;

				if (payload) {
					const { feedbackId, userId } = payload;

					if (feedbackId && state.data[feedbackId]) {
						const feedback = state.data[feedbackId] as IFeedback;

						if (feedback.actionItemFor) feedback.actionItemFor[userId] = false;

						state.loading[feedbackId] = true;
						state.error[feedbackId] = null;
					}
				}
			}
		);
		// REJECTED
		builder.addCase(
			addUserToActionItem.rejected,
			(
				state: IFeedBackInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TAddUserToActionItemThunkArg>['rejectedMeta']
				>
			) => {
				const { payload } = action.meta.arg;
				const { payload: error } = action;

				if (payload) {
					const { feedbackId, userId } = payload;

					if (feedbackId && state.data[feedbackId]) {
						const feedback = state.data[feedbackId] as IFeedback;

						if (feedback.actionItemFor) delete feedback.actionItemFor[userId];

						state.loading[feedbackId] = false;
						state.error[feedbackId] = error as string;
					}
				}
			}
		);

		/** ---- REMOVE USER FROM ACTION ITEM ---- */
		// FULFILLED
		builder.addCase(
			removeUserFromActionItem.fulfilled,
			(
				state: IFeedBackInitState,
				action: PayloadAction<
					TActionType<TRemoveUserFromActionItemThunkArg['payload'], {}>['payload']
				>
			) => {
				const { payload } = action;

				if (payload) {
					const { feedbackId } = payload;

					state.loading[feedbackId] = false;
					state.error[feedbackId] = null;
				}
			}
		);
		// PENDING
		builder.addCase(
			removeUserFromActionItem.pending,
			(
				state: IFeedBackInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TRemoveUserFromActionItemThunkArg>['pendingMeta']
				>
			) => {
				const { payload } = action.meta.arg;

				if (payload) {
					const { feedbackId, userId } = payload;

					if (feedbackId && state.data[feedbackId]) {
						const feedback = state.data[feedbackId] as IFeedback;

						if (feedback.actionItemFor) delete feedback.actionItemFor[userId];

						state.loading[feedbackId] = true;
						state.error[feedbackId] = null;
					}
				}
			}
		);
		// REJECTED
		builder.addCase(
			removeUserFromActionItem.rejected,
			(
				state: IFeedBackInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TRemoveUserFromActionItemThunkArg>['rejectedMeta']
				>
			) => {
				const { payload, prevUsers } = action.meta.arg;
				const { payload: error } = action;

				if (payload && prevUsers) {
					const { feedbackId } = payload;

					if (feedbackId && state.data[feedbackId]) {
						const feedback = state.data[feedbackId] as IFeedback;

						if (feedback.actionItemFor) feedback.actionItemFor = prevUsers;

						state.loading[feedbackId] = false;
						state.error[feedbackId] = error as string;
					}
				}
			}
		);

		/** ---- ADD ANSWER FOR ACTION ITEM ---- */
		// FULFILLED
		builder.addCase(
			addAnswerForActionItem.fulfilled,
			(
				state: IFeedBackInitState,
				action: PayloadAction<
					TActionType<TAddAnswerForActionItemThunkArg['payload'], {}>['payload']
				>
			) => {
				const { payload } = action;

				if (payload) {
					const { feedbackId } = payload;

					state.loading[feedbackId] = false;
					state.error[feedbackId] = null;
				}
			}
		);
		// PENDING
		builder.addCase(
			addAnswerForActionItem.pending,
			(
				state: IFeedBackInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TAddAnswerForActionItemThunkArg>['pendingMeta']
				>
			) => {
				const { payload } = action.meta.arg;

				if (payload) {
					const { feedbackId, answer } = payload;

					if (feedbackId && state.data[feedbackId]) {
						const feedback = state.data[feedbackId] as IFeedback;

						if (feedback.answers)
							feedback.answers = [...(feedback.answers as TAnswer[]), answer];

						state.loading[feedbackId] = true;
						state.error[feedbackId] = null;
					}
				}
			}
		);
		// REJECTED
		builder.addCase(
			addAnswerForActionItem.rejected,
			(
				state: IFeedBackInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TAddAnswerForActionItemThunkArg>['rejectedMeta']
				>
			) => {
				const { payload } = action.meta.arg;
				const { payload: error } = action;

				if (payload) {
					const { feedbackId, answer } = payload;

					if (feedbackId && state.data[feedbackId]) {
						const feedback = state.data[feedbackId] as IFeedback;

						feedback.answers = feedback.answers?.filter((a) => a._id !== answer._id);

						state.loading[feedbackId] = false;
						state.error[feedbackId] = error as string;
					}
				}
			}
		);

		/** -- Vote Poll --*/
		builder.addCase(
			votePoll.pending,
			(
				state: IFeedBackInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TVotePollThunkArgs>['pendingMeta']
				>
			) => {
				const {
					payload: { feedbackId, option, userId }
				} = action.meta.arg;
				const feedback = state.data[feedbackId];

				state.loading[feedbackId] = true;
				state.error[feedbackId] = '';
				if (feedback) {
					feedback.pollOptions
						?.find((poll) => poll.option === option)
						?.votes.push({ votedAt: new Date().toISOString(), votedBy: userId });
				}
			}
		);

		builder.addCase(
			votePoll.rejected,
			(
				state: IFeedBackInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TVotePollThunkArgs>['rejectedMeta']
				>
			) => {
				const {
					payload: { feedbackId, option, userId }
				} = action.meta.arg;
				const feedback = state.data[feedbackId];
				if (feedback) {
					const foundOption = (feedback.pollOptions as TPollOption[])?.find(
						(poll) => poll.option === option
					) as TPollOption;
					const voteIndex = foundOption.votes.findIndex(
						(vote) => vote.votedBy === userId
					);
					foundOption.votes.splice(voteIndex, 1);
				}

				state.loading[feedbackId] = false;
				state.error[feedbackId] = 'Error while adding a vote';
			}
		);

		builder.addCase(
			votePoll.fulfilled,
			(
				state: IFeedBackInitState,
				action: PayloadAction<TActionType<TVotePollFulfill, {}>['payload']>
			) => {
				const { feedbackId } = action.payload;
				state.loading[feedbackId] = false;
				state.error[feedbackId] = '';
			}
		);

		/** ---- MARK NOTIFICATION -> MARK FEEDBACK */
		// marking notif as complete/incomplete marks the corresponding feedback as complete/incomplete
		builder.addMatcher(
			notificationSlice.endpoints.toggleNotifComplete.matchFulfilled,
			(state: any, action: any) => {
				const { feedback } = action.payload;

				if (feedback) {
					state.data[feedback._id as string].actionItemFor = feedback.actionItemFor;
					state.loading[feedback._id as string] = false;
					state.error[feedback._id as string] = '';
				}
			}
		);

		builder.addMatcher(
			notificationSlice.endpoints.updateFeebackNotif.matchFulfilled,
			(state: any, action: any) => {
				const { feedbackId, userId, isCompleted } = action.meta.arg.originalArgs;

				if (feedbackId) {
					state.data[feedbackId as string].actionItemFor[userId] = isCompleted;
					state.loading[feedbackId as string] = false;
					state.error[feedbackId as string] = '';
				}
			}
		);

		/** --- SENT NOTIFICATION --- */
		// When a sender marks the task as complete, mark the feedback as complete for everyone
		builder.addMatcher(
			sentNotificationSlice.endpoints.toggleSentNotifComplete.matchFulfilled,
			(state: any, action: any) => {
				const { feedback } = action.payload;

				state.data[feedback._id as string].actionItemFor = feedback.actionItemFor;
				state.loading[feedback._id as string] = false;
				state.error[feedback._id as string] = '';
			}
		);
	}
});

export const { loadFeedback, addFeedback, deleteFeedbackById, editFeedback, unloadFeedbacks } =
	feedbackSlice.actions;

export default feedbackSlice.reducer;
