/* eslint-disable @typescript-eslint/no-shadow */
import axios from 'axios';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PDF_TO_IMAGE_ENDPOINT } from 'src/endpoints/upload-endpoints';
import { storage } from 'src/util/storage/firebaseStorage';
import useBlock from 'src/redux/hooks/useBlocks';
import useBlockActions from 'src/util/block/useBlockActions';
import {
	EUserType,
	I3D,
	I3DSubtype,
	IBlock,
	ICanvas,
	IFeedback,
	IFile,
	IFileSubtype,
	ILink,
	ILinkSubtype,
	INode,
	IProjectDetail,
	IUser,
	TAbsoluteBoundingBox,
	TUserAndRole
} from '@naya_studio/types';
import { v1 as uuidv1 } from 'uuid';
import React, {
	useEffect,
	useState,
	useRef,
	createContext,
	useCallback,
	useContext,
	useMemo
} from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Prompt, useHistory, useLocation, useParams } from 'react-router';
import { store } from 'src';
import validator from 'validator';
import { CustomDispatch } from 'src/redux/actions/types';
import { ISnackBar, ReduxState } from 'src/redux/reducers/root.types';
import { checkAccess, checkUserAccessLevel } from 'src/util/accessLevel/accessLevelActions';
import './CollaborationTool.scss';
import { addBatchUndoActions, undoAction } from 'src/redux/actions/undoRedoActions';
import { EActionType } from 'src/redux/reducers/undoRedo/undoActionHistory.types';
import { threeDModelFormats } from 'src/util/helper/constants';
import { cancelPrediction } from 'src/redux/actions/textToImage';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';
import { Button, SessionStorageKeys, SnackBar } from '@naya_studio/radix-ui';
import mongoose from 'mongoose';
import { base64StringToBlob } from 'blob-util';
import {
	TAddNodesArg,
	TAddUserToBlockThunkArgs,
	TAddUserToProjectThunkArg,
	TAddUserToStageThunkArg,
	TEditBlockArgs,
	TEditNodesArgs,
	TRequestProjectThunkArg,
	TRequestType
} from 'src/types/argTypes';
import { addNodes, editNodes } from 'src/redux/reduxActions/node';
import { generateIdsFromUrl, saveReduxStateInIndexedDB } from 'src/redux/reduxActions/util';
import { addUserToBlock, editBlock } from 'src/redux/reduxActions/block';
import useProject from 'src/redux/hooks/useProject';
import {
	getDownloadURL,
	getMetadata,
	ref,
	uploadBytesResumable,
	uploadString
} from 'firebase/storage';
import { unloadProject } from 'src/redux/features/projects';
import { TSocketEvent, connectToWebSocket, getWebSocket, getYDoc } from 'src/rtc/yjs/yjsConfig';
import useObserverInitializer from 'src/redux/hooks/observers/useObserverInitializer';
import { Map as YMap } from 'yjs';
import {
	informUserToReconnect,
	removeSocketConnectionSnackbar
} from 'src/redux/hooks/observers/utils';
import { unloadProjectUsers } from 'src/redux/features/projectUsers';
import useHighlightObserver from 'src/redux/hooks/observers/useHighlightObserver';
import useProjectUsersObserver from 'src/redux/hooks/observers/useProjectUsersObserver';
import { WebsocketProvider } from 'y-websocket';
import useUser from 'src/redux/hooks/user';
import { resetUser } from 'src/redux/features/user';
import { unloadStages } from 'src/redux/features/stages';
import { unloadBlocks } from 'src/redux/features/blocks';
import { unloadFeedbacks } from 'src/redux/features/feedbacks';
import { unloadNotes } from 'src/redux/features/notes';
import { unloadNodes } from 'src/redux/features/nodes';
import useNayaExpress from 'src/redux/hooks/useNayaExpress';
import { CustomEvents } from 'src/util/analytics/events';
import useMonitorPerformance from 'src/hooks/useMonitorPerformance';
import trackEvent from 'src/util/analytics/analytics';
import { TRequestAcessEventData, TUseSearchEventData } from 'src/util/analytics/analytic.types';
import { saveGroupInLocalForage, saveUserInLocalForage } from 'src/util/storage/indexedDBStorage';
import {
	addUserToProject,
	requestAccess,
	syncProjectWithDrive
} from 'src/redux/reduxActions/project';
import { getProjectSyncObserver } from 'src/util/helper/project';
import { addUserToStage } from 'src/redux/reduxActions/stage';
import useAiDigestForAi from 'src/util/block/useAiDigestForAi';
import { getStageAndChildren } from 'src/redux/actions/util';
import getUserFromRedux from 'src/util/helper/user';
import {
	EMultiselectAIOpt,
	FeedbackAISnackbars,
	TupdateSnackbarPayload
} from 'src/util/block/utilAI';
import { throttle } from 'lodash';
import GuestAccessModal from '../GuestAccess/GuestAccessModal';
import { AuthContext } from '../auth/AuthProvider';
import useAiGeneration from '../../util/block/useAiGeneration';
import {
	IDeliverableTypes,
	NayaStats,
	NayaStatsBlockType,
	PathParamsType,
	TSearchQuery,
	TGuestAccessTo,
	TProjectStatuses,
	TBlockStatuses,
	TAccess,
	TLDFlags,
	TReminderModalDetails,
	TRedirectModalDetails
} from './CollaborationTool.types';
import { ExpandedBlock } from '../expandedBlockView/ExpandedBlockView'; // NOTE: Do not move this import to below journeyContainer
import { getNodeData } from '../utilities/upload/upload';
import Canvas from '../canvas/Canvas';
import Toolbar from '../canvas/toolbar/Toolbar';
import App from '../canvas/app/App';
import onPaste from '../../features/copyPaste/paste';
import AppLoader from '../utilities/appLoader/AppLoader';
import Model from '../canvas/app/nodes/Model';
import documentIcon from '../../assets/icons/document.svg';
import FilePlaceholder from '../canvas/app/nodes/FilePlaceholder';
import JourneyContainer from '../journeyContainer/JourneyContainer';
import 'vidstack/styles/defaults.css';
import 'vidstack/styles/community-skin/video.css';
import SharingModal from '../sharingModal/SharingModal';
import { ESharingTabs } from '../sharingModal/SharingModal.types';
import { getFileLinkSubType } from '../utilities/navbar/utils';
import Deliverables from '../journeyContainer/Deliverables';
import { checkIfUserOnJourney, loadMiroCanvas } from '../../util/collaboration/util';
import NavBar from './Navbar/JourneyNavbar';
import useLoader from './hooks/useLoader';
import EditThumbnailModal from '../editThumbnailModal/EditThumbnailModal';
import { TEditThumbnailDetails } from '../editThumbnailModal/EditThumbnailModal.types';
import ReminderModal from '../renameReminderModal/ReminderModal';
import { RequestAccess, SuccessfulRequest } from './RequestAccess';
import RedirectModal from '../redirectModal/RedirectModal';

type ActionType = 'ADD' | 'UNDO' | 'REDO';
type TPayload = {
	originalAction: (...args: any) => void;
	oppositeAction: (...args: any) => void;
	originalActionPayload: any;
	oppositeActionPayload: any;
};

type TAction = {
	type: ActionType;
	payload?: TPayload;
};

export const CollaborationToolContext = createContext<{
	createdByUsers: IUser[];
	availableTypes: string[];
	searchFilters: TSearchQuery;
	deliverableType: IDeliverableTypes;
	showShareModal: boolean;
	stickyNotes: string[];
	isGuestUser: boolean;
	redirectModalDetails: TRedirectModalDetails | null;
	isUndoActive: boolean;
	isRedoActive: boolean;
	pendingUsersId: string[];

	setRedirectModalDetails: React.Dispatch<React.SetStateAction<TRedirectModalDetails | null>>;
	setCreatedByUsers: React.Dispatch<React.SetStateAction<IUser[]>>;
	setAvailableTypes: React.Dispatch<React.SetStateAction<string[]>>;
	setSearchFilters: React.Dispatch<React.SetStateAction<TSearchQuery>>;
	onDropFilesInProjectPanel: (arg0: any, arg1: any) => void;
	uploadfilesFromOtherSource: (arg0: any, arg1: TAbsoluteBoundingBox) => void;
	cancelUploading: () => void;
	setShowShareModal: React.Dispatch<React.SetStateAction<boolean>>;
	setDeliverableType: React.Dispatch<React.SetStateAction<IDeliverableTypes>>;
	setStickyNotes: React.Dispatch<React.SetStateAction<string[]>>;
	goBack: () => void;
	handleUndoRedo: (action: TAction) => void;
	onCollabUnMount: (cb: () => void) => void;
	handleMultiselectAIWrapper: (
		option: EMultiselectAIOpt,
		blockIds: string[],
		groupIds: string[],
		subOption?: string,
		noOfConcepts?: number,
		inputPrompt?: string
	) => void;
	handleRegenerate: (
		option: EMultiselectAIOpt,
		subOption?: string,
		noOfConcepts?: number
	) => void;
	updateMultiselectAiProgressSnackbar: (data: TupdateSnackbarPayload) => void;
	handleReplaceOlder: (
		phaseId: string,
		option: EMultiselectAIOpt,
		isUndoRedo?: boolean,
		createNewPhase?: boolean
	) => void;
}>({
	createdByUsers: [],
	availableTypes: [],
	deliverableType: undefined,
	searchFilters: {},
	showShareModal: false,
	stickyNotes: [],
	isGuestUser: true,
	isUndoActive: false,
	isRedoActive: false,
	pendingUsersId: [],
	redirectModalDetails: null,

	setDeliverableType: () => {},
	setCreatedByUsers: () => {},
	setAvailableTypes: () => {},
	setSearchFilters: () => {},
	onDropFilesInProjectPanel: () => {},
	uploadfilesFromOtherSource: () => {},
	cancelUploading: () => {},
	setShowShareModal: () => {},
	setStickyNotes: () => {},
	handleUndoRedo: () => {},
	goBack: () => {},
	onCollabUnMount: () => {},
	setRedirectModalDetails: () => {},
	handleMultiselectAIWrapper: () => {},
	handleRegenerate: () => {},
	updateMultiselectAiProgressSnackbar: () => {},
	handleReplaceOlder: () => {}
});

export const CollaborationTool = () => {
	const [loading, setLoading] = useState(true);
	const [progress, setProgress] = useState(0);
	const [access, setAccess] = useState<TAccess>();
	const [projectStatus, setProjectStatus] = useState<TProjectStatuses>();
	const [blockStatus, setBlockStatus] = useState<TBlockStatuses>();
	const [snackbarMsg, setSnackbarMsg] = useState('');
	const [isUploadingCancelled, setUploadingCancelled] = useState(false);
	const [uploads3dData, setuploads3dData] = useState<any>([]);
	const [uploads2dData, setuploads2dData] = useState<any>([]);
	const [placeHolderData, setPlaceholderData] = useState<any>([]);
	const [dragFiles, setDragFiles] = useState<boolean>(false);
	const [dragCounter, setDragCounter] = useState<number>(0);
	const [createdByUsers, setCreatedByUsers] = useState<IUser[]>([]);
	const [availableTypes, setAvailableTypes] = useState<string[]>([]);
	const [activeFeedbackId, setActiveFeedbackId] = useState<string | undefined>(undefined);
	const [stickyNotes, setStickyNotes] = useState<string[]>([]);
	const [isRequestStatus, setisRequestStatus] = useState<boolean>(false);
	// State to manage the deliverable type
	const [deliverableType, setDeliverableType] = useState<IDeliverableTypes>(undefined);
	const [showGuestAccessModal, setShowGuestAccessModal] = useState(false);

	const [undoStack, setUndoStack] = useState<any[]>([]);
	const [redoStack, setRedoStack] = useState<any[]>([]);
	const MAX_STACK_LENGTH = 100;

	const [pendingUsersId, setPendingUsersId] = useState<string[]>([]);

	// Ref to handle timeout for how long user was on another tab
	const leftTabOpenFor = useRef<number | null>(null);

	const snackbarTimeout = 2000;
	const threeDfileUploadFinishedCount = useRef<number>(0);
	const twoDfileCount = useRef<number>(0);
	const threeDfileCount = useRef<number>(0);
	const uploadStorageRefs = useRef<any[]>([]);
	const nodeIdRef = useRef<string[]>([]);
	const uploads3dDataRefs = useRef<any[]>([]);
	const uploads2dDataRefs = useRef<any[]>([]);
	const placeHolderDataRef = useRef<any[]>([]);
	const placeAllClicked = useRef<boolean>(false);
	const canvasClicked = useRef<boolean>(false);
	const canvasIdRef = useRef<string>('');
	const gridPositionWrap = useRef<any>({
		xPosition: 100,
		yPosition: 100,
		maxHeight: 0,
		isCentred: false
	});
	const isInitialLoad = useRef<boolean>(false);
	const isSnapshot = useRef<boolean>(false);
	const timeout = useRef<any>(null);
	const app = useRef<App>();
	// holds ref of expanded view
	const expandedViewRef = useRef<any>();

	// Monitors the app overall performance and shows snackbar to user
	useMonitorPerformance();

	const stopDragDrop = [
		'Adding Files',
		'Adding Snapshot',
		'Click or drag to place files'
	].includes(snackbarMsg);
	const [dragRandomSentence, setDragRandomSentence] = useState('');
	const [hideAllMenu, setHideAllMenu] = useState<boolean>(false);
	const [ptMode, setPTMode] = useState(false); // set true in Presentation mode
	const [currentBlock, setCurrentBlock] = useState<IBlock>(); // holds the current active block
	const [isGuestUser, setIsGuestUser] = useState<boolean>(false);
	const [isRequestingAccess, setIsRequestingAccess] = useState<boolean>(false);

	const allBlockFeedbacks = useSelector((state) => (state as ReduxState).feedbacks.data);

	const history = useHistory();
	const params = useParams<PathParamsType>();
	function useQuery() {
		return new URLSearchParams(useLocation().search);
	}
	const query = useQuery();

	const [searchFilters, setSearchFilters] = useState<TSearchQuery>({});
	const [showShareModal, setShowShareModal] = useState(false);
	const [sharingDetails, setSharingDetails] = useState<{
		projectId: string;
		phaseId: string | undefined;
		blockId: string | undefined;
	}>({ projectId: params.projectId, phaseId: undefined, blockId: undefined });
	const [editThumbnailDetails, setEditThumbnailDetails] = useState<
		TEditThumbnailDetails | undefined
	>(undefined);
	const [activeTab, setActiveTab] = useState<keyof typeof ESharingTabs>(ESharingTabs.PROJECT);
	const [reminderModalDetails, setShowReminderModalDetails] =
		useState<TReminderModalDetails | null>(null);
	const [redirectModalDetails, setRedirectModalDetails] = useState<TRedirectModalDetails | null>(
		null
	);

	const ranDomSentences = [
		'It’s a bird! It’s a plane! It’s an incoming file drop!',
		'Beam me down, Scotty!',
		'Look out from 30,000 ft below!',
		'CANNONBAAALLLLLLLLLL!',
		'Psst... you can also add 3D models'
	];

	const { updateHasGuestAccessTo, hasGuestAccessTo, status, setStatus } = useContext(AuthContext);
	const { user } = useUser();
	const brainTool = useSelector((state) => (state as ReduxState).brainTool, shallowEqual);
	const snackBarData = useSelector((state) => (state as ReduxState).snackBar, shallowEqual);
	const allNodes = useSelector((state) => (state as ReduxState).nodes.data, shallowEqual);
	const allBlocks = useSelector((state) => (state as ReduxState).blocks.data, shallowEqual);
	const allStages = useSelector((state) => (state as ReduxState).stages.data, shallowEqual);
	const newComment = useSelector((state) => (state as ReduxState).newComment);
	const { blockId: cId } = generateIdsFromUrl();
	const { createTextBlockIf3DRedirect, trigger3DGenerationIf3DRedirect } = useNayaExpress();

	// Load project and handle project error
	const { project, isLoading: projectLoading } = useProject(params.projectId, (err) => {
		if (typeof err !== 'string' && err?.status === 404) {
			if (err.message === 'No matching record found') setProjectStatus('PROJECT_NOT_FOUND');
			else if (err.message === 'Project has been deleted')
				setProjectStatus('PROJECT_IS_DELETED');
			else setProjectStatus('FAILED_TO_LOAD');
		} else if (
			typeof err !== 'string' &&
			err?.status === 400 &&
			err.message === 'Error ocurred in authentication'
		) {
			// If cookie is expired in session, then sign out the user and redirect user to login page
			setStatus('SIGNED_OUT');
			history.push('/login');
		} else setProjectStatus('FAILED_TO_LOAD');
		setProgress(100);
	});

	// Load block and handle error
	const { block, isLoading } = useBlock(cId, (err) => {
		if (typeof err !== 'string' && err?.status === 404) {
			if (err.message === 'Block has been deleted') {
				setBlockStatus('BLOCK_IS_DELETED');
			} else {
				setBlockStatus('BLOCK_NOT_FOUND');
			}
		}
	});

	const { onBlockEdit, onAddBlocks, startUploadOnPaste } = useBlockActions(
		trigger3DGenerationIf3DRedirect
	);

	const { onGenerateWithAI, onAIRename, onAISuggestion, onAIOrganize, abortController } =
		useAiGeneration();

	const {
		handleMultiselectAIWrapper,
		handleRegenerate,
		updateMultiselectAiProgressSnackbar,
		handleReplaceOlder
	} = useAiDigestForAi();

	// Get trigger on unmount and abort the ai oragnise request if it is in progress.
	useEffect(
		() => () => {
			if (window.sessionStorage.getItem('isInteractionDisabled')) {
				removeSnackbar(0);
				abortController();
				window.sessionStorage.removeItem('isInteractionDisabled');
			}
		},
		[]
	);

	// Use effect to identify ai ingest status change
	useEffect(() => {
		const isIngestionTriggeredFromFeedback = window.sessionStorage.getItem(
			'ingestion-triggered-from-feedback'
		);
		if (
			project?.aiIngestStatus === 'READY' &&
			isIngestionTriggeredFromFeedback === project._id
		) {
			updateMultiselectAiProgressSnackbar({
				start: 0,
				end: 0,
				snackbarType: 'NORMAL',
				msg: FeedbackAISnackbars.INGESTION_COMPLETE,
				forceAdd: true
			});
			window.sessionStorage.removeItem('ingestion-triggered-from-feedback');
		}
	}, [project?.aiIngestStatus]);

	const {
		miroCanvas,
		generativeAiFeatures,
		textEditor,
		journeyZoom,
		isDueDateEnabled,
		myTaskEnabled,
		journeyRtc,
		guestAccess,
		isPmAiEnabled,
		is3DGenEnabled,
		createInNaya,
		isMiroIntegrated,
		isGoogleIntegrated,
		isFullscreen,
		isMultiSelectEnabled,
		isJourneyAlignEnabled,
		isSyncDriveEnabled,
		isSyncStorageEnabled,
		isCustomThumbnail,
		isReusingNotesEnabled,
		isCustomColorEnabled,
		isBlockResetEnabled,
		isProductTourEnabled,
		isTextToTextAiEnabled,
		isUndoRedo,
		isAiRenameEnabled,
		isAiOrganizeEnabled,
		isLinkEmbedsEnabled,
		isMultiselectAiEnabled,
		isPasswordProtectionEnabled,
		isExcalidrawCanvasEnabled,
		isAiPresentationEnabled,
		isTodoBlocksEnabled,
		isJourneyToolbarEnabled,
		isImageEnhancementsEnabled,
		isPasteHereEnabled,
		isOpenLinkEnabled,
		isCollapseBlocksEnabled,
		...remainingFlags
	} = useFlags<TLDFlags>();

	const { initializeObservers, initializeLocalStorageBroadcast, stopLocalStorageBroadcast } =
		useObserverInitializer();
	const { insertUsersToProjectUsersYMap } = useProjectUsersObserver();
	const { removeHighlightsOfUser, highlightObserver } = useHighlightObserver();
	const wsProvider = useRef<WebsocketProvider | null>(null);

	const { loadJourneyThumbnails: loadFontsAndThumbnails } = useLoader();

	/** Memoized flags */
	const flags = useMemo(
		() => ({
			isZoomEnabled: journeyZoom || Boolean(user.userType?.includes('ADMIN')),
			isMiroCanvasEnabled: miroCanvas || Boolean(user.userType?.includes('ADMIN')),
			areGenerativeAIFeaturesEnabled:
				generativeAiFeatures || Boolean(user.userType?.includes('ADMIN')),
			isTextEditorEnabled: textEditor || Boolean(user.userType?.includes('ADMIN')),
			areDateFeaturesEnabled: isDueDateEnabled || Boolean(user.userType?.includes('ADMIN')),
			isTaskIndicatorPopupEnabled: myTaskEnabled || Boolean(user.userType?.includes('ADMIN')),
			isJourneyRtcEnabled: journeyRtc || Boolean(user.userType?.includes('ADMIN')),
			isGuestAccessEnabled: guestAccess || Boolean(user.userType?.includes('ADMIN')),
			isPMAIEnabled: isPmAiEnabled || Boolean(user.userType?.includes('ADMIN')),
			is3DGenEnabled: is3DGenEnabled || Boolean(user.userType?.includes('ADMIN')),
			isCreateInNayaEnabled: createInNaya || Boolean(user.userType?.includes('ADMIN')),
			isMiroIntegrated: isMiroIntegrated || Boolean(user.userType?.includes('ADMIN')),
			isGoogleIntegrated: isGoogleIntegrated || Boolean(user.userType?.includes('ADMIN')),
			isFullscreen: isFullscreen || Boolean(user.userType?.includes('ADMIN')),
			isMultiSelectEnabled: isMultiSelectEnabled || Boolean(user.userType?.includes('ADMIN')),
			isJourneyAlignEnabled:
				isJourneyAlignEnabled || Boolean(user.userType?.includes('ADMIN')),
			isSyncStorageEnabled,
			isSyncDriveEnabled,
			isCustomThumbnailsEnabled:
				isCustomThumbnail || Boolean(user.userType?.includes('ADMIN')),
			isReusingNotesEnabled:
				isReusingNotesEnabled || Boolean(user.userType?.includes('ADMIN')),
			isCustomColorEnabled: isCustomColorEnabled || Boolean(user.userType?.includes('ADMIN')),
			isBlockResetEnabled: isBlockResetEnabled || Boolean(user.userType?.includes('ADMIN')),
			isProductTourEnabled: isProductTourEnabled || Boolean(user.userType?.includes('ADMIN')),
			isTextToTextAiEnabled:
				isTextToTextAiEnabled || Boolean(user.userType?.includes('ADMIN')),
			isUndoRedoEnabled: isUndoRedo,
			isAiRenameEnabled,
			isAiOrganizeEnabled,
			isLinkEmbedsEnabled,
			isMultiselectAiEnabled,
			isPasswordProtectionEnabled,
			isExcalidrawCanvasEnabled,
			isAiPresentationEnabled,
			isTodoBlocksEnabled,
			isJourneyToolbarEnabled,
			isImageEnhancementsEnabled,
			isPasteHereEnabled,
			isOpenLinkEnabled,
			isCollapseBlocksEnabled,
			...remainingFlags
		}),
		[
			miroCanvas,
			generativeAiFeatures,
			textEditor,
			journeyZoom,
			isDueDateEnabled,
			myTaskEnabled,
			journeyRtc,
			guestAccess,
			isPmAiEnabled,
			is3DGenEnabled,
			createInNaya,
			isMiroIntegrated,
			isGoogleIntegrated,
			isFullscreen,
			isMultiSelectEnabled,
			isJourneyAlignEnabled,
			isSyncDriveEnabled,
			isSyncStorageEnabled,
			isCustomThumbnail,
			isReusingNotesEnabled,
			isCustomColorEnabled,
			isBlockResetEnabled,
			isProductTourEnabled,
			user.userType,
			isTextToTextAiEnabled,
			isAiRenameEnabled,
			isAiOrganizeEnabled,
			isLinkEmbedsEnabled,
			isMultiselectAiEnabled,
			isPasswordProtectionEnabled,
			isExcalidrawCanvasEnabled,
			isAiPresentationEnabled,
			isTodoBlocksEnabled,
			isJourneyToolbarEnabled,
			isImageEnhancementsEnabled,
			isPasteHereEnabled,
			isOpenLinkEnabled,
			isCollapseBlocksEnabled
		]
	);

	useEffect(() => {
		if (isLoading) {
			setCurrentBlock(undefined);
		} else if (!block) {
			setCurrentBlock(undefined);
		} else if (block) {
			const tempBlock = { ...block };
			tempBlock.hasGuestAccess = tempBlock?.hasGuestAccess || hasGuestAccessTo === 'PROJECT';
			switch (tempBlock.blockType) {
				case 'LINK': {
					if ((tempBlock as ILink).link) {
						(tempBlock as ILink).subType = getFileLinkSubType(
							(tempBlock as ILink).link,
							'LINK'
						) as keyof typeof ILinkSubtype;
					}
					break;
				}
				case 'FILE': {
					if ((tempBlock as IFile).fileName) {
						(tempBlock as IFile).subType = getFileLinkSubType(
							(tempBlock as IFile).fileName,
							'FILE'
						) as keyof typeof IFileSubtype;
					}
					break;
				}
				case 'THREE_D': {
					if ((tempBlock as I3D).fileName) {
						// This will be used to show type as [ILLUSTRATOR, RHINO, ETC...] as such
						(tempBlock as I3D).subType = getFileLinkSubType(
							(tempBlock as I3D).fileName,
							'THREE_D'
						) as keyof typeof I3DSubtype;
					}
					break;
				}
				default:
					break;
			}
			setCurrentBlock(tempBlock);
			if (tempBlock.isVisible) {
				setBlockStatus(undefined);
			} else {
				setBlockStatus('BLOCK_IS_DELETED');
			}
		}
	}, [block, isLoading]);

	/**
	 * Whenever the canvasId changes, set block status to undefined
	 */
	useEffect(() => {
		setBlockStatus(undefined);
	}, [params.canvasId]);
	/**
	 * Triggers when someone uses journey search
	 */
	useEffect(() => {
		// If search filters present, then track journey search event
		if (Object.keys(searchFilters).length) {
			// Tracke search event for journey
			const eventProps: TUseSearchEventData = {
				searchQuery: searchFilters,
				searchType: 'JOURNEY'
			};
			trackEvent(CustomEvents.USE_SEARCH, eventProps);
		}
	}, [searchFilters]);

	// const snapshotTimer = useRef<any>(false);

	/**
	 * Function to convert the url to valid one if a group url is pasted in tab
	 */
	const convertToValidUrl = () => {
		const { canvasId, stageOrDashboard } = params;

		// Check if pasted url has group id in it
		const hasGroupId =
			!stageOrDashboard || !mongoose.Types.ObjectId.isValid(stageOrDashboard) || !canvasId;
		// Check if pasted url is indeed of group
		const isGroupUrl =
			hasGroupId && history.location.pathname !== `/project/${params.projectId}`;

		// If url is of group, then return user to project url
		if (isGroupUrl) history.push(`/project/${params.projectId}`);
	};

	const initialize = () => {
		const { canvasId, stageOrDashboard } = params;
		let userHasAccessToProject = checkAccess(params.projectId, 'PROJECT', true);
		if (canvasId) {
			userHasAccessToProject = checkAccess(canvasId, 'CANVAS');
		} else if (stageOrDashboard) {
			userHasAccessToProject = checkAccess(stageOrDashboard, 'STAGE');
		} else {
			userHasAccessToProject = checkAccess(params.projectId, 'PROJECT', true);
		}
		const requestType = query.get('requestType') || '';
		const hasGuestAccess = Boolean(
			(project?.hasGuestAccess || (params.canvasId && block?.hasGuestAccess)) &&
				flags.isGuestAccessEnabled
		);
		// If user has access to project or project or block has guest access enabled
		if (hasGuestAccess || userHasAccessToProject) {
			setAccess('ALLOWED');
			if (!requestType) {
				convertToValidUrl();
			}
		} else if (params.projectId && user._id) {
			// If the user does not have access to project, show denied screen
			if (!blockStatus) setAccess('DENIED');

			setProgress(100);
		}
	};

	// Toggle loading screen once loading is done i.e. progress is completed
	useEffect(() => {
		if (progress === 100) setLoading(false);
	}, [progress]);

	// Runs after project is loaded to redux
	useEffect(() => {
		if (project && project._id && !projectLoading && isInitialLoad.current) {
			const isGuestUser =
				((project?.hasGuestAccess || block?.hasGuestAccess) && !user._id) || false;
			const checkUser = async () => {
				loadFontsAndThumbnails(params.projectId, isGuestUser, setProgress);
			};
			checkUser();

			// Now that resources have been loaded set it to false to prevent further calls to loadFontsAndTextures
			isInitialLoad.current = false;
		}
	}, [projectLoading, project?._id, isInitialLoad.current]);

	/**
	 * Sync project with drive folder connected on refresh
	 * pulls in any updates in child folders too
	 */
	useEffect(() => {
		if (project?._id && user._id) {
			const driveObserver = getProjectSyncObserver(project?._id as string, 'GOOGLE_DRIVE');
			if (
				createInNaya &&
				isSyncStorageEnabled &&
				isSyncDriveEnabled &&
				driveObserver?.channelDetails?.channelId
			)
				(store.dispatch as CustomDispatch)(
					syncProjectWithDrive({
						projectId: driveObserver.projectId,
						userId: driveObserver.userId
					})
				);
		}
	}, [project?._id, user._id]);

	// use effect to init the app only when the project and user BOTH are loaded
	useEffect(() => {
		if (project && project._id && user._id) {
			initialize();
			// Function to create text block if its a 3D redirect
			createTextBlockIf3DRedirect(onAddBlocks);
		}
	}, [project, user._id]);

	// Updates the hasGuestAccessTo
	useEffect(() => {
		let guestAccessTo: TGuestAccessTo = false;
		if (project?._id) {
			if (block?._id && block.hasGuestAccess) {
				guestAccessTo = 'BLOCK';
			}
			if (project?._id && project.hasGuestAccess) {
				guestAccessTo = 'PROJECT';
			}
			updateHasGuestAccessTo(guestAccessTo);
			// Deny the guest user access if there is no guestAccessTo
			if (!guestAccessTo && isGuestUser) {
				setAccess('DENIED');
			}

			initialize();
		}
	}, [
		project?._id,
		project?.hasGuestAccess,
		block?._id,
		block?.hasGuestAccess,
		flags.isGuestAccessEnabled
	]);

	// Sets the guest user
	useEffect(() => {
		if (user._id && hasGuestAccessTo && project?._id && flags.isGuestAccessEnabled) {
			let userHasAccess = false;
			// check for project level and block level access
			if (hasGuestAccessTo === 'PROJECT') {
				userHasAccess = checkAccess(params.projectId, 'PROJECT', false);
			} else if (hasGuestAccessTo === 'BLOCK' && params.canvasId) {
				userHasAccess = checkAccess(params.canvasId, 'CANVAS');
			}
			setIsGuestUser(user.userType?.includes(EUserType.GUEST) || !userHasAccess);
		}
	}, [hasGuestAccessTo, user._id, user.userType]);

	// On unmount RESET the guest user
	useEffect(
		() => () => {
			if (user.userType?.includes(EUserType.GUEST)) {
				store.dispatch(resetUser());
			}
		},
		[user.userType?.includes(EUserType.GUEST)]
	);

	// use effect to add grey background to avoid flicker
	useEffect(() => {
		if (!loading && params.canvasId && !document.body.classList.contains('bg-grey')) {
			document.body.classList.add('bg-grey');
		}
		return () => {
			document.body.classList.remove('bg-grey');
		};
	}, [params.canvasId]);

	const cleanup = (skipGlobalFilesDnDReset?: boolean) => {
		threeDfileCount.current = 0;
		twoDfileCount.current = 0;
		uploadStorageRefs.current = [];
		if (!isUploadingCancelled) {
			nodeIdRef.current = [];
			setUploadingCancelled(false);
		}
		threeDfileUploadFinishedCount.current = 0;
		placeAllClicked.current = false;
		gridPositionWrap.current = {
			xPosition: 100,
			yPosition: 100,
			maxHeight: 0,
			isCentred: false,
			alreadySet: false
		};
		isSnapshot.current = false;
		uploads2dDataRefs.current = [];
		uploads3dDataRefs.current = [];
		setuploads3dData([]);
		setuploads2dData([]);
		setPlaceholderData([]);
		setUploadingCancelled(false);
		if (!skipGlobalFilesDnDReset) {
			(app.current as App).globalFilesDnD = true;
		}
		(app.current as App).showFilesHover = false;
	};

	const setGridPosition = (width: number, height: number) => {
		if (
			twoDfileCount.current + threeDfileCount.current === 1 &&
			gridPositionWrap.current.isCentred
		) {
			const bound = (app.current as App).viewport.getVisibleBounds();
			gridPositionWrap.current = {
				xPosition: bound.x + bound.width / 2 - width,
				yPosition: bound.y + bound.height / 2 - height,
				maxHeight: 410
			};
		}
	};

	const alertModelUpload = (e: any) => {
		e.preventDefault();
		e.returnValue = '';
		// return 'Are you sure you want to leave the page?';
	};

	const checkAll3DFilesUploads = () => {
		if (
			threeDfileUploadFinishedCount.current === threeDfileCount.current &&
			(app.current as App).globalFilesDnD
		) {
			// Won't be needing a snackbar dispatch here because we are already handling the same in Model.tsx
			setSnackbarMsg('Files Uploaded');
			window.removeEventListener('beforeunload', alertModelUpload);
			setTimeout(() => {
				cleanup();
				setSnackbarMsg('');
			}, 2000);
		}
	};

	const uploadToFirebase = (
		originalFile: any,
		dateFileName: string,
		canvasId: string,
		nodeId: string
	) => {
		const extension = originalFile.name.split('.').pop();
		/** Upload original file directly to firebase storage */
		const originalFileName = dateFileName.split('.')[0];
		const storageRef = ref(storage, `${originalFileName}.${extension}`);
		const uploadTask = uploadBytesResumable(storageRef, originalFile);
		window.addEventListener('beforeunload', alertModelUpload);
		uploadTask.on(
			'state_changed',
			(snapshot) => {
				const fileProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				console.log('Upload is ', fileProgress, '% done', snapshot.state);
			},
			() => {
				// if upload failed
				// Won't be needing a snackbar dispatch here because we are already handling the same in Model.tsx
				if (!isUploadingCancelled)
					setSnackbarMsg('File failed to upload. Please try again');
				cleanup();
			},
			() => {
				/** As the upload is successful, get its download url */
				getDownloadURL(storageRef).then(async (url: string) => {
					const nodeData = getNodeData(nodeId as string) as INode;
					if (nodeData.model) nodeData.model.src = url;
					if (nodeData.version) {
						nodeData.version += 1;
					} else nodeData.version = 0;
					nodeData.lastUpdatedBy = user._id as string;
					const { stageId, projectId } = generateIdsFromUrl();

					// generating payload for edit nodes action
					const apiPayload: TEditNodesArgs = {
						data: {
							nodes: [nodeData],
							blockId: canvasId,
							stageId,
							projectId
						},
						prevState: {
							prevBlocks: allNodes
						},
						next: () => {
							if ((app.current as App).globalFilesDnD) {
								threeDfileUploadFinishedCount.current += 1;
							}
							checkAll3DFilesUploads();
							if (isUploadingCancelled) {
								nodeIdRef.current = [];
								setUploadingCancelled(false);
							}
						}
					};
					// dispatch edit nodes
					await (store.dispatch as CustomDispatch)(editNodes(apiPayload));
				});
			}
		);
	};

	const add3DModels = async (
		data: any,
		index: number,
		worldX: number,
		worldY: number,
		canvasId: string,
		fromPlaceAll?: boolean,
		fromInsideApp?: boolean
	) => {
		// Won't be needing a snackbar dispatch here because we are already handling the same in Model.tsx
		if (fromPlaceAll) setSnackbarMsg('Uploading Files');
		const newNodeData = JSON.parse(JSON.stringify(Model.defaultNodeData));
		let dateFileName;
		let originalFile;
		newNodeData.absoluteBounds.x = worldX;
		newNodeData.absoluteBounds.y = worldY;
		newNodeData.zIndex = (app.current as App).getHighestZIndex() + 1;
		if (fromInsideApp) {
			newNodeData.model = data;
		} else {
			// const { name: dateFileName, originalFile } = data;
			dateFileName = data.name;
			originalFile = data.originalFile;
			const extension = originalFile.name.split('.').pop();
			newNodeData.model = {
				name: `${dateFileName}`,
				extension
			};
		}
		const { stageOrDashboard } = params;
		//  generating payload for add nodes action
		if (!newNodeData?._id) newNodeData._id = uuidv1();
		const payload: TAddNodesArg = {
			data: {
				nodes: [
					{
						...newNodeData,
						createdBy: user._id as string,
						lastUpdatedBy: user._id as string,
						version: 0
					}
				],
				blockId: canvasId,
				projectId: project?._id as string,
				stageId: stageOrDashboard as string
			},
			prevState: {
				prevNodes: allNodes,
				prevBlock: allBlocks[canvasId as string] as IBlock
			},
			next: () => {
				nodeIdRef.current = [...nodeIdRef.current, newNodeData._id];
				if (app.current) app.current.hasCanvasUpdatedForThumbnail = true;
			}
		};
		// dispatch add nodes
		await (store.dispatch as CustomDispatch)(addNodes(payload))
			.unwrap()
			.then((response?: { nodes?: INode[] }) => {
				if (response?.nodes) {
					if (response?.nodes?.length > 1) {
						const data: Array<{
							prevNodeData: INode | IFeedback;
							newNodeData: INode | IFeedback;
						}> = [];

						response?.nodes?.forEach((node: INode) => {
							if (node) {
								data.push({
									prevNodeData: node,
									newNodeData: { ...node, isVisible: false }
								});
							}
						});

						addBatchUndoActions(EActionType.NEW_NODE, data);
					} else {
						undoAction(
							EActionType.NEW_NODE,
							{ ...(response?.nodes![0] as INode), isVisible: false },
							response?.nodes[0] as INode,
							false
						);
					}
				}
			})
			.catch((error) => {
				console.error('Error : ', error);

				const snackbarPayload: ISnackBar = {
					text: 'Failed to upload files.',
					show: true,
					type: 'ERROR'
				};

				addSnackbar(snackbarPayload);
				removeSnackbar(2000);
			});

		if (!fromInsideApp) {
			if (!fromPlaceAll && index === threeDfileCount.current - 1) {
				// Won't be needing a snackbar dispatch here because we are already handling the same in Model.tsx
				setSnackbarMsg('Uploading Files');
			}
			uploadToFirebase(
				originalFile,
				dateFileName,
				canvasId,
				nodeIdRef.current[index] as string
			);
		}
	};

	const upload3dFiles = () => {
		const cId = params.canvasId as string;
		const bound = (app.current as App).viewport.getVisibleBounds();
		const xLimit =
			bound.x + bound.width < (app.current as App).viewport.screenWidth
				? bound.x + bound.width
				: (app.current as App).viewport.screenWidth;

		uploads3dData.forEach((data: any, index: number) => {
			setGridPosition(200, 200);
			let { xPosition, yPosition } = gridPositionWrap.current;
			add3DModels(data, index, xPosition, yPosition, cId, true);
			if (xPosition + 400 >= xLimit) {
				xPosition = bound.x + 25;
				yPosition += 410;
			} else {
				xPosition += 410;
			}
			gridPositionWrap.current = { xPosition, yPosition, maxHeight: 410 };
		});
	};

	const addImageToCanvas = async (data: any) => {
		const payloadData = JSON.parse(JSON.stringify(data));
		const nodeData = payloadData as INode;
		payloadData.zIndex = (app.current as App).getHighestZIndex() + 1;
		const { canvasId } = params;
		const { stageOrDashboard } = params;

		// generating payload for add nodes action
		const payload: TAddNodesArg = {
			data: {
				nodes: [
					{
						...nodeData,
						createdBy: user._id as string,
						lastUpdatedBy: user._id as string,
						version: 0,
						_id: uuidv1()
					}
				],
				blockId: canvasId,
				projectId: project!._id as string,
				stageId: stageOrDashboard as string
			},
			prevState: {
				prevNodes: allNodes,
				prevBlock: allBlocks[canvasId as string] as IBlock
			},
			next: () => {
				nodeIdRef.current = [...nodeIdRef.current, nodeData._id as string];
				if (app.current) app.current.hasCanvasUpdatedForThumbnail = true;
				setTimeout(() => {
					(app.current as App).is3DSnapshot = false;
				});
			}
		};
		// add nodes dispatch call
		await (store.dispatch as CustomDispatch)(addNodes(payload))
			.unwrap()
			.then((response?: { nodes?: INode[] }) => {
				if (response?.nodes) {
					if (response?.nodes?.length > 1) {
						const data: Array<{
							prevNodeData: INode | IFeedback;
							newNodeData: INode | IFeedback;
						}> = [];

						response?.nodes?.forEach((node: INode) => {
							if (node) {
								data.push({
									prevNodeData: node,
									newNodeData: { ...node, isVisible: false }
								});
							}
						});

						addBatchUndoActions(EActionType.NEW_NODE, data);
					} else {
						undoAction(
							EActionType.NEW_NODE,
							{ ...(response?.nodes![0] as INode), isVisible: false },
							response?.nodes[0] as INode,
							false
						);
					}
				}
			})
			.catch((error) => {
				console.error('Error : ', error);

				const snackbarPayload: ISnackBar = {
					text: 'Failed to upload files.',
					show: true,
					type: 'ERROR'
				};

				addSnackbar(snackbarPayload);
				removeSnackbar(2000);
			});
	};

	const addImage = async (
		url: string,
		width: number,
		type: string,
		height: number,
		worldX?: number,
		worldY?: number
	) => {
		const data = {
			nodeType: type,
			isVisible: true,
			absoluteBounds: {
				x: worldX || window.innerWidth / 2,
				y: worldY || window.innerHeight / 2,
				width,
				height
			},
			image: {
				src: url,
				alternateURLs: {
					original: url
				},
				displayImageType: 'ORIGINAL'
			},
			opacity: 1
		};
		await addImageToCanvas(data);
	};

	const upload2dFiles = () => {
		let fileUploaded = 0;
		const bound = (app.current as App).viewport.getVisibleBounds();
		const xLimit =
			bound.x + bound.width < (app.current as App).viewport.screenWidth
				? bound.x + bound.width
				: (app.current as App).viewport.screenWidth;
		for (let i = 0; i < uploads2dData.length; i++) {
			const data = uploads2dData[i];
			const imageWidth = data.width > data.height ? 315 : 320 * (data.width / data.height);
			const imgHeight = data.height > data.width ? 315 : (data.height / data.width) * 315;
			setGridPosition(imageWidth / 2, imgHeight / 2);
			let { xPosition, yPosition } = gridPositionWrap.current;
			const { maxHeight } = gridPositionWrap.current;
			addImage(data.url, data.width, 'IMAGE', data.height, xPosition, yPosition);
			let maxH = maxHeight > imgHeight ? maxHeight : imgHeight;
			if (xPosition + imageWidth >= xLimit) {
				xPosition = bound.x + 25;
				yPosition += maxH;
				maxH = 0;
			} else {
				xPosition += imageWidth;
			}
			gridPositionWrap.current = { xPosition, yPosition, maxHeight: maxH };
			fileUploaded += 1;
		}
		placeHolderData.forEach((data: any) => {
			const oImage = new FilePlaceholder(app.current as App, data);
			oImage.save();
		});
		if (threeDfileCount.current === 0 && uploads2dData.length === fileUploaded) {
			let msg = 'File Added';
			if (isSnapshot.current) {
				msg = 'Snapshot Added';
			} else if (twoDfileCount.current > 1) {
				msg = 'Files Added';
			}

			const payload: ISnackBar = {
				text: msg,
				show: true,
				type: 'NORMAL'
			};

			setSnackbarMsg(msg);
			addSnackbar(payload);
			removeSnackbar(snackbarTimeout, () => setSnackbarMsg(''));

			cleanup();
		}
	};

	const uploadOnCanvasClick = async (e: any) => {
		const appClone = app.current as App;
		if (!placeAllClicked.current && !canvasClicked.current && appClone.showFilesHover) {
			canvasClicked.current = true;
			timeout.current = null;
			const { x: worldX, y: worldY } = appClone.viewport.toLocal(e.data.global);
			if (uploads2dDataRefs.current.length > 0) {
				const { url, width, height } = uploads2dDataRefs.current[0];
				appClone.upload2dFiles = appClone.upload2dFiles.slice(1);
				setuploads2dData(uploads2dDataRefs.current.slice(1));
				addImage(url, width, 'IMAGE', height, worldX, worldY);
				if (
					appClone.upload2dFiles.length === 0 &&
					appClone.upload3dFiles.length === 0 &&
					appClone.uploadPlaceholderFiles.length === 0
				) {
					appClone.showFilesHover = false;
					let msg = 'File Added';
					if (isSnapshot.current) {
						msg = 'Snapshot Added';
					} else if (twoDfileCount.current > 1) {
						msg = 'Files Added';
					}

					const payload: ISnackBar = {
						text: msg,
						show: true,
						type: 'NORMAL'
					};

					setSnackbarMsg(msg);
					addSnackbar(payload);
					removeSnackbar(snackbarTimeout, () => setSnackbarMsg(''));

					appClone.globalFilesDnD = true;
					// !appClone.is3DSnapshot && appClone.setActiveTool("SELECT");
					appClone.viewport.off('click', uploadOnCanvasClick);
				}
			} else if (placeHolderDataRef.current.length > 0) {
				const nodeData = placeHolderDataRef.current[0];
				nodeData.absoluteBounds.x = worldX;
				nodeData.absoluteBounds.y = worldY;
				addImageToCanvas(nodeData);
				setPlaceholderData(placeHolderDataRef.current.slice(1));
				setuploads2dData(uploads2dDataRefs.current.slice(1));
				appClone.upload2dFiles = appClone.upload2dFiles.slice(1);
				appClone.uploadPlaceholderFiles = appClone.uploadPlaceholderFiles.slice(1);
				if (
					placeHolderDataRef.current.length === 0 &&
					appClone.upload3dFiles.length === 0
				) {
					appClone.showFilesHover = false;
					setSnackbarMsg('');
					appClone.globalFilesDnD = true;
					// appClone.setActiveTool("SELECT");
					appClone.viewport.off('click', uploadOnCanvasClick);
				}
			} else if (uploads3dDataRefs.current.length > 0) {
				const index = threeDfileCount.current - uploads3dDataRefs.current.length;
				const cId = canvasIdRef.current as string;
				await add3DModels(uploads3dDataRefs.current[0], index, worldX, worldY, cId);
				setuploads3dData(uploads3dDataRefs.current.slice(1));
				uploads3dDataRefs.current = uploads3dDataRefs.current.slice(1);
				appClone.upload3dFiles = appClone.upload3dFiles.slice(1);
				if (appClone.upload3dFiles.length === 0) {
					appClone.showFilesHover = false;
					appClone.globalFilesDnD = true;
					// appClone.setActiveTool("SELECT");
					appClone.viewport.off('click', uploadOnCanvasClick);
				}
			}
			canvasClicked.current = false;
		}
	};

	const onPlaceAll = () => {
		const appClone = app.current as App;
		appClone.viewport.off('click', uploadOnCanvasClick);
		placeAllClicked.current = true;
		appClone.globalFilesDnD = true;
		appClone.showFilesHover = false;
		timeout.current = null;
		// !appClone.is3DSnapshot && appClone.setActiveTool("SELECT");
		upload3dFiles();
		upload2dFiles();
	};

	const onPlaceAllClick = () => {
		const bound = (app.current as App).viewport.getVisibleBounds();
		gridPositionWrap.current = {
			xPosition: bound.x + 25,
			yPosition: bound.y + (1 / (app.current as App).viewport.scale._y) * 50
		};
		onPlaceAll();
	};

	const snackbarActionData = [
		{
			buttonData: 'Cancel',
			onClick: () => setUploadingCancelled(true),
			className: 'snackbar-cancel',
			show: true
		},
		{
			buttonData: 'Place All',
			onClick: onPlaceAllClick,
			className: 'snackbar-gothere',
			show: true
		}
	];

	const uploadSnapshot = (snapUrls: string[], snapNames: string[]) => {
		const metadata = {
			contentType: 'image/webp'
		};
		if (!stopDragDrop) {
			cleanup();
			(app.current as App).is3DSnapshot = true;
			twoDfileCount.current = snapNames.length;
			isSnapshot.current = true;

			setSnackbarMsg('Adding Snapshot');
			const payload: ISnackBar = {
				text: 'Adding Snapshot',
				show: true,
				type: 'LOADER',
				loaderColor: 'var(--theme-color-1)'
			};
			addSnackbar(payload);

			for (let i = 0; i < snapNames.length; i++) {
				const storageRef = ref(storage, `modelSnaps/${snapNames[i]}.webp`);
				const uploadTask = uploadString(storageRef, snapUrls[i]!, 'data_url', metadata);
				uploadStorageRefs.current = [...uploadStorageRefs.current, uploadTask];
				uploadTask.then(
					() => {
						getDownloadURL(storageRef).then((downloadURL) => {
							const img = new Image();
							img.onload = () => {
								setuploads2dData((prev: any) => [
									...prev,
									{
										url: downloadURL,
										width: img.width,
										height: img.height
									}
								]);
							};
							img.src = downloadURL;
						});
					},
					() => {
						if (!isUploadingCancelled) {
							const payload: ISnackBar = {
								text: 'Failed to upload. Please try again!',
								show: true,
								type: 'ERROR'
							};

							setSnackbarMsg('File failed to upload. Please try again');
							addSnackbar(payload);
							removeSnackbar(snackbarTimeout, () => setSnackbarMsg(''));
						}
						(app.current as App).is3DSnapshot = false;
					}
				);
			}
		}
	};

	/**
	 * TODO: Do we need this?
	 */
	const extractPixiApp = (app1: App) => {
		app.current = app1;
		app.current.upload3DSnapshot = uploadSnapshot;
	};

	const uploadfilesFromOtherSource = (file: any, absBounds: TAbsoluteBoundingBox) => {
		if (!stopDragDrop) {
			cleanup(true);
			threeDfileCount.current = 0;
			twoDfileCount.current = 1;
			(app.current as App).globalFilesDnD = false;
			timeout.current = new Date(Date.now() - 20000);

			setSnackbarMsg('Adding Files');
			const payload: ISnackBar = {
				text: 'Adding Files',
				show: true,
				type: 'LOADER',
				loaderColor: 'var(--theme-color-1)'
			};

			addSnackbar(payload);

			const dateFileName = `${Date.now()}.${file.name.split('.').pop()}`;
			const storageRef = ref(storage, `${dateFileName}`);
			const uploadTask = uploadBytesResumable(storageRef, file);
			uploadStorageRefs.current = [...uploadStorageRefs.current, uploadTask];
			uploadTask.on(
				'state_changed',
				() => {},
				() => {
					if (!isUploadingCancelled) {
						const payload: ISnackBar = {
							text: 'Failed to upload. Please try again!',
							show: true,
							type: 'ERROR'
						};

						setSnackbarMsg('File failed to upload. Please try again');
						addSnackbar(payload);
						removeSnackbar(snackbarTimeout, () => setSnackbarMsg(''));
					}
				},
				() => {
					getDownloadURL(storageRef).then((downloadURL: string) => {
						gridPositionWrap.current = {
							xPosition: absBounds.x,
							yPosition: absBounds.y,
							alreadySet: true
						};
						const img = new Image();
						img.onload = () => {
							setuploads2dData((prev: any) => [
								...prev,
								{
									url: downloadURL,
									width: img.width,
									height: img.height
								}
							]);
						};
						img.src = downloadURL;
					});
				}
			);
		}
	};
	/**
	 * Handle Undo/Redo the Journey spacw
	 */
	const handleUndoRedo = (action: TAction, isUndoRedo: boolean = false) => {
		switch (action.type) {
			case 'ADD': {
				if (!isUndoRedo) {
					const { payload } = action;
					setUndoStack((prevUndoStack) => {
						const newUndoStack = [...prevUndoStack, payload];
						if (newUndoStack.length > MAX_STACK_LENGTH) {
							newUndoStack.shift();
						}
						return newUndoStack;
					});
					setRedoStack([]);
				}
				break;
			}
			case 'UNDO': {
				setUndoStack((prevUndoStack) => {
					if (prevUndoStack.length > 0) {
						const lastUndoAction = prevUndoStack[prevUndoStack.length - 1];
						const payload = lastUndoAction.oppositeActionPayload;
						if (Array.isArray(payload)) {
							lastUndoAction.oppositeAction(...payload, true);
						} else {
							lastUndoAction.oppositeAction(payload, true);
						}
						setRedoStack((prevRedoStack) => [...prevRedoStack, lastUndoAction]);
						return prevUndoStack.slice(0, -1); // Remove the last item from undoStack
					}
					return prevUndoStack;
				});
				break;
			}

			case 'REDO': {
				setRedoStack((prevRedoStack) => {
					if (prevRedoStack.length > 0) {
						const lastRedoAction = prevRedoStack[prevRedoStack.length - 1];
						const payload = lastRedoAction.originalActionPayload;
						if (Array.isArray(payload)) {
							lastRedoAction.originalAction(...payload, true);
						} else {
							lastRedoAction.originalAction(payload, true);
						}
						setUndoStack((prevUndoStack) => [...prevUndoStack, lastRedoAction]);
						return prevRedoStack.slice(0, -1); // Remove the last item from redoStack
					}
					return prevRedoStack;
				});
				break;
			}

			default:
				break;
		}
	};

	// Function to handle using keyboard buttons to undo/redo in the journey space and the
	// expanded state
	const handleKeyDown = useCallback(
		(event: KeyboardEvent) => {
			if ((event.ctrlKey || event.metaKey) && (event.key === 'y' || event.key === 'z')) {
				event.preventDefault();

				if (event.key === 'y') {
					handleUndoRedo({ type: 'REDO' });
				} else if (event.key === 'z') {
					handleUndoRedo({ type: 'UNDO' });
				}
			}
		},
		[handleUndoRedo]
	);

	// Conditionally enable undo-redo
	useEffect(() => {
		if (flags?.isUndoRedoEnabled && !Object.keys(searchFilters).length) {
			window.addEventListener('keydown', handleKeyDown);
			return () => {
				window.removeEventListener('keydown', handleKeyDown);
			};
		}
		return () => {}; // Return an empty function when the condition is false
	}, [flags, searchFilters]);

	// Runs on mount
	// if has guest and no user, check for the guest session

	useEffect(() => {
		if (isUploadingCancelled) {
			if (uploadStorageRefs.current && uploadStorageRefs.current.length > 0) {
				uploadStorageRefs.current.forEach((storageRef) => {
					storageRef.cancel();
				});
				// deleteModel();

				const payload: ISnackBar = {
					text: 'Upload cancelled!',
					show: true,
					type: 'ERROR'
				};

				setSnackbarMsg('Upload cancelled!');
				addSnackbar(payload);
				removeSnackbar(snackbarTimeout, () => setSnackbarMsg(''));

				cleanup();
			} else {
				cleanup();

				const payload: ISnackBar = {
					text: 'Upload cancelled!',
					show: true,
					type: 'ERROR'
				};

				setSnackbarMsg('Upload cancelled!');
				addSnackbar(payload);
				removeSnackbar(snackbarTimeout, () => setSnackbarMsg(''));
			}
			(app.current as App).is3DSnapshot = false;
		}
	}, [isUploadingCancelled]);

	useEffect(() => {
		canvasIdRef.current = params.canvasId;
	}, [params.canvasId]);

	useEffect(() => {
		const appClone = app.current as App;
		if (uploads3dData.length > 0 || uploads2dData.length > 0 || placeHolderData.length > 0) {
			if (
				uploads3dData.length === threeDfileCount.current &&
				uploads2dData.length === twoDfileCount.current &&
				placeHolderData.length >= 0
			) {
				const bound = (app.current as App).viewport.getVisibleBounds();
				const { isCentred, alreadySet } = gridPositionWrap.current;
				if (!['SELECT', '3D', 'IMAGE'].includes(appClone.activeTool)) {
					if (!alreadySet) {
						gridPositionWrap.current = {
							xPosition: bound.x + 25,
							yPosition: bound.y + (1 / (app.current as App).viewport.scale._y) * 50,
							isCentred
						};
					}
					onPlaceAll();
				} else if (
					timeout.current &&
					(new Date().getTime() - timeout.current.getTime()) / 1000 > 15
				) {
					if (!alreadySet) {
						gridPositionWrap.current = {
							xPosition: bound.x + 25,
							yPosition: bound.y + (1 / (app.current as App).viewport.scale._y) * 50,
							isCentred
						};
					}
					onPlaceAll();
				} else {
					appClone.showFilesHover = true;
					const payload: ISnackBar = {
						text: 'Click or drag to place files',
						show: true,
						type: 'NORMAL',
						actionButtonData: snackbarActionData
					};
					setSnackbarMsg('Click or drag to place files');

					addSnackbar(payload);
					appClone.viewport.on('click', uploadOnCanvasClick);
				}
			}
		}
		uploads2dDataRefs.current = uploads2dData;
		uploads3dDataRefs.current = uploads3dData;
		placeHolderDataRef.current = placeHolderData;
		if (appClone) {
			appClone.upload2dFiles = uploads2dData;
			appClone.upload3dFiles = uploads3dData;
			appClone.uploadPlaceholderFiles = placeHolderData;
		}
	}, [uploads3dData, uploads2dData, placeHolderData]);

	const threeDUploadFlow = (acceptedFiles: any) => {
		const payload: ISnackBar = {
			text: 'Adding Files',
			show: true,
			type: 'LOADER',
			loaderColor: 'var(--theme-color-1)'
		};
		setSnackbarMsg('Adding Files');

		addSnackbar(payload);

		acceptedFiles.forEach(async (file: any) => {
			const dateFileName = `${Date.now()}_${file.name.split('.')[0]}`; // assuming to be unique
			if ('caches' in window) {
				const appCache = await caches.open('naya-app');
				const dataResponse = new Response(file);
				await appCache.put(dateFileName, dataResponse);
				setuploads3dData((prev: any) => [
					...prev,
					{
						name: `${dateFileName}`,
						originalFile: file
					}
				]);
			}
		});
	};

	const onImageLoadError = (file: any, url: string) => {
		twoDfileCount.current--;
		const nodeData1: INode = {
			nodeType: 'FILE_PLACEHOLDER',
			absoluteBounds: {
				x: window.innerWidth / 2,
				y: window.innerHeight / 2,
				height: 150,
				width: 150
			},
			scale: {
				scaleX: 1,
				scaleY: 1
			},
			isVisible: true,
			fill: {
				fillColor: '#EDE5F8'
			},
			image: {
				src: documentIcon
			},
			text: {
				// value: meta.name
				value:
					file.name.length > 15
						? `${file.name.substr(0, 15)}...${file.name.split('.').pop()}`
						: file.name
			},
			additionalProperties: {
				downloadLink: url
			},
			zIndex: (app.current as App).getHighestZIndex() + 1
		};
		setPlaceholderData((prev: any) => [...prev, nodeData1]);
	};

	const twoDUpload = (acceptedFiles: any) => {
		for (let i = 0; i < acceptedFiles.length; i++) {
			const file = acceptedFiles[i];
			if (file) {
				const dateFileName = `${Date.now() + i}.${file.name.split('.').pop()}`;
				const payload: ISnackBar = {
					text: 'Adding Files',
					show: true,
					type: 'LOADER',
					loaderColor: 'var(--theme-color-1)'
				};
				setSnackbarMsg('Adding Files');

				addSnackbar(payload);

				const storageRef = ref(storage, `${dateFileName}`);
				const uploadTask = uploadBytesResumable(storageRef, file);
				uploadStorageRefs.current = [...uploadStorageRefs.current, uploadTask];
				uploadTask.on(
					'state_changed',
					() => {},
					() => {
						if (!isUploadingCancelled) {
							const payload: ISnackBar = {
								text: 'Failed to upload. Please try again!',
								show: true,
								type: 'ERROR'
							};

							setSnackbarMsg('Failed to upload. Please try again!');
							addSnackbar(payload);
							removeSnackbar(snackbarTimeout, () => setSnackbarMsg(''));
						}
					},
					() => {
						getDownloadURL(storageRef).then((url) => {
							getMetadata(storageRef).then(async (data) => {
								if (data.contentType?.includes('image')) {
									const img = new Image();
									img.onload = () => {
										setuploads2dData((prev: any) => [
											...prev,
											{
												url,
												width: img.width,
												height: img.height
											}
										]);
									};
									img.onerror = () => {
										onImageLoadError(file, url);
									};
									img.src = url;
								} else if (
									data.contentType?.includes('pdf') ||
									file.name.split('.').pop() === 'heic'
								) {
									const { projectId } = generateIdsFromUrl();
									const payload = {
										link: url,
										projectId
									};
									const response = await axios.post(
										PDF_TO_IMAGE_ENDPOINT,
										payload
									);
									if (response.data && response.data.payload) {
										twoDfileCount.current =
											twoDfileCount.current +
											response.data.payload.length -
											1;
										const promises = [];
										for (let j = 0; j < response.data.payload.length; j++) {
											promises.push(
												new Promise((resolve) => {
													const img = new Image();
													img.onload = () => {
														resolve({
															url: response.data.payload[j].link,
															width: img.width,
															height: img.height
														});
													};
													img.src = response.data.payload[j].link;
												})
											);
										}
										const promisesResponse = await Promise.all(promises);
										setuploads2dData([...uploads2dData, ...promisesResponse]);
									}
								} else {
									twoDfileCount.current--;
									const nodeData1: INode = {
										nodeType: 'FILE_PLACEHOLDER',
										absoluteBounds: {
											x: window.innerWidth / 2,
											y: window.innerHeight / 2,
											height: 150,
											width: 150
										},
										scale: {
											scaleX: 1,
											scaleY: 1
										},
										isVisible: true,
										fill: {
											fillColor: '#EDE5F8'
										},
										image: {
											src: documentIcon
										},
										text: {
											// value: meta.name
											value:
												file.name.length > 15
													? `${file.name.substr(0, 15)}...${file.name
															.split('.')
															.pop()}`
													: file.name
										},
										additionalProperties: {
											downloadLink: url
										},
										zIndex: (app.current as App).getHighestZIndex() + 1
									};
									setPlaceholderData((prev: any) => [...prev, nodeData1]);
								}
							});
						});
					}
				);
			}
		}
	};

	const onDropFilesInProjectPanel = (threeDFiles: any, twoDFiles: any) => {
		cleanup(true);
		threeDfileCount.current = threeDFiles.length;
		twoDfileCount.current = twoDFiles.length;
		if (threeDFiles.length || twoDFiles.length) {
			(app.current as App).globalFilesDnD = false;
			timeout.current = new Date();
		}
		if (threeDFiles.length > 0) {
			threeDUploadFlow(threeDFiles);
		}
		if (twoDFiles.length > 0) {
			twoDUpload(twoDFiles);
		}
	};

	/**
	 * Function to notify is the canvas is zoomed or panned --> to re-arrange the
	 */
	const notifyZoom = () => {
		if (expandedViewRef.current) {
			const { setRenderFBS } = expandedViewRef.current;
			if (setRenderFBS) {
				setRenderFBS((renderFBS: boolean) => !renderFBS);
			}
		}
	};

	/** Take screenshot of the canvas using pixi method
	 * and upload it to the thumbnail bucket */
	const createThumbnailOfPage = async (canvasIdVal: string) => {
		try {
			/** Take screenshot only if something has been changed on the canvas */
			if (
				app.current &&
				app.current.hasCanvasUpdatedForThumbnail &&
				!block?.thumbnail?.isCustom
			) {
				/** If canvas has children, then update the thumbnail, else remove the thumbnail */
				if (app.current.viewport.children[1]) {
					app.current.removeTransformer();
					app.current.viewport.setZoom(0.5, true); // Setting the zoom to 50% to ensure proper quality of thumbnail
					const b64Data = app.current.getCanvasBlob();
					if (b64Data) {
						const contentType = 'image/png';
						const blob = base64StringToBlob(
							b64Data.replace(/^[^,]+,/, ''),
							contentType
						);
						const file = new File([blob], 'thumbnail.jpg', {
							type: blob.type
						});
						if (app.current) {
							app.current.hasCanvasUpdatedForThumbnail = false;
							app.current.viewport.setZoom(4, true);
							app.current.zoomPercent = 4;
							app.current.initialZoom();
						}
						const dateFileName = `${canvasIdVal}.${file.name.split('.').pop()}`;
						const storageRef = ref(storage, `${dateFileName}`);
						const uploadTask = uploadBytesResumable(storageRef, file);
						uploadTask.then(() => {
							getDownloadURL(storageRef)
								.then((url: string) => {
									// generating payload for edit block action
									const apiPayload: TEditBlockArgs = {
										data: {
											block: {
												thumbnail: {
													src: url,
													originalSrc: url,
													isCustom: false
												},
												_id: canvasIdVal
											},
											...generateIdsFromUrl()
										},
										prevState: {
											prevStateBlock: allBlocks[
												canvasIdVal as string
											] as IBlock
										}
									};
									// edit block dispatch
									(store.dispatch as CustomDispatch)(editBlock(apiPayload));
								})
								.catch((e: Error) => {
									console.error('upload error', e);
								});
						});
					}
				} else {
					// generating payload for edit block action
					const apiPayload: TEditBlockArgs = {
						data: {
							block: {
								thumbnail: {
									src: '',
									isCustom: false,
									originalSrc: '#FFFFFF'
								},
								_id: canvasIdVal
							},
							...generateIdsFromUrl()
						},
						prevState: {
							prevStateBlock: allBlocks[canvasIdVal as string] as IBlock
						}
					};
					// edit block dispatch
					(store.dispatch as CustomDispatch)(editBlock(apiPayload));
				}
			}
		} catch (error: any) {
			console.error('Error : ', error);
		}
	};

	/**
	 * Callback to handle on saving changed thumbnail
	 * @param blockId id of the block whose thumbnail is changed
	 * @param thumbnail new thumbnail
	 * @param onComplete callback to handle request completion
	 */
	const onThumbnailChange = (
		blockId: string,
		thumbnail: string,
		originalThumbnail: string,
		onComplete: () => void
	) => {
		try {
			const reduxState = store.getState();
			const { blocks } = reduxState;
			const oldBlock = blocks.data[blockId] as IBlock;

			// Construct the action payload
			const actionPayload: TEditBlockArgs = {
				data: {
					block: {
						_id: blockId,
						thumbnail: {
							src: thumbnail,
							isCustom: originalThumbnail !== thumbnail,
							originalSrc: originalThumbnail
						}
					},
					stageId: oldBlock.parentId,
					projectId: oldBlock.projectId
				},
				prevState: {
					prevStateBlock: oldBlock
				}
			};

			/**
			 * Function to update redux state, display snackbar
			 */
			const onSuccess = () => {
				const snackbarPayload: ISnackBar = {
					text: 'Thumbnail changed.',
					show: true,
					type: 'NORMAL'
				};

				addSnackbar(snackbarPayload);
				removeSnackbar(2000);
			};

			/**
			 * Function to display snackbar on edit failure
			 */
			const onFailure = () => {
				const snackbarPayload: ISnackBar = {
					text: 'Failed to change thumbnaiil of block.',
					show: true,
					type: 'ERROR'
				};

				addSnackbar(snackbarPayload);
				removeSnackbar(2000);
			};

			(store.dispatch as CustomDispatch)(editBlock(actionPayload))
				.unwrap()
				.then(() => onSuccess())
				.catch(() => onFailure())
				.finally(() => onComplete());
		} catch (error) {
			console.error('Error editing thumbnail of block : ', error);
		}
	};

	const onBlockShare = () => {
		setSharingDetails({
			projectId: params.projectId,
			phaseId: params.stageOrDashboard,
			blockId: params.canvasId
		});

		setActiveTab(ESharingTabs.BLOCK);
		setShowShareModal(true);
	};

	/**
	 * Function to render nav bar
	 * @returns Navbar component
	 */
	const renderNavBar = () => (
		<NavBar
			setActiveFeedbackId={setActiveFeedbackId}
			setSharingDetails={setSharingDetails}
			setActiveTab={setActiveTab}
			onAIOrganize={onAIOrganize}
		/>
	);

	const goBack = () => {
		const blockId = params.canvasId;
		history.push(`/project/${params.projectId}`);
		createThumbnailOfPage(blockId).then(() => {
			if (app && app.current) {
				app.current.hasCanvasUpdatedForThumbnail = false;
				if (currentBlock?.blockType === 'CANVAS')
					app.current.textInputNode.removeDOMElement();
			}
		});
	};
	/**
	 * Function to decide what happens when someone tries to leave collaboration tool i.e. journey
	 * @param callback What to do on journey unmount
	 */
	const onCollabUnMount = (callback: () => void) => {
		const projectName = project?.projectDetails?.name;
		const isAdmin = user.userType?.includes('ADMIN');
		const isEditor = checkUserAccessLevel(
			project?.users as TUserAndRole[],
			user._id as string,
			['OWNER', 'EDITOR']
		);

		const hasAccess = isAdmin || isEditor;
		const isUntitled = projectName && projectName.toLowerCase() === 'untitled';
		const isTemplate = project?.isTemplate;

		// Fetch ignored ids from local storage
		const ignoredReminderForJourneysString = window.localStorage.getItem(
			'ignored-reminder-for-journeys'
		);
		// Check if journey id exists in local storage's ignored ids
		const isIgnored =
			ignoredReminderForJourneysString &&
			(JSON.parse(ignoredReminderForJourneysString) as string[]).includes(params.projectId);

		if (isUntitled && hasAccess && !isIgnored && !isTemplate) {
			setShowReminderModalDetails({
				show: true,
				onRedirect: callback
			});
		} else callback();
	};

	const renderDashboardOrMilestones = () => {
		const { stageOrDashboard } = params;
		return (
			<CollaborationToolContext.Provider
				value={{
					createdByUsers,
					availableTypes,
					deliverableType,
					searchFilters,
					showShareModal,
					stickyNotes,
					isGuestUser,
					redirectModalDetails,
					isUndoActive: undoStack.length > 0,
					isRedoActive: redoStack.length > 0,
					pendingUsersId,

					setRedirectModalDetails,
					onDropFilesInProjectPanel,
					uploadfilesFromOtherSource,
					cancelUploading: () => setUploadingCancelled(true),
					setShowShareModal,
					setSearchFilters,
					setCreatedByUsers,
					setAvailableTypes,
					setDeliverableType,
					setStickyNotes,
					handleUndoRedo,
					goBack,
					onCollabUnMount,
					handleMultiselectAIWrapper,
					updateMultiselectAiProgressSnackbar,
					handleRegenerate,
					handleReplaceOlder
				}}
			>
				{renderNavBar()}
				{!stageOrDashboard || !mongoose.Types.ObjectId.isValid(stageOrDashboard) ? (
					<JourneyContainer
						setActiveTab={setActiveTab}
						setSharingDetails={setSharingDetails}
						setEditThumbnailDetails={setEditThumbnailDetails}
						onGenerateWithAI={onGenerateWithAI}
						onAIRename={onAIRename}
						onAISuggestion={onAISuggestion}
						onAIOrganize={onAIOrganize}
						handleMultiselectAIWrapper={handleMultiselectAIWrapper}
						flags={flags}
					/>
				) : (
					<ExpandedBlock
						app={app.current}
						currentBlock={blockStatus ? undefined : currentBlock}
						onBlockShare={onBlockShare}
						createThumbnailOfPage={createThumbnailOfPage}
						activeFeedbackId={activeFeedbackId}
						setActiveFeedbackId={setActiveFeedbackId}
						ref={expandedViewRef}
						onGenerateWithAI={onGenerateWithAI}
						flags={flags}
						status={blockStatus}
						query={searchFilters}
						handleUndoRedo={handleUndoRedo}
						isUndoActive={undoStack.length > 0}
						isRedoActive={redoStack.length > 0}
						// setBlockStatus={setBlockStatus}
					>
						<Canvas
							extractPixiApp={extractPixiApp}
							toolComponent={Toolbar}
							width={
								block &&
								(block as ICanvas).bounds &&
								(block as ICanvas).bounds?.width
									? (block as ICanvas).bounds.width
									: 1920
							}
							height={
								block &&
								(block as ICanvas).bounds &&
								(block as ICanvas).bounds?.height
									? (block as ICanvas).bounds.height
									: 1080
							}
							onPlaceAll={onPlaceAll}
							snackbarMsg={snackbarMsg}
							setSnackbarMsg={setSnackbarMsg}
							hideAllMenu={hideAllMenu}
							setHideAllMenu={setHideAllMenu}
							ptMode={ptMode}
							setPTMode={setPTMode}
							createThumbnailOfPage={createThumbnailOfPage}
							notifyZoom={notifyZoom}
						/>
					</ExpandedBlock>
				)}
			</CollaborationToolContext.Provider>
		);
	};

	/**
	 * Function to show template creation successful snackbar
	 */
	const handleTemplateSuccessSnackbar = () => {
		if (sessionStorage.getItem(SessionStorageKeys.TEMPLATE_SUCCESS_SNACKBAR)) {
			sessionStorage.removeItem(SessionStorageKeys.TEMPLATE_SUCCESS_SNACKBAR);
			// Show success snackbar
			addSnackbar({
				text: 'Template project successfully created.',
				show: true
			});
			removeSnackbar(5000);
		}
	};

	useEffect(() => {
		if (dragCounter === 0) {
			setDragFiles(false);
		}
	}, [dragCounter]);

	useEffect(() => {
		if (dragFiles) {
			setDragRandomSentence(
				ranDomSentences[Math.floor(Math.random() * ranDomSentences.length)] as string
			);
		}
	}, [dragFiles]);

	// Function to handle paste
	const handlePaste = useCallback((e: ClipboardEvent) => {
		onPaste(
			e,
			app.current as App,
			onDropFilesInProjectPanel,
			gridPositionWrap,
			timeout,
			onAddBlocks,
			onBlockEdit,
			startUploadOnPaste
		);
	}, []);

	// Re-runs when websocket connection status is changed
	useEffect(() => {
		/**
		 * Function to handle socket status change event
		 * @param event socket event
		 */
		const handleStatusChange = (event: TSocketEvent) => {
			const onJourney = checkIfUserOnJourney();

			if (event.status === 'disconnected' && onJourney)
				informUserToReconnect(
					"You've been disconnected from server, please re-connect to receive or send updates."
				);
			else if (event.status === 'connected' && onJourney) removeSocketConnectionSnackbar();
		};

		/**
		 * Function to initialize observers
		 */
		const initObservers = () => {
			initializeObservers({
				project: true,
				stages: true,
				blocks: true,
				notes: true,
				feedbacks: true,
				nodes: true,
				projectUsers: true,
				syncStorage: true
			});

			initializeLocalStorageBroadcast();

			// Load project users to map after observers are started
			const { projectUsers } = store.getState();
			insertUsersToProjectUsersYMap(projectUsers.data);
		};

		if (wsProvider.current) {
			wsProvider.current.on('sync', initObservers);
			wsProvider.current.on('status', handleStatusChange);
			wsProvider.current.awareness.on('change', highlightObserver);
		}

		return () => {
			if (wsProvider.current) {
				wsProvider.current.off('sync', initObservers);
				wsProvider.current.off('status', handleStatusChange);
				wsProvider.current.awareness.off('change', highlightObserver);
			}
		};
	}, [wsProvider.current, wsProvider.current?.wsconnected]);

	/**
	 * Re-runs when flag value is calculated again based on defined rules
	 */
	useEffect(() => {
		if (flags.isJourneyRtcEnabled && wsProvider.current && !wsProvider.current.wsconnected) {
			wsProvider.current.connect();
			wsProvider.current.connectBc();
		}
	}, [flags.isJourneyRtcEnabled]);

	// Runs when run time config for REACT_APP_IS_TEST_RUN is changed
	// To insert a cursor at cursor location in test runs to more efficiently debug failing tests
	useEffect(() => {
		const isTestRun = window.__RUNTIME_CONFIG__.REACT_APP_IS_TEST_RUN === 'true';

		const onMouseMove = throttle((e: MouseEvent) => {
			const { clientX, clientY } = e;

			const cursor = document.querySelector<HTMLDivElement>('#test-cursor');
			if (cursor) {
				cursor.style.display = 'block';
				cursor.style.left = `${clientX}px`;
				cursor.style.top = `${clientY}px`;
			}
		}, 250);

		if (isTestRun) window.addEventListener('mousemove', onMouseMove);

		return () => {
			if (isTestRun) window.removeEventListener('mousemove', onMouseMove);
		};
	}, [window.__RUNTIME_CONFIG__.REACT_APP_IS_TEST_RUN]);

	useEffect(() => {
		isInitialLoad.current = true;
		const { projectId } = params;
		connectToWebSocket(projectId, flags.isJourneyRtcEnabled);
		wsProvider.current = getWebSocket();
		const doc = getYDoc();
		let highlightTimeoutId: null | NodeJS.Timeout = null;

		loadMiroCanvas();
		document.addEventListener('paste', handlePaste);
		// handler called before unload(refresh)
		const onBeforeUnload = () => {
			isInitialLoad.current = false;
			if (wsProvider.current?.wsconnected) removeHighlightsOfUser();

			// Save project state in indexed db when collaboration tool unmounts
			saveReduxStateInIndexedDB(projectId);
			saveUserInLocalForage(getUserFromRedux());

			// Save group state in indexed db when collaboration tool unmounts
			const reduxState = store.getState();
			const activeGroup = reduxState.projectGroup.data;
			if (activeGroup._id) saveGroupInLocalForage(activeGroup);

			window.sessionStorage.removeItem('journey-scroll');
			window.sessionStorage.removeItem('open-note');
			window.sessionStorage.removeItem('current-node-id');
			window.sessionStorage.removeItem('selected-poll-option-index');
			window.sessionStorage.removeItem('isInteractionDisabled');
			window.sessionStorage.removeItem('filtered-blocks');
		};

		/**
		 * Triggered when user navigates to another tab
		 */
		const onBlur = () => {
			if (!wsProvider.current?.wsconnected) return;

			leftTabOpenFor.current = Date.now();
			highlightTimeoutId = setTimeout(() => {
				removeHighlightsOfUser();
				wsProvider.current?.awareness.setLocalState(null);
				wsProvider.current?.disconnect();
				wsProvider.current?.disconnectBc();
			}, 5 * 60 * 1000);
		};

		/**
		 * Triggered when user opens the tab
		 */
		const onFocus = () => {
			// When user comes back to his screen check if he was connected to socket before or not
			// If he was then display snackbar, (to handle scenario when user was never connected to socket)
			const wasConnectedToSocket = wsProvider.current?.wsLastMessageReceived;

			if (leftTabOpenFor.current && wasConnectedToSocket) {
				const timeAway = Date.now() - leftTabOpenFor.current;
				const minutesAway = timeAway / 60000;

				if (minutesAway > 5) {
					informUserToReconnect(
						"You've been away for more than 5 minutes now, please sync up to make sure you don't loose any changes.",
						'Sync'
					);
				} else if (highlightTimeoutId) {
					clearTimeout(highlightTimeoutId);
				}

				leftTabOpenFor.current = null;
			}
		};

		/**
		 * Triggered when user goes offline
		 */
		const onOffline = () => {
			if (!wsProvider.current?.wsconnected) return;

			const webSocket = wsProvider.current;

			removeHighlightsOfUser();
			webSocket?.awareness.setLocalState(null);

			webSocket?.disconnect();
			webSocket?.disconnectBc();

			// after disconnection in offline mode, the event handler does not listen to it
			// so adding snackbar manually
			informUserToReconnect(
				"You've been disconnected from server, please re-connect to receive or send updates.",
				'Reconnect',
				true
			);
		};

		/**
		 * Function to remove context menu from dom on scroll
		 */
		const onScroll = () => {
			const contextMenu = document.querySelector<HTMLDivElement>(
				'[data-radix-popper-content-wrapper] .context-content'
			);

			if (contextMenu) contextMenu.remove();
		};

		window.addEventListener('beforeunload', onBeforeUnload);
		window.addEventListener('blur', onBlur);
		window.addEventListener('focus', onFocus);
		window.addEventListener('offline', onOffline);
		window.addEventListener('scroll', onScroll);

		return () => {
			isInitialLoad.current = false;
			updateHasGuestAccessTo(false);
			// Save project state in indexed db when hook unmounts
			saveReduxStateInIndexedDB(projectId);

			// Unload the project and users from redux on collab unmount
			// so that when collab is mounted it will load the project to redux again from api
			store.dispatch(unloadProject(projectId));
			store.dispatch(unloadStages(projectId));
			store.dispatch(unloadBlocks(projectId));
			store.dispatch(unloadFeedbacks(projectId));
			store.dispatch(unloadNotes(projectId));
			store.dispatch(unloadNodes(projectId));
			store.dispatch(unloadProjectUsers());

			if (wsProvider.current?.wsconnected) {
				if (doc) {
					(doc.getMap('project') as YMap<string>)._eH.l = [];
					(doc.getMap('pathways') as YMap<string>)._eH.l = [];
					(doc.getMap('blocks') as YMap<string>)._eH.l = [];
					(doc.getMap('notes') as YMap<string>)._eH.l = [];
					(doc.getMap('nodes') as YMap<string>)._eH.l = [];
					(doc.getMap('feedbacks') as YMap<string>)._eH.l = [];
					(doc.getMap('projectUsers') as YMap<string>)._eH.l = [];
					stopLocalStorageBroadcast();
				}

				removeHighlightsOfUser();
				wsProvider.current.awareness.setLocalState(null);
				wsProvider.current.disconnect();
				wsProvider.current.disconnectBc();
			}
			document.removeEventListener('paste', handlePaste);
			window.removeEventListener('beforeunload', onBeforeUnload);
			window.removeEventListener('blur', onBlur);
			window.removeEventListener('focus', onFocus);
			window.removeEventListener('offline', onOffline);
			window.removeEventListener('scroll', onScroll);

			window.sessionStorage.removeItem('zoom-meta-data');
			window.sessionStorage.removeItem('journey-scroll');
			window.sessionStorage.removeItem('my-task-dropdown-project');
			window.sessionStorage.removeItem('open-note');
			window.sessionStorage.removeItem('current-node-id');
			window.sessionStorage.removeItem('selected-poll-option-index');
			window.sessionStorage.removeItem('filtered-blocks');
			window.sessionStorage.removeItem(SessionStorageKeys.VERIFIED_BLOCK_IDS);
		};
	}, []);

	useEffect(() => {
		if (!loading) {
			// show the snackbar once the project has been loaded
			handleTemplateSuccessSnackbar();
		}
	}, [loading]);

	/**
	 * Function to get Brain Tool Snackbar's text
	 * @returns Text for Brain Tool Snackbar
	 */
	const getBrainToolText = () => {
		const { error, status } = brainTool;
		if (error && error !== '') {
			return 'Unable to return AI results';
		}
		if (status === 'processing' || status === 'queued' || status === 'starting') {
			return 'Creating AI images';
		}
		return 'AI images have been created';
	};

	/**
	 * Function to get Brain Tool Snackbar's type
	 * @returns Type of Brain Tool Snackbar
	 */
	const getTTISnackBarType = () => {
		const { error, status } = brainTool;
		if (error && error !== '') {
			return 'ERROR';
		}
		if (status === 'processing' || status === 'queued' || status === 'starting') {
			return 'LOADER';
		}
		return 'NORMAL';
	};

	const textToImageAction = [
		{
			buttonData: 'Cancel',
			onClick: () => (store.dispatch as CustomDispatch)(cancelPrediction()),
			className: brainTool.error && brainTool.error !== '' ? 'cancel error' : 'cancel',
			show: brainTool.status !== ''
		},
		{
			buttonData: 'Open AI tool',
			onClick: () => {
				app.current?.setActiveTool('BRAIN');
				app.current?.toggleShowActiveTool(true);
			},
			className: brainTool.error && brainTool.error !== '' ? 'place-all error' : 'place-all',
			show: ['succeeded', 'failed'].includes(brainTool.status)
		}
	];

	const getAccessLevel = () => {
		const { canvasId, stageOrDashboard } = params;
		if (canvasId) {
			return 'Block';
		}
		if (stageOrDashboard) {
			return 'Stage';
		}
		return 'Project';
	};

	// Handle requesting for access, approving or declining a request
	const handleRequestAccess = (type: TRequestType) => {
		const { projectId, canvasId, stageOrDashboard } = params;
		const requestingUserEmail = query.get('requestingUserEmail') || '';
		const requestingUserId = query.get('requestingUserId') || '';
		const thumbnailUrl = validator.isURL(project?.thumbnail?.src || '')
			? project?.thumbnail?.src
			: '';

		switch (type) {
			case 'REQUEST_ACCESS': {
				setIsRequestingAccess(true);
				const actionPayload: TRequestProjectThunkArg = {
					payload: {
						projectId,
						email: user?.email as string,
						role: 'EDITOR',
						projectName: (project?.projectDetails as IProjectDetail)?.name || '',
						username: user?.userName as string,
						userId: user?._id as string,
						projectOwnerId: project?.createdBy as string
					},
					blockId: canvasId,
					stageId: stageOrDashboard,
					requestType: type
				};
				(store.dispatch as CustomDispatch)(requestAccess(actionPayload))
					.unwrap()
					.then(() => {
						setisRequestStatus(true);
						setIsRequestingAccess(false);

						// Track request access event for journey and blocks
						const eventProps: TRequestAcessEventData = {
							elementId: canvasId ?? stageOrDashboard ?? projectId,
							elementType: canvasId.length ? 'BLOCK' : 'JOURNEY'
						};
						trackEvent(CustomEvents.REQUEST_ACCESS, eventProps);
					})
					.catch((error) => {
						addSnackbar({
							text: error || `Error requesting access, Please try again later`,
							show: true
						});
						removeSnackbar(5000);
						setIsRequestingAccess(false);
						throw new Error(error);
					});
				break;
			}
			case 'REQUEST_APPROVED': {
				// Give block access
				if (canvasId) {
					const actionPayload: TAddUserToBlockThunkArgs = {
						data: {
							projectId,
							blockId: canvasId,
							stageId: stageOrDashboard,
							email: requestingUserEmail,
							role: 'EDITOR',
							isInvitedUser: false,
							inviteNote: '',
							isRequestFile: false,
							requestType: type,
							thumbnailUrl
						}
					};

					(store.dispatch as CustomDispatch)(addUserToBlock(actionPayload))
						.unwrap()
						.then(() => {
							addSnackbar({
								text: `Block access approved for ${requestingUserEmail}.`,
								show: true
							});
							removeSnackbar(5000);
							history.replace({
								pathname: history.location.pathname,
								search: ''
							});
						})
						.catch((error) => {
							addSnackbar({
								text: `Failed to provide access to ${requestingUserEmail}. Please try again.`,
								show: true,
								type: 'ERROR'
							});
							removeSnackbar(5000);
							throw new Error(error);
						});
				} else if (stageOrDashboard) {
					// Give phase access
					const { stages, blocks } = getStageAndChildren(stageOrDashboard, 'NORMALIZED');
					const blockIdsInvite: string[] = [];
					const stageIdsInvite: string[] = [];
					blocks.forEach((block) => blockIdsInvite.push(block._id as string));
					stages.forEach((stage) => stageIdsInvite.push(stage._id as string));
					const actionPayload: TAddUserToStageThunkArg = {
						payload: {
							stageIds: stageIdsInvite,
							projectId,
							email: requestingUserEmail,
							role: 'EDITOR',
							override: false,
							isInvitedUser: false,
							inviteNote: '',
							userId: requestingUserId
						},
						blockIds: blockIdsInvite,
						requestType: type
					};

					(store.dispatch as CustomDispatch)(addUserToStage(actionPayload))
						.unwrap()
						.then(() => {
							addSnackbar({
								text: `Group access approved for ${requestingUserEmail}.`,
								show: true
							});
							removeSnackbar(5000);
							convertToValidUrl();
						})
						.catch((error) => {
							addSnackbar({
								text: `Failed to provide access to ${requestingUserEmail}. Please try again.`,
								show: true,
								type: 'ERROR'
							});
							removeSnackbar(5000);
							throw new Error(error);
						});
				} else {
					const projectBlocks = Object.values(allStages).reduce(
						(initialValue, stage) => initialValue.concat(stage?.children as string[]),
						[] as string[]
					);
					const actionPayload: TAddUserToProjectThunkArg = {
						payload: {
							projectId,
							email: requestingUserEmail,
							role: 'EDITOR',
							override: false,
							projectName: project
								? (project?.projectDetails as IProjectDetail).name || ''
								: '',
							username: requestingUserEmail,
							isInvitedUser: false,
							inviteNote: '',
							userId: requestingUserId,
							thumbnailUrl
						},
						blockIds: projectBlocks,
						stageIds: Object.keys(allStages),
						requestType: type
					};

					(store.dispatch as CustomDispatch)(addUserToProject(actionPayload))
						.unwrap()
						.then(() => {
							addSnackbar({
								text: `Project access approved for ${requestingUserEmail}.`,
								show: true,
								type: 'NORMAL'
							});
							removeSnackbar(5000);
							history.replace({
								pathname: history.location.pathname,
								search: ''
							});
						})
						.catch((error) => {
							addSnackbar({
								text: `Failed to provide access to ${requestingUserEmail}. Please try again.`,
								show: true,
								type: 'ERROR'
							});
							removeSnackbar(5000);
							throw new Error(error);
						});
				}
				break;
			}
			case 'REQUEST_DECLINED': {
				const actionPayload: TRequestProjectThunkArg = {
					payload: {
						projectId,
						email: user?.email as string,
						projectName: (project?.projectDetails as IProjectDetail)?.name || '',
						username: user?.userName as string,
						userId: requestingUserId as string,
						projectOwnerId: project?.createdBy as string
					},
					blockId: canvasId,
					stageId: stageOrDashboard,
					requestType: type
				};
				(store.dispatch as CustomDispatch)(requestAccess(actionPayload))
					.unwrap()
					.then(() => {
						setisRequestStatus(true);
						addSnackbar({
							text: `${getAccessLevel()} access denied for ${requestingUserEmail}.`,
							show: true
						});
						removeSnackbar(5000);
					})
					.catch((error) => {
						throw new Error(error);
					});
				history.replace({
					pathname: history.location.pathname,
					search: ''
				});
				break;
			}
			default:
				break;
		}
	};

	useEffect(() => {
		if (brainTool.showSnackBar) {
			const payload: ISnackBar = {
				text: getBrainToolText(),
				show: true,
				type: getTTISnackBarType(),
				loaderColor: 'var(--theme-color-1)',
				actionButtonData: textToImageAction
			};

			addSnackbar(payload);
		}
		// else if (!brainTool.showSnackBar && snackBarData.show) {
		// NOTE - removing this for the scenario:
		// Opening of a newly made template project through snackbar removed the Make a Copy Snackbar
		// removeSnackbar(0);
		// }
	}, [brainTool.showSnackBar, brainTool.status]);

	// Function to allow for approving or rejecting access
	const hasRun = useRef(false);
	useEffect(() => {
		const projectStages = project?.children;
		let stageState = false;
		if (projectStages && projectStages.length > 0) {
			projectStages?.forEach((stage) => {
				if (allStages[stage as string]) {
					stageState = true;
				} else {
					stageState = false;
				}
			});
		}
		if (project?._id && !hasRun.current && stageState) {
			hasRun.current = true;
			const requestType = query.get('requestType') || '';
			if (requestType) {
				handleRequestAccess(requestType as TRequestType);
			}
		}
	}, [project?._id, allStages]);

	// Get all users with pending access
	useEffect(() => {
		if (project?.users) {
			const projectUsers = project.users as TUserAndRole[];
			const pendingUserIdList = projectUsers
				.filter((user) => user.status === 'PENDING')
				.map((user) => {
					if (typeof user.user === 'string') {
						return user.user;
					}
					return (user.user as IUser)._id;
				})
				.filter((id): id is string => id !== null);
			setPendingUsersId(pendingUserIdList);
			// If project/block has guest access allow user to access the project/block
			if (pendingUserIdList.includes(user?._id as string) && !hasGuestAccessTo) {
				setAccess('PENDING');
			}
		}
	}, [project?.users, hasGuestAccessTo]);

	// Runs on mount and on prop change
	// set the show guest modal state
	useEffect(() => {
		// Show guest login modal only when they try to add the first comment/feedback.
		setShowGuestAccessModal(
			Boolean(
				newComment.data.initialComment &&
					hasGuestAccessTo &&
					!user._id &&
					flags.isGuestAccessEnabled &&
					status !== 'LOADING'
			)
		);
	}, [newComment, hasGuestAccessTo, user._id, flags.isGuestAccessEnabled]);

	/**
	 * Function to get the error message based on project status and access
	 * @param type TAccess | TProjectStatuses
	 * @returns
	 */
	const getErrorMessage = (type?: TAccess | TProjectStatuses) => {
		switch (type) {
			case 'PROJECT_IS_DELETED': {
				return "Oops! The project you're looking for has been deleted.";
			}
			case 'PROJECT_NOT_FOUND': {
				return "Oops! The project you're looking for couldn't be found.";
			}
			case 'FAILED_TO_LOAD': {
				return 'Oops! Failed to load the project. Please refresh once and try again.';
			}
			case 'PENDING': {
				return <SuccessfulRequest />;
			}
			default:
				return isRequestStatus ? (
					<SuccessfulRequest />
				) : (
					<RequestAccess
						handleRequestAccess={handleRequestAccess}
						isLoading={isRequestingAccess}
					/>
				);
		}
	};

	const getRenderData = () => {
		if (loading) {
			return <AppLoader progress={progress} />;
		}

		const isAccessPendingOrDenied = access && ['DENIED', 'PENDING'].includes(access);
		if (projectStatus || isAccessPendingOrDenied) {
			const errorType = isAccessPendingOrDenied ? access : projectStatus;
			const isUserLoggedIn = user._id && !isGuestUser;
			return (
				<CollaborationToolContext.Provider
					value={{
						createdByUsers,
						availableTypes,
						deliverableType,
						searchFilters,
						showShareModal,
						stickyNotes,
						isGuestUser,
						redirectModalDetails,
						isUndoActive: undoStack.length > 0,
						isRedoActive: redoStack.length > 0,
						pendingUsersId,

						setRedirectModalDetails,
						onDropFilesInProjectPanel,
						uploadfilesFromOtherSource,
						cancelUploading: () => setUploadingCancelled(true),
						setShowShareModal,
						setSearchFilters,
						setCreatedByUsers,
						setAvailableTypes,
						setDeliverableType,
						setStickyNotes,
						handleUndoRedo,
						goBack,
						onCollabUnMount,
						handleMultiselectAIWrapper,
						updateMultiselectAiProgressSnackbar,
						handleRegenerate,
						handleReplaceOlder
					}}
				>
					<div
						className="project-not-found tw-flex tw-items-center tw-justify-center tw-flex-col"
						style={{ width: '100%', height: '100vh' }}
					>
						{renderNavBar()}
						<p>{getErrorMessage(errorType)}</p>
						{errorType !== 'DENIED' && errorType !== 'PENDING' && (
							<Button
								disabled={false}
								onBtnClick={() => {
									if (isUserLoggedIn) {
										history.push('/studio');
									} else window.location.href = '/';
								}}
								text={`Go back to ${isUserLoggedIn ? 'projects' : 'Naya'}`}
								variant="PRIMARY"
								style={{ height: '40px' }}
							/>
						)}
					</div>
				</CollaborationToolContext.Provider>
			);
		}

		const inApplicationImage = (ev: any) => {
			let object = ev.dataTransfer.getData('upload');
			object = JSON.parse(object);
			const cId = params.canvasId as string;
			const appClone = app.current as App;
			const { x: worldX, y: worldY } = appClone.viewport.toLocal({
				x: ev.clientX,
				y: ev.clientY
			});
			if (object.type === 'model') {
				const data = {
					name: `${object.name}.glb`,
					src: object.model,
					extension: 'GLB'
				};
				add3DModels(data, 1, worldX, worldY, cId, false, true);
			} else {
				const src = new DOMParser()
					.parseFromString(ev.dataTransfer.getData('text/html'), 'text/html')
					.getElementsByTagName('img')[0]
					?.getAttribute('src');
				const image = document.querySelector(`img[src="${src}`) as HTMLImageElement;
				const width = image?.naturalWidth;
				const height = image?.naturalHeight;
				if (src) addImage(src, width, object.type, height, worldX, worldY);
			}
		};

		const getStats = (): NayaStats => {
			const blockTypeCounts = Object.values(allBlocks).reduce((allBlockData, block) => {
				const currCount = allBlockData[block.blockType] ?? 0;
				return { ...allBlockData, [block.blockType]: currCount + 1 };
			}, {} as NayaStatsBlockType);

			return {
				noOfBlocks: Object.keys(allBlocks).length,
				noOfPhases: allStages ? Object.keys(allStages).length : 0,
				noOfFeedback: allBlockFeedbacks ? Object.keys(allBlockFeedbacks).length : 0,
				blockTypeCounts
			};
		};

		window.Naya = { getStats, handleUndoRedo };

		// Get interaction flag from session
		const isInteractionDisabled = !!sessionStorage.getItem('isInteractionDisabled');

		return (
			<div style={{ minWidth: '100vw' }}>
				{
					<div id="collaboration-tool" style={{ width: 'inherit' }}>
						{/* If block/project has guest access enabled and user don't exists then show the guest access modal */}
						{showGuestAccessModal && <GuestAccessModal />}
						<div
							className="canvas-toolbar"
							onDragEnter={(e: any) => {
								if (
									(app.current as App)?.globalFilesDnD &&
									e.dataTransfer.items[0].kind === 'file' &&
									!stopDragDrop
								) {
									setDragFiles(true);
									setDragCounter(dragCounter + 1);
								}
							}}
							onDragLeave={() => {
								setDragCounter(dragCounter - 1);
								// setDragFiles(false);
							}}
							onDragOver={(e: any) => {
								e.preventDefault();
							}}
							onDrop={(ev: any) => {
								ev.preventDefault();
								const appClone = app.current as App;
								ev.stopPropagation();
								ev.preventDefault();
								if (appClone?.globalFilesDnD && !stopDragDrop) {
									cleanup(true);
									setDragFiles(false);
									setDragCounter(0);
									if (ev.dataTransfer.items.length) {
										// Use DataTransferItemList interface to access the file(s)
										const allFiles = Object.values(ev.dataTransfer.items)
											.filter((f: any) => f.kind === 'file')
											.map((f: any) => f.getAsFile());
										const threeDFiles = allFiles.filter((f) =>
											threeDModelFormats.includes(
												f?.name.split('.').pop()?.toUpperCase() as string
											)
										);
										const twoDFiles = allFiles.filter(
											(f) =>
												f !== undefined &&
												!threeDModelFormats.includes(
													f?.name
														.split('.')
														.pop()
														?.toUpperCase() as string
												)
										);
										threeDfileCount.current = threeDFiles.length;
										twoDfileCount.current = twoDFiles.length;
										if (threeDFiles.length || twoDFiles.length) {
											appClone.globalFilesDnD = false;
											timeout.current = new Date();
										}
										if (threeDFiles.length > 0) {
											threeDUploadFlow(threeDFiles);
										} else if (twoDFiles.length > 0) {
											twoDUpload(twoDFiles);
										} else {
											inApplicationImage(ev);
										}
									}
								}
							}}
						>
							{dragFiles && (
								<div className="drag-files-overlay">
									<p style={{ fontSize: '36px', fontWeight: '500' }}>
										{' '}
										Drag and drop files here
									</p>
									<p
										style={{
											fontSize: '16px',
											fontWeight: '500',
											marginTop: '-1.5rem'
										}}
									>
										{dragRandomSentence}
									</p>
									<br />
									<p
										style={{
											bottom: '76px',
											position: 'absolute',
											fontSize: '14px'
										}}
									>
										Supported 3D file types: .3dm .3ds .fbx .glb .obj .stl
									</p>
									<p
										style={{
											bottom: '55px',
											position: 'absolute',
											fontSize: '14px'
										}}
									>
										Maximum file size 25 MB
									</p>
								</div>
							)}
							<div
								className={dragFiles ? 'blurred' : ''}
								style={{ backgroundColor: '#E2E2E2' }}
							>
								{renderDashboardOrMilestones()}
							</div>
							<SnackBar
								show={snackBarData.show as boolean}
								snackbarMessage={snackBarData.text as string}
								variant={snackBarData.type as 'LOADER' | 'NORMAL' | 'ERROR'}
								loaderColor={snackBarData.loaderColor}
								actionButtonData={snackBarData.actionButtonData}
								isToolbarAtBottom
							/>
							{showShareModal && (
								<SharingModal
									open={showShareModal}
									selectedTab={activeTab}
									sharingDetails={sharingDetails}
									onClose={() => setShowShareModal(false)}
									variant="DEFAULT"
								/>
							)}
							{editThumbnailDetails?.id && (
								<EditThumbnailModal
									variant="BLOCK"
									id={editThumbnailDetails.id}
									name={editThumbnailDetails.name}
									thumbnail={editThumbnailDetails.thumbnail}
									originalThumbnail={editThumbnailDetails.originalThumbnail}
									isCustom={editThumbnailDetails.isCustom}
									onClose={() => setEditThumbnailDetails(undefined)}
									onThumbnailChange={onThumbnailChange}
								/>
							)}
							{reminderModalDetails && (
								<ReminderModal
									onRedirect={reminderModalDetails.onRedirect}
									onClose={() => setShowReminderModalDetails(null)}
								/>
							)}
							{redirectModalDetails && (
								<RedirectModal
									link={redirectModalDetails.link}
									canSkip={redirectModalDetails.canSkip}
									onCancel={redirectModalDetails.onCancel}
									onClose={() => setRedirectModalDetails(null)}
								/>
							)}
							{deliverableType && project && (
								<Deliverables
									deliverableType={deliverableType}
									projectId={project?._id as string}
									journeyName={
										(project?.projectDetails as IProjectDetail).name ||
										'Untitled'
									}
									setDeliverableType={setDeliverableType}
									searchFilters={searchFilters}
									projectChildren={project.children as string[]}
								/>
							)}
							<Prompt
								when={isInteractionDisabled}
								message={(location) => {
									if (location.pathname.includes(`/project/${params.projectId}`))
										return true;
									return `AI Organize is in progress, are you sure you want to leave the project?`;
								}}
							/>
							{window.__RUNTIME_CONFIG__.REACT_APP_IS_TEST_RUN === 'true' && (
								<div
									id="test-cursor"
									className="tw-absolute tw-hidden tw-w-5 tw-h-5 tw-rounded-full 
									tw-pointer-events-none -tw-translate-x-1/2 -tw-translate-y-1/2"
								/>
							)}
						</div>
					</div>
				}
			</div>
		);
	};

	return <div id="collab-app">{getRenderData()}</div>;
};
