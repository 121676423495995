import * as PIXI from 'pixi.js';
import { INode } from '@naya_studio/types';
import BaseNode from './BaseNode';
import App from '../App';

class Shape extends BaseNode {
  static defaultNodeData: INode = {
    nodeType: 'SHAPE',
    isVisible: true,
    absoluteBounds: {
      x: 0,
      y: 0,
      width: 100,
      height: 100,
    },
    stroke: {
      strokeWidth: 2,
      strokeColor: '#000000',
    },
    fill: {
      fillColor: 'NO_FILL'
    },
  };

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(app: App, nodeData: INode) {
    super(app, nodeData);
  }

  /**
   * Edit the node
   * @param {string} type -- defines the edit type
   * @param data -- data to be changed
   */
  public edit(type: string, data: any) {
    this.prevNodeData = JSON.parse(JSON.stringify(this.nodeData));
    switch (type) {
      case 'ZINDEX':
        this.nodeData.zIndex = data;
        this.draw();
        break;
      case 'FILL':
        if(this.nodeData.fill){
          this.nodeData.fill.fillColor = data;
        }
        this.draw();
        break;
      case 'STROKE_COLOR':
        if (this.nodeData.stroke) {
          this.nodeData.stroke.strokeColor = data;
        }
        this.draw();
        break;
      case 'STROKE_WIDTH':
        if (this.nodeData.stroke) {
          this.nodeData.stroke.strokeWidth = data.strokeWidth;
        }
        this.draw();
        break;
      // Commented because not being used anywhere
      // case 'SHAPE':
      //   this.nodeData.shapeType = data.shapeType;
      //   this.draw();
      //   this.save();
      //   break;
      case 'OPACITY':
        this.nodeData.opacity = data;
        this.draw();
        break;
      case 'FILL_OPACITY':
        if(this.nodeData.fill) {
          this.nodeData.fill.fillOpacity = data;
        };
        this.draw();
        break;
      case 'STROKE_OPACITY':
        if(this.nodeData.stroke) {
          this.nodeData.stroke.strokeOpacity = data;
        }
        this.draw();
        break;
      default:
        break;
    }
  }

  /**
   * Can be used to implement drag to draw functionality
   * @function
   * @param {number} x The x coordinate of the node
   * @param {number} y The y coordinate of the node
   * @param {number} width The width of the node
   * @param {number} height The height of the node
   */
  resize = (x: number, y: number, width: number, height: number) => {
    // this.app.viewport.removeChild(this.displayObject!);
    this.nodeData.absoluteBounds = {
      x, y, width, height,
    };
    this.draw();
  };

  static distance = (p1: any, p2: any) => Math.hypot(p2.x - p1.x, p2.y - p1.y);

  /**
   * Function to apply Opacity to shape based on Shape Type
   */
  applyOpacity = () => {
    const shapeType = this.nodeData.shapeType as string;

    if(['SOLID_LINE', 'DASHED_LINE', 'ARROW'].includes(shapeType)){
      if(this.displayObject) this.displayObject.alpha = this.nodeData?.opacity !== undefined ? this.nodeData.opacity : 1;
    }
    else{
      if(this.displayObject?.children){
        const child = this.displayObject.children[0];
        if(child && !(this.nodeData.stroke?.strokeOpacity || this.nodeData.fill?.fillOpacity)){
          child.alpha = this.nodeData?.opacity !== undefined ? this.nodeData.opacity : 1;
        }
      }
    }
  }

  /**
   * Draw the shape as per the selected shape type
   */
  draw = () => {
    if (this.displayObject) {
      this.app.viewport.removeChild(this.displayObject);
    }
    const shape = new PIXI.Graphics();
    if (this.nodeData) {
      const stroke = JSON.parse(JSON.stringify(this.nodeData.stroke));
      shape.lineStyle(stroke.strokeWidth!, BaseNode.getColorValue(stroke.strokeColor), stroke.strokeOpacity, 1, true);
      shape.beginFill(BaseNode.getColorValue(this.nodeData.fill?.fillColor), this.nodeData?.fill?.fillOpacity);

      shape.scale.set(1);
      shape.zIndex = this.nodeData.zIndex! || 10;
      this.displayObject = shape;
      this.applyOpacity();
      const { absoluteBounds, scale, rotation } = this.nodeData;
      if (scale && absoluteBounds) {
        if (scale.scaleX && absoluteBounds.width) {
          absoluteBounds.width *= scale.scaleX!;
          this.nodeData.scale!.scaleX = 1;
        }
        if (scale.scaleY && absoluteBounds.height) {
          absoluteBounds.height *= scale.scaleY!;
          this.nodeData.scale!.scaleY = 1;
        }
      }
      switch (this.nodeData.shapeType) {
        case 'SOLID_LINE':
          if (this.nodeData.absoluteBounds) {
            shape.lineTo(absoluteBounds?.width!, absoluteBounds?.height!);
            shape.endFill();
            shape.hitArea = new PIXI.Rectangle(
              0,
              0,
              absoluteBounds?.width!,
              absoluteBounds?.height!,
            );
          }
          break;
        case 'ARROW':
          if (absoluteBounds) {
            shape.lineStyle(stroke.strokeWidth!, BaseNode.getColorValue(stroke.strokeColor), 1);
            shape.beginFill(BaseNode.getColorValue(this.nodeData.fill?.fillColor));
            // Adding a straight line
            shape.lineTo(absoluteBounds?.width!, absoluteBounds?.height!);

            // adding arrow
            const smallArrow = new PIXI.Graphics();

            // getting the angle between the current line and user's mouse pointer
            smallArrow.lineStyle(
              stroke.strokeWidth!,
              BaseNode.getColorValue(stroke.strokeColor),
              1,
            );
            let arrowStartY = 0.5;
            switch (stroke.strokeWidth) {
              case 2: arrowStartY = 0.5;
                break;
              case 4: arrowStartY = 1.25;
                break;
              case 6: arrowStartY = 1.75;
                break;
              default:
                break;
            }
            smallArrow.moveTo(0, arrowStartY);
            smallArrow.lineTo(-10, -10);
            smallArrow.moveTo(0, -arrowStartY);
            smallArrow.lineTo(-10, 10);
            smallArrow.x = absoluteBounds?.width!;
            smallArrow.y = absoluteBounds?.height!;
            shape.addChild(smallArrow);

            // setting angle to the small arrow
            const angle = Math.atan2(absoluteBounds.height!, absoluteBounds.width!);
            smallArrow.rotation = angle;

            shape.endFill();
            // TODO: re-check the hitarea -- if implemented correctly
            shape.hitArea = new PIXI.Rectangle(0, 0, shape.width, shape.height);
          }
          break;
        case 'DASHED_LINE':
          if (this.nodeData.absoluteBounds) {
            const p1 = { x: 0, y: 0 };
            const p2 = {
              x: this.nodeData.absoluteBounds.width!,
              y: this.nodeData.absoluteBounds.height!,
            };
            shape.lineStyle(stroke.strokeWidth!, BaseNode.getColorValue(stroke.strokeColor), 1);
            shape.moveTo(p1.x, p1.y).lineTo(p2.x, p2.y);
            const dash = 5;
            const gap = 10;
            const len = Shape.distance(p1, p2);
            const norm = { x: (p2.x - p1.x) / len, y: (p2.y - p1.y) / len };
            shape.lineStyle(stroke.strokeWidth!, 0xffffff, 1);
            shape.moveTo(p1.x, p1.y).lineTo(p1.x + dash * norm.x, p1.y + dash * norm.y);
            let progress = dash + gap;
            while (progress < len) {
              shape.moveTo(p1.x + progress * norm.x, p1.y + progress * norm.y);
              progress += dash;
              shape.lineTo(p1.x + progress * norm.x, p1.y + progress * norm.y);
              progress += gap;
            }
            shape.lineTo(absoluteBounds?.width!, absoluteBounds?.height!);
            shape.endFill();
            shape.hitArea = new PIXI.Rectangle(0, 0, shape.width, shape.height);
          }
          break;
        default:
          break;
      }

      // shape, markup, comment, and reaction should NOT be interactive
      this.displayObject.interactive = this.app.checkIfNodesShouldBeInteractive();
      this.displayObject.setTransform(absoluteBounds?.x, absoluteBounds?.y, 0, 0, rotation);
      this.displayObject.name = 'SHAPE';
      // this.displayObject.on('click', this.onClick);
      if (this._interactive) {
        this.displayObject.on('mousedown', this.onMouseDown);
        this.displayObject.on('mouseup', this.onMouseUp);
        this.displayObject.on('mouseover', this.onMouseOver);
        this.displayObject.on('mouseout', this.onMouseOut);
        this.displayObject.on('rightclick', this.rightClick);
      }
      this.app.viewport.addChild(shape);
    }
  };
}

export default Shape;