import { ActionReducerMapBuilder, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TActionType, TRenewAccessTokenArgs, TRenewAccessTokenFulfill } from 'src/types/argTypes';
import {
	EIntegrationType,
	TIntegrationDetails,
	TIntegrations,
	TIntegrationState
} from '../reducers/root.types';
import { renewGoogleAccessToken, renewMiroAccessToken } from '../reduxActions/integrations';
import {
	getIntegrationAuthFromLocalStrorage,
	saveIntegrationAuthInLocalStrorage
} from '../reduxActions/integrations/utils';

// default access token details for every integration
const defaultTokenData: TIntegrationDetails = {
	access_token: '',
	expires_in: 0,
	createdAt: 0,
	loading: false,
	error: null
};
const initialState: TIntegrationState = {
	[EIntegrationType.MIRO]: getIntegrationAuthFromLocalStrorage('MIRO') || defaultTokenData,
	[EIntegrationType.GOOGLE]: getIntegrationAuthFromLocalStrorage('GOOGLE') || defaultTokenData,
	isIntegrationModalOpen: false, // show/hide app integrations modal
	isSyncStorageModalOpen: false, // show/hide storage apps modal
	isConfirmationModelOpen: false // show/hide storage disconnection confirmation modal
};

const integrationSlice = createSlice({
	name: 'integration',
	initialState,
	reducers: {
		updateIntegration: (
			state: TIntegrationState,
			action: PayloadAction<{
				integrationType: TIntegrations;
				tokenData: TIntegrationDetails;
			}>
		) => {
			const { integrationType, tokenData } = action.payload;
			state[EIntegrationType[integrationType]] = {
				...state[EIntegrationType[integrationType]],
				...tokenData
			};
			saveIntegrationAuthInLocalStrorage(integrationType, tokenData);
		},
		setShowAppIntegrationModal: (
			state: TIntegrationState,
			action: PayloadAction<Partial<boolean>>
		) => {
			const { payload } = action;
			state.isIntegrationModalOpen = payload;
		},
		setShowSyncStorageModal: (
			state: TIntegrationState,
			action: PayloadAction<Partial<boolean>>
		) => {
			const { payload } = action;
			state.isSyncStorageModalOpen = payload;
		},
		setShowConfirmationModal: (
			state: TIntegrationState,
			action: PayloadAction<Partial<boolean>>
		) => {
			const { payload } = action;
			state.isConfirmationModelOpen = payload;
		}
	},
	extraReducers: (builder: ActionReducerMapBuilder<TIntegrationState>) => {
		builder
			/** RENEW GOOGLE ACCESS TOKEN */
			// PENDING
			.addCase(renewGoogleAccessToken.pending, (state: TIntegrationState) => {
				state.google.loading = true;
				state.google.error = null;
			})
			// FULFILLED
			.addCase(
				renewGoogleAccessToken.fulfilled,
				(
					state: TIntegrationState,
					action: PayloadAction<TActionType<TRenewAccessTokenFulfill, {}>['payload']>
				) => {
					state.google.loading = false;
					state.google = { ...state.google, ...action.payload.tokenData };
					saveIntegrationAuthInLocalStrorage('GOOGLE', action.payload.tokenData);
				}
			)
			// REJECT
			.addCase(
				renewGoogleAccessToken.rejected,
				(
					state: TIntegrationState,
					action: PayloadAction<
						unknown,
						string,
						TActionType<{}, TRenewAccessTokenArgs>['rejectedMeta']
					>
				) => {
					state.google = {
						...defaultTokenData,
						loading: false,
						error: action.payload as string
					};
					saveIntegrationAuthInLocalStrorage('GOOGLE', defaultTokenData);
				}
			)
			/** RENEW MIRO ACCESS TOKEN */
			// PENDING
			.addCase(renewMiroAccessToken.pending, (state) => {
				state.miro.loading = true;
				state.miro.error = null;
			})
			// FULFILLED
			.addCase(
				renewMiroAccessToken.fulfilled,
				(
					state: TIntegrationState,
					action: PayloadAction<TActionType<TRenewAccessTokenFulfill, {}>['payload']>
				) => {
					state.miro.loading = false;
					state.miro = { ...state.miro, ...action.payload.tokenData };
					saveIntegrationAuthInLocalStrorage('MIRO', action.payload.tokenData);
				}
			)
			// REJECTED
			.addCase(
				renewMiroAccessToken.rejected,
				(
					state: TIntegrationState,
					action: PayloadAction<
						unknown,
						string,
						TActionType<{}, TRenewAccessTokenArgs>['rejectedMeta']
					>
				) => {
					state.miro = {
						...defaultTokenData,
						loading: false,
						error: action.payload as string
					};
					saveIntegrationAuthInLocalStrorage('MIRO', defaultTokenData);
				}
			);
	}
});
export const {
	updateIntegration,
	setShowAppIntegrationModal,
	setShowSyncStorageModal,
	setShowConfirmationModal
} = integrationSlice.actions;
export default integrationSlice.reducer;
