import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ReduxState } from 'src/redux/reducers/root.types';
import './RemoveBg.scss';
import { ClipLoader } from 'react-spinners';
import { EDisplayImageType, INode, TImage, IBlock } from '@naya_studio/types';
import { css } from '@emotion/react';
import _ from 'lodash';
import { EActionType } from 'src/redux/reducers/undoRedo/undoActionHistory.types';
import { undoAction } from 'src/redux/actions/undoRedoActions';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { generateIdsFromUrl, getNodesBasedOnBlockType } from 'src/redux/reduxActions/util';
import { editNodes, removeImageBackground } from 'src/redux/reduxActions/node';
import { TEditNodesArgs, TRemoveImageBackground } from 'src/types/argTypes';
import { CustomDispatch } from 'src/redux/actions/types';
import { store } from 'src';
import { RemoveBgOptionProps, RemoveBgOptionDispatchProps, PathParamsType } from './RemoveBg.types';
import remove_bg from '../../../../../assets/icons/toolbar/remove_bg.svg';

const override = css`
	display: block;
	margin: 0.5rem;
`;

class RemoveBgOption extends React.Component<
	RemoveBgOptionProps & RemoveBgOptionDispatchProps & RouteComponentProps<PathParamsType>
> {
	// eslint-disable-next-line react/state-in-constructor
	state = {
		imageBackgroundLoader: false
	};

	/**
	 * Removes image background
	 * Or
	 * Toggles between transparent background image and original image
	 * @param e
	 */
	removeImageBackground = async (e: any) => {
		const { app, setSelectedOption, block, user } = this.props;
		const { imageBackgroundLoader } = this.state;
		e.stopPropagation();
		app.isColourPickerOpen = false;
		if (!imageBackgroundLoader) {
			const nodeData = app.selectedNodes[0]?.nodeData as INode;
			const prevNodeData = _.cloneDeep(nodeData);
			const image = nodeData?.image?.src;
			setSelectedOption('REMOVE_BG');
			this.setState({ imageBackgroundLoader: true });

			// If transparent background image doesn't exist - call api to remove transparent background
			if (!nodeData.image?.alternateURLs?.transparentBackground) {
				const apiPayload: TRemoveImageBackground = {
					data: {
						img: image as string,
						nodeData,
						canvasId: block._id as string
					},
					next: () => {
						app.addNodes(getNodesBasedOnBlockType(block), true);
					}
				};
				await (store.dispatch as CustomDispatch)(removeImageBackground(apiPayload));
				app.removeTransformer();
				app.removeAllSelectedNodes();
				app.toggleEditMenu(false);
				this.setState({ imageBackgroundLoader: false });
			} else {
				// Else toggle between the transparent and original image
				const payload = nodeData as INode;
				const imageData = payload.image as TImage;

				if (imageData.displayImageType?.toString() === 'TRANSPARENT_BACKGROUND') {
					imageData.src = imageData.alternateURLs?.original;
					imageData.displayImageType = 'ORIGINAL' as EDisplayImageType;
					payload.image = imageData;
				} else {
					imageData.src = imageData.alternateURLs?.transparentBackground;
					imageData.displayImageType = 'TRANSPARENT_BACKGROUND' as EDisplayImageType;
					payload.image = imageData;
				}

				const { nodes } = this.props;
				const { data } = nodes;
				
				if(payload.version){
					payload.version = payload.version + 1;
				}
				else payload.version = 0;

				payload.lastUpdatedBy = user._id as string

				// generating payload for edit nodes action
				const apiPayload: TEditNodesArgs = {
					data: {
						nodes: [payload],
						...generateIdsFromUrl()
					},
					prevState: {
						prevBlocks: data
					},
					next: () => {
						undoAction('EDIT' as EActionType, prevNodeData, payload, false);
						const blockNodes = getNodesBasedOnBlockType(block);
						app.addNodes(blockNodes, true);
						app.removeTransformer();
						app.toggleEditMenu(false);
						this.setState({ imageBackgroundLoader: false });
					}
				};
				// eslint-disable-next-line react/destructuring-assignment
				this.props.editNodes(apiPayload);
			}
		}
	};

	render() {
		const { imageBackgroundLoader } = this.state;
		const { theme } = this.props;
		return (
			<OverlayTrigger
				placement="bottom"
				delay={{ show: 1000, hide: 0 }}
				overlay={
					<Tooltip
						id="button-tooltip-2"
						style={{
							fontSize: '12px',
							borderRadius: '2px',
							lineHeight: '16px',
							marginTop: '8px'
						}}
					>
						Remove background
					</Tooltip>
				}
			>
				<div
					className="icon-container"
					onClick={this.removeImageBackground}
					role="presentation"
				>
					{imageBackgroundLoader ? (
						<div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
							<ClipLoader color={theme.color} loading css={override} size={25} />
						</div>
					) : (
						<img src={remove_bg} alt="color" className="icon" />
					)}
				</div>
			</OverlayTrigger>
		);
	}
}

/**
 * Redux state mapped to canvas props
 * @param state
 * @returns
 */
// @ts-ignore
const mapStateToProps = (state: ReduxState) => {
	const { blockId } = generateIdsFromUrl();
	const block = state.blocks.data[blockId] as IBlock;
	return {
		block,
		theme: state.theme,
		nodes: state.nodes,
		user: state.user
	};
};

/**
 * Redux actions mapped to canvas props
 * @param dispatch
 * @returns
 */
// @ts-ignore
const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
	editNodes: (payload: TEditNodesArgs) => dispatch(editNodes(payload))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RemoveBgOption));
