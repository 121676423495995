import { FC, ReactElement, useEffect, useRef, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import './EditProfile.scss';
import Select from 'react-select';
import dummyUserIcon from 'src/assets/icons/dummy-user.svg';
import { IUserDetail, IUserDetailNode } from '@naya_studio/types';
import { CustomDispatch } from 'src/redux/actions/types';
import { store } from 'src';
import { storage } from 'src/util/storage/firebaseStorage';
import { SyncLoader } from 'react-spinners';
import moment from 'moment-timezone';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { editUser, editUserDetails } from 'src/redux/actions/user';
import useUser from 'src/redux/hooks/user';
import { EditProfileProps } from './EditProfile.types';

const professionOptions = [
	{ value: 'Project Manager', label: 'Project Manager' },
	{ value: 'Industrial Designer', label: 'Industrial Designer' },
	{ value: 'Designer', label: 'Designer' },
	{ value: 'Fabricator', label: 'Fabricator' },
	{ value: 'Supplier', label: 'Supplier' },
	{ value: 'Engineer', label: 'Engineer' },
	{ value: 'Other', label: 'Other' }
];

const customStyles = {
	menuList: (base: any) => ({
		fontSize: '14px',
		...base
	}),
	option: () => ({
		backgroundColor: 'none',
		padding: '0.5rem',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: '#F4F4F4'
		},
		'&:active': {
			backgroundColor: '#F4F4F4'
		},
		'&:focused': {
			backgroundColor: '#F4F4F4'
		}
		// ...base
	}),

	control: (base: any) => ({
		...base,
		borderColor: '#afafaf',
		boxShadow: 'none',
		'&:hover': {
			borderColor: '#D1D1D1'
		}
	})
};

const EditProfile: FC<EditProfileProps> = ({ show, close }): ReactElement => {
	const inputFile = useRef<HTMLInputElement>(null);
	const [timeZoneData, setTimeZoneData] = useState([]);
	const [profilePicLoading, setProfilePicLoading] = useState(false);
	const [uploading, setUploading] = useState(false);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [languages, setLanguages] = useState('');
	const [whatIdo, setWhatIDo] = useState('');
	const [timeZone, setTimeZone] = useState('');
	const [profilePic, setProfilePic] = useState('');
	const { user } = useUser();

	useEffect(() => {
		const timeZones = moment.tz.names();
		const temp: any = [];
		for (let i = 0; i < timeZones.length; i++) {
			temp.push({
				value: timeZones[i],
				label: timeZones[i]
			});
		}
		setTimeZoneData(temp);
		setFirstName(user.firstName as string);
		setLastName(user.lastName as string);
		setProfilePicLoading(true);
		const allUserDetails = user.userDetails as IUserDetailNode[];
		if (allUserDetails[0]) {
			const details = allUserDetails[0].details as IUserDetail;
			if (details && details.profession) {
				setWhatIDo(details.profession);
			}
			if (details && details.preferredLanguages) {
				setLanguages(details.preferredLanguages);
			}
			if (details && details.timeZone) {
				setTimeZone(details.timeZone);
			}
		}
		// return undefined
		if (user.profilePic) {
			setProfilePic(user.profilePic);
		}
	}, [user]);

	const handleFileUpload = async (e: any) => {
		setProfilePicLoading(true);
		setUploading(true);
		const filesData = e.target.files;
		const dateFileName = `${Date.now() + 0}.${filesData[0].name.split('.').pop()}`;
		const storageRef = ref(storage, `${dateFileName}`);
		await uploadBytesResumable(storageRef, filesData[0]);
		getDownloadURL(storageRef).then((url) => {
			setProfilePic(url);
			setUploading(false);
		});
	};

	const renderUserDetails = () => (
		<div className="user-details">
			<div className="input-container">
				<label>First Name</label>
				<input
					className="text-input"
					type="text"
					placeholder="First Name"
					value={firstName}
					name="firstName"
					onChange={(e) => {
						setFirstName(e.target.value);
					}}
				/>
			</div>
			<div className="input-container">
				<label>Last Name</label>
				<input
					className="text-input"
					type="text"
					placeholder="Last Name"
					value={lastName}
					name="lastName"
					onChange={(e) => {
						setLastName(e.target.value);
					}}
				/>
			</div>
			<div className="input-container">
				<label>What I do</label>
				<Select
					classNamePrefix="react-select"
					options={professionOptions}
					value={professionOptions.find((item) => item.label === whatIdo)}
					placeholder="What I do"
					onChange={(e: any) => {
						setWhatIDo(e.label);
					}}
					styles={customStyles}
				/>
			</div>
			<div className="input-container">
				<label>Time zone</label>
				<Select
					classNamePrefix="react-select"
					options={timeZoneData}
					value={timeZoneData.find((item: any) => item.label === timeZone)}
					placeholder="Time zone"
					onChange={(e: any) => {
						setTimeZone(e.label);
					}}
					styles={customStyles}
				/>
			</div>
			<div className="input-container">
				<label>Preferred Languages</label>
				<input
					className="text-input"
					type="text"
					placeholder="Preferred Languages"
					value={languages}
					name="languages"
					onChange={(e) => {
						setLanguages(e.target.value);
					}}
				/>
			</div>
		</div>
	);

	const renderProfilePicContainer = () => (
		<div className="profile-pic-container">
			<label>Profile photo</label>
			<div className="image-loader-container">
				{profilePicLoading && (
					<div className="loader-background">
						<Spinner animation="border" />
					</div>
				)}
				<img
					referrerPolicy="no-referrer"
					src={profilePic || dummyUserIcon}
					alt="user"
					className="profile-pic"
					onLoad={() => {
						setProfilePicLoading(false);
					}}
				/>
			</div>
			<input
				ref={inputFile}
				onChange={handleFileUpload}
				type="file"
				style={{ display: 'none' }}
				multiple={false}
			/>
			<button
				type="button"
				className="primary-btn secondary-btn"
				onClick={() => {
					if (inputFile.current) {
						inputFile.current.click();
					}
				}}
			>
				{uploading ? <SyncLoader size={5} color="#1b1b1b" /> : 'Upload Photo'}
			</button>
			<button
				type="button"
				className="primary-btn secondary-btn custom-btn"
				onClick={() => {
					setProfilePic('');
				}}
			>
				Remove Photo
			</button>
		</div>
	);

	const getUserDetailsId = () => {
		const allUserDetails = user.userDetails as IUserDetailNode[];
		return allUserDetails[0]?._id;
	};

	const saveChanges = () => {
		const userDetailsPayload = {
			userDetailsId: getUserDetailsId() as string,
			update: {
				details: {
					profession: whatIdo,
					preferredLanguages: languages,
					timeZone
				}
			}
		};

		(store.dispatch as CustomDispatch)(
			editUserDetails({
				data: userDetailsPayload,
				prevState: user.userDetails as IUserDetailNode[]
			})
		);

		const userPayload = {
			profilePic,
			firstName,
			lastName
		};
		const { userDetails, ...previousUserState } = user;
		(store.dispatch as CustomDispatch)(
			editUser({
				data: {
					userId: user._id as string,
					update: { ...userPayload }
				},
				prevState: previousUserState
			})
		);
		close();
	};

	return (
		<Modal show={show} onHide={close} centered size="lg" id="edit-profile-modal">
			<Modal.Header closeButton>
				<Modal.Title>Edit your profile</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="content-container">
					{renderUserDetails()}
					{renderProfilePicContainer()}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button type="button" className="primary-btn secondary-btn" onClick={close}>
					Cancel
				</button>
				<button
					type="button"
					className={profilePicLoading ? 'primary-btn disabled-btn' : 'primary-btn'}
					style={{}}
					onClick={saveChanges}
					disabled={profilePicLoading}
				>
					Save changes
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default EditProfile;
