import { FinishType, SavedColorsType, SavedMaterialsType } from '../prototyping.types';

export const savedMaterialsList: SavedMaterialsType = {
  versatile: {
    value: 'versatile',
    label: 'PA 12(SLS) Versatile plastic',
    name: 'Versatile plastic',
    isDisabled: true,
  },
  tpu: {
    value: 'tpu', label: 'TPU (SLS)', name: 'TPU (SLS)', isDisabled: true,
  },
};

export const savedFinishList: FinishType = {
  versatile: {
    natural: {
      value: 'natural',
      label: 'Natural',
      isDisabled: true,
    },
    smooth: { value: 'smooth', label: 'Smooth', isDisabled: true },
  },
  tpu: {
    natural: {
      value: 'natural',
      label: 'Natural',
      isDisabled: true,
    },
    smooth: { value: 'smooth', label: 'Smooth', isDisabled: true },
  },
};

export const materialsHexColors = {
  25: 0x202020,
  208: 0x180f12,
  236: 0x1675f8,
  240: 0x4ac2a9,
  239: 0xfe8326,
  242: 0xff4a77,
  241: 0x9338c3,
  237: 0xdf372e,
  207: 0xfaf9f5,
  238: 0xfef373,
  6: 0xf1f2ed,
  144: 0xfaf9f5,
};

export const savedColorList: SavedColorsType = {
  versatile: {
    smooth: {
      207: {
        id: '207',
        value: 'white',
        label: 'white',
        isDisabled: true,
        price: 0,
      },
      208: {
        id: '208',
        value: 'black',
        label: 'black',
        isDisabled: true,
        price: 0,
      },
      236: {
        id: '236',
        value: 'blue',
        label: 'blue',
        isDisabled: true,
        price: 0,
      },
      237: {
        id: '237',
        value: 'red',
        label: 'red',
        isDisabled: true,
        price: 0,
      },
      238: {
        id: '238',
        value: 'yellow',
        label: 'yellow',
        isDisabled: true,
        price: 0,
      },
      239: {
        id: '239',
        value: 'orange',
        label: 'orange',
        isDisabled: true,
        price: 0,
      },
      240: {
        id: '240',
        value: 'green',
        label: 'green',
        isDisabled: true,
        price: 0,
      },
      241: {
        id: '241',
        value: 'purple',
        label: 'purple',
        isDisabled: true,
        price: 0,
      },
      242: {
        id: '242',
        value: 'pink',
        label: 'pink',
        isDisabled: true,
        price: 0,
      },
    },
    natural: {
      6: {
        id: '6',
        value: 'white',
        label: 'white',
        isDisabled: true,
        price: 0,
      },
      25: {
        id: '25',
        value: 'black',
        label: 'black',
        isDisabled: true,
        price: 0,
      },
      236: {
        id: '236',
        value: 'blue',
        label: 'blue',
        isDisabled: true,
        price: 0,
      },
      237: {
        id: '237',
        value: 'red',
        label: 'red',
        isDisabled: true,
        price: 0,
      },
      238: {
        id: '238',
        value: 'yellow',
        label: 'yellow',
        isDisabled: true,
        price: 0,
      },
      239: {
        id: '239',
        value: 'orange',
        label: 'orange',
        isDisabled: true,
        price: 0,
      },
      240: {
        id: '240',
        value: 'green',
        label: 'green',
        isDisabled: true,
        price: 0,
      },
      241: {
        id: '241',
        value: 'purple',
        label: 'purple',
        isDisabled: true,
        price: 0,
      },
      242: {
        id: '242',
        value: 'pink',
        label: 'pink',
        isDisabled: true,
        price: 0,
      },
    },
  },
  tpu: {
    smooth: {
      144: {
        id: '144',
        value: 'white',
        label: 'white',
        isDisabled: true,
        price: 0,
      },
      208: {
        id: '208',
        value: 'black',
        label: 'black',
        isDisabled: true,
        price: 0,
      },
      236: {
        id: '236',
        value: 'blue',
        label: 'blue',
        isDisabled: true,
        price: 0,
      },
      237: {
        id: '237',
        value: 'red',
        label: 'red',
        isDisabled: true,
        price: 0,
      },
      238: {
        id: '238',
        value: 'yellow',
        label: 'yellow',
        isDisabled: true,
        price: 0,
      },
      239: {
        id: '239',
        value: 'orange',
        label: 'orange',
        isDisabled: true,
        price: 0,
      },
      240: {
        id: '240',
        value: 'green',
        label: 'green',
        isDisabled: true,
        price: 0,
      },
      241: {
        id: '241',
        value: 'purple',
        label: 'purple',
        isDisabled: true,
        price: 0,
      },
      242: {
        id: '242',
        value: 'pink',
        label: 'pink',
        isDisabled: true,
        price: 0,
      },
    },
    natural: {
      144: {
        id: '144',
        value: 'white',
        label: 'white',
        isDisabled: true,
        price: 0,
      },
      208: {
        id: '208',
        value: 'black',
        label: 'black',
        isDisabled: true,
        price: 0,
      },
      236: {
        id: '236',
        value: 'blue',
        label: 'blue',
        isDisabled: true,
        price: 0,
      },
      237: {
        id: '237',
        value: 'red',
        label: 'red',
        isDisabled: true,
        price: 0,
      },
      238: {
        id: '238',
        value: 'yellow',
        label: 'yellow',
        isDisabled: true,
        price: 0,
      },
      239: {
        id: '239',
        value: 'orange',
        label: 'orange',
        isDisabled: true,
        price: 0,
      },
      240: {
        id: '240',
        value: 'green',
        label: 'green',
        isDisabled: true,
        price: 0,
      },
      241: {
        id: '241',
        value: 'purple',
        label: 'purple',
        isDisabled: true,
        price: 0,
      },
      242: {
        id: '242',
        value: 'pink',
        label: 'pink',
        isDisabled: true,
        price: 0,
      },
    },
  },
};
