import { useEffect, useState } from 'react';
import { TSocketEvent, getWebSocket } from './yjsConfig';

/**
 * Custom hook to manage WebSocket connection state.
 * @returns {boolean} WebSocket connection status.
 */
const useWebSocketConnection = () => {
	const webSocket = getWebSocket();

	// State to manage WebSocket connection status
	const [wsConnected, setWsConnected] = useState<boolean>(false);

	// effect runs only once on mount
	useEffect(() => {
		// Handler function to update connection status
		const statusHandle = (event: TSocketEvent) => {
			// Update connection status based on the event
			setWsConnected(event.status === 'connected');
		};

		// Initial check for connection status and set state accordingly
		if (webSocket?.wsconnected) statusHandle({ status: 'connected' });

		// Subscribe to 'status' event to update connection status
		webSocket?.on('status', statusHandle);

		// Clean-up function to unsubscribe from WebSocket status updates
		return () => {
			// Unsubscribe from 'status' event to avoid memory leaks
			webSocket?.off('status', statusHandle);
		};
	}, []);

	// Return WebSocket connection status
	return wsConnected;
};

export default useWebSocketConnection;
