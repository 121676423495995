import { FC } from 'react';
import { useHistory } from 'react-router';
import { CTAProps } from './CTA.types';
// import { Transition } from 'react-transition-group';
import './CTA.scss';
// import { AuthComponent } from "src/components/authComponent/AuthComponent";
// import { Views } from "src/components/authComponent/AuthComponent.types";

const CTA: FC<CTAProps> = () => {
	// const [login, setlogin] = useState(false)
	// const [view, setView] = useState('LOGIN')
	const history = useHistory();
	return (
		<div id="home-cta" className="tw-flex tw-justify-center tw-items-center tw-gap-2">
			{/* <AuthComponent show={login} close={() => {
        setView('LOGIN');
        setlogin(false);
      }} view={view as Views} /> */}
			{!window.location.pathname.includes('/login') && (
				<div
					className="cta-btn-wrapper login-btn-wrapper"
					role="presentation"
					onClick={() => {
						history.push('/login');
					}}
				>
					<button className="login-btn tw-font-randMedium tw-text-sm" type="button">
						Log In
					</button>
				</div>
			)}
			{!['/scheduleDemo', '/register'].includes(window.location.pathname) && (
				<div
					className="cta-btn-wrapper register-btn-wrapper"
					role="presentation"
					onClick={() => {
						history.push('/register');
					}}
				>
					<button className="register-btn tw-font-randMedium tw-text-sm" type="button">
						Get a demo
					</button>
				</div>
			)}
		</div>
	);
};

export default CTA;
