/* eslint-disable react/jsx-props-no-spreading */
import './View.scss';
import {
  FC, ReactElement, useState, useEffect,
} from 'react';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ViewProps } from './View.types';
import DownArrow from '../../../../../assets/icons/DownArrow.svg';
import BaseNode from 'src/components/canvas/app/nodes/BaseNode';

const View : FC<ViewProps> = ({ app, setSelectedOption, selectedOption }): ReactElement => {
  const [title, setTitle] = useState('Perspective');

  useEffect(() => {
    if(app.selectedNodes.length === 1) {
      const nodeData = app.selectedNodes[0]?.nodeData;
      if (nodeData?.model?.view) {
        setTitle(nodeData?.model?.view);
      }
    }
    else {
      setTitle('Mixed');
    }
  }, []);

  const handleOptions = (view : string) => {
    const allNodes = app.selectedNodes as BaseNode[];
    setTitle(view);
    switch (view) {
      case 'Perspective':
        for(let i = 0; i < allNodes.length; i++) {
          allNodes[i]?.edit('ANGLE', { isPerspective: true, view: 'Perspective' }, false);
        }
        break;
      case 'Top':
        for(let i = 0; i < allNodes.length; i++) {
          allNodes[i]?.edit('ANGLE', { axis: 'y', isPositive: true, view: 'Top' }, false);
        }
        break;
      case 'Front':
        for(let i = 0; i < allNodes.length; i++) {
          allNodes[i]?.edit('ANGLE', { axis: 'z', isPositive: true, view: 'Front' }, false);
        }
        break;
      case 'Left':
        for(let i = 0; i < allNodes.length; i++) {
          allNodes[i]?.edit('ANGLE', { axis: 'x', isPositive: true, view: 'Left' }, false);
        }
        break;
      case 'Right':
        for(let i = 0; i < allNodes.length; i++) {
          allNodes[i]?.edit('ANGLE', { axis: 'x', isPositive: false, view: 'Right' }, false);
        }
        break;
      case 'Back':
        for(let i = 0; i < allNodes.length; i++) {
          allNodes[i]?.edit('ANGLE', { axis: 'z', isPositive: false, view: 'Back' }, false);
        }
        break;
      case 'Bottom':
        for(let i = 0; i < allNodes.length; i++) {
          allNodes[i]?.edit('ANGLE', { axis: 'y', isPositive: false, view: 'Bottom' }, false);
        }
        break;
      default: break;
    }

    app.saveAllNodes([]);
  };

  return (
    <Dropdown
      bsPrefix="View-Dropdown"
      show={selectedOption === 'VIEW'}
      onClick={() => {
        if (selectedOption === 'VIEW') setSelectedOption('');
        else setSelectedOption('VIEW');
      }}
      className='tw-flex'
    >
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 1000, hide: 0 }}
        overlay={(
          <Tooltip
            id="button-tooltip-2"
            style={{
              fontSize: '12px',
              borderRadius: '2px',
              lineHeight: '16px',
              marginTop: '4px',
            }}
          >
            View
          </Tooltip>
          )}
      >
        {({ ref, ...triggerHandler }) => (
          <>
            <Dropdown.Toggle id="View-basic-button" variant="success" ref={ref} {...triggerHandler}>
              {title}
              {' '}
              <img style={{ marginLeft: '4px' }} src={DownArrow} alt="" />
            </Dropdown.Toggle>
            <Dropdown.Menu ref={ref} {...triggerHandler}>
              <Dropdown.Item onClick={() => handleOptions('Perspective')}>
                Perspective
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleOptions('Top')}>Top</Dropdown.Item>
              <Dropdown.Item onClick={() => handleOptions('Front')}>Front</Dropdown.Item>
              <Dropdown.Item onClick={() => handleOptions('Left')}>Left</Dropdown.Item>
              <Dropdown.Item onClick={() => handleOptions('Right')}>Right</Dropdown.Item>
              <Dropdown.Item onClick={() => handleOptions('Back')}>Back</Dropdown.Item>
              <Dropdown.Item onClick={() => handleOptions('Bottom')}>Bottom</Dropdown.Item>
            </Dropdown.Menu>
          </>
        )}
      </OverlayTrigger>
    </Dropdown>
  );
};

export default View;
