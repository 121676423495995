import { FC, useState } from 'react';
import unorderdListSvg from 'src/assets/icons/edit-menu/list.svg';
import orderdListSvg from 'src/assets/icons/edit-menu/orderedList.svg';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { listTypes } from './constants';
import { ListProps } from './List.types';
import './List.scss';

const List: FC<ListProps> = ({
  app,
}) => {
  const [listIndex, setListIndex] = useState<number>(0); // TODO: should be from backend
  const [listSvg, setListSvg] = useState(unorderdListSvg);

  const handleClick = () => {
    let newListIndex;
    if ([0, 1].includes(listIndex)) newListIndex = listIndex + 1;
    else newListIndex = 0;
    setListIndex(newListIndex);
    if (newListIndex === 0 || newListIndex === 1) {
      setListSvg(unorderdListSvg);
      // document.execCommand("insertUnorderedList")
    } else if (newListIndex === 2) {
      setListSvg(orderdListSvg);
      // document.execCommand("insertOrderedList")
    }
    // const currentNode = app.selectedNodes && app.selectedNodes[0];
    // if (currentNode) currentNode.edit('LIST', listTypes[newListIndex], false);
    const currentNode = app.selectedNodes;
    for (let i = 0; i < currentNode.length; i++) {
      currentNode[i]?.edit('LIST', listTypes[newListIndex], !!(currentNode.length > 1 && i !== 0));
    }

    app.saveAllNodes([]);
  };

  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 1000, hide: 0 }}
      overlay={(
        <Tooltip
          id="button-tooltip-2"
          style={{
            fontSize: '12px',
            borderRadius: '2px',
            lineHeight: '16px',
            marginTop: '8px',
          }}
        >
          List
        </Tooltip>
      )}
    >
      {({ ref, ...triggerHandler }) => (
        <div className="listContainer">
          <img
            src={listSvg}
            className="listImage"
            role="presentation"
            onKeyDown={handleClick}
            ref={ref}
            alt=""
            {...triggerHandler}
            onClick={handleClick}
          />
        </div>
      )}
    </OverlayTrigger>
  );
};
export default List;
