import { ControlModal } from '@naya_studio/radix-ui';
import { ReactComponent as Cancel } from '../../assets/icons/details/cancel.svg';
import './RedirectModal.scss';

/**
 * Type of Redirect Modal Props
 */
type TRedirectModalProps = {
	/**
	 * Link of the Project embedded into block
	 */
	link: string;
	/**
	 * Boolean indicating whether the block is last block in navigation
	 */
	canSkip: boolean;

	/**
	 * Callback to handle what happens on closing the modal
	 */
	onClose: () => void;
	/**
	 * Callback to handle what happens on cancelling the process
	 */
	onCancel: () => void;
};

/**
 * Component to Render Redirect Modal on Screen
 * @param TRedirectModalProps Redirect Modal Props
 * @returns Redirect Modal Component
 */
const RedirectModal = ({ link, canSkip, onCancel, onClose }: TRedirectModalProps) => {
	/**
	 * Callback function to open project in new window
	 */
	const onOpen = () => {
		window.open(link, '_blank', 'noopener,noreferrer');
		onClose();
	};

	return (
		<ControlModal open>
			<ControlModal.ContentWrap
				id="redirect-modal"
				width={400}
				data-testid="redirect-modal"
				close={() => onClose()}
				className="DialogContent tw-bg-white tw-rounded-md tw-flex tw-flex-col tw-items-stretch tw-justify-center 
                tw-fixed tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2
                tw-min-w-[400px] tw-overflow-auto content-wrap tw-px-6 tw-py-4 tw-gap-2"
			>
				<ControlModal.Content>
					<div className="content-header tw-flex tw-justify-between tw-items-center tw-h-8 tw-w-full">
						<p className="tw-m-0 tw-font-randMedium tw-text-sm tw-leading-4 tw-text-black tw-flex-1">
							Opens in new tab
						</p>
						<div
							role="presentation"
							className="cross-icon tw-flex tw-justify-center tw-items-center tw-p-2 tw-rounded-full tw-cursor-pointer"
							onClick={() => onClose()}
						>
							<Cancel width={16} height={16} />
						</div>
					</div>

					<div className="content-info tw-font-randRegular tw-text-xs tw-text-black tw-h-16 tw-py-4">
						You are about to be redirected to a new tab. Would you like to continue?
					</div>

					<div className="content-cta-buttons tw-flex tw-justify-end tw-items-center tw-h-10 tw-w-full tw-gap-2">
						<div
							role="presentation"
							className="cancel-button tw-flex tw-justify-center tw-items-center tw-p-4 
							tw-rounded-2xl tw-text-naya tw-cursor-pointer tw-text-xs tw-font-randBold tw-h-10"
							onClick={() => onCancel()}
							data-testid={`redirect-modal-${canSkip ? 'skip' : 'cancel'}-btn`}
						>
							{canSkip ? 'Skip' : 'Cancel'}
						</div>
						<div
							role="presentation"
							className="open-button tw-flex tw-justify-center tw-items-center 
								tw-p-4 tw-rounded-2xl tw-text-white tw-bg-naya 
								tw-cursor-pointer tw-text-xs tw-font-randBold tw-h-10"
							onClick={() => onOpen()}
							data-testid="redirect-modal-open-btn"
						>
							Open in new tab
						</div>
					</div>
				</ControlModal.Content>
			</ControlModal.ContentWrap>
		</ControlModal>
	);
};

export default RedirectModal;
