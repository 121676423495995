import { IAction, LoadingActionTypes } from "../root.types"
import { ReduxLoading } from "./loader.types"

// keep state from different requests? waitingToResolve array to keep track?
// currently used for Blocking loader.
const initialState: ReduxLoading = {
  loading: false,
  loadCount: 0
}

const loaderReducer = (state = initialState, action: IAction<LoadingActionTypes>) => {
  let newState: ReduxLoading

  let { loadCount } = state
  const {payload} = action

  // payload will add and subtract from loadCount
  if (payload) {
    loadCount ++
  } else {
    loadCount --
  }

  switch (action.type) {
    case 'SET_LOADING':
      // if loadCount is 0, loading must be false
      if (loadCount <= 0) {
        newState ={
          loading: false,
          loadCount: 0
        }
      // if loadCount is great than 0, it must be loading
      } else {
        newState = {
          loading: true,
          loadCount
        }
      }
      break
    default:
      newState = state
      break
  }

  return newState
}

export default loaderReducer
