import { ReactNode } from 'react';
import { Route, Redirect, RouteProps } from 'react-router';
import useUser from 'src/redux/hooks/user';

type PrivateRouteProps = RouteProps & { children: ReactNode };

/**
 * @Component To handle protected routes
 * @param param0 PrivateRouteProps
 * @returns If user is logged in returns children else Redirects to /login
 */
const ProtectedRoute = ({ children, ...rest }: PrivateRouteProps) => {
	const { user } = useUser();
	const url = encodeURIComponent(window.location.pathname);

	return (
		<Route
			{...rest}
			render={({ location }) =>
				user._id && !user.userType?.includes('GUEST') ? (
					children
				) : (
					<Redirect
						to={{
							pathname: `/login/${url}`,
							state: { from: location }
						}}
					/>
				)
			}
		/>
	);
};

export default ProtectedRoute;
