import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { store } from 'src';
import { useSelector } from 'react-redux';
import { ReduxState } from 'src/redux/reducers/root.types';
import { CustomDispatch } from 'src/redux/actions/types';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';

import {
	addPrototypeError,
	updatePrototypeState,
	uploadModelToShapeways
} from 'src/redux/actions/prototyping';
import { SuperPowerEvents } from 'src/util/analytics/events';
import trackEvent from 'src/util/analytics/analytics';
import { isMaxOrderReached, isSupportedModel } from '../utils/helper';

import { ReactComponent as BackButton } from '../../../../../assets/prototyping/arrow-with-circle.svg';
import { ReactComponent as UploadBox } from '../../../../../assets/prototyping/monitor-with-box.svg';
import { ReactComponent as UploadBtn } from '../../../../../assets/prototyping/upload.svg';
import { ReactComponent as Uploading } from '../../../../../assets/prototyping/uploading.svg';
import { ReactComponent as Spinner } from '../../../../../assets/prototyping/spinner2.svg';
import { ReactComponent as CrossIcon } from '../../../../../assets/cross.svg';

import './Upload3DModel.scss';
import PrototypePrompt from '../checkout/PrototypePrompt';
import { PrototypingHomeProps } from '../homepage/PrototypingHomeProps';

function UploadModal({ setPrototypePage }: PrototypingHomeProps) {
	const uploadInputRef = useRef<HTMLInputElement>(null);
	const [showFilesModal, setShowFilesModal] = useState<boolean>(false);
	const [isTakingTime, setIstakingTime] = useState<boolean>(false);
	const { isUploaded, isUploading, error } = useSelector(
		(state: ReduxState) => state.prototyping
	);
	const timeoutRef = useRef<NodeJS.Timeout>();

	// if the model is uploaded change the page to order-details
	if (isUploaded) {
		(store.dispatch as CustomDispatch)(updatePrototypeState({ isUploaded: false }));
		setPrototypePage('ORDER_DETAILS');
		// history.push('/prototyping/order-details');
	}

	// click on input type file to open file selection
	function handleOnClick() {
		if (uploadInputRef.current) {
			uploadInputRef.current.click();
		}
	}

	function handleUpload(file: File) {
		if (file && !isMaxOrderReached()) {
			if (file.size > 64 * 1024 * 1024) {
				(store.dispatch as CustomDispatch)(
					addPrototypeError(true, 'File size can not be more than 64mb')
				);
				return;
			}
			const ext = file?.name.split('.').pop();
			const isSupportedFile = isSupportedModel(ext);
			if (isSupportedFile && ext) {
				const fileUrl = URL.createObjectURL(file);
				const fileData = {
					file,
					fileName: file.name,
					url: fileUrl,
					extension: ext,
					uploadedFrom: '/prototyping'
				};
				(store.dispatch as CustomDispatch)(uploadModelToShapeways(fileData));
				trackEvent(SuperPowerEvents.ORDER_3D_PRINT);
				timeoutRef.current = setTimeout(() => {
					setIstakingTime(true);
				}, 10000);
			} else {
				addSnackbar({
					type: 'ERROR',
					text: 'Unsupported model type, please check supported types',
					show: true
				});
				removeSnackbar(7000, () => {});
			}
		}
	}

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files[0]) handleUpload(event.target.files[0]);
		event.target.value = '';
	};

	const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		if (event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files[0])
			handleUpload(event.dataTransfer.files[0]);
	};

	useEffect(() => {
		if (error.isError) {
			addSnackbar({ type: 'ERROR', text: error.msg, show: true });
			removeSnackbar(5000, () => {
				(store.dispatch as CustomDispatch)(addPrototypeError(false, ''));
			});
			setIstakingTime(false);
		}
	}, [error]);

	useEffect(
		() => () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		},
		[]
	);

	return (
		<div className="upload-modal" onDrop={handleDrop} onDragOver={(e) => e.preventDefault()}>
			{!isUploading ? (
				<UploadBox color="var(--theme-color-3)" />
			) : (
				<Uploading color="var(--theme-color-3)" />
			)}

			{!isUploading ? (
				<h5>Upload a 3D model to get started</h5>
			) : (
				<h5>
					<Spinner width={24} height={24} color="var(--theme-color-1)" />
					{!isTakingTime ? 'Uploading 3D model' : 'Fixing your 3D model'}
				</h5>
			)}
			{!isUploading && <p>or drag and drop 3D model here</p>}
			<input
				ref={uploadInputRef}
				onChange={handleInputChange}
				type="file"
				accept=".dae,.OBJ,.obj,.stl,.x3d,.x3db,.x3dv,.3mf,.step,.stp"
				name="3d-model"
				id="3d-model"
				hidden
			/>
			{!isUploading && (
				<button
					className="upload-btn"
					type="submit"
					onClick={handleOnClick}
					onKeyDown={handleOnClick}
				>
					<UploadBtn /> Upload
				</button>
			)}
			<div className="supported-files">
				<p
					onClick={() => setShowFilesModal(true)}
					onKeyDown={() => setShowFilesModal(true)}
					role="presentation"
				>
					Supported file types
				</p>
				{showFilesModal && (
					<div className="supported-files-modal">
						<span className="file-types">
							File types: DAE,OBJ, STL, X3D, X3DB, X3DV, 3MF, STEP, STP
							<div>
								<CrossIcon
									className="close-modal"
									onClick={() => setShowFilesModal(false)}
									role="button"
								/>
							</div>
						</span>
						<span>Maximum file size: 64 MB or 1 Million polygons</span>
					</div>
				)}
			</div>
		</div>
	);
}

const Upload3DModel = ({ setPrototypePage }: PrototypingHomeProps) => {
	const { formData } = useSelector((state: ReduxState) => state.prototyping);
	// const history = useHistory();

	useEffect(() => {
		formData.color = undefined;
		formData.material = undefined;
		formData.colors = undefined;
		formData.dimensions = undefined;
		formData.finish = undefined;
		(store.dispatch as CustomDispatch)(
			updatePrototypeState({
				currentLocation: 'UPLOAD',
				formData,
				color: 0x888888,
				isReadyForOrder: false
			})
		);
	}, [formData]);
	return (
		<div className="upload-modal-container">
			<div>
				<h6 className="heading">Upload and preview your 3D model here</h6>
				<UploadModal setPrototypePage={setPrototypePage} />
				<div
					className="back-button"
					role="presentation"
					onClick={() => setPrototypePage('HOME')}
				>
					<BackButton />
					<span>Back to Prototyping</span>
				</div>
			</div>
			<PrototypePrompt
				text="You have reached the maximum limit to order a 3D print. Connect with us to request additional orders."
				title="Maximum order limit reached"
				onClose={() => setPrototypePage('HOME')}
				showModel={isMaxOrderReached()}
				primaryBtnText="Contact Naya support"
				secondaryBtnText="Cancel"
				onPrimaryBtnClick={() => setPrototypePage('HOME')}
				contactNaya
			/>
		</div>
	);
};

export default Upload3DModel;
