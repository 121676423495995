// import { INode } from "@naya_studio/types";
import { IFeedback, INode } from "@naya_studio/types";
// import BaseNode from "src/components/canvas/app/nodes/BaseNode";

export enum EActionType  {
    NEW_NODE = 'NEW_NODE',
    EDIT = 'EDIT',
    RESOLVE_COMMENT = 'RESOLVE_COMMENT',
    PAGE = 'PAGE',
    PHASE = 'PHASE',
    NAVIGATION = 'NAVIGATION',
    RENAME_PAGE = 'RENAME_PAGE',
    RENAME_PHASE = 'RENAME_PHASE'
}

export type TActionItem = {
  changeType:keyof typeof EActionType,
  prevNodeData:INode | IFeedback,
  newNodeData:INode | IFeedback
}