import { ICanvas, TCanvasBounds } from '@naya_studio/types';
import { base64StringToBlob } from 'blob-util';
import React, { ReactElement } from 'react';
import * as PIXI from 'pixi.js';
import Backgrounds from '../backgrounds/Backgrounds';
import './CanvasEditMenu.scss';
import { CanvasEditMenuProps } from './CanvasEditMenu.types';

const CanvasEditMenu: React.FC<CanvasEditMenuProps> = ({ app, canvas, setCanvasColor  }): ReactElement => {
  const {
    x, y, width, height,
  } = (canvas as ICanvas)?.bounds as TCanvasBounds;
  const pageCoordinates = app.viewport.toGlobal({
    x: (app.frameX || 1) + (app.width as number) / 2,
    y: (app.frameY || 1) + (app.height as number),
  });

  const downloadCanvas = () => {
    const pxMaskOuterBounds = new PIXI.Graphics();
    pxMaskOuterBounds.beginFill(0xFFFFFF);
    pxMaskOuterBounds.drawRect((x as number), y as number, width as number, height as number);
    pxMaskOuterBounds.endFill();
    app.removeTransformer();
    app.viewport.addChild(pxMaskOuterBounds);
    app.viewport.mask = pxMaskOuterBounds;
    if(app.activeTexture && app.backgroundPattern) app.viewport.removeChild(app.backgroundPattern!);
    const b64Data = app.app.renderer.plugins.extract.image(app.app.stage);
    app.viewport.mask = null;
    app.viewport.removeChild(pxMaskOuterBounds);
    if(app.activeTexture && app.backgroundPattern) app.viewport.addChild(app.backgroundPattern!);
    // pxMaskOuterBounds.destroy();
    app.selectFrame();
    const contentType = 'image/png';
    const blob = base64StringToBlob(b64Data.src.replace(/^[^,]+,/, ''), contentType);
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', `${canvas.name}.jpeg`);
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div
      id="canvas-edit-menu"
      role="presentation"
      style={{
        transform: `translate3d(${pageCoordinates.x - 53}px, ${pageCoordinates.y + 20}px, 0)`,
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
    >
      <Backgrounds 
        app={app} 
        downloadCanvas={downloadCanvas} 
        setCanvasBkgColor={(data) => setCanvasColor(data as string)}
      />
    </div>  
  );
};

export default CanvasEditMenu;
