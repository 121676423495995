import { FC, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import {
	EUserRole,
	IBlock,
	INotification,
	IProjectDetail,
	ISentNotification,
	IGroup,
	IUser,
	TInvitedEmail,
	TUserAndRole,
	Collaborator
} from '@naya_studio/types';
import dropdownIcon from 'src/assets/icons/sharing-modal/dropdown.svg';
import { ReactComponent as AlertIcon } from 'src/assets/icons/sharing-modal/alert-red.svg';
import './InviteUsers.scss';
import { DropDown, Popover, TagsInput } from '@naya_studio/radix-ui';
import { store } from 'src';
import { SyncLoader } from 'react-spinners';
import { getStageBlocks, getStagesBlocksNodesOfProject } from 'src/redux/reduxActions/util';
import {
	TAddUserToBlockThunkArgs,
	TAddUserToProjectThunkArg,
	TAddUserToStageThunkArg
} from 'src/types/argTypes';
import useProject from 'src/redux/hooks/useProject';
import { ISnackBar, ReduxState } from 'src/redux/reducers/root.types';
import { CustomDispatch } from 'src/redux/actions/types';
import { addUserToProject } from 'src/redux/reduxActions/project';
import { addUserToStage } from 'src/redux/reduxActions/stage';
import { addUserToBlock } from 'src/redux/reduxActions/block';
import { findUsersByEmail } from 'src/redux/actions/user';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';
import { CustomEvents } from 'src/util/analytics/events';
import trackEvent from 'src/util/analytics/analytics';
import { useDispatch } from 'react-redux';
import { sentNotificationSlice } from 'src/redux/features/api/sentNotification';
import { insertDocumentToYDoc } from 'src/redux/hooks/observers/utils';
import useProjectUsersObserver from 'src/redux/hooks/observers/useProjectUsersObserver';
import getUserFromRedux from 'src/util/helper/user';
import { getWebSocket } from 'src/rtc/yjs/yjsConfig';
import { getStageAndChildren } from 'src/redux/actions/util';
import { TShareItemEventData } from 'src/util/analytics/analytic.types';
import { ESharingTabs } from '../../SharingModal.types';
import { InviteUserProps, TRole } from './InviteUsers.types';

/** Invite user on basis of these roles */
const roles: TRole[] = [
	{
		key: EUserRole.EDITOR,
		title: 'Editor'
	},
	{
		key: EUserRole.COMMENTER,
		title: 'Commenter'
	},
	{
		key: EUserRole.VIEWER,
		title: 'Viewer'
	}
];

/** Component for Invite User in Sharing Modal */
const InviteUsers: FC<InviteUserProps> = ({
	activeTab,
	projectId,
	stageId,
	blockId,
	users,
	setOverrideRoleModalPayload,
	setShowOverrideRoleModal,
	setShowInviteNote,
	variant
}): ReactElement => {
	const { stages: reduxStages, blocks: reduxBlocks } = store.getState() as ReduxState;
	const { project: actionableProject } = useProject(projectId);
	const webSocket = getWebSocket();
	const { insertUsersToProjectUsersYMap } = useProjectUsersObserver();

	const [inputValue, setInputValue] = useState<string>('');
	const [inviteLoader, setInviteLoader] = useState<boolean>(false);
	const [emails, setEmails] = useState<string[]>([]);
	const [inputFocused, setInputFocused] = useState<boolean>(false);
	const [inviteNote, setInviteNote] = useState<string>('');
	const [selectedRole, setSelectedRole] = useState<TRole>({
		key: EUserRole.EDITOR,
		title: 'Editor'
	});
	const activeTabEmails = useMemo(
		() => users.map((u) => (u.user as IUser).email?.toLowerCase()),
		[users]
	);
	const isEmailAlreadyInvited =
		emails.some((e) => activeTabEmails.includes(e.toLowerCase())) ||
		activeTabEmails.includes(inputValue.toLowerCase());

	const dispatch = useDispatch();
	// used to create sentNotif as the curr user invites other users
	const sentNotification = useRef<ISentNotification[]>([]);
	// Store whether to show the email suggestions popup
	const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
	// Store the initial suggestions list
	const suggestionList = useRef<Collaborator[]>([]);

	// Runs when Invite Users is mounted
	useEffect(() => {
		// Function to check if mouse down is happening on input or not
		const onMouseDown = (event: MouseEvent) => {
			const tagsContainer = document.querySelector<HTMLDivElement>(`.tags-input-container`);
			// If mouse down has happened outside input tag then close suggestions
			if (tagsContainer && !tagsContainer.contains(event.target as Node)) {
				setInputFocused(false);
				setShowSuggestions(false);
			}
		};

		const sharingModal = document.querySelector<HTMLDivElement>(
			'[data-testid="share-block-modal"]'
		);

		if (sharingModal) {
			// Add event listener
			sharingModal.addEventListener('mousedown', onMouseDown);
		}

		// Cleanup event listener
		return () => {
			if (sharingModal) sharingModal.removeEventListener('mousedown', onMouseDown);
		};
	}, []);

	/**
	 * Function to remove a email from input tag
	 * @param tag email to remove
	 */
	const removeTag = (tag: string) => {
		setEmails(emails.filter((el) => el !== tag));
	};

	/**
	 * Function to add all provided emails to state variable
	 * @param agrTags invited emails
	 */
	const onEnter = (agrTags: string[]) => {
		setInputValue('');
		setEmails([...emails, ...agrTags]);
	};

	/**
	 * Function gets called when input value is empty and backspace is pressed to remove last tag created
	 */
	const onBackspace = () => setEmails((tags) => tags.slice(0, -1));

	/**
	 * Function to check if email is valid
	 * @param email Email to check
	 */
	const checkIfValidEmail = (email: string) => {
		const validRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (email.match(validRegex)) {
			return true;
		}
		return false;
	};

	/**
	 * Function to check if invited user exists on lower level
	 * @param level Current Invite Level
	 * @param actionableLevelId Id of current level project/stage
	 * @param email Email of invited user
	 * @param role Role of invited user
	 */
	const checkIfUserExistsOnLowerLevels = (
		level: keyof typeof ESharingTabs,
		actionableLevelId: string,
		userId: string,
		role: keyof typeof EUserRole,
		isInvitedUser: boolean
	) => {
		switch (level) {
			case 'PROJECT': {
				let foundUser = false;
				const { stages, blocks } = getStagesBlocksNodesOfProject(actionableLevelId);
				for (let i = 0; i < (stages as IGroup[]).length; i++) {
					const stage = (stages as IGroup[])[i] as IGroup;
					if (isInvitedUser) {
						foundUser =
							stage.invitedEmails?.some(
								(e) =>
									(e as TInvitedEmail).email === userId &&
									(e as TInvitedEmail).role !== role
							) || false;
					} else {
						foundUser =
							stage.users?.some(
								(u) =>
									(u as TUserAndRole).user === userId &&
									(u as TUserAndRole).role !== role
							) || false;
					}

					if (foundUser) return true;
				}

				for (let k = 0; k < (blocks as IBlock[]).length; k++) {
					const block = (blocks as IBlock[])[k] as IBlock;
					if (isInvitedUser) {
						foundUser =
							block.invitedEmails?.some(
								(e) =>
									(e as TInvitedEmail).email === userId &&
									(e as TInvitedEmail).role !== role
							) || false;
					} else {
						foundUser =
							block.users?.some(
								(u) =>
									(u as TUserAndRole).user === userId &&
									(u as TUserAndRole).role !== role
							) || false;
					}

					if (foundUser) return true;
				}

				return false;
			}

			case 'PHASE': {
				let foundUser = false;
				const blocks = getStageBlocks(actionableLevelId);
				if (blocks) {
					for (let k = 0; k < (blocks as IBlock[]).length; k++) {
						const block = (blocks as IBlock[])[k] as IBlock;
						if (isInvitedUser) {
							foundUser =
								block.invitedEmails?.some(
									(e) =>
										(e as TInvitedEmail).email === userId &&
										(e as TInvitedEmail).role !== role
								) || false;
						} else {
							foundUser =
								block.users?.some(
									(u) =>
										(u as TUserAndRole).user === userId &&
										(u as TUserAndRole).role !== role
								) || false;
						}

						if (foundUser) return true;
					}
				}

				return false;
			}

			default:
				return false;
		}
	};

	/**
	 * Function to generate a snackbar text based on share level
	 * @param level Share Level
	 * @returns snackbar text to display
	 */
	const getSnackbarText = (level: keyof typeof ESharingTabs) => {
		let text = `%NAME% is successfully shared.`;

		switch (level) {
			case 'PROJECT': {
				text = text.replace(
					'%NAME%',
					(actionableProject?.projectDetails as IProjectDetail).name || 'Project'
				);
				break;
			}
			case 'PHASE': {
				text = text.replace('%NAME%', reduxStages.data[stageId]?.name || 'Phase');
				break;
			}
			case 'BLOCK': {
				const blockType = reduxBlocks.data[blockId]?.blockType;
				const blockName = reduxBlocks.data[blockId]?.name || 'Block';

				if (blockType === 'EMPTY') text = 'Successfully sent request for file upload.';
				else text = text.replace('%NAME%', blockName);
				break;
			}
			default:
				return text;
		}

		return text;
	};

	/**
	 * Add snackbar when all invites are sent
	 * @param index current index
	 * @param total length of array
	 */
	const checkIfAllInvitesSent = (index: number, total: number) => {
		if (index === total) {
			/** Hide the loader */
			setInviteLoader(false);

			const payload: ISnackBar = {
				text: getSnackbarText(activeTab),
				show: true,
				type: 'NORMAL'
			};

			addSnackbar(payload);
			removeSnackbar(2000);

			/** Scroll to the added user list */
			const scrollContainer = document.querySelector(`[data-testid="share-modal-user-list"]`);
			if (scrollContainer) {
				scrollContainer.scrollTop = scrollContainer.scrollHeight;
			}
		}
	};

	/**
	 * Function to display error snackbar and hide invite loader
	 */
	const displayErrorSnackbar = () => {
		/** Hide the loader */
		setInviteLoader(false);

		/** Snackbar Payload */
		const snackbarPayload: ISnackBar = {
			text: 'Failed to share to one or more users.',
			show: true,
			type: 'ERROR'
		};

		addSnackbar(snackbarPayload);
		removeSnackbar(2000);
	};

	/**
	 * When a user invites someone, immediately create a sent notification
	 * Manipulate the toUsers field to club notifications together
	 */
	const handleSentNotification = (newNotification: INotification) => {
		const toUsers = {
			notifId: newNotification?._id as string,
			user: newNotification?.user,
			isCompleted: false
		};
		if (sentNotification.current.length === 0) {
			const sentNotif: ISentNotification = {
				...newNotification,
				toUsers: [toUsers],
				isCompleted: false,
				createdBy: getUserFromRedux()._id
			};
			sentNotification.current.push(sentNotif);
		} else {
			sentNotification.current[0]!.toUsers.push(toUsers);
		}
	};

	/**
	 * Create Sent Notification for the logged in user for the action of
	 * inviting user to block/phase/project
	 */
	const sentNotificationAddToRedux = (notificationArg: ISentNotification[]) => {
		const currentUserId = getUserFromRedux()._id;
		const getSentNotifs = sentNotificationSlice.endpoints.getSentNotifs.select({
			userId: currentUserId
		})(store.getState());
		(dispatch as CustomDispatch)(
			sentNotificationSlice.util.upsertQueryData(
				'getSentNotifs',
				{ userId: currentUserId },
				{
					tasks: {
						[notificationArg[0]!._id as string]: {
							...(notificationArg[0] as ISentNotification)
						},
						...getSentNotifs.data?.tasks
					}
				}
			)
		);
		sentNotification.current = [];
	};

	/**
	 * Function to construct payload and dispatch action for invite
	 */
	const onInvite = async () => {
		try {
			/** Filter out all valid and unique emails to invite */
			const allEmails = new Set([
				...emails
					.map((email) => email.toLowerCase())
					.filter(
						(email) =>
							checkIfValidEmail(email) &&
							!activeTabEmails.includes(email.toLowerCase())
					)
			]);

			/** Check if input tag has any text and if it has then push it to array */
			const textInInputIsEmail =
				checkIfValidEmail(inputValue) &&
				!activeTabEmails.includes(inputValue.toLowerCase());
			if (textInInputIsEmail) allEmails.add(inputValue);

			/** Convert them all to an array */
			const emailsToInvite = Array.from(allEmails);

			const usersToOverride: Array<TAddUserToStageThunkArg | TAddUserToProjectThunkArg> = [];

			/** Find out all the users present in db from invited emails */
			const existingUsers = (await findUsersByEmail(emailsToInvite)) as {
				[key: string]: IUser;
			};

			/** Clear the input tag */
			setEmails([]);
			setInputValue('');
			// let notification: ISentNotification[] = [];

			if (emailsToInvite.length) {
				/** Show loader */
				setInviteLoader(true);

				for (let i = 0; i < emailsToInvite.length; i++) {
					const isInvitedUser =
						!existingUsers[emailsToInvite[i]?.toLowerCase() as string];

					switch (activeTab) {
						case 'BLOCK': {
							// Construct payload for dispatch
							const actionPayload: TAddUserToBlockThunkArgs = {
								data: {
									projectId,
									blockId,
									stageId,
									email: emailsToInvite[i]?.toLowerCase() as string,
									role: selectedRole.key,
									isInvitedUser,
									inviteNote,
									isRequestFile: variant === 'DEFAULT'
								}
							};

							if (!isInvitedUser) {
								actionPayload.data.userId = existingUsers[
									emailsToInvite[i]?.toLowerCase() as string
								]?._id as string;
							}

							// Fix for inviting user to a new block which does not exist in ydoc
							if (webSocket?.wsconnected) insertDocumentToYDoc('BLOCK', blockId);

							(store.dispatch as CustomDispatch)(addUserToBlock(actionPayload))
								.unwrap()
								// eslint-disable-next-line no-loop-func
								.then((response) => {
									checkIfAllInvitesSent(i, emailsToInvite.length - 1);

									if (webSocket?.wsconnected && !isInvitedUser)
										insertUsersToProjectUsersYMap([response.user as IUser]);

									if (response?.notification && response?.notification?._id) {
										handleSentNotification(
											response.notification as INotification
										);

										if (i + 1 === emailsToInvite.length)
											sentNotificationAddToRedux(sentNotification.current);
									}

									// Track sharing of block event
									const eventProps: TShareItemEventData = {
										elementId: blockId,
										elementType: 'BLOCK'
									};
									trackEvent(CustomEvents.SHARE_ITEM, eventProps);
								})
								.catch((error) => {
									displayErrorSnackbar();

									throw new Error(error);
								});
							break;
						}
						case 'PHASE': {
							const userId = !isInvitedUser
								? (existingUsers[emailsToInvite[i]?.toLowerCase() as string]
										?._id as string)
								: '';
							const profilePic = !isInvitedUser
								? (existingUsers[emailsToInvite[i]?.toLowerCase() as string]
										?.profilePic as string)
								: '';
							const { stages, blocks } = getStageAndChildren(stageId, 'NORMALIZED');
							const blockIdsInvite: string[] = [];
							const stageIdsInvite: string[] = [];
							blocks.forEach((block) => blockIdsInvite.push(block._id as string));
							stages.forEach((stage) => stageIdsInvite.push(stage._id as string));
							// Construct payload for dispatch
							const actionPayload: TAddUserToStageThunkArg = {
								payload: {
									stageIds: stageIdsInvite,
									projectId,
									email: emailsToInvite[i]?.toLowerCase() as string,
									role: selectedRole.key,
									override: checkIfUserExistsOnLowerLevels(
										'PHASE',
										stageId,
										isInvitedUser ? (emailsToInvite[i] as string) : userId,
										selectedRole.key,
										isInvitedUser
									),
									isInvitedUser,
									inviteNote
								},
								blockIds: blockIdsInvite
							};

							if (!isInvitedUser) {
								actionPayload.payload.userId = userId;
								actionPayload.payload.profilePic = profilePic;
							}

							// Fix for inviting user to a new stage which does not exist in ydoc
							if (webSocket?.wsconnected) insertDocumentToYDoc('STAGE', stageId);

							/** Check if override exist otherwise add user */
							if (actionPayload.payload.override) {
								usersToOverride.push(actionPayload);
							} else {
								(store.dispatch as CustomDispatch)(addUserToStage(actionPayload))
									.unwrap()
									// eslint-disable-next-line no-loop-func
									.then((response) => {
										checkIfAllInvitesSent(i, emailsToInvite.length - 1);

										if (webSocket?.wsconnected && !isInvitedUser)
											insertUsersToProjectUsersYMap([response.user as IUser]);

										if (response?.notification && response?.notification?._id) {
											if (response?.notification) {
												handleSentNotification(
													response.notification as INotification
												);

												if (i + 1 === emailsToInvite.length)
													sentNotificationAddToRedux(
														sentNotification.current
													);
											}
										}

										// Track sharing of group event
										const eventProps: TShareItemEventData = {
											elementId: stageId,
											elementType: 'GROUP'
										};
										trackEvent(CustomEvents.SHARE_ITEM, eventProps);
									})
									.catch((error) => {
										displayErrorSnackbar();
										throw new Error(error);
									});
							}
							break;
						}
						case 'PROJECT': {
							const projectChildren = actionableProject?.children as string[];
							const blockIds = [] as string[];
							const stageIds = [] as string[];
							projectChildren.forEach((id) => {
								const { stages, blocks } = getStageAndChildren(
									id as string,
									'NORMALIZED'
								);
								blocks.forEach((block) => blockIds.push(block._id as string));
								stages.forEach((stage) => stageIds.push(stage._id as string));
							});

							const userId = !isInvitedUser
								? (existingUsers[emailsToInvite[i]?.toLowerCase() as string]
										?._id as string)
								: '';
							const profilePic = !isInvitedUser
								? (existingUsers[emailsToInvite[i]?.toLowerCase() as string]
										?.profilePic as string)
								: '';

							// Construct payload for dispatch
							const actionPayload: TAddUserToProjectThunkArg = {
								payload: {
									projectId,
									email: emailsToInvite[i]?.toLowerCase() as string,
									role: selectedRole.key,
									override: checkIfUserExistsOnLowerLevels(
										'PROJECT',
										projectId,
										isInvitedUser ? (emailsToInvite[i] as string) : userId,
										selectedRole.key,
										isInvitedUser
									),
									projectName: (
										actionableProject?.projectDetails as IProjectDetail
									).name!,
									username: emailsToInvite[i]?.split('@')[0] as string,
									isInvitedUser,
									inviteNote
								},
								blockIds,
								stageIds
							};

							if (!isInvitedUser) {
								actionPayload.payload.userId = userId;
								actionPayload.payload.profilePic = profilePic;
							}

							/** Check if override exist otherwise add user */
							if (actionPayload.payload.override) {
								usersToOverride.push(actionPayload);
							} else {
								(store.dispatch as CustomDispatch)(addUserToProject(actionPayload))
									.unwrap()
									// eslint-disable-next-line no-loop-func
									.then((response) => {
										checkIfAllInvitesSent(i, emailsToInvite.length - 1);

										if (webSocket?.wsconnected && !isInvitedUser)
											insertUsersToProjectUsersYMap([response.user as IUser]);

										if (response?.notification && response?.notification?._id) {
											handleSentNotification(
												response.notification as INotification
											);
											if (i + 1 === emailsToInvite.length)
												sentNotificationAddToRedux(
													sentNotification.current
												);
										}

										// Track sharing of journey event
										const eventProps: TShareItemEventData = {
											elementId: projectId,
											elementType: 'JOURNEY'
										};
										trackEvent(CustomEvents.SHARE_ITEM, eventProps);
									})
									.catch((error) => {
										displayErrorSnackbar();
										throw new Error(error);
									});
							}
							break;
						}

						default:
							break;
					}
				}

				/** If users exist in lower levels, close the current modal and show override role modal */
				if (usersToOverride.length) {
					setOverrideRoleModalPayload(usersToOverride);
					setShowOverrideRoleModal(true);
				}
			}
		} catch (error) {
			console.error('Error inviting user: ', error);
		}
	};

	// check if all emails are valid
	const isAllEmailsValid = emails.some((e) => !checkIfValidEmail(e));

	/**
	 * Function to check if invite button should be disabled
	 */
	const shouldDisableInvite = () => {
		if (emails.length) return isAllEmailsValid;
		if (inputValue.length) return !checkIfValidEmail(inputValue);
		return true;
	};

	const showInviteNote = !shouldDisableInvite() && !isEmailAlreadyInvited;

	// triggered when invite not is visible to decrease the height of user list
	useEffect(() => {
		setShowInviteNote(showInviteNote);
	}, [showInviteNote]);

	/**
	 * Function to check for input change and seperate the emails on comma
	 */
	const onInputChange = (insertedValue: string) => {
		const inputEmails = (insertedValue.split(',') as string[]).map((email) => email.trim());
		const last = inputEmails[inputEmails.length - 1];
		const rest = inputEmails.slice(0, -1);
		setEmails([...emails, ...rest]);
		setInputValue(last as string);

		if (!showSuggestions) setShowSuggestions(true);
	};

	/**
	 *
	 * @param email
	 */
	const handleSuggestionClick = (email: string): void => {
		setInputValue('');
		setShowSuggestions(false);
		setEmails([...emails, email.trim()]);
	};

	/**
	 * useEffect to store the list of suggestions in the state variable
	 */
	useEffect(() => {
		suggestionList.current = [];
		// Retrieve existing data from localStorage
		const storedSharedWithUsers = localStorage.getItem('sharedWithUsers');
		const parsedStoredSharedWithUsers = storedSharedWithUsers
			? JSON.parse(storedSharedWithUsers)
			: [];

		// Use a Set to keep track of unique email addresses
		const uniqueEmails = new Set();

		// Add users from localStorage to suggestions and filter duplicates
		parsedStoredSharedWithUsers.forEach((user: Collaborator) => {
			const userEmail = user.email?.toLowerCase();
			if (
				!uniqueEmails.has(userEmail) &&
				!activeTabEmails.includes(userEmail) &&
				!emails.includes(userEmail as string)
			) {
				suggestionList.current.push({ ...user });
				uniqueEmails.add(user?.email?.toLowerCase());
			}
		});

		suggestionList.current.sort((a, b) => {
			const emailA = (a.email || '').toLowerCase();
			const emailB = (b.email || '').toLowerCase();
			if (emailA < emailB) {
				return -1;
			}
			if (emailA > emailB) {
				return 1;
			}
			return 0;
		});
	}, [activeTabEmails, emails]);

	// Filtered suggestions with input value
	const filteredValue = suggestionList.current.filter((suggestion) =>
		suggestion?.email?.toLowerCase().includes(inputValue.toLowerCase())
	);

	/**
	 * Function to generate the warning text to display
	 */
	const generateWarningText = () =>
		isEmailAlreadyInvited
			? `User with provided email already exist on ${activeTab.toLowerCase()}`
			: 'Enter a valid email address';

	return (
		<>
			<Popover open={showSuggestions}>
				<div className="tw-flex tw-items-center">
					<div
						className={`input-container tw-flex tw-items-center ${cn({
							focused: inputFocused
						})}`}
					>
						<Popover.Trigger style={{ padding: '2px', flex: 1 }}>
							<TagsInput
								placeholder={emails.length > 0 ? '' : 'Email, comma separated'}
								onEnter={onEnter}
								onBackspace={onBackspace}
								onFocus={() => {
									setInputFocused(true);
									setShowSuggestions(true);
								}}
								value={inputValue}
								onInputChange={onInputChange}
								data-testid="share-modal-email-input"
							>
								{emails.map((tag) => (
									<TagsInput.Tag
										tag={tag}
										isValidTag={checkIfValidEmail}
										removeTag={removeTag}
									/>
								))}
							</TagsInput>
						</Popover.Trigger>
						<Popover.Portal>
							<Popover.Content
								className="suggestions tw-overflow-hidden tw-rounded-2xl tw-absolute tw-bg-[#FFFFFF]"
								side="bottom"
								sideOffset={6}
								align="start"
								onOpenAutoFocus={(e) => e.preventDefault()}
								onMouseDown={(e) => {
									e.preventDefault();
								}}
							>
								{filteredValue.slice(0, 5).map((suggestion) => (
									<div
										role="presentation"
										onClick={() =>
											handleSuggestionClick(suggestion?.email || '')
										}
										key={suggestion.id?.toString()}
										className="suggestion tw-flex tw-items-center tw-cursor-pointer tw-w-full tw-py-2 tw-pl-4"
									>
										<img
											referrerPolicy="no-referrer"
											src={
												suggestion.profilePic
													? suggestion.profilePic
													: 'https://firebasestorage.googleapis.com/v0/b/naya-assets' +
													  '/o/icons%2FMonogram.svg?alt=media&token=18a53168-1bef-4bf4-a097-f77f4c814093'
											}
											alt="profile"
											className={cn({
												'profile-pic': !suggestion.profilePic,
												'tw-rounded-full tw-w-8 tw-h-8 tw-mr-4': true
											})}
										/>
										<div className="tw-flex tw-flex-col">
											<p
												className={`fullName tw-inline tw-w-[316px] tw-h-4 tw-text-[#161616] 
								tw-mb-0 tw-text-sm tw-leading-4 tw-text-left tw-font-randRegular`}
											>
												{suggestion.fullName}
											</p>
											<p
												className={`email tw-inline tw-w-[316px] tw-h-4 tw-text-[#7E7E7E] 
								tw-mt-0.5 tw-text-sm tw-leading-4 tw-font-randRegular`}
											>
												{suggestion.email}
											</p>
										</div>
									</div>
								))}
							</Popover.Content>
						</Popover.Portal>
						<DropDown>
							<DropDown.DropDownTrigger>
								<div
									className="sharing-dropdown-role tw-flex tw-items-center tw-mr-1 tw-px-2 tw-py-1 tw-rounded"
									data-testid="share-modal-access-type-dropdown"
								>
									<span className="tw-text-xs tw-font-randRegular tw-mr-2">
										{selectedRole.title}
									</span>
									<img src={dropdownIcon} alt="dropdown" />
								</div>
							</DropDown.DropDownTrigger>
							<DropDown.DropDownContent
								classNames={`tw-p-2 dropdown-content width-130 
									tw-rounded-2xl tw-bg-white tw-right-0`}
								style={{ zIndex: '1000' }}
							>
								{roles.map((role: TRole) => (
									<DropDown.DropDownItem>
										<div
											role="presentation"
											className={`kebab-items tw-text-xs tw-font-randRegular 
									tw-leading-6 tw-pt-1 tw-pb-1 tw-px-2 tw-cursor-pointer`}
											onClick={() => setSelectedRole(role)}
											data-testid={`share-modal-access-type-${role.title}`}
										>
											{role.title}
										</div>
									</DropDown.DropDownItem>
								))}
							</DropDown.DropDownContent>
						</DropDown>
					</div>
					<button
						type="button"
						className="invite-btn"
						onClick={onInvite}
						disabled={shouldDisableInvite()}
						data-testid="share-modal-invite-btn"
					>
						{inviteLoader ? <SyncLoader size={5} color="#fff" /> : 'Invite'}
					</button>
				</div>
			</Popover>

			{(isAllEmailsValid || isEmailAlreadyInvited) && (
				<div
					className="tw-flex tw-items-start tw-gap-1"
					data-testid="share-modal-email-error-text"
				>
					<AlertIcon />
					<p className="tw-font-randRegular tw-text-xs tw-m-0 red-text">
						{generateWarningText()}
					</p>
				</div>
			)}
			{showInviteNote && (
				<textarea
					className="invite-note tw-flex tw-items-center tw-rounded-2xl tw-text-randRegular tw-text-xs"
					placeholder={`${
						activeTab === 'BLOCK' && reduxBlocks.data[blockId]?.blockType === 'EMPTY'
							? 'You are sharing an empty block, add a note'
							: 'Add a note'
					} (optional)`}
					onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
						setInviteNote(e.target.value)
					}
				/>
			)}
		</>
	);
};

export default InviteUsers;
