import { createAsyncThunk } from '@reduxjs/toolkit';

import axios, { CancelTokenSource } from 'axios';
import {
	ThreeDModelGenRouter,
	aiIntentRouter,
	aiOrganizeRouter,
	aiRenameRouter,
	imageEnhancementAIRouter,
	jobRouter,
	multiselectAIRouter,
	pmJourneyRouter,
	textToTextRouter
} from 'src/endpoints/projects-api';
import { EBlockType, IJob, ITTIGenerationJob, IUser } from '@naya_studio/types';
import { TJobPayload } from 'src/types/payloadTypes';
import {
	TGenerate3DPreviewArgs,
	TGeneratePMJourneyArgs,
	TJobArgs,
	TJobStatusArg,
	TMeshyTaskStatus,
	TCreateJobArgs,
	TGenerateTextArgs,
	TAIRenameArgs,
	TGetAiDigestArgs
} from 'src/types/argTypes';
import { getGatewayKey } from 'src/util/helper/queryString';
import trackEvent from 'src/util/analytics/analytics';
import { BlockEvents } from 'src/util/analytics/events';
import getUserFromRedux from 'src/util/helper/user';
import { TGenAIEventData } from 'src/util/analytics/analytic.types';
import { TGenPresentationType } from 'src/util/block/utilAI';
import { AxiosResponseWrapper } from '../actions/types';
import { generateIdsFromUrl } from './util';

const qs = `?${getGatewayKey()}`;

/**
 * Get job for text to image generation
 */
export const getJob = createAsyncThunk('jobs/status', async (payload: TJobStatusArg, thunkApi) => {
	try {
		const { id, handleGenerateWithAI, reqCancelAI, options } = payload;
		const response = await axios.get<AxiosResponseWrapper<ITTIGenerationJob>>(
			`${jobRouter}/${id}/${qs}`,
			{ cancelToken: reqCancelAI.current.token }
		);
		const job = response.data.payload as ITTIGenerationJob;
		handleGenerateWithAI({ job, options });

		if (job.status === 'FAILED') return thunkApi.rejectWithValue('AI Generation has failed');

		return job;
	} catch (error) {
		if (error instanceof TypeError) {
			return thunkApi.rejectWithValue(error.message);
		}
		// Handle other types of errors
		return thunkApi.rejectWithValue('An error occurred');
	}
});

/**
 * Publish job for text to image generation
 */
export const publishJob = createAsyncThunk('jobs/publish', async (payload: TJobArgs, thunkApi) => {
	const { data, handleGenerateWithAI, reqCancelAI, options } = payload;
	try {
		const { _id: userId } = getUserFromRedux();
		const { projectId } = generateIdsFromUrl();
		const { type, aiType, prompt, _id: jobId } = data;
		const apiPayload: TJobPayload = {
			type,
			status: 'QUEUED',
			_id: jobId,
			prompt,
			aiType,
			createdBy: userId as string,
			projectId
		};
		const response = await axios.post<{
			messageId: string;
			Job: IJob;
		}>(
			`${jobRouter}/publish/${qs}`,
			{
				...apiPayload
			},
			{
				cancelToken: reqCancelAI.current.token
			}
		);

		if (response.data.messageId) {
			handleGenerateWithAI({ job: response.data.Job as ITTIGenerationJob, options });
		}

		return response.data.Job;
	} catch (error) {
		handleGenerateWithAI({ job: { ...data, status: 'FAILED' } as ITTIGenerationJob, options });
		if (error instanceof TypeError) {
			return thunkApi.rejectWithValue(error.message);
		}
		// Handle other types of errors
		return thunkApi.rejectWithValue('An error occurred');
	}
});

/**
 * Saves a Job in the database. Does not create PubSub
 */
export const createJob = createAsyncThunk(
	'jobs/create',
	async (payload: TCreateJobArgs, thunkApi) => {
		const { data } = payload;
		try {
			const user: IUser = getUserFromRedux();
			const apiPayload: TJobPayload = {
				...data,
				createdBy: user._id as string
			};
			const response = await axios.post(`${jobRouter}/${qs}`, {
				...apiPayload
			});

			return response.data.Job;
		} catch (error) {
			console.error('Error createJob', error);
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * API call to super-power api to generate PM journey
 */
export const generatePMJourney = createAsyncThunk(
	'super-power/pm-ai',
	async (payload: TGeneratePMJourneyArgs, thunkApi) => {
		const { reqCancelAI, options, previousStructure } = payload;
		try {
			const response: any = await axios.post<{
				messageId: string;
				Job: IJob;
			}>(
				`${pmJourneyRouter}${qs}${
					window.__RUNTIME_CONFIG__.REACT_APP_MOCK_AI === 'true' ? '&isMock=true' : ''
				}`,
				{
					payload: {
						prompt: options.prompt,
						previousStructure
					}
				},
				{
					cancelToken: reqCancelAI.current.token
				}
			);
			// AI returns data with escaped characters, remove them and store them in stores
			const stages = JSON.parse(
				response.data.choices[0].message.content.replace(/\\"/g, '"')
			);

			// Track pm journey generation event
			const eventProps: TGenAIEventData = {
				blockType: 'PM_JOURNEY',
				prompt: options.prompt
			};
			trackEvent(BlockEvents.GENERATIVE_AI, eventProps);

			return stages;
		} catch (error) {
			console.error('error :', error);
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * API call to super-power api to generate Text
 */
export const generateAiText = createAsyncThunk(
	'super-power/text',
	async (payload: TGenerateTextArgs, thunkApi) => {
		const { prompt, reqCancelAI } = payload;
		try {
			const response: any = await axios.post(
				`${textToTextRouter}${qs}${
					window.__RUNTIME_CONFIG__.REACT_APP_MOCK_AI === 'true' ? '&isMock=true' : ''
				}`,
				{
					payload: {
						prompt
					}
				},
				{
					cancelToken: reqCancelAI.current.token
				}
			);

			// Track text generation event
			const eventProps: TGenAIEventData = {
				blockType: 'TEXT',
				prompt
			};
			trackEvent(BlockEvents.GENERATIVE_AI, eventProps);

			return response.data.original;
		} catch (error) {
			console.error('error :', error);
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * API call to super-power api to generate preview of 3D model from propmt
 */
export const generate3DPreview = createAsyncThunk(
	'super-power/preview-3d',
	async (payload: TGenerate3DPreviewArgs, thunkApi) => {
		const { prompt } = payload;
		try {
			const response = await axios.post(
				`${ThreeDModelGenRouter}/meshy-preview${qs}${
					window.__RUNTIME_CONFIG__.REACT_APP_MOCK_AI === 'true' ? '&isMock=true' : ''
				}`,
				{
					prompt
				}
			);

			return response.data.data.result as string;
		} catch (error) {
			console.error('generate3DPreview error :', error);
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * API call to super-power api to generate refined 3D model from propmt
 */
export const generate3DRefined = createAsyncThunk(
	'super-power/refined-3d',
	async (payload: TGenerate3DPreviewArgs, thunkApi) => {
		const { jobId } = payload;
		try {
			const response = await axios.post(
				`${ThreeDModelGenRouter}/meshy-refine${qs}${
					window.__RUNTIME_CONFIG__.REACT_APP_MOCK_AI === 'true' ? '&isMock=true' : ''
				}`,
				{
					task_id: jobId
				}
			);

			// Track model generation event
			const eventProps: TGenAIEventData = {
				blockType: 'THREE_D',
				prompt: payload?.prompt
			};
			trackEvent(BlockEvents.GENERATIVE_AI, eventProps);

			return response.data.data.result;
		} catch (error) {
			console.error('generate3DRefined error :', error);
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * API call to super-power api to fetch the status of 3D model generation
 * Used for both preview and refined model
 */
export const get3DTaskStatus = createAsyncThunk(
	'super-power/task-status',
	async (payload: TGenerate3DPreviewArgs, thunkApi) => {
		const { projectId } = generateIdsFromUrl();
		const { jobId } = payload;
		try {
			const response = await axios.post<TMeshyTaskStatus>(
				`${ThreeDModelGenRouter}/meshy-status${qs}${
					window.__RUNTIME_CONFIG__.REACT_APP_MOCK_AI === 'true' ? '&isMock=true' : ''
				}`,
				{
					task_id: jobId,
					projectId
				}
			);

			return {
				progress: response.data.data.progress,
				final_link: response.data.data?.final_link,
				thumbnail_url: response.data.data?.thumbnail_url
			};
		} catch (error) {
			console.error('get3DTaskStatus error :', error);
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

/**
 * API call to super-power api to rename blocks and groups
 * @param { TAIRenameArgs} payload
 * @returns
 */
export const handleAIRename = async (payload: TAIRenameArgs) => {
	try {
		const { data, reqCancelAI } = payload;
		const response = await axios.post(
			`${aiRenameRouter}${qs}${
				window.__RUNTIME_CONFIG__.REACT_APP_MOCK_AI === 'true' ? '&isMock=true' : ''
			}`,
			{
				payload: data
			},
			{
				cancelToken: reqCancelAI.current.token
			}
		);

		return response.data.original;
	} catch (error) {
		console.error('handleAIRename error :', error);
		return null;
	}
};

/**
 * API call to super-power api to rename blocks and groups
 * @param { TAIRenameArgs} payload
 * @returns
 */
export const getAISuggestion = async (payload: TAIRenameArgs) => {
	try {
		const { data, reqCancelAI } = payload;
		const response = await axios.post(
			`${aiRenameRouter}/suggestion${qs}${
				window.__RUNTIME_CONFIG__.REACT_APP_MOCK_AI === 'true' ? '&isMock=true' : ''
			}`,
			{
				payload: data
			},
			{
				cancelToken: reqCancelAI.current.token
			}
		);

		return response.data.original.suggestions;
	} catch (error) {
		console.error('ai suggestion error :', error);
		return null;
	}
};

/**
 * API call to super-power api to organize blocks and groups
 * @param { TAIRenameArgs} payload
 * @returns
 */
export const handleAIOrganize = async (payload: TAIRenameArgs) => {
	try {
		const { data, reqCancelAI } = payload;
		const response = await axios.post(
			`${aiOrganizeRouter}${qs}${
				window.__RUNTIME_CONFIG__.REACT_APP_MOCK_AI === 'true' ? '&isMock=true' : ''
			}`,
			{
				payload: data
			},
			{
				cancelToken: reqCancelAI.current.token
			}
		);

		return response.data.original;
	} catch (error) {
		console.error('handleAIOrganize error :', error);
		if (axios.isCancel(error)) return null;
		throw error;
	}
};

/**
 * API call to fetch digests of given blockIds
 */
export const getAiDigests = async (payload: TGetAiDigestArgs) => {
	try {
		const response = await axios.post(
			`${multiselectAIRouter}/ai-digest${qs}${
				window.__RUNTIME_CONFIG__.REACT_APP_MOCK_AI === 'true' ? '&isMock=true' : ''
			}`,
			{
				payload
			}
		);
		return response.data?.aiDigests;
	} catch (error) {
		console.error('getAiDigests error', error);
		throw error;
	}
};

/**
 * Get Prompts for creating concepts/images
 */
export const getConceptPrompts = async (
	payload: any,
	reqCancelAI: React.MutableRefObject<CancelTokenSource>
) => {
	try {
		const response = await axios.post(
			`${multiselectAIRouter}/concept-prompts${qs}${
				window.__RUNTIME_CONFIG__.REACT_APP_MOCK_AI === 'true' ? '&isMock=true' : ''
			}`,
			{
				payload
			},
			{
				cancelToken: reqCancelAI.current.token
			}
		);
		return response.data.prompts;
	} catch (error) {
		console.error('getConceptPrompts error', error);
		throw error;
	}
};

/**
 * For a given prompt, generate its stability AI image
 */
export const generateStabilityAIImage = async (
	payload: any,
	reqCancelAI: React.MutableRefObject<CancelTokenSource>
): Promise<string> => {
	try {
		const { projectId } = generateIdsFromUrl();
		const response = await axios.post(
			`${multiselectAIRouter}/stabilty-img${qs}${
				window.__RUNTIME_CONFIG__.REACT_APP_MOCK_AI === 'true' ? '&isMock=true' : ''
			}`,
			{
				prompt: payload,
				projectId
			},
			{
				cancelToken: reqCancelAI.current.token
			}
		);
		return response.data.links[0];
	} catch (error) {
		console.error('generateStabilityAIImage error', error);
		throw error;
	}
};

/**
 * Upload a firebase file to Tripo
 */
export const uploadToTripo = async (
	payload: any,
	reqCancelAI: React.MutableRefObject<CancelTokenSource>
) => {
	try {
		const response = await axios.post(
			`${multiselectAIRouter}/upload-tripo${qs}${
				window.__RUNTIME_CONFIG__.REACT_APP_MOCK_AI === 'true' ? '&isMock=true' : ''
			}`,
			{
				payload: {
					fileUrl: payload
				}
			},
			{
				cancelToken: reqCancelAI.current.token
			}
		);
		return response.data.response.data.image_token;
	} catch (error) {
		console.error('uploadToTripo error', error);
		throw error;
	}
};

/**
 * Generate 3D from an image
 */
export const generateTripo3dModel = async (
	payload: any,
	reqCancelAI: React.MutableRefObject<CancelTokenSource>
) => {
	try {
		const { projectId } = generateIdsFromUrl();
		const response = await axios.post(
			`${multiselectAIRouter}/tripo-generate${qs}${
				window.__RUNTIME_CONFIG__.REACT_APP_MOCK_AI === 'true' ? '&isMock=true' : ''
			}`,
			{
				payload: {
					image_token: payload,
					projectId
				}
			},
			{
				cancelToken: reqCancelAI.current.token
			}
		);
		return response;
	} catch (error) {
		console.error('generateTripo3dModel error', error);
		throw error;
	}
};

/**
 * Get Prompts for creating summary
 */
export const getSummaryPrompts = async (
	payload: any,
	reqCancelAI: React.MutableRefObject<CancelTokenSource>
) => {
	try {
		const response = await axios.post(
			`${multiselectAIRouter}/summarize-to-text${qs}${
				window.__RUNTIME_CONFIG__.REACT_APP_MOCK_AI === 'true' ? '&isMock=true' : ''
			}`,
			{
				payload
			},
			{
				cancelToken: reqCancelAI.current.token
			}
		);
		return response.data.summary;
	} catch (error) {
		console.error('getConceptPrompts error', error);
		throw error;
	}
};

/**
 * Function to generate presentation by calling superpower API
 */
export const generatePresentation = async (
	payload: any,
	reqCancelAI: React.MutableRefObject<CancelTokenSource>,
	presentationType: TGenPresentationType = 'MULTISELECT',
	prompt?: string
) => {
	try {
		const { projectId } = generateIdsFromUrl();
		const response = await axios.post(
			`${multiselectAIRouter}/generate-presentation${qs}${
				window.__RUNTIME_CONFIG__.REACT_APP_MOCK_AI === 'true' ? '&isMock=true' : ''
			}`,
			{
				payload,
				presentationType,
				projectId,
				prompt
			},
			{
				cancelToken: reqCancelAI.current.token
			}
		);
		return response.data.links[0];
	} catch (error) {
		console.error('generatePresentation error', error);
		throw error;
	}
};

/**
 * Function to get a render from a sketch by calling superpower API
 */
export const imageEnhancement = async (
	payload: any,
	reqCancelAI: React.MutableRefObject<CancelTokenSource>
) => {
	try {
		const { projectId } = generateIdsFromUrl();
		const response = await axios.post(
			`${imageEnhancementAIRouter}/${qs}${
				window.__RUNTIME_CONFIG__.REACT_APP_MOCK_AI === 'true' ? '&isMock=true' : ''
			}`,
			{
				payload,
				projectId
			},
			{
				cancelToken: reqCancelAI.current.token
			}
		);
		return { link: response.data.link, feedbackSummary: response.data.feedbackSummary };
	} catch (error) {
		console.error('imageEnhancement error', error);
		throw error;
	}
};

/**
 * Function to get AI intent given a prompt
 */
export const identifyAiIntent = createAsyncThunk(
	'ai/getIntent',
	async (payload: { prompt: string; blockType: EBlockType }, thunkApi) => {
		try {
			const response = await axios.post(
				`${aiIntentRouter}/${qs}${
					window.__RUNTIME_CONFIG__.REACT_APP_MOCK_AI === 'true' ? '&isMock=true' : ''
				}`,
				{
					payload
				}
			);
			return response.data.original;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);
