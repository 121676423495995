import TimeAgo from 'react-timeago';
import './FeedbackUserMenu.scss';
import { ReactComponent as KebabIcon } from 'src/assets/icons/kebab.svg';
import { UserItem, DropDown, FeedbackTag, FeedbackTagT } from '@naya_studio/radix-ui';
import { useEffect, useState } from 'react';
import _ from 'lodash';

// user menu's props
export type FeedbackKebabMenuProps = {
	options?: {
		key: string;
		callback: () => void;
		tag?: FeedbackTagT;
	}[];
	profilePic: string;
	name: string;
	createdAt?: string;
	open?: boolean;
	type?: 'OPTIONS' | 'TAG';
	onClose?: () => void;
};

/**
 * Component to render the feedback's user menu
 * 1. profile pic
 * 2. name
 * 3. created at time
 * 4. Kebab menu
 */
export const FeedbackUserMenu = ({
	options,
	profilePic,
	name,
	createdAt,
	open,
	type,
	onClose
}: FeedbackKebabMenuProps) => {
	const [showDropdown, setShowDropdown] = useState<boolean>(false);

	// Returns the classes as per type
	const getClassNames = () => {
		if (type === 'TAG') {
			return `tw-flex tw-items-center tw-content-center tw-gap-2 tw-flex-wrap`;
		}
		return 'tw-p-2 dropdown-content tw-rounded-2xl tw-bg-white tw-right-0 tw-w-auto';
	};

	/**
	 * Function to get the option drop down items
	 * @returns Dropdown.Item with tag
	 */
	const getOptionItems = () => {
		if (options) {
			return (
				<div>
					{options.map(
						(opt: { key: string; callback: () => void; tag?: FeedbackTagT }) => (
							<DropDown.DropDownItem>
								<p
									role="presentation"
									onClick={opt.callback}
									className={`user-menu kebab-item tw-m-0 tw-w-auto tw-text-xs tw-font-randRegular 
									tw-leading-6 tw-pt-1 tw-pb-1 tw-px-2 tw-cursor-pointer tw-rounded
									${opt.key.includes('Delete') && 'red-text'}`}
								>
									{opt.key}
								</p>
							</DropDown.DropDownItem>
						)
					)}
				</div>
			);
		}
		return false;
	};

	/**
	 * Function to get the tag drop down items
	 * @returns Dropdown.Item with tag
	 */
	const getTagItems = () => {
		if (options) {
			return (
				<div
					className={`
						tag-popover 
						tw-p-4 tw-max-w-[232px] 
						tw-h-[152px] 
						tw-shadow-[0px_1px_3px_0px_rgba(0,0,0,0.07),0px_4px_8px_3px_rgba(0,0,0,0.04)] 
						tw-rounded-2xl
						tw-bg-white
					`}
				>
					<p className="tw-text-xs tw-font-randRegular tw-text-[#9F9F9F] tw-mb-4 tw-mt-0">
						Select a tag
					</p>
					<div className="tw-flex tw-items-center tw-content-center tw-gap-2 tw-flex-wrap">
						{options.map((opt) => {
							if (opt.tag) {
								const { backgroundColor, name: tagName } = opt.tag;
								return (
									<DropDown.DropDownItem key={tagName}>
										<FeedbackTag
											text={tagName}
											style={{ backgroundColor }}
											onTagClick={() => opt.callback()}
										/>
									</DropDown.DropDownItem>
								);
							}
							return <div />;
						})}
					</div>
				</div>
			);
		}
		return false;
	};

	/**
	 * Function to handle changes in the open state of a dropdown.
	 * @param isOpen - A boolean indicating whether the dropdown is open or closed.
	 */
	const handleOpenChange = (isOpen: boolean) => {
		// Check if the dropdown is being closed and an onClose callback is provided
		if (!isOpen && onClose) {
			// Send out the callback
			onClose();
		}

		// Update the state to reflect the new open state of the dropdown
		setShowDropdown(isOpen);
	};

	// Runs on mount and open prop change
	useEffect(() => {
		if (typeof open === 'boolean') {
			// Added delay here so that the handleOpenChange executed first
			setTimeout(() => {
				setShowDropdown(open);
			}, 50);
		}
	}, [open]);

	return (
		<div className="tw-flex tw-justify-between" id="feedback-user-menu">
			<div className="tw-flex">
				{/* user item - profile pic and name */}
				<UserItem userName={_.capitalize(name)} profilePic={profilePic} />
				{/* show created at time only if time exists */}
				{createdAt && (
					<div className="time-wrapper tw-text-xs tw-flex tw-items-center">
						<TimeAgo date={createdAt} />
					</div>
				)}
			</div>
			{/* show kebab menu only if options exists */}
			{options && options.length > 0 && (
				<DropDown open={showDropdown} onOpenChange={handleOpenChange}>
					<DropDown.DropDownTrigger>
						{/* Kebab icon */}
						<KebabIcon className={`${showDropdown && 'active'}`} />
					</DropDown.DropDownTrigger>
					<DropDown.DropDownContent align="start" classNames={getClassNames()}>
						{type === 'TAG' ? getTagItems() : getOptionItems()}
					</DropDown.DropDownContent>
				</DropDown>
			)}
		</div>
	);
};

FeedbackUserMenu.defaultProps = {
	options: [],
	createdAt: '',
	open: false,
	type: 'OPTIONS',
	onClose: () => {}
};
