import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';
import { Progress } from '@naya_studio/radix-ui';
import { IBlock, IGroup, IImage } from '@naya_studio/types';
import { useEffect, useRef } from 'react';
import { store } from 'src';
import {
	generateStabilityAIImage,
	getAiDigests,
	getConceptPrompts,
	uploadToTripo,
	generateTripo3dModel,
	getSummaryPrompts,
	generatePresentation,
	imageEnhancement
} from 'src/redux/reduxActions/aiGeneration';
import useUser from 'src/redux/hooks/user';
import {
	onDeleteBlock,
	onDeletePhase
} from 'src/components/journeyContainer/JourneyContainerCallbacks';
import axios from 'axios';
import { checkIfExpandedView, generateIdsFromUrl } from 'src/redux/reduxActions/util';
import useLoader from 'src/components/collaborationTool/hooks/useLoader';
import { useHistory } from 'react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useAiGeneration, { AiIcon } from './useAiGeneration';
import {
	EMultiselectAIOpt,
	generatePromptPayload,
	multiselectAiCreateBlocks,
	TMultiSelResultGroupAndBlocks,
	TTripoOutputFormat,
	TupdateSnackbarPayload,
	TGenPresentationType,
	outputOptionString,
	addAIFeedbackReply,
	removeFeedbackAiSessions,
	EAIReplyType
} from './utilAI';
import useBlockActions from './useBlockActions';
import { TGenAIEventData } from '../analytics/analytic.types';
import trackEvent from '../analytics/analytics';
import { BlockEvents } from '../analytics/events';
import useFeedbackActions from '../feedback/useFeedbackActions';

const useAiDigestForAi = () => {
	const { user } = useUser();

	const history = useHistory();

	// Multiselect AI related refs
	const multiSelectAIProgress = useRef(0);
	const isMultiSelectAIinProgressRef = useRef(false);
	const inputMultiSelectBlocksRef = useRef<string[]>([]);
	const feedbackInputRef = useRef<string>();
	const outputStructureRef = useRef<TMultiSelResultGroupAndBlocks[]>([]);
	const regeneratedOutputStructureRef = useRef<TMultiSelResultGroupAndBlocks[]>([]);
	const isOutputRegeneratedRef = useRef(false);
	const multiSelectAIProgressInterval = useRef<NodeJS.Timeout>();
	const multiSelectAIActnBtnTimeout = useRef<NodeJS.Timeout>();
	const isActionButtonClicked = useRef<boolean>(false);

	const { generateOptimisticFeedback } = useFeedbackActions();

	const { undoDeleteBlocks } = useBlockActions();
	const { updateBlocksAndGroup } = useAiGeneration();
	const reduxBlocks = store.getState().blocks.data;
	// ----

	const { convertUrlToCloudinaryUrl } = useLoader();
	const { projectId, blockId: activeBlockId } = generateIdsFromUrl();

	const reqCancelAI = useRef(axios.CancelToken.source());

	const { journeyRtc } = useFlags();

	// Reset action btn click state on block change
	useEffect(() => {
		isActionButtonClicked.current = false;
	}, [activeBlockId]);

	/**
	 * For each prompt, generate a stability AI Image
	 */
	const getLinksOfImagesCreated = async (prompts: string[], count: number = 4) => {
		try {
			const links: string[] = ['', '', '', ''];
			reqCancelAI.current = axios.CancelToken.source();
			const stabiltyAiPromises = prompts.map((prompt) =>
				generateStabilityAIImage(prompt, reqCancelAI)
			);

			const stabiltyAiResponses: PromiseSettledResult<string>[] = await Promise.allSettled(
				stabiltyAiPromises
			);
			let errorCount = 0;
			stabiltyAiResponses.forEach((result, index) => {
				if (result.status === 'fulfilled') {
					links[index] = result.value;
				} else {
					errorCount++;
					console.error(`Operation ${index + 1} failed with reason:`, result?.reason);
				}
			});
			if (errorCount === count) {
				throw new Error('Something went wrong while generating links of images');
			} else {
				return links;
			}
		} catch (error) {
			console.error('error getLinksOfImagesCreated', error);
			throw error;
		}
	};

	// Update multiselect snackbar
	const updateMultiselectAiProgressSnackbar = ({
		start, // show progress & add interval only if start is greater than 0
		end,
		msg,
		snackbarType,
		buttonMsg,
		buttonAction,
		interval,
		showAiIconAtStart,
		forceAdd
	}: TupdateSnackbarPayload) => {
		// clear previous snackbar interval, incase some operation completes sooner
		if (multiSelectAIProgressInterval.current) {
			clearInterval(multiSelectAIProgressInterval.current);
		}
		if (multiSelectAIActnBtnTimeout.current) {
			clearTimeout(multiSelectAIActnBtnTimeout.current);
		}

		// set progress to start
		multiSelectAIProgress.current = start;

		// snackbar wrapper function
		const addProgressSnackbar = () => {
			addSnackbar({
				show: true,
				type: snackbarType,
				actionButtonData: buttonMsg
					? [
							{
								buttonData: buttonMsg as string,
								onClick: () => {
									isActionButtonClicked.current = true;
									if (buttonAction) buttonAction();
								},
								className: 'snackbar-gothere',
								show: true
							}
					  ]
					: [
							{
								// Unicode of cross mark
								buttonData: `\u2715`,
								onClick: () => {
									reqCancelAI.current.cancel();
									isMultiSelectAIinProgressRef.current = false;
									removeSnackbar(0);
									throw new Error('Abort');
								},
								className: 'dismiss',
								show: true
							}
					  ],
				text: (
					<p
						style={{
							display: 'flex',
							alignItems: 'center',
							textAlign: 'start',
							margin: 0
						}}
					>
						{showAiIconAtStart && (
							<img
								src={AiIcon}
								alt="ai-icon"
								height={32}
								width={32}
								className="tw-mr-1"
							/>
						)}
						{msg} &nbsp;
						{start > 0 && (
							<Progress
								variant="PERCENT_LOADER"
								style={{
									width: '176px',
									borderRadius: '16px',
									marginBottom: '1px',
									marginLeft: '5px'
								}}
								percent={multiSelectAIProgress.current}
							/>
						)}
						&nbsp;
						{!buttonMsg && start > 1 && (
							<img src={AiIcon} alt="ai-icon" height={32} width={32} />
						)}
					</p>
				)
			});
		};

		if ((start === 0 && isMultiSelectAIinProgressRef.current) || forceAdd) {
			// this snackbar get added only once and does not update
			addProgressSnackbar();
		} else {
			multiSelectAIProgressInterval.current = setInterval(() => {
				if (multiSelectAIProgress.current < end && isMultiSelectAIinProgressRef.current) {
					multiSelectAIProgress.current++;
					addProgressSnackbar();
				} else if (multiSelectAIProgressInterval.current) {
					// if progress is more than end, then stop updating it
					clearInterval(multiSelectAIProgressInterval.current);
				}
			}, interval || 200);
		}

		multiSelectAIActnBtnTimeout.current = setTimeout(() => {
			// If no buttons are clicked in 5 seconds, remove the snackbar
			if ((buttonAction && !isActionButtonClicked.current) || forceAdd) {
				removeSnackbar(0);
			}
		}, 5000);
	};

	/**
	 * Wrapper function for all multiselect AI functionality related to concepts
	 */
	// eslint-disable-next-line consistent-return
	const handleGenerateConcepts = async (noOfConcepts: number = 4) => {
		try {
			// Step 1
			updateMultiselectAiProgressSnackbar({
				start: 1,
				end: 3,
				msg: isOutputRegeneratedRef.current
					? 'Regenerating concepts'
					: 'Generating concepts',
				snackbarType: 'NORMAL'
			});
			//  Fetch the AI Digest for the required blocks
			const aiDigests = await getAiDigests({
				blockIds: inputMultiSelectBlocksRef.current
			});
			// -----

			// Step 2
			updateMultiselectAiProgressSnackbar({
				start: 3,
				end: 5,
				msg: isOutputRegeneratedRef.current
					? 'Regenerating concepts'
					: 'Generating concepts',
				snackbarType: 'NORMAL'
			});
			// Create input data for Prompt using aiDigest
			const conceptPromptInput = generatePromptPayload(
				aiDigests,
				inputMultiSelectBlocksRef.current
			);
			// -----

			// Step 3
			updateMultiselectAiProgressSnackbar({
				start: 5,
				end: 45,
				msg: isOutputRegeneratedRef.current
					? 'Regenerating concepts'
					: 'Generating concepts',
				snackbarType: 'NORMAL'
			});
			reqCancelAI.current = axios.CancelToken.source();
			// API to getConceptPrompts to create prompts
			const prompts = await getConceptPrompts(
				{
					conceptPromptInput,
					noOfConcepts,
					inputPrompt: feedbackInputRef.current || undefined
				},
				reqCancelAI
			);
			// -----

			// Step 4
			updateMultiselectAiProgressSnackbar({
				start: 45,
				end: 85,
				msg: isOutputRegeneratedRef.current
					? 'Regenerating concepts'
					: 'Generating concepts',
				snackbarType: 'NORMAL',
				interval: 350
			});

			//  For each prompt, create a stability AI and gets its link
			const links = await getLinksOfImagesCreated(prompts, noOfConcepts);

			return { conceptLinks: links, prompts };
			// -----
		} catch (error) {
			console.error('error caught in handleGenerateConcepts', error);
			throw error;
		}
	};

	// Function to generate multiselect summary and showing the progress bar
	const handleGenerateSummary = async (
		type: string,
		snackbarMsg: string,
		regeneratingSnackMsg: string
	) => {
		try {
			// Step 1
			updateMultiselectAiProgressSnackbar({
				start: 1,
				end: 3,
				msg: isOutputRegeneratedRef.current ? regeneratingSnackMsg : snackbarMsg,
				snackbarType: 'NORMAL'
			});
			//  Fetch the AI Digest for the required blocks
			const aiDigests = await getAiDigests({
				blockIds: inputMultiSelectBlocksRef.current
			});

			// Step 2
			updateMultiselectAiProgressSnackbar({
				start: 3,
				end: 5,
				msg: isOutputRegeneratedRef.current ? regeneratingSnackMsg : snackbarMsg,
				snackbarType: 'NORMAL'
			});
			// Create input data for Prompt using aiDigest
			const conceptPromptInput = generatePromptPayload(
				aiDigests,
				inputMultiSelectBlocksRef.current
			);

			// Step 3
			updateMultiselectAiProgressSnackbar({
				start: 5,
				end: 95,
				msg: isOutputRegeneratedRef.current ? regeneratingSnackMsg : snackbarMsg,
				snackbarType: 'NORMAL'
			});
			// API to getConceptPrompts to create prompts
			reqCancelAI.current = axios.CancelToken.source();
			const summary = await getSummaryPrompts(
				{
					type,
					data: conceptPromptInput,
					inputPrompt: feedbackInputRef.current || undefined
				},
				reqCancelAI
			);
			return summary;
		} catch (error) {
			console.error('error caught in handleGenerateConcepts', error);
			throw error;
		}
	};

	// Function to generate multiselect presentation and showing the progress bar
	const handleGeneratePresentation = async (
		presentationType: TGenPresentationType,
		option: EMultiselectAIOpt
	) => {
		try {
			// Step 1
			updateMultiselectAiProgressSnackbar({
				start: 1,
				end: 3,
				msg: isOutputRegeneratedRef.current
					? 'Regenerating presentation'
					: 'Generating presentation',
				snackbarType: 'NORMAL',
				interval: 300
			});
			//  Fetch the AI Digest for the required blocks
			const aiDigests = await getAiDigests({
				blockIds: inputMultiSelectBlocksRef.current
			});

			// Step 2
			updateMultiselectAiProgressSnackbar({
				start: 3,
				end: 5,
				msg: isOutputRegeneratedRef.current
					? 'Regenerating presentation'
					: 'Generating presentation',
				snackbarType: 'NORMAL',
				interval: 300
			});
			// Create input data for Prompt using aiDigest
			const conceptPromptInput = generatePromptPayload(
				aiDigests,
				inputMultiSelectBlocksRef.current,
				['link'],
				option
			);

			// Step 3
			updateMultiselectAiProgressSnackbar({
				start: 5,
				end: 95,
				msg: isOutputRegeneratedRef.current
					? 'Regenerating presentation'
					: 'Generating presentation',
				snackbarType: 'NORMAL',
				interval: 300
			});
			// API to getConceptPrompts to create prompts
			reqCancelAI.current = axios.CancelToken.source();
			const presentation = await generatePresentation(
				conceptPromptInput,
				reqCancelAI,
				presentationType,
				feedbackInputRef.current || undefined
			);
			return presentation;
		} catch (error) {
			console.error('error caught in handleGeneratePresentation', error);
			throw error;
		}
	};

	/**
	 * Init function for starting any multiselect AI Job
	 */
	const startMultiselectAIProgress = (
		blockIds: string[],
		groupIds: string[],
		inputPrompt?: string
	) => {
		feedbackInputRef.current = inputPrompt || '';
		removeFeedbackAiSessions();

		multiSelectAIProgress.current = 0;
		isMultiSelectAIinProgressRef.current = true;
		inputMultiSelectBlocksRef.current = blockIds;
		// loop through the groups to fetch the blockIds
		const groupRedux = store.getState().stages.data;
		if (!checkIfExpandedView()) {
			for (let i = 0; i < groupIds.length; i++) {
				inputMultiSelectBlocksRef.current.push(
					...((groupRedux[groupIds[i] as string] as IGroup).children as string[])
				);
			}
		}
		const blockRedux = store.getState().blocks.data;
		let i = 0;
		while (i < inputMultiSelectBlocksRef.current.length) {
			const bid = inputMultiSelectBlocksRef.current[i] as string;

			// If blockRedux does not contain the blockId
			if (!blockRedux[bid]) {
				const group = groupRedux[bid] as IGroup;

				if (group?.children) {
					// Add the children of the group to the end of the array
					inputMultiSelectBlocksRef.current.push(...(group.children as string[]));
				}
				// remove the group from the inputSelectBlocksRef
				inputMultiSelectBlocksRef.current.splice(i, 1);
			}

			i++;
		}

		outputStructureRef.current = [] as TMultiSelResultGroupAndBlocks[];
		regeneratedOutputStructureRef.current = [] as TMultiSelResultGroupAndBlocks[];
		isOutputRegeneratedRef.current = false;
		multiSelectAIProgress.current = 2;
	};

	// Handle Regeneration of Concepts
	const handleRegenerate = (
		option: EMultiselectAIOpt,
		subOption?: string,
		noOfConcepts?: number
	) => {
		try {
			multiSelectAIProgress.current = 0;
			isOutputRegeneratedRef.current = true;
			multiSelectAIProgress.current = 2;
			// eslint-disable-next-line  @typescript-eslint/no-use-before-define
			processMultiselectAI(option, subOption, noOfConcepts);
		} catch (error) {
			console.error('handleRegenerate error', error);
		}
	};

	/**
	 * Wrapper function to handle all 3D functionality for Multiselect
	 */
	const handleGenerate3DMultiselect = async (links: string[]) => {
		try {
			updateMultiselectAiProgressSnackbar({
				start: 1,
				end: 20,
				msg: 'Generating 3D model',
				snackbarType: 'NORMAL'
			});
			reqCancelAI.current = axios.CancelToken.source();
			const uploadToTripoPromises = links.map((link) => uploadToTripo(link, reqCancelAI));

			const uploadToTripoResponses = await Promise.allSettled(uploadToTripoPromises);
			const uploadTokens = ['', '', '', ''];
			let errorCount = 0;
			uploadToTripoResponses.forEach((result, index) => {
				if (result.status === 'fulfilled') {
					uploadTokens[index] = result.value;
				} else {
					errorCount++;
					console.error(
						`Operation upload to Tripo ${index + 1} failed with reason:`,
						result?.reason
					);
				}
			});

			if (errorCount === 4) {
				throw new Error('Upload to Tripo failed for all images');
			} else {
				errorCount = 0;
			}

			const generateTripo3dModelPromises = uploadTokens.map((uploadToken) => {
				if (uploadToken !== '') {
					reqCancelAI.current = axios.CancelToken.source();
					return generateTripo3dModel(uploadToken, reqCancelAI);
				}

				// required to maintain sequence
				return new Promise((_, reject) => {
					setTimeout(() => reject(new Error('Error as no uplaod token')), 200);
				});
			});

			updateMultiselectAiProgressSnackbar({
				start: 20,
				end: 90,
				msg: 'Generating 3D model',
				snackbarType: 'NORMAL'
			});

			const generateTripo3dModelResponses = await Promise.allSettled(
				generateTripo3dModelPromises
			);
			const threeDLinks: TTripoOutputFormat[] = [
				{} as TTripoOutputFormat,
				{} as TTripoOutputFormat,
				{} as TTripoOutputFormat,
				{} as TTripoOutputFormat
			];
			generateTripo3dModelResponses.forEach((result, index) => {
				if (result.status === 'fulfilled') {
					threeDLinks[index] = (result.value as any).data.output as TTripoOutputFormat;
				} else {
					errorCount++;
					console.error(
						`Operation generate 3D model ${index + 1} failed with reason:`,
						result?.reason
					);
				}
			});
			if (errorCount === 4) {
				throw new Error('Generating 3D Model failed for all images');
			} else {
				return threeDLinks;
			}
		} catch (error) {
			console.error('error', error);
			throw error;
		}
	};

	// Function for generating proper error messages
	const generateErrorMsg = (option: EMultiselectAIOpt) => {
		switch (option) {
			case 'GENERATE_3D_MODEL':
			case 'GENERATE_CONCEPTS': {
				updateMultiselectAiProgressSnackbar({
					start: 0,
					end: 100,
					msg:
						'Unable to generate concepts. Add more assets or select different blocks.' +
						' Try text blocks for best results.',
					snackbarType: 'ERROR'
				});
				break;
			}
			case 'SUMMARIZE': {
				isActionButtonClicked.current = false;
				updateMultiselectAiProgressSnackbar({
					start: 0,
					end: 100,
					msg:
						'Unable to generate summary. Add more assets or select different blocks.' +
						' Try text blocks for best results.',
					buttonMsg: 'Add a text block',
					buttonAction: async () => {
						await multiselectAiCreateBlocks(
							[],
							option,
							user._id as string,
							isOutputRegeneratedRef.current,
							isMultiSelectAIinProgressRef.current,
							inputMultiSelectBlocksRef.current,
							'',
							undoDeleteBlocks,
							updateBlocksAndGroup,
							feedbackInputRef.current,
							!!feedbackInputRef.current,
							!feedbackInputRef.current
						);
						removeSnackbar(0);
					},
					snackbarType: 'ERROR'
				});
				break;
			}
			case 'GENERATE_PRESENTATION': {
				updateMultiselectAiProgressSnackbar({
					start: 0,
					end: 100,
					msg: 'Unable to generate presentation. Please try again.',
					snackbarType: 'ERROR'
				});
				break;
			}
			case 'EDIT_IMAGE_WITH_FEEDBACK':
			case 'GENERATE_RENDER': {
				updateMultiselectAiProgressSnackbar({
					start: 0,
					end: 100,
					msg: 'Unable to generate renders. Please try again.',
					snackbarType: 'ERROR'
				});
				break;
			}
			default: {
				break;
			}
		}
		removeSnackbar(5000);
	};

	/**
	 * Replaces an older summary by deleting a specified phase and updating the UI accordingly.
	 * Additionally, it sets up undo functionality for the delete action.
	 *
	 * @param {string} phaseId - The ID of the phase to delete.
	 * @param {boolean} [isUndoRedo=false] - Indicates whether the action is part of an undo/redo operation.
	 *
	 */
	const handleReplaceOlder = (
		phaseId: string,
		option: EMultiselectAIOpt,
		isUndoRedo: boolean = false,
		createNewPhase: boolean = true // handle feedback Ai scenario where response is added to the same phase
	) => {
		const groups = store.getState().stages.data;
		const blocks = store.getState().blocks.data;

		const projects = store.getState().projects.data;
		const project = projects[projectId];

		const groupToUndo = groups[phaseId];
		const blocksToUndo = [] as IBlock[];
		if (groupToUndo) {
			groupToUndo.children.forEach((b) => {
				blocksToUndo.push(blocks[b as string] as IBlock);
			});
		}
		if (
			option === 'EDIT_IMAGE_WITH_FEEDBACK' ||
			option === 'GENERATE_RENDER' ||
			!createNewPhase
		) {
			const { groupId, blockIds } = (
				outputStructureRef.current as TMultiSelResultGroupAndBlocks[]
			)[0] as TMultiSelResultGroupAndBlocks;
			onDeleteBlock(blockIds, [groupId]);
		} else onDeletePhase(phaseId);
		setTimeout(() => {
			isActionButtonClicked.current = false;
			updateMultiselectAiProgressSnackbar({
				start: 0,
				end: 100,
				msg: `Replaced older ${outputOptionString(option)}!`,
				snackbarType: 'NORMAL',
				buttonMsg: 'Undo',
				buttonAction: () => {
					removeSnackbar(0);
					window.Naya.handleUndoRedo({ type: 'UNDO' });
				}
			});

			// Save history when user removes older block
			if (!isUndoRedo) {
				window.Naya.handleUndoRedo({
					type: 'ADD',
					payload: {
						originalAction: onDeletePhase,
						oppositeAction: undoDeleteBlocks,
						originalActionPayload: [phaseId],
						oppositeActionPayload: [
							blocksToUndo,
							[groupToUndo],
							undefined,
							project?.children
						]
					}
				});
			}
		}, 100);
	};

	/**
	 * Updates the session storage with AI data for a given key, block ID, group ID, and AI operation type.
	 *
	 * @param {string} key - The key under which the AI data will be stored in session storage.
	 * @param {string | undefined} blockId - The ID of the block that is being processed. If undefined, no data is stored.
	 * @param {string | undefined} groupId - The ID of the previous block phase group. This can be undefined if not applicable.
	 * @param {string} aiType - The type of AI operation being performed (e.g., GENERATE_3D_MODEL, SUMMARIZE).
	 */
	const updateAIData = (
		key: string,
		blockId: string | undefined,
		groupId: string | undefined,
		aiType: string
	) => {
		if (blockId) {
			const AIData = {
				[blockId]: {
					blockId: inputMultiSelectBlocksRef.current[0],
					prevBlockPhase: groupId,
					aiType
				}
			};
			window.sessionStorage.setItem(key, JSON.stringify(AIData));
		}
	};

	const handleSuccess = async (
		option: EMultiselectAIOpt,
		subOption?: string,
		noOfConcepts: number = 4
	) => {
		isActionButtonClicked.current = false;

		const isExapandedView = checkIfExpandedView();

		let msgText = 'Concepts';
		switch (option) {
			case 'GENERATE_3D_MODEL':
				msgText = '3D model';
				break;
			case 'GENERATE_CONCEPTS':
				msgText = `Concept${noOfConcepts > 1 ? 's' : ''}`;
				break;
			case 'GENERATE_NEXT_STEPS':
				msgText = 'Next Steps';
				break;
			case 'SUMMARIZE':
				msgText = 'Summary';
				break;
			case 'GENERATE_PRESENTATION':
				msgText = 'Presentation';
				break;
			case 'EDIT_IMAGE_WITH_FEEDBACK':
				msgText = 'Edited image';
				break;
			case 'GENERATE_RENDER':
				msgText = 'Render';
				break;
			default:
				msgText = 'Summary';
				break;
		}

		// Get the appropriate message for the snackbar based on view and regeneration status
		const getMessage = () => {
			if (isExapandedView) return 'generated!';
			if (isOutputRegeneratedRef.current) return 'regenerated';
			return 'generated! Add more assets or select different blocks for better results.';
		};

		const getButtonMessage = () => {
			if (isExapandedView) return 'Go there';
			return isOutputRegeneratedRef.current ? 'Replace older' : 'Regenerate';
		};

		const groupIndex = 0;

		if (isExapandedView) {
			const currentGroup = isOutputRegeneratedRef.current
				? regeneratedOutputStructureRef.current[groupIndex]
				: outputStructureRef.current[groupIndex];

			if (currentGroup) {
				if (!isOutputRegeneratedRef.current) {
					addAIFeedbackReply(
						generateOptimisticFeedback(),
						projectId,
						currentGroup.groupId,
						currentGroup.blockIds[0] as string,
						EAIReplyType.CUSTOM,
						journeyRtc
					);
				}
				updateAIData(
					isOutputRegeneratedRef.current ? 'ai-regenerate-data' : 'ai-generate-data',
					currentGroup.blockIds[0],
					isOutputRegeneratedRef.current
						? outputStructureRef.current[groupIndex]?.groupId
						: undefined,
					option
				);
			}
		}

		updateMultiselectAiProgressSnackbar({
			start: 0,
			end: 100,
			msg: `${msgText} ${getMessage()}`,
			snackbarType: 'NORMAL',
			buttonMsg: getButtonMessage(),
			showAiIconAtStart: !!(isExapandedView && feedbackInputRef.current),
			buttonAction: () => {
				if (isExapandedView) {
					const group = isOutputRegeneratedRef.current
						? regeneratedOutputStructureRef.current[groupIndex]
						: outputStructureRef.current[groupIndex];

					if (group) {
						history.push(`/project/${projectId}/${group.groupId}/${group.blockIds[0]}`);
					}
				} else if (isOutputRegeneratedRef.current) {
					handleReplaceOlder(
						(outputStructureRef.current[0] as TMultiSelResultGroupAndBlocks).groupId,
						option
					);
				} else {
					if (subOption) handleRegenerate(option, subOption);
					else handleRegenerate(option);
					isMultiSelectAIinProgressRef.current = true;
				}
			}
		});
	};

	// Sketch to render conversion and adding the created block
	const handleSketchToRender = async (option: EMultiselectAIOpt) => {
		try {
			const imageBlock = reduxBlocks[
				inputMultiSelectBlocksRef.current[0] as string
			] as IImage;

			const imageEnhancementPayload = {
				model: 'STABLE_DIFFUSION',
				action: option === 'GENERATE_RENDER' ? 'SKETCH' : 'STRUCTURE',
				imageURL: convertUrlToCloudinaryUrl(imageBlock.link).replace('w_500', 'w_1024'),
				fileName: imageBlock.fileName,
				projectId,
				blockId: inputMultiSelectBlocksRef.current,
				inputPrompt: feedbackInputRef.current || undefined
			};
			let snackbarMsg = '';
			if (option === 'GENERATE_RENDER') {
				if (isOutputRegeneratedRef.current) {
					snackbarMsg = 'Regenerating render';
				} else {
					snackbarMsg = 'Generating render';
				}
			} else if (option === 'EDIT_IMAGE_WITH_FEEDBACK') {
				if (isOutputRegeneratedRef.current) {
					snackbarMsg = 'Editing image again';
				} else {
					snackbarMsg = 'Editing image';
				}
			}
			updateMultiselectAiProgressSnackbar({
				start: 5,
				end: 90,
				msg: snackbarMsg,
				snackbarType: 'NORMAL',
				interval: 100
			});
			reqCancelAI.current = axios.CancelToken.source();

			const { link, feedbackSummary } = await imageEnhancement(
				imageEnhancementPayload,
				reqCancelAI
			);

			updateMultiselectAiProgressSnackbar({
				start: 90,
				end: 100,
				msg: isOutputRegeneratedRef.current ? 'Regenerating render' : 'Generating render',
				snackbarType: 'NORMAL',
				interval: 100
			});
			const phaseAndBlocksAdded = await multiselectAiCreateBlocks(
				[link],
				option,
				user._id as string,
				isOutputRegeneratedRef.current,
				isMultiSelectAIinProgressRef.current,
				inputMultiSelectBlocksRef.current,
				'',
				undoDeleteBlocks,
				updateBlocksAndGroup,
				feedbackSummary,
				!!feedbackInputRef.current,
				!feedbackInputRef.current
			);

			if (phaseAndBlocksAdded && Object.keys(phaseAndBlocksAdded).length === 0) return;

			if (!isOutputRegeneratedRef.current) {
				if (phaseAndBlocksAdded) outputStructureRef.current.push(phaseAndBlocksAdded);
			} else if (phaseAndBlocksAdded)
				regeneratedOutputStructureRef.current.push(phaseAndBlocksAdded);

			// handle Sucess
			handleSuccess(option);
		} catch (error) {
			console.error('sketch to render', error);
			throw error;
		}
	};

	/**
	 * Handles the process of generating a 3D model from an image with optional edits.
	 * This includes image enhancement, 3D model generation, and updating the UI with progress.
	 *
	 * @async
	 * @function handleGenerate3DWithEdit
	 * @returns {Promise<void>} A promise that resolves when the 3D model generation is complete.
	 */
	const handleGenerate3DWithEdit = async () => {
		updateMultiselectAiProgressSnackbar({
			start: 5,
			end: 90,
			msg: 'Generating render',
			snackbarType: 'NORMAL',
			interval: 100
		});

		const imageBlock = reduxBlocks[inputMultiSelectBlocksRef.current[0] as string] as IImage;

		const imageEnhancementPayload = {
			model: 'STABLE_DIFFUSION',
			action: 'SKETCH',
			imageURL: convertUrlToCloudinaryUrl(imageBlock.link).replace('w_500', 'w_1024'),
			fileName: imageBlock.fileName,
			projectId,
			blockId: inputMultiSelectBlocksRef.current,
			inputPrompt: feedbackInputRef.current || undefined
		};

		reqCancelAI.current = axios.CancelToken.source();

		const { link } = await imageEnhancement(imageEnhancementPayload, reqCancelAI);
		updateMultiselectAiProgressSnackbar({
			start: 90,
			end: 100,
			msg: 'Generating render',
			snackbarType: 'NORMAL',
			interval: 100
		});

		const output = await handleGenerate3DMultiselect([link]);
		updateMultiselectAiProgressSnackbar({
			start: 90,
			end: 99,
			msg: 'Generating 3D model',
			snackbarType: 'NORMAL'
		});

		// create a phase and add the blocks
		const threeDBlocksAndGroup = await multiselectAiCreateBlocks(
			output as TTripoOutputFormat[],
			'GENERATE_3D_MODEL',
			user._id as string,
			isOutputRegeneratedRef.current,
			isMultiSelectAIinProgressRef.current,
			inputMultiSelectBlocksRef.current,
			'',
			undoDeleteBlocks,
			updateBlocksAndGroup,
			feedbackInputRef.current,
			!!feedbackInputRef.current,
			!feedbackInputRef.current
		);

		if (threeDBlocksAndGroup && Object.keys(threeDBlocksAndGroup).length === 0) return;

		if (!isOutputRegeneratedRef.current) {
			if (threeDBlocksAndGroup) outputStructureRef.current.push(threeDBlocksAndGroup);
		} else if (threeDBlocksAndGroup)
			regeneratedOutputStructureRef.current.push(threeDBlocksAndGroup);

		// Handle Generation/Regeneration Sucess
		handleSuccess('GENERATE_3D_MODEL');
	};

	/**
	 * Wrapper function to process multiselect ai based on option type
	 */
	const processMultiselectAI = async (
		option: EMultiselectAIOpt,
		subOption?: string,
		noOfConcepts: number = 4
	) => {
		try {
			switch (option) {
				case 'GENERATE_3D_MODEL_WITH_EDIT':
					handleGenerate3DWithEdit();
					break;
				case 'GENERATE_3D_MODEL':
				case 'GENERATE_CONCEPTS': {
					/**
					 * If only one image is selected and we want to convert it to 3D
					 */
					let oneImageTo3D = false;
					let links;
					if (
						option === 'GENERATE_3D_MODEL' &&
						((inputMultiSelectBlocksRef.current.length === 1 &&
							reduxBlocks[inputMultiSelectBlocksRef.current[0] as string]
								?.blockType === 'IMAGE') ||
							checkIfExpandedView())
					) {
						oneImageTo3D = true;
						links = [
							(reduxBlocks[inputMultiSelectBlocksRef.current[0] as string] as IImage)
								.link
						];
					}

					if (!oneImageTo3D) {
						const { conceptLinks, prompts } = await handleGenerateConcepts(
							noOfConcepts
						);
						links = conceptLinks;
						// Handle Progress Bar
						updateMultiselectAiProgressSnackbar({
							start: 95,
							end: 100,
							msg: isOutputRegeneratedRef.current
								? 'Regenerating concepts'
								: `Generating concept${noOfConcepts > 1 ? 's' : ''}`,
							snackbarType: 'NORMAL'
						});
						// create a phase and add the blocks
						const phaseAndBlocksAdded = await multiselectAiCreateBlocks(
							links as string[],
							option,
							user._id as string,
							isOutputRegeneratedRef.current,
							isMultiSelectAIinProgressRef.current,
							inputMultiSelectBlocksRef.current,
							'',
							undoDeleteBlocks,
							updateBlocksAndGroup,
							feedbackInputRef.current,
							!!feedbackInputRef.current,
							!feedbackInputRef.current
						);

						if (phaseAndBlocksAdded && Object.keys(phaseAndBlocksAdded).length === 0)
							return;

						if (!isOutputRegeneratedRef.current) {
							if (phaseAndBlocksAdded)
								outputStructureRef.current.push(phaseAndBlocksAdded);
						} else if (phaseAndBlocksAdded)
							regeneratedOutputStructureRef.current.push(phaseAndBlocksAdded);

						if (option === 'GENERATE_CONCEPTS') {
							// Handle Generation/Regeneration Sucess
							handleSuccess(option);

							// Track generate concepts event
							const eventProps: TGenAIEventData = {
								blockType: option,
								prompts,
								links
							};
							trackEvent(BlockEvents.GENERATED_PROMPTS, eventProps);
						}
					}

					// if option is GENERATE_3D_MODEL, then start the process of generating 3D Model
					if (option === 'GENERATE_3D_MODEL' && links && links?.length) {
						const output = await handleGenerate3DMultiselect(links as string[]);
						updateMultiselectAiProgressSnackbar({
							start: 90,
							end: 99,
							msg: 'Generating 3D model',
							snackbarType: 'NORMAL'
						});

						// create a phase and add the blocks
						const threeDBlocksAndGroup = await multiselectAiCreateBlocks(
							output as TTripoOutputFormat[],
							option,
							user._id as string,
							isOutputRegeneratedRef.current,
							isMultiSelectAIinProgressRef.current,
							inputMultiSelectBlocksRef.current,
							'',
							undoDeleteBlocks,
							updateBlocksAndGroup,
							feedbackInputRef.current,
							!!feedbackInputRef.current,
							!feedbackInputRef.current
						);

						if (threeDBlocksAndGroup && Object.keys(threeDBlocksAndGroup).length === 0)
							return;

						if (!isOutputRegeneratedRef.current) {
							if (threeDBlocksAndGroup)
								outputStructureRef.current.push(threeDBlocksAndGroup);
						} else if (threeDBlocksAndGroup)
							regeneratedOutputStructureRef.current.push(threeDBlocksAndGroup);

						// Handle Generation/Regeneration Sucess
						handleSuccess(option);
					}
					break;
				}
				case 'SUMMARIZE':
				case 'EVALUATE_SUSTAINABILITY':
				case 'EVALUATE_MANUFACTURING':
				case 'GENERATE_ESTIMATE': {
					let type: string;
					let snackbarMsg: string;
					let regeneratingSnackMsg: string;

					switch (option) {
						case 'EVALUATE_SUSTAINABILITY':
							type = 'sustainability';
							snackbarMsg = 'Evaluating sustainability';
							regeneratingSnackMsg = 'Re-evaluating sustainability';
							break;
						case 'GENERATE_ESTIMATE':
							type = 'estimate';
							snackbarMsg = 'Generating estimate';
							regeneratingSnackMsg = 'Regenerating estimate';
							break;
						case 'EVALUATE_MANUFACTURING':
							type = 'manufacturing';
							snackbarMsg = 'Evaluating manufacture';
							regeneratingSnackMsg = 'Re-evaluating manufacture';
							break;
						default:
							type = 'summary';
							snackbarMsg = 'Generating summary';
							regeneratingSnackMsg = 'Regenerating summary';
							break;
					}
					const summary = await handleGenerateSummary(
						type,
						snackbarMsg,
						regeneratingSnackMsg
					);
					// Handle Progress Bar
					updateMultiselectAiProgressSnackbar({
						start: 95,
						end: 100,
						msg: isOutputRegeneratedRef.current ? regeneratingSnackMsg : snackbarMsg,
						snackbarType: 'NORMAL'
					});
					// create a phase and add the blocks
					const summarisedGroupAndBlocks = await multiselectAiCreateBlocks(
						[],
						option,
						user._id as string,
						isOutputRegeneratedRef.current,
						isMultiSelectAIinProgressRef.current,
						inputMultiSelectBlocksRef.current,
						summary,
						undoDeleteBlocks,
						updateBlocksAndGroup,
						feedbackInputRef.current,
						!!feedbackInputRef.current,
						!feedbackInputRef.current
					);

					if (
						summarisedGroupAndBlocks &&
						Object.keys(summarisedGroupAndBlocks).length === 0
					)
						return;

					if (!isOutputRegeneratedRef.current) {
						if (summarisedGroupAndBlocks)
							outputStructureRef.current.push(summarisedGroupAndBlocks);
					} else if (summarisedGroupAndBlocks)
						regeneratedOutputStructureRef.current.push(summarisedGroupAndBlocks);

					// Handle Generation/Regeneration Sucess
					handleSuccess(option);
					break;
				}
				case 'GENERATE_PRESENTATION': {
					const presentation = await handleGeneratePresentation(
						subOption as TGenPresentationType,
						option
					);
					// Handle Progress Bar
					updateMultiselectAiProgressSnackbar({
						start: 95,
						end: 100,
						msg: isOutputRegeneratedRef.current
							? 'Regenerating presentation'
							: 'Generating presentation',
						snackbarType: 'NORMAL',
						interval: 300
					});
					// create a phase and add the blocks
					const phaseAndBlocksAdded = await multiselectAiCreateBlocks(
						[],
						option,
						user._id as string,
						isOutputRegeneratedRef.current,
						isMultiSelectAIinProgressRef.current,
						inputMultiSelectBlocksRef.current,
						presentation,
						undoDeleteBlocks,
						updateBlocksAndGroup,
						feedbackInputRef.current,
						!!feedbackInputRef.current,
						!feedbackInputRef.current
					);

					if (phaseAndBlocksAdded && Object.keys(phaseAndBlocksAdded).length === 0)
						return;

					if (!isOutputRegeneratedRef.current) {
						if (phaseAndBlocksAdded)
							outputStructureRef.current.push(phaseAndBlocksAdded);
					} else if (phaseAndBlocksAdded)
						regeneratedOutputStructureRef.current.push(phaseAndBlocksAdded);

					// handle Sucess
					handleSuccess(option, subOption);

					break;
				}
				case 'EDIT_IMAGE_WITH_FEEDBACK':
				case 'GENERATE_RENDER': {
					await handleSketchToRender(option);
					break;
				}
				default: {
					console.error('No case');
				}
			}

			if (option !== 'GENERATE_CONCEPTS') {
				// Track generate concepts event
				const eventProps: TGenAIEventData = {
					blockType: option
				};
				trackEvent(BlockEvents.GENERATIVE_AI, eventProps);
			}
		} catch (error) {
			console.error('error in process');
			generateErrorMsg(option);
		}
	};
	/**
	 * Wrapper function that starts, processes, success, error, abort and regenerate multiselected ai flow
	 * @param option | 'GENERATE_3D_MODEL'| 'SUMMARIZE'| 'GENERATE_NEXT_STEPS'| 'GENERATE_CONCEPTS'
	 * 							 | 'GENERATE_PRESENTATION' |
	 * @param blockIds - multiselected block ids
	 * @param groupIds - multiselected group ids
	 * @param subOption - subOption for generation features with multiple options
	 */
	const handleMultiselectAIWrapper = (
		option: EMultiselectAIOpt,
		blockIds: string[],
		groupIds: string[],
		subOption?: string,
		noOfConcepts?: number,
		inputPrompt?: string
	) => {
		// initialise all multiselect AI related features
		startMultiselectAIProgress(blockIds, groupIds, inputPrompt);
		processMultiselectAI(option, subOption, noOfConcepts);
	};

	return {
		handleMultiselectAIWrapper,
		handleRegenerate,
		updateMultiselectAiProgressSnackbar,
		handleReplaceOlder
	};
};

export default useAiDigestForAi;
