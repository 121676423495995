import { TEditGroupCallbackArgs, IGroup } from '@naya_studio/types';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch, store } from 'src';
import { CollaborationToolContext } from 'src/components/collaborationTool/CollaborationTool';
import { CustomDispatch } from 'src/redux/actions/types';
import { editStage } from 'src/redux/reduxActions/stage';
import { generateIdsFromUrl } from 'src/redux/reduxActions/util';
import { TEditStageThunkArg } from 'src/types/argTypes';
import trackEvent from '../analytics/analytics';
import { CustomEvents } from '../analytics/events';
import { TAddDateEventData } from '../analytics/analytic.types';

const useGroupActions = () => {
	const dispatch = useDispatch<AppDispatch>();
	const collabContext = useContext(CollaborationToolContext);
	const { handleUndoRedo } = collabContext;

	const onEditGroup = async (
		{ editType, groupId, payload: editPayload = undefined }: TEditGroupCallbackArgs,
		isUndoRedo: boolean = false
	) => {
		const reduxState = store.getState();
		const groups = reduxState.stages.data;
		const { projectId } = generateIdsFromUrl();

		switch (editType) {
			case 'ADD_DUE_DATE':
			case 'ADD_RANGE': {
				const actionPayload: TEditStageThunkArg = {
					payload: {
						id: groupId,
						update: {
							...editPayload,
							_id: groupId
						},
						projectId
					},
					prevState: {
						prevStage: groups[groupId] as IGroup
					}
				};

				// if we have previous date, then it's an edit
				const prevDate = groups[groupId]?.dates;
				let undoPayload;
				if (prevDate) {
					undoPayload = {
						editType: editType === 'ADD_DUE_DATE' ? 'ADD_DUE_DATE' : 'ADD_RANGE',
						groupId,
						payload: { ...editPayload, dates: prevDate }
					};
				} else {
					undoPayload = {
						editType: editType === 'ADD_DUE_DATE' ? 'REMOVE_DUE_DATE' : 'REMOVE_RANGE',
						groupId,
						payload: editPayload
					};
				}
				if (!isUndoRedo) {
					handleUndoRedo({
						type: 'ADD',
						payload: {
							originalAction: onEditGroup,
							oppositeAction: onEditGroup,
							originalActionPayload: { editType, groupId, payload: editPayload },
							oppositeActionPayload: undoPayload
						}
					});
				}

				// edit stage dispatch action
				(store.dispatch as CustomDispatch)(editStage(actionPayload))
					.unwrap()
					.then(() => {
						if (!prevDate) {
							// Track adding date, range event
							const eventProps: TAddDateEventData = {
								elementType: 'GROUP',
								elementId: groupId
							};
							trackEvent(
								editType === 'ADD_DUE_DATE'
									? CustomEvents.ADD_DATE
									: CustomEvents.ADD_RANGE,
								eventProps
							);
						}
					});
				break;
			}
			case 'REMOVE_DUE_DATE':
			case 'REMOVE_RANGE': {
				const prevGroup = groups[groupId] as IGroup;
				const actionPayload: TEditStageThunkArg = {
					payload: {
						id: groupId,
						update: {
							...editPayload,
							_id: groupId,
							dates: null
						},
						projectId
					},
					prevState: {
						prevStage: prevGroup
					}
				};
				if (!isUndoRedo) {
					// Save history when due date is set
					handleUndoRedo({
						type: 'ADD',
						payload: {
							originalAction: onEditGroup,
							oppositeAction: onEditGroup,
							originalActionPayload: { editType, groupId, payload: editPayload },
							oppositeActionPayload: {
								editType:
									editType === 'REMOVE_DUE_DATE' ? 'ADD_DUE_DATE' : 'ADD_RANGE',
								groupId,
								payload: {
									...editPayload,
									dates: prevGroup?.dates
								}
							}
						}
					});
				}

				// edit stage dispatch action
				dispatch(editStage(actionPayload));
				break;
			}
			default:
				break;
		}

		return undefined;
	};

	return {
		onEditGroup
	};
};

export default useGroupActions;
