import { AUTH_API_URL } from './api';

// export const registerRouter = `${AUTH_API_URL}/register`
// export const statusRouter = `${AUTH_API_URL}/status`
// export const userRouter = `${AUTH_API_URL}/user`

const statusEndpoint = `${AUTH_API_URL}/status`;
const loginEndpoint = `${AUTH_API_URL}/authSession/login`;
const registerEndpoint = `${AUTH_API_URL}/register`;
const getUserEndpoint = `${AUTH_API_URL}/authUser`;
const logoutEndpoint = `${AUTH_API_URL}/logout`;
const sessionEndpoint = `${AUTH_API_URL}/authSession`;
const guestUserEndpoint = `${AUTH_API_URL}/guest`;
const getGuestAccessDataEndpoint = `${guestUserEndpoint}/get-guest-access-data`;

export {
	statusEndpoint,
	loginEndpoint,
	getUserEndpoint,
	registerEndpoint,
	logoutEndpoint,
	sessionEndpoint,
	guestUserEndpoint,
	getGuestAccessDataEndpoint
};
