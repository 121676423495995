import { EBlockType } from '@naya_studio/types';
import car from './car';
import plantPot from './plantPot';
import spaceship from './spaceship';
import tableLamp from './tableLamp';
import chair from './chair';
import plantPot2 from './plantPot2';
import handBag from './handBag';
import shoe from './shoe';
import toothbrush from './toothbrush';
import bowl from './bowl';
import candle from './candle';
import dress from './dress';
import plantPot3 from './plantPot3';
import platPot4 from './plantPot4';

export type TI2RData = {
	text: string;
	projectTitle: string;
	groups: {
		name: string;
		blocks: { url: string; name: string; blockType: EBlockType }[];
	}[];
};

// eslint-disable-next-line max-len
export const dataEntryGuide = `{"root":{"children":[{"children":[{"detail":0,"format":1,"mode":"normal","style":"","text":"Please note: The AI features take about 30 seconds to be activated.","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1},{"children":[{"detail":0,"format":1,"mode":"normal","style":"","text":"Step 1:","type":"text","version":1},{"detail":0,"format":0,"mode":"normal","style":"","text":" Enter your design brief.","type":"text","version":1},{"type":"linebreak","version":1},{"detail":0,"format":1,"mode":"normal","style":"","text":"Step 2: ","type":"text","version":1},{"detail":0,"format":0,"mode":"normal","style":"","text":"Add images, PDFs, website references, or sketches.","type":"text","version":1},{"type":"linebreak","version":1},{"detail":0,"format":1,"mode":"normal","style":"","text":"Step 3: ","type":"text","version":1},{"detail":0,"format":0,"mode":"normal","style":"","text":"Shift + select > right-click to generate your 3D.","type":"text","version":1},{"type":"linebreak","version":1},{"detail":0,"format":1,"mode":"normal","style":"","text":"Step 4: ","type":"text","version":1},{"detail":0,"format":0,"mode":"normal","style":"","text":"Share your project at ","type":"text","version":1},{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":"https://discord.gg/UZ9fte7sp3","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"autolink","version":1,"rel":null,"target":null,"title":null,"url":"https://discord.gg/UZ9fte7sp3"}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}`;

export const i2rData: TI2RData[] = [
	plantPot,
	car,
	spaceship,
	tableLamp,
	chair,
	plantPot2,
	handBag,
	shoe,
	toothbrush,
	bowl,
	candle,
	dress,
	plantPot3,
	platPot4
];
