import { IAction, ISnackBar, SnackbarActionTypes } from '../root.types';

// keep state from different requests? waitingToResolve array to keep track?
// currently used for Blocking loader.
const initialState: ISnackBar = {
  show: false,
  type: 'NORMAL',
  text: '',
  loaderColor: 'var(--theme-color-1)',
  actionButtonData: undefined
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const snackBarReducer = (state = initialState, action: IAction<SnackbarActionTypes | keyof typeof SnackbarActionTypes>) => {
  let newState: ISnackBar;
  const { payload } = action;

  switch (action.type) {
    case SnackbarActionTypes.SET_SNACKBAR_DATA: {
      const {
        show, type, text, loaderColor, actionButtonData
      } = payload;
      newState = {
        ...state,
        show,
        type,
        text,
        loaderColor,
        actionButtonData
      };
      break;
    }
    default:
      newState = state;
      break;
  }

  return newState;
};

export default snackBarReducer;
