import { Link } from 'react-router-dom';
import { TRequestType } from 'src/types/argTypes';
import { ThreeDots } from 'react-loader-spinner';
import SocialLinks from '../authComponent/views/socialLinks/SocialLinks';
import NayaLogo from './Navbar/NayaLogo/NayaLogo';

type IRequestAccess = {
	handleRequestAccess: (type: TRequestType) => void;
	isLoading: boolean;
};

export const RequestAccess = ({ handleRequestAccess, isLoading }: IRequestAccess) => (
	<div
		className="tw-text-center tw-flex tw-flex-col tw-justify-center request-access-container
        tw-items-center tw-px-6 tw-py-10 tw-rounded-3xl tw-border tw-border-solid tw-border-[#E2E2E2]"
	>
		<div className="logo-container tw-mb-6">
			<NayaLogo />
		</div>
		<div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-6">
			<h4 className="tw-mb-0">You need access!</h4>

			<p className="tw-mb-0 tw-text-xs">
				Request access, or switch to an account with access.
			</p>
			<button
				className="primary-btn tw-w-full tw-flex tw-justify-center tw-text-sm tw-rounded-3xl tw-max-w-[300px]"
				type="submit"
				onClick={() => handleRequestAccess('REQUEST_ACCESS' as TRequestType)}
			>
				{isLoading ? (
					<ThreeDots color="#fefefe" height={20} width={20} />
				) : (
					'Request access'
				)}
			</button>
			<p className="tw-text-xs">
				{' '}
				Do you have another account?
				<span>
					<Link to="/login" className="switch-account">
						{' '}
						Switch account
					</Link>{' '}
				</span>
			</p>
		</div>
	</div>
);

export const SuccessfulRequest = () => (
	<div
		className="tw-text-center tw-flex tw-flex-col tw-justify-center request-access-container
        tw-items-center tw-px-6 tw-py-10 tw-rounded-3xl tw-border tw-border-solid tw-border-[#E2E2E2]"
	>
		<div className="logo-container tw-mb-6">
			<NayaLogo />
		</div>
		<div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-6 tw-mb-6">
			<h4 className="tw-mb-0">Access request successfully sent!</h4>
			<p className="tw-text-xs tw-mb-0">
				{' '}
				Do you have another account?
				<span>
					<Link to="/login" className="switch-account">
						{' '}
						Switch account
					</Link>{' '}
				</span>
			</p>
		</div>
		<SocialLinks />
	</div>
);
