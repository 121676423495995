import axios from 'axios';
import { projectRouter } from 'src/endpoints/projects-api';
import { getGatewayKey } from 'src/util/helper/queryString';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { TLoadProjectUsersFulfill, TLoadProjectUsersThunkArg } from 'src/types/argTypes';
import { AxiosResponseWrapper } from '../actions/types';

const qs = `?${getGatewayKey()}`;

/**
 * Loads email, userName, profilePic of users from project, phase and page level
 */
const loadProjectUsers = createAsyncThunk(
	'project/loadUsers',
	async ({ payload, next }: TLoadProjectUsersThunkArg, thunkApi) => {
		try {
			const { projectId } = payload;

			const response = await axios.put<AxiosResponseWrapper<any>>(
				`${projectRouter}/users${qs}`,
				{
					payload: {
						projectId
					}
				}
			);

			const users = response.data.payload;

			if (response.status !== 200) {
				throw new Error('Error setting project users');
			}

			if (next && typeof next === 'function') next();

			const fulfillValue: TLoadProjectUsersFulfill = {
				users,
				projectId,
			};

			return fulfillValue;
		} catch (error) {
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue('An error occurred');
		}
	}
);

export default loadProjectUsers;
