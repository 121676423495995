import { useEffect } from 'react'
import LandingPageFooter from '../footer/footer'
import ZendeskForm from '../utilities/zendeskForm/ZendeskForm'
import { getInTouch } from './GetInTouch.copy'
import './GetInTouch.scss'

const {
  header,
  description,
  zendeskHeader,
  calendlyHeader
} = getInTouch

const GetInTouch = () => {

  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
    head?.appendChild(script);
  }, [])

  return <div id='get-in-touch'>
    <div className='get-in-touch-container landing-page-padding grey-background'>
      <div className='max-width-container' >
        <h1>{header}</h1>
        <p style={{"display":"none"}}>{description}</p>
        <div className='conversation-container' >
          <div className='zendesk-container' >
            <h5>{zendeskHeader}</h5>
            <div className='zendesk-box'>
              {/* Zendesk Custom Form */}
              <ZendeskForm />
            </div>
          </div>
          <div className='linebreak' />
          <div className='calendly-container'>
            <h5>{calendlyHeader}</h5>
            <div className='calendly-box'>
              {/* Calendly IFrame */}
              <div
                className="calendly-inline-widget"
                data-url="https://calendly.com/ops-naya-studio/info-session"
                style={{ minWidth: '320px', height: '650px' }} />
            </div>
          </div>
        </div>
      </div>
    </div>
    <LandingPageFooter/>
  </div>
}

export default GetInTouch