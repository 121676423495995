import { FC, ReactElement, useContext } from 'react';
import './canvasMenu.scss';
import _ from 'lodash';
import { INode } from '@naya_studio/types';
// import CryptoJS from 'crypto-js';
import { undoAction } from 'src/redux/actions/undoRedoActions';
import { EActionType } from 'src/redux/reducers/undoRedo/undoActionHistory.types';
import { store } from 'src';
import { CustomDispatch } from 'src/redux/actions/types';
import pasteHere from 'src/features/copyPaste/pasteHere';
import BaseNode from '../canvas/app/nodes/BaseNode';
import { CollaborationToolContext } from '../collaborationTool/CollaborationTool';
import { CanvasMenuProps } from './canvasMenu.types';
import { isChrome } from '../utilities/clipboard/clipboard';
import { TEditNodesArgs } from 'src/types/argTypes';
import { generateIdsFromUrl } from 'src/redux/reduxActions/util';
import { editNodes } from 'src/redux/reduxActions/node';
import { shallowEqual, useSelector } from 'react-redux';
import { ReduxState } from 'src/redux/reducers/root.types';

const CanvasMenu: FC<CanvasMenuProps> = ({
  app, rightX, rightY, toggleCanvasMenu, toggleHideAllMenu,
}): ReactElement => {
  const { 
    uploadfilesFromOtherSource, 
    setShowShareModal, 
  } = useContext(CollaborationToolContext);
	const nodesRedux = useSelector((state) => (state as ReduxState).nodes.data, shallowEqual);

  const onPaste = async () => {
    pasteHere(app, rightX, rightY, uploadfilesFromOtherSource, () => { toggleCanvasMenu(false); });
  };

  const onSelectAll = () => {
    const allNodes = app.allNodes as BaseNode[];
    if (allNodes && allNodes.length > 0) {
      app.selectedNodes = allNodes as BaseNode[];
      app.removeTransformer();
      app.addSelectedNodesToTransformer();
      app.toggleEditMenu(false);
      app.toggleEditMenu(true);
      app.render();
      app.viewport.emit('node-selected', app.selectedNodes);
    }
    toggleCanvasMenu(false);
  };

  const unlockAllNodes = async () => {
    const allNodes = app.allNodes as BaseNode[];
    if (allNodes && allNodes.length > 0) {
      for (let i = 0; i < allNodes.length; i++) {
        if (allNodes[i]?._isLocked) {
          const prevData = _.cloneDeep(allNodes[i]?.nodeData);
          allNodes[i]?.toggleLock();
          const payload:INode = {
            ...allNodes[i]?.nodeData,
            isLocked: allNodes[i]?._isLocked,
            version: (allNodes[i] as INode).version ? ((allNodes[i] as INode).version as number) + 1 :  0
          };
        
          // generating payload for edit nodes action
          const apiPayload:TEditNodesArgs = {
            data: {
              nodes: [payload],
              ...generateIdsFromUrl(),
            },
            prevState: {
              prevBlocks: nodesRedux
            }
          };
  
          // dispatch edit nodes
          (store.dispatch as CustomDispatch)(editNodes(apiPayload))
          .unwrap()
          .then(() => {
            undoAction('EDIT' as EActionType, prevData as INode, payload, false);
          });
        }
      }
      app.removeTransformer();
      app.addNodesToTransformer();
      allNodes[0]?.setTransformerType();
      if (app.checkIfAnySelectedNodeIsLocked()) {
        app.transformer.translateEnabled = false;
      }
    }
    toggleCanvasMenu(false);
  };

  const onShare = (e: React.MouseEvent) => {
    e.stopPropagation();
    
    toggleCanvasMenu(false);
    setShowShareModal(true);
  };

  return (
    <div
      role="presentation"
      id="context-3d"
      style={{
        top: Math.min(rightY, window.innerHeight - 250 - 16),
        left: rightX,
      }}
      onPointerDown={(e: any) => { e.stopPropagation(); }}
      onPointerUp={(e: any) => { e.stopPropagation(); }}
      onClick={(e: any) => { e.stopPropagation(); }}
    >
      <div 
        role="presentation" 
        className="tw-cursor-pointer context-3d-item" 
        onClick={onShare}
        data-testid='share-block-context'
      >Share block</div>
      <hr />
      {isChrome && <div role="presentation" className="tw-cursor-pointer context-3d-item" style={{ marginTop: '4px' }} onClick={onPaste}>Paste here </div>}
      {isChrome && <hr />}
      <div
        role="presentation"
        className="tw-cursor-pointer context-3d-item"
        style={{ marginTop: !isChrome ? '4px' : 0 }}
        onClick={unlockAllNodes}
      >
        Unlock all
      </div>
      <hr />
      <div role="presentation" className="tw-cursor-pointer context-3d-item" onClick={onSelectAll}>Select all</div>
      <hr />
      <div
        role="presentation"
        className="tw-cursor-pointer context-3d-item"
        onClick={() => { toggleHideAllMenu(); toggleCanvasMenu(false); }}
      >
        Show/hide UI
      </div>
    </div>
  );
};

export default CanvasMenu;
