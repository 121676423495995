import mongoose from 'mongoose';
import { useDispatch, useSelector } from 'react-redux';
import { store } from 'src';
import { CustomDispatch } from 'src/redux/actions/types';
import useUser from 'src/redux/hooks/user';
import { ReduxState, TGoogleFolder, TSyncStorage } from 'src/redux/reducers/root.types';
import {
	createFolderWatch,
	createObserver,
	stopFolderWatch,
	updateObserver
} from 'src/redux/reduxActions/observer';
import { generateIdsFromUrl } from 'src/redux/reduxActions/util';
import useDrivePicker from 'react-google-drive-picker';
import { setShowConfirmationModal, setShowSyncStorageModal } from 'src/redux/features/integration';
import { useEffect, useRef } from 'react';
import { syncProjectWithDrive } from 'src/redux/reduxActions/project';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';
import { getProjectSyncObserver } from '../helper/project';
import useIntegration from '../integration/useIntegration';
import checkChannelExpiration from './renewObserverChannels';

/**
 * Hook to handle project storage sync related actions
 * @returns actions related to project sync
 */
const useSync = () => {
	const dispatch = useDispatch();
	const [openPicker] = useDrivePicker();
	const { user } = useUser();
	const { projectId } = generateIdsFromUrl();
	const { google } = useSelector((state) => (state as ReduxState).integrations);
	// stores the type of storage to continue with after its authentication
	const syncDataRef = useRef<TSyncStorage | null>();

	const { toggleAuth } = useIntegration();

	// check for expiring google drive channels
	useEffect(() => {
		let intervalId: any = null;
		const observer = getProjectSyncObserver(projectId, 'GOOGLE_DRIVE');

		// check for expired channelIds if project observer is being watched
		if (observer?.channelDetails) {
			checkChannelExpiration(observer, google.access_token);
			intervalId = setInterval(() => {
				const channelObserver = getProjectSyncObserver(projectId, 'GOOGLE_DRIVE');
				if (channelObserver?.channelDetails)
					checkChannelExpiration(channelObserver, google.access_token);
				else clearInterval(intervalId);
			}, 300000); // 5 minutes
		}
		return () => clearInterval(intervalId);
	}, []);

	/**
	 * Function to sync project with the folder selected
	 * @param folder - basic details of the folder which will get synced
	 * @returns
	 */
	const syncProject = async (folder: TGoogleFolder) => {
		if (!folder) return;
		const observer = getProjectSyncObserver(projectId, 'GOOGLE_DRIVE');
		const existingFolderId = observer?.folderId;
		if (!existingFolderId) {
			/**
			 * if project is not linked to a folder to be watched
			 * create a new observer to link project to a folder
			 * then watch the folder to listen to folder updates
			 */
			const newObserverId = new mongoose.Types.ObjectId().toString();
			try {
				await dispatch(
					createObserver({
						_id: newObserverId,
						folderId: folder.id,
						projectId,
						userId: user._id as string,
						observerType: 'GOOGLE_DRIVE'
					})
				);
				await dispatch(
					createFolderWatch({
						observerId: newObserverId,
						accessToken: google.access_token
					})
				);
				addSnackbar({ show: true, type: 'LOADER', text: 'Syncing journey with drive' });
				await dispatch(
					syncProjectWithDrive({
						projectId,
						userId: user._id as string,
						uploadAssetsToDrive: true
					})
				);
				addSnackbar({
					show: true,
					type: 'NORMAL',
					text: 'Journey synced with drive.'
				});
				removeSnackbar(3000);
			} catch (e) {
				console.error('syncProject error: ', e);
				addSnackbar({
					show: true,
					type: 'ERROR',
					text: 'Failed to sync drive'
				});
				removeSnackbar(3000);
			}
		} else {
			/**
			 * if project is already linked to folder
			 * update its observer to listen to the newly selected folder
			 * then watch the new folder
			 */

			(store.dispatch as CustomDispatch)(
				updateObserver({
					observerId: observer._id as string,
					projectId,
					data: {
						folderId: folder.id,
						projectId,
						userId: user._id as string,
						observerType: 'GOOGLE_DRIVE'
					}
				})
			).then(() => {
				(store.dispatch as CustomDispatch)(
					createFolderWatch({
						observerId: observer._id,
						accessToken: google.access_token
					})
				).then(() => {
					addSnackbar({ show: true, type: 'LOADER', text: 'Syncing journey with drive' });
					(store.dispatch as CustomDispatch)(
						syncProjectWithDrive({
							projectId,
							userId: user._id as string,
							uploadAssetsToDrive: true
						})
					)
						.then(() => {
							addSnackbar({
								show: true,
								type: 'NORMAL',
								text: 'Journey synced with drive.'
							});
							removeSnackbar(3000);
						})
						.catch((e) => {
							console.log(e);
							addSnackbar({
								show: true,
								type: 'ERROR',
								text: 'Failed to sync drive'
							});
							removeSnackbar(3000);
						});
				});
			});
		}
	};

	/**
	 * Function to open drive picker and select folder to be linked with project
	 */
	const handleOpenDrivePicker = () => {
		/**
		 * close any other previously opened modals
		 * else drive picker shows error while selecting folder
		 */
		dispatch(setShowSyncStorageModal(false));
		openPicker({
			clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID as string,
			developerKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
			viewId: 'FOLDERS',
			token: google.access_token,
			showUploadView: true,
			showUploadFolders: true,
			supportDrives: true,
			multiselect: true,
			setSelectFolderEnabled: true,
			callbackFunction: (data) => {
				// sync selected folder data into project
				if (data.action === 'picked' && data.docs[0]) {
					const folder = data.docs[0];
					syncProject({ id: folder.id, name: folder.name });
				}
			}
		});
	};

	/**
	 * Function to connect/disconnect to diff storage platforms
	 * @param syncType - Type of storage
	 * @param isConnected - where or not project is connected to storage
	 */
	const toggleSync = async (
		syncType: TSyncStorage,
		isConnected: boolean,
		isConfirmed?: boolean
	) => {
		switch (syncType) {
			case 'GOOGLE_DRIVE': {
				if (isConnected && !isConfirmed) {
					dispatch(setShowSyncStorageModal(false));
					dispatch(setShowConfirmationModal(true));
				} else if (isConnected && isConfirmed) {
					const observer = getProjectSyncObserver(projectId, 'GOOGLE_DRIVE');
					(store.dispatch as CustomDispatch)(
						stopFolderWatch({
							accessToken: google.access_token,
							observerId: observer?._id as string
						})
					);
				} else if (google.access_token) {
					handleOpenDrivePicker();
				} else {
					// connect google first
					syncDataRef.current = 'GOOGLE_DRIVE';
					toggleAuth('GOOGLE', false);
				}

				break;
			}
			default:
				break;
		}
	};

	/**
	 * check which storage to sync after authentication is completed
	 */
	useEffect(() => {
		if (syncDataRef.current === 'GOOGLE_DRIVE') {
			handleOpenDrivePicker();
			syncDataRef.current = null;
		}
	}, [google, syncDataRef]);
	return {
		syncProject,
		toggleSync
	};
};

export default useSync;
