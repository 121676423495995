import { Progress, SnackBar } from '@naya_studio/radix-ui';
import { ReactElement, useRef, useEffect, useMemo } from 'react';
import { Prompt } from 'react-router-dom';

import {
	convertBytesToMb,
	getDownloadAndZipData,
	useDownloadAndZip
} from 'src/components/collaborationTool/hooks/useDownloadAndZip';
import { getStagesBlocksNodesOfProject } from 'src/redux/reduxActions/util';
import useDownloadJourneyPoster from 'src/components/collaborationTool/hooks/useDownloadJourneyPoster';
import trackEvent from 'src/util/analytics/analytics';
import { CustomEvents } from 'src/util/analytics/events';
import filterPhaseAndBlocks from 'src/util/collaboration/searchAndSortAlgo';
import useNotes from 'src/redux/hooks/useNotes';
import { IBlock, IGroup } from '@naya_studio/types';
import { TExportItemEventData } from 'src/util/analytics/analytic.types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getFormattedData } from './JourneyContainerCallbacks';
import {
	IDeliverableTypes,
	TLDFlags,
	TSearchQuery
} from '../collaborationTool/CollaborationTool.types';

type DeliverablesProps = {
	projectId: string;
	journeyName: string;
	deliverableType: IDeliverableTypes;
	setDeliverableType: (type: IDeliverableTypes) => void;
	searchFilters: TSearchQuery;
	projectChildren: string[];
};

/**
 * @Component to show the snack while downloading journey/poster
 * @param param0 DeliverablesProps
 * @returns ReactComponent
 */

const Deliverables = ({
	projectId,
	journeyName,
	deliverableType,
	setDeliverableType,
	searchFilters,
	projectChildren
}: DeliverablesProps) => {
	const flags = useFlags<TLDFlags>();
	// Holds snackbar message
	const snackbarMessage = useRef<ReactElement | string>('');
	const { downloadJourneyPoster, abortController, posterGenerationProgress } =
		useDownloadJourneyPoster(journeyName);
	// Memoized value to store search status
	const isSearchActive = useMemo(
		() =>
			searchFilters &&
			Object.keys(searchFilters).length > 0 &&
			Object.values(searchFilters).length > 0,
		[searchFilters]
	);

	const { progress: DAZProgress, startDownloadAndZip } = useDownloadAndZip();
	const { reduxNotes } = useNotes();
	// Total size of files converted to mb
	const totalSize = Math.round(convertBytesToMb(DAZProgress.downloadData?.totalSize || 0));

	// Creating message to show in the snackbar
	if (deliverableType === 'DOWNLOAD_FILES') {
		if (DAZProgress.status === 'DOWNLOADING') {
			snackbarMessage.current = (
				<p
					style={{
						display: 'flex',
						alignItems: 'center',
						textAlign: 'start',
						margin: 0,
						marginBottom: 0
					}}
				>
					Downloading files - &nbsp;{totalSize} MB&nbsp;&nbsp;
					<Progress
						variant="PERCENT_LOADER"
						style={{
							width: '176px',
							borderRadius: '16px',
							marginBottom: '1px',
							marginLeft: '5px'
						}}
						percent={
							((DAZProgress.downloadData?.downloadedSize || 0) /
								(DAZProgress.downloadData?.totalSize || 0)) *
							100
						}
					/>
				</p>
			);
		} else if (DAZProgress.status === 'PACKING') {
			snackbarMessage.current = (
				<p
					style={{
						display: 'flex',
						alignItems: 'center',
						textAlign: 'start',
						margin: 0,
						marginBottom: 0
					}}
				>
					Packing downloaded files &nbsp;&nbsp;
					<Progress
						variant="PERCENT_LOADER"
						style={{ width: '176px', borderRadius: '16px', marginBottom: '1px' }}
						percent={DAZProgress?.zipData?.percent || 1}
					/>
				</p>
			);
		} else {
			snackbarMessage.current = DAZProgress.message || '';
		}
	} else if (posterGenerationProgress.status === 'CLONING') {
		snackbarMessage.current = 'Preparing journey for poster.';
	} else if (
		posterGenerationProgress.status === 'GENERATING' ||
		posterGenerationProgress.status === 'GENERATED'
	) {
		snackbarMessage.current = 'Creating journey poster.';
	} else if (posterGenerationProgress.status === 'FAILED') {
		snackbarMessage.current =
			'Unable to download poster. Please check the internet connection and try again.';
	} else if (posterGenerationProgress.status === 'CLONING_FAILURE') {
		snackbarMessage.current =
			'Unable to convert journey to poster. Failed to apply styles to poster.';
	} else if (posterGenerationProgress.status === 'COMPLETED') {
		snackbarMessage.current = 'Journey poster created.';
	}

	// Runs on mount and on DAZProcess.status update
	useEffect(() => {
		const status = DAZProgress.status || posterGenerationProgress.status;
		if (status === 'COMPLETED' || status === 'FAILED' || status === 'CLONING_FAILURE') {
			setTimeout(() => {
				setDeliverableType(undefined);
			}, 5000);
		}
	}, [DAZProgress.status, posterGenerationProgress.status]);

	// Runs on mount and on deliverable change
	useEffect(() => {
		if (deliverableType) {
			const eventProps: TExportItemEventData = {
				elementId: projectId,
				exportType: deliverableType === 'DOWNLOAD_POSTER' ? 'IMAGE' : 'ZIP_FOLDER'
			};

			if (deliverableType === 'DOWNLOAD_POSTER') {
				setTimeout(() => {
					downloadJourneyPoster();
				}, 0);

				// Track export as poster event
				trackEvent(CustomEvents.EXPORT_ITEM, eventProps);
			} else {
				const { stages, blocksJson } = getStagesBlocksNodesOfProject(projectId);
				const { formattedBlocks: journeyBlocks, formattedPhases: journeyPhases } =
					getFormattedData(stages, blocksJson, reduxNotes);

				let phases = journeyPhases;
				let blocks = journeyBlocks;

				if (isSearchActive) {
					const filteredResult = filterPhaseAndBlocks(
						searchFilters,
						journeyPhases,
						journeyBlocks
					);

					if (filteredResult) {
						phases = filteredResult.phases;
						blocks = filteredResult.blocks;
					}
				}
				const jsonGroups: { [id: string]: Partial<IGroup> } = {};
				phases.forEach((group) => {
					if (group._id) jsonGroups[group._id.toString()] = group;
				});

				startDownloadAndZip(
					getDownloadAndZipData(
						jsonGroups,
						blocks as { [key: string]: IBlock },
						journeyName,
						projectId,
						flags,
						true,
						projectChildren
					)
				);

				// Track export as zip folder event
				trackEvent(CustomEvents.EXPORT_ITEM, eventProps);
			}
		}
	}, [deliverableType, isSearchActive]);

	return (
		<>
			<Prompt
				when={
					!['COMPLETED', 'FAILED', 'CLONING_FAILURE'].includes(
						DAZProgress.status || posterGenerationProgress.status
					)
				}
				message={(location) => {
					if (location.pathname.includes('/project/')) return true;
					return `Download journey${
						deliverableType === 'DOWNLOAD_FILES' ? '' : 'poster'
					} is in progress, are you sure you want to leave the project?`;
				}}
			/>
			<SnackBar
				style={{ backgroundColor: 'blue' }}
				show={!!(DAZProgress.status || posterGenerationProgress.status)}
				snackbarMessage={snackbarMessage.current}
				variant={
					!['COMPLETED', 'FAILED', 'CLONING_FAILURE'].includes(
						DAZProgress.status || posterGenerationProgress.status
					)
						? 'LOADER'
						: 'NORMAL'
				}
				actionButtonData={
					['COMPLETED', 'FAILED', 'CLONING_FAILURE'].includes(
						DAZProgress.status || posterGenerationProgress.status
					)
						? []
						: [
								{
									buttonData: 'cancel',
									className: 'cancel-btn',
									onClick: () => {
										if (deliverableType === 'DOWNLOAD_POSTER')
											abortController();
										setDeliverableType(undefined);
									},
									show: true
								}
						  ]
				}
			/>
		</>
	);
};

export default Deliverables;
