import { ActionReducerMapBuilder, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IBlock, INote } from '@naya_studio/types';
import {
	TActionType,
	TAddNoteArg,
	TDeleteNoteArg,
	TDuplicateBlockArgs,
	TDuplicateStageThunkArg,
	TEditNoteArg,
	TLoadProjectByIdFulfill,
	TLoadTemplateByIdFulfill
} from 'src/types/argTypes';
import { INotesInitState } from '../reducers/root.types';
import { addNote, deleteNote, editNote, unsaveNote } from '../reduxActions/notes';
import { loadProjectById, loadTemplateById } from '../reduxActions/project';
import { duplicateBlock, loadBlockById } from '../reduxActions/block';
import { duplicateStage } from '../reduxActions/stage';

const initialState: INotesInitState = {
	data: {},
	loading: {},
	error: {}
};

/**
 * Reducer for Notes
 */
const notesSlice = createSlice({
	name: 'notes',
	initialState,
	reducers: {
		addNotesInRedux: (state: INotesInitState, action: PayloadAction<INote[]>) => {
			const notes = action.payload;
			notes.forEach((n: INote) => {
				state.data[n._id as string] = {
					...state.data[n._id as string],
					...n
				};
			});
		},
		editNoteInRedux: (state: INotesInitState, action: PayloadAction<Partial<INote>>) => {
			const note = action.payload;
			state.data[note._id as string] = {
				...state.data[note._id as string],
				...note
			} as INote;
		},
		deleteNoteFromRedux: (state: INotesInitState, action: PayloadAction<string>) => {
			const noteId = action.payload;
			delete state.data[noteId as string];
		},
		/**
		 * Reducer to unload notes from redux
		 */
		unloadNotes: (state: INotesInitState, action: PayloadAction<string>) => {
			const projectId = action.payload;

			const notesToDelete = [];
			const keys = Object.keys(state.data);
			for (let i = 0; i < keys.length; i++) {
				const noteId = keys[i] as string;
				if (state.data[noteId]!.projectId === projectId) notesToDelete.push(noteId);
			}

			notesToDelete.forEach((id) => delete state.data[id]);
		},

		/**
		 * Add temporary notes
		 */
		addTempNotesRedux: (
			state: INotesInitState,
			action: PayloadAction<{ noteIds: string[]; color: string }>
		) => {
			const { noteIds, color } = action.payload;

			noteIds.forEach((n) => {
				// adding ts-ignore as these are temp notes and dont contain all the data for notes
				// @ts-ignore
				state.data[`NEW_NOTE_MULTI_${n}` as string] = {
					_id: `NEW_NOTE_MULTI_${n}`,
					color
				};
			});
		}
	},
	extraReducers: (builder: ActionReducerMapBuilder<INotesInitState>) => {
		/** ---- LOAD BLOCK BY ID ---- */
		// FULFILLED
		builder.addCase(
			loadBlockById.fulfilled,
			(
				state: INotesInitState,
				action: PayloadAction<TActionType<{ block: IBlock }, {}>['payload']>
			) => {
				const { payload } = action;

				if (payload) {
					const { block } = payload;

					if (block?.notes) {
						(block.notes as INote[]).forEach((note) => {
							state.data[note._id as string] = note;
							state.loading[note._id as string] = false;
							state.error[note._id as string] = null;
						});
					}
				}
			}
		);

		/** ---- ADD NOTE ---- */
		// PENDING
		builder.addCase(
			addNote.pending,
			(
				state: INotesInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TAddNoteArg>['pendingMeta']
				>
			) => {
				const { payload } = action.meta.arg;
				if (payload) {
					const note = payload;
					state.data[note._id as string] = note;
					state.loading[note._id as string] = true;
					state.error[note._id as string] = null;

					if (state.data[`NEW_NOTE_MULTI_${note.parentId}`]) {
						delete state.data[`NEW_NOTE_MULTI_${note.parentId}`];
					}
				}
			}
		);
		// FULFILLED
		builder.addCase(
			addNote.fulfilled,
			(state: INotesInitState, action: PayloadAction<TActionType<INote, {}>['payload']>) => {
				const { payload } = action;

				if (payload) {
					const { _id: noteId } = payload;

					state.loading[noteId as string] = false;
					state.error[noteId as string] = null;
				}
			}
		);
		// REJECTED
		builder.addCase(
			addNote.rejected,
			(
				state: INotesInitState,
				action: PayloadAction<unknown, string, TActionType<{}, TAddNoteArg>['rejectedMeta']>
			) => {
				const { payload } = action.meta.arg;
				const { payload: error } = action;

				if (payload) {
					const { _id: noteId } = payload;

					delete state.data[noteId as string];
					state.loading[noteId as string] = false;
					state.error[noteId as string] = error as string;
				}
			}
		);

		/** ---- EDIT NOTE ---- */
		// PENDING
		builder.addCase(
			editNote.pending,
			(
				state: INotesInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TEditNoteArg>['pendingMeta']
				>
			) => {
				const { payload } = action.meta.arg;
				if (payload) {
					const note = payload;

					if (note?.savedNoteReference) {
						// find all occurences of the saved note to update
						const notesToUpdate = Object.values(state.data).filter(
							(n) => n.savedNoteReference === note.savedNoteReference
						);
						notesToUpdate.forEach((noteArg) => {
							(state.data[noteArg._id as string] as INote).color = note.color;
							(state.data[noteArg._id as string] as INote).text = note.text;
							state.loading[noteArg._id as string] = true;
							state.error[noteArg._id as string] = null;
						});
					} else {
						state.data[note._id as string] = note;
						state.loading[note._id as string] = true;
						state.error[note._id as string] = null;
					}
				}
			}
		);
		// FULFILLED
		builder.addCase(
			editNote.fulfilled,
			(state: INotesInitState, action: PayloadAction<TActionType<INote, {}>['payload']>) => {
				const { payload } = action;

				if (payload) {
					const { _id: noteId, updatedAt } = payload;

					if (new Date(updatedAt) > new Date(state.data[noteId as string]!.updatedAt))
						state.data[noteId as string] = payload;

					state.loading[noteId as string] = false;
					state.error[noteId as string] = null;
				}
			}
		);
		// REJECTED
		builder.addCase(
			editNote.rejected,
			(
				state: INotesInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TEditNoteArg>['rejectedMeta']
				>
			) => {
				const { payload, prevState } = action.meta.arg;
				const { payload: error } = action;

				if (payload) {
					const { _id: noteId } = payload;
					const { noteBeforeUpdate } = prevState;

					state.data[noteId as string] = noteBeforeUpdate;
					state.loading[noteId as string] = false;
					state.error[noteId as string] = error as string;
				}
			}
		);

		/** --- UNSAVE NOTE ----*/
		// PENDING
		builder.addCase(
			unsaveNote.pending,
			(
				state: INotesInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, { payload: Partial<INote> }>['pendingMeta']
				>
			) => {
				const { payload } = action.meta.arg;
				if (payload) {
					// find all notes to update
					const notesToUpdate = Object.values(state.data).filter(
						(n) => n.savedNoteReference === payload.savedNoteReference
					);
					notesToUpdate.forEach((noteArg) => {
						(state.data[noteArg._id as string] as INote).savedNoteReference = null;
						state.loading[noteArg._id as string] = true;
						state.error[noteArg._id as string] = null;
					});
				}
			}
		);
		// FULFILLED
		builder.addCase(
			unsaveNote.fulfilled,
			(
				state: INotesInitState,
				action: PayloadAction<TActionType<Partial<INote>, {}>['payload']>
			) => {
				const { payload } = action;

				// find all notes to update
				const notesToUpdate = Object.values(state.data).filter(
					(n) => n.savedNoteReference === payload.savedNoteReference
				);
				notesToUpdate.forEach((noteArg) => {
					state.loading[noteArg._id as string] = false;
				});
			}
		);
		// REJECTED
		builder.addCase(
			unsaveNote.rejected,
			(
				state: INotesInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, { payload: Partial<INote> }>['rejectedMeta']
				>
			) => {
				const { payload } = action.meta.arg;
				const { payload: error } = action;

				if (payload) {
					// find all notes to update
					const notesToUpdate = Object.values(state.data).filter(
						(n) => n.savedNoteReference === payload.savedNoteReference
					);
					notesToUpdate.forEach((noteArg) => {
						state.loading[noteArg._id as string] = false;
						state.error[noteArg._id as string] = error as string;
					});
				}
			}
		);
		/** ---- DELETE NOTE ---- */
		// PENDING
		builder.addCase(
			deleteNote.pending,
			(
				state: INotesInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TDeleteNoteArg>['pendingMeta']
				>
			) => {
				const { payload } = action.meta.arg;
				if (payload) {
					const { noteId } = payload;

					delete state.data[noteId];
					state.loading[noteId] = true;
					state.error[noteId] = null;
				}
			}
		);
		// FULFILLED
		builder.addCase(
			deleteNote.fulfilled,
			(
				state: INotesInitState,
				action: PayloadAction<
					TActionType<{ noteId: string; parentId: string }, {}>['payload']
				>
			) => {
				const { payload } = action;

				if (payload) {
					const { noteId } = payload;

					state.loading[noteId] = false;
					state.error[noteId] = null;
				}
			}
		);
		// REJECTED
		builder.addCase(
			deleteNote.rejected,
			(
				state: INotesInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TDeleteNoteArg>['rejectedMeta']
				>
			) => {
				const { payload, prevState } = action.meta.arg;
				const { payload: error } = action;

				if (payload) {
					const { noteId } = payload;
					const { noteBeforeUpdate } = prevState;

					state.data[noteId as string] = noteBeforeUpdate;
					state.loading[noteId as string] = false;
					state.error[noteId as string] = error as string;
				}
			}
		);

		/** ---- LOAD PROJECT ---- */
		// FULFILLED
		builder.addCase(
			loadProjectById.fulfilled,
			(
				state: INotesInitState,
				action: PayloadAction<TActionType<TLoadProjectByIdFulfill, {}>['payload']>
			) => {
				const { notes } = action.payload;
				notes.forEach((note: INote) => {
					const noteId = note._id as string;
					let overwriteNote = true;

					if (state.data[noteId]) {
						const oldNote = state.data[noteId];

						// Only update the note if note from api have been updated later
						// to prevent the scenario where a user refreshes and someone edits a note
						if (
							new Date(oldNote?.updatedAt as string) >
							new Date(note.updatedAt as string)
						)
							overwriteNote = false;
					}

					if (overwriteNote) state.data[noteId] = note;
					state.loading[noteId] = false;
					// reset the error just in case previous call has some error
					state.error[noteId] = null;
				});
			}
		);

		/** ---- LOAD TEMPLATE ---- */
		// FULFILLED
		builder.addCase(
			loadTemplateById.fulfilled,
			(
				state: INotesInitState,
				action: PayloadAction<TActionType<TLoadTemplateByIdFulfill, {}>['payload']>
			) => {
				const { template } = action.payload;
				template?.notes?.forEach((note) => {
					const id = note._id as string;

					state.data[id] = note;
					state.error[id] = null;
				});
			}
		);

		/** DUPLICATE BLOCK */
		// PENDING
		builder.addCase(
			duplicateBlock.pending,
			(
				state: INotesInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TDuplicateBlockArgs>['pendingMeta']
				>
			) => {
				const { clonedItems } = action.meta.arg.data;
				const { notes: clonedNotes } = clonedItems;

				if (clonedNotes) {
					clonedNotes.forEach((clonedNote) => {
						const noteId = clonedNote._id as string;
						state.data[noteId] = clonedNote;
						state.loading[noteId] = true;
						state.error[noteId] = null;
					});
				}
			}
		);
		// REJECTED
		builder.addCase(
			duplicateBlock.rejected,
			(
				state: INotesInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TDuplicateBlockArgs>['rejectedMeta']
				>
			) => {
				const { clonedItems } = action.meta.arg.data;
				const { notes: clonedNotes } = clonedItems;

				if (clonedNotes) {
					clonedNotes.forEach((clonedNote) => {
						const noteId = clonedNote._id as string;

						if (state.data[noteId]) delete state.data[noteId];

						state.error[noteId] = action.payload as string;
						state.loading[noteId] = false;
					});
				}
			}
		);

		/** DUPLICATE STAGE */
		// PENDING
		builder.addCase(
			duplicateStage.pending,
			(
				state: INotesInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TDuplicateStageThunkArg>['pendingMeta']
				>
			) => {
				const { clonedItems } = action.meta.arg.payload;
				const { notes: clonedNotes } = clonedItems;

				if (clonedNotes) {
					clonedNotes.forEach((clonedNote) => {
						const noteId = clonedNote._id as string;
						state.data[noteId] = clonedNote;
						state.error[noteId] = null;
					});
				}
			}
		);
		// REJECTED
		builder.addCase(
			duplicateStage.rejected,
			(
				state: INotesInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TDuplicateStageThunkArg>['rejectedMeta']
				>
			) => {
				const { clonedItems } = action.meta.arg.payload;
				const { notes: clonedNotes } = clonedItems;

				if (clonedNotes) {
					clonedNotes.forEach((clonedNote) => {
						const noteId = clonedNote._id as string;

						if (state.data[noteId]) delete state.data[noteId];

						state.error[noteId] = action.payload as string;
						state.loading[noteId] = false;
					});
				}
			}
		);
	}
});

export const {
	addNotesInRedux,
	editNoteInRedux,
	deleteNoteFromRedux,
	unloadNotes,
	addTempNotesRedux
} = notesSlice.actions;
export default notesSlice.reducer;
