/**
 * 
 * @param {string} value email address to be validated
 * @returns {boolean} true if email is a valid email 
 */
export const validateEmail = (value: string) => {
    const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/

    if (emailRegex.test(value)) {
        return true
    }

    return false
}