import { ISentNotification } from '@naya_studio/types';
import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';
import { sentTaskRouter } from 'src/endpoints/projects-api';
import { ReduxState } from 'src/redux/reducers/root.types';
import { getGatewayKey } from 'src/util/helper/queryString';

const qs = `?${getGatewayKey()}`;

export type TNotifState = {
	[key: string]: ISentNotification;
};
export type TSentNotifRTKData = {
	tasks: TNotifState;
};

export const sentNotificationSlice = createApi({
	reducerPath: 'sentNotifications',
	baseQuery: fetchBaseQuery({ baseUrl: `${sentTaskRouter}`, credentials: 'include' }),
	tagTypes: ['sentNotifs'],
	endpoints: (builder) => ({
		/**
		 * Fetches all notifs for the user
		 */
		getSentNotifs: builder.query({
			query: ({ userId }) => `/${userId}${qs}`,
			transformResponse: ({ tasks }) => {
				const notifData = {} as { [key: string]: ISentNotification };
				tasks.forEach((notif: ISentNotification) => {
					const notifId = notif._id as string;
					notifData[notifId] = notif;
				});
				const allNotifData: TSentNotifRTKData = {
					tasks: notifData
				};
				return allNotifData;
			},
			providesTags: ['sentNotifs']
		}),
		/**
		 * Marks notif as complete/incomplete
		 */
		toggleSentNotifComplete: builder.mutation({
			query: (notifData) => ({
				url: `/toggle-complete${qs}`,
				method: 'PUT',
				body: {
					payload: notifData
				}
			}),
			onQueryStarted: async (
				{ isComplete, notifId },
				{ dispatch, queryFulfilled, getState }
			) => {
				const { _id: userId } = (getState() as ReduxState).user.data;
				const getSentNotifs = sentNotificationSlice.endpoints.getSentNotifs.select({
					userId
				})(getState());
				const notifsArr = Object.values(getSentNotifs.data?.tasks || {});
				const notifsToUpdate = notifsArr.filter((n) => n._id === notifId);
				try {
					const patchResult = dispatch(
						sentNotificationSlice.util.updateQueryData(
							'getSentNotifs',
							{ userId },
							(draft) => {
								notifsToUpdate.forEach((n: ISentNotification) => {
									Object.assign(
										draft.tasks[n._id as string] as ISentNotification,
										{
											...n,
											isCompleted: isComplete
										}
									);
								});
							}
						)
					);
					try {
						await queryFulfilled;
					} catch {
						patchResult.undo();
					}
				} catch (error) {
					console.log('Error', error);
				}
			}
		}),
		/**
		 * Project Progress
		 */
		getProjectProgress: builder.mutation({
			query: (projectIds) => ({
				url: `/projects-progress${qs}`,
				method: 'POST',
				body: {
					payload: { projectIds }
				}
			}),
			invalidatesTags: ['sentNotifs']
		})
	})
});
export const {
	useGetSentNotifsQuery,
	useToggleSentNotifCompleteMutation,
	useGetProjectProgressMutation
} = sentNotificationSlice;
