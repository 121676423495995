import { TSearchQuery } from '@naya_studio/radix-ui';
import { I3DSubtype, IFileSubtype, ILinkSubtype, IUser } from '@naya_studio/types';
import { store } from 'src';
import { ICommonSubType } from 'src/components/collaborationTool/CollaborationTool.types';

export const fileLinkTypes: { [key: string]: string } = {
	LINK:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2F1694605982196_filter_link_icon.svg?alt=media&token=158908f9-06c3-47d8-9e81-c0638547c1bf',
	EXCALIDRAW_CANVAS:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2F1694606332654_filter_canvas_icon.svg?alt=media&token=8a04efa9-ba05-4749-8fd2-9b33bef5295d',
	CANVAS:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2F1694606332654_filter_canvas_icon.svg?alt=media&token=8a04efa9-ba05-4749-8fd2-9b33bef5295d',
	FILE:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2F1694606368540_filter_file_icon.svg?alt=media&token=304c2c2c-57b1-446f-9161-b208d0ff8b6f',
	THREE_D:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2F1694606406046_filter_3d_icon.svg?alt=media&token=e0aef7d8-4bce-49de-9dbc-ef94af39cef5',
	IMAGE:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2F1694606440564_filter_image_icon.svg?alt=media&token=d42f33e8-7529-4102-af44-250a64e5f9a7',
	VIDEO:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2F1694606485211_filter_video_icon.svg?alt=media&token=914e8c40-9e1d-409b-a7f8-9ffb4f676561',
	PDF:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2F1694607746903_filter_PDF_icon.svg?alt=media&token=0bfaf9d1-9828-4912-baec-2b7171d354e8',
	FAVORITE:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2F1694607748910_filter_FAVORITE_icon.svg?alt=media&token=e3aaff2b-8fc7-491b-900f-419bd3b26c9c',
	GMAIL:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2F1694607750906_filter_GMAIL_icon.svg?alt=media&token=450618c4-f700-4574-99c7-d9d59ba6cdc6',
	PINTEREST:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2F1694607752914_filter_PINTEREST_icon.svg?alt=media&token=320ace8f-43b5-4c57-a777-669cd69a302f',
	GOOGLE_DRIVE:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2F1694607754911_filter_DRIVE_icon.svg?alt=media&token=33a04a46-85cd-427f-9a39-1ddee9f5b86d',
	DROPBOX:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2F1694607756909_filter_DROPBOX_icon.svg?alt=media&token=4c0f4847-3999-4f16-b4a9-20df4bbab20f',
	SLACK:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2F1694607758903_filter_SLACK_icon.svg?alt=media&token=16dcdc4a-96eb-46d6-82b3-dc0590a7c74a',
	BLENDER:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2F1694607760904_filter_BLENDER_icon.svg?alt=media&token=9f0e10f0-98f3-40fa-81a9-6ffc5028a471',
	NOTION:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2F1694607762903_filter_NOTION_icon.svg?alt=media&token=efce2d8a-e2b8-4294-919d-9bcb08133fbb',
	SOLID_WORKS:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2F1694607764911_filter_SOLID_WORKS_icon.svg?alt=media&token=2dbdd615-9a83-42a7-8caf-10564e9d6d16',
	KEYSHOT:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2F1694607766909_filter_KEYSHOT_icon.svg?alt=media&token=570d5cda-c850-44e7-93c2-25bf20fb4abf',
	RHINO:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2F1694607768916_filter_RHINO_icon.svg?alt=media&token=a94562a1-ad8d-4d36-ab39-627bac328a9a',
	PHOTO_SHOP:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2F1694607770909_filter_PHOTO_SHOP_icon.svg?alt=media&token=f8c72199-f39e-4823-88a0-206e6b267052',
	INDESIGN:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2F1694607772903_filter_INDESIGN_icon.svg?alt=media&token=115079c4-4135-4d5f-b75b-9b6b42b16013',
	ILLUSTRATOR:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2F1694607774916_filter_ILLUSTRATOR_icon.svg?alt=media&token=75994acb-5a88-4267-8044-45b93b5a4d92',
	GOOGLE_SHEETS:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2F1694607776910_filter_SHEET_icon.svg?alt=media&token=733b51b2-9e90-45f0-89a7-beac36309363',
	FIGMA:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2F1694607778906_filter_FIGMA_icon.svg?alt=media&token=abba71ae-e6a8-43dd-8579-b2f64fbe34eb',
	ARTICLE:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2F1694607780915_filter_ARTICLE_icon.svg?alt=media&token=1e31f158-eb4a-4231-9fdc-0c62f055110d',
	GOOGLE_DOCS:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2F1694607782916_filter_DOCS_icon.svg?alt=media&token=18ea37db-cd93-4dc4-8c52-b11cac4ff8e5',
	GOOGLE_SLIDES:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2F1694607784908_filter_SLIDES_icon.svg?alt=media&token=d3255779-2716-4fc5-b89c-e204644c5c1d',
	MIRO:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2F1694607754917_filter_MIRO_icon.svg?alt=media&token=a1b4dd3b-2652-4d6e-8d23-e4178402328c',
	NAYA:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2F1704707784908_default_naya_thumbnail.svg?alt=media&token=168a91a9-6bd9-41ae-823e-5b5e03bcf1ca',
	MS_EXCEL:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2FType%3Dm-excel.svg?alt=media&token=180fa03d-ab28-45e0-9d4d-97db78a5a6ab',
	MS_WORD:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2FType%3Dm-word.svg?alt=media&token=c1856943-c4dd-4f23-98a3-3586cc6e8467',
	MS_PPT:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2FType%3Dm-ppt.svg?alt=media&token=db5ebe6b-97cf-4ddd-84f6-91668a1d54e1',
	MS_LINK:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'ondrive.svg?alt=media&token=256d9238-931f-49e4-9f7c-e76c64243cd2&_gl=1*1fol550',
	CODE_JS:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2Fcode%2Fjs.svg?alt=media&token=b39a3e7e-c500-4cf1-a9fd-2d8a92bcd9b2',
	CODE_TS:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2Fcode%2Fts.svg?alt=media&token=b39a3e7e-c500-4cf1-a9fd-2d8a92bcd9b2',
	CODE_PY:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2Fcode%2Fpy.svg?alt=media&token=b39a3e7e-c500-4cf1-a9fd-2d8a92bcd9b2',
	CODE_JAVA:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2Fcode%2Fjava.svg?alt=media&token=b39a3e7e-c500-4cf1-a9fd-2d8a92bcd9b2',
	CODE_CPP:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2Fcode%2Fcpp.svg?alt=media&token=b39a3e7e-c500-4cf1-a9fd-2d8a92bcd9b2',
	CODE_HTML:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2Fcode%2Fhtml.svg?alt=media&token=b39a3e7e-c500-4cf1-a9fd-2d8a92bcd9b2',
	CODE_CSS:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2Fcode%2Fcss.svg?alt=media&token=b39a3e7e-c500-4cf1-a9fd-2d8a92bcd9b2',
	CODE_SASS:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2Fcode%2Fsass.svg?alt=media&token=b39a3e7e-c500-4cf1-a9fd-2d8a92bcd9b2',
	CODE_RB:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2Fcode%2Frb.svg?alt=media&token=b39a3e7e-c500-4cf1-a9fd-2d8a92bcd9b2',
	CODE_LUA:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2Fcode%2Flua.svg?alt=media&token=b39a3e7e-c500-4cf1-a9fd-2d8a92bcd9b2',
	CODE_FS:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2Fcode%2Ffs.svg?alt=media&token=b39a3e7e-c500-4cf1-a9fd-2d8a92bcd9b2',
	CODE_PUG:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2Fcode%2Fpug.svg?alt=media&token=b39a3e7e-c500-4cf1-a9fd-2d8a92bcd9b2',
	CODE_BAT:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2Fcode%2Fbat.svg?alt=media&token=b39a3e7e-c500-4cf1-a9fd-2d8a92bcd9b2',
	CODE_COFFEE:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2Fcode%2Fcoffee.svg?alt=media&token=b39a3e7e-c500-4cf1-a9fd-2d8a92bcd9b2',
	CODE_VB:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2Fcode%2Fvb.svg?alt=media&token=b39a3e7e-c500-4cf1-a9fd-2d8a92bcd9b2',
	CODE_MD:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2Fcode%2Fmd.svg?alt=media&token=b39a3e7e-c500-4cf1-a9fd-2d8a92bcd9b2',
	CODE_CS:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2Fcode%2Fcs.svg?alt=media&token=b39a3e7e-c500-4cf1-a9fd-2d8a92bcd9b2',
	CODE_PHP:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2Fcode%2Fphp.svg?alt=media&token=b39a3e7e-c500-4cf1-a9fd-2d8a92bcd9b2',
	CODE_XML:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2Fcode%2Fxml.svg?alt=media&token=b39a3e7e-c500-4cf1-a9fd-2d8a92bcd9b2',
	CODE_JSON:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2Fcode%2Fjson.svg?alt=media&token=b39a3e7e-c500-4cf1-a9fd-2d8a92bcd9b2',
	CODE_LESS:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2Fcode%2Fless.svg?alt=media&token=b39a3e7e-c500-4cf1-a9fd-2d8a92bcd9b2',
	CODE_SCSS:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2Fcode%2Fscss.svg?alt=media&token=b39a3e7e-c500-4cf1-a9fd-2d8a92bcd9b2',
	CODE_R:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2Fcode%2Fr.svg?alt=media&token=b39a3e7e-c500-4cf1-a9fd-2d8a92bcd9b2',
	CODE_C:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2Fcode%2Fc.svg?alt=media&token=b39a3e7e-c500-4cf1-a9fd-2d8a92bcd9b2',
	CODE_TXT:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2Fcode%2Ftxt.svg?alt=media&token=b39a3e7e-c500-4cf1-a9fd-2d8a92bcd9b2',
	YOUTUBE:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/' +
		'icons%2F1698821255164_youtube.svg?alt=media&token=0270699a-652d-49ae-96fb-a2d63ed7cced',
	TEXT:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/icons%2Ftext-icon.svg?' +
		'alt=media&token=49fe16bc-998b-4187-b874-0bd4704d361d',
	AI_GENERATED: 'https://storage.googleapis.com/naya-assets/ai-icon-dark.svg',
	TODO:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/icons%2F1724134556524_Todo.svg?' +
		'alt=media&token=194b0a37-5931-4088-bbf8-cbdd87576de9',
	DOCSEND:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/Doc%20send%20logo.svg?' +
		'alt=media&token=a5187992-a3b5-482c-bc69-d0c1ceeb7ddb'
};

export const linksAndFilesTypes = {
	links: {
		'mail.google.com': ILinkSubtype.GMAIL,
		'docs.google.com/presentation': ILinkSubtype.GOOGLE_SLIDES,
		'docs.google.com/document': ILinkSubtype.GOOGLE_DOCS,
		'figma.com': ILinkSubtype.FIGMA,
		'docs.google.com/spreadsheets': ILinkSubtype.GOOGLE_SHEETS,
		'www.notion.so': ILinkSubtype.NOTION,
		'slack.com': ILinkSubtype.SLACK,
		'dropbox.com': ILinkSubtype.DROPBOX,
		'drive.google.com': ILinkSubtype.GOOGLE_DRIVE,
		'/pinterest\\.[a-z]/': ILinkSubtype.PINTEREST,
		'pin.it': ILinkSubtype.PINTEREST,
		'miro.com': ILinkSubtype.MIRO,
		'naya.studio/project': ILinkSubtype.NAYA,
		'naya-app.com/project': ILinkSubtype.NAYA,
		'naya.love/project': ILinkSubtype.NAYA,
		'1drv.ms': ILinkSubtype.MS_LINK,
		'onedrive.live.com': ILinkSubtype.MS_LINK,
		'onedrive.com': ILinkSubtype.MS_LINK,
		'login.live.com': ILinkSubtype.MS_LINK,
		'youtube.com': ILinkSubtype.YOUTUBE,
		'youtu.be': ILinkSubtype.YOUTUBE,
		'docsend.com': ILinkSubtype.DOCSEND
	} as Record<string, ICommonSubType>,
	files: {
		ai: IFileSubtype.ILLUSTRATOR,
		indd: IFileSubtype.INDESIGN,
		psd: IFileSubtype.PHOTO_SHOP,
		'3dm': I3DSubtype.RHINO,
		ksp: IFileSubtype.KEYSHOT,
		sldprt: I3DSubtype.SOLID_WORKS,
		slddrt: I3DSubtype.SOLID_WORKS,
		sldasm: I3DSubtype.SOLID_WORKS,
		slddrw: I3DSubtype.SOLID_WORKS,
		blend: I3DSubtype.BLENDER,
		fig: IFileSubtype.FIGMA,
		jam: IFileSubtype.FIGMA,
		xlsx: IFileSubtype.MS_EXCEL,
		xls: IFileSubtype.MS_EXCEL,
		doc: IFileSubtype.MS_WORD,
		docx: IFileSubtype.MS_WORD,
		ppt: IFileSubtype.MS_PPT,
		pptx: IFileSubtype.MS_PPT,
		js: IFileSubtype.CODE_JS,
		ts: IFileSubtype.CODE_TS,
		py: IFileSubtype.CODE_PY,
		java: IFileSubtype.CODE_JAVA,
		cpp: IFileSubtype.CODE_CPP,
		html: IFileSubtype.CODE_HTML,
		css: IFileSubtype.CODE_CSS,
		sass: IFileSubtype.CODE_SASS,
		rb: IFileSubtype.CODE_RB,
		lua: IFileSubtype.CODE_LUA,
		fs: IFileSubtype.CODE_FS,
		pug: IFileSubtype.CODE_PUG,
		bat: IFileSubtype.CODE_BAT,
		coffee: IFileSubtype.CODE_COFFEE,
		vb: IFileSubtype.CODE_VB,
		md: IFileSubtype.CODE_MD,
		cs: IFileSubtype.CODE_CS,
		php: IFileSubtype.CODE_PHP,
		xml: IFileSubtype.CODE_XML,
		json: IFileSubtype.CODE_JSON,
		less: IFileSubtype.CODE_LESS,
		scss: IFileSubtype.CODE_SCSS,
		r: IFileSubtype.CODE_R,
		c: IFileSubtype.CODE_C,
		txt: IFileSubtype.CODE_TXT
	} as Record<string, ICommonSubType>
} as const;

/**
 * Checks if a given string represents a valid regular expression pattern.
 * @param {string} str - The string to be checked.
 * @returns {boolean} - True if the string is a valid regular expression pattern, false otherwise.
 */
const isRegex = (str: string): boolean => {
	const regex = /^\/.*\/[a-z]*$/;
	// Test if the string matches the regex pattern
	if (!regex.test(str)) {
		return false; // String doesn't match the regex pattern
	}
	return true; // String matches the regex pattern
};

/**
 * Get the type of a link or file based on the provided link or extension.
 * @param {string} linkOrExtension - The link or file name to determine the type for.
 * @param {'LINK' | 'FILE'} blockType - 'LINK' for link types, 'FILE' for file name.
 * @returns {string} - The type of the link or file, or undefined if not found.
 *
 */
export const getFileLinkSubType = (
	linkOrFilename: string,
	blockType: 'LINK' | 'FILE' | 'THREE_D'
): ICommonSubType | undefined => {
	if (linkOrFilename) {
		let type: ICommonSubType | undefined;
		if (blockType === 'LINK') {
			// Check for link types
			Object.keys(linksAndFilesTypes.links).forEach((key) => {
				if (linkOrFilename.includes(key)) {
					type = linksAndFilesTypes.links[key]; // Return the type if a match is found
					// for MS_LINK subtype, modify subtype to show thumbnail
					if (type === 'MS_LINK') {
						if (linkOrFilename?.includes('docx')) type = ILinkSubtype.MS_WORD;
						if (linkOrFilename?.includes('pptx')) type = ILinkSubtype.MS_PPT;
						if (linkOrFilename?.includes('xlsx')) type = ILinkSubtype.MS_EXCEL;
						if (linkOrFilename?.includes('login')) type = ILinkSubtype.MS_LINK;
					}
				} else if (isRegex(key)) {
					// Match the regex
					const regex = new RegExp(key.slice(1, -1));
					if (regex.test(linkOrFilename)) {
						type = linksAndFilesTypes.links[key];
					}

					// Manually check for pptx file uploaded onto GCS bucket
				} else if (
					linkOrFilename.includes('storage.googleapis.com') &&
					linkOrFilename.includes('/PRESENTATION/')
				) {
					type = ILinkSubtype.MS_PPT;
				}
			});
		} else if (['FILE', 'THREE_D'].includes(blockType)) {
			const extension = linkOrFilename.split('.').pop();
			if (extension) {
				// Check for file extensions (case-insensitive)
				type = linksAndFilesTypes.files[extension.toLowerCase()];
			}
		}
		return type || blockType;
	}
	return undefined;
};

// Function to get collaborators for a project
export const getCollaborators = (): IUser[] =>
	// Filter the list of project users to exclude the current user
	store.getState().projectUsers.data;

export type TContent = {
	icon?: string; // This is the icon for different file types
	userDetail?: Partial<IUser>; // This returns partial user detail;
	text: string; // This returns text ;
};

/**
 * Handles the option click event and updates the search filters accordingly.
 */
export const handleFilterOption = (
	variant: string,
	content: TContent,
	searchFilters: TSearchQuery
) => {
	// Convert content text to uppercase for consistency
	const upperCaseType = content.text.toUpperCase();
	let tempSearchFilters = { ...searchFilters };

	// Switch statement to handle different option variants
	switch (variant) {
		case 'LAST_UPDATED':
			// Toggle the 'lastUpdated' property in searchFilters
			if (tempSearchFilters.lastUpdated) {
				delete tempSearchFilters.lastUpdated;
			} else {
				tempSearchFilters = {
					...tempSearchFilters,
					lastUpdated: true
				};
			}
			break;
		case 'ICON':
			if (
				tempSearchFilters &&
				tempSearchFilters.types &&
				tempSearchFilters.types.includes(upperCaseType)
			) {
				// Remove the type if it's already present in the 'type' array
				tempSearchFilters = {
					...tempSearchFilters,
					types: tempSearchFilters.types.filter((type) => type !== upperCaseType)
				};
				if (!tempSearchFilters.types?.length) {
					delete tempSearchFilters.types;
				}
			} else {
				// Add the type if it's not already in the 'type' array
				tempSearchFilters = {
					...tempSearchFilters,
					types: tempSearchFilters.types
						? [...tempSearchFilters.types, upperCaseType]
						: [upperCaseType]
				};
			}
			break;

		case 'USER_CREATED_BY':
		case 'CREATED_BY':
			if (
				tempSearchFilters?.createdBy &&
				tempSearchFilters?.createdBy?._id === content?.userDetail?._id
			) {
				// Remove the 'createdBy' property if it exists
				delete tempSearchFilters.createdBy;
			} else {
				// Set the 'createdBy' property with user details
				tempSearchFilters = {
					...tempSearchFilters,
					createdBy: {
						email: content?.userDetail?.email,
						profilePic: content?.userDetail?.profilePic,
						_id: content?.userDetail?._id
					}
				};
			}
			break;

		case 'SHARED_WITH':
		case 'USER_SHARED_WITH':
			if (
				tempSearchFilters?.sharedWith &&
				tempSearchFilters?.sharedWith?._id === content?.userDetail?._id
			) {
				// Remove the 'sharedWith' property if it exists
				delete tempSearchFilters.sharedWith;
			} else {
				// Set the 'sharedWith' property with user details
				tempSearchFilters = {
					...tempSearchFilters,
					sharedWith: {
						email: content?.userDetail?.email,
						profilePic: content?.userDetail?.profilePic,
						_id: content?.userDetail?._id
					}
				};
			}
			break;

		case 'STICKY_NOTE':
			if (
				tempSearchFilters &&
				tempSearchFilters.stickyNotes &&
				tempSearchFilters.stickyNotes.includes(content.icon as string)
			) {
				// Remove the color if it's already present in the 'sticky notes' array
				tempSearchFilters = {
					...tempSearchFilters,
					stickyNotes: tempSearchFilters.stickyNotes.filter(
						(color) => color !== content.icon
					)
				};
				if (!tempSearchFilters.stickyNotes?.length) {
					delete tempSearchFilters?.stickyNotes;
				}
			} else {
				// Add the color if it's not already in the 'sticky notes' array
				tempSearchFilters = {
					...tempSearchFilters,
					stickyNotes: [...(tempSearchFilters.stickyNotes ?? []), content?.icon ?? '']
				};
			}
			break;
		case 'DUE_DATE':
			// Toggle the 'date added' property in searchFilters
			if (tempSearchFilters.dueDate) {
				delete tempSearchFilters.dueDate;
			} else {
				tempSearchFilters = {
					...tempSearchFilters,
					dueDate: true
				};
			}
			break;

		case 'TIMELINE_VIEW':
			// Toggle the 'date added' property in searchFilters
			if (tempSearchFilters.timelineView) {
				delete tempSearchFilters.timelineView;
			} else {
				tempSearchFilters = {
					...tempSearchFilters,
					timelineView: true
				};
			}
			break;
		default:
			// Do nothing for other variants
			break;
	}
	return tempSearchFilters;
};

export const stickyColorNames: { [key: string]: string } = {
	'#C6C0C4': 'Slate grey',
	'#161616': 'Black',
	'#02CBEF': 'Turquoise blue',
	'#9ED36D': 'Lime green',
	'#F9DB60': 'Sunshine yellow',
	'#E397B3': 'Flamingo pink',
	'#EE8C84': 'Peach orange',
	'#FA4D56': 'Tomato red',
	'#FFA35E': 'Tangerine orange',
	'#B989FF': 'Lavender purple'
};

export const integrationOptions: { [key: string]: string } = {
	GOOGLE:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/app-options%2Fgoogle.svg' +
		'?alt=media&token=4a138ca8-e2b6-4321-902e-599bf7bf6e2b',
	FIGMA:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/app-options%2Ffigma.svg' +
		'?alt=media&token=c3c85eaf-cbbe-4452-9253-aa40f0217e8c',
	MIRO:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/app-options%2Fmiro.svg' +
		'?alt=media&token=038fd6a7-3f1a-4893-8844-3a808ddece4b',
	ADOBE:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/app-options%2Fadobe.svg?' +
		'alt=media&token=2d776d7b-2cce-40d7-9c83-51d4e43f9515',
	ONSHAPE:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/app-options%2FonShape.svg' +
		'?alt=media&token=ec58b6b4-c265-402c-bf9f-62435c999267',
	MS:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/app-options%2Fmicrosoft365.svg' +
		'?alt=media&token=92ba4c93-c0d0-48a8-af3d-bd369b5605b9',
	OPEN_AI:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/app-options%2FopenAi.svg' +
		'?alt=media&token=ce99399e-c33f-4512-b87e-4133197ebaf6',
	MID_JOURNEY:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/app-options%2Fmidjourney.svg' +
		'?alt=media&token=556aabf6-6b05-4e5e-8426-614bcaac68ff',
	STABILITY_AI:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/app-options%2FstabilityAi.svg' +
		'?alt=media&token=9530c379-04e7-4fbe-bcbf-4ffe1ac04a4c'
};

export const syncStorageOptions: { [key: string]: string } = {
	GOOGLE_DRIVE:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/app-options%2Fgoogle-drive.svg' +
		'?alt=media&token=3b64a5da-7d96-4080-83a0-36d6f6ddcb3c',
	DROPBOX:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/app-options%2Fdropbox.svg' +
		'?alt=media&token=60d21d3b-36cd-4e77-99f9-3f5c1a6d76ef',
	ICLOUD:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/app-options%2FiCloud.svg' +
		'?alt=media&token=4cbe290c-47e8-4146-a36f-513af389da91',
	SHARE_POINT:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/app-options%2Fshare_point.svg' +
		'?alt=media&token=33e04afa-068c-43e6-a319-29e325433a90',
	SLACK:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/app-options%2Fslack.svg' +
		'?alt=media&token=fb0cc5f7-f6ab-49b6-a87d-8e5596ea8bff',
	LOCAL:
		'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/app-options%2Flocal.svg' +
		'?alt=media&token=cf1311c5-0b6e-484f-b4be-ae0e1356c765'
};

export const timelineIcon =
	'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/Timeline.svg?' +
	'alt=media&token=ccde8afe-607d-415f-b12c-b23142071e77';
export const groupIcon =
	'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/group-icon.svg?' +
	'alt=media&token=ccde8afe-607d-415f-b12c-b23142071e77';
export const defaultBlockIcon =
	'https://firebasestorage.googleapis.com/v0/b/naya-assets/o/1707404466134_plus_dark.svg?' +
	'alt=media&token=14db801e-97bf-4f74-b22d-be8843d9ca27';
