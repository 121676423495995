import { IObserver, IProject } from '@naya_studio/types';
import { store } from 'src';
import { IRestrictedAccess, TSyncStorage } from 'src/redux/reducers/root.types';
import { generateIdsFromUrl } from 'src/redux/reduxActions/util';
import { checkOrCreateGoogleFolder } from 'src/redux/reduxActions/integrations/googleIntegrations';
import { NAYA_FOLDER_NAME } from 'src/redux/reduxActions/integrations/utils';
import { CustomDispatch } from 'src/redux/actions/types';
import { createObserver } from 'src/redux/reduxActions/observer';
import mongoose from 'mongoose';
import getUserFromRedux from './user';

/**
 * Function to get project observer based to storage type
 * @param projectId - prroject ID
 * @param syncType - type of sync storage
 * @returns
 */
export const getProjectSyncObserver = (projectId: string, syncType: TSyncStorage) => {
	const { projects } = store.getState();
	const project = projects.data[projectId] as IProject;
	const userId = getUserFromRedux()._id;
	const observer = project?.observers?.filter(
		(item) =>
			(item as IObserver).projectId === projectId &&
			(item as IObserver).userId === userId &&
			(item as IObserver).observerType === syncType
	);
	return observer ? (observer[0] as IObserver) : null;
};

/**
 * Function to get project from redux
 * @param projectId - ID of the project
 * @returns project
 */
export const getProjectFromRedux = (projectId: string) => {
	const { projects } = store.getState();
	const project = projects.data[projectId] as IProject;
	return project;
};

/**
 * Function to get ids of blocks on which user is not invited
 * @returns array of ids
 */
export const getRestrictedBlocks = () => {
	const { projectId } = generateIdsFromUrl();
	const project = getProjectFromRedux(projectId) as IProject & IRestrictedAccess;
	return project?.restrictedAccess?.blocks || [];
};

/**
 * Function to link project to its respective folder on google drive
 * @param {string} access_token - google access token for authentication
 * @returns
 */
export const linkProjectToGoogleDrive = async (access_token: string, oldFolderId?: string) => {
	const { projectId } = generateIdsFromUrl();
	const { projects } = store.getState();
	const project = projects.data[projectId] as IProject;

	// check for NAYA folder
	const parentFolderId = await checkOrCreateGoogleFolder(access_token, NAYA_FOLDER_NAME);
	if (!parentFolderId) throw new Error('Naya folder not found !');

	// extract project folder ID
	const folderId = await checkOrCreateGoogleFolder(
		access_token,
		`${project?.projectDetails?.name}-${projectId}`,
		parentFolderId
	);

	if (oldFolderId !== folderId)
		(store.dispatch as CustomDispatch)(
			createObserver({
				_id: new mongoose.Types.ObjectId().toString(),
				folderId,
				projectId,
				userId: getUserFromRedux()._id as string,
				observerType: 'GOOGLE_DRIVE'
			})
		);
	return folderId;
};
