export const defaultDimensions: DefaultDimensions = {
  chair: [20, 18, 32],
  "dining-chair": [20, 18, 32],
  "accent-chair": [32, 30, 34],
  "outdoor-chair": [22, 23, 30],
  "lounge-chair": [58, 35, 27],
  "rocking-chair": [32, 25, 42],
  table: [28, 45, 29],
  "console-table": [12, 52, 31],
  "coffee-table": [23, 43, 16],
  "dining-table": [71, 36, 31],
  "outdoor-table": [18, 18, 18],
  "changing-table": [20, 34, 40],
  "entry-table": [40, 40, 32],
  "cocktail-table": [14, 14, 24],
  "dress-chest": [53, 17, 33],
  sofa: [32, 78, 33],
  "sectional-sofa": [50, 78, 35],
  "outdoor-sofa": [36, 76, 27],
  desk: [24, 55, 30],
  "standing-desk": [24, 42, 45],
  "roll_top-desk": [24, 42, 20],
  "executive-desk": [32, 80, 30],
  bench: [17, 52, 17],
  "bedroom-bench": [16, 46, 15],
  "entryway-bench": [12, 35, 19],
  "kitchen-bench": [14, 42, 19],
  "outdoor-bench": [17, 50, 34],
  bar_cart: [14, 27, 32],
  "tv_stand-console": [14, 63, 22],
  sideboard: [16, 54, 34],
  "stool-ottoman": [15, 30, 15],
  "bar-stool": [16, 14, 32],
  "accent-stool": [11, 16, 19],
  "outdoor-stool": [17, 17, 30],
  "outdoor-ottoman": [28, 28, 19],
  "bookshelves-bookcases-openshelves": [12, 36, 60],
  cabinet: [16, 30, 32],
  "filling-cabinet": [18, 14, 46],
  "accent-cabinet": [16, 38, 25],
  "bar-cabinet": [19, 47, 30],
  chaise: [59, 34, 34],
  "outdoor-chaise": [79, 25, 15],
  wardrobe: [19, 35, 70],
  "side_table-nightstand": [17, 22, 23],
  "vanities-washstands": [22, 30, 38],
  "makeup-vanities": [16, 32, 52],
  bed: [82, 63, 45],
  headboard: [63, 2, 46],
  "racks_hooks-ladder": [1.5, 19, 77],
  crib: [30, 54, 35],
};

export const materialsList = [
  "aluminum",
  "ash",
  "bamboo",
  "birch",
  "brass",
  "bronze",
  "cedar",
  "concrete",
  "fabric",
  "finished",
  "fir",
  "glass",
  "granite",
  "hardwood",
  "iron",
  "leathers",
  "mahogany",
  "marble",
  "metal",
  "oak",
  "patina",
  "pine",
  "plywood",
  "poplar",
  "sanded",
  "steel",
  "stone",
  "unfinished",
  "walnut",
  "wood",
];

export type Length = number;
export type Width = number;
export type Height = number;

export type Dimensions = [Length, Width, Height];

export type DefaultDimensions = {
  [key: string]: Dimensions;
};
