import {
	EditableTitle,
	DropDown,
	MenuTag,
	handleFilterOption,
	TContent,
	SessionStorageKeys
} from '@naya_studio/radix-ui';
import { IProject, IProjectDetail, TUserAndRole } from '@naya_studio/types';
import classNames from 'classnames';
import { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { store } from 'src';
import { CustomDispatch } from 'src/redux/actions/types';
import useProject from 'src/redux/hooks/useProject';
import { duplicateProjectOptimistically, editProjectDetails } from 'src/redux/reduxActions/project';
import {
	generateIdsFromUrl,
	getStageAndBlockIdsOfProject,
	ignoreRemindersForJourney
} from 'src/redux/reduxActions/util';
import {
	TDuplicateProjectOptimisticallyThunkArg,
	TEditProjectDetailsThunkArg
} from 'src/types/argTypes';
import { ReactComponent as DropdownIcon } from 'src/assets/icons/navbar/dropdown.svg';
import { ReactComponent as Arrow } from 'src/assets/icons/arrow-menu.svg';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';
import { useUpdateMultipleNotifsMutation } from 'src/redux/features/api/notification';
import useUser from 'src/redux/hooks/user';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ESharingTabs } from 'src/components/sharingModal/SharingModal.types';
import { onDuplicateProject } from 'src/redux/actions/cloneUtils';
import { IDeliverableTypes, PathParamsType, TLDFlags } from '../../CollaborationTool.types';
import { CollaborationToolContext } from '../../CollaborationTool';

/**
 * Component Prop types for Journey Name
 */
type JourneyNameProps = {
	journeyName: string;
	setJourneyName: React.Dispatch<React.SetStateAction<string>>;
	setShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
	onAIOrganize: (blockIds: string[], groupIds: string[]) => void;
	setActiveTab: React.Dispatch<React.SetStateAction<keyof typeof ESharingTabs>>;
	setSharingDetails: React.Dispatch<
		React.SetStateAction<{
			projectId: string;
			phaseId: string | undefined;
			blockId: string | undefined;
		}>
	>;
};

/**
 * Dropdown options for Journey Name
 */
enum DropdownOptionKeys {
	SHARE_JOURNEY = 'SHARE_JOURNEY',
	DOWNLOAD_JOURNEY_POSTER = 'DOWNLOAD_JOURNEY_POSTER',
	DOWNLOAD_FILES = 'DOWNLOAD_FILES',
	RENAME_JOURNEY = 'RENAME_JOURNEY',
	DUPLICATE_JOURNEY = 'DUPLICATE_JOURNEY',
	USE_TEMPLATE = 'USE_TEMPLATE',
	TURN_INTO_TEMPLATE = 'TURN_INTO_TEMPLATE',
	WITH_ASSETS = 'WITH_ASSETS',
	WITHOUT_ASSETS = 'WITHOUT_ASSETS',
	DELETE_JOURNEY = 'DELETE_JOURNEY',
	ORGANIZE_JOURNEY = 'ORGANIZE_JOURNEY',
	TIMELINE_VIEW = 'TIMELINE_VIEW'
}

/**
 * Component to render Journey Name in navbar
 * @renders Journey Name in Navbar
 */
const JourneyName = ({
	journeyName,
	setJourneyName,
	setShowDeleteModal,
	onAIOrganize,
	setActiveTab,
	setSharingDetails
}: JourneyNameProps) => {
	const history = useHistory();
	const params = useParams<PathParamsType>();
	const { user } = useUser();
	const { projectId } = generateIdsFromUrl();
	const { project } = useProject(projectId);

	const [updateMultipleNotifs] = useUpdateMultipleNotifsMutation();
	const collabContext = CollaborationToolContext;

	const {
		deliverableType,
		showShareModal,
		setDeliverableType,
		setShowShareModal,
		handleUndoRedo,
		onCollabUnMount,
		setSearchFilters,
		searchFilters
	} = useContext(collabContext);

	const { isAiOrganizeEnabled, isTimelineEnabled, isTurnIntoTemplateEnabled } =
		useFlags<TLDFlags>();

	// State to check if journey title is in edit state
	const [isEditState, setIsEditState] = useState(false);
	// State to check if journey dropdown is active
	const [isDropdownActive, setIsDropdownActive] = useState(false);

	/**
	 * Function to check if user is the Project Owner
	 * @returns Boolean
	 */
	const isProjectOwner = () => project?.createdBy === user._id;

	/**
	 * Function to handle filters change
	 * @param query {TSearchQuery} - applied filters object
	 */
	const handleFiltersChange = (variant: string, content: TContent) => {
		const query = handleFilterOption(variant, content, searchFilters);
		if (query) {
			setSearchFilters(query);
		}
	};

	/**
	 * Function to check if user has editor level access to Project
	 * @returns Boolean indicating user's access to Project
	 */
	const isProjectLevelEditor = () => {
		for (let i = 0; i < ((project as IProject)?.users as TUserAndRole[])?.length; i++) {
			if (
				(project?.users as TUserAndRole[])[i]!.user === user._id &&
				(project?.users as TUserAndRole[])[i]!.role === 'EDITOR'
			) {
				return true;
			}
		}
		return false;
	};

	/**
	 * Callback to handle journey rename
	 * @param newTitle
	 */
	const onSubmit = (newTitle: string, isUndoRedo: boolean = false) => {
		// Fetch active project group details from redux
		const reduxState = store.getState();
		const activeGroup = reduxState.projectGroup.data;
		// Fetch the group id of the project being renamed
		const parentGroupId = (
			activeGroup._id ? activeGroup._id : user.defaultProjectGroup
		) as string;

		const journeyTitle = newTitle.trim() || (project?.projectDetails as IProjectDetail)?.name;
		const apiPayload: TEditProjectDetailsThunkArg = {
			payload: {
				projectId,
				updates: {
					_id: (project?.projectDetails as IProjectDetail)?._id,
					name: journeyTitle
				},
				parentGroupId
			},
			prevState: {
				name: (project?.projectDetails as IProjectDetail).name
			}
		};

		(store.dispatch as CustomDispatch)(editProjectDetails(apiPayload))
			.unwrap()
			.then((response: TEditProjectDetailsThunkArg['payload']) => {
				if (journeyTitle) setJourneyName(journeyTitle);

				// If someone renames the journey and sets it as Untitled, ignore it as well
				// Considering it is renamed
				if (response.updates.name?.toLowerCase() === 'untitled')
					ignoreRemindersForJourney(projectId);

				/** --- Update Project Name in all notifications of that project ---*/
				updateMultipleNotifs({
					searchBy: { 'data.projectId': projectId },
					update: { 'data.projectName': journeyTitle },
					operation: 'PROJECT_RENAME'
				});
			});

		if (!isUndoRedo) {
			handleUndoRedo({
				type: 'ADD',
				payload: {
					originalAction: onSubmit,
					oppositeAction: onSubmit,
					originalActionPayload: [newTitle],
					oppositeActionPayload: [journeyName]
				}
			});
		}
	};

	/**
	 * Function to toggle share modal
	 */
	const toggleShareModal = () => {
		setShowShareModal(!showShareModal);
	};

	/**
	 * Function to set active deliverable type
	 * @param newType
	 */
	const setActiveDeliverableType = (newType: IDeliverableTypes) => {
		if (setDeliverableType) setDeliverableType(newType);
	};

	/**
	 * Function to handle rename dropdown option click
	 */
	const onRenameJourneyStart = () => {
		setIsEditState(true);
	};

	/**
	 * Function to Duplicate/Make a template of a project from Journey Space
	 * @param action - variant of Duplication
	 * @param shouldOpenProject Make a copy
	 */
	const onDuplicateJourney = async (
		action?: 'DUPLICATE' | 'WITH_ASSETS' | 'WITHOUT_ASSETS' | 'USE_TEMPLATE',
		fromMakeACopy?: boolean
	) => {
		const sessionGroup = window.sessionStorage.getItem(SessionStorageKeys.ACTIVE_GROUP);
		const isTemplate = !!(action === 'WITH_ASSETS' || action === 'WITHOUT_ASSETS');
		// Set the name of the new duplicated project
		// If duplicating a template remove Template word and add (Copy)
		const name =
			action === 'USE_TEMPLATE' || fromMakeACopy
				? (`${`${((project?.projectDetails as IProjectDetail).name as string).replace(
						'Template',
						''
				  )}(Copy)`}` as string)
				: (`${(project?.projectDetails as IProjectDetail).name}${
						isTemplate ? ' Template' : ' (Copy)'
				  }` as string);

		const parentGroupId = sessionGroup
			? (JSON.parse(sessionGroup).id as string)
			: (user.defaultProjectGroup as string);
		const response = await onDuplicateProject(projectId, {
			parentGroupId,
			isTemplate,
			newName: name,
			shouldCloneAssets: action !== 'WITHOUT_ASSETS'
		});

		if (response) {
			const { clonedProject, clonedGroups, clonedBlocks, clonedNotes, clonedNodes } =
				response;
			const apiPayload: TDuplicateProjectOptimisticallyThunkArg = {
				payload: {
					parentGroupId,
					clonedItems: {
						project: clonedProject,
						groups: clonedGroups,
						blocks: clonedBlocks,
						notes: clonedNotes,
						nodes: clonedNodes
					},
					originalProjectId: projectId,
					populatedUser: {
						userDetails: user.userDetails,
						_id: user._id,
						userName: user.userName,
						email: user.email,
						profilePic: user.profilePic,
						userPreferences: user.userPreferences
					}
				}
			};

			// Call to Redux Action to Duplicate the project optimistically
			(store.dispatch as CustomDispatch)(duplicateProjectOptimistically(apiPayload))
				.unwrap()
				.catch(() => {
					// Add Error Snackbar
					addSnackbar({ show: true, type: 'ERROR', text: 'Project Duplication Failed' });
					removeSnackbar(5000);
					history.push('/studio');
				});

			if (!fromMakeACopy) {
				// Snackbar for Duplication from Navbar drop down
				addSnackbar({
					show: true,
					type: 'NORMAL',
					text: isTemplate ? 'Journey template created.' : 'Journey duplicated.',
					actionButtonData: [
						{
							buttonData: 'Open',
							onClick: () => {
								removeSnackbar(0);
								setTimeout(() => {
									history.push(`/project/${clonedProject._id}`);
								}, 500);
							},
							className: 'custom-button',
							show: true
						}
					]
				});
				if (!isTemplate) {
					// Remove snackbaar only if the duplicated project is not a template
					removeSnackbar(5000);
				}
			} else {
				// Snackbar for Duplication using Make a Copy option in snackbar
				addSnackbar({
					show: true,
					type: 'NORMAL',
					text: 'Journey Duplicated',
					actionButtonData: [
						{
							buttonData: 'Go there',
							onClick: () => {
								removeSnackbar(0);
								setTimeout(() => {
									history.push(`/project/${clonedProject._id}`);
								}, 500);
							},
							className: 'custom-button',
							show: true
						}
					]
				});
				removeSnackbar(5000);
			}
		}
	};

	/** True, if user has edit access */
	const hasEditAccess = useMemo(
		() => user.userType?.includes('ADMIN') || isProjectOwner() || isProjectLevelEditor(),
		[user._id, user.userType]
	);

	type TDropdownOptionType = {
		key: keyof typeof DropdownOptionKeys;
		value: string;
		onClick: () => void;
		isDisabled?: boolean;
		icon?: ReactNode;
		showDivider?: boolean;
		classNames?: string;
		isSubMenu?: boolean;
		subOptions?: TDropdownOptionType[];
	};
	/**
	 * Dropdown options
	 */
	let dropdownOptions: TDropdownOptionType[] = [
		{
			key: DropdownOptionKeys.TIMELINE_VIEW,
			value: 'Timeline view',
			onClick: () => {
				handleFiltersChange('TIMELINE_VIEW', {
					text: 'timelineView'
				} as TContent & string);
			},
			showDivider: true,
			isDisabled: !isTimelineEnabled
		},
		{
			key: DropdownOptionKeys.ORGANIZE_JOURNEY,
			value: 'Organize journey',
			onClick: () => {
				if (isAiOrganizeEnabled) {
					const { blockIds, stageIds } = getStageAndBlockIdsOfProject(projectId);
					if (stageIds.length > 0 || blockIds.length > 0)
						onAIOrganize(blockIds, stageIds);
				}
			},
			showDivider: true,
			isDisabled: !isAiOrganizeEnabled,
			icon: (
				<MenuTag
					label={isAiOrganizeEnabled ? 'AI beta' : 'AI coming soon'}
					showAIIcon
					isDisabled={!isAiOrganizeEnabled}
				/>
			),
			classNames:
				'ai-menu menu-tag-parent tw-flex tw-items-center tw-justify-between tw-w-[224px]'
		},
		{
			key: DropdownOptionKeys.DOWNLOAD_JOURNEY_POSTER,
			value: 'Export poster',
			onClick: () => {
				if (!deliverableType) setActiveDeliverableType('DOWNLOAD_POSTER');
			}
		},
		{
			key: DropdownOptionKeys.DOWNLOAD_FILES,
			value: 'Export links and files',
			onClick: () => {
				if (!deliverableType) setActiveDeliverableType('DOWNLOAD_FILES');
			},
			showDivider: true
		},
		{
			key: DropdownOptionKeys.SHARE_JOURNEY,
			value: 'Share journey',
			onClick: () => {
				onCollabUnMount(toggleShareModal);
				setSharingDetails({
					projectId,
					phaseId: undefined,
					blockId: undefined
				});
				setActiveTab(ESharingTabs.PROJECT);
			}
		},
		{
			key: DropdownOptionKeys.RENAME_JOURNEY,
			value: 'Rename journey',
			onClick: () => {
				onRenameJourneyStart();
			}
		},
		{
			key: DropdownOptionKeys.DUPLICATE_JOURNEY,
			value: 'Duplicate journey',
			onClick: () => {
				onDuplicateJourney('DUPLICATE');
			},
			showDivider: !isTurnIntoTemplateEnabled
		},
		{
			key: DropdownOptionKeys.USE_TEMPLATE,
			value: 'Use template',
			onClick: () => {
				onDuplicateJourney('USE_TEMPLATE');
			},
			showDivider: true
		},
		{
			key: DropdownOptionKeys.TURN_INTO_TEMPLATE,
			value: 'Turn into template',
			onClick: () => {
				onDuplicateJourney('USE_TEMPLATE');
			},
			isSubMenu: true,
			subOptions: [
				{
					key: DropdownOptionKeys.WITHOUT_ASSETS,
					value: 'Without existing assets',
					onClick: () => {
						onDuplicateJourney('WITHOUT_ASSETS');
					}
				},
				{
					key: DropdownOptionKeys.WITH_ASSETS,
					value: 'With existing assets',
					onClick: () => {
						onDuplicateJourney('WITH_ASSETS');
					}
				}
			],
			showDivider: true
		},
		{
			key: DropdownOptionKeys.DELETE_JOURNEY,
			value: 'Delete journey',
			onClick: () => {
				setShowDeleteModal(true);
			}
		}
	];

	/**
	 * Function to render dropdown items
	 * @returns
	 */
	const renderDropdownItems = () => {
		/**
		 * Function to check if user is allowed to rename journey
		 */
		const checkIfUserCanRename = () =>
			user.userType?.includes('ADMIN') || isProjectOwner() || isProjectLevelEditor();

		/**
		 * Function to check if user is allowed to delete journey
		 */
		const checkIfUserCanDelete = () => user.userType?.includes('ADMIN') || isProjectOwner();

		if (!isAiOrganizeEnabled) {
			dropdownOptions = dropdownOptions.filter(
				(option) => option.key !== DropdownOptionKeys.ORGANIZE_JOURNEY
			);
		}

		if (!isTimelineEnabled) {
			dropdownOptions = dropdownOptions.filter(
				(option) => option.key !== DropdownOptionKeys.TIMELINE_VIEW
			);
		}

		if (!checkIfUserCanDelete())
			dropdownOptions = dropdownOptions.filter(
				(option) => option.key !== DropdownOptionKeys.DELETE_JOURNEY
			);

		if (!checkIfUserCanRename())
			dropdownOptions = dropdownOptions.filter(
				(option) =>
					![
						DropdownOptionKeys.RENAME_JOURNEY,
						DropdownOptionKeys.ORGANIZE_JOURNEY
					].includes(option.key as DropdownOptionKeys)
			);

		if (project?.isTemplate || !isTurnIntoTemplateEnabled)
			dropdownOptions = dropdownOptions.filter(
				(option) => option.key !== DropdownOptionKeys.TURN_INTO_TEMPLATE
			);
		if (!project?.isTemplate)
			dropdownOptions = dropdownOptions.filter(
				(option) => option.key !== DropdownOptionKeys.USE_TEMPLATE
			);

		return (
			<>
				{dropdownOptions.map((option, index) => {
					if (option.isSubMenu) {
						return (
							<DropDown.Sub key={option.key}>
								<DropDown.SubTrigger
									className={`kebab-items tw-text-xs tw-font-randRegular 
													tw-leading-6 tw-pt-1 tw-pb-1 tw-px-2 tw-cursor-pointer`}
									data-testid={`tdid-${option.key}`}
								>
									<div>{option.value}</div>
									<div className="tw-flex">
										<Arrow />
									</div>
								</DropDown.SubTrigger>
								<DropDown.SubContent
									sideOffset={8}
									alignOffset={-5}
									classNames="tw-p-2 dropdown-content tw-rounded-2xl tw-bg-white"
								>
									{option.subOptions?.map((subOpt) => (
										<DropDown.DropDownItem key={`item-${subOpt.key}`}>
											<div
												role="presentation"
												className={`kebab-items tw-text-xs tw-font-randRegular 
															tw-leading-6 tw-pt-1 tw-pb-1 tw-px-2 tw-cursor-pointer`}
												onClick={() => subOpt.onClick()}
												data-testid={`tdid-${subOpt.key}`}
											>
												{subOpt.value}
											</div>
										</DropDown.DropDownItem>
									))}
								</DropDown.SubContent>
								{option.showDivider && index !== dropdownOptions.length - 1 && (
									<div className="context-menu-separator" />
								)}
							</DropDown.Sub>
						);
					}

					return (
						<DropDown.DropDownItem key={option.key}>
							<div
								role="presentation"
								data-testid={`dtid-${option.key}`}
								className={`kebab-items tw-text-xs tw-font-randRegular 
                                tw-leading-6 tw-pt-1 tw-pb-1 tw-px-2 tw-cursor-pointer
								${option.classNames ? option.classNames : ''}
                                ${classNames({
									'red-text': option.key === 'DELETE_JOURNEY',
									disabled:
										['DOWNLOAD_FILES', 'DOWNLOAD_JOURNEY_POSTER'].includes(
											option.key
										) && deliverableType,
									'context-menu-item-disabled': option.isDisabled
								})}
                            `}
								onClick={() => option.onClick()}
							>
								{option.value}
								{option.icon && option.icon}
							</div>
							{option.showDivider && index !== dropdownOptions.length - 1 && (
								<div className="context-menu-separator" />
							)}
						</DropDown.DropDownItem>
					);
				})}
			</>
		);
	};

	useEffect(
		() => () => {
			const { snackBar } = store.getState();
			// Remove Snackbar on unmount only if it is Template Snackbar
			if (snackBar.text === 'Heads up—this is a template project.') removeSnackbar(0);
		},
		[]
	);

	/**
	 * Whenever project changes, check if project is template and show the Template Snackbar
	 */
	useEffect(() => {
		const { projectId: projectIdInUrl } = generateIdsFromUrl();
		if (project?.isTemplate && projectIdInUrl && hasEditAccess) {
			addSnackbar({
				show: true,
				type: 'NORMAL',
				text: 'Heads up—this is a template project.',
				actionButtonData: [
					{
						buttonData: 'Make a copy',
						onClick: () => {
							onDuplicateJourney('DUPLICATE', true);
						},
						className: 'custom-button',
						show: true
					},
					{
						// Unicode of cross mark
						buttonData: `\u2715`,
						onClick: () => {
							removeSnackbar(0);
						},
						className: 'dismiss',
						show: true
					}
				]
			});
		}
	}, [params.projectId]);

	return (
		<>
			<EditableTitle
				title={journeyName}
				isEditState={isEditState && hasEditAccess}
				onEnter={onSubmit}
				setIsEditState={(args) => {
					if (hasEditAccess) setIsEditState(args);
				}}
				onRevert={() => setIsEditState(false)}
				variant="PROJECT"
				isSearchActive={false}
				color="none"
			/>
			{hasEditAccess && (
				<DropDown
					modal={false}
					onOpenChange={(value: boolean) => setIsDropdownActive(value)}
				>
					<DropDown.DropDownTrigger>
						<div
							className={`journey-title-dropdown-trigger tw-flex tw-justify-center tw-items-center 
                        tw-w-6 tw-h-6 tw-rounded-xl ${classNames({
							'tw-bg-[#F1EBFA] active': isDropdownActive
						})}`}
						>
							<DropdownIcon data-testid="btn-export-menu" />
						</div>
					</DropDown.DropDownTrigger>
					<DropDown.DropDownContent classNames="tw-p-2 dropdown-content tw-rounded-2xl tw-bg-white tw-right-0 tw-top-2">
						{renderDropdownItems()}
					</DropDown.DropDownContent>
				</DropDown>
			)}
		</>
	);
};

export default JourneyName;
