import { IBlock, INode, IUser, TAbsoluteBoundingBox } from '@naya_studio/types';
import {
	getHighlightedBoxCoordinates,
	getNodeDataWithAbsoluteBounds
} from 'src/components/utilities/clipboard/clipboard';
import App from 'src/components/canvas/app/App';
import { Rectangle } from 'pixi.js';
import { v1 as uuidv1 } from 'uuid';
import { store } from 'src';
import { CustomDispatch } from 'src/redux/actions/types';
import { addNodes } from 'src/redux/reduxActions/node';
import { TAddNodesArg } from 'src/types/argTypes';
import { ReduxState } from 'src/redux/reducers/root.types';
import { generateIdsFromUrl } from 'src/redux/reduxActions/util';
import getUserFromRedux from 'src/util/helper/user';

const isSameCanvas = (app: App, canvasId: string) => app.getCanvasId() === canvasId;
/**
 * Function to paste copied node
 * @param nodeDataArray specifies array of nodeData
 * @param absBounds specifies location of pasting the node/nodes
 * @param type specifies mode(TOPLEFT, CENTRE) of viewport, where the node/nodes are being pasted
 */
const pasteNode = async (
	app: App,
	nodeDataArray: INode[],
	type: string,
	absBounds?: TAbsoluteBoundingBox | undefined,
	blockId?: string,
	offsetCounter?: number,
	prevCenter?: Rectangle
) => {
	const boxCordinates = getHighlightedBoxCoordinates(nodeDataArray);
	app.toggleEditMenu(false);
	app.removeAllSelectedNodes();
	app.removeTransformer();
	const allNodesToSave: INode[] = [];
	for (let i = 0; i < nodeDataArray.length; i++) {
		const nodeData = nodeDataArray[i] as INode;
		let localAbsBounds = absBounds || nodeData.absoluteBounds;
		if (!localAbsBounds) {
			localAbsBounds = {
				x: 0,
				y: 0,
				width: 100,
				height: 100
			};
		}
		let copyData = nodeData;
		if (blockId && isSameCanvas(app, blockId)) {
			copyData = getNodeDataWithAbsoluteBounds(
				nodeData,
				localAbsBounds!,
				boxCordinates,
				type,
				(offsetCounter || 1) * 25,
				app,
				prevCenter
			);
		} else {
			copyData = getNodeDataWithAbsoluteBounds(
				nodeData,
				localAbsBounds!,
				boxCordinates,
				'',
				((offsetCounter || 1) - 1) * 25,
				app,
				prevCenter
			);
		}
		copyData.zIndex = app.getNewZindex(undefined, false, true);
		delete copyData._id;
		copyData._id = uuidv1();
		const user: IUser = getUserFromRedux();
		copyData.createdBy = user._id as string;
		copyData.lastUpdatedBy = user._id as string;
		copyData.version = 0;
		allNodesToSave.push(copyData);
	}
	// extracting redux state
	const reduxState = store.getState() as ReduxState;
	//  generating payload for add nodes action
	const payload: TAddNodesArg = {
		data: {
			nodes: allNodesToSave,
			...generateIdsFromUrl()
		},
		prevState: {
			prevNodes: reduxState.nodes.data,
			prevBlock: reduxState.blocks.data[blockId as string] as IBlock
		},
		next: () => true
	};
	// dispatch add nodes
	(store.dispatch as CustomDispatch)(addNodes(payload));
};

export default pasteNode;
