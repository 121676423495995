import { AppDispatch } from 'src';
import {
	EUserRole,
	IBlock,
	IProject,
	IProjectDetail,
	IProjectGroup,
	IGroup,
	IUser,
	IUserPreferenceNode,
	TReferenceOrObject,
	TUserAndRole,
	CustomProject,
	Collaborator
} from '@naya_studio/types';
import getUserFromRedux from 'src/util/helper/user';
import { updateUser } from 'src/redux/features/user';
import { isThumbnailColorString } from '@naya_studio/radix-ui';
import useLoader from 'src/components/collaborationTool/hooks/useLoader';

/**
 * Function to return the first character from users details
 * @param details User Details
 * @param userName Username of user
 * @returns first character which will be shown if no profile pic
 */
const getDisplayName = (user: IUser) => {
	let name = user.firstName || user.userName || '';
	if (user.lastName) {
		name = `${name} ${user.lastName}`;
	}

	return { displayName: name?.substring(0, 1).toUpperCase(), fullName: name };
};

/**
 * Returns  the default project Group
 * finds the project group
 * returns default project group
 */
export const getDefaultGroup = () => {
	const user: IUser = getUserFromRedux();
	const { defaultProjectGroup: defaultProjectGroupId, projectGroups } = user;

	// Get the projects in the default project group
	const defaultProjectGroup = projectGroups?.find(
		(group) => (group as IProjectGroup)._id === defaultProjectGroupId
	);

	return defaultProjectGroup;
};

export function formatProjectData(
	projects: IProject[],
	projectProgress?: { [key: string]: number }
) {
	const user: IUser = getUserFromRedux();
	const userPreference = user.userPreferences as IUserPreferenceNode;
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { convertUrlToCloudinaryUrl } = useLoader();

	if (!projects || projects.length === 0) return [];
	const allDefaultProjects = (projects as IProject[])
		.filter((project: IProject) => {
			const { statuses } = project;

			if (!statuses) return false;

			const latestStatus = statuses[statuses.length - 1];

			if (latestStatus?.status && ['ARCHIVED', 'DELETED'].includes(latestStatus.status))
				return false;

			// only return those which aren't ARCHIVED or DELETED
			return true;
		})
		.map((project: IProject) => {
			let yourRole: TReferenceOrObject<TUserAndRole>;

			if (project.users) {
				yourRole = project.users.find(
					(userOne: TReferenceOrObject<TUserAndRole>) =>
						((userOne as TUserAndRole).user as IUser)._id === user._id
				);
			}
			let collaborators: Collaborator[] = [];
			if (project.users) {
				collaborators = project.users
					.filter(
						(userOne: TReferenceOrObject<TUserAndRole>) =>
							(userOne as TUserAndRole).status !== 'PENDING'
					)
					.map((userOne: TReferenceOrObject<TUserAndRole>) => {
						const profilePic =
							(((userOne as TUserAndRole).user as IUser)?.profilePic as string) || '';
						const color =
							((
								((userOne as TUserAndRole).user as IUser)
									?.userPreferences as IUserPreferenceNode
							)?.color as string) || '#4F00C1';
						const id = ((userOne as TUserAndRole).user as IUser)._id;
						const email = ((userOne as TUserAndRole).user as IUser)?.email;

						return {
							id,
							profilePic,
							color,
							displayName: getDisplayName((userOne as TUserAndRole).user as IUser)
								.displayName,
							fullName: getDisplayName((userOne as TUserAndRole).user as IUser)
								.fullName,
							email
						};
					});
			}

			// Optimize the thumbnail using cloudinary
			const optimizedThumbnail =
				project.thumbnail?.src && !isThumbnailColorString(project.thumbnail?.src)
					? convertUrlToCloudinaryUrl(project.thumbnail.src, 'f_jpg/q_auto:eco').replace(
							/ /g,
							'%20'
					  )
					: undefined;

			return {
				_id: project._id as string,
				projectName: (project?.projectDetails as IProjectDetail).name || '',
				lastUpdatedAt: project.updatedAt!,
				createdBy: project.createdBy as IUser,
				thumbnail: {
					src: project.thumbnail?.src || '#FFFFFF',
					originalSrc: project.thumbnail?.originalSrc || '#FFFFFF',
					isCustom: Boolean(project?.thumbnail?.isCustom),
					optimizedSrc: optimizedThumbnail
				},
				yourRole: (yourRole as TUserAndRole)?.role || ('VIEWER' as keyof typeof EUserRole),
				isPinned: Boolean(
					userPreference?.pinnedProjects?.some(
						(p) => (p as IProject)?._id === (project._id as string)
					)
				),
				isSubscribed: !userPreference?.noNotificationsFor?.includes(project._id as string),
				link: `/project/${project._id}`,
				accessLevel: 'PROJECT' as 'PROJECT' | 'BLOCK',
				collaborators: collaborators || [],
				noOfPhases: 0,
				noOfBlocks: 0,
				size: (project?.size || 0) as number,
				isTemplate: project.isTemplate || false,
				isNayaProject: false,
				isGroup: false,
				projectProgress: projectProgress?.[project._id as string] ?? 0,
				isAIGenerated: project.isAIGenerated
			} as CustomProject;
		});

	return allDefaultProjects;
}

/**
 * Function to extract the @type CustomProject data from all projects user has access to
 * @param projectProgress All projects with the progress percentage
 * @returns Aggregated Data
 */
export function aggregateProjectData(projectProgress?: { [key: string]: number }) {
	let data = [];
	const user: IUser = getUserFromRedux();
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { convertUrlToCloudinaryUrl } = useLoader();
	let groupCount = 0;

	const userPreference = user.userPreferences as IUserPreferenceNode;

	const defaultProjects = () => {
		const { defaultProjectGroup: defaultProjectGroupId, projectGroups } = user;

		if (!projectGroups) return [];

		// Get the projects in the default project group
		const defaultProjectGroup = (getDefaultGroup() as IProjectGroup)?.projects;

		const getAllProjectGroupsAndProjects = (projectGroups as IProjectGroup[])
			.map((projectGroup: IProjectGroup) => {
				if (!projectGroup?._id) return null;
				const names: string[] = [];
				const thumbnails: string[] = [];

				let yourRole: TReferenceOrObject<TUserAndRole>; // Assuming this is set later in the code
				(projectGroup?.projects as IProject[]).forEach((project) => {
					const { statuses } = project;
					if (statuses) {
						const latestStatus = statuses[statuses.length - 1];

						if (
							latestStatus?.status &&
							!['ARCHIVED', 'DELETED'].includes(latestStatus.status) &&
							names.length < 4
						) {
							if (project) {
								const projectName =
									(project?.projectDetails as IProjectDetail)?.name || 'Untitled';
								const projectThumbnail = project?.thumbnail.src || '#FFFFFF';

								// Optimize the project group's thumbnails using cloudinary
								const optimizedThumbnail = !isThumbnailColorString(projectThumbnail)
									? convertUrlToCloudinaryUrl(projectThumbnail).replace(
											/ /g,
											'%20'
									  )
									: projectThumbnail;

								names.push(projectName);
								thumbnails.push(optimizedThumbnail);
							}
						}
					}
				});

				if (projectGroup._id !== defaultProjectGroupId && names.length) {
					const groupName = projectGroup?.name || '';
					const lastUpdatedAt = projectGroup.updatedAt!;
					const yourRoleValue =
						(yourRole as TUserAndRole)?.role || ('VIEWER' as keyof typeof EUserRole);

					groupCount++;
					return {
						_id: projectGroup._id as string,
						projectName: groupName as string,
						lastUpdatedAt,
						thumbnail: {
							src: '#FFFFFF',
							originalSrc: '#FFFFFF',
							isCustom: false
						},
						groupThumbnailUrl: {
							names,
							thumbnails
						},
						yourRole: yourRoleValue,
						isPinned: Boolean(
							userPreference?.pinnedProjects?.some(
								(p) => (p as IProject)?._id === (projectGroup._id as string)
							)
						),
						isSubscribed: false,
						link: `/project/${projectGroup._id}`,
						accessLevel: 'PROJECT' as 'PROJECT' | 'BLOCK',
						collaborators: [],
						noOfPhases: 0,
						noOfBlocks: 0,
						size: 0,
						isTemplate: false,
						isNayaProject: false,
						projectProgress: 0,
						isGroup: true,
						isAIGenerated: false
					} as CustomProject;
				}

				return null; // Returning null as default value
			})
			.filter((project) => project !== null); // Filter out null values

		if (!defaultProjectGroup) return [];

		const allDefaultProjects = formatProjectData(
			defaultProjectGroup as IProject[],
			projectProgress
		);

		const mergedProjects: CustomProject[] = [
			...(allDefaultProjects.filter((project) => project !== null) as CustomProject[]),
			...(getAllProjectGroupsAndProjects?.filter(
				(project) => project !== null
			) as CustomProject[])
		];

		return mergedProjects;
	};

	const sharedProjects = () => {
		if (!user.sharedProjects) return [];

		const allSharedProjects = (user.sharedProjects as IProject[])
			.filter((project: IProject) => {
				const { statuses } = project;

				if (!statuses) return false;

				const latestStatus = statuses[statuses.length - 1];

				if (latestStatus?.status && ['ARCHIVED', 'DELETED'].includes(latestStatus.status))
					return false;

				// only return those which aren't ARCHIVED or DELETED
				return true;
			})
			.map((project: IProject) => {
				let accessLevel;
				let yourRole: TReferenceOrObject<TUserAndRole>;

				if (project.users) {
					yourRole = project.users.find(
						(userOne: TReferenceOrObject<TUserAndRole>) =>
							((userOne as TUserAndRole).user as IUser)._id === user._id
					);
				}
				if (yourRole !== undefined) {
					accessLevel = 'PROJECT';
				}
				let link;
				if (
					(yourRole === undefined ||
						(yourRole as TUserAndRole)?.role === 'COMMENTER' ||
						(yourRole as TUserAndRole)?.role === 'VIEWER') &&
					project &&
					project.children
				) {
					for (let i = 0; i < (project.children as IGroup[]).length!; i++) {
						// const stage = stages.data[project?.stages[i] as string]
						const blocks = (project.children[i] as IGroup).children as IBlock[];

						for (let j = 0; j < blocks?.length!; j++) {
							const block = (blocks as IBlock[])[j] as IBlock;

							if (block.users) {
								block.users.forEach(
									// Since we are checking yourRole and updating it as well
									// eslint-disable-next-line @typescript-eslint/no-loop-func
									(blockUser: TReferenceOrObject<TUserAndRole>) => {
										if ((blockUser as TUserAndRole)!.user === user._id) {
											if (
												yourRole === undefined ||
												(yourRole as TUserAndRole)?.role === 'COMMENTER' ||
												(yourRole as TUserAndRole)?.role === 'VIEWER'
											) {
												yourRole = blockUser as TUserAndRole;
												link = `/project/${project._id}`;
												accessLevel = 'BLOCK';
											}
										}
									}
								);
							}
						}
					}
				}

				let collaborators: Collaborator[] = [];
				if (project.users) {
					collaborators = project.users.map(
						(userOne: TReferenceOrObject<TUserAndRole>) => {
							const profilePic = ((userOne as TUserAndRole).user as IUser)
								.profilePic as string;
							const color =
								((
									((userOne as TUserAndRole).user as IUser)
										.userPreferences as IUserPreferenceNode
								)?.color as string) || '#4F00C1';
							const id = ((userOne as TUserAndRole).user as IUser)._id as string;

							const email = ((userOne as TUserAndRole).user as IUser)?.email;

							return {
								id,
								profilePic,
								color,
								displayName: getDisplayName((userOne as TUserAndRole).user as IUser)
									.displayName,
								fullName: getDisplayName((userOne as TUserAndRole).user as IUser)
									.fullName,
								email
							};
						}
					);
				}

				// Optimize the thumbnail using cloudinary
				const optimizedThumbnail =
					project.thumbnail?.src && !isThumbnailColorString(project.thumbnail?.src)
						? convertUrlToCloudinaryUrl(
								project.thumbnail.src,
								'f_jpg/q_auto:eco'
						  ).replace(/ /g, '%20')
						: undefined;
				// NOTE: This is a temporary fix for the issue where user is unable to access the context menu of a project
				// if only a block is shared with them.
				// This issue happened because the "ref" was from the group children schema.
				if (!yourRole) {
					yourRole = {
						role: 'VIEWER',
						user: user._id as string
					};
				}

				return {
					_id: project._id as string,
					projectName: (project?.projectDetails as IProjectDetail).name || '',
					lastUpdatedAt: project.updatedAt!,
					createdBy: project.createdBy as IUser,
					thumbnail: {
						src: project.thumbnail?.src || '#FFFFFF',
						originalSrc: project.thumbnail?.originalSrc || '#FFFFFF',
						isCustom: Boolean(project.thumbnail?.isCustom),
						optimizedSrc: optimizedThumbnail
					},
					yourRole: (yourRole as TUserAndRole)?.role as keyof typeof EUserRole,
					isPinned: Boolean(
						userPreference?.pinnedProjects?.some(
							(p) => (p as IProject)?._id === (project._id as string)
						)
					),
					isSubscribed: !userPreference?.noNotificationsFor?.includes(
						project._id as string
					),
					link: link || `/project/${project._id}`,
					accessLevel: (accessLevel as 'PROJECT' | 'BLOCK') || 'BLOCK',
					collaborators: collaborators || [],
					noOfPhases: 0,
					noOfBlocks: 0,
					size: (project?.size || 0) as number,
					isTemplate: project.isTemplate || false,
					isNayaProject: false,
					isGroup: false,
					projectProgress: projectProgress?.[project._id as string] ?? 0,
					isAIGenerated: project.isAIGenerated
				} as CustomProject;
			});

		return allSharedProjects;
	};

	const outputDefaultProjects = defaultProjects();
	const outputSharedProjects = sharedProjects();
	data = outputDefaultProjects.concat(outputSharedProjects);

	return { data, groupCount };
}

/**
 * Function to compare pin status of two Custom Projects
 */
export function compareIsPinned(projectA: CustomProject, projectB: CustomProject) {
	if (projectA.isPinned < projectB.isPinned) {
		return 1;
	}
	if (projectA.isPinned > projectB.isPinned) {
		return -1;
	}
	return 0;
}

/**
 * Function to compare last updated status of two projects
 */
export function compareLastUpdated(projectA: CustomProject, projectB: CustomProject) {
	if (projectA.lastUpdatedAt < projectB.lastUpdatedAt) {
		return 1;
	}
	if (projectA.lastUpdatedAt > projectB.lastUpdatedAt) {
		return -1;
	}
	return 0;
}

/**
 * Finds project in the users defaultProjects or shared projects or if it's a group project,
 * finds the project group
 * returns the user specific project
 * @param projectId string
 */
export const findProject = (
	projectIdToFind: string,
	isGroupDetail: boolean,
	currentParentGroup: IProjectGroup,
	sharedProjects: IProject[] | undefined
) => {
	let projects: IProject[] = [];
	// check if the user is on project detail page, if they are, then the project is not a default ptoject
	if (isGroupDetail && currentParentGroup) {
		projects = (currentParentGroup.projects || []) as IProject[];
	} else {
		projects = (getDefaultGroup() as IProjectGroup)?.projects as IProject[];
	}

	// Find the project within the determined projects array
	let projectFound = projects.find((p) => p._id === projectIdToFind);

	// if we don't find the project in default group and we are not on details page
	// we search for it within the sharedProjects
	if (!projectFound && !isGroupDetail) {
		projectFound = sharedProjects?.find((p) => p._id === projectIdToFind);
	}

	return { projectFound, projects };
};

/**
 * Finds a project group and removes the projects from the group,
 * @param groupProjectId string
 * @param projectId string
 * @param dispatch AppDispatch
 */
export const removeGroupThumbnail = (
	groupProjectId: string,
	projectId: string,
	dispatch: AppDispatch
) => {
	const user: IUser = getUserFromRedux();
	const groupInfo = user?.projectGroups?.find(
		(pG) => (pG as IProjectGroup)._id === groupProjectId
	) as IProjectGroup;

	// Filter out the project with the specified projectId
	const updatedProjects = ((groupInfo as IProjectGroup)?.projects as IProject[]).filter(
		(project) => project._id !== projectId
	);

	// Update the groupInfo with the filtered projects
	const updatedGroupInfo = { ...groupInfo, projects: updatedProjects };

	// Update the user state with the updated groupInfo
	dispatch(
		updateUser({
			projectGroups:
				user?.projectGroups?.map((pG) =>
					(pG as IProjectGroup)._id === groupProjectId ? updatedGroupInfo : pG
				) || []
		})
	);
};
