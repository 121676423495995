import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ReduxState } from 'src/redux/reducers/root.types';
import './Stroke.scss';
import { colors, strokes } from 'src/components/canvas/toolbar/toolbar.config';
import { SketchPicker } from 'react-color';
import { INode, ENodeType } from '@naya_studio/types';
import localforage from 'localforage';
import { StrokeOptionDispatchProps, StrokeOptionProps, PathParamsType } from './Stroke.types';
// import noFillIcon from '../../../../../assets/icons/toolbar/no_fill.svg';
import eyeDropIcon from '../../../../../assets/icons/toolbar/eyedrop.svg';
import addIcon from '../../../../../assets/icons/toolbar/add_icon.svg';
import Opacity from '../Opacity/Opacity';

class StrokeOption extends React.Component<StrokeOptionDispatchProps & StrokeOptionProps & RouteComponentProps<PathParamsType>> {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    activeStrokeWidth: 0,
    // selectedOption: '',
    activeStrokeColor: '',
    multiColorSelect: [],
    colorOptions: colors,
    colorPicked: '#FF0000',
    showColorPicker: false,
    eyeDropperActive: false,
    showEyedropper: true,
    hexValue: '',
    projectId: window.location.href.split('/')[4],
  };

  componentDidMount() {
    if (!(window as any).EyeDropper) {
      this.setState({ showEyedropper: false });
    }
    // ----
    this.extractDefaulrStrokeWidthData();
    this.extractDefaultStrokeColorData();
  }

  componentDidUpdate(
    prevProps: StrokeOptionProps,
  ) {
    const { nodes } = this.props;
    if (prevProps.nodes !== nodes) {
      this.extractDefaulrStrokeWidthData();
      this.extractDefaultStrokeColorData();
    }
  }

  async componentWillUnmount() {
    const { projectId, colorOptions } = this.state;
    if (projectId) {
      const localColors = JSON.parse(await localforage.getItem(`${projectId}-stroke`) as string);
      const localColorsLength = localColors ? localColors.length : 0;
      if (localColorsLength < colorOptions.length) {
        localforage.setItem(`${projectId}-stroke`, JSON.stringify(colorOptions));
      }
    }
  }

  extractDefaultStrokeColorData = async () => {
    const { app } = this.props;
    const { colorOptions, activeStrokeColor } = this.state;
    const { nodes: allNodes } = this.props;
    if (allNodes && allNodes.length > 1) {
      let fillColors = [];
      for (let i = 0; i < 2; i++) {
        const nodeData = allNodes[i]?.nodeData;
        fillColors.push(nodeData?.stroke?.strokeColor);
      }
      if (fillColors.includes('NO_FILL')) {
        fillColors = fillColors.filter((x) => x !== 'NO_FILL');
        this.setState({ activeStrokeColor: fillColors[2] });
      } else if (fillColors.every((val, _i, arr) => val === arr[0])) {
        this.setState({ activeStrokeColor: fillColors[0] });
      } else {
        this.setState({ multiColorSelect: fillColors });
      }
    } else {
      const nodeData = app.selectedNodes[0]?.nodeData as INode;
      let localColorsOptions;
      if (nodeData.stroke?.strokeColor) {
        const { projectId } = this.state;
        if (projectId) localColorsOptions = JSON.parse(await localforage.getItem(`${projectId}-stroke`) as string);
        const temp : Array<string> = localColorsOptions && localColorsOptions.length > 0 ? localColorsOptions : colorOptions;
        if (activeStrokeColor !== 'NO_FILL') {
          if (!temp.includes(nodeData.stroke.strokeColor)) {
            temp.push(nodeData.stroke.strokeColor);
          }
        }
        this.setState({
          activeStrokeColor: nodeData.stroke.strokeColor,
          colorOptions: temp,
        });
      }
    }
  };

  extractDefaulrStrokeWidthData = () => {
    const { app } = this.props;
    const allNodes = app.selectedNodes;
    const allStrokes = [];
    for (let i = 0; i < allNodes.length; i++) {
      const nodeData = allNodes[i]?.nodeData;
      const stroke = nodeData?.stroke;
      if (stroke) {
        allStrokes.push(stroke.strokeWidth);
      }
    }
    if (allStrokes.every((val, _i, arr) => val === arr[0])) {
      this.setState({
        activeStrokeWidth: allStrokes[0],
      });
    }
  };

  /**
   * Toggle color picker
   */
  toggleColorPicker = () => {
    const { app } = this.props;
    const { showColorPicker, colorOptions, colorPicked } = this.state;
    this.setState({ colorPicked: '#FF0000', hexValue: '#FF0000' });
    this.removeDuplicateColors();
    if (!showColorPicker) {
      app.isColourPickerOpen = true;
      const tempColors: string[] = colorOptions;
      let c : number = 0;
      for (let i = 0; i < tempColors.length; i++) {
        if (tempColors[i] === '#FF0000') {
          c++;
        }
      }
      if (c <= 1) {
        tempColors.push('#FF0000');
        this.setState({ colorOptions: tempColors });
      }
      this.setState({ showColorPicker: !showColorPicker, colorOptions: tempColors, activeStrokeColor: colorPicked });

      const allNodes = app.selectedNodes;
      // looping over all the selected nodes to edit the nodeData in BaseNode
      for (let i = 0; i < allNodes.length; i++) {
        app.selectedNodes[i]?.edit('STROKE_COLOR', colorPicked, !!(allNodes.length > 1 && i !== 0));
      }
      // saving all the edited nodes data to db
      app.saveAllNodes([])
      
    } else {
      app.isColourPickerOpen = false;
      this.removeDuplicateColors();
      this.setState({ showColorPicker: !showColorPicker, colorPicked: '#FF0000' });
    }
  };

  /**
   * Remove duplicate color values
   */
  removeDuplicateColors = () => {
    const { colorOptions } = this.state;
    const arr:string[] = colorOptions.filter((item, index, inputArray) => inputArray.indexOf(item) === index);
    this.setState({ colorOptions: arr });
  };

  RGBToHex = (r: any, g: any, b: any) => {
    let red = parseInt(r, 10).toString(16);
    let green = parseInt(g, 10).toString(16);
    let blue = parseInt(b, 10).toString(16);

    if (red.length === 1) red = `0${red}`;
    if (green.length === 1) green = `0${green}`;
    if (blue.length === 1) blue = `0${blue}`;
    console.log(this.RGBToHex);
    return `#${red}${green}${blue}`;
  };

  /**
   * handles color change - color picker
   * @param color
   */
  handleChange = (color:any) => {
    const { app } = this.props;
    const { colorOptions } = this.state;
    const tempColors:string[] = colorOptions;
    if (!tempColors.includes(color.hex)) {
      tempColors[tempColors.length - 1] = color.hex;
      this.setState({ colorOptions: tempColors });
    }
    this.setState({ colorPicked: color.hex, activeStrokeColor: color.hex, hexValue: color.hex });

    const allNodes = app.selectedNodes;
    // looping over all the selected nodes to edit the nodeData in BaseNode
    for (let i = 0; i < allNodes.length; i++) {
      app.selectedNodes[i]?.edit('STROKE_COLOR', color.hex, !!(allNodes.length > 1 && i !== 0));
    }
    // saving all the edited nodes data to db
    app.saveAllNodes([])
  };

  useEyeDropper = () => {
    const { app } = this.props;
    const { colorOptions } = this.state;
    const arr: string[] = colorOptions.filter((item, index, inputArray) => inputArray.indexOf(item) === index);
    this.setState({ eyeDropperActive: true });
    const eyeDropper = new (window as any).EyeDropper();
    let hexDecimalColor : string;
    eyeDropper.open().then((result:any) => {
      if (result.sRGBHex[0] !== '#') {
        const RGBValues = result.sRGBHex.slice(4, result.sRGBHex.length - 1).split(',');
        hexDecimalColor = this.RGBToHex(RGBValues[0], RGBValues[1], RGBValues[2]).toUpperCase();
      } else hexDecimalColor = result.sRGBHex;
      const tempColors:string[] = arr;
      if (!tempColors.includes(hexDecimalColor)) {
        tempColors.push(hexDecimalColor);
      }
      this.setState({
        activeStrokeColor: hexDecimalColor, eyeDropperActive: false, hexValue: hexDecimalColor, colorOptions: tempColors,
      });

      const allNodes = app.selectedNodes;
      // looping over all the selected nodes to edit the nodeData in BaseNode
      for (let i = 0; i < allNodes.length; i++) {
        app.selectedNodes[i]?.edit('STROKE_COLOR', hexDecimalColor, !!(allNodes.length > 1 && i !== 0));
      }
      // saving all the edited nodes data to db
      app.saveAllNodes([])
    }).catch((e:any) => {
      console.log(e);
    });
  };

  handleHexInput = (e : any) => {
    const { app } = this.props;
    const { colorOptions } = this.state;
    this.setState({ hexValue: e.target.value });
    if (e.target.value.length <= 6 && e.target.value.length >= 3) {
      const colorArray : string[] = colorOptions;
      if (!colorArray.includes(`#${e.target.value}`)) {
        colorArray[colorArray.length - 1] = `#${e.target.value}`;
        this.setState({ colorOptions: colorArray });
      }
      this.setState({ colorPicked: `#${e.target.value}`, activeStrokeColor: `#${e.target.value}` });

      const allNodes = app.selectedNodes;
      // looping over all the selected nodes to edit the nodeData in BaseNode
      for (let i = 0; i < allNodes.length; i++) {
        app.selectedNodes[i]?.edit('STROKE_COLOR', `#${e.target.value}`, !!(allNodes.length > 1 && i !== 0));
      }
      // saving all the edited nodes data to db
      app.saveAllNodes([])
    }
  };

  getActiveStrokeClassName = (color : string, index: number) => {
    const { activeStrokeColor, colorOptions } = this.state;
    const newArr = colorOptions.filter((colour) => colour === color);
    if (activeStrokeColor === '#FF0000' && color === '#FF0000') {
      if (newArr.length > 1) {
        if (index === (colorOptions.length - 1)) return 'color-container active-color';
      } else if (newArr.length === 1 && color === '#FF0000') {
        return 'color-container active-color';
      }
    }
    if (activeStrokeColor !== '#FF0000' && color === activeStrokeColor) {
      return 'color-container active-color';
    }
    return 'color-container';
  };

  // checkIfSelectedNodesIncludeMarkup = () => {
  //   const { app } = this.props;
  //   const allNodes = app.selectedNodes;
  //   for (let i = 0; i < allNodes.length; i++) {
  //     const currentNode = allNodes[i];
  //     if (currentNode?.nodeData.nodeType === 'MARKUP') {
  //       return true;
  //     }
  //   }
  //   return false;
  // };

  /**
   *
   * @returns color options
   */
  renderColorOptions = () => {
    const { app } = this.props;
    const {
      showColorPicker, colorOptions, colorPicked, activeStrokeColor, showEyedropper, eyeDropperActive,
      hexValue,
    } = this.state;

    return (
      <>
        {colorOptions.map((color: string, index: number) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={color + index}
            className={this.getActiveStrokeClassName(color, index)}
            role="presentation"
            onClick={() => {
              const allNodes = app.selectedNodes;
              this.removeDuplicateColors();
              // looping over all the selected nodes to edit the nodeData in BaseNode
              for (let i = 0; i < allNodes.length; i++) {
                if (activeStrokeColor === 'NO_STROKE') {
                  this.setState({ activeStrokeWidth: 2 });
                  allNodes[i]?.edit('STROKE_WIDTH', strokes[0], !!(allNodes.length > 1 && i !== 0));
                }
                this.setState({
                  activeStrokeColor: color, multiColorSelect: [], hexValue: color, colorPicked: color,
                });
                allNodes[i]?.edit('STROKE_COLOR', color, !!(allNodes.length > 1 && i !== 0));
              }
              // saving all the edited nodes data to db
              app.saveAllNodes([])
            }}
          >
            <div style={{ backgroundColor: color }} className="color" />
          </div>
        ))}
        {
          showEyedropper && (
            <div
              className={eyeDropperActive ? 'color-container active-eyedrop' : 'color-container'}
              role="presentation"
              onClick={this.useEyeDropper}
            >
              <img className="color" src={eyeDropIcon} alt="eye-drop-icon" style={{ borderRadius: 0 }} />
            </div>
          )
        }
        <div
          id="color-picker"
          className="color-container"
          role="presentation"
          onClick={this.toggleColorPicker}
          style={{ background: showColorPicker ? '#EDEDED' : '', borderRadius: '4px' }}
        >
          <img className="color" src={addIcon} alt="add-icon" />
        </div>
        {
          showColorPicker && (
            <div className="chrome-picker-wrapper">
              <SketchPicker
                color={colorPicked}
                onChangeComplete={this.handleChange}
              />
              <div className="hex-input-container">
                <div className={`eye-drop-container ${eyeDropperActive ? 'active' : ''}`}>
                  <img
                    src={eyeDropIcon}
                    alt="eye-drop-icon"
                    onClick={this.useEyeDropper}
                    style={{ width: '15px', height: '15px' }}
                    role="presentation"
                  />
                </div>
                <div className="input-container">
                  <span>
                    #
                  </span>
                  <input
                    type="text"
                    value={`${hexValue[0] === '#' ? hexValue.slice(1) : hexValue}`}
                    onChange={this.handleHexInput}
                    placeholder="Enter hex code"
                  />
                </div>
              </div>
            </div>
          )
        }
      </>
    );
  };

  getStrokeClassName = (width : number) => {
    const { activeStrokeWidth } = this.state;
    if (this.getStrokeColor() === 'NO_STROKE') return 'icon-container disabled';
    if (activeStrokeWidth === width) return 'icon-container active';
    return 'icon-container';
  };

  /**
   *
   * @returns stroke options
   */
  renderStrokeOptions = () => {
    const { app } = this.props;
    return (
      <>
        {strokes.map(
          (stroke: { icon: any; strokeWidth: number }) => (
            <div
              role="presentation"
              key={stroke.icon}
              className={this.getStrokeClassName(stroke.strokeWidth)}
              onClick={() => {
                if (this.getStrokeColor() !== 'NO_STROKE') {
                  this.setState({ activeStrokeWidth: stroke.strokeWidth });
                  const allNodes = app.selectedNodes;
                  // looping over all the selected nodes to edit the nodeData in BaseNode
                  for (let i = 0; i < allNodes.length; i++) {
                    allNodes[i]?.edit('STROKE_WIDTH', stroke, !!(allNodes.length > 1 && i !== 0));
                  }
                  // saving all the edited nodes data to db
                  app.saveAllNodes([])
                }
              }}
            >
              <img
                src={stroke.icon}
                alt={stroke.strokeWidth.toString()}
                className="icon"
              />
            </div>
          ),
        )}
      </>
    );
  };

  /**
   *
   * @returns strke width
   */
  getStrokeWidth = () => {
    const { activeStrokeWidth } = this.state;
    return activeStrokeWidth !== 0 ? `${activeStrokeWidth}px` : '2px';
  };

  /**
   *
   * @returns stroke color
   */
  getStrokeColor = () => {
    const { activeStrokeColor } = this.state;
    return activeStrokeColor || '#FF0000';
  };

  getStyle = () => {
    const style:any = {};
    const { multiColorSelect } = this.state;
    if (multiColorSelect.length > 1) {
      style['border-color'] = `${multiColorSelect[0]} ${multiColorSelect[0]} ${multiColorSelect[1]} ${multiColorSelect[1]}`;
    } else {
      style['border-color'] = this.getStrokeColor();
    }
    style['border-width'] = this.getStrokeWidth();
    return style;
  };

  /**
   * Function to check if the Node has Editable Stroke or Not
   * @returns Boolean
   */
  checkIfNodeHaveStrokeOrNot = () => {
    const { app } = this.props;
    const node = app.selectedNodes[0]?.nodeData;

    if (node?.nodeType === ENodeType.SHAPE) {
      const shape = node.shapeType;
      if (shape === 'SOLID_LINE' || shape === 'DASHED_LINE' || shape === 'ARROW') return false;
    }
    return true;
  };

  /**
   * Function to check if node opacity is allowed or not
   * @returns boolean
   */
  checkIfNodeHasOpacity = () => {
    const { app } = this.props;
    const nodeType = app.selectedNodes[0]?.nodeData.nodeType;

    return nodeType === ENodeType.SHAPE
    || nodeType === ENodeType.IMAGE
    || nodeType === ENodeType.SAMPLE_IMAGE
    || nodeType === ENodeType.ONBOARDING_IMAGE
    || nodeType === ENodeType.MARKUP
    || nodeType === ENodeType.TEXT;
  };

  render() {
    const { app, setSelectedOption, selectedOption } = this.props;
    const { showColorPicker } = this.state;
    return (
      <>
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 1000, hide: 0 }}
          overlay={(
            <Tooltip
              id="button-tooltip-2"
              style={{
                fontSize: '12px',
                borderRadius: '2px',
                lineHeight: '16px',
                marginTop: '4px',
              }}
            >
              Stroke
            </Tooltip>
        )}
        >
          <div
            className="icon-container"
            style={{ background: selectedOption === 'STROKES' ? '#EDEDED' : 'transparent' }}
            role="presentation"
            onClick={(e: any) => {
              e.stopPropagation();
              this.removeDuplicateColors();
              if (selectedOption === 'STROKES') {
                setSelectedOption('');
                this.setState({ showColorPicker: false, colorPicked: '#FF0000', hexValue: '#FF0000' });
              } else {
                setSelectedOption('STROKES');
              }
              if (showColorPicker) {
                this.toggleColorPicker();
              }
            }}
          >
            {
          // this.getStrokeColor() === 'NO_STROKE' ?
          // <img className="no_fill_icon" src={noFillIcon} alt="no-fill" />
          //  : (
              <div className="stroke-option-outer-circle" style={this.getStyle()}>
                {/* <div className='inner-circle'
              style={{width:24-parseInt(this.getStrokeWidth()), height:24-parseInt(this.getStrokeWidth())}}>
              </div> */}
              </div>
          // )
        }
          </div>
        </OverlayTrigger>
        {
        selectedOption === 'STROKES' && (
          <div className="sub-option-container" style={{ minWidth: '152px' }}>
            {this.renderStrokeOptions()}
            <hr
              style={{
                width: '100%',
                marginTop: '0.2rem',
                marginBottom: '0.5rem',
                backgroundColor: '#DCDCDC',
              }}
            />
            {
              this.checkIfNodeHasOpacity()
              && (
              <>
                <Opacity
                  app={app}
                  setSelectedOption={setSelectedOption}
                  selectedOption={selectedOption}
                  isStroke={this.checkIfNodeHaveStrokeOrNot()}
                  isFill={false}
                />
                <hr
                  style={{
                    width: '100%',
                    marginTop: '0.2rem',
                    marginBottom: '0.5rem',
                    backgroundColor: '#DCDCDC',
                  }}
                />
              </>
              )
            }
            {this.renderColorOptions()}
          </div>
        )
      }
      </>
    );
  }
}

/**
 * Redux state mapped to canvas props
 * @param state
 * @returns
 */
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mapStateToProps = (state: ReduxState) => ({
});

/**
 * Redux actions mapped to canvas props
 * @param dispatch
 * @returns
 */
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StrokeOption));
