import axios from 'axios';
import { loginEndpoint, sessionEndpoint } from 'src/endpoints/authentication-api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { TLoginPWDUserArgs, TLoginUserArgs, TRegisterUserArgs } from 'src/types/argTypes';
import { IUser } from '@naya_studio/types';
import { removeUserFromLocalForage } from 'src/util/storage/indexedDBStorage';
import { resetUser, setUser } from '../features/user';
import { AxiosResponseWrapper } from './types';
import { getGatewayKey } from '../../util/helper/queryString';

/**
 * api key for api-gateway
 * all request urls to backend needs to be suffixed by qs
 */
const qs = `?${getGatewayKey()}`;

export const register = createAsyncThunk(
	'user/register',
	async (payload: TRegisterUserArgs, thunkApi) => {
		try {
			const { data, isGuestUser, next } = payload;
			const res = await axios.post<AxiosResponseWrapper<IUser>>(
				`${sessionEndpoint}/register${qs}`,
				data,
				{
					withCredentials: true
				}
			);
			if (res.status !== 200) thunkApi.rejectWithValue('Server Error');
			if (isGuestUser) thunkApi.dispatch(setUser(res.data.user));
			if (next) {
				next(res.data.user as IUser);
			}
		} catch (error: any) {
			console.error(error);
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue(error.message);
		}
		return null;
	}
);

export const login = createAsyncThunk('user/login', async (payload: TLoginUserArgs, thunkApi) => {
	try {
		const { data, next } = payload;
		const res = await axios.post<AxiosResponseWrapper<IUser>>(`${loginEndpoint}${qs}`, data, {
			withCredentials: true
		});

		if (res.data.user) {
			next(res.data.user);
		} else throw new Error('Server Error');
	} catch (error: any) {
		console.error(error);
		if (error instanceof TypeError) {
			return thunkApi.rejectWithValue(error.message);
		}
		// Handle other types of errors
		return thunkApi.rejectWithValue(error.message);
	}
	return null;
});

export const loginPWD = createAsyncThunk(
	'user/login',
	async (payload: TLoginPWDUserArgs, thunkApi) => {
		try {
			const {
				data: { email, password },
				next
			} = payload;
			const res = await axios.get<AxiosResponseWrapper<IUser>>(
				`${loginEndpoint}${qs}&code=&email=${email}&password=${password}`,
				{
					withCredentials: true
				}
			);
			if (res.status === 200) {
				next();
			} else throw new Error('Server Error');
		} catch (error: any) {
			console.error(error);
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue(error.message);
		}
		return null;
	}
);

export const logout = createAsyncThunk(
	'user/logout',
	async (payload: { email: string; next: () => void }, thunkApi) => {
		try {
			const { email, next } = payload;
			await axios.get(`${sessionEndpoint}/signout${qs}`, { withCredentials: true });
			await thunkApi.dispatch(resetUser());
			await removeUserFromLocalForage(email as string);
			window.localStorage.removeItem('showUserForm');
			window.localStorage.removeItem('redirect-from-userform');
			window.localStorage.removeItem('registeredUnappovedUserId');
			next();
			return 'logged out';
		} catch (error: any) {
			console.info(error);
			if (error instanceof TypeError) {
				return thunkApi.rejectWithValue(error.message);
			}
			// Handle other types of errors
			return thunkApi.rejectWithValue(error.message);
		}
	}
);
