import { FC } from 'react';
import { AuthComponent } from '../authComponent/AuthComponent';
import { ScheduleDemoComponentProps } from './ScheduleDemo.types';

const ScheduleDemoComponent:FC<ScheduleDemoComponentProps> = () => (
  <div>
    <AuthComponent
      show
      close={() => {}}
      view="SCHEDULE_DEMO"
      redirectTo={window.location.href}
    />
  </div>
);

export default ScheduleDemoComponent;
