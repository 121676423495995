import {
	EEvent,
	I3D,
	IBlock,
	ICanvas,
	IFeedback,
	IFeedbackBlock,
	IFile,
	IImage,
	ILink,
	INode,
	INodesBlock,
	INote,
	IPdf,
	IText,
	IUser,
	IVideo,
	TBlockEdit,
	TInvitedEmail,
	TUserAndRole
} from '@naya_studio/types';
import { ActionReducerMapBuilder, PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
	TActionType,
	TAddCommentFulfill,
	TAddCommentThunkArg,
	TAddMultipleBlockArgs,
	TAddNodesArg,
	TAddNoteArg,
	TAddSingleBlockArgs,
	TAddUserToBlockFulfill,
	TAddUserToBlockThunkArgs,
	TAddUserToProjectFulfill,
	TAddUserToProjectThunkArg,
	TAddUserToStageFulfill,
	TAddUserToStageThunkArg,
	TConvertBlockArgs,
	TCreateNewStageFromBlockThunkArg,
	TBatchCreateGroupAndBlocks,
	TCreateStageWithBlocksFulfill,
	TDeleteBlockArgs,
	TDeleteCommentThunkArg,
	TDeleteNoteArg,
	TDuplicateBlockArgs,
	TDuplicateProjectFulfill,
	TDuplicateProjectOptimisticallyThunkArg,
	TDuplicateStageThunkArg,
	TEditBlockArgs,
	TEditUserInBlockFulfill,
	TEditUserRoleForProjectFulfill,
	TEditUserRoleForProjectThunkArg,
	TEditUserRoleForStageFulfill,
	TEditUserRoleForStageThunkArg,
	TEditUserRoleInBlockArgs,
	TLoadBlockThunkArg,
	TLoadCommentFulfill,
	TLoadProjectByIdFulfill,
	TRemoveUserFromBlockBlockArgs,
	TRemoveUserFromBlockFulfill,
	TRemoveUserFromProjectFulfill,
	TRemoveUserFromProjectThunkArg,
	TRemoveUserFromStageFulfill,
	TRemoveUserFromStageThunkArg,
	TReorderBlocksThunkArg,
	TUndoredoBlockArgs,
	// TDeleteGroupThunkArg,
	TResetBlockArgs,
	TUndoRedoArgs,
	TBulkUpdateBlockArgs,
	TUndoDeleteFufill,
	TDeleteGroupThunkArg,
	TLoadTemplateByIdFulfill
} from 'src/types/argTypes';
import { getFileLinkSubType } from 'src/components/utilities/navbar/utils';
import {
	addMultipleBlocks,
	addSingleBlock,
	addUserToBlock,
	convertBlock,
	deleteBlock,
	duplicateBlock,
	editBlock,
	editUserRoleInBlock,
	favoriteBlock,
	loadBlockById,
	removeUserFromBlock,
	resetBlock,
	undoDelete,
	undoRedoBlock,
	bulkUpdateBlocks
} from '../reduxActions/block';
import { IBlocksInitState } from '../reducers/root.types';
import { addComment, deleteComment, loadComment } from '../reduxActions/feedbacks';
// import { findAndUpdateInComments, findAndUpdateInCommentsWithParentId } from '../actions/util';
import {
	addUserToProject,
	duplicateProject,
	duplicateProjectOptimistically,
	editUserRoleForProject,
	loadProjectById,
	loadProjectForGuestAction,
	loadTemplateById,
	removeUserFromProject
} from '../reduxActions/project';
import {
	addUserToStage,
	createNewStageFromOrphanBlock,
	batchCreateGroupAndBlocks,
	duplicateStage,
	editUserRoleForStage,
	removeUserFromStage,
	reorderBlocks,
	deleteStage
} from '../reduxActions/stage';
import { addNodes, editNodes, removeImageBackground } from '../reduxActions/node';
import { addNote, deleteNote } from '../reduxActions/notes';
import { notificationSlice } from './api/notification';
import { sentNotificationSlice } from './api/sentNotification';
import { addUserInArray, removeUserFromArray } from './util';

const initialState: IBlocksInitState = {
	data: {},
	loading: {},
	error: {}
};

const blocksSlice = createSlice({
	name: 'blocks',
	initialState,
	reducers: {
		// reducer to load all the blocks to the redux
		loadBlocks: (state: IBlocksInitState, action: PayloadAction<IBlock[]>) => {
			const blocks = action.payload;
			blocks.forEach((block) => {
				state.data[block._id as string] = {
					...state.data[block._id as string],
					...block
				};
			});
		},
		// reducer to a add block to the blocks reducer
		addBlock: (state: IBlocksInitState, action: PayloadAction<IBlock>) => {
			state.data[action.payload._id as string] = action.payload;
		},
		// reducer to edit a block by id
		editBlockById: (state: IBlocksInitState, action: PayloadAction<Partial<TBlockEdit>>) => {
			if (action.payload._id && state.data[action.payload._id as string])
				state.data[action.payload._id as string] = {
					...state.data[action.payload._id as string],
					...action.payload
				} as IBlock;
		},
		// reducer to delete a block by id
		deleteBlockById: (state: IBlocksInitState, action: PayloadAction<string>) => {
			delete state.data[action.payload as string];
		},
		// reducer to edit array of blocks
		editBlocksById: (state: IBlocksInitState, action: PayloadAction<IBlock[]>) => {
			action.payload.forEach((block) => {
				state.data[block._id as string] = {
					...state.data[block._id as string],
					...block
				};
			});
		},
		/**
		 * Reducer to unload blocks from redux
		 */
		unloadBlocks: (state: IBlocksInitState, action: PayloadAction<string>) => {
			const projectId = action.payload;

			const blocksToDelete = [];
			const keys = Object.keys(state.data);
			for (let i = 0; i < keys.length; i++) {
				const blockId = keys[i] as string;
				if (state.data[blockId]!.projectId === projectId) blocksToDelete.push(blockId);
			}

			blocksToDelete.forEach((id) => delete state.data[id]);
		},
		/**
		 * Add temporary notes to blocks - while adding note to blocks in multiselect
		 */
		addTempNotesOnBlocks: (state: IBlocksInitState, action: PayloadAction<string[]>) => {
			const selectedBlockIds = action.payload;
			selectedBlockIds.forEach((selBlock) => {
				if (state.data[selBlock] && !state.data[selBlock]?.notes) {
					state.data[selBlock]!.notes = [`NEW_NOTE_MULTI_${selBlock}`];
				} else if (!state.data[selBlock]?.notes?.includes(`NEW_NOTE_MULTI_${selBlock}`)) {
					state.data[selBlock]?.notes?.push(`NEW_NOTE_MULTI_${selBlock}`);
				}
			});
		},
		/**
		 * Remove temporary notes to blocks - while removing notes from blocks in multiselect
		 */
		removeTempNotesOnBlocks: (state: IBlocksInitState, action: PayloadAction<string[]>) => {
			const selectedBlockIds = action.payload;
			selectedBlockIds.forEach((selBlock) => {
				state.data[selBlock]!.notes = state.data[selBlock]?.notes?.filter(
					(n) => !(n as string)?.includes('NEW_NOTE')
				);
			});
		}
	},
	extraReducers: (builder: ActionReducerMapBuilder<IBlocksInitState>) => {
		/** ---- LOAD BLOCK BY ID ---- */
		// PENDING- set the loading state
		builder.addCase(
			loadBlockById.pending,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TLoadBlockThunkArg>['pendingMeta']
				>
			) => {
				const { id } = action.meta.arg;
				state.loading[id as string] = true;
				// reset the error just in case previous call has some error
				state.error[id as string] = '';
			}
		);
		// FULFILLED - update the redux data
		builder.addCase(
			loadBlockById.fulfilled,
			(
				state: IBlocksInitState,
				action: PayloadAction<TActionType<{ block: IBlock }, {}>['payload']>
			) => {
				if (action.payload) {
					const { block } = action.payload;
					const feedbackBlock = block as
						| IText
						| IFile
						| I3D
						| IImage
						| IVideo
						| IPdf
						| ILink
						| ICanvas;
					const nodeBlock = block as IText | IFile | I3D | IImage | ILink | ICanvas;

					const nodeIds = (nodeBlock?.nodes as INode[])?.map((n) => n._id as string);
					const feedbackIds = (feedbackBlock?.feedbacks as IFeedback[])?.map(
						(f) => f._id as string
					);
					const noteIds = (block?.notes as INote[])?.map((n) => n._id as string);

					state.data[block._id as string] = {
						...block,
						feedbacks: feedbackIds,
						nodes: nodeIds,
						notes: noteIds
					};
					// setting loading false
					state.loading[block._id as string] = false;
				}
			}
		);
		// REJECTED - set loading and error states
		builder.addCase(
			loadBlockById.rejected,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TLoadBlockThunkArg>['rejectedMeta']
				>
			) => {
				if (action.payload) {
					const { id } = action.meta.arg;
					state.error[id as string] = action.payload as string;
					// setting loading false
					state.loading[id as string] = false;
				}
			}
		);

		/** ---- ADD SINGLE EMPTY BLOCK ---- */
		// PENDING- add the block to redux in pending state
		builder.addCase(
			addSingleBlock.pending,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TAddSingleBlockArgs>['pendingMeta']
				>
			) => {
				const { blockData } = action.meta.arg.data;
				state.data[blockData._id as string] = {
					...blockData,
					updatedAt: new Date().toISOString()
				};
				state.loading[blockData._id as string] = true;
				// reset the error just in case previous call has some error
				state.error[blockData._id as string] = '';
			}
		);
		// FULFILLED- set the loading state
		builder.addCase(
			addSingleBlock.fulfilled,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					TActionType<TAddSingleBlockArgs, {}>['payload'],
					string,
					TActionType<{}, TAddSingleBlockArgs>['fulfilledMeta']
				>
			) => {
				const { blockData } = action.payload.data;
				const { blockData: pendingBlock } = action.meta.arg.data;
				const blockId = pendingBlock._id as string;

				if (!state.data[blockId]) state.data[blockId] = pendingBlock;

				state.data[blockId]!.updatedAt = blockData.updatedAt;
				state.loading[blockId] = false;
				state.error[blockId] = null;
			}
		);
		// REJECTED - remove the block from redux if action fails, set error and loading
		builder.addCase(
			addSingleBlock.rejected,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TAddSingleBlockArgs>['rejectedMeta']
				>
			) => {
				const { _id } = action.meta.arg.data.blockData;
				delete state.data[_id as string];
				state.loading[_id as string] = false;
				state.error[_id as string] = action.payload as string;
			}
		);

		/** ---- DUPLICATE BLOCK ---- */
		// PENDING- add the block to redux in pending state
		builder.addCase(
			duplicateBlock.pending,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TDuplicateBlockArgs>['pendingMeta']
				>
			) => {
				const { clonedItems } = action.meta.arg.data;
				const { block: clonedBlock } = clonedItems;

				state.data[clonedBlock._id as string] = {
					...clonedBlock,
					updatedAt: new Date().toISOString()
				};
				state.loading[clonedBlock._id as string] = true;
				// reset the error just in case previous call has some error
				state.error[clonedBlock._id as string] = '';
			}
		);
		// FULFILLED- set the loading state
		builder.addCase(
			duplicateBlock.fulfilled,
			(
				state: IBlocksInitState,
				action: PayloadAction<TActionType<TDuplicateBlockArgs, {}>['payload']>
			) => {
				const { clonedItems } = action.payload.data;
				const { block: clonedBlock } = clonedItems;

				state.data[clonedBlock._id as string]!.updatedAt = clonedBlock.updatedAt;
				state.loading[clonedBlock._id as string] = false;
				state.error[clonedBlock._id as string] = null;
			}
		);
		// REJECTED - remove the block from redux if action fails, set error and loading
		builder.addCase(
			duplicateBlock.rejected,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TDuplicateBlockArgs>['rejectedMeta']
				>
			) => {
				const { clonedItems } = action.meta.arg.data;
				const { block: clonedBlock } = clonedItems;

				delete state.data[clonedBlock._id as string];
				state.loading[clonedBlock._id as string] = false;
				state.error[clonedBlock._id as string] = action.payload as string;
			}
		);

		/** ---- ADD MULTIPLE EMPTY BLOCKS ---- */
		// PENDING - add the block to redux in pending state
		builder.addCase(
			addMultipleBlocks.pending,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TAddMultipleBlockArgs>['pendingMeta']
				>
			) => {
				const { blocks } = action.meta.arg.data;
				blocks.forEach((b: IBlock) => {
					state.data[b._id as string] = { ...b, updatedAt: new Date().toISOString() };
					state.loading[b._id as string] = true;
					// reset the error just in case previous call has some error
					state.error[b._id as string] = '';
				});
			}
		);
		// FULFILLED - set the loading state
		builder.addCase(
			addMultipleBlocks.fulfilled,
			(
				state: IBlocksInitState,
				action: PayloadAction<TActionType<TAddMultipleBlockArgs, {}>['payload']>
			) => {
				const { blocks } = action.payload.data;
				blocks.forEach((b: IBlock) => {
					if (b.blockType === 'LINK') {
						const subType = getFileLinkSubType((b as ILink).link, 'LINK');
						if (subType === 'NAYA') {
							state.data[b._id as string] = b; // for Naya blocks, always replace from backend data
						}
					} else if (!state.data[b._id as string]) {
						state.data[b._id as string] = b;
					}
					state.data[b._id as string]!.updatedAt = b.updatedAt;
					state.loading[b._id as string] = false;
					state.error[b._id as string] = null;
				});
			}
		);
		// REJECTED-remove the block from redux if action fails
		builder.addCase(
			addMultipleBlocks.rejected,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TAddMultipleBlockArgs>['rejectedMeta']
				>
			) => {
				const { blocks } = action.meta.arg.data;
				blocks.forEach((b: IBlock) => {
					delete state.data[b._id as string];
					state.error[b._id as string] = action.payload as string;
					state.loading[b._id as string] = false;
				});
			}
		);

		/** ---- UNDO REDO BLOCK ---- */
		// FULFILLED
		builder.addCase(
			undoRedoBlock.fulfilled,
			(
				state: IBlocksInitState,
				action: PayloadAction<TActionType<IBlock, {}>['payload']>
			) => {
				const block = action.payload;
				state.data[block._id as string]!.updatedAt = block.updatedAt;
			}
		);
		// PENDING
		builder.addCase(
			undoRedoBlock.pending,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TUndoredoBlockArgs>['pendingMeta']
				>
			) => {
				const block = action.meta.arg.data.block as IBlock;
				if (block.isVisible) {
					state.data[block._id as string] = block;
				} else {
					delete state.data[block._id as string];
				}
			}
		);
		// REJECTED
		builder.addCase(
			undoRedoBlock.rejected,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TUndoredoBlockArgs>['rejectedMeta']
				>
			) => {
				const prevBlock = action.meta.arg.prevState.prevStateBlock;
				const block = action.meta.arg.data.block as IBlock;
				state.data[block._id as string] = prevBlock;
			}
		);

		/** ---- EDIT BLOCK ---- */
		// PENDING - edit the block in redux
		builder.addCase(
			editBlock.pending,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TEditBlockArgs>['pendingMeta']
				>
			) => {
				const { block } = action.meta.arg.data;
				const data = {
					...state.data[block._id as string],
					...block,
					updatedAt: new Date().toISOString()
				};
				state.data[block._id as string] = data as IBlock;
				// state.loading[block._id as string] = true;
				// reset the error just in case previous call has some error
				state.error[block._id as string] = '';
			}
		);
		// FULFILLED
		builder.addCase(
			editBlock.fulfilled,
			(
				state: IBlocksInitState,
				action: PayloadAction<TActionType<TEditBlockArgs, {}>['payload']>
			) => {
				const { data } = action.payload;

				if (data && data.block) {
					const { block } = data;

					const reduxBlock = state.data[block?._id as string] as IBlock;

					if (
						new Date(block.updatedAt as string) >
						new Date(reduxBlock.updatedAt as string)
					)
						state.data[block._id as string] = { ...reduxBlock, ...block } as IBlock;
					else state.data[block._id as string]!.updatedAt = block.updatedAt;
				}
			}
		);
		// REJECTED - revert the block from redux if action fails
		builder.addCase(
			editBlock.rejected,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TEditBlockArgs>['rejectedMeta']
				>
			) => {
				const { data, prevState } = action.meta.arg;
				const { block } = data;
				state.data[block._id as string] = prevState.prevStateBlock;
				state.error[block._id as string] = action.payload as string;
				// state.loading[block._id as string] = false;
			}
		);

		/** ---- RESET BLOCK ---- */
		// PENDING - edit the block in redux
		builder.addCase(
			resetBlock.pending,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TResetBlockArgs>['pendingMeta']
				>
			) => {
				const { block } = action.meta.arg.data;
				state.data[block._id as string] = block as IBlock;
				// reset the error just in case previous call has some error
				state.error[block._id as string] = '';
			}
		);
		// REJECTED - revert the block from redux if action fails
		builder.addCase(
			resetBlock.rejected,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TResetBlockArgs>['rejectedMeta']
				>
			) => {
				const { data, prevState } = action.meta.arg;
				const { block } = data;
				state.data[block._id as string] = prevState.prevStateBlock;
				state.error[block._id as string] = action.payload as string;
			}
		);

		/** ---- CONVERT BLOCK ---- */
		// PENDING - edit the block in redux
		builder.addCase(
			convertBlock.pending,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TConvertBlockArgs>['pendingMeta']
				>
			) => {
				const { blockData, blockId } = action.meta.arg.data;
				const data = {
					...state.data[blockId],
					...blockData,
					updatedAt: new Date().toISOString()
				};
				state.data[blockId] = data as IBlock;
				// state.loading[blockId] = true;
				// reset the error just in case previous call has some error
				state.error[blockId] = '';
			}
		);
		// FULFILLED
		builder.addCase(
			convertBlock.fulfilled,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					TActionType<TConvertBlockArgs, {}>['payload'],
					string,
					TActionType<{}, TConvertBlockArgs>['fulfilledMeta']
				>
			) => {
				const { blockData: block } = action.payload;

				if (block) {
					const reduxBlock = state.data[block._id as string] as IBlock;

					if (
						new Date(block.updatedAt as string) >
						new Date(reduxBlock.updatedAt as string)
					)
						state.data[block._id as string] = { ...reduxBlock, ...block };
				}
			}
		);
		// REJECTED - revert the block from redux if action fails
		builder.addCase(
			convertBlock.rejected,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TConvertBlockArgs>['rejectedMeta']
				>
			) => {
				const { data, prevState } = action.meta.arg;
				const { blockId } = data;
				state.data[blockId] = prevState.prevStateBlock;
				state.error[blockId] = action.payload as string;
				// state.loading[blockId] = false;
			}
		);

		/** ---- DELETE BLOCK ---- */
		// PENDING - edit the block in redux
		builder.addCase(
			deleteBlock.pending,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TDeleteBlockArgs>['pendingMeta']
				>
			) => {
				const { blockIds } = action.meta.arg.data;
				blockIds.forEach((blockId) => {
					// state.data[blockId]!.isVisible = false;
					delete state.data[blockId];
					// state.loading[blockId] = true;
					// reset the error just in case previous call has some error
					state.error[blockId] = '';
				});
			}
		);
		// FULFILLED
		// builder.addCase(
		// 	deleteBlock.fulfilled,
		// 	(
		// 		state: IBlocksInitState,
		// 		action: PayloadAction<TActionType<TDeleteBlockArgs, {}>['payload']>
		// 	) => {
		// 		const { blockId } = action.payload.data;
		// 		state.loading[blockId] = false;
		// 	}
		// );
		// revert the block from redux if action fails
		builder.addCase(
			deleteBlock.rejected,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TDeleteBlockArgs>['rejectedMeta']
				>
			) => {
				const { data, prevState } = action.meta.arg;
				state.data = prevState.prevBlocks;
				// state.loading[data.blockId] = false;
				data.blockIds.forEach((blockId) => {
					state.error[blockId] = action.payload as string;
				});
			}
		);

		/** ---- REMOVE USER FROM BLOCK ---- */
		// FULFILLED
		builder.addCase(
			removeUserFromBlock.fulfilled,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					TActionType<TRemoveUserFromBlockFulfill, {}>['payload'],
					string,
					TActionType<{}, TRemoveUserFromBlockBlockArgs>['fulfilledMeta']
				>
			) => {
				const { data } = action.meta.arg;
				if (data) {
					const { blockId, invitedUserRole, userId } = data;
					const isInvitedUser = !!invitedUserRole;

					if (isInvitedUser) {
						state.data[blockId]!.invitedEmails = removeUserFromArray(
							isInvitedUser,
							userId,
							state.data[blockId]!.invitedEmails as TInvitedEmail[]
						) as TInvitedEmail[];
					} else {
						state.data[blockId]!.users = removeUserFromArray(
							isInvitedUser,
							userId,
							state.data[blockId]!.users as TUserAndRole[]
						) as TUserAndRole[];
					}
					state.loading[blockId] = false;
					state.error[blockId] = null;
				}
			}
		);
		// PENDING
		builder.addCase(
			removeUserFromBlock.pending,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TRemoveUserFromBlockBlockArgs>['pendingMeta']
				>
			) => {
				const { data } = action.meta.arg;
				if (data) {
					const { blockId, invitedUserRole, userId } = data;
					const isInvitedUser = !!invitedUserRole;

					if (isInvitedUser) {
						state.data[blockId]!.invitedEmails = removeUserFromArray(
							isInvitedUser,
							userId,
							state.data[blockId]!.invitedEmails as TInvitedEmail[]
						) as TInvitedEmail[];
					} else {
						state.data[blockId]!.users = removeUserFromArray(
							isInvitedUser,
							userId,
							state.data[blockId]!.users as TUserAndRole[]
						) as TUserAndRole[];
					}

					// reset the error just in case previous call has some error
					state.error[blockId] = null;
				}
			}
		);
		// REJECTED
		builder.addCase(
			removeUserFromBlock.rejected,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TRemoveUserFromBlockBlockArgs>['rejectedMeta']
				>
			) => {
				const { data } = action.meta.arg;
				if (data) {
					const { blockId, invitedUserRole, userRole } = data;
					const isInvitedUser = !!invitedUserRole;

					if (isInvitedUser) state.data[blockId]!.invitedEmails.push(invitedUserRole);
					else state.data[blockId]!.users.push(userRole);

					// reset the error just in case previous call has some error
					state.error[blockId] = action.payload as string;
					state.loading[blockId] = false;
				}
			}
		);

		/** ---- ADD USER TO BLOCK ---- */
		// FULFILLED
		builder.addCase(
			addUserToBlock.fulfilled,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					TActionType<TAddUserToBlockFulfill, {}>['payload'],
					string,
					TActionType<{}, TAddUserToBlockThunkArgs>['fulfilledMeta']
				>
			) => {
				if (action.payload) {
					const { data } = action.meta.arg;

					if (data) {
						const { isInvitedUser, blockId, email, userId, role } = data;

						// check id invited user
						if (isInvitedUser) {
							const invitedEmails = state.data[blockId]
								?.invitedEmails as TInvitedEmail[];
							const updatedInvitedEmails = addUserInArray(
								isInvitedUser,
								email,
								role,
								invitedEmails
							);
							state.data[blockId]!.invitedEmails =
								updatedInvitedEmails as TInvitedEmail[];
						} else {
							const users = state.data[blockId]?.users as TUserAndRole[];
							const updatedUsers = addUserInArray(
								isInvitedUser,
								userId as string,
								role,
								users
							);
							state.data[blockId]!.users = updatedUsers as TUserAndRole[];
						}

						state.loading[blockId] = false;
						state.error[blockId] = null;
					}
				}
			}
		);
		// PENDING
		builder.addCase(
			addUserToBlock.pending,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TAddUserToBlockThunkArgs>['pendingMeta']
				>
			) => {
				const { data } = action.meta.arg;

				if (data) {
					const { isInvitedUser, blockId, email, userId, role } = data;
					// check id invited user
					if (isInvitedUser) {
						const invitedEmails = state.data[blockId]?.invitedEmails as TInvitedEmail[];
						const updatedInvitedEmails = addUserInArray(
							isInvitedUser,
							email,
							role,
							invitedEmails
						);
						state.data[blockId]!.invitedEmails =
							updatedInvitedEmails as TInvitedEmail[];
					} else {
						const users = state.data[blockId]?.users as TUserAndRole[];
						const updatedUsers = addUserInArray(
							isInvitedUser,
							userId as string,
							role,
							users
						);
						state.data[blockId]!.users = updatedUsers as TUserAndRole[];
					}

					state.error[blockId] = null;
				}
			}
		);
		// REJECTED
		builder.addCase(
			addUserToBlock.rejected,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TAddUserToBlockThunkArgs>['rejectedMeta']
				>
			) => {
				const { data } = action.meta.arg;

				if (data) {
					const { isInvitedUser, blockId, email, userId } = data;
					// check id invited user
					if (isInvitedUser) {
						state.data[blockId]!.invitedEmails = removeUserFromArray(
							isInvitedUser,
							email,
							state.data[blockId]!.invitedEmails as TInvitedEmail[]
						) as TInvitedEmail[];
					} else {
						state.data[blockId]!.users = removeUserFromArray(
							isInvitedUser,
							userId as string,
							state.data[blockId]!.users as TUserAndRole[]
						) as TUserAndRole[];
					}

					state.error[blockId] = action.payload as string;
					state.loading[blockId] = false;
				}
			}
		);

		/** ---- EDIT USER ROLE IN BLOCK ---- */
		// FULFILLED
		builder.addCase(
			editUserRoleInBlock.fulfilled,
			(
				state: IBlocksInitState,
				action: PayloadAction<TActionType<TEditUserInBlockFulfill, {}>['payload']>
			) => {
				if (action.payload) {
					const { blockId } = action.payload;
					// check id invited user
					state.data[blockId]!.updatedAt = new Date().toISOString();
					state.loading[blockId] = false;
					state.error[blockId] = null;
				}
			}
		);
		// PENDING
		builder.addCase(
			editUserRoleInBlock.pending,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TEditUserRoleInBlockArgs>['pendingMeta']
				>
			) => {
				const { data } = action.meta.arg;
				if (data) {
					const { blockId, invitedUser, role, userId } = data;
					const isInvitedUser = !!invitedUser;
					// check id invited user
					if (isInvitedUser) {
						const invitedEmails = state.data[blockId]?.invitedEmails as TInvitedEmail[];
						if (invitedEmails) {
							for (let i = 0; i < invitedEmails.length; i++) {
								if (invitedEmails[i] && invitedEmails[i]?.email === userId) {
									invitedEmails[i]!.role = role;
								}
							}
						}
						state.data[blockId]!.invitedEmails = invitedEmails;
					} else {
						const users = state.data[blockId]?.users as TUserAndRole[];
						if (users) {
							for (let i = 0; i < users.length; i++) {
								if (users[i] && users[i]?.user === userId) {
									users[i]!.role = role;
								}
							}
						}
						state.data[blockId]!.users = users;
					}

					state.error[blockId] = null;
				}
			}
		);
		// REJECTED
		builder.addCase(
			editUserRoleInBlock.rejected,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TEditUserRoleInBlockArgs>['rejectedMeta']
				>
			) => {
				const { data, prevRole } = action.meta.arg;

				if (data) {
					const { blockId, invitedUser, userId } = data;
					const isInvitedUser = !!invitedUser;
					// check id invited user
					if (isInvitedUser) {
						const invitedEmails = state.data[blockId]?.invitedEmails as TInvitedEmail[];
						if (invitedEmails) {
							for (let i = 0; i < invitedEmails.length; i++) {
								if (invitedEmails[i] && invitedEmails[i]?.email === userId) {
									invitedEmails[i]!.role = prevRole;
								}
							}
						}
						state.data[blockId]!.invitedEmails = invitedEmails;
					} else {
						const users = state.data[blockId]?.users as TUserAndRole[];
						if (users) {
							for (let i = 0; i < users.length; i++) {
								if (users[i] && users[i]?.user === userId) {
									users[i]!.role = prevRole;
								}
							}
						}
						state.data[blockId]!.users = users;
					}

					state.error[blockId] = action.payload as string;
					state.loading[blockId] = false;
				}
			}
		);

		/** ---- ADD USER TO STAGE ---- */
		// FULFILLED
		builder.addCase(
			addUserToStage.fulfilled,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					TActionType<TAddUserToStageFulfill, {}>['payload'],
					string,
					TActionType<{}, TAddUserToStageThunkArg>['fulfilledMeta']
				>
			) => {
				const { payload, blockIds } = action.meta.arg;
				if (payload) {
					const { isInvitedUser, email, userId, role, override } = payload;

					if (blockIds) {
						blockIds.forEach((blockId) => {
							if (isInvitedUser) {
								const invitedEmails = state.data[blockId]
									?.invitedEmails as TInvitedEmail[];
								const updatedInvitedEmails = addUserInArray(
									isInvitedUser,
									email,
									role,
									invitedEmails,
									override
								);
								state.data[blockId]!.invitedEmails =
									updatedInvitedEmails as TInvitedEmail[];
							} else {
								const users = state.data[blockId]?.users as TUserAndRole[];
								const updatedUsers = addUserInArray(
									isInvitedUser,
									userId as string,
									role,
									users,
									override
								);
								state.data[blockId]!.users = updatedUsers as TUserAndRole[];
							}

							state.loading[blockId] = false;
							state.error[blockId] = null;
						});
					}
				}
			}
		);
		// PENDING
		builder.addCase(
			addUserToStage.pending,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TAddUserToStageThunkArg>['pendingMeta']
				>
			) => {
				if (action.meta.arg) {
					const { payload, blockIds } = action.meta.arg;

					if (payload) {
						const { isInvitedUser, email, userId, role, override } = payload;

						// Add user to users/invitedEmails in redux
						if (blockIds) {
							blockIds.forEach((blockId) => {
								if (isInvitedUser) {
									const invitedEmails = state.data[blockId]
										?.invitedEmails as TInvitedEmail[];
									const updatedInvitedEmails = addUserInArray(
										isInvitedUser,
										email,
										role,
										invitedEmails,
										override
									);
									state.data[blockId]!.invitedEmails =
										updatedInvitedEmails as TInvitedEmail[];
								} else {
									const users = state.data[blockId]?.users as TUserAndRole[];
									const updatedUsers = addUserInArray(
										isInvitedUser,
										userId as string,
										role,
										users,
										override
									);
									state.data[blockId]!.users = updatedUsers as TUserAndRole[];
								}

								state.error[blockId] = null;
							});
						}
					}
				}
			}
		);
		// REJECTED
		builder.addCase(
			addUserToStage.rejected,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TAddUserToStageThunkArg>['rejectedMeta']
				>
			) => {
				if (action.meta.arg) {
					const { payload, blockIds } = action.meta.arg;
					const { payload: error } = action;

					if (payload) {
						const { isInvitedUser, email, userId } = payload;

						// Remove added user from users/invitedEmails in redux
						if (blockIds) {
							blockIds.forEach((blockId) => {
								if (isInvitedUser) {
									state.data[blockId]!.invitedEmails = removeUserFromArray(
										isInvitedUser,
										email,
										state.data[blockId]!.invitedEmails as TInvitedEmail[]
									) as TInvitedEmail[];
								} else {
									state.data[blockId]!.users = removeUserFromArray(
										isInvitedUser,
										userId as string,
										state.data[blockId]!.users as TUserAndRole[]
									) as TUserAndRole[];
								}

								state.loading[blockId] = false;
								state.error[blockId] = error as string;
							});
						}
					}
				}
			}
		);

		// ---- REMOVE USER FROM STAGE ----
		builder.addCase(
			removeUserFromStage.fulfilled,
			(
				state: IBlocksInitState,
				action: PayloadAction<TActionType<TRemoveUserFromStageFulfill, {}>['payload']>
			) => {
				const { blockIds } = action.payload;

				// check if invited user
				blockIds.forEach((b: string) => {
					state.loading[b] = false;
					state.error[b] = null;
				});
			}
		);
		// PENDING
		builder.addCase(
			removeUserFromStage.pending,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TRemoveUserFromStageThunkArg>['pendingMeta']
				>
			) => {
				const { payload, blockIds } = action.meta.arg;

				if (payload) {
					const { invitedUserRole, userId } = payload;

					const isInvitedUser = !!invitedUserRole;
					blockIds.forEach((blockId: string) => {
						if (isInvitedUser) {
							state.data[blockId]!.invitedEmails = removeUserFromArray(
								isInvitedUser,
								userId,
								state.data[blockId]!.invitedEmails as TInvitedEmail[]
							) as TInvitedEmail[];
						} else {
							state.data[blockId]!.users = removeUserFromArray(
								isInvitedUser,
								userId,
								state.data[blockId]!.users as TUserAndRole[]
							) as TUserAndRole[];
						}

						state.error[blockId] = null;
					});
				}
			}
		);
		// REJECTED
		builder.addCase(
			removeUserFromStage.rejected,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TRemoveUserFromStageThunkArg>['rejectedMeta']
				>
			) => {
				const { payload, blockIds } = action.meta.arg;

				if (payload) {
					const { invitedUserRole, userRole } = payload;

					const isInvitedUser = !!invitedUserRole;
					blockIds.forEach((s) => {
						if (isInvitedUser) state.data[s]!.invitedEmails!.push(invitedUserRole);
						else state.data[s]!.users!.push(userRole);
						state.loading[s] = false;
						state.error[s] = null;
					});
				}
			}
		);

		// ---- EDIT USER ROLE FROM STAGE ----
		// FULFILLED
		builder.addCase(
			editUserRoleForStage.fulfilled,
			(
				state: IBlocksInitState,
				action: PayloadAction<TActionType<TEditUserRoleForStageFulfill, {}>['payload']>
			) => {
				if (action.payload) {
					const { blockIds } = action.payload;
					if (blockIds) {
						blockIds.forEach((b) => {
							state.loading[b] = false;
							state.error[b] = null;
						});
					}
				}
			}
		);
		// PENDING
		builder.addCase(
			editUserRoleForStage.pending,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TEditUserRoleForStageThunkArg>['pendingMeta']
				>
			) => {
				const { payload, blockIds } = action.meta.arg;

				if (payload) {
					const { invitedUser, userId, role } = payload;
					const isInvitedUser = !!invitedUser;

					if (blockIds) {
						blockIds.forEach((b) => {
							// check user exists in the payload - meaning existing user
							if (isInvitedUser) {
								const invitedEmails = state.data[b]
									?.invitedEmails as TInvitedEmail[];
								if (invitedEmails) {
									for (let i = 0; i < invitedEmails.length; i++) {
										if (
											invitedEmails[i] &&
											invitedEmails[i]?.email === userId
										) {
											invitedEmails[i]!.role = role;
										}
									}
								}
								state.data[b]!.invitedEmails = invitedEmails;
							} else {
								const users = state.data[b]?.users as TUserAndRole[];
								if (users) {
									for (let i = 0; i < users.length; i++) {
										if (users[i] && users[i]?.user === userId) {
											users[i]!.role = role;
										}
									}
								}
								state.data[b]!.users = users;
							}

							state.error[b] = null;
						});
					}
				}
			}
		);
		// REJECTED
		builder.addCase(
			editUserRoleForStage.rejected,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TEditUserRoleForStageThunkArg>['rejectedMeta']
				>
			) => {
				const { payload, blockIds, prevRole } = action.meta.arg;

				if (payload) {
					const { invitedUser, userId } = payload;
					const isInvitedUser = !!invitedUser;

					if (blockIds) {
						blockIds.forEach((b) => {
							// check user exists in the payload - meaning existing user
							if (isInvitedUser) {
								const invitedEmails = state.data[b]
									?.invitedEmails as TInvitedEmail[];
								if (invitedEmails) {
									for (let i = 0; i < invitedEmails.length; i++) {
										if (
											invitedEmails[i] &&
											invitedEmails[i]?.email === userId
										) {
											invitedEmails[i]!.role = prevRole;
										}
									}
								}
								state.data[b]!.invitedEmails = invitedEmails;
							} else {
								const users = state.data[b]?.users as TUserAndRole[];
								if (users) {
									for (let i = 0; i < users.length; i++) {
										if (users[i] && users[i]?.user === userId) {
											users[i]!.role = prevRole;
										}
									}
								}
								state.data[b]!.users = users;
							}

							state.loading[b] = false;
							state.error[b] = null;
						});
					}
				}
			}
		);

		// ---- ADD USER TO PROJECT ----
		// FULFILLED
		builder.addCase(
			addUserToProject.fulfilled,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					TActionType<TAddUserToProjectFulfill, {}>['payload'],
					string,
					TActionType<{}, TAddUserToProjectThunkArg>['fulfilledMeta']
				>
			) => {
				if (action.meta.arg) {
					const { payload, blockIds } = action.meta.arg;

					if (payload) {
						const { isInvitedUser, email, userId, role, override } = payload;

						// Add user to users/invitedEmails in redux
						if (blockIds) {
							blockIds.forEach((blockId) => {
								if (isInvitedUser) {
									const invitedEmails = state.data[blockId]
										?.invitedEmails as TInvitedEmail[];
									const updatedInvitedEmails = addUserInArray(
										isInvitedUser,
										email,
										role,
										invitedEmails,
										override
									);
									state.data[blockId]!.invitedEmails =
										updatedInvitedEmails as TInvitedEmail[];
								} else {
									const users = state.data[blockId]?.users as TUserAndRole[];
									const updatedUsers = addUserInArray(
										isInvitedUser,
										userId as string,
										role,
										users,
										override
									);
									state.data[blockId]!.users = updatedUsers as TUserAndRole[];
								}

								state.error[blockId] = null;
							});
						}
					}
				}
			}
		);
		// PENDING
		builder.addCase(
			addUserToProject.pending,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TAddUserToProjectThunkArg>['pendingMeta']
				>
			) => {
				if (action.meta.arg) {
					const { payload, blockIds } = action.meta.arg;

					if (payload) {
						const { isInvitedUser, email, userId, role, override } = payload;

						// Add user to users/invitedEmails in redux
						if (blockIds) {
							blockIds.forEach((blockId) => {
								if (isInvitedUser) {
									const invitedEmails = state.data[blockId]
										?.invitedEmails as TInvitedEmail[];
									const updatedInvitedEmails = addUserInArray(
										isInvitedUser,
										email,
										role,
										invitedEmails,
										override
									);
									state.data[blockId]!.invitedEmails =
										updatedInvitedEmails as TInvitedEmail[];
								} else {
									const users = state.data[blockId]?.users as TUserAndRole[];
									const updatedUsers = addUserInArray(
										isInvitedUser,
										userId as string,
										role,
										users,
										override
									);
									state.data[blockId]!.users = updatedUsers as TUserAndRole[];
								}

								state.error[blockId] = null;
							});
						}
					}
				}
			}
		);
		// REJECTED
		builder.addCase(
			addUserToProject.rejected,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TAddUserToProjectThunkArg>['rejectedMeta']
				>
			) => {
				if (action.meta.arg) {
					const { payload, blockIds } = action.meta.arg;
					const { payload: error } = action;

					if (payload) {
						const { isInvitedUser, email, userId } = payload;

						// Remove added user from users/invitedEmails in redux
						if (blockIds) {
							blockIds.forEach((s) => {
								if (isInvitedUser) {
									const invitedEmails = state.data[s]
										?.invitedEmails as TInvitedEmail[];
									state.data[s]!.invitedEmails = invitedEmails.filter(
										(u) => (u as TInvitedEmail).email !== email
									);
								} else {
									const users = state.data[s]?.users;
									if (users) {
										state.data[s]!.users = users.filter(
											(u) => (u as TUserAndRole).user !== userId
										);
									}
								}

								state.loading[s] = false;
								state.error[s] = error as string;
							});
						}
					}
				}
			}
		);

		// ---- REMOVE USER FROM PROJECT ----
		// FULFILLED
		builder.addCase(
			removeUserFromProject.fulfilled,
			(
				state: IBlocksInitState,
				action: PayloadAction<TActionType<TRemoveUserFromProjectFulfill, {}>['payload']>
			) => {
				const { payload } = action;

				if (payload) {
					const { blockIds } = payload;

					blockIds.forEach((s) => {
						state.loading[s] = false;
						state.error[s] = null;
					});
				}
			}
		);
		// PENDING
		builder.addCase(
			removeUserFromProject.pending,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TRemoveUserFromProjectThunkArg>['pendingMeta']
				>
			) => {
				const { payload, blockIds } = action.meta.arg;

				if (payload) {
					const { invitedUserRole, userId } = payload;

					const isInvitedUser = !!invitedUserRole;
					blockIds.forEach((s) => {
						if (isInvitedUser) {
							state.data[s]!.invitedEmails = state.data[s]!.invitedEmails?.filter(
								(x) => (x as TInvitedEmail).email !== userId
							);
						} else {
							state.data[s]!.users = state.data[s]!.users?.filter(
								(x) => ((x as TUserAndRole).user as IUser) !== userId
							);
						}

						state.error[s] = null;
					});
				}
			}
		);
		// REJECTED
		builder.addCase(
			removeUserFromProject.rejected,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TRemoveUserFromProjectThunkArg>['rejectedMeta']
				>
			) => {
				const { payload, blockIds } = action.meta.arg;

				if (payload) {
					const { invitedUserRole, userRole } = payload;

					const isInvitedUser = !!invitedUserRole;
					blockIds.forEach((s) => {
						if (isInvitedUser) state.data[s]!.invitedEmails!.push(invitedUserRole);
						else state.data[s]!.users!.push(userRole);
						state.loading[s] = false;
						state.error[s] = null;
					});
				}
			}
		);

		// ---- EDIT USER ROLE FROM PROJECT ----
		// FULFILLED
		builder.addCase(
			editUserRoleForProject.fulfilled,
			(
				state: IBlocksInitState,
				action: PayloadAction<TActionType<TEditUserRoleForProjectFulfill, {}>['payload']>
			) => {
				if (action.payload) {
					const { blockIds } = action.payload;
					if (blockIds) {
						blockIds.forEach((b) => {
							state.loading[b] = false;
							state.error[b] = null;
						});
					}
				}
			}
		);
		// PENDING
		builder.addCase(
			editUserRoleForProject.pending,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TEditUserRoleForProjectThunkArg>['pendingMeta']
				>
			) => {
				const { payload, blockIds } = action.meta.arg;

				if (payload) {
					const { invitedUser, userId, role } = payload;
					const isInvitedUser = !!invitedUser;

					if (blockIds) {
						blockIds.forEach((b) => {
							// check user exists in the payload - meaning existing user
							if (isInvitedUser) {
								const invitedEmails = state.data[b]
									?.invitedEmails as TInvitedEmail[];
								if (invitedEmails) {
									for (let i = 0; i < invitedEmails.length; i++) {
										if (
											invitedEmails[i] &&
											invitedEmails[i]?.email === userId
										) {
											invitedEmails[i]!.role = role;
										}
									}
								}
								state.data[b]!.invitedEmails = invitedEmails;
							} else {
								const users = state.data[b]?.users as TUserAndRole[];
								if (users) {
									for (let i = 0; i < users.length; i++) {
										if (users[i] && users[i]?.user === userId) {
											users[i]!.role = role;
										}
									}
								}
								state.data[b]!.users = users;
							}

							state.error[b] = null;
						});
					}
				}
			}
		);
		// REJECTED
		builder.addCase(
			editUserRoleForProject.rejected,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TEditUserRoleForProjectThunkArg>['rejectedMeta']
				>
			) => {
				const { payload, blockIds, prevRole } = action.meta.arg;

				if (payload) {
					const { invitedUser, userId } = payload;
					const isInvitedUser = !!invitedUser;

					if (blockIds) {
						blockIds.forEach((b) => {
							// check user exists in the payload - meaning existing user
							if (isInvitedUser) {
								const invitedEmails = state.data[b]
									?.invitedEmails as TInvitedEmail[];
								if (invitedEmails) {
									for (let i = 0; i < invitedEmails.length; i++) {
										if (
											invitedEmails[i] &&
											invitedEmails[i]?.email === userId
										) {
											invitedEmails[i]!.role = prevRole;
										}
									}
								}
								state.data[b]!.invitedEmails = invitedEmails;
							} else {
								const users = state.data[b]?.users as TUserAndRole[];
								if (users) {
									for (let i = 0; i < users.length; i++) {
										if (users[i] && users[i]?.user === userId) {
											users[i]!.role = prevRole;
										}
									}
								}
								state.data[b]!.users = users;
							}

							state.loading[b] = false;
							state.error[b] = null;
						});
					}
				}
			}
		);

		/** ---- LOAD PROJECT ---- */
		// FULFILLED
		builder.addCase(
			loadProjectById.fulfilled,
			(
				state: IBlocksInitState,
				action: PayloadAction<TActionType<TLoadProjectByIdFulfill, {}>['payload']>
			) => {
				const { blocks } = action.payload;
				blocks.forEach((block: IBlock) => {
					const blockId = block._id as string;
					let overwriteBlock = true;

					if (state.data[blockId]) {
						const oldBlock = state.data[blockId];

						// Only update the block if block from api have been updated later
						// to prevent the scenario where a user refreshes and someone edits a block
						if (
							new Date(oldBlock?.updatedAt as string) >
							new Date(block.updatedAt as string)
						)
							overwriteBlock = false;
					}

					if (overwriteBlock) state.data[blockId] = block;
					// state.loading[b._id as string] = false;
					// reset the error just in case previous call has some error
					state.error[blockId] = null;
				});
			}
		);

		/** ---- LOAD TEMPLATE ---- */
		// FULFILLED
		builder.addCase(
			loadTemplateById.fulfilled,
			(
				state: IBlocksInitState,
				action: PayloadAction<TActionType<TLoadTemplateByIdFulfill, {}>['payload']>
			) => {
				const { template } = action.payload;
				template?.blocks?.forEach((block: IBlock) => {
					const blockId = block._id as string;

					state.data[blockId] = block;
					state.error[blockId] = null;
				});
			}
		);

		/** ---- LOAD PROJECT FOR GUEST ---- */
		// FULFILLED
		builder.addCase(
			loadProjectForGuestAction.fulfilled,
			(
				state: IBlocksInitState,
				action: PayloadAction<TActionType<TLoadProjectByIdFulfill, {}>['payload']>
			) => {
				const { blocks } = action.payload;
				blocks.forEach((b: IBlock) => {
					state.data[b._id as string] = b;
					// state.loading[b._id as string] = false;
					// reset the error just in case previous call has some error
					state.error[b._id as string] = '';
				});
			}
		);

		/** ---- DUPLICATE PROJECT ---- */
		// FULFILLED
		builder.addCase(
			duplicateProject.fulfilled,
			(
				state: IBlocksInitState,
				action: PayloadAction<TActionType<TDuplicateProjectFulfill, {}>['payload']>
			) => {
				const { blocks } = action.payload;
				blocks.forEach((b: IBlock) => {
					state.data[b._id as string] = b;
					// state.loading[b._id as string] = false;
					// reset the error just in case previous call has some error
					state.error[b._id as string] = '';
				});
			}
		);

		/** ---- DUPLICATE STAGE ---- */
		// PENDING
		builder.addCase(
			duplicateStage.pending,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TDuplicateStageThunkArg>['pendingMeta']
				>
			) => {
				const { clonedItems } = action.meta.arg.payload;
				const { blocks: clonedBlocks } = clonedItems;

				if (clonedBlocks) {
					clonedBlocks.forEach((clonedBlock) => {
						const blockId = clonedBlock._id as string;
						state.data[blockId] = clonedBlock;
						state.error[blockId] = null;
					});
				}
			}
		);
		// REJECTED
		builder.addCase(
			duplicateStage.rejected,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TDuplicateStageThunkArg>['rejectedMeta']
				>
			) => {
				const { clonedItems } = action.meta.arg.payload;
				const { blocks: clonedBlocks } = clonedItems;

				if (clonedBlocks) {
					clonedBlocks.forEach((clonedBlock) => {
						const blockId = clonedBlock._id as string;

						if (state.data[blockId]) {
							delete state.data[blockId];
						}

						state.error[blockId] = action.payload as string;
						state.loading[blockId] = false;
					});
				}
			}
		);
		/** ---- DUPLICATE STAGE ---- */

		/** ---- ADD COMMENT ---- */
		// PENDING
		builder.addCase(
			addComment.fulfilled,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					TActionType<TAddCommentFulfill, {}>['payload'],
					string,
					TActionType<{}, TAddCommentThunkArg>['fulfilledMeta']
				>
			) => {
				const { payload } = action.meta.arg;
				if (payload) {
					const { blockId, feedback, parentCommentId } = payload;

					if (blockId && state.data[blockId] && !parentCommentId) {
						const block = state.data[blockId] as IFeedbackBlock;
						const blockFeedbacks = block.feedbacks as string[];

						if (!blockFeedbacks.includes(feedback._id as string)) {
							(state.data[blockId] as IFeedbackBlock).feedbacks.push(
								feedback._id as string
							);
						}
					}
				}
			}
		);
		// PENDING
		builder.addCase(
			addComment.pending,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TAddCommentThunkArg>['pendingMeta']
				>
			) => {
				const { payload } = action.meta.arg;
				if (payload) {
					const { blockId, feedback, parentCommentId } = payload;

					if (blockId && state.data[blockId] && !parentCommentId) {
						const block = state.data[blockId];
						if (block) {
							(block as IFeedbackBlock).feedbacks = (block as IFeedbackBlock)
								.feedbacks
								? [...(block as IFeedbackBlock).feedbacks, feedback._id as string]
								: [feedback._id as string];
						}
					}
				}
			}
		);
		// REJECTED
		builder.addCase(
			addComment.rejected,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TAddCommentThunkArg>['rejectedMeta']
				>
			) => {
				const { payload } = action.meta.arg;
				if (payload) {
					const { blockId, feedback, parentCommentId } = payload;

					if (blockId && state.data[blockId] && !parentCommentId) {
						const block = state.data[blockId];
						if (block) {
							switch (block.blockType) {
								case 'CANVAS':
									(block as ICanvas).feedbacks = (
										block as ICanvas
									).feedbacks.filter((f) => f !== feedback._id);
									break;
								case 'IMAGE':
									(block as IImage).feedbacks = (
										block as IImage
									).feedbacks.filter((f) => f !== feedback._id);
									break;
								case 'THREE_D':
									(block as I3D).feedbacks = (block as I3D).feedbacks.filter(
										(f) => f !== feedback._id
									);
									break;
								case 'LINK':
									(block as ILink).feedbacks = (block as ILink).feedbacks.filter(
										(f) => f !== feedback._id
									);
									break;
								case 'FILE':
									(block as IFile).feedbacks = (block as IFile).feedbacks.filter(
										(f) => f !== feedback._id
									);
									break;
								default:
									break;
							}
						}
					}
				}
			}
		);

		/** ---- LOAD COMMENT ---- */
		// FULFILLED
		builder.addCase(
			loadComment.fulfilled,
			(
				state: IBlocksInitState,
				action: PayloadAction<TActionType<TLoadCommentFulfill, {}>['payload']>
			) => {
				const { blockId, comment } = action.payload;
				const block = state.data[blockId];

				if (blockId && block) {
					if (block) {
						switch (block.blockType) {
							case 'CANVAS':
								(block as ICanvas).feedbacks = (block as ICanvas).feedbacks
									? [...(block as ICanvas).feedbacks, comment._id as string]
									: [comment._id as string];
								break;
							case 'IMAGE':
								(block as IImage).feedbacks = (block as IImage).feedbacks
									? [...(block as IImage).feedbacks, comment._id as string]
									: [comment._id as string];
								break;
							case 'THREE_D':
								(block as I3D).feedbacks = (block as I3D).feedbacks
									? [...(block as I3D).feedbacks, comment._id as string]
									: [comment._id as string];
								break;
							case 'LINK':
								(block as ILink).feedbacks = (block as ILink).feedbacks
									? [...(block as ILink).feedbacks, comment._id as string]
									: [comment._id as string];
								break;
							case 'FILE':
								(block as IFile).feedbacks = (block as IFile).feedbacks
									? [...(block as IFile).feedbacks, comment._id as string]
									: [comment._id as string];
								break;
							default:
								break;
						}
					}
				}
			}
		);

		/** ---- DELETE COMMENT ---- */
		// PENDING
		builder.addCase(
			deleteComment.pending,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TDeleteCommentThunkArg>['pendingMeta']
				>
			) => {
				const { payload } = action.meta.arg;

				if (payload) {
					const { blockId, _id, parentCommentId } = payload;
					const block = state.data[blockId];
					if (blockId && block && !parentCommentId) {
						if (block) {
							switch (block.blockType) {
								case 'CANVAS':
									(block as ICanvas).feedbacks = (
										block as ICanvas
									).feedbacks.filter((f) => f !== _id);
									break;
								case 'IMAGE':
									(block as IImage).feedbacks = (
										block as IImage
									).feedbacks.filter((f) => f !== _id);
									break;
								case 'THREE_D':
									(block as I3D).feedbacks = (block as I3D).feedbacks.filter(
										(f) => f !== _id
									);
									break;
								case 'LINK':
									(block as ILink).feedbacks = (block as ILink).feedbacks.filter(
										(f) => f !== _id
									);
									break;
								case 'FILE':
									(block as IFile).feedbacks = (block as IFile).feedbacks.filter(
										(f) => f !== _id
									);
									break;
								default:
									break;
							}
						}
					}
				}
			}
		);

		// REJECTED
		builder.addCase(
			deleteComment.rejected,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TDeleteCommentThunkArg>['rejectedMeta']
				>
			) => {
				const { payload } = action.meta.arg;
				if (payload) {
					const { blockId, _id } = payload;
					const block = state.data[blockId];
					if (blockId && block) {
						if (block) {
							switch (block.blockType) {
								case 'CANVAS':
									(block as ICanvas).feedbacks = (block as ICanvas).feedbacks
										? [...(block as ICanvas).feedbacks, _id as string]
										: [_id as string];
									break;
								case 'IMAGE':
									(block as IImage).feedbacks = (block as IImage).feedbacks
										? [...(block as IImage).feedbacks, _id as string]
										: [_id as string];
									break;
								case 'THREE_D':
									(block as I3D).feedbacks = (block as I3D).feedbacks
										? [...(block as I3D).feedbacks, _id as string]
										: [_id as string];
									break;
								case 'LINK':
									(block as ILink).feedbacks = (block as ILink).feedbacks
										? [...(block as ILink).feedbacks, _id as string]
										: [_id as string];
									break;
								case 'FILE':
									(block as IFile).feedbacks = (block as IFile).feedbacks
										? [...(block as IFile).feedbacks, _id as string]
										: [_id as string];
									break;
								default:
									break;
							}
						}
					}
				}
			}
		);

		/** ---- ADD NODES ---- */
		// FULFILLED
		builder.addCase(
			addNodes.fulfilled,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					TActionType<{ nodes: INode[] | undefined; blockId: string }, {}>['payload'],
					string,
					TActionType<{}, TAddNodesArg>['fulfilledMeta']
				>
			) => {
				const { meta } = action;
				// after rtc fulfilled case will work as validation
				// since we won't be receiving our own change from db (bcoz lastUpdatedBy would be current user)
				// there could be scenes where we would lose data updated in pending case
				// so that's why we'll use fulfilled cases to validate the data
				const { data } = meta.arg;

				if (data?.nodes) {
					const { nodes, blockId } = data;
					const blockNodes = (state.data[blockId] as INodesBlock)?.nodes;
					if (blockNodes) {
						const overwrittenIds = [] as string[];

						nodes.forEach((n) => {
							if (!blockNodes.includes(n._id as string))
								overwrittenIds.push(n._id as string);
						});

						(state.data[blockId] as INodesBlock)!.nodes = [
							...new Set(blockNodes.concat(overwrittenIds))
						];
					}
					state.data[blockId]!.updatedAt = new Date().toISOString();
					state.error[blockId] = null;
				}
			}
		);
		// PENDING
		builder.addCase(
			addNodes.pending,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TAddNodesArg>['pendingMeta']
				>
			) => {
				const { data } = action.meta.arg;
				const { nodes, blockId } = data;
				const block = state.data[blockId] as IBlock;
				const nodeIds = nodes.map((x: INode) => x._id as string);

				(block as INodesBlock).nodes = (block as INodesBlock).nodes
					? (block as INodesBlock).nodes.concat(nodeIds)
					: nodeIds;
			}
		);
		// REJECTED
		builder.addCase(
			addNodes.rejected,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TAddNodesArg>['rejectedMeta']
				>
			) => {
				const { prevState, data } = action.meta.arg;
				const { prevBlock } = prevState;
				const { blockId } = data;
				state.data[blockId] = prevBlock;
			}
		);

		/** ---- EDIT NODES ---- */
		// FULFILLED
		builder.addCase(
			editNodes.fulfilled,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					TActionType<{ nodeIds: string[] | undefined; blockId: string }, {}>['payload']
				>
			) => {
				const { payload } = action;

				if (payload) {
					const { blockId } = payload;
					state.data[blockId]!.updatedAt = new Date().toISOString();
					state.error[blockId] = null;
				}
			}
		);

		/** ---- REMOVE IMAGE BACKGROUND ---- */
		// FULFILLED
		builder.addCase(
			removeImageBackground.fulfilled,
			(
				state: IBlocksInitState,
				action: PayloadAction<TActionType<{ blockId: string }, {}>['payload']>
			) => {
				const { payload } = action;

				if (payload) {
					const { blockId } = payload;
					state.data[blockId]!.updatedAt = new Date().toISOString();
					state.error[blockId] = null;
				}
			}
		);

		/** --- CREATE PHASE WITH EMPTY BLOCKS ---- */
		// FULFILLED- set the loading state
		builder.addCase(
			batchCreateGroupAndBlocks.fulfilled,
			(
				state: IBlocksInitState,
				action: PayloadAction<TActionType<TCreateStageWithBlocksFulfill, {}>['payload']>
			) => {
				const blocks = action.payload?.blocks;

				if (blocks) {
					blocks.forEach((block: IBlock) => {
						const blockId = block._id as string;
						if (block.blockType === 'LINK') {
							const subType = getFileLinkSubType((block as ILink).link, 'LINK');
							if (subType === 'NAYA') state.data[blockId] = block;
						}
						state.loading[blockId] = false;
						state.error[blockId] = null;
					});
				}
			}
		);
		// PENDING
		builder.addCase(
			batchCreateGroupAndBlocks.pending,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TBatchCreateGroupAndBlocks>['pendingMeta']
				>
			) => {
				const { blocks } = action.meta.arg;

				if (blocks) {
					blocks.forEach((block) => {
						const blockId = block._id as string;
						state.data[blockId] = block;
						state.loading[blockId] = true;
						state.error[blockId] = null;
					});
				}
			}
		);
		// REJECTED
		builder.addCase(
			batchCreateGroupAndBlocks.rejected,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TBatchCreateGroupAndBlocks>['rejectedMeta']
				>
			) => {
				const { blocks } = action.meta.arg;

				if (blocks) {
					blocks.forEach((block) => {
						const blockId = block._id as string;

						if (state.data[blockId]) {
							delete state.data[blockId];
						}

						state.error[blockId] = action.payload as string;
						state.loading[blockId] = false;
					});
				}
			}
		);
		// PENDING - update block favorite
		builder.addCase(
			favoriteBlock.pending,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TEditBlockArgs>['pendingMeta']
				>
			) => {
				const { block } = action.meta.arg.data;

				const data: IBlock = {
					...state.data[block._id as string]!,
					...block!,
					updatedAt: new Date().toISOString()
				};
				state.data[block._id as string] = data;
				// state.loading[block._id as string] = true;
				// reset the error just in case previous call has some error
				state.error[block._id as string] = '';
			}
		);
		// FULFILLED
		builder.addCase(
			favoriteBlock.fulfilled,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					TActionType<TEditBlockArgs, {}>['payload'],
					string,
					TActionType<{}, TEditBlockArgs>['fulfilledMeta']
				>
			) => {
				const { data } = action.meta.arg;

				if (data) {
					const { block } = data;
					const reduxBlock = state.data[block._id as string] as IBlock;
					if (
						new Date(block.updatedAt as string) >
						new Date(reduxBlock.updatedAt as string)
					)
						state.data[block._id as string] = { ...reduxBlock, ...block };
				}
			}
		);
		// REJECTED
		builder.addCase(
			favoriteBlock.rejected,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TEditBlockArgs>['rejectedMeta']
				>
			) => {
				const { prevState, data } = action.meta.arg;
				const { prevStateBlock } = prevState;
				const { block } = data;
				state.data[block._id as string] = prevStateBlock;
			}
		);

		// PENDING - add note
		builder.addCase(
			addNote.fulfilled,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					TActionType<INote, {}>['payload'],
					string,
					TActionType<{}, TAddNoteArg>['fulfilledMeta']
				>
			) => {
				const { payload } = action.meta.arg;
				if (payload) {
					const { _id, parentId: blockId } = payload;

					if (state.data[blockId as string]) {
						const block = state.data[blockId as string] as IBlock;
						const blockNotes = block.notes as string[];

						if (!blockNotes.includes(_id as string)) {
							state.data[blockId as string]!.notes!.push(_id as string);
						}
					}

					state.error[blockId as string] = null;
				}
			}
		);
		builder.addCase(
			addNote.pending,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TAddNoteArg>['pendingMeta']
				>
			) => {
				const { payload } = action.meta.arg;
				if (payload) {
					const { _id: noteId, parentId: blockId, parentType } = payload;

					if (parentType === 'BLOCK') {
						if (state.data[blockId as string]) {
							const block = state.data[blockId as string] as IBlock;

							const notes = block?.notes
								? [...block.notes, noteId as string]
								: [noteId as string];
							block.notes = notes;
						}
					}

					state.error[blockId as string] = null;
				}
			}
		);
		// REJECTED
		builder.addCase(
			addNote.rejected,
			(
				state: IBlocksInitState,
				action: PayloadAction<unknown, string, TActionType<{}, TAddNoteArg>['rejectedMeta']>
			) => {
				const { payload } = action.meta.arg;
				const { payload: error } = action;

				if (payload) {
					const { _id: noteId, parentId, parentType } = payload;

					if (parentType === 'BLOCK') {
						if (state.data[parentId as string]) {
							const block = state.data[parentId as string] as IBlock;
							block.notes = block.notes?.filter((nid) => nid !== noteId);
						}

						state.loading[parentId as string] = false;
						state.error[parentId as string] = error as string;
					}
				}
			}
		);

		// PENDING - delete note
		builder.addCase(
			deleteNote.fulfilled,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					TActionType<{ noteId: string; parentId: string }, {}>['payload'],
					string,
					TActionType<{}, TDeleteNoteArg>['fulfilledMeta']
				>
			) => {
				const { payload } = action.meta.arg;

				if (payload) {
					const { noteId, parentId: blockId, parentType } = payload;

					if (parentType === 'BLOCK') {
						if (state.data[blockId as string]) {
							const block = state.data[blockId as string] as IBlock;
							if (block.notes!.includes(noteId))
								block.notes = block.notes?.filter((nid) => nid !== noteId);
						}

						state.error[blockId as string] = null;
					}
				}
			}
		);
		builder.addCase(
			deleteNote.pending,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TDeleteNoteArg>['pendingMeta']
				>
			) => {
				const { payload } = action.meta.arg;

				if (payload) {
					const { noteId, parentId: blockId } = payload;

					if (state.data[blockId as string]) {
						const block = state.data[blockId as string] as IBlock;
						block.notes = block.notes?.filter((nid) => nid !== noteId);
					}

					state.error[blockId as string] = null;
				}
			}
		);
		// REJECTED
		builder.addCase(
			deleteNote.rejected,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TDeleteNoteArg>['rejectedMeta']
				>
			) => {
				const { payload } = action.meta.arg;
				const { payload: error } = action;

				if (payload) {
					const { noteId, parentId: blockId } = payload;

					if (state.data[blockId as string]) {
						const block = state.data[blockId as string] as IBlock;
						const notes = block?.notes
							? [...block.notes, noteId as string]
							: [noteId as string];
						block.notes = notes;
					}

					state.loading[blockId as string] = false;
					state.error[blockId as string] = error as string;
				}
			}
		);

		/** ---- DUPLICATE PROJECT OPTIMISTICALLY---- */

		// FULFILLED
		builder.addCase(
			duplicateProjectOptimistically.fulfilled,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					TActionType<TDuplicateProjectOptimisticallyThunkArg['payload'], {}>['payload'],
					string,
					TActionType<{}, TDuplicateProjectOptimisticallyThunkArg>['fulfilledMeta']
				>
			) => {
				const { clonedItems } = action.meta.arg.payload;
				const { blocks: clonedBlocks } = clonedItems;

				if (clonedBlocks) {
					clonedBlocks.forEach((block: IBlock) => {
						const blockId = block._id as string;

						state.loading[blockId] = false;
						state.error[blockId] = null;
					});
				}
			}
		);
		// PENDING
		builder.addCase(
			duplicateProjectOptimistically.pending,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TDuplicateProjectOptimisticallyThunkArg>['pendingMeta']
				>
			) => {
				const { payload } = action.meta.arg;

				if (payload) {
					const { clonedItems } = payload;
					const { blocks } = clonedItems;

					blocks.forEach((block: IBlock) => {
						const blockId = block._id as string;

						state.data[blockId] = block;
						state.loading[blockId] = true;
						state.error[blockId] = null;
					});
				}
			}
		);
		// REJECTED
		builder.addCase(
			duplicateProjectOptimistically.rejected,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TDuplicateProjectOptimisticallyThunkArg>['rejectedMeta']
				>
			) => {
				const { payload } = action.meta.arg;
				const { payload: error } = action;

				if (payload) {
					const { clonedItems } = payload;
					const { blocks } = clonedItems;

					blocks.forEach((block: IBlock) => {
						const blockId = block._id as string;

						if (state.data[blockId]) delete state.data[blockId];
						state.loading[blockId] = false;
						state.error[blockId] = error as string;
					});
				}
			}
		);

		/** ---- REORDER BLOCKS ---- */
		// PENDING
		builder.addCase(
			reorderBlocks.pending,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TReorderBlocksThunkArg>['pendingMeta']
				>
			) => {
				const { payload } = action.meta.arg;
				// type will be PHASE when a nested phase is reordered
				if (payload.type !== 'BLOCK') return;
				if (payload) {
					const { sourcePhaseId, destinationPhaseId, reorderedBlock } = payload;
					if (sourcePhaseId !== destinationPhaseId) {
						const { blockId, newStageId } = reorderedBlock;
						state.data[blockId]!.parentId = newStageId;
					}
				}
			}
		);
		// REJECTED
		builder.addCase(
			reorderBlocks.rejected,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TReorderBlocksThunkArg>['rejectedMeta']
				>
			) => {
				const { payload } = action.meta.arg;
				if (payload.type !== 'BLOCK') return;
				const { payload: error } = action;

				if (payload) {
					const { sourcePhaseId, destinationPhaseId, reorderedBlock } = payload;
					if (sourcePhaseId !== destinationPhaseId) {
						const { blockId } = reorderedBlock;
						state.data[blockId]!.parentId = sourcePhaseId;
						state.error[blockId] = error as string;
					}
				}
			}
		);

		/** ---- CREATE NEW STAGE FROM BLOCK ---- */
		// PENDING
		builder.addCase(
			createNewStageFromOrphanBlock.pending,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TCreateNewStageFromBlockThunkArg>['pendingMeta']
				>
			) => {
				const { payload } = action.meta.arg;

				if (payload) {
					const { stageData, blockIds } = payload;

					blockIds.forEach((blockId) => {
						state.data[blockId]!.parentId = stageData._id as string;
						state.error[blockId] = null;
					});
				}
			}
		);
		// REJECTED
		builder.addCase(
			createNewStageFromOrphanBlock.rejected,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TCreateNewStageFromBlockThunkArg>['rejectedMeta']
				>
			) => {
				const { payload, prevState } = action.meta.arg;
				const { payload: error } = action;

				state.data = prevState.prevBlocks;
				if (payload) {
					const { blockIds } = payload;

					blockIds.forEach((blockId) => {
						// state.loading[blockId] = false;
						state.error[blockId] = error as string;
					});
				}
			}
		);

		// /** ---- UNDO DELETE OPTIMISTICALLY---- */
		builder.addCase(
			undoDelete.pending,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TUndoRedoArgs>['pendingMeta']
				>
			) => {
				const { blocks } = action.meta.arg.data;
				if (blocks.length > 0) {
					blocks.forEach((block) => {
						const blockId = block._id.toString();
						state.data[blockId] = block;
					});
				}
			}
		);

		// FULFILLED
		builder.addCase(
			undoDelete.fulfilled,
			(
				state: IBlocksInitState,
				action: PayloadAction<TActionType<TUndoDeleteFufill, {}>['payload']>
			) => {
				const { payload } = action;
				if (payload) {
					const { blocks } = payload;
					if (blocks.length > 0) {
						blocks.forEach((block) => {
							const blockId = block._id.toString();
							state.data[blockId] = block;
						});
					}
				}
			}
		);

		/** ----- BULK UPDATE BLOCKS ------- */
		// PENDING
		builder.addCase(
			bulkUpdateBlocks.pending,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TBulkUpdateBlockArgs>['pendingMeta']
				>
			) => {
				const { blocks = [] } = action.meta.arg.data;
				for (let i = 0; i < blocks.length; i++) {
					const blockId = blocks[i]?.blockId;
					if (blockId) {
						const data = {
							...state.data[blockId],
							...blocks[i]?.updates,
							updatedAt: new Date().toISOString()
						};
						state.data[blockId] = data as IBlock;
						state.error[blockId] = '';
					}
				}
			}
		);
		// REJECTED
		builder.addCase(
			bulkUpdateBlocks.rejected,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TBulkUpdateBlockArgs>['rejectedMeta']
				>
			) => {
				const { blocks } = action.meta.arg.prevState;
				for (let i = 0; i < blocks.length; i++) {
					const blockId = (blocks[i] as IBlock)._id.toString();
					state.data[blockId] = blocks[i] as IBlock;
					state.error[blockId] = action.payload as string;
				}
			}
		);

		// // REJECTED - revert the block from redux if action fails
		builder.addCase(
			undoDelete.rejected,
			(
				state: IBlocksInitState,
				action: PayloadAction<
					unknown,
					string,
					TActionType<{}, TUndoRedoArgs>['rejectedMeta']
				>
			) => {
				const { prevStateBlocks } = action.meta.arg.prevState;
				state.data = prevStateBlocks;
			}
		);

		/** ---- DELETE STAGE ---- */
		// FULFILLED
		builder.addCase(
			deleteStage.fulfilled,
			(
				state: IBlocksInitState,
				action: PayloadAction<TActionType<TDeleteGroupThunkArg['payload'], {}>['payload']>
			) => {
				const { childrens } = action.payload;

				const nestedGroupIds: Array<string> = [];

				childrens?.forEach((id) => {
					const isBlock = state.data[id];

					if (isBlock) {
						state.data[id]!.isVisible = false;
						state.loading[id] = false;
						state.error[id] = null;
					} else nestedGroupIds.push(id);
				});

				if (nestedGroupIds.length) {
					const allBlockIds = Object.keys(state.data);
					allBlockIds?.forEach((id) => {
						if (nestedGroupIds.includes(state.data[id]!.parentId))
							state.data[id]!.isVisible = false;
						state.loading[id] = false;
						state.error[id] = null;
					});
				}
			}
		);

		/** --- UPDATE BLOCK when notif related to Block due date get marked comp/incomp --- */
		builder.addMatcher(
			notificationSlice.endpoints.toggleNotifComplete.matchFulfilled,
			(state: any, action: any) => {
				const { eventType, data, sent } = action.payload.notif;

				if (data.blockId && eventType === EEvent.BLOCK_DUE_DATE) {
					state.data[data.blockId as string].isCompleted = sent.inApp;
					state.loading[data.blockId as string] = false;
					state.error[data.blockId as string] = '';
				}
			}
		);

		/** --- UPDATE BLOCK when SENT notif related to Block due date get marked comp/incomp --- */
		builder.addMatcher(
			sentNotificationSlice.endpoints.toggleSentNotifComplete.matchPending,
			(state: any, action: any) => {
				const { isComplete, blockId, eventType } = action.meta.arg.originalArgs;
				if (blockId && eventType === EEvent.BLOCK_DUE_DATE) {
					state.data[blockId as string].isCompleted = isComplete;
					state.loading[blockId as string] = false;
					state.error[blockId as string] = '';
				}
			}
		);
	}
});

export const {
	loadBlocks,
	addBlock,
	editBlockById,
	deleteBlockById,
	editBlocksById,
	unloadBlocks,
	addTempNotesOnBlocks,
	removeTempNotesOnBlocks
} = blocksSlice.actions;

export default blocksSlice.reducer;
