import { useState } from 'react';
import { DropDown, IconWithTooltip, UserProfileIcon } from '@naya_studio/radix-ui';
import { ISocketUser } from 'src/redux/hooks/useSocketUsers';
import classNames from 'classnames';
import { capitalize } from 'lodash';

type TSocketUserProps = {
	socketUsers: ISocketUser[];
};

/**
 * Component to render users connected to Socket in Navbar
 * @returns users connected to Socket
 */
const SocketUsers = ({ socketUsers }: TSocketUserProps) => {
	// State to check if socket users dropdown is active
	const [isDropdownActive, setIsDropdownActive] = useState(false);
	const visibleUsers = 3;

	/**
	 * Function to check is user color is a of light luminous
	 * @param color color assigned to user
	 * @returns boolean
	 */
	const isLightColor = (color: string) => {
		const hexColor = color.replace('#', '');

		// Convert hex color to RGB
		const r = parseInt(hexColor.substr(0, 2), 16);
		const g = parseInt(hexColor.substr(2, 2), 16);
		const b = parseInt(hexColor.substr(4, 2), 16);

		// Calculate luminance
		const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

		// Check if luminance is above a threshold
		return luminance > 160;
	};

	/**
	 * Function to render socket users as dropdown item
	 * @returns socket users after starting form 4th position
	 */
	const renderExtraUsersAsItems = () =>
		socketUsers.slice(visibleUsers).map((awareness) => (
			<DropDown.DropDownItem className="tw-w-full tw-h-full">
				<div
					role="presentation"
					className="tw-flex tw-justify-start tw-items-center tw-gap-1 tw-cursor-pointer"
				>
					<UserProfileIcon
						url={awareness.profile}
						displayName={awareness.displayName}
						color={awareness.color}
						className="rtc-socket-user tw-w-8 tw-h-8 "
						style={{
							border: `1px solid ${awareness.profile ? awareness.color : '#4f00c1'}`
						}}
					/>

					<IconWithTooltip tooltipText={awareness.userName} side="bottom">
						<div
							className="kebab-items tw-text-xs tw-font-randRegular 
							tw-leading-6 tw-pt-1 tw-pb-1 tw-px-2 tw-cursor-pointer tw-flex-1 tw-truncate tw-max-w-[170px]"
						>
							{awareness.userName}
						</div>
					</IconWithTooltip>
				</div>
			</DropDown.DropDownItem>
		));

	/**
	 * Function to render a dropdown for extra socket users
	 * @returns socket user dropdown
	 */
	const renderExtraUserDropdown = () => (
		<DropDown modal={false} onOpenChange={(value: boolean) => setIsDropdownActive(value)}>
			<DropDown.DropDownTrigger
				className={`socket-users-dropdown tw-flex tw-justify-center tw-items-center tw-font-randMedium tw-text-sm ${classNames(
					{
						active: isDropdownActive
					}
				)}`}
			>
				+{socketUsers.length - visibleUsers}
			</DropDown.DropDownTrigger>

			<DropDown.DropDownContent
				classNames="socket-users-content dropdown-content tw-rounded-2xl 
			tw-bg-white tw-p-2 tw-right-0 tw-flex tw-justify-center tw-items-center tw-gap-1 tw-flex-col"
			>
				{renderExtraUsersAsItems()}
			</DropDown.DropDownContent>
		</DropDown>
	);

	return (
		<div className="socket-users tw-flex tw-justify-end tw-items-center">
			{socketUsers.slice(0, visibleUsers).map((awareness: ISocketUser, index) => (
				<div
					className={`tw-flex ${index > 0 ? 'overlap-socket-user' : ''}`}
					key={awareness.userId}
				>
					<IconWithTooltip
						tooltipText={capitalize(awareness.userName)}
						side="bottom"
						classNames="tw-flex"
					>
						<UserProfileIcon
							url={awareness.profile}
							displayName={awareness.displayName}
							color={awareness.color}
							className={`rtc-socket-user tw-cursor-pointer ${classNames({
								'dark-text': isLightColor(awareness.color)
							})}`}
							style={{
								border:
									index === 0
										? `2px solid ${
												awareness.profile ? awareness.color : '#4f00c1'
										  }`
										: 'none',
								outline: index === 0 ? 'none' : `2px solid white`
							}}
						/>
					</IconWithTooltip>
				</div>
			))}
			{socketUsers.length > visibleUsers && renderExtraUserDropdown()}
		</div>
	);
};

export default SocketUsers;
