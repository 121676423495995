/* eslint-disable import/no-mutable-exports */
export let PROJECTS_API_URL: string;
export let ACCOUNTS_API_URL: string;
export let AUTH_API_URL: string;
export let NOTIFICATION_API_URL: string;
export let API_GATEWAY: string;
export let ESTIMATE_ENDPOINT: string;
export const OLD_API: string = 'https://api.naya.studio';
export let APP_URL: string;
export let REALTIME_API_WS: string;
export let FILE_CONVERTER_API: string;
export let SUPER_POWER_API_URL: string;

if (window.__RUNTIME_CONFIG__.REACT_APP_STAGING === 'true') {
	PROJECTS_API_URL = 'https://staging-api.naya-app.com';
	ACCOUNTS_API_URL = 'https://staging-api.naya-app.com';
	NOTIFICATION_API_URL = 'https://staging-api.naya-app.com';
	AUTH_API_URL = 'https://staging-api.naya-app.com';
	API_GATEWAY = 'https://staging-api.naya-app.com';
	APP_URL = 'https://staging.naya-app.com/';
	ESTIMATE_ENDPOINT = 'https://estimate-api-staging-ybbseckska-ue.a.run.app/estimate';
	REALTIME_API_WS = 'wss://multiplayer-staging-2ba5cho72q-ue.a.run.app';
	FILE_CONVERTER_API = 'https://naya-file-converter-staging-2ba5cho72q-ue.a.run.app';
	SUPER_POWER_API_URL = 'https://superpower-api-staging-2ba5cho72q-ue.a.run.app';
} else if (window.__RUNTIME_CONFIG__.REACT_APP_STAGING_CUSTOM === 'true') {
	PROJECTS_API_URL =
		window.__RUNTIME_CONFIG__.REACT_APP_PROJECTS_API_URL || 'https://staging-api.naya-app.com';
	ACCOUNTS_API_URL =
		window.__RUNTIME_CONFIG__.REACT_APP_ACCOUNTS_API_URL || 'https://staging-api.naya-app.com';
	NOTIFICATION_API_URL =
		window.__RUNTIME_CONFIG__.REACT_APP_NOTIFICATIONS_API_URL ||
		'https://staging-api.naya-app.com';
	AUTH_API_URL =
		window.__RUNTIME_CONFIG__.REACT_APP_AUTH_API_URL || 'https://staging-api.naya-app.com';
	API_GATEWAY = 'https://staging-api.naya-app.com';
	ESTIMATE_ENDPOINT = 'https://estimate-api-staging-ybbseckska-ue.a.run.app/estimate';
	REALTIME_API_WS =
		window.__RUNTIME_CONFIG__.REACT_APP_RTC_API_URL ||
		'wss://multiplayer-staging-2ba5cho72q-ue.a.run.app';
	FILE_CONVERTER_API = 'https://naya-file-converter-staging-2ba5cho72q-ue.a.run.app';
	SUPER_POWER_API_URL = 'https://superpower-api-staging-2ba5cho72q-ue.a.run.app';
} else if (process.env.REACT_APP_PRODUCTION) {
	PROJECTS_API_URL = 'https://api2.naya.studio';
	ACCOUNTS_API_URL = 'https://api2.naya.studio';
	NOTIFICATION_API_URL = 'https://api2.naya.studio';
	AUTH_API_URL = 'https://api2.naya.studio';
	API_GATEWAY = 'https://api2.naya.studio';
	ESTIMATE_ENDPOINT = 'https://estimate-api-production-ybbseckska-ue.a.run.app/estimate';
	APP_URL = 'https://naya.studio/';
	FILE_CONVERTER_API = 'https://naya-file-converter-prod-awjyehnnha-ue.a.run.app';
	REALTIME_API_WS = 'wss://multiplayer-api-production-awjyehnnha-ue.a.run.app';
	SUPER_POWER_API_URL = 'https://superpower-api-prod-awjyehnnha-ue.a.run.app';
} else if (process.env.REACT_APP_PRODUCTION_AWS) {
	PROJECTS_API_URL = process.env.REACT_APP_PROJECTS_API_URL || 'https://project-api.naya.studio';
	ACCOUNTS_API_URL = process.env.REACT_APP_ACCOUNTS_API_URL || 'https://account-api.naya.studio';
	NOTIFICATION_API_URL =
		process.env.REACT_APP_NOTIFICATIONS_API_URL || 'https://notifications-api.naya.studio';
	AUTH_API_URL = process.env.REACT_APP_AUTH_API_URL || 'https://auth-api.naya.studio';
	API_GATEWAY = 'https://api2.naya.studio';
	ESTIMATE_ENDPOINT = 'https://estimate-api-production-ybbseckska-ue.a.run.app/estimate';
	FILE_CONVERTER_API = 'https://naya-file-converter-prod-awjyehnnha-ue.a.run.app';
	REALTIME_API_WS = 'wss://multiplayer-api-production-awjyehnnha-ue.a.run.app';
	SUPER_POWER_API_URL = 'https://superpower-api-staging-2ba5cho72q-ue.a.run.app';
} else if (process.env.REACT_APP_TESTING) {
	PROJECTS_API_URL = 'https://staging-api.naya-app.com';
	ACCOUNTS_API_URL = 'https://staging-api.naya-app.com';
	NOTIFICATION_API_URL = 'https://staging-api.naya-app.com';
	AUTH_API_URL = 'https://auth-api-testing-2ba5cho72q-ue.a.run.app';
	API_GATEWAY = 'https://staging-api.naya-app.com';
	ESTIMATE_ENDPOINT = 'https://estimate-api-staging-ybbseckska-ue.a.run.app/estimate';
	APP_URL = 'https://naya.studio/';
	REALTIME_API_WS = 'wss://multiplayer-staging-2ba5cho72q-ue.a.run.app';
	FILE_CONVERTER_API = 'https://naya-file-converter-staging-2ba5cho72q-ue.a.run.app';
	SUPER_POWER_API_URL = 'https://superpower-api-staging-2ba5cho72q-ue.a.run.app';
} else {
	PROJECTS_API_URL = 'http://localhost:3010';
	ACCOUNTS_API_URL = 'http://localhost:3011';
	NOTIFICATION_API_URL = 'http://localhost:3012';
	AUTH_API_URL = 'http://localhost:3015';
	API_GATEWAY = 'http://localhost:8080';
	ESTIMATE_ENDPOINT = 'https://estimate-api-production-ybbseckska-ue.a.run.app/estimate';
	APP_URL = 'http://localhost:3000/';
	REALTIME_API_WS = 'ws://localhost:3013';
	FILE_CONVERTER_API = 'http://localhost:3016';
	SUPER_POWER_API_URL = 'https://superpower-api-staging-2ba5cho72q-ue.a.run.app';
}
