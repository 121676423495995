import { IFeedback, TAnswer, TUserAndRole } from '@naya_studio/types';
import { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { addAnswerForActionItem } from 'src/redux/reduxActions/feedbacks';
import { getAllUsersBasedOnIds } from 'src/redux/reduxActions/util';
import { TAddAnswerForActionItemThunkArg } from 'src/types/argTypes';
import { Button, FeedbackInput, UserT } from '@naya_studio/radix-ui';
import { ISnackBar, ReduxState } from 'src/redux/reducers/root.types';
import { useParams } from 'react-router';
import { PathParamsType } from 'src/types/pathParams';
import mongoose from 'mongoose';
import { store } from 'src';
import { CustomDispatch } from 'src/redux/actions/types';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';
import useFeedbackActions from 'src/util/feedback/useFeedbackActions';
import useUser from 'src/redux/hooks/user';
import { getFormattedUsersData } from '../util';

// Props for QNA's answer component
export type NewAnsweProps = {
	setShowResults: (val: boolean) => void;
	data: IFeedback;
	actionItemAssignees: string[];
	setActionItemAsignees: (userIds: string[]) => void;
	// eslint-disable-next-line
	answers: TAnswer[];
};

// New answer component for QNA
export const NewAnswer = ({
	setShowResults,
	data,
	actionItemAssignees,
	setActionItemAsignees
}: NewAnsweProps) => {
	const [replyText, setReplyText] = useState('');

	// extracting ids from url
	const { canvasId: blockId } = useParams<PathParamsType>();

	// extract current user
	const { user } = useUser();

	// extract all blocks from redux
	const allBlocks = useSelector((state) => (state as ReduxState).blocks.data, shallowEqual);

	// extract all user role objs in current block
	const userRoles = allBlocks[blockId]?.users as TUserAndRole[];

	// extract all populated users based on ids
	const allUsers = getAllUsersBasedOnIds(userRoles.map((x: TUserAndRole) => x.user as string));

	const { extractTextFromHTML } = useFeedbackActions();

	const dispatch = useDispatch();

	// send out an API call to save reply
	const saveAnswer = () => {
		const answerToSave: TAnswer = {
			_id: new mongoose.Types.ObjectId().toString() as string,
			answer: replyText,
			answeredBy: user._id as string,
			answeredAt: new Date()
		};

		const apiPayload: TAddAnswerForActionItemThunkArg = {
			payload: {
				feedbackId: data._id as string,
				answer: answerToSave
			}
		};

		(store.dispatch as CustomDispatch)(addAnswerForActionItem(apiPayload))
			.unwrap()
			.then(() => {})
			.catch((error) => {
				const snackbarPayload: ISnackBar = {
					text: 'Failed to post answer to action item.',
					show: true,
					type: 'ERROR'
				};

				addSnackbar(snackbarPayload);
				removeSnackbar(2000);

				console.error('Error :', error);
			});

		dispatch({
			type: 'MARK_FB_NOTIF_COMP',
			payload: { markFeedbackAsComplete: data._id, isCompleted: true }
		});
	};

	// function to modify action item asigneed
	const onTagUser = (selectedIds: string[]) => {
		const tempList = actionItemAssignees;
		for (let i = 0; i < selectedIds.length; i++) {
			if (!actionItemAssignees.includes(selectedIds[i] as string)) {
				tempList.push(selectedIds[i] as string);
			}
		}
		setActionItemAsignees(tempList);
	};

	return (
		<>
			{/* rendering input for new reply */}
			<FeedbackInput
				text={replyText}
				variant="BORDERED"
				onSubmit={saveAnswer}
				onEsc={() => {
					setReplyText('');
				}}
				id="new"
				className=" tw-mb-4 tw-pl-2"
				placeHolder="Short answer text"
				onTagUser={onTagUser}
				users={getFormattedUsersData(allUsers, false).blockUsers as UserT[]}
				currentUser={getFormattedUsersData(allUsers, false).currentUser}
				onInputChange={(value: string) => setReplyText(value)}
				onShowReplyBtn={() => {}}
				key="new"
			/>
			{/* action buttons for showing results, post reply and cancel */}
			<div className="tw-flex tw-justify-between">
				<Button
					variant="SECONDARY"
					text="View results"
					disabled={false}
					onBtnClick={() => {
						setShowResults(true);
					}}
				/>
				<div className="tw-flex">
					<Button
						variant="SECONDARY-GREY"
						text="Cancel"
						className="tw-mr-2"
						disabled={false}
						onBtnClick={() => {
							setReplyText('');
						}}
					/>
					<Button
						variant="PRIMARY"
						text="Post"
						disabled={extractTextFromHTML(replyText).length === 0}
						onBtnClick={saveAnswer}
					/>
				</div>
			</div>
		</>
	);
};
