import {
	ICanvas,
	IProject,
	IUser,
	INode,
	IComment,
	IUserDetailReact,
	IUserGroup,
	IGroup,
	IImage,
	I3D,
	IPdf,
	IBlock,
	IFile,
	ILink,
	IVideo,
	IFeedback,
	EFeedbackType,
	INote,
	IProjectGroup,
	IObserver
} from '@naya_studio/types';
import { Action } from 'redux';
import { ObjectId } from 'mongoose';
import { PrototypeFormData } from 'src/features/components/homebase/prototyping/prototyping.types';
import { ReduxLoading } from './loader/loader.types';
import { ITheme } from './theme/theme';
import { IUploadingStatus } from './uploadingStatus/uploadingStatus';
import { EstimateAIData } from './estimationAI/estimationAI';
import { CommunityAIData } from './communityAI/communityAI';
import { IPredictionData } from './textToImage/textToImage';

export interface IActionButtonData {
	/**
	 * The name or label of the button.
	 */
	buttonData: string | JSX.Element;

	/**
	 * The function to be executed when the button is clicked.
	 */
	onClick: () => void;

	/**
	 * The CSS class name(s) to be applied to the button.
	 */
	className?: string;

	/**
	 * Determines whether the button should be displayed.
	 */
	show: boolean;
}

export interface IAction<T, P = any> extends Action {
	type: T;
	payload?: P;
}

export interface IOpenComment {
	commentId: string;
	searchVal: string;
	tab: string;
	markFeedbackAsComplete: string;
	isCompleted: boolean;
}

export interface ISearchComment {
	keyword: string;
}

export interface ISnackBar {
	text?: string | JSX.Element;
	type?: 'LOADER' | 'NORMAL' | 'ERROR';
	loaderColor?: string | undefined;
	show?: boolean;
	actionButtonData?: IActionButtonData[] | undefined;
}
export interface IGroupsState {
	[key: string]: IGroup;
}

export interface ICanvasesState {
	[key: string]: ICanvas;
}

export interface INodesState {
	[key: string]: INode;
}

export type OrderDetailsType = {
	items: [
		{
			materialId: string;
			modelId: string;
			quantity: number;
		}
	];
	color: string;
	material: string;
	finish: string;
	phoneNumber: string;
	dimension: { [key: string]: string };
	price: number;
	city: string;
	country: string;
	firstName: string;
	zipCode: string;
	address1: string;
	lastName: string;
	countryCode: string;
	shippingPrice: number;
};

export interface IPrototyping {
	uploadedModel: { [key: string]: any };
	isUploading: boolean;
	isOrderFailed: boolean;
	isReadyForOrder: boolean;
	isOrderPlaced: boolean;
	orderDetails: OrderDetailsType;
	isUploaded: boolean;
	file: { url: string; extension: string };
	error: { isError: boolean; msg: string };
	color: number;
	placedOrderDetails: [{ [key: string]: any }] | null;
	uploadedFrom: string; // gives the url from where the model is uploaded
	currentLocation: string; // stores the current page url
	goToLocation: string; // stores the last page url when user switches to other tab
	formData: PrototypeFormData;
	fetchOrders: boolean;
}

export interface IUserInitState {
	data: IUser;
	loading: boolean;
	error: string | null;
}

export interface IGroupDetailInitState {
	data: IProjectGroup;
	loading: boolean;
	error: string | null;
}

export interface INodesInitState {
	data: {
		[key: string]: INode;
	};
	loading: { [key: string]: boolean };
	error: { [key: string]: string | null };
}

export interface IBlocksInitState {
	data: {
		[key: string]: ICanvas | IImage | IPdf | I3D | ILink | IVideo | IFile | IBlock;
	};
	loading: { [key: string]: boolean };
	error: { [key: string]: string | null };
}

export type IRestrictedAccess = {
	restrictedAccess?: {
		blocks: string[];
		stages: string[];
	};
};

/**
 * Project Initial State Type
 */
export type IProjectInitState = {
	data: {
		[key: string]: IProject & IRestrictedAccess;
	};
	loading: { [key: string]: boolean };
	error: { [key: string]: string | null };
};

/**
 * Project Initial State Type
 */
export type IProjectUsersInitState = {
	data: IUser[];
	loading: boolean;
	error: string | null;
};

/**
 * Note Redux State Type
 */
export type INotesInitState = {
	data: {
		[key: string]: INote;
	};
	loading: { [key: string]: boolean }; // key is noteid
	error: { [key: string]: string | null };
};

/**
 * Stage Redux State Type
 */
export type IGroupsInitState = {
	data: {
		[key: string]: IGroup;
	};
	loading: { [key: string]: boolean }; // key is stageid
	error: { [key: string]: string | null };
};

/**
 * New Comment Redux State Type
 */
export type INewCommentInitState = {
	data: {
		initialComment: IFeedback | null;
		initialType: keyof typeof EFeedbackType | null;
	};
	loading: boolean;
	error: string | null;
};

export interface ReduxState {
	user: IUserInitState;
	aiUser: IUserInitState;
	integrations: TIntegrationState;
	userGroup: IUserGroup;
	projects: IProjectInitState;
	loader: ReduxLoading;
	theme: ITheme;
	openComment: IOpenComment;
	newComment: INewCommentInitState;
	searchComment: ISearchComment;
	uploadingStatus: IUploadingStatus;
	projectUsers: IProjectUsersInitState;
	estimateAI: EstimateAIData;
	communityAI: CommunityAIData;
	brainTool: IPredictionData;
	snackBar: ISnackBar;
	stages: IGroupsInitState;
	canvases: ICanvasesState;
	prototyping: IPrototyping;
	nodes: INodesInitState;
	blocks: IBlocksInitState;
	feedbacks: IFeedBackInitState;
	notes: INotesInitState;
	sentNotifications: any;
	projectGroup: IGroupDetailInitState;
	notifications: any; // stored using RTKQ whose structure is different and it comes by default
	search: any; // stored using RTKQ whose structure is different and it comes by default
}

export type ReduxStateUnion = ReduxMongoStateUnion | ReduxLoading;

export type ReduxMongoStateUnion = IUser | ICanvas | IProject;

export enum UserGroupActionTypes {
	LOAD_USER_GROUP = 'LOAD_USER_GROUP',
	EDIT_USER_GROUP = 'EDIT_USER_GROUP',
	RESET_USER_GROUP = 'RESET_USER_GROUP'
}

/**
 * All the actions available to the Project Reducer
 */
export enum ProjectActionTypes {
	LOAD_PROJECT = 'LOAD_PROJECT',
	EDIT_PROJECT = 'EDIT_PROJECT',
	EDIT_PROJECT_DETAILS = 'EDIT_PROJECT_DETAILS',
	UNLOAD_PROJECT = 'UNLOAD_PROJECT',
	ADD_NODES = 'ADD_NODES'
}

/**
 * All the actions available to the Canvas Reducer
 */
export enum CanvasActionTypes {
	ADD_NODES = 'ADD_NODES',
	DELETE_NODES = 'DELETE_NODES',
	ADD_CANVASES = 'ADD_CANVASES'
}

/**
 * All the actions available to the Node Reducer
 */
export enum NodeActionTypes {
	ADD_NODES = 'ADD_NODES',
	EDIT_NODES = 'EDIT_NODES',
	DELETE_NODES = 'DELETE_NODES'
}

export enum ProjectUserActionTypes {
	LOAD_PROJECT_USERS = 'LOAD_PROJECT_USERS'
}
/**
 * All the actions available to the Template Reducer
 */
export enum TemplateActionTypes {
	LOAD_TEMPLATES = 'LOAD_TEMPLATES'
}

/**
 * All the actions available to the Project Reducer
 */
export enum ThemeActionTypes {
	LOAD_THEME = 'LOAD_THEME',
	EDIT_THEME = 'EDIT_THEME'
}

/**
 * All the actions available to the actionst Reducer
 */
export enum UndoRedoActionTypes {
	ADD_ACTION = 'ADD_ACTION',
	ADD_REDO_ACTION = 'ADD_REDO_ACTION',
	EMPTY_REDO_ACTIONS = 'EMPTY_REDO_ACTIONS'
}

export enum OpenCommentTypes {
	OPEN_COMMENT = 'OPEN_COMMENT',
	ACTIVITY_LOG_UPDATE = 'ACTIVITY_LOG_UPDATE',
	MARK_FB_NOTIF_COMP = 'MARK_FB_NOTIF_COMP'
}

export enum UploadingStatusTypes {
	SET_SNACKBAR_PROPS = 'SET_SNACKBAR_PROPS'
}

export enum SearchCommentTypes {
	SEARCH_COMMENT = 'SEARCH_COMMENT'
}
// ?? depends on structure of payloads
export interface BackendPayload {
	user?: IUser;
	project?: IProject;
}

/**
 * All the actions available to the Loading Reducer
 */
export enum LoadingActionTypes {
	SET_LOADING = 'SET_LOADING'
}

export enum SnackbarActionTypes {
	SET_SNACKBAR_DATA = 'SET_SNACKBAR_DATA'
}

/**
 * All the actions available to the Stage Reducer
 */
// export enum StageActionTypes {
//   LOAD_STAGE = 'LOAD_STAGE',
//   EDIT_STAGE = 'EDIT_STAGE'
// }

// export enum CanvasActionTypes {
//   LOAD_CANVAS = 'LOAD_CANVAS',
//   EDIT_CANVAS = 'EDIT_CANVAS',
//   ADD_NODE = 'ADD_NODE',
//   REPLACE_CHILDREN = 'REPLACE_CHILDREN',
//   ADD_COMMENT = 'ADD_COMMENT',
//   REPLACE_COMMENTS = 'REPLACE_COMMENTS',
// }

export type EditNodeComment = INode | IComment;

export type EditCanvasNodeComment = ICanvas | EditNodeComment;

export type ReplyCommentPayload = IComment & {
	parentCommentId: string;
};

export type TRegisterPayload = {
	email?: string;
	profilePic?: string;
	userName?: string;
	firstName?: string;
	lastName?: string;
	host?: string;
	organizationSize?: string;
	roleAndInterest?: string;
	isTestCall?: boolean;
};

export type EditUserDetails = IUserDetailReact & {
	userDetailsId: ObjectId | string;
	update: any;
};
/**
 * [ A, B ]
 * A = The new object to insert into the array, must have _id
 * B = The array which houses A
 *
 */

export enum EstimationAITypes {
	DISPLAY_PREDICTION = 'DISPLAY_PREDICTION',
	EDIT_PREDICTION = 'EDIT_PREDICTION'
}

export enum CommunityAITypes {
	DISPLAY_AI_RESULTS = 'DISPLAY_AI_RESULTS',
	EDIT_AI_RESULTS = 'EDIT_AI_RESULTS'
}

export enum TextToImageActionTypes {
	CREATE_PREDICTION = 'CREATE_PREDICTION',
	EDIT_PREDICTION = 'EDIT_PREDICTION',
	GET_PREDICTION = 'GET_PREDICTION'
}

export enum StagesActionTypes {
	ADD_STAGES = 'ADD_STAGES',
	DEFAULT_ACTION = 'DEFAULT_ACTION'
}

export enum PrototypingActionTypes {
	UPDATE_PROTOTYPE_STATE = 'UPDATE_PROTOTYPE_STATE',
	READY_FOR_ORDER = 'READY_FOR_ORDER',
	ORDER_DETAILS = 'ORDER_DETAILS',
	ADD_FILE = 'ADD_FILE',
	ADD_ERROR = 'ADD_ERROR',
	RESET_STATE = 'RESET_STATE'
}

export interface IFeedBackInitState {
	data: {
		[key: string]: IFeedback;
	};
	loading: { [key: string]: boolean };
	error: { [key: string]: string | null };
}

export type ReduxActionUnion =
	| keyof typeof UserGroupActionTypes
	| keyof typeof ProjectActionTypes
	| keyof typeof LoadingActionTypes
	| keyof typeof ThemeActionTypes
	| keyof typeof OpenCommentTypes
	| keyof typeof SearchCommentTypes
	| keyof typeof UndoRedoActionTypes
	| keyof typeof TemplateActionTypes
	| keyof typeof UploadingStatusTypes
	| keyof typeof TextToImageActionTypes
	| keyof typeof ProjectUserActionTypes
	| keyof typeof EstimationAITypes
	| keyof typeof CommunityAITypes
	| keyof typeof SnackbarActionTypes
	| keyof typeof StagesActionTypes
	| keyof typeof CanvasActionTypes
	| keyof typeof NodeActionTypes
	| keyof typeof PrototypingActionTypes;
export type ITaskCount = {
	taskCount: number;
};

export type TIntegrations = 'GOOGLE' | 'MIRO';
export type TIntegrationValues = 'google' | 'miro';

export enum EIntegrationType {
	GOOGLE = 'google',
	MIRO = 'miro'
}

export type TIntegrationDetails = {
	access_token: string;
	expires_in: number;
	createdAt: number;
	loading?: boolean;
	error?: string | null;
};

export type TIntegrationState = { [key in TIntegrationValues]: TIntegrationDetails } & {
	isIntegrationModalOpen: boolean;
	isSyncStorageModalOpen: boolean;
	isConfirmationModelOpen: boolean;
};

export type TSyncStorage = 'GOOGLE_DRIVE' | 'DROPBOX';

export type TEditObserverPayload = {
	observerId: string;
	projectId: string;
	data: Partial<IObserver>;
};

export type TGoogleFolder = {
	id: string;
	name: string;
};

export type TGDriveFile = { id: string; file: File | string; fileName?: string };
export type TDriveGroupData = {
	name: string;
	id: string;
	children: (TDriveGroupData | TGDriveFile)[];
};
export type TDriveData = { data: TDriveGroupData[]; uploadCount: number };

export type TAddBlocksToGDrive = {
	projectId: string;
	uploadToDrive: TDriveData;
};
