/* eslint-disable max-len */
import { EBlockType } from '@naya_studio/types';
import { TI2RData } from './i2rData';

const chair: TI2RData = {
	text: `{"root":{"children":[{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":"design a classic and simple wood chair inspired by iconic mid century modern chairs","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1},{"children":[],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}`,
	projectTitle: 'Example - Chair',
	groups: [
		{
			name: 'Example - Chair Inspiration',
			blocks: [
				{
					name: 'Barcelona Chair',
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/chair/efcm33cul9rk3wom6hjq',
					blockType: EBlockType.IMAGE
				},
				{
					name: 'Egg Chair',
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/chair/vxafywxrzukotfhjsgbr',
					blockType: EBlockType.IMAGE
				},
				{
					name: 'Eames Chair',
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/chair/hd8kzkdmwl9tokfh9kz0',
					blockType: EBlockType.IMAGE
				},
				{
					name: 'Ekstrem Chair',
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/chair/tqsx85vv9gceajywqtsl',
					blockType: EBlockType.IMAGE
				}
			]
		}
	]
};

export default chair;
