import './PartnerPopup.scss'
import {ReactComponent as NayaLogo} from 'src/assets/brand/logo.svg'
import { FC } from 'react'

export type PartnerPopupProps = {
  show: boolean,
  close: () => void
}

const PartnerPopup: FC<PartnerPopupProps> = ({
  show = false,
  close
}) => {

  if (!show)
    return <></>

  return <div id='partner-popup' onClick={e => {
    e.stopPropagation()
    e.preventDefault()

    close()
  }}>
    <div className='modal-container' onClick={e => e.stopPropagation()}>
      <div className='small-logo-container'><NayaLogo/></div>
      <p>You are now switching to our community page for designers and fabrication partners. Would you like to continue?</p>
      <a href='https://partners.naya.studio/'>
        <button className='next-button'>
          Continue
        </button>
      </a>
    </div>
  </div>
}

export default PartnerPopup