import { FC, useState, useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontFormatProps } from './FontFormat.types';
import './FontFormat.scss';
import fontFormatTypes from './constants';

const FontFormat: FC<FontFormatProps> = ({
  app,
  activeTextProperties,
  nodes,
}) => {
  const [isBold, setBold] = useState(false);
  const [isItalic, setItalic] = useState(false);
  const [isUnderline, setUnderline] = useState(false);

  useEffect(() => {
    const { nodeData } = nodes[0];
    if (nodeData) {
      const textStyle = nodeData.text?.style;
      if (textStyle) {
        const { fontStyle, textDecoration, fontWeight } = textStyle;
        if ((activeTextProperties && activeTextProperties[0])) {
          const { bold, textDeco, italic } = activeTextProperties[0];
          setBold(fontWeight === 'bold' || bold === 'bold');
          setItalic(fontStyle === 'italic' || italic === 'italic');
          setUnderline(textDecoration === 'underline' || textDeco === 'underline');
        } else {
          setBold(fontWeight === 'bold');
          setItalic(fontStyle === 'italic');
          setUnderline(textDecoration === 'underline');
        }
      }
    }
  }, [activeTextProperties, nodes]);

  const handleClick = (fontFormatType: keyof typeof fontFormatTypes) => {
    if (fontFormatType === 'bold') setBold(!isBold);
    else if (fontFormatType === 'italic') setItalic(!isItalic);
    else setUnderline(!isUnderline);
    // const currentNode = app.selectedNodes && app.selectedNodes[0];
    // if (currentNode) currentNode.edit('FONT_FORMAT', fontFormatTypes[fontFormatType], false);
    const currentNode = app.selectedNodes;
    for (let i = 0; i < currentNode.length; i++) {
      currentNode[i]?.edit('FONT_FORMAT', fontFormatTypes[fontFormatType], !!(currentNode.length > 1 && i !== 0));
    }

    app.saveAllNodes([]);
  };

  const renderFontFormatIcons = () => {
    const fontFormatKeys = Object.keys(fontFormatTypes) as [keyof typeof fontFormatTypes];
    return (
      <div style={{ display: 'flex', alignItems: 'center', margin: '4px' }}>
        {fontFormatKeys.map((ft: keyof typeof fontFormatTypes) => {
          let isActive = false;
          if (ft === 'bold') isActive = isBold;
          else if (ft === 'italic') isActive = isItalic;
          else isActive = isUnderline;
          const currentClassName = isActive
            ? `fontFormatOption ${fontFormatTypes[ft].className} activeFontFormat noselect`
            : `fontFormatOption ${fontFormatTypes[ft].className} noselect`;
          return (
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 1000, hide: 0 }}
              overlay={(
                <Tooltip
                  id="button-tooltip-2"
                  style={{
                    fontSize: '12px',
                    borderRadius: '2px',
                    lineHeight: '16px',
                    marginTop: '16px',
                  }}
                >
                  {fontFormatTypes[ft].palceholder}
                </Tooltip>
              )}
            >
              {({ ref, ...triggerHandler }) => (
                <div
                  role="presentation"
                  className={currentClassName}
                  ref={ref}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...triggerHandler}
                  onClick={() => handleClick(ft)}
                >
                  {fontFormatTypes[ft].text}
                </div>
              )}
            </OverlayTrigger>
          );
        })}
      </div>
    );
  };

  return (
    <>
      {renderFontFormatIcons()}
    </>
  );
};
export default FontFormat;
