import * as PIXI from 'pixi.js';

const appInstance = new PIXI.Application({
  width: window.innerWidth,
  height: window.innerHeight,
  autoDensity: true,
  resolution: window.devicePixelRatio || 1,
  // resolution: 5,
  backgroundColor: 0xf1f1f1,
  antialias: true,
  autoStart: true,
});

export default appInstance;
