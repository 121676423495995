import { IBlock, IFeedback } from '@naya_studio/types';
import { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'src/redux/reducers/root.types';
import { FBSWrapper } from 'src/components/feedbackSuite/FBSWrapper';
import { FeedbackRow } from 'src/components/feedbackSuite/feedbackRow/FeedbackRow';
import { IFeedbackWithIndex } from 'src/components/collaborationTool/CollaborationTool.types';
import { FeedbackContext } from 'src/components/expandedBlockView/ExpandedBlockView';
import { ReactComponent as ChatBubble } from '../../../../assets/icons/feedback/chat-icon.svg';
import { ReactComponent as CrossIcon } from '../../../../assets/icons/feedback/cross-icon.svg';
import './feedbackWrapper.scss';
import { FeedbackWrapperProps } from './feedbackWrapper.types';

const FeedbackWrapper = (props: FeedbackWrapperProps) => {
	const { feedbacks, canvasId, view, renderFBS } = props;
	const [canvasloaded, setCanvasLoaded] = useState<boolean>(false);
	const [localBlock, setLocalBlock] = useState<IBlock>();
	// Holds a boolean value to determine feedback panel's scrollbar
	const [hasScrollbar, setHasScrollbar] = useState<boolean>();
	const newComment = useSelector((state) => (state as ReduxState).newComment);
	const allBlocks = useSelector((state) => (state as ReduxState).blocks.data);

	// Holds fbsScrollable element
	const fbsScrollableRef = useRef<HTMLDivElement>(null);

	const { activeFeedbackId, setActiveFeedbackId, feedbackView, showPanel, setShowPanel } =
		useContext(FeedbackContext);
	const newFeedback = newComment.data?.initialComment;
	// True if to show PANEL_VIEW
	const isPanelMode =
		feedbackView === 'PANEL_VIEW' && (feedbacks.length > 0 || !!newComment.data.initialComment);

	// triggered on block id change
	useEffect(() => {
		const canvasFound = allBlocks[canvasId] as IBlock;
		if (canvasFound) {
			setLocalBlock(canvasFound);
			setCanvasLoaded(true);
		}
		return () => {
			// Reset on unmount or block change
			setShowPanel(false);
			setActiveFeedbackId(undefined);
		};
	}, [canvasId]);

	// Runs on mount and on activeFeedbackId and newComment change
	// Set a boolean value to show and hide the feedback panel
	useEffect(() => {
		if ((activeFeedbackId || newFeedback) && isPanelMode && !showPanel) {
			setShowPanel(true);
		}
		// Check if the feedback panel has scrollbar
		if (fbsScrollableRef.current) {
			setHasScrollbar(
				fbsScrollableRef.current.scrollHeight > fbsScrollableRef.current.clientHeight
			);
		}
	}, [activeFeedbackId, newFeedback]);

	// if all the feedback are deleted then close the panel
	useEffect(() => {
		if (feedbacks.length === 0 && showPanel) {
			setShowPanel(false);
			setActiveFeedbackId(undefined);
		}
	}, [feedbacks]);

	// Runs on mount
	useEffect(() => {
		// Used to collapse the expanded feedback
		const handleEscKey = (e: KeyboardEvent) => {
			if (e.code === 'Escape')
				setActiveFeedbackId(undefined);
		}
		window.addEventListener('keydown', handleEscKey);
		return () => {
			window.removeEventListener('keydown', handleEscKey);
		}
	}, [])

	const renderFeedbackPanel = () =>
		showPanel ? (
			<div
				className={`fbs-panel-wrapper 
					feedback-${newFeedback ? feedbacks.length + 1 : feedbacks.length}
				`}
			>
				<div className="fbs-panel-header">
					<ChatBubble />
					<CrossIcon
						onClick={() => {
							setShowPanel(false);
							setActiveFeedbackId(undefined);
						}}
						className="tw-cursor-pointer"
					/>
				</div>
				{/* Setting the paddingRight based on the hasScrollbar to avoid extra space when scrollbar is present */}
				<div className="fbs-scrollable" ref={fbsScrollableRef} style={{paddingRight: hasScrollbar ? '0' : '6px'}}>
					{localBlock?._id && canvasloaded && newFeedback && (
						<FeedbackRow
							index={feedbacks?.length ? feedbacks.length + 1 : 1}
							feedbackData={newFeedback}
							key="NEW"
						/>
					)}
					{canvasloaded &&
						feedbacks?.map((comment: IFeedbackWithIndex, i: number) => (
							<FeedbackRow
								key={comment._id as string}
								index={comment.index || i}
								feedbackData={comment}
							/>
						))}
				</div>
			</div>
		) : (
			// eslint-disable-next-line react/jsx-no-useless-fragment
			<></>
		);

	return isPanelMode ? (
		renderFeedbackPanel()
	) : (
		<>
			{canvasloaded &&
				feedbacks?.map((comment: IFeedback, i: number) => (
					<FBSWrapper
						key={comment._id as string}
						index={i + 1}
						feedbackData={comment}
						type="EXISTING"
						view={view}
						renderFBS={renderFBS}
					/>
				))}
			{localBlock?._id && canvasloaded && newComment.data?.initialComment && (
				<FBSWrapper
					index={feedbacks?.length ? feedbacks.length + 1 : 1}
					feedbackData={newComment.data?.initialComment}
					type="NEW"
					key="NEW"
					view={view}
					renderFBS={renderFBS}
				/>
			)}
		</>
	);
};

FeedbackWrapper.defaultProps = {
	displayView: 'DEFAULT'
};

export default FeedbackWrapper;
