/* eslint-disable max-len */
import { EBlockType } from '@naya_studio/types';
import { TI2RData } from './i2rData';

const handBag: TI2RData = {
	text: `{"root":{"children":[{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":"design a simple luxury handbag that is geometrically faceted","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}`,
	projectTitle: 'Example - Handbag',
	groups: [
		{
			name: 'Example - Handbag Inspiration',
			blocks: [
				{
					name: 'Elegant Bag Image',
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/handbag/jrc4rnz2q5fkuihltcdc',
					blockType: EBlockType.IMAGE
				},
				{
					name: 'Chic Bag Closeup',
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/handbag/heu6691wy4qkwazsukgr',
					blockType: EBlockType.IMAGE
				},
				{
					name: 'Stylish Bag Snap',
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/handbag/heu6691wy4qkwazsukgr',
					blockType: EBlockType.IMAGE
				},
				{
					name: 'Trendy Bag Display',
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/handbag/h5x1uf4y82ietvft7dco',
					blockType: EBlockType.IMAGE
				},
				{
					name: 'Pink and Blue Bags',
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/handbag/cmr1x2rtldw76wxnzovv',
					blockType: EBlockType.IMAGE
				},
				{
					name: 'Vintage Bag Focus',
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/handbag/ctndclug6mfsnvy853bg',
					blockType: EBlockType.IMAGE
				},
				{
					name: 'Luxury Bags',
					url: 'https://res.cloudinary.com/dj0yxjzt0/image/upload/f_png,q_auto,w_1024/v1/i2r/handbag/dw5cmawhlcylkmhsednj',
					blockType: EBlockType.IMAGE
				}
			]
		}
	]
};

export default handBag;
