import { FC, ReactElement } from 'react';
import './AccountDetails.scss';
import moment from 'moment';
import useUser from 'src/redux/hooks/user';
import { AccountDetailsProps } from './AccountDetails.types';

const AccountDetails: FC<AccountDetailsProps> = (): ReactElement => {
	const { user } = useUser();
	return (
		<div id="account-details">
			<p>{user.email}</p>
			<p>created {moment(user.createdAt).format('DD/MM/YY')}</p>
			{/* TODO: Add license details */}
			{/* <h4 style={{marginTop:'3rem'}}>License</h4> */}
		</div>
	);
};
export default AccountDetails;
