import App from 'src/components/canvas/app/App';
import { jsonEncryptionSecretKey } from 'src/components/utilities/clipboard/clipboard';
import CryptoJS from 'crypto-js';
import pasteNode from './pasteNode';
import { DEFAULT_TEXT, isJson } from './utils/helpers';

/**
 * Pastes the nodes or files in system clipboard from the clipboard to the canvas.
 * @param app Collab app instance
 * @param x X coordinate of the 'here'
 * @param y Y coordinate of the 'here'
 * @param uploadFile Upload file helper function
 * @param cb Callback function
 */
async function pasteHere(app: App, x: number, y: number, uploadFile: Function, cb?: Function) {
  // x and y coordinates of the 'here'
  const absBound = app.viewport.toLocal({ x, y });

  // Paste here is a chrome only feature currently.
  // So, we can safely assume that the data is in the system clipboard
  const permissionName = 'clipboard-read' as PermissionName;
  const auth = await navigator.permissions.query({ name: permissionName });

  // If the user has granted permission to read the clipboard
  if (auth.state !== 'denied') {
    const itemList = await navigator.clipboard.read().catch((e) => console.error('clipboard error', e));
    if (itemList) {
      itemList.forEach(async (item) => {
        // If the item is a image
        if (item.types && item.types.some((type) => type.startsWith('image/'))) {
          item.types.forEach(async (type) => {
            if (type.startsWith('image/')) {
              const blob = await item.getType(type);
              const file = new File([blob], `image.${type.split('/')[1]}`);

              // Upload the image to the server and create a node at the 'here' position
              uploadFile(file, absBound);
            }
          });
        } else if (item.types.some((type) => type.startsWith('text/html'))) {
          item.types.forEach(async (type) => {
            if (type.startsWith('text/html')) {
              await item.getType(type).then(async (blob) => {
                // If the item is a text
                const pastedText = await blob.text();
                const htmlClipboardData = (new DOMParser()).parseFromString(pastedText, 'text/html');
                const dataBuffer = htmlClipboardData.querySelector('span[naya-data-buffer]')?.getAttribute('naya-data-buffer');

                if (dataBuffer) {
                // If its a naya data buffer, extract the node data from the buffer
                  const pastedData = CryptoJS
                    .enc.Utf8.stringify(CryptoJS.AES.decrypt(dataBuffer, jsonEncryptionSecretKey));
                  const { nodes, canvasId: id } = JSON.parse(pastedData);

                  // Check if the data is a naya data buffer; if not, paste as text
                  const data = isJson(nodes) ? JSON.parse(nodes) : await navigator.clipboard.readText();
                  if (Array.isArray(nodes)) {
                    pasteNode(app, nodes, 'TOPLEFT', absBound, id);
                  } else {
                    const nodeData = DEFAULT_TEXT(absBound.x, absBound.y, data, app);
                    pasteNode(app, [nodeData], 'TOPLEFT', absBound);
                  }
                }
              });
            }
          });
        } else if (item.types.some((type) => type.startsWith('text/plain'))) {
          item.types.forEach(async (type) => {
            if (type.startsWith('text/plain')) {
              // If the item is a text
              const pastedText = await item.getType(type);
              const data = await pastedText.text();

              // Paste the text as a node at the 'here' position
              const nodeData = DEFAULT_TEXT(absBound.x, absBound.y, data, app);
              pasteNode(app, [nodeData], 'TOPLEFT', absBound);
            }
          });
        }
      });
    }
  }

  // Callback function
  if (cb && typeof cb === 'function') { cb(); }
}

export default pasteHere;
