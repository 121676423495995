import { ControlModal } from '@naya_studio/radix-ui';
import classNames from 'classnames';
import './ReminderModal.scss';
import { FC, useEffect, useRef, useState } from 'react';
import { throttle } from 'lodash';
import { useDispatch } from 'react-redux';
import { AppDispatch, store } from 'src';
import { TEditProjectDetailsThunkArg } from 'src/types/argTypes';
import { IProjectDetail } from '@naya_studio/types';
import useProject from 'src/redux/hooks/useProject';
import useUser from 'src/redux/hooks/user';
import { generateIdsFromUrl, ignoreRemindersForJourney } from 'src/redux/reduxActions/util';
import { editProjectDetails } from 'src/redux/reduxActions/project';
import { ISnackBar } from 'src/redux/reducers/root.types';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';
import { useUpdateMultipleNotifsMutation } from 'src/redux/features/api/notification';
import { SyncLoader } from 'react-spinners';
import trackEvent from 'src/util/analytics/analytics';
import { ProjectEvents } from 'src/util/analytics/events';
import { ReactComponent as Cancel } from '../../assets/icons/details/cancel.svg';

// Prop Types for Reminder Modal Component
type ReminderModalProps = {
	onRedirect: () => void;
	onClose: () => void;
};

/**
 * @component to render Rename Reminder Modal on Collaboration Tool
 */
const ReminderModal: FC<ReminderModalProps> = ({ onRedirect, onClose }) => {
	const dispatch = useDispatch<AppDispatch>();
	const { user } = useUser();
	const { projectId } = generateIdsFromUrl();
	const { project } = useProject(projectId);
	const [updateMultipleNotifs] = useUpdateMultipleNotifsMutation();

	// Ref to store count of retries if request fails
	const retryCountRef = useRef<number>(0);
	// State to store input string from input tag
	const [inputString, setInputString] = useState<string>('');
	// State to store edit request's state
	const [inProgress, setInProgress] = useState<boolean>(false);

	/**
	 * Function to insert journey id in ignored journeys in local storage
	 */
	const onCancel = () => {
		try {
			ignoreRemindersForJourney(projectId);
			onClose();
			onRedirect();
		} catch (error) {
			console.error('Failed to insert journey in ignored journeys', error);

			onClose();
			onRedirect();
		}
	};

	/**
	 * Function to display success snackbar when rename is completed
	 */
	const onSuccess = (updatedName: string) => {
		const snackbarPayload: ISnackBar = {
			text: `Journey renamed to ${updatedName}`,
			type: 'NORMAL',
			show: true
		};

		addSnackbar(snackbarPayload);
		removeSnackbar(2500, () => {
			// Track rename from reminder event
			trackEvent(ProjectEvents.RENAMED_FROM_REMINDER, {
				name: updatedName,
				projectId
			});
		});
	};

	/**
	 * Function to display failure snackbar if redux action fails
	 */
	const onFailure = () => {
		onClose();
		const snackbarPayload: ISnackBar = {
			text: `Failed to rename the journey. ${
				retryCountRef.current < 3 ? 'Please try again.' : ''
			}`,
			type: 'ERROR',
			show: true,
			actionButtonData: [
				{
					buttonData: 'Retry',
					onClick: () => {
						retryCountRef.current++;
						// eslint-disable-next-line @typescript-eslint/no-use-before-define
						removeSnackbar(0, () => onSave());
					},
					className: 'custom-button',
					show: retryCountRef.current < 3
				},
				{
					buttonData: 'Cancel',
					onClick: () => {
						retryCountRef.current = 0;
						removeSnackbar(0, () => onCancel());
					},
					className: 'custom-button',
					show: true
				}
			]
		};

		addSnackbar(snackbarPayload);
		if (retryCountRef.current === 3) removeSnackbar(5000);
	};

	/**
	 * Functio to handle what happens on error
	 */
	const onError = () => {
		setInProgress(false);
		onFailure();
	};

	/**
	 * Function to handle renaming of journey
	 */
	const onSave = () => {
		try {
			setInProgress(true);

			const updatedName = inputString.trim();
			const prevName = (project?.projectDetails as IProjectDetail).name;
			// Fetch active project group details from redux
			const { projectGroup } = store.getState();
			const activeGroup = projectGroup.data;
			// Fetch the group id of the project being renamed
			const parentGroupId = (
				activeGroup._id ? activeGroup._id : user.defaultProjectGroup
			) as string;

			// Construct the action payload
			const actionPayload: TEditProjectDetailsThunkArg = {
				payload: {
					projectId,
					updates: {
						_id: (project?.projectDetails as IProjectDetail)?._id,
						name: updatedName
					},
					parentGroupId
				},
				prevState: {
					name: prevName
				}
			};

			// Dispatch action to rename project
			dispatch(editProjectDetails(actionPayload))
				.unwrap()
				.then((response: TEditProjectDetailsThunkArg['payload']) => {
					/** --- Update Project Name in all notifications of that project ---*/
					updateMultipleNotifs({
						searchBy: { 'data.projectId': projectId },
						update: { 'data.projectName': updatedName },
						operation: 'PROJECT_RENAME'
					});

					// If someone renames the journey and sets it as Untitled, ignore it as well
					// Considering it is renamed
					if (response.updates.name?.toLowerCase() === 'untitled')
						ignoreRemindersForJourney(projectId);

					onClose();
					setTimeout(() => {
						onRedirect();
						onSuccess(updatedName);
					}, 0);
				})
				.catch((error) => {
					console.error('Error renaming journey : ', error);
					onError();
				});
		} catch (error) {
			console.error('Error renaming journey : ', error);
			onError();
		}
	};

	/**
	 * Update the input state by throttling it, when it is changed in input tag
	 */
	const onChange = throttle(
		(event: React.ChangeEvent<HTMLInputElement>) => setInputString(event.target.value),
		250
	);

	/**
	 * Function to handle key down event on input
	 */
	const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		const isValidString = inputString.trim()?.length;
		if ((e.key === 'Enter' || e.keyCode === 13) && isValidString) onSave();
		// else if (e.key === 'Escape' || e.keyCode === 27) onCancel();
	};

	// Runs when modal is mounted, to add event listener
	useEffect(() => {
		/**
		 * Function to handle key down event on window
		 */
		const onWindowKeyDown = (e: KeyboardEvent) => {
			if (e.key === 'Escape' || e.keyCode === 27) onCancel();
		};

		window.addEventListener('keydown', onWindowKeyDown);

		return () => window.removeEventListener('keydown', onWindowKeyDown);
	}, []);

	return (
		<ControlModal open>
			<ControlModal.ContentWrap
				id="reminder-modal"
				width={400}
				data-testid="reminder-modal"
				close={() => onClose()}
				className="DialogContent tw-bg-white tw-rounded-md tw-fixed tw-left-1/2 
        tw--transform tw--translate-x-1/2 tw-min-w-[300px] tw-px-1/2 tw-overflow-auto 
        tw-flex tw-flex-col tw-items-stretch content-wrap tw-px-6 tw-py-4 tw-justify-center tw-gap-6"
			>
				<ControlModal.Content>
					<div className="content-top-block tw-flex tw-justify-center tw-items-center tw-gap-4 tw-flex-col">
						<div className="content-header tw-flex tw-justify-between tw-items-center tw-h-8 tw-w-full">
							<p className="tw-m-0 tw-font-randBold tw-text-lg tw-leading-6 tw-text-black tw-flex-1">
								Journey title
							</p>
							<div
								role="presentation"
								className="cross-icon tw-flex tw-justify-center tw-items-center tw-p-2 tw-rounded-lg tw-cursor-pointer"
								onClick={() => onCancel()}
							>
								<Cancel width={16} height={16} />
							</div>
						</div>

						<div className="content-info tw-font-randRegular tw-text-xs tw-text-black tw-h-8">
							<span className="tw-m-0 tw-font-randBold">A friendly heads-up! </span>
							Your journey is still untitled. What should we call this masterpiece?
						</div>
					</div>

					<input
						type="text"
						name="reminder-input"
						id="reminder-input"
						data-testid="reminder-input"
						value={inputString}
						placeholder="Untitled journey"
						className="content-input tw-flex tw-justify-center tw-items-center tw-px-4 tw-py-2 
						tw-rounded-2xl tw-h-10 tw-w-full tw-font-randRegular tw-text-sm tw-leading-4"
						onChange={onChange}
						autoComplete="off"
						onKeyDown={onKeyDown}
					/>

					<div className="content-cta-buttons tw-flex tw-justify-end tw-items-center tw-h-10 tw-w-full tw-gap-2">
						<div
							role="presentation"
							className="cancel-button tw-flex tw-justify-center tw-items-center tw-p-4 
							tw-rounded-2xl tw-text-naya tw-cursor-pointer tw-text-xs tw-font-randBold tw-h-10"
							onClick={() => onCancel()}
							data-testid="reminder-modal-cancel-btn"
						>
							Do it later
						</div>
						<div
							role="presentation"
							className={`save-button tw-flex tw-justify-center tw-items-center 
								tw-p-4 tw-rounded-2xl tw-text-white tw-bg-naya 
								tw-cursor-pointer tw-text-xs tw-font-randBold tw-h-10
								${classNames({
									disabled: !inputString.trim()?.length,
									'tw-opacity-50': inProgress
								})}
							`}
							onClick={() => onSave()}
							data-testid="reminder-modal-save-btn"
						>
							{inProgress ? <SyncLoader size={5} color="#fff" /> : 'Save'}
						</div>
					</div>
				</ControlModal.Content>
			</ControlModal.ContentWrap>
		</ControlModal>
	);
};

export default ReminderModal;
