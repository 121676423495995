import { ShippingType } from 'src/features/components/homebase/prototyping/prototyping.types';
import { IAction, PrototypingActionTypes, IPrototyping } from '../root.types';

const initialPhoneData = { dialCode: '1', countryCode: 'us', phoneNumber: '' };

export const initialShippingInfo: ShippingType = {
  firstName: '',
  address1: '',
  city: '',
  country: '',
  zipCode: '',
  phoneNumber: '',
  lastName: '',
  state: '',
};

export const initialPrototypeState: IPrototyping = {
  uploadedModel: {},
  isOrderFailed: false,
  isOrderPlaced: false,
  isReadyForOrder: false,
  isUploading: false,
  orderDetails: {
    countryCode: '',
    shippingPrice: 0,
    color: '',
    dimension: {},
    finish: '',
    items: [{
      materialId: '',
      modelId: '',
      quantity: 0,
    }],
    material: '',
    phoneNumber: '',
    price: 0,
    firstName: '',
    city: '',
    address1: '',
    country: '',
    zipCode: '',
    lastName: '',
  },
  isUploaded: false,
  file: {
    url: '',
    extension: '',
  },
  uploadedFrom: '',
  error: {
    isError: false,
    msg: '',
  },
  color: 0x888888,
  placedOrderDetails: null,
  currentLocation: '', // stores the location of the current page when user swiches to other tab
  goToLocation: '',
  formData: {
    shippingInfo: initialShippingInfo,
    phoneData: initialPhoneData,
  },
  fetchOrders: true,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const prototypingReducer = (state = initialPrototypeState, action: IAction<PrototypingActionTypes>) => {
  switch (action.type) {
    case PrototypingActionTypes.ORDER_DETAILS: {
      return { ...state, orderDetails: action.payload, isReadyForOrder: true };
    }
    case PrototypingActionTypes.ADD_ERROR: {
      return { ...state, error: { ...action.payload } };
    }
    case PrototypingActionTypes.UPDATE_PROTOTYPE_STATE: {
      const payload = action.payload ? action.payload : initialPrototypeState;
      return { ...state, ...payload };
    }
    default:
      return state;
  }
};
