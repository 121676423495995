import { AABB, Simple } from "pixi-cull";

class SimpleCull extends Simple {
  constructor() {
    super();
  }

  cull(bounds: AABB, skipUpdate?: boolean) {
    if (!skipUpdate) {
      this.updateObjects()
    }
    for (const list of this.lists) {
      const length = list.length
      for (let i = 0; i < length; i++) {
        const object = list[i]
        const box = object?.AABB!
        if (object?.rotation === 0) {
          object!.visible =
            box.x + box.width > bounds.x && box.x < bounds.x + bounds.width &&
            box.y + box.height > bounds.y && box.y < bounds.y + bounds.height
        }
      }
    }
  }
}

export default SimpleCull