import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ReduxState } from 'src/redux/reducers/root.types';
import './Crop.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CropOptionProps, CropOptionDispatchProps, PathParamsType } from './Crop.types';
import crop from '../../../../../assets/icons/toolbar/edit-menu-crop.svg';

// eslint-disable-next-line react/prefer-stateless-function
class CropOption extends React.Component<CropOptionProps & CropOptionDispatchProps & RouteComponentProps<PathParamsType>> {
  render() {
    const { app } = this.props;
    return (
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 1000, hide: 0 }}
        overlay={(
          <Tooltip
            id="button-tooltip-2"
            style={{
              fontSize: '12px',
              borderRadius: '2px',
              lineHeight: '16px',
              marginTop: '8px',
            }}
          >
            Crop
          </Tooltip>
        )}
      >
        <div
          className="icon-container"
          onClick={(e: any) => {
            e.stopPropagation();
            app.selectedNodes[0]?.setCropMode();
            app.cropModeActive = true;
          }}
          role="presentation"
        >
          <img
            src={crop}
            alt="crop"
            className="icon"
          />
        </div>
      </OverlayTrigger>

    );
  }
}

/**
 * Redux state mapped to canvas props
 * @param state
 * @returns
 */
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mapStateToProps = (state: ReduxState) => ({
});

/**
 * Redux actions mapped to canvas props
 * @param dispatch
 * @returns
 */
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CropOption));
