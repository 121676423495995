// import image2 from 'src/assets/images/landingPage/whyNaya/example_medium.png'
// import image1 from 'src/assets/images/landingPage/whyNaya/article1.jpeg'
// import bala from 'src/assets/images/landingPage/whyNaya/bala.jpeg'
// import jordan from 'src/assets/images/landingPage/whyNaya/jordan.jpeg'
// import image3 from 'src/assets/images/landingPage/whyNaya/article3.jpeg'
import saad from 'src/assets/images/landingPage/whyNaya/saad.jpg'
import gif4 from 'src/assets/images/landingPage/whyNaya/brandNotLogo.gif'
import gif5 from 'src/assets/images/landingPage/whyNaya/designPrinciples.gif'
import Logo_Fastco from 'src/assets/images/landingPage/press/Logo_Fastco.png'
import Logo_Harvard from 'src/assets/images/landingPage/press/Logo_Harvard.png'
import Logo_Hi from 'src/assets/images/landingPage/press/Logo_Hi.png'
import Logo_Pivot from 'src/assets/images/landingPage/press/Logo_Pivot.png'
import Logo_Mashable from 'src/assets/images/landingPage/press/Logo_Mashable.png'

const platformDescriptions = [
  'a creative tool',
  'an end to end service',
  'a place where ideas come to life',
  'a community of creators',
  'a collaborative design experience',
  'a platform for creators',
  'an engine for unique designs',
  'a design technology tool',
  'a hub for inspiration',
  'a platform that empowers creativity'
]

export const whyNaya = {
  typer: {
    header: `Naya is `,
    descriptions: platformDescriptions
  },
  mission: {
    header: 'Our Vision',
    text1: <>Naya aims to create more <span className='purple bold'>inclusive, delightful, </span>and <span className='purple bold'>sustainable products </span>through our adaptive platform that embodies co:creation and trust while providing an incredible user experience. </>
  },
  name: {
    header: 'Our name.',
    text1: 'Naya means newness and innovation in Hindi, Hebrew, and Arabic.',
    names: ['חָדָשׁ','الجديد','नया','naya']
  },
  idea: {
    header: 'Our Ideas.',
    articles: [
      {
        url: 'https://medium.com/naya-studio/nayas-design-principles-85b7b4791af8',
        imageUrl: gif5,
        profilePic: saad,
        userName: 'Saad Rajan',
        userPositionTitle: '',
        description: 'Naya re-imagines co:creation and democratizes design to create products for our unique, diverse needs. The platform is trusted by brand names like Googl...',
        name: "Naya's Design Principles",
        subject: 'brand',
      },
      {
        url: 'https://medium.com/naya-studio/a-brand-is-not-a-logo-it-is-a-story-80d0ae2519f9',
        imageUrl: gif4,
        profilePic: saad,
        userName: 'Saad Rajan',
        userPositionTitle: '',
        description: 'Our previous branding and identity were created well before Naya’s current direction was defined. Although our previous identity was well receiv...',
        name: 'A brand is not a logo; it is a story...',
        subject: 'insight',
      },
      // {
      //   url: 'https://medium.com/naya-studio/naya-studio-wants-you-to-take-control-of-your-living-room-ddc179afef3d',
      //   imageUrl: image3,
      //   profilePic: jordan,
      //   userName: 'Jordan Gowen',
      //   userPositionTitle: '',
      //   description: 'Naya ­– a startup which strives to simplify the process of commissioning custom furniture. How? Through a full-service web platform that connects...',
      //   name: 'Naya Studio Wants You To Take Control of Your Living Room',
      //   subject: 'inspiration',
      // },
      // {
      //   url: 'https://medium.com/naya-studio/from-idea-to-furniture-47eae0c5397a',
      //   imageUrl: image2,
      //   profilePic: bala,
      //   userName: 'Bala Subramanian',
      //   userPositionTitle: '',
      //   description: 'It was late at night in Boston. Peter was in his home office, hunched over his computer. Hope flickered in his chest as he opened the webs...',
      //   name: 'From idea to furniture',
      //   subject: 'inspiration',
      // },
      // {
      //   url: 'https://medium.com/naya-studio/stop-consuming-start-creating-dd12593d24e8',
      //   imageUrl: image1,
      //   profilePic: bala,
      //   userName: 'Bala Subramanian',
      //   userPositionTitle: '',
      //   description: 'What we seek at the deepest level, is inwardlly to resemble, rather than physically to posess, the objects and places that touch us...',
      //   name: 'Stop consuming, start creating',
      //   subject: 'design',
      // }
    ]
  },
  news: {
    header: 'Our Press.',
    articles: [
      {
        name: 'fast-company',
        src: Logo_Fastco,
        link: 'https://www.fastcompany.com/90584520/these-harvard-grads-want-to-turn-your-doodles-into-gorgeous-custom-furniture'
      },
      {
        name: 'harvard-innovations',
        src: Logo_Hi,
        link: 'https://www.youtube.com/watch?v=TSX414-MQHE'
      },
      {
        name: 'pivot',
        src: Logo_Pivot
      },
      {
        name: 'harvard-seas',
        src: Logo_Harvard,
        link: 'https://www.seas.harvard.edu/news/2019/08/crafting-collaboration'
      },
      {
        name: 'mashable',
        src: Logo_Mashable,
        link: 'https://mashable.com/video/doodle-to-furniture-naya-studio'
      }
    ]
  }
}