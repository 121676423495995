/**
 * Checks if the model is supported or not
 * @param ext - model extension
 * @returns boolean
 */
import getUserFromRedux from 'src/util/helper/user';

/* eslint-disable import/prefer-default-export */
export const isSupportedModel = (ext: string | undefined) => {
	if (ext) {
		const supportedExts = ['DAE', 'OBJ', 'STL', 'X3D', 'X3DB', 'X3DV', '3MF', 'STEP', 'STP'];
		return supportedExts.includes(ext.toUpperCase());
	}
	return false;
};

/**
 * Checks if the maximun order limit has reached
 * @returns boolean
 */
export const isMaxOrderReached = (): boolean => {
	const { prototypeOrders } = getUserFromRedux();
	if (prototypeOrders && prototypeOrders?.length) {
		return prototypeOrders?.length > 0;
	}
	return false;
};
