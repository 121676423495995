import * as PIXI from 'pixi.js';
import { SpriteH } from 'pixi-heaven';
import BaseNode from './BaseNode';
import {
  getFileIcon, getFileName, positionAuthorFill, positionAuthorImage,
} from './utils/helper';

class FilePlaceholder extends BaseNode {
  iconSprite: any;

  background: PIXI.Graphics = new PIXI.Graphics();

  textContainer: any;

  selected: boolean = false;

  activeColor: number = PIXI.utils.string2hex('#5D5D5D');

  fileTextStyle: PIXI.TextStyle = new PIXI.TextStyle({
    fontFamily: 'Rand Regular',
    fontWeight: '500',
    fontSize: 12,
    whiteSpace: 'pre',
    align: 'center',
    fill: 0x7E7E7E,
  });

  edit = async (type: string, data: any) => {
    this.prevNodeData = JSON.parse(JSON.stringify(this.nodeData));
    switch (type) {
      case 'AUTHOR':
        this.nodeData.showAuthor = data;
        break;
      case 'ZINDEX':
        this.nodeData.zIndex = data;
        break;
      default:
        break;
    }
    await this.draw();
    this.app.removeTransformer();
    this.app.addSelectedNodesToTransformer();
  };

  onBaseMouseDown = (e: any) => {
    this.onMouseDown(e);
    if (!this.app.isPanSelected) {
      this.selected = true;
      const activeColor = PIXI.utils.string2hex(this.app.theme.color);
      this.activeColor = activeColor;
      const sprite = this.iconSprite;
      const { background } = this;
      if (sprite) {
        sprite.tint = activeColor;
        sprite.color.dark[0] = sprite.color.light[0] as number;
        sprite.color.dark[1] = sprite.color.light[1] as number;
        sprite.color.dark[2] = sprite.color.light[2] as number;
      }
      if (this.textContainer) {
        this.fileTextStyle.fill = activeColor;
      }
      if (background) {
        background.tint = 0xffffff;
      }
    }
  };

  // Interactions witn frame + 3d tag
  frameMouseover = () => {
    if (!this.selected && !this.app.isPanSelected) {
      const { color, colorWithOpacity } = this.app.theme;
      const activeColor = PIXI.utils.string2hex(color);
      this.activeColor = activeColor;
      const sprite = this.iconSprite;
      const { background } = this;
      if (sprite) {
        sprite.tint = activeColor;
        sprite.color.dark[0] = sprite.color.light[0] as number;
        sprite.color.dark[1] = sprite.color.light[1] as number;
        sprite.color.dark[2] = sprite.color.light[2] as number;
      }
      if (this.textContainer) {
        this.fileTextStyle.fill = activeColor;
      }
      if (background) {
        background.tint = PIXI.utils.string2hex(colorWithOpacity);
      }
    }
    this.onMouseOver();
  };

  frameMouseout = () => {
    if (!this.selected && !this.app.isPanSelected) {
      const activeColor = PIXI.utils.string2hex('#5D5D5D');
      this.activeColor = activeColor;
      const sprite = this.iconSprite;
      const { background } = this;
      if (sprite) {
        sprite.tint = activeColor;
        sprite.color.dark[0] = sprite.color.light[0] as number;
        sprite.color.dark[1] = sprite.color.light[1] as number;
        sprite.color.dark[2] = sprite.color.light[2] as number;
      }
      if (this.textContainer) {
        this.fileTextStyle.fill = activeColor;
      }
      if (background) {
        background.tint = 0xffffff;
      }
    }
    this.onMouseOut();
  };

  /**
   * place the orbit, move, 3d tags in the frame
   * @param pixiSpriteH
   * @param placement
   */
  // eslint-disable-next-line class-methods-use-this
  placeSpriteH = (pixiSpriteH: PIXI.Container | SpriteH, placement: { width: number, height: number, x: number, y: number }) => {
    const {
      width, height, x, y,
    } = placement;
    // @ts-ignore
    pixiSpriteH.width = width;
    // @ts-ignore
    pixiSpriteH.height = height;
    // @ts-ignore
    pixiSpriteH.x = x;
    // @ts-ignore
    pixiSpriteH.y = y;
  };

  /**
   * Draws a node object with image
   *
   */
  draw = async () => {
    try {
      if (this.displayObject) {
        this.app.viewport.removeChild(this.displayObject);
      }

      if (this.nodeData) {
        const mainContainer = new PIXI.Container();
        const containerWithoutText = new PIXI.Container();

        // start draw
        const background = new PIXI.Graphics();
        background.beginFill(0xffffff);
        background.scale.set(1);

        const {
          absoluteBounds: nodeDataBounds,
          scale,
          rotation,
          image,
          text: textData,
        } = this.nodeData;

        const defaultAbsoluteBounds = {
          x: window.innerWidth / 2,
          y: window.innerHeight / 2,
          height: 150,
          width: 130,
        };

        const ICON_WIDTH = 60;
        const ICON_HEIGHT = 60;

        const scaleX = scale && scale?.scaleX ? scale?.scaleX : 1;
        const scaleY = scale && scale?.scaleY ? scale?.scaleY : 1;

        const frame = new PIXI.Graphics();
        const frameWidth = this.nodeData.showAuthor ? ((ICON_WIDTH * scaleX)) + 80 : ((ICON_WIDTH * scaleX) + 20);
        const frameHeight = this.nodeData.showAuthor ? (ICON_HEIGHT * scaleY) + 80 : (ICON_HEIGHT * scaleY) + 40;
        const frameX = this.nodeData.showAuthor ? -40 : -10;
        const frameY = this.nodeData.showAuthor ? -45 : -10;
        frame.drawRoundedRect(frameX, frameY, frameWidth, frameHeight, 0);
        mainContainer.addChild(frame);

        if (!textData || !textData.value) return;

        // onboarding answer
        const { value } = textData;

        // used for multi-selects
        const answers = value.split(',');

        // default absoluteBounds
        const absoluteBounds = nodeDataBounds || defaultAbsoluteBounds;

        if (image && image.src) {
          // draw one rectangle
          background.drawRoundedRect(0, 0, ICON_WIDTH, ICON_HEIGHT, 22);
          // finish background and add
          background.endFill();
          containerWithoutText.addChild(background);
          this.background = background;
          // create text
          const temp: string[] = value.split('.');

          // create file icon based on extention
          const texture = await PIXI.Texture.from(getFileIcon(temp[temp.length - 1] as string));
          const sprite = new SpriteH(texture);
          sprite.tint = this.activeColor;
          sprite.color.dark[0] = sprite.color.light[0] as number;
          sprite.color.dark[1] = sprite.color.light[1] as number;
          sprite.color.dark[2] = sprite.color.light[2] as number;
          this.iconSprite = sprite;
          this.placeSpriteH(this.iconSprite, {
            width: ICON_WIDTH,
            height: ICON_HEIGHT,
            x: 0,
            y: 0,
          });
          containerWithoutText.addChild(this.iconSprite);

          // create file name text based on extention
          const textValue = getFileName(temp, this.app.viewport.scale._x);
          const text = new PIXI.Text(textValue, this.fileTextStyle);
          const textYPosition = (this.iconSprite.x + this.iconSprite.width) * scaleY + 20;
          text.setTransform(
            (this.iconSprite.x + (this.iconSprite.width) / 2)
            * scaleX,
            textYPosition,
            1 / this.app.viewport.scale._x,
            1 / this.app.viewport.scale._y,
          );
          // fixes bluriness
          text.resolution = 3;
          // sets origin center so text is always centered
          text.anchor.set(0.5);
          mainContainer.addChild(text);
          this.textContainer = text;
        } else {
          const { length } = answers;

          // create big rectangle based on length of answers
          background.drawRoundedRect(0, 0, absoluteBounds.width! * length, absoluteBounds.height!, 13);
          // finish background and add
          background.endFill();
          mainContainer.addChild(background);

          // create PIXI.text for each word and addChild
          answers.forEach((a, i) => {
            // create text
            const text = new PIXI.Text(a, {
              fontFamily: 'Arial',
              fontSize: 14,
              wordWrap: true,
              wordWrapWidth: 115,
              align: 'left',
            });
            // 150*i is the number of rectangles there are
            text.setTransform(75 + (150 * i), 75);
            // fixes bluriness
            text.resolution = 3;
            // sets origin center so text is always centered
            text.anchor.set(0.5);

            mainContainer.addChild(text);
          });
        }
        // Add author if required
        const createdBy = this.nodeData.createdBy as any;
        const profilePicStatus = typeof createdBy === 'object' && 'profilePic' in createdBy && createdBy.profilePic !== '';
        const userNameStatus = typeof createdBy === 'object' && 'userName' in createdBy && createdBy.userName !== '';
        if (this.nodeData.showAuthor && profilePicStatus) {
          const authorImage = PIXI.Sprite.from(createdBy.profilePic);
          const circularMask = new PIXI.Graphics();
          positionAuthorImage(
            authorImage,
            circularMask,
            {
              width: 30,
              height: 30,
              x: (this.iconSprite.x + this.iconSprite.width) * scaleX + 20,
              y: (this.iconSprite.y * scaleY) - 30,
              scaleX: 1,
              scaleY: 1,
              rotation: 0,
            },
            0.1,
          );
          mainContainer.addChild(circularMask);
          mainContainer.addChild(authorImage);
          authorImage.mask = circularMask;
        } else if (this.nodeData.showAuthor && userNameStatus) {
          // TODO: pixi graphics to be replaced with corresponding letter pictur
          const circleFill = new PIXI.Graphics();
          const circleText = new PIXI.Text(createdBy.userName.toUpperCase()[0]);
          positionAuthorFill(
            circleFill,
            circleText,
            {
              width: 30,
              height: 30,
              x: (this.iconSprite.x + this.iconSprite.width) * scaleX + 20,
              y: (this.iconSprite.y * scaleY) - 30,
              scaleX: 1,
              scaleY: 1,
              rotation: 0,
            },
            0.1,
          );
          mainContainer.addChild(circleFill);
        }
        containerWithoutText.setTransform(0, 0, scaleX, scaleY, rotation);
        this.withoutTextContainer = containerWithoutText;
        mainContainer.addChild(containerWithoutText);

        mainContainer.setTransform(absoluteBounds.x!, absoluteBounds.y!, 0, 0, rotation);
        mainContainer.zIndex = this.nodeData.zIndex as number;
        this.displayObject = mainContainer;
        // shape, markup, comment, and reaction should NOT be interactive
        this.displayObject.interactive = this.app.checkIfNodesShouldBeInteractive();
        this.displayObject.name = 'FILE_PLACEHOLDER';
        this.app.viewport.addChild(mainContainer);
        this.displayObject.on('click', this.onClick);
        this.displayObject.on('mousedown', this.onBaseMouseDown);
        this.displayObject.on('mouseup', this.onMouseUp);
        this.displayObject.on('mouseover', this.frameMouseover);
        this.displayObject.on('mouseout', this.frameMouseout);
        this.displayObject.on('rightclick', this.rightClick);
        this.render();
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export default FilePlaceholder;
