import { FC } from 'react';
import './LoadingModal.scss';
import { Modal } from 'react-bootstrap';
import NayaLoader from 'src/components/loader/Loader';
import { LoadingModalProps } from './LoadingModal.types';
// const video = require('src/assets/brand/loader1.webm')

const LoadingModal: FC<LoadingModalProps> = ({ show }) => (
	<Modal show={show} onHide={() => {}} id="loading-modal" centered>
		<Modal.Body>
			<div className="logo-container homebase-loader">
				<NayaLoader />
			</div>
		</Modal.Body>
	</Modal>
);

export default LoadingModal;
