import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ReduxState } from 'src/redux/reducers/root.types';
import './Rotate.scss'
import { RotateOptionProps, RotateOptionDispatchProps, PathParamsType } from './Rotate.types';
import rotateIcon from "../../../../../assets/icons/toolbar/rotate.svg";

class RotateOption extends React.Component<RotateOptionProps & RotateOptionDispatchProps & RouteComponentProps<PathParamsType>>{

  state = {
    rotation : Math.PI/4, 
  }

  render(){

    return (
      <div className="icon-container"
      onClick={(e: any) => {
        e.stopPropagation();
        let allNodes = this.props.app.selectedNodes;
        allNodes.forEach((node, i)=>{
        let newRotate: number = this.state.rotation 
        // pass 45deg(0.785398) in rotation to the Image node each time the rotate option is clicked.
        node?.edit("ROTATE", newRotate , allNodes.length > 1 && i!==0 ? true : false);
      })
      }}>
          <img
            src={rotateIcon}
            alt="rotate"
            className="icon"
            style={{width:'1.5rem'}}
            
          ></img>
      </div>
    )
  }
}

/**
 * Redux state mapped to canvas props
 * @param state 
 * @returns 
 */
// @ts-ignore
const mapStateToProps = (state: ReduxState) => {
  return {
  }
}

/**
 * Redux actions mapped to canvas props
 * @param dispatch 
 * @returns 
 */
// @ts-ignore
const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RotateOption));