import { EFeedbackType, IFeedback } from '@naya_studio/types';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'src/redux/reducers/root.types';
import { resetNewCommentData } from 'src/redux/features/newComment';
import { PollWrapper } from '../poll/PollWrapper';
import { NewFeedback } from '../feedback/NewFeedback';
import { FeedbackTile } from '../feedback/FeedbackTile';
import { ReactComponent as Collapse } from '../../../assets/icons/feedback/collapse.svg';
import './FeedbackRow.scss';
import { FeedbackContext } from 'src/components/expandedBlockView/ExpandedBlockView';

/** Props for FBS wrapper component */
export type FBSProps = {
	// Feedback data
	feedbackData: IFeedback;
	// Index of the feedback
	index: number;
};

/**
 * @Component FBS wrapper component to render new or existing feedback based on type
 * @param {FBSProps}
 * @returns React Component/JSXElement
 */
export const FeedbackRow = ({ feedbackData, index }: FBSProps) => {
	const [feedbackText, setFeedbackText] = useState('');
	const [feedbackType, setFeedbackType] = useState<EFeedbackType | undefined>(); // to hold the feedback type chossen by the user
	const newComment = useSelector((state) => (state as ReduxState).newComment);
	const { activeFeedbackId, setActiveFeedbackId } = useContext(FeedbackContext);

	const dispatch = useDispatch();

	useEffect(() => {
		if (feedbackData.feedbackType) setFeedbackType(feedbackData.feedbackType as EFeedbackType);
	}, [feedbackData.feedbackType]);

	const resetFeedbackType = (text: string) => {
		setFeedbackText(text);
		setFeedbackType(undefined);
		dispatch(resetNewCommentData());
	};

	// function to render new feedback based on type
	const renderNewFeedback = () => {
		if (feedbackType) {
			return (
				<NewFeedback
					text={feedbackText}
					resetFeedbackType={resetFeedbackType}
					feedbackType={feedbackType}
					getNewFeedbackPosition={() =>
						newComment.data?.initialComment?.absoluteBounds as {
							x: number;
							y: number;
							z?: number | undefined;
						}
					}
				/>
			);
		}

		return <div />;
	};

	// function to render existing feedbacks based on feedback types
	const renderExistingFeedback = () => {
		switch (feedbackType) {
			case EFeedbackType.CHAT:
				return <FeedbackTile data={feedbackData} index={index} />;
			case EFeedbackType.POLL:
				return <PollWrapper data={feedbackData} index={index} />;
			default:
				return <div />;
		}
	};

	// Triggered when the feedback is selected from viewers and will scroll to selected feedback
	useEffect(() => {
		if (activeFeedbackId) {
			setTimeout(() => {
				const ele = document.querySelector(`[data-feedbackId="${activeFeedbackId}"]`);
				ele?.scrollIntoView({ behavior: 'smooth' });
			}, 50);
		}
	}, [activeFeedbackId]);

	return (
		<div
			style={{ position: 'relative', flex: '1' }}
			className="tw-cursor-pointer"
			role="presentation"
			data-feedbackId={feedbackData._id}
			onClick={() => {
				if (feedbackData?._id !== activeFeedbackId) {
					setActiveFeedbackId(feedbackData._id as string);
				}
			}}
		>
			{!feedbackData._id ? renderNewFeedback() : renderExistingFeedback()}
			{feedbackData._id && activeFeedbackId === feedbackData._id && (
				<Collapse
					onClick={(e) => {
						e.stopPropagation();
						if (feedbackData._id === activeFeedbackId) {
							setActiveFeedbackId(undefined);
						}
					}}
					className="collapse-icon"
				/>
			)}
		</div>
	);
};
