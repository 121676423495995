import { useEffect, useState } from 'react';
import { ENodeType, INode, INodeAdditionalPropertiesReact } from '@naya_studio/types';
import axios from 'axios';
import { useHistory } from 'react-router';
import { store } from 'src';
import { CustomDispatch } from 'src/redux/actions/types';
import { undoAction } from 'src/redux/actions/undoRedoActions';
import { EActionType } from 'src/redux/reducers/undoRedo/undoActionHistory.types';
import { getNodeDataDifferences } from 'src/redux/actions/util';
import _ from 'lodash';
import { isSupportedModel } from 'src/features/components/homebase/prototyping/utils/helper';
import { uploadModelToShapeways } from 'src/redux/actions/prototyping';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';
import './3dContextMenu.scss';
import { TEditNodesArgs } from 'src/types/argTypes';
import { generateIdsFromUrl } from 'src/redux/reduxActions/util';
import { shallowEqual, useSelector } from 'react-redux';
import { ReduxState } from 'src/redux/reducers/root.types';
import { editNodes } from 'src/redux/reduxActions/node';
import { getFileInfoFromCache } from '../canvas/utils/helper';
import BaseNode from '../canvas/app/nodes/BaseNode';
import { isChrome } from '../utilities/clipboard/clipboard';
import { ThreeDContextMenuProps } from './3dContextMenu.types';

const ThreeDContextMenu = (props: ThreeDContextMenuProps) => {
	const { app, toggle3dContext, setSnackbarMsg, rightY, rightX } = props;
	const { rightClickedNode } = app;
	const selectedNode = rightClickedNode as BaseNode;
	const [showDownloadOption, setShowDownloadOption] = useState(false);
	const allNodes = useSelector((state) => (state as ReduxState).nodes.data, shallowEqual);

	const history = useHistory();

	useEffect(() => {
		if (selectedNode) {
			const showDownload = [
				ENodeType.MODEL,
				ENodeType.FILE_PLACEHOLDER,
				ENodeType.IMAGE
			].includes(selectedNode.nodeData.nodeType as ENodeType);
			setShowDownloadOption(showDownload);
		}
	}, [selectedNode]);

	const copyFunction = async () => {
		// onCopy(null, app, selectedNode, () => {
		// 	toggle3dContext(false);
		// 	setSnackbarMsg('Copied');
		// 	setTimeout(() => setSnackbarMsg(''), 2000);
		// });
	};

	const changeOrder = async (isBackwardMovement: boolean, isExtremeMovement: boolean) => {
		const zIndex = app.getNewZindex(
			app.rightClickedNode!,
			isBackwardMovement,
			isExtremeMovement
		);
		await app.rightClickedNode!.edit('ZINDEX', zIndex, false);
		app.saveAllNodes([]);
		toggle3dContext(false);
	};

	const getDownloadData = (node: INode) => {
		const nodeData = node;
		switch (nodeData.nodeType) {
			case ENodeType.MODEL:
				return {
					downloadURL: nodeData?.model?.src,
					dateFileName: `${nodeData?.model?.name}.${nodeData?.model?.extension}` as string
				};
			case ENodeType.FILE_PLACEHOLDER: {
				const { additionalProperties } = nodeData;
				return {
					downloadURL: (additionalProperties as INodeAdditionalPropertiesReact)
						?.downloadLink,
					dateFileName: nodeData.text?.value as string
				};
			}
			case ENodeType.IMAGE: {
				const { additionalProperties, image } = nodeData;
				let fileExtension: string = node.image?.src?.split('.').pop() as string;
				if (fileExtension?.length > 4) {
					fileExtension = 'png';
				}
				return {
					downloadURL:
						(additionalProperties as INodeAdditionalPropertiesReact)?.downloadLink ||
						image?.src,
					dateFileName: (nodeData?.text?.value as string) || `image.${fileExtension}`
				};
			}
			default:
				return {};
		}
	};

	const downloadFunction = () => {
		const { nodeData } = selectedNode;
		const { downloadURL, dateFileName } = getDownloadData(nodeData) as any;
		setSnackbarMsg('Downloading File');
		axios({
			url: downloadURL,
			method: 'GET',
			responseType: 'blob' // important
		}).then((response) => {
			setSnackbarMsg('File downloaded');

			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', dateFileName); // or any other extension
			document.body.appendChild(link);
			link.click();

			setTimeout(() => setSnackbarMsg(''), 2000);
		});
		toggle3dContext(false);
	};

	const bringToFront = () => {
		changeOrder(false, true);
	};

	const bringForward = () => {
		changeOrder(false, false);
	};

	const sendBackwards = () => {
		changeOrder(true, false);
	};

	const sendToBack = () => {
		changeOrder(true, true);
	};

	const handle3DPrint = async () => {
		if (selectedNode) {
			const { nodeData } = selectedNode;
			const modelSrc = nodeData.model?.src;
			const modelName = nodeData.model?.name;
			const extension = nodeData.model?.extension;
			if (isSupportedModel(extension)) {
				if (modelName && modelSrc && extension) {
					const data = await getFileInfoFromCache(modelName, modelSrc, extension);
					if (data === 'Invalid Model') {
						addSnackbar({ show: true, type: 'ERROR', text: 'Invalid Model' });
						removeSnackbar(5000);
					} else if (data) {
						(store.dispatch as CustomDispatch)(
							uploadModelToShapeways({
								...data,
								uploadedFrom: history.location.pathname
							})
						);
						history.push('/prototyping/upload');
					}
				}
			} else {
				addSnackbar({
					show: true,
					type: 'ERROR',
					text: 'Model type is not support for printing'
				});
				removeSnackbar(5000);
			}
		}
	};

	const deleteFunction = () => {
		if (app.rightClickedNode?.nodeData?._id) {
			const nodeData = app.rightClickedNode?.nodeData;
			app.removeTransformer();
			app.removeNode(nodeData._id!);
			app.render();
			const nodeDataAfterDelete = _.cloneDeep(nodeData);
			app.removeAllSelectedNodes();
			app.toggleEditMenu(false);
			if (nodeDataAfterDelete?.isVisible) {
				nodeDataAfterDelete!.isVisible = false;
				nodeDataAfterDelete.version = nodeDataAfterDelete.version
					? nodeDataAfterDelete.version + 1
					: 1;
			}
			undoAction(
				'EDIT' as EActionType,
				nodeData as INode,
				nodeDataAfterDelete as INode,
				app.selectedNodes.length > 1
			);

			app.rightClickedNode.nodeData = nodeDataAfterDelete;
			const nodeDataDiffs = getNodeDataDifferences([app.rightClickedNode]);

			// generating payload for edit nodes action
			const apiPayload: TEditNodesArgs = {
				data: {
					nodes: nodeDataDiffs,
					...generateIdsFromUrl()
				},
				prevState: {
					prevBlocks: allNodes
				}
			};

			// dispatch edit nodes
			(store.dispatch as CustomDispatch)(editNodes(apiPayload));
		}
		toggle3dContext(false);
	};

	return (
		<div
			id="context-3d"
			style={{
				top: Math.min(rightY, window.innerHeight - 250 - 16),
				left: rightX
			}}
			role="presentation"
			onPointerDown={(e: any) => {
				e.stopPropagation();
			}}
			onPointerUp={(e: any) => {
				e.stopPropagation();
			}}
			onClick={(e: any) => {
				e.stopPropagation();
			}}
		>
			<div
				className="context-3d-item"
				style={{ marginTop: '4px' }}
				onClick={handle3DPrint}
				role="presentation"
			>
				3D Print
			</div>
			<hr />
			{/* Right-click copy paste only available on chrome */}
			{isChrome && (
				<>
					<div
						className="context-3d-item"
						style={{ marginTop: '4px' }}
						onClick={copyFunction}
						role="presentation"
					>
						Copy{' '}
					</div>
					<hr />
				</>
			)}
			{showDownloadOption && (
				<>
					<div className="context-3d-item" onClick={downloadFunction} role="presentation">
						Download
					</div>
					<hr />
				</>
			)}
			<div className="context-3d-item" onClick={bringToFront} role="presentation">
				Bring to front
			</div>
			<div className="context-3d-item" onClick={bringForward} role="presentation">
				Bring forward
			</div>
			<div className="context-3d-item" onClick={sendBackwards} role="presentation">
				Send backwards
			</div>
			<div className="context-3d-item" onClick={sendToBack} role="presentation">
				Send to back
			</div>
			<hr />
			<div
				className="context-3d-item"
				style={{ marginBottom: '4px', color: 'red' }}
				onClick={deleteFunction}
				role="presentation"
			>
				Delete
			</div>
		</div>
	);
};

export default ThreeDContextMenu;
