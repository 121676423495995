import { useEffect, useState } from 'react';
import useUser from 'src/redux/hooks/user';
import { getUserBlocksCount } from 'src/util/helper/user';
import Badge from './Badge';
import './badges.scss';
import { ReactComponent as BlockBaronDisabled } from './badge-icons/block-baron-disabled.svg';
import { ReactComponent as WallWeaverDisabled } from './badge-icons/wall-weaver-disabled.svg';
import { ReactComponent as MasterMasonDisabled } from './badge-icons/master-mason-disabled.svg';
import { ReactComponent as HighRiseDisabled } from './badge-icons/high-rise-disabled.svg';
import { ReactComponent as SkylineDisabled } from './badge-icons/skyline-sculpture-disabled.svg';
import { ReactComponent as VerticalDisabled } from './badge-icons/vertical-visionary-disabled.svg';
import cityBuilderDisabled from './badge-icons/city-builder-disabled.png';
import worldWonderDisabled from './badge-icons/world-wonder.png';
import { ReactComponent as BlockBaron } from './badge-icons/block-baron.svg';
import { ReactComponent as WallWeaver } from './badge-icons/wall-weaver.svg';
import { ReactComponent as MasterMason } from './badge-icons/master-mason.svg';
import { ReactComponent as HighRise } from './badge-icons/high-rise.svg';
import { ReactComponent as Skyline } from './badge-icons/skyline-sculpture.svg';
import { ReactComponent as Vertical } from './badge-icons/vertical-visionary.svg';

const badges = [
	{
		title: 'Block Baron',
		subTitle: '10 blocks',
		imgSrc: <BlockBaron />,
		imgSrcDisabled: <BlockBaronDisabled />,
		minValue: 1,
		maxValue: 10
	},
	{
		title: 'Wall Weaver',
		subTitle: '50 blocks',
		imgSrc: <WallWeaver />,
		imgSrcDisabled: <WallWeaverDisabled />,
		minValue: 11,
		maxValue: 50
	},
	{
		title: 'Master Mason',
		subTitle: '100 blocks',
		imgSrc: <MasterMason />,
		imgSrcDisabled: <MasterMasonDisabled />,
		minValue: 51,
		maxValue: 100
	},
	{
		title: 'High Rise Hero',
		subTitle: '300 blocks',
		imgSrc: <HighRise />,
		imgSrcDisabled: <HighRiseDisabled />,
		minValue: 101,
		maxValue: 300
	},
	{
		title: 'Skyline Sculptor',
		subTitle: '1,000 blocks',
		imgSrc: <Skyline />,
		imgSrcDisabled: <SkylineDisabled />,
		minValue: 301,
		maxValue: 1000
	},
	{
		title: 'Vertical Visionary',
		subTitle: '2,500 blocks',
		imgSrc: <Vertical />,
		imgSrcDisabled: <VerticalDisabled />,
		minValue: 1001,
		maxValue: 2500
	},
	{
		title: 'City Builder',
		subTitle: '7,500 blocks',
		imgSrc: <img src={cityBuilderDisabled} alt="city builder" />,
		imgSrcDisabled: <img src={cityBuilderDisabled} alt="city builder" />,
		minValue: 2501,
		maxValue: 7500
	},
	{
		title: 'World Wonder',
		subTitle: '15,000 blocks',
		imgSrc: <img src={worldWonderDisabled} alt="world wonder" />,
		imgSrcDisabled: <img src={worldWonderDisabled} alt="world wonder" />,
		minValue: 7501,
		maxValue: 15000
	}
];

const BadgeContainer = () => {
	const [blocksCreated, setBlocksCreated] = useState(0);
	const { user } = useUser();

	const percentageCompleted = (num: number, total: number) => Math.min(num / total, 1) * 100;

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await getUserBlocksCount(user._id as string);
				if (data.totalBlocksByUser) {
					setBlocksCreated(data.totalBlocksByUser);
				}
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		// fetching no of blocks created by user
		fetchData();
	}, [user._id]);
	return (
		<div className="badges-container">
			{badges.map((badge) => {
				const isDisabled = blocksCreated < badge.minValue;
				return (
					<Badge
						key={badge.title}
						isDisabled={isDisabled}
						value={isDisabled ? 0 : percentageCompleted(blocksCreated, badge.maxValue)}
						pathColor="#4F00C1"
						trailColor="#D6D6D6"
						title={badge.title}
						counterClockwise
						subTitle={badge.subTitle}
						badgeContent={
							percentageCompleted(blocksCreated, badge.maxValue) === 100
								? badge.imgSrc
								: badge.imgSrcDisabled
						}
					/>
				);
			})}
		</div>
	);
};
export default BadgeContainer;
