import { IFeedback, IUserPreferenceNode, TActionItemFor } from '@naya_studio/types';
import mongoose from 'mongoose';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';
import { CustomDispatch } from 'src/redux/actions/types';
import { editUserPreferences } from 'src/redux/actions/user';
import useUser from 'src/redux/hooks/user';
import { ISnackBar, ReduxState } from 'src/redux/reducers/root.types';
import {
	addUserToActionItem,
	deleteComment,
	editComment,
	removeUserFromActionItem,
	votePoll
} from 'src/redux/reduxActions/feedbacks';
import { generateIdsFromUrl, getFeedbackBasedOnBlockType } from 'src/redux/reduxActions/util';
import {
	TAddUserToActionItemThunkArg,
	TDeleteCommentThunkArg,
	TEditCommentThunkArg,
	TEditUserPreferenceThunkArgs,
	TRemoveUserFromActionItemThunkArg
} from 'src/types/argTypes';

/**
 * Hook to handle all the feedbacl related actions
 * @returns actions related to feedbacks
 */
const useFeedbackActions = () => {
	const { user } = useUser();
	const allFeedbacks = useSelector((state) => (state as ReduxState).feedbacks.data, shallowEqual);
	const preferences = useSelector(
		(state) => (state as ReduxState).user.data.userPreferences as IUserPreferenceNode,
		shallowEqual
	);
	const { blockId, stageId, projectId } = generateIdsFromUrl();

	const dispatch = useDispatch();

	// Function to extract action item for of a given feedback id
	const getFeedbackActionItemFor = (id: string) => {
		const feedback = allFeedbacks[id];
		return feedback?.actionItemFor as TActionItemFor;
	};

	// function to send edit feedback api call
	const sendEditFeedback = (feedback: Partial<IFeedback>, parentCommentId?: string) => {
		// extract old feedback to revert in case api fails
		const oldComments = getFeedbackBasedOnBlockType(blockId);

		// payload
		const apiPayload: TEditCommentThunkArg = {
			payload: {
				userId: user._id,
				projectId,
				stageId,
				feedback,
				blockId,
				parentCommentId
			},
			prevState: {
				oldComments
			}
		} as TEditCommentThunkArg;

		// send api action call
		dispatch(editComment(apiPayload));
	};

	// function to check if user has turned off the action item toggle warning
	const checkIfUserTurnerOffActionItemToggleWarning = (pId: string) => {
		const noActionItemToggleWArning = preferences.noActionItemToggleWArning
			? [...preferences.noActionItemToggleWArning]
			: [];
		if (noActionItemToggleWArning.includes(pId)) {
			return true;
		}
		return false;
	};

	// function to set no action item toggle warning preference
	const setNoActionItemToggleWarning = (pId: string) => {
		const existingPreferences = preferences.noActionItemToggleWArning
			? preferences.noActionItemToggleWArning
			: [];
		existingPreferences.push(pId);

		const actionPayload: TEditUserPreferenceThunkArgs = {
			payload: {
				noActionItemToggleWArning: existingPreferences
			},
			prevState: {
				preferences
			}
		};
		dispatch(editUserPreferences(actionPayload));
	};

	// function to send out delete feedback dispatch action
	const sendDeleteFeedback = (feedbackId: string) => {
		// extract old feedback to revert in case api fails
		const oldComments = getFeedbackBasedOnBlockType(blockId);

		const apiPayload: TDeleteCommentThunkArg = {
			payload: {
				_id: feedbackId,
				blockId
			},
			prevState: {
				oldComments
			}
		};
		dispatch(deleteComment(apiPayload));
	};

	// Function to generate optimistoc feedback
	const generateOptimisticFeedback = () => {
		const data: IFeedback = {
			_id: new mongoose.Types.ObjectId().toString() as string,
			createdBy: user._id as string,
			isCompleted: false,
			lastUpdatedBy: user._id as string,
			taggedUsers: [],
			replies: [],
			answers: [],
			pollOptions: [],
			tags: [],
			blockId,
			projectId,
			origin: 'NAYA'
		};
		return data;
	};

	// Function to extract tagged users from html string for feedback
	const getTaggedUsersFromHtmlString = (htmlString: string) => {
		const doc = new DOMParser().parseFromString(htmlString, 'text/html');
		const elems = doc.getElementsByClassName('mention');
		const taggedUsers: string[] = [];
		if (elems) {
			for (let i = 0; i < elems.length; i++) {
				const elem = elems[i];
				taggedUsers.push(elem?.getAttribute('data-id') as string);
			}
		}
		return taggedUsers;
	};

	// function to add action item assignee
	const addActionItemAssignee = (feedbackId: string, userId: string) => {
		const actionPayload: TAddUserToActionItemThunkArg = {
			payload: {
				feedbackId,
				userId
			}
		};

		(dispatch as CustomDispatch)(addUserToActionItem(actionPayload))
			.unwrap()
			.then(() => {})
			.catch(() => {
				const snackbarPayload: ISnackBar = {
					text: 'Failed to add user to action item.',
					show: true,
					type: 'ERROR'
				};

				addSnackbar(snackbarPayload);
				removeSnackbar(2000);
			});
	};

	// function to remove action item assignee
	const removeActionItemAssignee = (feedbackId: string, userId: string) => {
		const actionPayload: TRemoveUserFromActionItemThunkArg = {
			payload: {
				feedbackId,
				userId
			},
			prevUsers: getFeedbackActionItemFor(feedbackId)
		};

		(dispatch as CustomDispatch)(removeUserFromActionItem(actionPayload))
			.unwrap()
			.then(() => {})
			.catch(() => {
				const snackbarPayload: ISnackBar = {
					text: 'Failed to remove user from action item.',
					show: true,
					type: 'ERROR'
				};

				addSnackbar(snackbarPayload);
				removeSnackbar(2000);
			});
	};

	/**
	 * Function to dispatch the add vote
	 * @param payload {option: string, feedbackId: string}
	 */
	const sendVotePoll = (payload: { option: string; feedbackId: string }) => {
		const userId = user._id as string;
		dispatch(votePoll({ payload: { ...payload, userId } }));
	};

	/**
	 * Function to extract the plain text from html string
	 * @param html
	 * @returns
	 */
	const extractTextFromHTML = (html: string) => {
		const customSpan = document.createElement('span');
		customSpan.innerHTML = html;
		const data = customSpan.textContent || customSpan.innerText;
		return data;
	};

	return {
		sendEditFeedback,
		checkIfUserTurnerOffActionItemToggleWarning,
		setNoActionItemToggleWarning,
		sendDeleteFeedback,
		generateOptimisticFeedback,
		getTaggedUsersFromHtmlString,
		addActionItemAssignee,
		removeActionItemAssignee,
		sendVotePoll,
		extractTextFromHTML
	};
};

export default useFeedbackActions;
