import { IAction, UndoRedoActionTypes } from "../root.types";
import { TActionItem } from "./undoActionHistory.types";

const initialState:Array<Array<TActionItem>> = [];

const redoActionReducer = (state = initialState, action: IAction<UndoRedoActionTypes | keyof typeof UndoRedoActionTypes>) => {

  let newState: Array<Array<TActionItem>>
  const actionItem: Array<Array<TActionItem>> = action.payload

  switch (action.type) {
    case 'ADD_REDO_ACTION':
      newState = actionItem
      break;
    case 'EMPTY_REDO_ACTIONS':
      newState = [];
      break;
    default:
      newState = state
      break
  }

  return newState
}

export default redoActionReducer