import { ControlModal } from '@naya_studio/radix-ui';
import { ReactElement } from 'react';
import { ReactComponent as CloseIcon } from 'src/assets/icons/close.svg';

type AppModalProps = {
    open: boolean,
    children: ReactElement,
    close: () => void,
    width: string,
    height: string,
};

/**
 * The Modal to display the AI tool and its corresponding data
 */
const AppModal = ({ open, children, close, width, height}: AppModalProps) => {
    return (
        <ControlModal open={open}>
            <ControlModal.ContentWrap width={width} height={height} close={close}>
                <ControlModal.Close>
                    <button
                        type="button"
                        onClick={close}
                        className={`tw-cursor-pointer IconButton tw-h-6 tw-w-6 tw-top-2.5 tw-right-2.5 tw-rounded-full tw-font-inherit 
                            tw-inline-flex tw-items-center tw-justify-center tw-absolute tw-outline-none`}
                        aria-label="Close"
                    >
                        <CloseIcon />
                    </button>
                </ControlModal.Close>
                <ControlModal.Content>
                    {children}
                </ControlModal.Content>
            </ControlModal.ContentWrap>
        </ControlModal>
    );
};

export default AppModal;