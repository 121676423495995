import {Skeleton as MUISkeleton} from '@mui/material';
import './Skeleton.scss';
/**
@component
Creates a sharing modal skeleton
* */
const Skeleton = () => (
	<div className="sharing-gap tw-p-2 tw-my-6">
		<MUISkeleton
			variant="rounded"
			height={24}
			sx={{ borderRadius: '1.5rem', border: '1px solid #EAEAEA' }}
			className="tw-mx-6"
		/>
		<MUISkeleton
			variant="rounded"
			height={1}
			sx={{ borderRadius: '1.5rem', border: '1px solid #EAEAEA' }}
		/>
		<div className="tw-mx-6">
			<div className="tw-mb-2 tw-flex">
				<MUISkeleton variant="circular" width={18} height={18} />
				<MUISkeleton variant="rounded" height={18} sx={{ marginLeft: '4px', flex: 0.6 }} />
			</div>
			<div className="tw-flex">
				<MUISkeleton
					variant="rounded"
					height={40}
					sx={{ borderRadius: '1.5rem', border: '1px solid #EAEAEA', flex: 3 }}
				/>
				<MUISkeleton
					variant="rounded"
					height={40}
					sx={{
						borderRadius: '1.5rem',
						border: '1px solid #EAEAEA',
						flex: 1,
						marginLeft: '8px'
					}}
				/>
			</div>
		</div>
		<div className="tw-mx-6 tw-gap-4 tw-flex tw-flex-col">
			<div className="tw-flex tw-items-center tw-justify-between">
				<div className="tw-flex tw-items-center">
					<MUISkeleton variant="circular" width={32} height={32} />
					<MUISkeleton
						variant="rounded"
						width={150}
						height={32}
						sx={{ marginLeft: '16px' }}
					/>
				</div>
				<MUISkeleton variant="rounded" width={82} height={16} />
			</div>
			<div className="tw-flex tw-items-center tw-justify-between">
				<div className="tw-flex tw-items-center">
					<MUISkeleton variant="circular" width={32} height={32} />
					<MUISkeleton
						variant="rounded"
						width={150}
						height={32}
						sx={{ marginLeft: '16px' }}
					/>
				</div>
				<MUISkeleton variant="rounded" width={82} height={16} />
			</div>
			<div className="tw-flex tw-items-center tw-justify-between">
				<div className="tw-flex tw-items-center">
					<MUISkeleton variant="circular" width={32} height={32} />
					<MUISkeleton
						variant="rounded"
						width={150}
						height={32}
						sx={{ marginLeft: '16px' }}
					/>
				</div>
				<MUISkeleton variant="rounded" width={82} height={16} />
			</div>
		</div>
		<MUISkeleton
			variant="rounded"
			height={16}
			sx={{ borderRadius: '1.5rem', border: '1px solid #EAEAEA' }}
			className="tw-mx-6"
		/>
		<MUISkeleton
			variant="rounded"
			height={24}
			sx={{ borderRadius: '1.5rem', border: '1px solid #EAEAEA' }}
			className="tw-mx-6"
		/>
		<MUISkeleton
			variant="rounded"
			height={1}
			sx={{ borderRadius: '1.5rem', border: '1px solid #EAEAEA' }}
		/>
		<MUISkeleton
			variant="rounded"
			height={24}
			sx={{ borderRadius: '1.5rem', border: '1px solid #EAEAEA' }}
			className="tw-mx-6"
		/>
	</div>
);

export default Skeleton;
