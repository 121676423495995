import * as PIXI from 'pixi.js';
import { SVG } from 'pixi-svg';
import BaseNode from './BaseNode';
import Shape from './Shape';

class Polygon extends Shape {
  rotate = () => {
    if (this.displayObject) {
      const x = this.displayObject.width / 2;
      const y = this.displayObject.height / 2;
      // console.log("previous pivot:", this.displayObject.pivot)

      this.displayObject.pivot.x = x;
      this.displayObject.pivot.y = y;

      // console.log('pivot:',this.displayObject.pivot)

      if (this.displayObject.rotation >= 6) {
        this.displayObject!.rotation = Math.floor(this.displayObject!.rotation) % 6;
      }
      this.displayObject!.rotation += 0.785398;
    }
  };

  /**
     * Draw the shape as per the selected shape type
     */
  draw = () => {
    if (this.displayObject) {
      this.app.viewport.removeChild(this.displayObject);
    }

    const container = new PIXI.Container();
    const fillContainer = new PIXI.Container();
    const strokeContainer = new PIXI.Container();
    const shape = new PIXI.Graphics();
    shape.name = 'POLYGON';

    if (this.nodeData) {
      const {
        absoluteBounds, scale, rotation, fill, shapeType, zIndex,
      } = this.nodeData;

      if (!absoluteBounds!.width || !absoluteBounds!.height || !absoluteBounds) { return; }

      if (absoluteBounds.height < 10 || absoluteBounds.width < 10) {
        absoluteBounds.height = 10;
        absoluteBounds.width = 10;
      }
      if (scale) {
        if (scale.scaleX) {
          absoluteBounds.width *= scale.scaleX!;
          this.nodeData.scale!.scaleX = 1;
        }
        if (scale.scaleY) {
          absoluteBounds.height *= scale.scaleY!;
          this.nodeData.scale!.scaleY = 1;
        }
      }
      const strokeData = JSON.parse(JSON.stringify(this.nodeData.stroke));

      if (fill?.fillColor === 'NO_FILL') {
        shape.beginFill(BaseNode.getColorValue(fill.fillColor), 1e-4);
      } else {
        shape.beginFill(BaseNode.getColorValue(fill?.fillColor), fill?.fillOpacity);
      }
      if (strokeData.strokeColor !== 'NO_STROKE') {
        shape.lineStyle(
          strokeData.strokeWidth!,
          BaseNode.getColorValue(strokeData.strokeColor),
          strokeData.strokeOpacity,
          0,
        );
      }

      switch (shapeType) {
        case 'RECTANGLE':
          if (absoluteBounds) {
            shape.drawRect(0, 0, absoluteBounds.width!, absoluteBounds.height!);
            shape.endFill();
          }
          break;

        case 'ELLIPSE':
          if (absoluteBounds) {
            shape.drawEllipse(
              absoluteBounds.width! / 2,
              absoluteBounds.height! / 2,
              absoluteBounds.width! / 2,
              absoluteBounds.height! / 2,
            );
            shape.endFill();
            // shape.x += absoluteBounds.width! / 2;
            // shape.y += absoluteBounds.height! / 2;
          }
          break;
        case 'ROUNDED_RECTANGLE':
          if (absoluteBounds) {
            shape.drawRoundedRect(0, 0, absoluteBounds.width!, absoluteBounds.height!, 10);
            shape.endFill();
          }
          break;
        case 'TRIANGLE':
          if (absoluteBounds) {
            shape.drawPolygon(
              0 + absoluteBounds.width / 2,
              0,
              0,
              absoluteBounds.height!,
              absoluteBounds.width!,
              absoluteBounds.height!,
            );
            shape.endFill();
          }
          break;
        case 'STAR':
          if (absoluteBounds) {
            const arr = [];
            let x = 0;
            let y = 0;
            let xOffset = 0;
            let yOffset = 0;
            const R = absoluteBounds.width! / 2;
            const r = absoluteBounds.width! / 4;

            for (let i = 0; i < 5; i++) {
              // The coordinates of each point protruding from the periphery
              x = Math.cos(((18 + 72 * i) / 180) * Math.PI) * R;

              // The positive direction of the y-axis in the
              // canvas is opposite to the rectangular coordinate system
              y = -Math.sin(((18 + 72 * i) / 180) * Math.PI) * R;
              arr.push(x);
              arr.push(y);
              xOffset = Math.max(xOffset, Math.abs(x));
              yOffset = Math.max(yOffset, Math.abs(y));
              // The coordinates of each point where the periphery is recessed
              x = Math.cos(((54 + 72 * i) / 180) * Math.PI) * r;
              xOffset = Math.max(xOffset, Math.abs(x));
              // The positive direction of the y-axis in the
              // canvas is opposite to the rectangular coordinate system
              y = -Math.sin(((54 + 72 * i) / 180) * Math.PI) * r;
              yOffset = Math.max(yOffset, Math.abs(y));
              arr.push(x);
              arr.push(y);
            }

            shape.drawPolygon(arr);
            shape.endFill();
            shape.x += xOffset;
            shape.y += yOffset;
          }
          break;
        case 'HEART': {
          const tempScaleX = absoluteBounds.width / 131;
          const tempScaleY = absoluteBounds.height / 119;

          const strokeStr = `<svg width=${absoluteBounds.width} height=${absoluteBounds.height} viewBox="0 0
            ${absoluteBounds.width} ${absoluteBounds.height}" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="
            M ${34.75 * tempScaleX} ${4 * tempScaleY} 
            C ${17.7699 * tempScaleX} ${4 * tempScaleY} 
              ${4 * tempScaleX} ${17.7556 * tempScaleY} 
              ${4 * tempScaleX} ${34.7267 * tempScaleY} 
            C ${4 * tempScaleX} ${48.4264 * tempScaleY}  
              ${9.38125 * tempScaleX} ${80.9408 * tempScaleY}  
              ${62.3512 * tempScaleX} ${113.809 * tempScaleY} 
            C ${63.3 * tempScaleX} ${114.392 * tempScaleY}  
              ${64.3893 * tempScaleX} ${114.7 * tempScaleY}  
              ${65.5 * tempScaleX} ${114.7 * tempScaleY} 
            C ${66.6107 * tempScaleX} ${114.7 * tempScaleY}  
              ${67.7 * tempScaleX} ${114.392 * tempScaleY}  
              ${68.6488 * tempScaleX} ${113.809 * tempScaleY} 
            C ${121.619 * tempScaleX} ${80.9408 * tempScaleY}  
              ${127 * tempScaleX} ${48.4264 * tempScaleY}  
              ${127 * tempScaleX} ${34.7267 * tempScaleY} 
            C ${127 * tempScaleX} ${17.7556 * tempScaleY}  
              ${113.23 * tempScaleX} ${4 * tempScaleY}  
              ${96.25 * tempScaleX} ${4 * tempScaleY} 
            C ${79.2699 * tempScaleX} ${4 * tempScaleY}  
              ${65.5 * tempScaleX} ${22.6222 * tempScaleY}  
              ${65.5 * tempScaleX} ${22.6222 * tempScaleY} 
            C ${65.5 * tempScaleX} ${22.6222 * tempScaleY}  
              ${51.7302 * tempScaleX} ${4 * tempScaleY}  
              ${34.75 * tempScaleX} ${4 * tempScaleY}Z"
            stroke="${strokeData.strokeColor === 'NO_STROKE' ? '"none"' : strokeData.strokeColor}"
            stroke-width=${strokeData.strokeColor === 'NO_STROKE' ? 0 : strokeData.strokeWidth * 2}
            stroke-linecap="round" stroke-linejoin="round"
            fill="#FFFFFF"
            opacity=0/>
            </svg>`;
          const strokeSvg = new SVG(strokeStr);

          const fillStr = `<svg width=${absoluteBounds.width} height=${absoluteBounds.height} viewBox="0 0
            ${absoluteBounds.width} ${absoluteBounds.height}" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="
            M ${34.75 * tempScaleX} ${4 * tempScaleY} 
            C ${17.7699 * tempScaleX} ${4 * tempScaleY} 
              ${4 * tempScaleX} ${17.7556 * tempScaleY} 
              ${4 * tempScaleX} ${34.7267 * tempScaleY} 
            C ${4 * tempScaleX} ${48.4264 * tempScaleY} 
              ${9.38125 * tempScaleX} ${80.9408 * tempScaleY} 
              ${62.3512 * tempScaleX} ${113.809 * tempScaleY} 
            C ${63.3 * tempScaleX} ${114.392 * tempScaleY} 
              ${64.3893 * tempScaleX} ${114.7 * tempScaleY} 
              ${65.5 * tempScaleX} ${114.7 * tempScaleY} 
            C ${66.6107 * tempScaleX} ${114.7 * tempScaleY} 
              ${67.7 * tempScaleX} ${114.392 * tempScaleY} 
              ${68.6488 * tempScaleX} ${113.809 * tempScaleY} 
            C ${121.619 * tempScaleX} ${80.9408 * tempScaleY} 
              ${127 * tempScaleX} ${48.4264 * tempScaleY} 
              ${127 * tempScaleX} ${34.7267 * tempScaleY} 
            C ${127 * tempScaleX} ${17.7556 * tempScaleY} 
              ${113.23 * tempScaleX} ${4 * tempScaleY} 
              ${96.25 * tempScaleX} ${4 * tempScaleY} 
            C ${79.2699 * tempScaleX} ${4 * tempScaleY} 
              ${65.5 * tempScaleX} ${22.6222 * tempScaleY} 
              ${65.5 * tempScaleX} ${22.6222 * tempScaleY}
            C ${65.5 * tempScaleX} ${22.6222 * tempScaleY} 
              ${51.7302 * tempScaleX} ${4 * tempScaleY} 
              ${34.75 * tempScaleX} ${4 * tempScaleY}Z"
            fill=${fill?.fillColor === 'NO_FILL' ? '"none"' : fill?.fillColor}/>
            </svg>`;
          const fillSvg = new SVG(fillStr);

          const fillShape = fillContainer.addChild(fillSvg);
          fillShape.alpha = fill?.fillOpacity !== undefined ? fill.fillOpacity : 1;

          const strokeShape = strokeContainer.addChild(strokeSvg);
          strokeShape.alpha = strokeData.strokeOpacity !== undefined ? strokeData.strokeOpacity : 1;

          container.addChild(strokeContainer, fillContainer);
          break;
        }
        default:
          break;
      }
      // if (this.nodeData.opacity) {
      //   shape.alpha = this.nodeData.opacity;
      // }

      this.displayObject = container;
      if (shapeType !== 'HEART') {
        this.displayObject.addChild(shape);
      }
      this.displayObject.setTransform(absoluteBounds.x, absoluteBounds.y, 0, 0, rotation);

      this.displayObject.zIndex = zIndex! || 12;
      this.displayObject.name = 'SHAPE';

      // this.displayObject.cacheAsBitmap = true;
      // this.displayObject.cacheAsBitmapResolution = 1
      // this.displayObject.cacheAsBitmapMultisample = PIXI.MSAA_QUALITY.HIGH;

      // shape, markup, comment, and reaction should NOT be interactive
      this.displayObject.interactive = this.app.checkIfNodesShouldBeInteractive();

      // this.displayObject.on('click', this.onClick);
      if (this._interactive) {
        this.displayObject.on('mousedown', this.onMouseDown);
        this.displayObject.on('mouseup', this.onMouseUp);
        this.displayObject.on('mouseover', this.onMouseOver);
        this.displayObject.on('mouseout', this.onMouseOut);
        this.displayObject.on('rightclick', this.rightClick);
      }
      this.app.viewport.addChild(this.displayObject);
    }
  };
}

export default Polygon;