import { useDispatch, useSelector } from 'react-redux';
import { editUser } from 'src/redux/actions/user';
import { updateIntegration } from 'src/redux/features/integration';
import useUser from 'src/redux/hooks/user';
import {
	EIntegrationType,
	ISnackBar,
	ReduxState,
	TIntegrations
} from 'src/redux/reducers/root.types';
import {
	GOOGLE_AUTH_URL,
	folderIdExistsInGoogle,
	revokeGoogle,
	stopAllDriveChannels
} from 'src/redux/reduxActions/integrations/googleIntegrations';
import {
	authenticationModalConfig,
	createIntegrationFile,
	duplicateLinkAsset,
	integrationSubTypeValue
} from 'src/redux/reduxActions/integrations/utils';
import { generateIdsFromUrl } from 'src/redux/reduxActions/util';
import { useCallback, useEffect, useRef, useState } from 'react';
import checkTokenExpiration from 'src/redux/reduxActions/integrations/refreshTokens';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';
import { getFileLinkSubType } from 'src/components/utilities/navbar/utils';
import { MIRO_AUTH_URL, revokeMiro } from 'src/redux/reduxActions/integrations/miroIntegrations';
import { TEditBlockFnArgs } from '@naya_studio/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import getUserFromRedux, { getGoogleTokensFromRedux, getMiroTokensFromRedux } from '../helper/user';
import { saveUserInLocalForage } from '../storage/indexedDBStorage';
import useBlockActions from '../block/useBlockActions';
import { getProjectSyncObserver, linkProjectToGoogleDrive } from '../helper/project';

// Supported GOOGLE types
export type TGoogleSubtype = 'SHEET' | 'SLIDE' | 'DOC';
// Integration action types
type TActionType = 'CREATE' | 'DUPLICATE' | 'CONVERT';

type TIntegrationPayload = {
	integrationType: TIntegrations;
	actionType?: TActionType;
	actionData?: TCreateFileParams | TDuplicateFileParams;
};

export type TCreateIntegrationFile = {
	integrationType: TIntegrations;
	subType?: TGoogleSubtype;
	projectId?: string;
	folderId?: string;
	fileName?: string;
};

type TCreateFileParams = {
	integrationType: TIntegrations;
	subType?: TGoogleSubtype;
	options?: {
		fileName?: string;
		blockId?: string;
		phaseId?: string;
		newBlockIndex?: number;
		newPhaseIndex?: number;
	};
};
type TDuplicateFileParams = {
	link: string;
	newFileName: string;
	phaseId: string;
	newBlockId: string;
	newBlockIndex: number;
};

/**
 * Snackbar message based on integration type
 */
const integrationSnackbarMsg: { [key in TIntegrations]: string } = {
	GOOGLE: 'Google',
	MIRO: 'Miro'
};

/**
 * Hook to handle all the integration related actions
 * @returns actions related to blocks
 */
const useIntegration = () => {
	const dispatch = useDispatch();
	const { user } = useUser();
	const { onAddBlocks, onBlockEdit } = useBlockActions();
	const { isSyncStorageEnabled, isSyncDriveEnabled } = useFlags();

	// used to show/hide integration auth modal
	const [showAuthModal, setShowAuthModal] = useState(false);

	// extracting access tokem details
	const { google, miro } = useSelector((state) => (state as ReduxState).integrations);

	// used to store action details while authentication is completed
	const integrationData = useRef<TIntegrationPayload | undefined>(undefined);

	// check for expiring google
	useEffect(() => {
		let intervalId: any = null;
		if (user.googleToken) {
			checkTokenExpiration(google, 'GOOGLE', user.googleToken);
			intervalId = setInterval(() => {
				checkTokenExpiration(
					getGoogleTokensFromRedux(),
					'GOOGLE',
					getUserFromRedux().googleToken
				);
			}, 300000); // 5 minutes
		}
		return () => clearInterval(intervalId);
	}, []);

	// check for expiring miro
	useEffect(() => {
		let intervalId: any = null;
		if (user.miroToken) {
			checkTokenExpiration(miro, 'MIRO', user.miroToken);
			intervalId = setInterval(() => {
				checkTokenExpiration(
					getMiroTokensFromRedux(),
					'MIRO',
					getUserFromRedux().miroToken
				);
			}, 300000); // 5 minutes
		}
		return () => clearInterval(intervalId);
	}, []);

	/**
	 * Function to create integrated file and add LINK block
	 * @param createFileData
	 * 	@param {string} createFileData.integrationType - integration type
	 * 	@param {string} createFileData.subtype - integration subtype
	 * 	@param createFileData.options - options to add new block
	 * @param access_token
	 */
	const createFile = async (
		{ integrationType, subType, options }: TCreateFileParams,
		access_token: string
	) => {
		try {
			const { projectId } = generateIdsFromUrl();

			// show loader while creating new file
			const payload: ISnackBar = {
				text: `Creating ${integrationSnackbarMsg[integrationType]} ${
					subType ? integrationSubTypeValue[subType] : ''
				}`,
				show: true,
				type: 'LOADER'
			};

			addSnackbar(payload);

			const observer = getProjectSyncObserver(projectId, 'GOOGLE_DRIVE');

			let folderId = observer?.folderId;
			const isGoogleIntegration = integrationType === 'GOOGLE';
			// if folderId absent or folder not found

			if (
				isGoogleIntegration &&
				(!isSyncStorageEnabled ||
					!isSyncDriveEnabled ||
					!observer?.channelDetails?.channelId ||
					!(await folderIdExistsInGoogle(
						google.access_token,
						observer?.folderId as string
					)))
			)
				folderId = await linkProjectToGoogleDrive(access_token, folderId as string);

			const createdFileLink = await createIntegrationFile(
				{
					integrationType,
					subType,
					projectId,
					folderId: folderId as string
				},
				access_token
			);

			if (!createdFileLink?.status) {
				const snackbarPayload: ISnackBar = {
					text:
						createdFileLink?.error ||
						`Failed to create ${integrationSnackbarMsg[integrationType]} ${
							subType ? integrationSubTypeValue[subType] : ''
						}`,
					show: true,
					type: 'ERROR'
				};

				addSnackbar(snackbarPayload);
				removeSnackbar(3000);
			} else {
				// Create a new block with link
				await onAddBlocks({
					blocksToAdd: [
						{
							addType: 'LINK',
							blockId: options?.blockId as string,
							payload: createdFileLink.link,
							googleResourceId: isGoogleIntegration ? createdFileLink.id : '',
							googleResourcePath: isGoogleIntegration ? `/${folderId}` : ''
						}
					],
					options: {
						phaseId: options?.phaseId,
						newBlockIndex: options?.newBlockIndex,
						newPhaseIndex: options?.newPhaseIndex
					}
				});
				removeSnackbar(0);
			}
		} catch (e: any) {
			console.error('createFile error: ', e);
			const payload: ISnackBar = {
				text: `Failed to create ${integrationSnackbarMsg[integrationType]} ${
					subType ? integrationSubTypeValue[subType] : ''
				}`,
				show: true,
				type: 'ERROR'
			};

			addSnackbar(payload);
			removeSnackbar(3000);
		}
	};

	/**
	 * Function to create a new integration file and convert empty block
	 * @param convertFileData
	 * 	@param {string} convertFileData.integrationType - integration type
	 * 	@param {string} convertFileData.subtype - integration subtype
	 * 	@param convertFileData.options - options to add new block
	 * @param access_token
	 */
	const convertBlock = async (
		{ integrationType, subType, options }: TCreateFileParams,
		access_token: string
	) => {
		try {
			// Fetch the project from redux, don't use useProject hook to access project
			// Since doing so will send api calls to access project with id as studio on studio tab
			const { projectId } = generateIdsFromUrl();

			// show loader while creating new file
			const payload: ISnackBar = {
				text: `Creating ${integrationSnackbarMsg[integrationType]} ${
					subType ? integrationSubTypeValue[subType] : ''
				}`,
				show: true,
				type: 'LOADER'
			};

			addSnackbar(payload);
			const observer = getProjectSyncObserver(projectId, 'GOOGLE_DRIVE');
			let folderId = observer?.folderId;
			const isGoogleIntegration = integrationType === 'GOOGLE';
			if (
				isGoogleIntegration &&
				(!isSyncStorageEnabled ||
					!isSyncDriveEnabled ||
					!observer?.channelDetails?.channelId ||
					!(await folderIdExistsInGoogle(
						google.access_token,
						observer?.folderId as string
					)))
			)
				folderId = await linkProjectToGoogleDrive(access_token, folderId as string);

			const createdFileLink = await createIntegrationFile(
				{
					integrationType,
					subType,
					projectId,
					folderId: folderId as string,
					fileName: options?.fileName
				},
				access_token
			);
			if (!createdFileLink?.status) {
				const snackbarPayload: ISnackBar = {
					text:
						createdFileLink?.error ||
						`Failed to create ${integrationSnackbarMsg[integrationType]} ${
							subType ? integrationSubTypeValue[subType] : ''
						}`,
					show: true,
					type: 'ERROR'
				};

				addSnackbar(snackbarPayload);
				removeSnackbar(3000);
			} else {
				// Edit block with link
				const editPayload: TEditBlockFnArgs = {
					editType: 'ADD_LINK',
					blockId: options?.blockId as string,
					payload: {
						link: createdFileLink.link,
						googleResourceId: isGoogleIntegration ? createdFileLink.id : '',
						googleResourcePath: isGoogleIntegration ? `/${folderId}` : ''
					}
				};
				await onBlockEdit(editPayload);
				removeSnackbar(0);
			}
		} catch (e: any) {
			console.error(e.message);
			const payload: ISnackBar = {
				text: `Failed to create ${integrationSnackbarMsg[integrationType]} ${
					subType ? integrationSubTypeValue[subType] : ''
				}`,
				show: true,
				type: 'ERROR'
			};

			addSnackbar(payload);
			removeSnackbar(3000);
		}
	};

	/**
	 * Function to duplicate integrated link block
	 * @param data
	 * 	@param data.link - link to be duplicated
	 *  @param data.newFileName - name of the duplicated asset
	 * 	@param data.folderName - name of the folder to store duplicated asset
	 * 	@param data.phaseId - phase ID for the new block
	 * 	@param data.newBlockId - ID of the new block
	 * 	@param data.newBlockIndex - index of the new block
	 */
	const duplicateFile = async ({
		link,
		newFileName,
		phaseId,
		newBlockId,
		newBlockIndex
	}: TDuplicateFileParams) => {
		// Fetch the project from redux, don't use useProject hook to access project
		// Since doing so will send api calls to access project with id as studio on studio tab
		const { projectId } = generateIdsFromUrl();

		// show loading snackbar while link duplicates
		addSnackbar({
			show: true,
			type: 'LOADER',
			text: 'Duplicating link asset',
			actionButtonData: []
		});

		const linkType = getFileLinkSubType(link, 'LINK');
		const isGoogleFile = linkType?.includes('GOOGLE');

		const observer = getProjectSyncObserver(projectId, 'GOOGLE_DRIVE');
		let folderId = observer?.folderId;
		if (
			isGoogleFile &&
			(!isSyncStorageEnabled ||
				!isSyncDriveEnabled ||
				!observer?.channelDetails?.channelId ||
				!(await folderIdExistsInGoogle(google.access_token, observer?.folderId as string)))
		)
			folderId = await linkProjectToGoogleDrive(google.access_token, folderId as string);

		// duplicating file based on link type
		const duplicatedFileLink = await duplicateLinkAsset(link, newFileName, folderId as string);

		// show success if link asset duplicated
		if (duplicatedFileLink.status) {
			addSnackbar({
				show: true,
				type: 'NORMAL',
				text: 'Duplicate link asset generated.',
				actionButtonData: []
			});
			removeSnackbar(3000);

			// add a new block of type LINK with the link of the duplicated file
			onAddBlocks({
				blocksToAdd: [
					{
						addType: 'LINK',
						blockId: newBlockId,
						payload: duplicatedFileLink.link,
						googleResourceId: isGoogleFile ? duplicatedFileLink.id : '',
						googleResourcePath: isGoogleFile ? `/${folderId}` : ''
					}
				],
				options: {
					phaseId,
					newBlockIndex
				}
			});
		} else {
			// show error snackbar when link asset duplication fails
			addSnackbar({
				show: true,
				type: 'ERROR',
				text: duplicatedFileLink.error || 'Failed duplicating link asset',
				actionButtonData: []
			});
			removeSnackbar(3000);
		}
	};

	/**
	 * Function to generate payload to update refresh token for user
	 * @param integrationType
	 * @param refresh_token
	 * @returns
	 */
	const integrationTypeBasedPayload = (integrationType: TIntegrations, refresh_token: string) => {
		switch (integrationType) {
			case 'GOOGLE':
				return { googleToken: refresh_token };
			case 'MIRO':
				return { miroToken: refresh_token };
			default:
				return {};
		}
	};

	/**
	 * @param {Object} data
	 *   @param {string} data.link - link of the asset to be duplicated
	 *   @param {string} data.newFileName - Name of the duplicated asset
	 *   @param {string} data.folderName - Name of the folder to store duplicated asset
	 *   @param {string} data.phaseId - ID of the phase
	 *   @param {number} data.newBlockIndex - Index of the new block
	 *   @param {string} data.newBlockId - ID of the new block
	 */
	const onDuplicateLinkAsset = useCallback(
		async (data: TDuplicateFileParams) => {
			const { link } = data;
			const linkSubType = getFileLinkSubType(link, 'LINK');
			switch (linkSubType) {
				case 'MIRO': {
					const { miroToken } = user;
					if (miroToken) duplicateFile(data);
					else {
						integrationData.current = {
							integrationType: 'MIRO',
							actionType: 'DUPLICATE',
							actionData: data
						};
						setShowAuthModal(true);
					}
					break;
				}
				case 'GOOGLE_DOCS':
				case 'GOOGLE_DRIVE':
				case 'GOOGLE_SHEETS':
				case 'GOOGLE_SLIDES': {
					const { googleToken } = user;
					if (googleToken) duplicateFile(data);
					else {
						integrationData.current = {
							integrationType: 'GOOGLE',
							actionType: 'DUPLICATE',
							actionData: data
						};
						setShowAuthModal(true);
					}
					break;
				}
				default:
					break;
			}
		},
		[google, miro]
	);

	useEffect(() => {
		let popupRef: any = null;
		let timer: NodeJS.Timeout;
		let isModalOpen = showAuthModal;
		const integrationDetails = integrationData.current;

		// function to reset popup auth settings
		function onClosePopup() {
			popupRef.close();
			isModalOpen = false;
			setShowAuthModal(false);
			popupRef = null;
			clearInterval(timer);
			integrationData.current = undefined;
			removeSnackbar(0);
		}

		// function to handle messages from popup window
		async function receiveMessage(event: any) {
			// Ensure that the message is from the popup window
			if (event.source === popupRef && integrationDetails?.integrationType) {
				// Retrieve the href sent by the popup window
				const { href } = event.data;
				// Now you can work with the href of the popup window in the main window

				// extracting token data
				const { refresh_token, access_token, expires_in, createdAt } = JSON.parse(
					decodeURIComponent(
						href.split(
							`${EIntegrationType[integrationDetails?.integrationType]}_tokens=`
						)[1]
					)
				);

				const integrationPayload = integrationTypeBasedPayload(
					integrationDetails?.integrationType,
					refresh_token
				);

				// update refresh token data for the user
				dispatch(
					editUser({
						data: {
							userId: user._id as string,
							update: integrationPayload
						},
						prevState: user
					})
				);
				await saveUserInLocalForage({ ...user, ...integrationPayload });
				// update access token details in redux
				dispatch(
					updateIntegration({
						integrationType: integrationDetails?.integrationType,
						tokenData: { access_token, expires_in, createdAt }
					})
				);

				addSnackbar({
					show: true,
					type: 'NORMAL',
					text: (
						<span>
							{integrationSnackbarMsg[integrationDetails?.integrationType]} account
							connected. <br /> Heads-up: new{' '}
							{integrationSnackbarMsg[integrationDetails?.integrationType]} files are
							automatically created with “anyone with link can view” permissions in
							original file.
						</span>
					),
					actionButtonData: []
				});
				if (!integrationDetails?.actionType) removeSnackbar(6000);
				setTimeout(() => {
					// continue with any pending action data
					switch (integrationDetails?.actionType) {
						case 'CREATE':
							createFile(
								integrationDetails?.actionData as TCreateFileParams,
								access_token
							);
							break;
						case 'DUPLICATE':
							duplicateFile(integrationDetails?.actionData as TDuplicateFileParams);
							break;
						case 'CONVERT':
							convertBlock(
								integrationDetails?.actionData as TCreateFileParams,
								access_token
							);
							break;
						default:
							break;
					}
				}, 6000);

				// reset integration modal settings
				isModalOpen = false;
				setShowAuthModal(false);
				popupRef = null;
				integrationData.current = undefined;
				clearInterval(timer);
			}
		}
		if (showAuthModal && integrationDetails?.integrationType) {
			// select auth url based on integration
			let authUrl = '';
			switch (integrationDetails.integrationType) {
				case 'GOOGLE':
					authUrl = GOOGLE_AUTH_URL;
					break;
				case 'MIRO':
					authUrl = MIRO_AUTH_URL;
					break;
				default:
					break;
			}
			// show loading snackbar while connecting
			addSnackbar({
				show: true,
				type: 'LOADER',
				text: `Connecting ${
					integrationSnackbarMsg[integrationDetails.integrationType]
				} account`,
				actionButtonData: [
					{
						buttonData: 'Cancel',
						onClick: () => {
							removeSnackbar(0);
							isModalOpen = false;
						},
						className: 'snackbar-cancel',
						show: true
					}
				]
			});
			popupRef = window.open(authUrl, 'PopupWindow', authenticationModalConfig);

			// listen to messages from popup window
			window.addEventListener('message', receiveMessage);
		}

		if (popupRef) {
			// check if auth popup window has been closed by user every sec
			timer = setInterval(() => {
				if (popupRef.closed) {
					onClosePopup();
					clearInterval(timer);
					removeSnackbar(0);
				}
			}, 1000);

			// Clear the interval after 2 minutes
			setTimeout(() => {
				if (popupRef) {
					onClosePopup();
				}
			}, 120000); // 2 minutes in milliseconds
		}

		return () => {
			if (!isModalOpen) {
				window.removeEventListener('message', receiveMessage);
			}
		};
	}, [showAuthModal]);

	const onIntegration = useCallback(
		(
			actionType: TActionType,
			integrationType: TIntegrations,
			subType?: TGoogleSubtype,
			options?: {
				fileName?: string;
				blockId?: string;
				phaseId?: string;
				newBlockIndex?: number;
				newPhaseIndex?: number;
			}
		) => {
			const { googleToken, miroToken } = user;

			/**
			 * if user is already connected perform task based on actionType
			 * else store task to be completed after authentication
			 */
			switch (integrationType) {
				case 'GOOGLE': {
					if (googleToken && google.access_token) {
						switch (actionType) {
							case 'CREATE':
								createFile(
									{ integrationType, subType, options } as TCreateFileParams,
									google.access_token
								);
								break;
							case 'CONVERT':
								convertBlock(
									{ integrationType, subType, options } as TCreateFileParams,
									google.access_token
								);
								break;
							default:
								break;
						}
					} else {
						integrationData.current = {
							integrationType,
							actionType,
							actionData: {
								integrationType,
								subType,
								options
							} as TCreateFileParams
						};
						setShowAuthModal(true);
					}
					break;
				}
				case 'MIRO': {
					if (miroToken && miro.access_token) {
						switch (actionType) {
							case 'CREATE':
								createFile(
									{ integrationType, subType, options } as TCreateFileParams,
									miro.access_token
								);
								break;
							case 'CONVERT':
								convertBlock(
									{ integrationType, subType, options } as TCreateFileParams,
									miro.access_token
								);
								break;
							default:
								break;
						}
					} else {
						integrationData.current = {
							integrationType,
							actionType,
							actionData: {
								integrationType,
								subType,
								options
							} as TCreateFileParams
						};
						setShowAuthModal(true);
					}
					break;
				}
				default:
					break;
			}
		},
		[google, miro]
	);

	/**
	 * Function to connect/disconnect integration
	 * @param integrationType
	 * @param isConnected
	 */
	const toggleAuth = async (integrationType: TIntegrations, isConnected: boolean) => {
		switch (integrationType) {
			case 'GOOGLE':
				if (isConnected) {
					// show disconnecting loader
					addSnackbar({
						show: true,
						type: 'LOADER',
						text: `Disconnecting ${integrationSnackbarMsg[integrationType]} account`,
						actionButtonData: []
					});
					// diconnect drive sync
					const { projectId } = generateIdsFromUrl();
					const observer = getProjectSyncObserver(projectId, 'GOOGLE_DRIVE');
					if (observer?.channelDetails?.channelId)
						await stopAllDriveChannels(user._id as string);

					// revoke access
					await revokeGoogle(google.access_token);
					addSnackbar({
						show: true,
						type: 'NORMAL',
						text: `${integrationSnackbarMsg[integrationType]} account successfully disconnected from Naya.`,
						actionButtonData: []
					});
					removeSnackbar(2000);
					// remove google refresh token in user
					dispatch(
						editUser({
							data: {
								userId: user._id as string,
								update: {
									googleToken: null
								}
							},
							prevState: user
						})
					);
					// remove google access token details
					dispatch(
						updateIntegration({
							integrationType: 'GOOGLE',
							tokenData: { access_token: '', expires_in: 0, createdAt: 0 }
						})
					);
				} else {
					integrationData.current = {
						integrationType
					};
					setShowAuthModal(true);
				}
				break;

			case 'MIRO':
				if (isConnected) {
					// show disconnecting loader
					addSnackbar({
						show: true,
						type: 'LOADER',
						text: `Disconnecting ${integrationSnackbarMsg[integrationType]} account`,
						actionButtonData: []
					});
					await revokeMiro(miro.access_token);
					await saveUserInLocalForage({ ...user, miroToken: null });
					addSnackbar({
						show: true,
						type: 'NORMAL',
						text: `${integrationSnackbarMsg[integrationType]} account successfully disconnected from Naya.`,
						actionButtonData: []
					});
					removeSnackbar(2000);
					// remove miro refresh token in user
					dispatch(
						editUser({
							data: {
								userId: user._id as string,
								update: {
									miroToken: null
								}
							},
							prevState: user
						})
					);
					// remove miro access token details
					dispatch(
						updateIntegration({
							integrationType: 'MIRO',
							tokenData: { access_token: '', expires_in: 0, createdAt: 0 }
						})
					);
				} else {
					integrationData.current = {
						integrationType
					};
					setShowAuthModal(true);
				}
				break;

			default:
				break;
		}
	};

	return {
		onIntegration,
		onDuplicateLinkAsset,
		toggleAuth,
		linkProjectToGoogleDrive
	};
};

export default useIntegration;
