export type ActionItemNotifProps = {
	toggleActionItemsNotifs: () => void;
	showActionItemNotifs: boolean;
	setNewItemsInActivityLog: (val: boolean) => void;
	setPendingActionItems: (val: boolean) => void;
	areActionItemsPending: boolean;
};

export enum NotificationTabs {
	ACTION_ITEMS = 'ACTION_ITEMS',
	ACTIVITY = 'ACTIVITY',
	SEARCH = 'SEARCH'
}

export interface CustomFeedbackNotif {
	_id: string;
	profilePic: string;
	name: string;
	action: string;
	date: string;
	details: string;
	page: string;
	isCompleted: string;
	canvasLink?: string;
}
