import { ActionReducerMapBuilder, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IUser } from '@naya_studio/types';
import {
	TActionType,
	TAddUserToBlockFulfill,
	TAddUserToProjectFulfill,
	TAddUserToStageFulfill,
	TCreateProjectThunkArg,
	TLoadProjectUsersFulfill,
	TRemoveUserFromBlockFulfill,
	TRemoveUserFromProjectFulfill,
	TRemoveUserFromStageFulfill
} from 'src/types/argTypes';
import { IProjectUsersInitState } from '../reducers/root.types';
import loadProjectUsers from '../reduxActions/projectUsers';
import { addUserToStage, removeUserFromStage } from '../reduxActions/stage';
import { addUserToProject, createProject, removeUserFromProject } from '../reduxActions/project';
import { addUserToBlock, removeUserFromBlock } from '../reduxActions/block';

const initialState: IProjectUsersInitState = {
	data: [],
	loading: false,
	error: null
};

/**
 * Reducer for Project Users
 */
const projectUsersSlice = createSlice({
	name: 'projectUsers',
	initialState,
	reducers: {
		loadUserOfProject: (state: IProjectUsersInitState, action: PayloadAction<IUser[]>) => {
			if (action.payload) state.data = action.payload;
		},
		unloadProjectUsers: (state: IProjectUsersInitState) => {
			state.data = [];
			state.loading = false;
			state.error = null;
		},
		addUsersToProjectUsers: (state: IProjectUsersInitState, action: PayloadAction<IUser[]>) => {
			const uniqueUsersMap = new Map();
			const uniqueUsersArray = [] as IUser[];

			[...state.data, ...action.payload].forEach((user) => {
				if (!uniqueUsersMap.has(user._id as string)) {
					uniqueUsersMap.set(user._id as string, user);
					uniqueUsersArray.push(user);
				}
			});

			state.data = uniqueUsersArray;
		},
		removeUserFromProjectUsers: (
			state: IProjectUsersInitState,
			action: PayloadAction<string>
		) => {
			state.data = state.data.filter((user) => (user._id as string) !== action.payload);
		}
	},
	extraReducers: (builder: ActionReducerMapBuilder<IProjectUsersInitState>) => {
		/** ---- LOAD PROJECT USERS IN REDUX ---- */
		// FULFILLED
		builder.addCase(
			loadProjectUsers.fulfilled,
			(
				state: IProjectUsersInitState,
				action: PayloadAction<TActionType<TLoadProjectUsersFulfill, {}>['payload']>
			) => {
				state.loading = true;
				if (action.payload) {
					const { payload } = action;
					if (payload) {
						const { users } = payload;
						state.data = users;
					}
				}
				state.loading = false;
			}
		);

		/** ---- ADD USER TO PROJECT ---- */
		// FULFILLED
		builder.addCase(
			addUserToProject.fulfilled,
			(
				state: IProjectUsersInitState,
				action: PayloadAction<TActionType<TAddUserToProjectFulfill, {}>['payload']>
			) => {
				if (action.payload) {
					const { user, isInvitedUser } = action.payload;

					if (!isInvitedUser) {
						const invitedUser = user as IUser;
						const userIndex = state.data.findIndex(
							(x: IUser) => x._id === invitedUser._id
						);
						if (userIndex === -1) {
							state.data = [...state.data, invitedUser];
						}
					}
				}
				state.loading = false;
			}
		);

		/** ---- ADD USER TO STAGE ---- */
		// FULFILLED
		builder.addCase(
			addUserToStage.fulfilled,
			(
				state: IProjectUsersInitState,
				action: PayloadAction<TActionType<TAddUserToStageFulfill, {}>['payload']>
			) => {
				if (action.payload) {
					const { user, isInvitedUser } = action.payload;

					if (!isInvitedUser) {
						const invitedUser = user as IUser;
						const userIndex = state.data.findIndex(
							(x: IUser) => x._id === invitedUser._id
						);
						if (userIndex === -1) {
							state.data = [...state.data, invitedUser];
						}
					}
				}
				state.loading = false;
			}
		);

		/** ---- ADD USER TO BLOCK ---- */
		// FULFILLED
		builder.addCase(
			addUserToBlock.fulfilled,
			(
				state: IProjectUsersInitState,
				action: PayloadAction<TActionType<TAddUserToBlockFulfill, {}>['payload']>
			) => {
				if (action.payload) {
					const { user, isInvitedUser } = action.payload;

					if (!isInvitedUser) {
						const invitedUser = user as IUser;
						const userIndex = state.data.findIndex(
							(x: IUser) => x._id === invitedUser._id
						);
						if (userIndex === -1) {
							state.data = [...state.data, invitedUser];
						}
					}
				}
				state.loading = false;
			}
		);

		/** ---- CREATE NEW PROJECT ---- */
		// PENDING
		builder.addCase(
			createProject.pending,
			(
				state: IProjectUsersInitState,
				action: PayloadAction<
					undefined,
					string,
					TActionType<{}, TCreateProjectThunkArg>['pendingMeta']
				>
			) => {
				const { user } = action.meta.arg;
				state.data = [user];
			}
		);

		/** ---- REMOVE USER FROM PROJECT ---- */
		// FULFILLED
		builder.addCase(
			removeUserFromProject.fulfilled,
			(
				state: IProjectUsersInitState,
				action: PayloadAction<TActionType<TRemoveUserFromProjectFulfill, {}>['payload']>
			) => {
				if (action.payload) {
					const { userId, isInvitedUser } = action.payload;

					if (!isInvitedUser) {
						state.data = state.data.filter((u) => (u._id as string) !== userId);
						state.loading = false;
						state.error = null;
					}
				}
			}
		);

		// ---- REMOVE USER FROM STAGE ----
		builder.addCase(
			removeUserFromStage.fulfilled,
			(
				state: IProjectUsersInitState,
				action: PayloadAction<TActionType<TRemoveUserFromStageFulfill, {}>['payload']>
			) => {
				const { isInvitedUser, existOnOtherLevels, user } = action.payload;

				if (!isInvitedUser && !existOnOtherLevels) {
					state.data = state.data.filter((u) => (u._id as string) !== user);
					state.loading = false;
					state.error = null;
				}
			}
		);

		/** ---- REMOVE USER FROM BLOCK ---- */
		// FULFILLED
		builder.addCase(
			removeUserFromBlock.fulfilled,
			(
				state: IProjectUsersInitState,
				action: PayloadAction<TActionType<TRemoveUserFromBlockFulfill, {}>['payload']>
			) => {
				const { isInvitedUser, existOnOtherLevels, user } = action.payload;

				if (!isInvitedUser && !existOnOtherLevels) {
					state.data = state.data.filter((u) => (u._id as string) !== user);
					state.loading = false;
					state.error = null;
				}
			}
		);
	}
});

export const {
	loadUserOfProject,
	unloadProjectUsers,
	addUsersToProjectUsers,
	removeUserFromProjectUsers
} = projectUsersSlice.actions;
export default projectUsersSlice.reducer;
