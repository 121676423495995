import { IProject, IProjectDetail, IProjectGroup, IUserPreferenceNode } from '@naya_studio/types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { AppDispatch } from 'src';
import { addSnackbar, removeSnackbar } from 'src/redux/actions/snackBar';
import { editUserPreferences } from 'src/redux/actions/user';
import { ISnackBar, ReduxState } from 'src/redux/reducers/root.types';
import { TEditUserPreferenceThunkArgs } from 'src/types/argTypes';
import getUserFromRedux from 'src/util/helper/user';

// When a user gets to this route, we wait till we can get the user's preferences.
// that way, we know we have the user's information. then we can make the request to the api
// Disable notifications if that project in the list of projects to disable notifications for.
// Redirect the user to the accounts page.

const Unsubscribe = () => {
	const dispatch = useDispatch<AppDispatch>();
	const history = useHistory();
	const { projectId } = useParams<{ projectId: string }>();
	const noNotificationsFor = useSelector(
		(state: ReduxState) =>
			(state.user.data.userPreferences as IUserPreferenceNode)?.noNotificationsFor || []
	);
	const preferences = useSelector(
		(state: ReduxState) => state.user.data.userPreferences as IUserPreferenceNode
	);
	let triggerApi = false;
	if (preferences._id) {
		triggerApi = true;
	}

	const disableNotification = async (_id: string) => {
		let idExists = false;
		for (let i = 0; i < noNotificationsFor.length; i++) {
			if (noNotificationsFor[i] === _id) {
				idExists = true;
			}
		}
		if (!idExists) {
			const updates: Partial<IUserPreferenceNode> = {
				noNotificationsFor: [...noNotificationsFor, _id]
			};

			const actionPayload: TEditUserPreferenceThunkArgs = {
				payload: updates,
				prevState: {
					preferences
				}
			};

			const user = getUserFromRedux();
			let journey = (user.defaultProjectGroup as IProjectGroup)?.projects?.find(
				(jou) => (jou as IProject)._id === _id
			);
			if (!journey)
				journey = user.sharedProjects?.find((project) => (project as IProject)._id === _id);

			const projectName =
				((journey as IProject)?.projectDetails as IProjectDetail)?.name || 'Untitled';

			dispatch(editUserPreferences(actionPayload))
				.unwrap()
				.then(() => {
					const successSnackbar: ISnackBar = {
						text: `Disabling email notifications for ${projectName}`,
						show: true,
						type: 'LOADER'
					};

					addSnackbar(successSnackbar);
				})
				.catch(() => {
					const failureSnackbar: ISnackBar = {
						text: `Unable to disable email notifications for ${projectName}. Please try again.`,
						show: true,
						type: 'ERROR'
					};

					addSnackbar(failureSnackbar);
				})
				.finally(() => removeSnackbar(3000));
		}
	};

	useEffect(() => {
		if (triggerApi) {
			disableNotification(projectId);
			history.push('/studio');
		}
	}, [projectId, triggerApi]);

	return <div />;
};

export default Unsubscribe;
